import React,{Component} from 'react';
import {PostData} from '../service/postData';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

let _categoryId = '0';
let _limitNo = 6;
export default class blogPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            blogArray : [],
            onLimit : 0,
            onListCount : 0,
            onRemainCount : 0
        }
    }

    async componentDidMount()
    {
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?category=');
        _categoryId = (_handleParam[1] > 0) ? _handleParam[1] : 0;
        
        this.renderBlogView(_categoryId,'N');
        this.setState({isLoaded:true});
    }

    renderBlogView(categoryId,know)
    {
        let _blogArray = [];
        let _limit = this.state.onLimit;
        let _limitUpdate = parseInt(_limit) + parseInt(_limitNo);
        let _listing = this.state.blogArray;

        if(know == 'F'){
            _limit = 0;
            _listing = [];
            _limitUpdate = parseInt(_limit) + parseInt(_limitNo);
        }
        else{
            _limitUpdate = parseInt(_limit) + parseInt(_limitNo);
        }

        let _url_GetData = global.webistePageBlogList+'?categoryId='+categoryId+'&limit='+_limit+','+_limitNo;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                let onRemainCount = 0;
                if (responseJson.response) {
                    responseJson.response.map((data,index) => {
                        _listing.push(data);
                    });
                    
                    _blogArray = responseJson.response;
                    onRemainCount = responseJson.totalBlog - parseInt(_limitUpdate);
                }
                this.setState({blogArray:_listing,onLimit:_limitUpdate,onListCount:responseJson.totalBlog,onRemainCount:onRemainCount,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    viewWebsiteBlogListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _blogDetails = this.state.blogArray;
            let dataList = [];

            if(_blogDetails.length > 0)
            {                          
                _blogDetails.map((compDatas, i) =>
                {
                    const row = compDatas;

                    let _imageUrl = global.webUrl+'img/blog_1.png';
                    if(row.image!=""){
                        _imageUrl = row.image;
                    }
                    
                    let _bTitle = atob(row.title);
                    let url_titles = _bTitle.replace(/ /gi,'-');
                    let url_title = url_titles.replace("?", "_");
                    let blog_id = row.blog_id;
                    let _finalUrl = global.webUrl+'blog/'+blog_id+'/'+url_title+'/';
                    let _shortDesc = atob(row.short_description);

                    dataList.push(
                        <div className="col-lg-4">
                            <div className=" card">
                                <a href={_finalUrl} className="link_poet">
                                    <div className="cover_link">
                                        <img className="card-img_top main_img" src={_imageUrl} alt="..." />
                                    </div>
                                </a>
                                <div className="card-body">
                                    <a href={_finalUrl} className="d-block">
                                        <h5 className="card-title">{_bTitle}</h5>
                                    </a>
                                    <p className="card-text">{_shortDesc}</p>
                                    <br/>
                                    <a href={_finalUrl} className="btn3">Read Full Blog</a>                                    
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            return dataList;
        }
    }

    showMore()
    {
        this.renderBlogView(_categoryId,'N');
    }

    render(){
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Vyavsay Blogs & News</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="sec4 sec4C">
                            <div className="container">
                                <div className="row">
                                    {this.viewWebsiteBlogListing()}
                                    <div className="blog_SM col-lg-12" style={{display:'block'}}>
                                        <ul>
                                            {
                                                this.state.onRemainCount <= 0 ?
                                                    <div></div>
                                                :
                                                <div><li>Showing Results of {this.state.onListCount - this.state.onRemainCount} from {this.state.onListCount}</li><li><p onClick={this.showMore.bind(this)}>Show More</p> </li></div>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}