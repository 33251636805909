import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};

let _BatchNoAdditionalChk1 = 0;
let _BatchNoAdditionalChk2 = 0;
let _BatchNoAdditionalLabel1 = '';
let _BatchNoAdditionalLabel2 = '';
let _BatchNoChk = 0;
let _BatchNoTxt = '';
let _MRPChK = 0;
let _MRPTxt = '';
let _ExpDateChk = 0;
let _ExpDateTxt = '';
let _MfgDateChk = 0;
let _MfgDateTxt = '';
let _ModelNoChk = 0;
let _ModelNoTxt = '';
let _SizeChk = 0;
let _SizeTxt = '';

let _loader = 'glb-ldr-prt active';

export default class QuickAddNewSaleBatchNo extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _BatchNoAdditionalChk1 = _businessSetting.BatchNoAdditionalChk1;
                        _BatchNoAdditionalChk2 = _businessSetting.BatchNoAdditionalChk2;
                        _BatchNoAdditionalLabel1 = _businessSetting.BatchNoAdditionalLabel1;
                        _BatchNoAdditionalLabel2 = _businessSetting.BatchNoAdditionalLabel2;

                        _BatchNoChk = _businessSetting.BatchNoChk;
                        _BatchNoTxt = _businessSetting.BatchNoTxt;
                        _MRPChK = _businessSetting.MRPChK;
                        _MRPTxt = _businessSetting.MRPTxt;
                        _ExpDateChk = _businessSetting.ExpDateChk;
                        _ExpDateTxt = _businessSetting.ExpDateTxt;
                        _MfgDateChk = _businessSetting.MfgDateChk;
                        _MfgDateTxt = _businessSetting.MfgDateTxt;
                        _ModelNoChk = _businessSetting.ModelNoChk;
                        _ModelNoTxt = _businessSetting.ModelNoTxt;
                        _SizeChk = _businessSetting.SizeChk;
                        _SizeTxt = _businessSetting.SizeTxt;

                    }
                }
            } 
        }
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-unit/',
            itemSelectData : this.props.itemSelectData ? this.props.itemSelectData : '0',
            itemId : this.props.itemId ? this.props.itemId : '0',
            _dataType: (this.props.dataType) ? this.props.dataType : 'ITEM',
            batchNo : this.props.batchNo ? this.props.batchNo : 0,
            OldBatTotalQty : this.props.OldBatTotalQty ? this.props.OldBatTotalQty : 0,
            OldBatStockTotalQty : this.props.OldBatStockTotalQty ? this.props.OldBatStockTotalQty : 0,
            itemFields :  this.props.itemFields ? this.props.itemFields : [],
            indexPosition: this.props.indexPosition ? this.props.indexPosition : 0,
            buttonTitle:'Add New',
            isLoaded : true, 
            unitArray : [],
            secUnitArray : [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            batch_no :  '',
            BatchNoAdditionalValue1 : '',
            BatchNoAdditionalValue2 : '',

            BatchNoAdditionalChk1 : _BatchNoAdditionalChk1,
            BatchNoAdditionalChk2 : _BatchNoAdditionalChk2,
            BatchNoAdditionalLabel1 : _BatchNoAdditionalLabel1,
            BatchNoAdditionalLabel2 : _BatchNoAdditionalLabel2,
            FreeItemQuantity : _businessSetting.FreeItemQuantity,
            BatchNoChk : _BatchNoChk,
            BatchNoTxt : _BatchNoTxt,
            MRPChK : _MRPChK,
            MRPTxt : _MRPTxt,
            ExpDateChk : _ExpDateChk,
            ExpDateTxt : _ExpDateTxt,
            MfgDateChk : _MfgDateChk,
            MfgDateTxt : _MfgDateTxt,
            ModelNoChk : _ModelNoChk,
            ModelNoTxt : _ModelNoTxt,
            SizeChk : _SizeChk,
            SizeTxt : _SizeTxt,

            opening_stock : this.props.opening_stock ? this.props.opening_stock : 0,
            itemBatchNoAll : this.props.itemBatchNoAll ? this.props.itemBatchNoAll : [],   
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            transactionId : this.props.transactionId ?  this.props.transactionId : 0,
            openingNewStock : 0,
            isError : false,
            batchSendType  : this.props.batchSendType ? this.props.batchSendType : 'New',
            error  :  '',
            error1  :  '',
            error2  :  '',
            error3  :  '',
            error4  :  '',
            error5  :  '',
            error6  :  '',
            error7  :  '',
            error8  :  '',
            error: {
                batch_no  :  '',
                error  :  '',
                error1  :  '',
                error2  :  '',
                error3  :  '',
                error4  :  '',
                error5  :  '',
                error6  :  '',
                error7  :  '',
                error8  :  '',
            }
        }
    }
    async componentDidMount ()
    {   
      
        let _itemBatchNoAllMain = this.state.itemBatchNoAll;
        _itemBatchNoAllMain = _itemBatchNoAllMain.map((data,index) => ({
            ...data,
            //exp_date : moment(data.exp_date, "MMMM D, YYYY").format('MM/DD/YYYY'),
            //mfg_date : moment(data.mfg_date, "MMMM D, YYYY").format('MM/DD/YYYY')
            exp_date : moment(data.exp_date).format('MM/DD/YYYY'),
            mfg_date : moment(data.mfg_date).format('MM/DD/YYYY')
        }))
        this.setState({itemBatchNoAll : _itemBatchNoAllMain});
    }
    
    handleChange(i, e, _type) {
        let itemBatchNoAll = this.state.itemBatchNoAll;
        let _openingStock = this.state.openingNewStock ? this.state.openingNewStock : 0; 
        let _isError = false; 
        
        if(_type == 'mfg_date'){               
            itemBatchNoAll[i][_type] = e;//moment(e).format('MMMM D, YYYY');//e;
        }
        else if(_type == 'exp_date' ){               
            itemBatchNoAll[i][_type] = e;//moment(e).format('MMMM D, YYYY');//e;
        }        
        else if(_type == 'batch_no' && _businessSetting.BatchNoChk == 1){
            _isError = false;             
            itemBatchNoAll[i][_type] = e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;
            itemBatchNoAll.map((data,index) => {
                data['error'] = "";
            })    
        }         
        else if(_type == 'model_no' && _businessSetting.ModelNoChk == 1){
            _isError = false;             
            itemBatchNoAll[i][_type] = e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;
            itemBatchNoAll.map((data,index) => {
                data['error3'] = "";
            })    
        }        
        else if(_type == 'size' && _businessSetting.SizeChk == 1){
            _isError = false;             
            itemBatchNoAll[i][_type] = e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;
            itemBatchNoAll.map((data,index) => {
                data['error4'] = "";
            })    
        }       
        else if(_type == 'batchNoAdditionalValue1' && _businessSetting.BatchNoAdditionalChk1 == 1){
            _isError = false;             
            itemBatchNoAll[i][_type] = e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;
            itemBatchNoAll.map((data,index) => {
                data['error5'] = "";
            })    
        }       
        else if(_type == 'batchNoAdditionalValue2' && _businessSetting.BatchNoAdditionalChk2 == 1){
            _isError = false;             
            itemBatchNoAll[i][_type] =   e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;
            itemBatchNoAll.map((data,index) => {
                data['error6'] = "";
            })    
        }       
        else if(_type == 'opening_stock'){
            _isError = false;             
            itemBatchNoAll[i][_type] = e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;
            itemBatchNoAll[i]['used_stock'] = e.target.value;
            if(e.target.value == 0 || e.target.value == '0'){
                itemBatchNoAll.map((data,index) => {
                    data['error7'] = "*Opening stock cannot be 0"; 
                }) 
            }
            itemBatchNoAll.map((data,index) => {
                data['error7'] = "";
            })    
        }  
        else if(_type == 'free_quantity'){
            _isError = false;             
            itemBatchNoAll[i][_type] = e.target.value;        
            itemBatchNoAll[i][e.target.name] = e.target.value;              
        }  
        //console.log("===itemBatchNoAll===",itemBatchNoAll);             
        //itemBatchNoAll[i][e.target.name] = e.target.value;
        this.setState({ itemBatchNoAll,isError:_isError});
    }
    
    addFormFields() {
        let _changeDate = moment().format('MM/DD/YYYY');
        this.setState(({
            itemBatchNoAll: [...this.state.itemBatchNoAll, { batch_no: "", mrp_price: "", exp_date: _changeDate, mfg_date: _changeDate, model_no: "", size: "", batchNoAdditionalValue1: "" , batchNoAdditionalValue2: "",opening_stock : 0, error : '' , error1 : '', error2 : '', error3 : '', error4 : '', error5 : '', error6 : '', error7 : '', error8 : ''   }]
        }));
    }

    removeFormFields(i) {
        let itemBatchNoAll = this.state.itemBatchNoAll;
        itemBatchNoAll.splice(i, 1);
        this.setState({ itemBatchNoAll });
    }  

    clickToAddData = (event) => {        
            event.preventDefault();
            let _itemBatchData = (this.state.itemBatchNoAll);
            let _isError = false;  
            let freeQuantity = 0;
            console.log("==_itemBatchData==",JSON.stringify(_itemBatchData));
            _itemBatchData.map((dataBatchItem,index) =>{
                dataBatchItem.isNewItem = true;
                freeQuantity = dataBatchItem.free_quantity ? dataBatchItem.free_quantity : 0;
                dataBatchItem.free_quantity = freeQuantity;
            })

            let _opening_stock = 0;// this.state.openingNewStock ?  this.state.openingNewStock: 0;
            
            let itemPrice = this.state.itemPrice ? this.state.itemPrice : 0;
            let _totalAmount =  0;
            
            let _totalAmountOld =  this.state.totalAmountOld ? this.state.totalAmountOld : 0;
            
            let _totalQuantity =  this.state.totalQuantity ? this.state.totalQuantity : 0 ;
            let batchId = 0;
            _itemBatchData.map((data,index) => {              
                _opening_stock += data.opening_stock ? parseInt(data.opening_stock) : 0;           
                //data.exp_date = moment(data.exp_date, "MM/DD/YYYY").format('MMMM D, YYYY');
                //data.mfg_date = moment(data.mfg_date, "MM/DD/YYYY").format('MMMM D, YYYY');
                
                //data.exp_date = moment(data.exp_date, "MMMM D, YYYY").format('MM/DD/YYYY');
               // data.mfg_date = moment(data.mfg_date, "MMMM D, YYYY").format('MM/DD/YYYY');
                batchId = data.batch_id ? data.batch_id : 0;
                if(data.batch_no == '' && _businessSetting.BatchNoChk == 1){
                    _isError = true;
                    data['error'] = "*Please Enter "+this.state.BatchNoTxt+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.mfg_date == '' && _businessSetting.MfgDateChk == 1){
                    _isError = true;
                    data['error1'] = "*Please Enter "+this.state.MfgDateTxt+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.exp_date == '' && _businessSetting.ExpDateChk == 1){
                    _isError = true;
                    data['error2'] = "*Please Enter "+this.state.ExpDateTxt+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.model_no == '' && _businessSetting.ModelNoChk == 1){
                    _isError = true;
                    data['error3'] = "*Please Enter "+this.state.ModelNoTxt+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.size == '' && _businessSetting.SizeChk == 1){
                    _isError = true;
                    data['error4'] = "*Please Enter "+this.state.SizeTxt+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.batchNoAdditionalValue1 == '' && _businessSetting.BatchNoAdditionalChk1 == 1){
                    _isError = true;
                    data['error5'] = "*Please Enter "+_businessSetting.BatchNoAdditionalLabel1+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.batchNoAdditionalValue2 == '' && _businessSetting.BatchNoAdditionalChk2 == 1){
                    _isError = true;
                    data['error6'] = "*Please Enter "+_businessSetting.BatchNoAdditionalLabel2+" ";
                    this.setState({isError: _isError}); 
                } 
                else if(data.opening_stock == '' || data.opening_stock == 0 || data.opening_stock == '0'){
                    _isError = true;
                   
                    if(data.opening_stock == 0 || data.opening_stock == '0')  {
                        data['error7'] = "*Opening stock cannot be 0 or blank"; 
                    }else{
                        data['error7'] = "*Please Enter Opening Stock "; 
                    }
                    this.setState({isError: _isError}); 
                }
            })

            if(_opening_stock == 0 || _opening_stock == ''){
                _isError = true;
                _opening_stock =  this.state.opening_stock;
                
            }
            let currentQty = 0;
            if(this.state.batchSendType == 'New'){ 
                
                _totalAmount =  (parseFloat(itemPrice) * parseFloat(_opening_stock)).toFixed(2);
                _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(_totalAmount) ;
                _totalQuantity = parseFloat(_totalQuantity) + parseFloat(_opening_stock)  + parseFloat(freeQuantity) ;
            }
            else{
                currentQty =  parseFloat(_opening_stock)  + parseFloat(freeQuantity);
                
                if(_totalQuantity > currentQty){
                    
                    let oldQtyAmt =   (parseFloat(this.state.itemPrice) * parseFloat(this.state.OldBatStockTotalQty)).toFixed(2);
                
                    _totalQuantity = parseFloat(_totalQuantity) - parseFloat(this.state.OldBatTotalQty);
                    _totalQuantity = _totalQuantity + currentQty;
                    _totalAmount =  (parseFloat(itemPrice) * parseFloat(_opening_stock)).toFixed(2);
                    
                    //_totalAmountOld = parseFloat(_totalAmountOld) - parseFloat(oldQtyAmt);
                    _totalAmountOld =  parseFloat(_totalAmount) + parseFloat(this.state.totalAmountOld);
                   
                    if(batchId > 0 && (_totalAmountOld < oldQtyAmt)){
                        _totalAmountOld =  parseFloat(_totalAmountOld);
                       
                    }else{
                        if(_totalAmountOld > oldQtyAmt){
                           
                            _totalAmountOld =  parseFloat(_totalAmountOld) - parseFloat(oldQtyAmt);
                           
                        }else{
                            _totalAmountOld = parseFloat(_totalAmountOld) - parseFloat(oldQtyAmt);
                            
                        }
                        
                    }
                }
                else{                        
                    currentQty = parseFloat(_opening_stock)  + parseFloat(freeQuantity);
                    _totalQuantity = parseFloat(_totalQuantity) - parseFloat(this.state.OldBatTotalQty);
                    _totalQuantity = _totalQuantity + currentQty;

                    let oldQtyAmt =   (parseFloat(this.state.itemPrice) * parseFloat(this.state.OldBatStockTotalQty)).toFixed(2);
                    
                    _totalAmount =  (parseFloat(itemPrice) * parseFloat(_opening_stock)).toFixed(2);
                    
                    //_totalAmountOld = _totalAmount;//parseFloat(_totalAmountOld) + parseFloat(_totalAmount) ;

                    _totalAmountOld =  parseFloat(_totalAmount) + parseFloat(this.state.totalAmountOld);
                    if(batchId > 0 && (_totalAmountOld < oldQtyAmt)){
                        _totalAmountOld =  parseFloat(_totalAmountOld);
                        
                    }else{
                        _totalAmountOld = parseFloat(_totalAmountOld) - parseFloat(oldQtyAmt);
                       
                    }
                }
            }

            
            let _fieldData =  this.state.itemFields;
            let discountAmt = 0;   
            let totalAmount = 0;
            let tax = 0;
            let amountBeforeTaxWithoutDisc = 0;
            let disc = 0;
            let amountBeforeTax = 0 ;
            let subTotal = 0;    
            let _discountAmtSend = 0;
            let _taxAmtSend = 0;
            let _itemToalAmtSend = 0;
            let _costPrice = 0;
            if(_isError ==  false){
                _fieldData.map((itemData, i) => {
                    if (i == this.state.indexPosition) {          
                        let qty = _opening_stock ? _opening_stock : 0;
                        let rateValue = parseFloat(itemData.rate);
                        let taxType = parseFloat(itemData.tax_type.ratio) ? parseFloat(itemData.tax_type.ratio) : 0.0;
                        let additionalCess = parseFloat(itemData.Additional_CESS) ? parseFloat(itemData.Additional_CESS) : 0;
                        let discount_in_per =  parseFloat(itemData.discount_in_per) ?  parseFloat(itemData.discount_in_per) : 0
                        let discount_in_amount =  parseFloat(itemData.discount_in_amount) ?  parseFloat(itemData.discount_in_amount) : 0
                        console.log("==discount_in_amount==",discount_in_amount);
                        subTotal = rateValue * qty;
                        if (itemData.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
                        {   
                            if(discount_in_per > 0){       
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100); 
                                discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                                disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                                tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                                totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);
                
                                //Discount amont == disc;
                                disc = disc.toFixed(2);
                                discountAmt = discountAmt.toFixed(2);
                                tax = tax.toFixed(2);
                                totalAmount = totalAmount.toFixed(2);
                                
                                _discountAmtSend = disc;
                                _taxAmtSend = tax;
                                _itemToalAmtSend = totalAmount;
                            }
                            else{
                                if(taxType > 0){
                                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                                }else{
                                    _costPrice = subTotal;
                                }                    
                                discountAmt = discount_in_amount;// * qty;;
                                amountBeforeTax = _costPrice - discountAmt;
                                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                                tax = amountBeforeTax * taxType / 100;
                                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                                discountAmt = discountAmt.toFixed(2);
                                tax = tax.toFixed(2);
                                totalAmount = totalAmount.toFixed(2);
                
                                _discountAmtSend = discountAmt;
                                _taxAmtSend = tax;
                                _itemToalAmtSend = totalAmount;
                            }
                        }            
                        else if (itemData.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0')) 
                        {              
                            if(discount_in_per > 0){           
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);        
                                discountAmt = (discount_in_per / 100) * subTotal;
                                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                                disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                                tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                                totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + additionalCess;
                
                                //Discount amont == disc;
                                disc = disc.toFixed(2);
                                discountAmt = discountAmt.toFixed(2);
                                tax = tax.toFixed(2);
                                totalAmount = totalAmount.toFixed(2);
                                
                                _discountAmtSend = disc;
                                _taxAmtSend = tax;
                                _itemToalAmtSend = totalAmount;
                            }
                            else{              
                                if(taxType > 0){
                                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                                }else{
                                    _costPrice = subTotal;
                                }             
                                discountAmt = discount_in_amount;// * qty;;
                                amountBeforeTax = _costPrice - discountAmt;
                                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                                tax = amountBeforeTax * taxType / 100;
                                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                                
                                discountAmt = discountAmt.toFixed(2);
                                tax = tax.toFixed(2);
                                totalAmount = totalAmount.toFixed(2);
                
                                _discountAmtSend = discountAmt;
                                _taxAmtSend = tax;
                                _itemToalAmtSend = totalAmount;
                            }
                        }                        
                        else {
                            if(discount_in_per > 0){
                                
                                console.log("==WWWW==",discount_in_amount);
                                _costPrice = subTotal;          
                                discountAmt = discount_in_amount;//(discount_in_per / 100) * subTotal;
                                amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                                tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                                totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);            
                                discountAmt = discountAmt.toFixed(2);
                                tax = tax.toFixed(2);
                                totalAmount = totalAmount.toFixed(2);
                                
                                _discountAmtSend = discountAmt;
                                _taxAmtSend = tax;
                                _itemToalAmtSend = totalAmount;
                            }
                            else{
                                console.log("==RRRR==",discount_in_amount);
                                subTotal = rateValue * qty;     
                                _costPrice = subTotal;                     
                                discountAmt = discount_in_amount;// * qty;;
                                amountBeforeTax = subTotal - discountAmt;
                                tax = amountBeforeTax * taxType / 100;
                                //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                                
                                discountAmt = discountAmt.toFixed(2);
                                tax = tax.toFixed(2);
                                totalAmount = totalAmount.toFixed(2);
                                
                                _discountAmtSend = discountAmt;
                                _taxAmtSend = tax;
                                _itemToalAmtSend = totalAmount;
                            }
                                    
                        }
                    }
                })
                this.props.callBack(_itemBatchData, _opening_stock,freeQuantity,_totalAmount,_totalQuantity,_totalAmountOld,itemPrice,_discountAmtSend,_taxAmtSend,_itemToalAmtSend, this.state.indexPosition,_costPrice);
            }
    }
    
    handleCallBackDate = (start,index) => {
        this.setState({mfg_date:start.format('MM/DD/YYYY')});
        this.handleChange(index,this.state.mfg_date,'mfg_date');
    }
    handleCallBackExpDate = (start,index) => {
        //this.setState({exp_date:start.format('MMMM D, YYYY')});
        this.setState({exp_date:start.format('MM/DD/YYYY')});
        this.handleChange(index,this.state.exp_date,'exp_date');
    }
    
    
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        if(this.state.itemBatchNoAll.length == 0){
            let _changeDate = moment().format('MM/DD/YYYY');          
            this.setState({ 
                itemBatchNoAll: [{ batch_no: "", mrp_price: "", exp_date: _changeDate, mfg_date: _changeDate, model_no: "", size: "", batchNoAdditionalValue1 : "" , batchNoAdditionalValue2 : "",opening_stock: 0 }]
            });            
        }
        else{
            if(this.state.batchNo!='0' && this.state.batchNo!=0){
                this.state.itemBatchNoAll.filter((data) => data.batch_no == this.state.batchNo);
                //this.setState({itemBatchNoAll:_itemBatchNoAll});
                console.log("==1==batchNo==",this.state.batchNo);
                console.log("==1==itemBatchNoAll==",this.state.itemBatchNoAll);
            }
        }
              
        const {errors} = this.state;
        //const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">  
                    
                    <div className="claerfix"></div>      
                              
                    {this.state.itemBatchNoAll.map((element, index) => (
                        
                    <div className="form-group row">                                                        
                        {this.state.BatchNoChk == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="batch_no" name="batch_no" value={element.batch_no || ""} placeholder={this.state.BatchNoTxt} onChange={e => this.handleChange(index, e,'batch_no')} required="required"/>
                                <label htmlFor="login-username">{this.state.BatchNoTxt} <span class="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error?.length > 0 && <span className='error'>{element.error}</span>}</span>
                            </div> 
                        </div>
                        }

                        {this.state.MfgDateChk == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <DateRangePicker
                                    initialSettings={{
                                    singleDatePicker: true,
                                    startDate: moment(element.mfg_date).format('MM/DD/YYYY') || this.state.mfg_date//this.state.mfg_date
                                    }}
                                    onCallback={(date) => this.handleCallBackDate(date,index)}
                                >
                                <input type="text" className="form-control" value={moment(element.mfg_date).format('MM/DD/YYYY') || this.state.mfg_date} name="mfg_date" id='mfg_date'/>
                                </DateRangePicker>
                                <label htmlFor="login-username">{this.state.MfgDateTxt}</label>
                                
                                <span className="iris_error_txt">{element.error1?.length > 0 && <span className='error'>{element.error1}</span>}</span>
                            </div> 
                        </div> 
                        }

                        {this.state.ExpDateChk == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <DateRangePicker
                                    initialSettings={{
                                    singleDatePicker: true,
                                    startDate1: moment(element.exp_date ).format('MM/DD/YYYY') || this.state.exp_date//this.state.exp_date
                                    }}
                                    onCallback={(date) => this.handleCallBackExpDate(date,index)}
                                >
                                <input type="text" className="form-control" value={moment(element.exp_date ).format('MM/DD/YYYY') || this.state.exp_date} name="exp_date" id='exp_date'/>
                                </DateRangePicker >
                                <label htmlFor="login-username">{this.state.ExpDateTxt}</label>
                                <span className="iris_error_txt">{element.error2?.length > 0 && <span className='error'>{element.error2}</span>}</span>
                            </div> 
                        </div> 
                        }

                        {this.state.ModelNoChk == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="model_no" name="model_no" value={element.model_no || ""} placeholder={this.state.ModelNoTxt} onChange={e => this.handleChange(index, e,'model_no')} maxLength="30"/>
                                <label htmlFor="login-username">{this.state.ModelNoTxt} <span class="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error3?.length > 0 && <span className='error'>{element.error3}</span>}</span>
                            </div> 
                        </div> 
                        }

                        {this.state.SizeChk == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="size" name="size" value={element.size || ""} placeholder={this.state.SizeTxt} onChange={e => this.handleChange(index, e,'size')}/>
                                <label htmlFor="login-username">{this.state.SizeTxt} <span class="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error4?.length > 0 && <span className='error'>{element.error4}</span>}</span>
                            </div> 
                        </div> 
                        }                       

                        {this.state.BatchNoAdditionalChk1 == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="batchNoAdditionalValue1" name="batchNoAdditionalValue1" value={element.batchNoAdditionalValue1 || ""} placeholder="Enter Value 1" onChange={e => this.handleChange(index, e,'batchNoAdditionalValue1')}/>
                                <label htmlFor="login-username">{this.state.BatchNoAdditionalLabel1} <span class="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error5?.length > 0 && <span className='error'>{element.error5}</span>}</span>
                                
                            </div> 
                        </div>  
                        }
                        {this.state.BatchNoAdditionalChk2 == 1 &&
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="batchNoAdditionalValue2" name="batchNoAdditionalValue2" value={element.batchNoAdditionalValue2 || ""} placeholder="Enter Value 2" onChange={e => this.handleChange(index, e,'batchNoAdditionalValue2')}/>
                                <label htmlFor="login-username">{this.state.BatchNoAdditionalLabel2} <span class="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error6?.length > 0 && <span className='error'>{element.error6}</span>}</span>
                            </div> 
                        </div>                                   
                        }
                                                
                        <div className="col-3">
                            <div className="form-material open">
                                <input type="number" className="form-control" id="opening_stock" name="opening_stock" value={element.opening_stock || ""} placeholder="Opening Stock" onChange={e => this.handleChange(index, e,'opening_stock')}/>
                                <label htmlFor="login-username">Opening Stock <span class="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error7?.length > 0 && <span className='error'>{element.error7}</span>}</span>
                            </div> 
                        </div> 
                                         
                        {this.state.FreeItemQuantity == 1 &&
                            <div className="col-2">
                                <div className="form-material open">
                                    <input type="number" className="form-control" id="free_quantity" name="free_quantity" value={element.free_quantity || ""} placeholder="Free Quantity" onChange={e => this.handleChange(index, e,'free_quantity')}   autoComplete="off"/>
                                    <label htmlFor="login-username">Free Quantity </label>
                                <span className="iris_error_txt">{element.error8?.length > 0 && <span className='error'>{element.error8}</span>}</span>
                                </div> 
                            </div> 
                            } 
                        {((this.state.itemBatchNoAll.length > 1) && (index >= 1) ) &&
                        <div className="col-1">
                            <div className="form-material open">
                                <a  className="badge badge-danger pull-right" onClick={() => this.removeFormFields(index)}>
                                <i className="fa fa-solid fa-trash"></i>
                                </a>
                            </div>
                        </div>

                        }                            
                        
                    </div>
                    ))}
                    
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} >
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                            &nbsp;&nbsp;
                            {/* <a className="btn btn-alt-primary" onClick={() => this.addFormFields()}>Add </a> */}
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}