export default{
    /* Login pages Here */
    accountLoginPanel: '/login/',//global.PATH_FOLDER,
    accountRegisterPanel: '/business-register/',
    /* End Login pages Here */


    /* Admin pages Here */
    privatePanel: global.PATH_FOLDER+"administrator/",
    _admin_DashboardPanel: global.PATH_FOLDER+"administrator/dashboard/",
    _admin_profilePanel: global.PATH_FOLDER+"administrator/profile/",
    
    _admin_countryPanel: global.PATH_FOLDER+"administrator/manage-country/",
    _admin_gstTypePanel: global.PATH_FOLDER+"administrator/manage-gst-type/",
    _admin_gstListPanel: global.PATH_FOLDER+"administrator/manage-gst/",
    _admin_unitListPanel: global.PATH_FOLDER+"administrator/manage-unit/",
    _admin_businessTypeListPanel: global.PATH_FOLDER+"administrator/manage-business-type/",
    _admin_businesscategoryListPanel: global.PATH_FOLDER+"administrator/manage-business-category/",

    _admin_BusinessListPanel: global.PATH_FOLDER+"administrator/manage-business/",
    _admin_BusinessAddPanel: global.PATH_FOLDER+"administrator/add-business/",
    _admin_CompanyListPanel: global.PATH_FOLDER+"administrator/manage-company/",
    _admin_CompanyAddPanel: global.PATH_FOLDER+"administrator/add-company/",
    _admin_BusinessUserListPanel: global.PATH_FOLDER+"administrator/manage-business-user/",

    _admin_BlogPanel : global.PATH_FOLDER+"administrator/blog-list/",
    _admin_BlogAddPanel : global.PATH_FOLDER+"administrator/add-blog/",
    _admin_BlogCategoryPanel : global.PATH_FOLDER+"administrator/manage-blog-category/",
    _admin_WebsitePlanPanel : global.PATH_FOLDER+"administrator/manage-website-plan/",
    _admin_WebsitePlanPricePanel : global.PATH_FOLDER+"administrator/manage-website-plan-price/",
    
    _admin_BusinessSMSSetting : global.PATH_FOLDER+"administrator/manage-sms-setting/",
    _admin_BusinessPendingSMS : global.PATH_FOLDER+"administrator/pending-business-sms/",
    /* End Admin pages Here */

    /* Business Admin pages Here */
    businessPanel: global.PATH_FOLDER+"business/",
    _business_DashboardPanel: global.PATH_FOLDER+"business/dashboard/",
    _business_profilePanel: global.PATH_FOLDER+"business/profile/",
    _business_CompanyListPanel: global.PATH_FOLDER+"business/manage-company/",
    _business_CompanyAddPanel: global.PATH_FOLDER+"business/add-company/",
    _business_BusinessUserListPanel: global.PATH_FOLDER+"business/manage-business-user/",
    _business_smsAddListData: global.PATH_FOLDER+"business/manage-sms-list/",
    
    _business_BusinessCommonSettingPanel: global.PATH_FOLDER+"business/company-setting/",
    /* End Business Admin pages Here */
    
    /* Business Admin pages Here */
    userPanel: global.PATH_FOLDER+"business/",
    _user_DashboardPanel: global.PATH_FOLDER+"business/dashboard/",
    
    _user_PartyPanel: global.PATH_FOLDER+"business/manage-party/",
    _user_AddParty: global.PATH_FOLDER+"business/add-party/",
    _user_saleList: global.PATH_FOLDER+"business/manage-sale/",
    _user_saleInvoice : global.PATH_FOLDER+"business/sale-invoice/",
    _user_saleInvoice_Add: global.PATH_FOLDER+"business/add-sale/",
    _user_saleInvoice_Add2: global.PATH_FOLDER+"business/add-sale-2/",

    /* Item / Service pages Here */
    _user_Item_Add: global.PATH_FOLDER+"business/add-item/",
    _user_itemList: global.PATH_FOLDER+"business/manage-items/",
    _user_ServiceList: global.PATH_FOLDER+"business/manage-service/",
    _user_Service_Add: global.PATH_FOLDER+"business/add-service/",
    
    /* Sale Transaction pages Here */
    _user_saleEstimateList : global.PATH_FOLDER+"business/manage-sale-estimate/",
    _user_saleEdtimate_Add: global.PATH_FOLDER+"business/add-sale-estimate/",

    _user_saleOrderList : global.PATH_FOLDER+"business/manage-sale-order/",
    _user_saleOrder_Add: global.PATH_FOLDER+"business/add-sale-order/",
    
    _user_saleDelvChallanList : global.PATH_FOLDER+"business/manage-sale-delivery-challan/",
    _user_saleDelvChallan_Add: global.PATH_FOLDER+"business/add-sale-delivery-challan/",
    
    _user_salePaymentIn_Add: global.PATH_FOLDER+"business/add-sale-payment-in/",
    _user_salePaymentInList : global.PATH_FOLDER+"business/manage-sale-payment-in/",
    
    _user_saleRetCreditNote_Add: global.PATH_FOLDER+"business/add-sale-credit-note/",
    _user_saleReturnList : global.PATH_FOLDER+"business/manage-sale-return/",
    
    _user_saleReceivePaymentIn_Add: global.PATH_FOLDER+"business/add-receive-payment-in/",
    //_user_saleToReturnCreditNote_Add: global.PATH_FOLDER+"business/add-sale-to-return/",
    _user_saleMakePaymentOut_Add: global.PATH_FOLDER+"business/add-make-payment-out/",
    
    _user_saleDuplicate_Add: global.PATH_FOLDER+"business/add-sale-duplicate/",
    _user_saleReturnDuplicate: global.PATH_FOLDER+"business/add-sale-return-duplicate/",
    
    _user_saleOrderDuplicate_Add: global.PATH_FOLDER+"business/add-sale-duplicate-order/",

    /* End Business Admin pages Here */

    _user_unitList: global.PATH_FOLDER+"business/manage-unit/",
    /* End Business Admin pages Here */
    
    /* Start Purchase Section */
    _user_purchaseInvoice : global.PATH_FOLDER+"business/purchase-invoice/",
    _user_purchaseInvoice_Add: global.PATH_FOLDER+"business/add-purchase/",
    _user_purchaseInvoice_Add2: global.PATH_FOLDER+"business/add-purchase-2/",

    _user_purchaseOrderList : global.PATH_FOLDER+"business/manage-purchase-order/",
    _user_purchaseOrder_Add: global.PATH_FOLDER+"business/add-purchase-order/",

    _user_purchasePaymentOut_Add: global.PATH_FOLDER+"business/add-purchase-payment-out/",
    _user_purchasePaymentOutList : global.PATH_FOLDER+"business/manage-purchase-payment-out/",
    
    _user_purchaseRetDebitNote_Add: global.PATH_FOLDER+"business/add-purchase-debit-note/",
    _user_purchaseReturnList : global.PATH_FOLDER+"business/manage-purchase-return/",

    _user_purchaseMakePayment : global.PATH_FOLDER+"business/manage-purchase-make-payment/",
    //_user_purchaseToReturn : global.PATH_FOLDER+"business/manage-purchase-to-return/",
    _user_purchaseToDuplicate : global.PATH_FOLDER+"business/manage-purchase-to-duplicate/",
    _user_purchaseMakePaymentIn : global.PATH_FOLDER+"business/manage-purchase-make-payment-in/",

    _user_purchaseToDuplicateReturn : global.PATH_FOLDER+"business/manage-purchase-to-duplicate-return/",

    _user_purchaseOrderDuplicate: global.PATH_FOLDER+"business/manage-purchase-order-duplicate/",

    _user_expense : global.PATH_FOLDER+"business/manage-expense/",
    _user_addExpense : global.PATH_FOLDER+"business/add-expense/",
    _user_addExpenseDuplicate : global.PATH_FOLDER+"business/duplicate-expense/",
    _user_addExpensePayment : global.PATH_FOLDER+"business/expense-make-payment/",

    _user_accountList : global.PATH_FOLDER+"business/manage-account-list/",
    _user_cashInHandList : global.PATH_FOLDER+"business/manage-cash-in-hand/",
    _user_loacAccountList : global.PATH_FOLDER+"business/manage-loan-accounts/",
    _user_chequeList : global.PATH_FOLDER+"business/manage-cheque-list/",

    _user_loacAccountStatementList : global.PATH_FOLDER+"business/manage-loan-statement/",

    _user_partyToPartyTransfer : global.PATH_FOLDER+"business/party-to-party-transfer/",

    _user_reports : global.PATH_FOLDER+"business/reports/",
    _user_allPrefixList : global.PATH_FOLDER+"business/all-prefix-list/",

    _user_otherIncomeTransList : global.PATH_FOLDER+"business/other-income-list/",
    _user_otherIncomeCreateTrans : global.PATH_FOLDER+"business/create-other-income/",
    _user_otherDuplicateIncomeCreate : global.PATH_FOLDER+"business/create-other-income-cuplicate/",
    _user_otherIncomeList : global.PATH_FOLDER+"business/other-income/", 
    _user_utilitiyImportItemList : global.PATH_FOLDER+"business/import-items-list/", 
    _user_utilitiyImportPartyList : global.PATH_FOLDER+"business/import-party-list/", 
    _user_utilitiyExportItemList : global.PATH_FOLDER+"business/export-items-list/", 
    _user_utilitiyBulkUpdateItemList : global.PATH_FOLDER+"business/bulk-update-items/", 
    _user_utilitiyBulkUpdateServiceList : global.PATH_FOLDER+"business/bulk-update-services/", 
    _user_utilitiyGenerateBarcode : global.PATH_FOLDER+"business/generate-barcode/", 
}