import React,{Component} from 'react';
import {PostData} from '../service/postData';
import {sendNotification} from '../_notificationSettings/notificationPanel';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class aboutUsPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            name:'',
            email:'',
            contact:'',
            message:'',
            errors:{
                name:'',
                email:'',
                contact:'',
                message:'',
            }
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                this.setState({ name: value });
                break;
            case 'email':
                this.setState({ email: value });
                break;
            case 'contact': 
                if (/^\d*$/.test(value)) {
                    if (value.length >= 0 && value.length <= 10) {
                        this.setState({ contact: value });
                    }
                }
            break;
            case 'message': 
                this.setState({message:value}); 
            break;
            default:
            break;
        }
    }

    clickToSubmitEnqury = (event) => {
        event.preventDefault();
        const regex = /^\d{10}$/;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(this.state.name == '')
        {
            alert("Name must be 3 characters long!");
        }else if(!(this.state.email && isValidEmail.test(this.state.email))){
            alert("Email-Id is invalid.");
        }else if(!regex.test(this.state.contact)){
            alert('Mobile number is invalid.')
        }else if(this.state.message == '') {
            alert("Please enter your message.");
        }else{
            this.getTouchFormSubmit();
        }
    }

    getTouchFormSubmit()
    {
        const requestData = JSON.stringify({
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            message: this.state.message,
            pageSubject:'Enquiry From Get In Touch - About Us Page',
        });
        
        PostData(global.webistePageGetTouchForm, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                this.setState({name: '',contact: '',email: '',message: ''});
                sendNotification("Success Message 👍","Enquiry mail send successfully.","success");
            }
            else
            {
                sendNotification("Error Message 😓","Some error occured. Please try again.","danger");
                return;
            }
        });
    }

    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row inner-banner">
                                    <div className="col-lg-7">
                                        <h1 className="txt-style-8">Easy to Use<br/>Business Accounting Software</h1>
                                        <a className="btn2" href={global.webUrl+"apk/vyavsay.apk"} target='_blank'>Download For Mobile <i className="fas fa-mobile-alt"></i></a>
                                    </div>
                                    <div className="col-lg-5 img100">
                                        <img src={global.webUrl+"websiteFilesFolder/img/about-banner.png"} alt="" title="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="about1">
                            <div className="container">
                                <p>
                                <b>vyavsay</b> is a <b>FREE Business Accounting Software</b> made for Indian Small Businessmen to deal with invoicing, inventory, accounting needs, and much more! The goal is to make a businessman’s daily routine less tiring and let them focus more on growing their business, less on paperwork.
                                </p>
                            </div>
                        </section>

                        <section className="sec4 sec4B">
                            <div className="container">
                                <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="txt-title-3">Adopt Right Technology for your Business</h2>
                                            <span className="line"></span>
                                        </div>
                                    </div>
                                    <div className="row space1">
                                        <div className="col-lg-4 vys1">
                                            <i className="fas fa-cloud-download-alt"></i>
                                            <p>
                                            Downloads
                                            </p>
                                            <h6>5M+</h6>
                                        </div>
                                        <div className="col-lg-4 vys1">
                                            <img src={global.webUrl+"websiteFilesFolder/img/madeinindia.png"} alt="" title="" />
                                        </div>
                                        <div className="col-lg-4 vys1">
                                            <i className="far fa-star"></i>
                                            <p>
                                            Play Store Ratings
                                            </p>
                                            <h6>4.7 out of 5 Stars</h6>
                                        </div>
                                        
                                    </div>
                            </div>
                        </section>

                        <section className="vyavsay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="txt-title-3">Small/Medium Business (SME)</h2>
                                        <span className="line"></span>
                                        <p className="txt-style1">
                                        Even today, <b>70% SMEs</b> in India still create bills on paper. Being that, most of their productive time is consumed in making manual entries and calculations. When small things go wrong, the entire business goes down, simply because there is no place to fall back. Not having the resources can really wear out the business owner driving them away from doing what matters most. <b>Small Business Accounting needs a Digital Upgrade.</b> An upgrade that is simple yet efficient. We are trying to bring in a simpler solution that works for them and hence <b>VYAPAR!</b> 
                                        </p>
                                        
                                    </div>
                                </div>
                                <div className="row space1">
                                    <div className="col-lg-6" align="center">
                                        <img src={global.webUrl+"websiteFilesFolder/img/100Free.png"} alt="" title="" />
                                    </div>
                                    <div className="col-lg-6 img100" align="center">
                                        <img src={global.webUrl+"websiteFilesFolder/img/aw.png"} alt="" title="" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <p className="txt-style2">
                                        "We are talking about improving the life of a segment that is the largest in our nation, i.e ‘Small Business Sector’ the heartbeat of our economy. One of the major aspects holding down the small and medium enterprise (SME) sector is that they hardly have any access to proper technology. Easing this situation will go a long way in nurturing and sustaining SMEs. To let India emerge as one of the brightest economic spots in the coming years, businesses should focus on ways to make cash rather than stucking up in counting cash. vyavsay basically helps them do business accounting easier with the modern digital way!"
                                        </p>
                                        <h6 className="txt-style-9">- Sumit Agarwal, CEO, vyavsay.</h6>
                                    </div>
                                </div>
                                <div className="row space1">
                                    <div className="col-lg-6" align="center">
                                        <img src={global.webUrl+"websiteFilesFolder/img/language.png"} alt="" title="" />
                                    </div>
                                    <div className="col-lg-6 img100" align="center">
                                        <img src={global.webUrl+"websiteFilesFolder/img/device.png"} alt="" title="" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <p className="txt-style2">
                                        A Business Accounting App like vyavsay (available both as Android/Desktop App) most importantly sets up the business financial data at all times 100% accurately . It works offline, helping one use it without being connected to unreliable internet. vyavsay App paints the picture of what they own (assets), how much they owe (liabilities) and what are their business values (equity). It serves as a basis for proper planning of the business accounting activities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="sec4 sec4B">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="txt-title-3">With vyavsay, a Businessman can effortlessly</h2>
                                        <span className="line"></span>
                                    </div>
                                </div>
                                <div className="row space1">
                                    <div className="col-lg-6">
                                    <ul className="list1">
                                    <li><i className="fas fa-check-circle"></i> Become GST compatible.</li>
                                    <li><i className="fas fa-check-circle"></i> Manage all parties in one single place and market business much more easily.</li>
                                    <li><i className="fas fa-check-circle"></i> View how business is performing instantly.</li>
                                    <li><i className="fas fa-check-circle"></i> Create, print/share customized invoices.</li>
                                    
                                    </ul>
                                    </div>
                                    <div className="col-lg-6">
                                    <ul className="list1">
                                                <li><i className="fas fa-check-circle"></i> Track and control individual units of an item by their expiry dates, batch no. to pick right ones for upcoming sales.</li>
                                                <li><i className="fas fa-check-circle"></i> Set automatic payment reminders.</li>
                                                <li><i className="fas fa-check-circle"></i> Do business accounting offline without internet.</li>
                                                <li><i className="fas fa-check-circle"></i> Set up “Auto Backup” and secure your business accounting data against accidental data loss.</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="faq_contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2 className="txt-style-5">History of vyavsay</h2>
                                        <span className="line line1"></span>
                                        <div id="accordion" className="accordion">
                                            <div className="card mb-0">
                                                <div className="card-header" data-toggle="collapse" href="#collapseOne">
                                                    <a className="card-title">2016</a>
                                                </div>
                                                <div id="collapseOne" className="card-body collapse show" data-parent="#accordion" >
                                                    <p><b>March</b><br/>
                                                    First version of vyavsay app (Mobile).<br/>
                                                    <b>April</b><br/>
                                                    Second version of vyavsay app (Mobile).
                                                    </p>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                                    <a className="card-title">2017</a>
                                                </div>
                                                <div id="collapseTwo" className="card-body collapse" data-parent="#accordion">
                                                    <p><b>June</b><br/>
                                                    vyavsay Launched Desktop App.
                                                    </p>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                                    <a className="card-title">2018</a>
                                                </div>
                                                <div id="collapseThree" className="collapse" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p><b>May</b><br/>
                                                        Funded by renowned Investors of India. <br/>
                                                        <b>July</b><br/>
                                                        vyavsay supports "Auto Sync" feature (BETA version).<br/>
                                                        <b></b><br/>
                                                        vyavsay launched “Hindi” version of Mobile App.<br/>
                                                        <b>November</b><br/>
                                                        Reached 700K+ Downloads</p>
                                                    </div>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree1">
                                                    <a className="card-title">2019</a>
                                                </div>
                                                <div id="collapseThree1" className="collapse" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p><b>November</b><br/>
                                                        Reached 700K+ Downloads
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree2">
                                                    <a className="card-title">2020</a>
                                                </div>
                                                <div id="collapseThree2" className="collapse" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p>
                                                            <b>November</b><br/>
                                                            Reached 800K+ Downloads
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree3">
                                                    <a className="card-title">2020</a>
                                                </div>
                                                <div id="collapseThree3" className="collapse" data-parent="#accordion" >
                                                    <div className="card-body">
                                                        <p>
                                                            <b>November</b><br/>
                                                            Reached 800K+ Downloads
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="get_in_touch">
                                            <h2>Get In Touch</h2>
                                            <form id="get_in_touch">
                                                <div>
                                                    <input className={errors.name.length > 0 && 'errorClass'} onChange={this.handleChange} type="text" placeholder="Name" value={this.state.name} name="name" required style={{color: 'white'}}/>
                                                </div>
                                                <div>
                                                    <input className={errors.email.length > 0 && 'errorClass'} onChange={this.handleChange} type="email" placeholder="Email" value={this.state.email} name="email" required style={{color: 'white'}}/>
                                                </div>
                                                <div>
                                                    <input className={errors.contact.length > 0 && 'errorClass'} onChange={this.handleChange} type="text" placeholder="Phone No." value={this.state.contact} name="contact" required style={{color: 'white'}}/>
                                                </div>
                                                <div>
                                                    <textarea className={errors.message.length > 0 && 'errorClass'} onChange={this.handleChange} placeholder="Message" value={this.state.message} name="message" required style={{color: 'white'}}></textarea>
                                                </div>
                                                <div>
                                                    <button type="button" disabled={isDisabled} onClick={this.clickToSubmitEnqury}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row space1">
                                    <div className="col-lg-12">
                                        <p className="txt-style1">
                                            We’re thrilled that vyavsay has been recognized by Indian Businessmen who have taken it up and made it a success as a result of which we’re running towards 1 Million installs on play store.
                                        </p>
                                        <p className="txt-style1">
                                            We hope "vyavsay App" continues to ease the life of our Indian businessmen.
                                        </p>
                                    </div>
                                </div>
                                <div className="row space1 vys2">
                                    <div className="col-lg-6">
                                    <a className="btn2" href={global.webUrl+"apk/vyavsay.apk"} target='_blank'>Download For Mobile <i className="fas fa-mobile-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}