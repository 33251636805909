import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import QuickViewTransactionDetail from '../viewTransaction';
import moment from 'moment';
import ButtonPdf from '../htmlPdfCreation';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from 'react-router-dom';

let _UserSession = localStorage.getItem('vs_UserSession');
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _businessSetting = {};

if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
    _businessSetting = _UserSession.businessSetting;    
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _companyId = _companySession.company_id;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'invoice_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Date',
        },
    },
    {
        label: 'Challan No.',
        field: 'invoice_no',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Challan No.',
        },
    },
    {
        label: 'Party Name',
        field: 'party_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Party Name',
        },
    },
    /*{
        label: 'Due Date',
        field: 'due_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Due Date',
        },
    },*/
    {
        label: 'Amount',
        field: 'total_amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Amount',
        },
    },
    // {
    //     label: 'Balance Due',
    //     field: 'balance',
    //     width: 150,
    //     attributes: {
    //     'aria-controls': 'DataTable',
    //     'aria-label': 'Balance Due',
    //     },
    // },
    /*{
        label: 'Status',
        field: 'status',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    },*/
    {
        label: '',
        field: 'extraAction',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';
let _isGoodReturn = false;
export default class userSaleListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add Sale',
            isLoaded : true,
            transactionArray :[],
            transactionArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            name :  null,

            modalIsOpen_SP : false,
            transactionIdActiveView : 0, 
            itemArrayTransaction : [],

            modalIsOpen_GR : false,

            paidAmount:0,
            unpaidAmount:0,
            total:0,
            changeReturnValue :0,

            pageCount : 10,
            page : 1,
            totalCount  : 0,
            pageShow  : 0,
            setPage : 1,
            searchInvNo : '',
            
            errors: {
                name : '',
            }
        }
    }

    async componentDidMount ()
    {   
        this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount); 
    }
       
    getInvoiceSearch = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let _valueSend = value;
        this.setState({searchInvNo:_valueSend})
        if(_valueSend.length >= 3 || _valueSend == ''){
            this.getSaleListData(this.state.page,_valueSend,this.state.pageCount);
        }
    }

    getSaleListData(_page,searchInvSend,pageCount){
        let _transArray = [];
        
        let _paidAmt = 0;
        let _unpaidAmt = 0;
        let _totalAmt = 0;

        const requestData = JSON.stringify({
            business_id:this.state.businessId,
            company_id:this.state.company_id,
            type:"DELIVERY CHALLAN",
            pageCount:pageCount,
            search_inv_no:searchInvSend,
            page:_page
        });

        PostData(global.userSaleTransactionList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt});            
            }
            else
            {
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    _transArray.map((itm, i) =>{
                        if(itm.payment_paid == 'UNPAID'){
                            _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.total_amount);
                        }
                        else{
                            _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.total_amount);
                        }
                    });
                }
                _totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,totalCount:responseJson.totalCount,pageShow:responseJson.pageShow});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    deleteChallan(value,type){
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        sale_id: value,
                        type_value: type
                    });
                    PostData(global.deleteSaleTransaction, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    convertToSaleChallan(value,type){
        confirmAlert({
            title: 'Confirm to convert into sale',
            message: 'Are you sure you want to convert to sale?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        sale_id: value,
                        type_value: type
                    });
                    PostData(global.convertToSaleTransaction, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    loadTranscListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.transactionArrayFilter;            
            if(_transDetails.length > 0){
                let _i = 0;
                _transDetails.map((value,index) => {
                    _i++;
                    let _type = "&tType="+value.type;
                    let _dueDate = moment(value.due_date).format('MM/DD/YYYY');
                    let _currDate =  moment().format('MM/DD/YYYY');

                    let _status = <span className="text-primary">Open</span>
                    if(_currDate > _dueDate){                        
                        _status = <span className="text-danger">Overdue</span>
                    }else{                        
                        _status = <span className="text-primary">Open</span>
                    }
                    

                    let _action = <a href={global.webUrl+'business/add-sale-duplicate/?auth='+value.id}><span className="btn btn-sm btn-secondary" >Convert To Sale</span></a>;
                    if(value.item_array !=''){                        
                        if(value.DeliveryChallan > 0 && value.DeliveryChallanGood >0){
                            _action = <a onClick={this.openModalHandler.bind(this,'modalGoodReturnTransaction',value.id)}><span className="btn btn-sm btn-secondary" >Convert To Sale</span></a>;
                        }
                    }
                    
                    let _extraAction = (<div className="btn-group" role="group">
                        <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                            <a className="dropdown-item" href={global.webUrl+'business/add-sale-delivery-challan/?auth='+value.id+_type}>
                                View / Edit Details
                            </a>
                            {
                                value.open_status!='1' &&
                                <a className="dropdown-item" onClick={this.deleteChallan.bind(this,value.id, 'Delivery Challan')}>
                                    Delete
                                </a>
                            }

                            <a className="dropdown-item" href={global.webUrl+'business/return-challan-pdf/?auth='+value.id} target="_blank">
                                Download PDF
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/return-challan-pdf/?auth='+value.id} target="_blank">
                                Preview
                            </a>
                            <a className="dropdown-item" href={global.webUrl+'business/return-challan-pdf/?auth='+value.id} target="_blank">
                                Print
                            </a>
                            {/* <ButtonPdf transactionId={value.id}/> */}
                        </div>
                    </div>);

                    if(value.open_status == '1'){
                        _status = <span className="text-successDark">Closed</span>;
                        _action = <span className="text-secondary">Sale Invoice No. {value.against_invoice_no}</span>;
                    }
                    let _pageAK = this.state.setPage;
                    let _showInv = value.customPrefix+' #'+value.invoice_no_web;
                    
                    rows.push({
                        srNo: ((_pageAK - 1) * this.state.pageCount) + _i,//value.srNo,//_i,//value.srNo,
                        invoice_date: value.invoice_date,
                        invoice_no:_showInv,
                        party_name:value.party_name,
                        //due_date:value.due_date,
                        payment_type: value.payment_type,
                        total_amount:numberFormat(value.total_amount),
                        balance: numberFormat(value.balance),
                        //status: _status,
                        action: _action,
                        extraAction:_extraAction
                    });
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }

    /*For Modal open close*/
    openModalHandler(e,transId)
    {
        if(e == 'modalAdjustViewTransaction'){
            this.setState({modalIsOpen_SP: true,transactionIdActiveView:transId});
        }
        else if(e == 'modalGoodReturnTransaction'){
            this.setState({modalIsOpen_GR: true,transactionIdActiveView:transId});
            //this.loadTransactionItemListing();
        }
    }
    closeModalHandler = (e) => {
        if(e == 'modalAdjustViewTransaction'){
            this.setState({modalIsOpen_SP: false,transactionIdActiveView:0});
        }
        else if(e == 'modalGoodReturnTransaction'){
            this.setState({modalIsOpen_GR: false,transactionIdActiveView:0});
        }
    }    
    /*End Modal open close*/

    /* Good Return Modal List */
    loadTransactionItemListing(){
        let dataList = [];
        if(this.state.isLoaded == true)
        {
            if(this.state.transactionIdActiveView >0)
            {
                this.state.transactionArrayFilter.map((allData,index) => {
                    if(allData.id == this.state.transactionIdActiveView)
                    {
                        let _itemArray = allData.item_array;
                        _itemArray.map((value,index) => {
                            dataList.push(
                                <tr key={index} className="tableRow">
                                    <td title={value.item_name}>{value.item_name}</td>
                                    <td>{value.quantity}</td>
                                    <td>{value.unit_name}</td>
                                    <td><input type="number" data-itemid={value.item_id} data-oldqty={value.quantity} onChange={this.getItemQty} defaultValue="0" min="1" 
                                    onWheel={() => document.activeElement.blur()}/></td>
                                </tr>
                            );
                        });
                    }
                });
            }
        }
        return dataList;
    }

    getItemQty = (e) => {
        let _oldItemArray = this.state.itemArrayTransaction;
        let _obj = {};
        const _value = e.target.value;
        let _minQty = e.target.attributes['data-oldqty'].value;
        let _itemId = e.target.attributes['data-itemid'].value;
        
        if(parseFloat(_minQty) < parseFloat(_value))
        {
            _isGoodReturn = false;
            this.setState({changeReturnValue: 1});
            sendNotification("Error Message 😓","Please enter correct returned quantity. Return quantity should be less than sold quantity.","danger");
        }
        else
        {
            _isGoodReturn = true;
            this.setState({changeReturnValue: 0});
            _obj.transactionId = this.state.transactionIdActiveView;
            _obj.itemId = _itemId;
            _obj.oldQty = _minQty;
            _obj.newQty = _value;

            let _isGot = false;
            if(_oldItemArray.length > 0)
            {
                _oldItemArray.filter(item => {
                    if(item.itemId == _itemId){
                        _isGot = true;
                        item.newQty = _value;
                    }
                });
            }

            if(_isGot == false){
                _oldItemArray.push(_obj);
            }
            this.setState({itemArrayTransaction:_oldItemArray});
        }
    }

    redirectToChallanEdit(e,type){
       
        if(this.state.changeReturnValue == 1){
            if(_isGoodReturn == false){
                sendNotification("Error Message 😓","Please enter correct returned quantity. Return quantity should be less than sold quantity.","danger");
                return;
            }
        }
        //console.log(this.state.itemArrayTransaction);
        //console.log(this.state.transactionIdActiveView);
        //console.log('Click Doen');
        let _decodetxt = new Buffer(JSON.stringify(this.state.itemArrayTransaction)).toString('base64');

        //console.log(_decodetxt);
        //console.log(Buffer.from(_decodetxt, "base64").toString());
        window.location.href = global.webUrl+'business/add-sale-duplicate/?auth='+this.state.transactionIdActiveView+'&goodReturn='+_decodetxt;
    }
    /* Good Return Modal List End*/
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Delivery Challan List<br/>
                            </h1>
                            
                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"business/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"business/add-sale-delivery-challan/"}>Create New Delivery Challan</a>                        
                        <span className="breadcrumb-item active">Manage Delivery Challan List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h2 className="block-title ml-10">Transactions</h2>
                                        
                                        <input type="text" className="form-control col-6" style={{display:'inline-block'}} placeholder='search invoice number (type at least three characters)'onChange={this.getInvoiceSearch}/>
                                        <Link to={"/business/add-sale-delivery-challan/"} style={{}} className="pull-right btn btn-sm btn-primary">
                                            <i className="fa fa-plus-circle"></i> Add Delivery Challan
                                        </Link>
                                    </div>
                                    <div className="block-content">
                                        <MDBDataTableV5 
                                            hover
                                            span
                                            displayEntries={false}
                                            entries={100}
                                            pagingTop={false}
                                            searchTop={false}
                                            searchBottom={false}
                                            info={false}
                                            barReverse
                                            data={this.loadTranscListing()}
                                        />
                                    </div>
                  
                                    <div style={{ float: "right", marginTop: "-15px" }}>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Rows per page:</div>
                                            </div>
                                            <div className="d-flex align-items-center" >
                                            <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        const { name, value } = event.target;
                                                        //this.setState({ pageCount: value, setPage: 1 });
                                                        this.setState(prevState => ({
                                                                pageCount : value,
                                                                setPage : 1,
                                                            }),
                                                            () => {
                                                                this.getSaleListData(1,this.state.searchInvNo,
                                                                    value
                                                                );
                                                            });
                                                    }}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <div style={{ float: "left", marginTop: "-45px" }}>
                                        <PaginationControl
                                            between={this.state.pageCount}
                                            total={this.state.totalCount}
                                            limit={this.state.pageCount}
                                            changePage={(page) => {
                                            this.setState({ setPage: page });
                                            this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount
                                            );
                                            }}
                                            ellipsis={1}
                                            activeClass={this.state.setPage}
                                            page={this.state.setPage}
                                        />
                                        </div>
                                    </div>
                                    {/* <div className="block-content">
                                        <div style={{float : 'left', marginTop : '-45px'}}>
                                            <PaginationControl
                                                between={this.state.pageCount}
                                                total={this.state.totalCount}
                                                limit={this.state.pageCount}
                                                changePage={(page) => {
                                                this.setState({setPage:page}); 
                                                this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount); 
                                                }}
                                                ellipsis={1}
                                                activeClass={this.state.setPage}
                                                page={this.state.setPage}
                                            />
                                        </div>
                                    </div> */}

                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>

                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

               {/* Modal Edit Item Details Begins */}
               <Modal isOpen={this.state.modalIsOpen_SP} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustViewTransaction">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">View Transaction Detail</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustViewTransaction')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content">
                                    <QuickViewTransactionDetail transactionIdActiveView={this.state.transactionIdActiveView}/>
                                </div>
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustViewTransaction')}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Modal End Edit Item Details*/}


                {/* Modal Good Return Begins */}
               <Modal isOpen={this.state.modalIsOpen_GR} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalGoodReturnTransaction">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Return Goods</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalGoodReturnTransaction')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content">
                                    <h2>List of Items on Challan</h2>
                                    <table className="table table-striped table-vcenter">
                                        <thead id="sideTable">
                                            <tr>
                                                <th>Items</th>
                                                <th>Shipped</th>
                                                <th>Unit</th>
                                                <th>Returned Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody id="sideTable">
                                            {this.loadTransactionItemListing()}
                                        </tbody>
                                    </table>
                                </div>
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.redirectToChallanEdit.bind(this,'modalGoodReturnTransaction')}>Done</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Modal End Good Return */}
            </React.Fragment>
        )    
    }
}