import React,{Component} from 'react';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class faqPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    render(){
        
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Frequently Asked Questions (FAQ)</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="sec4 sec4C">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All Categories</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pricing</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Product (App Related)</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact1" role="tab" aria-controls="contact" aria-selected="false">Distributorship</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div id="accordion" className="accordion space2A">
                                                    <div className="card mb-0">
                                                        <div className="card-header" data-toggle="collapse" href="#collapseOne">
                                                            <a className="card-title">
                                                                What are the charges for vyavsay App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne" className="card-body collapse show" data-parent="#accordion">
                                                            <p>
                                                                vyavsay Mobile App (Basic Version) is completely free of cost!
                                                                <br />
                                                                vyavsay Mobile App (Premium Version) has a price attached.
                                                                <br />
                                                                View all plans here
                                                                <br />
                                                                vyavsay Desktop App is not free, but has a 30-day free trial.<br />
                                                                There are different plans for different durations, i.e. 1 Year and 3 Years.<br />
                                                                View all plans here
                                                            </p>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                                            <a className="card-title">
                                                                Where can we download vyavsay Desktop App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseTwo" className="card-body collapse" data-parent="#accordion">
                                                            <p>"You can download vyavsay Desktop App here >> <a href={global.webUrl} target="_blank">{global.webUrl}</a> Note: Open this link on the Desktop to install!"</p>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                                            <a className="card-title">
                                                                How to become a distributor of vyavsay?
                                                            </a>
                                                        </div>
                                                        <div id="collapseThree" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>Visit our ""Channel Partner"" Page to find out about our distributor program >> <a href={global.webUrl+"partner-with-us/"} target="_blank">Click here</a></p>
                                                            </div>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree1">
                                                            <a className="card-title">
                                                                Where can I find the pricing detials?
                                                            </a>
                                                        </div>
                                                        <div id="collapseThree1" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "You can either visit our website <a href={global.webUrl+"pricing/"} target="_blank">{global.webUrl+"pricing/"}</a> <br />
                                                                    or click on ""Buy Now"" in the left menu inside the App."
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree2">
                                                            <a className="card-title">
                                                                How to update vyavsay App to its latest version?
                                                            </a>
                                                        </div>
                                                        <div id="collapseThree2" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "Whenever a new update is released, vyavsay App automatically sends you a notification clicking on which your App gets updated
                                                                    <br />
                                                                    (or) You can also opt for "check for updates" option in the App to do the same.
                                                                    <br />
                                                                    (or) You can download the latest version of vyavsay App from <a href={global.webUrl} target="_blank">{global.webUrl}</a>.<br />
                                                                    The new downloaded file overrides the old version of App.<br />
                                                                    (or) Go to playstore, search for vyavsay app and click on update & open the app again. Now you will be on the latest version of the app."
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                <div id="accordion" className="accordion space2A">
                                                    <div className="card mb-0">
                                                        <div className="card-header" data-toggle="collapse" href="#collapseOne1">
                                                            <a className="card-title">
                                                                What are the charges for vyavsay App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne1" className="card-body collapse show" data-parent="#accordion">
                                                            <p>
                                                                vyavsay Mobile App (Basic Version) is completely free of cost!
                                                                <br />
                                                                vyavsay Mobile App (Premium Version) has a price attached.
                                                                <br />
                                                                View all plans here
                                                                <br />
                                                                vyavsay Desktop App is not free, but has a 30-day free trial.<br />
                                                                There are different plans for different durations, i.e. 1 Year and 3 Years.<br />
                                                                View all plans here
                                                            </p>
                                                        </div>

                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne4">
                                                            <a className="card-title">
                                                                Where can I find the pricing detials?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne4" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "You can either visit our website <a href={global.webUrl+"pricing/"} target="_blank">{global.webUrl+"pricing/"}</a> <br />
                                                                    or click on ""Buy Now"" in the left menu inside the App."
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                <div id="accordion" className="accordion space2A">
                                                    <div className="card mb-0">
                                                        <div className="card-header" data-toggle="collapse" href="#collapseOne6">
                                                            <a className="card-title">
                                                                What are the charges for vyavsay App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne6" className="card-body collapse show" data-parent="#accordion">
                                                            <p>
                                                                vyavsay Mobile App (Basic Version) is completely free of cost!
                                                                <br />
                                                                vyavsay Mobile App (Premium Version) has a price attached.
                                                                <br />
                                                                View all plans here
                                                                <br />
                                                                vyavsay Desktop App is not free, but has a 30-day free trial.<br />
                                                                There are different plans for different durations, i.e. 1 Year and 3 Years.<br />
                                                                View all plans here
                                                            </p>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne7">
                                                            <a className="card-title">
                                                                Where can we download vyavsay Desktop App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne7" className="card-body collapse" data-parent="#accordion">
                                                            <p>"You can download vyavsay Desktop App here >> <a href={global.webUrl} target="_blank">{global.webUrl}</a> Note: Open this link on the Desktop to install!"</p>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne8">
                                                            <a className="card-title">
                                                                How to become a distributor of vyavsay?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne8" className="collapse" data-parent="#accordion">
                                                            <div className="card-body"><p>Visit our "Channel Partner" Page to find out about our distributor program >> <a href={global.webUrl+"partner-with-us/"} target="_blank">Click here</a></p></div>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne9">
                                                            <a className="card-title">
                                                                Where can I find the pricing detials?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne9" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "You can either visit our website <a href={global.webUrl+"pricing/"} target="_blank">{global.webUrl+"pricing/"}</a> <br />
                                                                    or click on ""Buy Now"" in the left menu inside the App."
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne10">
                                                            <a className="card-title">
                                                                How to update vyavsay App to its latest version?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne10" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "Whenever a new update is released, vyavsay App automatically sends you a notification clicking on which your App gets updated
                                                                    <br />
                                                                    (or) You can also opt for ""check for updates"" option in the App to do the same.
                                                                    <br />
                                                                    (or) You can download the latest version of vyavsay App from <a href={global.webUrl} target="_blank">{global.webUrl}</a>.<br />
                                                                    The new downloaded file overrides the old version of App.<br />

                                                                    (or) Go to playstore, search for vyavsay app and click on update & open the app again. Now you will be on the latest version of the app."
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="contact1" role="tabpanel" aria-labelledby="contact-tab">
                                                <div id="accordion" className="accordion space2A">
                                                    <div className="card mb-0">
                                                        <div className="card-header" data-toggle="collapse" href="#collapseOne11">
                                                            <a className="card-title">
                                                                What are the charges for vyavsay App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne11" className="card-body collapse show" data-parent="#accordion">
                                                            <p>
                                                                vyavsay Mobile App (Basic Version) is completely free of cost!
                                                                <br />
                                                                vyavsay Mobile App (Premium Version) has a price attached.
                                                                <br />
                                                                View all plans here
                                                                <br />
                                                                vyavsay Desktop App is not free, but has a 30-day free trial.<br />
                                                                There are different plans for different durations, i.e. 1 Year and 3 Years.<br />
                                                                View all plans here
                                                            </p>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne12">
                                                            <a className="card-title">
                                                                Where can we download vyavsay Desktop App?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne12" className="card-body collapse" data-parent="#accordion">
                                                            <p>"You can download vyavsay Desktop App here >> <a href={global.webUrl} target="_blank">{global.webUrl}</a> Note: Open this link on the Desktop to install!"</p>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne13">
                                                            <a className="card-title">
                                                                How to become a distributor of vyavsay?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne13" className="collapse" data-parent="#accordion">
                                                            <div className="card-body"><p>Visit our "Channel Partner" Page to find out about our distributor program >> <a href={global.webUrl+"partner-with-us/"} target="_blank">Click here</a></p></div>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne14">
                                                            <a className="card-title">
                                                                Where can I find the pricing detials?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne14" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "You can either visit our website <a href={global.webUrl+"pricing/"} target="_blank">{global.webUrl+"pricing"}</a> <br />
                                                                    or click on ""Buy Now"" in the left menu inside the App."
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne15">
                                                            <a className="card-title">
                                                                How to update vyavsay App to its latest version?
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne15" className="collapse" data-parent="#accordion">
                                                            <div className="card-body">
                                                                <p>
                                                                    "Whenever a new update is released, vyavsay App automatically sends you a notification clicking on which your App gets updated
                                                                    <br />
                                                                    (or) You can also opt for ""check for updates"" option in the App to do the same.
                                                                    <br />
                                                                    (or) You can download the latest version of vyavsay App from <a href={global.webUrl} target="_blank">{global.webUrl}</a>.<br />
                                                                    The new downloaded file overrides the old version of App.<br />

                                                                    (or) Go to playstore, search for vyavsay app and click on update & open the app again. Now you will be on the latest version of the app."
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}