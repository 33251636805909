import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";

import QuickAddNewLoanBankAccount from "./quickLoanAccountAdd";
import QuickAddNewIncreaseLoanBankAccount from "./quickIncreaseLoan";
import QuickAddNewChargeLoanBankAccount from "./quickChargesLoan";
import QuickAddNewEmiMakePayment from "./quickEmiMakePayment";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal"; 
import * as XLSX from "xlsx";
import { PaginationControl } from "react-bootstrap-pagination-control";

import ButtonPdf from "../htmlPdfCreation";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 50,
  },
  {
    label: "Date",
    field: "date",
    width: 100,
  },
  /*{
        label: 'Txn Type',
        field: 'txn_type',
        width: 100,
    },*/
  {
    label: "Principal",
    field: "principal",
    width: 150,
  },
  {
    label: "Interest",
    field: "Interest",
    width: 100,
  },
  {
    label: "Total Amount",
    field: "total_amount",
    width: 100,
  },
  {
    label: "Action",
    field: "actionextra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class bankAccountListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",

      isLoaded: true,
      modalIsOpen_LoanAcc: false,
      modalIsOpen_LoanIncrease: false,
      modalIsOpen_LoanCharge: false,
      modalIsOpen_MEP: false,

      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,
      loanAccountArrayTransaction: [],
      bankAccArrayFilter: [],
      loanAccountArray: [],
      loanArrayTransaction: [],

      activeAccountLenderBank: "",
      activeAccountNumber: "",
      activeAccountOpeningBalance: "",
      importExcelFile: "",
      activeEditloanAccountId: 0,
      addEdit: "Add",
      activeLoanAccountId: 0,
      activeLoanTrasactionId: 0,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),

      activeMinDate: moment().format("MM/DD/YYYY"),
      activeIsTransaction: false,

      pageCount: 10,
      page: 1,
      totalCount: 0,
      pageShow: 0,
      setPage: 1,

      errors: {},
    };
  }

  async componentDidMount() {
    this.getBankAccListData();
    //this.getBankTrasactionListData();
  }

  getTransactionListData(id, _page) {
    let _transactionArray = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      isDateFilter: true,
      pageCount: this.state.pageCount,
      page: _page,
      loan_id: id, //this.state.activeBankId
    });
    // console.log(requestData);
    // return false;
    PostData(global.userIncreaseLoanAccountList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            loanArrayTransaction: [],
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _transactionArray = responseJson.response;
          }
          this.setState({
            loanArrayTransaction: _transactionArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getLoanTransactionListData(id) {
    let _transactionArray = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      loan_id: id,
    });

    PostData(global.userIncreaseLoanAccountList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            loanArrayTransaction: [],
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _transactionArray = responseJson.response;
          }
          this.setState({
            loanArrayTransaction: _transactionArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getBankAccListData() {
    let _loanAccountArray = [];
    let _url_GetData =
      global.userLoanAccountList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _activeLoanAccount,
            _activeLoanAccountId,
            _activeLoanAccountNumber,
            _activeLoanAccountOpeningBal,
            _activeLoanAccountLenderBank,
            _activeLoanMinDate,
            _activeLoanIsTransaction = "";
          if (responseJson.response) {
            _loanAccountArray = responseJson.response;
            if (responseJson.response) {
              _loanAccountArray = responseJson.response;
              _loanAccountArray.map((party, i) => {
                if (i == 0) {
                  _loanAccountArray[i].isActiveClick = true;
                  _activeLoanAccount = party.account_name;
                  _activeLoanAccountNumber = party.account_number;
                  _activeLoanAccountOpeningBal = party.current_balance;
                  _activeLoanAccountLenderBank = party.lender_bank;
                  _activeLoanAccountId = party.loan_id;
                  _activeLoanMinDate = party.minimumDate;
                  _activeLoanIsTransaction = party.isTransaction;
                } else {
                  _loanAccountArray[i].isActiveClick = false;
                }
              });
            }
          }
          this.setState({
            bankAccArrayFilter: _loanAccountArray,
            loanAccountArray: _loanAccountArray,
            isLoaded: true,
            account_number: _activeLoanAccountNumber,
            account_name: _activeLoanAccount,
            activeAccount: _activeLoanAccount,
            activeLoanAccountId: _activeLoanAccountId,
            activeAccountNumber: _activeLoanAccountNumber,
            activeAccountLenderBank: _activeLoanAccountLenderBank,
            activeAccountOpeningBalance: _activeLoanAccountOpeningBal,
            activeMinDate: _activeLoanMinDate,
            activeIsTransaction: _activeLoanIsTransaction,
          });

          if (_activeLoanAccountId > 0) {
            this.getLoanTransactionListData(_activeLoanAccountId);
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadBankAccListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _bankDetails = this.state.bankAccArrayFilter;
      if (_bankDetails.length > 0) {
        _bankDetails.map((value, index) => {
          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeLoanAccountId == value.loan_id) {
            _activeClass = "activeRow";
          }
          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.loan_id,
                value.account_name,
                value.account_number,
                value.lender_bank,
                value.current_balance
              )}
            >
              <td>{value.account_name}</td>
              <td>{numberFormat(value.current_balance)}</td>
              <th className="text-center" scope="row">
                <div className="btn-group show" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right min-width-200"
                    aria-labelledby="toolbarDrop"
                    style={{ margin: "0px" }}
                  >
                    <a
                      className="dropdown-item"
                      onClick={this.openModalEditLoanAccountHandler.bind(
                        this,
                        "modalAdjustNewLoanAcc",
                        value.loan_id
                      )}
                      style={{ margin: "0px" }}
                    >
                      <i className="si si-pencil mr-5 text-info"></i>Edit
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={this.clickToDeleteAccount.bind(
                        this,
                        value.loan_id
                      )}
                      style={{ margin: "0px" }}
                    >
                      <i className="fa fa-remove mr-5 text-info"></i>Delete
                    </a>
                  </div>
                </div>
              </th>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  clickToActiveRow(id, txt, accountNumber, lenderBank, openingBalance) {
    this.setState({
      activeAccount: txt,
      activeAccountNumber: accountNumber,
      activeLoanAccountId: id,
      activeAccountOpeningBalance: openingBalance,
      activeAccountLenderBank: lenderBank,
    });
    if (id > 0) {
      //this.getTransactionListData(id);
      this.getLoanTransactionListData(id, this.state.page);
    }
  }
  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getTransactionListData(
      this.state.activeLoanAccountId,
      this.state.page,
      this.state.startDate,
      this.state.endDate
    );
  };
  clickToDeleteAccount(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              loan_id: value,
            });
            PostData(global.userLoanAccountDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getBankAccListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDelete(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const requestData = JSON.stringify({
              loan_transaction_id: value,
            });
            PostData(
              global.userLoanAccountTransactionDelete,
              requestData,
              "POST"
            ).then((result) => {
              let responseJson = result;
              if (responseJson.success == false) {
                sendNotification(
                  "Error Message 😓",
                  responseJson.message,
                  "danger"
                );
                return;
              } else {
                sendNotification(
                  "Success Message 👍",
                  responseJson.message,
                  "success"
                );
                this.getBankAccListData();
                return;
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  loadTrasactionListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.loanArrayTransaction;
      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let TypeData = "";
          let updatebtn = "";

          if (value.type == "INCREASE") {
            TypeData = "Load Adjustment Txn";
            updatebtn = "modalUploadIncrease";
          } else if (value.type == "EMI PAYMENT") {
            TypeData = value.trasaction_type_name;
            updatebtn = "modalMakePaymentEMI";
          } else {
            TypeData = value.trasaction_type_name;
            updatebtn = "modalUploadCharge";
          }

          let _actionExtra = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                <a
                  className="dropdown-item"
                  onClick={this.openModalTrasacEditHandler.bind(
                    this,
                    updatebtn,
                    value.loan_detail_id
                  )}
                >
                  View/Edit Details
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.loan_id}
                  onClick={this.clickToDelete.bind(this, value.loan_detail_id)}
                >
                  Delete
                </a>
              </div>
            </div>
          );

          let _intAmount = 0;
          let _typeName = "";
          if (value.type == "EMI PAYMENT") {
            _intAmount = value.principal_amount;
            _typeName = "EMI Paid";
          } else if (value.type == "INCREASE") {
            _intAmount = value.amount;
            _typeName = "Loan Adjustment Txn";
          } else if (value.type == "CHARGES") {
            _intAmount = 0; //value.amount;
            _typeName = value.trasaction_type_name;
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    onClick={this.openModalTrasacEditHandler.bind(
                      this,
                      updatebtn,
                      value.loan_detail_id
                    )}
                  >
                    View/Edit Details
                  </a>
                  <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      data-id={value.loan_id}
                      onClick={this.clickToDelete.bind(this, value.loan_detail_id)}
                  >
                    Delete
                  </a>
                </div>
              </div>
            );
          } else {
            _intAmount = value.principal_amount;
            _typeName = value.type;
            _actionExtra = "";
          }

          rows.push({
            srNo: _i, //value.srNo,
            type: value.showTxt, //value.type,
            date: value.added_on,
            //txn_type : TypeData,
            Interest: numberFormat(value.interest_amount),
            principal: numberFormat(_intAmount),
            total_amount: numberFormat(value.total_amount),
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /*For Modal open close*/
  openModalEditLoanAccountHandler(popId, loanAccountId) {
    if (popId == "modalAdjustNewLoanAcc") {
      this.setState({
        modalIsOpen_LoanAcc: true,
        activeEditloanAccountId: loanAccountId,
        addEdit: "Edit",
      });
    }
  }

  openModalHandler(popId) {
    if (popId == "modalAdjustNewLoanAcc") {
      this.setState({ modalIsOpen_LoanAcc: true });
    } else if (popId == "modalMakePaymentEMI") {
      this.setState({ modalIsOpen_MEP: true });
    }
  }

  openModalTrasacHandler(popId, loanAccountId) {
    if (popId == "modalUploadIncrease") {
      this.setState({
        modalIsOpen_LoanIncrease: true,
        loanAccountId: loanAccountId,
      });
    }
    if (popId == "modalUploadCharge") {
      this.setState({
        modalIsOpen_LoanCharge: true,
        loanAccountId: loanAccountId,
      });
    }
  }

  openModalTrasacEditHandler(popId, loanAccountId) {
    if (popId == "modalUploadIncrease") {
      this.setState({
        modalIsOpen_LoanIncrease: true,
        activeLoanTrasactionId: loanAccountId,
      });
    } else if (popId == "modalUploadCharge") {
      this.setState({
        modalIsOpen_LoanCharge: true,
        activeLoanTrasactionId: loanAccountId,
      });
    } else if (popId == "modalMakePaymentEMI") {
      this.setState({
        modalIsOpen_MEP: true,
        activeLoanTrasactionId: loanAccountId,
      });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustNewLoanAcc") {
      this.setState({
        modalIsOpen_LoanAcc: false,
        activeEditloanAccountId: 0,
        activeLoanTrasactionId: 0,
        addEdit: "Add",
      });
    } else if (popId == "modalUploadIncrease") {
      this.setState({
        modalIsOpen_LoanIncrease: false,
        activeLoanTrasactionId: 0,
      });
    } else if (popId == "modalMakePaymentEMI") {
      this.setState({
        modalIsOpen_MEP: false,
        activeLoanTrasactionId: 0,
      });
    } else if (popId == "modalUploadCharge") {
      this.setState({
        modalIsOpen_LoanCharge: false,
        activeLoanTrasactionId: 0,
      });
    }
  };
  /*End Modal open close*/

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({
      modalIsOpen_MEP: false,
      modalIsOpen_LoanAcc: false,
      modalIsOpen_LoanIncrease: false,
      modalIsOpen_LoanCharge: false,
      activeLoanTrasactionId: 0,
    });
    this.getBankAccListData();
  };
  /* End Here */

  /* Loan Filter Code */
  onPartyFilterName = (e) => {
    const data = this.state.loanAccountArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ bankAccArrayFilter: filtered });
    this.loadBankAccListing();
  };
  /* End Loan Filter Code */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideCss = "hide";
    if (this.state.bankAccArrayFilter.length > 0) {
      _hideCss = "";
    }

    if (this.state.activeAccountOpeningBalance == 0) {
      //_hideCss = 'hide';
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Loan Account List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Manage Loan Account List
            </span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    <div className="mb-10">
                      <button
                        type="button"
                        data-toggle="layout"
                        data-action="header_search_on"
                        className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                      >
                        <i className="fa fa-search"></i>
                      </button>

                      <div
                        className="btn-group pull-right"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.openModalHandler.bind(
                            this,
                            "modalAdjustNewLoanAcc"
                          )}
                        >
                          <i className="fa fa-plus mr-5"></i>{" "}
                          <b>Add Loan Account</b>
                        </button>
                      </div>
                    </div>
                    <div id="page-header-search" className="overlay-header">
                      <div className="content-header content-header-fullrow">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-toggle="layout"
                              data-action="header_search_off"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            onChange={this.onPartyFilterName}
                            className="form-control"
                            placeholder="Search here.."
                            id="page-header-search-input"
                            name="page-header-search-input"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th>Account Name</th>
                          <th style={{ width: "50px" }}>Amount&nbsp;</th>
                          <th style={{ width: "50px" }}>&nbsp;&nbsp;Action</th>
                        </tr>
                      </thead>
                      <tbody id="sideTable">{this.loadBankAccListing()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    <div className="col-lg-12">
                      <div className={_hideCss}>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <b>{this.state.activeAccount} </b>{" "}
                          <i className="si si-pin"></i>
                          <span
                            className="btn-group pull-right"
                            role="group"
                            aria-label="Button group with nested dropdown"
                          >
                            {(this.state.activeAccountOpeningBalance!=0 ||
                              this.state.activeAccountOpeningBalance !=
                                "0") && (
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalMakePaymentEMI"
                                )}
                              >
                                <i className="fa fa-plus mr-5"></i>{" "}
                                <b>Make Payment</b>
                              </button>
                            )}
                            <div className="btn-group show" role="group">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle"
                                id="toolbarDrop"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              ></button>
                              <div
                                className="dropdown-menu dropdown-menu-right min-width-200"
                                aria-labelledby="toolbarDrop"
                                style={{ margin: "0px" }}
                              >
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0)"
                                  onClick={this.openModalTrasacHandler.bind(
                                    this,
                                    "modalUploadIncrease",
                                    this.state.activeLoanAccountId
                                  )}
                                  style={{ margin: "0px" }}
                                >
                                  <i className="fa fa-file-text mr-5"></i>
                                  Increase Loan
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0)"
                                  onClick={this.openModalTrasacHandler.bind(
                                    this,
                                    "modalUploadCharge"
                                  )}
                                  style={{ margin: "0px" }}
                                >
                                  <i className="fa fa-file-text mr-5"></i>
                                  Charges On Loan
                                </a>
                              </div>
                            </div>
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Leading Bank / Agency :{" "}
                            {this.state.activeAccountLenderBank}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Account Number : {this.state.activeAccountNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Balance Amount :{" "}
                            {numberFormat(
                              this.state.activeAccountOpeningBalance
                            )}
                          </span>
                          <a
                            className="btn-group pull-right"
                            role="group"
                            aria-label="Button group with nested dropdown"
                            href={
                              global.webUrl +
                              "business/manage-loan-statement/?auth=" +
                              this.state.activeLoanAccountId
                            }
                          >
                            View Loan Statement
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="block-content">
                  <span>Filter : </span>
                  <DateRangePicker
                    initialSettings={{
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      locale: {
                        format: "MMMM D, YYYY",
                      },
                      ranges: {
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                          moment().subtract(1, "days").toDate(),
                          moment().subtract(1, "days").toDate(),
                        ],
                        "This Week": [
                          moment().startOf("week").format("MMM DD, YYYY"),
                          moment().endOf("week").format("MMM DD, YYYY"),
                        ],
                        "This Month": [
                          moment().startOf("month").toDate(),
                          moment().endOf("month").toDate(),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          moment().subtract(1, "month").endOf("month").toDate(),
                        ],
                        "This Quarter": [
                          moment().startOf("month").toDate(),
                          moment().add(2, "month").endOf("month").toDate(),
                        ],
                        "This Financial Year": [
                          moment()
                            .month("April")
                            .startOf("month")
                            .format("MMM DD, YYYY"),
                          moment()
                            .add(1, "year")
                            .month("March")
                            .endOf("month")
                            .format("MMM DD, YYYY"),
                        ],
                      },
                    }}
                    onCallback={this.handleCallBackDate}
                  >
                    <input
                      type="text"
                      className="form-control col-4"
                      style={{ display: "inline-block" }}
                    />
                  </DateRangePicker>
                  <MDBDataTableV5
                    hover
                    entriesOptions={[25, 50, 100]}
                    entries={25}
                    pagesAmount={4}
                    span
                    pagingTop
                    searchTop
                    searchBottom={false}
                    barReverse
                    data={this.loadTrasactionListing()}
                  />
                </div>
                <div className="block-content">
                  <div style={{ float: "left", marginTop: "-45px" }}>
                    <PaginationControl
                      between={this.state.pageCount}
                      total={this.state.totalCount}
                      limit={this.state.pageCount}
                      changePage={(page) => {
                        this.setState({ setPage: page });
                        this.getSaleListData(page);
                      }}
                      ellipsis={1}
                      activeClass={this.state.setPage}
                      page={this.state.setPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Add Loan Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_LoanAcc}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewLoanAcc"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    {this.state.addEdit} Loan Account
                  </h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewLoanAcc"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewLoanBankAccount
                    callBack={this.getUpdateResponse}
                    loanAccountId={this.state.activeEditloanAccountId}
                    isRedirectTo={"business/manage-loan-accounts/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewLoanAcc"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Loan Details*/}

        {/* Modal Add  Increase Loan Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_LoanIncrease}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadIncrease"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Loan Increase</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadIncrease"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewIncreaseLoanBankAccount
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeIsTransaction}
                    callBack={this.getUpdateResponse}
                    loanAccountId={this.state.activeLoanAccountId}
                    loanTrasactionId={this.state.activeLoanTrasactionId}
                    isRedirectTo={"business/manage-loan-accounts/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadIncrease"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* Modal Add  Increase Loan Details*/}

        {/* Modal Add  charges Loan Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_LoanCharge}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadCharge"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Loan Charges</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadCharge"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewChargeLoanBankAccount
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeIsTransaction}
                    callBack={this.getUpdateResponse}
                    loanAccountId={this.state.activeLoanAccountId}
                    loanTrasactionId={this.state.activeLoanTrasactionId}
                    isRedirectTo={"business/manage-loan-accounts/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadCharge"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add  charges Loan Details*/}

        {/* Modal Add  charges Loan Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_MEP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalMakePaymentEMI"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Make Payment</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalMakePaymentEMI"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewEmiMakePayment
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeIsTransaction}
                    callBack={this.getUpdateResponse}
                    loanAccountId={this.state.activeLoanAccountId}
                    loanTrasactionId={this.state.activeLoanTrasactionId}
                    isRedirectTo={"business/manage-loan-accounts/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalMakePaymentEMI"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add  charges Loan Details*/}
      </React.Fragment>
    );
  }
}
