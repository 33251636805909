import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class QuickAddNewSalePurchaseSerialNo extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                    }
                }
            } 
        }
        let _transactionId = this.props.transactionId ?  this.props.transactionId : 0;
        let _without_sr_quantity = 0;
        if(_transactionId > 0){
            _without_sr_quantity = this.props.normalFQtyAdded ? this.props.normalFQtyAdded :0;
        }
        this.state = {
            itemId : this.props.itemId ? this.props.itemId : '0',
            normalRemainingQuantity : this.props.normalRemainingQuantity ? this.props.normalRemainingQuantity : '0',
            selectTypeTxt : this.props.selectTypeTxt ? this.props.selectTypeTxt : 'Normal',
            normalQuantityTYpe : this.props.normalQuantityTYpe ? this.props.normalQuantityTYpe : '',
            openingStock : 0,  
            isLoaded : true, 
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            transactionId : this.props.transactionId ?  this.props.transactionId : 0,
            indexPosition : this.props.indexPosition ?  this.props.indexPosition : 0,
            normalUsedQty : this.props.normalUsedQty ? this.props.normalUsedQty : 0,
            freeQtyAk : this.props.freeQtyAk ? this.props.freeQtyAk : 0,
            normalFQtyAdded : this.props.normalFQtyAdded ? this.props.normalFQtyAdded :0,
            
            isError : false,
            without_sr_quantity : _without_sr_quantity,
            withoutTypeQty : 0,
            errors: {
                error  :  '',
                without_sr_quantity : '',
            }
        }
    }
    async componentDidMount ()
    {   
        if(this.state.addedQty > 0){
            let  normalRemainingQuantity =  parseFloat(this.state.normalRemainingQuantity) - parseFloat(this.state.addedQty);
            if(normalRemainingQuantity <= 0){
                normalRemainingQuantity = 0;
                this.setState({normalRemainingQuantity : normalRemainingQuantity, normalQuantityTYpe : 'Purchase'});
            }
            else{
                this.setState({normalRemainingQuantity : normalRemainingQuantity});
            }
        }
    }    
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        let _isError = false;
        switch (name) {
            
            case 'without_sr_quantity':
                if(this.state.normalRemainingQuantity < value && this.state.normalQuantityTYpe == 'Sale'){
                    _isError = true;
                    if(this.state.selectTypeTxt == 'Free'){
                        errors.error = '';
                        _isError = false;
                    }else{
                        _isError = true;
                        errors.error = '*Enter quantity is not gretor than available quantity';
                    }
                }else{
                    /*if(this.state.transactionId == 0)  {                                         
                        if(validator.isInt(value)){
                            _isError = false;
                            errors.error = '';
                        } else {
                            _isError = true;
                            errors.error = '*quantity should be numeric!';
                        }
                    }*/ 
                    //_isError = false;
                    //errors.error = '';
                }
                this.setState({without_sr_quantity:value,error:errors,isError:_isError,withoutTypeQty:value}); 
            break;
        }
        
    }
    

    clickToAddData = (event) => {   
        event.preventDefault();
        let errors = this.state.errors;
        let _isError =  false;
        let _without_sr_quantity = this.state.without_sr_quantity  ? this.state.without_sr_quantity  : 0
        if(this.state.without_sr_quantity == ''){
            _without_sr_quantity = 0;
        }
        /*if(this.state.transactionId == 0){            
            if(_without_sr_quantity == '0' || _without_sr_quantity == 0){
                _isError  = true;
                errors.error = 'Please Enter quantity';
                this.setState({errors :errors,isError:_isError});
                return false;
            }
        }*/
        if(this.state.isError ==  false){
            let _totalQuantity = parseFloat(_without_sr_quantity) +  parseFloat(this.state.totalQuantity);
            _totalQuantity =  parseFloat(_totalQuantity) - parseFloat(this.state.normalFQtyAdded);
            if(this.state.freeQtyAk > 0){
                _totalQuantity =  _totalQuantity - this.state.freeQtyAk;
            }
            this.props.callBack (_without_sr_quantity,_totalQuantity,this.state.indexPosition);
        }
              
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        let  isDisabled = '';//Object.keys(errors).some(x => errors[x]);
        if(this.state.isError == true){
            isDisabled = 'disabled';
        }
        let _normalFQtyAdded = '';

        if(this.state.transactionId >0){
            if(this.state.without_sr_quantity == 0 || this.state.without_sr_quantity == ''){
                _normalFQtyAdded = '';
            }
            else{
                if(this.state.normalFQtyAdded > 0){
                    _normalFQtyAdded =  this.state.normalFQtyAdded;
                }
                if(this.state.withoutTypeQty == ''){
                    _normalFQtyAdded = '';
                }
            }
        }
        else{
            if(this.state.normalFQtyAdded > 0){
                _normalFQtyAdded =  this.state.normalFQtyAdded;
            }
            if(this.state.withoutTypeQty == ''){
                _normalFQtyAdded = '';
            }
        }
        
        // if(this.state.normalFQtyAdded > 0){
        //     _normalFQtyAdded =  this.state.normalFQtyAdded;
        // }
        return(
            <React.Fragment>           
                <form method="post" id="form-login" className="webForm px-30">                  
                    <div className="form-group row">{this.state.normalQuantityTYpe+'=='+this.state.normalRemainingQuantity} 
                    
                        {(this.state.normalQuantityTYpe == 'Sale' ) && 
                            <h4 className="badge badge-light k-mt-md "> Available Quantity  Is : {this.state.normalRemainingQuantity}</h4>
                        }
                    </div>                       
                    <div className="form-group row">                       
                        {(this.state.normalRemainingQuantity > 0 && this.state.normalQuantityTYpe == 'Sale' ) && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || ""} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity </label>
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }
                        
                        {( (this.state.normalRemainingQuantity == 0 || this.state.normalRemainingQuantity == '0') && this.state.normalQuantityTYpe == 'Sale' ) && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || ""} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity </label>
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }  

                        {(this.state.normalQuantityTYpe == 'Purchase' ) && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="number" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || _normalFQtyAdded} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity </label>
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }                        
                    </div>                    
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}