import React, { Component } from "react";
import { PostData } from "../../../../service/postData";
import { sendNotification } from "../../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ButtonPdf from "../../htmlPdfCreation";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}

let columns = [
  {
    label: "Date",
    field: "invoice_date",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Date",
    },
  },
  {
    label: "Invoice No.",
    field: "invoice_no",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Invoice No.",
    },
  },
  {
    label: "Party Name",
    field: "party_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Name",
    },
  },
  {
    label: "Amount",
    field: "total_amount",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Amount",
    },
  },
  {
    label: "Profit(+) /  Loss(-)",
    field: "balance",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Balance Due",
    },
  },
  {
    label: "Action",
    field: "actionExtra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";
export default class dayBookReportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      transactionArray: [],
      transactionArrayFilter: [],
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      name: null,

      paidAmount: 0,
      unpaidAmount: 0,
      total: 0,

      startDate: moment()
        .month("April")
        .startOf("month")
        .format("MMM DD, YYYY"),
      endDate: moment()
        .add(1, "year")
        .month("March")
        .endOf("month")
        .format("MMM DD, YYYY"),
      dateLabel: "",
      errors: {
        name: "",
      },
    };
  }

  async componentDidMount() {
    this.getSaleListData();
  }

  getSaleListData() {}

  loadTranscListing() {
    let dataList = [];
    let rows = [];

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getSaleListData();
  };

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <h5># Balance Sheet</h5>
                <div className="block">
                  <div className="block-content" style={{ marginTop: "-20px" }}>
                    <div className="row items-push">
                      <div className="row col-lg-12">
                        <div className="col-lg-9">
                          <span>Filter : </span>
                          <DateRangePicker
                            initialSettings={{
                              startDate: this.state.startDate,
                              endDate: this.state.endDate,
                              locale: {
                                format: "MMMM D, YYYY",
                              },
                              ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, "days").toDate(),
                                  moment().subtract(1, "days").toDate(),
                                ],
                                "This Week": [
                                  moment()
                                    .startOf("week")
                                    .format("MMM DD, YYYY"),
                                  moment().endOf("week").format("MMM DD, YYYY"),
                                ],
                                "This Month": [
                                  moment().startOf("month").toDate(),
                                  moment().endOf("month").toDate(),
                                ],
                                "Last Month": [
                                  moment()
                                    .subtract(1, "month")
                                    .startOf("month")
                                    .toDate(),
                                  moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Quarter": [
                                  moment().startOf("month").toDate(),
                                  moment()
                                    .add(2, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Financial Year": [
                                  moment()
                                    .month("April")
                                    .startOf("month")
                                    .format("MMM DD, YYYY"),
                                  moment()
                                    .add(1, "year")
                                    .month("March")
                                    .endOf("month")
                                    .format("MMM DD, YYYY"),
                                ],
                              },
                            }}
                            onCallback={this.handleCallBackDate}
                          >
                            <input
                              type="text"
                              className="form-control col-7"
                              style={{ display: "inline-block" }}
                            />
                          </DateRangePicker>
                        </div>
                        <div className="col-lg-3">
                          <div className="row pb-20 pull-right">
                            <div className="text-right">
                              <div className="js-appear-enabled text-center">
                                <div className="text-info">
                                  <i className="fa fa-file-excel-o"></i>
                                </div>
                                <div className="font-size-sm text-muted">
                                  Excel Export
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block" style={{ marginTop: "-60px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content">
                    <h2 className="block-title ml-10">Transactions</h2>
                    {/* <a href={global.webUrl+"business/add-sale/"} style={{marginTop: '35px'}} className="pull-right pull-right btn btn-sm btn-primary mb-10">
                                            <i className="fa fa-plus-circle"></i> Add Sale
                                        </a> */}
                  </div>
                  <div className="block-content" id="tableData">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTranscListing()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
