import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import { numberFormat } from "../../../config/numberFormat";
import Modal from "react-modal";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];

_bankArray.push({ bank_id: "0", bank_name: "", paymentType: "Cash" });

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class chequeDepositPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    this.state = {
      minDate: this.props.minDate
        ? this.props.minDate
        : [moment().subtract(7, "days")],

      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-loan-accounts/",
      buttonTitle: "",
      hideShow: "",
      isLoaded: true,
      companyId: _company_id,
      businessId: _businessId,
      userId: _signingId,

      editChequeTrasactionId: this.props.isChequeTrasactionId > 0  ? this.props.isChequeTrasactionId
          : 0,
      trasactionId: this.props.isTransaction,
      transactionType:
        this.props.isTransactionType == "Deposit" ? "Withdraw" : "Deposit",
      type:
        this.props.isTransactionType == "Deposit"
          ? "DEPOSITCHEQUE"
          : "WITHDRAWCHEQUE",

      transactionFType:
        this.props.isTransactionType == "Deposit" ? "Withdraw From" : "Paid To", // Deposit To
      transactionTType:
        this.props.isTransactionType == "Deposit"
          ? "Deposit To"
          : "Withdraw From",

      mainTransactionType: "Sale",
      party_id: "0",
      party_name: "NA",
      receivedAmount: "0",
      paid_from: "Cash",
      paymentRefNo: "NA",
      bank_id: "0",
      bank_name: "",
      pay_date: moment().format("MM/DD/YYYY"),
      currentDate: [moment().subtract(7, "days")],
      description: "",
      errors: {
        description: "",
      },
    };
    _isEditMode = this.props.isTransaction > 0 ? true : false;
  }

  async componentDidMount() {
    let _loanTrasacId = this.props.isTransaction;

    if (this.props.isTransaction > 0) {
      const requestData = JSON.stringify({
        transaction_id: this.props.isTransaction,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let customerData = _transactionArray.customer;
              let p_name = customerData.expense_category;
              if (
                customerData.party_name!="" &&
                customerData.party_name!=null
              ) {
                if (
                  customerData.expense_category!="" &&
                  customerData.expense_category!=null
                ) {
                  p_name =
                    customerData.party_name +
                    " (" +
                    customerData.expense_category +
                    ")";
                } else {
                  p_name = customerData.party_name;
                }
              }
              console.log('_transactionArray', JSON.stringify(_transactionArray))
              this.setState({
                party_id: customerData.party_id,
                party_name: p_name,
                //businessId:_transactionArray.business_id,
                //companyId:_transactionArray.company_id,
                receivedAmount:
                  parseFloat(transAmout.receivedAmount) +
                  parseFloat(transAmout.paidAmount),
                paymentRefNo:
                  _transactionArray.paymentRefNo!=""
                    ? _transactionArray.paymentRefNo
                    : "NA",
                mainTransactionType: _transactionArray.type,
                description :  _transactionArray.description,
                pay_date :  moment(_transactionArray.invoiceDate).format("MM/DD/YYYY"),
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "paid_from":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        //console.log(value);
        let _valType = "";
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        this.setState({
          paid_from: _valType,
          bank_id: _bankId,
          bank_name: _bankName,
        });
        break;
      case "description":
        errors.description = value.length < 3 ? "*Enter description" : "";
        this.setState({ description: value });
        break;
      default:
        break;
    }
  };

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.principal_amount > this.state.loanOpenningBalance) {
      _isError = true;
      errors["principal_amount"] =
        "*Principal amount cannot be greater than current balance of " +
        this.state.loanOpenningBalance;
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.editChequeTrasactionId > 0) {
        this.editMakePaymentTrasacDMethodCall();
      } else {
        this.addMakePaymentTrasacDMethodCall();
      }
    }
  };

  addMakePaymentTrasacDMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.companyId,
      businessId: this.state.businessId,
      userId: this.state.userId,
      loan_id: this.state.loanAccountId,
      trasactionId: this.state.trasactionId,

      received_amount: this.state.receivedAmount,
      type: this.state.type,
      paid_from: this.state.paid_from,
      pay_date: moment(this.state.pay_date).format("MMMM D, YYYY"),
      pay_time: moment().format("h:mm A"),
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      bank_id: this.state.bank_id,
      bank_name: this.state.bank_name,
      description: this.state.description,
      mainTransactionType: this.state.mainTransactionType,
    });
    //console.log("===11 requestData===",requestData);return;
    PostData(global.createChequeDepositTransaction, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editMakePaymentTrasacDMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.companyId,
      businessId: this.state.businessId,
      userId: this.state.userId,
      loan_detail_id: this.state.trasactionId,
      loan_id: this.state.loanAccountId,
      trasactionId: this.state.trasactionId,
      received_amount: this.state.receivedAmount,
      adjust_amount: this.state.receivedAmount,
      type: this.state.type,
      paid_from: this.state.paid_from,
      pay_date: moment(this.state.pay_date).format("MMMM D, YYYY"),
      pay_time: moment().format("h:mm A"),
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      bank_id: this.state.bank_id,
      bank_name: this.state.bank_name,
      description: this.state.description,
      mainTransactionType: this.state.mainTransactionType,
    });

    //console.log("===222 requestData===",requestData);return;
    PostData(global.editChequeDepositTransaction, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  handleCallBackDate = (start) => {
    this.setState({ pay_date: start.format("MMMM D, YYYY") });
  };

  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
            }
          }
        });
        _payData.push(<option value="NewBank">Add New Bank</option>);
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  isDateRefresh() {
    let _pay_date = this.state.pay_date;
    // let _given = moment(this.state.minDate,'MMMM D, YYYY');
    // let _current = moment().startOf('day');
    // let _remianDays = (moment.duration(_given.diff(_current)).asDays() < 0) ? moment.duration(_given.diff(_current)).asDays() : 7;
    // _remianDays = Math.abs(_remianDays);
    if (_isEditMode == true && this.state.trasactionId > 0) {
      console.log('selected={_pay_date}', _pay_date);
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _pay_date,
            //minDate: moment().subtract(_remianDays, 'days'),
          }}
          setStartDate={_pay_date}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" value={this.state.pay_date} dataVal="111"/>
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.state.trasactionId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _pay_date,
            //minDate: moment().subtract(_remianDays, 'days'),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" value={this.state.pay_date} dataVal="222"/>
        </DateRangePicker>
      );
    }
  }

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-12">
              <div>
                <label htmlFor="login-principal">
                  {this.state.transactionFType}
                </label>{" "}
                : {this.state.party_name}
              </div>
              <div>
                <span>
                  <label htmlFor="login-principal">Cheque Amount</label> :{" "}
                  {numberFormat(this.state.receivedAmount)}
                </span>
                <span style={{ marginLeft: "100px" }}>
                  <label htmlFor="login-principal">Cheque Ref No.</label> :{" "}
                  {this.state.paymentRefNo}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <select
                  className="form-control"
                  defaultValue={
                    this.state.bankId > 0
                      ? this.state.bankId
                      : this.state.paid_from
                  }
                  id="paid_from"
                  name="paid_from"
                  onChange={this.handleChange}
                >
                  {this.settleAmountType()}
                </select>
                <label htmlFor="login-principal">
                  {this.state.transactionTType}
                </label>
              </div>
            </div>
            <div className="col-lg-6 form-material" id="paddingOff">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">Transfer Date</label>
                <span className="iris_error_txt">
                  {<span className="error">{errors.received_in}</span>}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="description"
                  name="description"
                  value={this.state.description}
                  placeholder="Enter Description"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">Description</label>
                <span className="iris_error_txt">
                  {errors.description.length > 0 && (
                    <span className="error">{errors.description}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SBANK}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewBank"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Bank</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewBank"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewBankDetail
                    isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewBank"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}
      </React.Fragment>
    );
  }
}
