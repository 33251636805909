import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = 'IN';
let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
    country_code = _companySession.country_code;
    tax_name = _companySession.tax_name;
    gstTaxDetails = _companySession.taxDetails;
}

let _loader = 'glb-ldr-prt active';

export default class ItemListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-unit/',
            itemSelectData : this.props.itemSelectData ? this.props.itemSelectData : '0',
            _dataType: (this.props.dataType) ? this.props.dataType : 'ITEM',
            buttonTitle:'Add New',
            isLoaded : true, 
            unitArray : [],
            secUnitArray : [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            category :  '',
            errors: {
                category  :  '',
            }
        }
    }
    async componentDidMount ()
    {        
        

    }
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            
            case 'category':                 
                errors.category = value.length < 3 ? '*Category must be 3 characters long!' : '';
                                 
                this.setState({category:value,errors: errors}); 
            break;

        }
    }
    

    clickToAddData = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
                   
        
        if(this.state.category == '')
        {
            _isError = true;
            errors['category'] = "*Please Enter Category";
            this.setState({errors: errors});
        }
        
        if (_isError == false){
            this.addNewItemCategory();
        }

    }

    addNewItemCategory()
    {  
        const requestData = JSON.stringify({
            businessId : this.state.businessId,
            company_id : this.state.company_id,
            categoryName: this.state.category,
        });   
          
        PostData(global.userAddItemCategory,requestData,'POST').then((result)=>{            
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        this.props.callBack('hello');
                        //window.location.href = global.webUrl+this.state.isPageRedirect;
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
              
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">  
                    
                    <div className="claerfix"></div>
                    <div className="form-group row">                                                        
                            
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="category" name="category" value={this.state.category} placeholder="Enter Category" onChange={this.handleChange} onWheel={() => document.activeElement.blur()}/>
                                    <label htmlFor="login-username">Category Name</label>
                                <span className="iris_error_txt">{errors.category.length > 0 && <span className='error'>{errors.category}</span>}</span>
                            </div>                           
                            
                        </div>                                    
                        
                    </div>
                    <div className="claerfix"></div>

                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData}  disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}