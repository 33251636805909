import React,{Component} from 'react';

global.BACK_URL = 'Dashboard';

let vsSession = localStorage.getItem('vs_UserSession');
let _authName = 'Administrator';
if(vsSession!=undefined || vsSession!=null)
{
    vsSession = JSON.parse(vsSession);
    _authName = vsSession ? vsSession.loginName : global.loginName;
}
export default class DashboardPanel extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){
        
    }
    render(){
        
        return(
            <React.Fragment>
                <div className="content">
                    <div className="row invisible" data-toggle="appear">
                        <div className="col-6 col-xl-3">
                            <a className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-bag fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600" data-toggle="countTo" data-speed="1000" data-to="1500">0</div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Sales</div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-xl-3">
                            <a className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-wallet fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600">$<span data-toggle="countTo" data-speed="1000" data-to="780">0</span></div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Earnings</div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-xl-3">
                            <a className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-envelope-open fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600" data-toggle="countTo" data-speed="1000" data-to="15">0</div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Messages</div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-xl-3">
                            <a className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-users fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600" data-toggle="countTo" data-speed="1000" data-to="4252">0</div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Online</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="row invisible" data-toggle="appear">
                        <div className="col-6 col-md-4 col-xl-2">
                            <a className="block block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content ribbon ribbon-bookmark ribbon-success ribbon-left">
                                    <div className="ribbon-box">15</div>
                                    <p className="mt-5">
                                        <i className="si si-envelope-letter fa-3x"></i>
                                    </p>
                                    <p className="font-w600">Inbox</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <a className="block block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content">
                                    <p className="mt-5">
                                        <i className="si si-user fa-3x"></i>
                                    </p>
                                    <p className="font-w600">Profile</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <a className="block block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content ribbon ribbon-bookmark ribbon-primary ribbon-left">
                                    <div className="ribbon-box">3</div>
                                    <p className="mt-5">
                                        <i className="si si-bubbles fa-3x"></i>
                                    </p>
                                    <p className="font-w600">Forum</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <a className="block block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content">
                                    <p className="mt-5">
                                        <i className="si si-magnifier fa-3x"></i>
                                    </p>
                                    <p className="font-w600">Search</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <a className="block block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content">
                                    <p className="mt-5">
                                        <i className="si si-bar-chart fa-3x"></i>
                                    </p>
                                    <p className="font-w600">Live Stats</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <a className="block block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content">
                                    <p className="mt-5">
                                        <i className="si si-settings fa-3x"></i>
                                    </p>
                                    <p className="font-w600">Settings</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
} 