import React,{Component} from 'react';

let vsSession = localStorage.getItem('vs_UserSession');
let _authName = 'Administrator';
let _businessSetting = {};

if(vsSession!=undefined || vsSession!=null)
{
    vsSession = JSON.parse(vsSession);
    _authName = vsSession ? vsSession.loginName : global.loginName;
    _businessSetting = (vsSession) ? vsSession.businessSetting : {};
}

export default class HeaderPage extends Component{
    constructor(props){
        super(props);
        this.state={}
    }

    //componentDidMount(){}

    render(){
        return (
            <React.Fragment>
                <div className="block pull-r-l">
                    <div className="block-header bg-body-light">
                        <h3 className="block-title">
                            <i className="fa fa-fw fa-clock-o font-size-default mr-5"></i>Sale Transactions
                        </h3>
                        <div className="block-options">
                            <button type="button" className="btn-block-option" data-toggle="block-option" data-action="state_toggle" data-action-mode="demo">
                                <i className="si si-refresh"></i>
                            </button>
                            <button type="button" className="btn-block-option" data-toggle="block-option" data-action="content_toggle"></button>
                        </div>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity">
                            <li>
                                <i className="si si-wallet text-success"></i>
                                <a href={global.webUrl+"business/sale-invoice/"}>
                                    <div className="font-w600">Sale Invoice</div>
                                </a>
                            </li>
                            <li>
                                <i className="si si-close text-danger"></i>
                                <a href={global.webUrl+"business/manage-sale-payment-in/"}>
                                    <div className="font-w600">Payment-In</div>
                                </a>
                            </li>
                            <li>
                                <i className="si si-pencil text-info"></i>
                                <a href={global.webUrl+"business/manage-sale-return/"}>
                                    <div className="font-w600">Cr. Note/Sale Return</div>
                                </a>
                            </li>
                            {_businessSetting.SalePurchaseOrder > 0  && 
                            <li>
                                <i className="si si-plus text-success"></i>
                                <a href={global.webUrl+"business/manage-sale-order/"}>
                                    <div className="font-w600">Sale Order</div>
                                </a>
                            </li>
                            }
                            {_businessSetting.EstimateQuitation > 0  && 
                            <li>
                                <i className="si si-wrench text-warning"></i>
                                <a href={global.webUrl+"business/manage-sale-estimate/"}>
                                    <div className="font-w600">Estimate Quotation</div>
                                </a>
                            </li>
                            }
                            {/* <li>
                                <i className="si si-user-follow text-pulse"></i>
                                <div className="font-w600">Performa Invoice</div>
                            </li> */}
                            {_businessSetting.DeliveryChallan > 0  && 
                            <li>
                                <i className="si si-user-follow text-pulse"></i>
                                <a href={global.webUrl+"business/manage-sale-delivery-challan/"}>
                                    <div className="font-w600">Delivery Challan</div>
                                </a>
                            </li>
                            }
                        </ul>
                    </div>
                    <div className="block-header bg-body-light">
                        <h3 className="block-title">
                            <i className="fa fa-fw fa-clock-o font-size-default mr-5"></i>Purchase Transactions
                        </h3>
                        <div className="block-options">
                            <button type="button" className="btn-block-option" data-toggle="block-option" data-action="state_toggle" data-action-mode="demo">
                                <i className="si si-refresh"></i>
                            </button>
                            <button type="button" className="btn-block-option" data-toggle="block-option" data-action="content_toggle"></button>
                        </div>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity">
                            <li>
                                <i className="si si-wallet text-success"></i>
                                <a href={global.webUrl+"business/purchase-invoice/"}>
                                    <div className="font-w600">Purchase</div>
                                </a>
                            </li>
                            <li>
                                <i className="si si-close text-danger"></i>
                                <a href={global.webUrl+"business/manage-purchase-payment-out/"}>
                                    <div className="font-w600">Payment-Out</div>
                                </a>
                            </li>
                            <li>
                                <i className="si si-pencil text-info"></i>
                                <a href={global.webUrl+"business/manage-purchase-return/"}>
                                    <div className="font-w600">Dr. Note/Purchase Return</div>
                                </a>
                            </li>
                            {_businessSetting.SalePurchaseOrder > 0  && 
                            <li>
                                <i className="si si-plus text-success"></i>
                                <a href={global.webUrl+"business/manage-purchase-order/"}>
                                    <div className="font-w600">Purchase Order</div>
                                </a>
                            </li>
                            }
                        </ul>
                    </div>
                    <div className="block-header bg-body-light">
                        <h3 className="block-title">
                            <i className="fa fa-fw fa-clock-o font-size-default mr-5"></i>Other Transactions
                        </h3>
                        <div className="block-options">
                            <button type="button" className="btn-block-option" data-toggle="block-option" data-action="state_toggle" data-action-mode="demo">
                                <i className="si si-refresh"></i>
                            </button>
                            <button type="button" className="btn-block-option" data-toggle="block-option" data-action="content_toggle"></button>
                        </div>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity">
                            {_businessSetting.OtherIncome > 0  && 
                            <li>
                                <i className="si si-wallet text-success"></i>
                                <a href={global.webUrl+"business/other-income/"}>
                                    <div className="font-w600">Other Income</div>
                                </a>
                            </li>
                            }
                            <li>
                                <i className="si si-wallet text-success"></i>
                                <a href={global.webUrl+"business/manage-expense/"}>
                                    <div className="font-w600">Expenses</div>
                                </a>
                            </li>
                            <li>
                                <i className="si si-wallet text-success"></i>
                                <a href={global.webUrl+"business/party-to-party-transfer/"}>
                                    <div className="font-w600">Party to Party</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </React.Fragment>
        );
    }
}

