// Business Companies Listing Apis
global.adminAddCompany = global.API_ADMIN_URL+'adminAddCompany/';
global.adminEditCompany = global.API_ADMIN_URL+'adminEditCompany/';
global.adminCompanyList = global.API_ADMIN_URL+'adminCompanyList/';
global.adminCompanyDelete = global.API_ADMIN_URL+'adminCompanyDelete/';
global.adminCompanyStatus = global.API_ADMIN_URL+'adminCompanyStatus/';
global.adminCompanyDetails = global.API_ADMIN_URL+'adminCompanyDetails/';
global.adminAssignCompanyList = global.API_ADMIN_URL+'adminAssignCompanyList/';
global.adminAssignCompanyAdd = global.API_ADMIN_URL+'adminAssignCompanyAdd/';

global.adminDeleteAssignCompany = global.API_ADMIN_URL+'adminDeleteAssignCompany/';

// For business dashbaord
global.businessAddCompany = global.API_BUSINESS_URL+'businessAddCompany/';
global._business_CompanyList = global.API_BUSINESS_URL+'companyList/';

global._business_CompanyListTrans = global.API_BUSINESS_URL+'companyListTrans/';