import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};

let _SerialNoChk = 0;
let _SerialNoTxt = '';
let _SerialNoAdditionalChk1 = 0;
let _SerialNoAdditionalChk2 = 0;
let _SerialNoAdditionalLabel1 = '';
let _SerialNoAdditionalLabel2 = '';

let _loader = 'glb-ldr-prt active';

export default class quickViewSerialList extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _SerialNoAdditionalChk1 = _businessSetting.SerialNoAdditionalChk1;
                        _SerialNoAdditionalChk2 = _businessSetting.SerialNoAdditionalChk2;
                        _SerialNoAdditionalLabel1 = _businessSetting.SerialNoAdditionalLabel1;
                        _SerialNoAdditionalLabel2 = _businessSetting.SerialNoAdditionalLabel2;
                        _SerialNoChk = _businessSetting.SerialNoChk;
                        _SerialNoTxt = _businessSetting.SerialNoTxt;
                    }
                }
            } 
        }
        this.state = {
            itemSerialNoAll : [],    
            itemId : this.props.itemId ? this.props.itemId : '0',  
            isLoaded : true, 
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            SerialNoAdditionalLabel1 : _SerialNoAdditionalLabel1,
            SerialNoAdditionalLabel2 : _SerialNoAdditionalLabel2,
            SerialNoAdditionalChk1 : _SerialNoAdditionalChk1,
            SerialNoAdditionalChk2 : _SerialNoAdditionalChk2,
            SerialNoChk : _SerialNoChk,
            SerialNoTxt : _SerialNoTxt,
            isError : false,
            errors: {
            }
        }
    }
    async componentDidMount ()
    {   
        
        this.getItemSerialListData(this.state.itemId);
    }    
    getItemSerialListData(itemId){
        let _serialArray = [];
        const requestData = JSON.stringify({
            itemId:itemId
        });

        PostData(global.userItemSerialList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({itemSerialNoAll:_serialArray,isLoaded:true});                
            }
            else
            {               
                if (responseJson.response) {
                    _serialArray = responseJson.response;
                }
                this.setState({itemSerialNoAll:_serialArray,isLoaded:true,});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    removeFormFields(itemId,serial_no,serial_id) {  
            
        const requestData = JSON.stringify({
            itemId: itemId,
            serial_no: serial_no,
            serial_id: serial_id,
        }); 
        PostData(global.userItemSerialDelete, requestData,'POST').then((result) => {
            let responseJson = result;                
            if(responseJson.success == false)
            {
                sendNotification("Error Message 😓",responseJson.message,"danger");
                return;
            }
            else
            {
                sendNotification("Success Message 👍",responseJson.message,"success");
                this.getItemSerialListData(this.state.itemId);
                
                this.props.callBack(itemId);
                return;
            }
        });
    } 



    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        //#react-confirm-alert .react-confirm-alert-overlay{z-index:9999999 !important;}
        return(
            <React.Fragment>           
                <form method="post" id="popupRemoveId" className="webForm px-30">                  
                    <div className="form-group row">                        
                    
                    </div>
                    {this.state.itemSerialNoAll.map((element, index) => (
                    <div className="form-group" >
                            <div className="row">
                                {this.state.SerialNoChk == 1 && 
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""}   placeholder={this.state.SerialNoTxt} readOnly onWheel={() => document.activeElement.blur()}/>
                                        <label htmlFor="login-username">{this.state.SerialNoTxt} </label>
                                    </div> 
                                </div>  
                                }
                                {this.state.SerialNoAdditionalChk1 == 1 && 
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.SerialNoAdditionalValue1 || ""}  placeholder="Enter Value 1" readOnly 
                  onWheel={() => document.activeElement.blur()}/>
                                        <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1} </label>
                                        
                                    </div> 
                                </div>  
                                }
                                {this.state.SerialNoAdditionalChk2 == 1 && 
                                <div className="col-3">
                                    <div className="form-material open">
                                        <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.SerialNoAdditionalValue2 || ""}  placeholder="Enter Value 2"  readOnly 
                  onWheel={() => document.activeElement.blur()}/>
                                        <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2}  </label>
                                    </div> 
                                </div>
                                }
                               
                                <div className="col-2">
                                    <a  className="badge badge-danger pull-right badge-danger" onClick={this.removeFormFields.bind(this,this.state.itemId,element.serial_no,element.id)} >
                                        <i className="fa fa-solid fa-trash"></i>
                                        Delete Serial
                                    </a>
                                </div>                            
                                
                                </div>
                        
                    </div>
                    ))}
                    
                    
                </form>
                
            </React.Fragment>
        )    
    }
}