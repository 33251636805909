import React,{Component} from 'react';

export default class HeaderPage extends Component{
    constructor(props){
        super(props);
        this.state={}
    }

    //componentDidMount(){}

    render(){
        return (
            <React.Fragment>
                <footer id="page-footer">
                    <div className="content py-20 font-size-sm clearfix">
                        <div className="float-right">
                            Crafted with <i className="fa fa-heart text-pulse"></i> by Hvantage Technologies Inc.
                        </div>
                        <div className="float-left">
                        Vyavsay.in &copy; <span className="js-year-copy"></span>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

