// Other Income Apis
global.userAddOIItemCategory = global.API_USER_URL+'userAddOIItemCategory/';
global.userOIItemCatList = global.API_USER_URL+'userOIItemCatList/';
global.userOIItemCategoryDetails = global.API_USER_URL+'userOIItemCategoryDetails/';
global.userEditOIItemCategory = global.API_USER_URL+'userEditOIItemCategory/';
global.userOIItemCategoryDelete = global.API_USER_URL+'userOIItemCategoryDelete/';

global.userAddOIItem = global.API_USER_URL+'userAddOIItem/';
global.userOIItemDetails = global.API_USER_URL+'userOIItemDetails/';
global.userOIItemList = global.API_USER_URL+'userOIItemList/';
global.userOIItemDelete = global.API_USER_URL+'userOIItemDelete/';
global.userEditOIItem = global.API_USER_URL+'userEditOIItem/';

global.createOtherIncomeTransaction = global.API_USER_URL+'createOtherIncomeTransaction/';
global.otherIncomeTransactionList = global.API_USER_URL+'otherIncomeTransactionList/';
global.otherIncomeTransactionDetails = global.API_USER_URL+'otherIncomeTransactionDetails/';
global.editOtherIncomeTransaction = global.API_USER_URL+'editOtherIncomeTransaction/';
global.userOIItemTransactionDelete = global.API_USER_URL+'userOIItemTransactionDelete/';
global.duplicateOtherIncomeTransaction = global.API_USER_URL+'duplicateOtherIncomeTransaction/';

global.userBankDepositDetails = global.API_USER_URL+'userBankDepositDetails/';