import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};

let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        
        
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,         
            modalIsOpen_Info : false,
            _infoData : '',
            _infoTitle : 'Information',
            isGSTHideShow : (_businessSetting.GST > 0) ? true : false,
        }
    }

    componentDidMount()
    {  

    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));

                if(_key == 'GST'){
                    _businessSettingData['HSN_SAC_CODE'] = 1;
                    _businessSettingData['AdditionalCESS'] = 0;
                    _businessSettingData['ReverseCharge'] = 0;
                    _businessSettingData['SateOfSupply'] = 1;
                    _businessSettingData['EWayBillNo'] = 0;
                    _businessSettingData['CompositeScheme'] = 1;
                    if(_value == 0){
                        _businessSettingData['HSN_SAC_CODE'] = 0;
                        _businessSettingData['AdditionalCESS'] = 0;
                        _businessSettingData['ReverseCharge'] = 0;
                        _businessSettingData['SateOfSupply'] = 0;
                        _businessSettingData['EWayBillNo'] = 0;
                        _businessSettingData['CompositeScheme'] = 0;
                    }                    
                    this.setState({isGSTHideShow : (_value > 0) ? true : false});
                }
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }  
    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let _toolTaxGST = "What is this? <br/> GST stands for Goods and Services Tax of Indian Government. Enabling GST, allows you to apply GST on Sale, Purchase and other types of transactions. <br/><br/> How it is used? <br/> Apply GST on Sale and/or purchase invoices. Generate GST reports for Tax filing. <br/><br/> Why to use? <br/> Businesses which have GSTIN no. & are registered under regular or composite scheme can enable GST. Vyavsay will generate readymade GSTR Reports like GSTR-1, GSTR - 3B for your GST filing in India.";   

        let _toolTaxHSN_SAC_CODE = "What is this?  <br/> Enables you to enter & track HSN/ SAC item.";

        let _toolTaxAdditionalCESS = "What is this?  <br/> Enables you to add additional cess on item.  <br/> " 

        let _toolTaxReverseCharge = "What is this?  <br/> Enables reverse charge for your purchase transaction" ; 

        let _toolTaxSateOfSupply = "What is this?  <br/> Enables you to add place of supply while making transaction";

        let _toolTaxEWayBillNo = "What is this?  <br/> Enables you to add E-way bill number transaction";

        let _toolTaxCompositeScheme = "What is this?  <br/>Enables Composite Business mode of Vyavsay. You will not be allowed to add taxes on transaction if you are under composite Business scheme of GST  <br/><br/> Why to use?  <br/> If your company is registered under Composite GST Scheme. Then you can turn this on & then you have to select the category of your business among the given options which are Manufacturing or Trader(Goods) or Restaurant";

        
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-6">
                        <h5 className="content-heading">Tax Setting</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.GST > 0) ? true : false} id="GST" name="GST" value="1" data-slug="GST" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>GST <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxGST,'GST')}></i></span>
                            </li>
                            {this.state.isGSTHideShow == true &&
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.HSN_SAC_CODE > 0) ? true : false} id="HSN_SAC_CODE" name="HSN_SAC_CODE" value="1" data-slug="HSN_SAC_CODE" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>HSN/SAC CODE <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxHSN_SAC_CODE,'HSN/SAC CODE')}></i></span>
                            </li>
                            }
                            {this.state.isGSTHideShow == true &&
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.AdditionalCESS > 0) ? true : false} id="AdditionalCESS" name="AdditionalCESS" value="1" data-slug="AdditionalCESS" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Additional CESS <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxAdditionalCESS,'Additional CESS')}></i></span>
                            </li>
                            }
                            {this.state.isGSTHideShow == true &&
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.ReverseCharge > 0) ? true : false} id="ReverseCharge" name="ReverseCharge" value="1" data-slug="ReverseCharge" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Reverse Charge <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxReverseCharge,'Reverse Charge')}></i></span>
                            </li>
                            }
                            {this.state.isGSTHideShow == true &&
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.SateOfSupply > 0) ? true : false} id="SateOfSupply" name="SateOfSupply" value="1" data-slug="SateOfSupply" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>State Of Supply <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxSateOfSupply,'Sate Of Supply')}></i></span>
                            </li>
                            }
                            {this.state.isGSTHideShow == true &&
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.EWayBillNo > 0) ? true : false} id="EWayBillNo" name="EWayBillNo" value="1" data-slug="EWayBillNo" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>E-Way Bill No <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxEWayBillNo,'E-Way Bill No')}></i></span>
                            </li>
                            }
                            {this.state.isGSTHideShow == true &&
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.CompositeScheme > 0) ? true : false} id="CompositeScheme" name="CompositeScheme" value="1" data-slug="CompositeScheme" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Composite Scheme <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolTaxCompositeScheme,'Composite Scheme')}></i></span>
                            </li>
                            }

                        </ul>
                    </div>
                </div>

                {/* Modal  Tooltip Information Begins */}
               <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">{this.state._infoTitle}</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                {parse(this.state._infoData)}
                                </div>
                                <br/>
                            </div>
                            
                        </div>
                    </div>
                </Modal>
                {/* Modal End Tooltip Information*/}
            </React.Fragment>
        )
    }
} 