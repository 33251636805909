import React,{Component} from 'react';
import {PostData} from '../service/postData';
import {userSessionManage} from '../service/userLocalStorage';
import {sendNotification} from '../_notificationSettings/notificationPanel';
import validator from 'validator';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

export default class accountRegisterPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            successType : false,
            _countryLang:'',
            _countryReg:'iti__hide',
            _countryFlag:'',
            _countryName:'',
            _countryPh:'',
            _countryId:'0',

            countryArray : [],
            //userContact : 'admin@vyavsay.com',
            businessName : '',
            businessEmail: '',
            businessPhone: '',
            businessAddress: '',
            ownerPassword: "",
            alertMsg : '',
            errors : {
                businessName : '',
                businessEmail : '',
                businessPhone : '',
                businessAddress : '',
                ownerPassword:''
            }
        }
    }

    async componentDidMount(){
        localStorage.removeItem('vs_UserSession');
        localStorage.removeItem('vs_UserCountrySession');
        let _countryCode = 'IN';
        
        //'http://api.hostip.info'
        //'https://ipinfo.io/'
        PostData('http://ip-api.com/json','','GET').then(response => {
            let responseJson = response;
            _countryCode = responseJson.countryCode;
            userSessionManage(localStorage,responseJson,'countrySession');
        })
        .catch(error => this.setState({ error, isLoaded: false }));
        
        let _urlCountryData = global.adminCountryList;
        let countryData = await PostData(_urlCountryData,'','GET');
        let _conLang,_conFlag,_conName,_conPh,_conId = '';
        countryData.response.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            let _imgPath = global.FLAG_URL+_name+'.png';

            if(_countryCode == conty.sortname){
                _conLang = conty.sortname;
                _conFlag = _imgPath;
                _conName=conty.name;
                _conPh=conty.phonecode;
                _conId=conty.id;
            }
        });
        this.setState({countryArray:countryData.response,_countryId:_conId,_countryLang:_conLang,_countryFlag:_conFlag,_countryName:_conName,_countryPh:_conPh,isLoaded:true});
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'businessName': 
                errors.businessName =  value.length < 3 ? '*Business Name must be 3 characters long!' : '';
                this.setState({businessName:value}); 
            break;
            case 'ownerPassword': 
                errors.ownerPassword = value.length < 5 ? '*Password must be 5 characters long!' : '';
                this.setState({ownerPassword:value}); 
            break;
            case 'businessEmail':
                if (validator.isEmail(value)) {
                    errors.businessEmail = '';
                } else {
                    errors.businessEmail = '*Please enter valid business email-id.';
                }
                this.setState({businessEmail:value});
            break;
            case 'businessPhone': 
                errors.businessPhone = value.length < 8 ? '*Please enter valid mobile no.' : '';

                if (!value.match(/^[0-9]*$/)) {
                    errors.businessPhone = "*Please enter valid mobile no.";
                }
                this.setState({businessPhone:value}); 
            break;
            case 'businessAddress': 
                errors.businessAddress = value.length < 5 ? '*Please enter valid business addresss.' : '';
                this.setState({businessAddress:value}); 
            break;
            default:
            break;
        }
    }

    clickToCreateBusinessAdmin = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.businessName == null)
        {
            _isError = true;
            errors['businessName'] = "*Please Enter Business Name.";
            this.setState({errors: errors});
        }
        if(this.state.businessEmail == null)
        {
            _isError = true;
            errors['businessEmail'] = "*Please Enter Business Email-id";
            this.setState({errors: errors});
        }
        if(this.state.businessPhone == null)
        {
            _isError = true;
            errors['businessPhone'] = "*Please Enter Business Contact No.";
            this.setState({errors: errors});
        }
        if(this.state.businessPhone.length < 8 || this.state.businessPhone.length >12 )
        {
            _isError = true;
            errors['businessPhone'] = "*Please enter valid contact no.";
            this.setState({errors: errors});
        }
        if(this.state.businessAddress == null)
        {
            _isError = true;
            errors['businessAddress'] = "*Please Enter Business Address.";
            this.setState({errors: errors});
        }

        if(_isError == false)
        {
            this.addBusinesMethodCall();
        }
    }

    addBusinesMethodCall(){
        var businessData = new FormData();
        businessData.append('businessName', this.state.businessName);
        businessData.append('businessEmail', this.state.businessEmail);
        businessData.append('businessPhone', this.state.businessPhone);
        businessData.append('businessAddress', this.state.businessAddress);
        businessData.append('ownerPassword', this.state.ownerPassword);
        businessData.append('countryID', this.state._countryId);
        businessData.append('businessPhoneCode', this.state._countryPh);

        businessData.append('ownerName', '');
        businessData.append('ownerPhone', '');
        businessData.append('ownerEmail', '');
        businessData.append('ownerPhoneCode', '0');
         
        
        new Promise((resolve, reject) =>{
            fetch(global.newUserAddBusiness, {
                method:  'POST',
                body: businessData
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success == true)
                {
                    /*sendNotification("Success Message 👍",res.message,"warning");
                    setTimeout(
                        () => {
                            window.location.href = global.webUrl+'login/';
                        }, 
                        Math.floor(Math.random() * 5000) + 1
                    )*/
                            
                    this.openModalHandler('modalIsPopupAlert');
                    this.setState({'alertMsg': res.message,successType : res.success});
                    return;
                }
                else
                {
                    /*sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['businessPhone'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })*/                        
                    this.openModalHandler('modalIsPopupAlert');
                    this.setState({'alertMsg': res.message,successType : res.success});
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    /* Country Code Dropdown Start Here */
    selectCounties(code,flag,name,e){
        this.setState({
            _countryReg:'iti__hide',
            _countryFlag:flag,
            _countryName:name,
            _countryPh:code,
        })
    }

    manageMultiCounties(){
        this.setState({
            _countryReg:'',
        })
    }

    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';

                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{conty.phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }

    onMouseLeaveHandler() {
        this.setState({
            _countryReg:'iti__hide',
        })
    }
    /* Country Code Dropdown End Here */
    
    /*For Modal open close*/
    openModalHandler(e)
    {
        if(e == 'modalIsPopupAlert'){
            this.setState({modalIsPopupAlert: true});
        }
    }
    closeModalHandler = (e) => {
        if(e == 'modalIsPopupAlert'){
            this.setState({modalIsPopupAlert: false});
        }
        
    } 
    /*End Modal open close*/
    
    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div className="bg-image" style={{backgroundImage: 'url('+global.webUrl+'exImages/background.jpg'+')'}}>
                        <div className="row mx-0 bg-black-op">
                            <div className="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end">
                                <div className="p-30 invisible" data-toggle="appear">
                                    <p className="font-size-h3 font-w600 text-white">
                                        Created By Hvantage Technologies Inc.
                                    </p>
                                    <p className="font-italic text-white-op">
                                        Copyright &copy; <span className="js-year-copy"></span>
                                    </p>
                                </div>
                            </div>
                            <div className="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-white invisible" data-toggle="appear" data-class="animated fadeInRight">
                                <div className="content content-full">
                                    <div className="px-30 py-10">
                                        <a className="font-w700" href={global.webUrl}>
                                            <img src={global.webUrl+"websiteFilesFolder/img/logo.png"} alt="Vyavsay Create Business Account" title="Vyavsay Create Business Account" />
                                            {/* <i className="si si-fire"></i><span className="font-size-xl text-primary-dark">Vyavsay </span><span className="font-size-xl">Dashboard</span> */}
                                        </a>
                                        <h1 className="h3 font-w700 mt-30 mb-10">Welcome to Dashboard </h1>
                                        <h2 className="h5 font-w400 text-muted mb-0">Please Register</h2>
                                    </div>
                                    <form method="post" id="form-login" className="js-validation-signin px-30">
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <input type="text" required="required" className="form-control" id="businessName" name="businessName" value={this.state.businessName} placeholder="Business Name Here" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">Business Name <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="userPassword-error" className="animated fadeInDown">{errors.businessName.length > 0 && <span className='error'>{errors.businessName}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <input type="text" required="required" placeholder="Business Email-id Here" value={this.state.businessEmail} className="form-control" id="businessEmail" name="businessEmail" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">Business Email-Id <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="userPassword-error" className="animated fadeInDown">{errors.businessEmail.length > 0 && <span className='error'>{errors.businessEmail}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this)} onMouseLeave={this.onMouseLeaveHandler.bind(this)}>
                                                        <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName}>
                                                            <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div>
                                                            <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                            <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                        </div>

                                                        <ul className={this.state._countryReg +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                            {this.getReadyMobileCode()}
                                                        </ul>
                                                    </div>
                                                    <input type="text" style={{display: 'inline-block',width:'70%'}} required="required" className="form-control" id="businessPhone" name="businessPhone" value={this.state.businessPhone} placeholder="Please enter contact number" onChange={this.handleChange} maxLength="12"/>
                                                    <label htmlFor="login-username">Contact No. <span className="text-danger">*</span></label>
                                                    <span className="vs_error_txt">{errors.businessPhone.length > 0 && <span className='error'>{errors.businessPhone}</span>}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <input type="password" required="required" placeholder="******" autoComplete="false" value={this.state.ownerPassword} className="form-control" id="ownerPassword" name="ownerPassword" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">Password <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="userPassword-error" className="animated fadeInDown">{errors.ownerPassword.length > 0 && <span className='error'>{errors.ownerPassword}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <input type="text" required="required" placeholder="Business Address Here" autoComplete="false" value={this.state.businessAddress} className="form-control" id="businessAddress" name="businessAddress" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">Business Address <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="userPassword-error" className="animated fadeInDown">{errors.businessAddress.length > 0 && <span className='error'>{errors.businessAddress}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" disabled={isDisabled} className="btn btn-sm btn-hero btn-alt-primary" onClick={this.clickToCreateBusinessAdmin}>
                                                <i className="si si-login mr-10"></i> Register Account
                                            </button>
                                            <div class="mt-30">
                                                <a class="link-effect text-muted mr-10 mb-5 d-inline-block" href={global.webUrl+"terms-condition/"}> <i class="fa fa-book text-muted mr-5"></i> Read Terms </a>
                                                <a class="link-effect text-muted mr-10 mb-5 d-inline-block" href={global.webUrl+"login/"}> <i class="fa fa-user text-muted mr-5"></i> Sign In </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal Alert Message Data Begins */}
                    <Modal isOpen={this.state.modalIsPopupAlert} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalIsPopupAlert">
                            <div className="modal-dialog modal-ss" role="document">
                                <div className="modal-content">
                                    <div className="block block-themed block-transparent mb-0">
                                        <div className="block-header bg-primary-dark">
                                            <h3 className="block-title">Message</h3>
                                            <div className="block-options">
                                                <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalIsPopupAlert')}>
                                                    <i className="si si-close"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="block-content">
                                            <p>{this.state.alertMsg}</p>
                                        </div>
                                        <br/>
                                    </div>
                                    <div className="modal-footer">
                                        {this.state.successType == true &&
                                            <a  className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalIsPopupAlert')} 
                                            href={global.webUrl+"login/"} >OK
                                            </a>                                        
                                        }
                                        
                                        {this.state.successType == false &&
                                            <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalIsPopupAlert')}>OK</button>
                                        }
                                    </div>
                                </div>
                            </div>
                    </Modal>
                    {/* Modal Alert Message Data End */}
                </React.Fragment>
            </div>
        )
    }
}