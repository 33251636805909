import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import validator from "validator";

import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";

import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import QuickAddNewPrefix from "../userSale/quickNewPrefixAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import QuickTransactionSaleInvoiceSection from "../userSale/QuickTransactionSaleInvoiceSection";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";

let length = 6;
let _invoiceNumber = Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
let _invoiceNoReturn = Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));

let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _bankArray = [];
_bankArray.push(
    { bank_id: "0", bank_name: "", paymentType: "Cash" },
    { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _businessSetting = {};
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails ? _companySession.bankDetails : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
      bankArrayData.map((bank, index) => {
      });
    }

    this.state = {
      _timeReg: "iti__hide",
      buttonTitle: "Add Purchase",
      isMakePayment: false,
      isItemCount: 0,
      partyRemainBalance: 0,
      _partyReg: "iti__hide",
      party_id: "0",
      party_name: "",
      is_party: "",
      partyArrayFilter: [],
      partyArray: [],
      stateArray: [],
      invoiceArray: [],
      selectedInvoice: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,
      modalIsOpen_ADDPREFIX: false,
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      billing_name: "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),
      isLoaded: true,
      isDateUpdate: false,
      modalIsOpen_LowStock: false,
      isChecked: false,
      lowStokItemName: "",
      receivedHide: "iti__hide",
      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      reverseChargeStatus: false,
      reverseChargeTax: 0,
      payableAmount: 0,
      paymentType: "Cash",
      settingType: _settingType,
      invoiceNo: _invoiceNumber,
      invoiceNoReturn: _invoiceNoReturn,
      rate_tax: "With Tax",
      invoiceDate: moment().format("MM/DD/YYYY"),
      invoiceDateReturn: moment().format("MM/DD/YYYY"),
      billingAddress: "",
      countryState: "",
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: "",
      //partyId : '',
      bankId: "0",
      bankName: "",
      dueAmount: 0,
      receivedAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      viaAgainst: 0,
      totalAmountOld: 0,
      totalTransDiscountAmount: 0.0,
      totalTransTaxAmount: 0.0,
      transactionWiseTaxId: 0,
      EnableItem: _businessSetting.EnableItem,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      TransAdditionalCharge1: 0,
      TransAdditionalCharge2: 0,
      TransAdditionalCharge3: 0,
      TransportDetailField1: "",
      TransportDetailField2: "",
      TransportDetailField3: "",
      TransportDetailField4: "",
      TransportDetailField5: "",
      TransportDetailField6: "",
      prefixId: 0,
      prefixName: "",
      prefixArray: [],
      handleAddDisable: "",
      party_contact_no: "",
      handleItemSalePurchasePrice: "",
      prefixD: 1,
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      additional_value_date: moment().format("MM/DD/YYYY"),
      transactionType: "",
      totalLinkHistoryAmt: 0,
      errors: {
        invoiceNo: "",
        invoiceNoReturn: "",
        invoiceDate: "",
        billingAddress: "",
        invoiceDateReturn: "",
        party_id: "",
        party_name: "",
        party_contact_no: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    let _transactionType = "";
    let isConvert = false;
    let transactionId = 0;
    _handleParam = _handleParam.split("?auth=");
    if (_handleParam.length > 1) {
      _handleParam = _handleParam[1].split("&");
      transactionId = _handleParam[0] ? _handleParam[0] : 0;
      if (transactionId > 0) {
        _isEditMode = true;
      }
      _transactionType = _handleParam[1].split("tType=");
      _transactionType = _transactionType[1];

      if(_handleParam[2]){
        isConvert = _handleParam[2].split("isConvert=");
        isConvert = isConvert[1];
      }
      this.setState({isConvert: isConvert});
    }
    this.getStateListData();
    //this.getItemListData(_company_id);

    /*For master Units*/
    let _urlDataUnits =
        global.userUnitList +
        "?businessId=" +
        _businessId +
        "&company_id=" +
        this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    /*For master Units*/

    /*Set Setting Data */
    let _prefixSetArray = [];
    let _SalePrefixName = "";
    let _SalePrefixID = "";
    const requestData = JSON.stringify({
      businessId: _businessId,
    });
    PostData(global.businessPrefixSetData, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _prefixSetArray = responseJson.response;
              _SalePrefixName = _prefixSetArray[0].SalePrefixName;
              _SalePrefixID = _prefixSetArray[0].SalePrefixId;
            }
            this.setState({
              prefixName: _SalePrefixName,
              prefixId: _SalePrefixID,
              isLoaded: true,
            });
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    /*Set Setting Data */

    this.setState({ unitArray: _unitArray, isLoaded: true });


    if (transactionId > 0) {
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(isConvert ? global.userTransactionDetails : global.userReturnTransactionDetails, requestData, "POST")
          .then((result) => {
            let responseJson = result;
            if (responseJson.success == false) {
              //this.setState({unitArray:_unitArray,isLoaded:true});
              alert("Transaction details not found in system.");
              setTimeout(() => {
                window.location.href =
                    global.webUrl + "business/manage-purchase-return/";
                return;
              }, Math.floor(Math.random() * 5000) + 1);
            } else {
              if (responseJson.response) {
                result = responseJson.response;
                let _transactionArray = result[0];
                console.log('_transactionArray', JSON.stringify(_transactionArray))
                let transAmout = _transactionArray.totalSummary;
                let itemsData = _transactionArray.items;
                let customerData = _transactionArray.customer;

                let _prefixDataUpdate = _transactionArray.prefix;


                let _imgPath = "";
                if (_transactionArray.descImage!="") {
                  _imgPath =
                      global.LIVE_URL +
                      "uploadDirectory/transaction/" +
                      _transactionArray.descImage;
                }
                let _additional_value_date =  _transactionArray.additional_value_date;
                if(_transactionArray.additional_value_date == ''){
                  _additional_value_date = moment().format("MM/DD/YYYY");
                }

                this.setState({
                  billing_name: customerData.billing_name
                      ? customerData.billing_name
                      : "",
                  invoiceTime: _transactionArray.invoice_time,

                  isItemCount: _transactionArray.isItemNumber,
                  isMakePayment: _transactionArray.isMakePayment,
                  party_id: customerData.party_id,
                  party_name: customerData.party_name,
                  bankId: _transactionArray.bankId,
                  bankName: _transactionArray.bankName,
                  unitArray: _unitArray,
                  buttonTitle: "Edit Debit Note",
                  prefixName: _prefixDataUpdate.name,
                  prefixId: _prefixDataUpdate.id,
                  invoiceNo: _transactionArray.invoiceNo,
                  invoiceDate: moment(_transactionArray.invoiceDate).format(
                      "MM/DD/YYYY"
                  ),
                  invoiceDateReturn: isConvert ? moment().format("MM/DD/YYYY") : moment(_transactionArray.invoice_date_return).format("MM/DD/YYYY"),
                  dueDate: moment(_transactionArray.dueDate).format("MM/DD/YYYY"),
                  state: _transactionArray.countryState,
                  countryState: _transactionArray.countryState,
                  businessId: _transactionArray.business_id,
                  companyId: _transactionArray.company_id,
                  totalAmount: transAmout.totalAmount,
                  receivedAmount: transAmout.receivedAmount ? transAmout.receivedAmount : transAmout.paidAmount,
                  subtotal: transAmout.subtotal,
                  gstRatio: transAmout.gstRatio,
                  gstAmount: transAmout.gstAmount,
                  totalQuantity: transAmout.totalQty,
                  totalDiscount: transAmout.totalDiscount,
                  dueAmount: transAmout.dueAmount,
                  reverseChargeStatus: transAmout.reverseChargeStatus == 1,
                  reverseChargeTax: transAmout.reverseChargeTax,
                  payableAmount: transAmout.payableAmount,
                  paymentType: _transactionArray.paymentType,
                  paymentRefNo: _transactionArray.paymentRefNo,
                  fields: itemsData,
                  isLoaded: true,
                  transactionId: transactionId,
                  invoiceNoReturn: isConvert ? _invoiceNoReturn : _transactionArray.invoice_no_return,
                  billingAddress: _transactionArray.billingAddress,
                  description: _transactionArray.description,
                  descImage: _transactionArray.descImage,
                  imagePreviewUrl: _imgPath,
                  isDateUpdate: true,
                  EnableItem: _transactionArray.EnableItem,
                  FreeItemQuantity: _transactionArray.FreeItemQuantity,
                  TransCount: _transactionArray.Count,
                  settingType: _transactionArray.settingType,
                  totalAmountOld: transAmout.totalAmount,
                  transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                  totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                  totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                  totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,
                  TransAdditionalCharge1:
                  _transactionArray.TransAdditionalCharge1,
                  TransAdditionalCharge2:
                  _transactionArray.TransAdditionalCharge2,
                  TransAdditionalCharge3:
                  _transactionArray.TransAdditionalCharge3,
                  TransportDetailField1: _transactionArray.TransportDetailField1,
                  TransportDetailField2: _transactionArray.TransportDetailField2,
                  TransportDetailField3: _transactionArray.TransportDetailField3,
                  TransportDetailField4: _transactionArray.TransportDetailField4,
                  TransportDetailField5: _transactionArray.TransportDetailField5,
                  TransportDetailField6: _transactionArray.TransportDetailField6,
                  party_contact_no: _transactionArray.party_contact_no,
                  additional_value_1: _transactionArray.additional_value_1,
                  additional_value_2: _transactionArray.additional_value_2,
                  additional_value_3: _transactionArray.additional_value_3,
                  additional_value_date: moment(_additional_value_date).format("MM/DD/YYYY"),
                  transactionType: _transactionType,
                  totalLinkHistoryAmt: _transactionArray.totalLinkHistoryAmt,
                });

                if(isConvert){
                  let _selectedInvoice = [...result];
                  _selectedInvoice = _selectedInvoice.map((data) => {

                    return({
                      ...data,
                      isSelect: true,
                      invoice_no: data.invoiceNo,
                      items: data.items.map((item) => ({
                        ...item,
                        isSelect: true,
                        newQuantity: item.quantity,
                        invoice_no: data.invoiceNo
                      }))
                    })
                  });

                  this.setState({
                    invoiceArray: result,
                    selectedInvoice: _selectedInvoice
                  });
                  this.getPartyListData(customerData.party_id);
                }else{
                  this.getPartyListData();
                  this.getInvoiceListPartyData(_company_id, customerData.party_id, _transactionArray);
                }

              }
            }
          })
          .catch((error) => this.setState({ error, isLoaded: false }));
    }else {
      this.getPartyListData();
    }

    this.businessPrefixList();

  }

  businessPrefixList() {
    let _prefixArray = [];
    const requestData = JSON.stringify({
      company_id: _company_id,
      businessId: _businessId,
      type: "DEBIT NOTE",
    });
    PostData(global.businessWebPrefixList, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true, prefixD: 1 });
          } else {
            if (responseJson.response) {
              _prefixArray = responseJson.response;
              _prefixArray = _prefixArray.filter(
                  (itemData) => itemData.prefix!=null
              );
            }
            this.setState({
              prefixArray: _prefixArray,
              isLoaded: true,
              prefixD: 1,
            });
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getStateListData() {
    let _stateArray = [];

    let _url_GetData =
        global.adminStateList + "?countryCode=" + this.state.countryCode;
    PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _stateArray = responseJson.response;
            }
            this.setState({ stateArray: _stateArray, isLoaded: true });
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
        global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            let _partyBal = 0;
            if (responseJson.response) {
              _partyArray = responseJson.response;

              if (this.state.party_id > 0) {
                _partyArray.map((party, index) => {
                  if (party.party_id == this.state.party_id) {
                    _partyBal = party.totalOutCome;
                  }
                });
              }
            }
            this.setState({
              partyArrayFilter: _partyArray,
              partyArray: _partyArray,
              isLoaded: true,
              partyRemainBalance: _partyBal,
            });
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
  }


  getInvoiceListPartyData(company_id, partyId, editData) {
    const requestData = JSON.stringify({
      company_id: company_id,
      partyId: partyId,
      type: "PURCHASE",
    });

    PostData(global.getPartyOldTransationData, requestData, "POST")
        .then((result) => {
          console.log('result', JSON.stringify(result))
          if (result.success == false) {
            if(!editData){
              this.setState({ invoiceArray: [], selectedInvoice: [] });
              alert('No Bill Available');
            }else {
              let dataInvoice = editData.invoiceData.map((item) => {
                let items = item.items.map((data) => ({...data, isSelect: true}));

                return({
                  ...item,
                  isSelect: items.length > 0,
                  items: [...items]
                })
              });



              this.setState({ invoiceArray: dataInvoice, selectedInvoice: dataInvoice });
            }
          } else {
            let data = result.response.map((item) => ({
              ...item,
              isSelect: false
            }))

            if(editData){
              let dataInvoice = [];
              let invoiceData = [];

              if(data.length > 0){
                dataInvoice = editData.invoiceData.map((item) => {
                  let items = item.items.map((data) => ({...data, isSelect: true}));
                  let remainingInvoice = data.filter((filteritem) => filteritem.invoice_no == item.invoice_no);

                  let remainingInvoiceItem = remainingInvoice.length > 0  ? remainingInvoice[0].items : [];

                  const isSameUser = (a, b) => a.inv_item_id == b.inv_item_id;

                  const onlyInLeft = (left, right, compareFunction) =>
                      left.filter(leftValue =>
                          !right.some(rightValue =>
                              compareFunction(leftValue, rightValue)));

                  let a = remainingInvoiceItem;
                  let b = items;
                  const onlyInA = onlyInLeft(a, b, isSameUser);
                  const onlyInB = onlyInLeft(b, a, isSameUser);

                  let _remainingInvoiceItem =  [...onlyInA, ...onlyInB];
                  return({
                    ...item,
                    isSelect: items.length > 0,
                    items: remainingInvoice.length > 0 ? (_remainingInvoiceItem ? [...items, ..._remainingInvoiceItem] : [...items]) : [..._remainingInvoiceItem]
                  })
                });
                dataInvoice = dataInvoice.filter((data) => data.isSelect);
                invoiceData = data.map(obj => dataInvoice.find(o => o.invoice_no == obj.invoice_no) || obj);
              }else {
                dataInvoice = editData.invoiceData;
                invoiceData = dataInvoice;
              }
              this.setState({selectedInvoice : dataInvoice});
              this.setState({invoiceArray : invoiceData});

            }else{
              this.setState({
                invoiceArray: data,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
            value.length < 1 ? "*Invoice No Must be 1 numbers" : "";

        if (validator.isInt(value)) {
          errors.invoiceNo = "";
        } else {
          errors.invoiceNo = "*Please enter only numeric value.";
        }
        this.setState({ invoiceNo: value });
        break;

      case "invoiceNoReturn":
        errors.invoiceNoReturn =
            value.length < 1 ? "*Return No Must be 1 numbers" : "";

        if (validator.isInt(value)) {
          errors.invoiceNoReturn = "";
        } else {
          errors.invoiceNoReturn = "*Please enter only numeric value.";
        }
        this.setState({ invoiceNoReturn: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
            value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "invoiceDateReturn":
        errors.invoiceDateReturn =
            value.length < 5 ? "* Due date must be selected" : "";
        this.setState({ invoiceDateReturn: value });
        break;

      case "countryState":
        errors.countryState =
            value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "party_contact_no":
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          errors.party_contact_no = "*Please enter valid mobile number!";
        }
        this.setState({ party_contact_no: value });
        break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      case "receivedAmount":
        let _remainAmount = 0;
        let _paidAmt = value;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        if (_paidAmt >= 0) {
          _remainAmount =
              parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
        }
        if(this.state.reverseChargeStatus){
          _remainAmount = _remainAmount - this.state.reverseChargeTax;
        }
        this.setState({
          receivedAmount: _paidAmt,
          dueAmount: _remainAmount.toFixed(2),
        });
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
            value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      case "billingAddress":
        this.setState({ billingAddress: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;

      case "selPrefix":
        if (value == "ADDPREFIX") {
          this.openModalHandler("modalAdjustADDPREFIX");
        }
        let _prefixId = 0;
        let _prefixName = "";
        if (value > 0) {
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _prefixId = optionElement.getAttribute("data-prefixId");
          _prefixName = optionElement.getAttribute("data-prefixName");
        }
        this.setState({ prefixId: _prefixId, prefixName: _prefixName });
        break;

      case "additional_value_date":
        this.setState({ additional_value_date: value });
        break;
      case "additional_value_1":
        this.setState({ additional_value_1: value });
        break;
      case "additional_value_2":
        this.setState({ additional_value_2: value });
        break;
      case "additional_value_3":
        this.setState({ additional_value_3: value });
        break;

      case "TransportDetailField1":
        this.setState({ TransportDetailField1: value });
        break;

      case "TransportDetailField2":
        this.setState({ TransportDetailField2: value });
        break;

      case "TransportDetailField3":
        this.setState({ TransportDetailField3: value });
        break;

      case "TransportDetailField4":
        this.setState({ TransportDetailField4: value });
        break;

      case "TransportDetailField5":
        this.setState({ TransportDetailField5: value });
        break;

      case "TransportDetailField6":
        this.setState({ TransportDetailField6: value });
        break;
      default:
        break;
    }
  };

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    } else if (e == "modalLowStockItem") {
      this.setState({ modalIsOpen_LowStock: true });
    } else if (e == "modalAdjustADDPREFIX") {
      this.setState({ modalIsOpen_ADDPREFIX: true, prefixD: 0 });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    } else if (e == "modalAdjustADDPREFIX") {
      this.setState({
        modalIsOpen_ADDPREFIX: false,
        _infoPrefixId: 0,
        _infoTitlePre: "",
        prefixId: 0,
        prefixName: "",
        prefixD: 1,
      });
    }
  };
  /*End Modal open close*/

  /*Low Stock Data */
  closeModalHandlerLowStock = (e) => {
    if (e == "modalLowStockItem") {
      this.setState({ modalIsOpen_LowStock: false });
    }
  };
  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  SaveModalHandlerLowStock = (e) => {
    let _valueCheck = 0;
    if (this.state.isChecked == true) {
      _valueCheck = 1;
    }
    if (e == "modalLowStockItem") {
      const requestDataLowStock = JSON.stringify({
        settingValue: _valueCheck,
        settingKey: "lowStockStatus",
        businessId: _businessId,
        passcode: 0,
      });
      PostData(global.businessSettingUpdate, requestDataLowStock, "POST").then(
          (result) => {
            if (result.success == true) {
              let _UserSession = localStorage.getItem("vs_UserSession");
              let _updateLocal = _UserSession;
              let _businessSettingData = _updateLocal.businessSetting;
              _businessSettingData["lowStockStatus"] = _valueCheck;
              localStorage.removeItem("vs_UserSession");
              localStorage.setItem(
                  "vs_UserSession",
                  JSON.stringify(_updateLocal)
              );
              this.addSaleTransactionMethodCall();
            } else {
              sendNotification("Error Message 😓", result.message, "danger");
              return;
            }
          }
      );
      this.setState({ modalIsOpen_LowStock: false });
    }
  };
  /*Low Stock Data */

  /* Add Party Code */
  selectPartiess(name, id, balance, party_contact_no) {
    let errors = this.state.errors;
    if (this.state.party_id > 0) {
      //document.body.dispatchEvent("clearData");
      document.body.dispatchEvent(new CustomEvent("clearData"));
    }
    errors.party_name = "";
    this.setState({
      _partyReg: "iti__hide",
      party_name: name,
      party_id: id,
      partyRemainBalance: balance,
      party_contact_no: party_contact_no,
    });

    this.state.is_party = "yes";
    //Party Wise Item Rate Ak

    //this.getItemListPartyData(_company_id, e);
    this.getInvoiceListPartyData(_company_id, id);
    //this.setState({ fields: [] });

  }

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true && this.state.isMakePayment == false) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }
          return (
              <li
                  key={i}
                  className="iti__party iti__standard"
                  id="iti-item-in"
                  onClick={this.selectPartiess.bind(
                      this,
                      party.party_name,
                      party.party_id,
                      _balance,
                      party.party_contact_no
                  )}
              >
                <span className="iti__party-name">{party.party_name}</span>
                <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(Math.abs(_balance))}
              </span>
              </li>
          );
        });
      } else {
        return (
            <li
                key="22"
                className="iti__party iti__standard"
                id="iti-item-in"
                onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
            >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
            </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
        Object.values(entry).some(
            (val) =>
                typeof val == "string" &&
                val.toLowerCase().includes(keyword.toLowerCase())
        )
    );
    this.setState({
      partyArrayFilter: filtered,
      party_name: keyword,
      is_party: "No",
    });
    this.getReadyPartyList();
  };
  /* End Party Code */

  /* Start Item Code */

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowPartyFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  /* End Item Code */

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
            <img
                style={{ width: "35px" }}
                className="preview"
                src={_imageDetails}
                alt={"image-"}
                key="13213"
            />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }

    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Invoice No.";

      this.setState({ errors: errors });
    }

    if (this.state.invoiceNoReturn == "") {
      _isError = true;
      errors["invoiceNoReturn"] = "*Please Enter Return No.";

      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (
          _businessSetting.ShowLowStockDialog == 1 &&
          _businessSetting.lowStockStatus == 0
      ) {
        if (this.state.lowStokItemName.length > 0) {
          this.openModalHandler("modalLowStockItem");
          return false;
        } else {
          this.addSaleTransactionMethodCall();
        }
      } else {
        /*End Low Stock Data*/
        this.addSaleTransactionMethodCall();
      }
    }
  };

  addSaleTransactionMethodCall() {
    let _mainSubTotalAmt = 0; //Add by AK
    let _mainTotalAmt = 0; //Add by AK
    if (this.state.dueAmount < 0) {
      sendNotification(
          "Error Message 😓",
          "Paid amount on debit note/purchase return can not be more than total order amount.",
          "warning"
      );
      return;
    }
    if (this.state.handleAddDisable.length > 2) {
      alert("Cannot submit empty item row, please select item.");
      return false;
    }
    if (this.state.handleItemSalePurchasePrice.length > 2) {
      alert("Item price can not be zero");
      return false;
    }

    let _prefix = JSON.stringify({
      name: this.state.prefixName,
      id: this.state.prefixId,
      isActive: "1",
    });

    if (_mainTotalAmt == 0) {
      _mainTotalAmt = this.state.totalAmount;
      _mainSubTotalAmt = this.state.totalAmount;
    } else {
      this.state.selectedInvoice.map((data) => {
        _mainSubTotalAmt += parseFloat(data.subtotal);
        _mainTotalAmt += parseFloat(data.total_amount);

        if (
            this.state.totalTransDiscountAmount!=0 ||
            this.state.totalTransTaxAmount!=0
        ) {
          _mainTotalAmt = parseFloat(this.state.totalAmount);
          _mainSubTotalAmt = parseFloat(this.state.totalAmountOld);
        }
      }); //Add by AK
    }

    let subtotal = 0.0;
    let gstRatio = 0.0;
    let gstAmount = 0.0;
    let totalDiscount = 0.0;
    let totalQty = 0.0;
    let _count = 0.0;

    let _selectedInvoice = [...this.state.selectedInvoice];
    let items = [];

    _selectedInvoice.map((data) => {
      let innerItem = data.items.filter((data) => data.isSelect);
      items = [...items, ...innerItem];
    });
    const invoiceData = _selectedInvoice.map(({items, ...rest}) => {
      return rest;
    });

    items.map((item) => {
      subtotal += parseFloat(item.rate) * parseFloat(item.newQuantity);
      totalQty += parseFloat(item.newQuantity);
      gstRatio += item.tax_type.ratio ? item.tax_type.ratio : 0;
      gstAmount += parseFloat(item.tax_value);
      totalDiscount += parseFloat(item.discount_in_amount);
      _count += item.trans_count ? parseInt(item.trans_count) : 0;
    });

    let _totalSummary = JSON.stringify({
      subtotal: subtotal ? subtotal : 0,
      gstRatio: gstRatio ? gstRatio : 0,
      gstAmount: gstAmount ? gstAmount : 0,
      totalQty: totalQty ? totalQty : 0,
      totalDiscount: totalDiscount ? totalDiscount : 0,
      totalAmount: _mainTotalAmt,
      reverseChargeStatus: this.state.reverseChargeStatus,
      reverseChargeTax: this.state.reverseChargeTax,
      payableAmount: this.state.payableAmount,
      receivedAmount: this.state.receivedAmount,
      dueAmount: this.state.dueAmount ? parseFloat(this.state.dueAmount).toFixed(2) : 0,
      totalCount: _count
    });


    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId
          ? this.state.transactionWiseTaxId
          : 0,
      totalTransTaxAmount: this.state.totalTransTaxAmount
          ? this.state.totalTransTaxAmount
          : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
          ? this.state.totalTransDiscountPer
          : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
          ? this.state.totalTransDiscountAmount
          : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
          ? this.state.TransAdditionalCharge1
          : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
          ? this.state.TransAdditionalCharge2
          : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
          ? this.state.TransAdditionalCharge3
          : 0,
    }); //Add by AK

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
          ? this.state.TransportDetailField1
          : "",
      TransportDetailField2: this.state.TransportDetailField2
          ? this.state.TransportDetailField2
          : "",
      TransportDetailField3: this.state.TransportDetailField3
          ? this.state.TransportDetailField3
          : "",
      TransportDetailField4: this.state.TransportDetailField4
          ? this.state.TransportDetailField4
          : "",
      TransportDetailField5: this.state.TransportDetailField5
          ? this.state.TransportDetailField5
          : "",
      TransportDetailField6: this.state.TransportDetailField6
          ? this.state.TransportDetailField6
          : "",
    }); //Add by AK

    var saleData = new FormData();
    saleData.append("transaction_id", this.state.transactionId);
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "DEBIT NOTE");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append("invoiceNoReturn", this.state.invoiceNoReturn);
    saleData.append("invoiceDate", this.state.isConvert ? moment().format("MMMM D, YYYY") : moment(this.state.invoiceDate).format("MMMM D, YYYY"));
    saleData.append("invoiceDateReturn",moment(this.state.invoiceDateReturn).format("MMMM D, YYYY"));

    saleData.append("invoiceTime", this.state.invoiceTime);

    saleData.append("billingAddress", this.state.billingAddress);
    saleData.append("totalSummary", _totalSummary);
    saleData.append("transactionWiseAmount", _transactionWiseAmount);
    saleData.append(
        "transactionAdditionalCharge",
        _transactionAdditionalCharge
    );
    saleData.append(
        "transactionTransportationDetails",
        _transacTransportationDetails
    );
    saleData.append("customer", _customer);
    saleData.append("invoiceData", JSON.stringify(this.state.selectedInvoice));
    saleData.append("items", JSON.stringify(items));
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("isSaleCash", this.state.toggleState);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("settingType", this.state.settingType);
    saleData.append("additional_value_1", this.state.additional_value_1);
    saleData.append("additional_value_2", this.state.additional_value_2);
    saleData.append("additional_value_3", this.state.additional_value_3);
    saleData.append(
        "additional_value_date",
        moment(this.state.additional_value_date).format("MMMM D, YYYY")
    );

    let consoleData = '';
    for (let pair of saleData.entries()) {
      consoleData += (pair[0]+ ' : ' + pair[1] + ', ');
    }
    //console.log("==consoleData==",consoleData);

    //console.log("==items",JSON.stringify(items));return false;
    new Promise((resolve, reject) =>{
      //let url = this.state.transactionId > 0 ? global.editPurchaseReturnTransaction : global.createPurchaseReturnTransaction;
      let url = (this.state.isConvert || this.state.transactionId == 0) ? global.createPurchaseReturnTransaction : global.editPurchaseReturnTransaction;
      fetch(url, {
        method: "POST",
        body: saleData,
      })
          .then((response) => response.json())
          .then((res) => {
            if (res.success == true) {
              sendNotification("Success Message 👍", res.message, "success");
              setTimeout(() => {
                //let id = (this.state.transactionId > 0 ? this.state.transactionId : res.transaction_Id);
                let id = (this.state.isConvert || this.state.transactionId == 0) ? res.transaction_Id : this.state.transactionId;
                window.location.href =
                    global.webUrl +
                    "business/return-challan-pdf/?auth=" + id;
              }, Math.floor(Math.random() * 5000) + 1);
            } else {
              sendNotification("Error Message 😓", res.message, "danger");
              return;
            }
            resolve(res);
          })
          .catch((error) => {
            console.log('error', error)
            //reject(error);
          });
    });

  }



  handleCallBackDate = (start) => {
    this.setState({ invoiceDate: start.format("MMMM D, YYYY") });
  };

  handleCallBackReturn = (start) => {
    this.setState({ invoiceDateReturn: start.format("MMMM D, YYYY") });
  };

  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
              _valOption > 0 ||
              _disOption == "Cash" ||
              _disOption == "Cheque"
          ) {
            _payData.push(
                <option
                    value={_valOption}
                    selected={_selBank}
                    data-bankName={_disOption}
                    data-bankId={_valOption}
                >
                  {_disOption}
                </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _invoiceDate,
              }}
              onCallback={this.handleCallBackDate}
          >
            <input
                type="text"
                className="form-control"
                readOnly
                dataSend="1111"
                value={this.state.invoiceDate}
            />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _invoiceDate,
              }}
              onCallback={this.handleCallBackDate}
          >
            <input type="text" className="form-control" />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _invoiceDate,
              }}
              onCallback={this.handleCallBackDate}
          >
            <input type="text" className="form-control" readOnly />
          </DateRangePicker>
      );
    }
  }

  isDateReturnRefresh() {
    let _invoiceRtnDate = this.state.invoiceDateReturn;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _invoiceRtnDate,
              }}
              onCallback={this.handleCallBackReturn}
          >
            <input
                type="text"
                className="form-control"
                readOnly
                dataSend="1111"
                value={this.state.invoiceDateReturn}
            />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _invoiceRtnDate,
              }}
              onCallback={this.handleCallBackReturn}
          >
            <input type="text" className="form-control" readOnly />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _invoiceRtnDate,
              }}
              onCallback={this.handleCallBackReturn}
          >
            <input type="text" className="form-control" readOnly />
          </DateRangePicker>
      );
    }
  }

  isAdditionalDateRefresh() {
    let _additional_value_date = this.state.additional_value_date;

    return (
        <DateRangePicker
            initialSettings={{
              singleDatePicker: true,
              startDate: _additional_value_date,
            }}
            onCallback={this.handleCallBackAddDate}
        >
          <input
              type="text"
              id="additional_value_date"
              name="additional_value_date"
              className="form-control"
              readOnly
          />
        </DateRangePicker>
    );
  }
  handleCallBackAddDate = (start) => {
    this.setState({ additional_value_date: start.format("MMMM D, YYYY") });
  };

  onToggleChange = (checked) => {



    let _payableAmt = 0;
    let _reverseChargeTaxAmt = 0;
    let _totalGstAmt = 0;
    let _dueAmount = 0;
    this.state.selectedInvoice.map((data) => {
      data.items.map((item) => {
        _totalGstAmt += item.tax_value ? item.tax_value : 0
      })
    });
    if(checked){
      _payableAmt = parseFloat(this.state.totalAmount) - parseFloat(_totalGstAmt);
      _reverseChargeTaxAmt = parseFloat(_totalGstAmt);
      _dueAmount = _payableAmt.toFixed(2)  - parseInt(this.state.receivedAmount)
    }else {
      _dueAmount = this.state.totalAmount  - parseInt(this.state.receivedAmount)
    }

    this.setState({
      reverseChargeStatus: checked,
      dueAmount: _dueAmount,
      reverseChargeTax: _reverseChargeTaxAmt.toFixed(2),
      payableAmount: _payableAmt.toFixed(2),
    });
  };

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
          <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
          >
            <span className="iti__party-name">{_timeInvoice}</span>
          </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";


    if (this.state.selectedInvoice.length > 0) {
      _hideRows = "";


    } else {
      if (this.state.totalAmount > 0) {
        _hideRows = "";


      }
    }

    let _disableTrans = false;
    if (this.state.viaAgainst > 0) {
      _disableTrans = true;
    }

    let _readonlyPaidInput = false;

    if (this.state.isMakePayment == true) {


      _disableTrans = true;
      _readonlyPaidInput = true;
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    /*For Low Stock Enable */
    let _finalStockHtml = "";
    let _lowStokItemName = this.state.lowStokItemName;
    if (this.state.lowStokItemName.length == 1) {
      _finalStockHtml = [];
      _finalStockHtml.push(<li>{_lowStokItemName}</li>);
    }
    if (this.state.lowStokItemName.length > 1) {
      const products = this.state.lowStokItemName;
      _finalStockHtml = [];
      products.forEach((product) => {
        _finalStockHtml.push(<li>{product}</li>);
      });
    }
    /*End Low Stock Enable */

    /*let _handleParamItemPage = this.props.location.search;
        _handleParamItemPage = _handleParamItemPage.split('?auth=');
        let _transId = _handleParamItemPage[1] ? _handleParamItemPage[1] : 0;*/

    let _transId = this.state.transactionId;
    let _colorBal = "text-normal";
    if (this.state.partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (this.state.partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }

    return (
        <React.Fragment>
          <div className="content">
            <div className="content-header">
              <div className="header-section">
                {/* <h2 className="content-heading">Manage Business</h2> */}
                <h1 className="content-heading">
                  <i className="gi gi-direction"></i>Add / Edit Debit Note / Purchase
                  Return
                  <br />
                </h1>
              </div>
            </div>
            <nav className="breadcrumb push">
              <a
                  className="breadcrumb-item"
                  href={global.webUrl + "business/dashboard/"}
              >
                Dashboard
              </a>
              <a
                  className="breadcrumb-item"
                  href={global.webUrl + "business/manage-purchase-return/"}
              >
                Manage Debit Note / Purchase Return List
              </a>
              <span className="breadcrumb-item active">
              Add / Edit Debit Note / Purchase Return{" "}
            </span>
              <a
                  className="breadcrumb-item"
                  href={global.webUrl + "business/add-purchase-debit-note/"}
                  target="_blank"
              >
                <i className="fa fa-plus"> (Add More)</i>
              </a>
            </nav>

            <div className="block">
              <div className="row">
                <div className="col-md-6">
                  <div className="block block-transparent">
                    <div className="block-content" id="formSideLeft">
                      <div className="form-group row">
                        <div className="col-6">
                          <div
                              className="form-material open"
                              onMouseLeave={this.onMouseLeaveHandler.bind(
                                  this,
                                  "I"
                              )}
                          >
                            <input
                                type="text"
                                className="form-control search-box"
                                onChange={this.state.transactionId == 0 ? this.onPartyFilterName : undefined}
                                onClick={this.state.transactionId == 0 ? this.manageShowPartyFilter.bind(this, "I") : undefined}
                                onKeyDown={this.state.transactionId == 0 ? this.manageShowPartyFilter.bind(
                                    this,
                                    "I"
                                ) : undefined}
                                placeholder="Search Party Name Here..."
                                autoComplete="off"
                                name="party_name"
                                id="party_name"
                                value={this.state.party_name}
                                readOnly={_disableTrans}
                            />

                            <label htmlFor="login-username">
                              Party Name <span className="text-danger">*</span>
                            </label>
                            <ul
                                className={
                                  this.state._partyReg +
                                  " iti__party-list iti__party-list--dropup"
                                }
                                id="country-listbox"
                                aria-expanded="false"
                                role="listbox"
                                aria-activedescendant="iti-item-ad"
                            >
                              {this.state.viaAgainst > 0
                                  ? ""
                                  : this.getReadyPartyList()}
                            </ul>
                            <div
                                id="party_name-error"
                                className="animated fadeInDown"
                            >
                              {errors.party_name.length > 0 && (
                                  <span className="error">{errors.party_name}</span>
                              )}
                            </div>

                            <div className={_colorBal}>
                              {this.state.party_name!="" &&
                              this.state.is_party!="No"
                                  ? "Bal : " +
                                  numberFormat(
                                      Math.abs(this.state.partyRemainBalance)
                                  )
                                  : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                                type="text"
                                className="form-control search-box"
                                placeholder="Phone Number"
                                autoComplete="off"
                                name="party_contact_no"
                                id="party_contact_no"
                                value={this.state.party_contact_no}
                                onChange={this.handleChange}
                                maxLength="13"
                            />
                            <label htmlFor="login-username">Phone Number</label>
                            <span className="iris_error_txt">
                            {errors.party_contact_no.length > 0 && (
                                <span className="error">
                                {errors.party_contact_no}
                              </span>
                            )}
                          </span>
                          </div>
                        </div>
                        {_businessSetting.Tran_BillingNameParty == 1 && (
                            <div className="col-6">
                              <div className="form-material open">
                                <input
                                    type="text"
                                    className="form-control search-box"
                                    placeholder="Billing Name Here..."
                                    autoComplete="off"
                                    name="billing_name"
                                    id="billing_name"
                                    value={this.state.billing_name}
                                    onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">Billing Name</label>
                              </div>
                            </div>
                        )}
                        {_businessSetting.ReverseCharge == 1 && (
                            <div
                                className="col-6"
                                style={{ padding: "8px 0 0 10px" }}
                            >
                              <div>
                                {/*<ToggleSwitch
                                    txtLeft=""
                                    txtRight="Reverse Charge"
                                    small
                                    id="newsletter"
                                    checked={this.state.reverseChargeStatus}
                                    onChange={this.onToggleChange}
                                />*/}
                                {/* <p>Newsletter: {String(this.state.reverseChargeStatus)}</p> */}
                              </div>
                            </div>
                        )}
                      </div>
                      <div
                          className="form-group row "
                          style={{ margin: "0 0 0 0" }}
                      >
                        <div className="col-8 form-material" id="paddingOff">
                          <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="val-username"
                          >
                            Billing Address <span className="text-danger">*</span>
                          </label>
                          <br />
                          <br />
                        </div>
                        <div className="col-8 form-material" id="paddingOff">
                          <input
                              type="text"
                              className="form-control"
                              id="billingAddress"
                              name="billingAddress"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Billing Address"
                              autoComplete="off"
                              value={this.state.billingAddress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="block block-transparent">
                    <div className="block-content">
                      <div className="" id="formSideRight">
                        {_businessSetting.InvoiceBillNo == 1 && (
                            <div className="form-group row">
                              <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                              >
                                Return No. <span className="text-danger">*</span>
                              </label>
                              <div className="col-lg-3 form-material" id="paddingOff">
                                <select
                                    className="form-control "
                                    id="selPrefix"
                                    name="selPrefix"
                                    required="required"
                                    onChange={this.handleChange}
                                >
                                  {this.state.prefixD == 1 && (
                                      <option value="0" selected="selected">
                                        {" "}
                                        None
                                      </option>
                                  )}
                                  {this.state.prefixArray.map((prefixData, index) => {
                                    let _selPrefix = "";
                                    if (this.state.transactionId == 0) {
                                      if (
                                          prefixData.prefix == this.state.prefixName
                                      ) {
                                        _selPrefix = "selected";
                                      }
                                    } else {
                                      if (
                                          this.state.prefixId == prefixData.prefix_id
                                      ) {
                                        _selPrefix = "selected";
                                      }
                                    }
                                    return (
                                        <option
                                            key={index}
                                            value={prefixData.prefix_id}
                                            selected={_selPrefix}
                                            data-prefixName={prefixData.prefix}
                                            data-prefixId={prefixData.prefix_id}
                                        >
                                          {prefixData.prefix}
                                        </option>
                                    );
                                  })}
                                  <option value="ADDPREFIX"> Add New Prefix</option>
                                </select>
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div
                                  className="col-lg-4 form-material"
                                  id="paddingOff"
                              >
                                <input
                                    type="text"
                                    className="form-control"
                                    id="invoiceNoReturn"
                                    name="invoiceNoReturn"
                                    required="required"
                                    onChange={this.handleChange}
                                    placeholder="Enter a Return No."
                                    autoComplete="off"
                                    value={this.state.invoiceNoReturn}
                                />
                                <div
                                    id="invoiceNoReturn-error"
                                    className="animated fadeInDown"
                                >
                                  {errors.invoiceNoReturn.length > 0 && (
                                      <span className="error">
                                  {errors.invoiceNoReturn}
                                </span>
                                  )}
                                </div>
                              </div>
                            </div>
                        )}

                        {/*<div className="form-group row">
                          <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                          >
                            Invoice Date<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8 form-material" id="paddingOff">
                            {this.isDateRefresh()}
                          </div>
                        </div>*/}
                        {/*{_businessSetting.Tran_AddTime == 1 && (
                            <div className="form-group row">
                              <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                              >
                                Time
                              </label>
                              <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                                  onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                                      this,
                                      "I"
                                  )}
                              >
                                <input
                                    readOnly={true}
                                    type="text"
                                    className="form-control search-box"
                                    onChange={this.onTimeFilterName}
                                    onClick={this.manageShowTimeFilter.bind(
                                        this,
                                        "I"
                                    )}
                                    onKeyDown={this.manageShowTimeFilter.bind(
                                        this,
                                        "I"
                                    )}
                                    placeholder="Search Party Name Here..."
                                    autoComplete="off"
                                    name="invoiceTime"
                                    id="invoiceTime"
                                    value={this.state.invoiceTime}
                                />
                                <ul
                                    className={
                                      this.state._timeReg +
                                      " iti__party-list iti__party-list--dropup"
                                    }
                                    id="country-listbox"
                                    aria-expanded="false"
                                    role="listbox"
                                    aria-activedescendant="iti-item-ad"
                                >
                                  {this.getTimeSlotList()}
                                </ul>
                              </div>
                            </div>
                        )}*/}
                        <div className="form-group row">
                          <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                          >
                            Return Date<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8 form-material" id="paddingOff">
                            {this.isDateReturnRefresh()}
                          </div>
                        </div>
                        {_businessSetting.SateOfSupply == 1 && (
                            <div className="form-group row">
                              <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                              >
                                State of Supply
                              </label>
                              <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                              >
                                <select
                                    className="form-control "
                                    id="countryState"
                                    name="countryState" 
                                    onChange={this.handleChange}
                                >
                                  <option value=""> None</option>
                                  {this.state.stateArray.map((item, index) => {
                                    let _sel = "";
                                    if (this.state.state == item.name) {
                                      _sel = "selected";
                                    }
                                    return (
                                        <option
                                            key={index}
                                            value={item.name}
                                            selected={_sel}
                                        >
                                          {item.name}
                                        </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                        )}
                        <div className="form-material open"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.invoiceArray.length > 0 && (
                  <QuickTransactionSaleInvoiceSection
                      invoiceArray={this.state.invoiceArray}
                      selectedInvoice={this.state.selectedInvoice}
                      isEditMode={this.state.transactionId > 0}
                      isConvert={this.state.isConvert}
                      transactionType={2}
                      _businessSetting={_businessSetting}
                      callBackItem={(
                          selectedInvoice, _totalAmount
                      ) => {
                        let transactionWiseTaxId = 0;
                        let totalTransTaxAmount = 0;
                        let reverseChargeStatus = 0;
                        let reverseChargeTax = 0;
                        let payableAmount = 0;
                        let totalAmount = 0;
                        let paidAmount = 0;
                        //let _totalGstAmt = 0;
                        selectedInvoice.map((data) => {
                          transactionWiseTaxId = data.transactionWiseTaxId;
                          totalTransTaxAmount += data.totalTransTaxAmount ? parseFloat(data.totalTransTaxAmount) : 0.0;
                          reverseChargeStatus = data.totalSummary.reverseChargeStatus;
                          reverseChargeTax = parseFloat(data.totalSummary.reverseChargeTax);
                          payableAmount = parseFloat(data.totalSummary.payableAmount);
                          paidAmount = parseFloat(data.totalSummary.paidAmount);
                          //totalAmount = parseFloat(data.totalSummary.totalAmount);
                          //dueAmount = parseFloat(data.totalSummary.dueAmount);
                          paidAmount = paidAmount/data.items.length;
                          paidAmount = paidAmount * data.items.filter((item) => item.isSelect).length;
                          data.items.map((item, index) => {
                            if(item.isSelect){
                              //totalAmount += parseFloat(item.total_amount);
                              totalAmount += (item.rate * item.newQuantity);
                              if(item.sale_pruchase_tax_type == 'With Tax'){
                                let costPrice = (item.rate * item.newQuantity / (100 + parseFloat(item.tax_value))) * 100;
                                //_totalGstAmt += costPrice * item.tax_value / 100;
                                //_totalGstAmt += item.tax_value
                              }else{
                                //_totalGstAmt += ((item.rate * item.newQuantity * item.tax_value / 100 ))
                                //_totalGstAmt += item.tax_value
                              }
                            }
                          })
                        });
                        let _selectedInvoice = selectedInvoice.map((data) => {
                          let SelectedQty = 0;
                          let qty = 0;
                          data.items.filter((data) => data.isSelect).map((item) => {
                            SelectedQty += item.newQuantity ? parseInt(item.newQuantity) : 0
                          });
                          data.items.map((item) => {
                            qty += item.quantity ? parseInt(item.quantity) : 0
                          });
                          let totalSummary = {...data.totalSummary};
                          totalSummary.totalSelectedInvQty = SelectedQty;
                          totalSummary.totalQty = qty;
                          return({
                            ...data,
                            totalSummary: totalSummary
                          })
                        });

                        let _total = parseFloat(totalAmount) + totalTransTaxAmount;


                        //let _dueAmount = _total  - parseInt(paidAmount);
                        let _dueAmount = _total;
                        if(reverseChargeStatus == 1){
                          _dueAmount = _total - reverseChargeTax;
                        }
                        _dueAmount = _dueAmount  - parseInt(paidAmount);

                        this.setState({
                          selectedInvoice: _selectedInvoice,
                          totalAmount: _total,
                          receivedAmount: paidAmount,
                          dueAmount: _dueAmount.toFixed(2),
                          reverseChargeTax: reverseChargeTax.toFixed(2),
                          payableAmount: payableAmount.toFixed(2),
                          transactionWiseTaxId: transactionWiseTaxId,
                          totalTransTaxAmount: totalTransTaxAmount,
                          reverseChargeStatus: reverseChargeStatus == 1,
                          TransAdditionalCharge1: selectedInvoice[0].TransAdditionalCharge1,
                          TransAdditionalCharge2: selectedInvoice[0].TransAdditionalCharge2,
                          TransAdditionalCharge3: selectedInvoice[0].TransAdditionalCharge3,
                          TransportDetailField1: selectedInvoice[0].TransportDetailField1,
                          TransportDetailField2: selectedInvoice[0].TransportDetailField2,
                          TransportDetailField3: selectedInvoice[0].TransportDetailField3,
                          TransportDetailField4: selectedInvoice[0].TransportDetailField4,
                          TransportDetailField5: selectedInvoice[0].TransportDetailField5,
                          TransportDetailField6: selectedInvoice[0].TransportDetailField6,
                          additional_value_1: selectedInvoice[0].additional_value_1,
                          additional_value_2: selectedInvoice[0].additional_value_2,
                          additional_value_3: selectedInvoice[0].additional_value_3,
                          additional_value_date: moment(selectedInvoice[0].additional_value_date).format("MM/DD/YYYY"),
                        });
                      }}
                  />
              )}
              {/* Sale Invoice Data Here by other pages */}

              {
                this.state.selectedInvoice.length > 0 &&
                <div className="row">
                  <div className="col-md-6">
                    <div className="block block-transparent">
                      <div className="block-content" id="formSideLeft">
                        <div className="form-group row">
                          <div className="col-12">
                            <div className="form-material open" id="paddingOff">
                              <select
                                  className="col-8 form-control"
                                  defaultValue={
                                    this.state.bankId > 0
                                        ? this.state.bankId
                                        : this.state.paymentType
                                  }
                                  id="paymentType"
                                  name="paymentType"
                                  onChange={this.handleChange}
                              >
                                {this.settleAmountType()}
                              </select>
                            </div>
                            {this.state.paymentType!="Cash" && (
                                <div className="form-material open">
                                  <div
                                      className="col-8 form-material"
                                      id="paddingOff"
                                  >
                                    <input
                                        type="text"
                                        className="form-control"
                                        required="required"
                                        id="paymentRefNo"
                                        name="paymentRefNo"
                                        value={this.state.paymentRefNo}
                                        onChange={this.handleChange}
                                        placeholder="Enter Reference No.."
                                        autoComplete="off"
                                    />
                                  </div>
                                </div>
                            )}

                            <div className="form-material open">
                              <div className="col-12 form-material" id="paddingOff">
                                <input
                                    type="text"
                                    className="form-control"
                                    required="required"
                                    id="description"
                                    name="description"
                                    onChange={this.handleChange}
                                    placeholder="Enter a description"
                                    autoComplete="off"
                                    value={this.state.description}
                                />
                              </div>
                            </div>
                            <div className="form-material open">
                              <div className="col-8 form-material" id="paddingOff">
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ width: "60%", display: "inline-block" }}
                                    autoComplete="false"
                                    className="form-control"
                                    id="descImage"
                                    name="descImage"
                                    onChange={this.onChangeFileUploadHandler}
                                />
                                {this.previewImagesListing()}
                              </div>
                            </div>
                            {_businessSetting.PrintOriginalDuplicate == 1 && (
                                <div className="form-material open">
                                  <label
                                      style={{ textAlign: "right" }}
                                      className="col-form-label"
                                      htmlFor="totalAmount"
                                  >
                                    Original / Duplicate Setting
                                  </label>
                                  <div
                                      className="col-8 form-material"
                                      id="paddingOff"
                                  >
                                    <select
                                        className="col-8 form-control"
                                        id="settingType"
                                        name="settingType"
                                        onChange={this.handleChange}
                                    >
                                      {optionsData.map(({ value, label }, index) => {
                                        let _selSet = "";
                                        if (this.state.settingType == value) {
                                          _selSet = "selected";
                                        }
                                        return (
                                            <option
                                                key={index}
                                                value={value}
                                                selected={_selSet}
                                            >
                                              {label}
                                            </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="block block-transparent">
                      <div className="block-content">
                        <div className="col-md-12" id="formSideRight">
                          {(this.state.reverseChargeStatus == true ||
                              this.state.reverseChargeStatus == 1) && (
                              <div id="reverseChargeData">
                                <div className="form-group row">
                                  <label
                                      style={{ textAlign: "right" }}
                                      className="col-lg-4 col-form-label"
                                      htmlFor="reverseChargeTax"
                                  >
                                    Reverse Charge Tax
                                  </label>
                                  <div
                                      className="col-lg-8 form-material"
                                      id="paddingOff"
                                  >
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className={"form-control"}
                                        id="reverseChargeTax"
                                        name="reverseChargeTax"
                                        placeholder=""
                                        value={this.state.reverseChargeTax}
                                        autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                      style={{ textAlign: "right" }}
                                      className="col-lg-4 col-form-label"
                                      htmlFor="payableAmount"
                                  >
                                    Payable Amount
                                  </label>
                                  <div
                                      className="col-lg-8 form-material"
                                      id="paddingOff"
                                  >
                                    <input
                                        type="text"
                                        readOnly={true}
                                        className={"form-control"}
                                        id="payableAmount"
                                        name="payableAmount"
                                        placeholder=""
                                        value={this.state.payableAmount}
                                        autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                          )}
                          <div className="form-group row">
                            <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalTransTaxAmount"
                            >
                              Transaction Tax Amount
                            </label>
                            <div className="col-lg-8 form-material" id="paddingOff">
                              <input
                                  type="text"
                                  readOnly={true}
                                  className="form-control"
                                  id="totalTransTaxAmount"
                                  name="totalTransTaxAmount"
                                  placeholder=""
                                  value={this.state.totalTransTaxAmount}
                                  autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                            >
                              Total Amount
                            </label>
                            <div className="col-lg-8 form-material" id="paddingOff">
                              <input
                                  type="text"
                                  readOnly="readOnly"
                                  className="form-control"
                                  id="totalAmount"
                                  name="totalAmount"
                                  placeholder=""
                                  value={this.state.totalAmount}
                                  autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className={_hideRows}>
                            <div className="form-group row">
                              <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="receivedAmount"
                              >
                                Received Amount
                              </label>
                              <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                              >
                                <input
                                    type="number"
                                    readOnly={_disableTrans}
                                    className="form-control"
                                    id="receivedAmount"
                                    name="receivedAmount"
                                    placeholder=""
                                    value={this.state.receivedAmount}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    onWheel={() => document.activeElement.blur()}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="dueAmount"
                              >
                                Balance Due
                              </label>
                              <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                              >
                                <input
                                    type="text"
                                    readOnly="readOnly"
                                    className="form-control"
                                    id="dueAmount"
                                    name="dueAmount"
                                    placeholder=""
                                    value={this.state.dueAmount}
                                    autoComplete="off"
                                />
                              </div>
                            </div>
                            <div >
                              {/*Additional Charges */}
                              {_businessSetting.TransactionAdditionalChargeEnable ==
                              1 && (
                                  <div id="taxDisData">
                                    {_businessSetting.TransactionAdditionalCharge1Enable ==
                                    1 && (
                                        <div className="form-group row">
                                          <label
                                              style={{ textAlign: "right" }}
                                              className="col-lg-4 col-form-label"
                                              htmlFor="TransAdditionalCharge1"
                                          >
                                            {
                                              _businessSetting.TransactionAdditionalCharge1Name
                                            }
                                          </label>
                                          <div
                                              className="col-lg-8 form-material"
                                              id="paddingOff"
                                          >
                                            <input
                                                type="text"
                                                readOnly={true}
                                                className="form-control"
                                                id="TransAdditionalCharge1"
                                                name="TransAdditionalCharge1"
                                                placeholder=""
                                                value={this.state.TransAdditionalCharge1}
                                                autoComplete="off"
                                            />
                                          </div>
                                        </div>
                                    )}

                                    {_businessSetting.TransactionAdditionalCharge2Enable ==
                                    1 && (
                                        <div className="col-md-12" id="formSideRight">
                                          <div className="form-group row">
                                            <label
                                                style={{ textAlign: "right" }}
                                                className="col-lg-4 col-form-label"
                                                htmlFor="TransAdditionalCharge2"
                                            >
                                              {
                                                _businessSetting.TransactionAdditionalCharge2Name
                                              }
                                            </label>
                                            <div
                                                className="col-lg-8 form-material"
                                                id="paddingOff"
                                            >
                                              <input
                                                  type="text"
                                                  readOnly={true}
                                                  id="TransAdditionalCharge2"
                                                  name="TransAdditionalCharge2"
                                                  placeholder=""
                                                  className="form-control"
                                                  value={this.state.TransAdditionalCharge2}
                                                  autoComplete="off"
                                                  style={{
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                  }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                    )}

                                    {_businessSetting.TransactionAdditionalCharge3Enable ==
                                    1 && (
                                        <div className="col-md-12" id="formSideRight">
                                          <div className="form-group row">
                                            <label
                                                style={{ textAlign: "right" }}
                                                className="col-lg-4 col-form-label"
                                                htmlFor="TransAdditionalCharge3"
                                            >
                                              {
                                                _businessSetting.TransactionAdditionalCharge3Name
                                              }
                                            </label>
                                            <div
                                                className="col-lg-8 form-material"
                                                id="paddingOff"
                                            >
                                              <input
                                                  type="text"
                                                  readOnly={true}
                                                  className="form-control"
                                                  id="TransAdditionalCharge3"
                                                  name="TransAdditionalCharge3"
                                                  placeholder=""
                                                  value={this.state.TransAdditionalCharge3}
                                                  autoComplete="off"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                    )}
                                  </div>
                              )}
                              {/*End Additional Charges */}
                            </div>
                          </div>
                          {_businessSetting.TransactionAdditionalFieldCN > 0 && (
                              <div id="additionFields">
                                {_businessSetting.additional_field_check1 > 0 && (
                                    <div className="form-group row">
                                      <label
                                          style={{ textAlign: "right" }}
                                          className="col-lg-4 col-form-label"
                                          htmlFor="addF1"
                                      >
                                        {_businessSetting.additional_field_name1}
                                      </label>
                                      <div
                                          className="col-lg-8 form-material"
                                          id="paddingOff"
                                      >
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="additional_value_1"
                                            name="additional_value_1"
                                            placeholder=""
                                            value={this.state.additional_value_1}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                )}

                                {_businessSetting.additional_field_check2 > 0 && (
                                    <div className="form-group row">
                                      <label
                                          style={{ textAlign: "right" }}
                                          className="col-lg-4 col-form-label"
                                          htmlFor="addF2"
                                      >
                                        {_businessSetting.additional_field_name2}
                                      </label>
                                      <div
                                          className="col-lg-8 form-material"
                                          id="paddingOff"
                                      >
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="additional_value_2"
                                            name="additional_value_2"
                                            placeholder=""
                                            value={this.state.additional_value_2}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                )}

                                {_businessSetting.additional_field_check3 > 0 && (
                                    <div className="form-group row">
                                      <label
                                          style={{ textAlign: "right" }}
                                          className="col-lg-4 col-form-label"
                                          htmlFor="addF3"
                                      >
                                        {_businessSetting.additional_field_name3}
                                      </label>
                                      <div
                                          className="col-lg-8 form-material"
                                          id="paddingOff"
                                      >
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="additional_value_3"
                                            name="additional_value_3"
                                            placeholder=""
                                            value={this.state.additional_value_3}
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                )}

                                {_businessSetting.additional_field_date_field_check >
                                0 && (
                                    <div className="form-group row">
                                      <label
                                          style={{ textAlign: "right" }}
                                          className="col-lg-4 col-form-label"
                                          htmlFor="addFDate"
                                      >
                                        {
                                          _businessSetting.additional_field_date_field_name
                                        }
                                      </label>
                                      <div
                                          className="col-lg-8 form-material"
                                          id="paddingOff"
                                      >
                                        {this.isAdditionalDateRefresh()}
                                      </div>
                                    </div>
                                )}
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {_businessSetting.TransportationDetailsField1Enable == 1 && (
                      // Transportation Detail Here
                      <div className="col-md-12">
                        <div className="block block-transparent">
                          <div className="block-content">
                            <h5 className="content-heading">
                              Transportation Details
                            </h5>
                            <div className="block">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="block block-transparent">
                                    <div className="block-content" id="formSideLeft">
                                      <div className="form-group row">
                                        {_businessSetting.TransportationDetailsField1Enable ==
                                        1 && (
                                            <div className="col-4">
                                              <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    placeholder={
                                                      _businessSetting.TransportationDetailsField1Name
                                                    }
                                                    autoComplete="off"
                                                    name="TransportDetailField1"
                                                    id="TransportDetailField1"
                                                    value={
                                                      this.state.TransportDetailField1
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">
                                                  {
                                                    _businessSetting.TransportationDetailsField1Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                        )}
                                        {_businessSetting.TransportationDetailsField2Enable ==
                                        1 && (
                                            <div className="col-4">
                                              <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    placeholder={
                                                      _businessSetting.TransportationDetailsField2Name
                                                    }
                                                    autoComplete="off"
                                                    name="TransportDetailField2"
                                                    id="TransportDetailField2"
                                                    value={
                                                      this.state.TransportDetailField2
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">
                                                  {
                                                    _businessSetting.TransportationDetailsField2Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                        )}
                                        {_businessSetting.TransportationDetailsField3Enable ==
                                        1 && (
                                            <div className="col-4">
                                              <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    placeholder={
                                                      _businessSetting.TransportationDetailsField3Name
                                                    }
                                                    autoComplete="off"
                                                    name="TransportDetailField3"
                                                    id="TransportDetailField3"
                                                    value={
                                                      this.state.TransportDetailField3
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">
                                                  {
                                                    _businessSetting.TransportationDetailsField3Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                        )}
                                        {_businessSetting.TransportationDetailsField4Enable ==
                                        1 && (
                                            <div className="col-4">
                                              <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    placeholder={
                                                      _businessSetting.TransportationDetailsField4Name
                                                    }
                                                    autoComplete="off"
                                                    name="TransportDetailField4"
                                                    id="TransportDetailField4"
                                                    value={
                                                      this.state.TransportDetailField4
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">
                                                  {
                                                    _businessSetting.TransportationDetailsField4Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                        )}
                                        {_businessSetting.TransportationDetailsField5Enable ==
                                        1 && (
                                            <div className="col-4">
                                              <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    placeholder={
                                                      _businessSetting.TransportationDetailsField5Name
                                                    }
                                                    autoComplete="off"
                                                    name="TransportDetailField5"
                                                    id="TransportDetailField5"
                                                    value={
                                                      this.state.TransportDetailField5
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">
                                                  {
                                                    _businessSetting.TransportationDetailsField5Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                        )}
                                        {_businessSetting.TransportationDetailsField6Enable ==
                                        1 && (
                                            <div className="col-4">
                                              <div className="form-material open">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    placeholder={
                                                      _businessSetting.TransportationDetailsField6Name
                                                    }
                                                    autoComplete="off"
                                                    name="TransportDetailField6"
                                                    id="TransportDetailField6"
                                                    value={
                                                      this.state.TransportDetailField6
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">
                                                  {
                                                    _businessSetting.TransportationDetailsField6Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  )}
                  <div className="col-md-12">
                    <div className="row justify-content-center text-right">
                      <div className="col-lg-9">
                        <button
                            type="submit"
                            onClick={this.clickToAddSale}
                            disabled={isDisabled}
                            className="btn btn-alt-primary mb-10 mx-5"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }

            </div>

            {/* Modal Edit Party Details Begins */}
            <Modal
                isOpen={this.state.modalIsOpen_SP}
                ariaHideApp={false}
                size="sm"
                aria-labelledby="modal-popout"
                onRequestClose={this.closeModalHandler}
                id="modalAdjustNewParty"
            >
              <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                  <div className="block block-themed block-transparent mb-0">
                    <div className="block-header bg-primary-dark">
                      <h3 className="block-title">Add New Party</h3>
                      <div className="block-options">
                        <button
                            type="button"
                            className="btn-block-option"
                            onClick={this.closeModalHandler.bind(
                                this,
                                "modalAdjustNewParty"
                            )}
                        >
                          <i className="si si-close"></i>
                        </button>
                      </div>
                    </div>
                    <div className="block-content">
                      <QuickAddNewParty
                          isRedirectTo={"business/add-purchase-debit-note/"}
                      />
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-alt-secondary"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalAdjustNewParty"
                        )}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Modal End Edit Party Details*/}

            {/* Modal Add Bank Details Begins */}
            <Modal
                isOpen={this.state.modalIsOpen_SBANK}
                ariaHideApp={false}
                size="sm"
                aria-labelledby="modal-popout"
                onRequestClose={this.closeModalHandler}
                id="modalAdjustNewBank"
            >
              <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                  <div className="block block-themed block-transparent mb-0">
                    <div className="block-header bg-primary-dark">
                      <h3 className="block-title">Add New Bank</h3>
                      <div className="block-options">
                        <button
                            type="button"
                            className="btn-block-option"
                            onClick={this.closeModalHandler.bind(
                                this,
                                "modalAdjustNewBank"
                            )}
                        >
                          <i className="si si-close"></i>
                        </button>
                      </div>
                    </div>
                    <div className="block-content">
                      <QuickAddNewBankDetail
                          isRedirectTo={"business/add-purchase-debit-note/"}
                      />
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-alt-secondary"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalAdjustNewBank"
                        )}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Modal Add Bank Details*/}

            {/* Modal Low Stock Item Begins */}
            <Modal
                isOpen={this.state.modalIsOpen_LowStock}
                ariaHideApp={false}
                size="sm"
                aria-labelledby="modal-popout"
                onRequestClose={this.closeModalHandler}
                id="modalLowStockItem"
            >
              <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                  <div className="block block-themed block-transparent mb-0">
                    <div className="block-header bg-primary-dark">
                      <h3 className="block-title">
                        <i
                            className="fa fa-warning"
                            style={{ color: "yellow" }}
                        ></i>
                        &nbsp;&nbsp;Low Stock
                      </h3>
                      <div className="block-options">
                        <button
                            type="button"
                            className="btn-block-option"
                            onClick={this.closeModalHandlerLowStock.bind(
                                this,
                                "modalLowStockItem"
                            )}
                        >
                          <i className="si si-close"></i>
                        </button>
                      </div>
                    </div>
                    <div className="block-content">
                      <p>
                        Your stock for following item(s) is not suifficient to
                        fulfil this purchase
                      </p>
                      <ul className="ml-50">
                        {this.state.lowStokItemName!="" && _finalStockHtml}
                      </ul>

                      <p>Do you really want to save this purchase?</p>
                      <p>
                        {" "}
                        <input
                            type="checkbox"
                            value="1"
                            onChange={this.handleChecked}
                        />
                        &nbsp;&nbsp;Don't show this again
                      </p>
                      <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={this.closeModalHandlerLowStock.bind(
                              this,
                              "modalLowStockItem"
                          )}
                      >
                        No
                      </button>

                      <button
                          type="button"
                          className="btn btn-alt-primary  ml-20"
                          onClick={this.SaveModalHandlerLowStock.bind(
                              this,
                              "modalLowStockItem"
                          )}
                      >
                        Yes
                      </button>
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-alt-secondary"
                        onClick={this.closeModalHandlerLowStock.bind(
                            this,
                            "modalLowStockItem"
                        )}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Modal Low Stock Item Details*/}

            {/* Modal Add New Prefix Details Begins */}
            <Modal
                isOpen={this.state.modalIsOpen_ADDPREFIX}
                ariaHideApp={false}
                size="sm"
                aria-labelledby="modal-popout"
                onRequestClose={this.closeModalHandler}
                id="modalAdjustADDPREFIX"
            >
              <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                  <div className="block block-themed block-transparent mb-0">
                    <div className="block-header bg-primary-dark">
                      <h3 className="block-title">Add New Prefix</h3>
                      <div className="block-options">
                        <button
                            type="button"
                            className="btn-block-option"
                            onClick={this.closeModalHandler.bind(
                                this,
                                "modalAdjustADDPREFIX"
                            )}
                        >
                          <i className="si si-close"></i>
                        </button>
                      </div>
                    </div>
                    <div className="block-content">
                      <QuickAddNewPrefix
                          callBack={(newPrefixId, newPrefixName) => {
                            this.setState({
                              prefixId: newPrefixId,
                              prefixName: newPrefixName,
                              modalIsOpen_ADDPREFIX: false,
                            });

                            this.businessPrefixList();
                          }}
                          transactionType="DEBIT NOTE"
                      />
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-alt-secondary"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalAdjustADDPREFIX"
                        )}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Modal Add New Prefix Details*/}
          </div>
        </React.Fragment>
    );
  }
}
