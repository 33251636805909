import React, { Component } from "react";
import QuickGeneralSetting from "./childFolder/generalSettingSection";
import QuickTransactionSetting from "./childFolder/transactionSettingSection";
import QuickPartySetting from "./childFolder/partySettingSection";
import QuickItemSetting from "./childFolder/itemSettingSection";
import QuickPrintSetting from "./childFolder/printSettingSection";
import QuickTaxSetting from "./childFolder/taxSettingSection";
import QuickTransactionSMSSetting from "./childFolder/transactionSmsSetting";

let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

let _loader = "glb-ldr-prt active";

export default class CommonSettingPanel extends Component {
  constructor(props) {
    super(props);
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _companyId = _companySession.company_id;
    }

    this.state = {
      isLoaded: false,
      listTitle: "General",
    };
  }

  componentDidMount() {}

  manageTabList(value) {
    this.setState({ listTitle: value });
    if (value!="") {
      this.loadDataContentDirectory(value);
    }
  }

  loadDataContentDirectory(type) {
    //alert(this.state.listTitle+'----'+type);
    if (this.state.listTitle == "General") {
      return <QuickGeneralSetting />;
    } else if (this.state.listTitle == "Transaction") {
      return <QuickTransactionSetting />;
    } else if (this.state.listTitle == "Party") {
      return <QuickPartySetting />;
    } else if (this.state.listTitle == "Item") {
      return <QuickItemSetting />;
    } else if (this.state.listTitle == "Print") {
      return <QuickPrintSetting />;
    } else if (this.state.listTitle == "Taxes") {
      return <QuickTaxSetting />;
    } else if (this.state.listTitle == "Transaction SMS") {
      return <QuickTransactionSMSSetting />;
    }
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="block">
                <ul
                  className="nav nav-tabs nav-tabs-block"
                  data-toggle="tabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "General"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "General")}
                    >
                      <i className="si si-emoticon-smile mr-5 text-primary"></i>{" "}
                      General
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Transaction"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Transaction")}
                    >
                      <i className="si si-basket text-info"></i> Transaction
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Party"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Party")}
                    >
                      <i className="fa fa-users text-default"></i> Party
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Item"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Item")}
                    >
                      <i className="si si-pin text-warning"></i> Item
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Print"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Print")}
                    >
                      <i className="si si-printer text-info"></i> Print
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Taxes"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Taxes")}
                    >
                      <i className="fa fa-turkish-lira text-danger"></i> Taxes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Transaction SMS"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Transaction SMS")}
                    >
                      <i className="fa fa-sms text-info"></i> Transaction SMS
                    </a>
                  </li>
                </ul>
                <div className="block-content tab-content">
                  <div
                    className="tab-pane active"
                    id="btabs-static-home"
                    role="tabpanel"
                  >
                    <div className="block-header">
                      <h3 className="block-title">
                        # {this.state.listTitle} Setting
                      </h3>
                      {/* <div className="block-options">{this.buttonAddShow()}</div> */}
                    </div>

                    <div className="block-content">
                      {this.loadDataContentDirectory()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
