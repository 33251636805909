import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from 'react-modal';
import QuickPaymentHistory from './quickPaymentHistory';
import QuickPaymentPurchaseHistory from '../userPurchase/quickPurchasePaymentHistory';
import { PaginationControl } from 'react-bootstrap-pagination-control';

import ButtonPdf from '../htmlPdfCreation';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _businessSetting = {};


let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'invoice_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Date',
        },
    },
    {
        label: 'Invoice No.',
        field: 'invoice_no',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Invoice No.',
        },
    },
    {
        label: 'Party Name',
        field: 'party_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Party Name',
        },
    },
    {
        label: 'Type',
        field: 'sale_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Party Type',
        },
    },
    {
        label: 'Amount',
        field: 'total_amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Amount',
        },
    },
    {
        label: 'Balance Due',
        field: 'balance',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Balance Due',
        },
    },
    {
        label: 'Action',
        field: 'actionExtra',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';
export default class userSaleListPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _businessSetting = _UserSession.businessSetting;
            if (_businessSetting!=undefined || _businessSetting!=null) {
              
            }
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        if(_businessSetting.DueDatesPaymentTerms == 1){
            columns = [
                {
                    label: '#',
                    field: 'srNo',
                    width: 20,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': '#',
                    },
                },
                {
                    label: 'Date',
                    field: 'invoice_date',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Date',
                    },
                },
                {
                    label: 'Due Date',
                    field: 'due_date',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Due Date',
                    },
                },
                {
                    label: 'Invoice No.',
                    field: 'invoice_no',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Invoice No.',
                    },
                },
                {
                    label: 'Party Name',
                    field: 'party_name',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Party Name',
                    },
                },
                {
                    label: 'Type',
                    field: 'sale_type',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Party Type',
                    },
                },
                {
                    label: 'Amount',
                    field: 'total_amount',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Amount',
                    },
                },
                {
                    label: 'Balance Due',
                    field: 'balance',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Balance Due',
                    },
                },
                {
                    label: 'Action',
                    field: 'actionExtra',
                    width: 100,
                }
            ];
        }

        else if(_businessSetting.DueDatesPaymentTerms == 0){
            columns = [
                {
                    label: '#',
                    field: 'srNo',
                    width: 20,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': '#',
                    },
                },
                {
                    label: 'Date',
                    field: 'invoice_date',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Date',
                    },
                },
                {
                    label: 'Invoice No.',
                    field: 'invoice_no',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Invoice No.',
                    },
                },
                {
                    label: 'Party Name',
                    field: 'party_name',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Party Name',
                    },
                },
                {
                    label: 'Type',
                    field: 'sale_type',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Party Type',
                    },
                },
                {
                    label: 'Amount',
                    field: 'total_amount',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Amount',
                    },
                },
                {
                    label: 'Balance Due',
                    field: 'balance',
                    width: 150,
                    attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Balance Due',
                    },
                },
                {
                    label: 'Action',
                    field: 'actionExtra',
                    width: 100,
                }
            ];
        }
        this.state = {
            buttonTitle:'Add Sale',
            isLoaded : true,
            modalPaymentHistory : false,
            modalPaymentHistoryPurchase : false,
            transactionArray :[],
            transactionArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            name :  null,

            paidAmount:0,
            unpaidAmount:0,
            total:0,
            activePaymentHistory : '',
            trasactionHisId : 0,
            startDate : moment().startOf('month').toDate(),
            endDate : moment().endOf('month').toDate(),
            dateLabel : '',

            pageCount : 10,
            page : 1,
            totalCount  : 0,
            pageShow  : 0,
            setPage : 1,
            searchInvNo : '',
            errors: {
                name : '',
            }
        }
    }

    async componentDidMount ()
    {   
        this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount); 
    }
        
    getInvoiceSearch = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let _valueSend = value;
        this.setState({searchInvNo:_valueSend})
        if(_valueSend.length >= 3 || _valueSend == ''){
            this.getSaleListData(this.state.page,_valueSend,this.state.pageCount);
        }
    }
    getSaleListData(_page,searchInvSend,pageCount){
        let _transArray = [];
        let _paidAmt = 0;
        let _unpaidAmt = 0;
        let _totalAmt = 0;
        const requestData = JSON.stringify({
            business_id:this.state.businessId,
            company_id:this.state.company_id,
            type:"SALE",
            isDateFilter:true,
            start_date : this.state.startDate,
            end_date : this.state.endDate,
            pageCount: pageCount,
            search_inv_no:searchInvSend,
            page:_page
        });

        PostData(global.userSaleTransactionList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt});
            }
            else
            {
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    _transArray.map((itm, i) =>{
                        if(itm.trans_duplicate_normal === 'Normal'){
                            _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.balance);
                            _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.received_amount);
                            _totalAmt = parseFloat(_totalAmt) + parseFloat(itm.total_amount);
                            let _payHis = itm.paymentHistory ? itm.paymentHistory : [];
                            if(_payHis.length >= 1){
                                _payHis.map((hisData, i) =>{
                                    _paidAmt = parseFloat(_paidAmt) + parseFloat(hisData.received_amount);
                                });
                            }
                        }
                    });
                }
                //_totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);

                this.setState({
                    transactionArrayFilter:_transArray,
                    itemArray:_transArray,
                    isLoaded:true,
                    paidAmount:_paidAmt,
                    unpaidAmount:_unpaidAmt,
                    total:_totalAmt,
                    totalCount:responseJson.totalCount,
                    pageShow:responseJson.pageShow
                });
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    /*confirmAlert({
        customUI: ({ onClose }) => {
            return (
            <div className='custom-ui'>
                <h1>Are you sure?</h1>
                <p>You want to delete this file?</p>
                <button onClick={onClose}>No</button>
                <button
                onClick={() => {
                    this.handleClickDelete();
                    onClose();
                }}
                >
                Yes, Delete it!
                </button>
            </div>
            );
        }
        }); */


    loadTranscListing(){
        let dataList = [];
        let rows = [];
        
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.transactionArrayFilter;
            if(_transDetails.length > 0){
                let _i=0;
                _transDetails.map((value,index) => {
                    _i++;
                    let _balDue = value.balance;
                    let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
                    let _recvPayLink = ""
                    let _type = "&tType="+value.type;
                    let _editActionUrl = '/business/add-sale/?auth='+value.id+_type;
                    let _editDuplicateActionUrl = '/business/add-sale-duplicate/?auth='+value.id+'&tType=DUPLICATE';
                    if(value.type == 'SALE'){
                        _editActionUrl = '/business/add-sale/?auth='+value.id+_type;
                        _editDuplicateActionUrl = '/business/add-sale-duplicate/?auth='+value.id+'&tType=DUPLICATE';
                    }
                    else if(value.type == 'CREDIT NOTE'){
                        _editActionUrl = '/business/add-sale-credit-note/?auth='+value.id+"&tType=CREDIT NOTE";
                        //_editDuplicateActionUrl = '/business/add-sale-return-duplicate/?auth='+value.id;
                    }

                    let _actionExtra = '';
                    let _actionHistory = '';
                    
                    if(_balDue > 0 && value.cashCreditToggle == 0){
                        _recvPayLink = <Link className="dropdown-item" to={'/business/add-receive-payment-in/?auth='+value.id+_type}> Receive Payment </Link>
                    }
                    if(value.type == 'SALE'){
                        if((value.paymentHistory!='' || value.received_amount >0) && (value.payment_paid == 'PARTIAL' || value.payment_paid == 'PAID')){
                            _actionHistory = <Link className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToPaymentHistory.bind(this,value.id,value.paymentHistory,value.received_amount)}>
                                Payment History
                            </Link>
                        }
                        let _delB = <Link className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToDelete.bind(this,value.id,'Sale')}>
                        Delete</Link>;
                        if(value.isReturn){
                            _delB = <Link className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToDeleteWithReturn.bind(this,value.id,'Sale')}>
                            Delete</Link>;
                        }

                        if(_trans_duplicate_normal == 'Duplicate'){
                            _editActionUrl = '';
                            _actionHistory = '';
                            _recvPayLink = '';
                        }
                        _actionExtra = (
                            <div className="btn-group" role="group">
                                <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    {_trans_duplicate_normal == 'Normal' &&
                                    <Link className="dropdown-item" to={_editDuplicateActionUrl}>
                                        Duplicate
                                    </Link>
                                    }
                                    {_trans_duplicate_normal == 'Normal' &&
                                    <Link className="dropdown-item" to={_editActionUrl}>
                                        View/Edit Details
                                    </Link>
                                    }
                                    {_recvPayLink}
                                    {
                                        (!value.isReturn && _trans_duplicate_normal == 'Normal') &&
                                            <>
                                                {value.total_discount == 0 ?
                                                <Link className="dropdown-item" to={'/business/add-sale-credit-note?auth='+value.id+"&tType=CREDIT NOTE&isConvert=true"}>
                                                    Convert to Return
                                                </Link> :
                                                <a className="dropdown-item"
                                                    onClick={() => {
                                                        alert('You can\'t create a sales return for this sales invoice as there is a discount given. To create a sales return please go to the sales invoices section and remove the discount from this sales invoice. Thanks');
                                                    }}
                                                >
                                                    Convert to Return
                                                </a>}
                                            </>
                                    }
                                    {!value.isReturn && _delB}
                                    <Link className="dropdown-item" to={'/business/return-challan-pdf/?auth='+value.id} target="_blank">
                                        Preview as Delivery Challan
                                    </Link>
                                    
                                    {_actionHistory}
                                    <Link className="dropdown-item" to={'/business/download-pdf/?auth='+value.id} target="_blank">
                                        Download PDF
                                    </Link>
                                    <Link className="dropdown-item" to={'/business/download-pdf/?auth='+value.id} target="_blank">
                                        Preview
                                    </Link>
                                    <Link className="dropdown-item" to={'/business/download-pdf/?auth='+value.id} target="_blank">
                                        Print
                                    </Link>

                                    {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                                </div>
                            </div>
                        );
                    }
                    else if(value.type == 'CREDIT NOTE'){
                        if(value.paymentHistory!='' || value.received_amount >0){
                            _actionHistory = <Link className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToPaymentPurchaseHistory.bind(this,value.id,value.paymentHistory,value.received_amount)}>
                                Payment History
                            </Link>
                        }
                        _actionExtra = (
                            <div className="btn-group" role="group">
                                <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    {_trans_duplicate_normal == 'Normal' &&
                                    <Link className="dropdown-item" to={_editActionUrl}>
                                        View/Edit Details
                                    </Link>
                                    }
                                    <Link className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToDelete.bind(this,value.id,'CREDIT NOTE')}>
                                        Delete
                                    </Link>
                                    {_trans_duplicate_normal == 'Normal' &&
                                    <Link className="dropdown-item" to={'/business/add-make-payment-out/?auth='+value.id+"&tType=SALE"}>
                                        Make Payment
                                    </Link>
                                    }

                                    {_trans_duplicate_normal == 'Normal' &&
                                    _actionHistory
                                    }
                                    <Link className="dropdown-item" to={'/business/return-challan-pdf/?auth='+value.id} target="_blank">
                                        Download PDF
                                    </Link>
                                    <Link className="dropdown-item" to={'/business/return-challan-pdf/?auth='+value.id} target="_blank">
                                        Preview
                                    </Link>
                                    <Link className="dropdown-item" to={'/business/return-challan-pdf/?auth='+value.id} target="_blank">
                                        Print
                                    </Link>
                                    {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                                </div>
                            </div>
                        );
                    }
                    else{
                        _actionExtra = (
                            <div className="btn-group" role="group">
                                <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">

                                    {_trans_duplicate_normal == 'Normal' &&
                                    <Link className="dropdown-item" to={_editDuplicateActionUrl}>
                                        Duplicate
                                    </Link>
                                    }

                                    {_trans_duplicate_normal == 'Normal' &&
                                    <Link className="dropdown-item" to={'/business/add-make-payment-out/?auth='+value.id+"&tType=SALE"}>
                                        Make Payment
                                    </Link>
                                    }
                                    <Link className="dropdown-item" to={'/business/download-pdf/?auth='+value.id} target="_blank">
                                        Download PDF
                                    </Link>
                                    <Link className="dropdown-item" to={'/business/download-pdf/?auth='+value.id} target="_blank">
                                        Preview
                                    </Link>
                                    <Link className="dropdown-item" to={'/business/download-pdf/?auth='+value.id} target="_blank">
                                        Print
                                    </Link>
                                    {/* <ButtonPdf transactionId={value.id}/> */}
                                </div>
                            </div>
                        );
                    }

                    let _saleType = <span className="badge badge-warning">UNPAID</span>;
                    if(value.payment_paid == 'PARTIAL'){
                        _saleType = <span className="badge badge-info">{value.payment_paid}</span>;
                    }
                    else if(value.payment_paid == 'PAID'){
                        _saleType = <span className="badge badge-success">{value.payment_paid}</span>;
                    }
                    
                    var msDiff = new Date(value.payment_term_due_date).getTime() - new Date().getTime();    //Future date - current date
                    var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));
                    //console.log("==daysTill==",daysTill);
                    if(daysTill < -1 && value.payment_term_id >0){
                        //_saleType = <span className="text-danger">Order Overdue </span>;
                    }
                    if(value.payment_term_due_status == 'Order Overdue' && _businessSetting.DueDatesPaymentTerms == 1){
                        _saleType = <span className="text-danger">Order Overdue </span>;
                    }     
                    let _showInv = value.customPrefix+' #'+value.invoice_no_web;
                    if(value.type == "CREDIT NOTE"){
                        _showInv = value.customPrefix+' #'+value.invoice_no_return_web;
                    }
                    
                    let _pageAK = this.state.setPage;
                    let _showDupL = '';
                    if(_trans_duplicate_normal == 'Duplicate'){
                        _showDupL = ' (Duplicate)';
                    }
                    if( _businessSetting.DueDatesPaymentTerms == 1){
                        let _dueDateShow = value.payment_term_due_date;
                        if(_dueDateShow == '0' || _dueDateShow.length <= 2){
                            _dueDateShow = 'NA';
                        }
                        rows.push({
                            srNo: ((_pageAK - 1) * this.state.pageCount) + _i,//value.srNo,//_i,//value.srNo,
                            invoice_date: value.invoice_date,
                            due_date: _dueDateShow,
                            invoice_no:_showInv + _showDupL,
                            party_name:value.party_name,
                            sale_type: _saleType,
                            total_amount:numberFormat(value.total_amount),
                            balance: numberFormat(value.balance),
                            actionExtra:_actionExtra
                        });
                    }
                    else{                        
                        rows.push({
                            srNo: ((_pageAK - 1) * this.state.pageCount) + _i,//value.srNo,//_i,//value.srNo,
                            invoice_date: value.invoice_date,
                            invoice_no:_showInv + _showDupL,
                            party_name:value.party_name,
                            sale_type: _saleType,
                            total_amount:numberFormat(value.total_amount),
                            balance: numberFormat(value.balance),
                            actionExtra:_actionExtra
                        });
                    }
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }

    handleCallBackDate = (start, end) => {
        this.setState({startDate:start.format('MMMM D, YYYY'),endDate:end.format('MMMM D, YYYY')});
        this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
    };

    clickToDelete(value,type)
    {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        sale_id: value,
                        type_value: type
                    });
                    PostData(global.deleteSaleTransaction, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',

            }
            ]
        });
    }

    
    clickToDeleteWithReturn(value,type)
    {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'If you delete this transaction, all the return transactions related to it will also be deleted.Do you still want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        sale_id: value,
                        type_value: type
                    });
                    PostData(global.deleteWithReturnTransaction, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',

            }
            ]
        });
    }

    clickToPaymentHistory(value,paymentHistory,receivedAmount){
        //console.log(paymentHistory);return false;
        this.setState({modalPaymentHistory: true,activePaymentHistory:paymentHistory,trasactionHisId:value,receivedAmount:receivedAmount});
    }
    
    clickToPaymentPurchaseHistory(value,paymentHistory,receivedAmount){
        this.setState({modalPaymentHistoryPurchase: true,activePaymentHistory:paymentHistory,trasactionHisId:value,receivedAmount:receivedAmount});
    }
    
    closeModalHandler = (e) => {
        if(e == 'modalPaymentHistory'){
            this.setState({modalPaymentHistory: false,activePaymentHistory:[],trasactionHisId:0,receivedAmount:0});
        }
        else if(e == 'modalPaymentPurchaseHistory'){
            this.setState({modalPaymentHistoryPurchase: false,activePaymentHistory:[],trasactionHisId:0,receivedAmount:0});
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Sale List<br/>
                            </h1>
                            
                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <Link className="breadcrumb-item" to={"/business/dashboard/"}>Dashboard</Link>
                        <Link className="breadcrumb-item" to={"/business/add-sale/"}>Create New Sale</Link>                        
                        <span className="breadcrumb-item active">Manage Sale List</span>
                    </nav>
                    
                    <div className="block">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <div className="row items-push">
                                            <div className="row col-lg-12">
                                                <div className="col-lg-9">
                                                    <span>Filter : </span>
                                                    <DateRangePicker
                                                        initialSettings={{ 
                                                            startDate: this.state.startDate, 
                                                            endDate: this.state.endDate,
                                                            locale: {
                                                                format: 'MMMM D, YYYY',
                                                            },
                                                            ranges: {
                                                                Today: [moment().toDate(), moment().toDate()],
                                                                Yesterday: [
                                                                moment().subtract(1, 'days').toDate(),
                                                                moment().subtract(1, 'days').toDate(),
                                                                ],
                                                                'This Week': [
                                                                    moment().startOf('week').format("MMM DD, YYYY"),
                                                                    moment().endOf('week').format("MMM DD, YYYY"),
                                                                    ],
                                                                'This Month': [
                                                                    moment().startOf('month').toDate(),
                                                                    moment().endOf('month').toDate(),
                                                                ],
                                                                'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                                ],
                                                                'This Quarter': [
                                                                    moment().startOf('month').toDate(),
                                                                    moment().add(2, 'month').endOf('month').toDate(),
                                                                ],
                                                                'This Financial Year': [
                                                                    moment().month('April').startOf('month').format("MMM DD, YYYY"),
                                                                    moment().add(1, 'year').month('March').endOf('month').format("MMM DD, YYYY"),
                                                                ],
                                                            }
                                                        }}
                                                        onCallback={this.handleCallBackDate}
                                                    >
                                                    <input type="text" className="form-control col-4" style={{display: 'inline-block'}}/>
                                                    </DateRangePicker>
                                                </div>
                                                <div className="col-lg-3">
                                                    
                                                <div className="row pb-20 pull-right">
                                                    <div className="text-right">
                                                        <div className="js-appear-enabled text-center">
                                                            <div className="text-info"><i className="fa fa-file-excel-o"></i></div>
                                                            <div className="font-size-sm text-muted">Excel Export</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-6 text-right border-r">
                                                        <div className="js-appear-enabled text-center">
                                                            <div className="text-info"><i className="fa fa-file-excel-o"></i></div>
                                                            <div className="font-size-sm text-muted">Excel Export</div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-6">
                                                        <div className="js-appear-enabled text-center">
                                                            <div className="text-success"><i className="fa fa-print"></i></div>
                                                            <div className="font-size-sm text-muted">Print</div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                </div>       
                                                <br />
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blockAreaList" style={{width : '250Px !important'}}>
                                                    <div className="py-10 text-left" id="paidArea">
                                                        <div className="text-muted">Paid</div>
                                                        <div className="font-size-h5 font-w600">{numberFormat(this.state.paidAmount)}</div>
                                                    </div>
                                                </div>
                                                <div className="blockAreaListIcon"><i className="fa fa-plus"></i></div>
                                                <div className="blockAreaList" style={{width : '250Px !important'}}>
                                                    <div className="py-10 text-left" id="unpaidArea">
                                                        <div className="text-muted">Unpaid</div>
                                                        <div className="font-size-h5 font-w600">{numberFormat(this.state.unpaidAmount)}</div>
                                                    </div>
                                                </div>
                                                <div className="blockAreaListIcon"><i className="fas fa-equals"></i></div>
                                                <div className="blockAreaList" style={{width : '250Px !important'}}>
                                                    <div className="py-10 text-left" id="totalArea">
                                                        <div className="text-muted">Total</div>
                                                        <div className="font-size-h5 font-w600">{numberFormat(this.state.total)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>

                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h2 className="block-title ml-10">Transactions</h2>
                                        <input type="text" className="form-control col-6" style={{display:'inline-block'}} placeholder='search invoice number (type at least three characters)'onChange={this.getInvoiceSearch}/>
                                        <Link to={"/business/add-sale/"} style={{}} className="pull-right btn btn-sm btn-primary">
                                            <i className="fa fa-plus-circle"></i> Add Sale
                                        </Link>
                                    </div>
                                    <div className="block-content" id="tableData"style={{margin:'10px'}} >
                                        {/* <MDBDataTableV5 
                                        hover
                                        //entriesOptions={[1,5,10]}
                                        //entries={25}
                                        //pagesAmount={4}
                                        span 
                                        pagingTop={false}
                                        searchTop={false}
                                        searchBottom={false}
                                        pagingBottom={false}
                                        paging={false}
                                        // barReverse
                                        data={this.loadTranscListing()}
                                        /> */}
                                        <MDBDataTableV5
                                            hover
                                            span
                                            displayEntries={false}
                                            entries={100}
                                            pagingTop={false}
                                            searchTop={false}
                                            searchBottom={false}
                                            info={false}
                                            barReverse
                                            data={this.loadTranscListing()}
                                        />
                                    </div>
                                    
                                    <div style={{ float: "right", marginTop: "-15px" }}>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Rows per page:</div>
                                            </div>
                                            <div className="d-flex align-items-center" >
                                            <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        const { name, value } = event.target;
                                                        //this.setState({ pageCount: value, setPage: 1 });
                                                        this.setState(prevState => ({
                                                                pageCount : value,
                                                                setPage : 1,
                                                            }),
                                                            () => {
                                                                this.getSaleListData(1,this.state.searchInvNo,
                                                                    value
                                                                );
                                                            });
                                                    }}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <div style={{ float: "left", marginTop: "-45px" }}>
                                        <PaginationControl
                                            between={this.state.pageCount}
                                            total={this.state.totalCount}
                                            limit={this.state.pageCount}
                                            changePage={(page) => {
                                            this.setState({ setPage: page });
                                            this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount
                                            );
                                            }}
                                            ellipsis={1}
                                            activeClass={this.state.setPage}
                                            page={this.state.setPage}
                                        />
                                        </div>
                                    </div>
                                    {/* <div className="block-content">
                                        <div style={{float : 'left', marginTop : '-45px'}}>
                                            <PaginationControl
                                                between={this.state.pageCount}
                                                total={this.state.totalCount}
                                                limit={this.state.pageCount}
                                                changePage={(page) => {
                                                this.setState({setPage:page}); 
                                                this.getSaleListData(page,this.state.searchInvNo); 
                                                    console.log("==HERR-===",page)
                                                }}
                                                ellipsis={1}
                                                activeClass={this.state.setPage}
                                                page={this.state.setPage}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

                {/* Modal Payment History Data List*/}
                    <Modal isOpen={this.state.modalPaymentHistory} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalPaymentHistory">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Payment History</h3>
                                        <div className="block-options">
                                            <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalPaymentHistory')}>
                                                <i className="si si-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <QuickPaymentHistory historyData={this.state.activePaymentHistory} transId={this.state.trasactionHisId} receivedAmount={this.state.receivedAmount}/>
                                       

                                    </div>
                                    <br/>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalPaymentHistory')}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* Modal Payment History Data List*/}
                    
                    
                 
                {/* Modal Payment History Data List*/}
                <Modal isOpen={this.state.modalPaymentHistoryPurchase} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalPaymentPurchaseHistory">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Payment History</h3>
                                        <div className="block-options">
                                            <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalPaymentPurchaseHistory')}>
                                                <i className="si si-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <QuickPaymentPurchaseHistory historyData={this.state.activePaymentHistory} transId={this.state.trasactionHisId} paidAmount={this.state.receivedAmount} />
                                       

                                    </div>
                                    <br/>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalPaymentPurchaseHistory')}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* Modal Payment History Data List*/}     
            </React.Fragment>
        )    
    }
}