import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

let _countrySession = localStorage.getItem("vs_UserCountrySession");
let _countryCode = "IN";
if (_countrySession!=undefined || _countrySession!=null) {
  _countrySession = JSON.parse(_countrySession);
  _countryCode = _countrySession.countryCode;
}

let _loader = "glb-ldr-prt active";
export default class companyListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",
      isLoaded: true,
      businessArray: [],
      stateArray: [],
      categoryArray: [],
      categoryTypeArray: [],

      _countryLang: "",
      _countryReg: "iti__hide",
      _countryFlag: "",
      _countryName: "",
      _countryPh: "",
      _countryId: "0",
      countryArray: [],

      countryCode: _countryCode,
      companyName: "",
      address: "",
      description: "",
      phone: "",
      email: "",
      logo: "",
      gstin: "",
      country_register: "",
      state: "",
      business_type: "",
      business_category: "",
      businessId: "",
      companyId: 0,
      imagePreviewUrl: "",
      errors: {
        businessId: "",
        companyName: "",
        address: "",
        country_register: "",
        phone: "",
        email: "",
      },
    };
  }

  async componentDidMount() {
    /* Get Url Pass Parameter */
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _companyId = _handleParam[1] ? _handleParam[1] : 0;
    /* End Here Url Pass Parameter */
    let _userCountryId = "0";

    if (_companyId > 0) {
      let _companyArray = [];
      let _url_GetData =
        global.adminCompanyDetails + "?companyId=" + _companyId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _companyArray = responseJson.response[0];
              _userCountryId = _companyArray.country_code;
              this.setState({
                buttonTitle: "Edit",
                companyName: _companyArray.company_name,
                businessId: _companyArray.business_id,
                companyId: _companyId,
                address: _companyArray.address,
                description: _companyArray.description,
                phone: _companyArray.phone,
                email: _companyArray.email,
                gstin: _companyArray.gstin,
                state: _companyArray.state,
                business_type: _companyArray.business_type,
                business_category: _companyArray.business_category,
                country_register: _companyArray.country_register,
                imagePreviewUrl: _companyArray.logo_path,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }

    let _urlCountryData = global.adminCountryList;
    let countryData = await PostData(_urlCountryData, "", "GET");
    let _conLang,
      _conFlag,
      _conName,
      _conPh,
      _conId = "";
    countryData.response.map((conty, i) => {
      let _name = conty.sortname.toLowerCase();
      let _imgPath = global.FLAG_URL + _name + ".png";
      //alert(_userCountryId+'-----'+_countryCode);
      if (_userCountryId > 0) {
        if (_userCountryId == conty.phonecode) {
          _conLang = conty.sortname;
          _conFlag = _imgPath;
          _conName = conty.name;
          _conPh = conty.phonecode;
          _conId = conty.id;
        }
      } else if (_countryCode == conty.sortname) {
        _conLang = conty.sortname;
        _conFlag = _imgPath;
        _conName = conty.name;
        _conPh = conty.phonecode;
        _conId = conty.id;
      }
    });

    let _urlData = global.adminBusinessNameList;
    let res = await PostData(_urlData, "", "GET");

    if (res.response == null || res.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _businessArray = res.response;
    this.setState({
      businessArray: _businessArray,
      countryArray: countryData.response,
      _countryId: _conId,
      _countryLang: _conLang,
      _countryFlag: _conFlag,
      _countryName: _conName,
      _countryPh: _conPh,
      isLoaded: true,
    });

    this.getStateListData(_conPh);
    this.getCategoryListData();
    this.getCategoryTypeListData();
  }

  getCategoryListData() {
    let _catArray = [];
    let _url_GetData = global.businessCategoryList;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _catArray = responseJson.response;
          }
          this.setState({ categoryArray: _catArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getCategoryTypeListData() {
    let _typeArray = [];
    let _url_GetData = global.businessTypeList;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _typeArray = responseJson.response;
          }
          this.setState({ categoryTypeArray: _typeArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getStateListData(e) {
    let _stateArray = [];

    let _url_GetData = global.adminStateList + "?countryCode=" + e;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _stateArray = responseJson.response;
          }
          this.setState({ stateArray: _stateArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ isDisabled: false });
    switch (name) {
      case "companyName":
        errors.companyName =
          value.length < 3 ? "*Company Name must be 3 characters long!" : "";
        this.setState({ companyName: value });
        break;
      case "businessId":
        errors.businessId =
          value.length < 0 ? "*Please select any business!" : "";
        this.setState({ businessId: value });
        break;
      case "address":
        errors.address =
          value.length < 10
            ? "*Company Address must be 10 characters long!"
            : "";
        this.setState({ address: value });
        break;
      case "country_register":
        errors.country_register =
          value.length < 0 ? "*Select Company registered location!" : "";
        this.setState({ country_register: value });
        this.getStateListData(value);
        break;

      case "business_category":
        this.setState({ business_category: value });
        break;
      case "business_type":
        this.setState({ business_type: value });
        break;
      case "logo":
        this.setState({ logo: value });
        break;
      case "gstin":
        this.setState({ gstin: value });
        break;
      case "state":
        this.setState({ state: value });
        break;
      case "phone":
        if (validator.isInt(value)) {
          errors.phone = "";
        } else {
          errors.phone = "*Please enter valid phone.";
        }
        this.setState({ phone: value });
        break;
      case "email":
        if (validator.isEmail(value)) {
          errors.email = "";
        } else {
          errors.email = "*Please enter valid email-id.";
        }
        this.setState({ email: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      default:
        break;
    }
  };

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        logo: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      //let _imageDetails = this.state.logo;
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  clickToAddCompany = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.companyName == "") {
      _isError = true;
      errors["companyName"] = "*Please Enter Company Name";
      this.setState({ errors: errors });
    }
    if (this.state.email == "") {
      _isError = true;
      errors["email"] = "*Please Enter Company Email";
      this.setState({ errors: errors });
    }
    if (this.state.phone == "") {
      _isError = true;
      errors["phone"] = "*Please Enter Company Contact Number";
      this.setState({ errors: errors });
    }

    if (this.state.phone.length < 8 || this.state.phone.length > 12) {
      _isError = true;
      errors["phone"] = "*Please enter valid company contact no.";
      this.setState({ errors: errors });
    }
    if (this.state.address == "") {
      _isError = true;
      errors["address"] = "*Please Enter Company Address";
      this.setState({ errors: errors });
    }
    if (this.state.businessId == "") {
      _isError = true;
      errors["businessId"] = "*Please Select Any Business.";
      this.setState({ errors: errors });
    }
    if (this.state.country_register == "") {
      _isError = true;
      errors["country_register"] =
        "*Please Select Company Registered Location.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.companyId > 0) {
        this.editCompanyMethodCall();
      } else {
        this.addCompanyMethodCall();
      }
    }
  };

  editCompanyMethodCall() {
    /*const requestData = JSON.stringify({
            companyId:this.state.companyId,
            businessId:this.state.businessId,
            company_name:this.state.companyName,
            address:this.state.address,
            description:this.state.description,
            phone:this.state.phone,
            email:this.state.email,
            logo:this.state.logo,
            gstin:this.state.gstin,
            state:this.state.state,
            business_type:this.state.business_type,
            business_category:this.state.business_category,
            countryID:this.state._countryId,
            country_code:this.state._countryPh,
            country_register:this.state.country_register
        });

        PostData(global.adminEditCompany, requestData,'POST').then((res) => 
        {
            if(res.success == true)
            {
                sendNotification("Success Message 👍",res.message,"success");
                window.location.href = global.webUrl+'administrator/manage-company/';
            }
            else
            {
                sendNotification("Error Message 😓",res.message,"danger");
                let errors = this.state.errors;
                errors['companyName'] = res.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });*/
    var companyData = new FormData();
    companyData.append("companyId", this.state.companyId);
    companyData.append("businessId", this.state.businessId);
    companyData.append("company_name", this.state.companyName);
    companyData.append("address", this.state.address);
    companyData.append("description", this.state.description);
    companyData.append("phone", this.state.phone);
    companyData.append("email", this.state.email);
    companyData.append("logo", this.state.logo);
    companyData.append("gstin", this.state.gstin);
    companyData.append("state", this.state.state);
    companyData.append("business_type", this.state.business_type);
    companyData.append("business_category", this.state.business_category);
    companyData.append("countryID", this.state._countryId);
    companyData.append("country_code", this.state._countryPh);
    companyData.append("country_register", this.state.country_register);
    //.append('signaturImage', this.state.imageSignaturePreviewUrl);
    //companyData.append('signaturImage', this.state.signaturImage);

    new Promise((resolve, reject) => {
      fetch(global.adminEditCompany, {
        method: "POST",
        body: companyData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");

            window.location.href =
              global.webUrl + "administrator/manage-company/";
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            let errors = this.state.errors;
            errors["companyName"] = res.message; //'Please enter valid phone no.';
            this.setState({
              errors: errors,
            });
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  addCompanyMethodCall() {
    /*const requestData = JSON.stringify({
            businessId:this.state.businessId,
            company_name:this.state.companyName,
            address:this.state.address,
            description:this.state.description,
            phone:this.state.phone,
            email:this.state.email,
            logo:this.state.logo,
            gstin:this.state.gstin,
            state:this.state.state,
            business_type:this.state.business_type,
            business_category:this.state.business_category,
            countryID:this.state._countryId,
            country_code:this.state._countryPh,
            country_register:this.state.country_register
        });*/

    var companyData = new FormData();
    companyData.append("businessId", this.state.businessId);
    companyData.append("company_name", this.state.companyName);
    companyData.append("address", this.state.address);
    companyData.append("description", this.state.description);
    companyData.append("phone", this.state.phone);
    companyData.append("email", this.state.email);
    companyData.append("logo", this.state.logo);
    companyData.append("gstin", this.state.gstin);
    companyData.append("state", this.state.state);
    companyData.append("business_type", this.state.business_type);
    companyData.append("business_category", this.state.business_category);
    companyData.append("countryID", this.state._countryId);
    companyData.append("country_code", this.state._countryPh);
    companyData.append("country_register", this.state.country_register);
    //companyData.append('signaturImage', this.state.imageSignaturePreviewUrl);
    //companyData.append('signaturImage', this.state.signaturImage);

    /*PostData(global.adminAddCompany, companyData,'POST').then((res) => 
        {
            if(res.success == true)
            {
                sendNotification("Success Message 👍",res.message,"success");
                window.location.href = global.webUrl+'administrator/manage-company/';
            }
            else
            {
                sendNotification("Error Message 😓",res.message,"danger");
                let errors = this.state.errors;
                errors['companyName'] = res.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });*/
    new Promise((resolve, reject) => {
      fetch(global.adminAddCompany, {
        method: "POST",
        body: companyData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");

            window.location.href =
              global.webUrl + "administrator/manage-company/";
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            let errors = this.state.errors;
            errors["companyName"] = res.message; //'Please enter valid phone no.';
            this.setState({
              errors: errors,
            });
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  /* Country Code Dropdown Start Here */
  selectCounties(code, flag, name, e) {
    this.setState({
      _countryReg: "iti__hide",
      _countryFlag: flag,
      _countryName: name,
      _countryPh: code,
    });
  }

  manageMultiCounties(key) {
    if (key == "I") {
      this.setState({ _countryReg: "" });
    } else if (key == "O") {
      this.setState({ _countryReg_O: "" });
    }
  }

  getReadyMobileCode() {
    let data = [];
    if (this.state.isLoaded == true) {
      data = this.state.countryArray.map((conty, i) => {
        let _name = conty.sortname.toLowerCase();
        let _imgPath = global.FLAG_URL + _name + ".png";

        return (
          <li
            key={i}
            className="iti__country iti__standard"
            id="iti-item-in"
            onClick={this.selectCounties.bind(
              this,
              conty.phonecode,
              _imgPath,
              conty.sortname,
              conty.id
            )}
          >
            <div className="iti__flag-box">
              <div className="iti__flag" id="itiFlagImg">
                <img src={_imgPath} />
              </div>
            </div>
            <span className="iti__country-name">{conty.sortname}</span>
            <span className="iti__dial-code">{conty.phonecode}</span>
          </li>
        );
      });
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _countryReg: "iti__hide" });
    } else if (key == "O") {
      this.setState({ _countryReg_O: "iti__hide" });
    }
  }
  /* Country Code Dropdown End Here */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Company List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/manage-business/"}
            >
              View Business List
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/manage-company/"}
            >
              View Company List
            </a>
            <span className="breadcrumb-item active">Add / Edit Company</span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Add / Edit Company</h3>
            </div>
            <div className="block-content">
              <form method="post" id="form-login" className="webForm px-30">
                <div className="form-group row">
                  <div className="col-6">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        className="form-control"
                        id="companyName"
                        name="companyName"
                        value={this.state.companyName}
                        placeholder="Company Name Here"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-username">
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <span className="vs_error_txt">
                        {errors.companyName.length > 0 && (
                          <span className="error">{errors.companyName}</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="businessId"
                        name="businessId"
                        onChange={this.handleChange}
                      >
                        <option value=""> Select Business</option>
                        {this.state.businessArray.map((item, index) => {
                          let _sel = "";
                          if (this.state.businessId == item.business_id) {
                            _sel = "selected";
                          }
                          return (
                            <option
                              key={index}
                              value={item.business_id}
                              selected={_sel}
                            >
                              {item.business_name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">
                        In Business <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div id="businessId-error" className="animated fadeInDown">
                      {errors.businessId.length > 0 && (
                        <span className="error">{errors.businessId}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-4">
                    <div className="form-material open">
                      <input
                        type="file"
                        required="required"
                        style={{ width: "60%", display: "inline-block" }}
                        placeholder="file"
                        autoComplete="false"
                        className="form-control"
                        id="logo"
                        name="logo"
                        onChange={this.onChangeFileUploadHandler}
                      />
                      {this.previewImagesListing()}
                      <label htmlFor="login-password">Company Logo</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <input
                        type="email"
                        required="required"
                        placeholder="abc@xxxx.com"
                        autoComplete="false"
                        value={this.state.email}
                        className="form-control"
                        id="email"
                        name="email"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-password">
                        Company Email <span className="text-danger">*</span>
                      </label>
                      <div id="email-error" className="animated fadeInDown">
                        {errors.email.length > 0 && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <div
                        className="iti__flag-container"
                        onMouseEnter={this.manageMultiCounties.bind(this, "I")}
                        onMouseLeave={this.onMouseLeaveHandler.bind(this, "I")}
                      >
                        <div
                          className="iti__selected-flag"
                          role="combobox"
                          aria-owns="country-listbox"
                          tabIndex="0"
                          title={this.state._countryName}
                        >
                          <div className="iti__flag" id="itiFlagImg">
                            <img src={this.state._countryFlag} />
                          </div>
                          <div className="iti__selected-dial-code">
                            {this.state._countryPh}
                          </div>
                          <div className="iti__arrow">
                            <i className="si si-arrow-down"></i>
                          </div>
                        </div>

                        <ul
                          className={
                            this.state._countryReg +
                            " iti__country-list iti__country-list--dropup"
                          }
                          id="country-listbox"
                          aria-expanded="false"
                          role="listbox"
                          aria-activedescendant="iti-item-ad"
                        >
                          {this.getReadyMobileCode()}
                        </ul>
                      </div>
                      <input
                        type="text"
                        style={{ display: "inline-block", width: "75%" }}
                        required="required"
                        placeholder="9876XXXXXX"
                        autoComplete="false"
                        value={this.state.phone}
                        className="form-control"
                        id="phone"
                        name="phone"
                        onChange={this.handleChange}
                        maxLength="12"
                      />
                      <label htmlFor="login-password">
                        Company Contact Number{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div id="phone-error" className="animated fadeInDown">
                        {errors.phone.length > 0 && (
                          <span className="error">{errors.phone}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        placeholder="Company Address Here"
                        autoComplete="false"
                        value={this.state.address}
                        className="form-control"
                        id="address"
                        name="address"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-password">
                        Company Address <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div id="address-error" className="animated fadeInDown">
                      {errors.address.length > 0 && (
                        <span className="error">{errors.address}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        placeholder="Company Description Here"
                        autoComplete="false"
                        value={this.state.description}
                        className="form-control"
                        id="description"
                        name="description"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-password">
                        Company Description{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="business_type"
                        name="business_type"
                        onChange={this.handleChange}
                      >
                        <option value=""> Select Business Type</option>
                        {this.state.categoryTypeArray.map((item, index) => {
                          let _sel = "";
                          if (this.state.business_type == item.id) {
                            _sel = "selected";
                          }
                          return (
                            <option key={index} value={item.id} selected={_sel}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">Business Type </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="business_category"
                        name="business_category"
                        onChange={this.handleChange}
                      >
                        <option value=""> Select Business Category</option>
                        {this.state.categoryArray.map((item, index) => {
                          let _sel = "";
                          if (this.state.business_category == item.id) {
                            _sel = "selected";
                          }
                          return (
                            <option key={index} value={item.id} selected={_sel}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">Business Category </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-4">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        value={this.state.gstin}
                        placeholder="Enter GSTIN Number"
                        autoComplete="false"
                        className="form-control"
                        id="gstin"
                        name="gstin"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-password">
                        Company GSTIN Number
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="country_register"
                        name="country_register"
                        onChange={this.handleChange}
                      >
                        <option value=""> Select Registered Location</option>
                        {this.state.countryArray.map((item, index) => {
                          let _sel = "";
                          if (this.state.country_register == item.phonecode) {
                            _sel = "selected";
                          }
                          return (
                            <option
                              key={index}
                              value={item.phonecode}
                              selected={_sel}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">
                        Registered Country{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <span className="vs_error_txt">
                        {errors.country_register.length > 0 && (
                          <span className="error">
                            {errors.country_register}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        onChange={this.handleChange}
                      >
                        <option value=""> Select State</option>
                        {this.state.stateArray.map((item, index) => {
                          let _sel = "";
                          if (this.state.state == item.name) {
                            _sel = "selected";
                          }
                          return (
                            <option
                              key={index}
                              value={item.name}
                              selected={_sel}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">State </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="btn btn-alt-primary"
                      onClick={this.clickToAddCompany}
                    >
                      <i className="fa fa-check mr-5"></i>{" "}
                      {this.state.buttonTitle} Company
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div class="gl-ldr-cld">
            <div class="gl-ldr-ctr">
              <div class="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
