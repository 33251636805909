import React,{Component} from 'react';
import {PostData} from '../service/postData';
import parse from 'html-react-parser';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class blogPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            title : '',
            imageAlt: '',
            image: '',
            imageImage: '',
            blog_id:0,
            imageTitle: '',
            short_description: '',
            meta_title: '',
            meta_keyword: '',
            meta_desc: '',
            description: '',
            date: '',
            blog_cat_id: '0',
            blogArray:[],
            blogCatArray:[]
        }
    }

    async componentDidMount()
    {
        let _blogid = (this.props.match.params.blogId > 0) ? this.props.match.params.blogId : '0';
        if(_blogid > 0)
        {
            let _urlData = global.webistePageBlogDetailsList+'?blogId='+_blogid;        
            let _blogArray = [];            
            PostData(_urlData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _blogArray = responseJson.response[0];
                        this.setState({blogArray:_blogArray,title:atob(_blogArray.title),imageAlt:atob(_blogArray.imageAlt),imageTitle:atob(_blogArray.imageTitle),short_description:atob(_blogArray.short_description),meta_title:atob(_blogArray.meta_title),meta_keyword:atob(_blogArray.meta_keyword),meta_desc:atob(_blogArray.meta_desc),image:_blogArray.image,blog_id:_blogArray.id,description:atob(_blogArray.description),blog_cat_id:_blogArray.blog_cat_id,date:_blogArray.date,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
        else{
            alert("Blog details not found in system.");
            window.location.href = global.BASE_URL+'blogs/';
        }

        this.blogCategoryListData();
    }

    blogCategoryListData(){
        let _blogCatArray = [];

        let _url_GetData = global.webistePageblogCategoryList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _blogCatArray = responseJson.response;
                }
                this.setState({blogCatArray:_blogCatArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    blogDetailsData(){
        if(this.state.isLoaded == true)
        {
            let dataList = [];
            console.log(this.state.description);
            dataList.push(
                <div className="blog_content">
                    <div className="blog_img">
                        <img src={this.state.image} alt={this.state.imageAlt} title={this.state.imageTitle} />
                    </div>
                    <div className="blog_txt">
                        {parse(this.state.description)}
                    </div>
                </div>
            );
            return dataList;
        }
    }


    blogCatDetailsData()
    {
        if(this.state.isLoaded == true)
        {
            let _blogCatDetails = this.state.blogCatArray;
            let dataList = [];
            if(_blogCatDetails.length > 0)
            {                          
                _blogCatDetails.map((datas, i) =>
                {
                    dataList.push(
                        <li className="cat-item active" key={i}><a href={global.webUrl+'blogs/?category='+datas.id}>{datas.name}</a></li>
                    );
                });
            }
            return dataList;
        }
    }

    render(){
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">{this.state.title}</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="sec4 sec4C">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="blog_content">
                                            <div className="blog_img">
                                                <img src="<?=$_imageUrl?>" alt="" title="" />
                                            </div>
                                            <div className="blog_txt">
                                                {this.blogDetailsData()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="blog_content">
                                            <h2 className="txt-style-9">Let's Connect</h2>
                                            <span className="line2"></span>
                                            <div className="socials">
                                                <ul class="social">
                                                    <li><a href="javascript:alert('Under process')"><i class="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="javascript:alert('Under process')"><i class="fab fa-twitter"></i></a></li>
                                                    <li><a href="javascript:alert('Under process')"><i class="fab fa-youtube"></i></a></li>
                                                    <li><a href="javascript:alert('Under process')"><i class="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="javascript:alert('Under process')"><i class="fab fa-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="blog_content space1">
                                            <h2 className="txt-style-9">Categories</h2>
                                            <span className="line2"></span>
                                            <div className="widget-wrap">
                                                <ul>
                                                    {this.blogCatDetailsData()}
                                                    {/* <?php
                                                    $_chkCat = $dbComObj->viewData($conn, "vs_admin_website_blog_category", "*", "1 and `isDelete` = '0' order by id DESC");
                                                    if ($dbComObj->num_rows($_chkCat) > 0) {
                                                        $i = 0;
                                                        while ($_catData = $dbComObj->fetch_object($_chkCat)) {
                                                            echo '<li className="cat-item active"><a href="'.WEB_URL.'blogs/?category='.$_catData->id.'">'.$_catData->category.'</a></li>';
                                                        }
                                                    }
                                                    else{
                                                        echo 'No new category found!';
                                                    }
                                                    ?> */}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className="blog_content space1">
                                            <h2 className="txt-style-9">Recent Blogs</h2>
                                            <span className="line2"></span>
                                            <?php
                                            $_chkblog = $dbComObj->viewData($conn, "vs_admin_website_blog", "*", "1 and `id`!='".$_id."' and `status` = '1' order by id DESC limit 0,5");
                                            if ($dbComObj->num_rows($_chkblog) > 0) {
                                                $i = 0;
                                                while ($_BlogData = $dbComObj->fetch_object($_chkblog)) {
                                                    $_imageUrl = 'http://vyavsay.in/img/blog_1.png';
                                                    if(strlen($_BlogData->image) > 10){
                                                        $_imageUrl = CONTENT_BASE_URL.'blog/'.$_BlogData->image;
                                                    }
                                                    
                                                    $_bTitle = base64_decode($_BlogData->title);
                                                    $url_titles = str_replace(" ", "-", $_bTitle);
                                                    $url_title = str_replace("?", "_", $url_titles);
                                                    $blog_id = $_BlogData->id;
                                                    $_finalUrl = WEB_URL.'blog/'.$blog_id . '/' . $url_title.'/';

                                                    echo '<div className="blog_block">
                                                        <div className="blog_block_img">
                                                            <a href="'.$_finalUrl.'"><img src="'.$_imageUrl.'" style="width: 80px;"/></a>
                                                        </div>
                                                        <div className="blog_block_txt">
                                                            <a href="'.$_finalUrl.'">'.$_bTitle.'</a>
                                                            <p>Date : '.date('d, M Y',strtotime($_BlogData->date)).'</p>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>';
                                                }
                                            }
                                            else{
                                                echo 'No new blog found!';
                                            }
                                            ?>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}