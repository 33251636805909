import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        
        
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,         
            modalIsOpen_Info : false,     
            modalIsOpen_TermsCond : false,
            _infoData : '',
            SaleTransName : (_businessSetting.SaleTransName) ? _businessSetting.SaleTransName : '',
            SaleTransCheck : (_businessSetting.SaleTransCheck) ? _businessSetting.SaleTransCheck : 0,
            
            PaymentInTransName : _businessSetting.PaymentInTransName ? _businessSetting.PaymentInTransName : '',                        
            CreditNoteTransName : _businessSetting.CreditNoteTransName ? _businessSetting.CreditNoteTransName : '',            
            SaleOrderTransName : _businessSetting.SaleOrderTransName ? _businessSetting.SaleOrderTransName : '',            
            EstimateTransName : _businessSetting.EstimateTransName ? _businessSetting.EstimateTransName : '',            
            DeliveryChallanTransName : _businessSetting.DeliveryChallanTransName ? _businessSetting.DeliveryChallanTransName : '',        
            PurchaseTransName : _businessSetting.PurchaseTransName ? _businessSetting.PurchaseTransName : '',        
            PaymentOutTransName : _businessSetting.PaymentOutTransName ? _businessSetting.PaymentOutTransName : '',        
            DebitNoteTransName : _businessSetting.DebitNoteTransName ? _businessSetting.DebitNoteTransName : '',        
            PurchaseOrderTransName : _businessSetting.PurchaseOrderTransName ? _businessSetting.PurchaseOrderTransName : '',        
            ExpensesTransName : _businessSetting.ExpensesTransName ? _businessSetting.ExpensesTransName : '',        
            OtherIncomeTransName : _businessSetting.OtherIncomeTransName ? _businessSetting.OtherIncomeTransName : '',
        }
    }

    componentDidMount()
    {  

    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        
        if(_key == 'SaleTransCheck'){
            this.setState({SaleTransCheck:_value}); 
        } 
        

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {

                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'SaleTransName'){
            this.setState({SaleTransName:activeValue}); 
        } 
        if(_keyVal == 'PaymentInTransName'){
            this.setState({PaymentInTransName:activeValue}); 
        } 
        if(_keyVal == 'CreditNoteTransName'){
            this.setState({CreditNoteTransName:activeValue}); 
        } 
        if(_keyVal == 'SaleOrderTransName'){
            this.setState({SaleOrderTransName:activeValue}); 
        } 
        if(_keyVal == 'EstimateTransName'){
            this.setState({EstimateTransName:activeValue}); 
        } 
        if(_keyVal == 'DeliveryChallanTransName'){
            this.setState({DeliveryChallanTransName:activeValue}); 
        }   
        if(_keyVal == 'PurchaseTransName'){
            this.setState({PurchaseTransName:activeValue}); 
        } 
        if(_keyVal == 'PaymentOutTransName'){
            this.setState({PaymentOutTransName:activeValue}); 
        } 
        if(_keyVal == 'DebitNoteTransName'){
            this.setState({DebitNoteTransName:activeValue}); 
        } 
        if(_keyVal == 'PurchaseOrderTransName'){
            this.setState({PurchaseOrderTransName:activeValue}); 
        } 
        if(_keyVal == 'ExpensesTransName'){
            this.setState({ExpensesTransName:activeValue}); 
        } 
        if(_keyVal == 'OtherIncomeTransName'){
            this.setState({OtherIncomeTransName:activeValue}); 
        }      
        //console.log(_keyVal+'=='+activeValue+'===STATE=='+this.state.PartyAddField1Name+'==END==');
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }   
    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession); 
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        
        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-12">
                        <h5 className="content-heading">Sale </h5>

                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Sale</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Sale Invoice" data-slug="SaleTransName" id="SaleTransName" name="SaleTransName" value ={this.state.SaleTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <p  style={{fontWeight: '400',fontSize: '15px',marginLeft: '140px'}}> Bill of supply for non tax  Transaction                                                             
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.SaleTransCheck > 0) ? true : false} id="SaleTransCheck" name="SaleTransCheck" value="1" data-slug="SaleTransCheck" onChange={this.onFilterChange}/>
                                </span></p>   
                            </li>  
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Payment-In</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Payment In" data-slug="PaymentInTransName" id="PaymentInTransName" name="PaymentInTransName" value ={this.state.PaymentInTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>  
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Credit Note</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Credit Note" data-slug="CreditNoteTransName" id="CreditNoteTransName" name="CreditNoteTransName" value ={this.state.CreditNoteTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>

                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Sale Order </label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Sale Order" data-slug="SaleOrderTransName" id="SaleOrderTransName" name="SaleOrderTransName" value ={this.state.SaleOrderTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Estimate</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Estimate" data-slug="EstimateTransName" id="EstimateTransName" name="EstimateTransName" value ={this.state.EstimateTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Delivery Challan</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Delivery Challan" data-slug="DeliveryChallanTransName" id="DeliveryChallanTransName" name="DeliveryChallanTransName" value ={this.state.DeliveryChallanTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li> 
                        </ul>
                        
                        <h5 className="content-heading">Purchase</h5>
                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Bill</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Bill" data-slug="PurchaseTransName" id="PurchaseTransName" name="PurchaseTransName" value ={this.state.PurchaseTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>  
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Payment Out</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Payment Out" data-slug="PaymentOutTransName" id="PaymentOutTransName" name="PaymentOutTransName" value ={this.state.PaymentOutTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>

                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Debit Note</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Debit Note" data-slug="DebitNoteTransName" id="DebitNoteTransName" name="DebitNoteTransName" value ={this.state.DebitNoteTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Purchase Order</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Purchase Order" data-slug="PurchaseOrderTransName" id="PurchaseOrderTransName" name="PurchaseOrderTransName" value ={this.state.PurchaseOrderTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>                            
                        </ul>                       
                        
                        
                        <h5 className="content-heading">Others</h5>
                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Expenses</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Expenses" data-slug="ExpensesTransName" id="ExpensesTransName" name="ExpensesTransName" value ={this.state.ExpensesTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>  
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Other Income</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Other Income" data-slug="OtherIncomeTransName" id="OtherIncomeTransName" name="OtherIncomeTransName" value ={this.state.OtherIncomeTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                            </li>                           
                        </ul>     
                    </div>                    
                </div> 
            </React.Fragment>
        )
    }
} 