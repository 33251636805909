import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import QuickAddNewItem from "./quickItemAdd";
import QuickAddInActiveItem from "./quickItemInactive";
import QuickAddActiveItem from "./quickItemActive";
import QuickAddUniConversion from "./quickUnitConversion";
import QuickAssignUnit from "./quickItemAssignUnit";
import QuickItemAdjustment from "./quickItemAdjustment";
import QuickItemSingleAdjustment from "./quickItemSingleAdjustment";
import QuickItemShare from "./quickItemEmailShare";
import QuickViewSerialList from "./quickItemSerialViewData";
import QuickViewBatchList from "./quickItemBatchViewData";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { EmailShareButton } from "react-share";
import { EmailIcon } from "react-share";
import * as XLSX from "xlsx";
import { PaginationControl } from 'react-bootstrap-pagination-control';

let _signingId = "0";
let _businessId = "0";
let company_id = "0";
let _serialText = "View Serial";
let _businessSetting = {};
let _editStockOption = "";
global.BACK_URL = "";

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 50,
  },
  {
    label: "Name/Description",
    field: "party_name",
    width: 150,
  },
  {
    label: "Date",
    field: "invoice_date",
    width: 100,
  },
  {
    label: "Quantity",
    field: "total_quantity",
    width: 100,
  },
  {
    label: "Tracking Type",
    field: "tracking_type",
    width: 100,
  },
  {
    label: "Price/Unit",
    field: "sale_price",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    width: 100,
  },
  {
    label: "",
    field: "actionextra",
  },
];

let _loader = "glb-ldr-prt active";

export default class serviceListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      company_id = _UserSession.company_id;

      if (_UserSession!=null && _UserSession!="") {
        if (_UserSession.loginType!="Admin") {
          if (_UserSession!=undefined || _UserSession!=null) {
            _businessSetting = _UserSession.businessSetting;
          }
        }
      }
    }

    this.state = {
      buttonTitle: "Add New",

      activeItem: "N/A",
      activeItemId: 0,
      activeItemQty: "0",
      activeSalePrice: "0",
      activePurchasePrice: "0",
      activeStockPrice: "0",
      totalStockQtyAK: "0",
      reservedQuantityAK: "0",
      price_per_unit: "0",
      opening_stock: "0",
      reservedQuantity: "0",
      availableForSale: "0",
      activeItemCode: "",
      activeItemLocation: "",
      currentStockQuan: "0",
      currentStockPrice: "0",
      currentKeyString: "+",
      filterSearch: true,
      isLoaded: true,
      modalIsOpen_IMI: false,
      modalIsOpen_AJP: false,
      modalIsOpen_AC: false,
      modalIsOpen_InActiveItem: false,
      modalIsOpen_ActiveItem: false,
      modalIsOpen_ActiveUnitConversion: false,
      modalIsOpen_ActiveAssignUnit: false,
      modalIsOpen_IShare: false,
      modalViewSerial: false,
      modalViewBatch: false,
      modalIsOpen_SAStock: false,
      viewSerialModal: false,
      viewData: [],
      itemArray: [],
      itemArrayFilter: [],
      importExcelData: [],
      itemArrayTransaction: [],
      businessId: _businessId,
      userId: _signingId,
      company_id: company_id,

      importExcelFile: "",
      item_name: "",

      activeFilter: false,
      inActiveFilter: false,
      chkDataFilter: "",
      adjustmentId: "0",
      pageCount : 10,
      page : 1,
      totalCount  : 0,
      pageShow  : 0,
      setPage : 1,
      errors: {
        importExcelFile: "",
        item_name: "",
        chkDataFilter: "",
      },
    };
  }

  async componentDidMount() {
    this.getItemListData(company_id);
  }

  getTransactionListData(id,_page,pageCount) {
    let _transArray = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      item_id: id, //this.state.activeItemId
      pageCount: pageCount,
      page:_page
    });

    PostData(global.userItemTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ itemArrayTransaction: _transArray, isLoaded: true });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
          }
          this.setState({ itemArrayTransaction: _transArray, isLoaded: true,totalCount:responseJson.totalCount,pageShow:responseJson.pageShow });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getItemListData(company_id) {
    //let _itemsArray = [];

    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      activeFilter: this.state.activeFilter,
      inActiveFilter: this.state.inActiveFilter,
    });
    PostData(global.userItemList, requestData, "POST").then((result) => {
      let responseJson = result;
      if (responseJson.success == false) {
        this.setState({ isLoaded: true });
      } else {
        let _itemsArray = [];
        let _activeItem,
          _activeItemId,
          _activeItemQty,
          _activeSalePrice,
          _activePurchasePrice,
          _reservedQuantity,
          _availableForSale,
          _pricePerUnit,
          _stockValue,
          _activeItemCode,
          _activeItemLocation,
          _currentStockQuan,
          _currentStockPrice,
          _currentKeyString,
          _totalStockQtyAK,
          _reservedQuantityAK = "";

        if (responseJson.response) {
          _itemsArray = responseJson.response;
          _itemsArray.map((itm, i) => {
            if (i == 0) {
              _itemsArray[i].isActiveClick = true;
              _activeItem = itm.name;
              _activeItemId = itm.item_id;
              _activeItemQty = itm.opening_stock;
              _activeSalePrice = itm.sale_price;
              _activePurchasePrice = itm.purchase_price;
              _reservedQuantity = itm.min_stock_quantity;
              _availableForSale = itm.availableQty; //itm.availableQty;// - itm.saleQuantity;
              _totalStockQtyAK = itm.totalStock;
              _reservedQuantityAK = itm.reservedQty;
              _pricePerUnit = itm.price_per_unit;
              _stockValue = itm.totalStockPrice; //itm.currentStockQuantity * itm.currentStockPrice;//itm.price_per_unit * itm.opening_stock;
              _activeItemCode = itm.code;
              _activeItemLocation = itm.item_location;
              _currentStockQuan = itm.currentStockQuantity;
              _currentStockPrice = itm.currentStockPrice;
              _currentKeyString = itm.keyString;

              if (itm.keyString == "-") {
                _stockValue = "-" + _stockValue;
                _availableForSale = "-" + _availableForSale;
              }
            } else {
              _itemsArray[i].isActiveClick = false;
            }
          });
        }
        this.setState({
          itemArrayFilter: _itemsArray,
          itemArray: _itemsArray,
          isLoaded: true,
          item_name: _activeItem,
          activeItem: _activeItem,
          activeItemId: _activeItemId,
          activeItemQty: _activeItemQty,
          activeSalePrice: _activeSalePrice,
          activePurchasePrice: _activePurchasePrice,
          reservedQuantity: _reservedQuantity,
          activeStockPrice: _stockValue,
          totalStockQtyAK: _totalStockQtyAK,
          reservedQuantityAK: _reservedQuantityAK,
          availableForSale: _availableForSale,
          activeItemCode: _activeItemCode,
          activeItemLocation: _activeItemLocation,
          currentStockQuan: _currentStockQuan,
          currentStockPrice: _currentStockPrice,
          currentKeyString: _currentKeyString,
        });
        if (_activeItemId > 0) {
          this.getTransactionListData(_activeItemId,this.state.page,this.state.pageCount);
        }
      }
    });
  }

  loadItemListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _itemDetails = this.state.itemArrayFilter;
      if (_itemDetails.length > 0) {
        _itemDetails.map((value, index) => {
          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeItem == value.name) {
            _activeClass = "activeRow";
          }

          let _deletebtn = (
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              onClick={this.clickToDeleteItem.bind(this, value.item_id)}
              style={{ margin: "0px" }}
            >
              Delete
            </a>
          );
          if (value.isTransaction == true) {
            _deletebtn = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.clickToDeleteNotifiyItem.bind(
                  this,
                  value.item_id
                )}
                style={{ margin: "0px" }}
              >
                Delete
              </a>
            );
          }

          let itemIdAK = value.item_id;
          let itemNameAK = value.name;
          let itemSalePAK = value.sale_price;
          let itemPurchaseAK = value.purchase_price;
          let itemCodeAK = value.code;
          let itemLocationAK = value.item_location;
          let itemStockQtyAK = value.totalStock;
          let itemStockPriceAK = value.totalStockPrice;
          let itemReservedQtyAK = value.reservedQty;
          let itemAvaiableSaleAK = value.availableQty;

          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                itemIdAK,
                itemNameAK,
                itemSalePAK,
                itemPurchaseAK,
                itemCodeAK,
                itemLocationAK,
                itemStockQtyAK,
                itemStockPriceAK,
                itemReservedQtyAK,
                itemAvaiableSaleAK
              )}
            >
              <td>{value.name}</td>
              <td></td>
              <td>{value.opening_stockNew}</td>
              <td className="text-center" scope="row">
                <div className="btn-group show" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right min-width-200"
                    aria-labelledby="toolbarDrop"
                    style={{ margin: "0px" }}
                  >
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-item/?auth=" +
                        value.item_id
                      }
                      style={{ margin: "0px" }}
                    >
                      Edit
                    </a>
                    {_deletebtn}
                  </div>
                </div>
              </td>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  clickSerialInfo() {
    confirmAlert({
      title: "Item Alert",
      message: "You don't have any serials left in stock.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToDeleteNotifiyItem() {
    confirmAlert({
      title: "Item Alert",
      message:
        "This item can not be deleted as it already has transactions. Please delete all transactions before deleting the item.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToDeleteItem(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: value,
              _type: "Item",
            });
            PostData(global.userItemDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getItemListData(company_id);
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToActiveRow(
    id,
    itemNameAK,
    itemSalePAK,
    itemPurchaseAK,
    itemCodeAK,
    itemLocationAK,
    itemStockQtyAK,
    itemStockPriceAK,
    itemReservedQtyAK,
    itemAvaiableSaleAK
  ) {
    //console.log(availForSale+'==kkk=='+resvQuantity);
    /*let _purchasePrice = (pPrice > 0) ? pPrice : 0;
        let _salePrice = (sPrice > 0) ? sPrice : 0;
        let _oStock = (openingStock > 0) ? openingStock : 0;
        let _pricePUnit = (pricePerUnit > 0) ? pricePerUnit : 0;
        let _stockValue = currentStockQuan * currentStockPrice;//_oStock * _pricePUnit;
        let _availableForSale = currentStockQuan;//availForSale;
        let _reservedQuantity =  resvQuantity;
        if(currentKeyString == '-'){
            _stockValue =  '-'+_stockValue;
            _availableForSale =  '-'+_availableForSale;
        }*/
    let _purchasePrice = itemPurchaseAK > 0 ? itemPurchaseAK : 0;
    let _salePrice = itemSalePAK > 0 ? itemSalePAK : 0;
    this.setState({
      activeItem: itemNameAK,
      activeItemId: id,
      activeItemQty: itemStockPriceAK,
      activeSalePrice: _salePrice,
      activePurchasePrice: _purchasePrice,
      activeItemCode: itemCodeAK,
      activeItemLocation: itemLocationAK,
      activeStockPrice: itemStockPriceAK,
      totalStockQtyAK: itemStockQtyAK,
      reservedQuantityAK: itemReservedQtyAK,
      availableForSale: itemAvaiableSaleAK,
    });

    if (id > 0) {
      this.getTransactionListData(id,this.state.page,this.state.pageCount);
    }
  }

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getItemListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteAdjust(value, trackingType) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete the adjustment?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              adjustmentId: value,
              stockType: trackingType,
            });
            PostData(global.deleteItemAdjustentData, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getItemListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteAllSerial(value) {
    confirmAlert({
      title: "Confirm to delete",
      message:
        "Are you sure you want to delete all un-used serial? Note: It will not affect any transaction",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: value,
            });
            PostData(global.deleteAllSerialInItem, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getItemListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }
  clickToDeleteAllBatch(value) {
    let _batchArrayLength = 0;
    const requestData = JSON.stringify({
      itemId: value,
    });

    PostData(global.userItemBatchList, requestData, "POST").then((result) => {
      let responseJson = result;
      _batchArrayLength = responseJson.response.length;
      if (_batchArrayLength == 0) {
        confirmAlert({
          title: "Alert!",
          message:
            "You have used some quantity of the un-used batch, so you can no longer delete that batch. For that you have to delete transaction related to that batch.",
          buttons: [
            {
              label: "OK",
              className: "btn btn-dark",
            },
          ],
        });
      } else {
        confirmAlert({
          title: "Confirm to delete",
          message:
            "Are you sure you want to delete all un-used batch? Note: It will not affect any transaction",
          buttons: [
            {
              label: "Yes",
              className: "btn btn-danger",
              onClick: () => {
                const requestData = JSON.stringify({
                  itemId: value,
                });
                PostData(global.deleteAllBatchInItem, requestData, "POST").then(
                  (result) => {
                    let responseJson = result;
                    if (responseJson.success == false) {
                      sendNotification(
                        "Error Message 😓",
                        responseJson.message,
                        "danger"
                      );
                      return;
                    } else {
                      sendNotification(
                        "Success Message 👍",
                        responseJson.message,
                        "success"
                      );
                      this.getItemListData();
                      return;
                    }
                  }
                );
              },
            },
            {
              label: "No",
              className: "btn btn-dark",
            },
          ],
        });
      }
    });
  }

  loadSaleListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _ii = 0;
      let _transDetails = this.state.itemArrayTransaction;
      if (_transDetails.length > 0) {
        _transDetails.map((value, index) => {
          _ii++;
          let _dateShow = "";

          let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
          let _status = value.payment_paid;
          if (_status == "" || _status.length <= 1) {
            _status = "NA";
          }
          let trackingType = "";
          if (value.tracking_type) {
            trackingType = value.tracking_type;
          }

          let _actionExtra = "";
          if (value.typeD == "ITEM") {
            _status = "NA";
            _dateShow = value.added_on; //value.adjustment_date;
            let _adjustStcokId = 0;
            let _trackType = value.tracking_type;
            // && value.total_quantity > 0

            if (parseInt(value.stock_type) == 2) {
              if (
                value.tracking_type == "Serial" &&
                value.total_quantity > 0
              ) {
                if (this.state.availableForSale == 0) {
                  _serialText = (
                    <a
                      className="dropdown-item"
                      onClick={this.clickSerialInfo.bind(this, value.item_id)}
                    >
                      View Serial
                    </a>
                  );
                } else {
                  _serialText = (
                    <a
                      className="dropdown-item"
                      onClick={this.openModalHandleSerialBatchView.bind(
                        this,
                        "modalViewSerial",
                        value.item_id
                      )}
                    >
                      View Serial
                    </a>
                  );
                }
                _actionExtra = (
                  <div className="btn-group" role="group">
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) && (
                      <button
                        type="button"
                        id="btnGroupVerticalDrop3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                    )}
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) && (
                      <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupVerticalDrop1"
                      >
                        <a
                          className="dropdown-item"
                          onClick={this.openModalHandlerSingleAdjust.bind(
                            this,
                            "modalSingleAdjustItem",
                            value.item_id,
                            _adjustStcokId,
                            "Serial",
                            "AddStock"
                          )}
                        >
                          Adjust Stock
                        </a>

                        {_serialText}

                        <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          data-id={value.id}
                          onClick={this.clickToDeleteAllSerial.bind(
                            this,
                            value.item_id
                          )}
                        >
                          Delete All Serial Data
                        </a>
                      </div>
                    )}
                  </div>
                );
              } else if (
                value.tracking_type == "Serial" &&
                value.total_quantity == 0
              ) {
                _actionExtra = (
                  <div className="btn-group" role="group">
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) && (
                      <button
                        type="button"
                        id="btnGroupVerticalDrop3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                    )}
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) && (
                      <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupVerticalDrop1"
                      >
                        <a
                          className="dropdown-item"
                          onClick={this.openModalHandlerSingleAdjust.bind(
                            this,
                            "modalSingleAdjustItem",
                            value.item_id,
                            _adjustStcokId,
                            "Serial",
                            "AddStock"
                          )}
                        >
                          Adjust Stock
                        </a>
                      </div>
                    )}
                  </div>
                );
              } else if (
                value.tracking_type == "Batch" &&
                value.total_quantity > 0
              ) {
                _actionExtra = (
                  <div className="btn-group" role="group">
                    {_businessSetting.BatchNoChk == 1 && (
                      <button
                        type="button"
                        id="btnGroupVerticalDrop3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                    )}
                    {_businessSetting.BatchNoChk == 1 && (
                      <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupVerticalDrop1"
                      >
                        <a
                          className="dropdown-item"
                          onClick={this.openModalHandlerSingleAdjust.bind(
                            this,
                            "modalSingleAdjustItem",
                            value.item_id,
                            _adjustStcokId,
                            "Batch",
                            "AddStock"
                          )}
                        >
                          Adjust Stock
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={this.openModalHandleSerialBatchView.bind(
                            this,
                            "modalViewBatch",
                            value.item_id
                          )}
                        >
                          View Batch
                        </a>

                        <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          data-id={value.id}
                          onClick={this.clickToDeleteAllBatch.bind(
                            this,
                            value.item_id
                          )}
                        >
                          Delete All Batch Data
                        </a>
                      </div>
                    )}
                  </div>
                );
              } else if (value.tracking_type == "Normal") {
                _actionExtra = (
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      id="btnGroupVerticalDrop3"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="btnGroupVerticalDrop1"
                    >
                      <a
                        className="dropdown-item"
                        onClick={this.openModalHandlerSingleAdjust.bind(
                          this,
                          "modalSingleAdjustItem",
                          value.item_id,
                          _adjustStcokId,
                          "Normal",
                          "AddStock"
                        )}
                      >
                        Adjust Stock
                      </a>
                    </div>
                  </div>
                );
              } else {
                _actionExtra = <div className="btn-group" role="group"></div>;
              }
            } else {
              if (value.total_quantity > 0) {
                _editStockOption = (
                  <a
                    className="dropdown-item"
                    onClick={this.viewSerialDetails.bind(
                      this,
                      value.adjustmentId
                    )}
                  >
                    View Stock
                  </a>
                );
              } else {
                _editStockOption = (
                  <a
                    className="dropdown-item"
                    onClick={this.clickSerialInfo.bind(this, value.item_id)}
                  >
                    View Stock
                  </a>
                );
              }
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    {/* <a className="dropdown-item" onClick={this.openModalHandlerAdjust.bind(this,'modalAdjustItem',value.id)}> */}
                    {_editStockOption}
                    {trackingType == "Serial" && (
                      <a
                        className="dropdown-item"
                        href="javascript:void(0)"
                        data-id={value.id}
                        onClick={this.clickToDeleteAdjust.bind(
                          this,
                          value.id,
                          trackingType
                        )}
                      >
                        Delete
                      </a>
                    )}
                  </div>
                </div>
              );
            }
          } else {
            _dateShow = value.invoice_date;
            let convertToSaleAction = "";
            let convertToPurchaseAction = "";
            let duplicateSaleAction = "";
            let duplicatePurchaseAction = "";
            let actionTrans = "#";
            let _type = "&tType=ITEM";
            if (value.type == "PURCHASE") {
              actionTrans =
                global.webUrl +
                "business/add-purchase/?auth=" +
                value.id +
                _type;
            } else if (value.type == "PURCHASE ORDER") {
              actionTrans =
                global.webUrl +
                "business/add-purchase-order/?auth=" +
                value.id +
                _type;
              convertToPurchaseAction = global.webUrl + "business/manage-purchase-to-duplicate/?auth=" + value.id+'&tType=ORDER';
              duplicatePurchaseAction = global.webUrl + "business/manage-purchase-order-duplicate/?auth=" + value.id;
            } else if (value.type == "DEBIT NOTE") {
              actionTrans =
                global.webUrl +
                "business/add-purchase-debit-note/?auth=" +
                value.id +
                _type;
            } else if (value.type == "PAYMENT-OUT") {
              actionTrans =
                global.webUrl +
                "business/add-purchase-payment-out/?auth=" +
                value.id +
                _type;
            } else if (value.type == "SALE") {
              //_type = "&tType="+value.type;
              actionTrans =
                global.webUrl + "business/add-sale/?auth=" + value.id + _type;
            } else if (value.type == "SALE ORDER") {
              actionTrans =
                global.webUrl +
                "business/add-sale-order/?auth=" +
                value.id +
                _type;
              convertToSaleAction = global.webUrl + 'business/add-sale-duplicate/?auth='+value.id+'&tType=ORDER';
              duplicateSaleAction = global.webUrl + 'business/add-sale-duplicate-order?auth='+value.id;
            } else if (value.type == "ESTIMATE") {
              actionTrans =
                global.webUrl +
                "business/add-sale-estimate/?auth=" +
                value.id +
                _type;
            } else if (value.type == "CREDIT NOTE") {
              actionTrans =
                global.webUrl +
                "business/add-sale-credit-note/?auth=" +
                value.id +
                _type;
            } else if (value.type == "PAYMENT-IN") {
              actionTrans =
                global.webUrl +
                "business/add-sale-payment-in/?auth=" +
                value.id +
                _type;
            } else if (value.type == "DELIVERY CHALLAN") {
              actionTrans =
                global.webUrl +
                "business/add-sale-delivery-challan/?auth=" +
                value.id +
                _type;
            }
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a className="dropdown-item" href={actionTrans}>
                      View/Edit Details
                    </a>
                  }
                  {
                    (convertToSaleAction!=''  && _trans_duplicate_normal == 'Normal') &&
                    <a className="dropdown-item" href={convertToSaleAction}>
                      Convert to sale
                    </a>
                  }
                  {
                    (duplicateSaleAction!=''  && _trans_duplicate_normal == 'Normal')  &&
                    <a className="dropdown-item" href={duplicateSaleAction}>
                      Duplicate
                    </a>
                  }
                  {
                    (convertToPurchaseAction!='' && _trans_duplicate_normal == 'Normal') &&
                    <a className="dropdown-item" href={convertToPurchaseAction}>
                      Convert to purchase
                    </a>
                  }
                  {
                    (duplicatePurchaseAction!='' && _trans_duplicate_normal == 'Normal') &&
                    <a className="dropdown-item" href={duplicatePurchaseAction}>
                      Duplicate
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(
                      this,
                      value.id,
                      value.type
                    )}
                  >
                    Delete
                  </a>
                  {/* isChallanPreview={false} */}
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );
          }
          let _showDupL = '';
          if(_trans_duplicate_normal == 'Duplicate'){
              _showDupL = ' (Duplicate)';
          }
          console.log('value', JSON.stringify(value))
          rows.push({
            srNo: _ii, //value.srNo,
            invoice_date: _dateShow, //value.invoice_date,
            invoice_no: value.invoice_no,
            sale_price: numberFormat(value.sale_price),
            total_quantity: value.total_quantity,
            tracking_type: trackingType,
            party_name: value.party_name,
            type: value.type + _showDupL,
            status: _status,
            balance: numberFormat(value.balance),
            actionextra: _actionExtra,
          });
        });
      }
    }
    console.log('rows', rows)
    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalAdjustItem") {
      this.setState({ modalIsOpen_AJP: true });
    } else if (popId == "modalSingleAdjustItem") {
      this.setState({ modalIsOpen_SAStock: true });
    } else if (popId == "modalAddItem") {
      this.setState({ modalIsOpen_AC: true });
    } else if (popId == "modalUploadItems") {
      this.setState({ modalIsOpen_IMI: true });
    } else if (popId == "modalUploadInActiveItem") {
      this.setState({ modalIsOpen_InActiveItem: true });
    } else if (popId == "modalUploadActiveItem") {
      this.setState({ modalIsOpen_ActiveItem: true });
    } else if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: true });
    } else if (popId == "modalUploadAssignUnit") {
      this.setState({ modalIsOpen_ActiveAssignUnit: true, dataType: "ITEM" });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustItem") {
      this.setState({ modalIsOpen_AJP: false });
    } else if (popId == "modalSingleAdjustItem") {
      this.setState({ modalIsOpen_SAStock: false });
    } else if (popId == "modalAddItem") {
      this.setState({ modalIsOpen_AC: false });
    } else if (popId == "modalUploadItems") {
      this.setState({ modalIsOpen_IMI: false });
    } else if (popId == "modalUploadInActiveItem") {
      this.setState({ modalIsOpen_InActiveItem: false });
    } else if (popId == "modalUploadActiveItem") {
      this.setState({ modalIsOpen_ActiveItem: false });
    } else if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: false });
    } else if (popId == "modalUploadAssignUnit") {
      this.setState({ modalIsOpen_ActiveAssignUnit: false });
    }
  };

  openModalHandlerAdjust(popId, adjustId) {
    if (popId == "modalAdjustItem") {
      this.setState({ modalIsOpen_AJP: true, adjustmentId: adjustId });
    } else if (popId == "modalIShare") {
      this.setState({ modalIsOpen_IShare: true, itemId: adjustId });
    }
  }

  viewSerialDetails(adjustmentId){
      const requestData = JSON.stringify({
        adjustmentId: adjustmentId,
      });
      PostData(global.userItemStockAdjustmentDetail, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
        
              this.setState({
                viewSerialModal: true,
                viewData: responseJson.response,
              });
            }
          }
        })
        .catch((error) => this.setState({ error}));
  }

  removeFormFields(itemId, batch_no, batch_id, adjustmentId) {
   
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: itemId,
              batch_no: batch_no,
              batch_id: batch_id,
            });
            PostData(global.userItemSingleBatchDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification("Error Message 😓", responseJson.message, "danger");
                  return;
                } else {
                  this.closeModalHandlerAdjust(
                    "viewSerialModalId"
                  )
                  this.getTransactionListData(itemId,this.state.page,this.state.pageCount);

                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  openModalHandlerSingleAdjust(
    popId,
    itemId,
    adjustId,
    itemAdjustType,
    stockAddEditType
  ) {
    if (popId == "modalSingleAdjustItem") {
      this.setState({
        modalIsOpen_SAStock: true,
        adjustmentId: adjustId,
        itemIdAdjust: itemId,
        itemAdjustType: itemAdjustType,
        stockAddEditType: stockAddEditType,
      });
    }
  }

  openModalHandleSerialBatchView(popId, itemId) {
    if (popId == "modalViewSerial") {
      this.setState({ modalViewSerial: true, itemId: itemId });
    } else if (popId == "modalViewBatch") {
      this.setState({ modalViewBatch: true, itemId: itemId });
    }
  }
  closeModalHandlerAdjust = (popId) => {
    if (popId == "modalAdjustItem") {
      this.setState({ modalIsOpen_AJP: false, adjustmentId: 0 });
    } else if (popId == "modalIShare") {
      this.setState({ modalIsOpen_IShare: false, itemId: 0 });
    } else if (popId == "modalViewSerial") {
      this.setState({ modalViewSerial: false, itemId: 0 });
    } else if (popId == "modalViewBatch") {
      this.setState({ modalViewBatch: false, itemId: 0 });
    } else if (popId == "modalSingleAdjustItem") {
      this.setState({
        modalIsOpen_SAStock: false,
        adjustmentId: 0,
        itemIdAdjust: 0,
        itemAdjustType: "",
      });
    } else if(popId == "viewSerialModalId"){
      this.setState({viewSerialModal: false})
    }
  };
  /*End Modal open close*/

  /* Code For Upload - Import Excel */
  onChangeFileUploadHandler = (event) => {
    this.setState({ importExcelData: [] });
    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
              // evt = on_file_select event
              /* Parse data */
              const bstr = evt.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
              /* Update state */
              //console.log("Data>>>" + data);// shows that excel data is read
              let _jsonData = this.convertToJson(data);
              //console.log(_jsonData); // shows data in json format
              this.setState({
                importExcelData: _jsonData,
                importExcelFile: file,
              });
            };
            reader.readAsBinaryString(file);
          });
        })
      );
      let errors = this.state.errors;
      errors["importExcelFile"] = "";
      this.setState({ errors: errors });
    }
  };

  convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var currentline = lines[i].split(",");
      let _fileColumn = 8;
      var obj = {};
      for (var j = 0; j < _fileColumn; j++) {
        if (currentline[0]!="") {
          if (currentline[3] > 0) {
            obj[headers[j]] = currentline[j];
          }
        }
      }

      //if(obj['Sale price'] > 0){
      console.log("tabish", obj);
      if (
        obj["Sale price"] > 0 &&
        obj["Purchase price"] > 0 &&
        obj["Opening stock quantity"] >= 0 &&
        obj["Item name"]!="" &&
        obj["Minimum stock quantity"] >= 0
      ) {
        result.push(obj);
      }
    }
    return JSON.stringify(result); //JSON
  }

  clickToImportSheet = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.importExcelFile == "") {
      _isError = true;
      errors["importExcelFile"] = "*Please Upload Item Excel File.";
      this.setState({ errors: errors });
    } else {
      _isError = false;
      errors["importExcelFile"] = "";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      this.importSheetMethodCall();
    }
  };

  importSheetMethodCall() {
    const requestData = JSON.stringify({
      importExcelData: this.state.importExcelData,
      userId: this.state.userId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      categoryId: 1,
    });

    if (JSON.parse(this.state.importExcelData).length > 0) {
      PostData(global.userImportItemSheet, requestData, "POST").then(
        (result) => {
          if (result.success == true) {
            sendNotification("Success Message 👍", result.message, "success");
            this.closeModalHandler("modalUploadItems");
            this.getItemListData(company_id);
            this.loadItemListing();
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
    } else {
      sendNotification(
        "Error Message 😓",
        "Please Upload Correct Item Excel File",
        "danger"
      );
      return;
    }
  }
  /* End Code For Upload - Import Excel */

  /* Adjust Item */
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "item_name":
        errors.item_name =
          value.length < 3 ? "*Item Name must be 3 characters long!" : "";
        this.setState({ item_name: value });
        break;
    }
  };

  clickToUpdateItemInfo = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.item_name == "") {
      _isError = true;
      errors["item_name"] = "*Please Enter Item Name";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.activeItemId > 0) {
        const requestData = JSON.stringify({
          itemId: this.state.activeItemId,
          businessId: this.state.businessId,
          company_id: this.state.company_id,
          item_name: this.state.item_name,
        });

        PostData(global.userEditItemInfo, requestData, "POST").then(
          (result) => {
            if (result.success == true) {
              sendNotification("Success Message 👍", result.message, "success");
              this.closeModalHandler("modalAdjustItem");
              this.loadItemListing();
            } else {
              sendNotification("Error Message 😓", result.message, "danger");
              let errors = this.state.errors;
              errors["item_name"] = result.message; //'Please enter valid phone no.';
              this.setState({
                errors: errors,
              });
              return;
            }
          }
        );
      }
    }
  };
  /* End Here Adjust Item*/

  /* party Filter Code */
  onItemFilterName = (e) => {
    const data = this.state.itemArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ itemArrayFilter: filtered });
    this.loadItemListing();
  };
  /* End party Filter Code */

  /*Begin Filter Data */
  onFilterChange = (e) => {
    let activeValue = e.target.checked;
    this.setState({ activeFilter: activeValue });
  };
  onFilterInActiveChange = (e) => {
    let inActiveValue = e.target.checked;
    this.setState({ inActiveFilter: inActiveValue });
  };
  clickDataFilter = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    let activeFilter = this.state.activeFilter;
    let inActiveFilter = this.state.inActiveFilter;
    if (activeFilter == false && inActiveFilter == false) {
      _isError = true;
      errors["chkDataFilter"] = "";
      this.setState({ errors: errors });
    } else {
      errors["chkDataFilter"] = "";
    }

    if (_isError == false) {
      this.addFilterDataMethodCall();
    }
  };

  addFilterDataMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      activeFilter: this.state.activeFilter,
      inActiveFilter: this.state.inActiveFilter,
    });
    PostData(global.userItemList, requestData, "POST").then((result) => {
      let responseJson = result;
      if (responseJson.success == false) {
        this.setState({
          itemArrayFilter: [],
          itemArray: [],
          isLoaded: true,
          item_name: "",
          activeItem: "",
          activeItemId: 0,
          activeItemQty: 0,
          activeSalePrice: 0,
          activePurchasePrice: 0,
          activeStockPrice: 0,
          totalStockQtyAK: 0,
          availableForSale: 0,
          reservedQuantityAK: 0,
          activeItemCode: "",
          activeItemLocation: "",
        });

        this.getTransactionListData(0,this.state.page,this.state.pageCount);
      } else {
        let _itemsArray = [];
        let _activeItem,
          _activeItemId,
          _activeItemQty,
          _activeSalePrice,
          _activePurchasePrice = "";
        if (responseJson.response) {
          _itemsArray = responseJson.response;
          _itemsArray.map((itm, i) => {
            if (i == 0) {
              _itemsArray[i].isActiveClick = true;
              _activeItem = itm.name;
              _activeItemId = itm.item_id;
              _activeItemQty = itm.min_stock_quantity;
              _activeSalePrice = itm.sale_price;
              _activePurchasePrice = itm.purchase_price;
            } else {
              _itemsArray[i].isActiveClick = false;
            }
          });
        }
        this.setState({
          itemArrayFilter: _itemsArray,
          itemArray: _itemsArray,
          isLoaded: true,
          item_name: _activeItem,
          activeItem: _activeItem,
          activeItemId: _activeItemId,
          activeItemQty: _activeItemQty,
          activeSalePrice: _activeSalePrice,
          activePurchasePrice: _activePurchasePrice,
        });
        if (_activeItemId > 0) {
          this.getTransactionListData(_activeItemId,this.state.page,this.state.pageCount);
        }
      }
    });
  }

  uncheckAll = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    errors["chkDataFilter"] = "";
    let _isError = false;
    this.setState({
      errors: errors,
      activeFilter: false,
      inActiveFilter: false,
    });
    document
      .querySelectorAll('input[type="checkbox"]')
      .forEach((el) => (el.checked = false));
    this.state.activeFilter = false;
    this.state.inActiveFilter = false;
    this.addFilterDataMethodCall();
  };
  /*Filter Data End*/
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_ActiveUnitConversion: false });
    this.setState({ modalIsOpen_ActiveAssignUnit: false });
    this.getItemListData(company_id);
  };
  setFilterSearch = () => {
    this.setState({ filterSearch: !this.state.filterSearch });
  };
  setFilterSearchCancel = () => {
    this.setState({
      filterSearch: !this.state.filterSearch,
      itemArrayFilter: this.state.itemArray,
    });
    this.loadItemListing();
  };
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Item List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/add-item/"}
            >
              Add Item
            </a>
            <span className="breadcrumb-item active">Manage Item List</span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    {this.state.filterSearch ? (
                      <div className="mb-10">
                        <button
                          type="button"
                          data-toggle="layout"
                          data-action="header_search_on"
                          className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                          onClick={this.setFilterSearch}
                        >
                          <i className="fa fa-search"></i>
                        </button>

                        <div
                          className="btn-group pull-right"
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.openModalHandler.bind(
                              this,
                              "modalAddItem"
                            )}
                          >
                            <i className="fa fa-plus mr-5"></i> <b>Add Item</b>
                          </button>
                          <div className="btn-group show" role="group">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              id="toolbarDrop"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                            ></button>
                            <div
                              className="dropdown-menu dropdown-menu-right min-width-200"
                              aria-labelledby="toolbarDrop"
                              style={{ margin: "0px" }}
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadItems"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-file-text mr-5"></i>Import
                                Item
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadInActiveItem"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-ban "></i>Mark Item as
                                Inactive
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadActiveItem"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-ban "></i>Mark Item as
                                Active
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadAssignUnit"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-ban "></i>Assign Units
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div id="page-header-search" className="show mb-10">
                        <div className="content-header content-header-fullrow mb-10">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-toggle="layout"
                                data-action="header_search_off"
                                onClick={this.setFilterSearchCancel}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                            <input
                              type="text"
                              onChange={this.onItemFilterName}
                              className="form-control"
                              placeholder="Search here.."
                              id="page-header-search-input"
                              name="page-header-search-input"
                              autoComplete="off"
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th>Item</th>
                          <th style={{ padding: "0px 25px" }}>
                            <div
                              className="pull-right btn-group show"
                              role="group"
                            >
                              <a
                                type="button"
                                className="dropdown-toggle text-danger"
                                id="toolbarDrop"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <i className="fa fa-filter"></i>
                              </a>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="toolbarDrop"
                                style={{
                                  margin: "0px",
                                  left: "-30px",
                                  width: "160px",
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="activeItem"
                                    name="activeItem"
                                    value="1"
                                    onChange={this.onFilterChange}
                                  />
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "13px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Active
                                  </span>
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="inActiveItem"
                                    name="inActiveItem"
                                    value="1"
                                    onChange={this.onFilterInActiveChange}
                                  />
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "13px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    InActive
                                  </span>
                                </div>
                                <span className="vs_error_txt">
                                  {errors.chkDataFilter.length > 0 && (
                                    <span className="error">
                                      {errors.chkDataFilter}
                                    </span>
                                  )}
                                </span>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-primary mt-10 mr-10"
                                  style={{ margin: "0px" }}
                                  onClick={this.uncheckAll}
                                >
                                  Clear All
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-info mt-10"
                                  style={{ margin: "0px" }}
                                  onClick={this.clickDataFilter}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </th>
                          <th colSpan="2">Quantity</th>
                        </tr>
                      </thead>
                      {this.state.itemArrayFilter.length > 0 && (
                        <tbody id="sideTable">{this.loadItemListing()}</tbody>
                      )}
                    </table>
                    {this.state.itemArrayFilter.length == 0 && (
                      <p>No item to show</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    <div className="col-lg-12">
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <b>{this.state.activeItem}</b>{" "}
                          <i className="si si-pin"></i>
                          {/* <EmailShareButton
                                                    subject ="Demo Mail"
                                                    body  ="Mail Send"
                                                >
                                                    <EmailIcon size={32} round={true} />
                                                </EmailShareButton> */}
                          {this.state.activeItemId > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary mr-5 mb-5"
                              onClick={this.openModalHandlerAdjust.bind(
                                this,
                                "modalIShare",
                                this.state.activeItemId
                              )}
                            >
                              <i className="fa fa-share "></i>
                            </button>
                          )}
                          {/* <button type="button" className="btn btn-sm btn-secondary mr-5 mb-5 pull-right" onClick={this.openModalHandlerAdjust.bind(this,'modalAdjustItem','0')}>
                                                    <i className="si si-equalizer mr-5"></i>Adjust Stock
                                                </button> */}
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Sale Price :{" "}
                            {numberFormat(this.state.activeSalePrice)}
                          </span>

                          <span className="pull-right">
                            Stock Quantity : {this.state.totalStockQtyAK}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Purchase Price :{" "}
                            {numberFormat(this.state.activePurchasePrice)}
                          </span>
                          <span className="pull-right">
                            Stock Value :{" "}
                            {numberFormat(this.state.activeStockPrice)}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Available For Sale : {this.state.availableForSale}
                          </span>

                          <span className="pull-right">
                            Reserved Quantity : {this.state.reservedQuantityAK}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>Item Code : {this.state.activeItemCode}</span>

                          <span className="pull-right">
                            Location : {this.state.activeItemLocation}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="block-content">
                  <MDBDataTableV5
                    // hover
                    // entriesOptions={[25, 50, 100]}
                    // entries={25}
                    // pagesAmount={4}
                    // span
                    // pagingTop
                    // searchTop
                    // searchBottom={false}
                    // barReverse
                    // data={this.loadSaleListing()}
                    hover
                    span
                    displayEntries={false}
                    entries={100}
                    pagingTop={false}
                    searchTop={false}
                    searchBottom={false}
                    info={false}
                    barReverse
                    data={this.loadSaleListing()}
                  />
                </div>

                  {/* <div style={{ float: "right", marginTop: "-45px" }}>
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center">
                        <div role="status" aria-live="polite"> Rows per page:</div>
                        </div>
                        <div className="d-flex align-items-center" >
                        <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                onChange={(event) => {
                                    event.preventDefault();
                                    const { name, value } = event.target;
                                    this.setState(prevState => ({
                                            pageCount : value,
                                            setPage : 1,
                                        }),
                                        () => {
                                            this.getTransactionListData(this.state.activeItemId,1,
                                                value
                                            );
                                        });
                                }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        </div>
                        <div className="d-flex align-items-center">
                        <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="block-content">
                    <div style={{ float: "left", marginTop: "-45px" }}>
                    <PaginationControl
                        between={this.state.pageCount}
                        total={this.state.totalCount}
                        limit={this.state.pageCount}
                        changePage={(page) => {
                        this.setState({ setPage: page });
                        this.getTransactionListData(this.state.activeItemId,page,this.state.pageCount
                        );
                        }}
                        ellipsis={1}
                        activeClass={this.state.setPage}
                        page={this.state.setPage}
                    />
                    </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Party Add Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AC}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Item</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickAddNewItem
                      isMainPage={false}
                      isRedirectTo={"business/manage-items/"}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this, "modalAddItem")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Party Add*/}

        {/* Modal Item Adjustment Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AJP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="modalAdjustItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Stock Adjustment</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "modalAdjustItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickItemAdjustment
                      isMainPage={false}
                      isRedirectTo={"business/manage-items/"}
                      itemName={this.state.activeItem}
                      itemId={this.state.activeItemId}
                      adjustmentId={this.state.adjustmentId}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "modalAdjustItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Adjustment Begins */}

        <Modal
          isOpen={this.state.viewSerialModal}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="viewSerialModalId"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Stock</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "viewSerialModalId"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <div
                      style={{
                        height: "40px !important",
                        margin: "0 0 14px 0 !important",
                      }}
                      className="block-content"
                      >
                      {this.state.viewData.map((element, index) => (
                        <div>     
                          <h3 className="block-title">Item Name : {element.item_name}</h3>
                          {
                            (element.inventrory_quantity_type == 'Batch' && element.itemBatchNo.length == 0) ?
                            <div className="form-group">
                                <div className="row">
                                  <div className="col-3">
                                      <div className="form-material open">
                                        <label htmlFor="login-username">
                                          You have no any batch
                                        </label>
                                      </div>
                                    </div>  
                                </div>
                            </div> : 
                            (element.itemBatchNo.length == 0 && element.itemSerialNo == 0) &&
                            <div className="form-group">
                                <div className="row">
                                  <div className="col-3">
                                      <div className="form-material open">
                                        <p>{element.stock_quantity}</p>
                                        <label htmlFor="login-username">
                                          Stock Quantity
                                        </label>
                                      </div>
                                    </div>  
                                </div>
                            </div>
                          }
                          
                          {
                            element.itemBatchNo.filter((item) => item.add_stock > 0 || item.reduce_stock > 0).map((data, index) => (
                              <div className="form-group">
                                <div className="row">
                                  {_businessSetting.BatchNoChk == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                        <p>{data.batch_no || ""}</p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.BatchNoTxt}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.MfgDateChk == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                        <p>{data.mfg_date || ""}</p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.MfgDateTxt}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.ExpDateChk == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                        <p>{data.exp_date || ""}</p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.ExpDateTxt}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.ModelNoChk == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                        <p>{data.model_no || ""}</p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.ModelNoTxt}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.SizeChk == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                        <p>{data.size || ""}</p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.SizeTxt}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.BatchNoAdditionalChk1 == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                      <p>{data.BatchNoAdditionalValue1 || ""} </p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.BatchNoAdditionalLabel1}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.BatchNoAdditionalChk2 == 1 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                      <p>{data.BatchNoAdditionalValue2 || ""} </p>
                                        <label htmlFor="login-username">
                                          {_businessSetting.BatchNoAdditionalLabel2}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-3">
                                    <div className="form-material open">
                                      <p>{data.opening_stock} </p>
                                      <label htmlFor="login-username">Opening Stock </label>
                                    </div>
                                  </div>
                                  {data.add_stock > 0 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                      <p>{data.add_stock || ""} </p>
                                        <label htmlFor="login-username">
                                          Add Stock
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {data.reduce_stock > 0 && (
                                    <div className="col-3">
                                      <div className="form-material open">
                                      <p>{data.reduce_stock || ""} </p>
                                        <label htmlFor="login-username">
                                          Reduce Stock
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  
                                  <div className="col-3">
                                  <div className="form-material open">
                                    <a
                                      className="badge pull-right"
                                      onClick={this.removeFormFields.bind(
                                        this,
                                        element.item_id,
                                        data.batch_no,
                                        data.batch_id,
                                        element.id,
                                      )}
                                    >
                                      <i className="fa fa-solid fa-trash"></i>
                                      Delete Batch
                                    </a>
                                    </div>
                                  </div>
                                </div>
                                {(index != element.itemBatchNo.length -1 ) && <hr />}
                              </div>
                            ))
                          }
                          {element.itemSerialNo.map((data, index) => (
                            <div style={{marginTop: 20}}>
                              <p>{index+1},</p>
                                <div
                            style={{
                              marginLeft: 30, marginTop: -40
                            }}
                            >
                              {_businessSetting.SerialNoChk == 1 && (
                                <p>
                                  {_businessSetting.SerialNoTxt} :{" "}
                                  {data.serial_no}
                                </p>
                              )}
                              {_businessSetting.SerialNoAdditionalChk1 == 1 && (
                                <p>
                                  {_businessSetting.SerialNoAdditionalLabel1} :{" "}
                                  {data.serialNoAdditionalValue1
                                    ? data.serialNoAdditionalValue1
                                    : "NA"}
                                </p>
                              )}
                              {_businessSetting.SerialNoAdditionalChk2 == 1 && (
                                <p>
                                  {_businessSetting.SerialNoAdditionalLabel2} :{" "}
                                  {data.serialNoAdditionalValue2
                                    ? data.serialNoAdditionalValue2
                                    : "NA"}
                                </p>
                              )}
                              {(index != element.itemSerialNo.length -1 ) && <hr />}
                              
                            </div>
                            </div>
                          ))}
                        </div>
                        
                      ))}
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "viewSerialModalId"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* Modal Item Single Adjustment Begins */}

        <Modal
          isOpen={this.state.modalIsOpen_SAStock}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="modalSingleAdjustItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Stock Adjustment</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "modalSingleAdjustItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickItemSingleAdjustment
                      isMainPage={false}
                      isRedirectTo={"business/manage-items/"}
                      itemName={this.state.activeItem}
                      itemId={this.state.itemIdAdjust}
                      adjustmentId={this.state.adjustmentId}
                      itemAdjustType={this.state.itemAdjustType}
                      stockAddEditType={this.state.stockAddEditType}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "modalSingleAdjustItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Single Adjustment Begins */}

        {/* Modal Edit Party Details Begins */}
        {/* <Modal isOpen={this.state.modalIsOpen_AJP} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustItem">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Item Adjustment</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustItem')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content">
                                    <div className="block-content">
                                    <form method="post" id="form-login" className="webForm px-30">
                                        <h3 className="content-heading" style={{paddingTop: '6px'}}><i className="fa fa-angle-right"></i> Personal Details </h3>
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" className="form-control" id="item_name" name="item_name" value={this.state.item_name} placeholder="Enter Item Name" onChange={this.handleChange}/>
                                                    <label htmlFor="login-username">Item Name</label>
                                                    <span className="vs_error_txt">{errors.item_name.length > 0 && <span className='error'>{errors.item_name}</span>}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="claerfix"></div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-alt-primary" disabled={isDisabled} onClick={this.clickToUpdateItemInfo}>
                                                    <i className="fa fa-check mr-5"></i> Save Item Details
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                    <br/>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustItem')}>Close</button>

                            </div>
                        </div>
                    </div>
                </Modal> */}
        {/* Modal End Edit Party Details*/}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_IMI}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadItems"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Import Excel - Item Details</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadItems"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <form
                      method="post"
                      id="form-login"
                      className="webForm px-30"
                    >
                      <h3
                        className="content-heading"
                        style={{ paddingTop: "6px" }}
                      >
                        <i className="fa fa-angle-right"></i> Upload Item Excel
                        Details{" "}
                      </h3>
                      <div className="form-group row">
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              accept=".xls,.xlsx"
                              type="file"
                              required="required"
                              className="form-control"
                              id="importExcelFile"
                              name="importExcelFile"
                              onChange={this.onChangeFileUploadHandler}
                              onWheel={() => document.activeElement.blur()}
                            />
                            <label htmlFor="login-username">
                              Upload Import Excel Item File
                            </label>
                            <span className="vs_error_txt">
                              {errors.importExcelFile.length > 0 && (
                                <span className="error">
                                  {errors.importExcelFile}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <span className="font-w600 text-info justify-content-end">
                            Download .xls/.xlsx (excel file) template file to
                            upload data
                          </span>
                          <br />
                          <a
                            href={
                              global.webUrl +
                              "sample-file/Import Items Template.xlsx"
                            }
                            target="_blank"
                            className="btn btn-alt-info min-width-125"
                          >
                            Download <i className="fa fa-file-excel-o ml-5"></i>
                          </a>
                        </div>
                      </div>
                      <div className="claerfix"></div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            type="submit"
                            disabled={isDisabled}
                            className="btn btn-sm btn-hero btn-alt-primary"
                            onClick={this.clickToImportSheet}
                          >
                            <i className="fa fa-check mr-5"></i> Import Item
                            Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadItems"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}

        {/* Modal Add  Item Inactive Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_InActiveItem}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadInActiveItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Item Inactive</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadInActiveItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddInActiveItem
                    isRedirectTo={"business/manage-items/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadInActiveItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Item Inactive Details*/}

        {/* Modal Add  Item Active Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveItem}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadActiveItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Item Active</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadActiveItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddActiveItem isRedirectTo={"business/manage-items/"} />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadActiveItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Item Active Details*/}

        {/* Modal Add  Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversion}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversion"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Unit Conversion</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversion"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddUniConversion
                    isRedirectTo={"business/manage-items/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversion"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Unit Conversion Details*/}

        {/* Modal Assign Unit Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveAssignUnit}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadAssignUnit"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Assign Units</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadAssignUnit"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAssignUnit
                    dataType={this.state.dataType}
                    isRedirectTo={"business/manage-items/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadAssignUnit"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Assign Unit Details*/}

        {/* Modal Item Share Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_IShare}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="modalIShare"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Share Item</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "modalIShare"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickItemShare
                      isMainPage={false}
                      isRedirectTo={"business/manage-items/"}
                      itemName={this.state.activeItem}
                      activeItemId={this.state.activeItemId}
                      sendType="Item"
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "modalIShare"
                  )}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Share End */}

        {/* Modal Item View Serial Data */}
        <Modal
          isOpen={this.state.modalViewSerial}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="modalAdjustItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Serials</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "modalViewSerial"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickViewSerialList
                      itemId={this.state.itemId}
                      callBack={(itemId) => {
                        this.setState({ modalViewSerial: false });
                        this.getTransactionListData(itemId,this.state.page,this.state.pageCount);
                        this.getItemListData(this.state.company_id);
                      }}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "modalViewSerial"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item View Serial Data*/}

        {/* Modal Item View Batch Data */}
        <Modal
          isOpen={this.state.modalViewBatch}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="modalAdjustItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Batch</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "modalViewBatch"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickViewBatchList
                      itemId={this.state.itemId}
                      callBack={(itemId) => {
                        this.setState({ modalViewBatch: false });
                        this.getTransactionListData(itemId,this.state.page,this.state.pageCount);
                        this.getItemListData(this.state.company_id);
                      }}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "modalViewBatch"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item View Serial Data*/}
      </React.Fragment>
    );
  }
}
