import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Plan Name',
        field: 'plan_name',
        width: 150
    },
    {
        label: 'Plan Heading',
        field: 'plan_heading',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';


export default class companyListPanel extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            plan_name : '',
            plan_type : '',
            plan_heading : '',
            plan_details : '',
            planArray : [],
            planId : 0,
            fields : [],
            errors: {
                plan_name : '',
                plan_type : '',
                plan_heading : '',
                plan_details : ''
            }
        }
    }

    async componentDidMount ()
    {
        /* Get Url Pass Parameter */
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _planId = _handleParam[1] ? _handleParam[1] : 0;
        /* End Here Url Pass Parameter */

        if(_planId > 0){
            let _planArray = [];
            let _url_GetData = global.webistePlanDetails+'?planId='+_planId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _planArray = responseJson.response[0];

                        let _feildArray = [];
                        if(_planArray.plan_details!=''){
                            _feildArray = JSON.parse(atob(_planArray.plan_details));
                        }
                        
                        this.setState({
                            buttonTitle:'Edit',
                            plan_name:_planArray.plan_name,
                            plan_type:_planArray.plan_type,
                            plan_heading:(_planArray.plan_heading),
                            plan_details:(_planArray.first_tag),
                            fields:_feildArray,
                            planId:_planArray.plan_id,
                            isLoaded:true
                        });
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
        this.planListData();
    }

    planListData(){
        let _planArray = [];

        let _url_GetData = global.webistePlanList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _planArray = responseJson.response;
                }
                this.setState({planArray:_planArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'plan_name': 
            errors.plan_name = 
                value.length < 3 ? '*Plan name must be 3 characters long!' : '';
                this.setState({plan_name:value}); 
            break;
            case 'plan_type': 
            errors.plan_type = 
                value.length < 3 ? '*Plan type must be 3 characters long!' : '';
                this.setState({plan_type:value}); 
            break;
            case 'plan_heading': 
            errors.plan_heading = 
                value.length < 3 ? '*plan heading must be 3 characters long!' : '';
                this.setState({plan_heading:value}); 
            break;
            case 'plan_details': 
            errors.plan_details = 
                value.length < 3 ? '*plan detail must be 3 characters long!' : '';
                this.setState({plan_details:value}); 
            break;
            
            default:
            break;
        }
    }

    clickToAddPlan = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.plan_name == '')
        {
            _isError = true;
            errors['plan_name'] = "*Please Enter Plan Name";
            this.setState({errors: errors});
        }
        if(this.state.plan_type == '')
        {
            _isError = true;
            errors['plan_type'] = "*Please Enter Plan Type";
            this.setState({errors: errors});
        }
        if(this.state.plan_heading == '')
        {
            _isError = true;
            errors['plan_heading'] = "*Please Enter Plan Heading";
            this.setState({errors: errors});
        }
        if(this.state.plan_details == '')
        {
            _isError = true;
            errors['plan_details'] = "*Please Enter Plan Details";
            this.setState({errors: errors});
        }

        if(_isError == false){
            if(this.state.planId > 0){
                this.editPlanMethodCall();
            }
            else{
                this.addPlanMethodCall();
            }
        }
    }

    editPlanMethodCall()
    {
        let buff = new Buffer(JSON.stringify(this.state.fields));
        let base64data = buff. toString('base64');

        const requestData = JSON.stringify({
            plan_name: this.state.plan_name,
            plan_type: this.state.plan_type,
            plan_heading: this.state.plan_heading,
            plan_details: this.state.plan_details,
            fields: base64data,
            planId: this.state.planId
        });
        PostData(global.adminEditWebistePlan, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-website-plan/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['plan_name'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    addPlanMethodCall(){
        let buff = new Buffer(JSON.stringify(this.state.fields));
        let base64data = buff. toString('base64');

        const requestData = JSON.stringify({
            plan_name: this.state.plan_name,
            plan_type: this.state.plan_type,
            plan_heading: this.state.plan_heading,
            plan_details: this.state.plan_details,
            fields: base64data
        });

        PostData(global.adminAddWebistePlan, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-website-plan/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['plan_name'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    loadPlanListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _planDetails = this.state.planArray;
            let dataList = [];
            let rows = [];

            if(_planDetails.length > 0)
            {                          
                _planDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    
                    rows.push({
                        plan_name: row.plan_name,
                        plan_type: row.plan_type,
                        plan_heading: (row.plan_heading),
                        srNo: row.srNo,
                        action:(
                            <div className="btn-group" role="group" key={'3212'+i} data-key={'3212'+i}>
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/manage-website-plan/?auth='+row.plan_id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }

    /* Add More Text Filed Area Code */
    handleAdd() {
        const values = this.state.fields;
        values.push({ value: null });
        this.setState({fields:values});
    }

    handleRemove(i) {
        const values = this.state.fields;
        values.splice(i, 1);
        this.setState({fields:values});
    }
    
    handleDynamicInputs(i, event) {
        const values = this.state.fields;
        values[i].value = event.target.value;
        this.setState({fields:values});
    }

    dynamicInputFiled(){
        let _dataSet = [];
        
        if(this.state.fields.length > 0){
            this.state.fields.map((field, idx) => {
                let _key = idx + 222;
                _dataSet.push(
                    <div key={_key} className="form-group row">
                        <div className="col-11">
                            <div className="form-material open" style={{paddingTop:'0px'}}>
                                <input 
                                    type="text" 
                                    required="required" 
                                    className="form-control" 
                                    id={"txtFiled_"+idx}
                                    name={"txtFiled_"+idx}
                                    value={field.value || ""} 
                                    placeholder="Plan Details Here" 
                                    onChange={this.handleDynamicInputs.bind(this,idx)}
                                />
                            </div>
                        </div>
                        <div className="col-1">
                        <button type="button" onClick={this.handleRemove.bind(this,idx)} className="btn btn-outline-danger">X</button>
                        </div>
                    </div>
                );
            });
        }
        
        return _dataSet;
    }
    /* End Add More Text Filed Area Code */
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Website Plan List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage Website Plan List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Website Plan</h3>
                        </div>
                        
                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="plan_name" name="plan_name" value={this.state.plan_name} placeholder="Plan Name Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Plan Name <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_name.length > 0 && <span className='error'>{errors.plan_name}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="plan_type" name="plan_type" value={this.state.plan_type} placeholder="Plan Type Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Plan Type <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_type.length > 0 && <span className='error'>{errors.plan_type}</span>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="plan_heading" name="plan_heading" value={this.state.plan_heading} placeholder="Plan Heading Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Plan Heading <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_heading.length > 0 && <span className='error'>{errors.plan_heading}</span>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-10">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="plan_details" name="plan_details" value={this.state.plan_details} placeholder="Plan Details Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Plan Details <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_details.length > 0 && <span className='error'>{errors.plan_details}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <a className="btn btn-sm btn-alt-primary" onClick={this.handleAdd.bind(this)}>+ Add More Plan Detail Tag</a>
                                    </div>
                                </div>
                                {this.dynamicInputFiled()}

                                <div className="form-group row">
                                    <div className="col-12">
                                        <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddPlan}>
                                            <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} Website Plan
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">List of Website Plan</h3>
                        </div>
                        <div className="block-content">
                            <MDBDataTableV5 
                            hover
                            entriesOptions={[25, 50, 100]}
                            entries={25}
                            pagesAmount={4}
                            span
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            data={this.loadPlanListing()} />
                        </div>
                    </div>    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div class="gl-ldr-cld">
                        <div class="gl-ldr-ctr">
                            <div class="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}