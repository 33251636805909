import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class QuickAddNewSaleNormalQuantity extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                    }
                }
            } 
        }
        let _transactionId = this.props.transactionId ?  this.props.transactionId : 0;
        let _without_sr_quantity = 0;
        if(_transactionId > 0){
            _without_sr_quantity = this.props.normalFQtyAdded ? this.props.normalFQtyAdded :0;
        }
        let _normalRemainingQuantity =  this.props.normalRemainingQuantity ? this.props.normalRemainingQuantity : 0;
        if(_normalRemainingQuantity < 0){
            _normalRemainingQuantity = 0
        }
        this.state = {
            itemId : this.props.itemId ? this.props.itemId : '0',
            normalRemainingQuantity : _normalRemainingQuantity,
            indexPosition : this.props.indexPosition ? this.props.indexPosition : 0,
            selectTypeTxt : this.props.selectTypeTxt ? this.props.selectTypeTxt : 'Normal',
            normalQuantityTYpe : this.props.normalQuantityTYpe ? this.props.normalQuantityTYpe : '',
            openingStock : 0,  
            isLoaded : true, 
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            transactionId : this.props.transactionId ?  this.props.transactionId : 0,
            normalUsedQty : this.props.normalUsedQty ? this.props.normalUsedQty : 0,
            usedFreeNormalQty : this.props.usedFreeNormalQty ?this.props.usedFreeNormalQty : 0,
            freeQtyAk : this.props.freeQtyAk ? this.props.freeQtyAk : 0,
            normalFQtyAdded : this.props.normalFQtyAdded ? this.props.normalFQtyAdded :0,
            
            addedQty : this.props.addedQty ?  this.props.addedQty : 0,
            normalQtyField : this.props.normalQtyField ? this.props.normalQtyField : 0,
            sendActualQty : this.props.sendActualQty ? this.props.sendActualQty : 0,
            itemTypeOption : this.props.itemTypeOption ? this.props.itemTypeOption : '',
            itemFields : this.props.itemFields ? this.props.itemFields : '',
            actualItemQty : 0,
            isError : false,
            without_sr_quantity : _without_sr_quantity,
            withoutTypeQty : 0,
            totalSumNorQty : 0,
            errors: {
                error  :  '',
                without_sr_quantity : '',
            }
        }
    }
    async componentDidMount ()
    {   
        /*if(this.state.addedQty > 0 && this.state.transactionId > 0){
            let  normalRemainingQuantity =  parseFloat(this.state.normalRemainingQuantity) - parseFloat(this.state.addedQty);
            if(normalRemainingQuantity <= 0){
                normalRemainingQuantity = 0;
                this.setState({normalRemainingQuantity : normalRemainingQuantity, normalQuantityTYpe : 'Purchase'});
            }
            else{
                this.setState({normalRemainingQuantity :normalRemainingQuantity});
            }
        }
        else{
            console.log(this.state.normalRemainingQuantity+"==>>=="+this.state.addedQty+"==>>=="+this.state.normalQtyField+"==>>=="+this.state.freeQtyAk+"==>>=="+this.state.normalUsedQty+"==>>=="+this.state.normalFQtyAdded+"==>>=="+this.state.totalQuantity);
        }*/
        console.log(this.props.usedFreeNormalQty+"==pos==",this.state.indexPosition);
        console.log("==itemFields==",JSON.stringify(this.state.itemFields));
        console.log(this.state.sendActualQty+'==>SActual--Total-->=='+this.state.totalQuantity+'==>addedQty--Total-->=='+this.state.itemTypeOption);

        

        /*let _normalRemainingQuantity  = 0;
        if(this.state.transactionId > 0){
            if(this.state.usedFreeNormalQty >0){
                _normalRemainingQuantity = parseFloat(this.state.sendActualQty) - parseFloat(this.state.usedFreeNormalQty);
            }else{
                _normalRemainingQuantity = parseFloat(this.state.sendActualQty);
            }
            //_normalRemainingQuantity = parseFloat(this.state.sendActualQty);
        }else{
            _normalRemainingQuantity = parseFloat(this.state.sendActualQty) - parseFloat(this.state.totalQuantity);
        }
        //let _normalRemainingQuantity = parseFloat(this.state.sendActualQty) - parseFloat(this.state.totalQuantity);
        if(_normalRemainingQuantity < 0){
            _normalRemainingQuantity = 0;
        }
      
        this.setState({normalRemainingQuantity :_normalRemainingQuantity});*/
        let _fieldData = this.state.itemFields;
        let _normaRmainQty = 0;
        let _actualItemQty = 0;
        let _oldQtySelect = 0
        let _freeQtySel = 0
        _fieldData.map((itemData, i) => {
            if (i == this.state.indexPosition) {
                _normaRmainQty =  itemData.normal_remaining_quantity > 0  ? itemData.normal_remaining_quantity : 0; 
                _oldQtySelect =  parseFloat(itemData.quantity) > 0  ? parseFloat(itemData.quantity) : 0; 
                _freeQtySel =  parseFloat(itemData.free_quantity) > 0  ? parseFloat(itemData.free_quantity) : 0;
                _normaRmainQty = parseFloat(this.state.sendActualQty) - (parseFloat(_oldQtySelect) + parseFloat(_freeQtySel))
                if(_normaRmainQty <= 0){
                    _normaRmainQty = 0;
                }
            }
        })

        //Start issue no 2270
        let _sumQty = 0;
        _fieldData.map((itemData, i) => {
            
            if (itemData.normal_quantity!='') {
                let _qtySum =parseFloat(itemData.normal_quantity) > 0 ? parseFloat(itemData.normal_quantity) : 0;
                _sumQty =  parseFloat(_sumQty) + parseFloat(_qtySum);
            }
        })
        // end issue no 2270

        _actualItemQty =  parseFloat(_normaRmainQty)  + parseFloat(this.state.normalUsedQty);
        console.log(this.state.normalUsedQty+"==_normaRmainQty==",_normaRmainQty);
        console.log("==_actualItemQty==",_actualItemQty);
        this.setState({normalRemainingQuantity :_normaRmainQty,actualItemQty : _actualItemQty,totalSumNorQty:_sumQty})
    }    
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        let _isError = false;
        let _value = value;
        let _sendNQtyType = '';
        switch (name) {
            
            case 'without_sr_quantity':
                
                let _normalRemainingQuantity = this.state.normalRemainingQuantity;
                if(this.state.usedFreeNormalQty > 0){
                    _normalRemainingQuantity = parseFloat(_normalRemainingQuantity) + parseFloat(this.state.usedFreeNormalQty);
                }
                errors.error =  '';
                if( (parseFloat(_normalRemainingQuantity) <= parseFloat(value) ) && this.state.normalQuantityTYpe == 'Sale'){
                    //_isError = true;
                    if(this.state.selectTypeTxt == 'Free' && (parseFloat(_normalRemainingQuantity) >= parseFloat(value)) ){
                        
                        errors.error = '';
                        _isError = false;
                    }else{
                        errors.error = '';
                        if(_normalRemainingQuantity > 0){
                            _isError = true;
                            errors.error = '*Enter quantity is not greater than available quantity';
                        }
                        else{                            
                            _sendNQtyType = 'NEW'
                        }
                    }
                }else{
                    /*if(parseFloat(_normalRemainingQuantity) >= parseFloat(value)){
                        
                        _isError = false;
                        errors.error = '';
                    }else{
                        _isError = true;
                        errors.error = '*Enter quantity is not gretor than available quantity 2';
                    }*/
                }
                //if(_isError ==  false){
                    if(_value == ''){
                        _value = '';
                    }
                    this.setState({without_sr_quantity:_value,errors:errors,isError:_isError,withoutTypeQty:_value,normal_quantity_type:_sendNQtyType}); 
                //}
            break;
        }
        
    }
    

    clickToAddData = (event) => {   
        event.preventDefault();
        let errors = this.state.errors;
        let _isError =  false;
        let _usedFreeNormalQty = this.state.usedFreeNormalQty ? this.state.usedFreeNormalQty :  0;
        let _without_sr_quantity = this.state.without_sr_quantity  ? this.state.without_sr_quantity  : _usedFreeNormalQty
        if(this.state.without_sr_quantity == ''){
            _without_sr_quantity = 0;
        }
        
        if(this.state.isError ==  false){
            let _totalQuantity = parseFloat(_without_sr_quantity) +  parseFloat(this.state.totalQuantity);
            let _normalFQtyAdded = this.state.normalFQtyAdded ? this.state.normalFQtyAdded :  0;
           

            console.log(_normalFQtyAdded+'==>No--SR-->=='+_without_sr_quantity+'==>Used>=='+_usedFreeNormalQty);

            _totalQuantity =  parseFloat(_totalQuantity) - parseFloat(_normalFQtyAdded);
            if(this.state.freeQtyAk > 0){
                _totalQuantity =  parseFloat(_totalQuantity) - parseFloat(this.state.freeQtyAk);
            }
            
            if(this.state.usedFreeNormalQty > 0){
                _totalQuantity = parseFloat(_totalQuantity) - parseFloat(_usedFreeNormalQty);
            }
            
            console.log(this.state.freeQtyAk+"==usedFreeNormalQty==",this.state.usedFreeNormalQty);
            console.log("==_without_sr_quantity==",_without_sr_quantity);
            let _finalRemainQty = parseFloat(this.state.normalRemainingQuantity) - parseFloat(_without_sr_quantity);
            
            console.log("==_totalQuantity==",_totalQuantity);
            console.log("==_finalRemainQty==",_finalRemainQty);

            this.props.callBack (_without_sr_quantity,_totalQuantity,this.state.indexPosition,_finalRemainQty,this.state.normal_quantity_type);
        }
              
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        let  isDisabled = '';//Object.keys(errors).some(x => errors[x]);
        if(this.state.isError == true){
            isDisabled = 'disabled';
        }
        let _normalFQtyAdded = '';

        if(this.state.transactionId >0){
            if(this.state.without_sr_quantity == 0 || this.state.without_sr_quantity == ''){
                _normalFQtyAdded = '';
            }
            else{
                if(this.state.normalFQtyAdded > 0){
                    _normalFQtyAdded =  this.state.normalFQtyAdded;
                }
                if(this.state.withoutTypeQty == ''){
                    _normalFQtyAdded = '';
                }
            }
        }
        else{
            if(this.state.normalFQtyAdded > 0){
                _normalFQtyAdded =  this.state.normalFQtyAdded;
            }
            if(this.state.withoutTypeQty == ''){
                _normalFQtyAdded = '';
            }
        }
        
        // if(this.state.normalFQtyAdded > 0){
        //     _normalFQtyAdded =  this.state.normalFQtyAdded;
        // }
        let _usedFreeNormalQty = ''
        if(this.state.usedFreeNormalQty > 0 && (this.state.without_sr_quantity!='')){
            _usedFreeNormalQty = this.state.usedFreeNormalQty;
        }
        
        let _normalRemainingQuantity = this.state.normalRemainingQuantity;
        let demoText = this.state.normalRemainingQuantity;
        if(this.state.totalSumNorQty > 0){
            console.log(this.state.actualItemQty+"==AA--SS=="+this.state.totalSumNorQty);
            demoText =  parseFloat(this.state.actualItemQty) -  parseFloat(this.state.totalSumNorQty);
            //issue no 2270  // (issue no 1279 -  parseFloat(this.state.totalSumNorQty) )
            if(demoText <= 0){
                demoText = 0;
            }
        }
        if(this.state.usedFreeNormalQty > 0 ){
            _normalRemainingQuantity = parseFloat(_normalRemainingQuantity) + parseFloat(this.state.usedFreeNormalQty);
            demoText =  demoText +'(remaining) + '+ this.state.usedFreeNormalQty +' (used)';
            
        }
        console.log(this.state.without_sr_quantity+"==>>_usedFreeNormalQty>>=="+_usedFreeNormalQty);
        
        return(
            <React.Fragment>           
                <form method="post" id="form-login" className="webForm px-30">                  
                    <div className="form-group row"> 
                    
                        {(this.state.normalQuantityTYpe == 'Sale' && this.state.itemTypeOption == '' ) && 
                            <h4 className="badge badge-light k-mt-md ">Available Quantity Is : {demoText}</h4>
                        }
                    </div>                       
                    <div className="form-group row">                       
                        {(this.state.normalRemainingQuantity > 0 && this.state.normalQuantityTYpe == 'Sale' ) && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || _usedFreeNormalQty} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity</label>
                                
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }

                        
                        {( (this.state.normalRemainingQuantity == 0 || this.state.normalRemainingQuantity == '0') && this.state.normalQuantityTYpe == 'Sale' ) && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || _usedFreeNormalQty} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity</label>
                                
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }  

                                              
                    </div>                    
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}