import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = "IN";
let _loader = "glb-ldr-prt active";

export default class QuickAddNewOICategory extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTaxDetails = _companySession.taxDetails;
    }

    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/other-income/",
      buttonTitle: "Add New",
      isLoaded: true,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      category: this.props.category_name ? this.props.category_name : "",
      catId: this.props.catId ? this.props.catId : 0,
      errors: {
        category: "",
      },
    };
  }
  async componentDidMount() {
    let catId = this.props.catId ? this.props.catId : 0;
    console.log("==catId==", catId);

    if (catId > 0) {
      const requestData = JSON.stringify({
        catId: catId,
      });

      PostData(global.userOIItemCategoryDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _categoryArray = responseJson.response[0];

              this.setState({
                buttonTitle: "Edit",
                id: _categoryArray.id,
                category: _categoryArray.category,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "category":
        errors.category =
          value.length < 3 ? "*Category name must be 3 characters long!" : "";
        this.setState({ category: value });
        break;
      default:
        break;
    }
  };

  clickToAddGst = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.category.length < 3) {
      _isError = true;
      errors["category"] = "Category name must be 3 characters long!";
      this.setState({ errors: errors });
    }
    if (this.state.category == null || this.state.category == "") {
      _isError = true;
      errors["category"] = "*Please Enter Category Name";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.catId == 0) {
        this.addGstMethodCall();
      }
      if (this.state.catId > 0) {
        this.editGstMethodCall();
      }
    }
  };

  addGstMethodCall() {
    const requestData = JSON.stringify({
      category: this.state.category,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.userAddOIItemCategory, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          let category_name = result.category_name;
          let category_id = result.category_id;
          sendNotification("Success Message 👍", result.message, "success");
          this.props.callBackCategory(category_name, category_id);
          //this.props.callBack();

          /*setTimeout(
                    () => {
                        window.location.href = global.webUrl+this.state.isRedirectTo;
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )*/
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          let errors = this.state.errors;
          errors["category"] = result.message;
          this.setState({
            errors: errors,
          });
          return;
        }
      }
    );
  }
  editGstMethodCall() {
    const requestData = JSON.stringify({
      category: this.state.category,
      catId: this.state.catId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.userEditOIItemCategory, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          let category_name = result.category_name;
          let category_id = result.category_id;
          sendNotification("Success Message 👍", result.message, "success");
          this.props.callBackCategory(category_name, category_id);
          this.props.callBack();

          setTimeout(() => {
            window.location.href = global.webUrl + this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          let errors = this.state.errors;
          errors["category"] = result.message;
          this.setState({
            errors: errors,
          });
          return;
        }
      }
    );
  }
  /* End Item Filter Code */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="block-content">
          <form method="post" id="form-login" className="webForm px-30">
            <div className="form-group row">
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="category"
                    name="category"
                    value={this.state.category}
                    placeholder="Category Name Here"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">
                    Category Name <span className="text-danger">*</span>
                  </label>
                  <span className="iris_error_txt">
                    {errors.category.length > 0 && (
                      <span className="error">{errors.category}</span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12">
                <button
                  type="submit"
                  disabled={isDisabled}
                  className="btn btn-alt-primary"
                  onClick={this.clickToAddGst}
                >
                  <i className="fa fa-check mr-5"></i> Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
