import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import validator from "validator";

import Modal from "react-modal";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];
let _payData = [];
let _loader = "glb-ldr-prt active";
let _isEditMode = false;
let _selBankId = "0";
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" }
  //{ bank_id: "0", bank_name: "", paymentType: "Cheque" }
);
export default class partyListPanel extends Component {
  constructor(props) {
    _isEditMode = false;
    super(props);

    //_bankArray.push({"bank_id":"0","bank_name":"","paymentType":"Cash"});

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    this.state = {
      minDate: this.props.minDate
        ? this.props.minDate
        : [moment().subtract(7, "days")],

      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-loan-accounts/",
      buttonTitle: "",
      hideShow: "",
      isLoaded: true,
      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,

      loanAccountTrasacId: this.props.loanTrasactionId,
      loanAccountId: this.props.loanAccountId,
      loanOpenningBalance: 0,
      loanCurrentBalance: 0,
      principal_amount: "0",
      interest_amount: "0",
      amount: "0",
      paid_from: "Cash",
      bank_id: "0",
      bank_name: "",
      pay_date: moment().format("MM/DD/YYYY"),
      currentDate: [moment().subtract(7, "days")],
      errors: {},
    };
  }

  async componentDidMount() {
    let _loanAccountTrasacId = this.props.loanTrasactionId;
    let _loanOpenningBalance = 0;
    let _loanCurrentBalance = 0;
    if (this.props.loanAccountId > 0) {
      const requestData = JSON.stringify({
        loanId: this.props.loanAccountId,
      });

      PostData(global.userLoanAccountDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transArray = responseJson.response[0];

              _loanOpenningBalance = _transArray.openingBalance;
              _loanCurrentBalance = _transArray.current_balance;
              this.setState({
                loanOpenningBalance: _loanOpenningBalance,
                loanCurrentBalance: _loanCurrentBalance,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }

    if (_loanAccountTrasacId > 0) {
      _isEditMode = true;
      const requestData = JSON.stringify({
        loanTrasacId: _loanAccountTrasacId,
      });
      PostData(global.userLoanAccountTrasactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transArray = responseJson.response[0];
              console.log('_transArray', JSON.stringify(_transArray))
              this.setState({
                buttonTitle: "Edit",
                loan_detail_id: _transArray.loan_detail_id,
                loan_id: _transArray.loan_id,
                principal_amount: _transArray.principal_amount,
                interest_amount: _transArray.interest_amount,
                amount: _transArray.amount,
                paid_from: _transArray.paid_from,
                bank_id: _transArray.emi_bank_id,
                bank_name: _transArray.emi_bank_name,
                pay_date: moment(_transArray.pay_date).format("MM/DD/YYYY"),
                isLoaded: true,
              });
              _selBankId = _transArray.emi_bank_id;
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "principal_amount":
        if (validator.isFloat(value)) {
          errors.principal_amount = "";
        } else {
          errors.principal_amount = "*Principal Amount should be numeric!";
        }

        let _totalPay = 0;
        if (value > 0) {
          if (value > this.state.loanCurrentBalance) {
            errors.principal_amount =
              "*Principal amount cannot be greater than current balance of " +
              this.state.loanCurrentBalance;
          } else {
            _totalPay =
              parseFloat(value) + parseFloat(this.state.interest_amount);
          }
          errors["amount"] = "";
          this.setState({ errors: errors });
        } else {
          _totalPay = parseFloat(this.state.interest_amount);
          if (_totalPay == 0) {
            errors["amount"] = "*Total amount cannot be 0";
            this.setState({ errors: errors });
          }
        }
        this.setState({ principal_amount: value, amount: _totalPay });
        break;
      case "interest_amount":
        if (validator.isFloat(value)) {
          errors.interest_amount = "";
        } else {
          errors.interest_amount = "*Interest Amount should be numeric!";
        }

        let _totalPayy = 0;
        if (value > 0) {
          _totalPayy =
            parseFloat(this.state.principal_amount) + parseFloat(value);

          errors["amount"] = "";
          this.setState({ errors: errors });
        } else {
          _totalPayy = parseFloat(this.state.principal_amount);
          errors["amount"] = "*Total amount cannot be 0";
          this.setState({ errors: errors });
        }
        this.setState({ interest_amount: value, amount: _totalPayy });
        break;

      case "paid_from":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        //console.log(value);
        let _valType = "";
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        //console.log(_bankId+'--'+_bankName+'---'+value+'----'+_valType);return;
        this.setState({
          paid_from: _valType,
          bank_id: _bankId,
          bank_name: _bankName,
        });
        break;
      default:
        break;
    }
  };

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.principal_amount > this.state.loanCurrentBalance) {
      _isError = true;
      errors["principal_amount"] =
        "*Principal amount cannot be greater than current balance of " +
        this.state.loanCurrentBalance;
      this.setState({ errors: errors });
    }

    if (this.state.amount == 0) {
      _isError = true;
      errors["amount"] = "*Total amount cannot be 0 ";
      this.setState({ errors: errors });
    }
    if (this.state.principal_amount!=0 && this.state.interest_amount!=0) {
      _isError = false;
      errors["amount"] = "";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.loanAccountTrasacId > 0) {
        this.editMakePaymentTrasacDMethodCall();
      } else {
        this.addMakePaymentTrasacDMethodCall();
      }
    }
  };
 
  addMakePaymentTrasacDMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      loan_id: this.state.loanAccountId,

      principal_amount: this.state.principal_amount,
      interest_amount: this.state.interest_amount,
      amount: this.state.amount,
      pay_date: moment(this.state.pay_date).format("MMMM D, YYYY"),
      asOnTime: moment().format("h:mm A"),
      paid_from: this.state.paid_from,
      bank_id: this.state.bank_id,
      bank_name: this.state.bank_name,
    });
    PostData(global.userAddEMIMakePayment, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editMakePaymentTrasacDMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      loan_detail_id: this.state.loanAccountTrasacId,
      loan_id: this.state.loanAccountId,

      principal_amount: this.state.principal_amount,
      interest_amount: this.state.interest_amount,
      amount: this.state.amount,
      pay_date: moment(this.state.pay_date).format("MMMM D, YYYY"),
      paid_from: this.state.paid_from,
      bank_id: this.state.bank_id,
      bank_name: this.state.bank_name,
    });

    PostData(global.userEditEMIMakePayment, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  handleCallBackDate = (start) => {
    this.setState({ pay_date: start.format("MMMM D, YYYY") });
  };

  settleAmountType() {
    if (_payData.length == 0) {
      if (this.state.isLoaded == true) {
        if (_bankArray.length > 0) {
          _bankArray.map((bankD, index) => {
            let _arrayLength = _bankArray.length;
            let _valOption = bankD.paymentType;
            let _disOption = bankD.paymentType;
            let _isBankExist = 0;

            if (bankD.bank_id > 0) {
              _isBankExist = 1;
              _valOption = bankD.bank_id;
              _disOption = bankD.bank_name;
            }
            _selBankId = this.state.bank_id;

            let _selBank = "";
            if (bankD.bank_id > 0) {
              if (_valOption == _selBankId) {
                _selBank = "selected";
              }
            } else {
              if (_valOption == this.state.paymentType) {
                _selBank = "selected";
              }
            }

            if (
              _valOption > 0 ||
              _disOption == "Cash" ||
              _disOption == "Cheque"
            ) {
              _payData.push(
                <option
                  value={_valOption}
                  selected={_selBank}
                  data-bankName={_disOption}
                  data-bankId={_valOption}
                >
                  {_disOption}
                </option>
              );
            }

            let _addNewBank = "";
            if (_arrayLength == index + 1) {
              if (_isBankExist == 0) {
                _payData.push(<option value="NewBank">Add New Bank</option>);
              }
            }
          });
        } else {
          return <option value="NewBank">Add New Bank</option>;
        }
      }
    }
    return _payData;
  }

  isDateRefresh() {
    let _pay_date = this.state.pay_date;
    let _given = moment(this.state.minDate, "MMMM D, YYYY");
    let _current = moment().startOf("day");
    let _remianDays =
      moment.duration(_given.diff(_current)).asDays() < 0
        ? moment.duration(_given.diff(_current)).asDays()
        : 7;
    _remianDays = Math.abs(_remianDays);

    if (_isEditMode == true && this.state.loanAccountTrasacId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _pay_date,
            minDate: moment().subtract(_remianDays, "days"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.state.loanAccountTrasacId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _pay_date,
            minDate: moment().subtract(_remianDays, "days"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  }

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="principal_amount"
                  name="principal_amount"
                  value={this.state.principal_amount}
                  placeholder="Enter Principal Amount"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-principal">Principal Amount</label>
                <span className="iris_error_txt">
                  {<span className="error">{errors.principal_amount}</span>}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <div className="col-lg-8 form-material" id="paddingOff">
                  {this.isDateRefresh()}
                </div>
                <label htmlFor="login-username">Date</label>
                <span className="iris_error_txt">
                  {<span className="error">{errors.received_in}</span>}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="interest_amount"
                  name="interest_amount"
                  value={this.state.interest_amount}
                  placeholder="Enter Interest Amount"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-interest_amount">Interest Amount</label>
                <span className="iris_error_txt">
                  {<span className="error">{errors.interest_amount}</span>}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  readOnly
                  type="text"
                  required="required"
                  className="form-control"
                  id="amount"
                  name="amount"
                  value={this.state.amount}
                  placeholder="Enter Total Amount"
                />
                <label htmlFor="login-principal">Total Amount</label>
                <span className="iris_error_txt">
                  {<span className="error">{errors.amount}</span>}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <select
                  className="form-control"
                  value={
                    this.state.bank_id > 0
                      ? this.state.bank_id
                      : this.state.paid_from
                  }
                  id="paid_from"
                  name="paid_from"
                  onChange={this.handleChange}
                >
                  {this.settleAmountType()}
                </select>
                <label htmlFor="login-principal">Paid From</label>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SBANK}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewBank"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Bank</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewBank"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewBankDetail isRedirectTo={"business/add-sale/"} />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewBank"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}
      </React.Fragment>
    );
  }
}
