import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";

import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import { numberFormat } from "../../../config/numberFormat";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import validator from "validator";
import QuickAddNewParty from "./quickPartyAdd";
import Modal from "react-modal";

let _UserSession = localStorage.getItem("vs_UserSession");
let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let company_id = "0";
let country_code = "0";
let companyData = [];
let tax_name = "";
let gstTypeData = "";
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  company_id = _UserSession.company_id;
  companyData = _UserSession.companies;
}

/*Active Compnay Data*/
companyData.map((companies, i) => {
  let activeData = companyData[i]["isActive"];
  if (activeData == true) {
    country_code = companyData[i]["country_code"];
    tax_name = companyData[i]["tax_name"];
    gstTypeData = companyData[i]["taxType"];
  }
});
/*Active Compnay Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class partyListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo ? this.props.isRedirectTo : "business/manage-party/",
      buttonTitle: "Add New",
      hideShow: "",
      isLoaded: true,
      transfer_date: moment().format("MM/DD/YYYY"),
      company_id: company_id,
      businessId: _businessId,
      userId: _signingId,
      modalIsOpen_SP: false,
      imagePreviewUrl: [],
      toggleFromParty: "Received",
      toggleToParty: "Paid",

      toggleFrom: false,
      toggleTo: true,

      fromAmount: "",
      toAmount: "",
      partyArray: [],
      fromPartyId: 0,
      toPartyId: 0,
      description: "",
      paymentInOutStatus :  false,
      toPartyStatus :  false,
      fromPartyStatus :  false,
      transaction_id :0 ,
      errors: {
        fromAmount: "",
        toAmount: "",
        fromPartyId: "",
        toPartyId: "",
        description: "",
      },
    };
  }

  async componentDidMount() {
    this.getPartyListData();

    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _PToPTransfer = _handleParam[1] ? _handleParam[1] : 0;

    if (_PToPTransfer > 0) {
      _isEditMode = true;
      let _partyTransferArray = [];
      let _url_GetData =
        global.userPartyToPartyTransferDetail +
        "?partyTransferId=" +
        _PToPTransfer;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _partyTransferArray = responseJson.response[0];
              let _fromTog = false;
              let _toTog = false;
              if (_partyTransferArray.from_party_payment_type == "Paid") {
                _fromTog = true;
              }
              if (_partyTransferArray.to_party_payment_type == "Paid") {
                _toTog = true;
              }

              this.setState({
                buttonTitle: "Edit",
                partyTransferId: _PToPTransfer,
                fromPartyId: _partyTransferArray.from_party_id,
                toPartyId: _partyTransferArray.to_party_id,
                partyFName: _partyTransferArray.from_party_name,
                partyTName: _partyTransferArray.to_party_name,
                fromAmount: _partyTransferArray.from_party_payment_amount,
                toAmount: _partyTransferArray.to_party_payment_amount,
                toggleFromParty: _partyTransferArray.from_party_payment_type,
                toggleToParty: _partyTransferArray.to_party_payment_type,
                description: _partyTransferArray.note_description,
                //transfer_date: moment(_partyTransferArray.trasaction_date).format("MMMM D, YYYY"),
                transfer_date: moment(_partyTransferArray.trasaction_date).format("MM/DD/YYYY"),
                imagePreviewUrl: _partyTransferArray.attach_image,
                isLoaded: true,
                toggleFrom: _fromTog,
                toggleTo: _toTog,
                paymentInOutStatus :  _partyTransferArray.paymentInOutStatus,
                toPartyStatus :  _partyTransferArray.toPartyStatus,
                fromPartyStatus :  _partyTransferArray.fromPartyStatus,
                transaction_id : _partyTransferArray.transaction_id,
              });

            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  getPartyListData() {
    let _partyArray = [];

    let _url_GetData = global.userPartyList + "?company_id=" + company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _partyArray = responseJson.response;
          }
          this.setState({ partyArray: _partyArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  /* From Toggle Manage */
  onToggleChange = (checked) => {
    let _toggleTo = false;
    let _toValue = "Received";
    let _fromValue = "Paid";
    if (checked == false) {
      _toValue = "Paid";
      _fromValue = "Received";
      _toggleTo = true;
    }
    this.setState({
      toggleTo: _toggleTo,
      toggleFrom: checked,
      toggleToParty: _toValue,
      toggleFromParty: _fromValue,
    });
    // True = Received
    // False = Paid
  };
  /* From Toggle Manage */

  /* To Toggle Manage */
  onToggleChangeTo = (checked) => {
    let _toggleFrom = false;
    let _toValue = "Paid";
    let _fromValue = "Received";
    if (checked == false) {
      _toValue = "Received";
      _fromValue = "Paid";
      _toggleFrom = true;
    }
    this.setState({
      toggleTo: checked,
      toggleFrom: _toggleFrom,
      toggleToParty: _toValue,
      toggleFromParty: _fromValue,
    });
    // True = Paid
    // False = Received
  };
  /* To Toggle Manage */

  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }

  openModalHandler = (e) => {

    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    }
  }
  closeModalHandler = (e) => {

    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _isError = false;
    switch (name) {

      case "fromPartyId":
        let _partyFName = "";
        errors.fromPartyId = value.length < 0 ? "*Select any party" : "";
        var index = event.target.selectedIndex;
        var optionElement = event.target.childNodes[index];
        _partyFName = optionElement.getAttribute("data-partyFName");
        if(_partyFName == 'addParty'){
          this.openModalHandler("modalAdjustNewParty");
          this.setState({ fromPartyId: 0, partyFName: '' });
        }else{
          this.setState({ fromPartyId: value, partyFName: _partyFName });
        }

        break;

      case "toPartyId":
        let _partyTName = "";
        errors.toPartyId = value.length < 0 ? "*Select any party" : "";

        var index = event.target.selectedIndex;
        var optionElement = event.target.childNodes[index];
        _partyTName = optionElement.getAttribute("data-partyTName");
        console.log('value', value)
        console.log('_partyTName', _partyTName)
        if(_partyTName == 'addParty'){
          this.openModalHandler("modalAdjustNewParty");
          this.setState({ toPartyId: 0, partyTName: '' });
          break
        }
        if (value > 0) {
          this.setState({ toPartyId: value, partyTName: _partyTName });
          if (this.state.fromPartyId == value) {
            _isError = true;
            errors["fromPartyId"] = "*Both party are not same";
            errors["toPartyId"] = "*Both party are not same ";
          } else {
            _isError = false;
            errors["fromPartyId"] = "";
            errors["toPartyId"] = "";
          }
        } else {
          _isError = true;
          errors["fromPartyId"] = "";
          errors["toPartyId"] = "*Please select party";
          this.setState({ toPartyId: 0, partyTName: "" });
        }

        break;

      case "fromAmount":
        if (validator.isInt(value)) {
          errors.fromAmount = "";
          errors.toAmount = "";
        } else {
          errors.fromAmount = "*Amount should be numeric!";
        }
        this.setState({ fromAmount: value, toAmount: value });
        break;

      case "toAmount":
        if (validator.isInt(value)) {
          errors.toAmount = "";
        } else {
          errors.toAmount = "*Amount should be numeric!";
        }
        this.setState({ fromAmount: value, toAmount: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      default:
        break;
    }
  };

  clickToAddItem = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _PToPTransfer = _handleParam[1] ? _handleParam[1] : 0;

    if (this.state.fromPartyId!="") {
      if ((this.state.fromPartyId == 0 || this.state.fromPartyId == '' || this.state.fromPartyId == '0') && this.state.toPartyId) {
        _isError = true;
        errors["fromPartyId"] = "*Please Select Party";
      } else if (this.state.fromPartyId == this.state.toPartyId) {
        _isError = true;
        errors["fromPartyId"] = "*Both party are not same";
      } else {
        errors["toPartyId"] = "";
        errors["fromPartyId"] = "";
      }
      this.setState({ errors: errors });
    }
    if (this.state.toPartyId!="") {
      if ((this.state.toPartyId == 0 || this.state.toPartyId == '' || this.state.toPartyId == '0') && this.state.fromPartyId) {
        _isError = true;
        errors["toPartyId"] = "*Please Select Party";
      } else if (this.state.fromPartyId == this.state.toPartyId) {
        _isError = true;
        errors["toPartyId"] = "*Both party are not same";
      } else {
        errors["toPartyId"] = "";
        errors["fromPartyId"] = "";
      }
      this.setState({ errors: errors });
    }
    if ((this.state.toPartyId == 0 || this.state.toPartyId == '' || this.state.toPartyId == '0')) {
      _isError = true;
      errors["toPartyId"] = "*Please Select Party";
    }
    if ((this.state.fromPartyId == 0 || this.state.fromPartyId == '' || this.state.fromPartyId == '0')) {
      _isError = true;
      errors["fromPartyId"] = "*Please Select Party";
    }
    if (this.state.fromAmount == "0" || this.state.fromAmount == "") {
      _isError = true;
      errors["fromAmount"] = "Please enter a valid amount";
      this.setState({ errors: errors });
    }
    if (this.state.toAmount == "0" || this.state.toAmount == "") {
      _isError = true;
      errors["toAmount"] = "Please enter a valid amount";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (_PToPTransfer > 0) {
        this.editPartyToPartTrsabferMethodCall();
      } else {
        this.addPartyToPartTrsabferMethodCall();
      }
    }
  };

  addPartyToPartTrsabferMethodCall() {
    var saleData = new FormData();
    saleData.append("businessId", this.state.businessId);
    saleData.append("userId", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("fromPartyId", this.state.fromPartyId);
    saleData.append("toPartyId", this.state.toPartyId);
    saleData.append("fromAmount", this.state.fromAmount);
    saleData.append("toAmount", this.state.toAmount);
    saleData.append("toggleFrom", this.state.toggleFromParty);
    saleData.append("toggleTo", this.state.toggleToParty);
    //saleData.append("trasaction_date", this.state.transfer_date);
    saleData.append("trasaction_date",moment(this.state.transfer_date).format("MMMM D, YYYY"));
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("partyFName", this.state.partyFName);
    saleData.append("partyTName", this.state.partyTName);
    saleData.append("created_from", "WEB");

    new Promise((resolve, reject) => {
      fetch(global.userPartyToPartyTransfer, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/party-to-party-transfer/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {});
    });
  }

  editPartyToPartTrsabferMethodCall() {
    var saleData = new FormData();
    saleData.append("partyTransferId", this.state.partyTransferId);
    saleData.append("businessId", this.state.businessId);
    saleData.append("transaction_id", this.state.transaction_id);
    saleData.append("userId", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("fromPartyId", this.state.fromPartyId);
    saleData.append("toPartyId", this.state.toPartyId);
    saleData.append("fromAmount", this.state.fromAmount);
    saleData.append("toAmount", this.state.toAmount);
    saleData.append("toggleFrom", this.state.toggleFromParty);
    saleData.append("toggleTo", this.state.toggleToParty);
    //saleData.append("trasaction_date", this.state.transfer_date);
    saleData.append("trasaction_date",moment(this.state.transfer_date).format("MMMM D, YYYY"));
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("partyFName", this.state.partyFName);
    saleData.append("partyTName", this.state.partyTName);
    saleData.append("created_from", "WEB");

    new Promise((resolve, reject) => {
      fetch(global.userPartyToPartyEditTransfer, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/manage-party/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {});
    });
  }

  isDateRefresh() {
    let _invoiceDate = this.state.transfer_date;
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onApply={(event, picker) => {
            this.setState({ transfer_date: picker.startDate.format("MMMM D, YYYY") });
          }}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            dataSend="1111"
            value={this.state.transfer_date}
          />
        </DateRangePicker>
      );
  }

  render() {
    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    let _hideRows = "iti__hide";
    if (this.state.toggleFromParty == true) {
      _hideRows = "iti__hide";
    }
    let _hideRowsTo = "iti__hide_to";
    if (this.state.toggleToParty == true) {
      _hideRowsTo = "iti__hide_to";
    }

    let isNotChange = false;
    let _toPartyStatus = false;
    let _fromPartyStatus = false;
    if(this.state.paymentInOutStatus ==  true){
      isNotChange  = true;
    }
    if(this.state.toPartyStatus ==  true){
      _toPartyStatus  = true;
    }
    if(this.state.fromPartyStatus ==  true){
      _fromPartyStatus  = true;
    }
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Party To Party Transfer
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Party To Party Transfer
            </span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Party To Party Transfer </h3>
            </div>
            <div className="block-content">
              <form method="post" id="form-login" className="webForm px-30">
                <div className="form-group row">
                  <div className="col-6">
                    <div className="form-material open">
                      {/* <DateRangePicker
                          initialSettings={{
                            autoApply: true,
                            singleDatePicker: true,
                            startDate: this.state.transfer_date,
                          }}
                          onCallback={this.handleCallBackDate}
                      >
                        <input type="text" className="form-control" />
                      </DateRangePicker> */}
                      {this.isDateRefresh()}
                      <label htmlFor="login-username">Date </label>
                    </div>
                  </div>
                </div>

                <h3 className="content-heading" style={{ paddingTop: "6px" }}>
                  <i className="fa fa-angle-right"></i>{" "}
                </h3>

                <div className="form-group row">
                  <div className="col-4">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="fromPartyId"
                        name="fromPartyId"
                        onChange={this.handleChange}
                        disabled={_fromPartyStatus}
                      >
                        <option value={0} selected={this.state.fromPartyId == 0}>
                          Select Party
                        </option>
                        <option value={0}  data-partyFName={'addParty'}>
                          Add Party
                        </option>
                        {this.state.partyArray.map((party, index) => {
                          let _selParty = "";
                          if (this.state.fromPartyId == party.party_id) {
                            _selParty = "selected";
                          }
                          let _balance = party.totalOutCome;
                          if (_balance < 0) {
                            _colorVal = "red";
                          } else if (
                            _balance == 0 ||
                            _balance == "0" ||
                            _balance == "0.00" ||
                            _balance == 0.0 ||
                            _balance == null
                          ) {
                            _colorVal = "black";
                          } else {
                            _colorVal = "green";
                          }
                          return (
                            <option
                              key={index}
                              value={party.party_id}
                              selected={_selParty}
                              data-partyFName={party.party_name}
                              style={{ color: _colorVal, textAlign: "justify" }}
                            >
                              {party.party_name} (
                              {numberFormat(Math.abs(_balance))})
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">Party</label>

                      <span className="iris_error_txt">
                        {errors.fromPartyId.length > 0 && (
                          <span className="error">{errors.fromPartyId}</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <div>
                        <ToggleSwitch
                          txtLeft="Received"
                          txtRight="Paid"
                          small
                          id="fromParty"
                          checked={this.state.toggleFrom}
                          onChange={this.onToggleChange}
                          disabled={isNotChange}
                        />
                        {/* <p>Newsletter: {String(this.state.toggleFromParty)}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <input
                        type="number"
                        className="form-control"
                        id="fromAmount"
                        name="fromAmount"
                        value={this.state.fromAmount}
                        onChange={this.handleChange}
                        autoComplete="off"
                        placeholder="Enter Party Amount"
                      />
                      <label htmlFor="login-password">Amount</label>

                      <span className="iris_error_txt">
                        {errors.fromAmount.length > 0 && (
                          <span className="error">{errors.fromAmount}</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="claerfix"></div>
                <h3 className="content-heading" style={{ paddingTop: "6px" }}>
                  <i className="fa fa-angle-right"></i>{" "}
                </h3>
                <div className="form-group row">
                  <div className="col-4">
                    <div className="form-material open">

                    <select
                        className="form-control"
                        id="toPartyId"
                        name="toPartyId"
                        onChange={this.handleChange}
                        disabled={_toPartyStatus}
                      >
                        <option value={0} selected={this.state.toPartyId == 0}>
                          Select Party
                        </option>
                        <option data-partyTName={'addParty'}>
                          Add Party
                        </option>
                        {this.state.partyArray.map((party, index) => {
                          let _selTParty = "";
                          if (this.state.toPartyId == party.party_id) {
                            _selTParty = "selected";
                          }
                          let _balance = party.totalOutCome;
                          if (_balance < 0) {
                            _colorVal = "red";
                          } else if (
                            _balance == 0 ||
                            _balance == "0" ||
                            _balance == "0.00" ||
                            _balance == 0.0 ||
                            _balance == null
                          ) {
                            _colorVal = "black";
                          } else {
                            _colorVal = "green";
                          }
                          return (
                            <option
                              key={index}
                              value={party.party_id}
                              selected={_selTParty}
                              data-partyTName={party.party_name}
                              style={{ color: _colorVal, textAlign: "justify" }}
                            >
                              {party.party_name} (
                              {numberFormat(Math.abs(_balance))})
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">Party</label>
                      <span className="iris_error_txt">
                        {errors.toPartyId.length > 0 && (
                          <span className="error">{errors.toPartyId}</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <div>
                        <ToggleSwitch
                          txtLeft="Received"
                          txtRight="Paid"
                          small
                          id="toParty"
                          checked={this.state.toggleTo}
                          onChange={this.onToggleChangeTo}
                          disabled={isNotChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-material open">
                      <input
                        type="number"
                        className="form-control"
                        id="toAmount"
                        name="toAmount"
                        value={this.state.toAmount}
                        onChange={this.handleChange}
                        autoComplete="off"
                        placeholder="Enter Party Amount"
                      />
                      <label htmlFor="login-password">Amount</label>

                      <span className="iris_error_txt">
                        {errors.toAmount.length > 0 && (
                          <span className="error">{errors.toAmount}</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="claerfix"></div>
                <div className="form-group row">
                  <div className="col-6">
                    <div className="form-material open">
                      <textarea
                        name="description"
                        id="description"
                        placeholder="Enter Description"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.description}
                      ></textarea>
                      <label htmlFor="login-username">
                        Add Note (Optional)
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="login-username">Image (Optional)</label>
                    <div className="form-material open">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.onChangeFileUploadHandler}
                        name="item_image"
                      />
                    </div>
                    <div>
                      {this.previewImagesListing()}
                      {/* {this.previewOldImagesListing()} */}
                    </div>
                  </div>
                </div>
                <br />
                <div className="claerfix"></div>
                <div className="form-group row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-alt-primary"
                      onClick={this.clickToAddItem}
                      disabled={isDisabled}
                    >
                      <i className="fa fa-check mr-5"></i>{" "}
                      {this.state.buttonTitle} Party Transfer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Modal
              isOpen={this.state.modalIsOpen_SP}
              ariaHideApp={false}
              size="sm"
              aria-labelledby="modal-popout"
              onRequestClose={() => this.closeModalHandler('modalAdjustNewParty')}
              id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                          type="button"
                          className="btn-block-option"
                          onClick={() => this.closeModalHandler("modalAdjustNewParty")}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty isRedirectTo={"business/party-to-party-transfer/"} />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                      type="button"
                      className="btn btn-alt-secondary"
                      onClick={() => this.closeModalHandler("modalAdjustNewParty")}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
