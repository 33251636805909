global.createSaleTransaction = global.API_USER_URL+'createSaleTransaction/';
global.createPaymentInTransaction = global.API_USER_URL+'createPaymentInTransaction/';
global.createSaleDeliveryChallanTransaction = global.API_USER_URL+'createSaleDeliveryChallanTransaction/';
global.createSaleEstimateQuotationTransaction = global.API_USER_URL+'createSaleEstimateQuotationTransaction/';
global.createSaleOrderTransaction = global.API_USER_URL+'createSaleOrderTransaction/';
global.createSaleReturnTransaction = global.API_USER_URL+'createSaleReturnTransaction/';
global.createSalePaymentInTransaction = global.API_USER_URL+'createSalePaymentInTransaction/';

global.deleteSaleTransaction = global.API_USER_URL+'deleteSaleTransaction/';
global.userTransactionDetails = global.API_USER_URL+'userTransactionDetails/';
global.deleteWithReturnTransaction = global.API_USER_URL+'deleteWithReturnTransaction/';

global.deleteCashInHandTransaction = global.API_USER_URL+'deleteCashInHandTransaction/';

// Listing Api
global.userSaleTransactionList = global.API_USER_URL+'userSaleTransactionList/';
global.userSaleTransactionViaTypeList = global.API_USER_URL+'userSaleTransactionViaTypeList/';
global.userBusinessTotalCountList = global.API_USER_URL+'userBusinessTotalCountList/';


global.createEditSaleEstimateTransaction = global.API_USER_URL+'createEditSaleEstimateTransaction/';
global.editSaleDeliveryChallanTransaction = global.API_USER_URL+'editSaleDeliveryChallanTransaction/';
global.createSaleEstimateQuotationDuplicateTransaction = global.API_USER_URL+'createSaleEstimateQuotationDuplicateTransaction/';
global.editSaleTransaction = global.API_USER_URL+'editSaleTransaction/';
global.editSaleOrderTransaction = global.API_USER_URL+'editSaleOrderTransaction/';
global.editSaleReturnTransaction = global.API_USER_URL+'editSaleReturnTransaction/';
global.editSalePaymentInTransaction = global.API_USER_URL+'editSalePaymentInTransaction/';

global.createSalePaymentINRecevTransaction = global.API_USER_URL+'createSalePaymentINRecevTransaction/';
global.createSalePaymentOutTransaction = global.API_USER_URL+'createSalePaymentOutTransaction/';
global.createSaleInToReturnTransaction = global.API_USER_URL+'createSaleInToReturnTransaction/';

global.convertToSaleTransaction = global.API_USER_URL+'convertToSaleTransaction/';

global.createBankAccountDetail = global.API_USER_URL+'createBankAccountDetail/';
global.userBankDetailsList = global.API_USER_URL+'userBankDetailsList/';
global.createSaleDuplicateTransaction = global.API_USER_URL+'createSaleDuplicateTransaction/';
global.createSaleDuplicateOrderTransaction = global.API_USER_URL+'createSaleDuplicateOrderTransaction/';
global.userPartyPaymentLinkTransList = global.API_USER_URL+'userPartyPaymentLinkTransList/';
global.userPartyPaymentPayOutLinkTransList = global.API_USER_URL+'userPartyPaymentPayOutLinkTransList/';
global.getPartyOldTransationData = global.API_USER_URL+'getPartyOldTransationData/';
global.getPartyOldTransationDCData = global.API_USER_URL+'getPartyOldTransationDCData/';

global.userTransactionDeliveryChallanDetails = global.API_USER_URL+'userTransactionDeliveryChallanDetails/';
global.userReturnTransactionDetails = global.API_USER_URL+'userReturnTransactionDetails/';
