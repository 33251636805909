import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
import { Editor, EditorTools, EditorUtils} from '@progress/kendo-react-editor';

const { Bold, Italic, Underline,
    AlignLeft, AlignRight, AlignCenter,
    Indent, Outdent,
    OrderedList, UnorderedList,
    Undo, Redo, Link, Unlink } = EditorTools;

let _sessionPeriod = true;
let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _sessionPeriod = false;
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}
global.BACK_URL = 'Business';

export default class blogAddPannel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : false,
            isLoggedin : false,
            blogCatgeoryArray : [],
            title : '',
            imageAlt: '',
            image: '',
            imageImage: '',
            blog_id:0,
            imageTitle: '',
            short_description: '',
            meta_title: '',
            meta_keyword: '',
            meta_desc: '',
            description: '',
            date: '',
            blog_cat_id: '0',
            errors : {
                title : '',
                imageAlt : '',
                image : '',
                imageTitle : '',
                short_description : '',
                meta_title : '',
                meta_keyword : '',
                meta_desc : '',
                description : '',
                date : '',
                blog_cat_id : '',
            }
        }
    }

    async componentDidMount()
    {
        
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _blogId = _handleParam[1];

        if(_blogId > 0){
            let _blogArray = [];
            let _url_GetData = global.blogDetails+'?blogId='+_blogId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _blogArray = responseJson.response[0];
                        this.setState({buttonTitle:'Edit',title:atob(_blogArray.title),imageAlt:atob(_blogArray.imageAlt),imageTitle:atob(_blogArray.imageTitle),short_description:atob(_blogArray.short_description),meta_title:atob(_blogArray.meta_title),meta_keyword:atob(_blogArray.meta_keyword),meta_desc:atob(_blogArray.meta_desc),image:_blogArray.image,blog_id:_blogArray.id,description:atob(_blogArray.description),blog_cat_id:_blogArray.blog_cat_id,date:_blogArray.date,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
      
        this.blogCatgeoryListData();
    }

    blogCatgeoryListData(){
        let _blogCatgeoryArray = [];

        let _url_GetData = global.blogCatgeoryList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _blogCatgeoryArray = responseJson.response;
                }
                this.setState({blogCatgeoryArray:_blogCatgeoryArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'title': 
            errors.title = 
                value.length < 5 ? '*Blog Title must be 5 characters long!' : '';
                this.setState({title:value}); 
            break;
            case 'imageAlt':
                errors.imageAlt = value.length < 5 ? '*Image Alt must be 5 characters long!' : '';
                this.setState({imageAlt:value}); 
            break;
            case 'imageTitle': 
                errors.imageTitle = 
                value.length < 5 ? '*Image Alt Title must be 5 characters long! ' : '';

                this.setState({imageTitle:value}); 
            break;
            case 'short_description': 
                errors.short_description = 
                value.length < 10
                ? '*Short Description must be 10 characters long! '
                : '';
                this.setState({short_description:value}); 
            break;
            case 'image': 
                errors.image = 
                value.length < 2
                ? '*Please upload blog image.'
                : '';
                this.setState({image:value}); 
            break;
            case 'meta_title': 
                errors.meta_title = 
                value.length < 5 ? '*Meta Title must be 5 characters long!' : '';
                
                this.setState({meta_title:value}); 
            break;
            case 'meta_keyword': 
                errors.meta_keyword = 
                value.length < 5 ? '* Meta keyword must be 5 characters long' : '';
                this.setState({meta_keyword:value}); 
            break;
            case 'meta_desc': 
            errors.meta_desc = 
            value.length < 5 ? '* Meta Description must be 5 characters long' : '';
            this.setState({meta_desc:value}); 
            break;
            
            case 'blog_cat_id': 
                this.setState({blog_cat_id:value}); 
            break;
            
            case 'description': 
                errors.description = 
                value.length < 10
                ? '*Description must be 10 characters long! '
                : '';
                this.setState({description:value}); 
            break;
            
            case 'date': 
                errors.date = 
                value.length < 1
                ? '*Date must be select! '
                : '';
                this.setState({date:value}); 
            break;
            default:
            break;
        }
    }

    clickToAddBlog = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.imageAlt == '')
        {
            _isError = true;
            errors['imageAlt'] = "*Please Enter Image Alt ";
            this.setState({errors: errors});
        }
        if(this.state.title == '')
        {
            _isError = true;
            errors['title'] = "*Please Enter Blog Title.";
            this.setState({errors: errors});
        }
        if(this.state.image == '')
        {
            if(this.state.blog_id == 0)
            {
                _isError = true;
                errors['image'] = "*Please Upload Blog Logo.";
                this.setState({errors: errors});
            }
        }
        if(this.state.imageTitle == '')
        {
            _isError = true;
            errors['imageTitle'] = "*Please Enter Image Alt Title.";
            this.setState({errors: errors});
        }
        if(this.state.short_description == '')
        {
            _isError = true;
            errors['short_description'] = "*Please Enter Blog Short Description.";
            this.setState({errors: errors});
        }
        if(this.state.meta_title == '')
        {
            _isError = true;
            errors['meta_title'] = "*Please Enter Meta Title.";
            this.setState({errors: errors});
        }
        if(this.state.meta_keyword == '')
        {
            _isError = true;
            errors['meta_keyword'] = "*Please Enter Meta Keyword.";
            this.setState({errors: errors});
        }
        if(this.state.meta_desc == '')
        {
            _isError = true;
            errors['meta_desc'] = "*Please Enter Meta Description.";
            this.setState({errors: errors});
        }
        if(this.state.blog_cat_id == '')
        {
            _isError = true;
            errors['blog_cat_id'] = "*Please Select blog category.";
            this.setState({errors: errors});
        }
        
        if(this.state.date == '')
        {
            _isError = true;
            errors['date'] = "*Please select any date.";
            this.setState({errors: errors});
        }

        if(_isError == false){
            this.getHtml();
        }
    }
    
    getHtml = () => {
        const view = this.editor.view;
        let value = EditorUtils.getHtml(view.state);
        value = Buffer.from(value).toString('base64');
        //this.setState({description:value});
        if(this.state.blog_id > 0){
            this.editBlogMethodCall(value);
        }
        else{
            this.addBlogMethodCall(value);
        }
    }

    onChangeFileUploadHandler=event=>{       
        let reader = new FileReader();
        let file = event.target.files[0];        
        let errors = this.state.errors;
        errors['image'] = '';
        reader.onloadend = () => {
        this.setState({
            image: file,
            errors: errors
            //imagePreviewUrl: reader.result
        });
        }
        reader.readAsDataURL(file);
    }

    addBlogMethodCall(descData){
        var blogData = new FormData();
        //alert(btoa(this.state.meta_title));
        blogData.append('title', btoa(this.state.title));
        blogData.append('imageAlt', btoa(this.state.imageAlt));
        blogData.append('image', this.state.image);
        blogData.append('imageTitle', btoa(this.state.imageTitle));
        blogData.append('short_description', btoa(this.state.short_description));
        blogData.append('meta_title', btoa(this.state.meta_title));
        blogData.append('meta_keyword', btoa(this.state.meta_keyword));
        blogData.append('meta_desc', btoa(this.state.meta_desc));
        blogData.append('blog_cat_id', this.state.blog_cat_id);
        blogData.append('description', descData);
        blogData.append('date', this.state.date);
        
        new Promise((resolve, reject) =>{
            fetch(global.adminAddBlog, {
                method:  'POST',
                body: blogData
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success == true)
                {
                    sendNotification("Success Message 👍",res.message,"success");
                    window.location.href = global.webUrl+'administrator/blog-list/';
                }
                else
                {
                    sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['imageTitle'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    editBlogMethodCall(descData)
    {
        var blogData = new FormData();
        blogData.append('title', btoa(this.state.title));
        blogData.append('imageAlt', btoa(this.state.imageAlt));
        blogData.append('image', this.state.image);
        blogData.append('imageTitle', btoa(this.state.imageTitle));
        blogData.append('short_description', btoa(this.state.short_description));
        blogData.append('meta_title', btoa(this.state.meta_title));
        blogData.append('meta_keyword', btoa(this.state.meta_keyword));
        blogData.append('meta_desc', btoa(this.state.meta_desc));
        blogData.append('blog_cat_id', this.state.blog_cat_id);
        blogData.append('description', descData);
        blogData.append('date', this.state.date);
        blogData.append('blog_id', this.state.blog_id);

        new Promise((resolve, reject) =>{
            fetch(global.adminEditBlog, {
                method:  'POST',
                body: blogData
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success == true)
                {
                    sendNotification("Success Message 👍",res.message,"success");
                    window.location.href = global.webUrl+'administrator/blog-list/';
                }
                else
                {
                    sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['imageTitle'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    loadContainer(){
        if(this.state.isLoaded == true){
            return(
                <Editor
                    tools={[
                        [ Bold, Italic, Underline ],
                        [ Undo, Redo ],
                        [ Link, Unlink ],
                        [ AlignLeft, AlignCenter, AlignRight ],
                        [ OrderedList, UnorderedList, Indent, Outdent ]
                    ]}
                    contentStyle={{ height: 160 }}
                    defaultContent={this.state.description}
                    ref={editor => this.editor = editor}
                />
            );
        }
    }
    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Add / Edit Blog<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/blog-list/"}>Blog List</a>
                        <span className="breadcrumb-item active">Add / Edit Blog </span>
                    </nav>
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Blog</h3>
                        </div>

                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                            <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Meta Title Here" autoComplete="false" value={this.state.meta_title} className="form-control" id="meta_title" name="meta_title" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Meta Title <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="meta_title-error" className="animated fadeInDown">{errors.meta_title.length > 0 && <span className='error'>{errors.meta_title}</span>}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            
                                            <input type="text" required="required" style={{display: 'inline-block',width:'80%'}} placeholder="Meta Keyword Here" autoComplete="false" value={this.state.meta_keyword} className="form-control" id="meta_keyword" name="meta_keyword" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Meta Keyword <span className="text-danger">*</span></label>
                                            
                                        </div>
                                        <div id="meta_keyword-error" className="animated fadeInDown">{errors.meta_keyword.length > 0 && <span className='error'>{errors.meta_keyword}</span>}</div>
                                    </div>
                                    
                                </div>
                                
                                <div className="form-group row">
                                    <div className="col-12">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Meta Description Here" autoComplete="false" value={this.state.meta_desc} className="form-control" id="meta_desc" name="meta_desc" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Meta Description <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="meta_desc-error" className="animated fadeInDown">{errors.meta_desc.length > 0 && <span className='error'>{errors.meta_desc}</span>}</div>
                                    </div>
                                    
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <select className="form-control" id="blog_cat_id" name="blog_cat_id" onChange={this.handleChange}>
                                                <option value=""> Select Blog Category</option>
                                                {
                                                    this.state.blogCatgeoryArray.map((blogCategory,index) => {
                                                        let _sel = '';
                                                        if(this.state.blog_cat_id == blogCategory.id){_sel = 'selected';}
                                                        return(            
                                                        <option key={index} value={blogCategory.id} selected={_sel}>{blogCategory.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="login-password">Blog Category <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="blog_cat_id-error" className="animated fadeInDown">{errors.blog_cat_id.length > 0 && <span className='error'>{errors.blog_cat_id}</span>}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Blog Tilte Here" value={this.state.title} className="form-control" id="title" name="title" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Blog Tilte<span className="text-danger">*</span></label>
                                        </div>
                                        <div id="title-error" className="animated fadeInDown">{errors.title.length > 0 && <span className='error'>{errors.title}</span>}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="file" required="required" placeholder="file" autoComplete="false" className="form-control" id="image" name="image" onChange={this.onChangeFileUploadHandler}/>
                                            <label htmlFor="login-password">Blog Image<span className="text-danger">*</span></label>
                                        </div>
                                        <div id="image-error" className="animated fadeInDown">{errors.image.length > 0 && <span className='error'>{errors.image}</span>}</div>
                                    </div>
                                    
                                </div>
                                
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Image Alt  Here" value={this.state.imageAlt} className="form-control" id="imageAlt" name="imageAlt" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Image Alt  <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="imageAlt-error" className="animated fadeInDown">{errors.imageAlt.length > 0 && <span className='error'>{errors.imageAlt}</span>}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            
                                            <input type="text" required="required" placeholder="" autoComplete="false" value={this.state.imageTitle} className="form-control" id="imageTitle" name="imageTitle" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Image Alt Title. <span className="text-danger">*</span></label>
                                            <span className="vs_error_txt">{errors.imageTitle.length > 0 && <span className='error'>{errors.imageTitle}</span>}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-group row">                                    
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="date" required="required" placeholder="Blog date" value={this.state.date} className="form-control" id="date" name="date" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Blog Date<span className="text-danger">*</span></label>
                                        </div>
                                        <div id="date-error" className="animated fadeInDown">{errors.date.length > 0 && <span className='error'>{errors.date}</span>}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Blog Short Description Here" autoComplete="false" value={this.state.short_description} className="form-control" id="short_description" name="short_description" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Blog Short Description <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="short_description-error" className="animated fadeInDown">{errors.short_description.length > 0 && <span className='error'>{errors.short_description}</span>}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="login-password">Blog Description <span className="text-danger">*</span></label>
                                        {this.loadContainer()}
                                        <div id="description-error" className="animated fadeInDown">{errors.description.length > 0 && <span className='error'>{errors.description}</span>}</div>
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    <button type="submit" disabled={isDisabled} className="btn btn-sm btn-hero btn-alt-primary" onClick={this.clickToAddBlog}>
                                    <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} Blog
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}