import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = 'IN';
let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
    country_code = _companySession.country_code;
    tax_name = _companySession.tax_name;
    gstTaxDetails = _companySession.taxDetails;
}

let _loader = 'glb-ldr-prt active';

export default class ItemListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-unit/',
            itemSelectData : this.props.itemSelectData ? this.props.itemSelectData : '0',
            _dataType: (this.props.dataType) ? this.props.dataType : 'ITEM',
            buttonTitle:'Add New',
            isLoaded : true, 
            unitArray : [],
            secUnitArray : [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            unit_name : null,
            slug : null,
            unitId : 0,
            errors: {
                unit_name : '',
                slug: ''
            }
        }
    }
    async componentDidMount ()
    {        
        

    }
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'unit_name': 
            errors.unit_name = 
                value.length < 3 ? '*Unit name must be 3 characters long!' : '';
                this.setState({unit_name:value}); 
            break;
            case 'slug': 
            errors.slug = 
                value.length < 2 ? '*Unit slug must be 2 characters long!' : '';
                this.setState({slug:value}); 
            break;
            default:
            break;
        }
    }
    
    clickToAddGst = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        //console.log(errors);
        let _isError = false;
        if(this.state.unit_name == null)
        {
            _isError = true;
            errors['unit_name'] = "*Please Enter Unit Name";
            this.setState({errors: errors});
        }
        if(this.state.slug == null)
        {
            _isError = true;
            errors['slug'] = "*Please Enter Slug";
            this.setState({errors: errors});
        }
        

        if(_isError == false){
            if(this.state.unitId > 0){
                this.editGstMethodCall();
            }
            else{
                this.addGstMethodCall();
            }
        }
    }

    editGstMethodCall()
    {
        const requestData = JSON.stringify({
            unit_name: this.state.unit_name,
            slug: this.state.slug,
            unit_id: this.state.unitId,
            businessId : this.state.businessId
        });
        PostData(global.userEditUnit, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'business/manage-unit/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['unit_name'] = result.message;
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    addGstMethodCall(){
        const requestData = JSON.stringify({
            unit_name: this.state.unit_name,
            slug: this.state.slug,
            businessId : this.state.businessId
        });

        PostData(global.userAddUnit, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {                        
                        this.props.callBack('hello');
                        //window.location.href = global.webUrl+'business/manage-unit/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['unit_name'] = result.message;
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">  
                    
                    <div className="claerfix"></div>
                    <div className="form-group row">
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" required="required" className="form-control" id="unit_name" name="unit_name" value={this.state.unit_name} placeholder="Unit Name Here" onChange={this.handleChange}/>
                                <label htmlFor="login-username">Unit Name <span className="text-danger">*</span></label>
                                <span className="iris_error_txt">{errors.unit_name.length > 0 && <span className='error'>{errors.unit_name}</span>}</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" required="required" className="form-control" id="slug" name="slug" value={this.state.slug} placeholder="Unit Slug Here" onChange={this.handleChange}/>
                                <label htmlFor="login-username">Unit Slug <span className="text-danger">*</span></label>
                                <span className="iris_error_txt">{errors.slug.length > 0 && <span className='error'>{errors.slug}</span>}</span>
                            </div>
                        </div>                               
                        
                    </div>
                    <div className="claerfix"></div>

                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddGst} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}