import React,{Component} from 'react';
import Modal from 'react-modal';
import {PostData} from '../service/postData';
import {sendNotification} from '../_notificationSettings/notificationPanel';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class partnerUsPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            modalIsOpen_AC : false,
            termsaconditios: false,
            name:'',
            email:'',
            contact:'',
            errors:{
                name:'',
                email:'',
                contact:''
            }
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    openModalHandler()
    {
        this.setState({modalIsOpen_AC: true});
    }

    closeModalHandler = () => {
        this.setState({
            modalIsOpen_AC: false
        });
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        
        switch (name) {
            case 'name': 
            errors.name = 
                value.length < 3 ? '*Name must be 3 characters long!' : '';
                this.setState({name:value}); 
            break;
            case 'email': 
                errors.email = value.length < 5 ? '*Please enter valid email id!' : '';
                this.setState({email:value}); 
            break;
            case 'contact': 
                errors.contact = value.length < 5 ? '*Please enter valid mobile no.!' : '';
                if (/^\d*$/.test(value)) {
                    if (value.length >= 0 && value.length <= 10) {
                        this.setState({contact:value}); 
                    }
                  }else{
                    errors.contact = "*Please enter valid mobile no.!";
                  }
            break;
            default:
            break;
        }
    }

    handleCheckBoxClick = () => {
        var checkBox = document.getElementById("termsaconditios");
        this.setState({termsaconditios: checkBox.checked})
      }

    clickToSubmitEnqury = (event) => {
        event.preventDefault();
        
        const regex = /^\d{10}$/;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(this.state.name == '')
        {
            alert("Name must be 3 characters long!");
        }else if(!(this.state.email && isValidEmail.test(this.state.email))){
            alert("Email-Id is invalid.");
        }else if(!regex.test(this.state.contact)){
            alert('Mobile number is invalid.')
        }else if(this.state.message == "") {
            alert("Please Enter Some Message.");
        }else if(this.state.termsaconditios === false) {
            alert("Please select terms and conditions.");
        }else{
            this.getPartnerUsFormSubmit();
        }
    }

    getPartnerUsFormSubmit()
    {
        const requestData = JSON.stringify({
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            pageSubject:'Enquiry From Become a Vyavsay Distributor - Partner With Us Page',
        });
        
        PostData(global.webistePageGetTouchForm, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                this.setState({name: '',contact: '',email: '',message: '', termsaconditios: false});
                sendNotification("Success Message 👍","Enquiry mail send successfully.","success");
            }
            else
            {
                sendNotification("Error Message 😓","Some error occured. Please try again.","danger");
                return;
            }
        });
    }

    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                            </div>
                        </section>
                        
                        <section className="sec7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6" align="center">
                                        <img src={global.webUrl+"websiteFilesFolder/img/samcopartnersbanner.png"} className="img101" />
                                        <h2 className="txt-style-10 space1">Become a vyavsay Distributor</h2>
                                        <p className="text-style-5A">
                                            Join India's No. 1 Mobile Billing & Accounting Software & <br />
                                            Earn Profits. Come be part of vyavsay Family.
                                        </p>
                                        <a className="btn4" data-toggle="modal" onClick={this.openModalHandler.bind(this)} data-target="#exampleModal2">View Benefits</a>
                                    </div>
                                    <div className="col-lg-6">
                                        <form method="post" id="formGetTouch" className="partner_form">
                                            <h2 className="txt-style-10">Fill out the form & we will get in touch with you</h2>
                                            <div className="form-group">
                                                <label>Name <span>*</span></label>
                                                <input type="text" onChange={this.handleChange} className={errors.name.length > 0 && 'errorClass'} value={this.state.name} placeholder="Enter Name Here" name="name" required />
                                            </div>
                                            <div className="form-group">
                                                <label>Email <span>*</span></label>
                                                <input type="email" onChange={this.handleChange} className={errors.email.length > 0 && 'errorClass'} value={this.state.email} placeholder="Enter Email Here" name="email" required />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone No. <span>*</span></label>
                                                <input type="text" onChange={this.handleChange} className={errors.contact.length > 0 && 'errorClass'} value={this.state.contact} placeholder="Enter Phone No. Here" name="contact" required />
                                            </div>
                                            <div className="form-group txt1"><input type="checkbox" id='termsaconditios' onClick={() => this.handleCheckBoxClick()} checked={this.state.termsaconditios}/> I accept <a>terms & conditions</a>.</div>
                                            <div className="form-group" align="center">
                                            <button type="button" disabled={isDisabled} onClick={this.clickToSubmitEnqury}>Submit</button>
                                            </div>
                                        </form>
                                        <div id="errorMessageLog"></div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />

                        {/* Modal for Price Show Begins */}
                        <Modal isOpen={this.state.modalIsOpen_AC} ariaHideApp={false} size="sm" onRequestClose={this.closeModalHandler} id="exampleModal2">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModalHandler} aria-label="Close">x</button>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <h2 className="txt-style3">Earn ₹1000 per License! Get 50% Commission on each License!</h2><table className="license-list">
                                                    <thead>
                                                        <tr>
                                                            <th>Number of Licenses (Sold per Month)</th>
                                                            <th>Commission</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>3 x ₹1000 = ₹3000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>4 x ₹1000 = ₹4000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>5 x ₹1000 = ₹5000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>And so on!</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            <div className="col-lg-6">
                                                    <h2 className="txt-style3">Exclusive benefits only for your customers</h2>
                                                    <div className="benefits-list-table">
                                                        <div className="benefit row">
                                                            <img src="https://vyaparwebsiteimages.vypcdn.in/img/pages/distributor/license - Copy.svg" alt="Vyapar Distributor" className="icon col s2 m2 l2"/>
                                                            <span className="benefit-desc col s10 m10 l10">Customers get extra validity when they buy licenses from Distributor</span>
                                                        </div>
                                                        <table className="benefit-table">
                                                            <thead>
                                                            <tr>
                                                                <th>LICENSE DURATION (months)</th>
                                                                <th>EXTRA VALIDITY (months)</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>12</td>
                                                                <td>3</td>
                                                            </tr>
                                                            <tr>
                                                                <td>36</td>
                                                                <td>6</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="benefit row">
                                                            <img src="https://vyaparwebsiteimages.vypcdn.in/img/pages/distributor/license - Copy.svg" alt="Vyavsay Distributor" className="icon col s2 m2 l2"/>
                                                            <span className="benefit-desc col s10 m10 l10">Flat 10% Commission on every Renewal.</span>
                                                        </div>
                                                        <div className="benefit row">
                                                            <img src="https://vyaparwebsiteimages.vypcdn.in/img/pages/distributor/baseline-person_pin.svg" alt="Vyavsay Distributor" className="icon col s2 m2 l2"/>
                                                            <span className="benefit-desc col s10 m10 l10">Local Assistance &amp; Help from Distributor on Vyavsay Software.</span>
                                                        </div>
                                                        <div className="benefit row">
                                                            <img src="https://vyaparwebsiteimages.vypcdn.in/img/pages/distributor/baseline-person_pin.svg" alt="Vyavsay Distributor" className="icon col s2 m2 l2"/>
                                                            <span className="benefit-desc col s10 m10 l10">Vyavsay Customer Care Support all time.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}