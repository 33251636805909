import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import validator from "validator";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import { Link } from "react-router-dom";
import QuickTransactionItemSection from "../userPurchase/quickTransactionItemNormalSection";
import QuickAddNewPrefix from "../userSale/quickNewPrefixAdd";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { data } from "jquery";

let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _businessSetting = {};
let length = 6;
let _invoiceNumber = Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;
      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;
    }
    /*For State Data*/
    this.state = {
      buttonTitle: "Add Sale Estimate",
      partyRemainBalance: 0,
      _partyReg: "iti__hide",
      _timeReg: "iti__hide",
      isDuplicate: false,
      party_id: "0",
      party_name: "",
      is_party: "",
      partyArrayFilter: [],
      partyArray: [],
      stateArray: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      modalIsOpen_SP: false,
      modalIsOpen_ADDPREFIX: false,
      billing_name: "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      isLoaded: true,
      isDateUpdate: false,
      modalIsOpen_LowStock: false,
      isChecked: false,
      lowStokItemName: "",
      receivedHide: "iti__hide",
      settingType: _settingType,
      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "",
      invoiceNo: _invoiceNumber,
      rate_tax: "With Tax",
      invoiceDate: moment().format("MM/DD/YYYY"),
      countryState: "",
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: [],
      dueAmount: 0,
      receivedAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      totalAmountOld: 0,
      totalTransDiscountAmount: 0.0,
      totalTransTaxAmount: 0.0,
      transactionWiseTaxId: 0,
      EnableItem: _businessSetting.EnableItem,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      TransAdditionalCharge1: 0,
      TransAdditionalCharge2: 0,
      TransAdditionalCharge3: 0,
      TransportDetailField1: "",
      TransportDetailField2: "",
      TransportDetailField3: "",
      TransportDetailField4: "",
      TransportDetailField5: "",
      TransportDetailField6: "",
      prefixId: 0,
      prefixName: "",
      prefixArray: [],
      handleAddDisable: "",
      party_contact_no: "",
      handleItemSalePurchasePrice: "",
      prefixD: 1,
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      additional_value_date: moment().format("MM/DD/YYYY"),
      transactionType: "ESTIMATE",
      totalLinkHistoryAmt : 0,
      transactionWiseTaxIdPer : 0,
      cheque_open_status : 0,
      errors: {
        invoiceNo: "",
        invoiceDate: "",
        party_id: "",
        party_name: "",
        party_contact_no: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    let _transactionType = "";
    let transactionId = 0;
    _handleParam = _handleParam.split("?auth=");
    console.log('_handleParam', JSON.stringify(this.props))
    let isDuplicate = false;
    if (_handleParam.length > 1) {
      _handleParam = _handleParam[1].split("&");
      transactionId = _handleParam[0] ? _handleParam[0] : 0;
      if (transactionId > 0) {
        _isEditMode = true;
      }
      _transactionType = _handleParam[1].split("tType=");
      _transactionType = _transactionType[1];
      if(_handleParam.length > 2) {
        isDuplicate = _handleParam[2].split("isDuplicate=");
        isDuplicate = isDuplicate[1];
        this.setState({isDuplicate: isDuplicate})
      }
    }

    this.getStateListData();
    //this.getItemListData(_company_id);

    /*For master Units*/
    let _urlDataUnits = global.userUnitList + "?businessId=" + _businessId+'&company_id='+this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    this.setState({ unitArray: _unitArray, isLoaded: true });
    /*For master Units*/

    /*Set Setting Data */
    let _prefixSetArray = [];
    let _SalePrefixName = "";
    let _SalePrefixID = "";
    const requestData = JSON.stringify({
      businessId: _businessId,
    });
    PostData(global.businessPrefixSetData, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _prefixSetArray = responseJson.response;
            _SalePrefixName = _prefixSetArray[0].SalePrefixName;
            _SalePrefixID = _prefixSetArray[0].SalePrefixId;
          }
          this.setState({
            prefixName: _SalePrefixName,
            prefixId: _SalePrefixID,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
    /*Set Setting Data */

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({isLoaded:true})
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/manage-sale-estimate/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let _prefixDataUpdate = _transactionArray.prefix;
              let itemsData = _transactionArray.items;
              let customerData = _transactionArray.customer;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              let _additional_value_date =  _transactionArray.additional_value_date;
              if(_transactionArray.additional_value_date == ''){
                _additional_value_date = moment().format("MM/DD/YYYY");
              }
              let _invoiceDate = moment(_transactionArray.invoiceDate).format("MM/DD/YYYY");
              let _invoice_time = _transactionArray.invoice_time;
              if(this.state.isDuplicate == true || isDuplicate ==  true || this.state.isDuplicate == 'true'){
                _invoiceDate = moment().format("MM/DD/YYYY");
                _invoice_time = _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A");
              }
              this.setState({
                billing_name: customerData.billing_name ? customerData.billing_name: "",
                party_id: customerData.party_id,
                party_name: customerData.party_name,
                unitArray: _unitArray,
                buttonTitle: "Edit Sale Estimate",
                invoiceNo: isDuplicate ? _invoiceNumber : _transactionArray.invoiceNo,
                invoiceDate:_invoiceDate,
                invoiceTime: _invoice_time,
                state: _transactionArray.countryState,
                countryState: _transactionArray.countryState,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                totalAmount: transAmout.totalAmount,
                receivedAmount: transAmout.receivedAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.dueAmount,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _transactionArray.paymentRefNo,
                fields: itemsData,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                EnableItem: _transactionArray.EnableItem,
                DisplayPurchasePrice: _transactionArray.DisplayPurchasePrice,
                FreeItemQuantity: _transactionArray.FreeItemQuantity,
                TransCount: _transactionArray.Count,
                settingType: _transactionArray.settingType,
                prefixId: _prefixDataUpdate.id,
                prefixName: _prefixDataUpdate.name,
                totalAmountOld: transAmout.totalAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,
                TransAdditionalCharge1:
                  _transactionArray.TransAdditionalCharge1,
                TransAdditionalCharge2:
                  _transactionArray.TransAdditionalCharge2,
                TransAdditionalCharge3:
                  _transactionArray.TransAdditionalCharge3,
                TransportDetailField1: _transactionArray.TransportDetailField1,
                TransportDetailField2: _transactionArray.TransportDetailField2,
                TransportDetailField3: _transactionArray.TransportDetailField3,
                TransportDetailField4: _transactionArray.TransportDetailField4,
                TransportDetailField5: _transactionArray.TransportDetailField5,
                TransportDetailField6: _transactionArray.TransportDetailField6,
                party_contact_no: _transactionArray.party_contact_no,
                additional_value_1: _transactionArray.additional_value_1,
                additional_value_2: _transactionArray.additional_value_2,
                additional_value_3: _transactionArray.additional_value_3,
                additional_value_date: moment(_additional_value_date).format("MM/DD/YYYY"),
                transactionType: _transactionType,
                totalLinkHistoryAmt : _transactionArray.totalLinkHistoryAmt,
                cheque_open_status : _transactionArray.cheque_open_status,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
    this.getPartyListData();
    this.businessPrefixList();
  }

  businessPrefixList() {
    let _prefixArray = [];
    const requestData = JSON.stringify({
      company_id: _company_id,
      businessId: _businessId,
      type: "Estimate",
    });
    PostData(global.businessWebPrefixList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, prefixD: 1 });
        } else {
          if (responseJson.response) {
            _prefixArray = responseJson.response;
            _prefixArray = _prefixArray.filter(
              (itemData) => itemData.prefix!=null
            );
          }
          this.setState({
            prefixArray: _prefixArray,
            isLoaded: true,
            prefixD: 1,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getStateListData() {
    let _stateArray = [];

    let _url_GetData =
      global.adminStateList + "?countryCode=" + this.state.countryCode;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _stateArray = responseJson.response;
          }
          this.setState({ stateArray: _stateArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            _partyArray = responseJson.response;

            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getTotalAmount(value, _type) {
    let _mainSubTotalAmt = 0;
    let _totalOtherCharges = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt += parseFloat(data.total_amount) + parseFloat(data.tax_value) - parseFloat(data.discount_in_amount);
    });
    let _totalTaxAmount = _mainSubTotalAmt;

    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1
      ? this.state.TransAdditionalCharge1 : 0;

    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2
      ? this.state.TransAdditionalCharge2 : 0;

    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3
      ? this.state.TransAdditionalCharge3: 0;      

    let _totalTransTaxAmount = this.state.totalTransTaxAmount  ? this.state.totalTransTaxAmount : 0;

    let _totalTranDisAmount = this.state.totalTransDiscountAmount ? this.state.totalTransDiscountAmount : 0;
    
    if (_type == "totalTransDiscountPer")
    {
      _totalTranDisAmount = (parseFloat(_totalTaxAmount)) * ((parseFloat(value)) / parseFloat(100));
    }
    if (_type == "totalTransDiscountAmount")
    {
      _totalTranDisAmount = parseFloat(value);
    }
    
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    if (_type == "taxTypeTrans") {
      _totalTransTaxAmount = parseFloat(value);
    }

    let _totalAmount = parseFloat(_totalTaxAmount) - parseFloat(_totalTranDisAmount);

    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3) + parseFloat(_totalTransTaxAmount);

    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);

    return _totalAmount;
  }

  getTotalDueAmount(_totalAmount) {
    let _dueAmountAK = _totalAmount;
    if (this.state.receivedAmount > 0) {
      _dueAmountAK = parseFloat(_totalAmount) - parseFloat(this.state.receivedAmount);
    }
    if (_dueAmountAK < 0) {
      _dueAmountAK = 0;
    }
    return _dueAmountAK;
  }

  //Party Wise Item Rate Ak
  getItemListPartyData(company_id, partyId) {
    let _itemsArray = [];
    const requestData = JSON.stringify({
      company_id: company_id,
      partyId: partyId,
    });
    PostData(global.userItemSalePurPartyList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
          }
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
          value.length < 0 ? "*Invoice No Must be 4 numbers" : "";

        if (validator.isInt(value)) {
          errors.invoiceNo = "";
        } else {
          errors.invoiceNo = "*Please enter only nuemeric value.";
        }
        this.setState({ invoiceNo: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "party_contact_no":
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          if(value.length == 0 || value == ''){
            errors.party_contact_no = "";
          }else{
            errors.party_contact_no = "*Please enter valid mobile number!";
          }
        }
        this.setState({ party_contact_no: value });
      break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        this.setState({ paymentType: value });
        break;
      case "receivedAmount":
        let _remainAmount = 0;
        let _paidAmt = value;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        if (_paidAmt >= 0) {
          _remainAmount =
            parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
        }
        this.setState({
          receivedAmount: _paidAmt,
          dueAmount: _remainAmount.toFixed(2),
        });
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      case "totalAmount":
        this.setState({ totalAmount: value, totalAmountOld: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;
      
      case "totalTransDiscountPer":
          
      if (!validator.isInt(value)) {    
        let _mainSubTotalAmt = 0;
        this.state.fields.map((data) => {
          _mainSubTotalAmt += parseFloat(data.total_amount);
        });
        this.setState({            
          totalAmount: _mainSubTotalAmt,
          totalTransDiscountAmount: 0,
          totalTransDiscountPer: 0,
          dueAmount: _mainSubTotalAmt,
          transactionWiseTaxId  : 0,
        });        
        //alert("Charges can not be less than zero or character");
        //return;
      }else{  
        let _valueSend = value;
        if (value > 100) {
          alert("Discount cannot be more than 100%");
        } 
        else {
          if (_valueSend == "" || _valueSend == 0) {
            _valueSend = 0;
          }
          let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          let _finalTotalAmt = _mainSubTotalAmt;
          let _payableAmount = 0;

          let _totalTranDiscPerAmount = (parseFloat(_mainSubTotalAmt) * parseFloat(_valueSend)) / parseFloat(100);

          let _afterDisTotAmt = (parseFloat(_finalTotalAmt) - parseFloat(_totalTranDiscPerAmount)).toFixed(2);
          
          let _percGstAmt = 0;
          if(this.state.transactionWiseTaxIdPer > 0){
            _percGstAmt = (parseFloat(_afterDisTotAmt) * parseFloat(this.state.transactionWiseTaxIdPer)) / parseFloat(100);
            _percGstAmt = _percGstAmt.toFixed(2)
          }
          _finalTotalAmt = parseFloat(_afterDisTotAmt) + parseFloat(_percGstAmt); 

          let _totalAdditionalCharges = parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3); 
          
          _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(_totalAdditionalCharges);  
          _payableAmount = _finalTotalAmt;                       

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            totalTransDiscountAmount: _totalTranDiscPerAmount.toFixed(2),
            totalTransDiscountPer: _valueSend,
            dueAmount: _payableAmount.toFixed(2),
            totalTransTaxAmount: _percGstAmt,
            transactionWiseTaxId  : 0,
          });

          if (_valueSend <= 0 || _valueSend == "") {
              this.setState({
                totalAmount: _finalTotalAmt,
                totalTransDiscountAmount: 0,
                totalTransDiscountPer: 0,
                dueAmount: _finalTotalAmt,
                transactionWiseTaxId  : 0,
              });
            }
          }
        }
      break;
      
      case "totalTransDiscountAmount":
              
        if (!validator.isInt(value)) {  
          
          /*let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          this.setState({            
            totalAmount: _mainSubTotalAmt,
            totalTransDiscountAmount: 0,
            totalTransDiscountPer: 0,
          });               
          alert("Charges can not be less than zero or character");
          return;*/
          let _value = value;
          let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          if (parseFloat(_value) > parseFloat(_mainSubTotalAmt)) {
            alert("Discount amount cannot be more than total amount.");
          }
          else {
            if (_value == "" || _value == undefined || _value == null) {
              _value = 0;
            }
              
            let _finalTotalAmt = _mainSubTotalAmt;
            let _payableAmount = 0;

            let _totalTranDiscPerAmount = parseFloat(_value * 100) / parseFloat(_mainSubTotalAmt);
            
            let _afterDisTotAmt = (parseFloat(_finalTotalAmt) - parseFloat(_value)).toFixed(2);
            let _percGstAmt = 0;
            if(this.state.transactionWiseTaxIdPer > 0){
              _percGstAmt = (parseFloat(_afterDisTotAmt) * parseFloat(this.state.transactionWiseTaxIdPer)) / parseFloat(100);
              _percGstAmt = _percGstAmt.toFixed(2)
            }
            
            _finalTotalAmt = parseFloat(_afterDisTotAmt) + parseFloat(_percGstAmt); 

            let _totalAdditionalCharges = parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3); 

            _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(_totalAdditionalCharges);
            _payableAmount = _finalTotalAmt.toFixed(2);
            

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              totalTransDiscountPer: _totalTranDiscPerAmount.toFixed(2),
              totalTransDiscountAmount: value,
              dueAmount: _payableAmount,
              totalTransTaxAmount: _percGstAmt,
              transactionWiseTaxId  : 0,
            });

            if (_value <= 0 || _value == "") {
              this.setState({
                totalAmount: _finalTotalAmt,
                totalTransDiscountAmount: _value,
                totalTransDiscountPer: 0,
                transactionWiseTaxId  : 0,
              });
            }
          }
        }
        else{  
          let _value = value;
          let _mainSubTotalAmt = 0;
          this.state.fields.map((data) => {
            _mainSubTotalAmt += parseFloat(data.total_amount);
          });
          if (parseFloat(_value) > parseFloat(_mainSubTotalAmt)) {
            alert("Discount amount cannot be more than total amount.");
          }
          else {
            if (_value == "" || _value == undefined || _value == null) {
              _value = 0;
            }
              
            let _finalTotalAmt = _mainSubTotalAmt;
            let _payableAmount = 0;

            let _totalTranDiscPerAmount = parseFloat(_value * 100) / parseFloat(_mainSubTotalAmt);
            
            let _afterDisTotAmt = (parseFloat(_finalTotalAmt) - parseFloat(_value)).toFixed(2);
            let _percGstAmt = 0;
            if(this.state.transactionWiseTaxIdPer > 0){
              _percGstAmt = (parseFloat(_afterDisTotAmt) * parseFloat(this.state.transactionWiseTaxIdPer)) / parseFloat(100);
              _percGstAmt = _percGstAmt.toFixed(2)
            }
            
            _finalTotalAmt = parseFloat(_afterDisTotAmt) + parseFloat(_percGstAmt); 

            let _totalAdditionalCharges = parseFloat(this.state.TransAdditionalCharge1) + parseFloat(this.state.TransAdditionalCharge2) + parseFloat(this.state.TransAdditionalCharge3); 

            _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(_totalAdditionalCharges);
            _payableAmount = _finalTotalAmt.toFixed(2);
            

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              totalTransDiscountPer: _totalTranDiscPerAmount.toFixed(2),
              totalTransDiscountAmount: value,
              dueAmount: _payableAmount,
              totalTransTaxAmount: _percGstAmt,
              transactionWiseTaxId  : 0,
            });

            if (_value <= 0 || _value == "") {
              this.setState({
                totalAmount: _finalTotalAmt,
                totalTransDiscountAmount: _value,
                totalTransDiscountPer: 0,
                transactionWiseTaxId  : 0,
              });
            }
          }
        }  
        
      break;         
      
      case "TransAdditionalCharge1":
        
        if (!validator.isInt(value)) { 
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge1");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge1");
          this.setState({            
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge1: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });         
          //alert("Charges can not be less than zero or character");
          return;
        }else{            
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{ 
            let _val1 = value;
            if (_val1 == "" || _val1 == undefined || _val1 == null) {
              _val1 = 0;
            }
            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val1, "TransAdditionalCharge1");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val1, "TransAdditionalCharge1");

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge1: value,
              dueAmount: _finalDueAmt.toFixed(2),
            });

            if (_val1 <= 0 || _val1 == "") {
              this.setState({
                TransAdditionalCharge1: _val1,
              });
            }
          }
        }

        break;

      case "TransAdditionalCharge2":
          if (!validator.isInt(value)) {              
            let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge2");
            let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge2");
            this.setState({            
              totalAmount: _finalTotalAmt_1.toFixed(2),
              TransAdditionalCharge2: 0,
              dueAmount: _finalDueAmt_1.toFixed(2),
            });              
            //alert("Charges can not be less than zero or character");
            return;
          }else{            
            if (value < 0) {
              alert("Charges can not be less than zero");
            }
            else{              
              let _val2 = value;
              console.log(_val2+"==_val1=="+value);            
              if (_val2 == "" || _val2 == undefined || _val2 == null) {
                _val2 = 0;
              }
              
              let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val2, "TransAdditionalCharge2");
              let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val2, "TransAdditionalCharge2");
    
              console.log(_finalTotalAmt+"==T--D=="+_finalDueAmt);
              this.setState({
                totalAmount: _finalTotalAmt.toFixed(2),
                TransAdditionalCharge2: value,
                dueAmount: _finalDueAmt.toFixed(2),
              });
              
              
              if (_val2 <= 0 || _val2 == "") {
                this.setState({
                  TransAdditionalCharge2: _val2,
                });
              }
            }
          }
          
        break;

      case "TransAdditionalCharge3":        
        if (!validator.isInt(value)) {             
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge3");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge3");
          this.setState({            
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge3: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });           
          //alert("Charges can not be less than zero or character");
          return;
        }else{            
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{ 
            let _val1 = value;
            if (_val1 == "" || _val1 == undefined || _val1 == null) {
              _val1 = 0;
            }
            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val1, "TransAdditionalCharge3");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val1, "TransAdditionalCharge3");

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge3: _val1,
              dueAmount: _finalDueAmt.toFixed(2),
            });
            
            if (_val1 <= 0 || _val1 == "") {
              this.setState({
                TransAdditionalCharge1: _val1,
              });
            }
          }
        }
        break;
    
      case "settingType":
        this.setState({ settingType: value });
        break;

      case "TransportDetailField1":
        this.setState({ TransportDetailField1: value });
        break;

      case "TransportDetailField2":
        this.setState({ TransportDetailField2: value });
        break;

      case "TransportDetailField3":
        this.setState({ TransportDetailField3: value });
        break;

      case "TransportDetailField4":
        this.setState({ TransportDetailField4: value });
        break;

      case "TransportDetailField5":
        this.setState({ TransportDetailField5: value });
        break;

      case "TransportDetailField6":
        this.setState({ TransportDetailField6: value });
        break;

      case "selPrefix":
        if (value == "ADDPREFIX") {
          this.openModalHandler("modalAdjustADDPREFIX");
        }
        let _prefixId = 0;
        let _prefixName = "";
        if (value > 0) {
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _prefixId = optionElement.getAttribute("data-prefixId");
          _prefixName = optionElement.getAttribute("data-prefixName");
        }
        this.setState({ prefixId: _prefixId, prefixName: _prefixName });
        break;

      case "additional_value_date":
        this.setState({ additional_value_date: value });
        break;
      case "additional_value_1":
        this.setState({ additional_value_1: value });
        break;
      case "additional_value_2":
        this.setState({ additional_value_2: value });
        break;
      case "additional_value_3":
        this.setState({ additional_value_3: value });
        break;

      default:
        break;
    }
  };

  getTotalAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalAmount = this.state.totalAmountOld;
    console.log(value+'==value--_type--'+_type);
    console.log(this.state.totalAmount+'==totalAmountOld--'+this.state.totalAmountOld);
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);
    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);
    return _totalAmount;
  }

  getTotalRemainAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalDuePayAmount = this.state.totalAmountOld;
    
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);

    _totalDuePayAmount = parseFloat(_totalDuePayAmount) + parseFloat(_totalOtherCharges);
    return _totalDuePayAmount;
  }	

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: true });
    } else if (e == "modalLowStockItem") {
      this.setState({ modalIsOpen_LowStock: true });
    } else if (e == "modalAdjustADDPREFIX") {
      this.setState({ modalIsOpen_ADDPREFIX: true, prefixD: 0 });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: false });
    } else if (e == "modalAdjustADDPREFIX") {
      this.setState({
        modalIsOpen_ADDPREFIX: false,
        _infoPrefixId: 0,
        _infoTitlePre: "",
        prefixId: 0,
        prefixName: "",
        prefixD: 1,
      });
    }
  };
  /*End Modal open close*/

  /*Low Stock Data */
  closeModalHandlerLowStock = (e) => {
    if (e == "modalLowStockItem") {
      this.setState({ modalIsOpen_LowStock: false });
    }
  };
  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  SaveModalHandlerLowStock = (e) => {
    let _valueCheck = 0;
    if (this.state.isChecked == true) {
      _valueCheck = 1;
    }
    if (e == "modalLowStockItem") {
      const requestDataLowStock = JSON.stringify({
        settingValue: _valueCheck,
        settingKey: "lowStockStatus",
        businessId: _businessId,
        passcode: 0,
      });
      PostData(global.businessSettingUpdate, requestDataLowStock, "POST").then(
        (result) => {
          if (result.success == true) {
            let _UserSession = localStorage.getItem("vs_UserSession");
            let _updateLocal = _UserSession;
            let _businessSettingData = _updateLocal.businessSetting;
            _businessSettingData["lowStockStatus"] = _valueCheck;
            localStorage.removeItem("vs_UserSession");
            localStorage.setItem(
              "vs_UserSession",
              JSON.stringify(_updateLocal)
            );
            this.addSaleTransactionMethodCall();
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
      this.setState({ modalIsOpen_LowStock: false });
    }
  };
  /*Low Stock Data */

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  
  handleDynamicInputsTrans(event) {
    let dataVal = event.target.value;
    const finalTaxTrans = dataVal.split("@");
    let _percGstAmt = 0;
    let _mainSubTotalAmt = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt += parseFloat(data.total_amount);
    }); 
    if (finalTaxTrans[1] >= 0) {      
      let _totalTaxAmount = _mainSubTotalAmt;//this.state.totalAmount;

      _percGstAmt =(parseFloat(_totalTaxAmount) * parseFloat(finalTaxTrans[1])) / parseFloat(100);
      _totalTaxAmount = parseFloat(_totalTaxAmount) + parseFloat(_percGstAmt); 

      let _finalTotalAmt = _totalTaxAmount;

      let _payableAmt = _finalTotalAmt.toFixed(2);

      this.setState({
        totalAmount: _finalTotalAmt.toFixed(2),
        dueAmount: _payableAmt,
        totalTransTaxAmount: _percGstAmt.toFixed(2),
        transactionWiseTaxId: finalTaxTrans[0],
        transactionWiseTaxIdPer: finalTaxTrans[1],
      });
    }
  }
  /* End Add More Data */

  /* Add Party Code */
  selectPartiess(name, e, balance, party_contact_no) {
    let errors = this.state.errors;
    errors.party_name = "";
    this.setState({
      _partyReg: "iti__hide",
      party_name: name,
      party_id: e,
      partyRemainBalance: balance,
      party_contact_no: party_contact_no,
    });
    this.state.is_party = "yes";
    //Party Wise Item Rate Ak
    if (_businessSetting.PartyWiseItemRate > 0) {
      this.getItemListPartyData(_company_id, e);

      this.setState({ fields: [] });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({
      partyArrayFilter: filtered,
      party_name: keyword,
      is_party: "No",
    });
    this.getReadyPartyList();
  };

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }

          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance,
                party.party_contact_no
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(Math.abs(_balance))}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }
  /* End Party Code */

  /* Start Item Code */
  onItemFilterName = () => {
    const data = this.state.itemArray;
    let fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        data.filter(function (item, index) {
          if (item.item_id == field.item_id) {
            data[index].isFilter = true;
          }
        });
      });
    }
    this.setState({ itemArrayFilter: data });
    this.getReadyItemList();
  };

  onItemFilterTypeName = (idx, e) => {
    const data = this.state.itemArray;
    let _name = e.target.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );

    const fieldArray = this.state.fields;
    fieldArray.map((field, index) => {
      if (index == idx) {
        field.item_name = keyword;
      }
    });

    this.setState({
      itemArrayFilter: filtered,
      item_name: keyword,
      fields: fieldArray,
    });
    this.getReadyItemList();
  };

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowPartyFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  getReadyItemList(index) {
    let data = [];
    if (this.state.isLoaded == true) {
      let _itemsArray = this.state.itemArrayFilter;
      if (_itemsArray.length > 0) {
        data = this.state.itemArrayFilter.map((items, i) => {
          if (items.isFilter == false) {
            let _obj = {};
            let _itemUnitArray = [];

            if (items.base_unit_id > 0) {
              _itemUnitArray.push(items.unit);
            } else {
              _itemUnitArray.push(this.state.unitArray);
            }

            _obj.index = index;
            _obj.name = items.name;
            _obj.id = items.item_id;
            _obj.price = items.sale_price;
            _obj.base_unit_id = items.base_unit_id;
            _obj.unitindividual = _itemUnitArray;
            _obj.tax_type = items.tax_type;
            _obj.discount_in_sale_price = items.discount_in_sale_price;
            _obj.discount_type = items.discount_type;
            _obj.purchase_price = items.purchase_price;
            _obj.availableQty = items.availableQty;
            _obj.type = items.type;

            return (
              <li
                key={i}
                className="iti__party iti__standard"
                id="iti-item-in"
                onClick={this.handleDynamicInputs.bind(this, _obj, "item")}
              >
                <span className="iti__party-name">
                  {items.name} <br />
                  {this.state.DisplayPurchasePrice == 1 && (
                    <span> Purchase Price : {items.purchase_price}</span>
                  )}
                </span>
              </li>
            );
          }
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewItem")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Item
            </span>
          </li>
        );
      }
    }
    return data;
  }
  /* End Item Code */

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }

    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Ref No.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (
          _businessSetting.ShowLowStockDialog == 1 &&
          _businessSetting.lowStockStatus == 0
      ) {
        if (this.state.lowStokItemName.length > 0) {
          this.openModalHandler("modalLowStockItem");
          return false;
        } else {
          this.addSaleTransactionMethodCall();
        }
      } else {
        /*End Low Stock Data*/
        this.addSaleTransactionMethodCall();
      }
    }
  };

  addSaleTransactionMethodCall() {
    let _mainSubTotalAmt = 0; //Add by AK
    let _mainTotalAmt = this.state.totalAmount; //Add by AK
    let _itemPriceStatus = false;
    let _prefix = JSON.stringify({
      name: this.state.prefixName,
      id: this.state.prefixId,
      isActive: "1",
    });

    if (this.state.handleAddDisable.length > 2) {
      alert("Cannot submit empty item row, please select item.");
      return false;
    }
    if (this.state.handleItemSalePurchasePrice.length > 2) {
      alert("Item price can not be zero");
      return false;
    }

    if (_mainTotalAmt == 0) {
      _mainTotalAmt = this.state.totalAmount;
      _mainSubTotalAmt = this.state.totalAmount;
    } else {
      
      let _mainTotalAmount = 0;
      this.state.fields.map((data) => {
        _mainTotalAmount = parseFloat(_mainTotalAmount) + parseFloat(data.total_amount);  

        if(parseFloat(data.rate <= 0) || data.rate == 0 || data.rate == '0' || data.rate == ''){
          _itemPriceStatus = true;
        }
      }); //Add by AK      
      _mainTotalAmt = _mainTotalAmount;
      if (parseFloat(this.state.totalTransDiscountAmount) > 0 || parseFloat(this.state.totalTransTaxAmount) > 0)
      {
        _mainTotalAmt = parseFloat(this.state.totalAmount);
        _mainSubTotalAmt = parseFloat(this.state.totalAmountOld);
      }
    }

    if(_itemPriceStatus == true){
      sendNotification(
        "Error Message 😓",
        "Item price can not 0",
        "warning"
      );
      return;
    }
    
    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmountOld, //this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount ? this.state.totalDiscount : 0,
      totalAmount: this.state.totalAmount, //_mainTotalAmt,
      receivedAmount: this.state.receivedAmount ? this.state.receivedAmount : 0,
      dueAmount: this.state.dueAmount ? this.state.dueAmount : 0,
    }); //Add by AK



    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId
        ? this.state.transactionWiseTaxId
        : 0,
      totalTransTaxAmount: this.state.totalTransTaxAmount
        ? this.state.totalTransTaxAmount
        : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
        ? this.state.totalTransDiscountPer
        : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
        ? this.state.totalTransDiscountAmount
        : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
        ? this.state.TransAdditionalCharge1
        : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
        ? this.state.TransAdditionalCharge2
        : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
        ? this.state.TransAdditionalCharge3
        : 0,
    }); //Add by AK

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
        ? this.state.TransportDetailField1
        : "",
      TransportDetailField2: this.state.TransportDetailField2
        ? this.state.TransportDetailField2
        : "",
      TransportDetailField3: this.state.TransportDetailField3
        ? this.state.TransportDetailField3
        : "",
      TransportDetailField4: this.state.TransportDetailField4
        ? this.state.TransportDetailField4
        : "",
      TransportDetailField5: this.state.TransportDetailField5
        ? this.state.TransportDetailField5
        : "",
      TransportDetailField6: this.state.TransportDetailField6
        ? this.state.TransportDetailField6
        : "",
    }); //Add by AK

    if(this.state.cheque_open_status === 1){
      sendNotification(
        "Error Message 😓",
        "This transaction can not be edited because cheque associated with the transaction is already   closed. Re-open the cheque to edit this transaction.",
        "warning"
      );
      return;
    }
    
    var saleData = new FormData();
    //saleData.append("transaction_id", _isEditMode ? this.state.transactionId : undefined);
    
    saleData.append("isDuplicate",this.state.isDuplicate);
    saleData.append("transaction_id", this.state.transactionId);
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "ESTIMATE");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    saleData.append("invoiceTime", this.state.invoiceTime);

    saleData.append("totalSummary", _totalSummary);
    saleData.append("transactionWiseAmount", _transactionWiseAmount);
    saleData.append(
      "transactionAdditionalCharge",
      _transactionAdditionalCharge
    );
    saleData.append(
      "transactionTransportationDetails",
      _transacTransportationDetails
    );
    saleData.append("customer", _customer);
    saleData.append("items", JSON.stringify(this.state.fields));
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("settingType", this.state.settingType);
    saleData.append("additional_value_1", this.state.additional_value_1);
    saleData.append("additional_value_2", this.state.additional_value_2);
    saleData.append("additional_value_3", this.state.additional_value_3);
    saleData.append("additional_value_date",moment(this.state.additional_value_date).format("MMMM D, YYYY"));

    new Promise((resolve, reject) => {
      //let url = this.state.isDuplicate ? global.createSaleEstimateQuotationDuplicateTransaction : (this.state.transactionId >0 ? global.createEditSaleEstimateTransaction : global.createSaleEstimateQuotationTransaction)
      
      let url = this.state.transactionId > 0 ? global.createEditSaleEstimateTransaction : global.createSaleEstimateQuotationTransaction;
      if(this.state.isDuplicate === true || this.state.isDuplicate === 'true'){
        url = global.createSaleEstimateQuotationDuplicateTransaction;
      }
      fetch(url, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              if (this.state.transactionType == "ITEM") {
                window.location.href = global.webUrl + "business/manage-items/";
              } else if (this.state.transactionType == "PARTY") {
                window.location.href = global.webUrl + "business/manage-party/";
              } else if (this.state.transactionType == "SERVICE") {
                window.location.href = global.webUrl + "business/manage-service/";
              } else if (this.state.transactionType == "Bank") {
                window.location.href = global.webUrl + "business/manage-account-list/";
              } else if (this.state.transactionType == "Dashboard") {
                window.location.href = global.webUrl + "business/dashboard/";
              } else if (this.state.transactionType == "Report") {
                window.location.href = global.webUrl + "business/reports/";
              } else {
                if(_isEditMode){
                  window.location.href =
                      global.webUrl + "business/manage-sale-estimate/";
                }else{
                  window.location.href =
                      global.webUrl + "business/download-pdf/?auth=" + res.transaction_Id;
                }

              }
            }, Math.floor(Math.random() * 5000) + 1);

          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  /* End Submit button */

  handleCallBackDate = (start) => {
    this.setState({ invoiceDate: start.format("MMMM D, YYYY") });
  };

  isDateRefresh() {
    if(this.state.isDuplicate === 'true' || this.state.isDuplicate === true){
      return (
          <input type="text" className="form-control" readOnly={true}  dataSend="1111" value={this.state.invoiceDate} disabled={true}/>
      );
    }else{
      let _invoiceDate = this.state.invoiceDate;
      if (_isEditMode) {
        return (
          <DateRangePicker
            initialSettings={{
              singleDatePicker: true,
              startDate: _invoiceDate,
            }}
            onCallback={this.handleCallBackDate}
          >
            <input type="text" className="form-control" readOnly dataSend="1111" value={this.state.invoiceDate}/>
          </DateRangePicker>
        );
      } else if (this.state.isLoaded == true && _isEditMode == false) {
        return (
          <DateRangePicker
            initialSettings={{
              singleDatePicker: true,
              startDate: _invoiceDate,
            }}
            onCallback={this.handleCallBackDate}
          >
            <input type="text" className="form-control" readOnly />
          </DateRangePicker>
        );
      } else if (this.state.isLoaded == true && _isEditMode == true) {
        return (
          <DateRangePicker
            initialSettings={{
              singleDatePicker: true,
              startDate: _invoiceDate,
            }}
            onCallback={this.handleCallBackDate}
          >
            <input type="text" className="form-control" readOnly />
          </DateRangePicker>
        );
      }
    }
    
  }

  isAdditionalDateRefresh() {
    if(this.state.isDuplicate === 'true' || this.state.isDuplicate === true){
      return (
          <input type="text" id="additional_value_date" name="additional_value_date" className="form-control" readOnly={true}  dataSend="1111" value={this.state.additional_value_date} />
      );
    }else{
        let _additional_value_date = this.state.additional_value_date;
        return (
          <DateRangePicker
            initialSettings={{
              singleDatePicker: true,
              startDate: _additional_value_date,
            }}
            onCallback={this.handleCallBackAddDate}
          >
            <input
              type="text"
              id="additional_value_date"
              name="additional_value_date"
              className="form-control"
              value={this.state.additional_value_date}
              readOnly
            />
          </DateRangePicker>
        );
    }
  }
  handleCallBackAddDate = (start) => {
    this.setState({ additional_value_date: start.format("MMMM D, YYYY") });
  };

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = true;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    } else {
      if (this.state.totalAmount > 0) {
        _hideRows = "";
        _readonlyInput = true;
        _readonlyCss = "";
      }
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    /*For Low Stock Enable */
    let _finalStockHtml = "";
    let _lowStokItemName = this.state.lowStokItemName;
    if (this.state.lowStokItemName.length == 1) {
      _finalStockHtml = [];
      _finalStockHtml.push(<li>{_lowStokItemName}</li>);
    }
    if (this.state.lowStokItemName.length > 1) {
      const products = this.state.lowStokItemName;
      _finalStockHtml = [];
      products.forEach((product) => {
        _finalStockHtml.push(<li>{product}</li>);
      });
    }
    /*End Low Stock Enable */

    /*let _handleParamItemPage = this.props.location.search;
        _handleParamItemPage = _handleParamItemPage.split('?auth=');
        let _transId = _handleParamItemPage[1] ? _handleParamItemPage[1] : 0;*/

    let _transId = this.state.transactionId;
    let _colorBal = "text-normal";
    if (this.state.partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (this.state.partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }
    let _isDuplicateRead = false;
    let _isDuplicateDisa = false;
    let _sendPageType = 'Normal';
    if(this.state.isDuplicate === true || this.state.isDuplicate === 'true'){
      _isDuplicateRead = true;
      _isDuplicateDisa = true;
      _sendPageType = 'Duplicate';
    }
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Sale
                Estimate(Quotation)
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/manage-sale-estimate/"}
            >
              Manage Sale Estimate List
            </a>
            <span className="breadcrumb-item active">
              Add / Edit Sale Estimate(Quotation)
            </span>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/add-sale-estimate/"}
              target="_blank"
            >
              <i className="fa fa-plus"> (Add More)</i>
            </a>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandler.bind(
                            this,
                            "I"
                          )}
                        >
                          <input
                            type="text"
                            className="form-control search-box"
                            onChange={this.onPartyFilterName}
                            onClick={this.manageShowPartyFilter.bind(this, "I")}
                            onKeyDown={this.manageShowPartyFilter.bind(
                              this,
                              "I"
                            )}
                            placeholder="Search Party Name Here..."
                            autoComplete="off"
                            name="party_name"
                            id="party_name"
                            value={this.state.party_name}
                            readOnly={_isDuplicateRead}
                          />
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>
                          <ul
                            className={
                              this.state._partyReg +
                              " iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {this.state.isDuplicate === '' || this.state.isDuplicate === false &&
                              this.getReadyPartyList()
                            }
                          </ul>
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>

                          <div className={_colorBal}>
                            {this.state.party_name!="" &&
                            this.state.is_party!="No"
                              ? "Bal : " +
                                numberFormat(
                                  Math.abs(this.state.partyRemainBalance)
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Phone Number"
                            autoComplete="off"
                            name="party_contact_no"
                            id="party_contact_no"
                            value={this.state.party_contact_no}
                            onChange={this.handleChange}
                            maxLength="13"
                            readOnly={_isDuplicateRead}
                          />
                          <label htmlFor="login-username">Phone Number</label>
                          <span className="iris_error_txt">
                            {errors.party_contact_no.length > 0 && (
                              <span className="error">
                                {errors.party_contact_no}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Name Here..."
                              autoComplete="off"
                              name="billing_name"
                              id="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Name</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      {_businessSetting.InvoiceBillNo == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Ref No. <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-3 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control "
                              id="selPrefix"
                              name="selPrefix"
                              required="required"
                              onChange={this.handleChange}
                              readOnly={_isDuplicateRead}
                              disabled={_isDuplicateDisa}
                            >
                              {this.state.prefixD == 1 && (
                                <option value="0" selected="selected">
                                  {" "}
                                  None
                                </option>
                              )}
                              {this.state.prefixArray.map(
                                (prefixData, index) => {
                                  let _selPrefix = "";
                                  if (this.state.transactionId == 0) {
                                    if (
                                      prefixData.prefix == this.state.prefixName
                                    ) {
                                      _selPrefix = "selected";
                                    }
                                  } else {
                                    if (
                                      this.state.prefixId ==
                                      prefixData.prefix_id
                                    ) {
                                      _selPrefix = "selected";
                                    }
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={prefixData.prefix_id}
                                      selected={_selPrefix}
                                      data-prefixName={prefixData.prefix}
                                      data-prefixId={prefixData.prefix_id}
                                    >
                                      {prefixData.prefix}
                                    </option>
                                  );
                                }
                              )}
                              <option value="ADDPREFIX"> Add New Prefix</option>
                            </select>
                          </div>
                          &nbsp;&nbsp;&nbsp;
                          <div
                            className="col-lg-4 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="invoiceNo"
                              name="invoiceNo"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Ref No."
                              autoComplete="off"
                              value={this.state.invoiceNo}
                              readOnly={_isDuplicateRead}                              
                            />
                            <div
                              id="invoiceNo-error"
                              className="animated fadeInDown"
                            >
                              {errors.invoiceNo.length > 0 && (
                                <span className="error">
                                  {errors.invoiceNo}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Invoice Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly="readOnly"
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Please Select Time"
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                      {_businessSetting.SateOfSupply == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            State of Supply
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control"
                              id="countryState"
                              name="countryState"
                              readOnly={_isDuplicateRead}
                              disabled={_isDuplicateDisa}                              
                              onChange={this.handleChange}
                            >
                              <option value=""> None</option>
                              {this.state.stateArray.map((item, index) => {
                                let _sel = "";
                                if (this.state.state == item.name) {
                                  _sel = "selected";
                                }
                                return (
                                  <option
                                    key={index}
                                    value={item.name}
                                    selected={_sel}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="form-material open"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Item Data Here by other pages */}
            {console.log('this.state.fields', JSON.stringify(this.state.fields))}
            {this.state.party_name!="" && this.state.party_id > 0 && (
              <QuickTransactionItemSection
                party_id={this.state.party_id}
                fields={this.state.fields}
                transId={_transId}
                sendPageType={_sendPageType}
                duplicateSale={this.state.isDuplicate}
                callBackItem={(
                  fieldArray,
                  _totalAmountOldSend,
                  _totalAmountNewSend,
                  _totalAmt,
                  _dueAmount,
                  _paidAM,
                  _totalDiscount,
                  _totalGstAmt,
                  _totalQuantity,
                  handleAddDisable,
                  handleItemSalePurchasePrice,
                  _transactionWiseTaxId,
                  _totalTransTaxAmount,
                  _totalTransDiscountPer,
                  _totalTransDiscountAmount
                ) => {

                  this.setState({
                    fields: fieldArray,
                    totalAmountOld: _totalAmountOldSend,
                    totalAmountNew: _totalAmountNewSend,
                    totalAmount: _totalAmt,
                    dueAmount: _dueAmount ? _dueAmount : _totalAmt,
                    receivedAmount: _paidAM,
                    gstAmount: _totalGstAmt,
                    totalDiscount: _totalDiscount,
                    totalQuantity: _totalQuantity,
                    handleAddDisable: handleAddDisable,
                    handleItemSalePurchasePrice: handleItemSalePurchasePrice,
                    transactionWiseTaxId: _transactionWiseTaxId
                      ? _transactionWiseTaxId
                      : 0,
                    totalTransTaxAmount: _totalTransTaxAmount
                      ? _totalTransTaxAmount
                      : 0,
                    totalTransDiscountPer: _totalTransDiscountPer
                      ? _totalTransDiscountPer
                      : 0,
                    totalTransDiscountAmount: _totalTransDiscountAmount
                      ? _totalTransDiscountAmount
                      : 0,
                  });
                }}
              />
            )}
            {/* Item Data Here by other pages */}

            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              value={this.state.description}
                              autoComplete="off"
                              readOnly={_isDuplicateRead}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                              readOnly={_isDuplicateRead}
                              disabled={_isDuplicateDisa}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>

                        {_businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                id="settingType"
                                name="settingType"
                                onChange={this.handleChange}
                              >
                                {optionsData.map(({ value, label }, index) => {
                                  let _selSet = "";
                                  if (this.state.settingType == value) {
                                    _selSet = "selected";
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                      selected={_selSet}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    {this.state.fields.length > 0 && (
                      <div id="taxDisData">
                        {_businessSetting.TransactionWiseDiscount == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Discount %
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountPer"
                                  name="totalTransDiscountPer"
                                  onChange={this.handleChange}
                                  placeholder="In %"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.totalTransDiscountPer}
                                  readOnly={_isDuplicateRead}
                                />
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountAmount"
                                  name="totalTransDiscountAmount"
                                  onChange={this.handleChange}
                                  placeholder=""
                                  className="form-control"
                                  value={this.state.totalTransDiscountAmount}
                                  autoComplete="off"
                                  style={{
                                    padding: "0 !important",
                                    margin: "0 !important",
                                  }}
                                  readOnly={_isDuplicateRead}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {_businessSetting.TransactionWiseTax == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Tax{" "}
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 0 !important",
                                }}
                              >
                                <select
                                  style={{ width: "85px" }}
                                  className="form-control"
                                  id="taxTypeTrans"
                                  name="taxTypeTrans"
                                  onChange={this.handleDynamicInputsTrans.bind(
                                    this
                                  )}
                                  readOnly={_isDuplicateRead}
                                  disabled={_isDuplicateDisa}
                                >
                                  <option value="0@0" selected>
                                    {" "}
                                    None
                                  </option>
                                  {this.state.taxRateArray.map((tax, index) => {
                                    let _sel = "";
                                    if (
                                      this.state.transactionWiseTaxId == tax.id
                                    ) {
                                      _sel = "selected";
                                    }
                                    return (
                                      <option
                                        key={index}
                                        value={tax.id + "@" + tax.ratio}
                                        selected={_sel}
                                      >
                                        {tax.name + "@" + tax.ratio + "%"}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransTaxAmount"
                                  name="totalTransTaxAmount"
                                  placeholder=""
                                  className={"form-control " + _readonlyCss}
                                  autoComplete="off"
                                  value={this.state.totalTransTaxAmount}
                                  readOnly={_isDuplicateRead}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/*Additional Charges */}
                        {_businessSetting.TransactionAdditionalChargeEnable ==
                          1 && (
                          <div id="taxDisData">
                            {_businessSetting.TransactionAdditionalCharge1Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge1"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge1Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge1"
                                      name="TransAdditionalCharge1"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge1}
                                      autoComplete="off"
                                      readOnly={_isDuplicateRead}
                                      onWheel={() => document.activeElement.blur()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {_businessSetting.TransactionAdditionalCharge2Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge2"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge2Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge2"
                                      name="TransAdditionalCharge2"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge2}
                                      autoComplete="off"
                                      readOnly={_isDuplicateRead}
                                      onWheel={() => document.activeElement.blur()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {_businessSetting.TransactionAdditionalCharge3Enable ==
                              1 && (
                              <div className="col-md-12" id="formSideRight">
                                <div className="form-group row">
                                  <label
                                    style={{ textAlign: "right" }}
                                    className="col-lg-4 col-form-label"
                                    htmlFor="TransAdditionalCharge3"
                                  >
                                    {
                                      _businessSetting.TransactionAdditionalCharge3Name
                                    }
                                  </label>
                                  <div
                                    className="col-lg-8 form-material"
                                    id="paddingOff"
                                  >
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="TransAdditionalCharge3"
                                      name="TransAdditionalCharge3"
                                      onChange={this.handleChange}
                                      placeholder=""
                                      value={this.state.TransAdditionalCharge3}
                                      autoComplete="off"
                                      readOnly={_isDuplicateRead}
                                      onWheel={() => document.activeElement.blur()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {/*End Additional Charges */}
                      </div>
                    )}
                    <div className="col-md-12" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="totalAmount"
                        >
                          Total Amount
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            className="form-control disabled"
                            id="totalAmount"
                            name="totalAmount"
                            placeholder=""
                            onChange={this.handleChange}
                            value={this.state.totalAmount}
                            autoComplete="off"
                            readOnly={_isDuplicateRead}
                          />
                        </div>
                      </div>
                    </div>

                    {_businessSetting.TransactionAdditionalFieldEstimate >
                      0 && (
                      <div id="additionFields">
                        {_businessSetting.additional_field_check1 > 0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF1"
                            >
                              {_businessSetting.additional_field_name1}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_1"
                                name="additional_value_1"
                                placeholder=""
                                value={this.state.additional_value_1}
                                autoComplete="off"
                                onChange={this.handleChange}
                                readOnly={_isDuplicateRead}
                              />
                            </div>
                          </div>
                        )}

                        {_businessSetting.additional_field_check2 > 0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF2"
                            >
                              {_businessSetting.additional_field_name2}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_2"
                                name="additional_value_2"
                                placeholder=""
                                value={this.state.additional_value_2}
                                autoComplete="off"
                                onChange={this.handleChange}
                                readOnly={_isDuplicateRead}
                              />
                            </div>
                          </div>
                        )}

                        {_businessSetting.additional_field_check3 > 0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addF3"
                            >
                              {_businessSetting.additional_field_name3}
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                id="additional_value_3"
                                name="additional_value_3"
                                placeholder=""
                                value={this.state.additional_value_3}
                                autoComplete="off"
                                onChange={this.handleChange}
                                readOnly={_isDuplicateRead}
                              />
                            </div>
                          </div>
                        )}

                        {_businessSetting.additional_field_date_field_check >
                          0 && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="addFDate"
                            >
                              {
                                _businessSetting.additional_field_date_field_name
                              }
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              {this.isAdditionalDateRefresh()}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {_businessSetting.TransportationDetailsField1Enable == 1 && (
                // Transportation Detail Here
                // <QuickTransportationDetails />
                <div className="col-md-12">
                  <div className="block block-transparent">
                    <div className="block-content">
                      <h5 className="content-heading">
                        Transportation Details
                      </h5>
                      <div className="block">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="block block-transparent">
                              <div className="block-content" id="formSideLeft">
                                <div className="form-group row">
                                  {_businessSetting.TransportationDetailsField1Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField1"
                                          id="TransportDetailField1"
                                          value={
                                            this.state.TransportDetailField1
                                          }
                                          onChange={this.handleChange}
                                          readOnly={_isDuplicateRead}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField2Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField2"
                                          id="TransportDetailField2"
                                          value={
                                            this.state.TransportDetailField2
                                          }
                                          onChange={this.handleChange}
                                          readOnly={_isDuplicateRead}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField3Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField3"
                                          id="TransportDetailField3"
                                          value={
                                            this.state.TransportDetailField3
                                          }
                                          onChange={this.handleChange}
                                          readOnly={_isDuplicateRead}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField4Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField4"
                                          id="TransportDetailField4"
                                          value={
                                            this.state.TransportDetailField4
                                          }
                                          onChange={this.handleChange}
                                          readOnly={_isDuplicateRead}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField5Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField5"
                                          id="TransportDetailField5"
                                          value={
                                            this.state.TransportDetailField5
                                          }
                                          onChange={this.handleChange}
                                          readOnly={_isDuplicateRead}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField6Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField6"
                                          id="TransportDetailField6"
                                          value={
                                            this.state.TransportDetailField6
                                          }
                                          onChange={this.handleChange}
                                          readOnly={_isDuplicateRead}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      {" "}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty
                      isRedirectTo={"business/add-sale-estimate/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Low Stock Item Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_LowStock}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalLowStockItem"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">
                      <i
                        className="fa fa-warning"
                        style={{ color: "yellow" }}
                      ></i>
                      &nbsp;&nbsp;Low Stock
                    </h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandlerLowStock.bind(
                          this,
                          "modalLowStockItem"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <p>
                      Your stock for following item(s) is not suifficient to
                      fulfil this sale
                    </p>
                    <ul className="ml-50">
                      {this.state.lowStokItemName!="" && _finalStockHtml}
                    </ul>

                    <p>Do you really want to save this sale?</p>
                    <p>
                      {" "}
                      <input
                        type="checkbox"
                        value="1"
                        onChange={this.handleChecked}
                      />
                      &nbsp;&nbsp;Don't show this again
                    </p>
                    <button
                      type="button"
                      className="btn btn-alt-secondary"
                      onClick={this.closeModalHandlerLowStock.bind(
                        this,
                        "modalLowStockItem"
                      )}
                    >
                      No
                    </button>

                    <button
                      type="button"
                      className="btn btn-alt-primary  ml-20"
                      onClick={this.SaveModalHandlerLowStock.bind(
                        this,
                        "modalLowStockItem"
                      )}
                    >
                      Yes
                    </button>
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandlerLowStock.bind(
                      this,
                      "modalLowStockItem"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Low Stock Item Details*/}

          {/* Modal Add New Prefix Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_ADDPREFIX}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustADDPREFIX"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Prefix</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustADDPREFIX"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewPrefix
                      callBack={(newPrefixId, newPrefixName) => {
                        this.setState({
                          prefixId: newPrefixId,
                          prefixName: newPrefixName,
                          modalIsOpen_ADDPREFIX: false,
                        });

                        this.businessPrefixList();
                      }}
                      transactionType="ESTIMATE"
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustADDPREFIX"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add New Prefix Details*/}
        </div>
      </React.Fragment>
    );
  }
}
