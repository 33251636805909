import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTaxDetails = _companySession.taxDetails;
}

let _loader = "glb-ldr-prt active";

export default class ItemShareListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-service/",
      activeItemId: this.props.activeItemId ? this.props.activeItemId : "0",
      sendType: this.props.sendType ? this.props.sendType : "Item",
      buttonTitle: "Add New",
      isLoaded: true,
      partyArray: [],
      partyArrayFilter: [],
      _itemsArray: [],
      selectItems: [],
      checkedItems: new Map(),
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemSelect: "",
      errors: {
        selectItems: "",
      },
    };
  }
  async componentDidMount() {
    this.getPartyData();
  }

  getPartyData() {
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyArray = responseJson.response;
          this.setState({ partyArray: _partyArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  onChange(e) {
    let errors = this.state.errors;
    const _ItemsArray = this.state.partyArray;
    _ItemsArray.forEach((item) => {
      if (item.party_id == e.target.value) {
        item.isChecked = e.target.checked;
      }
    });
    this.setState({ partyArray: _ItemsArray });
    errors["selectItems"] = "";
  }

  selectAllCheckboxes = (event) => {
    //console.log(this.state.partyArray);
    let _partyArray = this.state.partyArray;
    _partyArray.forEach(
      (_partyArray) => (_partyArray.isChecked = event.target.checked)
    );
    this.setState({ partyArray: _partyArray });
    let errors = this.state.errors;
    errors["selectItems"] = "";
  };

  clickToShareItem = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    const _ItemsArray = this.state.partyArray;

    isOneCheck = false;
    _ItemsArray.forEach((item) => {
      if (item.isChecked == true) {
        isOneCheck = true;
      }
    });
    if (isOneCheck == false) {
      _isError = true;
      errors["selectItems"] = "*Please select any party";
      this.setState({ errors: errors });
    } else {
      errors["selectItems"] = "";
    }
    if (_isError == false) {
      this.addItemInactiveMethodCall();
    }
  };

  addItemInactiveMethodCall() {
    let _selectedItems = [];
    const _ItemsArray = this.state.partyArray;
    _ItemsArray.forEach((item) => {
      if (item.isChecked == true) {
        _selectedItems.push(item.party_id);
      }
    });

    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      userId: this.state.userId,
      company_id: this.state.company_id,
      //selectItems : this.state.selectItems,
      selectPartyId: _selectedItems,
      itemId: this.state.activeItemId,
    });
    if (this.state.sendType == "Item") {
      PostData(global.AddshareItemDetails, requestData, "POST").then(
        (result) => {
          if (result.success == true) {
            sendNotification("Success Message 👍", result.message, "success");
            /*setTimeout(() => {
              window.location.href = global.webUrl + "business/manage-items/";
            }, Math.floor(Math.random() * 5000) + 1);*/
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
    } else {
      PostData(global.AddshareServiceDetails, requestData, "POST").then(
        (result) => {
          if (result.success == true) {
            sendNotification("Success Message 👍", result.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/manage-service/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
    }
  }
  /* Item Filter Code */
  onItemFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    if (_name == "") {
      this.getPartyData();
    } else {
      const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
      const filtered = data.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val == "string" && val.includes(keyword)
        )
      );

      this.setState({ partyArray: filtered });
    }
  };

  /* End Item Filter Code */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="claerfix"></div>
          <h3>Share {this.state.sendType} Details With Parties</h3>
          <p>Select the parties and share {this.state.sendType} details with them by Email</p>
          <div className="form-group row">
            <div className="col-6">
              <input
                type="text"
                onChange={this.onItemFilterName}
                className="form-control"
                placeholder="Search Party here.."
                id="page-header-search-input-item"
                name="page-header-search-input-item"
                autoComplete="off"
                  onWheel={() => document.activeElement.blur()}
              />
            </div>
            <div className="claerfix"></div>
            {this.state.partyArray!="" && (
              <div className="col-12">
                <input
                  type="checkbox"
                  name="selectAll"
                  onChange={this.selectAllCheckboxes}
                />{" "}
                Select All
              </div>
            )}
            <div className="claerfix"></div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <table>
                <tr>
                  <th style={{ width: "400px" }}>Select Party</th>
                  <th style={{ width: "50%" }}>Party Email</th>
                </tr>
                {this.state.partyArray.map((item, index) => {
                  let isChecked = "";
                  if (item.isChecked == true) {
                    isChecked = "checked";
                  }
                  let _emailId = item.party_email_id;
                  if (
                    _emailId == null ||
                    _emailId == "" ||
                    _emailId == "null"
                  ) {
                    _emailId = "";
                  }
                  return (
                    <tr>
                      <td style={{ width: "400px" }}>
                        <input
                          type="checkbox"
                          name="selectItems"
                          checked={isChecked}
                          className="checkBoxClass"
                          value={item.party_id}
                          onChange={this.onChange.bind(this)}
                        />
                        &nbsp;&nbsp;
                        <label>
                          <storng>{item.party_name}</storng>
                        </label>
                      </td>
                      <td style={{ width: "50%" }}>{_emailId}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <p>
            Note : {this.state.sendType} will be shared with only the parties whose email address
            is present.
          </p>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToShareItem}
              >
                <i className="fa fa-check mr-5"></i> Share {this.state.sendType} Details
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
