// Like countryInt = "en-IN" & currency = "INR"
// Like countryInt = "en-US" & currency = "USD"
let countryInt = 'en-IN';
let currency = 'INR';

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    countryInt = 'en-'+_companySession.country_short;
    currency = _companySession.currency_code;
}

export const numberFormat = (value) =>
    new Intl.NumberFormat(countryInt, {
        style: "currency",
        currency: currency //"INR"
}).format(value);
