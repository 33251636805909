import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};
let _SerialNoChk = 0;
let _SerialNoTxt = '';
let _SerialNoAdditionalChk1 = 0;
let _SerialNoAdditionalChk2 = 0;
let _SerialNoAdditionalLabel1 = '';
let _SerialNoAdditionalLabel2 = '';

let _loader = 'glb-ldr-prt active';
export default class quickAddTransSerialNumberFreeQty extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _SerialNoAdditionalChk1 = _businessSetting.SerialNoAdditionalChk1;
                        _SerialNoAdditionalChk2 = _businessSetting.SerialNoAdditionalChk2;
                        _SerialNoAdditionalLabel1 = _businessSetting.SerialNoAdditionalLabel1;
                        _SerialNoAdditionalLabel2 = _businessSetting.SerialNoAdditionalLabel2;
                        _SerialNoChk = _businessSetting.SerialNoChk;
                        _SerialNoTxt = _businessSetting.SerialNoTxt;
                    }
                }
            } 
        }
        this.state = {
            itemSerialNoAll : this.props.itemSerialNoAll ? this.props.itemSerialNoAll : [],  
            itemFields :  this.props.itemFields   ? this.props.itemFields : [],
            itemId : this.props.itemId ? this.props.itemId : 0,  
            openingStock : this.props.opening_stock ? this.props.opening_stock : 0,  
            transactionId : this.props.transactionId ? this.props.transactionId : 0,
            itemPosition :  this.props.itemPosition ? this.props.itemPosition : 0,
            isLoaded : true, 
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            SerialNoAdditionalLabel1 : _SerialNoAdditionalLabel1,
            SerialNoAdditionalLabel2 : _SerialNoAdditionalLabel2,
            SerialNoAdditionalChk1 : _SerialNoAdditionalChk1,
            SerialNoAdditionalChk2 : _SerialNoAdditionalChk2,
            SerialNoChk : _SerialNoChk,
            SerialNoTxt : _SerialNoTxt,
            //opSCount : (this.props.itemSerialNoAll.length > 0) ? this.props.itemSerialNoAll.length : 0,
            //totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            opSCount : 0,
            noCheck : '',
            pageLoadCount :0,
            isSelected : false,
            errors: {
                serial_no  :  '',
                noCheck : '',
            }
        }
    }
    async componentDidMount ()
    {   
        console.log(this.state.itemFields.length+"==itemSerialNoAll==",JSON.stringify(this.state.itemSerialNoAll));
        console.log(this.state.itemPosition+"==itemFields==",JSON.stringify(this.state.itemSerialNoAll.length));
        if(this.state.itemSerialNoAll.length == 0 && this.state.transactionId > 0){
            let _setFieldData = this.state.itemFields;
            _setFieldData.map((itemData, i) => {
                if((this.state.itemId == itemData.item_id)){
                    console.log("==dd==",JSON.stringify(itemData))
                    let _allSerialSendRedturn = itemData.allSerialSendRedturn ? itemData.allSerialSendRedturn : [];
                    this.setState({itemSerialNoAll : _allSerialSendRedturn})
                    this.state.itemSerialNoAll =  _allSerialSendRedturn;
                }
            })
        }
        if(this.state.itemFields.length > 0 && this.state.transactionId == 0){
            let _setFieldD = this.state.itemFields;
            _setFieldD.map((itemData, i) => {
                console.log(this.state.itemPosition+'==>-itemPosition->=='+i);
                if((this.state.itemId == itemData.item_id) && (this.state.itemPosition == i)){
                    
                    let _allSerialSendRedturn = itemData.allSerialSendRedturn ? itemData.allSerialSendRedturn : [];
                    console.log(itemData.item_id+'==>-itemPosition->==',_allSerialSendRedturn);
                    this.setState({itemSerialNoAll : _allSerialSendRedturn})
                    this.state.itemSerialNoAll =  _allSerialSendRedturn;
                }
            })
        }
        // let _checkSrAva = this.state.itemSerialNoAll.filter((data) => data.dataCheck == 1);
        // console.log("==len==",_checkSrAva.length);
        // if(_checkSrAva.length == 0){
            
        // }
    }
    onCheckChange(e) {
        let errors = this.state.errors; 
        errors['noCheck'] = "";            
        this.setState({errors: errors});
        
        let _noCheck = this.state.pageLoadCount;
        const _ItemsArray = this.state.itemSerialNoAll;
        _ItemsArray.forEach(item => {
            /*if (item.serial_id == e.target.value)
            {
                item.productId = this.state.itemId;
                item.isFreeQuantity = e.target.checked;
                item.isSelected = e.target.checked;
            }*/
            if (item.serial_id == e.target.value)
            {
                item.isSelected = e.target.checked;
                item.productId = this.state.itemId;
                item.isFreeQuantity = e.target.checked;
                
                if(e.target.checked == true){
                    //item.dataCheck = 0;
                    _noCheck =  _noCheck + 1 ;
                }else{
                    //item.dataCheck = 1;
                    _noCheck =  _noCheck - 1 ;
                }
                
            }
        });
        console.log("==_ItemsArray Fre--==",_ItemsArray);
        console.log("==_noCheck--==",_noCheck);
        this.setState({ itemSerialNoAll: _ItemsArray,pageLoadCount:_noCheck  });
    }

    addSaveSerialData= (event) =>
    {   
        let errors = this.state.errors; 
        let _selectedItems = [];
        let _serialQty = 0
        let _ItemsArray = this.state.itemSerialNoAll;
        _ItemsArray.forEach(item => {
            if (item.isFreeQuantity == true)
            {
                item.dataCheck = 0;
                _selectedItems.push(item);
            }
        });
        if(_selectedItems.length >0){
            errors['noCheck'] = "";            
            this.setState({errors: errors});
            console.log(this.state.totalQuantity+'==>T--L->=='+_selectedItems.length);
            let _totalQuantity = parseFloat(this.state.pageLoadCount) + parseFloat(this.state.totalQuantity);//this.state.totalQuantity;
            /*let itemPrice = this.state.itemPrice * _selectedItems.length;
            let _totalAmountOld = this.state.itemPrice * _selectedItems.length;        
            if(this.state.totalAmountOld > 0){
                _totalAmountOld = this.state.totalAmountOld + _totalAmountOld;
            }
            if(this.state.totalAmountOld > 0){
                _totalQuantity =  _selectedItems.length ;
            }
            this.props.callBack (_selectedItems,_selectedItems.length,itemPrice,_totalAmountOld,_totalQuantity);*/
            
            //console.log(parseFloat(_selectedItems.length)+'===here=='+parseFloat(this.state.totalQuantity)+'==>>----'+_totalQuantity);
            if(_totalQuantity > 1){
                //console.log('===ee==');
                //_totalQuantity = _totalQuantity - 1;
            }
            console.log("==_selectedItems=kkk=",_selectedItems);
            console.log("==pageLoadCount--==",this.state.pageLoadCount);
            this.props.callBack (_selectedItems,this.state.pageLoadCount,_totalQuantity);
        }
        else{           
            errors['noCheck'] = "Please check at least one.";            
            this.setState({errors: errors}); 
        }        
    }
 
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let _countMain = this.state.openingStock;
        if(this.state.openingStock == 0){
            _countMain = this.state.opSCount;
        }        
        if(this.state.openingStock > 0 && (this.state.opSCount > this.state.openingStock)){
            _countMain = this.state.opSCount;
        }
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        //console.log("==here free length---",this.state.itemSerialNoAll.length);
        //console.log("==here free sr-11--",this.state.itemSerialNoAll);
        let _length  = JSON.stringify(this.state.itemSerialNoAll);
        _length = _length.length;
        console.log(JSON.stringify(this.state.itemSerialNoAll),"==_lengthhh--",_length);
        return(
            <React.Fragment>                
                <form method="post" id="form-login" className="webForm px-30">  
                     
                    {_length > 0 &&
                    <div>
                    {this.state.itemSerialNoAll.map((element, index) => (
                    <div >
                        {element.dataCheck == 1 &&
                            <div className="form-group row">
                                {this.state.SerialNoChk == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoTxt} </label>
                                        </div> 
                                    </div>  
                                    }
                                    {this.state.SerialNoAdditionalChk1 == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1}</label>
                                            
                                        </div> 
                                    </div>  
                                    }
                                    {this.state.SerialNoAdditionalChk2 == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2}</label>
                                        </div> 
                                    </div>
                                    
                                    
                                    }
                                    {((this.state.itemSerialNoAll.length  >0 ) && (index >= 0) ) &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <div className="wrapperRadio">
                                                <input type="checkbox" name="checkData" value={element.serial_id} onChange={this.onCheckChange.bind(this)}  checked={element.isSelected ? element.isSelected : ''}/>
                                                <label htmlFor="option-1" className="option option-1">
                                                    <div className="dot"></div>
                                                    <span>Select</span>
                                                </label>                                
                                            </div>
                                        </div>   
                                    </div>                           
                                    }
                            </div>
                        }
                        {/* {(element.dataCheck == 0  && element.isFreeQuantity == true )&&
                            <div className="form-group row">
                                {this.state.SerialNoChk == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoTxt} </label>
                                        </div> 
                                    </div>  
                                    }
                                    {this.state.SerialNoAdditionalChk1 == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1}</label>
                                            
                                        </div> 
                                    </div>  
                                    }
                                    {this.state.SerialNoAdditionalChk2 == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" readOnly/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2}</label>
                                        </div> 
                                    </div>
                                    
                                    
                                    }
                                    {((this.state.itemSerialNoAll.length  >0 ) && (index >= 0) ) &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <div className="wrapperRadio">
                                                <input type="checkbox" name="checkData" value={element.serial_id} onChange={this.onCheckChange.bind(this)}  checked={element.isSelected ? element.isSelected : ''}/>
                                                <label htmlFor="option-1" className="option option-1">
                                                    <div className="dot"></div>
                                                    <span>Select</span>
                                                </label>                                
                                            </div>
                                        </div>   
                                    </div>                           
                                    }
                            </div>
                        } */}
                    </div>

                    ))}
                    <button type="button" className="btn btn-alt-primary" onClick={this.addSaveSerialData} ><i className="fa fa-check mr-5"></i> Save</button>

                    <span className="iris_error_txt">{errors.noCheck.length > 0 && <span className='error'>{errors.noCheck}</span>}</span>

                    </div> 
                    } 
                    
                </form>
            </React.Fragment>
        )    
    }
}