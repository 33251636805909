import React,{Component} from 'react';
import {Link,NavLink} from 'react-router-dom';

let _sessionPeriod = true;
let _UserSession = localStorage.getItem('vs_UserSession');
let _signingName = 'Business Admin';
//let _profile_logo = global.LIVE_URL+'uploadDirectory/profile/vIcons.png';
let _profile_logo = global.LIVE_URL+'websiteFilesFolder/img/vyavsay.png';
let _loginType = 'Business';
let _businessSetting = {};
let _companyDetails = '';
if(_UserSession!=undefined || _UserSession!=null)
{
    _sessionPeriod = false;
    _UserSession = JSON.parse(_UserSession);
    _profile_logo = _UserSession.profile_logo;
    if(_UserSession.loginType == "Business" || _UserSession.loginType == "BusinessUser"){
        let _companySession = JSON.parse(localStorage.getItem('vs_UserCompanySession'));
        
        if(_companySession.company_logo.length > 6){
            let _company_Logo = _companySession.company_logo;
            _profile_logo = _company_Logo;
        }
        else{
            //_profile_logo = global.LIVE_URL+'uploadDirectory/profile/vIcons.png';
            _profile_logo = global.LIVE_URL+'websiteFilesFolder/img/vyavsay.png';
        }
    }
    _loginType = _UserSession.loginType;
    _signingName = _UserSession.loginName;
    _businessSetting = _UserSession.businessSetting;
}

//className={this.props.location.pathname == '/' ? 'active' : ''}
export default class MenuPage extends Component{
    constructor(props){
        super(props);
        
        this.state={
            isLoggedIn : _sessionPeriod,
            loggedName : _signingName,
            isSessionOff : _sessionPeriod,
            pathName:'Dashboard'
        }
    }

    componentDidMount(){
        let _handleMenu = this.props.pageId;
    }
    
    handleLogoutClick = (event) => {
        localStorage.removeItem('vs_UserSession');
        this.setState({isSessionOff:true})
        //window.location.href = ("/");
    }

    manageBusinessAdminMenu(){
        if(_loginType == 'Business'){
            return(
                <span>
                    <li className="nav-main-heading">
                        <span className="sidebar-header-title">Manage Busniess</span>
                    </li>
                    <li>
                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><i className="si si-layers"></i><span className="sidebar-mini-hide">Business Settings</span></Link>     
                        <ul>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><span className="sidebar-mini-hide">Manage Companies</span></Link> 
                                <ul>
                                    <li>
                                        <a href={global.webUrl+"business/add-company/"}><span className="sidebar-mini-hide">Add Company</span></a>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/manage-company/"}><span className="sidebar-mini-hide">Company List</span></NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={"/business/manage-business-user/"} eventKey={3}>Manage Business Users</NavLink>
                            </li>
                            <li>
                                <NavLink to={"/business/manage-sms-list/"}>Manage SMS Setting</NavLink>
                            </li>
                        </ul>
                    </li>
                    
                </span>
            )
        }
    }

    manageBusinessAdminMenuReport(){
        if(_loginType == 'Business'){
            return(
                <span>
                    <li>
                        <NavLink to={"/business/company-setting/"}>
                            <i className="si si-settings sidebar-nav-icon"></i> <span className="sidebar-mini-hide">Setting</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/business/all-prefix-list/"}>
                            <i className="si si-layers sidebar-nav-icon"></i> <span className="sidebar-mini-hide">All Transaction Prefix </span>
                        </NavLink>
                    </li>
                </span>
            )
        }
    }

    render(){
        if (this.state.isSessionOff == true)
        {
            //console.log('Session Expire...!');   
            //this.logoutCallBack();         
            window.location.href = global.webUrl+'login/';
        }
        
        return(
            <React.Fragment>
                {/* Main Sidebar */}
                <nav id="sidebar" data-simplebar="init">
                    <div className="sidebar-content">
                        <div className="content-header content-header-fullrow px-15">
                            <div className="content-header-section sidebar-mini-visible-b">
                                <span className="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                                    <span className="text-dual-primary-dark">V</span><span className="text-primary">D</span>
                                </span>
                            </div>
                            <div className="content-header-section text-center align-parent sidebar-mini-hidden">
                                <button type="button" className="btn btn-circle btn-dual-secondary d-lg-none align-v-r" data-toggle="layout" data-action="sidebar_close">
                                    <i className="fa fa-times text-danger"></i>
                                </button>
                                <div className="content-header-item">
                                    <Link  className="font-w700" to={"/business/dashboard/"}>
                                        <i className="si si-fire text-primary"></i> <span className="text-dual-primary-dark">Vyavsay</span> Dashboard
                                    </Link> 
                                </div>
                            </div>
                        </div>
                            <div className="content-side content-side-full content-side-user px-10 align-parent">
                                <div className="sidebar-mini-visible-b align-v animated fadeIn">                        
                                    <img src={_profile_logo} alt="avatar" className="img-avatar img-avatar32"/>
                                </div>
                                <div className="sidebar-mini-hidden-b text-center">
                                    <Link  className="img-link" href={() => false}>
                                        <img src={_profile_logo} alt="avatar" className="img-avatar"/>
                                    </Link> 
                                    <ul className="list-inline mt-10">
                                        <li className="list-inline-item">
                                            <b>{this.state.loggedName}</b>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link  className="link-effect text-dual-primary-dark" data-toggle="layout" data-action="sidebar_style_inverse_toggle" href={() => false}>
                                                <i className="si si-drop"></i>
                                            </Link> 
                                        </li>
                                        <li className="list-inline-item">
                                            <Link  onClick={this.handleLogoutClick} className="link-effect text-dual-primary-dark" data-box="#mb-signout" href={() => false}>
                                                <i className="si si-logout"></i>
                                            </Link> 
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        <div className="content-side content-side-full">

                        {/* Sidebar Navigation */}
                        <ul className="nav-main">
                            <li>
                                <Link  to={"/business/dashboard/"} className="active" eventKey={1}>
                                    <i className="si si-cup sidebar-nav-icon"></i> <span className="sidebar-mini-hide">Dashboard</span>
                                </Link> 
                            </li>
                            {this.manageBusinessAdminMenu()}
                            
                            
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manange Company</span>
                            </li>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><i className="si si-layers"></i><span className="sidebar-mini-hide">Company Settings</span></Link>     
                                <ul>
                                    <li>
                                        <NavLink to={"/business/manage-unit/"}>Manage Unit</NavLink>
                                    </li>
                                    <li>
                                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><span className="sidebar-mini-hide">Manage Party</span></Link> 
                                        <ul>
                                            <li>
                                                <NavLink to={"/business/add-party/"}><span className="sidebar-mini-hide">Add Party</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/business/manage-party/"}><span className="sidebar-mini-hide">Party List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    { (_businessSetting.ItemType == 0 || _businessSetting.ItemType == 1 || _businessSetting.ItemType == 3 ) &&
                                    <li>
                                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><span className="sidebar-mini-hide">Manage Items</span></Link> 
                                        <ul>
                                            <li>
                                                <NavLink to={"/business/add-item/"}><span className="sidebar-mini-hide">Add Items</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/business/manage-items/"}><span className="sidebar-mini-hide">Items List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    }                                    
                                    { (_businessSetting.ItemType == 0 || _businessSetting.ItemType == 2 || _businessSetting.ItemType == 3 ) &&
                                    <li>
                                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><span className="sidebar-mini-hide">Manage Services</span></Link> 
                                        <ul>
                                            <li>
                                                <NavLink to={"/business/add-service/"}><span className="sidebar-mini-hide">Add Service</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/business/manage-service/"}><span className="sidebar-mini-hide">Service List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    }
                                </ul>
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Sale</span>
                            </li>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><i className="si si-note"></i><span className="sidebar-mini-hide">Sales Settings</span></Link>     
                                <ul>
                                    {/*<li>
                                        <Link  to={"/business/saleList/"}>Sale List</Link> 
                                    </li>*/}
                                    <li>
                                        <NavLink to={"/business/sale-invoice/"}>Sale Invoice 
                                            <span className="pull-right" id="menuPlusIcon">
                                                <Link  style={{padding: '0px',fontWeight: '600',color: '#979797'}} to={"/business/add-sale/"}> + </Link> 
                                            </span>
                                        </NavLink>
                                    </li>
                                    {_businessSetting.EstimateQuitation > 0  && 
                                    <li>
                                        <NavLink to={"/business/manage-sale-estimate/"}>Estimate/Quotation 
                                            <span className="pull-right" id="menuPlusIcon">
                                                <Link  style={{padding: '0px',fontWeight: '600',color: '#979797'}} to={"/business/add-sale-estimate/"}> + </Link> 
                                            </span>
                                        </NavLink>
                                    </li>
                                    }
                                    <li>
                                        <NavLink to={"/business/manage-sale-return/"}>Cr. Note/Sale Return 
                                            <span className="pull-right" id="menuPlusIcon">
                                                <Link  style={{padding: '0px',fontWeight: '600',color: '#979797'}} to={"/business/add-sale-credit-note/"}> + </Link> 
                                            </span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/manage-sale-payment-in/"}>Payment-In 
                                            <span className="pull-right" id="menuPlusIcon">
                                                <Link  style={{padding: '0px',fontWeight: '600',color: '#979797'}} to={"/business/add-sale-payment-in/"}> + </Link> 
                                            </span>
                                        </NavLink>
                                    </li>
                                    {_businessSetting.SalePurchaseOrder > 0  && 
                                    <li>
                                        <NavLink to={"/business/manage-sale-order/"}>Sale Order 
                                            <span className="pull-right" id="menuPlusIcon">
                                                <Link  style={{padding: '0px',fontWeight: '600',color: '#979797'}} to={"/business/add-sale-order/"}> + </Link> 
                                            </span>
                                        </NavLink>
                                    </li>
                                    }
                                    {_businessSetting.DeliveryChallan > 0  && 
                                    <li>
                                        <NavLink to={"/business/manage-sale-delivery-challan/"}>Delivery Challan 
                                            <span className="pull-right" id="menuPlusIcon">
                                                <Link  style={{padding: '0px',fontWeight: '600',color: '#979797'}} to={"/business/add-sale-delivery-challan/"}> + </Link> 
                                            </span>
                                        </NavLink>
                                    </li>
                                    }
                                </ul>
                            </li>
                            
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Purchase</span>
                            </li>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><i className="si si-basket-loaded"></i><span className="sidebar-mini-hide">Purchase Settings</span></Link>     
                                <ul>
                                    <li>
                                        <NavLink to={"/business/purchase-invoice/"}>Purchase Bills <span className="pull-right" id="menuPlusIcon"> + </span></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/manage-purchase-payment-out/"}>Payment-Out <span className="pull-right" id="menuPlusIcon"> + </span></NavLink>
                                    </li>
                                    {_businessSetting.SalePurchaseOrder > 0  && 
                                    <li>
                                        <NavLink to={"/business/manage-purchase-order/"}>Purchase Order <span className="pull-right" id="menuPlusIcon"> + </span></NavLink>
                                    </li>
                                    }
                                    <li>
                                        <NavLink to={"/business/manage-purchase-return/"}>Purchase Return <span className="pull-right" id="menuPlusIcon"> + </span></NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Business Interface</span>
                            </li>
                            <li>
                                <NavLink to={"/business/manage-expense/"} eventKey={4}><i className="si si-wallet sidebar-nav-icon"></i> Expenses</NavLink>
                            </li>
                            {_businessSetting.OtherIncome > 0  && 
                            <li>
                                <NavLink to={"/business/other-income/"} eventKey={4}><i className="si si-wallet sidebar-nav-icon"></i> Other Income</NavLink>
                            </li>
                            }
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><i className="si si-layers"></i><span className="sidebar-mini-hide">Cash & Bank</span></Link>     
                                <ul>
                                    <li>
                                        <NavLink to={"/business/manage-account-list/"} eventKey={1}>Bank Account</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/manage-cash-in-hand/"} eventKey={2}>Cash In-Hand</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/manage-cheque-list/"} eventKey={3}>Cheques</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/manage-loan-accounts/"} eventKey={4}>Loan Accounts</NavLink>
                                    </li>
                                </ul>
                            </li>
                            
                            <li>
                                <NavLink to={"/business/reports/"} eventKey={4}><i className="si si-bar-chart sidebar-nav-icon"></i> Reports</NavLink>
                            </li>
                            
                            {this.manageBusinessAdminMenuReport()}
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="javascript:void(0)"><i className="si si-wrench"></i><span className="sidebar-mini-hide">Utilities</span></Link>
                                <ul>
                                    <li>
                                        <NavLink to={"/business/generate-barcode/"} eventKey={1}>Generate Barcode</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/import-items-list/"} eventKey={2}>Import Items</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/bulk-update-items/"} eventKey={3}>Bulk Update Items</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/bulk-update-services/"} eventKey={4}>Bulk Update Services</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/import-party-list/"} eventKey={5}>Import Parties</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/business/export-items-list/"} eventKey={6}>Export Items</NavLink>
                                    </li>
                                </ul>
                            </li>
                            
                            
                        </ul>
                        {/* END Sidebar Navigation */}
                </div>
            </div>
        </nav>
            </React.Fragment>
        )
    }
}