import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import { numberFormat } from "../../../config/numberFormat";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = "IN";
let _businessSetting = {};
let _loader = "glb-ldr-prt active";

export default class quickItemDetailListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessSetting = _UserSession.businessSetting;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTaxDetails = _companySession.taxDetails;
    }

    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-service/",
      buttonTitle: "Add New",
      getItmeId: this.props.getItmeId ? this.props.getItmeId : "0",
      isLoaded: true,
      itemDetailDataList: [],
      checkedItems: new Map(),
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemName: "",
      base_unit_name: "",
      secound_unit_name: "",
      unit_conversion_rates: "",
      hsn_sac: "",
      code: "",
      sale_price: "",
      sale_tax_type: "",
      purchase_price: "",
      purchase_tax_type: "",
      tax_rate_name: "",
      tax_rate_ratio: "",
      min_stock_quantity: "",
      item_location: "",
      Additional_CESS: "",
      item_description: "",
      normal_remaining_quantity: "",
      itemSerialNo: "",
      itemBatchNo: "",
      errors: {
        selectItems: "",
      },
    };
  }
  async componentDidMount() {
    this.getItemDetailData();
  }
  getItemDetailData() {
    let _url_GetData =
      global.userItemDetails + "?itemId=" + this.state.getItmeId;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _itemDetailDataList = responseJson.response[0];
          this.setState({
            itemDetailDataList: _itemDetailDataList,
            itemName: _itemDetailDataList.name,
            base_unit_name: _itemDetailDataList.base_unit_name,
            secound_unit_name: _itemDetailDataList.secound_unit_name,
            unit_conversion_rates: _itemDetailDataList.unit_conversion_rates,
            hsn_sac: _itemDetailDataList.hsn_sac,
            code: _itemDetailDataList.code,
            sale_price: _itemDetailDataList.sale_price,
            sale_tax_type: _itemDetailDataList.sale_tax_type,
            purchase_price: _itemDetailDataList.purchase_price,
            purchase_tax_type: _itemDetailDataList.purchase_tax_type,
            tax_rate_name: _itemDetailDataList.tax_rate_name,
            tax_rate_ratio: _itemDetailDataList.tax_rate_ratio,
            min_stock_quantity: _itemDetailDataList.min_stock_quantity,
            item_location: _itemDetailDataList.item_location,
            Additional_CESS: _itemDetailDataList.Additional_CESS,
            item_description: _itemDetailDataList.item_description,
            normal_remaining_quantity:
              _itemDetailDataList.normal_remaining_quantity,
            itemSerialNo: _itemDetailDataList.itemSerialNo,
            itemBatchNo: _itemDetailDataList.itemBatchNo,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  /* End Item Filter Code */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    let _conversionData = "NA";
    if (this.state.unit_conversion_rates > 0) {
      _conversionData =
        "1 " +
        this.state.base_unit_name +
        " = " +
        this.state.unit_conversion_rates +
        " " +
        this.state.secound_unit_name;
    }
    let _salePrice = numberFormat(this.state.sale_price);
    let _purchasePrice = numberFormat(this.state.purchase_price);
    let taxRatio =
      this.state.tax_rate_ratio + "%" + " " + this.state.tax_rate_name;
    let normalQty = this.state.normal_remaining_quantity;
    let Additional_CESS = this.state.Additional_CESS;
    if (normalQty < 0) {
      normalQty = 0;
    }
    if (Additional_CESS == 0) {
      Additional_CESS = "NA";
    }
    if (this.state.tax_rate_ratio == 0) {
      taxRatio = "NA";
    }
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <p>
                {" "}
                Item Name : <span>{this.state.itemName}</span>
              </p>
              <p>
                {" "}
                HSN/SAC :{" "}
                <span>{this.state.hsn_sac ? this.state.hsn_sac : "NA"}</span>
              </p>
              <p>
                {" "}
                Code/Barcode : <span>{this.state.code}</span>
              </p>
              <p>
                {" "}
                Description :{" "}
                <span>
                  {this.state.item_description!=""
                    ? this.state.item_description
                    : "NA"}
                </span>
              </p>
              <p>
                {" "}
                Item Location : <span>{this.state.item_location}</span>
              </p>
              <p>
                {" "}
                Minimum Stock Qty : <span>{this.state.min_stock_quantity}</span>
              </p>
              <p>
                {" "}
                Serial No Qty : <span>{this.state.itemSerialNo.length}</span>
              </p>
            </div>
            <div className="col-6">
              <p>
                {" "}
                Conversion : <span>{_conversionData}</span>
              </p>
              <p>
                {" "}
                Sale Price :{" "}
                <span>
                  {_salePrice + " ( " + this.state.sale_tax_type + " )"}
                </span>
              </p>
              <p>
                {" "}
                Purchase Price :{" "}
                <span>
                  {_purchasePrice + " ( " + this.state.purchase_tax_type + " )"}
                </span>
              </p>
              <p>
                {" "}
                Tax Ratio : <span>{taxRatio}</span>
              </p>
              {_businessSetting.AdditionalCESS == 1 && (
                <p>
                  {" "}
                  Additional CESS : <span>{Additional_CESS}</span>
                </p>
              )}
              <p>
                {" "}
                Normal Qty : <span>{normalQty}</span>
              </p>
              <p>
                {" "}
                Batch No Qty : <span>{this.state.itemBatchNo.length}</span>
              </p>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
