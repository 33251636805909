import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

import QuickAddUniConversion from "./quickUnitConversion";
import Modal from "react-modal";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTaxDetails = _companySession.taxDetails;
}

let _loader = "glb-ldr-prt active";

export default class ItemListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo:
        this.props.dataType == "ITEM"
          ? "business/manage-items/"
          : "business/manage-service/",
      _dataType: this.props.dataType ? this.props.dataType : "ITEM",
      buttonTitle: "Add New",
      isLoaded: true,
      modalIsOpen_ActiveUnitConversion: false,
      itemArray: [],
      itemArrayFilter: [],
      _itemsArray: [],
      selectItems: [],
      checkedItems: new Map(),
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemSelect: "",
      errors: {
        selectItems: "",
      },
    };
  }
  async componentDidMount() {
    const requestData = JSON.stringify({
      company_id: _company_id,
      dataType: this.props.dataType,
    });
    PostData(global.userItemNoUnitSelect, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _itemArray = responseJson.response;
          this.setState({ itemArray: _itemArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  onChange(e) {
    let errors = this.state.errors;
    const _ItemsArray = this.state.itemArray;
    _ItemsArray.forEach((item) => {
      if (item.item_id == e.target.value) {
        item.isChecked = e.target.checked;
      }
    });
    this.setState({ itemArray: _ItemsArray });
    errors["selectItems"] = "";
  }

  selectAllCheckboxes = (event) => {
    //console.log(this.state.itemArray);
    let _itemArray = this.state.itemArray;
    _itemArray.forEach(
      (_itemArray) => (_itemArray.isChecked = event.target.checked)
    );
    this.setState({ itemArray: _itemArray });
    let errors = this.state.errors;
    errors["selectItems"] = "";
  };

  clickToAddConversion = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    const _ItemsArray = this.state.itemArray;

    isOneCheck = false;
    _ItemsArray.forEach((item) => {
      if (item.isChecked == true) {
        isOneCheck = true;
      }
    });
    if (isOneCheck == false) {
      _isError = true;
      errors["selectItems"] = "*Please select any item";
      this.setState({ errors: errors });
    } else {
      errors["selectItems"] = "";
    }
    if (_isError == false) {
      this.openModalHandler("modalUnitConversion");
    }
  };

  /*For Modal open close*/
  openModalHandler(popId) {
    let _selectedItems = [];
    const _ItemsArray = this.state.itemArray;
    _ItemsArray.forEach((item) => {
      if (item.isChecked == true) {
        _selectedItems.push(item.item_id);
      }
    });
    if (popId == "modalUnitConversion") {
      this.setState({
        modalIsOpen_ActiveUnitConversion: true,
        selectedItemsAK: _selectedItems,
      });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: false });
    }
  };
  /*End Modal open close*/

  render() {
    console.log(this.state.itemArray);
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="claerfix"></div>
          {this.state.itemArray!="" && (
            <div className="form-group row">
              <div className="col-6">
                <input
                  type="checkbox"
                  name="selectAll"
                  onChange={this.selectAllCheckboxes}
                />{" "}
                Name
              </div>
            </div>
          )}
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                {this.state.itemArray.map((item, index) => {
                  let isChecked = "";
                  if (item.isChecked == true) {
                    isChecked = "checked";
                  }
                  return (
                    <div>
                      <input
                        type="checkbox"
                        name="selectItems"
                        checked={isChecked}
                        className="checkBoxClass"
                        value={item.item_id}
                        onChange={this.onChange.bind(this)}
                      />
                      &nbsp;&nbsp;
                      <label>
                        <storng>
                          {item.name}
                          {/* ({item.opening_stock > 0 ? item.opening_stock : 0}) */}
                        </storng>
                      </label>
                    </div>
                  );
                })}
                {this.state.itemArray!="" && (
                  <label htmlFor="login-password">Select Items</label>
                )}
                <span className="vs_error_txt">
                  {errors.selectItems.length > 0 && (
                    <span className="error">{errors.selectItems}</span>
                  )}
                </span>
              </div>
            </div>

            {/* {this.props.dataType == "ITEM" && (
              <div className="col-6">
                <div className="form-material open">
                  {this.state.itemArray.map((item, index) => {
                    return (
                      <div>
                        <label>
                          <storng>
                            {item.opening_stock > 0 ? item.opening_stock : 0}
                          </storng>
                        </label>
                      </div>
                    );
                  })}
                  {this.state.itemArray!="" && (
                    <label htmlFor="login-password">Quantity</label>
                  )}
                </div>
              </div>
            )} */}
            {this.state.itemArray == "" && <p>No Rows To Show</p>}
          </div>
          <div className="claerfix"></div>
          {this.state.itemArray!="" && (
            <div className="form-group row">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-alt-primary"
                  onClick={this.clickToAddConversion}
                >
                  <i className="fa fa-check mr-5"></i> Next
                </button>
              </div>
            </div>
          )}
        </form>

        {/* Modal Add  Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversion}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversion"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Unit Conversion</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversion"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddUniConversion
                    isRedirectTo={this.state.isRedirectTo}
                    itemSelectData={this.state.selectedItemsAK}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversion"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Unit Conversion Details*/}
      </React.Fragment>
    );
  }
}
