import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";

import QuickAddDepositeMakePayment from "./chequeDeposit";
import QuickAddDepositeMakePaymentOI from "./chequeDepositOI";
import QuickAddReopenDetails from "./chequeReopen";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import moment from "moment";
import ButtonPdf from "../htmlPdfCreation";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let _businessSetting = {};

let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}
let _t = "";
if (_businessSetting.Tran_AddTime == 1) {
  _t = " & Time";
}
let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 50,
  },
  {
    label: "Name",
    field: "display_name",
    width: 150,
  },
  {
    label: "Ref No.",
    field: "refNo",
    width: 100,
  },
  {
    label: "Date" + _t,
    field: "date",
    width: 100,
  },
  {
    label: "Amount",
    field: "amount",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    width: 100,
  },
  {
    label: "Reopen Reason",
    field: "reopen_reason",
    width: 100,
  },
  {
    label: "Action",
    field: "actionextra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class bankAccountListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      modalIsOpen_MEP: false,
      modalIsOpen_MEP_OI: false,
      modalIsOpen_Reopen: false,
      company_id: _company_id,
      businessId: _businessId,

      chequeArrayTransfer: [],
      userId: _signingId,

      activeMinDate: moment().format("MM/DD/YYYY"),
      activeTrasactionId: 0,
      activeTrasactionOId: 0,
      activeTrasactionType: "",
      activeTypeAccount: "Deposit",
      errors: {},
    };
  }

  async componentDidMount() {
    this.getTransactionListData();
  }

  getTransactionListData() {
    let _transactionArray = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      type: "ChequeBank",
    });

    PostData(global.userChequeBankTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            moneyArrayTransfer: _transactionArray,
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _transactionArray = responseJson.response;
          }
          this.setState({
            chequeArrayTransfer: _transactionArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadTrasactionListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.chequeArrayTransfer;
      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let _type = "";
          let _editUrl = "";
          let _actionTxt = "Deposit";

          let _typeSend = "&tType=" + value.type;
          let _amountTxt = numberFormat(value.paid_amount);

          let _actionExtra = (
            <a
              className=""
              href="javascript:void(0)"
              onClick={this.openModalHandler.bind(
                this,
                "modalMakePaymentDeposit",
                value.id,
                _actionTxt
              )}
            >
              {_actionTxt}
            </a>
          );

          if (value.type == "SALE") {
            _type = <span className={`badge badge-success`}>SALE</span>;
            _actionTxt = "Deposit";
            _editUrl =
              global.webUrl + "business/add-sale/?auth=" + value.id + _typeSend;
          } else if (value.type == "ESTIMATE") {
            _type = (
              <span className={`badge badge-primaryLight`}>ESTIMATE</span>
            );
            _actionTxt = "Deposit";
            _editUrl =
              global.webUrl +
              "business/add-sale-estimate/?auth=" +
              value.id +
              _typeSend;
          } else if (value.type == "CREDIT NOTE") {
            _type = (
              <span className={`badge badge-primaryLight`}>CREDIT NOTE</span>
            );
            _actionTxt = "Withdraw";
            _editUrl =
              global.webUrl +
              "business/add-sale-credit-note/?auth=" +
              value.id +
              _typeSend;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
          } else if (value.type == "PAYMENT-IN") {
            _type = (
              <span className={`badge badge-successDark`}>PAYMENT-IN</span>
            );
            _actionTxt = "Deposit";
            _editUrl =
              global.webUrl +
              "business/add-sale-payment-in/?auth=" +
              value.id +
              _typeSend;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
          } else if (value.type == "DELIVERY CHALLAN") {
            _type = (
              <span className={`badge badge-primaryLight`}>
                DELIVERY CHALLAN
              </span>
            );
            _actionTxt = "Deposit";
            _editUrl = global.webUrl + "business/manage-sale-delivery-challan/";
          } else if (value.type == "PURCHASE") {
            _type = <span className={`badge badge-success`}>PURCHASE</span>;
            _actionTxt = "Withdraw";
            _editUrl =
              global.webUrl +
              "business/add-purchase/?auth=" +
              value.id +
              _typeSend;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
          } else if (value.type == "EXPENSE") {
            _type = <span className={`badge badge-secondary`}>EXPENSE</span>;
            _actionTxt = "Withdraw";
            _editUrl =
              global.webUrl +
              "business/add-expense/?auth=" +
              value.id +
              _typeSend;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
          } else if (value.type == "DEBIT NOTE") {
            _type = <span className={`badge badge-secondary`}>DEBIT NOTE</span>;
            _actionTxt = "Deposit";
            _editUrl =
              global.webUrl +
              "business/add-purchase-debit-note/?auth=" +
              value.id +
              _typeSend;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
          } else if (value.type == "PAYMENT-OUT") {
            _type = (
              <span className={`badge badge-secondary`}>PAYMENT-OUT</span>
            );
            _actionTxt = "Withdraw";
            _editUrl =
              global.webUrl +
              "business/add-purchase-payment-out/?auth=" +
              value.id +
              _typeSend;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
          } else if (value.type == "PURCHASE ORDER") {
            _type = (
              <span className={`badge badge-secondary`}>PURCHASE ORDER</span>
            );
            _actionTxt = "Withdraw";
            _editUrl =
              global.webUrl +
              "business/add-purchase-order/?auth=" +
              value.id +
              _typeSend;
            if (value.balance_amount > 0) {
              _actionExtra = (
                <a
                  className=""
                  href="javascript:void(0)"
                  onClick={this.clickToDeleteNotifiyAccount.bind(
                    this,
                    "Purchase Order"
                  )}
                >
                  {_actionTxt}
                </a>
              );
            }
          } else if (value.type == "SALE ORDER") {
            _type = (
              <span className={`badge badge-primaryLight`}>SALE ORDER</span>
            );
            _actionTxt = "Deposit";
            _editUrl =
              global.webUrl +
              "business/add-sale-order/?auth=" +
              value.id +
              _typeSend;
            if (value.balance_amount > 0) {
              _actionExtra = (
                <a
                  className=""
                  href="javascript:void(0)"
                  onClick={this.clickToDeleteNotifiyAccount.bind(
                    this,
                    "Sale Order"
                  )}
                >
                  {_actionTxt}
                </a>
              );
            }
          } else if (value.type == "OTHER INCOME") {
            _type = (
              <span className={`badge badge-primaryLight`}>Other Income</span>
            );
            _actionTxt = "Deposit";
            _editUrl =
              global.webUrl + "business/create-other-income/?auth=" + value.id;
            _actionExtra = (
              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalMakePaymentDepositOI",
                  value.id,
                  _actionTxt
                )}
              >
                {_actionTxt}
              </a>
            );
            _amountTxt = numberFormat(value.total_amount);
          } else if (
            value.type == "PROCESSING" &&
            value.typeShow == "Processing Fee"
          ) {
            _type = (
              <span className={`badge badge-danger`}>PROCESSING FEE</span>
            );
          } else if (
            value.type == "PROCESSING" &&
            value.typeShow.includes("Loan Received")
          ) {
            _type = <span className={`badge badge-primaryLight`}>LOAN</span>;
          } else if (value.type == "EMI PAYMENT") {
            _type = (
              <span className={`badge badge-primaryLight`}>EMI PAYMENT</span>
            );
          }

          let _disPlayPName = value.party_name;
          if (value.type == "EXPENSE" && value.party_id > 0) {
            _disPlayPName =
              value.party_name + " (" + value.expense_category + ")";
          } else if (value.type == "EXPENSE" && value.party_id == 0) {
            _disPlayPName = value.expense_category;
          }
          let _statusTxt = <span className={`badge badge-warning`}>OPEN</span>;
          if (value.open_status == 2) {
            _statusTxt = (
              <span className={`badge badge-danger`}>{value.status_name}</span>
            );
          }
          if (value.open_status == 1) {
            _statusTxt = (
              <span className={`badge badge-success`}>{value.status_name}</span>
            );
            _actionTxt = "Re-open";
            _actionExtra = (
              // <a
              //   className=""
              //   href="javascript:void(0)"
              //   onClick={this.deleteRemoveTansaction.bind(
              //     this,
              //     value.id,
              //     _actionTxt,
              //     value.type
              //   )}
              // >
              //   Re-open
              // </a>

              <a
                className=""
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalReopenCheque",
                  value.id,
                  _actionTxt,
                  value.transaction_id,
                  value.type
                )}
              >
                Re-open
              </a>
            );
          }
          let reopen_reason = value.reopen_reason;
          let _viewAction = (
            <a className="" href={_editUrl}>
              View
            </a>
          );
          let showDateTime = value.invoice_date;
          if (_businessSetting.Tran_AddTime == 1 && value.invoice_time!==null) {
            showDateTime = value.invoice_date + " " + (value.invoice_time ? value.invoice_time : "");
          }

          rows.push({
            srNo: _i,
            type: _type,
            date: showDateTime,
            display_name: _disPlayPName, //value.party_name,
            refNo: value.payment_ref_no,
            amount: _amountTxt,
            status: _statusTxt,
            reopen_reason: reopen_reason,
            actionextra: (
              <span>
                {_actionExtra} | {_viewAction}
              </span>
            ),
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  clickToDeleteNotifiyAccount(txt) {
    let process = "deposited";
    if (txt == "Sale Order") {
      process = "deposited";
    } else {
      process = "withdrawn";
    }
    confirmAlert({
      title: "Transaction Alert",
      message:
        txt + " cheques can't be " + process + " before the order completion!!",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  deleteRemoveTansaction(value, type, transType) {
    confirmAlert({
      title: "Re-open Cheque",
      message: "Are you sure you want to Re-open this cheque?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const requestData = JSON.stringify({
              trasactionId: value,
              type: type,
              mainTransactionType: transType,
            });
            console.log("==requestData==",requestData);return false;
            PostData(
              global.userChequeTransactionDeleteByCheckPage,
              requestData,
              "POST"
            ).then((result) => {
              let responseJson = result;
              if (responseJson.success == false) {
                sendNotification(
                  "Error Message 😓",
                  responseJson.message,
                  "danger"
                );
                return;
              } else {
                sendNotification(
                  "Success Message 👍",
                  responseJson.message,
                  "success"
                );
                this.getTransactionListData();
                return;
              }
            });
          },
        },
        {
          label: "No, Cancel",
        },
      ],
    });
  }

  /*For Modal open close*/
  openModalHandler(popId, id, tpe, oid, type) {
    if (popId == "modalMakePaymentDeposit") {
      this.setState({
        modalIsOpen_MEP: true,
        activeTrasactionId: id,
        activeTypeAccount: tpe,
      });
    }
    if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: true,
        activeTrasactionId: id,
        activeTypeAccount: tpe,
      });
    }
    if (popId == "modalReopenCheque") {
      this.setState({
        modalIsOpen_Reopen: true,
        activeTrasactionId: id,
        activeTypeAccount: tpe,
        activeTrasactionOId: oid,
        activeTrasactionType: type,
      });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalMakePaymentDeposit") {
      this.setState({
        modalIsOpen_MEP: false,
        activeTypeAccount: "Deposit",
        activeTrasactionId: "0",
      });
    }
    if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: false,
        activeTrasactionId: 0,
        activeTypeAccount: "Deposit",
      });
    }
    if (popId == "modalReopenCheque") {
      this.setState({
        modalIsOpen_Reopen: false,
        activeTrasactionId: 0,
        activeTypeAccount: "Deposit",
        activeTrasactionOId: 0,
        activeTrasactionType: "",
      });
    }
  };
  /*End Modal open close*/

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({
      modalIsOpen_MEP: false,
      activeTrasactionId: 0,
      modalIsOpen_MEP_OI: false,
      modalIsOpen_Reopen: false,
    });
    this.getTransactionListData();
  };
  /* End Here */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Cheque Transaction
                List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Manage Cheque Transaction List
            </span>
          </nav>

          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <div className="block-content">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[25, 50, 100]}
                    entries={25}
                    pagesAmount={4}
                    span
                    pagingTop
                    searchTop
                    searchBottom={false}
                    barReverse
                    data={this.loadTrasactionListing()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Add  charges Loan Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_MEP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalMakePaymentDeposit"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Transfer Cheque</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalMakePaymentDeposit"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddDepositeMakePayment
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeTrasactionId}
                    isTransactionType={this.state.activeTypeAccount}
                    callBack={this.getUpdateResponse}
                    isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalMakePaymentDeposit"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add  charges Loan Details*/}

        {/* Modal Reopen Details Begins OI */}
        <Modal
          isOpen={this.state.modalIsOpen_Reopen}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalReopenCheque"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Reopen Cheque</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalReopenCheque"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddReopenDetails
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeTrasactionId}
                    isTransactionOId={this.state.activeTrasactionOId}
                    isTransactionType={this.state.activeTypeAccount}
                    isTransactionOType={this.state.activeTrasactionType}
                    callBack={this.getUpdateResponse}
                    isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalReopenCheque"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Reopen Details*/}

        {/* Modal Add  charges Loan Details Begins OI */}
        <Modal
          isOpen={this.state.modalIsOpen_MEP_OI}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalMakePaymentDepositOI"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Transfer Cheques</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalMakePaymentDepositOI"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddDepositeMakePaymentOI
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeTrasactionId}
                    isTransactionType={this.state.activeTypeAccount}
                    callBack={this.getUpdateResponse}
                    isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalMakePaymentDepositOI"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add  charges Loan Details*/}
      </React.Fragment>
    );
  }
}
