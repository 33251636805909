import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};

let _SerialNoChk = 0;
let _SerialNoTxt = '';
let _SerialNoAdditionalChk1 = 0;
let _SerialNoAdditionalChk2 = 0;
let _SerialNoAdditionalLabel1 = '';
let _SerialNoAdditionalLabel2 = '';

let _loader = 'glb-ldr-prt active';

export default class QuickAddSerialNoAdjustment extends Component{
    constructor(props){
        super(props);

        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _SerialNoAdditionalChk1 = _businessSetting.SerialNoAdditionalChk1;
                        _SerialNoAdditionalChk2 = _businessSetting.SerialNoAdditionalChk2;
                        _SerialNoAdditionalLabel1 = _businessSetting.SerialNoAdditionalLabel1;
                        _SerialNoAdditionalLabel2 = _businessSetting.SerialNoAdditionalLabel2;
                        _SerialNoChk = _businessSetting.SerialNoChk;
                        _SerialNoTxt = _businessSetting.SerialNoTxt;
                    }
                }
            }
        }
        console.log('this.props.itemSerialNoAll', this.props.itemSerialNoAll.length)
        this.state = {
            itemSerialNoAll : this.props.itemSerialNoAll ? this.props.itemSerialNoAll : [],
            itemId : this.props.itemId ? this.props.itemId : '0',
            openingStock : this.props.opening_stock ? this.props.opening_stock : 0,
            adjustmentId : this.props.adjustmentId ? this.props.adjustmentId : 0,
            stockType : this.props.stockType ? this.props.stockType  : '',
            stockAddEditType : this.props.stockAddEditType ? this.props.stockAddEditType  : '',
            itemStockType : this.props.itemStockType ? this.props.itemStockType  : '',
            isLoaded : true,
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            SerialNoAdditionalLabel1 : _SerialNoAdditionalLabel1,
            SerialNoAdditionalLabel2 : _SerialNoAdditionalLabel2,
            SerialNoAdditionalChk1 : _SerialNoAdditionalChk1,
            SerialNoAdditionalChk2 : _SerialNoAdditionalChk2,
            SerialNoChk : _SerialNoChk,
            SerialNoTxt : _SerialNoTxt,
            opSCount : this.props?.itemSerialNoAll ? this.props.itemSerialNoAll.length : 0,
            serial_no_val : '',
            isError : false,
            errors: {
                error  :  '',
                serial_no_val : '',
                error1  :  '',
                error2  :  '',
            }
        }
    }
    async componentDidMount ()
    {

        console.log("==AK--1st===",JSON.stringify(this.state.itemSerialNoAll));
        let dataStoc = this.state.itemSerialNoAll.filter((data) => data.isSoldOut!=true);
        this.setState({itemSerialNoAll : dataStoc});
        let dataStocNew = this.state.itemSerialNoAll.filter((data) => data.serial_id == 0);
        let lengthSrData  = dataStocNew ?  dataStocNew.length : 0;

        if((this.state.adjustmentId == 0 && this.state.stockType == 'Add Stock') && lengthSrData == 0){
            console.log("==aaa==") ;
            this.setState({
                itemSerialNoAll: [{ serial_id: 0, serial_no: "", serialNoAdditionalValue1 : "" , serialNoAdditionalValue2 : "" , isSoldOut : false, dataCheck:0,isFreeQuantity:false,isSelected:true,productId:this.state.itemId }]
            });
        }
        else if(this.state.stockAddEditType == 'EditStock' && this.state.itemStockType == 3){
            console.log("==zzzz==") ;

            this.setState({itemSerialNoAll : dataStoc});
        }
        else if(this.state.stockAddEditType == 'EditStock' && this.state.itemStockType == true){
            //let editStockFilter = this.state.itemSerialNoAll.filter((data) => data.adjustmentId > 0); issues no 3421
            let editStockFilter = this.state.itemSerialNoAll;
            console.log("==bbb==", JSON.stringify(editStockFilter)) ;
            this.setState({itemSerialNoAll : editStockFilter});
        }
        else if(this.state.stockAddEditType == 'EditStock' && this.state.itemStockType!=true){

            let editStockFilter = this.state.itemSerialNoAll.filter((data) => data.serialAddedType!='ADD');
            //let editStockFilter = this.state.itemSerialNoAll;
            editStockFilter.map((data,index) => {
                if(data.serialAddedType == 'REDUCE'){
                    data.isSelected = true;
                }
                else{
                    data.isSelected = false;
                }
            })
            console.log("==ccc==", JSON.stringify(editStockFilter)) ;
            this.setState({itemSerialNoAll : editStockFilter});
        }

    }

    handleChange(i, e) {
        let itemSerialNoAll = this.state.itemSerialNoAll;
        let _opCount = 0;
        let errors = this.state.errors;
        let _isError = false;

        if(this.state.itemSerialNoAll.length == 1){
            _opCount =  _opCount + 1;
        }else{
            _opCount =  this.state.itemSerialNoAll.length;
        }
        if(e.target.name == 'serial_no' && _businessSetting.SerialNoChk == 1){
            if(this.state.itemSerialNoAll.length == 1 && e.target.value == '' && this.state.itemId == 0){
                _opCount = 0;
            }

            _isError = false;
            itemSerialNoAll.map((data,index) => {
               if(data.serial_no == e.target.value){
                    _isError = true;
                    if(e.target.value == ''){
                        data['error'] = "* Please Enter "+this.state.SerialNoTxt+" ";
                    }else{
                        data['error'] = "*Serial number already exists.";
                    }
               }else{
                    data['error'] = "";
               }
            })
            this.setState({opSCount : _opCount});
        }
        else if(e.target.name == 'serialNoAdditionalValue1' && _businessSetting.SerialNoAdditionalChk1 == 1){
            _isError = false;
            itemSerialNoAll.map((data,index) => {
            if(data.serialNoAdditionalValue1 == e.target.value){
                 _isError = true;
                 data['error1'] = "* "+this.state.SerialNoAdditionalLabel1+" already exists.";
                }
                else{
                    data['error1'] = "";
                }
            })
        }
        else if(e.target.name == 'serialNoAdditionalValue2' && _businessSetting.SerialNoAdditionalChk2 == 1){
            _isError = false;
            itemSerialNoAll.map((data,index) => {
                if(data.serialNoAdditionalValue2 == e.target.value){
                    _isError = true;
                    data['error2'] = "* "+this.state.SerialNoAdditionalLabel2+" already exists.";
                }
                else{
                    data['error2'] = "";
                }
            })
        }
        else{
            _opCount = this.state.opSCount;
            this.setState({opSCount : _opCount });
        }
        itemSerialNoAll[i][e.target.name] = e.target.value;
        this.setState({ itemSerialNoAll,isError:_isError});
    }

    addFormFields() {
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.opSCount  == 0 && this.state.itemId == 0){
            _isError = true;
        }
        else{
            _isError = false;
            this.setState(({
                itemSerialNoAll: [...this.state.itemSerialNoAll, {serial_id: 0,serial_no: "", serialNoAdditionalValue1: "" , serialNoAdditionalValue2: "" , isSoldOut : false,dataCheck:0,isFreeQuantity:false,isSelected:true,productId:this.state.itemId}]
            }))
        }
    }

    removeFormFields(i) {
        let _opCount = this.state.opSCount;
        if(this.state.itemSerialNoAll.length == 1){
            _opCount =  this.state.itemSerialNoAll.length;//_opCount + 1;
        }else{
            _opCount =  this.state.itemSerialNoAll.length -  1;
        }
        this.setState({opSCount : _opCount });

        let itemSerialNoAll = this.state.itemSerialNoAll;
        itemSerialNoAll.splice(i, 1);

        let errors = this.state.errors;
        let _isError = false;

        this.setState({ itemSerialNoAll,isError:_isError});
    }
    removeFormFieldsEdit(i,srNo,itemId,srId){
        //alert(i+'==>>='+srNo+'==>>='+itemId+'==>=='+srId);
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure, it will be delete permanently. Do you still want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        itemId: itemId,
                        serial_no: srNo,
                        serial_id: srId
                    });
                    PostData(global.userItemSerialDelete, requestData,'POST').then((result) => {
                        let responseJson = result;
                        if(responseJson.success == false)
                        {
                            //sendNotification("Error Message 😓",responseJson.message,"danger");
                            //return;
                        }
                        else
                        {
                            let _opCount = this.state.opSCount;
                            if(this.state.itemSerialNoAll.length == 1){
                                _opCount =  this.state.itemSerialNoAll.length;//_opCount + 1;
                            }else{
                                _opCount =  this.state.itemSerialNoAll.length -  1;
                            }
                            this.setState({opSCount : _opCount });

                            let itemSerialNoAll = this.state.itemSerialNoAll;
                            itemSerialNoAll.splice(i, 1);

                            let errors = this.state.errors;
                            let _isError = false;

                            this.setState({ itemSerialNoAll,isError:_isError});
                            //sendNotification("Success Message 👍",responseJson.message,"success");
                            //this.getItemListData(company_id);
                            //return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });

    }


    clickToAddData = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        let _itemSerialData = (this.state.itemSerialNoAll);
        let _selectedItems = [];

        if(this.state.stockType == 'Add Stock'){
            _itemSerialData.map((data,index) => {
                if(data.serial_no == '' && _businessSetting.SerialNoChk == 1){
                    _isError = true;
                    data['error'] = "*Please Enter "+this.state.SerialNoTxt+" ";
                    this.setState({isError: _isError});
                }
                else if(data.serialNoAdditionalValue1 == '' && _businessSetting.SerialNoAdditionalChk1 == 1){
                    _isError = true;
                    data['error1'] = "*Please Enter  "+this.state.SerialNoAdditionalLabel1+" ";
                    this.setState({isError: _isError});
                }
                else if(data.serialNoAdditionalValue2 == '' && _businessSetting.SerialNoAdditionalChk2 == 1){
                    _isError = true;
                    data['error2'] = "*Please Enter  "+this.state.SerialNoAdditionalLabel2+" ";
                    this.setState({isError: _isError});
                }
            });

            if(_isError ==  false){
                let _opening_stock = 0;
                if(_itemSerialData.length <= this.state.opening_stock){
                    _opening_stock = this.state.opening_stock;
                }else{
                    _opening_stock = _itemSerialData.length;
                }

                this.props.callBack (_itemSerialData, _opening_stock);
            }
        }
        else{
            /**/
            let _count = 0;
            let _countOldCheck = 0;
            _itemSerialData.forEach(item => {
                if(this.state.transactionId > 0){
                    if (item.isSelected == true && item.isSelectedOld == true)
                    {
                        _countOldCheck++;
                    }
                }
                if (item.isSelected == true)
                {
                    _count++;
                    _selectedItems.push(item);
                }
            });

            let _srLength = _selectedItems ? _selectedItems.length : 0;
            console.log(_srLength+"==HERE--11===",JSON.stringify(_itemSerialData));
            console.log(_srLength+"==HERE-22===",JSON.stringify(_selectedItems));
            let _itemStockType = this.state.itemStockType;
            if(this.state.stockAddEditType == 'EditStock'){
                _itemStockType = 3;
            }
            console.log("==_itemStockType==",_itemStockType);
            if(_srLength > 0){
                this.props.callBack(_itemSerialData,_srLength,_itemStockType);
            }else{
                alert("Please select any one serial");
            }
        }

    }

    onCheckChange(e) {
        const _ItemsArray = this.state.itemSerialNoAll;

        let errors = this.state.errors;
        errors['noCheck'] = "";
        this.setState({errors: errors});
        let _noCheck = 0;
        _ItemsArray.forEach(item => {
            console.log(item.serial_id+"==>dddd>=="+e.target.value);
            if (item.serial_id == e.target.value)
            {
                item.isSelected = e.target.checked;
                item.productId = this.state.itemId;
                if(this.state.transactionId > 0){
                    if(e.target.checked == true){
                        item.dataCheck = 0;
                        _noCheck =  parseFloat(_noCheck) + 1 ;
                    }
                    else{
                        item.dataCheck = 1;
                        _noCheck =  parseFloat(_noCheck) - 1 ;
                    }
                }else{
                    if(e.target.checked == true){
                        item.dataCheck = 0;
                        _noCheck =  parseFloat(_noCheck) + 1 ;
                    }else{
                        item.dataCheck = 1;
                        _noCheck =  parseFloat(_noCheck) - 1 ;
                    }
                }
            }
        });
        //console.log(_noCheck+"==demo===",JSON.stringify(_ItemsArray));
        this.setState({ itemSerialNoAll: _ItemsArray});
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let _countMain = this.state.openingStock;
        if(this.state.openingStock == 0){

            _countMain = this.state.opSCount;
        }
        if(this.state.openingStock > 0 && (this.state.opSCount > this.state.openingStock)){
            _countMain = this.state.opSCount;
        }
        //let dataStoc = this.state.itemSerialNoAll.filter((data) => data.isSoldOut!=true);
        //this.setState({itemSerialNoAll : dataStoc});
        const {errors} = this.state;
        let  isDisabled = '';//Object.keys(errors).some(x => errors[x]);
        if(this.state.isError == true){
            isDisabled = 'disabled';
        }
        const mystyle = {
            'z-index': '9999999 !important',
        };
        return(
            <React.Fragment>
                <form method="post" id="popupRemoveId" className="webForm px-30">
                    {this.state.stockType == 'Reduce Stock' &&
                    <div id="reduceStock">
                        {this.state.itemSerialNoAll.length == 0 &&
                            <p><strong style={{color : 'red'}}>You have no serial in stock</strong></p>
                        }
                        {this.state.itemSerialNoAll.map((element, index) => (
                        <div className="form-group" >
                            {(element.isSoldOut == false && element.serial_id > 0) &&
                                <div className="row">
                                    {this.state.SerialNoChk == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} onWheel={() => document.activeElement.blur()} />
                                            <label htmlFor="login-username">{this.state.SerialNoTxt} <span class="text-danger">*</span> </label>
                                            <span className="iris_error_txt">{element.error?.length > 0 && <span className='error'>{element.error}</span>}</span>
                                        </div>
                                    </div>
                                    }
                                    {this.state.SerialNoAdditionalChk1 == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" onWheel={() => document.activeElement.blur()}/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1} <span class="text-danger">*</span> </label>
                                            <span className="iris_error_txt">{element.error1?.length > 0 && <span className='error'>{element.error1}</span>}</span>

                                        </div>
                                    </div>
                                    }
                                    {this.state.SerialNoAdditionalChk2 == 1 &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" onWheel={() => document.activeElement.blur()}/>
                                            <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2} <span class="text-danger">*</span> </label>
                                            <span className="iris_error_txt">{element.error2?.length > 0 && <span className='error'>{element.error2}</span>}</span>
                                        </div>
                                    </div>
                                    }
                                    {((this.state.itemSerialNoAll.length  >0 ) && (index >= 0) ) &&
                                    <div className="col-3">
                                        <div className="form-material open">
                                            <div class="wrapperRadio">
                                                <input type="checkbox" name="checkData" value={element.serial_id} onChange={this.onCheckChange.bind(this)}  checked={element.isSelected ? element.isSelected : ''} onWheel={() => document.activeElement.blur()}/>
                                                <label for="option-1" class="option option-1">
                                                    <div class="dot"></div>
                                                    <span>Select</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {( (this.state.itemId == '0')  && ( (this.state.itemSerialNoAll.length  >1 ) && (index >= 1)) ) &&

                                    <div className="col-3">
                                        <a  className="badge badge-danger pull-right badge-danger" onClick={() => this.removeFormFields(index)} >
                                            <i className="fa fa-solid fa-trash"></i>
                                        </a>
                                    </div>
                                    }
                                    </div>
                            }
                        </div>
                    ))}
                    </div>
                    }
                    {(this.state.stockType == 'Add Stock' && this.state.stockAddEditType == 'AddStock') &&
                    <div id="addStock">
                        {this.state.itemSerialNoAll.map((element, index) => (
                            <div className="form-group" >

                                {/* {(element.isSoldOut == false && element.serial_id > 0) && */}
                                {(element.serial_id == 0) &&
                                    <div className="row">
                                        {this.state.SerialNoChk == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} />
                                                <label htmlFor="login-username">{this.state.SerialNoTxt} <span class="text-danger">*</span> </label>
                                                <span className="iris_error_txt">{element.error?.length > 0 && <span className='error'>{element.error}</span>}</span>
                                            </div>
                                        </div>
                                        }
                                        {this.state.SerialNoAdditionalChk1 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" onWheel={() => document.activeElement.blur()}/>
                                                <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1} <span class="text-danger">*</span> </label>
                                                <span className="iris_error_txt">{element.error1?.length > 0 && <span className='error'>{element.error1}</span>}</span>

                                            </div>
                                        </div>
                                        }
                                        {this.state.SerialNoAdditionalChk2 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" onWheel={() => document.activeElement.blur()}/>
                                                <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2} <span class="text-danger">*</span> </label>
                                                <span className="iris_error_txt">{element.error2?.length > 0 && <span className='error'>{element.error2}</span>}</span>
                                            </div>
                                        </div>
                                        }

                                        {( (this.state.itemId == '0')  && ( (this.state.itemSerialNoAll.length  >1 ) && (index >= 1)) ) &&

                                        <div className="col-3">
                                            <a  className="badge badge-danger pull-right badge-danger" onClick={() => this.removeFormFields(index)} >
                                                <i className="fa fa-solid fa-trash"></i>
                                            </a>
                                        </div>
                                        }

                                        {( (this.state.itemId > 0)  && ( (this.state.itemSerialNoAll.length  >1 ) && (index >= 1)) ) &&

                                        <div className="col-3">
                                            <a  className="badge badge-danger pull-right badge-danger" onClick={() => this.removeFormFields(index)} >
                                                <i className="fa fa-solid fa-trash"></i>
                                            </a>
                                        </div>
                                        }
                                        </div>
                                }
                                {/* } */}
                            </div>
                        ))}
                    </div>
                    }

                    {(this.state.stockType == 'Add Stock' && this.state.stockAddEditType == 'EditStock') &&
                    <div id="reduceStock">
                        {this.state.itemSerialNoAll.map((element, index) => (
                            <div className="form-group" >
                                {/* {(element.adjustmentId > 0) && */}
                                    <div className="row">
                                        {this.state.SerialNoChk == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} onWheel={() => document.activeElement.blur()}/>
                                                <label htmlFor="login-username">{this.state.SerialNoTxt} <span class="text-danger">*</span> </label>
                                                <span className="iris_error_txt">{element.error?.length > 0 && <span className='error'>{element.error}</span>}</span>
                                            </div>
                                        </div>
                                        }
                                        {this.state.SerialNoAdditionalChk1 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" onWheel={() => document.activeElement.blur()}/>
                                                <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1} <span class="text-danger">*</span> </label>
                                                <span className="iris_error_txt">{element.error1?.length > 0 && <span className='error'>{element.error1}</span>}</span>

                                            </div>
                                        </div>
                                        }
                                        {this.state.SerialNoAdditionalChk2 == 1 &&
                                        <div className="col-3">
                                            <div className="form-material open">
                                                <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" onWheel={() => document.activeElement.blur()}/>
                                                <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2} <span class="text-danger">*</span> </label>
                                                <span className="iris_error_txt">{element.error2?.length > 0 && <span className='error'>{element.error2}</span>}</span>
                                            </div>
                                        </div>
                                        }

                                        {( (this.state.itemId == '0')  && ( (this.state.itemSerialNoAll.length  >1 ) && (index >= 1)) ) &&

                                        <div className="col-3">
                                            <a  className="badge badge-danger pull-right badge-danger" onClick={() => this.removeFormFields(index)} >
                                                <i className="fa fa-solid fa-trash"></i>
                                            </a>
                                        </div>
                                        }
                                        </div>
                                {/* } */}
                            </div>
                        ))}
                    </div>
                    }

                    <span className="iris_error_txt">{errors.serial_no_val.length > 0 && <span className='error'>{errors.serial_no_val}</span>}</span>
                    {this.state.itemSerialNoAll.length > 0 &&
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>&nbsp;&nbsp;
                            {this.state.stockType == 'Add Stock' &&
                                <button type='button' className="btn btn-alt-primary" onClick={() => this.addFormFields()}  disabled={isDisabled}> <i className='fa fa-plus'></i>Add </button>
                            }
                        </div>
                    </div>
                    }
                </form>

            </React.Fragment>
        )
    }
}
