import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class QuickAdditionalChargePanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }


        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,         
            modalIsOpen_Info : false,     
            modalIsOpen_TermsCond : false,
            _infoData : '',
            TransactionAdditionalChargeEnable : (_businessSetting.TransactionAdditionalChargeEnable > 0) ? true : false,
            
            TransactionAdditionalCharge1Name : (_businessSetting.TransactionAdditionalCharge1Name) ? _businessSetting.TransactionAdditionalCharge1Name : 'Shipping',
            TransactionAdditionalCharge1Enable : (_businessSetting.TransactionAdditionalCharge1Enable > 0) ? true : false,
                 
            TransactionAdditionalCharge2Name : (_businessSetting.TransactionAdditionalCharge2Name) ? _businessSetting.TransactionAdditionalCharge2Name : 'Packaging',
            TransactionAdditionalCharge2Enable : (_businessSetting.TransactionAdditionalCharge2Enable > 0) ? true : false,

            TransactionAdditionalCharge3Name : (_businessSetting.TransactionAdditionalCharge3Name) ? _businessSetting.TransactionAdditionalCharge3Name : 'Adjustment',
            TransactionAdditionalCharge3Enable : (_businessSetting.TransactionAdditionalCharge3Enable > 0) ? true : false,
        }
    }

    componentDidMount()
    {  

    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        
        if(_key == 'TransactionAdditionalChargeEnable'){
            this.setState({TransactionAdditionalChargeEnable:_value}); 
        } 
        if(_key == 'TransactionAdditionalCharge1Enable'){
            this.setState({TransactionAdditionalCharge1Enable:_value}); 
        } 
        if(_key == 'TransactionAdditionalCharge2Enable'){
            this.setState({TransactionAdditionalCharge2Enable:_value}); 
        } 
        if(_key == 'TransactionAdditionalCharge3Enable'){
            this.setState({TransactionAdditionalCharge3Enable:_value}); 
        }

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'TransactionAdditionalCharge1Name'){
            this.setState({TransactionAdditionalCharge1Name:activeValue}); 
        } 
        if(_keyVal == 'TransactionAdditionalCharge2Name'){
            this.setState({TransactionAdditionalCharge2Name:activeValue}); 
        } 
        if(_keyVal == 'TransactionAdditionalCharge3Name'){
            this.setState({TransactionAdditionalCharge3Name:activeValue}); 
        }       
        //console.log(_keyVal+'=='+activeValue+'===STATE=='+this.state.PartyAddField1Name+'==END==');
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }   
    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        
        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        let _disabled1 = 'disabled';
        let _disabled2 = 'disabled';
        let _disabled3 = 'disabled';
        if(this.state.TransactionAdditionalCharge1Enable == 1 || this.state.TransactionAdditionalCharge1Enable == true){
            _disabled1 = '';
        }
        if(this.state.TransactionAdditionalCharge2Enable == 1 || this.state.TransactionAdditionalCharge2Enable == true){
            _disabled2 = '';
        }
        if(this.state.TransactionAdditionalCharge3Enable == 1 || this.state.TransactionAdditionalCharge3Enable == true){
            _disabled3 = '';
        }
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-12">
                        <h6 className="content-heading">Enable additional charges
                            <p style={{'font-size': '15px !important'}}>Allows you to add additional charges like shipping, packaging etc. in sale and purchase transaction.
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                    &nbsp;&nbsp;
                                        <input type="checkbox" defaultChecked={(this.state.TransactionAdditionalChargeEnable > 0) ? true : false} id="TransactionAdditionalChargeEnable" name="TransactionAdditionalChargeEnable" value="1" data-slug="TransactionAdditionalChargeEnable" onChange={this.onFilterChange} />
                                    </span>
                            </p>
                        </h6>
                        {this.state.TransactionAdditionalChargeEnable == true &&
                            <ul>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <label style={{width:'18%'}}>Additional Charge 1</label> 
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                        <input type="text" placeholder="Additional Charge 1" data-slug="TransactionAdditionalCharge1Name" id="TransactionAdditionalCharge1Name" name="TransactionAdditionalCharge1Name" value ={this.state.TransactionAdditionalCharge1Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled1} />
                                    </span>
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>  </span>                                
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                    &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransactionAdditionalCharge1Enable > 0) ? true : false} id="TransactionAdditionalCharge1Enable" name="TransactionAdditionalCharge1Enable" value="1" data-slug="TransactionAdditionalCharge1Enable" onChange={this.onFilterChange}/>
                                    </span>
                                </li>  
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <label style={{width:'18%'}}>Additional Charge 2</label> 
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                        <input type="text" placeholder="Additional Charge 2" data-slug="TransactionAdditionalCharge2Name" id="TransactionAdditionalCharge2Name" name="TransactionAdditionalCharge2Name" value ={this.state.TransactionAdditionalCharge2Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled2} />
                                    </span>
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>  </span>                                
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                    &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransactionAdditionalCharge2Enable > 0) ? true : false} id="TransactionAdditionalCharge2Enable" name="TransactionAdditionalCharge2Enable" value="1" data-slug="TransactionAdditionalCharge2Enable" onChange={this.onFilterChange}/>
                                    </span>
                                </li>  
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <label style={{width:'18%'}}>Additional Charge 3</label> 
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                        <input type="text" placeholder="Additional Charge 3" data-slug="TransactionAdditionalCharge3Name" id="TransactionAdditionalCharge3Name" name="TransactionAdditionalCharge3Name" value ={this.state.TransactionAdditionalCharge3Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled3} />
                                    </span>
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>  </span>                                
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                    &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransactionAdditionalCharge3Enable > 0) ? true : false} id="TransactionAdditionalCharge3Enable" name="TransactionAdditionalCharge3Enable" value="1" data-slug="TransactionAdditionalCharge3Enable" onChange={this.onFilterChange}/>
                                    </span>
                                </li>                            
                            </ul>
                        }
                    </div>
                    
                </div>

            

            </React.Fragment>
        )
    }
} 