import React,{Component} from 'react';
import {PostData} from '../../service/postData';
import { numberFormat } from '../../config/numberFormat';
import Modal from 'react-modal';

import jsPDF from 'jspdf';
import html2PDF from 'jspdf-html2canvas';
import ReactToPrint from "react-to-print";



let _UserSession = localStorage.getItem('vs_UserSession');

let _companyName = 'NA';
let _companyLogo = 'NA';
let _companyAddress = 'NA';
let _companyPhone = 'NA';
let _countryGst = 'NA';
let _businessId = '0';
let _company_id = '0';
let _countryCode = 'IN';
let _countryName = 'India';
let _signingId = '0';
let _tax_name = '';

if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
    _company_id = _UserSession.company_id;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _companyName = _companySession.company_name;
    _companyLogo = _companySession.company_logo;
    _companyAddress = _companySession.company_address;
    _companyPhone = _companySession.company_contact;
    _company_id = _companySession.company_id;
    _countryCode = _companySession.country_code;
    _countryName = _companySession.country_name;
    _countryGst = (_companySession.gstin) ? _companySession.gstin : '';
    _tax_name = _companySession.tax_name;
}

let _imageBaseDecode = '';
let _imageLogoBaseDecode = '';

let _extraReceiveDiv = (<p><b>Received By</b><br/>Name:<br/>Comment:<br/>Date:<br/>Signature:</p>);
let _extraDeleiveryDiv = (<p><b>Delivered By</b><br/>Name:<br/>Comment:<br/>Date:<br/>Signature:</p>);

const footer = {
    // position: 'absolute',
    // bottom: '70px',
    // width: '93%',
    display:'none'
};

let _loader = 'glb-ldr-prt active';

export default class userPDFPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            invoiceRtnHide:'hide',
            modalIsOpen_PDF : false,
            isLoaded : false,
            transactionId : this.props.transactionId,
            companyName: _companyName,
            companyAddress: _companyAddress,
            companyPhone: _companyPhone,
            companyGstin: _countryGst,
            customerId: '#001',
            customerCompanyName: 'Pahal Collection',
            customerAddress: '#861/9, Nanda Nagar, Indore (M.P.) - 452010',
            customerPhone: '0123456789',
            invoiceNo: '1122334455',
            
            invoiceRtnNo: '1122334455',
            invoiceDate : '1 June, 2021',
            date: '13 Apr 2021',
            logo: 'NA',
            paymentType : 'Cash',

            titleTxt : 'TAX INVOICE',
            billTxt : 'Bill To',

            _invNo : 'Invoice No.',
            gstArray : [],
            description : '',
            totalAmount: 0,
            receivedAmount: 0,
            subtotal: 0,
            gstRatio: 0,
            gstAmount: 0,
            totalQty: 0,
            totalDiscount: 0,
            dueAmount: 0,
            imagePreviewUrl:'',
            items: [
                {
                    id: 1,
                    item_name: 'Sample Item 1',
                    quantity: 1,
                    qtyType: 'BAG',
                    rate: 50,
                    subTotalAmount: (50 * 10),
                    totalAmount: ((50 * 10) * 18 / 100) + (50 * 10),
                }
            ]
        }
    }

    async componentDidMount ()
    {}

    async convertImageUrlToBase64(url, callback, outputFormat)
    {
        var img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = function()
        {
            var originalWidth = img.style.width;
            var originalHeight = img.style.height;

            img.style.width = "auto";
            img.style.height = "auto";
            img.crossOrigin = "Anonymous";

            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            img.style.width = originalWidth;
            img.style.height = originalHeight;

            // Get the data-URL formatted image
            // Firefox supports PNG and JPEG. You could check img.src to
            // guess the original format, but be aware the using "image/jpg"
            // will re-encode the image.
            var dataUrl = canvas.toDataURL(outputFormat);

            //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            _imageBaseDecode = dataUrl;
        };
        img.src = url;
    }

    async convertImageUrlToBase64Logo(url, callback, outputFormat)
    {
        var img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = function()
        {
            var originalWidth = img.style.width;
            var originalHeight = img.style.height;

            img.style.width = "auto";
            img.style.height = "auto";
            img.crossOrigin = "Anonymous";

            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            img.style.width = originalWidth;
            img.style.height = originalHeight;

            // Get the data-URL formatted image
            // Firefox supports PNG and JPEG. You could check img.src to
            // guess the original format, but be aware the using "image/jpg"
            // will re-encode the image.
            var dataUrl = canvas.toDataURL(outputFormat);

            //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            _imageLogoBaseDecode = dataUrl;
        };
        img.src = url;
    }

    async getTransactionDetails()
    {
        if(this.state.transactionId >0){
            let _transactionArray = [];
            const requestData = JSON.stringify({
                transaction_id: this.state.transactionId
            });

            PostData(global.userTransactionDetails, requestData,'POST').then((result) => {
                let responseJson = result;
                if(responseJson.success == false)
                {
                    this.setState({});
                }
                else
                {
                    if (responseJson.response) {
                        _transactionArray = responseJson.response[0];
                        let transAmout = _transactionArray.totalSummary;
                        let itemsData = _transactionArray.items;
                        let customerData = _transactionArray.customer;

                        let _gstArray = [];
                        let _itemsArray = [];
                        itemsData.map((items,index) => {
                            let _obj = {};
                            _obj.item_name = items.item_name;
                            _obj.hsn_sac = items.hsn_sac;
                            _obj.quantity = items.quantity;
                            _obj.rate = items.rate;
                            _obj.qtyType = '';
                            _obj.discountAmount = items.discount_in_amount;
                            _obj.gstAmount = items.tax_value;
                            _obj.subTotalAmount = items.subtotal;
                            _obj.totalAmount = items.subtotal;

                            _obj.unitName = (items.unit.unit_name) ? items.unit.unit_name : '';
                            _itemsArray.push(_obj);

                            items.tax_type.price = items.tax_value;
                            _gstArray.push(items.tax_type);
                        });

                        let _titleTrans = 'TAX INVOICE';
                        let _billTrans = 'TAX INVOICE';
                        let _invoiceRtnHide = 'hide';
                        let _invNumber = 'Invoice No.';
                        if(_transactionArray.type == 'SALE ORDER'){
                            _invNumber = 'Order No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _titleTrans = 'Sale Order';
                            _billTrans = 'Order For';
                        }
                        else if(_transactionArray.type == 'DELIVERY CHALLAN'){
                            _invNumber = 'Challan No.';
                            _titleTrans = 'Delivery Challan';
                            _billTrans = 'Delivery Challan For';
                        }
                        else if(_transactionArray.type == 'ESTIMATE'){
                            _invNumber = 'Estimate No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _titleTrans = 'Estimate Quotation';
                            _billTrans = 'Estimate For';
                        }
                        else if(_transactionArray.type == 'CREDIT NOTE'){
                            _invNumber = 'Invoice No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _invoiceRtnHide = '';
                            _titleTrans = 'Credit Note';
                            _billTrans = 'Return For';
                        }
                        else if(_transactionArray.type == 'PAYMENT-IN'){
                            _invNumber = 'Receipt No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _titleTrans = 'Payment Receipt';
                            _billTrans = 'Received For';
                        }
                                                
                        else if(_transactionArray.type == 'PURCHASE'){
                            _invNumber = 'Bill No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _titleTrans = 'Bill';
                            _billTrans = 'Bill From';
                        }
                        else if(_transactionArray.type == 'PAYMENT-OUT'){
                            _invNumber = 'Receipt No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _titleTrans = 'Payment Out';
                            _billTrans = 'Paid To';
                        }
                        else if(_transactionArray.type == 'PURCHASE ORDER'){
                            _invNumber = 'Order No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _titleTrans = 'Purchase Order';
                            _billTrans = 'Order To';
                        }
                        else if(_transactionArray.type == 'DEBIT NOTE'){
                            _invNumber = 'Invoice No.';
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                            _invoiceRtnHide = '';
                            _titleTrans = 'Debit Note';
                            _billTrans = 'Return To';
                        }
                        else{
                            _extraReceiveDiv = '';
                            _extraDeleiveryDiv = '';
                        }
                        
                        this.setState({
                            isLoaded:true,
                            invoiceRtnHide:_invoiceRtnHide,
                            customerId: customerData.party_id,
                            customerCompanyName: customerData.party_name,
                            customerAddress: customerData.party_billing_address,
                            customerPhone: customerData.party_contact_no,
                            invoiceNo: _transactionArray.invoiceNo,
                            invoiceRtnNo : _transactionArray.invoice_no_return,
                            invoiceDate : _transactionArray.invoiceDate,
                            date: _transactionArray.invoiceDate,
                            description: _transactionArray.description,
                            totalAmount:transAmout.totalAmount,
                            receivedAmount:transAmout.receivedAmount,
                            paidAmount:transAmout.paidAmount,
                            subtotal:transAmout.subtotal,
                            gstRatio:transAmout.gstRatio,
                            gstAmount:transAmout.gstAmount,
                            totalQty:transAmout.totalQty,
                            totalDiscount:transAmout.totalDiscount,
                            dueAmount:transAmout.dueAmount,
                            paymentType:_transactionArray.paymentType,
                            items: _itemsArray,
                            gstArray : _gstArray,
                            _invNo : _invNumber,
                            _type:_transactionArray.type,
                            titleTxt : _titleTrans,
                            billTxt : _billTrans
                        });
                    }
                }
            })
            .catch(error => this.setState({ error}));
        }
    }

    async clickToGenratePDF(value)
    {
        const printArea = document.getElementById("pdfFormatReceipt");
        //console.log(-window.scrollX+'-----'+-window.scrollY);
        //console.log(document.documentElement.offsetWidth+'-----'+document.documentElement.offsetHeight);
        const pdf = await html2PDF(printArea, {
            
            html2canvas: {
                scrollX: -window.scrollX,
                scrollY: -window.scrollY,
                windowWidth: document.documentElement.offsetWidth,
                windowHeight: document.documentElement.offsetHeight
            },
            imageType: 'image/png',
            output: './invoiceRecipt.pdf'
        });
    }

    async clickToGenratePrint(e)
    {
        /*let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

        mywindow.document.write(`<html><head><title>Test NJ</title>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById("pdfFormatReceipt").innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10

        mywindow.print();
        mywindow.close();*/

        let printContents = document.getElementById("pdfFormatReceipt").innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;


        //window.print();
    }

    /*For Modal open close*/
    openModalHandler(popId)
    {
        this.getTransactionDetails();
        if(popId == 'modalAdjustPDF'){
            this.setState({modalIsOpen_PDF: true});
        }
    }

    closeModalHandler = (popId) => {
        if(popId == 'modalAdjustPDF'){
            this.setState({modalIsOpen_PDF: false});
        }
    }
    /*End Modal open close*/

    getGstAmtDetails(){
        if(this.state.gstArray.length > 0){
            this.state.gstArray.map((value,index) => {
                let _i = parseInt(index) + 1;
                return(
                    <tr>
                        <td style={{textAlign:'left',fontFamily:'Arial',fontSize: '15px',color:'#333333',lineHeight:'24px'}}>
                            {value.name+'('+value.ratio+'%)'}
                        </td>
                        <td style={{textAlign:'left',fontFamily:'Arial',fontSize: '15px',color:'#333333',lineHeight:'24px'}}>
                            {numberFormat(value.price)}
                        </td>
                    </tr>
                )
            })
        }
    }

    getTotalCalculation()
    {
        let totalAmtCode = (<tr style={{background: '#9f9fdf'}}>
            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#ffffff',lineHeight: '24px'}}>
                Total
            </td>
            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#ffffff',lineHeight: '24px'}}>
                {numberFormat(this.state.totalAmount)}
            </td>
        </tr>);
        let discountAmtCode = (<tr>
            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>
                Discount
            </td>
            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>
                {this.state.totalDiscount}
            </td>
        </tr>);

        let subTotalAmtCode = (<tr>
            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>
                Subtotal
            </td>
            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>
                {this.state.subtotal}
            </td>
        </tr>);

        let receiveAmtCode =  (<tr>
                                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Received Amount</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.receivedAmount)}</td>
                        </tr>);
        let balanceAmtCode =  ( <tr><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Balance Due</td>
                            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.dueAmount)}</td>
                        </tr>);
        let paymentAmtCode =  (<tr>
                            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Payment Mode</td>
                            <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{this.state.paymentType}</td>
                        </tr>);

        let _invType = this.state._type;
        if(_invType == 'SALE ORDER')
        {
            receiveAmtCode =  (<tr>
                    <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Advance Amount</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.receivedAmount)}</td>
                </tr>);
            balanceAmtCode =  ( <tr><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Balance Due</td>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.dueAmount)}</td>
            </tr>);
            paymentAmtCode = '';
        }
        else if(_invType == 'DELIVERY CHALLAN'){
            receiveAmtCode = '';
            balanceAmtCode = '';
            paymentAmtCode = '';
        }
        else if(_invType == 'ESTIMATE'){
            receiveAmtCode = '';
            balanceAmtCode = '';
            paymentAmtCode = '';
        }
        else if(_invType == 'CREDIT NOTE'){
            receiveAmtCode =  (<tr>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Paid Amount</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.receivedAmount)}</td>
            </tr>);
            balanceAmtCode =  ( <tr><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Balance Due</td>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.dueAmount)}</td>
            </tr>);
        }
        else if(_invType == 'PAYMENT-IN'){
            subTotalAmtCode = '';
            totalAmtCode = '';
            discountAmtCode = '';
            receiveAmtCode = (<tr>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Received</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.receivedAmount)}</td>
            </tr>);
            balanceAmtCode = '';
            paymentAmtCode = '';
        }
        else if(_invType == 'PAYMENT-OUT'){
            subTotalAmtCode = '';
            totalAmtCode = '';
            discountAmtCode = '';
            receiveAmtCode = (<tr>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Paid</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.paidAmount)}</td>
            </tr>);
            balanceAmtCode = '';
            paymentAmtCode = '';
        }
        else if(_invType == 'DEBIT NOTE'){
            receiveAmtCode =  (<tr>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Paid Amount</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.receivedAmount)}</td>
            </tr>);
            balanceAmtCode =  ( <tr><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Balance Due</td>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.dueAmount)}</td>
            </tr>);
        }
        else if(_invType == 'PURCHASE ORDER')
        {
            receiveAmtCode =  (<tr>
                    <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Advance Amount</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.receivedAmount)}</td>
                </tr>);
            balanceAmtCode =  ( <tr><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Balance Due</td>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.dueAmount)}</td>
            </tr>);
            paymentAmtCode = '';
        }
        else if(_invType == 'PURCHASE')
        {
            receiveAmtCode =  (<tr>
                    <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Paid Amount</td><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.paidAmount)}</td>
                </tr>);
            balanceAmtCode =  ( <tr><td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>Balance Due</td>
                <td style={{textAlign: 'left',fontFamily: 'Arial',fontSize: '15px',color: '#333333',lineHeight: '24px'}}>{numberFormat(this.state.dueAmount)}</td>
            </tr>);
            paymentAmtCode = '';
        }

        return(
            <table cellPadding="0" cellSpacing="0" width="100%">
                <tr>
                    <td colSpan="2" height="10">
                        &nbsp;
                    </td>
                </tr>
                {subTotalAmtCode}
                {discountAmtCode}
                {this.getGstAmtDetails()}
                {totalAmtCode}
                {receiveAmtCode}
                {balanceAmtCode}
                {paymentAmtCode}
                <tr>
                    <td colSpan="2" height="50">
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td colSpan="2"
                        style={{
                            textAlign: 'center',
                            fontFamily: 'Arial',
                            fontSize: '15px',
                            color: '#333333',
                            lineHeight: '24px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold'
                        }}>
                        Authorized Signature
                    </td>
                </tr>
            </table>
        )
    }

    printPdfArea(){
        let _hideDiv = '';
        if(this.state._type == 'PAYMENT-IN' || this.state._type == 'PAYMENT-OUT')
        {
            _hideDiv = 'hide';
        }

        if(this.state.isLoaded == true){
            return(
                <html style={{marginTop: '15px'}}>
                    <head>
                        <title>Recipt</title>
                    </head>
                    <div id="template">
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div id="logo" style={{
                                flex: '1',
                                alignItems: 'center',
                                width: '200px',
                                height: '125px',
                                marginTop: '10px'
                            }} onClick="_captureLogoButton()">
                                <img src={_imageBaseDecode} style={{
                                    maxWidth: '150px',
                                    maxHeight: '110px',
                                    borderRadius: '10px'
                                }}/>
                            </div>
                            <div style={{
                                flex: '1',
                                fontFamily: 'Arial',
                                fontSize: '15px',
                                lineHeight: '20px',
                                textAlign: 'right'
                            }}>
                                <p style={{
                                    fontWeight: 'bold',
                                    margin: '3px 0',
                                    color: 'black',
                                    fontSize: '15px'
                                }}>
                                    Company Name : {this.state.companyName}
                                </p>
                                <p style={{margin: '3px 0', color: 'black', fontSize: '15px'}}>
                                    {(this.state.companyAddress.length > 2) ? ("Company Address : " + this.state.companyAddress) : ''}
                                </p>
                                <p style={{margin: '3px 0', color: 'black', fontSize: '15px'}}>
                                    
                                    {(this.state.companyPhone.length > 2) ? ("Company Phone no. : " + this.state.companyPhone) : ''}
                                </p>
                                <p style={{
                                    margin: '3px 0 10px 0',
                                    color: 'black',
                                    fontSize: '15px'
                                }}>
                                    {(this.state.companyGstin!='') ? ("GSTIN : " + this.state.companyGstin) : ''}
                                </p>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                        <div style={{
                            textAlign: 'center',
                            fontSize: '24px',
                            fontFamily: 'Arial',
                            padding: '10px 0',
                            fontWeight: 'bold',
                            color: '#9f9fdf'
                        }}>
                            {this.state.titleTxt}
                        </div>
                        <div>
                            <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{marginBottom: '40px'}}>
                                <tr>
                                    <td width="49%" valign="top">
                                        <h6 style={{
                                            background: '#9f9fdf',
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                            fontFamily: 'Arial',
                                            margin: '5px 0'
                                        }}>
                                            {this.state.billTxt}
                                        </h6>
                                        <p style={{
                                            fontWeight: 'bold',
                                            fontSize: '15px',
                                            margin: '3px 0',
                                            color: 'black',
                                            marginBottom: '5px'
                                        }}>
                                            {this.state.customerCompanyName}
                                        </p>
                                        <p style={{
                                            fontFamily: 'Arial',
                                            fontSize: '15px',
                                            lineHeight: '20px',
                                            margin: '0 0',
                                            color: 'black',
                                            marginBottom: '5px'
                                        }}>
                                            {this.state.customerAddress}
                                        </p>
                                        <p style={{
                                            fontFamily: 'Arial',
                                            fontSize: '15px',
                                            lineHeight: '20px',
                                            margin: '0 0',
                                            color: 'black'
                                        }}>
                                            Contact No. : {this.state.customerPhone}
                                        </p>
                                    </td>
                                    <td width="2%" valign="top">&nbsp;</td>
                                    <td width="49%" valign="top">
                                        <h6 className={this.state.invoiceRtnHide} style={{
                                            background: '#9f9fdf',
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                            fontFamily: 'Arial',
                                            margin: '5px 0'
                                        }}>
                                            Return No.</h6>
                                        <p className={this.state.invoiceRtnHide} style={{
                                            fontFamily: 'Arial',
                                            fontSize: '15px',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            margin: '0 0',
                                            color: 'black'
                                        }}>
                                            {this.state.invoiceRtnNo}
                                        </p>
                                        <h6 style={{
                                            background: '#9f9fdf',
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                            fontFamily: 'Arial',
                                            margin: '5px 0'
                                        }}>
                                            {this.state._invNo}</h6>
                                        <p style={{
                                            fontFamily: 'Arial',
                                            fontSize: '15px',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            margin: '0 0',
                                            color: 'black'
                                        }}>
                                            {this.state.invoiceNo}
                                        </p>
                                        <h6 style={{
                                            background: '#9f9fdf',
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                            fontFamily: 'Arial',
                                            margin: '5px 0'
                                        }}>Date</h6>
                                        <p style={{
                                            fontFamily: 'Arial',
                                            fontSize: '15px',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            margin: '0 0',
                                            color: 'black'
                                        }}>
                                            {this.state.invoiceDate}
                                        </p>
                                    </td>
                                </tr>
                                <tr className={_hideDiv}>
                                    <td colSpan="3" width="100%">
                                        <table width="100%" cellPadding="0" cellSpacing="0"
                                                style={{marginTop: '10px'}}>
                                            <tr style={{backgroundColor: '#9f9fdf'}}>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    #
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    Item Name
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    HSN/SAC
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    Qty
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    Unit
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    Rate
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    Discount
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    GST
                                                </th>
                                                <th style={{
                                                    color: '#FFFFFF',
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    fontFamily: 'Arial',
                                                    lineHeight: '24px'
                                                }}>
                                                    Amount
                                                </th>
                                            </tr>
                                            {
                                                this.state.items.map((value, index) => {
                                                    let _i = parseInt(index) + 1;
                                                    return (
                                                        <tr>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {_i}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.item_name}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.hsn_sac}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.quantity}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.unitName}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.rate}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.discountAmount}
                                                            </td>
                                                            <td style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {value.gstAmount}
                                                            </td>
                                                            <td colSpan="2" style={{
                                                                fontFamily: 'Arial',
                                                                fontSize: '15px',
                                                                textAlign: 'center',
                                                                color: '#333333',
                                                                lineHeight: '24px',
                                                                borderTop: '1px solid #2929af',
                                                                borderBottom: '1px solid #2929af'
                                                            }}>
                                                                {numberFormat(value.totalAmount)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="40%">
                                        <table cellPadding="0" cellSpacing="0" width="100%">
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    <div>
                                                        <br/>
                                                        Description
                                                        <p style={{
                                                            margin: '3px 0',
                                                            color: 'black',
                                                            fontSize: '15px'
                                                        }}>
                                                            {this.state.description}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    {_extraReceiveDiv}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    {_extraDeleiveryDiv}
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" height="10">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td colSpan="2" width="60%">
                                        {this.getTotalCalculation()}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/* <div style={footer}>
                            <p style={{float:'left'}}>https://vyavsay.in</p>
                            <img src={_imageLogoBaseDecode} style={{float:'right',width: '105px'}}/>
                        </div> */}
                    </div>
                </html>
            )
        }
    }

    render()
    {
        if(this.state.isLoaded == true){
            this.convertImageUrlToBase64(_companyLogo, function(url) {});
            this.convertImageUrlToBase64Logo(global.webUrl+"websiteFilesFolder/img/logo.png", function(url) {});
        }

        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        return(
            <React.Fragment>
                {/* <a className="dropdown-item" href="javascript:void(0)" data-id={this.props.transactionId} onClick={this.clickTogenratePDF.bind(this,this.props.transactionId)}>
                    Download PDF {this.props.transactionId}
                </a> */}

                {(this.props.isChallanPreview) ? <a className="dropdown-item" href="javascript:void(0)" onClick={this.openModalHandler.bind(this,'modalAdjustPDF')}>
                    Preview as Delivery Challan
                </a> : ''}

                <a className="dropdown-item" href="javascript:void(0)" onClick={this.openModalHandler.bind(this,'modalAdjustPDF')}>
                    Download PDF
                </a>
                <a className="dropdown-item" href="javascript:void(0)" onClick={this.openModalHandler.bind(this,'modalAdjustPDF')}>
                    Preview
                </a>
                <a className="dropdown-item" href="javascript:void(0)" onClick={this.openModalHandler.bind(this,'modalAdjustPDF')}>
                    Print
                </a>

                {/* <div className="content">
                    CLick Pdf Button
                    <QuickPDF />
                </div> */}

                
                {/* Modal Edit Party Details Begins */}
                <Modal isOpen={this.state.modalIsOpen_PDF} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustPDF">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Order Preview</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustPDF')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content" style={{background:'#fff',border:'2px solid #cacaca',height: '97%',padding:'10px'}}>
                                    <div className="block-content" id='pdfFormatReceipt'  ref={el => (this.componentRef = el)} >
                                        {this.printPdfArea()}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustparty')}>Close</button>
                                <button type="button" className="btn btn-alt-secondary" onClick={this.clickToGenratePDF.bind(this,this.props.transactionId)}><i className="fa fa-file-pdf-o"></i> Download PDF</button>
                                
                                <ReactToPrint
                                    trigger={() => <button type="button" className="btn btn-alt-secondary"><i className="fa fa-print"></i> View Print</button>}
                                    content={() => this.componentRef}
                                />
                            </div>
                            <div id="global_loader" className={_loader}>
                                <div className="gl-ldr-cld">
                                    <div className="gl-ldr-ctr">
                                        <div className="gl-loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Modal End Edit Party Details*/}
            </React.Fragment>
        )
    }
}
