import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddService from "./quickServiceAdd";
import QuickAddInActiveService from "./quickServiceInactive";
import QuickAddActiveService from "./quickServiceActive";
import QuickAddUniConversion from "./quickUnitConversion";
import QuickAssignUnit from "./quickItemAssignUnit";
import QuickItemShare from "./quickItemEmailShare";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { EmailShareButton } from "react-share";
import { EmailIcon } from "react-share";
import Modal from "react-modal";

import * as XLSX from "xlsx";

import ButtonPdf from "../htmlPdfCreation";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let company_id = "0";
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  company_id = _UserSession.company_id;
}
global.BACK_URL = "";

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 50,
  },

  {
    label: "Name",
    field: "party_name",
    width: 150,
  },
  {
    label: "Date",
    field: "invoice_date",
    width: 100,
  },
  {
    label: "Quantity",
    field: "total_quantity",
    width: 100,
  },
  {
    label: "Price/Unit",
    field: "sale_price",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    width: 100,
  },
  {
    label: "",
    field: "actionextra",
  },
];

let _loader = "glb-ldr-prt active";

export default class serviceListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      modalIsOpen_AJP: false,
      modalIsOpen_AC: false,

      modalIsOpen_InActiveService: false,
      modalIsOpen_ActiveService: false,
      modalIsOpen_ActiveUnitConversion: false,
      modalIsOpen_ActiveAssignUnit: false,
      modalIsOpen_IShare: false,
      activeItem: "N/A",
      activeItemId: "0",
      activeItemQty: "0",
      activeSalePrice: "0",
      filterSearch: true,

      serviceArray: [],
      serviceArrayFilter: [],
      itemArrayTransaction: [],
      businessId: _businessId,
      userId: _signingId,
      company_id: company_id,

      activeFilter: false,
      inActiveFilter: false,
      chkDataFilter: "",

      item_name: "",
      errors: {
        item_name: "",
        chkDataFilter: "",
      },
    };
  }

  async componentDidMount() {
    this.getServiceListData(company_id);
  }

  getTransactionListData(id) {
    let _transArray = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      item_id: id, //this.state.activeItemId
    });

    PostData(global.userItemTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ itemArrayTransaction: _transArray, isLoaded: true });
        } else {
          let _activeStockPrice = 0;
          if (responseJson.response) {
            _transArray = responseJson.response;
            _transArray.map((trans, i) => {
              _activeStockPrice =
                parseFloat(_activeStockPrice) +
                parseFloat(trans.total_quantity) *
                  parseFloat(trans.total_amount);
            });
          }
          this.setState({
            itemArrayTransaction: _transArray,
            isLoaded: true,
            activeStockPrice: _activeStockPrice,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getServiceListData(company_id) {
    let _serviceArray = [];
    if (this.state.userId > 0) {
      const requestData = JSON.stringify({
        company_id: this.state.company_id,
        activeFilter: this.state.activeFilter,
        inActiveFilter: this.state.inActiveFilter,
      });

      PostData(global.userServiceList, requestData, "POST").then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _activeItem,
            _activeItemId,
            _activeItemQty,
            _activeSalePrice = "";

          if (responseJson.response) {
            _serviceArray = responseJson.response;
            _serviceArray.map((itm, i) => {
              if (i == 0) {
                _serviceArray[i].isActiveClick = true;
                _activeItem = itm.name;
                _activeItemId = itm.item_id;
                _activeItemQty = itm.min_stock_quantity;
                _activeSalePrice = itm.sale_price;
              } else {
                _serviceArray[i].isActiveClick = false;
              }
            });
          }
          this.setState({
            serviceArrayFilter: _serviceArray,
            serviceArray: _serviceArray,
            isLoaded: true,
            item_name: _activeItem,
            activeItem: _activeItem,
            activeItemId: _activeItemId,
            activeItemQty: _activeItemQty,
            activeSalePrice: _activeSalePrice,
          });
          if (_activeItemId > 0) {
            this.getTransactionListData(_activeItemId);
          }
        }
      });
    }
  }
  clickToActiveRow(id, txt, qty, sPrice, pPrice, openingStock, pricePerUnit) {
    let _purchasePrice = pPrice > 0 ? pPrice : 0;
    let _salePrice = sPrice > 0 ? sPrice : 0;
    let _oStock = openingStock > 0 ? openingStock : 0;
    let _pricePUnit = pricePerUnit > 0 ? pricePerUnit : 0;
    let _stockValue = _oStock * _pricePUnit;
    this.setState({
      activeItem: txt,
      activeItemId: id,
      activeItemQty: qty,
      activeSalePrice: _salePrice,
      activePurchasePrice: _purchasePrice,
      activeStockPrice: _stockValue,
    });

    if (id > 0) {
      this.getTransactionListData(id);
    }
  }
  clickToDeleteNotifiyItem() {
    confirmAlert({
      title: "Service Alert",
      message:
        "This Service can not be deleted as it already has transactions. Please delete all transactions before deleting the service.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToItemDelete(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: value,
              _type: "Service",
            });
            PostData(global.userItemDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getServiceListData(company_id);
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }
  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  loadServiceListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _serviceDetails = this.state.serviceArrayFilter;
      if (_serviceDetails.length > 0) {
        _serviceDetails.map((value, index) => {
          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeItem == value.name) {
            _activeClass = "activeRow";
          }
          let _deletebtn = (
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              onClick={this.clickToItemDelete.bind(this, value.item_id)}
              style={{ margin: "0px" }}
            >
              Delete
            </a>
          );
          if (value.isTransaction == true) {
            _deletebtn = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.clickToDeleteNotifiyItem.bind(
                  this,
                  value.item_id
                )}
                style={{ margin: "0px" }}
              >
                Delete
              </a>
            );
          }

          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.item_id,
                value.name,
                value.min_stock_quantity,
                value.sale_price,
                value.purchase_price,
                value.opening_stock,
                value.price_per_unit
              )}
            >
              <td>{value.name}</td>
              <td></td>
              <td className="text-center" scope="row">
                <div className="btn-group show" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right min-width-200"
                    aria-labelledby="toolbarDrop"
                    style={{ margin: "0px" }}
                  >
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-service/?auth=" +
                        value.item_id
                      }
                      style={{ margin: "0px" }}
                    >
                      Edit
                    </a>
                    {_deletebtn}
                  </div>
                </div>
              </td>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  loadSaleListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.itemArrayTransaction;
      if (_transDetails.length > 0) {
        _transDetails.map((value, index) => {
          let _type = "&tType=SERVICE";
          let _status = "Paid";
          let actionTrans = "#";
          if (value.payment_paid == "UNPAID") {
            _status = value.payment_paid; //'Partial';
          }
          if (value.payment_paid == "PARTIAL") {
            _status = value.payment_paid; //'Partial';
          }

          if (value.type == "PURCHASE") {
            actionTrans =
              global.webUrl + "business/add-purchase/?auth=" + value.id + _type;
          } else if (value.type == "PURCHASE ORDER") {
            actionTrans =
              global.webUrl +
              "business/add-purchase-order/?auth=" +
              value.id +
              _type;
          } else if (value.type == "DEBIT NOTE") {
            actionTrans =
              global.webUrl +
              "business/add-purchase-debit-note/?auth=" +
              value.id +
              _type;
          } else if (value.type == "PAYMENT-OUT") {
            actionTrans =
              global.webUrl +
              "business/add-purchase-payment-out/?auth=" +
              value.id +
              _type;
          } else if (value.type == "SALE") {
            //_type = "&tType="+value.type;
            actionTrans =
              global.webUrl + "business/add-sale/?auth=" + value.id + _type;
          } else if (value.type == "SALE ORDER") {
            actionTrans =
              global.webUrl +
              "business/add-sale-order/?auth=" +
              value.id +
              _type;
          } else if (value.type == "ESTIMATE") {
            actionTrans =
              global.webUrl +
              "business/add-sale-estimate/?auth=" +
              value.id +
              _type;
          } else if (value.type == "CREDIT NOTE") {
            actionTrans =
              global.webUrl +
              "business/add-sale-credit-note/?auth=" +
              value.id +
              _type;
          } else if (value.type == "PAYMENT-IN") {
            actionTrans =
              global.webUrl +
              "business/add-sale-payment-in/?auth=" +
              value.id +
              _type;
          } else if (value.type == "DELIVERY CHALLAN") {
            actionTrans =
              global.webUrl +
              "business/add-sale-delivery-challan/?auth=" +
              value.id +
              _type;
          }
          let _actionExtra = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                <a className="dropdown-item" href={actionTrans}>
                  View/Edit Details
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToDelete.bind(this, value.id, "Sale")}
                >
                  Delete
                </a>
                {/* isChallanPreview={false} */}
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Download PDF
                </a>
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Preview
                </a>
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Print
                </a>
              </div>
            </div>
          );

          rows.push({
            srNo: value.srNo,
            invoice_date: value.invoice_date,
            invoice_no: value.invoice_no,
            sale_price: numberFormat(value.sale_price),
            total_quantity: value.total_quantity,
            party_name: value.party_name,
            type: value.type,
            status: _status,
            balance: numberFormat(value.balance),
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalAdjustService") {
      this.setState({ modalIsOpen_AJP: true });
    } else if (popId == "modalAddService") {
      this.setState({ modalIsOpen_AC: true });
    } else if (popId == "modalUploadInActiveService") {
      this.setState({ modalIsOpen_InActiveService: true });
    } else if (popId == "modalUploadActiveService") {
      this.setState({ modalIsOpen_ActiveService: true });
    } else if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: true });
    } else if (popId == "modalUploadAssignUnit") {
      this.setState({
        modalIsOpen_ActiveAssignUnit: true,
        dataType: "SERVICE",
      });
    }
  }
  closeModalHandler = (popId) => {
    if (popId == "modalAdjustService") {
      this.setState({ modalIsOpen_AJP: false });
    } else if (popId == "modalAddService") {
      this.setState({ modalIsOpen_AC: false });
    } else if (popId == "modalUploadInActiveService") {
      this.setState({ modalIsOpen_InActiveService: false });
    } else if (popId == "modalUploadActiveService") {
      this.setState({ modalIsOpen_ActiveService: false });
    } else if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: false });
    } else if (popId == "modalUploadAssignUnit") {
      this.setState({ modalIsOpen_ActiveAssignUnit: false });
    }
  };
  /*End Modal open close*/

  openModalHandlerAdjust(popId, adjustId) {
    if (popId == "modalIShare") {
      this.setState({ modalIsOpen_IShare: true, itemId: adjustId });
    }
  }
  closeModalHandlerAdjust = (popId) => {
    if (popId == "modalIShare") {
      this.setState({ modalIsOpen_IShare: false, itemId: 0 });
    }
  };

  /* Adjust Party */
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "item_name":
        errors.item_name =
          value.length < 3 ? "*Service Name must be 3 characters long!" : "";
        this.setState({ item_name: value });
        break;
    }
  };

  clickToUpdateItemInfo = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.item_name == "") {
      _isError = true;
      errors["item_name"] = "*Please Enter service Name";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.activeItemId > 0) {
        const requestData = JSON.stringify({
          itemId: this.state.activeItemId,
          businessId: this.state.businessId,
          company_id: this.state.company_id,
          item_name: this.state.item_name,
        });

        PostData(global.userEditItemInfo, requestData, "POST").then(
          (result) => {
            if (result.success == true) {
              sendNotification("Success Message 👍", result.message, "success");
              this.closeModalHandler("modalAdjustService");
              this.loadServiceListing();
            } else {
              sendNotification("Error Message 😓", result.message, "danger");
              let errors = this.state.errors;
              errors["item_name"] = result.message; //'Please enter valid phone no.';
              this.setState({
                errors: errors,
              });
              return;
            }
          }
        );
      }
    }
  };
  /* End Here Adjust Party*/

  /* party Filter Code */
  onServiceFilterName = (e) => {
    const data = this.state.serviceArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ serviceArrayFilter: filtered });
    this.loadServiceListing();
  };
  /* End party Filter Code */

  /*Begin Filter Data */
  onFilterChange = (e) => {
    let activeValue = e.target.checked;
    this.setState({ activeFilter: activeValue });
  };
  onFilterInActiveChange = (e) => {
    let inActiveValue = e.target.checked;
    this.setState({ inActiveFilter: inActiveValue });
  };
  clickDataFilter = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    let activeFilter = this.state.activeFilter;
    let inActiveFilter = this.state.inActiveFilter;
    if (activeFilter == false && inActiveFilter == false) {
      _isError = true;
      errors["chkDataFilter"] = "";
      this.setState({ errors: errors });
    } else {
      errors["chkDataFilter"] = "";
    }
    if (_isError == false) {
      this.addFilterDataMethodCall();
    }
  };

  addFilterDataMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      activeFilter: this.state.activeFilter,
      inActiveFilter: this.state.inActiveFilter,
    });
    PostData(global.userServiceList, requestData, "POST").then((result) => {
      let responseJson = result;
      if (responseJson.success == false) {
        //this.setState({isLoaded:true});
        this.setState({
          serviceArrayFilter: [],
          serviceArray: [],
          isLoaded: true,
          item_name: "",
          activeItem: 0,
          activeItemId: 0,
          activeItemQty: 0,
          activeSalePrice: 0,
        });
        this.getTransactionListData(0);
      } else {
        let _serviceArray = [];
        let _activeItem,
          _activeItemId,
          _activeItemQty,
          _activeSalePrice = "";

        if (responseJson.response) {
          _serviceArray = responseJson.response;
          _serviceArray.map((itm, i) => {
            if (i == 0) {
              _serviceArray[i].isActiveClick = true;
              _activeItem = itm.name;
              _activeItemId = itm.item_id;
              _activeItemQty = itm.min_stock_quantity;
              _activeSalePrice = itm.sale_price;
            } else {
              _serviceArray[i].isActiveClick = false;
            }
          });
        }
        this.setState({
          serviceArrayFilter: _serviceArray,
          serviceArray: _serviceArray,
          isLoaded: true,
          item_name: _activeItem,
          activeItem: _activeItem,
          activeItemId: _activeItemId,
          activeItemQty: _activeItemQty,
          activeSalePrice: _activeSalePrice,
        });
        if (_activeItemId > 0) {
          this.getTransactionListData(_activeItemId);
        }
      }
    });
  }

  uncheckAll = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    errors["chkDataFilter"] = "";
    let _isError = false;
    this.setState({
      errors: errors,
      activeFilter: false,
      inActiveFilter: false,
    });
    document
      .querySelectorAll('input[type="checkbox"]')
      .forEach((el) => (el.checked = false));
    this.state.activeFilter = false;
    this.state.inActiveFilter = false;
    this.addFilterDataMethodCall();
  };
  /*Filter Data End*/

  setFilterSearch = () => {
    this.setState({ filterSearch: !this.state.filterSearch });
  };
  setFilterSearchCancel = () => {
    this.setState({
      filterSearch: !this.state.filterSearch,
      serviceArrayFilter: this.state.serviceArray,
    });
    this.loadServiceListing();
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Service List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/add-service/"}
            >
              Add Service
            </a>
            <span className="breadcrumb-item active">Manage Service List</span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    {this.state.filterSearch ? (
                      <div className="mb-10">
                        <button
                          type="button"
                          data-toggle="layout"
                          data-action="header_search_on"
                          className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                          onClick={this.setFilterSearch}
                        >
                          <i className="fa fa-search"></i>
                        </button>

                        <div
                          className="btn-group pull-right"
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.openModalHandler.bind(
                              this,
                              "modalAddService"
                            )}
                          >
                            <i className="fa fa-plus mr-5"></i>{" "}
                            <b>Add Service</b>
                          </button>
                          <div className="btn-group show" role="group">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              id="toolbarDrop"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                            ></button>
                            <div
                              className="dropdown-menu dropdown-menu-right min-width-200"
                              aria-labelledby="toolbarDrop"
                              style={{ margin: "0px" }}
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadInActiveService"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-ban "></i>Mark Service as
                                Inactive
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadActiveService"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-ban "></i>Mark Service as
                                Active
                              </a>

                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadAssignUnit"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-ban "></i>Assign Units
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div id="page-header-search" className="show mb-10">
                        <div className="content-header content-header-fullrow">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-toggle="layout"
                                data-action="header_search_off"
                                onClick={this.setFilterSearchCancel}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                            <input
                              type="text"
                              onChange={this.onServiceFilterName}
                              className="form-control"
                              placeholder="Search here.."
                              id="page-header-search-input"
                              name="page-header-search-input"
                              autocomplete="off"
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th className="text-center">Service</th>
                          <th style={{ padding: "0px 70px" }}>
                            <div
                              className="pull-right btn-group show"
                              role="group"
                            >
                              <a
                                type="button"
                                className="dropdown-toggle text-danger"
                                id="toolbarDrop"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <i className="fa fa-filter"></i>
                              </a>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="toolbarDrop"
                                style={{ margin: "0px", width: "160px" }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="activeItem"
                                    name="activeItem"
                                    value="1"
                                    onChange={this.onFilterChange}
                                  />
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "13px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Active
                                  </span>
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="inActiveItem"
                                    name="inActiveItem"
                                    value="1"
                                    onChange={this.onFilterInActiveChange}
                                  />
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "13px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    InActive
                                  </span>
                                </div>
                                <span className="vs_error_txt">
                                  {errors.chkDataFilter.length > 0 && (
                                    <span className="error">
                                      {errors.chkDataFilter}
                                    </span>
                                  )}
                                </span>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-primary mt-10 mr-10"
                                  style={{ margin: "0px" }}
                                  onClick={this.uncheckAll}
                                >
                                  Clear All
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-info mt-10"
                                  style={{ margin: "0px" }}
                                  onClick={this.clickDataFilter}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </th>
                          <th style={{ width: "50px" }}></th>
                        </tr>
                      </thead>
                      <tbody id="sideTable">{this.loadServiceListing()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    <div className="col-lg-12">
                      <div>
                        <p className="text-muted">
                          <b>{this.state.activeItem}</b>{" "}
                          <i className="si si-pin"></i>
                          {this.state.activeItemId > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary mr-5 mb-5"
                              onClick={this.openModalHandlerAdjust.bind(
                                this,
                                "modalIShare",
                                this.state.activeItemId
                              )}
                            >
                              <i className="fa fa-share "></i>
                            </button>
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="text-muted">
                          <span>
                            Sale Price :{" "}
                            {numberFormat(this.state.activeSalePrice)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="block-content">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    span
                    pagingTop
                    searchTop
                    searchBottom={false}
                    barReverse
                    data={this.loadSaleListing()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Party Add Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AC}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddService"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Service</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddService"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickAddService
                      isMainPage={false}
                      isRedirectTo={"business/manage-service/"}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this, "modalAddService")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Party Add*/}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AJP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustService"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Service Addjustment</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustService"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <form
                      method="post"
                      id="form-login"
                      className="webForm px-30"
                    >
                      <h3
                        className="content-heading"
                        style={{ paddingTop: "6px" }}
                      >
                        <i class="fa fa-angle-right"></i> Personal Details{" "}
                      </h3>
                      <div className="form-group row">
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              required="required"
                              className="form-control"
                              id="item_name"
                              name="item_name"
                              value={this.state.item_name}
                              placeholder="Enter Item Name"
                              onChange={this.handleChange}
                              onWheel={() => document.activeElement.blur()}
                            />
                            <label htmlFor="login-username">Service Name</label>
                            <span className="vs_error_txt">
                              {errors.item_name.length > 0 && (
                                <span className="error">
                                  {errors.item_name}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="claerfix"></div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-alt-primary"
                            disabled={isDisabled}
                            onClick={this.clickToUpdateItemInfo}
                          >
                            <i className="fa fa-check mr-5"></i> Save Service
                            Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustService"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}

        {/* Modal Add  Service Inactive Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_InActiveService}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadInActiveService"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Service Inactive</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadInActiveService"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddInActiveService
                    isRedirectTo={"busines/manage-service/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadInActiveService"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Service Inactive Details*/}

        {/* Modal Add  Service Active Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveService}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadActiveService"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Service Active</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadActiveService"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddActiveService
                    isRedirectTo={"business/manage-service/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadActiveService"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Service Active Details*/}

        {/* Modal Add  Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversion}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversion"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Unit Conversion</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversion"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddUniConversion
                    isRedirectTo={"business/manage-items/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversion"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Unit Conversion Details*/}

        {/* Modal Assign Unit Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveAssignUnit}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadAssignUnit"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Assign Units</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadAssignUnit"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAssignUnit
                    dataType={this.state.dataType}
                    isRedirectTo={"business/manage-service/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadAssignUnit"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Assign Unit Details*/}

        {/* Modal Item Share Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_IShare}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandlerAdjust}
          id="modalIShare"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Share Service</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerAdjust.bind(
                        this,
                        "modalIShare"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickItemShare
                      isMainPage={false}
                      isRedirectTo={"business/manage-items/"}
                      itemName={this.state.activeItem}
                      activeItemId={this.state.activeItemId}
                      sendType="Service"
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerAdjust.bind(
                    this,
                    "modalIShare"
                  )}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Share End */}
      </React.Fragment>
    );
  }
}
