import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

global.BACK_URL = 'Business';

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Business Name',
        },
    },
    {
        label: 'Business Name',
        field: 'business_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Business Name',
        },
    },
    {
        label: 'Business Email',
        field: 'business_email',
        width: 150,
    },
    {
        label: 'Business Contact No.',
        field: 'business_contact',
        width: 30,
    },
    // {
    //     label: 'Logo',
    //     field: 'logo_path',
    //     width: 100,
    // },
    {
        label: 'Password',
        field: 'password',
        width: 150,
    },
    {
        label: 'Added',
        field: 'added_on',
        width: 150,
    },
    {
        label: 'Status',
        field: 'status',
        width: 100,
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class businessListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : true,
            businessArray :[],
        }
    }

    componentDidMount ()
    {
        console.log(this.props.match);
        this.getBUsinessListData();
    }

    getBUsinessListData(){
        let _businessArray = [];
        let _url_GetData = global.adminBusinessList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _businessArray = responseJson.response;
                }
                this.setState({businessArray:_businessArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    clickToDelete(value)
    {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        businessId: value
                    });
                    PostData(global.adminBusinessDelete, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getBUsinessListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }
    clickToStatus(value,_status)
    {
        confirmAlert({
            title: 'Confirm to manage status',
            message: 'Are you sure you want to change the status?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        businessId: value,
                        status:_status
                    });
                    PostData(global.adminBusinessStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getBUsinessListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    loadOrderListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _businessDetails = this.state.businessArray;
            let dataList = [];
            let rows = [];

            if(_businessDetails.length > 0)
            {                          
                _businessDetails.map((bussDatas, i) =>
                {
                    const row = bussDatas;
                    let _status = 1;
                    let status = <span className={`badge badge-danger`}>Un-approved</span>;
                    if (row.status == '1') {
                        _status = 0;
                        status = <span className={`badge badge-success`}>Approved</span>;
                    }
        
                    let _dtt = row.added_on;
                    var options = {year: 'numeric', month: 'long', day: 'numeric' };
                    
                    let _logo = 'N/A';
                    if(row.logo_path!='N/A'){
                        _logo = <a href={`${row.logo_path}`} target="_blank"><img src={`${row.logo_path}`} style={{width:20}}/></a>
                    }

                    rows.push({
                        business_name: row.business_name,
                        business_email: row.business_email,
                        business_contact: row.country_code+'-'+row.business_contact,
                        password: row.password,
                        //logo_path: _logo,
                        srNo: row.srNo,
                        status: (status),
                        added_on: new Intl.DateTimeFormat('en-US', options).format(_dtt),
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/add-business/?auth='+row.business_id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.business_id} onClick={this.clickToStatus.bind(this,row.business_id,_status)}>
                                        <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>Manage Status
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.business_id} onClick={this.clickToDelete.bind(this,row.business_id)}>
                                        <i className="si si-trash mr-5 text-danger"></i>Delete Details
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Business List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/add-business/"}>Add New Business</a>
                        <span className="breadcrumb-item active">Manage Business List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">List of Business</h3>
                        </div>
                        <div className="block-content">
                            <MDBDataTableV5 
                            hover
                            entriesOptions={[25, 50, 100]}
                            entries={25}
                            pagesAmount={4}
                            span
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            data={this.loadOrderListing()} />
                        </div>
                    </div>    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div class="gl-ldr-cld">
                        <div class="gl-ldr-ctr">
                            <div class="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}