import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import './loanstatment.css';
import {Link} from "react-router-dom";
import html2PDF from "jspdf-html2canvas";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

let _UserSession = localStorage.getItem('vs_UserSession');
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';

if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _companyId = _companySession.company_id;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'invoice_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Date',
        },
    },
    {
        label: 'Txn Type',
        field: 'txn_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Txn Type',
        },
    },
    {
        label: 'Amount',
        field: 'amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Amount',
        },
    },
    {
        label: 'Ending Bal',
        field: 'end_bal',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Ending Bal',
        },
    }
];

let _loader = 'glb-ldr-prt active';

export default class userSaleListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add Sale',
            isLoaded : true,
            transactionArray :[],
            transactionArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            name :  null,
            opening_balance: '',
            
            loanAccountArray : [],
            paidAmount:0,
            unpaidAmount:0,
            total:0,
            loanId :0,

            startDate : moment().format('MMMM D, YYYY'),
            endDate : moment().format('MMMM D, YYYY'),
            dateLabel : '',
            errors: {
                name : '',
            }
        }
    }

    async componentDidMount ()
    {   
        
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let loanId = _handleParam[1] ? _handleParam[1] : 0;

        this.getSaleListData();
        this.getLoanAccListData();

        this.setState({loanId:loanId});
    }

    getLoanAccListData(){
        let _loanAccountArray = [];
        
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let loanId = _handleParam[1] ? _handleParam[1] : 0;

        let _url_GetData = global.userLoanAccountList+'?company_id='+this.state.company_id;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            console.log('responseJson', JSON.stringify(responseJson))
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _loanAccountArray = responseJson.response;
                }
                this.setState({loanAccountArray:_loanAccountArray,isLoaded:true,loanId:loanId});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    getSaleListData(){
        let _transArray = [];
        let _opening_balance = 0;
        let _accountName = '';
        let _balanceDue = 0;
        let _totalPrincipal = 0;
        let _totalInterest = 0;
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let loanId = _handleParam[1] ? _handleParam[1] : 0;

        const requestData = JSON.stringify({          
            loan_id:loanId,
            isDateFilter:true,
            start_date : this.state.startDate,
            end_date : this.state.endDate,
        });

        PostData(global.userIncreaseLoanStatementList, requestData,'POST').then((result) => {
            let responseJson = result;

            if(responseJson.success == false)
            {  
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,opening_balance:_opening_balance,accountName:_accountName,balanceDue:_balanceDue,totalPrincipal:_totalPrincipal,totalInterest:_totalInterest});
            }
            else
            {
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    _accountName = responseJson.accountName;
                    _balanceDue = parseFloat(responseJson.balanceDue);
                    _totalPrincipal = parseFloat(responseJson.totalPrincipal);
                    _totalInterest = parseFloat(responseJson.totalInterest);
                    _opening_balance = parseFloat(responseJson.opening_balance);

                }
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,opening_balance:_opening_balance,accountName:_accountName,balanceDue:_balanceDue,totalPrincipal:_totalPrincipal,totalInterest:_totalInterest});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    async exportPdf() {

        let _transDetails = this.state.transactionArrayFilter;
        if (_transDetails.length > 0) {
            let _currentDate = moment().format("MM-DD-YYYY-HH-mm-GG");
            //const printArea = document.getElementById("pdfFormatReceipt");
            const printArea = document.getElementById("pdfFormatReceipt");

            printArea.style.display = 'block';

            const pdf = await html2PDF(printArea, {
                html2canvas: {
                    scrollX: -window.scrollX,
                    scrollY: -window.scrollY,
                    windowWidth: document.documentElement.offsetWidth,
                    windowHeight: document.documentElement.offsetHeight,
                },
                imageType: "image/png",
                output: 'loan_statement_'+_currentDate + ".pdf",
            });
            printArea.style.display = 'none';

        }
    }
    renderPdf(){
        let _transDetails = this.state.transactionArrayFilter;
        let loanAccountArray = this.state.loanAccountArray;
        if(_transDetails.length === 0 || loanAccountArray.length === 0){
            return (
                <div></div>
            )
        }
        let account_name = 'N/A';
        let account_number = 'N/A';
        if(loanAccountArray.length > 0){
            let loanId = this.state.loanId;
            let activeLoanAccount = loanAccountArray.filter((data) => parseInt(data.loan_id) === parseInt(loanId));
            account_name = activeLoanAccount.length > 0 ? activeLoanAccount[0].account_name : 'NA';
            account_number = activeLoanAccount.length > 0 ? activeLoanAccount[0].account_number : 'NA';
        }

        return(
            <html>

            <body className={'bodyBorder'}>
            <p style={{fontSize: '18px', textAlign: 'center', marginBottom: '30px'}}><b><u>Loan Statement</u></b></p>
            <p style={{fontSize: '16px'}}><b>Duration: From {this.state.startDate} to {this.state.endDate}</b></p>
            <p style={{fontSize: '16px'}}><b>Firm: All firms</b></p>
            <p style={{fontSize: '16px'}}><b>Loan Account: {account_name}</b></p>
            <p style={{fontSize: '16px'}}><b>Account Number: {account_number}</b></p>
            <br/>
            <table className={'loanTable'} cellPadding="0" cellSpacing="0" border="0" width="100%">
                <tr>
                    <th className={'th'} width="15%">
                        <p><b>Date</b></p></th>
                    <th className={'th'} width="40%">
                        <p><b>Type</b></p></th>
                    <th className={'th'} width="20%">
                        <p><b>Amount</b></p></th>
                    <th className={'th'} width="25%">
                        <p><b>Ending Balance</b></p></th>
                </tr>

                {
                    _transDetails.map((data) => {
                        return(
                            <tr>
                                <td className={'tdLeft'} width="15%">
                                    <p>{moment(data.added_on).format("DD MMM YYYY")}</p></td>
                                <td className={'tdLeft'} width="40%">
                                    <p>{data.type}</p></td>
                                <td className={'tdLeft'} width="20%">
                                    <p>&#8377; {data.total_amount}</p></td>
                                <td className={'tdLeft'} width="25%">
                                    <p>&#8377; {data.end_balance}</p></td>
                            </tr>
                        )
                    })
                }

                <tr>
                    <td colSpan="4" width="100%" className={'bottom_left_td'}>
                        <p><b>Loan Statement Summary</b></p>
                    </td>
                </tr>

                <tr>
                    <td colSpan="2" width="50%" className={'bottom_left_td'}>
                        <p>Opening Balance: &#8377; {this.state.opening_balance} </p>
                    </td>
                    <td colSpan="2" width="50%" className={'bottom_right_td'}>
                        <p>Total Principal Paid: &#8377; {this.state.totalPrincipal}</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" width="50%" className={'bottom_left_td'}>
                        <p>Balance Due : &#8377; {this.state.balanceDue} </p>
                    </td>
                    <td colSpan="2" width="50%" className={'bottom_right_td'}>
                        <p>Total Interest Paid: &#8377; {this.state.totalInterest}</p>
                    </td>
                </tr>
            </table>
            </body>
            </html>
        );
    }
    renderXls(){
        let _transDetails = this.state.transactionArrayFilter;
        let loanAccountArray = this.state.loanAccountArray;
        if(_transDetails.length === 0 || loanAccountArray.length === 0){
            return (
                <div></div>
            )
        }
        let account_name = 'N/A';
        let account_number = 'N/A';
        if(loanAccountArray.length > 0){
            let loanId = this.state.loanId;
            let activeLoanAccount = loanAccountArray.filter((data) => parseInt(data.loan_id) === parseInt(loanId));
            account_name = activeLoanAccount.length > 0 ? activeLoanAccount[0].account_name : 'NA';
            account_number = activeLoanAccount.length > 0 ? activeLoanAccount[0].account_number : 'NA';
        }
        return(
            <table className={'loanTable'}  id={'exportxls'} style={{display: 'none'}}>
                <table className={'loanTable'} cellPadding="0" cellSpacing="0" border="0" width="100%">
                    <tr>
                        <td className="one" width="100%">
                            Loan Statementss
                        </td>
                    </tr>
                    <tr>
                        <td className="two" width="100%">
                            Duration: From {this.state.startDate} to {this.state.endDate}
                        </td>
                    </tr>
                    <tr>
                        <td className="two" width="100%">
                            Firm: All firms
                        </td>
                    </tr>
                    <tr>
                        <td className="two" width="100%">
                            Loan Account: {account_name}
                        </td>
                    </tr>
                    <tr>
                        <td className="two" width="100%">
                            Account Number: {account_number}
                        </td>
                    </tr>
                </table>
                <table className={'loanTable'} cellPadding="0" cellSpacing="0" border="0" width="100%">
                    <tr>
                        <th className={'th'} width="15%">
                            <p><b>Date</b></p></th>
                        <th className={'th'} width="40%">
                            <p><b>Type</b></p></th>
                        <th className={'th'} width="20%">
                            <p><b>Amount</b></p></th>
                        <th className={'th'} width="25%">
                            <p><b>Ending Balance</b></p></th>
                    </tr>

                    {
                        _transDetails.map((data) => {
                            return(
                                <tr>
                                    <td className={'tdLeft'} width="15%">
                                        <p>{moment(data.added_on).format("DD MMM YYYY")}</p></td>
                                    <td className={'tdLeft'} width="40%">
                                        <p>{data.type}</p></td>
                                    <td className={'tdLeft'} width="20%">
                                        <p>&#8377; {data.total_amount}</p></td>
                                    <td className={'tdLeft'} width="25%">
                                        <p>&#8377; {data.end_balance}</p></td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan="4" width="100%" className={'bottom_left_td'}>
                            <p><b>Loan Statement Summary</b></p>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" width="50%" className={'bottom_left_td'}>
                            <p>Opening Balance: &#8377; {this.state.opening_balance} </p>
                        </td>
                        <td colSpan="2" width="50%" className={'bottom_right_td'}>
                            <p>Total Principal Paid: &#8377; {this.state.totalPrincipal}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" width="50%" className={'bottom_left_td'}>
                            <p>Balance Due : &#8377; {this.state.balanceDue} </p>
                        </td>
                        <td colSpan="2" width="50%" className={'bottom_right_td'}>
                            <p>Total Interest Paid: &#8377; {this.state.totalInterest}</p>
                        </td>
                    </tr>
                </table>
            </table>
        )
    }
    loadTranscListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.transactionArrayFilter;
            if(_transDetails.length > 0){
                let _i = 0;
                _transDetails.map((value,index) => {
                    _i++;
                    rows.push({
                        srNo: _i,//value.srNo,
                        invoice_date: value.added_on,
                        txn_type:value.type,
                        amount: numberFormat(value.amount),
                        end_bal: numberFormat(value.end_balance)
                    });
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }

    handleCallBackDate = (start, end) => {
        this.setState({startDate:start.format('MMMM D, YYYY'),endDate:end.format('MMMM D, YYYY')});
        this.getSaleListData();
    };

    loanAccChange(e){   
        window.location.href = global.webUrl+'business/manage-loan-statement/?auth='+e.target.value;
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Loan Statement<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"business/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"business/manage-loan-accounts/"}>Loan Account List</a>                        
                        <span className="breadcrumb-item active">Loan Statement </span>
                    </nav>
                    
                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <div className="row items-push">
                                            <div className="row col-lg-12">
                                                <div className="col-lg-8">
                                                    <span>Filter : </span>
                                                    <DateRangePicker
                                                        initialSettings={{ 
                                                            startDate: this.state.startDate, 
                                                            endDate: this.state.endDate,
                                                            locale: {
                                                                format: 'MMMM D, YYYY',
                                                            },
                                                            ranges: {
                                                                Today: [moment().toDate(), moment().toDate()],
                                                                Yesterday: [
                                                                moment().subtract(1, 'days').toDate(),
                                                                moment().subtract(1, 'days').toDate(),
                                                                ],
                                                                'Last 7 Days': [
                                                                moment().subtract(6, 'days').toDate(),
                                                                moment().toDate(),
                                                                ],
                                                                'Last 30 Days': [
                                                                moment().subtract(29, 'days').toDate(),
                                                                moment().toDate(),
                                                                ],
                                                                'This Month': [
                                                                moment().startOf('month').toDate(),
                                                                moment().endOf('month').toDate(),
                                                                ],
                                                                'Last Month': [
                                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                                                ],
                                                            }
                                                        }}
                                                        onCallback={this.handleCallBackDate}
                                                    >
                                                    <input type="text" className="form-control col-6" style={{display: 'inline-block'}}/>
                                                    </DateRangePicker>
                                                </div>
                                                
                                                <div className="col-lg-4">
                                                    
                                                        <span>Select Account : </span>
                                                        <select className="form-control" style={{width: '215px',display: 'inline-block'}} id="loanAccount" name="loanAccount" required="required" onChange={this.loanAccChange}>
                                                            {
                                                                this.state.loanAccountArray.map((loanAcc,index) => {
                                                                    let _sel = '';
                                                                     if(this.state.loanId == loanAcc.loan_id){_sel = 'selected';}
                                                                    return(            
                                                                    <option key={index} value={loanAcc.loan_id} selected={_sel}>{loanAcc.account_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                                                               
                                                </div>       
                                                <br />
                                            </div>
                                            <div className="col-lg-12">
                                                <p className="mb-10" style={{fontSize: '15px'}}>
                                                    <b>Loan Accounts : {this.state.accountName} </b><br/>
                                                    Opening Balance : {numberFormat(this.state.opening_balance)}<br/>
                                                    Balance Due : {numberFormat(this.state.balanceDue)}<br/>
                                                    Total Principal Paid : {numberFormat(this.state.totalPrincipal)}<br/>
                                                    Total Interest Paid : {numberFormat(this.state.totalInterest)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>

                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content row">
                                        <h2 className="block-title ml-10" style={{display : 'inline-block'}}>Transactions</h2>
                                        {
                                            this.state.transactionArrayFilter.length > 0 &&
                                            <>
                                                <ReactHTMLTableToExcel
                                                    className="btn btn-alt-secondary"
                                                    table="exportxls"
                                                    filename="loanstatement"
                                                    sheet="sheet"
                                                    buttonText="Download as XLS"
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-alt-secondary"
                                                    style={{marginLeft: 10}}
                                                    onClick={() => this.exportPdf()}
                                                >
                                                    <i className="fa fa-file-pdf-o"></i> Download PDF
                                                </button>
                                                <div className="block-content"
                                                     style={{display: 'none', position: 'absolute', marginLeft: 10000}}
                                                     id={'pdfFormatReceipt'}>
                                                    {this.renderPdf()}

                                                </div>
                                                {this.renderXls()}
                                                </>
                                        }
                                    </div>
                                    <div className="block-content" id="tableData">
                                        <MDBDataTableV5 
                                        hover
                                        entriesOptions={[25, 50, 100]}
                                        entries={25}
                                        pagesAmount={4}
                                        span
                                        pagingTop
                                        searchTop
                                        searchBottom={false}
                                        barReverse
                                        data={this.loadTranscListing()}
                                        />
                                    </div>
                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )    
    }
}