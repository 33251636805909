import { store } from 'react-notifications-component';

export function sendNotification(_title,_message,_color){
    store.addNotification({
        title: _title,
        message: _message,
        type: _color,
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
    });
}