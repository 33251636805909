import React,{Component} from 'react';
import {PostData} from '../service/postData';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class featuresPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            blogArray : []
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    render(){
        
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Vyavsay Features</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="sec5">
                            <div className="container">
                                <div className="row rowE">
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-file-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Send Estimate & Quotations</h4>
                                            <p>
                                                Using this free billing software, you can easily create quotations, estimates as well as accurate GST invoice.With the use of vyavsay billing software with GST, it is quite easy to create instant estimates along with
                                                quotes.
                                            </p>
                                            <div>
                                                <span id="text4">
                                                    Now you have a better option for simply sharing the detailed costs along with the taxes for customers. Within a few clicks, you can convert your estimates into bills from vyavsay software for billing. <br />
                                                    <br />
                                                    vyavsay free billing Software offers the business a complete option for easily saving more time in getting instant quotes. We are sure you would like to manage your business with higher productivity and in a
                                                    professional way for your valued customers to come back again. For that, choosing an advanced Software for GST billing would be one of the significant things you can do. Now you can easily use the GST software
                                                    for billing and accounting! The GST Billing Software helps in sending your Quotations online (WhatsApp, email or SMS)
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle4">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-weight"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Track Orders</h4>
                                            <p>
                                                With using this advanced GST Software for Billing, it is easier for creating as well as tracking the sales or purchase orders. With the use of this GST Accounting and Billing Software,
                                            </p>
                                            <div>
                                                <span id="text5">
                                                    it is quite simple to extensively generate the bills within a few minutes. This is quite helpful for fulfilling the orders quickly and ultimately increasing customer satisfaction to a great extent. <br />
                                                    <br />
                                                    vyavsay free Billing Software gives you multiple options on your sales order. It is also easier to improve your purchase/sale order formats using this App as vyavsay App provides multiple formats. When you want
                                                    to easily save your time on your business transaction and add data to your accounts, then using this GST invoicing software is one of the best choices. It definitely improves your business reach to the next level
                                                    while making the business account instantly. GST Invoicing Software is a perfect option for gaining more features and advantages.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle5">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-list-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Choose Themes</h4>
                                            <p>
                                                Maintaining and sharing professional Invoice to your clients would definitely improve your Brand's Identity. In the GST Billing App, it is easier to
                                            </p>
                                            <div>
                                                <span id="text6">
                                                    choose the best multiple formats that also include tally invoice themes and many others. With the use of this GST invoice software, you can easily improve the look of your invoice and have it neatly prepared for
                                                    your client. Business bills generated beautifully are also considered as the better choice for easily impressing your clients. The Billing App for GST is the best option for your accounting, inventory and is very
                                                    easy to handle. Choose from the best GST invoice formats along with the extensive preferred invoice format. <br />
                                                    <br />
                                                    Most businesses choose this free billing software for easily improving their business scale professionally. With the use of this free billing App, it is quite an efficient option for easily gaining the highest
                                                    standard to the maximum extent possible. GST Invoicing Software is a suitable option for all the commercials retails, pharmaceutical businesses, gyms, restaurants, and many other businesses. Since there is a huge
                                                    demand for the best reliable GST solutions among the business owners, this free GST Invoicing App is considered as the most efficient option. The GST system has been introduced for simplifying indirect taxation.
                                                    It also mainly ensures the ease of doing business with much better transparency. GST Invoicing Software is one of the highly advanced robust IT infrastructures suitable for your business to aim at achieving
                                                    better compliances easily.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle6">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-wallet"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Record Expenses</h4>
                                            <p>
                                                Authorized GST Billing software is mainly suitable for your business to run smoothly. It would also give you a better option for extensively tracking the billing as well as account needs.
                                            </p>
                                            <div>
                                                <span id="text7">
                                                    The software allows the business to easily keep track of their business in a more hassle-free way. GST Invoicing Software mainly saves more time even during the tax filing. <br />
                                                    <br />
                                                    Tracking or recording the expenses in the business is most important for accounting and tax filing. With the use of the GST software, it is quite easier to track the money that is spent as well as creates an
                                                    accurate report. It is quite an efficient option for recording expenses, and businesses could easily optimize the business expenditure by saving more money in the process. It is a mainly suitable option for
                                                    growing the business with free GST software for billing.
                                                    <br />
                                                    <br />
                                                    The free GST billing software is quite an efficient option for easily getting the accurate GST bill format. It is also helpful for claiming the complete input tax credits to the extent. By thoroughly recording
                                                    the expenses using GST software, the business could easily optimize the expenditure of business and save more money in the process. Moreover, it would also be helpful for saving time on the tax filing. This free
                                                    billing software would definitely give you an adequate option for saving all the data accurately.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle7">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-sync"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Receivables & Payables</h4>
                                            <p>
                                                Advanced free billing and accounting software lets the user easily keep every detail and completed cashbook. Now you have the better way of keeping your transaction details safer.
                                            </p>
                                            <div>
                                                <span id="text8">
                                                    Free GST Invoicing Software allows easily keeping track of the complete finance and also keeping the focus on the inventory along the billing section. <br />
                                                    <br />
                                                    Can track the money you ‘have to receive’ and the money you ‘need to pay’ using vyavsay app. Find who hasn’t paid you yet. Set payment reminders to collect payment from customers on time. The GST Billing and
                                                    invoicing software is a suitable option for the business to perform the most automated calculations. Now you have the better option to simply install the GST billing software on your mobile for free.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle8">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-truck-moving"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Delivery Challan</h4>
                                            <p>
                                                Get acknowledgement upon delivery with “Delivery Challan” of vyavsay. Create delivery challans and attach them with your consignment using this free GST invoicing App.
                                            </p>
                                            <div>
                                                <span id="text9">
                                                    Easily ensures your goods have reached customers safely. <br />
                                                    <br />
                                                    With the use of this ultimate free GST Invoicing Software, it is quite an efficient option for saving more time as you get instant acknowledgement. This process makes the business run smoothly and is helpful for
                                                    the customers to get the consignment safely. Converting the delivery challans into bills is also quite an efficient option. This would definitely give the complete way of saving more time.
                                                    <br />
                                                    <br />
                                                    Maintain all the records of delivery acknowledgment with the GST billing & Accounting Software. It is an easier way of running the business with major productivity goals. When you like to convert your delivery
                                                    challans, then using the free Billing App would be one of the best choices.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle9">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>

                                <div className="row rowE">
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-file-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Bank Accounts</h4>
                                            <p>
                                                Businesses can easily add, manage as well as track the payment in a quicker manner. Whether your payment is from the banks or e-wallets,
                                            </p>
                                            <div>
                                                <span id="text101">
                                                    the data could be easily entered into the free billing software. So, it is quite an efficient way to manage the accounts with all the cash-ins and cash-outs. A business account in your bank needs to be added with
                                                    the GST Accounting Software. <br />
                                                    <br />
                                                    Easily tally your bank statement with the bank account book maintained on the vyavsay app. This process is the most suitable option for maintaining the complete bank account book without any hassle. This gives
                                                    you complete hassle-free control on business in an extensive way. Free invoicing & accounting software can also be accessed from your mobile from anywhere with internet connectivity. It gives you a better option
                                                    to become a smart businessman. Download GST billing App to have better control over your accounting and taxation.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle101">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-weight"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Track Cash Flow</h4>
                                            <p>
                                                GST billing & accounting software allows the user to easily record all the business transactions. It mainly prevents any kind of accidental mistakes in accounting.
                                            </p>
                                            <div>
                                                <span id="text11">
                                                    Most businesses have started to use this free software for their billing, accounting, and many more. By investing in this billing software, you could easily gain major growth in your business transactions.
                                                    <br />
                                                    Managing the cash transactions such as bank withdrawal, deposit, and many more is easier with this all-in-one software. This free GST invoicing Software is more helpful for creating a real-time cash book.
                                                    Tallying with the data of the expenses, payments, purchases, and many others from this GST invoicing & Accounting Software becomes quite easy. GST billing software/app gives added benefits for the businesses with
                                                    its daybook as well as cash flow statement. This free software especially eliminates the necessity for any kind of manual data entry. This also gives a better option for maintaining the financial figure.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle11">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-list-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Cheques</h4>
                                            <p>
                                                Most businesses use this free software for easily receiving the payments through Cheques. The software has a built in feature of marking the ‘Cheque Payments’
                                            </p>
                                            <div>
                                                <span id="text12">
                                                    on the bills so it is quite easier to track the payment. The vyavsay App has open cheques which allow the user to easily make a quick deposit or withdraw and close them. vyavsay App is the leading free billing
                                                    software suitable for: <br />
                                                    <br />
                                                    1. Managing bounced Cheque<br />
                                                    2. Direct Cheque transfer
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle12">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-wallet"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>GST Invoicing / Billing</h4>
                                            <p>
                                                Ultimate free software for GST billing mainly comes with an abundance of features. This is quite efficient for easily assisting the medium and small and enterprises
                                            </p>
                                            <div>
                                                <span id="text13">
                                                    to save more time in accounting. With the help of free billing software with GST, business owners could easily perform various tasks that include GST return filing, inventory management, invoicing, and Billing.
                                                    This app allows businesses to easily generate monthly or quarterly GST reports. <br />
                                                    <br />
                                                    Generate GST Invoice for your clients within 20 seconds. GST bills are mainly recommended in the GST invoice format, so you can easily create using this GST Software for billing. Share your data with your clients
                                                    instantly for gaining the maximum customer satisfaction.
                                                    <br />
                                                    <br />
                                                    “Bill wise payment” in the vyavsay app is the most important feature as it is quite easy to link your payments with your sales invoices. With the help of this feature, it is easier to track all the due dates in
                                                    invoice. vyavsay app allows any business to easily identify any overdue payments.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle13">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-sync"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Business Status</h4>
                                            <p>
                                                With free GST billing & invoicing Software, you can simply manage your business even from your mobile. Accounting of the financial system in your business becomes
                                            </p>
                                            <div>
                                                <span id="text14">
                                                    quite simple and efficient with this free software. You can easily get complete updates of your business anytime and anywhere. Free GST billing app offers adequate facilities that include <br />
                                                    <br />
                                                    1. Cash-flow<br />
                                                    2. Bank status<br />
                                                    3. Stock/Inventory status<br />
                                                    4. Open Orders
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle14">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-truck-moving"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Business Reports</h4>
                                            <p>
                                                Businesses are required to make strong and informed decisions with the best reports. Using this free billing software is quite an efficient option for getting a
                                            </p>
                                            <div>
                                                <span id="text15">
                                                    suitable solution. Users can easily view all the data instantly on the free GST invoicing & Accounting Software. It is enabled with the “Balance Sheet,” so that this would be quite an efficient option for
                                                    extensively increasing the operational efficiency of the business. <br />
                                                    <br />
                                                    1. E-Payment<br />
                                                    2. Billing and Invoicing<br />
                                                    3. Monthly GST Report<br />
                                                    4. Dashboard<br />
                                                    5. Reporting<br />
                                                    6. GST returns<br />
                                                    7. Permission Management<br />
                                                    <br />
                                                    <br />
                                                    This free software allows the detailed analysis of accurate business details, accounts, and many more; it is also quite an efficient way for easily analyzing the profit in the business. Various reports such as
                                                    <br />
                                                    <br />
                                                    1. GSTR 1 format<br />
                                                    2. GSTR 3B<br />
                                                    3. GST related reports<br />
                                                    4. Balance Sheet<br />
                                                    5. Profit & Loss
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle15">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>

                                <div className="row rowE">
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-file-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Data Safety and Security</h4>
                                            <p>
                                                GST billing & accounting Software is 100% secure, and you can easily store your data accurately. This free app allows you to easily keep your data secure.
                                            </p>
                                            <div>
                                                <span id="text16">
                                                    Now you could easily use this GST invoicing & Accounting Software for getting a quick backup of data. The data will be encrypted with added security. vyavsay app is the advanced free invoicing & accounting
                                                    software that provides hassle free backup system with the “auto-backup” feature. <br />
                                                    <br />
                                                    After activating this mode on vyavsay app, it is easier to get all your data back-up so you would not lose anything. Most businesses in India have been using this free accounting and invoicing software as it
                                                    makes the job quick with added data security.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle16">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-weight"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Regular/Thermal Printer</h4>
                                            <p>
                                                Whether you require your invoice in the perfect format instead of bill format or Excel format then using this free billing software is easier. Whether you are ...
                                            </p>
                                            <div>
                                                <span id="text17">
                                                    using a thermal printer or regular printer, you can use the vyavsay app as it has been designed to support all printers. <br />
                                                    <br />
                                                    vyavsay’s invoicing and accounting software/app is an efficient way for printing your Invoice and Bills. Now you have the better option to easily generate the prints in all sizes that include thermal print sizes,
                                                    A4, A5, and many others. You can simply connect this app with your regular printer via Bluetooth or plug in thermal printers to start printing your invoices.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle17">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-list-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Online / Offline billing software</h4>
                                            <p>
                                                vyavsay app helps you generate invoices for your customers without requiring you to stay online.You can rely on our business accounting software to validate
                                            </p>
                                            <div>
                                                <span id="text18">
                                                    your transactions and update yourdatabase when connecting it to the internet. Using our billing tool, you need not stop your business operations when internet connectivity is weak. <br />
                                                    <br />
                                                    Using our GST accounting software, you can create bills for your customers as soon as they make the purchase. You can use the offline tool in your retail business to receive payments through cash and eWallets
                                                    that do not require active internet connection, making it the best suit for India’s remote locations.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle18">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-wallet"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Lifetime Fee</h4>
                                            <p>
                                                You can seamlessly create invoices, manage your dashboard, look out for stock, and use many other features for free using the vyavsay invoicing tool on Android device.
                                            </p>
                                            <div>
                                                <span id="text19">
                                                    We have made our business accounting tool free for a lifetime for Android mobile users. So, you only have to pay when you need access to the premium features and desktop application. <br />
                                                    <br />
                                                    Lifetime free access in mobile devices makes our invoicing tool a best fit for small and medium businesses. vyavsay is committed to provide the best quality services and become a core part of every growing
                                                    industry in the country. You can download the free app from the play store and sign up to use the accounting app for free.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle19">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}