import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

global.BACK_URL = 'Company';

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Business Category Name',
        field: 'name',
        width: 150
    },
    {
        label: 'Status',
        field: 'status',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class companyListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            catName : null,
            catArray : [],
            catId : 0,
            errors: {
                catName : ''
            }
        }
    }

    async componentDidMount ()
    {
        /* Get Url Pass Parameter */
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _catId = _handleParam[1] ? _handleParam[1] : 0;
        /* End Here Url Pass Parameter */

        if(_catId > 0){
            let _catArray = [];
            let _url_GetData = global.businessCategoryDetails+'?catId='+_catId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _catArray = responseJson.response[0];
                        this.setState({buttonTitle:'Edit',catName:_catArray.name,catId:_catArray.category_id,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
        this.getGstListData();
    }

    getGstListData(){
        let _catArray = [];
        let _url_GetData = global.businessCategoryList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _catArray = responseJson.response;
                }
                this.setState({catArray:_catArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'catName': 
            errors.catName = 
                value.length < 3 ? '*Business Category Name must be 3 characters long!' : '';
                this.setState({catName:value}); 
            break;
            default:
            break;
        }
    }

    clickToAddGst = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.catName == null)
        {
            _isError = true;
            errors['catName'] = "*Please Enter Business Category Name";
            this.setState({errors: errors});
        }

        if(_isError == false){
            if(this.state.catId > 0){
                this.editGstMethodCall();
            }
            else{
                this.addGstMethodCall();
            }
        }
    }

    editGstMethodCall()
    {
        const requestData = JSON.stringify({
            cat_name: this.state.catName,
            catId: this.state.catId
        });
        PostData(global.adminEditBusinessCategory, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-business-category/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['catName'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    addGstMethodCall(){
        const requestData = JSON.stringify({
            cat_name: this.state.catName
        });

        PostData(global.adminAddBusinessCategory, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-business-category/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['catName'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }
    
    clickToStatus(value,_status)
    {
        confirmAlert({
            title: 'Confirm to manage status',
            message: 'Are you sure you want to change the status?',
            buttons: [
            { 
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        typeCatId: value,
                        status:_status
                    });
                    PostData(global.businessCategoryStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Welcome Back  👍",responseJson.message,"success");
                            this.getGstListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    loadOrderListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _gstDetails = this.state.catArray;
            let dataList = [];
            let rows = [];

            if(_gstDetails.length > 0)
            {                          
                _gstDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    
                    let _status = 1;
                    let status = <span className={`badge badge-danger`}>Disabled</span>;
                    if (row.status == '1' || row.status == 1) {
                        _status = 0;
                        status = <span className={`badge badge-success`}>Enabled</span>;
                    }
                    rows.push({
                        name: row.name,
                        srNo: row.srNo,
                        status: (status),
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/manage-business-category/?auth='+row.id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.id} onClick={this.clickToStatus.bind(this,row.id,_status)}>
                                        <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>Manage Status
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Business Category List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage Business Category List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Business Category</h3>
                        </div>
                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="catName" name="catName" value={this.state.catName} placeholder="Business Category Name Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Business Category Name <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.catName.length > 0 && <span className='error'>{errors.catName}</span>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddGst}>
                                            <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} Business Category
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">List of Business Category</h3>
                        </div>
                        <div className="block-content">
                            <MDBDataTableV5 
                            hover
                            entriesOptions={[25, 50, 100]}
                            entries={25}
                            pagesAmount={4}
                            span
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            data={this.loadOrderListing()} />
                        </div>
                    </div>    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div class="gl-ldr-cld">
                        <div class="gl-ldr-ctr">
                            <div class="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}