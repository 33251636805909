import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];
let _classValue = "";
let _businessSetting = {};
let HSN_SAC_CODE = 0;
let _AdditionalCESS = 0;
let _ItemCategory = 0;
let _Description = 0;
let _countryCode = "IN";

let _loader = "glb-ldr-prt active";

export default class addExpenseMasterItem extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    if (_UserSession!=null && _UserSession!="") {
      let _companySession = localStorage.getItem("vs_UserCompanySession");
      if (_companySession!=undefined || _companySession!=null) {
        _companySession = JSON.parse(_companySession);
        _businessId = _companySession.business_id;
        _company_id = _companySession.company_id;
        country_code = _companySession.country_code;
        tax_name = _companySession.tax_name;
        gstTaxDetails = _companySession.taxDetails;

        if (_UserSession!=null && _UserSession!="") {
          if (_UserSession.loginType!="Admin") {
            if (_UserSession!=undefined || _UserSession!=null) {
              _businessSetting = _UserSession.businessSetting;
              HSN_SAC_CODE = _businessSetting.HSN_SAC_CODE;
              _AdditionalCESS = _businessSetting.AdditionalCESS;
              _ItemCategory = _businessSetting.ItemCategory;
              _Description = _businessSetting.Description;
              if (_businessSetting.ItemsUnit == 0) {
                _classValue = "iti__hide";
              }
            }
          }
        }
      }
    }

    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-expense/",
      buttonTitle: "Add New",
      isLoaded: true,

      expenseCatId: this.props.expenseCatId,

      taxRateArray: gstTaxDetails,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,

      expenseItemId: 0,

      name: "",
      hsn_sac: "",
      sale_price: "",
      sale_tax_type: "With Tax",
      taxRateId: "0",
      taxRate_id: "0",
      HSNSACCODE: HSN_SAC_CODE,

      errors: {
        name: "",
        hsn_sac: "",
        sale_price: "",
        sale_tax_type: "",
        taxRateId: "",
      },
    };
  }

  async componentDidMount() {
    let _expenseItemId = this.props.expenseItemId;
    if (_expenseItemId > 0) {
      let _itemArray = [];
      let _url_GetData =
        global.userExpenseItemDetails + "?expenseItemId=" + _expenseItemId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _itemArray = responseJson.response[0];

              this.setState({
                buttonTitle: "Edit",
                name: _itemArray.name,
                businessId: _itemArray.business_id,
                userId: _itemArray.user_id,
                expenseItemId: _expenseItemId,
                hsn_sac: _itemArray.hsn_sac,
                sale_price: _itemArray.sale_price,
                sale_tax_type: _itemArray.sale_tax_type,
                tax_rate_name: _itemArray.tax_rate_name,
                taxRate_id: _itemArray.tax_rate_id,
                taxRateId:
                  _itemArray.tax_rate_id +
                  "##" +
                  _itemArray.tax_rate_name +
                  "##" +
                  _itemArray.tax_rate_ratio,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name =
          value.length < 3 ? "*Item Name must be 3 characters long!" : "";
        this.setState({ name: value });
        break;
      case "hsn_sac":
        errors.hsn_sac =
          value.length < 3 && value.length!=0
            ? "*Item HSN SAC must be 3 characters long!"
            : "";
        this.setState({ hsn_sac: value });
        break;
      case "sale_price":
        if (validator.isFloat(value)) {
          errors.sale_price = "";
          if (value <= 0) {
            errors.sale_price = "*Price must be must be greater than zero";
          } else {
            errors.sale_price = "";
          }
        } else {
          errors.sale_price = "*Price must be 1!";
        }
        this.setState({ sale_price: value });
        break;
      case "sale_tax_type":
        errors.sale_tax_type =
          value.length < 3 ? "*Tax type 3 characters long!" : "";
        this.setState({ sale_tax_type: value });
        break;

      case "taxRateId":
        errors.taxRateId = value.length < 0 ? "*Select tax rate" : "";
        this.setState({ taxRateId: value });
        break;

      default:
        break;
    }
  };

  clickToAddParty = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.name == "") {
      _isError = true;
      errors["name"] = "*Please Enter Item Name";
      this.setState({ errors: errors });
    }
    if (this.state.sale_price == "") {
      _isError = true;
      errors["sale_price"] = "*Please Enter Price";
      this.setState({ errors: errors });
    }
    if (this.state.sale_price == "0" || this.state.sale_price == 0) {
      _isError = true;
      errors["sale_price"] = "*Please Enter Price";
      this.setState({ errors: errors });
    }
    if (this.state.sale_tax_type == "") {
      _isError = true;
      errors["sale_tax_type"] = "*Please Select Tax Type";
      this.setState({ errors: errors });
    }
    if (this.state.taxRateId == "") {
      _isError = true;
      errors["taxRateId"] = "*Please Select Any Tax Rate.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.props.expenseItemId > 0) {
        this.editItemMethodCall();
      } else {
        this.addItemMethodCall();
      }
    }
  };

  addItemMethodCall() {
    let fullTaxRateD = this.state.taxRateId;
    let mainData = fullTaxRateD.split("##");

    let _rateTax = {};
    _rateTax.id = mainData[0];
    _rateTax.name = mainData[1] + mainData[2];
    _rateTax.ratio = mainData[2];

    var itemData = new FormData();
    itemData.append("businessId", this.state.businessId);
    itemData.append("userId", this.state.userId);
    itemData.append("company_id", this.state.company_id);
    itemData.append("name", this.state.name);
    itemData.append("hsn_sac", this.state.hsn_sac);
    itemData.append("sale_price", this.state.sale_price);
    itemData.append("sale_tax_type", this.state.sale_tax_type);
    itemData.append("rateTax", JSON.stringify(_rateTax));

    new Promise((resolve, reject) => {
      fetch(global.userAddExpenseItem, {
        method: "POST",
        body: itemData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              //this.props.callback();
              //this.props.callBack('hello');
              //window.location.href = global.webUrl+this.state.isRedirectTo;
              let newItemId = res.newItemId ? res.newItemId : 0;

              if (newItemId > 0) {
                let _itemsArray = [];
                let _url_GetData =
                  global.userexpenseItemList +
                  "?company_id=" +
                  this.state.company_id;
                PostData(_url_GetData, "", "GET").then((result) => {
                  let responseJson = result;
                  if (responseJson.success == false) {
                    this.setState({ isLoaded: true });
                  } else {
                    if (responseJson.response) {
                      _itemsArray = responseJson.response;
                      _itemsArray.map((field, index) => {});
                      this.props.callBack(
                        _itemsArray,
                        newItemId,
                        this.state.name,
                        this.state.itemPosition
                      );
                    }
                  }
                });
              }
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editItemMethodCall() {
    let fullTaxRateD = this.state.taxRateId;
    let mainData = fullTaxRateD.split("##");

    let _rateTax = {};
    _rateTax.id = mainData[0];
    _rateTax.name = mainData[1] + mainData[2];
    _rateTax.ratio = mainData[2];

    var itemData = new FormData();
    itemData.append("expenseItemId", this.state.expenseItemId);
    itemData.append("businessId", this.state.businessId);
    itemData.append("userId", this.state.userId);
    itemData.append("company_id", this.state.company_id);
    itemData.append("name", this.state.name);
    itemData.append("hsn_sac", this.state.hsn_sac);
    itemData.append("sale_price", this.state.sale_price);
    itemData.append("sale_tax_type", this.state.sale_tax_type);
    itemData.append("rateTax", JSON.stringify(_rateTax));

    new Promise((resolve, reject) => {
      fetch(global.userEditExpenseItem, {
        method: "POST",
        body: itemData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              this.props.callBack("hello");
              //this.props.callback();
              //window.location.href = global.webUrl+this.state.isRedirectTo;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Item Details{" "}
          </h3>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="name"
                  name="name"
                  value={this.state.name}
                  placeholder="Enter Item Name"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Item Name <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.name.length > 0 && (
                    <span className="error">{errors.name}</span>
                  )}
                </span>
              </div>
            </div>

            {this.state.HSNSACCODE == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="hsn_sac"
                    name="hsn_sac"
                    value={this.state.hsn_sac}
                    placeholder="Item HSN/SAC"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">Item HSN/SAC</label>
                  <span className="iris_error_txt">
                    {errors.hsn_sac.length > 0 && (
                      <span className="error">{errors.hsn_sac}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="claerfix"></div>

          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Pricing Details{" "}
          </h3>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="sale_price"
                  name="sale_price"
                  value={this.state.sale_price}
                  placeholder="Enter Price"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Price<span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.sale_price.length > 0 && (
                    <span className="error">{errors.sale_price}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="sale_tax_type"
                  name="sale_tax_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Without Tax"
                    selected={
                      this.state.sale_tax_type == "Without Tax"
                        ? "selected"
                        : ""
                    }
                  >
                    Without Tax
                  </option>
                  <option
                    value="With Tax"
                    selected={
                      this.state.sale_tax_type == "With Tax" ? "selected" : ""
                    }
                  >
                    With Tax
                  </option>
                </select>
                <label htmlFor="login-password">Select Tax Type </label>
              </div>
              <div id="sale_tax_type-error" className="animated fadeInDown">
                {errors.sale_tax_type.length > 0 && (
                  <span className="error">{errors.sale_tax_type}</span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="taxRateId"
                  name="taxRateId"
                  onChange={this.handleChange}
                >
                  <option value="" selected>
                    {" "}
                    Select Tax Rate
                  </option>
                  {this.state.taxRateArray.map((item, index) => {
                    let _sel = "";
                    if (this.state.taxRate_id == item.id) {
                      _sel = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.id + "##" + item.name + "##" + item.ratio}
                        selected={_sel}
                      >
                        {item.name + "@" + item.ratio + "%"}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Tax Rate</label>
              </div>
              <div id="taxRateId-error" className="animated fadeInDown">
                {errors.taxRateId.length > 0 && (
                  <span className="error">{errors.taxRateId}</span>
                )}
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddParty}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Expense Item
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
