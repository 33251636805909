import React,{Component} from 'react';
import {Link,NavLink} from 'react-router-dom';

let _sessionPeriod = true;


export default class MenuPage extends Component{
    constructor(props){
        super(props);

        let _UserSession = localStorage.getItem('vs_UserSession');
        let _signingName = 'Administrator';
        
        if(_UserSession!=undefined || _UserSession!=null)
        {    
            if(_UserSession.loginType!='Admin'){
                _sessionPeriod = false;
                _UserSession = JSON.parse(_UserSession);
                //_signingName = _UserSession.loginName;
            }
        }
        this.state={
            isLoggedIn : _sessionPeriod,
            loggedName : _signingName,
            isSessionOff : _sessionPeriod,
            pathName:'Dashboard'
        }
    }

    componentDidMount(){
        let _handleMenu = this.props.pageId;
    }
    
    handleLogoutClick = (event) => {
        localStorage.removeItem('vs_UserSession');
        this.setState({isSessionOff:true})
    }

    render(){
        if (this.state.isSessionOff == true)
        {
            //console.log('Session Expire...!');   
            //this.logoutCallBack();         
            window.location.href = global.webUrl+'login/';
        }
        return(
            <React.Fragment>
                {/* Main Sidebar */}
                <nav id="sidebar" data-simplebar="init">
                    <div className="sidebar-content">
                        <div className="content-header content-header-fullrow px-15">
                            <div className="content-header-section sidebar-mini-visible-b">
                                <span className="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                                    <span className="text-dual-primary-dark">V</span><span className="text-primary">D</span>
                                </span>
                            </div>
                            <div className="content-header-section text-center align-parent sidebar-mini-hidden">
                                <button type="button" className="btn btn-circle btn-dual-secondary d-lg-none align-v-r" data-toggle="layout" data-action="sidebar_close">
                                    <i className="fa fa-times text-danger"></i>
                                </button>
                                <div className="content-header-item">
                                    <a  className="font-w700" href={"global.webUrl+/business/dashboard/"}>
                                        <i className="si si-fire text-primary"></i> <span className="text-dual-primary-dark">Vyavsay</span> User Dashboard
                                    </a> 
                                </div>
                            </div>
                        </div>
                            <div className="content-side content-side-full content-side-user px-10 align-parent">
                                <div className="sidebar-mini-visible-b align-v animated fadeIn">                        
                                    <img src="http://vyavsay.in/uploadDirectory/profile/vIcons.png" alt="avatar" className="img-avatar img-avatar32"/>
                                </div>
                                <div className="sidebar-mini-hidden-b text-center">
                                    <Link  className="img-link" href={() => false}>
                                        <img src="http://vyavsay.in/uploadDirectory/profile/vIcons.png" alt="avatar" className="img-avatar"/>
                                    </Link> 
                                    <ul className="list-inline mt-10">
                                        <li className="list-inline-item">
                                            <b>{this.state.loggedName}</b>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link  className="link-effect text-dual-primary-dark" data-toggle="layout" data-action="sidebar_style_inverse_toggle" href={() => false}>
                                                <i className="si si-drop"></i>
                                            </Link> 
                                        </li>
                                        <li className="list-inline-item">
                                            <Link  onClick={this.handleLogoutClick} className="link-effect text-dual-primary-dark" data-box="#mb-signout" href={() => false}>
                                                <i className="si si-logout"></i>
                                            </Link> 
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        <div className="content-side content-side-full">

                        {/* Sidebar Navigation */}
                        <ul className="nav-main">
                            <li>
                                <a  href={global.webUrl+"/business/dashboard/"}  className=" active">
                                    <i className="si si-cup sidebar-nav-icon"></i> <span className="sidebar-mini-hide">Dashboard</span>
                                </a> 
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manange Company</span>
                            </li>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-layers"></i><span className="sidebar-mini-hide">Company Settings</span></Link>     
                                <ul>
                                    <li>
                                        <NavLink to={"/business/manage-unit/"}>Manage Unit</NavLink>
                                    </li>
                                    <li>
                                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Party</span></Link> 
                                        <ul>
                                            <li>
                                                <NavLink to={"/business/add-party/"}><span className="sidebar-mini-hide">Add Party</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/business/manage-party/"}><span className="sidebar-mini-hide">Party List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Items</span></Link> 
                                        <ul>
                                            <li>
                                                <NavLink to={"/business/add-item/"}><span className="sidebar-mini-hide">Add Items</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/business/manage-items/"}><span className="sidebar-mini-hide">Items List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li>
                                        <Link  className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Services</span></Link> 
                                        <ul>
                                            <li>
                                                <NavLink to={"/business/add-service/"}><span className="sidebar-mini-hide">Add Service</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/business/manage-service/"}><span className="sidebar-mini-hide">Service List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Sale</span>
                            </li>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-note"></i><span className="sidebar-mini-hide">Sales Settings</span></Link>     
                                <ul>
                                    {/*<li>
                                        <Link  to={"/business/saleList/"}>Sale List</Link> 
                                    </li>*/}
                                    <li>
                                        <Link  to={"/business/sale-invoice/"}>Sale Invoice</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/manage-sale-estimate/"}>Estimate/Quotation</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Sale Return</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Payment-In</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Sale Order</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Delivery Challan</Link> 
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Purchase</span>
                            </li>
                            <li>
                                <Link  className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-basket-loaded"></i><span className="sidebar-mini-hide">Purchase Settings</span></Link>     
                                <ul>
                                    <li>
                                        <Link  to={"/business/purchase-invoice/"}>Purchase Bills</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Payment-Out</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Purchase Return</Link> 
                                    </li>
                                    <li>
                                        <Link  to={"/business/dashboard/"}>Purchase Order</Link> 
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Expense</span>
                            </li>
                            <li>
                                <Link  to={"/business/dashboard/"}  className="">
                                    <i className="si si-book-open sidebar-nav-icon"></i> <span className="sidebar-mini-hide">Expense Orders</span>
                                </Link> 
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Reports</span>
                            </li>
                        </ul>
                        {/* END Sidebar Navigation */}
                </div>
            </div>
        </nav>
            </React.Fragment>
        )
    }
}