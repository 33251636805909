import React, { Component } from "react";

import QuickExpenseCategory from "./manageExpenseCategory";
import QuickExpenseItem from "./manageExpenseItem";
import { Link } from "react-router-dom";
import moment from "moment";
import { MDBDataTableV5 } from "mdbreact";
import { PostData } from "../../../service/postData";
import { numberFormat } from "../../../config/numberFormat";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
} 

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}  

let _loader = "glb-ldr-prt active";

export default class DashboardPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      listTitle: "Category",
    };
  }

  componentDidMount() {}

  manageTabList(value) {
    this.setState({ listTitle: value });
    //this.buttonAddShow();
  }

  buttonAddShow() {
    //alert('calll');
    let _returnButton = "";
    if (this.state.listTitle == "Category") {
      _returnButton = (
        <div>
          <a
            href={global.webUrl + "business/manage-party/"}
            className="btn btn-sm btn-outline-secondary"
          >
            <span>
              <i className="si si-plus"></i> Add New Expense Category
            </span>
          </a>
        </div>
      );
    } else if (this.state.listTitle == "Items") {
      _returnButton = (
        <div>
          <a
            href={global.webUrl + "business/manage-items/"}
            className="btn btn-sm btn-outline-secondary"
          >
            <span>
              <i className="si si-plus"></i> Add New Expense Item
            </span>
          </a>
        </div>
      );
    }
    return _returnButton;
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _callListing = "Njjj";
    if (this.state.listTitle == "Category") {
      _callListing = <QuickExpenseCategory />;
    } else if (this.state.listTitle == "Items") {
      _callListing = <QuickExpenseItem />;
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Expense List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">Manage Expense List</span>
          </nav>
          <div className="row">
            <div className="col-lg-12">
              <span>
                <a
                  href={global.webUrl + "business/add-expense/"}
                  type="button"
                  className="btn btn-danger pull-right"
                >
                  <i className="fa fa-plus mr-5"></i> <b>Add Expense</b>
                </a>
              </span>
              <div className="block">
                <ul
                  className="nav nav-tabs nav-tabs-block"
                  data-toggle="tabs"
                  role="tablist"
                  style={{ position: "relative", zIndex: "1000" }}
                >
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Category"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Category")}
                    >
                      <i className="si si-emoticon-smile mr-5 text-primary"></i>{" "}
                      Category
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        this.state.listTitle == "Items"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.manageTabList.bind(this, "Items")}
                    >
                      <i className="si si-check text-success"></i> Item
                    </a>
                  </li>
                </ul>

                <div className="block-content tab-content">{_callListing}</div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
