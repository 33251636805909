
import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';
import { numberFormat } from '../../../config/numberFormat';

import { MDBDataTableV5 } from 'mdbreact';
let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = 'IN';
let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
    country_code = _companySession.country_code;
    tax_name = _companySession.tax_name;
    gstTaxDetails = _companySession.taxDetails;
}

let columns = [
    {
        label: 'Transaction Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Receipt No',
        field: 'receipt_no',
        width: 100,
    },
    {
        label: 'Transaction Type',
        field: 'transaction_type',
        width: 100,
    },
    {
        label: 'Link Amount',
        field: 'link_amount',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class QuickPaymentInPayLinkHistory extends Component{
    constructor(props){
        super(props);
        this.state = {
            otherTranPayInHistoryArray: (this.props.otherTranPayInHistoryArray) ? this.props.otherTranPayInHistoryArray : [],
            totalLinkHistoryAmt : (this.props.totalLinkHistoryAmt) ? this.props.totalLinkHistoryAmt : '0',
            buttonTitle:'Add New',
            isLoaded : true, 
            itemArray : [],
            totalTransCount : this.props.totalTransCount ? this.props.totalTransCount : 5,
            errors: {
            }

        }
    }
    componentDidMount()
    {  
        let _totalLinkAmt = 0;
        let _itemDetails = this.props.otherTranPayInHistoryArray;       
        if(_itemDetails.length > 0){            
            _itemDetails.map((value,index) => {
                _totalLinkAmt = parseFloat(_totalLinkAmt) + parseFloat((value.link_amount));
            })
        }
        this.setState({totalLinkHistoryAmt :_totalLinkAmt});
    }
     

    loadItemListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _itemDetails = this.props.otherTranPayInHistoryArray;
           
            if(_itemDetails.length > 0){
                
                _itemDetails.map((value,index) => {
                    rows.push({                    
                        date: value.trans_Date,
                        receipt_no: value.invoice_no,
                        transaction_type: value.type,
                        link_amount: numberFormat(value.link_amount),
                    });
                });
            }
        }
        
        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }       
        return dataList;
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        return(
            <React.Fragment>

                <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        {/* <h6 className="block-title ml-10">Linked With : </h6> */}
                                        
                                    </div>
                                    <div className="block-content" id="tableData">
                                        <MDBDataTableV5 
                                        hover
                                        entriesOptions={[this.state.totalTransCount]}
                                        entries={this.state.totalTransCount}
                                        pagesAmount={4}
                                        span
                                        pagingTop
                                        searchTop
                                        searchBottom={false}
                                        barReverse
                                        data={this.loadItemListing()}
                                        />
                                    </div>
                                </div>
                                <h6 className="block-title ml-10 text-right">Total : {this.state.totalLinkHistoryAmt}</h6> 
                            </div>
                        </div>
                    </div>
                
            </React.Fragment>
        )    
    }
}