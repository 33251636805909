import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import validator from "validator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import QuickItemDetailList from "./quickItemDetailList";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

import ButtonPdf from "../htmlPdfCreation";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0"; 
let tax_name = "";

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Item Name",
    field: "item_name",
    width: 100,
  },
  {
    label: "Cat Name",
    field: "categoryName",
    width: 100,
  },
  {
    label: "HSN/SAC",
    field: "hsn",
    width: 80,
  },
  {
    label: "Sale Price",
    field: "sale_price",
    width: 150,
  },
  {
    label: "Purchase Price",
    field: "purchase_price",
    width: 150,
  },
  {
    label: "Conversion",
    field: "conversion",
    width: 100,
  },
  {
    label: "Action",
    field: "actionextra",
    width: 20,
  },
];

let _loader = "glb-ldr-prt active";

export default class manageImportItemListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",
      activeParty: "N/A",
      activeCategoryId: 1,
      filterSearch: true,
      isLoaded: true,
      modalIsOpenItemList: false,
      modalIsOpen_IMI: false,
      company_id: _company_id,
      businessId: _businessId,
      categoryArray: [],
      categoryArrayFilter: [],
      categoryItemsArray: [],
      importExcelData: [],
      userId: _signingId,
      categoryName: "",
      importExcelFile: "",
      getItmeId: 0,
      errors: {
        categoryName: "",
        importExcelFile: "",
      },
    };
  }

  async componentDidMount() {
    this.getCategoryListData();
  }

  getCategoryItemListData(id) {
    let _itemsCategoryArray = [];
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      categoryId: id,
    });

    PostData(global.userCategoryItemList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            categoryItemsArray: _itemsCategoryArray,
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _itemsCategoryArray = responseJson.response;
          }
          this.setState({
            categoryItemsArray: _itemsCategoryArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getCategoryListData() {
    let _categoryArray = [];
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    PostData(global.itemCategoryList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _activeCategory,
            _activeCategoryId,
            _activeCategoryTxt = "";
          if (responseJson.response) {
            _categoryArray = responseJson.response;
            if (responseJson.response) {
              _categoryArray = responseJson.response;
              _categoryArray.map((party, i) => {
                if (i == 0) {
                  let _party_billing_address = party.party_billing_address;
                  if (party.party_billing_address == null) {
                    _party_billing_address = "";
                  }
                  _categoryArray[i].isActiveClick = true;
                  _activeCategory = party.categoryName;
                  _activeCategoryId = party.id;
                } else {
                  _categoryArray[i].isActiveClick = false;
                }
              });
            }
          }
          this.setState({
            categoryArrayFilter: _categoryArray,
            categoryArray: _categoryArray,
            isLoaded: true,
            categoryName: _activeCategory,
            activeParty: _activeCategory,
            activeCategoryId: _activeCategoryId,
          });
          if (_activeCategoryId > 0) {
            this.getCategoryItemListData(_activeCategoryId);
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadCategoryListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _partyDetails = this.state.categoryArrayFilter;

      if (_partyDetails.length > 0) {
        _partyDetails.map((value, index) => {
          let _activeClass = "simpleRow";
          if (this.state.activeParty == value.categoryName) {
            _activeClass = "activeRow";
          }

          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.id,
                value.categoryName
              )}
            >
              <td>{value.categoryName}</td>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  clickToActiveRow(id, txt) {
    this.setState({
      activeParty: txt,
      categoryName: txt,
      activeCategoryId: id,
    });
    if (id > 0) {
      this.getCategoryItemListData(id);
    }
  }

  openModalHandlerUnit(popId, item_id) {
    if (popId == "modalItemDetailList") {
      this.setState({ modalIsOpenItemList: true, getItmeId: item_id });
    }
  }

  loadCatItemListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.categoryItemsArray;
      if (_transDetails.length > 0) {
        let _actionExtra = "";
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let salePrice = numberFormat(value.sale_price);
          let purchasePrice = numberFormat(value.purchase_price);
          let _conversionData = "";
          if (value.unit_conversion_rates > 0) {
            _conversionData =
              "1 " +
              value.base_unit_name +
              " = " +
              value.unit_conversion_rates +
              " " +
              value.secound_unit_name;
          }
          _actionExtra = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                <Link
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={this.openModalHandlerUnit.bind(
                    this,
                    "modalItemDetailList",
                    value.item_id
                  )}
                  style={{ margin: "0px" }}
                >
                  <i className="fa fa-eye "> View Item</i>
                </Link>
              </div>
            </div>
          );
          rows.push({
            srNo: _i,
            item_name: value.name,
            categoryName: this.state.activeParty,
            hsn: value.hsn_sac ? value.hsn_sac : "",
            sale_price: salePrice + " ( " + value.sale_tax_type + " )",
            purchase_price:
              purchasePrice + " ( " + value.purchase_tax_type + " )",
            conversion: _conversionData,
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /* Code For Upload - Import Excel */
  onChangeFileUploadHandler = (event) => {
    this.setState({ importExcelData: [],
      errors: {
        categoryName: "",
        importExcelFile: "",
      }, });
    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
              // evt = on_file_select event
              /* Parse data */
              const bstr = evt.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
              /* Update state */
              // console.log("Data>>>" + data);// shows that excel data is read
              let _jsonData = this.convertToJson(data);
              //console.log("==Here==",_jsonData); // shows data in json format
              this.setState({
                importExcelData: _jsonData,
                importExcelFile: file,
              });
            };
            reader.readAsBinaryString(file);
          });
        })
      );
    }
  };

  convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 2; i < lines.length; i++) {
      var currentline = lines[i].split(",");
      let _fileColumn = 8;
      var obj = {};
      for (var j = 0; j < _fileColumn; j++) {
        if (currentline[0]!="") {
          if (currentline[3] > 0) {
            obj[headers[j]] = currentline[j];
          }
        }
      }
      if (
        obj["Sale price"] > 0 &&
        obj["Purchase price"] > 0 &&
        obj["Opening stock quantity"] >= 0 &&
        obj["Item name"]!="" &&
        obj["Minimum stock quantity"] >= 0
      ) {
        result.push(obj);
      }
    }
    return JSON.stringify(result); //JSON
  }

  clickToImportSheet = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.importExcelFile == "") {
      _isError = true;
      errors["importExcelFile"] = "*Please Upload Item Excel File.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      this.importSheetMethodCall();
    }
  };

  importSheetMethodCall() {
    const requestData = JSON.stringify({
      importExcelData: this.state.importExcelData,
      userId: this.state.userId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      categoryId: this.state.activeCategoryId,
    });
    let lengthExcelD = this.state.importExcelData ? (this.state.importExcelData).length :  0;
    
    if(lengthExcelD == 0 || lengthExcelD == '0'){
      console.log("==aa==");
        sendNotification(
          "Error Message 😓",
          "Please Upload Item Excel File",
          "danger"
        );
        return;
    }else{        
        if (JSON.parse(this.state.importExcelData).length > 0) {
          PostData(global.userImportItemSheet, requestData, "POST").then(
            (result) => {
              if (result.success == true) {
                sendNotification("Success Message 👍", result.message, "success");
                this.closeModalHandler("modalUploadItems");
                this.getCategoryListData();
                this.loadCategoryListing();
              } else {
                sendNotification("Error Message 😓", result.message, "danger");
                return;
              }
            }
          );
        } else {
          sendNotification(
            "Error Message 😓",
            "Please Upload Correct Item Excel File",
            "danger"
          );
          return;
        }
    }
  }
  /* End Code For Upload - Import Excel */

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalUploadItems") {
      this.setState({ modalIsOpen_IMI: true });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalItemDetailList") {
      this.setState({ modalIsOpenItemList: false, getItmeId: 0,importExcelFile:"",importExcelData :[] });
    } else if (popId == "modalUploadItems") {
      this.setState({ modalIsOpen_IMI: false,importExcelFile:"",importExcelData :[] });
    }
  };
  /*End Modal open close*/

  /* party Filter Code */
  onPartyFilterName = (e) => {
    const data = this.state.categoryArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ categoryArrayFilter: filtered });
    this.loadCategoryListing();
  };
  /* End party Filter Code */

  setFilterSearch = () => {
    this.setState({ filterSearch: !this.state.filterSearch });
  };
  setFilterSearchCancel = () => {
    this.setState({
      filterSearch: !this.state.filterSearch,
      categoryArrayFilter: this.state.categoryArray,
    });
    this.loadCategoryListing();
  };
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Bulk import items from excel
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">Bulk import items from excel</span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    {this.state.filterSearch ? (
                      <div className="mb-10">
                        <button
                          type="button"
                          data-toggle="layout"
                          data-action="header_search_on"
                          className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                          onClick={this.setFilterSearch}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    ) : (
                      <div id="page-header-search" className="show mb-10">
                        <div className="content-header content-header-fullrow">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-toggle="layout"
                                data-action="header_search_off"
                                onClick={this.setFilterSearchCancel}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                            <input
                              type="text"
                              onChange={this.onPartyFilterName}
                              className="form-control"
                              placeholder="Search here.."
                              id="page-header-search-input"
                              name="page-header-search-input"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th>Category Name</th>
                        </tr>
                      </thead>
                      <tbody id="sideTable">{this.loadCategoryListing()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    <div className="col-lg-12">
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <b>{this.state.activeParty}</b>{" "}
                          <i className="si si-pin"></i>
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary mr-5 mb-5 pull-right"
                            onClick={this.openModalHandler.bind(
                              this,
                              "modalUploadItems"
                            )}
                          >
                            <i className="si si-equalizer mr-5"></i>Import Items
                          </button>
                        </p>
                      </div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="block-content">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[25, 50, 100]}
                    entries={25}
                    pagesAmount={4}
                    span
                    pagingTop
                    searchTop
                    searchBottom={false}
                    barReverse
                    data={this.loadCatItemListing()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal List Item Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpenItemList}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalItemDetailList"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Item Details</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalItemDetailList"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickItemDetailList getItmeId={this.state.getItmeId} />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalItemDetailList"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal List Item Details*/}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_IMI}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadItems"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Import Excel - Item Details</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadItems"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <form
                      method="post"
                      id="form-login"
                      className="webForm px-30"
                    >
                      <h3
                        className="content-heading"
                        style={{ paddingTop: "6px" }}
                      >
                        <i className="fa fa-angle-right"></i> Upload Item Excel
                        Details{" "}
                      </h3>
                      <div className="form-group row">
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              accept=".xls,.xlsx"
                              type="file"
                              required="required"
                              className="form-control"
                              id="importExcelFile"
                              name="importExcelFile"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            <label htmlFor="login-username">
                              Upload Import Excel Item File
                            </label>
                            <span className="vs_error_txt">
                              {errors.importExcelFile.length > 0 && (
                                <span className="error">
                                  {errors.importExcelFile}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <span className="font-w600 text-info justify-content-end">
                            Download .xls/.xlsx (excel file) template file to
                            upload data
                          </span>
                          <br />
                          <a
                            href={
                              global.webUrl +
                              "sample-file/Import Items Template.xlsx"
                            }
                            target="_blank"
                            className="btn btn-alt-info min-width-125"
                          >
                            Download <i className="fa fa-file-excel-o ml-5"></i>
                          </a>
                        </div>
                      </div>
                      <div className="claerfix"></div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            type="submit"
                            disabled={isDisabled}
                            className="btn btn-sm btn-hero btn-alt-primary"
                            onClick={this.clickToImportSheet}
                          >
                            <i className="fa fa-check mr-5"></i> Import Item
                            Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadItems"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}
      </React.Fragment>
    );
  }
}
