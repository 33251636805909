// Loan account Apis
global.userAddLoanAccount = global.API_USER_URL+'userAddLoanAccount/';
global.userLoanAccountDetails = global.API_USER_URL+'userLoanAccountDetails/';
global.userLoanAccountList = global.API_USER_URL+'userLoanAccountList/';
global.userEditLoanAccount = global.API_USER_URL+'userEditLoanAccount/';
global.userLoanAccountDelete = global.API_USER_URL+'userLoanAccountDelete/';

global.userAddLoanIncreaseAmount = global.API_USER_URL+'userAddLoanIncreaseAmount/';
global.userLoanAccountTrasactionDetails = global.API_USER_URL+'userLoanAccountTrasactionDetails/';
global.userIncreaseLoanAccountList = global.API_USER_URL+'userIncreaseLoanAccountList/';
global.userEditLoanIncreaseAmount = global.API_USER_URL+'userEditLoanIncreaseAmount/';

global.userAddLoanChargesAmount = global.API_USER_URL+'userAddLoanChargesAmount/';
global.userEditLoanChargeAmount =  global.API_USER_URL+'userEditLoanChargeAmount/';

global.userAddEMIMakePayment =  global.API_USER_URL+'userAddEMIMakePayment/';
global.userEditEMIMakePayment =  global.API_USER_URL+'userEditEMIMakePayment/';

global.userLoanAccountViaTypeList = global.API_USER_URL+'userLoanAccountViaTypeList/';
global.userIncreaseLoanStatementList = global.API_USER_URL+'userIncreaseLoanStatementList/';

global.userLoanAccountTransactionDelete = global.API_USER_URL+'userLoanAccountTransactionDelete/';

global.userLoanAccountProcessList = global.API_USER_URL+'userLoanAccountProcessList/';
