import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import validator from "validator";
import Modal from "react-modal";
import { Multiselect } from "multiselect-react-dropdown";

let _sessionPeriod = true;
let _countrySession = localStorage.getItem("vs_UserCountrySession");
let _countryCode = "IN";
if (_countrySession!=undefined || _countrySession!=null) {
  _sessionPeriod = false;
  _countrySession = JSON.parse(_countrySession);
  _countryCode = _countrySession.countryCode;
}

global.BACK_URL = "BusinessUser";

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Business User",
    field: "bussiness_user_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Business Name",
    },
  },
  {
    label: "Contact No.",
    field: "contact_number",
    width: 150,
  },
  {
    label: "Password",
    field: "password",
    width: 150,
  },
  {
    label: "Business Name",
    field: "business_name",
    width: 150,
  },
  {
    label: "Companies",
    field: "companies",
    width: 150,
  },
  {
    label: "Added",
    field: "added_on",
    width: 150,
  },
  {
    label: "Status",
    field: "status",
    width: 100,
  },
  {
    label: "Action",
    field: "action",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";
let _selectedItems = [];
let _selectedUserId = "";
let _selectedBussinessId = "";

export default class businessUserListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",
      modalIsOpen_AC: false,
      isLoaded: true,

      _countryLang: "",
      _countryReg: "iti__hide",
      _countryFlag: "",
      _countryName: "",
      _countryPh: "",
      _countryId: "0",

      countryArray: [],
      businessArray: [],
      userArray: [],
      companyOptions: [],
      businessId: "",
      bussiness_user_name: "",
      user_contact_no: "",
      user_password: "",
      userId: 0,
      errors: {
        businessId: "",
        bussiness_user_name: "",
        user_contact_no: "",
        user_password: "",
      },
    };
  }

  async componentDidMount() {
    /* Get Url Pass Parameter */
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _userId = _handleParam[1] ? _handleParam[1] : 0;
    /* End Here Url Pass Parameter */
    let _userCountryId = "0";
    if (_userId > 0) {
      let _userArray = [];
      let _url_GetData = global.adminBusinessUserDetails + "?userId=" + _userId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _userArray = responseJson.response[0];
              _userCountryId = _userArray.country_code;
              this.setState({
                buttonTitle: "Edit",
                bussiness_user_name: _userArray.user_name,
                businessId: _userArray.business_id,
                user_contact_no: _userArray.user_contact_no,
                userId: _userId,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }

    let _urlData = global.adminBusinessNameList;
    let res = await PostData(_urlData, "", "GET");

    if (res.response == null || res.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }

    let _urlCountryData = global.adminCountryList;
    let countryData = await PostData(_urlCountryData, "", "GET");
    let _conLang,
      _conFlag,
      _conName,
      _conPh,
      _conId = "";
    countryData.response.map((conty, i) => {
      let _name = conty.sortname.toLowerCase();
      let _imgPath = global.FLAG_URL + _name + ".png";
      //alert(_userCountryId+'-----'+_countryCode);
      if (_userCountryId > 0) {
        if (_userCountryId == conty.phonecode) {
          _conLang = conty.sortname;
          _conFlag = _imgPath;
          _conName = conty.name;
          _conPh = conty.phonecode;
          _conId = conty.id;
        }
      } else if (_countryCode == conty.sortname) {
        _conLang = conty.sortname;
        _conFlag = _imgPath;
        _conName = conty.name;
        _conPh = conty.phonecode;
        _conId = conty.id;
      }
    });

    let _businessArray = res.response;
    this.setState({
      businessArray: _businessArray,
      countryArray: countryData.response,
      _countryId: _conId,
      _countryLang: _conLang,
      _countryFlag: _conFlag,
      _countryName: _conName,
      _countryPh: _conPh,
      isLoaded: true,
    });
    this.getBusinessUserListData();
  }

  getBusinessUserListData() {
    let _userArray = [];

    let _url_GetData = global.adminBusinessUserList;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _userArray = responseJson.response;
          }
          this.setState({ userArray: _userArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ isDisabled: false });
    switch (name) {
      case "bussiness_user_name":
        errors.bussiness_user_name =
          value.length < 3
            ? "*Business User Name must be 3 characters long!"
            : "";
        this.setState({ bussiness_user_name: value });
        break;
      case "user_password":
        errors.user_password =
          value.length < 5 ? "*Password must be 5 characters long!" : "";
        this.setState({ user_password: value });
        break;
      case "user_contact_no":
        if (validator.isInt(value)) {
          errors.user_contact_no = "";
        } else {
          errors.user_contact_no = "*Please enter valid phone.";
        }
        this.setState({ user_contact_no: value });
        break;
      case "businessId":
        errors.businessId =
          value.length < 0 ? "*Please select any business!" : "";
        this.setState({ businessId: value });
        break;
      default:
        break;
    }
  };

  clickToAddCompany = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.bussiness_user_name == null) {
      _isError = true;
      errors["bussiness_user_name"] = "*Please Enter Business User Name";
      this.setState({ errors: errors });
    }
    if (this.state.businessId == "") {
      _isError = true;
      errors["businessId"] = "*Please Select Any Business.";
      this.setState({ errors: errors });
    }
    if (this.state.user_contact_no == null) {
      _isError = true;
      errors["user_contact_no"] = "*Please Enter Contact No.";
      this.setState({ errors: errors });
    }
    if (
      this.state.user_contact_no.length < 8 ||
      this.state.user_contact_no.length > 12
    ) {
      _isError = true;
      errors["user_contact_no"] = "*Please enter valid contact no.";
      this.setState({ errors: errors });
    }
    if (this.state.user_password == null || this.state.user_password == "") {
      if (this.state.userId == 0) {
        _isError = true;
        errors["user_password"] = "*Please Enter Password.";
        this.setState({ errors: errors });
      }
    }

    if (_isError == false) {
      if (this.state.userId > 0) {
        this.editBussinessUserMethodCall();
      } else {
        this.addBussinessUserMethodCall();
      }
    }
  };

  editBussinessUserMethodCall() {
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      user_name: this.state.bussiness_user_name,
      user_password: this.state.user_password,
      user_contact_no: this.state.user_contact_no,
      countryID: this.state._countryId,
      country_code: this.state._countryPh,
      userId: this.state.userId,
    });
    PostData(global.adminEditBusinessUser, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            window.location.href =
              global.webUrl + "administrator/manage-business-user/";
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          let errors = this.state.errors;
          errors["businessPhone"] = result.message; //'Please enter valid phone no.';
          this.setState({
            errors: errors,
          });
          return;
        }
      }
    );
  }

  addBussinessUserMethodCall() {
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      user_name: this.state.bussiness_user_name,
      user_password: this.state.user_password,
      user_contact_no: this.state.user_contact_no,
      countryID: this.state._countryId,
      country_code: this.state._countryPh,
    });

    PostData(global.adminAddBusinessUser, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            window.location.href =
              global.webUrl + "administrator/manage-business-user/";
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          let errors = this.state.errors;
          errors["businessPhone"] = result.message; //'Please enter valid phone no.';
          this.setState({
            errors: errors,
          });
          return;
        }
      }
    );
  }

  clickToDelete(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              userId: value,
            });
            PostData(global.adminBusinessUserDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getBusinessUserListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToStatus(value, _status) {
    confirmAlert({
      title: "Confirm to manage status",
      message: "Are you sure you want to change the status?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              userId: value,
              status: _status,
            });
            PostData(global.adminBusinessUserStatus, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getBusinessUserListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  loadBusinessUserListing() {
    if (this.state.isLoaded == true) {
      let _companyDetails = this.state.userArray;
      let dataList = [];
      let rows = [];

      if (_companyDetails.length > 0) {
        _companyDetails.map((compDatas, i) => {
          const row = compDatas;
          let _status = 1;
          let status = (
            <span className={`badge badge-danger`}>Un-approved</span>
          );
          if (row.status == "1") {
            _status = 0;
            status = <span className={`badge badge-success`}>Approved</span>;
          }

          let companyList = [];
          for (let _j = 0; _j < row.company.length; _j++) {
            let comData = row.company[_j];
            companyList.push(
              <span className="njCompanyClose">
                {comData.company_name}{" "}
                <i
                  onClick={this.unAssignCompany.bind(
                    this,
                    row.user_id,
                    comData.company_id
                  )}
                  className="si si-close mr-5"
                  id="clickEvent"
                ></i>
              </span>
            );
          }

          if (companyList.length < 1) {
            companyList = "N/A";
          }

          let _dtt = row.added_on;
          var options = { year: "numeric", month: "long", day: "numeric" };

          rows.push({
            bussiness_user_name: row.user_name,
            contact_number: row.country_code + "-" + row.user_contact_no,
            password: row.password,
            business_name: row.business_name,
            srNo: row.srNo,
            companies: companyList,
            status: status,
            added_on: new Intl.DateTimeFormat("en-US", options).format(_dtt),
            action: (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="si si-list"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "administrator/manage-business-user/?auth=" +
                      row.user_id
                    }
                  >
                    <i className="si si-pencil mr-5 text-info"></i>Edit Details
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={row.user_id}
                    onClick={this.clickToStatus.bind(
                      this,
                      row.user_id,
                      _status
                    )}
                  >
                    <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>
                    Manage Status
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={row.user_id}
                    onClick={this.clickToDelete.bind(this, row.user_id)}
                  >
                    <i className="si si-trash mr-5 text-danger"></i>Delete
                    Details
                  </a>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={row.company_id}
                    onClick={this.openModalHandler.bind(
                      this,
                      row.business_id,
                      row.user_id
                    )}
                  >
                    <i className="si si-directions mr-5 text-warning"></i>Assign
                    Company
                  </a>
                </div>
              </div>
            ),
          });
        });
      }

      if (rows) {
        dataList = {
          columns: columns,
          rows: rows,
        };
      }
      return dataList;
    }
  }

  openModalHandler(businessId, userId) {
    this.setState({ companyOptions: [] });
    _selectedUserId = userId;
    _selectedBussinessId = businessId;
    let _url_GetData =
      global.adminAssignCompanyList +
      "?businessId=" +
      businessId +
      "&userId=" +
      userId;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, modalIsOpen_AC: true });
        } else {
          if (responseJson.response) {
            let _companyArray = responseJson.response;
            this.setState({
              modalIsOpen_AC: true,
              companyOptions: _companyArray,
            });
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  closeModalHandler = () => {
    this.setState({
      modalIsOpen_AC: false,
      companyOptions: [],
    });
  };

  unAssignCompany = (user_id, company_id) => {
    const requestData = JSON.stringify({
      companyId: company_id,
      userId: user_id,
    });
    PostData(global.adminDeleteAssignCompany, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          _selectedItems = [];
          this.closeModalHandler();
          sendNotification(
            "Success Message 👍",
            "Company unassign from user.",
            "success"
          );
          this.getBusinessUserListData();
        } else {
          this.closeModalHandler();
          sendNotification(
            "Error Message 😓",
            "Some error occured. Please try again.",
            "danger"
          );
          this.getBusinessUserListData();
          return;
        }
      }
    );
  };

  assignCompaniedBusiness = () => {
    const requestData = JSON.stringify({
      userId: _selectedUserId,
      businessId: _selectedBussinessId,
      selectedItems: _selectedItems,
    });

    PostData(global.adminAssignCompanyAdd, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          _selectedItems = [];
          this.closeModalHandler();
          sendNotification(
            "Success Message 👍",
            "Company Assigned Successfully.",
            "success"
          );
          this.getBusinessUserListData();
        } else {
          this.closeModalHandler();
          sendNotification(
            "Error Message 😓",
            "Some error occured. Please try again.",
            "danger"
          );
          this.getBusinessUserListData();
          return;
        }
      }
    );
  };

  onSelect(selectedList, selectedItem) {
    var datas = {
      id: selectedItem.id,
    };
    _selectedItems.push(datas);
  }

  onRemove(selectedList, removedItem) {
    _selectedItems.forEach((items, index, object) => {
      if (removedItem >= 0) {
        _selectedItems.splice(index, 1);
      } else if (removedItem.id == items.id) {
        _selectedItems.splice(index, 1);
      }
    });
  }

  /* Country Code Dropdown Start Here */
  selectCounties(code, flag, name, e) {
    this.setState({
      _countryReg: "iti__hide",
      _countryFlag: flag,
      _countryName: name,
      _countryPh: code,
    });
  }

  manageMultiCounties() {
    this.setState({
      _countryReg: "",
    });
  }

  getReadyMobileCode() {
    let data = [];
    if (this.state.isLoaded == true) {
      data = this.state.countryArray.map((conty, i) => {
        let _name = conty.sortname.toLowerCase();
        let _imgPath = global.FLAG_URL + _name + ".png";

        return (
          <li
            key={i}
            className="iti__country iti__standard"
            id="iti-item-in"
            onClick={this.selectCounties.bind(
              this,
              conty.phonecode,
              _imgPath,
              conty.sortname,
              conty.id
            )}
          >
            <div className="iti__flag-box">
              <div className="iti__flag" id="itiFlagImg">
                <img src={_imgPath} />
              </div>
            </div>
            <span className="iti__country-name">{conty.sortname}</span>
            <span className="iti__dial-code">{conty.phonecode}</span>
          </li>
        );
      });
    }
    return data;
  }

  onMouseLeaveHandler() {
    this.setState({
      _countryReg: "iti__hide",
    });
  }
  /* Country Code Dropdown End Here */
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Business User List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/manage-business/"}
            >
              View Business List
            </a>
            <span className="breadcrumb-item active">
              Manage Business User List
            </span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Add / Edit Business User</h3>
            </div>
            <div className="block-content">
              <form method="post" id="form-login" className="webForm px-30">
                <div className="form-group row">
                  <div className="col-3">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        className="form-control"
                        id="bussiness_user_name"
                        name="bussiness_user_name"
                        value={this.state.bussiness_user_name}
                        placeholder="Business User Name Here"
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <label htmlFor="login-username">
                        Business User Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <span className="vs_error_txt">
                        {errors.bussiness_user_name.length > 0 && (
                          <span className="error">
                            {errors.bussiness_user_name}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-material open">
                      <div
                        className="iti__flag-container"
                        onMouseEnter={this.manageMultiCounties.bind(this)}
                        onMouseLeave={this.onMouseLeaveHandler.bind(this)}
                      >
                        <div
                          className="iti__selected-flag"
                          role="combobox"
                          aria-owns="country-listbox"
                          tabIndex="0"
                          title={this.state._countryName}
                        >
                          <div className="iti__flag" id="itiFlagImg">
                            <img src={this.state._countryFlag} />
                          </div>
                          <div className="iti__selected-dial-code">
                            {this.state._countryPh}
                          </div>
                          <div className="iti__arrow">
                            <i className="si si-arrow-down"></i>
                          </div>
                        </div>

                        <ul
                          className={
                            this.state._countryReg +
                            " iti__country-list iti__country-list--dropup"
                          }
                          id="country-listbox"
                          aria-expanded="false"
                          role="listbox"
                          aria-activedescendant="iti-item-ad"
                        >
                          {this.getReadyMobileCode()}
                        </ul>
                      </div>
                      <input
                        type="text"
                        required="required"
                        placeholder="9876XXXXXX"
                        autoComplete="false"
                        value={this.state.user_contact_no}
                        className="form-control"
                        id="user_contact_no"
                        name="user_contact_no"
                        style={{ display: "inline-block", width: "60%" }}
                        onChange={this.handleChange}
                        maxLength="12"
                      />
                      <label htmlFor="login-username">
                        Business User Contact No.{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <span className="vs_error_txt">
                        {errors.user_contact_no.length > 0 && (
                          <span className="error">
                            {errors.user_contact_no}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-material open">
                      <input
                        type="password"
                        required="required"
                        placeholder="******"
                        autoComplete="false"
                        value={this.state.user_password}
                        className="form-control"
                        id="user_password"
                        name="user_password"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-password">
                        Password <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div
                      id="ownerPassword-error"
                      className="animated fadeInDown"
                    >
                      {errors.user_password.length > 0 && (
                        <span className="error">{errors.user_password}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-material open">
                      <select
                        className="form-control"
                        id="businessId"
                        name="businessId"
                        onChange={this.handleChange}
                      >
                        <option value=""> Select Business</option>
                        {this.state.businessArray.map((item, index) => {
                          let _sel = "";
                          if (this.state.businessId == item.business_id) {
                            _sel = "selected";
                          }
                          return (
                            <option
                              key={index}
                              value={item.business_id}
                              selected={_sel}
                            >
                              {item.business_name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="login-password">
                        In Business <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div id="businessId-error" className="animated fadeInDown">
                      {errors.businessId.length > 0 && (
                        <span className="error">{errors.businessId}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="btn btn-alt-primary"
                      onClick={this.clickToAddCompany}
                    >
                      <i className="fa fa-check mr-5"></i>{" "}
                      {this.state.buttonTitle} Business User
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">List of Business User</h3>
            </div>
            <div className="block-content">
              <MDBDataTableV5
                hover
                entriesOptions={[25, 50, 100]}
                entries={25}
                pagesAmount={4}
                span
                pagingTop
                searchTop
                searchBottom={false}
                barReverse
                data={this.loadBusinessUserListing()}
              />
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal for Company Assign Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AC}
          ariaHideApp={false}
          size="sm"
          onRequestClose={this.closeModalHandler}
          id="modalPop"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Assign Companies to Business User
                  </h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="form-material open">
                    <Multiselect
                      style={{
                        chips: { background: "#697d9b" },
                        searchBox: {
                          border: "none",
                          borderBottom: "1px solid #ccc",
                          borderRadius: "0px",
                        },
                      }}
                      placeholder="Select Companies..."
                      options={this.state.companyOptions} // Options to display in the dropdown
                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      closeIcon="close"
                    />
                    <label htmlFor="login-username">
                      Select Multiple Companies
                    </label>
                    <span className="vs_error_txt">
                      {errors.bussiness_user_name.length > 0 && (
                        <span className="error">
                          {errors.bussiness_user_name}
                        </span>
                      )}
                    </span>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-alt-success"
                  onClick={this.assignCompaniedBusiness}
                >
                  <i className="fa fa-check"></i> Assign Company
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
