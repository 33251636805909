// Party Apis
global.userAddParty = global.API_USER_URL+'userAddParty/';
global.userEditParty = global.API_USER_URL+'userEditParty/';
global.userEditPartyInfo = global.API_USER_URL+'userEditPartyInfo/';
global.userPartyList = global.API_USER_URL+'userPartyList/';
global.userPartyStatus = global.API_USER_URL+'userPartyStatus/';
global.userPartyDetails = global.API_USER_URL+'userPartyDetails/';
global.userImportPartySheet = global.API_USER_URL+'userImportPartySheet/';

global.userPartyTransactionList = global.API_USER_URL+'userPartyTransactionList/';

global.userPartyToPartyTransfer = global.API_USER_URL+'userPartyToPartyTransfer/';
global.userPartyToPartyEditTransfer = global.API_USER_URL+'userPartyToPartyEditTransfer/';
global.userPartyToPartyTransferDetail = global.API_USER_URL+'userPartyToPartyTransferDetail/';

global.userPartyTransferDelete = global.API_USER_URL+'userPartyTransferDelete/';
global.userPartyDelete = global.API_USER_URL+'userPartyDelete/';


global.userPartyGroupList = global.API_USER_URL+'userPartyGroupList/';
global.userAddPartyGroup = global.API_USER_URL+'userAddPartyGroup/';
global.userEditPartyGroup = global.API_USER_URL+'userEditPartyGroup/';