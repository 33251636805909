import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import {userSessionManage} from '../../../service/userLocalStorage';
import validator from 'validator';
import 'react-confirm-alert/src/react-confirm-alert.css';

let _loader = 'glb-ldr-prt active';
let vsSession = localStorage.getItem('vs_UserSession');
let _adminId = '0';
let _loginName = '';
let _loginEmail = '';
let _profileLogo = '';
let _loginType = 'Business';
let _owner_name = '';
let _owner_email = '';
let _owner_country_code = '';
let _owner_contact = '';
let _business_address = '';
if(vsSession!=undefined || vsSession!=null)
{
    vsSession = JSON.parse(vsSession);
    
    _adminId = (vsSession.loginId > 0) ? vsSession.loginId : '0';
    _loginName = vsSession.loginName ? vsSession.loginName : 'Business Admin / User';
    _loginEmail = vsSession.loginEmail ? vsSession.loginEmail : 'admin@vyavsay.com';
    _profileLogo = vsSession ? vsSession.profile_logo : '';
    _loginType = vsSession.loginType ? vsSession.loginType : 'Business';
    _owner_name = vsSession.owner_name ? vsSession.owner_name : '';
    _owner_email = vsSession.owner_email ? vsSession.owner_email : '';
    _owner_country_code = vsSession.owner_country_code ? vsSession.owner_country_code : '';
    _owner_contact = vsSession.owner_contact ? vsSession.owner_contact : '';
    _business_address = vsSession.business_address ? vsSession.business_address : '';
    
}

export default class profilePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Update Profile',
            isLoaded : true,
            loginName : _loginName,
            loginEmail : _loginEmail,
            loginType : _loginType,
            ownerName : _owner_name,
            ownerEmail : _owner_email,
            ownerContact : _owner_contact,
            ownerCountryCode : _owner_country_code,
            ownerBussAddress:_business_address,
            logo : '',
            currentPassword : '',
            newPassword : '',
            confirmPassword : '',
            
            _countryLang:'',
            _countryReg:'iti__hide',
            _countryReg_O:'iti__hide',
            _countryFlag:'',
            _countryName:'',
            _countryPh:'',
            _countryId:'0',
            countryArray : [],

            errors: {
                loginName : '',
                loginEmail : '',
                loginType : '',
                ownerName : '',
                ownerEmail : '',
                ownerContact : '',
                ownerCountryCode : '',
                ownerBussAddress : '',
            },
            errorsPassword: {
                currentPassword : '',
                newPassword : '',
                confirmPassword : ''
            }
        }
    }

    async componentDidMount ()
    {
        let _countryCode = 'IN';
        
        //'http://api.hostip.info'
        //'https://ipinfo.io/'
        PostData('http://ip-api.com/json','','GET').then(response => {
            let responseJson = response;
            _countryCode = responseJson.countryCode;
            userSessionManage(localStorage,responseJson,'countrySession');
        })
        .catch(error => this.setState({ error, isLoaded: false }));
        
        let _urlCountryData = global.adminCountryList;
        let countryData = await PostData(_urlCountryData,'','GET');
        let _conLang,_conFlag,_conName,_conPh,_conId = '';
        countryData.response.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            let _imgPath = global.FLAG_URL+_name+'.png';

            if(_countryCode == conty.sortname){
                _conLang = conty.sortname;
                _conFlag = _imgPath;
                _conName=conty.name;
                _conPh=conty.phonecode;
                _conId=conty.id;
            }
        });
    
        if(this.state.ownerCountryCode.length > 2){
            _conPh = this.state.ownerCountryCode;
        }
        this.setState({countryArray:countryData.response,_countryId:_conId,_countryLang:_conLang,_countryFlag:_conFlag,_countryName:_conName,_countryPh:_conPh,isLoaded:true});
    }


    selectCounties(code,flag,name,e){
        
        this.setState({
            _countryReg:'iti__hide',
            _countryFlag:flag,
            _countryName:name,
            _countryPh:code,
        })
    }

    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';
                let _phonecode = conty.phonecode;
                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{_phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }
    manageMultiCounties(key){
        if(key == 'I'){
            this.setState({_countryReg:''});
        }
        else if(key == 'O'){
            this.setState({_countryReg_O:''});
        }
    }

    onMouseLeaveHandler(key) {
        if(key == 'I'){
            this.setState({_countryReg:'iti__hide'});
        }
        else if(key == 'O'){
            this.setState({_countryReg_O:'iti__hide'});
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'loginName': 
                errors.loginName = value.length < 3 ? '*Name must be 3 characters long!' : '';
                this.setState({loginName:value}); 
            break;
            case 'loginEmail': 
                if (validator.isEmail(value)) {
                    errors.loginEmail = '';
                } else {
                    errors.loginEmail = '*Please enter valid email-id.';
                }   
                //errors.loginEmail = value.length < 3 ? '*Enter Valid Email Id!' : '';
                this.setState({loginEmail:value}); 
            break;
            case 'ownerName': 
                errors.ownerName = 
                value.length < 3 ? '*Owner Name must be 3 characters long!' : '';
                if (!value.match(/^[a-zA-Z ]*$/)) {
                    errors.ownerName = "*Please enter alphabet characters only.";
                }
                this.setState({ownerName:value}); 
            break;
            case 'ownerBussAddress': 
                errors.ownerBussAddress = 
                value.length < 3 ? '*Address must be 3 characters long!' : '';
                this.setState({ownerBussAddress:value}); 
            break;
            
            case 'ownerContact': 
                if (validator.isInt(value)) {
                    errors.ownerContact = '';
                } else {
                    errors.ownerContact = '*Please enter valid owner mobile no.';
                } 
                this.setState({ownerContact:value}); 
            break;
            case 'ownerEmail': 
                if (validator.isEmail(value)) {
                    errors.ownerEmail = '';
                } else {
                    errors.ownerEmail = '*Please enter valid owner email-id.';
                }
                this.setState({ownerEmail:value}); 
            break;

            default:
            break;
        }
    }
    
    handlePasswordChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errorsPassword = this.state.errorsPassword;
        this.setState({isDisabled:false});
        switch (name) {
            

            case 'currentPassword': 
            errorsPassword.currentPassword = value.length < 5 ? '*Old Password must be 5 characters long!' : '';
                this.setState({currentPassword:value}); 
            break;
            case 'newPassword': 
            errorsPassword.newPassword = value.length < 5 ? '*New Password must be 5 characters long!' : '';
                if(this.state.confirmPassword.length > 4 && value.length > 4){
                    errorsPassword.newPassword = value!=this.state.confirmPassword ? '*New password and confirm password are not match!' : '';
                }
                this.setState({newPassword:value}); 
            break;
            case 'confirmPassword': 
            errorsPassword.confirmPassword = value.length < 5 ? '*Confirm Password must be 5 characters long!' : '';
                if(value.length > 4){
                    errorsPassword.confirmPassword = value!=this.state.newPassword ? '*Confirm Password not match!' : '';
                }
                this.setState({confirmPassword:value}); 
            break;
            default:
            break;
        }
    }

    clickToManageProfileInfo = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.loginName == '')
        {
            _isError = true;
            errors['loginName'] = "*Please Enter Name";
            this.setState({errors: errors});
        }
        if(this.state.loginEmail == '')
        {
            _isError = true;
            errors['loginEmail'] = "*Please Enter Email-Id";
            this.setState({errors: errors});
        }
        
        if(this.state.ownerName == '')
        {
            _isError = true;
            errors['ownerName'] = "*Please Enter Owner Name.";
            this.setState({errors: errors});
        }
        if(this.state.ownerBussAddress == '')
        {
            _isError = true;
            errors['ownerBussAddress'] = "*Please Enter Business Address.";
            this.setState({errors: errors});
        }
        
        if(this.state.ownerContact == '')
        {
            _isError = true;
            errors['ownerContact'] = "*Please Enter Owner Contact No.";
            this.setState({errors: errors});
        }
        if(this.state.ownerContact.length < 8 || this.state.ownerContact.length >12 )
        {
            _isError = true;
            errors['ownerContact'] = "*Please enter valid owner contact no.";
            this.setState({errors: errors});
        }
        if(this.state.ownerEmail == '')
        {
            _isError = true;
            errors['ownerEmail'] = "*Please Enter Owner Email.";
            this.setState({errors: errors});
        }

        if(_isError == false)
        {
            let _apiUrl = global.userProfileUpdate;
            if(_loginType == 'Business'){
                _apiUrl = global.businessProfileUpdate;
            }
            let requestData = '' ;
            if(_loginType == 'Business'){
                    requestData = JSON.stringify({
                    loginName: this.state.loginName,
                    emailId :this.state.loginEmail,
                    ownerName :this.state.ownerName,
                    ownerEmail :this.state.ownerEmail,
                    ownerContact :this.state.ownerContact,
                    ownerCountryCode :this.state._countryPh,
                    ownerBussAddress :this.state.ownerBussAddress,
                    loginId: _adminId,
                    loginType: 'Admin',
                });
            }
            if(_loginType!='Business'){
                 requestData = JSON.stringify({
                    loginName: this.state.loginName,
                    emailId :this.state.loginEmail,
                    loginId: _adminId,
                    loginType: 'Admin',
                });
            }
    
            PostData(_apiUrl, requestData,'POST').then((result) => {
                if(result.success == true)
                {
                    sendNotification("Success Message 👍",result.message,"success");
                    // setTimeout(
                    //     () => {
                    //         window.location.href = global.webUrl+'business/dashboard/';
                    //     }, 
                    //     Math.floor(Math.random() * 5000) + 1
                    // )

                    let _updateLocal = vsSession;
                    _updateLocal.loginEmail = this.state.loginEmail;
                    _updateLocal.loginName = this.state.loginName;

                    _updateLocal.owner_name = this.state.ownerName;
                    _updateLocal.owner_email = this.state.ownerEmail;
                    _updateLocal.owner_country_code = this.state._countryPh;
                    _updateLocal.owner_contact = this.state.ownerContact;
                    _updateLocal.business_address = this.state.ownerBussAddress;
                    localStorage.removeItem('vs_UserSession');
                    localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                    this.setState({reChangeComapny:true});
                    window.location.href = global.webUrl+'business/dashboard/';
                }
                else
                {
                    sendNotification("Error Message 😓",result.message,"danger");
                    let errors = this.state.errors;
                    errors['loginName'] = result.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            });
        }
    }

    clickToManageProfilePassword = (event) => {
        event.preventDefault();
        let errorsPassword = this.state.errorsPassword;
        let _isError = false;
        if(this.state.currentPassword == '')
        {
            _isError = true;
            errorsPassword['currentPassword'] = "*Please Enter Old Password.";
            this.setState({errorsPassword: errorsPassword});
        }
        if(this.state.newPassword == '')
        {
            _isError = true;
            errorsPassword['newPassword'] = "*Please Enter New Password.";
            this.setState({errorsPassword: errorsPassword});
        }
        if(this.state.confirmPassword == '')
        {
            _isError = true;
            errorsPassword['confirmPassword'] = "*Please Enter Confirm Password.";
            this.setState({errorsPassword: errorsPassword});
        }

        if(_isError == false)
        {
            let _apiUrl = global.userPasswordUpdate;
            if(_loginType == 'Business'){
                _apiUrl = global.businessPasswordUpdate;
            }
            
            const requestData = JSON.stringify({
                newPassword :this.state.newPassword,
                currentPassword :this.state.currentPassword,
                loginId: _adminId,
                loginType: _loginType,
            });
    
            PostData(_apiUrl,requestData,'POST').then((result) => {
                if(result.success == true)
                {
                    sendNotification("Success Message 👍",result.message,"success");
                    setTimeout(
                        () => {
                            window.location.href = global.webUrl+"login/";
                        }, 
                        Math.floor(Math.random() * 5000) + 1
                    )
                }
                else
                {
                    sendNotification("Error Message 😓",result.message,"danger");
                    let errors = this.state.errors;
                    errors['currentPassword'] = "Please enter correct current password";//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            });
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const {errorsPassword} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        const isDisabledPass = Object.keys(errorsPassword).some(x => errorsPassword[x]);
        let textTitle = "Manage Business Profile";
        let businessName = "Business Name";
        let businessEmail = "Business Email Id";
        if(this.state.loginType == 'BusinessUser'){
            textTitle = "Manage Business User Profile";
            businessName = "Business User Name";
            businessEmail = "Business User Email Id";
        }
        console.log("==_owner_country_code==",_owner_country_code);
        console.log("==JSON--==",JSON.stringify(vsSession));
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>{textTitle}<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">{textTitle}</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-user-circle mr-5 text-muted"></i> User Profile
                            </h3>
                        </div>
                        <div className="block-content">
                            <div className="row items-push">
                                <div className="col-lg-3">
                                    <p className="text-muted">
                                        Your account’s vital info. Your username will be publicly visible.
                                    </p>
                                </div>
                                <div className="col-lg-7 offset-lg-1">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-name">{businessName} <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-lg" id="loginName" name="loginName" onChange={this.handleChange} placeholder="Enter your name.." value={this.state.loginName} required="required"/>
                                            <span className="iris_error_txt">{errors.loginName.length > 0 && <span className='error'>{errors.loginName}</span>}</span>
                                        </div>
                                    </div>
                                    {this.state.loginType!='BusinessUser' &&
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-name">{businessEmail} <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-lg" id="loginEmail" name="loginEmail" onChange={this.handleChange} placeholder="Enter your email id.." value={this.state.loginEmail} required="required"/>
                                            <span className="iris_error_txt">{errors.loginEmail.length > 0 && <span className='error'>{errors.loginEmail}</span>}</span>
                                        </div>
                                    </div>
                                    
                                    }

                                    {this.state.loginType!='BusinessUser' &&
                                    <div id='ownerDetail'>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label htmlFor="profile-settings-name">Owner Name <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control form-control-lg" id="ownerName" name="ownerName" onChange={this.handleChange} placeholder="Enter your owner name" value={this.state.ownerName} required="required"/>
                                                <span className="iris_error_txt">{errors.ownerName.length > 0 && <span className='error'>{errors.ownerName}</span>}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label htmlFor="profile-settings-name">Owner Email  <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control form-control-lg" id="ownerEmail" name="ownerEmail" onChange={this.handleChange} placeholder="Enter your owner email id" value={this.state.ownerEmail} required="required"/>
                                                <span className="iris_error_txt">{errors.ownerEmail.length > 0 && <span className='error'>{errors.ownerEmail}</span>}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label htmlFor="profile-settings-name">Owner Contact No  <span className="text-danger">*</span></label>
                                                <div className="form-material open">
                                                    <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this,'I')} onMouseLeave={this.onMouseLeaveHandler.bind(this,'I')}style={{borderBottom : 0}}>
                                                        <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName} style={{borderBottom : '0px !important'}}>
                                                            {/* <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div> */}
                                                            <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                            <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                        </div>

                                                        <ul className={this.state._countryReg +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                            {this.getReadyMobileCode()}
                                                        </ul>
                                                    </div>
                                                    <input type="numner" className="form-control form-control-lg" id="ownerContact" name="ownerContact" onChange={this.handleChange} placeholder="Enter Owner Contact No" value={this.state.ownerContact} maxLength="12" autoComplete='off' style={{display: 'inline-block',width:'70%'}} />
                                                </div>
                                                <span className="iris_error_txt">{errors.ownerContact.length > 0 && <span className='error'>{errors.ownerContact}</span>}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label htmlFor="profile-settings-name">Business Address <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control form-control-lg" id="ownerBussAddress" name="ownerBussAddress" onChange={this.handleChange} placeholder="Enter your business address" value={this.state.ownerBussAddress} />
                                                <span className="iris_error_txt">{errors.ownerBussAddress.length > 0 && <span className='error'>{errors.ownerBussAddress}</span>}</span>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToManageProfileInfo}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-asterisk mr-5 text-muted"></i> Change Password
                            </h3>
                        </div>
                        <div className="block-content">
                            <div className="row items-push">
                                <div className="col-lg-3">
                                    <p className="text-muted">
                                        Changing your sign in password is an easy way to keep your account secure.
                                    </p>
                                </div>
                                
                                <div className="col-lg-7 offset-lg-1">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-password">Current Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control form-control-lg" id="currentPassword" name="currentPassword" onChange={this.handlePasswordChange}/>
                                            <span className="iris_error_txt">{errorsPassword.currentPassword.length > 0 && <span className='error'>{errorsPassword.currentPassword}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-password-new">New Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control form-control-lg" id="newPassword" name="newPassword" onChange={this.handlePasswordChange}/>
                                            <span className="iris_error_txt">{errorsPassword.newPassword.length > 0 && <span className='error'>{errorsPassword.newPassword}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-password-new-confirm">Confirm New Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control form-control-lg" id="confirmPassword" name="confirmPassword" onChange={this.handlePasswordChange}/>
                                            <span className="iris_error_txt">{errorsPassword.confirmPassword.length > 0 && <span className='error'>{errorsPassword.confirmPassword}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <button type="submit" disabled={isDisabledPass} className="btn btn-alt-primary" onClick={this.clickToManageProfilePassword}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}