import React, { Component } from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';

import TaxGstFormList from './manageGST';
import TaxGstTypeFormList from './manageGSTType';


let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Type',
        field: 'type',
        width: 50,
    },
    {
        label: 'Name',
        field: 'name',
        width: 150,
    },
    {
        label: 'Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Quantity',
        field: 'quantity',
        width: 100,
    },
    {
        label: 'Price/Unit',
        field: 'price',
        width: 100,
    },
    {
        label: 'Status',
        field: 'status',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class serviceListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            modalIsOpen_CAT : false,
            countryArrayFilter :[],
            countryArray :[],
            taxTypeArray : [],
            taxArray : [],
            country_code:'+91',
            activeCountry:'India',
            activeCountryFlag:'',
            tax_name : '',
            errors: {
                tax_name : '',
            }
        }
    }
    
    async componentDidMount ()
    {
        this.getCountryList();
    }

    /* Country Code Listing */
    async getCountryList(){
        let _urlData = global.allCountryList;
        let res = await PostData(_urlData,'','GET');
        
        if(res.response == null || res.response == undefined){
            window.location.href = global.BASE_URL+'not-found/';
            return false;
        }
        let _companyArray = res.response;
        let _activeCountry,_activeCountryFlag,_tax_name,_country_code = '';
        _companyArray.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            _companyArray[i].flag = global.FLAG_URL+_name+'.png';
            
            if(i == 0){
                _companyArray[i].isActiveClick = true;
                _activeCountry = conty.name;
                _activeCountryFlag = _companyArray[i].flag;
                _tax_name = conty.tax_name;
                _country_code = conty.phonecode;
            }
            else{
                _companyArray[i].isActiveClick = false;
            }
            
        });
        this.setState({countryArrayFilter:_companyArray,countryArray:_companyArray,activeCountry:_activeCountry,activeCountryFlag:_activeCountryFlag,tax_name:_tax_name,country_code:_country_code});
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'tax_name':
                errors['tax_name'] = "";
                this.setState({tax_name:value,errors:errors});
            break;
            default:
            break;
        }
    }
    
    clickToAddjustTaxName = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        
        if(this.state.tax_name == '' || this.state.tax_name == null){
            errors['tax_name'] = "*Please Enter Tax Name";
            this.setState({errors: errors});
            return;
        }

        const requestData = JSON.stringify({
            countryName: this.state.activeCountry,
            taxName:this.state.tax_name
        });
        
        PostData(global.countryAETaxName, requestData,'POST').then((result) => {
            let responseJson = result;                
            if(responseJson.success == false)
            {
                sendNotification("Error Message 😓",responseJson.message,"danger");
                return;
            }
            else
            {
                sendNotification("Success Message 😓",responseJson.message,"success");
                this.setState({tax_name: this.state.tax_name});
                this.closeModalHandler('modalAdjustTaxName');
            }
        });
    }
    
    clickToStatus(value,_status)
    {
        confirmAlert({
            title: 'Confirm to manage status',
            message: 'Are you sure you want to change the status?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        countryId: value,
                        status:_status
                    });
                    PostData(global.countryManageStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getCountryList();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }
    
    loadCountryListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _countryDetails = this.state.countryArrayFilter;
            if(_countryDetails.length > 0){
                _countryDetails.map((value,index) => {
                    let _status = 1;
                    let status = 'Enable';
                    let _txtCountry = <del>{value.name}</del>
                    if (value.status == '1' || value.status == 1) {
                        _status = 0;
                        status =  'Disable';
                        _txtCountry = <b>{value.name}</b>
                    }

                    let _activeClass = 'deactiveRow';
                    //if(this.state.activeCountry == value.name){
                    if (value.status == '1' || value.status == 1) {
                        _activeClass = 'activeRow';
                    }
                    dataList.push(
                        <tr key={"country-"+index} className={_activeClass+" tableRow"} onClick={this.clickToActiveRow.bind(this,value.name,value.flag,value.tax_name,value.status,value.phonecode)}>
                            <td><img src={value.flag} /> {_txtCountry}</td>
                            <th className="text-center" scope="row">
                                <div className="btn-group show" role="group">
                                    <button type="button" className="btn btn-sm btn-secondary mr-5 mb-5" id="toolbarDrop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"> <i className="fa fa-ellipsis-v mr-5 text-info"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right min-width-200" aria-labelledby="toolbarDrop" style={{margin: '0px'}}>
                                        <a className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToStatus.bind(this,value.id,_status)}>
                                            <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>{status} Country
                                        </a>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    );
                });
            }   
        }
        return dataList;
    }
    
    clickToActiveRow(txt,flag,tax,status,country){
        this.setState({activeCountry:txt,activeCountryFlag:flag,tax_name:tax,country_code:country});
        // if(status > 0){
        //     this.setState({activeCountry:txt,activeCountryFlag:flag,tax_name:tax});
        // }
        // else{
        //     sendNotification("Warning Message 😓","Please first active this country.","warning");
        // }
    }

    /* Country Listing Code Here */
    
    /*For Modal open close*/
    openModalHandler(popId)
    {
        if(popId == 'modalAdjustTaxName'){
            this.setState({modalIsOpen_CAT: true});
        }
        else if(popId == 'modalAdjustTax'){
            this.setState({modalIsOpen_CTT: true});
        }
        else if(popId == 'modalAdjustTaxType'){
            this.setState({modalIsOpen_CTA: true});
        }
    }
    closeModalHandler = (popId) => {
        
        let errors = this.state.errors; 
        if(popId == 'modalAdjustTaxName'){
            errors['tax_name'] = "";
            this.setState({modalIsOpen_CAT: false,errors:errors});
            this.getCountryList();

        }
        else if(popId == 'modalAdjustTax'){
            this.setState({modalIsOpen_CTT: false});
        }
        else if(popId == 'modalAdjustTaxType'){
            this.setState({modalIsOpen_CTA: false});
        }
    }
    /*End Modal open close*/

    /* Country Filter Code */
    onCountryFilterName = (e) => {
        const data = this.state.countryArray;
        let _name = e.currentTarget.value;
        const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
        const filtered = data.filter(entry => Object.values(entry).some(val => typeof val == "string" && val.includes(keyword)));
        this.setState({countryArrayFilter:filtered});
        this.loadCountryListing();
    }
    /* End Country Filter Code */

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _taxName = this.state.tax_name;
        if(_taxName == '' || _taxName == null){
            _taxName = 'Not Set';
        }
        
        const {errors} = this.state;        
        const isDisabled = Object.keys(errors).some(x => errors[x]);

        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Country List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"user/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage Country List</span>
                    </nav>
                    
                    <div className="row">
                        <div className="col-md-3">
                            <div className="block">
                                <div className="block pull-r-l">
                                    <div className="block-content">
                                        <div className="mb-10">
                                            <div className="input-group input-group-lg">
                                                <input
                                                    type="text"
                                                    className="form-control search-box"
                                                    onChange={this.onCountryFilterName}
                                                    // onKeyDown={onKeyDown}
                                                    placeholder="Search here..."
                                                    autocomplete="off"
                                                />
                                                <div className="input-group-append">
                                                    <button type="submit" className="btn btn-sm btn-secondary">
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{borderBottom: '1px solid #e4e7ed'}}>
                                    </div>
                                    <div className="block-content" data-toggle="slimscroll" data-height="600px" data-color="#cccccc" data-opacity="1" data-always-visible="true">
                                        <table className="table table-vcenter">
                                            <thead id="">
                                                <tr>
                                                    <th>Country Name</th>
                                                    <th style={{width: '50px'}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody id="sideTable">
                                                {this.loadCountryListing()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="block">
                                <div className="block-content">
                                    <div className="row items-push">
                                        <div className="col-lg-12">
                                            <div>
                                                <p className="text-muted">
                                                <b><img src={this.state.activeCountryFlag}/> {this.state.activeCountry}</b> <i className="fa fa-map-marker"></i>
                                                
                                                <button type="button" className="btn btn-sm btn-secondary mr-5 mb-5 pull-right" onClick={this.openModalHandler.bind(this,'modalAdjustTaxName')}>
                                                    <i className="si si-equalizer mr-5"></i>Adjust Tax Name
                                                </button>
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-muted">
                                                    <span>Country Tax Name : <b>{_taxName}</b></span>
                                                </p>
                                                <p style={{fontSize:'10px'}}>
                                                <span><b><i className="si si-info"></i> Note: This tax name will use be use as TAX title in all transaction.</b></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block">
                                <div className="block-content">
                                    <TaxGstTypeFormList taxName={_taxName} countryCode={this.state.country_code}/>
                                </div>
                            </div>

                            <div className="block">
                                <div className="block-content">
                                    <TaxGstFormList taxName={_taxName} countryCode={this.state.country_code}/>
                                </div>
                            </div> 
                        </div>
                    </div>                    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

                {/* Modal Edit Party Details Begins */}
                <Modal isOpen={this.state.modalIsOpen_CAT} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustTaxName">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Addjust Tax Name</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustTaxName')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content">
                                    <div className="block-content">
                                    <form method="post" id="form-login" className="webForm px-30">
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" className="form-control" id="tax_name" name="tax_name" value={this.state.tax_name} placeholder="Tax Name Here" onChange={this.handleChange}/>
                                                    <label htmlFor="login-username">Tax Name <span className="text-danger">*</span></label>
                                                    <span className="vs_error_txt">{errors.tax_name.length > 0 && <span className='error'>{errors.tax_name}</span>}</span>
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="claerfix"></div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddjustTaxName}>
                                                    <i className="fa fa-check mr-5"></i> Save Tax Name Details
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                    <br/>
                                    
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustTaxName')}>Close</button>
                                
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Modal End Edit Party Details*/}

                
            </React.Fragment>
        )    
    }
}