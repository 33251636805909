global.createPurchaseTransaction = global.API_USER_URL+'createPurchaseTransaction/';
global.editPurchaseTransaction = global.API_USER_URL+'editPurchaseTransaction/';

global.createPurchaseOrderTransaction = global.API_USER_URL+'createPurchaseOrderTransaction/';
global.editPurchaseOrderTransaction = global.API_USER_URL+'editPurchaseOrderTransaction/';

global.createPurchaseReturnTransaction = global.API_USER_URL+'createPurchaseReturnTransaction/';
global.editPurchaseReturnTransaction = global.API_USER_URL+'editPurchaseReturnTransaction/';

global.createPurchasePaymentOutTransaction = global.API_USER_URL+'createPurchasePaymentOutTransaction/';
global.editPurchasePaymentOutTransaction = global.API_USER_URL+'editPurchasePaymentOutTransaction/';

global.userPurchaseTransactionList = global.API_USER_URL+'userPurchaseTransactionList/';
global.createPurchaseDuplicateTransaction = global.API_USER_URL+'createPurchaseDuplicateTransaction/';
global.createPurchaseInToReturnTransaction = global.API_USER_URL+'createPurchaseInToReturnTransaction/';

global.createPurchasePaymentOutRecevTransaction = global.API_USER_URL+'createPurchasePaymentOutRecevTransaction/';

global.createSalePaymentInTransactionViaPurchase = global.API_USER_URL+'createSalePaymentInTransactionViaPurchase/';
global.createPurchaseDuplicateOrderTransaction = global.API_USER_URL+'createPurchaseDuplicateOrderTransaction/';
