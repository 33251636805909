import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,
            modalIsOpen_Info : false,
            _infoData : '',
            isChallanActive : (_businessSetting.DeliveryChallan > 0) ? true : false,
            _infoTitle : 'Information',
        }
    }

    /* Checkbox Chnage */
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id : _companyId,
            passcode : 0
        });

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");
                if(_key == 'DeliveryChallan'){
                    this.setState({isChallanActive : (_value > 0) ? true : false});
                }
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Checkbox Chnage */

    allCompanyFirmList()
    {
        // let _rawData = [];
        // if(_allCompanyFirm.length > 0){
        //     _allCompanyFirm.map((comData, i) =>{
        //         _rawData.push(
        //             <div>
        //                 <input name="companyActive" type="radio" value={comData.company_id}/>
        //                 <span>{comData.company_name}</span>
        //                 {comData.isActive}
        //                 <i className="si si-pencil" />
        //             </div>
        //         );
        //     });
        // }
        // return _rawData;

        let dataList = [];
        if(_allCompanyFirm.length > 0)
        {
            let _activeCompany = 'Change Company';                    
            _allCompanyFirm.map((compDatas, i) =>
            {
                const row = compDatas;
                let _iconActive = '';
                if(row.isActive == true){
                    _activeCompany = row.company_name;
                    _iconActive = ' text-info';
                }
                dataList.push(
                    <a key={i} className={"dropdown-item"+_iconActive} href="javascript:void(0)" onClick={this.updateCompany.bind(this,row.company_id)}>
                        <i className="fa fa-check"></i> {row.company_name}
                    </a>
                )
            });
            return(
                <div className="dropdown d-inline-block" role="group">
                    <button type="button" className="btn btn-dual-secondary dropdown-toggle active" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {_activeCompany}
                    </button>
                    <div className="dropdown-menu min-width-200" aria-labelledby="page-header-user-dropdown">
                        {dataList}
                    </div>
                </div>
            );
        }
    }

    updateCompany(value)
    {
        const requestData = JSON.stringify({
            userId : _signingId,
            company_id : value
        });
        PostData(global.userUpdateCompanyPriority, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                //console.log(result);
            }
        });

        let _UserSession = localStorage.getItem('vs_UserSession');             
        _UserSession = JSON.parse(_UserSession);
        let _updateLocal = _UserSession;
        let _comData = _updateLocal.companies;
        _comData.map((compDatas, i) =>{
            _comData[i].isActive = false;
            if(value == _comData[i].company_id){
                _comData[i].isActive = true;
                localStorage.removeItem('vs_UserCompanySession');
                localStorage.setItem('vs_UserCompanySession', JSON.stringify(compDatas));
            }
        });
        _updateLocal.company_id = value;
        _updateLocal.companies = _comData;
        localStorage.removeItem('vs_UserSession');
        localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
        this.setState({reChangeComapny:true});
        window.location.reload();
    }
    
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    } 

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _toolPartyGSTINNumber = "What is this? <br/> You can enter GSTIN/TIN of the party while adding a party to Vyavsay. This GSTIN/TIN will be printed invoices to the parties. <br/><br/> Why to use? <br/> In case you want Party's GSTIN/TIN no, to be printed on invoice then you can enable this."; 

        let _toolEstimateQuitation = "What is this? <br/> Enables the feature for generating Estimate/Quotation. <br/><br/> How it is used? <br/> Vyavsay will show Estimate/Quotation transaction on enabling this setting. <br/><br/> Why to use? <br/>  You can make Estimate or Quotation and share them with your parties. You can easily convert an estimate/quotation into sale invoice anytime.";

        let _toolSalePurchaseOrder = "What is this? <br/> Allows you to make sale/Purchase orders and convert them to Sale Invoices/ Purchase Bills. <br/><br/> How it is used? <br/> Vyavsay will show Sale and Purchase transactions on enabling setting.";

        let _toolOtherIncome = "What is this? <br/> You can maintain income from other sources apart form your direct business in vyavsay. This income will be shown in your Profilt and Loss report. <br/><br/>  How it is used? <br/> Enabling this settings will show Other Income type of transaction. You can use it for maintaning other income in vyavsay. <br/><br/> Why to use? <br/> To track incomes from various sources other than your primary business at one place. E.g. Rent received etc.";

        let _toolDeliveryChallan = "What is this? <br/> Enables the feature for generating Delivery challan <br/><br/> How it is used? <br/> Vyavsay will show Delivery Challan transaction on enabling this setting.<br/><br/> Why to use? <br/> You can make Delivery Challans and Share them with your parties. You can easily convert a delivery challan into sale sale invoice anytime.";

        let _toolDeliveryChallanGood = "What is this? <br/> Enables you to return goods that are sent back or are not delivered after making delivery challan <br/><br/> How it is used? <br/> While Converting Delivery Challan to sale inovoice, you can specify the quantity of items returned or not delivered to your party. <br/> <br/> Why to use? <br/> If your party do not accept full delivery or reutrns some defective items back, you can easily enter them in vyavsay while converting a Delivery Challan to a Sale Invoice.";

        let _toolDeliveryChallanPrint = "What is this? <br/> Enables you to print amount, description and tax details on delivery challan. <br/> <br/> Why to use? <br/> This allows you to turn on total amount or pricing detail in delivery challan. This setting can be turned off if you dont want your transaporter to know about the pricing of the transaction. ";


        let _subMenuChallan = '';
        if(this.state.isChallanActive == true){
            _subMenuChallan = (
                <ul style={{marginLeft: '15px',marginTop: '15px'}}>
                    <li style={{listStyle: 'none',marginBottom: '10px'}}>
                        <input type="checkbox" defaultChecked={(_businessSetting.DeliveryChallanGood > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="DeliveryChallanGood" onChange={this.onFilterChange}/>
                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Goods Return on <b>Delivery Challan</b> <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDeliveryChallanGood,'Goods Return on Delivery Challan')}></i></span>
                    </li>
                    <li style={{listStyle: 'none',marginBottom: '10px'}}>
                        <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.DeliveryChallanPrint > 0) ? true : false} name="activeItem" value="1" data-slug="DeliveryChallanPrint" onChange={this.onFilterChange}/>
                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Amount on <b>Delivery Challan</b> <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDeliveryChallanPrint,'Print Amount in Delivery Challan')}></i></span>
                    </li>
                </ul>
            );
        }
        return(
            <React.Fragment>
                <div className="row items-push" style={{marginTop: '-50px'}}>
                        <div className="col-md-6">
                            <h5 className="content-heading">Application</h5>
                            <ul>
                                <li style={{listStyle: 'none'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.GSTINNumber > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="GSTINNumber" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>GSTIN Number <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyGSTINNumber,'GSTIN Number ')}></i></span>
                                </li>
                            </ul>

                            <h5 className="content-heading">More Transactions</h5>
                            <ul>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.EstimateQuitation > 0) ? true : false} name="activeItem" value="1" data-slug="EstimateQuitation" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Estimate/Quotation (Proforma Invoice) <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolEstimateQuitation,'Estimate/Quotation')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.OtherIncome > 0) ? true : false} name="activeItem" value="1" data-slug="OtherIncome" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Other Income <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolOtherIncome,'Other Income')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.SalePurchaseOrder > 0) ? true : false} name="activeItem" value="1" data-slug="SalePurchaseOrder" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Sale/Purchase Order <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolSalePurchaseOrder,'Sale/Purchase Order ')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.DeliveryChallan > 0) ? true : false} name="activeItem" value="1" data-slug="DeliveryChallan" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Delivery Challan <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDeliveryChallan,'Delivery Challan')}></i></span>
                                    
                                    {_subMenuChallan}
                                    
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h5 className="content-heading">Multi Firm</h5>
                            {this.allCompanyFirmList()}
                        </div>
                    
                </div>
                {/* Modal  Tooltip Information Begins */}
                <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">{this.state._infoTitle}</h3>
                                        <div className="block-options">
                                            <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                                <i className="si si-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                    {parse(this.state._infoData)}
                                    </div>
                                    <br/>
                                </div>
                                
                            </div>
                        </div>
                    </Modal>
                    {/* Modal End Tooltip Information*/}
            </React.Fragment>
        )
    }
} 