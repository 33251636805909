import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import QuickAddUniConversion from "../userItem/quickUnitConversion";
import QuickUniConversionList from "./quickUnitConversionList";

import Modal from "react-modal";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let company_id = "0";
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  company_id = _UserSession.company_id;
}
global.BACK_URL = "Unit";

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Unit Name",
    field: "unit_name",
    width: 150,
  },
  {
    label: "Unit Slug",
    field: "slug",
    width: 150,
  },
  {
    label: "Action",
    field: "action",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class companyListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",
      isLoaded: true,
      unit_name: null,
      slug: null,
      modalIsOpen_ActiveUnitConversion: false,
      modalIsOpen_ActiveUnitConversionList: false,
      businessId: _businessId,
      userId: _signingId,
      company_id: company_id,
      gstArray: [],
      unitId: 0,
      errors: {
        unit_name: "",
        slug: "",
      },
    };
  }

  async componentDidMount() {
    /* Get Url Pass Parameter */
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _unitId = _handleParam[1] ? _handleParam[1] : 0;
    /* End Here Url Pass Parameter */

    if (_unitId > 0) {
      let _gstArray = [];
      let _url_GetData = global.userUnitDetails + "?unitId=" + _unitId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _gstArray = responseJson.response[0];
              this.setState({
                buttonTitle: "Edit",
                unit_name: _gstArray.unit_name,
                unitId: _gstArray.unit_id,
                slug: _gstArray.slug,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
    this.getGstListData();
  }

  getGstListData() {
    let _gstArray = [];
    let _url_GetData =
      global.userUnitList + "?businessId=" + this.state.businessId;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _gstArray = responseJson.response;
          }
          this.setState({ gstArray: _gstArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: true });
    }
  }
  openModalHandlerUnit(popId, unitId) {
    if (popId == "modalUnitConversionList") {
      this.setState({
        modalIsOpen_ActiveUnitConversionList: true,
        _unitSendId: unitId,
      });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: false });
    } else if (popId == "modalUnitConversionList") {
      this.setState({ modalIsOpen_ActiveUnitConversionList: false });
    }
  };
  /*End Modal open close*/

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "unit_name":
        errors.unit_name =
          value.length < 3 ? "*Unit name must be 3 characters long!" : "";
        this.setState({ unit_name: value });
        break;
      case "slug":
        errors.slug =
          value.length < 2 ? "*Unit slug must be 2 characters long!" : "";
        this.setState({ slug: value });
        break;
      default:
        break;
    }
  };

  clickToAddGst = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    //console.log(errors);
    let _isError = false;
    if (this.state.unit_name == null) {
      _isError = true;
      errors["unit_name"] = "*Please Enter Unit Name";
      this.setState({ errors: errors });
    }
    if (this.state.slug == null) {
      _isError = true;
      errors["slug"] = "*Please Enter Slug";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.unitId > 0) {
        this.editGstMethodCall();
      } else {
        this.addGstMethodCall();
      }
    }
  };

  editGstMethodCall() {
    const requestData = JSON.stringify({
      unit_name: this.state.unit_name,
      slug: this.state.slug,
      unit_id: this.state.unitId,
      businessId: this.state.businessId,
    });
    PostData(global.userEditUnit, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          window.location.href = global.webUrl + "business/manage-unit/";
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        let errors = this.state.errors;
        errors["unit_name"] = result.message;
        this.setState({
          errors: errors,
        });
        return;
      }
    });
  }

  addGstMethodCall() {
    const requestData = JSON.stringify({
      unit_name: this.state.unit_name,
      slug: this.state.slug,
      businessId: this.state.businessId,
    });

    PostData(global.userAddUnit, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          window.location.href = global.webUrl + "business/manage-unit/";
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        let errors = this.state.errors;
        errors["unit_name"] = result.message;
        this.setState({
          errors: errors,
        });
        return;
      }
    });
  }

  deleteUnit(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              unit_id: value,
            });
            PostData(global.userUnitDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getGstListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }
  clickUnitInfo() {
    console.log("Tabish");
    confirmAlert({
      title: "Unit Alert",
      message:
        "This unit can not be deleted as it already has transactions or items. Please delete all transactions and update items unit before deleting the unit.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }
  loadOrderListing() {
    if (this.state.isLoaded == true) {
      let _gstDetails = this.state.gstArray;
      let dataList = [];
      let rows = [];
      if (_gstDetails.length > 0) {
        _gstDetails.map((compDatas, i) => {
          const row = compDatas;
          let _action = "N/A";
          if (row.isEdit == true) {
            let _delOption = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.deleteUnit.bind(this, row.unit_id)}
              >
                <i className="si si-trash mr-5 text-danger"></i>Delete Details
              </a>
            );

            if (row.isEdit == true && row.isTransaction == true) {
              _delOption = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={this.clickUnitInfo.bind(this, row.unit_id)}
                >
                  <i className="si si-trash mr-5 text-danger"></i>Delete Details
                </a>
              );
            }
            _action = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="si si-list"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/manage-unit/?auth=" +
                      row.unit_id
                    }
                  >
                    <i className="si si-pencil mr-5 text-info"></i>Edit Details
                  </a>
                  {_delOption}
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    onClick={this.openModalHandlerUnit.bind(
                      this,
                      "modalUnitConversionList",
                      row.unit_id
                    )}
                    style={{ margin: "0px" }}
                  >
                    <i className="fa fa-eye ">View Unit Conversion</i>
                  </a>
                </div>
              </div>
            );
          } else {
            _action = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    onClick={this.openModalHandlerUnit.bind(
                      this,
                      "modalUnitConversionList",
                      row.unit_id
                    )}
                    style={{ margin: "0px" }}
                  >
                    <i className="fa fa-eye ">View Unit Conversion</i>
                  </a>
                </div>
              </div>
            );
          }
          rows.push({
            unit_name: row.unit_name,
            slug: row.slug,
            srNo: row.srNo,
            action: _action,
          });
        });
      }

      if (rows) {
        dataList = {
          columns: columns,
          rows: rows,
        };
      }
      return dataList;
    }
  }

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_ActiveUnitConversion: false });
    this.getGstListData();
  };
  /* End Here */
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Unit List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">Manage Unit List</span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Add / Edit Unit</h3>
            </div>
            <div className="block-content">
              <form method="post" id="form-login" className="webForm px-30">
                <div className="form-group row">
                  <div className="col-6">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        className="form-control"
                        id="unit_name"
                        name="unit_name"
                        value={this.state.unit_name}
                        placeholder="Unit Name Here"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-username">
                        Unit Name <span className="text-danger">*</span>
                      </label>
                      <span className="iris_error_txt">
                        {errors.unit_name.length > 0 && (
                          <span className="error">{errors.unit_name}</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-material open">
                      <input
                        type="text"
                        required="required"
                        className="form-control"
                        id="slug"
                        name="slug"
                        value={this.state.slug}
                        placeholder="Unit Slug Here"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-username">
                        Unit Slug <span className="text-danger">*</span>
                      </label>
                      <span className="iris_error_txt">
                        {errors.slug.length > 0 && (
                          <span className="error">{errors.slug}</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="btn btn-alt-primary"
                      onClick={this.clickToAddGst}
                    >
                      <i className="fa fa-check mr-5"></i>{" "}
                      {this.state.buttonTitle} Unit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">List of Unit</h3>
              <a
                className="btn btn-primary"
                href="javascript:void(0)"
                onClick={this.openModalHandler.bind(
                  this,
                  "modalUnitConversion"
                )}
                style={{ margin: "0px" }}
              >
                {" "}
                Set Conversion{" "}
              </a>
            </div>
            <div className="block-content">
              <MDBDataTableV5
                hover
                entriesOptions={[25, 50, 100]}
                entries={25}
                pagesAmount={4}
                span
                pagingTop
                searchTop
                searchBottom={false}
                barReverse
                data={this.loadOrderListing()}
              />
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div class="gl-ldr-cld">
            <div class="gl-ldr-ctr">
              <div class="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Add  Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversion}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversion"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Set Conversion</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversion"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddUniConversion
                    isRedirectTo={"business/manage-unit/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversion"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Unit Conversion Details*/}

        {/* Modal List Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversionList}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversionList"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Conversion List</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversionList"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickUniConversionList getUnitId={this.state._unitSendId} />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversionList"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal List Unit Conversion Details*/}
      </React.Fragment>
    );
  }
}
