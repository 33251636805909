import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};

let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        
        this.state = {
            isLoaded : false,
            modalIsOpen_Info : false,
            _infoData : '',
            SMSSendSelf : (_businessSetting.SMSSendSelf > 0) ? true : false,
            SMSSendSelfMobNo : _businessSetting.SMSSendSelfMobNo,
            _infoTitle : 'Information',
        }
    }
    
    /* Checkbox Chnage */
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id : _companyId,
            passcode : 0
        });

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");
                if(_key == 'SMSSendSelf'){
                    this.setState({SMSSendSelf : (_value > 0) ? true : false});
                }
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Checkbox Chnage */
    
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',infoTitle:''});
        }
    } 
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'SMSSendSelfMobNo'){
            this.setState({SMSSendSelfMobNo:activeValue}); 
        }       
        
       
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id : _companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _subMenuTimePrint = '';
        if(this.state.SMSSendSelf == true){
            _subMenuTimePrint = (
                <ul style={{marginLeft: '15px',marginTop: '15px'}}>
                    <li style={{listStyle: 'none',marginBottom: '10px'}}>
                        <input type="number"  data-slug="SMSSendSelfMobNo" id="SMSSendSelfMobNo" name="SMSSendSelfMobNo" value ={this.state.SMSSendSelfMobNo} onChange={this.handleLTxtKeyUp} placeholder='Phone Number'/>
                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Phone Number</span>
                    </li>
                </ul>
            );
        }
        
        return(
            <React.Fragment>
                <div className="row items-push" style={{marginTop: '-50px'}}>
                        <div className="col-md-6">
                            <h5 className="content-heading">Transaction SMS</h5>
                            <ul>
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSSendParty" name="SMSSendParty" data-slug="SMSSendParty" defaultChecked={(_businessSetting.SMSSendParty > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Send to party </span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSSendSelf" name="SMSSendSelf" data-slug="SMSSendSelf" defaultChecked={(_businessSetting.SMSSendSelf > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Send SMS Copy to Self </span>
                                    {_subMenuTimePrint}
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSShowPartyCurrentBal" name="SMSShowPartyCurrentBal" data-slug="SMSShowPartyCurrentBal" defaultChecked={(_businessSetting.SMSShowPartyCurrentBal > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Show Party's Current Balance </span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSShowWebInvLink" name="SMSShowWebInvLink" data-slug="SMSShowWebInvLink" defaultChecked={(_businessSetting.SMSShowWebInvLink > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Show web invoice link </span>
                                </li>
                            </ul>

                            <h5 className="content-heading">Sale transactions for automatic messaging</h5>
                            <ul>                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSSale" name="SMSSale" data-slug="SMSSale" defaultChecked={(_businessSetting.SMSSale > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Sale </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSPurchase" name="SMSPurchase" data-slug="SMSPurchase" defaultChecked={(_businessSetting.SMSPurchase > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Purchase </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSSaleReturn" name="SMSSaleReturn" data-slug="SMSSaleReturn" defaultChecked={(_businessSetting.SMSSaleReturn > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Sale Return </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSPurchaseReturn" name="SMSPurchaseReturn" data-slug="SMSPurchaseReturn" defaultChecked={(_businessSetting.SMSPurchaseReturn > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Purchase Return </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSEstimate" name="SMSEstimate" data-slug="SMSEstimate" defaultChecked={(_businessSetting.SMSEstimate > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Estimate </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSPatmentIn" name="SMSPatmentIn" data-slug="SMSPatmentIn" defaultChecked={(_businessSetting.SMSPatmentIn > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Patment-In </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSPaymentOut" name="SMSPaymentOut" data-slug="SMSPaymentOut" defaultChecked={(_businessSetting.SMSPaymentOut > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Payment-Out </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSSaleOrder" name="SMSSaleOrder" data-slug="SMSSaleOrder" defaultChecked={(_businessSetting.SMSSaleOrder > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Sale Order </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSPurchaseOrder" name="SMSPurchaseOrder" data-slug="SMSPurchaseOrder" defaultChecked={(_businessSetting.SMSPurchaseOrder > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Purchase Order </span>
                                </li>                             
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="SMSDeliveryChallan" name="SMSDeliveryChallan" data-slug="SMSDeliveryChallan" defaultChecked={(_businessSetting.SMSDeliveryChallan > 0) ? true : false}  value="1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Delivery Challan </span>
                                </li>
                                
                            </ul>

                            
                        </div>
                    
                </div>
                
                {/* Modal  Tooltip Information Begins */}
                <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">{this.state._infoTitle}</h3>
                                        <div className="block-options">
                                            <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                                <i className="si si-close"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                    {parse(this.state._infoData)}
                                    </div>
                                    <br/>
                                </div>
                                
                            </div>
                        </div>
                    </Modal>
                    {/* Modal End Tooltip Information*/}
            </React.Fragment>
        )
    }
} 