import React, { Component } from "react";
import { PostData } from "../../service/postData";
import { numberFormat } from "../../config/numberFormat";
import Modal from "react-modal";
import moment from "moment";
import jsPDF from "jspdf";
import html2PDF from "jspdf-html2canvas";
import { ToWords } from "to-words";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

let _UserSession = localStorage.getItem("vs_UserSession");
let _vLogoBaseDecode = "";
let _mainLogoBaseDecode = global.webUrl + "websiteFilesFolder/img/logo.png";
let _companySignatureLen = 0;
let _companySignature = "NA";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _companyEmail = "";
let _companyPhone = "";
let _signingId = "0";
let _tax_name = "";
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _company_id = _UserSession.company_id;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);

    
    _companySignature = _companySession.company_signature;
  _company_id = _companySession.company_id;
  _countryCode = _companySession.country_code;
  _countryName = _companySession.country_name;
  _companyEmail = _companySession.email;
  _companyPhone = _companySession.phone;

}

let _signatureBaseDecode = _companySignature;

let _invoiceName = "otherIncomeInvoiceRecipt";

const footer = {
  //position: 'absolute',
  //top: '90%',
  //width: '93%',
  display: "none",
};

let _loader = "glb-ldr-prt active";

export default class userPDFPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceRtnHide: "hide",
      modalIsOpen_PDF: true,
      isLoaded: false,
      transactionId: this.props.transactionId,
      companyEmail: _companyEmail,
      companyPhone: _companyPhone,
      invoiceDate: "",
      invoiceTime: "",
      logo: "NA",
      paymentType: "Cash",

      titleTxt: "Other Income",
      billTxt: "Other Income",
      billingName: "",
      description: "",
      totalAmount: 0,
      subtotal: 0,
      totalQty: 0,
      returnUrl: "",
      printTerms: 0,
      printTermsMsg: "",
      totalAmtInChar: 0,
      categoryName: "",
      firm_additional_field1: "",
      firm_additional_value1: "",
      firm_additional_field2: "",
      firm_additional_value2: "",
      items: [
        {
          id: 1,
          item_name: "Sample Item 1",
          quantity: 1,
          rate: 50,
          totalAmount: (50 * 10 * 18) / 100 + 50 * 10,
        },
      ],
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");


      if (_mainLogoBaseDecode.length > 15) {
          await this.toDataURL(_mainLogoBaseDecode, function (dataUrl) {
              _vLogoBaseDecode = dataUrl;
          });
      }
      if (_companySignature!="undefined" && _companySignature!=undefined) {
          if (_companySignature.length > 15) {
              _companySignatureLen = _companySignature.length;
              await this.toDataURL(_companySignature, function (dataUrl) {
                  _signatureBaseDecode = dataUrl;
              });
          }
      }

      let transactionId = _handleParam[1] ? _handleParam[1] : 0;
      if (transactionId > 0) {
          this.getTransactionDetails(transactionId);
          this.openModalHandler("modalAdjustPDFDownload");
          this.setState({ transactionId: transactionId });
          this.setState({ transactionId: transactionId,_companySignatureLen:_companySignatureLen });
      }
  }
    toDataURL(src, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
            var canvas = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);

            callback(dataURL);
        };
        img.src = src;
        if (img.complete || img.complete == undefined) {
            img.src =
                "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
        }
    }
  async getTransactionDetails(transactionId) {
    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.otherIncomeTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({});
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;
              let _itemsArray = [];

              itemsData.map((items, index) => {
                let _obj = {};
                _obj.item_name = items.item_name;
                _obj.quantity = items.quantity;
                _obj.rate = items.rate;
                //_obj.subTotalAmount = items.subtotal;
                _obj.totalAmount = items.subtotal;
                _itemsArray.push(_obj);
              });
              let _titleTrans = "Other Income";
              let _billTrans = "Other Income";
              let _returnUrl = global.webUrl + "business/other-income/";
              let _printTerms = 0;
              let _printTermsMsg = "";

              _printTermsMsg = _businessSetting.TermCondSaleInvMsg;
              _printTerms = _businessSetting.TermCondSaleInvMsgPrint;
              _titleTrans = "Other Income";

              let _paymentType = _transactionArray.paymentType;
              if (_transactionArray.paymentType == "Bank") {
                _paymentType =
                  _transactionArray.paymentType +
                  " ( " +
                  _transactionArray.bankName +
                  " )";
              } else if (_transactionArray.paymentType == "Cheque") {
                _paymentType =
                  _transactionArray.paymentType +
                  " ( " +
                  _transactionArray.paymentRefNo +
                  " )";
              }

              if (_businessSetting.OtherIncomeTransName.length > 1) {
                _titleTrans = _businessSetting.OtherIncomeTransName;
              }

              this.setState({
                isLoaded: true,
                billingName: _transactionArray.billing_name,
                categoryName: _transactionArray.category_name,
                invoiceTime: (_businessSetting.Tran_AddTimePrint == 1 || _businessSetting.Tran_AddTimePrint == "1") ? _transactionArray.invoice_time : 0,
                invoiceDate: _transactionArray.invoice_date,
                dueDate: _transactionArray.dueDate,
                description: _transactionArray.description,
                totalAmtInChar: transAmout.totalAmount,
                totalAmount: numberFormat(transAmout.totalAmount),
                subtotal: numberFormat(transAmout.subtotal),
                totalQty: transAmout.totalQty,
                paymentType: _paymentType,
                items: _itemsArray,
                titleTxt: _titleTrans,
                billTxt: _billTrans,
                returnUrl: _returnUrl,
                printTermsMsg: _printTermsMsg,
                printTerms: _printTerms,
                firm_additional_field1:
                  _transactionArray.firm_additional_field1,
                firm_additional_value1:
                  _transactionArray.firm_additional_value1,
                firm_additional_field2:
                  _transactionArray.firm_additional_field2,
                firm_additional_value2:
                  _transactionArray.firm_additional_value2,
              });

              if (_businessSetting.AmountWithDecimal == 0) {
                this.setState({
                  totalAmount: parseInt(transAmout.totalAmount),
                  subtotal: parseInt(transAmout.subtotal),
                  totalQty: parseInt(transAmout.totalQty),
                  totalAmtInChar: transAmout.totalAmount,
                });
              }
            }
          }
        })
        .catch((error) => this.setState({ error }));
    }
  }

  async clickToGenratePDF(value) {
    let _currentDate = moment().format("MM-DD-YYYY-HH-mm-GG");
    const printArea = document.getElementById("pdfFormatReceipt");
    const pdf = await html2PDF(printArea, {
      html2canvas: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      },
      imageType: "image/png",
      output: _invoiceName + _currentDate + ".pdf",
    });
  }

  async clickToGenratePrint(e) {
    /*let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

        mywindow.document.write(`<html><head><title>Test NJ</title>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById("pdfFormatReceipt").innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10

        mywindow.print();
        mywindow.close();*/

    let printContents = document.getElementById("pdfFormatReceipt").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.print();
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    this.getTransactionDetails();
    if (popId == "modalAdjustPDFDownload") {
      this.setState({ modalIsOpen_PDF: true });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustPDFDownload") {
      this.setState({ modalIsOpen_PDF: false });
    }
  };
  /*End Modal open close*/

  getTotalCalculation() {
    let totalAmtCode = (
      <tr style={{ background: "#9f9fdf" }}>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#ffffff",
            lineHeight: "24px",
          }}
        >
          Total
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#ffffff",
            lineHeight: "24px",
          }}
        >
          {this.state.totalAmount}
        </td>
      </tr>
    );

    let subTotalAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Subtotal
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.subtotal}
        </td>
      </tr>
    );

    let paymentAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Payment Mode
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.paymentType}
        </td>
      </tr>
    );

    let _challanCssTotal = "";

    if (this.state.totalQty == "0") {
      subTotalAmtCode = "";
      paymentAmtCode = "";
    }

    return (
      <div>
        <table
          cellPadding="0"
          cellSpacing="0"
          width="100%"
          className={_challanCssTotal}
        >
          <tr>
            <td colSpan="2" height="10">
              &nbsp;
            </td>
          </tr>
          {subTotalAmtCode}

          {totalAmtCode}

          {_businessSetting.PaymentMode == 1 && paymentAmtCode}

          <tr>
              {_businessSetting.PrintSignatureText == 1 ? (
                  <td  width="100%"  valign="bottom" align="right">
                      {/* image here */}
                      {_companySignatureLen > 15 && (
                          <img src={_companySession.company_signature} height="100" width="100" />
                      )}
                      {_companySignatureLen > 15 && (
                          <p><b>Authorised Signature</b></p>
                      )}
                  </td>
              ) : <td colSpan="2" height="50">
                  &nbsp;
              </td>}

          </tr>

        </table>
      </div>
    );
  }
    
    printDemo() {

      let _extra = "";

      let _hideItemPrice = "";

      let _showTransportD = 0;
      if (
          (this.state.TransportDetailField1!=null &&
              this.state.TransportDetailField1!="") ||
          (this.state.TransportDetailField2!=null &&
              this.state.TransportDetailField2!="") ||
          (this.state.TransportDetailField3!=null &&
              this.state.TransportDetailField3!="") ||
          (this.state.TransportDetailField4!=null &&
              this.state.TransportDetailField4!="")
      ) {
          _showTransportD = 1;
      }
      let amt = this.state.totalAmtInChar;
      let _totalAmtInChar = "";
      if (amt > 0) {
          let returnData = new ToWords({
              converterOptions: { ignoreDecimal: false },
          }).convert(amt ? amt : 0);
          _totalAmtInChar = returnData + " Only";
      } else {
          _totalAmtInChar = "Zero";
      }
      let _companyAddressPrint = this.state.companyAddress
          ? this.state.companyAddress.length
          : 0;
      let _stateNamePrint = this.state.stateNamePrint
          ? this.state.stateNamePrint.length
          : 0;
      let _itemName = _businessSetting.item_name ? _businessSetting.item_name : "Item Name";
      let _si_no = "#";
      let _item_code = "Item code";
      let _hsn_sac = "HSN/SAC";
      let _quantity = "Qty";
      let _unit = "Unit";
      let _discount = "Discount";
      let _price_per_unit = "Rate";
      let _total_amount = "Amount";
      let _addditional_cess = _businessSetting.addditional_cess ? _businessSetting.addditional_cess : "Ad. CESS Tabish";

      if (_businessSetting.si_enable == 1) {
          _si_no = _businessSetting.si_no;
      }
      if (_businessSetting.item_code_enabled == 1) {
          _item_code = _businessSetting.item_code;
      }
      if (_businessSetting.hsn_sac_enable == 1) {
          _hsn_sac = _businessSetting.hsn_sac;
      }
      if (_businessSetting.quantity_enable == 1) {
          _quantity = _businessSetting.quantity;
      }
      if (_businessSetting.unit_enabled == 1) {
          _unit = _businessSetting.unit;
      }
      if (_businessSetting.price_per_unit_enabled == 1) {
          _price_per_unit = _businessSetting.price_per_unit;
      }
      if (_businessSetting.discount_enable == 1) {
          _discount = _businessSetting.discount;
      }
      if (_businessSetting.total_amount_enable == 1) {
          _total_amount = _businessSetting.total_amount;
      }
      let _finalTranWiseTaxPer = parseFloat(this.state.transactionWiseTaxPercentage);
      let _finalTranWiseTaxAmt = parseFloat(this.state.totalTransTaxAmount);
      
      // for Excel export xls
      if (this.state.isLoaded == true) {
          return (

              <table
                  cellPadding=""
                  cellSpacing=""
                  border={0}
                  width="100%"
                  id={"exportxls"}
                  style={{ display: "none" }}
              >
                  
                  <tr>
                      <td>
                          <table width="100%">
                              <tr>
                                  <td width="20%" valign="top">
                                      <div stlye={{ height:"200px", width:"200px", display:"block" }} onClick="_captureLogoButton()" id="logo">
                                          {/* image here */}
                                          {_businessSetting.CompanyLogo == 1 && (
                                              <img src={_companySession.company_logo} style={{maxWidth: "100% !important"}} height="100" width="100" alt="" title=""
                                              />
                                          )}
                                      </div>
                                  </td>
                                  
                                  {(_businessSetting.TransportationDetailsField1Enable == 1 ||
                                      _businessSetting.TransportationDetailsField2Enable == 1 ||
                                      _businessSetting.TransportationDetailsField3Enable == 1 ||
                                      _businessSetting.TransportationDetailsField4Enable == 1 ||
                                      _businessSetting.TransportationDetailsField5Enable == 1 ||
                                      _businessSetting.TransportationDetailsField5Enable == 1) &&
                                  _showTransportD == 1 && (
                                      <td style= {{textAlign: "right" ,width :"40%", valign:"top"}}>
                                          <p style={{margin: "6px 0"}}><b>Transportation Details :</b></p>
                                          {_businessSetting.TransportationDetailsField1Enable ==
                                          1 &&
                                          this.state.TransportDetailField1!=null &&
                                          this.state.TransportDetailField1!="" && (
                                              <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField1Name} : {" "} </b>
                                                  {this.state.TransportDetailField1}
                                              </p>
                                          )}

                                          {_businessSetting.TransportationDetailsField2Enable ==
                                          1 &&
                                          this.state.TransportDetailField2!=null &&
                                          this.state.TransportDetailField2!="" && (
                                              <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField2Name} : {" "} </b>
                                                  {this.state.TransportDetailField2}</p>
                                          )}
                                          {_businessSetting.TransportationDetailsField3Enable ==
                                          1 &&
                                          this.state.TransportDetailField3!=null &&
                                          this.state.TransportDetailField3!="" && (
                                              <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField3Name} :{" "} </b>
                                                  {this.state.TransportDetailField3}
                                              </p>
                                          )}
                                          {_businessSetting.TransportationDetailsField4Enable ==
                                          1 &&
                                          this.state.TransportDetailField4!=null &&
                                          this.state.TransportDetailField4!="" && (
                                              <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField4Name} :{" "} </b> {this.state.TransportDetailField4}
                                              </p>
                                          )}

                                          {_businessSetting.TransportationDetailsField5Enable ==
                                          1 &&
                                          this.state.TransportDetailField5!=null &&
                                          this.state.TransportDetailField5!="" && (
                                              <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField5Name} :{" "} </b> {this.state.TransportDetailField5}
                                              </p>

                                          )}
                                          {_businessSetting.TransportationDetailsField6Enable ==
                                          1 &&
                                          this.state.TransportDetailField6!=null &&
                                          this.state.TransportDetailField6!="" && (
                                              <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField6Name} :{" "} </b> {this.state.TransportDetailField6}
                                              </p>
                                          )}
                                      </td>
                                  )}
                                  <td width="20%" valign="top" style={{textAlign : "right"}}>
                                      {_businessSetting.SateOfSupply === 1 &&  this.state.stateNamePrint!== "Select State" && (
                                          <p style={{margin: "6px 0"}}><b>
                                              {_stateNamePrint > 2
                                                  ? "State Of Supply : " + this.state.stateNamePrint
                                                  : ""}</b>
                                              {/* {_stateNamePrint > 2 ? "State Of Supply" : ' : ' }</b> {this.state.stateNamePrint}  */}
                                          </p>
                                      )}
                                      {_businessSetting.PartyCompanyName === 1 && (
                                          <p style={{margin: "6px 0"}}><b> Company Name : </b> {this.state.companyName}
                                          </p>
                                      )}
                                      {_businessSetting.Address == 1 && (
                                          <p style={{margin: "6px 0"}}><b>Company Address :</b> {this.state.companyAddress}
                                          </p>
                                      )}
                                      {_businessSetting.PhoneNumber == 1 && (
                                          <p style={{margin: "6px 0"}}><b>Company Phone no. :</b> {this.state.companyPhone}
                                          </p>
                                      )}
                                      {_businessSetting.Email == 1 && (
                                          <p style={{margin: "6px 0"}}><b>Company Email :</b> {this.state.companyEmail}
                                          </p>
                                      )}

                                      {this.state.firm_additional_field1!="" && (
                                          <p style={{margin: "6px 0"}}><b>{this.state.firm_additional_field1} : {" "}</b> {this.state.firm_additional_value1}
                                          </p>
                                      )}

                                      {this.state.firm_additional_field2!="" && (
                                          <p style={{margin: "6px 0"}}><b>{this.state.firm_additional_field2} : {" "}</b> {this.state.firm_additional_value2}
                                          </p>
                                      )}

                                      {_businessSetting.GSTINSale == 1 && (
                                          <p style={{margin: "6px 0"}}><b>GSTIN :</b> {this.state.companyGstin}
                                          </p>
                                      )}

                                      {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                          <p style={{margin: "6px 0"}}>
                                              {_businessSetting.additional_field_check1 === 1 && (
                                                  <b>{_businessSetting.additional_field_name1} :{" "} : {this.state.additional_value_1}</b>
                                              )}
                                          </p>
                                      )}

                                      {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                          <p style={{margin: "6px 0"}}>
                                              {_businessSetting.additional_field_check2 === 1 && (
                                                  <b>{_businessSetting.additional_field_name2} :{" "} : {this.state.additional_value_2}</b>
                                              )}
                                          </p>
                                      )}

                                      {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                          <p style={{margin: "6px 0"}}>
                                              {_businessSetting.additional_field_check3 === 1 && (
                                                  <b>{_businessSetting.additional_field_name3} :{" "} : {this.state.additional_value_3}</b>
                                              )}
                                          </p>
                                      )}

                                      {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                          <p style={{margin: "6px 0"}}>
                                              {_businessSetting.additional_field_date_field_check === 1 && (
                                                  <b>{_businessSetting.additional_field_date_field_name} :{" "} : {this.state.additional_value_date}</b>
                                              )}
                                          </p>
                                      )}
                                      {_businessSetting.Tran_CustomerPO > 0 && (
                                          <p style={{margin: "6px 0"}}>
                                              {this.state.poDate!=null &&
                                              this.state.poNumber!="null" &&
                                              this.state.poNumber!="Invalid date" && (
                                                  <b>PO Date :</b>  + this.state.poDate    )}
                                          </p>
                                      )}
                                      {this.state.poNumber!=null && this.state.poNumber!="null" && (
                                          <p style={{margin: "6px 0"}}><b>PO Number :</b> {this.state.poNumber}</p>
                                      )}



                                      {this.state.E_Way_Bill_No!=null &&
                                      this.state.E_Way_Bill_No!="null" &&
                                      this.state.EWayBillNo == 1 && (
                                          <p style={{margin: "6px 0"}}><b>E-Way Bill Number :</b> {this.state.E_Way_Bill_No}</p>
                                      )}

                                  </td>
                              </tr>
                          </table>
                      </td>
                  </tr>
                  <tr>
                      <td align="center">
                          <p style={{color: "#9f9fdf", margin: "15px 0", fontSize : "24px"}}><b>{this.state.titleTxt}</b></p>
                      </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                  <tr>
                      <td>
                          <table width="100%">
                              <tr>
                                  <td width="49%" align="center">
                                      <p  style={{background : "#cacaca", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#9f9fdf", fontSize: "20px"}}>{this.state.billTxt}</p>

                                      {this.state.expensePartyName === "" && (
                                          <p style={{ margin: "5px 0" }}>{this.state.customerCompanyName}</p>
                                      )}
                                      {this.state.expensePartyName != "" && (
                                          <p style={{ margin: "5px 0" }}>{this.state.expensePartyName} (
                                              {this.state.customerCompanyName})</p>
                                      )}
                                      {this.state.customerAddress!=null &&
                                      this.state.customerAddress!="null" && (
                                          <p style={{ margin: "5px 0" }} >
                                              {this.state.customerAddress}
                                          </p>
                                      )}

                                      <p style={{background: "#cacaca !important", color: "#FFF", textAlign: "center", WebkitPrintColorAdjust: "exact", margin:"5px 0"}}>Contact No.</p>
                                      <p style={{ margin: "5px 0" }}>{this.state.customerPhone}</p>

                                      {this.state.PartyAddField1ShowPrint == 1 && (
                                          <p style={{ margin: "5px 0" }}>
                                              {this.state.party_add_field_value1!=null &&
                                              this.state.party_add_field_value1!="" &&
                                              this.state.party_add_field_key1!=null &&
                                              this.state.party_add_field_key1!="" && (
                                                  <span>
                                        {this.state.party_add_field_value1!=""
                                            ? this.state.party_add_field_key1 +
                                            " : " +
                                            this.state.party_add_field_value1
                                            : ""}
                                      </span>
                                              )}
                                          </p>
                                      )}

                                      {this.state.PartyAddField2ShowPrint == 1 && (
                                          <p style={{ margin: "5px 0" }}>
                                              {this.state.party_add_field_value2!=null &&
                                              this.state.party_add_field_value2!="" &&
                                              this.state.party_add_field_key2!=null &&
                                              this.state.party_add_field_key2!="" && (
                                                  <span>
                                        {this.state.party_add_field_value2!=""
                                            ? this.state.party_add_field_key2 +
                                            " : " +
                                            this.state.party_add_field_value2
                                            : ""}
                                      </span>
                                              )}
                                          </p>
                                      )}

                                      {this.state.PartyAddField3ShowPrint == 1 && (
                                          <p style={{ margin: "5px 0" }}>
                                              {this.state.party_add_field_value3!=null &&
                                              this.state.party_add_field_value3!="" &&
                                              this.state.party_add_field_key3!=null &&
                                              this.state.party_add_field_key3!="" && (
                                                  <span>
                                        {this.state.party_add_field_value3!=""
                                            ? this.state.party_add_field_key3 +
                                            " : " +
                                            this.state.party_add_field_value3
                                            : ""}
                                      </span>
                                              )}
                                          </p>
                                      )}

                                  </td>

                                  <td width="49%" align="center">
                                      <p style={{background: "#9f9fdf !important", color: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", margin:"5px 0", fontSize : "20px"}}>Invoice No.</p>
                                      <p style={{ margin: "5px 0" }}>0021354</p>
                                      <p style={{background: "#9f9fdf !important", color: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", margin:"5px 0", fontSize : "20px"}}>Date & Time</p>
                                      <p style={{ margin: "5px 0" }}>23rh February 2024 | 08:30 PM</p>
                                  </td>
                              </tr>
                          </table>
                      </td>
                  </tr>
                  {this.state.totalQty!=0 && (

                      <tr>
                          <td>
                              <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  width="100%"
                                  style={{ marginBottom: "40px" }}
                              >
                                  {this.state.items.length > 0 && (
                                      <tr >
                                          <td colSpan="3" width="100%">
                                              <table
                                                  width="100%"
                                                  cellPadding="0"
                                                  cellSpacing="0"
                                                  style={{ marginTop: "10px" }}
                                              >
                                                  <tr style={{ backgroundColor: "#9f9fdf" }}>
                                                      <th
                                                          style={{
                                                              color: "#FFFFFF",
                                                              textAlign: "center",
                                                              fontSize: "15px",
                                                              fontFamily: "Arial",
                                                              lineHeight: "24px",
                                                          }}
                                                      >
                                                          #
                                                      </th>
                                                      <th
                                                          style={{
                                                              color: "#FFFFFF",
                                                              textAlign: "center",
                                                              fontSize: "15px",
                                                              fontFamily: "Arial",
                                                              lineHeight: "24px",
                                                          }}
                                                      >
                                                          Item Name
                                                      </th>
                                                      <th
                                                          style={{
                                                              color: "#FFFFFF",
                                                              textAlign: "center",
                                                              fontSize: "15px",
                                                              fontFamily: "Arial",
                                                              lineHeight: "24px",
                                                          }}
                                                      >
                                                          Qty
                                                      </th>
                                                      <th
                                                          className={_hideItemPrice}
                                                          style={{
                                                              color: "#FFFFFF",
                                                              textAlign: "center",
                                                              fontSize: "15px",
                                                              fontFamily: "Arial",
                                                              lineHeight: "24px",
                                                          }}
                                                      >
                                                          Rate
                                                      </th>

                                                      <th
                                                          className={_hideItemPrice}
                                                          style={{
                                                              color: "#FFFFFF",
                                                              textAlign: "center",
                                                              fontSize: "15px",
                                                              fontFamily: "Arial",
                                                              lineHeight: "24px",
                                                          }}
                                                      >
                                                          Amount
                                                      </th>
                                                  </tr>
                                                  {this.state.items.map((value, index) => {
                                                      let _i = parseInt(index) + 1;

                                                      return (
                                                          <tr>
                                                              <td
                                                                  style={{
                                                                      fontFamily: "Arial",
                                                                      fontSize: "15px",
                                                                      textAlign: "center",
                                                                      color: "#333333",
                                                                      lineHeight: "24px",
                                                                      borderTop: "1px solid #2929af",
                                                                      borderBottom: "1px solid #2929af",
                                                                  }}
                                                              >
                                                                  {_i}
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      fontFamily: "Arial",
                                                                      fontSize: "15px",
                                                                      textAlign: "center",
                                                                      color: "#333333",
                                                                      lineHeight: "24px",
                                                                      borderTop: "1px solid #2929af",
                                                                      borderBottom: "1px solid #2929af",
                                                                  }}
                                                              >
                                                                  <table width="100%">
                                                                      <tr>
                                                                          <td>
                                                                              <span>{value.item_name}</span>
                                                                          </td>
                                                                      </tr>
                                                                  </table>
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      fontFamily: "Arial",
                                                                      fontSize: "15px",
                                                                      textAlign: "center",
                                                                      color: "#333333",
                                                                      lineHeight: "24px",
                                                                      borderTop: "1px solid #2929af",
                                                                      borderBottom: "1px solid #2929af",
                                                                  }}
                                                              >
                                                                  {value.quantity}
                                                              </td>
                                                              <td
                                                                  className={_hideItemPrice}
                                                                  style={{
                                                                      fontFamily: "Arial",
                                                                      fontSize: "15px",
                                                                      textAlign: "center",
                                                                      color: "#333333",
                                                                      lineHeight: "24px",
                                                                      borderTop: "1px solid #2929af",
                                                                      borderBottom: "1px solid #2929af",
                                                                  }}
                                                              >
                                                                  {value.rate}
                                                              </td>

                                                              <td
                                                                  colSpan="2"
                                                                  className={_hideItemPrice}
                                                                  style={{
                                                                      fontFamily: "Arial",
                                                                      fontSize: "15px",
                                                                      textAlign: "center",
                                                                      color: "#333333",
                                                                      lineHeight: "24px",
                                                                      borderTop: "1px solid #2929af",
                                                                      borderBottom: "1px solid #2929af",
                                                                  }}
                                                              >
                                                                  {_businessSetting.AmountWithDecimal == 1 &&
                                                                  numberFormat(
                                                                      parseFloat(value.rate) *
                                                                      parseFloat(value.quantity)
                                                                  )}
                                                                  {_businessSetting.AmountWithDecimal == 0 &&
                                                                  parseInt(
                                                                      parseFloat(value.rate) *
                                                                      parseFloat(value.quantity)
                                                                  )}
                                                                  {/* {numberFormat(value.totalAmount)} */}
                                                              </td>
                                                          </tr>
                                                      );
                                                  })}
                                                  {_businessSetting.TotalItemQuantity == 1 && (
                                                      <tr
                                                          style={{
                                                              fontFamily: "Arial",
                                                              fontSize: "15px",
                                                              textAlign: "center",
                                                              color: "#333333",
                                                              lineHeight: "24px",
                                                              borderTop: "1px solid #2929af",
                                                              borderBottom: "1px solid #2929af",
                                                          }}
                                                      >
                                                          <td></td>
                                                          <td>
                                                              <b>Total</b>
                                                          </td>
                                                          <td>
                                                              <b>{this.state.totalQty}</b>
                                                          </td>
                                                          <td></td>
                                                          <td>
                                                              <b>{this.state.subtotal}</b>
                                                          </td>
                                                      </tr>
                                                  )}
                                              </table>
                                          </td>
                                      </tr>
                                  )}
                                  <tr>
                                      <td width="40%">
                                          <table cellPadding="0" cellSpacing="0" width="100%">
                                              {_businessSetting.PrintDescription > 0 && (
                                                  <tr>
                                                      <td colSpan="2" height="10">
                                                          <div
                                                              className={
                                                                  this.state.description.length > 0 ? "" : "hide"
                                                              }
                                                          >
                                                              <br />
                                                              Description
                                                              <p
                                                                  style={{
                                                                      margin: "3px 0",
                                                                      fontSize: "15px",
                                                                  }}
                                                              >
                                                                  {this.state.description}
                                                              </p>
                                                          </div>
                                                      </td>
                                                  </tr>
                                              )}

                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      <div>
                                                          <br />
                                                          <b>Total Amount In Words</b>
                                                          <p
                                                              style={{
                                                                  margin: "3px 0",
                                                                  fontSize: "15px",
                                                                  textTransform: "capitalize",
                                                              }}
                                                          >
                                                              {_totalAmtInChar}
                                                          </p>
                                                      </div>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                  </td>
                                              </tr>


                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td colSpan="2" height="10">
                                                      &nbsp;
                                                      <div
                                                          style={{
                                                              float: "left !important",
                                                              margin: "0 0 0 0",
                                                          }}
                                                      >
                                                          <a
                                                              className="float-left"
                                                              style={{
                                                                  flex: "1",
                                                                  alignItems: "center",
                                                                  width: "200px",
                                                                  marginTop: "10px",
                                                              }}
                                                          >
                                                              <img
                                                                  src={_vLogoBaseDecode}
                                                                  style={{
                                                                      maxWidth: "250px",
                                                                      maxHeight: "110px",
                                                                      borderRadius: "10px",
                                                                  }}
                                                              />
                                                          </a>
                                                      </div>
                                                  </td>
                                              </tr>
                                          </table>
                                      </td>

                                      <td colSpan="2" width="60%">
                                          {this.getTotalCalculation()}
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>

                  )}
                  
                  {_businessSetting.PrintAcknowledgement == 1 && (
                      <tr>
                          <td>
                              <table width="100%">
                                  <tr>
                                      <td width="27%" valign="middle">
                                          <p style={{margin: "30px 0 6px 0"}}><b>{this.state.customerCompanyName}</b></p>
                                      </td>
                                      <td width="33%" valign="middle">
                                          <p style={{margin: "30px 0 6px 0"}}><b>Acknowledgement</b></p>
                                          <p style={{margin: "6px 0"}}><b>{this.state.companyName}</b></p>
                                      </td>
                                      <td width="40%" valign="middle" style={{textAlign: "right"}}>
                                          <p style={{margin: "30px 0 6px 0"}}><b>Invoice No :</b> {this.state.invoiceNo}</p>
                                          <p style={{margin: "6px 0"}}><b>Invoice Date :</b> {this.state.invoiceDate}</p>
                                          <p style={{margin: "6px 0"}}><b>Invoice Amount :</b>{this.state.totalAmount}</p>
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  )}
                  <tr>
                      
                      <td width="40%" valign="bottom" align="right">
                          <p>&nbsp;&nbsp;</p>
                          <p><b>Receiver's Seal & Sign</b></p>
                      </td>
                  </tr>
              </table>
          );
      }
  }
  printPdfArea() {


    let amt = this.state.totalAmtInChar;
    let _totalAmtInChar = "";
    if (amt > 0) {
      let returnData = new ToWords({
        converterOptions: { ignoreDecimal: false },
      }).convert(amt ? amt : 0);
      _totalAmtInChar = returnData + " Only";
    } else {
      _totalAmtInChar = "Zero";
    }
    let _hideItemPrice = "";
    if (this.state.isLoaded == true) {
      return (
        <html 
        style={{ marginTop: "15px" }}>
          <head>
            <title>Recipt</title>
          </head>
          <div id="template">
            <div style={{ display: "flex", flexDirection: "row" }}>
                <td width="20%" valign="top">
                    <div stlye={{ height:"200px", width:"200px", display:"block" }} onClick="_captureLogoButton()" id="logo">
                        {/* image here */}
                        {_businessSetting.CompanyLogo == 1 && (
                            <img src={_companySession.company_logo} style={{maxWidth: "100% !important"}} height="100" width="100" alt="" title=""
                            />
                        )}
                    </div>
                </td>
              <div
                style={{
                  flex: "1",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  lineHeight: "20px",
                  textAlign: "right",
                }}
              >
                {_businessSetting.PhoneNumber == 1 &&
                  this.state.companyPhone!="" && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      Phone : {this.state.companyPhone}
                    </p>
                  )}

                {_businessSetting.Email == 1 &&
                  this.state.companyEmail!="" && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      Email : {this.state.companyEmail}
                    </p>
                  )}
                <p
                  style={{
                    fontWeight: "bold",
                    margin: "3px 0",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Other Income From: {this.state.categoryName}
                </p>

                {this.state.firm_additional_field1!="" && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.firm_additional_field1} :{" "}
                    {this.state.firm_additional_value1}
                  </p>
                )}
                {this.state.firm_additional_field2!="" && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.firm_additional_field2} :{" "}
                    {this.state.firm_additional_value2}
                  </p>
                )}

                {this.state.invoiceDate!=null &&
                  this.state.invoiceDate!="null" &&
                  this.state.invoiceDate!="Invalid date" && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.invoiceDate!=""
                        ? "Date : " + this.state.invoiceDate
                        : ""}
                    </p>
                  )}
                {this.state.invoiceTime!=null &&
                  this.state.invoiceTime!="null" && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.invoiceTime!=""
                        ? "Time : " + this.state.invoiceTime
                        : ""}
                    </p>
                  )}
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontFamily: "Arial",
                padding: "10px 0",
                fontWeight: "bold",
                color: "#9f9fdf",
              }}
            >
              {this.state.titleTxt}
            </div>
            <div>
              <table
                cellPadding="0"
                cellSpacing="0"
                border="0"
                width="100%"
                style={{ marginBottom: "40px" }}
              >
                {this.state.items.length > 0 && (
                  <tr >
                    <td colSpan="3" width="100%">
                      <table
                        width="100%"
                        cellPadding="0"
                        cellSpacing="0"
                        style={{ marginTop: "10px" }}
                      >
                        <tr style={{ backgroundColor: "#9f9fdf" }}>
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              lineHeight: "24px",
                            }}
                          >
                            #
                          </th>
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              lineHeight: "24px",
                            }}
                          >
                            Item Name
                          </th>
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              lineHeight: "24px",
                            }}
                          >
                            Qty
                          </th>
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              lineHeight: "24px",
                            }}
                          >
                            Rate
                          </th>

                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              lineHeight: "24px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                        {this.state.items.map((value, index) => {
                          let _i = parseInt(index) + 1;

                          return (
                            <tr>
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_i}
                              </td>
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                <table width="100%">
                                  <tr>
                                    <td>
                                      <span>{value.item_name}</span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.quantity}
                              </td>
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.rate}
                              </td>

                              <td
                                colSpan="2"
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_businessSetting.AmountWithDecimal == 1 &&
                                  numberFormat(
                                    parseFloat(value.rate) *
                                      parseFloat(value.quantity)
                                  )}
                                {_businessSetting.AmountWithDecimal == 0 &&
                                  parseInt(
                                    parseFloat(value.rate) *
                                      parseFloat(value.quantity)
                                  )}
                                {/* {numberFormat(value.totalAmount)} */}
                              </td>
                            </tr>
                          );
                        })}
                        {_businessSetting.TotalItemQuantity == 1 && (
                          <tr
                            style={{
                              fontFamily: "Arial",
                              fontSize: "15px",
                              textAlign: "center",
                              color: "#333333",
                              lineHeight: "24px",
                              borderTop: "1px solid #2929af",
                              borderBottom: "1px solid #2929af",
                            }}
                          >
                            <td></td>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{this.state.totalQty}</b>
                            </td>
                            <td></td>
                            <td>
                              <b>{this.state.subtotal}</b>
                            </td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                )}
                <tr>
                  <td width="40%">
                    <table cellPadding="0" cellSpacing="0" width="100%">
                      {_businessSetting.PrintDescription > 0 && (
                        <tr>
                          <td colSpan="2" height="10">
                            <div
                              className={
                                this.state.description.length > 0 ? "" : "hide"
                              }
                            >
                              <br />
                              Description
                              <p
                                style={{
                                  margin: "3px 0",
                                  fontSize: "15px",
                                }}
                              >
                                {this.state.description}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td colSpan="2" height="10">
                          <div>
                            <br />
                            <b>Total Amount In Words</b>
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                            >
                              {_totalAmtInChar}
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>


                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                        <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                        <tr>
                            <td colSpan="2" height="10">
                                &nbsp;
                                <div
                                    style={{
                                        float: "left !important",
                                        margin: "0 0 0 0",
                                    }}
                                >
                                    <a
                                        className="float-left"
                                        style={{
                                            flex: "1",
                                            alignItems: "center",
                                            width: "200px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <img
                                            src={_vLogoBaseDecode}
                                            style={{
                                                maxWidth: "250px",
                                                maxHeight: "110px",
                                                borderRadius: "10px",
                                            }}
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </table>
                  </td>

                  <td colSpan="2" width="60%">
                    {this.getTotalCalculation()}
                  </td>
                </tr>
              </table>
            </div>
            {_businessSetting.PrintAcknowledgement == 1 && (
              <div>
                <span
                  style={{
                    textAlign: "center",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Acknowledgement
                </span>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {this.state.billingName}
                </p>
                <p> &nbsp;</p>
                <p>Receiver's Seal & Sign</p>
              </div>
            )}
          </div>
        </html>
      );
    }
  }

  closeWindow() {
    window.close();
  }
    convertMainImageUrlToBase64(url, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function () {
            var originalWidth = img.style.width;
            var originalHeight = img.style.height;

            img.style.width = "auto";
            img.style.height = "auto";
            img.crossOrigin = "Anonymous";

            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            img.style.width = originalWidth;
            img.style.height = originalHeight;

            // Get the data-URL formatted image
            // Firefox supports PNG and JPEG. You could check img.src to
            // guess the original format, but be aware the using "image/jpg"
            // will re-encode the image.
            var dataUrl = canvas.toDataURL(outputFormat);
            //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            _vLogoBaseDecode = dataUrl;
        };
        img.src = url;
    }
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
        this.convertMainImageUrlToBase64(_mainLogoBaseDecode, function (url) {});
    }

    return (
      <React.Fragment>
        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_PDF}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          id="modalAdjustPDFDownload"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Other Income PDF Preview</h3>
                  <div className="block-options">
                    {/* <button type="button" className="btn-block-option" onClick={this.closeWindow.bind()}>
                                            <i className="si si-close"></i>
                                        </button> */}
                  </div>
                </div>
                <div
                  className="block-content"
                  style={{
                    background: "#fff",
                    border: "2px solid #cacaca",
                    height: "97%",
                    padding: "10px",
                  }}
                >
                  <div
                    className="block-content"
                    id="pdfFormatReceipt"
                    ref={(el) => (this.componentRef = el)}
                  >
                    {this.printDemo()}
                    {this.printPdfArea()}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" >Close</button> */}
                  <ReactHTMLTableToExcel
                      className="btn btn-alt-secondary"
                      table="exportxls"
                      filename="exportxls"
                      sheet="sheet"
                      buttonText="Download as XLS"
                  />

                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.clickToGenratePDF.bind(
                    this,
                    this.props.transactionId
                  )}
                >
                  <i className="fa fa-file-pdf-o"></i> Download PDF
                </button>

                <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn btn-alt-secondary">
                      <i className="fa fa-print"></i> View Print
                    </button>
                  )}
                  content={() => this.componentRef}
                />

                <a
                  type="button"
                  className="btn btn-alt-danger"
                  href={this.state.returnUrl}
                >
                  <i className="fa fa-times"></i>Close
                </a>
              </div>
              <div id="global_loader" className={_loader}>
                <div className="gl-ldr-cld">
                  <div className="gl-ldr-ctr">
                    <div className="gl-loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}
      </React.Fragment>
    );
  }
}
