import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { Link } from "react-router-dom";
import { numberFormat } from "../../../config/numberFormat";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";

let gstTaxDetails = [];
let _businessSetting = {};
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";
let _Description = 0;
let _categoryShow = 0;
let _HsnSacShow = 0;
let _AdditionalCESS = 0;

let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

if (_UserSession!=null && _UserSession!="") {
  let _companySession = localStorage.getItem("vs_UserCompanySession");
  if (_companySession!=undefined || _companySession!=null) {
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _companyId = _companySession.company_id;
    gstTaxDetails = _companySession.taxDetails;
    if (_UserSession!=null && _UserSession!="") {
      if (_UserSession.loginType!="Admin") {
        if (_UserSession!=undefined || _UserSession!=null) {
          _businessSetting = _UserSession.businessSetting;
          _Description = _businessSetting.Description;
          _categoryShow = _businessSetting.ItemCategory;
          _HsnSacShow = _businessSetting.HSN_SAC_CODE;
          _AdditionalCESS = _businessSetting.AdditionalCESS;
        }
      }
    }
  }
}
let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Service Name",
    field: "item_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Service Name",
    },
  },
  {
    label: "Cateory",
    field: "category",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Category",
    },
  },
  {
    label: "Service Item Name",
    field: "code",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Service Item Name",
    },
  },
  {
    label: "Description",
    field: "description",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Description",
    },
  },
  {
    label: "Sale Price",
    field: "salePrice",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Sale Price",
    },
  },
  {
    label: "Sale Tax",
    field: "sale_tax_type",
    width: 250,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Sale Tax",
    },
  },
  {
    label: "Tax Rate",
    field: "tax_rate_name",
    width: 250,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Tax Rate",
    },
  },
  {
    label: "Additional CESS",
    field: "additionalCESS",
    width: 100,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "additionalCESS",
    },
  },
];

if (_Description == 0 || _Description == "0") {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Cateory",
      field: "category",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Category",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
    {
      label: "Additional CESS",
      field: "additionalCESS",
      width: 100,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "additionalCESS",
      },
    },
  ];
}

if (_categoryShow == 0 || _categoryShow == "0") {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Description",
      field: "description",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Description",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
    {
      label: "Additional CESS",
      field: "additionalCESS",
      width: 100,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "additionalCESS",
      },
    },
  ];
}

if (_AdditionalCESS == 0 || _AdditionalCESS == "0") {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Description",
      field: "description",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Description",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
  ];
}

if (
  (_Description == 0 || _Description == "0") &&
  (_categoryShow == 0 || _categoryShow == "0")
) {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
    {
      label: "Additional CESS",
      field: "additionalCESS",
      width: 100,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "additionalCESS",
      },
    },
  ];
}

if (
  (_AdditionalCESS == 0 || _AdditionalCESS == "0") &&
  (_categoryShow == 0 || _categoryShow == "0")
) {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Description",
      field: "description",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Description",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
  ];
}

if (
  (_AdditionalCESS == 0 || _AdditionalCESS == "0") &&
  (_Description == 0 || _Description == "0")
) {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Cateory",
      field: "category",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Category",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
  ];
}

if (
  (_AdditionalCESS == 0 || _AdditionalCESS == "0") &&
  (_Description == 0 || _Description == "0") &&
  (_categoryShow == 0 || _categoryShow == "0")
) {
  columns = [
    {
      label: "#",
      field: "srNo",
      width: 20,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "#",
      },
    },
    {
      label: "Service Name",
      field: "item_name",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Name",
      },
    },
    {
      label: "Service Item Name",
      field: "code",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Service Item Name",
      },
    },
    {
      label: "Sale Price",
      field: "salePrice",
      width: 150,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Price",
      },
    },
    {
      label: "Sale Tax",
      field: "sale_tax_type",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Sale Tax",
      },
    },
    {
      label: "Tax Rate",
      field: "tax_rate_name",
      width: 250,
      attributes: {
        "aria-controls": "DataTable",
        "aria-label": "Tax Rate",
      },
    },
  ];
}

let _loader = "glb-ldr-prt active";

export default class manageBulkUpdateService extends Component {
  constructor(props) {
    super(props);
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    if (_UserSession!=null && _UserSession!="") {
      let _companySession = localStorage.getItem("vs_UserCompanySession");
      if (_companySession!=undefined || _companySession!=null) {
        _companySession = JSON.parse(_companySession);
        _businessId = _companySession.business_id;
        _companyId = _companySession.company_id;
        gstTaxDetails = _companySession.taxDetails;

        if (_UserSession!=null && _UserSession!="") {
          if (_UserSession.loginType!="Admin") {
            if (_UserSession!=undefined || _UserSession!=null) {
              _businessSetting = _UserSession.businessSetting;
            }
          }
        }
      }
    }

    this.state = {
      isLoaded: true,
      modalIsOpen_IMI: false,
      taxRateArray: gstTaxDetails,
      itemArrayFilter: [],
      updateItemArrayFilter: [],
      itemCategoryArray: [],
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      dateLabel: "",
      importExcelFile: "",
      showError: "",
      Description: _Description,
      categoryShow: _categoryShow,
      AdditionalCESS: _AdditionalCESS,
      errors: {
        name: "",
        importExcelFile: "",
        showError: "",
      },
    };
  }
  async componentDidMount() {
    this.geItemListData();
    this.getItemCategoryListData();
  }

  getItemCategoryListData() {
    let _itemsCatArray = [];

    const requestData = JSON.stringify({
      businessId: this.state.businessId,
    });
    PostData(global.itemCategoryList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsCatArray = responseJson.response;
          }
          this.setState({ itemCategoryArray: _itemsCatArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  geItemListData() {
    let _itemArray = [];
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      activeFilter: true,
      inActiveFilter: false,
    });
    PostData(global.userServiceList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            itemArrayFilter: _itemArray,
            itemArray: _itemArray,
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _itemArray = responseJson.response;
            _itemArray.map((itm, i) => {});
          }
          this.setState({
            itemArrayFilter: _itemArray,
            itemArray: _itemArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  handleChange(i, e) {
    let itemArrayFilter = this.state.itemArrayFilter;
    let _updateItemArrayFilter = this.state.updateItemArrayFilter;
    let _updateItemArray = [];
    let errors = this.state.errors;
    errors["showError"] = "";
    itemArrayFilter.map((data, index) => {
      if (i == index) {
        if (
          _updateItemArrayFilter.filter((item) => item.item_id == data.item_id)
            .length > 0
        ) {
          _updateItemArrayFilter.map((item, indexD) => {
            if (item.item_id == data.item_id) {
              _updateItemArrayFilter[indexD] = data;
            }
          });
        } else {
          _updateItemArray.push(data);
        }
      }
    });
    _updateItemArrayFilter = [..._updateItemArrayFilter, ..._updateItemArray];
    itemArrayFilter[i][e.target.name] = e.target.value;
    this.setState({
      itemArrayFilter,
      updateItemArrayFilter: _updateItemArrayFilter,
      errors: errors,
    });
  }

  loadTranscListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.itemArrayFilter;
      if (_transDetails.length > 0) {
        let _i = 0;
        let _itemName = "";
        let _itemCategory = "";
        let _itemAddCess = "";
        let _itemCode = "";
        let _itemDesc = "";
        let _salePrice = "";
        let _saleTaxType = "";
        let _taxRateName = "";
        _transDetails.map((data, index) => {
          _i++;

          _itemName = (
            <input
              type="text"
              required="required"
              className="form-control"
              id="name"
              name="name"
              value={data.name || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );
          if (
            this.state.categoryShow == 1 ||
            this.state.categoryShow == "1"
          ) {
            _itemCategory = (
              <select
                className="form-control"
                id="category"
                name="category"
                onChange={(e) => this.handleChange(index, e)}
              >
                {this.state.itemCategoryArray.map((itemCat, index) => {
                  let _selCat = "";
                  if (data.category == itemCat.id) {
                    _selCat = "selected";
                  }
                  return (
                    <option key={index} value={itemCat.id} selected={_selCat}>
                      {itemCat.categoryName}
                    </option>
                  );
                })}
              </select>
            );
          }
          //Enter Item HSN, Enter Service Item Name, Enter Item Description

          if (
            this.state.AdditionalCESS == 1 ||
            this.state.AdditionalCESS == "1"
          ) {
            _itemAddCess = (
              <input
                type="text"
                required="required"
                className="form-control"
                id="Additional_CESS"
                name="Additional_CESS"
                value={data.Additional_CESS || ""}
                placeholder=""
                onChange={(e) => this.handleChange(index, e)}
              />
            );
          }

          _itemCode = (
            <input
              type="text"
              required="required"
              className="form-control"
              id="code"
              name="code"
              value={data.code || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );

          if (this.state.Description == 1 || this.state.Description == "1") {
            _itemDesc = (
              <input
                type="text"
                required="required"
                className="form-control"
                id="item_description"
                name="item_description"
                value={data.item_description || ""}
                placeholder=""
                onChange={(e) => this.handleChange(index, e)}
              />
            );
          }
          _salePrice = (
            <input
              type="number"
              required="required"
              className="form-control"
              id="sale_price"
              name="sale_price"
              value={data.sale_price || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );

          _saleTaxType = (
            <select
              className="form-control"
              id="sale_tax_type"
              name="sale_tax_type"
              onChange={(e) => this.handleChange(index, e)}
            >
              <option
                value="Without Tax"
                selected={
                  data.sale_tax_type == "Without Tax" ? "selected" : ""
                }
              >
                Without Tax
              </option>
              <option
                value="With Tax"
                selected={data.sale_tax_type == "With Tax" ? "selected" : ""}
              >
                With Tax
              </option>
            </select>
          );

          _taxRateName = (
            <select
              className="form-control"
              id="tax_rate_id"
              name="tax_rate_id"
              onChange={(e) => this.handleChange(index, e)}
            >
              <option value="0" selected>
                {" "}
                Select Tax Rate
              </option>
              {this.state.taxRateArray.map((item, index) => {
                let _sel = "";
                if (data.tax_rate_id == item.id) {
                  _sel = "selected";
                }
                return (
                  <option key={index} value={item.id} selected={_sel}>
                    {item.name + "@" + item.ratio + "%"}
                  </option>
                );
              })}
            </select>
          );

          rows.push({
            srNo: _i,
            item_name: _itemName,
            category: _itemCategory,
            additionalCESS: _itemAddCess,
            code: _itemCode,
            description: _itemDesc,
            salePrice: _salePrice,
            sale_tax_type: _saleTaxType,
            tax_rate_name: _taxRateName,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  clickToAddItem = (event) => {
    event.preventDefault();

    let errors = this.state.errors;
    if (
      this.state.updateItemArrayFilter == "" ||
      this.state.updateItemArrayFilter.length == 0
    ) {
      errors["showError"] =
        "You have not change any item, before save please change any item details";
      this.setState({ errors: errors });
    } else {
      errors["showError"] = "";
      this.setState({ errors: errors });
      confirmAlert({
        title: "Confirm to Update",
        message: "Are you sure you want to update all these change data?",
        buttons: [
          {
            label: "Yes",
            className: "btn btn-danger",
            onClick: () => {
              let _itemsCatArray = [];

              const requestData = JSON.stringify({
                businessId: this.state.businessId,
                company_id: this.state.company_id,
                userId: this.state.userId,
                type: "Service-Update",
                itemUpdate: this.state.updateItemArrayFilter,
              });

              PostData(global.multipleBulkItemUpdate, requestData, "POST")
                .then((result) => {
                  let responseJson = result;
                  if (responseJson.success == false) {
                    this.setState({ isLoaded: true });
                  } else {
                    if (responseJson.response) {
                      _itemsCatArray = responseJson.response;
                    }
                    sendNotification(
                      "Success Message 👍",
                      result.message,
                      "success"
                    );
                    setTimeout(() => {
                      window.location.href =
                        global.webUrl + "business/bulk-update-services/";
                    }, Math.floor(Math.random() * 2000) + 1);
                  }
                })
                .catch((error) => this.setState({ error, isLoaded: false }));
            },
          },
          {
            label: "No",
            className: "btn btn-dark",
          },
        ],
      });
    }
  };
  resetForm = (event) => {
    event.preventDefault();

    confirmAlert({
      title: "Confirm to Update",
      message: "Are you sure you want to reset all these changes?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            this.setState({ updateItemArrayFilter: [] });
            window.location.href =
              global.webUrl + "business/bulk-update-services/";
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Bulk Update Service
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <Link className="breadcrumb-item" to={"/business/dashboard/"}>
              Dashboard
            </Link>
            <span className="breadcrumb-item active">Bulk Update Services</span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <div className="block-content">
                  <div className="form-group row">
                    <h2 className="block-title ml-10">Update Information</h2>
                    <br />
                    <h5 className="block-title ml-10">
                      Updated Service Count :{" "}
                      {this.state.updateItemArrayFilter.length}
                    </h5>
                    <button
                      className="btn btn-primary"
                      onClick={this.resetForm}
                    >
                      Reset Data
                    </button>
                    <div className="block-content" id="tableData">
                      <MDBDataTableV5
                        hover
                        entriesOptions={[25, 50, 100]}
                        entries={25}
                        pagesAmount={4}
                        span
                        pagingTop
                        searchTop
                        searchBottom={false}
                        barReverse
                        data={this.loadTranscListing()}
                      />
                      <div className="pull-right mb-10">
                        <button
                          type="submit"
                          className="btn btn-alt-primary text-right"
                          onClick={this.clickToAddItem}
                        >
                          <i className="fa fa-check mr-5"></i> Save Services
                        </button>
                        <div
                          id="showError-error"
                          className="animated fadeInDown"
                        >
                          {errors.showError.length > 0 && (
                            <span className="error">{errors.showError}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
