global.businessSettingUpdate = global.API_BUSINESS_URL+'businessSettingUpdate/';
global.businessSettingApi = global.API_BUSINESS_URL+'businessSettingApi/';
global.businessTypePrefixList = global.API_BUSINESS_URL+'businessTypePrefixList/';
global.businessAddNewPrefix = global.API_BUSINESS_URL+'businessAddNewPrefix/';
global.businessPrefixList = global.API_BUSINESS_URL+'businessPrefixList/';
global.businessWebPrefixList = global.API_BUSINESS_URL+'businessWebPrefixList/';
global.busiessPrefixDelete = global.API_BUSINESS_URL+'busiessPrefixDelete/';
global.businessPrefixSetData = global.API_BUSINESS_URL+'businessPrefixSetData/';
global.businessPrefixAllData = global.API_BUSINESS_URL+'businessPrefixAllData/';
global.businessDueDatePaymentTermList = global.API_BUSINESS_URL+'businessDueDatePaymentTermList/';
global.businessUpdateDueDate= global.API_BUSINESS_URL+'businessUpdateDueDate/';
global.businessTransAdditionalFieldList= global.API_BUSINESS_URL+'businessTransAdditionalFieldList/';
global.businessTransactionSettingUpdate= global.API_BUSINESS_URL+'businessTransactionSettingUpdate/';
global.businessSettingLocalDataApi = global.API_BUSINESS_URL+'businessSettingLocalDataApi/';
global.deleteTerms = global.API_BUSINESS_URL+'deleteTerms/';
global.addNewTerms = global.API_BUSINESS_URL+'addNewTerms/';
global.businessItemTableCustomizationList = global.API_BUSINESS_URL+'businessItemTableCustomizationList/';
global.businessSettingItemTblCustUpdate = global.API_BUSINESS_URL+'businessSettingItemTblCustUpdate/';



