import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MDBDataTableV5 } from 'mdbreact';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';


let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Prefix Type',
        field: 'prifix_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Prefix Type',
        },
    },
    {
        label: 'Prefix Name',
        field: 'prefix_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Prefix Name',
        },
    },
    // {
    //     label: 'Status',
    //     field: 'status',
    //     width: 150,
    //     attributes: {
    //     'aria-controls': 'DataTable',
    //     'aria-label': 'Status',
    //     },
    // },
    {
        label: 'Action',
        field: 'actionExtra',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class AllPrefixDataList extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            activeEditCashHandId : 0,
            buttonTitle:'Add Sale',
            isLoaded : true,
            prefixData:[],
            businessId:_businessId,
            company_id:_companyId, 
            userId : _signingId,
            name :  '',
            dateLabel : '',
            errors: {
                name : '',
            }
        }
    }

    async componentDidMount ()
    {   
        this.getPrefixAllData();
    }

     
    /*End Modal open close*/
    
    getPrefixAllData(){
        let _transArray = [];
        const requestData = JSON.stringify({
            businessId:this.state.businessId,
            company_id:this.state.company_id
        });

        PostData(global.businessPrefixAllData, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    
                }
                this.setState({isLoaded:true,prefixData:responseJson.response});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    loadTranscListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _prefixDetails = this.state.prefixData;            
            if(_prefixDetails.length > 0){
                let _i = 0;
                _prefixDetails.map((value,index) => {
                    _i++;
                    let _actionExtra = '';
                    let _actionLinkEdit = '';
                    let _hideActionButton = '';
                   
                    let _actionLinkDelete = (<a className="dropdown-item" href="javascript:void(0)" data-id={value.id} onClick={this.clickToDelete.bind(this,value.prefix_id)}>
                        Delete
                    </a>);  

                    if (value.isTransaction == true) {
                        _actionLinkDelete = (
                        <a
                            className="dropdown-item"
                            href="javascript:void(0)"
                            onClick={this.clickToDeleteNotifiyItem.bind(
                            this,
                            value.item_id
                            )}
                            style={{ margin: "0px" }}
                        >
                            Delete
                        </a>
                        );
                    }
                    _actionExtra = (
                        <div className="btn-group" style={{display:_hideActionButton}} role="group">
                            <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                {_actionLinkEdit}
                                {_actionLinkDelete}
                            </div>
                        </div>
                    ); 
                    
                    rows.push({
                        srNo: _i,
                        prifix_type: value.prefix_type,
                        prefix_name: value.prefix,
                        //status:'',
                        actionExtra:_actionExtra
                    });
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }


    clickToDelete(value)
    {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        prefix_id: value
                    });
                    PostData(global.busiessPrefixDelete, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getPrefixAllData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    clickToDeleteNotifiyItem() {
        confirmAlert({
          title: "Item Alert",
          message:
            "This prefix is currently used in transactions and cannot be deleted",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      }
    /* Callback Function from Child */
    getUpdateResponse = () => {
        this.setState({modalIsOpen_AC: false,activeEditCategoryId:0,modalIsOpen_BankToCash:false,activeBankId:0});
        this.getCashHandListData();
    }
    /* End Here */

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage All Prefix List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <Link className="breadcrumb-item" to={"/business/dashboard/"}>Dashboard</Link>                      
                        <span className="breadcrumb-item active">All Prefix</span>
                    </nav>                    
                    <div className="block">
                        
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h2 className="block-title ml-10">All Prefix</h2>
                                        
                                        
                                        
                                    </div>
                                    <div className="block-content" id="tableData">
                                        <MDBDataTableV5 
                                        hover
                                        entriesOptions={[25, 50, 100]}
                                        entries={25}
                                        pagesAmount={4}
                                        span
                                        pagingTop
                                        searchTop
                                        searchBottom={false}
                                        barReverse
                                        data={this.loadTranscListing()}
                                        />
                                    </div>
                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            
        )    
    }
}