import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import validator from "validator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import * as XLSX from "xlsx";

import ButtonPdf from "../htmlPdfCreation";
import moment from "moment";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let _clearBal = "";
let _businessSetting = {};

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 50,
  },
  {
    label: "Name",
    field: "party_name",
    width: 150,
  },
  {
    label: "Date",
    field: "invoice_date",
    width: 100,
  },
  {
    label: "Invoice No.",
    field: "invoice_no",
    width: 100,
  },
  {
    label: "Amount",
    field: "amount",
    width: 100,
  },
  {
    label: "Balance",
    field: "balance",
    width: 100,
  },
  {
    label: "Status",
    field: "payment_status",
    width: 100,
  },
  {
    label: "",
    field: "actionextra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class partyListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",
      activeParty: "N/A",
      activePartyId: "0",
      activePartyOpnBalanace: "0",
      activePartyEmail: "",
      activePartyPhoneT: "",
      activePartyPhone: "",
      activePartyAddress: "",
      activePartyColor: "",
      activePartyTxt: "",
      filterSearch: true,

      isLoaded: true,
      modalIsOpen_AJP: false,
      modalIsOpen_AC: false,
      company_id: _company_id,
      businessId: _businessId,

      partyArray: [],
      partyArrayFilter: [],
      partyArrayTransaction: [],

      userId: _signingId,
      party_name: "",
      party_contact_no: "",

      importExcelFile: "",
      isLoadedPartyTrans: false,
      errors: {
        party_name: "",
        party_contact_no: "",
        importExcelFile: "",
      },
    };
  }

  async componentDidMount() {
    this.getPartyListData();
  }

  getTransactionListData(id) {
    let _transArray = [];
    this.setState({ isLoadedPartyTrans: false });
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      party_id: id, //this.state.activePartyId
    });

    PostData(global.userPartyTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            partyArrayTransaction: _transArray,
            isLoadedPartyTrans: true,
          });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
          }
          this.setState({
            partyArrayTransaction: _transArray,
            isLoadedPartyTrans: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoadedPartyTrans: false }));
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true , isLoadedPartyTrans: true});
        } else {
          let _activeParty,
            _activePartyId,
            _activePartyBal,
            _activePartyContact,
            _activePartyAddress,
            _activePartyEmail,
            _activePartyColor,
            _activePartyTxt = "";
          if (responseJson.response) {
            _partyArray = responseJson.response;
            if (responseJson.response) {
              _partyArray = responseJson.response;
              _partyArray.map((party, i) => {
                if (i == 0) {
                  let _party_billing_address = party.party_billing_address;
                  if (party.party_billing_address == null) {
                    _party_billing_address = "";
                  }
                  _partyArray[i].isActiveClick = true;
                  _activeParty = party.party_name;
                  _activePartyContact = party.party_contact_no;
                  _activePartyAddress = _party_billing_address;
                  _activePartyEmail = party.party_email_id;
                  _activePartyId = party.party_id;
                  _activePartyBal = party.totalOutCome;
                  _activePartyColor = party.color;
                  _activePartyTxt = party.txtBalance;
                } else {
                  _partyArray[i].isActiveClick = false;
                }
              });
            }
          }
          console.log("==_activePartyAddress==", _activePartyAddress);
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            party_contact_no: _activePartyContact,
            activePartyOpnBalanace: _activePartyBal,
            party_name: _activeParty,
            activeParty: _activeParty,
            activePartyId: _activePartyId,
            activePartyPhone:
              _activePartyContact!="" ? _activePartyContact : "NA",
            activePartyEmail:
              _activePartyEmail!="" ? _activePartyEmail : "NA",
            activePartyAddress:
              _activePartyAddress!="" ? _activePartyAddress : "NA",
            activePartyColor: _activePartyColor,
            activePartyTxt: _activePartyTxt,
          });
          if (_activePartyId > 0) {
            this.getTransactionListData(_activePartyId);
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadPartyListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _partyDetails = this.state.partyArrayFilter;

      if (_partyDetails.length > 0) {
        _partyDetails.map((value, index) => {
          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeParty == value.party_name) {
            _activeClass = "activeRow";
          }

          let _deletebtn = (
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              onClick={this.clickToDeleteParty.bind(this, value.party_id)}
              style={{ margin: "0px" }}
            >
              Delete
            </a>
          );
          if (value.isTransaction == true) {
            _deletebtn = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.clickToDeleteNotifiyParty.bind(
                  this,
                  value.party_id
                )}
                style={{ margin: "0px" }}
              >
                Delete
              </a>
            );
          }

          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.party_id,
                value.party_name,
                value.party_contact_no,
                value.totalOutCome,
                value.party_billing_address,
                value.party_email_id,
                value.color,
                value.txtBalance
              )}
            >
              <td>{value.party_name}</td>
              <td>{numberFormat(Math.abs(value.totalOutCome))} </td>
              <th className="text-center" scope="row">
                <div className="btn-group show" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right min-width-200"
                    aria-labelledby="toolbarDrop"
                    style={{ margin: "0px" }}
                  >
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-party/?auth=" +
                        value.party_id
                      }
                      style={{ margin: "0px" }}
                    >
                      Edit
                    </a>
                    {_deletebtn}
                  </div>
                </div>
              </th>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  clickToDeleteParty(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              party_id: value,
            });
            PostData(global.userPartyDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getPartyListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteNotifiyParty() {
    confirmAlert({
      title: "Party Alert",
      message:
        "This party account can not be deleted as it already has transactions. Please delete all transactions before deleting the party.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToActiveRow(
    id,
    txt,
    contact,
    pOpeningBal,
    pBAdd,
    party_email_id,
    color,
    txtShow
  ) {
    this.setState({
      activeParty: txt,
      party_name: txt,
      party_contact_no: contact,
      activePartyId: id,
      activePartyOpnBalanace: pOpeningBal,
      activePartyPhoneT: contact,
      activePartyPhone: contact!="" ? contact : "NA",
      activePartyEmail: party_email_id!="" ? party_email_id : "NA",
      activePartyAddress: pBAdd!="" ? pBAdd : "NA",
      activePartyColor: color,
      activePartyTxt: txtShow,
    });
    if (id > 0) {
      this.getTransactionListData(id);
    }
  }

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getPartyListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeletePartyTransfer(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              party_transfer_id: value,
            });
            PostData(global.userPartyTransferDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getPartyListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  loadTrasactionListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.partyArrayTransaction;
      _transDetails.sort(function(a, b) {

        var c = new Date(a.invoiceDateTime ? parseInt(a.invoiceDateTime) : a.invoice_date);
        var d = new Date(b.invoiceDateTime ? parseInt(b.invoiceDateTime) : b.invoice_date);
        return d-c;
      });

      if (_transDetails.length > 0) {
        let _actionExtra = "";
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;

          let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
          let _saleDuplicate = "";
          let _saleDuplicate1 = "";
          let _purchaseDuplicate = "";
          if (value.typeD == "party-open") {
            _actionExtra = "";
          } else if (value.typeD == "party-to-party") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/party-to-party-transfer/?auth=" +
                      value.id
                    }
                  >
                    View/Edit Details
                  </a>

                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDeletePartyTransfer.bind(
                      this,
                      value.id
                    )}
                  >
                    Delete
                  </a>
                </div>
              </div>
            );
          } else {
            let actionTrans = "#";
            let _type = "&tType=PARTY";
            if (value.type == "PURCHASE") {
              actionTrans =
                global.webUrl +
                "business/add-purchase/?auth=" +
                value.id +
                _type;
              let editDuplicate =
                global.webUrl +
                "business/manage-purchase-to-duplicate/?auth=" +
                value.id+'&tType=DUPLICATE';
              _purchaseDuplicate = (
                <a className="dropdown-item" href={editDuplicate}>
                  Duplicate
                </a>
              );
            } else if (value.type == "PURCHASE ORDER") {
              actionTrans =
                global.webUrl +
                "business/add-purchase-order/?auth=" +
                value.id +
                _type;
              let editDuplicate =
                global.webUrl +
                "business/manage-purchase-to-duplicate/?auth=" +
                value.id+'&tType=ORDER';
              let _actionDupicate =
                global.webUrl +
                "business/manage-purchase-order-duplicate/?auth=" +
                value.id;
              _saleDuplicate1 = (
                <a className="dropdown-item" href={_actionDupicate}>
                  Duplicate
                </a>
              );
              _saleDuplicate = (
                <a className="dropdown-item" href={editDuplicate}>
                  Convert To Purchase
                </a>
              );
            } else if (value.type == "DEBIT NOTE") {
              actionTrans =
                global.webUrl +
                "business/add-purchase-debit-note/?auth=" +
                value.id +
                _type;
            } else if (value.type == "PAYMENT-OUT") {
              actionTrans =
                global.webUrl +
                "business/add-purchase-payment-out/?auth=" +
                value.id +
                _type;
            } else if (value.type == "SALE") {
              actionTrans =
                global.webUrl + "business/add-sale/?auth=" + value.id + _type;
              let editDuplicate =
                global.webUrl + "business/add-sale-duplicate/?auth=" + value.id+'&tType=DUPLICATE';
              _saleDuplicate = (
                <a className="dropdown-item" href={editDuplicate}>
                  Duplicate
                </a>
              );
            } else if (value.type == "SALE ORDER") {
              actionTrans =
                global.webUrl +
                "business/add-sale-order/?auth=" +
                value.id +
                _type;
              let editDuplicate =
                global.webUrl + "business/add-sale-duplicate/?auth=" + value.id+'&tType=ORDER';
              _saleDuplicate = (
                <a className="dropdown-item" href={editDuplicate}>
                  Convert To Sale
                </a>
              );
              let _actionDupicate =
                global.webUrl +
                "business/add-sale-duplicate-order/?auth=" +
                value.id;
              _saleDuplicate1 = (
                <a className="dropdown-item" href={_actionDupicate}>
                  Duplicate
                </a>
              );
            } else if (value.type == "ESTIMATE") {
              actionTrans =
                global.webUrl +
                "business/add-sale-estimate/?auth=" +
                value.id +
                _type;
              let editDuplicate =
                global.webUrl + "business/add-sale-duplicate/?auth=" + value.id+'&tType=DUPLICATE';
              _saleDuplicate = (
                <a className="dropdown-item" href={editDuplicate}>
                  Convert To Sale
                </a>
              );
              let _actionDuplicate =
                  global.webUrl +
                  "business/add-sale-estimate/?auth=" +
                  value.id+_type+"&isDuplicate="+true;
              _saleDuplicate1 = (
                  <a className="dropdown-item" href={_actionDuplicate}>
                    Duplicate
                  </a>
              );
            } else if (value.type == "CREDIT NOTE") {
              actionTrans =
                global.webUrl +
                "business/add-sale-credit-note/?auth=" +
                value.id +
                _type;
            } else if (value.type == "PAYMENT-IN") {
              actionTrans =
                global.webUrl +
                "business/add-sale-payment-in/?auth=" +
                value.id +
                _type;
            } else if (value.type == "DELIVERY CHALLAN") {
              actionTrans =
                global.webUrl +
                "business/add-sale-delivery-challan/?auth=" +
                value.id +
                _type;
            } else if (value.type == "EXPENSE") {
              let _ttype = "&tType=manage-party";
              actionTrans =
                global.webUrl +
                "business/add-expense/?auth=" +
                value.id +
                _ttype;
            }

            
            if(_trans_duplicate_normal == 'Duplicate'){
              _saleDuplicate = '';
              _saleDuplicate1 = '';
          }
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal == 'Normal' &&
                    <a className="dropdown-item" href={actionTrans}>
                      View/Edit Details
                    </a>
                  }
                  
                  {_saleDuplicate}
                  {_saleDuplicate1}

                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this, value.id, "Sale")}
                  >
                    Delete
                  </a>
                  {/* isChallanPreview={false} */}
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                </div>
              </div>
            );
          }

          let _showDupL = '';
          if(_trans_duplicate_normal == 'Duplicate'){
              _showDupL = ' (Duplicate)';
          }
          let _invoiceNo = "";
          if (value.invoice_no!="" && value.type == "PAYMENT-IN") {
            _invoiceNo = "Receipt #" + value.invoice_no;
          } else if (value.invoice_no!="" && value.type == "PAYMENT-OUT") {
            _invoiceNo = "Receipt #" + value.invoice_no;
          } else if (
            value.invoice_no!="" &&
            value.type!="PAYMENT-IN" &&
            value.type!="PAYMENT-OUT"
          ) {
            _invoiceNo = value.customPrefix + " #" + value.invoice_no;
          }
          let balance = "";
          if (value.type == "PAYMENT-OUT" || value.type == "PAYMENT-IN") {
            balance = numberFormat(value.unUsedAmount); //numberFormat(0);
            if(value.balance === value.unUsedAmount){
              balance = numberFormat(value.balance);
            }
          } else {
            balance = numberFormat(value.balance);
          }
          let _payment_status = value.payment_paid;
          if (_payment_status == "" || _payment_status.length <= 1) {
            _payment_status = "NA";
          }
          if(index == 0){
            console.log('value', JSON.stringify(value))
          }
          let dateTime = value.invoiceDateTime ? moment(parseInt(value.invoiceDateTime)).format('MMM DD, YYYY'+(_businessSetting.Tran_AddTime == 1 ? ' HH:mm A' : '')) : value.invoice_date;
          rows.push({
            srNo: _i, //value.srNo,
            invoice_date: dateTime,
            invoice_no: _invoiceNo + _showDupL,
            party_name: value.party_name,
            type: value.type,
            amount: numberFormat(value.total_amount),
            balance: balance,
            payment_status: _payment_status,
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /* Code For Upload - Import Excel */
  onChangeFileUploadHandler = (event) => {
    this.setState({ importExcelData: [] });
    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
              // evt = on_file_select event
              /* Parse data */
              const bstr = evt.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
              /* Update state */
              //console.log("Data>>>" + data);// shows that excel data is read
              let _jsonData = this.convertToJson(data);
              console.log('JSON',_jsonData); // shows data in json format
              if(_jsonData.length > 0){
                let errors = this.state.errors;
                errors["importExcelFile"] = "";
                this.setState({ errors: errors });
              }
              this.setState({
                importExcelData: _jsonData,
                importExcelFile: file,
              });
            };
            reader.readAsBinaryString(file);
          });
        })
      );
    }
  };

  convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var currentline = lines[i].split(",");
      let _fileColumn = 7;
      var obj = {};
      for (var j = 0; j < _fileColumn; j++) {
        if (currentline[0]!="") {
          obj[headers[j]] = currentline[j];
        }
      }
      if(obj.hasOwnProperty('Name')){
        if (obj["Name"].length > 0 && obj["Opening Balance"] >= 0) {
          result.push(obj);
        }
      }
    }
    return JSON.stringify(result); //JSON
  }

  clickToImportSheet = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.importExcelFile == "") {
      _isError = true;
      errors["importExcelFile"] = "*Please Upload Party Excel File.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      this.importSheetMethodCall();
    }
  };

  importSheetMethodCall() {
    const requestData = JSON.stringify({
      importExcelData: this.state.importExcelData,
      userId: this.state.userId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });
    console.log('Length',JSON.parse(this.state.importExcelData).length);
    if (JSON.parse(this.state.importExcelData).length > 0) {
      PostData(global.userImportPartySheet, requestData, "POST").then(
        (result) => {
          if (result.success == true) {
            sendNotification("Success Message 👍", result.message, "success");
            this.closeModalHandler("modalUploadParty");
            this.getPartyListData();
            this.loadPartyListing();
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
    } else {
      sendNotification(
        "Error Message 😓",
        "Please Upload Correct Party Excel File",
        "danger"
      );
      return;
    }
  }
  /* End Code For Upload - Import Excel */

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalAdjustparty") {
      this.setState({ modalIsOpen_AJP: true });
    } else if (popId == "modalAddParty") {
      this.setState({ modalIsOpen_AC: true });
    } else if (popId == "modalUploadParty") {
      this.setState({ modalIsOpen_IMI: true });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustparty") {
      this.setState({ party_contact_no: this.state.activePartyPhone });
      this.setState({ party_name: this.state.activeParty });
      this.setState({ modalIsOpen_AJP: false });
    } else if (popId == "modalAddParty") {
      this.setState({ modalIsOpen_AC: false });
    } else if (popId == "modalUploadParty") {
      this.setState({ modalIsOpen_IMI: false });
    }
  };
  /*End Modal open close*/

  /* Adjust Party */
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "party_name":
        errors.party_name =
          value.length < 3 ? "*Party Name must be 3 characters long!" : "";
        this.setState({ party_name: value });
        break;
      case "party_contact_no":
        //errors.party_contact_no = value.length < 9 ? '*phone no must be 10 number ' : '';
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          //errors.party_contact_no = "*Please enter valid phone no.";
        }
        this.setState({ party_contact_no: value });
        break;
      default:
        break;
    }
  };

  clickToUpdatePartyInfo = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter party Name";
      this.setState({ errors: errors });
    }
    if (this.state.party_contact_no == "") {
      // _isError = true;
      // errors["party_contact_no"] = "*Please Enter phone no.";
      // this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.activePartyId > 0) {
        const requestData = JSON.stringify({
          partyId: this.state.activePartyId,
          businessId: this.state.businessId,
          company_id: this.state.company_id,
          party_name: this.state.party_name,
          party_contact_no: this.state.party_contact_no,
        });

        PostData(global.userEditPartyInfo, requestData, "POST").then(
          (result) => {
            if (result.success == true) {
              sendNotification("Success Message 👍", result.message, "success");
              this.closeModalHandler("modalAdjustparty");
              this.loadPartyListing();
              this.getPartyListData();
            } else {
              sendNotification("Error Message 😓", result.message, "danger");
              let errors = this.state.errors;
              errors["party_name"] = result.message; //'Please enter valid phone no.';
              this.setState({
                errors: errors,
              });
              return;
            }
          }
        );
      }
    }
  };
  /* End Here Adjust Party*/

  /* party Filter Code */
  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ partyArrayFilter: filtered });
    this.loadPartyListing();
  };
  /* End party Filter Code */

  setFilterSearch = () => {
    this.setState({ filterSearch: !this.state.filterSearch });
  };
  setFilterSearchCancel = () => {
    this.setState({
      filterSearch: !this.state.filterSearch,
      partyArrayFilter: this.state.partyArray,
    });
    this.loadPartyListing();
  };
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Party List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/add-party/"}
            >
              Add Party
            </a>
            <span className="breadcrumb-item active">Manage Party List</span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    {this.state.filterSearch ? (
                      <div className="mb-10">
                        <button
                          type="button"
                          data-toggle="layout"
                          data-action="header_search_on"
                          className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                          onClick={this.setFilterSearch}
                        >
                          <i className="fa fa-search"></i>
                        </button>

                        <div
                          className="btn-group pull-right"
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.openModalHandler.bind(
                              this,
                              "modalAddParty"
                            )}
                          >
                            <i className="fa fa-plus mr-5"></i> <b>Add Party</b>
                          </button>
                          <div className="btn-group show" role="group">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              id="toolbarDrop"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                            ></button>
                            <div
                              className="dropdown-menu dropdown-menu-right min-width-200"
                              aria-labelledby="toolbarDrop"
                              style={{ margin: "0px" }}
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0)"
                                onClick={this.openModalHandler.bind(
                                  this,
                                  "modalUploadParty"
                                )}
                                style={{ margin: "0px" }}
                              >
                                <i className="fa fa-fw fa-bell mr-5"></i>Import
                                Parties
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div id="page-header-search" className="show mb-10">
                        <div className="content-header content-header-fullrow">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-toggle="layout"
                                data-action="header_search_off"
                                onClick={this.setFilterSearchCancel}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                            <input
                              type="text"
                              onChange={this.onPartyFilterName}
                              className="form-control"
                              placeholder="Search here.."
                              id="page-header-search-input"
                              name="page-header-search-input"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th>Party Name</th>
                          <th style={{ width: "80px" }}>Amount&nbsp;</th>
                          <th style={{ width: "50px" }}>&nbsp;&nbsp;Action</th>
                        </tr>
                      </thead>
                      <tbody id="sideTable">{this.loadPartyListing()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    <div className="col-lg-12">
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <b>{this.state.activeParty}</b>{" "}
                          <i className="si si-pin"></i>
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary mr-5 mb-5 pull-right"
                            onClick={this.openModalHandler.bind(
                              this,
                              "modalAdjustparty"
                            )}
                          >
                            <i className="si si-equalizer mr-5"></i>Adjust Party
                          </button>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>Phone : {this.state.activePartyPhone}</span>

                          <span className="pull-right">
                            Address : {this.state.activePartyAddress}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>Email : {this.state.activePartyEmail}</span>
                          {/* text-danger */}
                          <span className="pull-right">
                            Opening Balance :{" "}
                            <span
                              style={{ color: this.state.activePartyColor }}
                              // class={
                              //   this.state.activePartyColor == "Green"
                              //     ? "text-success"
                              //     : "text-danger"
                              // }
                            >
                              {numberFormat(
                                Math.abs(this.state.activePartyOpnBalanace)
                              )}
                              {_clearBal}
                              <br />
                              <span className="pull-right">
                                {this.state.activePartyTxt}
                              </span>
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="block-content">
                  {this.state.isLoadedPartyTrans == false && (
                    <div>
                      <p>Data Loading.....</p>
                    </div>
                  )}
                  {this.state.isLoadedPartyTrans == true && (
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTrasactionListing()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Party Add Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AC}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddParty"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Create New Party</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddParty"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickAddNewParty
                      isMainPage={false}
                      isRedirectTo={"business/manage-party/"}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this, "modalAddParty")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Party Add*/}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AJP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustparty"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Party Adjustment</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustparty"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <form
                      method="post"
                      id="form-login"
                      className="webForm px-30"
                    >
                      <h3
                        className="content-heading"
                        style={{ paddingTop: "6px" }}
                      >
                        <i className="fa fa-angle-right"></i> Personal Details{" "}
                      </h3>
                      <div className="form-group row">
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              required="required"
                              className="form-control"
                              id="party_name"
                              name="party_name"
                              value={this.state.party_name}
                              placeholder="Enter Party Name"
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Party Name</label>
                            <span className="vs_error_txt">
                              {errors.party_name.length > 0 && (
                                <span className="error">
                                  {errors.party_name}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              required="required"
                              className="form-control"
                              id="party_contact_no"
                              name="party_contact_no"
                              value={this.state.party_contact_no}
                              placeholder="Enter Phone No."
                              onChange={this.handleChange}
                              maxlength="12"
                            />
                            <label htmlFor="login-username">Phone No.</label>
                            <span className="vs_error_txt">
                              {errors.party_contact_no.length > 0 && (
                                <span className="error">
                                  {errors.party_contact_no}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="claerfix"></div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-alt-primary"
                            disabled={isDisabled}
                            onClick={this.clickToUpdatePartyInfo}
                          >
                            <i className="fa fa-check mr-5"></i> Save Party
                            Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustparty"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_IMI}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadParty"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Import Excel - Party Details</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadParty"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <form
                      method="post"
                      id="form-login"
                      className="webForm px-30"
                    >
                      <h3
                        className="content-heading"
                        style={{ paddingTop: "6px" }}
                      >
                        <i className="fa fa-angle-right"></i> Upload Party Excel
                        Details{" "}
                      </h3>
                      <div className="form-group row">
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              accept=".xls,.xlsx"
                              type="file"
                              required="required"
                              className="form-control"
                              id="importExcelFile"
                              name="importExcelFile"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            <label htmlFor="login-username">
                              Upload Import Excel Party File
                            </label>
                            <span className="vs_error_txt">
                              {errors.importExcelFile.length > 0 && (
                                <span className="error">
                                  {errors.importExcelFile}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <span className="font-w600 text-info justify-content-end">
                            Download .xls/.xlsx (excel file) template file to
                            upload data
                          </span>
                          <br />
                          <a
                            href={
                              global.webUrl +
                              "sample-file/Import Parties Template.xlsx"
                            }
                            target="_blank"
                            className="btn btn-alt-info min-width-125"
                          >
                            Download <i className="fa fa-file-excel-o ml-5"></i>
                          </a>
                        </div>
                      </div>
                      <div className="claerfix"></div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            type="submit"
                            disabled={isDisabled}
                            className="btn btn-sm btn-hero btn-alt-primary"
                            onClick={this.clickToImportSheet}
                          >
                            <i className="fa fa-check mr-5"></i> Import Party
                            Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadParty"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}
      </React.Fragment>
    );
  }
}
