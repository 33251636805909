import React,{Component} from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import {PostData} from '../../service/postData';
import { Link } from 'react-router-dom';
import { numberFormat } from '../../config/numberFormat';

global.BACK_URL = 'Dashboard';

let vsSession = localStorage.getItem('vs_UserSession');
let _authName = 'Business User';
if(vsSession!=undefined || vsSession!=null)
{
    vsSession = JSON.parse(vsSession);
    _authName = vsSession ? vsSession.loginName : global.loginName;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Type',
        field: 'type',
        width: 50,
    },
    {
        label: 'Name',
        field: 'name',
        width: 150,
    },
    {
        label: 'Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Quantity',
        field: 'quantity',
        width: 100,
    },
    {
        label: 'Price/Unit',
        field: 'price',
        width: 100,
    },
    {
        label: 'Status',
        field: 'status',
        width: 100,
    }
];


export default class DashboardPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            listTitle : 'Parties',
            transactionArrayFilter : [],
            transactionArray : []
        }
    }

    componentDidMount()
    {
        let _transArray = [];
        const requestData = JSON.stringify({
            business_id:this.state.businessId,
            company_id:this.state.company_id
        });

        PostData(global.userSaleTransactionViaTypeList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                let _paidAmt = 0;
                let _unpaidAmt = 0;
                let _totalAmt = 0;
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    _transArray.map((itm, i) =>{
                        if(itm.payment_paid == 'UNPAID'){
                            _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.total_amount);
                        }
                        else{
                            _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.total_amount);
                        }
                    });
                }
                _totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
                this.setState({transactionArrayFilter:_transArray,transactionArray:_transArray,isLoaded:true});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    loadSaleListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _partyDetails = this.state.partyArray;
        }
    
        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }
    
    manageTabList(value){
        this.setState({listTitle:value});
        //this.buttonAddShow();
    }

    buttonAddShow()
    {
        //alert('calll');
        let _returnButton = '<span>Add New Party</span>';
        if(this.state.listTitle == 'Parties'){
            _returnButton = <div><Link to={"/business/manage-party/"} className="btn btn-sm btn-outline-secondary"><span><i className="si si-plus"></i> Add New Party</span></Link></div>
        }
        /*else if(this.state.listTitle == 'Transactions'){
            _returnButton = <div><a href={global.webUrl+"business/sale-invoice/"} className="btn btn-sm btn-outline-secondary"><span><i className="si si-plus"></i> Add New Transaction 666</span></a></div>
        }*/
        else if(this.state.listTitle == 'Items'){
            _returnButton = <div><Link to={"/business/manage-items/"} className="btn btn-sm btn-outline-secondary"><span><i className="si si-plus"></i> Add New Item</span></Link></div>
        }
        return _returnButton;
    }

    render(){
        
        return(
            <React.Fragment>
                <div className="content">
                    <div className="row invisible" data-toggle="appear">
                        <div className="col-6 col-xl-3">
                            <Link className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-emoticon-smile fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600">+<span data-toggle="countTo" data-speed="1000" data-to="780">0</span></div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Parties</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 col-xl-3">
                            <Link className="block block-link-shadow text-right" to={"/business/add-sale/"}>
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-basket fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600">+<span data-toggle="countTo" data-speed="1000" data-to="780">0</span></div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Sales</div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-6 col-xl-3">
                            <Link className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="si si-basket fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600">+<span data-toggle="countTo" data-speed="1000" data-to="780">0</span></div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Purchase</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 col-xl-3">
                            <Link className="block block-link-shadow text-right" href="javascript:void(0)">
                                <div className="block-content block-content-full clearfix">
                                    <div className="float-left mt-10 d-none d-sm-block">
                                        <i className="fa fa-check fa-3x text-body-bg-dark"></i>
                                    </div>
                                    <div className="font-size-h3 font-w600">+<span data-toggle="countTo" data-speed="1000" data-to="780">0</span></div>
                                    <div className="font-size-sm font-w600 text-uppercase text-muted">Items</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="block">
                                <ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist">
                                    <li className="nav-item">
                                        <Link className="nav-link active" href="javascript:void(0)" onClick={this.manageTabList.bind(this,'Parties')}>
                                            <i className="si si-emoticon-smile mr-5 text-primary"></i> Parties
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" href="javascript:void(0)" onClick={this.manageTabList.bind(this,'Transactions')}>
                                            <i className="si si-basket text-info"></i> Transactions
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" href="javascript:void(0)" onClick={this.manageTabList.bind(this,'Items')}>
                                            <i className="si si-check text-success"></i> Items
                                        </Link>
                                    </li>
                                </ul>
                                <div className="block-content tab-content">
                                    <div className="tab-pane active" id="btabs-static-home" role="tabpanel">
                                        <div className="block-header">
                                            <h3 className="block-title"># {this.state.listTitle} List</h3>
                                            <div className="block-options">{this.buttonAddShow()}</div>
                                        </div>

                                        <div className="block-content">
                                                <MDBDataTableV5
                                                hover
                                                entriesOptions={[25, 50, 100]}
                                                entries={5}
                                                pagesAmount={4}
                                                span
                                                pagingTop
                                                searchTop
                                                searchBottom={false}
                                                barReverse
                                                data={this.loadSaleListing()}
                                                />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </React.Fragment>
        )
    }
} 