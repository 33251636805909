import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};

let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        
        
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,        
            
            TransportationDetailsField1Name : (_businessSetting.TransportationDetailsField1Name) ? _businessSetting.TransportationDetailsField1Name : '',//Transport Name
            TransportationDetailsField1Enable : (_businessSetting.TransportationDetailsField1Enable > 0) ? true : false,      
            
            TransportationDetailsField2Name : (_businessSetting.TransportationDetailsField2Name) ? _businessSetting.TransportationDetailsField2Name : '',//Vechicle Number
            TransportationDetailsField2Enable : (_businessSetting.TransportationDetailsField2Enable > 0) ? true : false,      
            
            TransportationDetailsField3Name : (_businessSetting.TransportationDetailsField3Name) ? _businessSetting.TransportationDetailsField3Name : '',//Delivery Date
            TransportationDetailsField3Enable : (_businessSetting.TransportationDetailsField3Enable > 0) ? true : false,      
            
            TransportationDetailsField4Name : (_businessSetting.TransportationDetailsField4Name) ? _businessSetting.TransportationDetailsField4Name : '',//Delivery Location
            TransportationDetailsField4Enable : (_businessSetting.TransportationDetailsField4Enable > 0) ? true : false,      
            
            TransportationDetailsField5Name : (_businessSetting.TransportationDetailsField5Name) ? _businessSetting.TransportationDetailsField5Name : '',//Field 5
            TransportationDetailsField5Enable : (_businessSetting.TransportationDetailsField5Enable > 0) ? true : false,      
            
            TransportationDetailsField6Name : (_businessSetting.TransportationDetailsField6Name) ? _businessSetting.TransportationDetailsField6Name : '',//Field 6
            TransportationDetailsField6Enable : (_businessSetting.TransportationDetailsField6Enable > 0) ? true : false,
                 
        }
    }

    componentDidMount()
    {  

    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        
        if(_key == 'TransportationDetailsField1Enable'){
            this.setState({TransportationDetailsField1Enable:_value}); 
            document.getElementById('TransportationDetailsField1Name').disabled = true;
            if(_value == 1){
                document.getElementById('TransportationDetailsField1Name').disabled = false;
            }
        } 
        if(_key == 'TransportationDetailsField2Enable'){
            this.setState({TransportationDetailsField2Enable:_value}); 
            document.getElementById('TransportationDetailsField2Name').disabled = true;
            if(_value == 1){
                document.getElementById('TransportationDetailsField2Name').disabled = false;
            }
        }
        if(_key == 'TransportationDetailsField3Enable'){
            this.setState({TransportationDetailsField3Enable:_value}); 
            document.getElementById('TransportationDetailsField3Name').disabled = true;
            if(_value == 1){
                document.getElementById('TransportationDetailsField3Name').disabled = false;
            }
        }
        if(_key == 'TransportationDetailsField4Enable'){
            this.setState({TransportationDetailsField4Enable:_value}); 
            document.getElementById('TransportationDetailsField4Name').disabled = true;
            if(_value == 1){
                document.getElementById('TransportationDetailsField4Name').disabled = false;
            }
        }
        if(_key == 'TransportationDetailsField5Enable'){
            this.setState({TransportationDetailsField5Enable:_value}); 
            document.getElementById('TransportationDetailsField5Name').disabled = true;
            if(_value == 1){
                document.getElementById('TransportationDetailsField5Name').disabled = false;
            }
        }
        if(_key == 'TransportationDetailsField6Enable'){
            this.setState({TransportationDetailsField6Enable:_value}); 
            document.getElementById('TransportationDetailsField6Name').disabled = true;
            if(_value == 1){
                document.getElementById('TransportationDetailsField6Name').disabled = false;
            }
        }

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'TransportationDetailsField1Name'){
            this.setState({TransportationDetailsField1Name:activeValue}); 
        } 
        if(_keyVal == 'TransportationDetailsField2Name'){
            this.setState({TransportationDetailsField2Name:activeValue}); 
        } 
        if(_keyVal == 'TransportationDetailsField3Name'){
            this.setState({TransportationDetailsField3Name:activeValue}); 
        }  
        if(_keyVal == 'TransportationDetailsField4Name'){
            this.setState({TransportationDetailsField4Name:activeValue}); 
        } 
        if(_keyVal == 'TransportationDetailsField5Name'){
            this.setState({TransportationDetailsField5Name:activeValue}); 
        } 
        if(_keyVal == 'TransportationDetailsField6Name'){
            this.setState({TransportationDetailsField6Name:activeValue}); 
        }      
        //console.log(_keyVal+'=='+activeValue+'===STATE=='+this.state.PartyAddField1Name+'==END==');
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    
    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _disabled1 = 'disabled';
        let _disabled2 = 'disabled';
        let _disabled3 = 'disabled';
        let _disabled4 = 'disabled';
        let _disabled5 = 'disabled';
        let _disabled6 = 'disabled';
        if(this.state.TransportationDetailsField1Enable == 1 || this.state.TransportationDetailsField1Enable == true){
            _disabled1 = '';
        }
        if(this.state.TransportationDetailsField2Enable == 1 || this.state.TransportationDetailsField2Enable == true){
            _disabled2 = '';
        }
        if(this.state.TransportationDetailsField3Enable == 1 || this.state.TransportationDetailsField3Enable == true){
            _disabled3 = '';
        }
        if(this.state.TransportationDetailsField4Enable == 1 || this.state.TransportationDetailsField4Enable == true){
            _disabled4 = '';
        }
        if(this.state.TransportationDetailsField5Enable == 1 || this.state.TransportationDetailsField5Enable == true){
            _disabled5 = '';
        }
        if(this.state.TransportationDetailsField6Enable == 1 || this.state.TransportationDetailsField6Enable == true){
            _disabled6= '';
        }
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-12">
                        <h6 className="content-heading"></h6>                        
                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'8%'}}>Feild 1</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Transport Name" data-slug="TransportationDetailsField1Name" id="TransportationDetailsField1Name" name="TransportationDetailsField1Name" value ={this.state.TransportationDetailsField1Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled1} />
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransportationDetailsField1Enable > 0) ? true : false} id="TransportationDetailsField1Enable" name="TransportationDetailsField1Enable" value="1" data-slug="TransportationDetailsField1Enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                             
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'8%'}}>Feild 2</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Vechicle Number" data-slug="TransportationDetailsField2Name" id="TransportationDetailsField2Name" name="TransportationDetailsField2Name" value ={this.state.TransportationDetailsField2Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled2}  />
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransportationDetailsField2Enable > 0) ? true : false} id="TransportationDetailsField2Enable" name="TransportationDetailsField2Enable" value="1" data-slug="TransportationDetailsField2Enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                            
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'8%'}}>Feild 3</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Delivery Date" data-slug="TransportationDetailsField3Name" id="TransportationDetailsField3Name" name="TransportationDetailsField3Name" value ={this.state.TransportationDetailsField3Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled3}  />
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransportationDetailsField3Enable > 0) ? true : false} id="TransportationDetailsField3Enable" name="TransportationDetailsField3Enable" value="1" data-slug="TransportationDetailsField3Enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                            
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'8%'}}>Feild 4</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Delivery Location" data-slug="TransportationDetailsField4Name" id="TransportationDetailsField4Name" name="TransportationDetailsField4Name" value ={this.state.TransportationDetailsField4Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled4}  />
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransportationDetailsField4Enable > 0) ? true : false} id="TransportationDetailsField4Enable" name="TransportationDetailsField4Enable" value="1" data-slug="TransportationDetailsField4Enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                            
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'8%'}}>Feild 5</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Feild 5" data-slug="TransportationDetailsField5Name" id="TransportationDetailsField5Name" name="TransportationDetailsField5Name" value ={this.state.TransportationDetailsField5Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled5}  />
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransportationDetailsField5Enable > 0) ? true : false} id="TransportationDetailsField5Enable" name="TransportationDetailsField5Enable" value="1" data-slug="TransportationDetailsField5Enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                            
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'8%'}}>Feild 6</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Feild 6" data-slug="TransportationDetailsField6Name" id="TransportationDetailsField6Name" name="TransportationDetailsField6Name" value ={this.state.TransportationDetailsField6Name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled6}  />
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TransportationDetailsField6Enable > 0) ? true : false} id="TransportationDetailsField6Enable" name="TransportationDetailsField6Enable" value="1" data-slug="TransportationDetailsField6Enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                           
                        </ul>                        
                    </div>                    
                </div> 
            </React.Fragment>
        )
    }
} 