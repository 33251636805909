let _http = "http:";
if (window.location.protocol == "https:") {
  _http = "https:";
}
global.PATH_FOLDER = "/";
let _mode = "PROD"; // Define Here --> PROD / TEST 

let ENV = "CLIENT"; // CLIENT  DEVELOPER

let DOMAIN = _http + "//www.vyavsay.in/";

if (ENV == "DEVELOPER") {
  DOMAIN = _http + "//www.developer.vyavsay.in/";
}
 
global.webUrl = DOMAIN;

if (_mode == "TEST") {
  global.webUrl = "http://localhost:3000/";
}

global.API_ADMIN_URL = DOMAIN + "api/adminApi/";
global.API_BUSINESS_URL = DOMAIN + "api/businessApi/";
global.API_USER_URL = DOMAIN + "api/userApi/";
global.API_WEBSITE_URL = DOMAIN + "/api/webApi/";
global.API_URL = DOMAIN + "api/";
global.LIVE_URL = DOMAIN;

global.FLAG_URL = DOMAIN + "uploadDirectory/flags/flags-small/";
global.BACK_URL = "";
