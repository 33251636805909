import React,{Component} from 'react';
import {PostData} from '../service/postData';
import {sendNotification} from '../_notificationSettings/notificationPanel';
import { Helmet } from 'react-helmet';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';

export default class homePagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            visibleStartBtn : false,
            mobileNumber: '',
            mobileErrorMessage: '',
            name:'',
            email:'',
            contact:'',
            message:'',
            errors:{
                name:'',
                email:'',
                contact:'',
                message:'',
            }
        }
    }

    async componentDidMount(){
        this.renderBlogView(0,'N');
    }


    renderBlogView(categoryId,know)
    {
        let _blogArray = [];
        
        let _url_GetData = global.webistePageBlogList+'?categoryId='+categoryId+'&limit=0,3';
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                let onRemainCount = 0;
                if (responseJson.response) {
                    _blogArray = responseJson.response;
                }
                this.setState({blogArray:_blogArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    viewWebsiteBlogListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _blogDetails = this.state.blogArray;
            let dataList = [];

            if(_blogDetails.length > 0)
            {                          
                _blogDetails.map((compDatas, i) =>
                {
                    const row = compDatas;

                    let _imageUrl = global.webUrl+'img/blog_1.png';
                    if(row.image!=""){
                        _imageUrl = row.image;
                    }
                    
                    let _bTitle = atob(row.title);
                    let url_titles = _bTitle.replace(/ /gi,'-');
                    let url_title = url_titles.replace("?", "_");
                    let blog_id = row.blog_id;
                    let _finalUrl = global.webUrl+'blog/'+blog_id+'/'+url_title+'/';
                    let _shortDesc = atob(row.short_description);

                    dataList.push(
                        <div className="col-lg-4">
                            <div className=" card">
                                <a href={_finalUrl} className="link_poet">
                                    <div className="cover_link">
                                        <img className="card-img_top main_img" src={_imageUrl} alt="..." />
                                    </div>
                                </a>
                                <div className="card-body">
                                    <a href={_finalUrl} className="d-block">
                                        <h5 className="card-title">{_bTitle}</h5>
                                    </a>
                                    <p className="card-text">{_shortDesc}</p>
                                    <br/>
                                    <a href={_finalUrl} className="btn3">Read Full Blog</a>                                    
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            return dataList;
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'name': 
            errors.name = 
                value.length < 3 ? '*Name must be 3 characters long!' : '';
                this.setState({name:value}); 
            break;
            case 'email': 
                errors.email = value.length < 5 ? '*Please enter valid email id!' : '';
                this.setState({email:value}); 
            break;
            case 'contact': 
                errors.contact = value.length < 5 ? '*Please enter valid mobile no.!' : '';
                if (/^\d*$/.test(value)) {
                    if (value.length >= 0 && value.length <= 10) {
                        this.setState({contact:value}); 
                    }
                  }else{
                    errors.contact = "*Please enter valid mobile no.!";
                  }
                  
                
            break;
            case 'message': 
                errors.message =  value.length < 0 ? '*Please enter some message!' : '';
                this.setState({message:value}); 
            break;
            default:
            break;
        }
    }

    clickToSubmitEnqury = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.name == '')
        {
            _isError = true;
            errors['name'] = "*Name must be 3 characters long!";
            this.setState({errors: errors});
        }
        const regex = /^\d{10}$/;
        if(regex.test(this.state.contact)){
            
        }else{
            _isError = true;
            errors['contact'] = "*Please Enter Contact No.";
            this.setState({errors: errors});
            alert('Mobile number is invalid.')
        }
        
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        
        if(this.state.email && isValidEmail.test(this.state.email)){
            
        }else{
            _isError = true;
            errors['email'] = "*Please Enter Email-Id.";
            this.setState({errors: errors});
        }
        
        if(this.state.message == "")
        {
            _isError = true;
            errors['message'] = "*Please Enter Some Message.";
            this.setState({errors: errors});
        }

        if(_isError == false){
            this.getTouchFormSubmit();
        }
    }

    getTouchFormSubmit()
    {
        const requestData = JSON.stringify({
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            message: this.state.message,
            pageSubject:'Enquiry From Get In Touch - Home Page',
        });
        
        PostData(global.webistePageGetTouchForm, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                this.setState({name: '',contact: '',email: '',message: ''});
                sendNotification("Success Message 👍","Enquiry mail send successfully.","success");
            }
            else
            {
                sendNotification("Error Message 😓","Some error occured. Please try again.","danger");
                return;
            }
        });
    }

    toggleDialogStartBtn = (event) => {
        event.preventDefault();
        const regex = /^\d{10}$/;
        if(regex.test(this.state.mobileNumber)){
            this.setState({visibleStartBtn: !this.state.visibleStartBtn})
        }else{
            alert('Mobile number is invalid.')
        }
    }

    handleInputChange = (event) => {
        const value = event.target.value;
    
        if (/^\d*$/.test(value)) {
          if (value.length >= 0 && value.length <= 10) {
            this.setState({mobileNumber: value})
          }
        }
      };

    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <Helmet>
                    <title>Vyavsay App</title>
                    <meta name="description" content="Vyavsay App"/>
                    <meta name="keywords" content="Vyavsay App" />
                </Helmet>
                <div id="page-wrapper">
                
                    <div id="main" className="mainSectionWebsite">
                        <section className="header" id="headerSectionLogo">
                            <div className="container" id="menuSection">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row hero has_style1">
                                    <div className="col-lg-8">
                                        <h1 className="txt-title-1">An Advanced <b>Billing</b> & <b>Stock Inventory Accounting software</b> for your business</h1>
                                        <br/>
                                        <form className="banner_form">
                                        <input
                                            type="text"
                                            value={this.state.mobileNumber}
                                            onChange={this.handleInputChange}
                                            placeholder="Enter your mobile number"
                                        />
                                            <button type="submit"  onClick={this.toggleDialogStartBtn}>Get Started</button>
                                            <div className="clearfix"></div>
                                        </form>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={global.webUrl+"websiteFilesFolder/img/banner-img.png"} alt="" title="" />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="after_header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="block1">
                                            <span><i className="fas fa-star"></i></span>
                                            <h3>Highly Rated</h3>
                                            <p>Above 60,658 ratings on Google Play Store</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                    <div className="block1 block2">
                                            <span><i className="fas fa-download"></i></span>
                                            <h3>5 Million+ Downloads</h3>
                                            <p>More than 30,00,000 satisfied users</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                    <div className="block1">
                                    <span><i className="fas fa-check-double"></i></span>
                                            <h3>Completely Free</h3>
                                            <p>Our mobile app (Android) is Free for Lifetime</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="txt-title-3">Make GST Bills & Share with your Customers</h2>
                                        <span className="line"></span>
                                        <p className="txt-style1">A professional invoice stands for a brand's identity. By using vyavsay billing software, you can create GST bills that comply with the goods...</p>
                                        <div>
                                            <span id="text">CSS is designed primarily to enable the separation of document content from document presentation, including aspects such as the layout, colors, and fonts. This separation can improve content accessibility, provide more flexibility and control in the specification of presentation characteristics, enable multiple HTML pages to share formatting by specifying the relevant CSS in a separate .css file, and reduce complexity and repetition in the structural content.
                                            <br/><br/>
                                            Separation of formatting and content makes it possible to present the same markup page in different styles for different rendering methods, such as on-screen, in print, by voice (via speech-based browser or screen reader), and on Braille-based tactile devices. It can also display the web page differently depending on the screen size or viewing device. Readers can also specify a different style sheet, such as a CSS file stored on their own computer, to override the one the author specified.
                                            <br/><br/>
                                            Changes to the graphic design of a document (or hundreds of documents) can be applied quickly and easily, by editing a few lines in the CSS file they use, rather than by changing markup in the documents.
                                            <br/><br/>
                                            The CSS specification describes a priority scheme to determine which style rules apply if more than one rule matches against a particular element. In this so-called cascade, priorities (or weights) are calculated and assigned to rules, so that the results are predictable.
                                            <br/><br/>
                                            The CSS specifications are maintained by the World Wide Web Consortium (W3C). Internet media type (MIME type) text/css is registered for use with CSS by RFC 2318 (March 1998). The W3C operates a free CSS validation service for CSS documents.
                                            <br/>
                                            </span>
                                        </div>
                                        <div className="btn-container" align="center">
                                            <button id="toggle">Read More</button>
                                        </div>
                                        <br/><br/>
                                        <div align="center">
                                            <img src={global.webUrl+"websiteFilesFolder/img/sec1.png"} alt="" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                        <section className="sec2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img src={global.webUrl+"websiteFilesFolder/img/sec2.png"} alt="" title="" />
                                    </div>
                                    <div className="col-lg-6 space1">
                                        <h2 className="txt-title-3">Check Inventory Instantly</h2>
                                        <span className="line"></span>
                                        <p className="txt-style1">The vyavsay billing & invoicing software comes with a highly effective inventory management system that helps you track down the items in...</p>
                                        <div>
                                            <span id="text1">CSS is designed primarily to enable the separation of document content from document presentation, including aspects such as the layout, colors, and fonts. This separation can improve content accessibility, provide more flexibility and control in the specification of presentation characteristics, enable multiple HTML pages to share formatting by specifying the relevant CSS in a separate .css file, and reduce complexity and repetition in the structural content.
                                                <br/><br/>
                                                Separation of formatting and content makes it possible to present the same markup page in different styles for different rendering methods, such as on-screen, in print, by voice (via speech-based browser or screen reader), and on Braille-based tactile devices. It can also display the web page differently depending on the screen size or viewing device. Readers can also specify a different style sheet, such as a CSS file stored on their own computer, to override the one the author specified.
                                            </span>
                                        </div>
                                        <div className="btn-container" align="center">
                                            <button id="toggle1">Read More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                        <section className="sec2 sec2A">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 space1">
                                        <h2 className="txt-title-3">Remind to Recover Payments</h2>
                                        <span className="line"></span>
                                            <p className="txt-style1">vyavsay software helps small businesses maintain cash flow by assisting them in keeping track of payments. Using the free GST Billing app, y..</p>
                                        <div>
                                            <span id="text2">CSS is designed primarily to enable the separation of document content from document presentation, including aspects such as the layout, colors, and fonts. This separation can improve content accessibility, provide more flexibility and control in the specification of presentation characteristics, enable multiple HTML pages to share formatting by specifying the relevant CSS in a separate .css file, and reduce complexity and repetition in the structural content.
                                                <br/><br/>
                                                Separation of formatting and content makes it possible to present the same markup page in different styles for different rendering methods, such as on-screen, in print, by voice (via speech-based browser or screen reader), and on Braille-based tactile devices. It can also display the web page differently depending on the screen size or viewing device. Readers can also specify a different style sheet, such as a CSS file stored on their own computer, to override the one the author specified.
                                            </span>
                                        </div>
                                        <div className="btn-container" align="center">
                                            <button id="toggle2">Read More</button>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <img src={global.webUrl+"websiteFilesFolder/img/sec3.png"} alt="" title="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                        <section className="sec4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="txt-title-3">GST filing made simpler and faster</h2>
                                        <span className="line"></span>
                                        <p className="txt-style1">
                                            GST filing is no longer a long process for vyavsay software users. If GST applies to your business, you can quickly generate all GST reports...
                                        </p>
                                        <div>
                                            <span id="text3">CSS is designed primarily to enable the separation of document content from document presentation, including aspects such as the layout, colors, and fonts. This separation can improve content accessibility, provide more flexibility and control in the specification of presentation characteristics, enable multiple HTML pages to share formatting by specifying the relevant CSS in a separate .css file, and reduce complexity and repetition in the structural content.
                                            <br/><br/>
                                            Separation of formatting and content makes it possible to present the same markup page in different styles for different rendering methods, such as on-screen, in print, by voice (via speech-based browser or screen reader), and on Braille-based tactile devices. It can also display the web page differently depending on the screen size or viewing device. Readers can also specify a different style sheet, such as a CSS file stored on their own computer, to override the one the author specified.
                                            </span>
                                        </div>
                                        <div className="btn-container" align="center">
                                            <button id="toggle3">Read More</button>
                                        </div>
                                        <br/><br/>
                                        <div align="center">
                                            <img src={global.webUrl+"websiteFilesFolder/img/sec4.png"} alt="" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                        <section className="sec5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="txt-title-3 tt3">vyavsay Features</h2>
                                        <span className="line l2"></span>
                                    </div>
                                </div>
                                    
                                <div className="row rowE">
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-file-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Send Estimate & Quotations</h4>
                                            <p>
                                                Using this free billing software, you can easily create quotations, estimates as well as accurate GST invoice.With the use of vyavsay billing software with GST, it is quite easy to create instant estimates along with quotes.
                                            </p>
                                            <div>
                                                <span id="text4">Now you have a better option for simply sharing the detailed costs along with the taxes for customers. Within a few clicks, you can convert your estimates into bills from vyavsay software for billing.
                                                <br/><br/>
                                                vyavsay free billing Software offers the business a complete option for easily saving more time in getting instant quotes. We are sure you would like to manage your business with higher productivity and in a professional way for your valued customers to come back again. For that, choosing an advanced Software for GST billing would be one of the significant things you can do. Now you can easily use the GST software for billing and accounting! The GST Billing Software helps in sending your Quotations online (WhatsApp, email or SMS)
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle4">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-weight"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Track Orders</h4>
                                            <p>
                                                With using this advanced GST Software for Billing, it is easier for creating as well as tracking the sales or purchase orders. With the use of this GST Accounting and Billing Software,
                                            </p>
                                            <div>
                                                <span id="text5">it is quite simple to extensively generate the bills within a few minutes. This is quite helpful for fulfilling the orders quickly and ultimately increasing customer satisfaction to a great extent.
                                                    <br/><br/>
                                                    vyavsay free Billing Software gives you multiple options on your sales order. It is also easier to improve your purchase/sale order formats using this App as vyavsay App provides multiple formats. When you want to easily save your time on your business transaction and add data to your accounts, then using this GST invoicing software is one of the best choices. It definitely improves your business reach to the next level while making the business account instantly. GST Invoicing Software is a perfect option for gaining more features and advantages.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle5">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="far fa-list-alt"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Choose Themes</h4>
                                            <p>
                                                Maintaining and sharing professional Invoice to your clients would definitely improve your Brand's Identity. In the GST Billing App, it is easier to
                                            </p>
                                            <div>
                                                <span id="text6">choose the best multiple formats that also include tally invoice themes and many others. With the use of this GST invoice software, you can easily improve the look of your invoice and have it neatly prepared for your client. Business bills generated beautifully are also considered as the better choice for easily impressing your clients. The Billing App for GST is the best option for your accounting, inventory and is very easy to handle. Choose from the best GST invoice formats along with the extensive preferred invoice format.
                                                <br/><br/>
                                                Most businesses choose this free billing software for easily improving their business scale professionally. With the use of this free billing App, it is quite an efficient option for easily gaining the highest standard to the maximum extent possible. GST Invoicing Software is a suitable option for all the commercials retails, pharmaceutical businesses, gyms, restaurants, and many other businesses. Since there is a huge demand for the best reliable GST solutions among the business owners, this free GST Invoicing App is considered as the most efficient option. The GST system has been introduced for simplifying indirect taxation. It also mainly ensures the ease of doing business with much better transparency. GST Invoicing Software is one of the highly advanced robust IT infrastructures suitable for your business to aim at achieving better compliances easily.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle6">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-wallet"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Record Expences</h4>
                                            <p>
                                                Authorized GST Billing software is mainly suitable for your business to run smoothly. It would also give you a better option for extensively tracking the billing as well as account needs.  
                                            </p>
                                            <div>
                                                <span id="text7">The software allows the business to easily keep track of their business in a more hassle-free way. GST Invoicing Software mainly saves more time even during the tax filing.
                                                <br/><br/>
                                                Tracking or recording the expenses in the business is most important for accounting and tax filing. With the use of the GST software, it is quite easier to track the money that is spent as well as creates an accurate report. It is quite an efficient option for recording expenses, and businesses could easily optimize the business expenditure by saving more money in the process. It is a mainly suitable option for growing the business with free GST software for billing.
                                                <br/><br/>
                                                The free GST billing software is quite an efficient option for easily getting the accurate GST bill format. It is also helpful for claiming the complete input tax credits to the extent. By thoroughly recording the expenses using GST software, the business could easily optimize the expenditure of business and save more money in the process. Moreover, it would also be helpful for saving time on the tax filing. This free billing software would definitely give you an adequate option for saving all the data accurately.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle7">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-sync"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Recievables & Payble</h4>
                                            <p>
                                                Advanced free billing and accounting software lets the user easily keep every detail and completed cashbook. Now you have the better way of keeping your transaction details safer. 
                                            </p>
                                            <div>
                                                <span id="text8">Free GST Invoicing Software allows easily keeping track of the complete finance and also keeping the focus on the inventory along the billing section.
                                                <br/><br/>
                                                Can track the money you ‘have to receive’ and the money you ‘need to pay’ using vyavsay app. Find who hasn’t paid you yet. Set payment reminders to collect payment from customers on time. The GST Billing and invoicing software is a suitable option for the business to perform the most automated calculations. Now you have the better option to simply install the GST billing software on your mobile for free.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle8">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="icon">
                                            <span><i className="fas fa-truck-moving"></i></span>
                                        </div>
                                        <div className="content">
                                            <h4>Delivery Challan</h4>
                                            <p>
                                                Get acknowledgement upon delivery with “Delivery Challan” of vyavsay. Create delivery challans and attach them with your consignment using this free GST invoicing App.
                                            </p>
                                            <div>
                                                <span id="text9">Easily ensures your goods have reached customers safely.
                                                <br/><br/>
                                                With the use of this ultimate free GST Invoicing Software, it is quite an efficient option for saving more time as you get instant acknowledgement. This process makes the business run smoothly and is helpful for the customers to get the consignment safely. Converting the delivery challans into bills is also quite an efficient option. This would definitely give the complete way of saving more time.
                                                <br/><br/>
                                                Maintain all the records of delivery acknowledgment with the GST billing & Accounting Software. It is an easier way of running the business with major productivity goals. When you like to convert your delivery challans, then using the free Billing App would be one of the best choices.
                                                </span>
                                            </div>
                                            <div className="btn-container">
                                                <button id="toggle9">Read More</button>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                <div className="" align="center">
                                    <a href={global.webUrl+"features/"} id="togglef">View More Features</a>
                                </div>
                            </div>
                        </section>
                    
                        <section className="sec6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h6 className="txt-style-4">Our Testimonials</h6>
                                        <h2 className="txt-style-5">What People Are Saying About Our Business.</h2>
                                        <p className="txt-style2A">
                                            Using our single innovative platform you can remove all your communication dependencies and the messy rat's nest of email, calls, texts, wikis, and apps you currently have.
                                        </p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="carousel slide" id="main-carousel" data-ride="carousel">
                                            <ol className="carousel-indicators">
                                                <li data-target="#main-carousel" data-slide-to="0" className="active"></li>
                                                <li data-target="#main-carousel" data-slide-to="1"></li>
                                                <li data-target="#main-carousel" data-slide-to="2"></li>
                                                <li data-target="#main-carousel" data-slide-to="3"></li>
                                            </ol>
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="testimonial">
                                                        <h6>I've been patiently waiting for this to arrive after using vyavsaay a while on my project. Keep up the good work!  This is fantastic work as usual byGood Themes. Keep up the good work! </h6>
                                                        <ul>
                                                            <li>
                                                                <img src={global.webUrl+"websiteFilesFolder/img/user.png"} alt="" title="" />
                                                                <b>Jesika Fennouni</b>
                                                            </li>
                                                            <li>
                                                            <i className="fas fa-quote-right"></i>
                                                            </li>
                                                        </ul>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="testimonial">
                                                        <h6>I've been patiently waiting for this to arrive after using vyavsaay a while on my project. Keep up the good work!  This is fantastic work as usual byGood Themes. Keep up the good work! </h6>
                                                        <ul>
                                                            <li>
                                                                <img src={global.webUrl+"websiteFilesFolder/img/user.png"} alt="" title="" />
                                                                <b>Jesika Fennouni</b>
                                                            </li>
                                                            <li>
                                                            <i className="fas fa-quote-right"></i>
                                                            </li>
                                                            </ul><div className="clearfix"></div>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="testimonial">
                                                        <h6>I've been patiently waiting for this to arrive after using vyavsaay a while on my project. Keep up the good work!  This is fantastic work as usual byGood Themes. Keep up the good work! </h6>
                                                        <ul>
                                                            <li>
                                                                <img src={global.webUrl+"websiteFilesFolder/img/user.png"} alt="" title="" />
                                                                <b>Jesika Fennouni</b>
                                                            </li>
                                                            <li>
                                                            <i className="fas fa-quote-right"></i>
                                                            </li>
                                                        </ul><div className="clearfix"></div>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="testimonial">
                                                        <h6>I've been patiently waiting for this to arrive after using vyavsaay a while on my project. Keep up the good work!  This is fantastic work as usual byGood Themes. Keep up the good work! </h6>
                                                        <ul>
                                                            <li>
                                                                <img src={global.webUrl+"websiteFilesFolder/img/user.png"} alt="" title="" />
                                                                <b>Jesika Fennouni</b>
                                                            </li>
                                                            <li>
                                                            <i className="fas fa-quote-right"></i>
                                                            </li>
                                                        </ul>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="sec4 sec4A">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="txt-title-3">vyavsay Latest News</h2>
                                        <span className="line"></span>
                                    </div>
                                </div>
                                <div className="row space1">
                                    {this.viewWebsiteBlogListing()}    
                                </div>
                                <a href={global.webUrl+"blogs/"} className="k2o">View More Blogs</a>
                            </div>
                        </section>
                    
                        <section className="faq_contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2 className="txt-style-5">Frequently Asked Questions</h2>
                                        <span className="line line1"></span>
                                        <div id="accordion" className="accordion">
                                            <div className="card mb-0">
                                                <div className="card-header" data-toggle="collapse" href="#collapseOne">
                                                    <a className="card-title">
                                                        What is billing and invoicing software?
                                                    </a>
                                                </div>
                                                <div id="collapseOne" className="card-body collapse show" data-parent="#accordion">
                                                    <p>Professional billing and invoicing software are used to create personalized invoices for businesses. These invoices help reflect the brand’s identity and cater to the brand’s message to clients. Using vyavsay as professional billing app, you can stand out among competitors with appealing invoice design. Further, you can track down paid and unpaid invoices and send payment reminders to the customers directly from the app.</p>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                                    <a className="card-title">Which is the best billing software for small businesses in India?</a>
                                                </div>
                                                <div id="collapseTwo" className="card-body collapse" data-parent="#accordion">
                                                    <p>vyavsay billing software is the best tool for small business owners who need help managing their finances and accounts. Using our billing tool, you can save a lot of time and efforts in creating an invoice for every customer.</p>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                                    <a className="card-title">What is the difference between invoices and bills?</a>
                                                </div>
                                                <div id="collapseThree" className="collapse" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p>Although invoices and bills both outline how much money a customer owes to a business, they differ in some ways. An invoice must contain an invoice number, which is not mandatory in caseof a bill. The invoice includes details about everything included in a product or service, while a bill can have little information to get an overview of a transaction.</p>
                                                    </div>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree1">
                                                    <a className="card-title">What are the types of billing?</a>
                                                </div>
                                                <div id="collapseThree1" className="collapse" data-parent="#accordion">
                                                    <div className="card-body"><p>Billing, in general, is categorized as:<br/>
                                                        1. Order-based billing: It is used for bills with a fixed scope of work or a fixed number of items to be delivered.<br/>
                                                        2. Delivery-based billing:It is used for bills that include an estimate of the cost involved as per the customer’s requirements.</p>
                                                    </div>
                                                </div>
                                                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree2">
                                                    <a className="card-title">List some top features of billing software?</a>
                                                </div>
                                                <div id="collapseThree2" className="collapse" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p>
                                                        Here are some essential features of billing software.<br/>
                                                        1. Creating quotations and estimates<br/>
                                                        2. Managing inventory and stock<br/>
                                                        3. Converting quotes to invoices<br/>
                                                        4. Setting alerts and reminders<br/>
                                                        5. Compatibility with mobile devices
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="get_in_touch">
                                            <h2>Get In Touch</h2>
                                            <span></span>
                                            <form>
                                                <div>
                                                    <input className={errors.name.length > 0 && 'errorClass'} value={this.state.name} onChange={this.handleChange} type="text" placeholder="Name" name="name" required style={{color: 'white'}}/>
                                                </div>
                                                <div>
                                                    <input className={errors.email.length > 0 && 'errorClass'} value={this.state.email} onChange={this.handleChange} type="email" placeholder="Email" id='email' name="email" required style={{color: 'white'}}/>
                                                </div>
                                                <div>
                                                    <input className={errors.contact.length > 0 && 'errorClass'} value={this.state.contact} onChange={this.handleChange} type="text" placeholder="Phone No." name="contact" required style={{color: 'white'}}/>
                                                </div>
                                                <div>
                                                    <textarea className={errors.message.length > 0 && 'errorClass'} value={this.state.message} onChange={this.handleChange} placeholder="Message" name="message" required style={{color: 'white'}}></textarea>
                                                </div>
                                                <div>
                                                    <button type="button" disabled={isDisabled} onClick={this.clickToSubmitEnqury}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </div>
                {this.state.visibleStartBtn && (
                    <Dialog title={"Success"} onClose={this.toggleDialogStartBtn}
                    width={500}
                    height={250}>
                    <p
                        style={{
                        margin: "25px",
                        textAlign: "center",
                        }}
                    >
                        Thank You For Submitting Your Mobile No. With Vyavsay. You can download the app from the link given below - <a href="https://www.vyavsay.in/apk/vyavsay.apk">https://www.vyavsay.in/apk/vyavsay.apk</a>
                    </p>
                    <DialogActionsBar>
                        <Button  type="button" onClick={this.toggleDialogStartBtn}>
                        Close
                        </Button>
                    </DialogActionsBar>
                    </Dialog>
                )}
            </React.Fragment> 
        )
    }
}