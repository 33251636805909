import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];
let _businessSetting = {};

let _BatchNoChk = 0;
let _BatchNoTxt = "";
let _BatchNoAdditionalChk1 = 0;
let _BatchNoAdditionalChk2 = 0;
let _BatchNoAdditionalLabel1 = "";
let _BatchNoAdditionalLabel2 = "";
let _ExpDateChk = "";

let _loader = "glb-ldr-prt active";

export default class QuickViewBatchList extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTaxDetails = _companySession.taxDetails;
      if (_UserSession!=null && _UserSession!="") {
        if (_UserSession.loginType!="Admin") {
          if (_UserSession!=undefined || _UserSession!=null) {
            _businessSetting = _UserSession.businessSetting;
            _BatchNoAdditionalChk1 = _businessSetting.BatchNoAdditionalChk1;
            _BatchNoAdditionalChk2 = _businessSetting.BatchNoAdditionalChk2;
            _BatchNoAdditionalLabel1 = _businessSetting.BatchNoAdditionalLabel1;
            _BatchNoAdditionalLabel2 = _businessSetting.BatchNoAdditionalLabel2;
            _BatchNoChk = _businessSetting.BatchNoChk;
            _BatchNoTxt = _businessSetting.BatchNoTxt;
            _ExpDateChk = _businessSetting.ExpDateChk;
          }
        }
      }
    }
    this.state = {
      itemBatchAllData: [],
      itemId: this.props.itemId ? this.props.itemId : "0",
      isLoaded: true,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      BatchNoAdditionalLabel1: _BatchNoAdditionalLabel1,
      BatchNoAdditionalLabel2: _BatchNoAdditionalLabel2,
      BatchNoAdditionalChk1: _BatchNoAdditionalChk1,
      BatchNoAdditionalChk2: _BatchNoAdditionalChk2,
      BatchNoChk: _BatchNoChk,
      BatchNoTxt: _BatchNoTxt,
      ExpDateChk: _ExpDateChk,
      isError: false,
      errors: {},
    };
  }
  async componentDidMount() {
    this.getItemSerialListData(this.state.itemId);
  }
  getItemSerialListData(itemId) {
    let _batchArray = [];
    const requestData = JSON.stringify({
      itemId: itemId,
    });

    PostData(global.userItemBatchList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ itemBatchAllData: _batchArray, isLoaded: true });
        } else {
          if (responseJson.response) {
            _batchArray = responseJson.response;
          }
          this.setState({ itemBatchAllData: _batchArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  
  removeFormFields(itemId, batch_no, batch_id) {

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: itemId,
              batch_no: batch_no,
              batch_id: batch_id,
            });
            PostData(global.userItemSingleBatchDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification("Error Message 😓", responseJson.message, "danger");
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getItemSerialListData(this.state.itemId);
        
                  this.props.callBack(itemId);
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    return (
      <React.Fragment>
        <form method="post" id="popupRemoveId" className="webForm px-30">
          <div className="form-group row"></div>
          {this.state.itemBatchAllData.map((element, index) => (
            <div className="form-group">
              <div className="row">
                {_businessSetting.BatchNoChk == 1 && (
                  <div className="col-3">
                    <div className="form-material open">
                      <p>{element.batch_no || ""}</p>
                      <label htmlFor="login-username">
                        {_businessSetting.BatchNoTxt}{" "}
                      </label>
                    </div>
                  </div>
                )}
                {_businessSetting.MfgDateChk == 1 && (
                  <div className="col-3">
                    <div className="form-material open">
                      <p>{element.mfg_date || ""}</p>
                      <label htmlFor="login-username">
                        {_businessSetting.MfgDateTxt}{" "}
                      </label>
                    </div>
                  </div>
                )}
                {_businessSetting.ExpDateChk == 1 && (
                  <div className="col-3">
                    <div className="form-material open">
                      <p>{element.exp_date || ""}</p>
                      <label htmlFor="login-username">
                        {_businessSetting.ExpDateTxt}{" "}
                      </label>
                    </div>
                  </div>
                )}
                {_businessSetting.ModelNoChk == 1 && (
                  <div className="col-3">
                    <div className="form-material open">
                      <p>{element.model_no || ""}</p>
                      <label htmlFor="login-username">
                        {_businessSetting.ModelNoTxt}{" "}
                      </label>
                    </div>
                  </div>
                )}
                {_businessSetting.SizeChk == 1 && (
                  <div className="col-3">
                    <div className="form-material open">
                      <p>{element.size || ""}</p>
                      <label htmlFor="login-username">
                        {_businessSetting.SizeTxt}{" "}
                      </label>
                    </div>
                  </div>
                )}
                {_businessSetting.BatchNoAdditionalChk1 == 1 && (
                  <div className="col-2">
                    <div className="form-material open">
                      <p>{element.BatchNoAdditionalValue1 || ""} </p>
                      <label htmlFor="login-username">
                        {_businessSetting.BatchNoAdditionalLabel1}{" "}
                      </label>
                    </div>
                  </div>
                )}
                {_businessSetting.BatchNoAdditionalChk2 == 1 && (
                  <div className="col-2">
                    <div className="form-material open">
                      <p>{element.BatchNoAdditionalValue2 || ""} </p>
                      <label htmlFor="login-username">
                        {_businessSetting.BatchNoAdditionalLabel2}{" "}
                      </label>
                    </div>
                  </div>
                )}
                <div className="col-2">
                  <div className="form-material open">
                    <p>{element.opening_stock} </p>
                    <label htmlFor="login-username">Opening Stock </label>
                  </div>
                </div>
                <div className="col-2">
                  <a
                    className="badge badge-danger pull-right badge-danger"
                    onClick={this.removeFormFields.bind(
                      this,
                      this.state.itemId,
                      element.batch_no,
                      element.id
                    )}
                  >
                    <i className="fa fa-solid fa-trash"></i>
                    Delete Batch
                  </a>
                </div>
              </div>
              <hr />
            </div>
          ))}

          {this.state.itemBatchAllData.length == 0 && (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Note : You have no batches in stock
              {/* Note : You have used some quantity of the un-used batch, so you can no longer delete that batch. For that you have to delete transaction related to that batch. */}
            </p>
          )}
        </form>
      </React.Fragment>
    );
  }
}
