import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import QuickAddService from "./quickServiceAdd";

let _loader = "glb-ldr-prt active";

export default class ItemListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {}

  render() {
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _itemId = _handleParam[1] ? _handleParam[1] : 0;

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Service
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/manage-service/"}
            >
              View Service List
            </a>
            <span className="breadcrumb-item active">Add / Edit Service</span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Add / Edit Service </h3>
            </div>
            <div className="block-content">
              <QuickAddService isMainPage={true} itemId={_itemId} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
