// Party Apis
global.userAddItem = global.API_USER_URL+'userAddItem/';
global.userItemList = global.API_USER_URL+'userItemList/';
global.userItemDetails = global.API_USER_URL+'userItemDetails/';
global.userEditItem = global.API_USER_URL+'userEditItem/';
global.userItemStatus = global.API_USER_URL+'userItemStatus/';
global.userGstTaxRate = global.API_USER_URL+'userGstTaxRate/';
global.userImportItemSheet = global.API_USER_URL+'userImportItemSheet/';
global.userEditItemInfo = global.API_USER_URL+'userEditItemInfo/';
global.userItemDelete = global.API_USER_URL+'userItemDelete/';
global.userItemImageDelete = global.API_USER_URL+'userItemImageDelete/';

global.userItemTransactionList = global.API_USER_URL+'userItemTransactionList/';
global.userItemSalePurList = global.API_USER_URL+'userItemSalePurList/';
global.userItemSalePurOrEstList = global.API_USER_URL+'userItemSalePurOrEstList/';
global.userItemSalePurPartyList = global.API_USER_URL+'userItemSalePurPartyList/';


global.userItemMultipleInActive = global.API_USER_URL+'userItemMultipleInActive/';
global.userItemMultipleActive = global.API_USER_URL+'userItemMultipleActive/';


global.userItemActiveList = global.API_USER_URL+'userItemActiveList/';
global.userItemInActiveList = global.API_USER_URL+'userItemInActiveList/';
global.userItemNoUnitSelect = global.API_USER_URL+'userItemNoUnitSelect/';

global.userItemAddStockAdjustment = global.API_USER_URL+'userItemAddStockAdjustment/';
global.userItemEditStockAdjustment = global.API_USER_URL+'userItemEditStockAdjustment/';
global.userItemStockAdjustmentDetail = global.API_USER_URL+'userItemStockAdjustmentDetail/'; 
global.deleteItemAdjustentData = global.API_USER_URL+'deleteItemAdjustentData/';
global.AddshareItemDetails = global.API_USER_URL+'AddshareItemDetails/';
global.AddshareServiceDetails = global.API_USER_URL+'AddshareServiceDetails/';

global.itemCategoryList = global.API_USER_URL+'itemCategoryList/';
global.userAddItemCategory = global.API_USER_URL+'userAddItemCategory/';
global.userCategoryItemList = global.API_USER_URL+'userCategoryItemList/';
global.userEditItemCategory = global.API_USER_URL+'userEditItemCategory/';
global.userItemSerialDelete = global.API_USER_URL+'userItemSerialDelete/';
global.deleteAllSerialInItem = global.API_USER_URL+'deleteAllSerialInItem/';
global.userItemSerialList = global.API_USER_URL+'userItemSerialList/';
global.deleteAllBatchInItem = global.API_USER_URL+'deleteAllBatchInItem/';
global.userItemBatchList = global.API_USER_URL+'userItemBatchList/';
global.userItemSingleBatchDelete = global.API_USER_URL+'userItemSingleBatchDelete/';
global.multipleBulkItemUpdate = global.API_USER_URL+'multipleBulkItemUpdate/';
global.userTransationItemDelete = global.API_USER_URL+'userTransationItemDelete/';

