import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        
        
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,         
            modalIsOpen_Info : false,     
            modalIsOpen_TermsCond : false,
            _infoData : '',
            TermCondSaleInvMsg : (_businessSetting.TermCondSaleInvMsg) ? _businessSetting.TermCondSaleInvMsg : 'Thank you for doing business with us.',
            TermCondSaleInvMsgPrint : (_businessSetting.TermCondSaleInvMsgPrint > 0) ? true : false,
            
            TermCondSaleOrderMsg : (_businessSetting.TermCondSaleOrderMsg) ? _businessSetting.TermCondSaleOrderMsg : 'Thank you for doing business with us.',
            TermCondSaleOrderMsgPrint : (_businessSetting.TermCondSaleOrderMsgPrint > 0) ? true : false,
                        
            TermCondDelvChallanMsg : (_businessSetting.TermCondDelvChallanMsg) ? _businessSetting.TermCondDelvChallanMsg : 'Thank you for doing business with us.',
            TermCondDelvChallanMsgPrint : (_businessSetting.TermCondDelvChallanMsgPrint > 0) ? true : false,
            
            TermCondEstimateMsg : (_businessSetting.TermCondEstimateMsg) ? _businessSetting.TermCondEstimateMsg : 'Thank you for doing business with us.',
             TermCondEstimateMsgPrint : (_businessSetting.TermCondEstimateMsgPrint > 0) ? true : false,
            
            TermCondPruchaseMsg : (_businessSetting.TermCondPruchaseMsg) ? _businessSetting.TermCondPruchaseMsg : 'Thank you for doing business with us.',
            TermCondPruchaseMsgPrint : (_businessSetting.TermCondPruchaseMsgPrint > 0) ? true : false,
            
            TermCondPruchaseOrderMsg : (_businessSetting.TermCondPruchaseOrderMsg) ? _businessSetting.TermCondPruchaseOrderMsg : 'Thank you for doing business with us.',
            TermCondPruchaseOrderMsgPrint : (_businessSetting.TermCondPruchaseOrderMsgPrint > 0) ? true : false,
        }
    }

    componentDidMount()
    {  

    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        
        if(_key == 'TermCondSaleInvMsgPrint'){
            this.setState({TermCondSaleInvMsgPrint:_value}); 
        } 
        if(_key == 'TermCondSaleOrderMsgPrint'){
            this.setState({TermCondSaleOrderMsgPrint:_value}); 
        } 
        if(_key == 'TermCondDelvChallanMsgPrint'){
            this.setState({TermCondDelvChallanMsgPrint:_value}); 
        } 
        if(_key == 'TermCondEstimateMsgPrint'){
            this.setState({TermCondEstimateMsgPrint:_value}); 
        } 
        if(_key == 'TermCondPruchaseMsgPrint'){
            this.setState({TermCondPruchaseMsgPrint:_value}); 
        } 
        if(_key == 'TermCondPruchaseOrderMsgPrint'){
            this.setState({TermCondPruchaseOrderMsgPrint:_value}); 
        }

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'TermCondSaleInvMsg'){
            this.setState({TermCondSaleInvMsg:activeValue}); 
        } 
        if(_keyVal == 'TermCondSaleOrderMsg'){
            this.setState({TermCondSaleOrderMsg:activeValue}); 
        } 
        if(_keyVal == 'TermCondDelvChallanMsg'){
            this.setState({TermCondDelvChallanMsg:activeValue}); 
        } 
        if(_keyVal == 'TermCondEstimateMsg'){
            this.setState({TermCondEstimateMsg:activeValue}); 
        } 
        if(_keyVal == 'TermCondPruchaseMsg'){
            this.setState({TermCondPruchaseMsg:activeValue}); 
        } 
        if(_keyVal == 'TermCondPruchaseOrderMsg'){
            this.setState({TermCondPruchaseOrderMsg:activeValue}); 
        }        
        //console.log(_keyVal+'=='+activeValue+'===STATE=='+this.state.PartyAddField1Name+'==END==');
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }   
    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        
        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-12">
                        <h5 className="content-heading"></h5>
                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Sale Invoice</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Sale Invoice" data-slug="TermCondSaleInvMsg" id="TermCondSaleInvMsg" name="TermCondSaleInvMsg" value ={this.state.TermCondSaleInvMsg} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TermCondSaleInvMsgPrint > 0) ? true : false} id="TermCondSaleInvMsgPrint" name="TermCondSaleInvMsgPrint" value="1" data-slug="TermCondSaleInvMsgPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Sale Order</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Sale Order" data-slug="TermCondSaleOrderMsg" id="TermCondSaleOrderMsg" name="TermCondSaleOrderMsg" value ={this.state.TermCondSaleOrderMsg} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TermCondSaleOrderMsgPrint > 0) ? true : false} id="TermCondSaleOrderMsgPrint" name="TermCondSaleOrderMsgPrint" value="1" data-slug="TermCondSaleOrderMsgPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>  
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Delivery Challan</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Delivery Challan" data-slug="TermCondDelvChallanMsg" id="TermCondDelvChallanMsg" name="TermCondDelvChallanMsg" value ={this.state.TermCondDelvChallanMsg} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TermCondDelvChallanMsgPrint > 0) ? true : false} id="TermCondDelvChallanMsgPrint" name="TermCondDelvChallanMsgPrint" value="1" data-slug="TermCondDelvChallanMsgPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Estimate / Quotation</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Estimate / Quotation" data-slug="TermCondEstimateMsg" id="TermCondEstimateMsg" name="TermCondEstimateMsg" value ={this.state.TermCondEstimateMsg} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TermCondEstimateMsgPrint > 0) ? true : false} id="TermCondEstimateMsgPrint" name="TermCondEstimateMsgPrint" value="1" data-slug="TermCondEstimateMsgPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Purchase</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Purchase" data-slug="TermCondPruchaseMsg" id="TermCondPruchaseMsg" name="TermCondPruchaseMsg" value ={this.state.TermCondPruchaseMsg} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TermCondPruchaseMsgPrint > 0) ? true : false} id="TermCondPruchaseMsgPrint" name="TermCondPruchaseMsgPrint" value="1" data-slug="TermCondPruchaseMsgPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'18%'}}>Purchase Order</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Purchase Order" data-slug="TermCondPruchaseOrderMsg" id="TermCondPruchaseOrderMsg" name="TermCondPruchaseOrderMsg" value ={this.state.TermCondPruchaseOrderMsg} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Show In Print </span>                                
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.TermCondPruchaseOrderMsgPrint > 0) ? true : false} id="TermCondPruchaseOrderMsgPrint" name="TermCondPruchaseOrderMsgPrint" value="1" data-slug="TermCondPruchaseOrderMsgPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                            
                                                   

                        </ul>

                        

                        
                    </div>
                    
                </div>

            

            </React.Fragment>
        )
    }
} 