import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import { numberFormat } from "../../../config/numberFormat";
import Modal from "react-modal";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];

_bankArray.push({ bank_id: "0", bank_name: "", paymentType: "Cash" });

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class chequeDepositPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    this.state = {
      trasactionId: this.props.isTransaction,
      oId: this.props.isTransactionOId,
      transactionType:
        this.props.isTransactionType == "Deposit" ? "Withdraw" : "Deposit",
      type:
        this.props.isTransactionType == "Deposit"
          ? "DEPOSITCHEQUE"
          : "WITHDRAWCHEQUE",
      mainTransactionType: "Sale",
      description: "",
      errors: {
        description: "",
      },
    };
    _isEditMode = this.props.isTransaction > 0 ? true : false;
  }

  async componentDidMount() {
    let _loanTrasacId = this.props.isTransaction;

    let _OType = this.props.isTransactionOType;
    if (_OType == "OTHER INCOME" || _OType == "Other Income") {
      _loanTrasacId = this.props.isTransactionOId;
    }

    if (this.props.isTransaction > 0) {
      const requestData = JSON.stringify({
        transaction_id: _loanTrasacId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let customerData = _transactionArray.customer;
              let p_name = customerData.expense_category;
              if (
                customerData.party_name!="" &&
                customerData.party_name!=null
              ) {
                if (
                  customerData.expense_category!="" &&
                  customerData.expense_category!=null
                ) {
                  p_name =
                    customerData.party_name +
                    " (" +
                    customerData.expense_category +
                    ")";
                } else {
                  p_name = customerData.party_name;
                }
              }
              this.setState({
                party_id: customerData.party_id,
                party_name: p_name,
                //businessId:_transactionArray.business_id,
                //companyId:_transactionArray.company_id,
                receivedAmount:
                  parseFloat(transAmout.receivedAmount) +
                  parseFloat(transAmout.paidAmount),
                paymentRefNo:
                  _transactionArray.paymentRefNo!=""
                    ? _transactionArray.paymentRefNo
                    : "NA",
                mainTransactionType: _transactionArray.type,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "description":
        errors.description = value.length < 3 ? "*Enter Reopen Reason" : "";
        this.setState({ description: value });
        break;
      default:
        break;
    }
  };

  clickToAddChequeReopen = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    let val = this.state.description;
    if (val.length < 3) {
      errors.description = "*Enter Reopen Reason";
      this.setState({ description: val });
      _isError = true;
    }

    if (_isError == false) {
      if (this.state.editChequeTrasactionId > 0) {
        this.editMakePaymentTrasacDMethodCall();
      } else {
        this.addReopenChequeMethodCall();
      }
    }
  };

  addReopenChequeMethodCall() {
    const requestData = JSON.stringify({
      trasactionId: this.state.trasactionId,
      oId: this.state.oId,
      mainTransactionType: this.state.mainTransactionType,
      reopenReason: this.state.description,
      type: "Deposit",
    });
    //console.log("===11 requestData===", requestData);
    //return; 
    PostData(global.userChequeTransactionDelete, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  handleCallBackDate = (start) => {
    this.setState({ pay_date: start.format("MMMM D, YYYY") });
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-12">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="description"
                  name="description"
                  value={this.state.description}
                  placeholder="Enter Reopen Reason"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">Reopen Reason</label>
                <span className="iris_error_txt">
                  {errors.description.length > 0 && (
                    <span className="error">{errors.description}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddChequeReopen}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
