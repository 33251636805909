import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _classValue = '';
let _loader = 'glb-ldr-prt active';

export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
            
            // let _enaItem = (_businessSetting.EnableItem) ? _businessSetting.EnableItem : '0';
            // if(_enaItem == 0){
            //     _classValue = '';//iti__hide';
            // }
            
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        let _classValue1 = 'iti__hide';
        let _classValue2 = 'iti__hide';
        let _classValueBatchNo1 = 'iti__hide';
        let _classValueBatchNo2 = 'iti__hide';

        if(_businessSetting.SerialNoAdditionalChk1 > 0){
            _classValue1 =  '';
        }
        if(_businessSetting.SerialNoAdditionalChk2 > 0){
            _classValue2 =   '';
        }
        if(_businessSetting.BatchNoAdditionalChk1 > 0){
            _classValueBatchNo1 =  '';
        }
        if(_businessSetting.BatchNoAdditionalChk2 > 0){
            _classValueBatchNo2 =  '';
        }
        this.state = {
            isLoaded : false,            
            modalIsOpen_Info : false,
            modalIsOpen_InfoUnCheck : false,
            _infoData : '',
            _popupDataUncheck : '',
            _infoData2 : '',
            _infoTitle2 : '',    
            modalIsOpen_Info2 : false,
            SerialNoTxt : (_businessSetting.SerialNoTxt)  ? _businessSetting.SerialNoTxt : 'Serial No',
            MRPTxt : (_businessSetting.MRPTxt) ? _businessSetting.MRPTxt : 'MRP',
            BatchNoTxt : (_businessSetting.BatchNoTxt) ? _businessSetting.BatchNoTxt : 'Batch No',
            ExpDateTxt : (_businessSetting.ExpDateTxt) ? _businessSetting.ExpDateTxt : 'Exp Date',
            MfgDateTxt : (_businessSetting.MfgDateTxt) ? _businessSetting.MfgDateTxt : 'Mfg Date',
            ModelNoTxt : (_businessSetting.ModelNoTxt) ? _businessSetting.ModelNoTxt : 'Model No',
            SizeTxt : (_businessSetting.SizeTxt) ? _businessSetting.SizeTxt : 'Size',
            isChallanActive : (_businessSetting.DeliveryChallan > 0) ? true : false,
            ItemType : (_businessSetting.ItemType) ? _businessSetting.ItemType : '2',
            _infoTitle : 'Information',
            trasactionCount : 0,
            purchaseCount: 0,
            classValue : _classValue,
            selectedOption: (_businessSetting.ItemType) ? _businessSetting.ItemType : '3',
            classValue1 :  _classValue1,
            classValue2 :  _classValue2,
            classValueBatchNo1 : _classValueBatchNo1,
            classValueBatchNo2 : _classValueBatchNo2,
            business_id : _businessId,
            company_id : _companyId,
            
        }
    }
    
    componentDidMount()
    {
        let _transArray = [];
        const requestData = JSON.stringify({
            business_id:_businessId,
            company_id: this.state.company_id
        });

        PostData(global.userBusinessTotalCountList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                let _totalTransCount = responseJson.countList[0].purchaseCount + responseJson.countList[0].saleCount;                
               
                this.setState({partyCount:responseJson.countList[0].partyCount,purchaseCount:responseJson.countList[0].purchaseCount,saleCount:responseJson.countList[0].saleCount,itemCount:responseJson.countList[0].itemCount,isLoaded:true,trasactionCount:_totalTransCount,ExpDateTxt : _businessSetting.ExpDateTxt,MfgDateTxt : _businessSetting.MfgDateTxt,ModelNoTxt :_businessSetting.ModelNoTxt,SizeTxt: _businessSetting.SizeTxt,BatchNoAdditionalLabel1:_businessSetting.BatchNoAdditionalLabel1,BatchNoAdditionalLabel2 : _businessSetting.BatchNoAdditionalLabel2,SerialNoAdditionalLabel1 : _businessSetting.SerialNoAdditionalLabel1, SerialNoAdditionalLabel2 :_businessSetting.SerialNoAdditionalLabel2});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    /* Checkbox Chnage */
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
            _classValue = '';
        }
        if(_value == 0){
            _classValue = '';//'iti__hide';
        }
        
        if(_key == 'ItemWiseTax'){
            if(_businessSetting.TransactionWiseTax == 1 && _value == 1){
                this.openModalInfoHandler2('openModalInfoHandler2','According to goverment there should not be tax on tax. You have enabled transaction wise tax, so you should not enable item level tax.','Warning'); 
            }
        } 
        if(_value == 0){
            if(_key == 'SerialNoAdditionalChk1'){
                this.setState({classValue1 : 'iti__hide'}); 
            } 
            else if(_key == 'SerialNoAdditionalChk2'){
                this.setState({classValue2 : 'iti__hide'}); 
            } 
            else if(_key == 'BatchNoAdditionalChk1'){
                this.setState({classValueBatchNo1 : 'iti__hide'}); 
            } 
            else if(_key == 'BatchNoAdditionalChk2'){
                this.setState({classValueBatchNo2 : 'iti__hide'}); 
            } 
        }
        else{
            if(_key == 'SerialNoAdditionalChk1'){
                this.setState({classValue1 : ''}); 
            } 
            else if(_key == 'SerialNoAdditionalChk2'){
                this.setState({classValue2 : ''}); 
            } 
            else if(_key == 'BatchNoAdditionalChk1'){
                this.setState({classValueBatchNo1 : ''}); 
            }
            else if(_key == 'BatchNoAdditionalChk2'){
                this.setState({classValueBatchNo2 : ''}); 
            }

        } 
        
        this.setState({classValue:_classValue});
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                if(_key == 'ShowLowStockDialog'){
                    if(_value == 1){
                        _businessSettingData['lowStockStatus'] = 0;
                    }
                } 

                if(_key == 'SerialNoChk' && _value == 0){
                    _businessSettingData['SerialNoAdditionalChk1'] = 0;
                    _businessSettingData['SerialNoAdditionalChk2'] = 0;
                } 
                if(_key == 'BatchNoChk' && _value == 0){
                    _businessSettingData['ExpDateChk'] = 0;
                    _businessSettingData['MfgDateChk'] = 0;
                    _businessSettingData['ModelNoChk'] = 0;
                    _businessSettingData['SizeChk'] = 0;
                    _businessSettingData['BatchNoAdditionalChk1'] = 0;
                    _businessSettingData['BatchNoAdditionalChk2'] = 0;
                } 
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");   
                if((_key == 'SerialNoChk' || _key == 'BatchNoChk') && _value == 0){
                    window.location.reload();
                }             
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /* End Checkbox Chnage */

    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'SerialNoTxt'){
            this.setState({SerialNoTxt:activeValue}); 
        }         
        else if(_keyVal == 'SerialNoAdditionalLabel1'){
            this.setState({SerialNoAdditionalLabel1:activeValue}); 
        }        
        else if(_keyVal == 'SerialNoAdditionalLabel2'){
            this.setState({SerialNoAdditionalLabel2:activeValue}); 
        }        
        else if(_keyVal == 'BatchNoTxt'){
            this.setState({BatchNoTxt:activeValue}); 
        }
        else if(_keyVal == 'MRPTxt'){
            this.setState({MRPTxt:activeValue}); 
        }
        else if(_keyVal == 'ExpDateTxt'){
            this.setState({ExpDateTxt:activeValue}); 
        }   
        else if(_keyVal == 'MfgDateTxt'){
            this.setState({MfgDateTxt:activeValue}); 
        }    
        else if(_keyVal == 'ModelNoTxt'){
            this.setState({ModelNoTxt:activeValue}); 
        }  
        else if(_keyVal == 'SizeTxt'){
            this.setState({SizeTxt:activeValue}); 
        }  
        else if(_keyVal == 'BatchNoAdditionalLabel1'){
            this.setState({BatchNoAdditionalLabel1:activeValue}); 
        }  
        else if(_keyVal == 'BatchNoAdditionalLabel2'){
            this.setState({BatchNoAdditionalLabel2:activeValue}); 
        } 
        
        //console.log(_keyVal+'=='+activeValue);
       
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                 
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/

    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    onFilterChangeOnPopup = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});
        
        let popupDataUncheck = '';
        let popupTitleUncheck = '';
        if(_key == 'ItemsUnit'){
            popupDataUncheck = "Item units already being used in transactions, so can't be disabled now. Please contact vyavsay tech support for help.";
            popupTitleUncheck = 'Items Unit';

            this.openModalInfoAfterUncheck('openModalInfoHandlerUncheck',popupDataUncheck,popupTitleUncheck); 
            document.getElementById("ItemsUnit").checked = true;
        }
        
    }

    allCompanyFirmList()
    {
        
        let dataList = [];
        if(_allCompanyFirm.length > 0)
        {
            let _activeCompany = 'Change Company';                    
            _allCompanyFirm.map((compDatas, i) =>
            {
                const row = compDatas;
                let _iconActive = '';
                if(row.isActive == true){
                    _activeCompany = row.company_name;
                    _iconActive = ' text-info';
                }
                dataList.push(
                    <a key={i} className={"dropdown-item"+_iconActive} href="javascript:void(0)" onClick={this.updateCompany.bind(this,row.company_id)}>
                        <i className="fa fa-check"></i> {row.company_name}
                    </a>
                )
            });
            return(
                <div className="dropdown d-inline-block" role="group">
                    <button type="button" className="btn btn-dual-secondary dropdown-toggle active" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {_activeCompany}
                    </button>
                    <div className="dropdown-menu min-width-200" aria-labelledby="page-header-user-dropdown">
                        {dataList}
                    </div>
                </div>
            );
        }
    }

    updateCompany(value)
    {
        const requestData = JSON.stringify({
            userId : _signingId,
            company_id : value
        });
        PostData(global.userUpdateCompanyPriority, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                //console.log(result);
            }
        });

        let _UserSession = localStorage.getItem('vs_UserSession');             
        _UserSession = JSON.parse(_UserSession);
        let _updateLocal = _UserSession;
        let _comData = _updateLocal.companies;
        _comData.map((compDatas, i) =>{
            _comData[i].isActive = false;
            if(value == _comData[i].company_id){
                _comData[i].isActive = true;
                localStorage.removeItem('vs_UserCompanySession');
                localStorage.setItem('vs_UserCompanySession', JSON.stringify(compDatas));
            }
        });
        _updateLocal.company_id = value;
        _updateLocal.companies = _comData;
        localStorage.removeItem('vs_UserSession');
        localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
        this.setState({reChangeComapny:true});
        window.location.reload();
    }
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }
    
    openModalInfoAfterUncheck(e,popupDataUncheck,popupTitleUncheck)
    {
        if(e == 'openModalInfoHandlerUncheck'){
            this.setState({modalIsOpen_InfoUnCheck: true,_popupDataUncheck:popupDataUncheck,_popupTitleUncheck:popupTitleUncheck});            
        }
    }
    
    closeModalHandlerAfterUncheck = (e) => {
        if(e == 'openModalInfoHandlerUncheck'){
            this.setState({modalIsOpen_InfoUnCheck: false,_popupDataUncheck:'',_popupTitleUncheck:''});
        }
    }  
    
    openModalInfoHandler2(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler2'){
            this.setState({modalIsOpen_Info2: true,_infoData2:infoData,_infoTitle2:infoTitle})            
        }
    } 
    closeModalWarning = (e) => {
        if(e == 'openModalInfoHandler2'){
            this.setState({modalIsOpen_Info2: false, _infoData2:'', _infoTitle2:''});
        }
    } 
    
    updateWarningData = (e) => {
        let _keyyyy = 'ItemWiseTax';
        let _valueee = 0;
        
        const requestDataaa = JSON.stringify({
            settingValue: _valueee,
            settingKey: _keyyyy,
            businessId : _businessId,
            company_id : _companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestDataaa,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyyyy] = _valueee;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));    
                this.setState({modalIsOpen_Info2: false, _infoData2:'', _infoTitle2:''}); 
                
                document.getElementById('ItemWiseTax').checked = false;         
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _subMenuChallan = '';

        let _toolEnableItem = "What is this? <br/> Allows you to add Stock items in sale, purchase and expense transactions.";

        let _toolItemType = "What is this? <br/> Select the type of item you sell (product,service or both)";

        let _toolBarcodeScan = 'What is this? <br/> Enables you to scan item code/ serial numbers for your items at the time of entering transactions.<br/><br/>Why to use?<br/>If you want to use barcode scanning for searching and adding items to the transactions, you can turn this feature on and use it.';

        let _toolStockMaintenance = "What is this??  <br/> Vyavsay will maintain stock quantity and stock value on enabling this setting. You will be able to check Stock inventory reports and track the quantity of each item in your inventory.? <br/> <br/> Why to use??  <br/> It helps you to track how many units of that item you are currently having in your stock. Transactions like Sale or Purchase will adjust the stock quantities and inventory value automatically";

        let _toolShowLowStockDialog = "What is this?  <br/> This setting allows you to update the sale price of an item whenever it is updated on sale transactions. Upon creating a transaction the last used sale price will be shown. This setting will be enabled for all old users, while disabled by default for all new users. If the setting is not enabled then all sale related transactions will have no impact on the price of the item set";

        let _toolItemsUnit = "What is this?  <br/> Define units for Stock Items like KG, Litre, Pieces, Jnits, Bags, Bottles, etc.  <br/><br/> Note: You can also define 2 units for an item Primary and Secondary. E.g. if 1 Bag contains 10 Bottles then your primary unit is Bag and Secondary unit is Bottles.";

        let _toolDefaultUnit = "What is this? <br/> Vyavsay allows users to set a default primary and secondary unit that would be used for all new items. Users can change the unit, for items they don't want to use the default unit. Existing item units don't get affected by enabling this setting. <br/><br/> Why to use? <br/> If you deal in items that majorly have the same unit, enable this so that you don't have to add the item unit again.";

        let _toolItemCategory = 'What is this? <br/> You can make different categories and assign items to them. Your items will have different categories based on which you can easily track them. <br/><br/> Why to use? <br/> You can see reports by different category of items & check their performance.';

        let _toolPartyWiseItemRate = "What is this? <br/> Vyavsay can remember different sale and purchase prices of same item for different parties. This setting will show you last sale/purchase price of any item to selected party in transaction forms. <br/><br/> Why to use? <br/> If you want to sell items at different prices to different parties, you can turn this on. Vyavsay will show you the last Sale/Purchase price of item to the party selected in transaction forms.";

        let _toolDescription = "Enable you to add description item";

        let _toolItemWiseTax = "What is this? <br/> Enables you to apply tax separately for each item in . your sale and purchase transactions. <br/><br/> Why to use? <br/> In case you are charging different tax on each item, you can enable this.";

        let _toolItemWiseDiscount = "What is this?  <br/> Enables you to apply discount separately for each item in your sale and purchase transactions. <br/><br/> Why to use? <br/> In case you are giving discount on each item separately, then you can enable this.";

        let _toolUpdateSalePriceTrans = "What is this?  <br/> This setting allows you to update the sale price of an item whenever it is updated on sale transactions. Upon creating a transaction the last used sale price will be shown. This setting will be enabled for all old users, while disabled by default for all new users. If the setting is not enabled then all sale related transactions will have no impact on the price of the item set inside the item menu.";

        let _toolSerialNoChk = "Track each item unit by assigning a unique number to it (e.g. IMEI etc)";

        let _toolMRPChK = "Demo Data 1";

        let _toolBatchNoChk = "Demo 1111";

        let _toolExpDateChk = "Demo  3";

        let _toolMfgDateChk = "Demo Data 4";

        let _toolModelNoChk = "Demo Data 5";

        let _toolSizeChk = "Demo Data 6";
        
        let _toolBatchTracking = "Track items in set of Batches with specific Batch No., Mfg Date, expiry Date etc.";
        
        if(this.state.isChallanActive == true){
            _subMenuChallan = (
                <ul style={{marginLeft: '15px',marginTop: '15px'}}>
                    <li style={{listStyle: 'none',marginBottom: '10px'}}>
                        <input type="checkbox" defaultChecked={(_businessSetting.DeliveryChallanGood > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="DeliveryChallanGood" onChange={this.onFilterChange}/>
                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Goods Return on <b>Delivery Challan</b></span>
                    </li>
                    <li style={{listStyle: 'none',marginBottom: '10px'}}>
                        <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.DeliveryChallanPrint > 0) ? true : false} name="activeItem" value="1" data-slug="DeliveryChallanPrint" onChange={this.onFilterChange}/>
                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Print Amount in <b>Delivery Challan</b></span>
                    </li>
                </ul>
            );
        }
        const optionsData = [
            { value: '1', label: 'Product' },
            { value: '2', label: 'Service' },
            { value: '3', label: 'Products and Services' },
        ];
        return(
            <React.Fragment>
                
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                        <div className="col-md-6">
                            <h5 className="content-heading">Item Setting</h5>
                            <ul>
                                {/*this.state.trasactionCount == 0 &&
                                    <li style={{listStyle: 'none'}}>
                                        <input type="checkbox" defaultChecked={(_businessSetting.EnableItem > 0) ? true : false} id="EnableItem" name="EnableItem" value="1" data-slug="EnableItem" onChange={this.onFilterChange}/>
                                        <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Enable Item <i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title="" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolEnableItem,'Enable Item')}></i></span>
                                    </li>
                                /*}
                                {/*this.state.trasactionCount > 0 &&
                                <li style={{listStyle: 'none'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.EnableItem > 0) ? true : false} id="EnableItem" name="EnableItem" value="1" data-slug="EnableItem" onChange={this.onFilterChange} disabled/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Enable Item <i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title="" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolEnableItem,'Enable Item')}></i></span>
                                </li>
                                */}
                                <div id="hideShow" className={this.state.classValue}>
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}} >
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Item Type <i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title=""  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolItemType,'Item Type')}></i></span>      
                                    <select  className="form-control" id="ItemType" name="ItemType"  data-slug="ItemType" onChange={this.onFilterSelChange}
                                    >
                                    {
                                        optionsData.map(({ value, label },index) => {
                                            let _sel = '';
                                            if(this.state.selectedOption == value)
                                            {_sel = 'selected';}
                                            return(            
                                            <option key={index} value={value}  selected={_sel}>{label}</option>
                                            )
                                        })
                                    }
                                    </select>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="BarcodeScan" defaultChecked={(_businessSetting.BarcodeScan > 0) ? true : false} name="BarcodeScan" value="1" data-slug="BarcodeScan" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Barcode scanning for items <i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title="" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolBarcodeScan,'Barcode Scan')}></i></span>
                                </li>

                                {/* <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="StockMaintenance" defaultChecked={(_businessSetting.StockMaintenance > 0) ? true : false} name="StockMaintenance" value="1" data-slug="StockMaintenance" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Stock Maintenance <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolStockMaintenance,'Stock Maintenance')}></i></span>
                                </li> * Becasue we added add quantity option on create and edit transaction/}
                                {/* <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="ShowLowStockDialog" defaultChecked={(_businessSetting.ShowLowStockDialog > 0) ? true : false} name="ShowLowStockDialog" value="1" data-slug="ShowLowStockDialog" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Show Low Stock Dialog <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolShowLowStockDialog,'Show Low Stock Dialog')}></i></span>
                                </li> */}
                                {(this.state.trasactionCount == 0 || this.state.trasactionCount == '0')&&
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="ItemsUnit" defaultChecked={(_businessSetting.ItemsUnit > 0) ? true : false} name="ItemsUnit" value="1" data-slug="ItemsUnit" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Items Unit<i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolItemsUnit,'Items Unit')}></i></span>
                                </li>
                                }
                                {this.state.trasactionCount > 0 &&
                                    
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="ItemsUnit" defaultChecked={(_businessSetting.ItemsUnit > 0) ? true : false} name="ItemsUnit" value="1" data-slug="ItemsUnit" onChange={this.onFilterChangeOnPopup}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Items Unit<i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolItemsUnit,'Items Unit')}></i></span>
                                </li>
                                }
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.DefaultUnit > 0) ? true : false} name="activeItem" value="1" data-slug="DefaultUnit" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Default Unit <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDefaultUnit,'Default Unit')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="ItemCategory" defaultChecked={(_businessSetting.ItemCategory > 0) ? true : false} name="ItemCategory" value="1" data-slug="ItemCategory" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Item Category <i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title="" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolItemCategory,'Item Category')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="PartyWiseItemRate" defaultChecked={(_businessSetting.PartyWiseItemRate > 0) ? true : false} name="PartyWiseItemRate" value="1" data-slug="PartyWiseItemRate" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Party Wise Item Rate <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyWiseItemRate,'Party Wise Item Rate')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="Description" defaultChecked={(_businessSetting.Description > 0) ? true : false} name="Description" value="1" data-slug="Description" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Description <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolDescription,'Description')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="ItemWiseTax" defaultChecked={(_businessSetting.ItemWiseTax > 0) ? true : false} name="ItemWiseTax" value="1" data-slug="ItemWiseTax" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Item Wise Tax <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolItemWiseTax,'Item Wise Tax')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.ItemWiseDiscount > 0) ? true : false} name="activeItem" value="1" data-slug="ItemWiseDiscount" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Item Wise Discount <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolItemWiseDiscount,'Item Wise Discount')}></i></span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" id="activeItem" defaultChecked={(_businessSetting.UpdateSalePriceTrans > 0) ? true : false} name="activeItem" value="1" data-slug="UpdateSalePriceTrans" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Update Sale Price Transaction <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolUpdateSalePriceTrans,'Update Sale Price Transaction')}></i></span>
                                </li>
                                {/* <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Quantity Decimal <i className="si si-info" data-toggle="tooltip" data-placement="bottom" data-original-title="Quantity Decimal"></i></span>
                                    <input type="number" id="activeItem" defaultChecked={(_businessSetting.QuantityDecimal > 0) ? true : false} name="activeItem" value="1" data-slug="QuantityDecimal" onChange={this.onFilterChange}/>
                                </li> */}
                                </div>
                            </ul>
                        </div>
                        <div className="col-md-6" id="getHideShowItem">
                            <div className={this.state.classValue}>
                            <h5 className="content-heading">Additional Item Fields</h5>
                            {/* {this.allCompanyFirmList()} */}
                            <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><b>Serial No.Tracking </b><i className="si si-info" data-toggle="tooltip" data-placement="bottom"  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolSerialNoChk,'Serial No.Tracking ')}></i></span>
                            <br/> <br/>
                            <ul>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.SerialNoChk > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="SerialNoChk" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Serial No <i className="si si-info" data-toggle="tooltip" data-placement="bottom"  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolSerialNoChk,'Serial No')}></i></span>
                                    &nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <input type="text" data-slug="SerialNoTxt" id="SerialNoTxt" name="SerialNoTxt" value ={this.state.SerialNoTxt} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.SerialNoAdditionalChk1 > 0) ? true : false} id="SerialNoAdditionalChk1" name="SerialNoAdditionalChk1" value="1" data-slug="SerialNoAdditionalChk1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Additional Field 1 </span>
                                    &nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} className={this.state.classValue1} >
                                        <input type="text" data-slug="SerialNoAdditionalLabel1" id="SerialNoAdditionalLabel1" name="SerialNoTxt" value ={this.state.SerialNoAdditionalLabel1} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                    {this.state.SerialNoAdditionalLabel1 == '' &&
                                        <span style={{color : 'red'}} className={this.state.classValue1} > Add a name for this field, uncheck the field if not required</span>
                                    }
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.SerialNoAdditionalChk2 > 0) ? true : false} id="SerialNoAdditionalChk2" name="SerialNoAdditionalChk2" value="1" data-slug="SerialNoAdditionalChk2" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Additional Field 2 </span>
                                    &nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}  className={this.state.classValue2} >
                                        <input type="text" data-slug="SerialNoAdditionalLabel2" id="SerialNoAdditionalLabel2" name="SerialNoTxt" value ={this.state.SerialNoAdditionalLabel2} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                    {this.state.SerialNoAdditionalLabel2 == '' &&
                                        <span style={{color : 'red'}} className={this.state.classValue2} > Add a name for this field, uncheck the field if not required</span>
                                    }
                                </li>
                                
                            </ul>
                            <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><b>Batch Tracking</b> <i className="si si-info" data-toggle="tooltip" data-placement="bottom"  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolBatchTracking,'Batch Tracking')}></i></span>
                            <br/> <br/>
                            <ul>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.BatchNoChk > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="BatchNoChk" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Batch No &nbsp;&nbsp;
                                    {/* <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolBatchNoChk,'Batch No')}></i> */}
                                    </span>&nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}><input type="text"  data-slug="BatchNoTxt" id="BatchNoTxt" name="BatchNoTxt" value ={this.state.BatchNoTxt} onChange={this.handleLTxtKeyUp}/></span>
                                </li>

                                {/* <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.MRPChK > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="MRPChK" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>MRP Price  &nbsp;&nbsp;
                                    </span>&nbsp;&nbsp;
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <input type="text" data-slug="MRPTxt" id="MRPTxt" name="MRPTxt" value={this.state.MRPTxt} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                </li> */}
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.ExpDateChk > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="ExpDateChk" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Exp Date &nbsp;&nbsp;
                                    {/* <i className="si si-info" data-toggle="tooltip" data-placement="bottom"  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolExpDateChk,'Exp Date')}></i> */}
                                    </span>&nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <input type="text" data-slug="ExpDateTxt" id="ExpDateTxt" name="ExpDateTxt" value ={this.state.ExpDateTxt} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                    {/* <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <DateRangePicker
                                            initialSettings={{
                                            singleDatePicker: true,
                                            startDate: this.state.ExpDateTxt
                                            }}
                                            onCallback={this.handleExpDate}
                                        >
                                            <input type="text" className="form-control" />
                                        </DateRangePicker>
                                    </span> */}
                                </li>
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.MfgDateChk > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="MfgDateChk" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Mfg Date &nbsp;&nbsp;
                                    {/* <i className="si si-info" data-toggle="tooltip" data-placement="bottom"  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolMfgDateChk,'Mfg Date')}></i> */}
                                    </span>&nbsp;&nbsp;
                                    
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <input type="text" data-slug="MfgDateTxt" id="MfgDateTxt" name="MfgDateTxt" value ={this.state.MfgDateTxt} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                    
                                </li>
                                
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.ModelNoChk > 0) ? true : false} id="activeItem" name="activeItem" value="1" data-slug="ModelNoChk" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Model No.&nbsp;&nbsp;
                                    {/* <i className="si si-info" data-toggle="tooltip" data-placement="bottom"  onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolModelNoChk,'Model No.')}></i> */}
                                    </span>&nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <input type="text" data-slug="ModelNoTxt" value ={this.state.ModelNoTxt} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.SizeChk > 0) ? true : false} id="SizeChk" name="SizeChk" value="1" data-slug="SizeChk" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Size &nbsp;&nbsp;
                                    {/* <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolSizeChk,'Size')}></i> */}
                                    </span>&nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                        <input type="text" data-slug="SizeTxt" value ={this.state.SizeTxt} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                </li>
                                
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.BatchNoAdditionalChk1 > 0) ? true : false} id="BatchNoAdditionalChk1" name="BatchNoAdditionalChk1" value="1" data-slug="BatchNoAdditionalChk1" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Additional Field 1 </span>
                                    &nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} className={this.state.classValueBatchNo1} >
                                        <input type="text" data-slug="BatchNoAdditionalLabel1" id="BatchNoAdditionalLabel1" name="SerialNoTxt" value ={this.state.BatchNoAdditionalLabel1} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                    {this.state.SerialNoAdditionalLabel1 == '' &&
                                        <span style={{color : 'red'}} className={this.state.classValueBatchNo1} > Add a name for this field, uncheck the field if not required</span>
                                    }
                                </li>
                                <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                    <input type="checkbox" defaultChecked={(_businessSetting.BatchNoAdditionalChk2 > 0) ? true : false} id="BatchNoAdditionalChk2" name="BatchNoAdditionalChk2" value="1" data-slug="BatchNoAdditionalChk2" onChange={this.onFilterChange}/>
                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Additional Field 2 </span>
                                    &nbsp;&nbsp;

                                    <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}  className={this.state.classValueBatchNo2} >
                                        <input type="text" data-slug="BatchNoAdditionalLabel2" id="BatchNoAdditionalLabel2" name="SerialNoTxt" value ={this.state.BatchNoAdditionalLabel2} onChange={this.handleLTxtKeyUp}/>
                                    </span>
                                    {this.state.SerialNoAdditionalLabel2 == '' &&
                                        <span style={{color : 'red'}} className={this.state.classValueBatchNo2} > Add a name for this field, uncheck the field if not required</span>
                                    }
                                </li>
                            </ul>
                            </div>
                        </div>
                    
                </div>
                
                {/* Modal  Tooltip Information Begins */}
               <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">{this.state._infoTitle}</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                {parse(this.state._infoData)}
                                </div>
                                <br/>
                            </div>
                            
                        </div>
                    </div>
                </Modal>
                {/* Modal End Tooltip Information*/}

                {/* Modal  Tooltip Information Uncheck Begins */}
               <Modal isOpen={this.state.modalIsOpen_InfoUnCheck} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandlerAfterUncheck} id="openModalInfoHandlerUncheck">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">{this.state._popupTitleUncheck}</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandlerAfterUncheck.bind(this,'openModalInfoHandlerUncheck')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                {parse(this.state._popupDataUncheck)}
                                </div>
                                <br/>
                            </div>
                            
                        </div>
                    </div>
                </Modal>
                {/* Modal End Tooltip Information Uncheck*/}
                
                {/* Modal  Warning Information Uncheck Begins */}
               <Modal isOpen={this.state.modalIsOpen_Info2} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalWarning} id="openModalInfoHandler2">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">{this.state._infoTitle2}</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalWarning.bind(this,'openModalInfoHandler2')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                {parse(this.state._infoData2)}
                                </div>
                                <br/>
                                
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick={this.updateWarningData.bind(this,'ItemWiseTax')}>No</button>
                                    
                                    <button type="button" className="btn btn-alt-primary" onClick={this.closeModalWarning.bind(this,'openModalInfoHandler2')}>Enable Anyway</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Modal>
                {/* Modal End Warning Information */}
            </React.Fragment>
        )
    }
} 
