import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Company Name',
        field: 'company_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Company Name',
        },
    },
    {
        label: 'Business Name',
        field: 'business_name',
        width: 150,
    },
    {
        label: 'Business Category',
        field: 'business_category',
        width: 150,
    },
    {
        label: 'Business Type',
        field: 'business_type',
        width: 150,
    },
    {
        label: 'Logo',
        field: 'logo',
        width: 150,
    },
    {
        label: 'Status',
        field: 'status',
        width: 100,
    },
    {
        label: 'Added',
        field: 'added_on',
        width: 150,
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';
export default class companyListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            companyArray :[]
        }
    }

    async componentDidMount ()
    {
        this.getCompanyListData();
    }

    getCompanyListData(){
        let _companyArray = [];
        let _url_GetData = global.adminCompanyList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _companyArray = responseJson.response;
                }
                this.setState({companyArray:_companyArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    clickToDelete(value)
    {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        companyId: value
                    });
                    PostData(global.adminCompanyDelete, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getCompanyListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    clickToStatus(value,_status)
    {
        confirmAlert({
            title: 'Confirm to manage status',
            message: 'Are you sure you want to change the status?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        companyId: value,
                        status:_status
                    });
                    PostData(global.adminCompanyStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getCompanyListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    loadOrderListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _companyDetails = this.state.companyArray;
            let dataList = [];
            let rows = [];

            if(_companyDetails.length > 0)
            {                          
                _companyDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    let _status = 1;
                    let status = <span className={`badge badge-danger`}>Disabled</span>;
                    if (row.status == '1') {
                        _status = 0;
                        status = <span className={`badge badge-success`}>Enabled</span>;
                    }
        
                    let _dtt = row.added_on;
                    var options = {year: 'numeric', month: 'long', day: 'numeric' };
                    
                    let _logo = 'N/A';
                    if(row.logo_path!='N/A'){
                        _logo = <a href={`${row.logo_path}`} target="_blank"><img src={`${row.logo_path}`} style={{width:20}}/></a>
                    }

                    let _typeName = 'N/A';
                    if(row.business_type_name!=null){
                        _typeName = row.business_type_name
                    }

                    let _catName = 'N/A';
                    if(row.business_category_name!=null){
                        _catName = row.business_category_name
                    }
                    
                    rows.push({
                        company_name: row.company_name,
                        business_name: row.business_name,
                        srNo: row.srNo,
                        status: (status),
                        business_type:_typeName,
                        business_category:_catName,
                        logo:_logo,
                        added_on: new Intl.DateTimeFormat('en-US', options).format(_dtt),
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/add-company/?auth='+row.company_id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.company_id} onClick={this.clickToStatus.bind(this,row.company_id,_status)}>
                                        <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>Manage Status
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.company_id} onClick={this.clickToDelete.bind(this,row.company_id)}>
                                        <i className="si si-trash mr-5 text-danger"></i>Delete Details
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Company List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/manage-business/"}>View Business List</a>
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/add-company/"}>Add New Company</a>
                        <span className="breadcrumb-item active">Manage Company List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">List of Company</h3>
                        </div>
                        <div className="block-content">
                            <MDBDataTableV5 
                            hover
                            entriesOptions={[25, 50, 100]}
                            entries={25}
                            pagesAmount={4}
                            span
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            data={this.loadOrderListing()} />
                        </div>
                    </div>    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div class="gl-ldr-cld">
                        <div class="gl-ldr-ctr">
                            <div class="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}