// Party Apis
global.userAddService = global.API_USER_URL+'userAddService/';
global.userServiceList = global.API_USER_URL+'userServiceList/';
global.userServiceDetails = global.API_USER_URL+'userServiceDetails/';
global.userEditService = global.API_USER_URL+'userEditService/';
global.userServiceStatus = global.API_USER_URL+'userServiceStatus/';

global.userServiceMultipleInActive = global.API_USER_URL+'userServiceMultipleInActive/';
global.userServiceMultipleActive = global.API_USER_URL+'userServiceMultipleActive/';
global.userActiveServiceList = global.API_USER_URL+'userActiveServiceList/';
global.userInActiveServiceList = global.API_USER_URL+'userInActiveServiceList/';