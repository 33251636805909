import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];
let _businessSetting = {};

let _loader = "glb-ldr-prt active";

export default class QuickAddNormalQuantity extends Component {
  constructor(props) {
    super(props);
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTaxDetails = _companySession.taxDetails;
      if (_UserSession!=null && _UserSession!="") {
        if (_UserSession.loginType!="Admin") {
          if (_UserSession!=undefined || _UserSession!=null) {
            _businessSetting = _UserSession.businessSetting;
          }
        }
      }
    }

    this.state = {
      itemId: this.props.itemId ? this.props.itemId : "0",
      normalRemainingQuantity: this.props.normalRemainingQuantity
        ? this.props.normalRemainingQuantity
        : "0",
      normalQuantityTYpe: this.props.normalQuantityTYpe
        ? this.props.normalQuantityTYpe
        : "",
      openingStock: 0,
      isLoaded: true,
      modalIsOpen_SerialNo: true,
      itemBatchNo: [],
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemFields :  this.props.itemFields ? this.props.itemFields : [],
      totalAmountOld: this.props.totalAmountOld ? this.props.totalAmountOld : 0,
      totalQuantity: this.props.totalQuantity ? this.props.totalQuantity : 0,
      itemPrice: this.props.itemPrice ? this.props.itemPrice : 0,
      transactionId: this.props.transactionId ? this.props.transactionId : 0,
      indexPosition: this.props.indexPosition ? this.props.indexPosition : 0,
      addedQty: this.props.addedQty ? this.props.addedQty : 0,
      normalQtyField: this.props.normalQtyField ? this.props.normalQtyField : 0,
      itemTypeOption: this.props.itemTypeOption ? this.props.itemTypeOption : "",

      isError: false,
      without_sr_quantity: 0,
      normal_quantity_type: "",
      errors: {
        error: "",
        without_sr_quantity: "",
      },
    };
  }
  async componentDidMount() {
    console.log(this.state.normalRemainingQuantity + "==>NO--AD--AK-->=="+this.state.addedQty+'==>Trans>=='+this.state.transactionId); 
    if (this.state.addedQty > 0 && this.state.transactionId > 0) {
      let normalRemainingQuantity =  parseFloat(this.state.normalRemainingQuantity) -  parseFloat(this.state.addedQty);
      console.log(normalRemainingQuantity+"==ZZZ===" + this.state.addedQty); 

      if (normalRemainingQuantity <= 0) {
        console.log("==RRRR==="+normalRemainingQuantity); 
        normalRemainingQuantity = 0;
        this.setState({normalRemainingQuantity: normalRemainingQuantity,normalQuantityTYpe: "Purchase",
        });
      } else {
        this.setState({ normalRemainingQuantity: normalRemainingQuantity });
      }
    }
    console.log("==here--akk-", this.state.itemTypeOption);
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _isError = false;
    let _sendNQtyType = "";
    switch (name) {
      case "without_sr_quantity":
        if (
          parseFloat(this.state.normalRemainingQuantity) < parseFloat(value) &&
          this.state.normalQuantityTYpe == "Sale"
        ) {
          console.log(
            this.state.normalQtyField +
              "==here--11--" +
              this.state.normalRemainingQuantity
          );
          //if(this.state.normalQtyField == 0 && this.state.normalRemainingQuantity > 0){
          if (this.state.normalRemainingQuantity > 0) {
            //_isError = true;
            //errors.error = "*Entered quantity should not be greater than available quantity";
          } else {
            _isError = false;
            errors.error = "";
            _sendNQtyType = "NEW";
          }
        } else {
          console.log(
            this.state.normalQtyField +
              "==here--22--" +
              this.state.normalRemainingQuantity
          );
          if (validator.isInt(value)) {
            _isError = false;
            errors.error = "";
          } else {
            _isError = true;
            errors.error = "*quantity should be numeric!";
          }
          //_isError = false;
          //errors.error = '';
        }
        console.log("===_sendNQtyType==", _sendNQtyType);
        this.setState({
          without_sr_quantity: value,
          error: errors,
          isError: _isError,
          normal_quantity_type: _sendNQtyType,
        });
        break;
      default:
        break;
    }
  };

  clickToAddData = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (
      this.state.without_sr_quantity == 0 ||
      this.state.without_sr_quantity == "0"
    ) {
      _isError = true;
      errors.error = "Quantity cannot be 0 or blank";
      this.setState({ errors: errors, isError: _isError });
      return false;
    }

    let _fieldData =  this.state.itemFields;
    let discountAmt = 0;   
    let totalAmount = 0;
    let tax = 0;
    let amountBeforeTaxWithoutDisc = 0;
    let disc = 0;
    let amountBeforeTax = 0 ;
    let subTotal = 0;    
    let _discountAmtSend = 0;
    let _taxAmtSend = 0;
    let _itemToalAmtSend = 0;
    let _costPrice = 0;
    
   
    if (_isError == false) {
      _fieldData.map((itemData, i) => {
        
        if (i == this.state.indexPosition) {          
            let qty = parseFloat(this.state.without_sr_quantity) ? parseFloat(this.state.without_sr_quantity) : 0;
            let rateValue = parseFloat(itemData.rate);
            let taxType = parseFloat(itemData.tax_type.ratio) ? parseFloat(itemData.tax_type.ratio) : 0.0;
            let additionalCess = parseFloat(itemData.Additional_CESS) ? parseFloat(itemData.Additional_CESS) : 0;
            let discount_in_per =  parseFloat(itemData.discount_in_per) ?  parseFloat(itemData.discount_in_per) : 0
            let discount_in_amount =  parseFloat(itemData.discount_in_amount) ?  parseFloat(itemData.discount_in_amount) : 0;
            subTotal = rateValue * qty;
          
            if (itemData.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
            {                 
              if(discount_in_per > 0){       
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
                discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                //disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                disc = (parseFloat(rateValue) / (1 + (parseFloat(taxType) / 100)));//parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                disc =  disc * parseFloat(discount_in_per) / 100;
                tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                console.log(amountBeforeTaxWithoutDisc+"===disc==",disc) ;
                totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);

                //Discount amont == disc;
                disc = disc.toFixed(2);
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = disc;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{
                if(taxType > 0){
                  _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                }else{
                  _costPrice = subTotal;
                }          
                discountAmt = discount_in_amount;// * qty;;
                amountBeforeTax = _costPrice - discountAmt;
                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                tax = amountBeforeTax * taxType / 100;
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);

                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
            }

            else if (itemData.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0')) 
            {      
              if(discount_in_per > 0){       
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);                    
                discountAmt = (discount_in_per / 100) * subTotal;
                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                //disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                disc = (parseFloat(rateValue) / (1 + (parseFloat(taxType) / 100)));
                disc =  disc * parseFloat(discount_in_per) / 100;
                tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + additionalCess;

                //Discount amont == disc;
                disc = disc.toFixed(2);
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = disc;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{
               
                if(taxType > 0){
                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                }else{
                    _costPrice = subTotal;
                }                 
                discountAmt = discount_in_amount;// * qty;;
                
                amountBeforeTax = _costPrice - discountAmt;
                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                tax = amountBeforeTax * taxType / 100;
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);

                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
            }
         
            else {
              if(discount_in_per > 0){                 
                _costPrice = subTotal;          
                discountAmt = (discount_in_per / 100) * subTotal;
                amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);            
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{   
                _costPrice = subTotal;           
                discountAmt = discount_in_amount;// * qty;;
                amountBeforeTax = subTotal - discountAmt;
                tax = amountBeforeTax * taxType / 100;
                //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
                      
            }
        }
      })
      
      console.log(_discountAmtSend+"==>discountAmt 22-->=="+_taxAmtSend+"==>tax-->=="+_itemToalAmtSend+"==>totalAmount-->==");   
      let _totalQuantity = parseFloat(this.state.without_sr_quantity) + parseFloat(this.state.totalQuantity);
      let itemPrice = parseFloat(this.state.itemPrice) * parseFloat(this.state.without_sr_quantity);
      let _totalAmountOld = parseFloat(this.state.itemPrice) * parseFloat(this.state.without_sr_quantity);
      
      _totalAmountOld = parseFloat(this.state.totalAmountOld) + parseFloat(_totalAmountOld);

      //this.props.callBack (_itemSerialData, _opening_stock);

      console.log(this.state.normalRemainingQuantity+"==>QQQ->=="+this.state.without_sr_quantity);   
      let _finalRemainQty = parseFloat(this.state.normalRemainingQuantity) - parseFloat(this.state.without_sr_quantity);
      if (_finalRemainQty < 0) {
        _finalRemainQty = 0;
      }
      
      console.log("==>zzzz->=="+_finalRemainQty);   
      this.props.callBack(
        this.state.without_sr_quantity,
        itemPrice,
        _totalAmountOld,
        _totalQuantity,
        this.state.indexPosition,
        _finalRemainQty,
        this.state.normal_quantity_type,
        _discountAmtSend,
        _taxAmtSend,
        _itemToalAmtSend,
        _costPrice
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    let isDisabled = ""; //Object.keys(errors).some(x => errors[x]);
    if (this.state.isError == true) {
      isDisabled = "disabled";
    }
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            {this.state.normalQuantityTYpe == "Sale" &&
              this.state.itemTypeOption == "" && (
                <h4 className="badge badge-light k-mt-md ">
                  Available Quantity Is : {this.state.normalRemainingQuantity}
                </h4>
              )}
          </div>

          <div className="form-group row">
            {this.state.normalRemainingQuantity >= 0 &&
              this.state.normalQuantityTYpe == "Sale" && (
                <div className="col-6">
                  <div className="form-material open">
                    <input
                      type="number"
                      className="form-control"
                      id="serial_no"
                      name="without_sr_quantity"
                      value={this.state.without_sr_quantity || ""}
                      onChange={this.handleChange}
                      placeholder="Enter Quantity"
                    />
                    <label htmlFor="login-username">Enter Quantity </label>
                    <span className="iris_error_txt">
                      {errors.error?.length > 0 && (
                        <span className="error">{errors.error}</span>
                      )}
                    </span>
                  </div>
                </div>
              )}

            {this.state.normalQuantityTYpe == "Purchase" && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="number"
                    className="form-control"
                    id="serial_no"
                    name="without_sr_quantity"
                    value={this.state.without_sr_quantity || ""}
                    onChange={this.handleChange}
                    placeholder="Enter Quantity"
                  />
                  <label htmlFor="login-username">Enter Quantity </label>
                  <span className="iris_error_txt">
                    {errors.error?.length > 0 && (
                      <span className="error">{errors.error}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddData}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
