import React, { Component } from "react";
import { PostData } from "../../../../service/postData";
import { sendNotification } from "../../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import ButtonPdf from "../../htmlPdfCreation";

let _businessSetting = {};
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";


let columns = [
  {
    label: "Date",
    field: "invoice_date",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Date",
    },
  },
  {
    label: "Invoice No.",
    field: "invoice_no",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Invoice No.",
    },
  },
  {
    label: "Party Name",
    field: "party_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Name",
    },
  },
  {
    label: "Transaction Type",
    field: "sale_type",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Type",
    },
  },
  {
    label: "Payment Type",
    field: "payment_type",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Type",
    },
  },
  {
    label: "Amount",
    field: "total_amount",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Amount",
    },
  },
  {
    label: "Balance Due",
    field: "balance",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Balance Due",
    },
  },
  {
      label: 'Status',
      field: 'status',
      width: 150,
      attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'status',
      },
  },
  {
    label: "Action",
    field: "actionExtra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";
let _currentYear = moment().format("YYYY");
let _startDateYaer = "01/01/" + _currentYear;
let _endDateYaer = "12/31/" + _currentYear;
export default class saleReportPanel extends Component {
  constructor(props) {
    super(props);
    
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _businessSetting = _UserSession.businessSetting;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _companyId = _companySession.company_id;
    }
        
    if(_businessSetting.DueDatesPaymentTerms == 1){
      columns = [
        {
          label: "Date",
          field: "invoice_date",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Date",
          },
        },
        {
          label: "Invoice No.",
          field: "invoice_no",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Invoice No.",
          },
        },
        {
          label: "Party Name",
          field: "party_name",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Party Name",
          },
        },
        {
          label: "Transaction Type",
          field: "sale_type",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Party Type",
          },
        },
        {
          label: "Payment Type",
          field: "payment_type",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Party Type",
          },
        },
        {
          label: "Amount",
          field: "total_amount",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Amount",
          },
        },
        {
          label: "Balance Due",
          field: "balance",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Balance Due",
          },
        },
        {
            label: 'Status',
            field: 'status',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'status',
            },
        },
        {
            label: 'Due Date',
            field: 'due_date',
            width: 150,
            attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'due dates',
            },
        },
        {
          label: "Action",
          field: "actionExtra",
          width: 100,
        },
      ];
    }
    this.state = {
      buttonTitle: "Add Sale",
      isLoaded: true,
      transactionArray: [],
      transactionArrayFilter: [],
      DueDatesPaymentTerms : _businessSetting.DueDatesPaymentTerms ? _businessSetting.DueDatesPaymentTerms : 0,
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      name: null,
      paidAmount: 0,
      unpaidAmount: 0,
      total: 0,
      // startDate: moment(_startDateYaer).format("MMMM D, YYYY"),
      // endDate: moment(_endDateYaer).format("MMMM D, YYYY"),
      startDate : moment().startOf('month').toDate(),
      endDate : moment().endOf('month').toDate(),
      //startDate : moment().month('April').startOf('month').format("MMM DD, YYYY"),
      //endDate : moment().add(1, 'year').month('March').endOf('month').format("MMM DD, YYYY"),
      dateLabel: "",
      partyData : [],
      activeTransactionPaymentKey : 'ALL',
      activeTransactionKey : 'ALL',
      activePartyKey : 'ALL',
      overDueAmt : 0,
      errors: {
        name: "",
      },
    };
  }

  async componentDidMount() {
    this.getSaleListData(this.state.activeTransactionKey,this.state.activeTransactionPaymentKey,this.state.page,this.state.activePartyKey);
    this.getPartyListData(this.state.company_id);
  }

  getPartyListData() {
      let _partyData = [];
      let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
      PostData(_url_GetData, "", "GET").then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
              this.setState({ isLoaded: true });
          } else {
              if (responseJson.response) {
              _partyData = responseJson.response;
              }
              this.setState({ partyData: _partyData, isLoaded: true });
          }
      }).catch((error) => this.setState({ error, isLoaded: false}));
  }

  getSaleListData(_type,_paymentStatus,_page,_partyIDS) {
    let _transArray = [];
    let _paidAmt = 0;
    let _unpaidAmt = 0;
    let _totalAmt = 0;
    let _overDueAmt = 0;

    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      transaction_type:_type,
      payment_status : _paymentStatus,
      partyId : parseFloat(_partyIDS),
      isDateFilter: true,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    });

    PostData(global.userSaleTransactionReportList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            overDueAmt : _overDueAmt,
            total: _totalAmt,
          });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
            _transArray.map((itm, i) => {
              
              /*if(itm.payment_term_due_status == 'Order Overdue'){
                _overDueAmt =  parseFloat(_unpaidAmt) + parseFloat(itm.balance);
              }
              _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.balance);
              _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.received_amount);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(itm.total_amount);*/
              _paidAmt = responseJson.totalPaidAmount;
              _unpaidAmt = responseJson.totalUnPaidAmount;
              _overDueAmt = responseJson.totalOverDueAmount;
              _totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt) + parseFloat(_overDueAmt);
            });
          }
          //_totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
          
          if(_transArray.length == 0){
            _overDueAmt = 0;
          }
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            overDueAmt: _overDueAmt,
            total: _totalAmt,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadTranscListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.transactionArrayFilter;
      if (_transDetails.length > 0) {
        _transDetails.map((value, index) => {
          let _textShow = "Credit Note";
          let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
          if (value.type == "SALE") {
            _textShow = "Sale";
          }
          let _typeSend = "&tType=Report";
          let _editActionUrl = global.webUrl + "business/add-sale/?auth=" + value.id+_typeSend;
          let _editDuplicateActionUrl =global.webUrl + "business/add-sale-duplicate/?auth=" + value.id+'&tType=DUPLICATE';
          if (value.type == "SALE") {
            _editActionUrl =global.webUrl + "business/add-sale/?auth=" + value.id+_typeSend;
            _editDuplicateActionUrl =global.webUrl + "business/add-sale-duplicate/?auth=" + value.id+'&tType=DUPLICATE';
          } else if (value.type == "CREDIT NOTE") {
            _editActionUrl = global.webUrl + "business/add-sale-credit-note/?auth=" + value.id+_typeSend;
            _editDuplicateActionUrl = global.webUrl + "business/add-sale-return-duplicate/?auth="+value.id;
          }

          let _actionExtra = "";
          if (value.type == "SALE") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  
                  {_trans_duplicate_normal === 'Normal' &&
                  <a className="dropdown-item" href={_editDuplicateActionUrl}>
                    Duplicate
                  </a>
                  }
                  
                  {_trans_duplicate_normal === 'Normal' &&
                    <a className="dropdown-item" href={_editActionUrl}>
                      View/Edit Details
                    </a>
                  }
                  
                  {_trans_duplicate_normal === 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-receive-payment-in/?auth=" +
                        value.id+_typeSend
                      }
                    >
                      Receive Payment
                    </a>
                  }
                  
                  {_trans_duplicate_normal === 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +'business/add-sale-credit-note?auth='+value.id+"&tType=CREDIT NOTE&isConvert=true"
                      }
                    >
                      Convert to Return
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this, value.id, "Sale")}
                  >
                    Delete
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview as Delivery Challan
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                  {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                </div>
              </div>
            );
          } else if (value.type == "CREDIT NOTE") {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  
                  {_trans_duplicate_normal === 'Normal' &&
                    <a className="dropdown-item" href={_editActionUrl}>
                      View/Edit Details
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    data-id={value.id}
                    onClick={this.clickToDelete.bind(this,value.id,"CREDIT NOTE")}
                  >
                    Delete
                  </a>
                  {_trans_duplicate_normal === 'Normal' &&
                    <a className="dropdown-item" href={_editDuplicateActionUrl}>
                      Duplicate
                    </a>
                  }
                  {_trans_duplicate_normal === 'Normal' &&
                    <a
                      className="dropdown-item"
                      href={
                        global.webUrl +
                        "business/add-make-payment-out/?auth=" +
                        value.id+_typeSend
                      }
                    >
                      Make Payment
                    </a>
                  }
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                  {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
                </div>
              </div>
            );
          } else {
            _actionExtra = (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {_trans_duplicate_normal === 'Normal' &&
                  <a className="dropdown-item" href={_editDuplicateActionUrl}>
                    Duplicate
                  </a>
                  }
                  {_trans_duplicate_normal === 'Normal' &&
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/add-make-payment-out/?auth=" +
                      value.id+_typeSend
                    }
                  >
                    Make Payment
                  </a>
                  }
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Download PDF
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Preview
                  </a>
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl + "business/download-pdf/?auth=" + value.id
                    }
                    target="_blank"
                  >
                    Print
                  </a>
                  {/* <ButtonPdf transactionId={value.id}/> */}
                </div>
              </div>
            );
          }


          let _saleType = <span className="badge badge-warning">UNPAID</span>;
          if(value.payment_paid == 'PARTIAL'){
              _saleType = <span className="badge badge-info">{value.payment_paid}</span>;
          }
          else if(value.payment_paid == 'PAID'){
              _saleType = <span className="badge badge-success">{value.payment_paid}</span>;
          }

          
          let _showDupL = '';
          if(_trans_duplicate_normal == 'Duplicate'){
              _showDupL = ' (Duplicate)';
          }
          
          if(this.state.DueDatesPaymentTerms == 1){
            let _dueDates =  value.payment_term_due_date;
            if(value.payment_term_due_date == '0' || value.payment_term_due_date == '' || value.payment_term_due_date == ''){
              _dueDates = 'NA'
            }
            if(value.payment_term_due_status.length > 3 && value.balance >0){
              _saleType = <span className="badge badge-danger">Overdue</span>;
            }
            rows.push({
              srNo: value.srNo,
              invoice_date: (value.invoice_date),
              invoice_no: value.invoice_no + _showDupL, //value.customPrefix+' #'+value.invoice_no,
              party_name: value.party_name,
              sale_type: _textShow,
              payment_type: value.payment_type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              status: _saleType,
              due_date: _dueDates,
              actionExtra: _actionExtra,
            });
          }
          if(this.state.DueDatesPaymentTerms == 0){
            rows.push({
              srNo: value.srNo,
              invoice_date: (value.invoice_date),
              invoice_no: value.invoice_no + _showDupL, //value.customPrefix+' #'+value.invoice_no,
              party_name: value.party_name,
              sale_type: _textShow,
              payment_type: value.payment_type,
              total_amount: numberFormat(value.total_amount),
              balance: numberFormat(value.balance),
              status: _saleType,
              actionExtra: _actionExtra,
            });
          }

        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  handleCallBackDate = (start, end) => {
    /*this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getSaleListData();*/
    
    this.setState({startDate:start.format('MMMM D, YYYY'),endDate:end.format('MMMM D, YYYY')});
    this.getSaleListData(this.state.activeTransactionKey,this.state.activeTransactionPaymentKey,this.state.page,this.state.activePartyKey);
  };

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  //this.getSaleListData();
                  this.getSaleListData(this.state.activeTransactionKey,this.state.activeTransactionPaymentKey,this.state.page,this.state.activePartyKey);
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  exportData = (event) =>{
    let _itemDetails = this.state.itemArray;
    try {
      let totalAmount = 0;
      _itemDetails.map((data) => {
        totalAmount += parseFloat(data.total_amount);
      });

      _itemDetails = _itemDetails.map((data) => {

        let newData = {
          'Date': data.invoice_date,
          'Order No': data.order_number,
          'Invoice No': data.invoice_no,
          'Party Name': data.party_name,
          'Party Phone No.': data.party_phone,
          'Transaction Type': data.type,
          'Total Amount': data.total_amount,
          'Payment Type': data.payment_type,
          'Received/Paid Amount': data.received_amount,
          'Balance Due': data.balance,
          'Payment Status': data.payment_paid,
          'Description': data.description,
          'Transport Name': data.TransportDetailField1,
          'Vehicle Number': data.TransportDetailField2,
        }
         return newData;
      });

      let totalAmountRow = {
        'Date': '',
        'Order No': '',
        'Invoice No': '',
        'Party Name': '',
        'Party Phone No.': '',
        'Transaction Type': 'Total',
        'Total Amount': totalAmount,
        'Payment Type': '',
        'Received/Paid Amount': '',
        'Balance Due': '',
        'Payment Status': '',
        'Description': '',
        'Transport Name': '',
        'Vehicle Number': '',
      };

      _itemDetails.push({});
      _itemDetails.push(totalAmountRow);
      let Heading = [['Generated on '+moment().format('MMMM DD YYYY')+' at '+moment().format('hh:mm A')]];
      var ws = XLSX.utils.json_to_sheet(_itemDetails, { origin: 'A3'});
      var wb = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, Heading);
      XLSX.utils.book_append_sheet(wb,ws,"Sale Report");
      const wbout = XLSX.write(wb, {type:'array', bookType:"xlsx"});
      let length = 6;
      let uniqueNo = String(Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)));
      let fileName = 'export_sale_'+moment().format('DD/MM/YYYY')+'.xlsx';
      const data = new Blob([wbout], {type: "xlsx"});
      FileSaver.saveAs(data, fileName);

    } catch (err) {
      //alert('Unknown Error: ' + JSON.stringify(err));
      throw err;
    }
  }

  changeTypeFilter = (e) => {        
      this.setState({activeTransactionKey : e.target.value});
      this.getSaleListData(e.target.value,this.state.activeTransactionPaymentKey,this.state.page,this.state.activePartyKey);
  }
  changeTypePayStausFilter = (e) => {        
      this.setState({activeTransactionPaymentKey : e.target.value});
      this.getSaleListData(this.state.activeTransactionKey,e.target.value,this.state.page,this.state.activePartyKey);
  }
  
  changePartyFilter = (e) => {        
    this.setState({activePartyKey : e.target.value});
    this.getSaleListData(this.state.activeTransactionKey,this.state.activeTransactionPaymentKey,this.state.page,e.target.value);
  }
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <h5># Sale Report</h5>
                <div className="block">
                  <div className="block-content" style={{ marginTop: "-20px" }}>
                    <div className="row items-push">
                      <div className="row col-lg-12">
                        <div className="col-lg-10">
                          <span>Filter : </span>
                          <DateRangePicker
                            initialSettings={{
                              startDate: this.state.startDate,
                              endDate: this.state.endDate,
                              locale: {
                                format: "MMMM D, YYYY",
                              },
                              ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, "days").toDate(),
                                  moment().subtract(1, "days").toDate(),
                                ],
                                "This Week": [
                                  moment()
                                    .startOf("week")
                                    .format("MMM DD, YYYY"),
                                  moment().endOf("week").format("MMM DD, YYYY"),
                                ],
                                "This Month": [
                                  moment().startOf("month").toDate(),
                                  moment().endOf("month").toDate(),
                                ],
                                "Last Month": [
                                  moment()
                                    .subtract(1, "month")
                                    .startOf("month")
                                    .toDate(),
                                  moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Quarter": [
                                  moment().startOf("month").toDate(),
                                  moment()
                                    .add(2, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Financial Year": [
                                  moment()
                                    .month("April")
                                    .startOf("month")
                                    .format("MMM DD, YYYY"),
                                  moment()
                                    .add(1, "year")
                                    .month("March")
                                    .endOf("month")
                                    .format("MMM DD, YYYY"),
                                ],
                              },
                            }}
                            onCallback={this.handleCallBackDate}
                          >
                            <input
                              type="text"
                              className="form-control col-4"
                              style={{ display: "inline-block" }}
                            />
                          </DateRangePicker>
                          <select onChange={this.changeTypeFilter} className="form-control col-2" style={{display: 'inline-block',marginLeft: '4px'}}>
                              <option value="ALL">All Transaction</option>
                              <option value="SALE">Sale</option>
                              <option value="CREDIT NOTE">Credit Note</option>
                          </select>
                          <select onChange={this.changeTypePayStausFilter} className="form-control col-2" style={{display: 'inline-block',marginLeft: '4px'}}>
                              <option value="ALL">All Status</option>
                              <option value="Paid">Paid</option>
                              <option value="Partial">Partial</option>
                              <option value="Unpaid">Unpaid</option>
                          </select>
                          <select onChange={this.changePartyFilter} className="form-control col-3" style={{display: 'inline-block',marginLeft: '4px'}}>
                              <option value="ALL">All Party</option>
                              {this.state.partyData.map((item, index) => {
                                  let _sel = "";
                                  if (this.state.activePartyKey == item.party_id) {
                                  _sel = "selected";
                                  }
                                  return (
                                  <option
                                      key={index}
                                      value={item.party_id}
                                      selected={_sel}
                                  >
                                      {item.party_name}
                                  </option>
                                  );
                              })}
                          </select>
                          
                        </div>
                        <div className="col-lg-2">
                          <div className="row pb-20 pull-right">
                            <div className="text-right">
                              <div className="js-appear-enabled text-center">
                                <Link onClick={this.exportData} >
                                  <div className="text-info">
                                    <i className="fa fa-file-excel-o"></i>
                                  </div>
                                  <div className="font-size-sm text-muted">
                                        Excel Export
                                  </div>
                                </Link>
                              </div>
                            </div>
                            {/* <div className="col-6 text-right border-r">
                                <div className="js-appear-enabled text-center">
                                    <div className="text-info"><i className="fa fa-file-excel-o"></i></div>
                                    <div className="font-size-sm text-muted">Excel Export</div>
                                </div>
                            </div> */}
                            {/* <div className="col-6">
                                <div className="js-appear-enabled text-center">
                                    <div className="text-success"><i className="fa fa-print"></i></div>
                                    <div className="font-size-sm text-muted">Print</div>
                                </div>
                            </div> */}
                          </div>
                        </div>
                        <br />
                      </div>
                      <div className="col-lg-12">
                        <div className="blockAreaList">
                          <div className="py-10 text-left" id="paidArea">
                            <div className="text-muted">Paid</div>
                            <div className="font-size-h5 font-w600">
                              {numberFormat(this.state.paidAmount)}
                            </div>
                          </div>
                        </div>
                        <div className="blockAreaListIcon">
                          <i className="fa fa-plus"></i>
                        </div>
                        <div className="blockAreaList">
                          <div className="py-10 text-left" id="unpaidArea">
                            <div className="text-muted">Unpaid</div>
                            <div className="font-size-h5 font-w600">
                              {numberFormat(this.state.unpaidAmount)}
                            </div>
                          </div>
                        </div>
                        {this.state.DueDatesPaymentTerms == 1 &&
                          <div className="blockAreaListIcon"><i className="fa fa-plus"></i></div>
                        }                        
                        {this.state.DueDatesPaymentTerms == 1 &&
                          <div className="blockAreaList">
                              <div className="py-10 text-left" id="unpaidArea">
                                  <div className="text-muted">Overdue</div>
                                  <div className="font-size-h5 font-w600">{numberFormat(this.state.overDueAmt)}</div>
                              </div>
                          </div>
                        }
                        <div className="blockAreaListIcon">
                          <i className="fas fa-equals"></i>
                        </div>
                        <div className="blockAreaList">
                          <div className="py-10 text-left" id="totalArea">
                            <div className="text-muted">Total</div>
                            <div className="font-size-h5 font-w600">
                              {numberFormat(this.state.total)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block" style={{ marginTop: "-60px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content">
                    <h2 className="block-title ml-10">Transactions</h2>
                    <Link to={"/business/add-sale/"} style={{marginTop: '35px'}} className="pull-right pull-right btn btn-sm btn-primary mb-10">
                        <i className="fa fa-plus-circle"></i> Add Sale
                    </Link>
                  </div>
                  <div className="block-content" id="tableData">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTranscListing()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
