import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};

let _loader = 'glb-ldr-prt active';

export default class QuickItemTableCustomizationPanel extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,         
            modalIsOpen_Info : false,     
            modalIsOpen_TermsCond : false,
            businessId : _businessId,
            company_id : _companyId,
            itemTblCusData : [],
            _infoData : '',
            si_no : (_businessSetting.si_no) ? _businessSetting.si_no : '#',
            si_enable : (_businessSetting.si_enable > 0) ? true : false,    
            item_name : (_businessSetting.item_name) ? _businessSetting.item_name : 'Item Name',
            item_code : (_businessSetting.item_code) ? _businessSetting.item_code : 'Item code',
            item_code_enabled : (_businessSetting.item_code_enabled > 0) ? true : false,   
            hsn_sac	: (_businessSetting.hsn_sac) ? _businessSetting.hsn_sac : 'HSN/ SAC',
            hsn_sac_enable : (_businessSetting.hsn_sac_enable > 0) ? true : false,  
            serial_tracking_enabled : (_businessSetting.serial_tracking_enabled > 0) ? true : false,
            batch_tracking_enable : (_businessSetting.batch_tracking_enable > 0) ? true : false, 
            quantity : (_businessSetting.quantity) ? _businessSetting.quantity : 'Quantity',
            quantity_enable : (_businessSetting.quantity_enable > 0) ? true : false,  
            unit : (_businessSetting.unit) ? _businessSetting.unit : 'Unit',
            unit_enabled : (_businessSetting.unit_enabled > 0) ? true : false,  
            price_per_unit	: (_businessSetting.price_per_unit) ? _businessSetting.price_per_unit : 'Price per unit',
            price_per_unit_enabled : (_businessSetting.price_per_unit_enabled > 0) ? true : false,  
            discount : (_businessSetting.discount) ? _businessSetting.discount : 'Discount',
            discount_enable : (_businessSetting.discount_enable > 0) ? true : false,
            discount_percentage_enable : (_businessSetting.discount_percentage_enable > 0) ? true : false,
            
            taxable_price_per_unit : (_businessSetting.taxable_price_per_unit) ? _businessSetting.taxable_price_per_unit : 'Taxable price/ unit',
            taxable_price_per_unit_enabled : (_businessSetting.taxable_price_per_unit_enabled > 0) ? true : false,
            tax_amount_enable : (_businessSetting.tax_amount_enable > 0) ? true : false,
            tax_percentage_enable : (_businessSetting.tax_percentage_enable > 0) ? true : false, 
            tax_percentage_amount : (_businessSetting.tax_percentage_amount) ? _businessSetting.tax_percentage_amount : 'Taxable Amount',
            addditional_cess : (_businessSetting.addditional_cess) ? _businessSetting.addditional_cess : 'Ad. CESS',
            total_amount : (_businessSetting.total_amount) ? _businessSetting.total_amount : 'Total Amount',
            total_amount_enable : (_businessSetting.total_amount_enable > 0) ? true : false, 
        }
                
    }

    componentDidMount()
    {  
        this.getItemTableCustomization();
    }
    
    getItemTableCustomization(){
        let _transArray = [];
        const requestData = JSON.stringify({
            businessId:this.state.businessId,
            company_id:this.state.company_id
        });
        PostData(global.businessItemTableCustomizationList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                }
                this.setState({isLoaded:true,itemTblCusData:responseJson.response});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        console.log(_key+"--demo---",this.state.itemTblCusData);
        //return false;
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        
        if(_key == 'si_enable'){
            this.setState({si_enable:_value}); 
        } 
        if(_key == 'item_code_enabled'){
            this.setState({item_code_enabled:_value}); 
        } 
        if(_key == 'hsn_sac_enable'){
            this.setState({hsn_sac_enable:_value}); 
        } 
        if(_key == 'serial_tracking_enabled'){
            this.setState({serial_tracking_enabled:_value}); 
        } 
        if(_key == 'batch_tracking_enable'){
            this.setState({batch_tracking_enable:_value}); 
        }    
        if(_key == 'quantity_enable'){
            this.setState({quantity_enable:_value}); 
        }  
        if(_key == 'unit_enabled'){
            this.setState({unit_enabled:_value}); 
        }  
        if(_key == 'price_per_unit_enabled'){
            this.setState({price_per_unit_enabled:_value}); 
        }  
        if(_key == 'discount_enable'){
            this.setState({discount_enable:_value}); 
        }  
        if(_key == 'taxable_price_per_unit_enabled'){
            this.setState({taxable_price_per_unit_enabled:_value}); 
        }  
        if(_key == 'total_amount_enable'){
            this.setState({total_amount_enable:_value,total_amount:this.state.total_amount}); 
        }       
 
        PostData(global.businessSettingItemTblCustUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                if(_key == 'item_code_enabled'){
                    _businessSettingData['item_code'] = _businessSetting.item_code;
                } 
                if(_key == 'hsn_sac_enable'){
                    _businessSettingData['hsn_sac'] = _businessSetting.hsn_sac;
                }   
                if(_key == 'quantity_enable'){ 
                    _businessSettingData['quantity'] = _businessSetting.quantity;
                }  
                if(_key == 'unit_enabled'){
                    _businessSettingData['unit'] = _businessSetting.unit;
                }  
                if(_key == 'price_per_unit_enabled'){
                    _businessSettingData['price_per_unit'] = _businessSetting.price_per_unit;
                }  
                if(_key == 'discount_enable'){
                    _businessSettingData['discount'] = _businessSetting.discount;
                }  
                if(_key == 'taxable_price_per_unit_enabled'){
                    _businessSettingData['taxable_price_per_unit'] = _businessSetting.taxable_price_per_unit;
                }  
                if(_key == 'total_amount_enable'){
                    _businessSettingData['total_amount'] = _businessSetting.total_amount;
                } 
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'si_no'){
            this.setState({si_no:activeValue}); 
        }    
        if(_keyVal == 'item_name'){
            this.setState({item_name:activeValue}); 
        }      
        if(_keyVal == 'hsn_sac'){
            this.setState({hsn_sac:activeValue}); 
        }     
        if(_keyVal == 'item_code'){
            this.setState({item_code:activeValue}); 
        }     
        if(_keyVal == 'hsn_sac'){
            this.setState({hsn_sac:activeValue}); 
        }       
        if(_keyVal == 'quantity'){
            this.setState({quantity:activeValue}); 
        }     
        if(_keyVal == 'price_per_unit'){
            this.setState({price_per_unit:activeValue}); 
        }     
        if(_keyVal == 'discount'){
            this.setState({discount:activeValue}); 
        }     
        if(_keyVal == 'taxable_price_per_unit'){
            this.setState({taxable_price_per_unit:activeValue}); 
        }     
        if(_keyVal == 'tax_percentage_amount'){
            this.setState({tax_percentage_amount:activeValue}); 
        }     
        if(_keyVal == 'addditional_cess'){
            this.setState({addditional_cess:activeValue}); 
        }     
        if(_keyVal == 'total_amount'){
            this.setState({total_amount:activeValue}); 
        }  
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingItemTblCustUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }   
    
    /* Selectbox Chnage */
    onFilterSelChange = (e) => {
        let activeValue = e.target.value;
        let _key = e.target.attributes['data-slug'].value;
        
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        this.setState({selectedOption:activeValue});

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession); 
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* End Selectbox Chnage */

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        
        let _disabled1 = 'disabled';
        let _disabled2 = 'disabled';
        let _disabled3 = 'disabled';
        let _disabled4 = 'disabled';
        let _disabled5 = 'disabled';
        let _disabled6 = 'disabled';
        let _disabled7 = 'disabled';
        let _disabled8 = 'disabled';
        let _disabled9 = 'disabled';
        if(this.state.si_enable == 1 || this.state.si_enable == true){
            _disabled1 = '';
        }
        if(this.state.item_code_enabled == 1 || this.state.item_code_enabled == true){
            _disabled2 = '';
        }
        if(this.state.hsn_sac_enable == 1 || this.state.hsn_sac_enable == true){
            _disabled3 = '';
        }
        if(this.state.quantity_enable	 == 1 || this.state.quantity_enable	 == true){
            _disabled4 = '';
        }
        if(this.state.unit_enabled == 1 || this.state.unit_enabled == true){
            _disabled5 = '';
        }
        if(this.state.price_per_unit_enabled == 1 || this.state.price_per_unit_enabled == true){
            _disabled6 = '';
        }
        if(this.state.discount_enable == 1 || this.state.discount_enable == true){
            _disabled7 = '';
        }
        if(this.state.taxable_price_per_unit_enabled == 1 || this.state.taxable_price_per_unit_enabled == true){
            _disabled8 = '';
        }
        if(this.state.total_amount_enable == 1 || this.state.total_amount_enable == true){
            _disabled9 = '';
        }
        
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-12">
                        <h5 className="content-heading">Items Related Column</h5>
                        <ul>                            
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>SI Number</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Transport Name" data-slug="si_no" id="si_no" name="si_no" value ={this.state.si_no} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled1} />
                                </span>
                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.si_enable > 0) ? true : false} id="si_enable" name="si_enable" value="1" data-slug="si_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                           
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Item Name</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Item Name" data-slug="item_name" id="item_name" name="item_name" value ={this.state.item_name} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}  />
                                </span>                                
                            </li>                        
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Item code</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Transport Name" data-slug="item_code" id="item_code" name="item_code" value ={this.state.item_code} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled2} />
                                </span>
                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.item_code_enabled > 0) ? true : false} id="item_code_enabled" name="item_code_enabled" value="1" data-slug="item_code_enabled" onChange={this.onFilterChange}/>
                                </span>
                            </li>                     
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>HSN / SAC</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="hsn/sac" data-slug="hsn_sac" id="hsn_sac" name="hsn_sac" value ={this.state.hsn_sac} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled3} />
                                </span>
                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.hsn_sac_enable > 0) ? true : false} id="hsn_sac_enable" name="hsn_sac_enable" value="1" data-slug="hsn_sac_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                     
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Serial No Tracking</label>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.serial_tracking_enabled > 0) ? true : false} id="serial_tracking_enabled" name="serial_tracking_enabled" value="1" data-slug="serial_tracking_enabled" onChange={this.onFilterChange}/>
                                </span>
                            </li>                      
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Batch No Tracking</label>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.batch_tracking_enable > 0) ? true : false} id="batch_tracking_enable" name="batch_tracking_enable" value="1" data-slug="batch_tracking_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>   

                        </ul>
                        
                        <h5 className="content-heading">Amount, Total & Tax</h5>
                        <ul>                                               
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Quantity</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="quantity" data-slug="quantity" id="quantity" name="quantity" value ={this.state.quantity} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled4} />
                                </span>
                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.quantity_enable > 0) ? true : false} id="quantity_enable" name="quantity_enable" value="1" data-slug="quantity_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                                          
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Unit</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="unit" data-slug="unit" id="quantity" name="unit" value ={this.state.unit} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled5} />
                                </span>
                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.unit_enabled > 0) ? true : false} id="unit_enabled" name="unit_enabled" value="1" data-slug="unit_enabled" onChange={this.onFilterChange}/>
                                </span>
                            </li>                                          
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Price per unit</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="price per unit" data-slug="price_per_unit" id="price_per_unit" name="price_per_unit" value ={this.state.price_per_unit} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled6} />
                                </span>
                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.price_per_unit_enabled > 0) ? true : false} id="price_per_unit_enabled" name="price_per_unit_enabled" value="1" data-slug="price_per_unit_enabled" onChange={this.onFilterChange}/>
                                </span>
                            </li>                                             
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Discount</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="discount" data-slug="discount" id="discount" name="discount" value ={this.state.discount} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled7} />
                                </span>                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.discount_enable > 0) ? true : false} id="discount_enable" name="discount_enable" value="1" data-slug="discount_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                   
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Discount Percentage</label>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.discount_percentage_enable > 0) ? true : false} id="discount_percentage_enable" name="discount_percentage_enable" value="1" data-slug="discount_percentage_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                                              
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Taxable price per unit</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Taxable price per unit" data-slug="taxable_price_per_unit" id="taxable_price_per_unit" name="taxable_price_per_unit" value ={this.state.taxable_price_per_unit} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled8} />
                                </span>                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.taxable_price_per_unit_enabled > 0) ? true : false} id="taxable_price_per_unit_enabled" name="taxable_price_per_unit_enabled" value="1" data-slug="taxable_price_per_unit_enabled" onChange={this.onFilterChange}/>
                                </span>
                            </li>                    
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Tax Amount</label>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.tax_amount_enable > 0) ? true : false} id="tax_amount_enable" name="tax_amount_enable" value="1" data-slug="tax_amount_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                    
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Tax Percentage</label>
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.tax_percentage_enable > 0) ? true : false} id="tax_percentage_enable" name="tax_percentage_enable" value="1" data-slug="tax_percentage_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                                              
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Taxable Amount</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Taxable price per unit" data-slug="tax_percentage_amount" id="tax_percentage_amount" name="tax_percentage_amount" value ={this.state.tax_percentage_amount} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}  />
                                </span>
                            </li>                                             
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Additional CESS</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Ad. CESS" data-slug="addditional_cess" id="addditional_cess" name="addditional_cess" value ={this.state.addditional_cess} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}  />
                                </span>
                            </li>                                           
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'25%'}}>Total Amount</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Total amount" data-slug="total_amount" id="total_amount" name="total_amount" value ={this.state.total_amount} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} disabled={_disabled9} />
                                </span>                                                              
                                <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                &nbsp;&nbsp;<input type="checkbox" defaultChecked={(this.state.total_amount_enable > 0) ? true : false} id="total_amount_enable" name="total_amount_enable" value="1" data-slug="total_amount_enable" onChange={this.onFilterChange}/>
                                </span>
                            </li>                            
                        </ul>                       
                        
                        
                        {/* <h5 className="content-heading">Others</h5>
                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <label style={{width:'125%'}}>Expenses</label> 
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} >
                                    <input type="text" placeholder="Expenses" data-slug="ExpensesTransName" id="ExpensesTransName" name="ExpensesTransName" value ={this.state.ExpensesTransName} onChange={this.handleLTxtKeyUp} style={{width : '60%',color:'#575757', 'background-color':'#fff',border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}}/>
                                </span>                                
                            </li>                          
                        </ul>      */}
                    </div>                    
                </div> 
            </React.Fragment>
        )
    }
} 