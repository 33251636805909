import React, { Component } from 'react';
//import {PostData} from '../service/postData';
import '../App.css';
import { Route, Switch } from 'react-router'
import routes from '../routes/routes';
//import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

//----Include Fragment Pages-----
import MenuSection from '../fragment_folder/menus';
import TopHeaderPage from '../fragment_folder/topHeader';
import FooterPage from '../fragment_folder/footer';
//----End Include Fragment Pages-----

import _admin_DashboardPanel from '../views_folder/administratorController/dashboard';
import _admin_profilePanel from '../views_folder/administratorController/profile/profile';

import _admin_countryPanel from '../views_folder/administratorController/country/manageCountry';
import _admin_gstTypePanel from '../views_folder/administratorController/masters/gstType';
import _admin_gstListPanel from '../views_folder/administratorController/masters/gst';

import _admin_unitListPanel from '../views_folder/administratorController/masters/unit';
import _admin_businessTypeListPanel from '../views_folder/administratorController/masters/businessType';
import _admin_businesscategoryListPanel from '../views_folder/administratorController/masters/businesscategory';

import _admin_BusinessListPanel from '../views_folder/administratorController/business/businessList';
import _admin_BusinessAddPanel from '../views_folder/administratorController/business/businessAdd';

import _admin_CompanyListPanel from '../views_folder/administratorController/company/companyList';
import _admin_CompanyAddPanel from '../views_folder/administratorController/company/companyAdd';

import _admin_BusinessUserListPanel from '../views_folder/administratorController/businessUser/manageBusinessUser';

import _admin_BlogPanel from '../views_folder/administratorController/blog/blogList';
import _admin_BlogAddPanel from '../views_folder/administratorController/blog/blogAdd';
import _admin_BlogCategoryPanel from '../views_folder/administratorController/blog/blogCategory';

import _admin_WebsitePlanPanel from '../views_folder/administratorController/websitePlan/websitePlan';
import _admin_WebsitePlanPricePanel from '../views_folder/administratorController/websitePlan/websitePlanPrice';

import _admin_BusinessSMSSetting from '../views_folder/administratorController/smsSetting/smsSettingList';
import _admin_BusinessPendingSMS from '../views_folder/administratorController/smsSetting/pendingSmsList';

export default class PrivatePanel extends Component{
  render(){
    return(
        <div id="page-wrapper">
            <div id="page-container" className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed">
              
            <MenuSection pageId={global.BACK_URL} />
            <TopHeaderPage />
            <main id="main-container">
                <div>
                    <Switch>
                        <Route exact={true} path={routes._admin_DashboardPanel} component={_admin_DashboardPanel} />

                        <Route exact={true} path={routes._admin_profilePanel} component={_admin_profilePanel} />
                        <Route exact={true} path={routes._admin_countryPanel} component={_admin_countryPanel} />
                        <Route exact={true} path={routes._admin_gstTypePanel} component={_admin_gstTypePanel} />
                        <Route exact={true} path={routes._admin_gstListPanel} component={_admin_gstListPanel} />
                        <Route exact={true} path={routes._admin_unitListPanel} component={_admin_unitListPanel} />
                        <Route exact={true} path={routes._admin_businessTypeListPanel} component={_admin_businessTypeListPanel} />
                        <Route exact={true} path={routes._admin_businesscategoryListPanel} component={_admin_businesscategoryListPanel} />

                        <Route exact={true} path={routes._admin_BusinessListPanel} component={_admin_BusinessListPanel} />
                        <Route exact={true} path={routes._admin_BusinessAddPanel} component={_admin_BusinessAddPanel} />
                        
                        <Route exact={true} path={routes._admin_CompanyListPanel} component={_admin_CompanyListPanel} />
                        <Route exact={true} path={routes._admin_CompanyAddPanel} component={_admin_CompanyAddPanel} />

                        <Route exact={true} path={routes._admin_BusinessUserListPanel} component={_admin_BusinessUserListPanel} />

                        <Route exact={true} path={routes._admin_BlogPanel} component={_admin_BlogPanel} />
                        <Route exact={true} path={routes._admin_BlogAddPanel} component={_admin_BlogAddPanel} />
                        <Route exact={true} path={routes._admin_BlogCategoryPanel} component={_admin_BlogCategoryPanel} />

                        <Route exact={true} path={routes._admin_WebsitePlanPanel} component={_admin_WebsitePlanPanel} />
                        <Route exact={true} path={routes._admin_WebsitePlanPricePanel} component={_admin_WebsitePlanPricePanel} />
                        
                        <Route exact={true} path={routes._admin_BusinessSMSSetting} component={_admin_BusinessSMSSetting} />
                        <Route exact={true} path={routes._admin_BusinessPendingSMS} component={_admin_BusinessPendingSMS} />
                    </Switch>
                </div>
            </main>
            <FooterPage />
            </div>
        </div>
    )
  }
}