import React,{Component} from 'react';

export default class homePagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    render(){
        return(
            <div id="page-wrapper">
                <React.Fragment>
                <a class="demo" data-toggle="modal" data-target="#myModalB">Request A Demo</a>
                <div className="row">
                    <div className="col-lg-9">
                        {/* ---Menu Start Here--- */}
                        <nav className="navbar navbar-expand-lg navbar-light">    
                            <a className="navbar-brand" href={global.webUrl}>
                                <img width="200" height="50" src={global.webUrl+"websiteFilesFolder/img/logo.png"} alt="" title="" />
                            </a>
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href={global.webUrl}>Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={global.webUrl+"about-us/"}>About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={global.webUrl+"pricing/"}>Our Pricing Plans</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={global.webUrl+"blogs/"}>Blogs & News</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={global.webUrl+"partner-with-us/"}>Partner With Us</a>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        More
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <ul style={{width: 200}} className="futer">
                                            <li><a href={global.webUrl+"features/"}>Features</a></li>
                                            <li><a href={global.webUrl+"faq/"}>FAQ</a></li>
                                            <li><a href={global.webUrl+"privacy-policy/"}>Privacy Policy</a></li>
                                            <li><a href={global.webUrl+"contact/"}>Contact Support</a></li>
                                            <li><a href={global.webUrl+"demo-videos/"}>Demo Videos</a></li>
                                            <li><a href={global.webUrl+"terms-condition/"}>Terms & Conditions</a></li>
                                        </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        {/* ---End Menu Start Here--- */}
                    </div>
                    <div className="col-lg-3">
                        <div className="top_right">
                            <ul>
                                <li>
                                    <a href={global.webUrl+"login/"}>Log In</a>
                                </li>
                                <li>
                                    <a href={global.webUrl+"apk/vyavsay.apk"} target='_blank'>Download</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="livechat">
                        <a href="https://tawk.to/chat/5d240b0822d70e36c2a4caab/default" target='_blank'><img src={global.webUrl+"img/chat.png"}/>
                        </a>
                    </div>
                    <div className="dcnpl_callnow"> 
                        <a href="tel:+919755299999" onclick="ga('send', 'event', 'Call Us', 'Click to Call Button', 'Click to Call Button');"><img src={global.webUrl+"img/contact-calling.png"} alt="contact calling" title="contact calling"/>
                        </a> 
                    </div>
                    <a href="https://api.whatsapp.com/send?phone=919755299999&amp;text=I%20am%20interested%20in%20Digitizing%20my%20business%20through%20Vyavsay%20App%20please%20send%20the%20Details"><div className="chat-icon1" i-amphtml-fixedid="F3"><img src={global.webUrl+"img/contact-whatsapp.png"} alt="contact whatsapp" title="contact whatsapp" /></div>
                    </a>
                </div>  
                </React.Fragment>
            </div>
        )
    }
}