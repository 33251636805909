import React, { Component } from "react";
import { PostData } from "../../../../service/postData";
import { sendNotification } from "../../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import ButtonPdf from "../../htmlPdfCreation";

let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

let _loader = "glb-ldr-prt active";
let _currentYear = moment().format("YYYY");
let _startDateYaer = "01/01/" + _currentYear;
let _endDateYaer = "12/31/" + _currentYear;

export default class dayBookReportPanel extends Component {
  constructor(props) {
    super(props);
    
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _companyId = _companySession.company_id;
    }
    this.state = {
      isLoaded: false,
      transactionArray: [],
      transactionArrayFilter: [],
      activeTransactionKey: "ALL",
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      name: null,

      paidAmount: 0,
      unpaidAmount: 0,
      total: 0,

      //startDate: moment(_startDateYaer).format("MMMM D, YYYY"),
      //endDate: moment(_endDateYaer).format("MMMM D, YYYY"),
      startDate : moment().startOf('month').toDate(),
      endDate : moment().endOf('month').toDate(),
      dateLabel: "",
      totalGrossPrfitAmount: 0,
      totalNetProfitAmount: 0,
      totalSaleAmount: 0,
      totalCreditNoteAmount: 0,
      totalPurchaseAmount: 0,
      totalDebitNoteAmount: 0,
      totalPaymentOutDiscount: 0,
      otherDirectExpense: 0,
      otherInDirectExpense : 0,
      totalPaymentInDiscount: 0,
      totalOpeningStock: 0,
      totalClosingStock: 0,
      totalOtherIncomeProfit: 0,
      otherIncomeIndirect: 0,
      loanInterestExpenseIndirect: 0,
      loanProcessingFeeExpenseIndirect: 0,
      loanChargesExpenseIndirect: 0,
      errors: {
        name: "",
      },
    };
  }

  async componentDidMount() {
    this.getSaleListData(this.state.activeTransactionKey);
  }

  getSaleListData(_type) {
    let _transArray = [];
    let _paidAmt = 0;
    let _unpaidAmt = 0;
    let _totalAmt = 0;

    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      type: _type,
      isDateFilter: true,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    });

    PostData(global.userProfitLossTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
          });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
            
            this.setState({
              transactionArrayFilter: _transArray,
              exportDataArray: _transArray,
              isLoaded: true,
              paidAmount: _paidAmt,
              unpaidAmount: _unpaidAmt,
              total: _totalAmt,
              //countList: _transArray.countList,
              //totalTransAmt: _transArray.totalTransAmt,
             // totalBalanceAmt: _transArray.totalBalanceAmt,
              totalGrossPrfitAmount: _transArray.totalGrossPrfitAmount,
              totalNetProfitAmount: _transArray.totalNetProfitAmount,
              totalSaleAmount: _transArray.totalSaleAmount,
              totalCreditNoteAmount: _transArray.totalCreditNoteAmount,
              totalPurchaseAmount: _transArray.totalPurchaseAmount,
              totalDebitNoteAmount: _transArray.totalDebitNoteAmount,
              totalPaymentOutDiscount: _transArray.totalPaymentOutDiscount,
              otherDirectExpense: _transArray.otherDirectExpense,
              otherInDirectExpense : _transArray.otherInDirectExpense,
              totalPaymentInDiscount: _transArray.totalPaymentInDiscount,
              totalOpeningStock: _transArray.totalOpeningStock,
              totalClosingStock: _transArray.totalClosingStock,
              totalOtherIncomeProfit: _transArray.totalOtherIncomeProfit,
              otherIncomeIndirect: _transArray.otherIncomeIndirect,
              loanInterestExpenseIndirect: _transArray.loanInterestExpenseIndirect,
              loanProcessingFeeExpenseIndirect: _transArray.loanProcessingFeeExpenseIndirect,
              loanChargesExpenseIndirect: _transArray.loanChargesExpenseIndirect,
            });
          }
        }
      })
      .catch((error) => this.setState({ error,isLoaded: true, }));
  }

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getSaleListData();
  };

  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getSaleListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-primary",
        },
      ],
    });
  }
  
  exportData = (event) =>{
    try {

      let _itemDetails = [
        { 'Particulars': 'Sale (+)', '': '', 'Amount': this.state.totalSaleAmount},
        { 'Particulars': 'Credit Note (-)', '': '', 'Amount': this.state.totalCreditNoteAmount},
        { 'Particulars': 'Purchase (-)', '': '', 'Amount': this.state.totalPurchaseAmount},
        { 'Particulars': 'Debit Note (+)', '': '', 'Amount': this.state.totalDebitNoteAmount},
        { 'Particulars': 'Payment Out Discount Amount', '': '', 'Amount': this.state.totalPaymentOutDiscount},
        { 'Particulars': 'Direct Expenses', '': '', 'Amount': ''},
        { 'Particulars': 'Other Direct Expenses(-)', '': '', 'Amount': this.state.otherDirectExpense},
        { 'Particulars': 'Payment In Discount Amount', '': '', 'Amount': this.state.totalPaymentInDiscount},
        { 'Particulars': 'Opening Stock (-)', '': '', 'Amount': this.state.totalOpeningStock},
        { 'Particulars': 'Closing Stock (+)', '': '', 'Amount': this.state.totalClosingStock},
        { 'Particulars': 'Gross Profit', '': '', 'Amount': this.state.totalGrossPrfitAmount},
        { 'Particulars': 'Other Income (+)', '': '', 'Amount': this.state.totalOtherIncomeProfit},
        { 'Particulars': 'Indirect Expenses (-)', '': '', 'Amount': ''}, 
        { 'Particulars': 'Other Indirect Expense', '': '', 'Amount': this.state.otherInDirectExpense},
        { 'Particulars': 'Loan Interest Expense', '': '', 'Amount': this.state.loanInterestExpenseIndirect},
        { 'Particulars': 'Loan Processing Fee Expense', '': '', 'Amount': this.state.loanProcessingFeeExpenseIndirect},
        { 'Particulars': 'Loan Charges Expense', '': '', 'Amount': this.state.loanChargesExpenseIndirect},
        { 'Particulars': 'Net Profit', '': '', 'Amount': this.state.totalNetProfitAmount},
      ];

      let Heading1 = [['Profit and Loss Report']];
      let Heading2 = [[moment(this.state.startDate).format("MM/DD/YYYY")+' to '+moment(this.state.endDate).format("MM/DD/YYYY")]];

      var wb = XLSX.utils.book_new();
      const ws =  XLSX.utils.json_to_sheet([]);
      var wscols = [
        {wch:25},
        {wch:25},
        {wch:15},
      ];

      ws['!cols'] = wscols;
      XLSX.utils.sheet_add_json(ws, _itemDetails, { origin: 'A5'});

      XLSX.utils.sheet_add_aoa(ws, Heading1, { origin: 'B2' });
      XLSX.utils.sheet_add_aoa(ws, Heading2, { origin: 'B3' });
      XLSX.utils.book_append_sheet(wb,ws,"Profit and Loss Report");

      const wbout = XLSX.write(wb, {type:'array', bookType:"xlsx"});
      let length = 6;
      let uniqueNo = String(Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)));
      let fileName = 'profit_and_loss_report_'+moment().format('DD/MM/YYYY')+'.xlsx';
      const data = new Blob([wbout], {type: "xlsx"});
      FileSaver.saveAs(data, fileName);

    } catch (err) {
      //alert('Unknown Error: ' + JSON.stringify(err));
      throw err;
    }
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content" style={{ marginTop: "-20px" }}>
                    <div className="row items-push">
                      <div className="row col-lg-12">
                        <div className="col-lg-10">
                          <span>Filter : </span>
                          <DateRangePicker
                            initialSettings={{
                              startDate: this.state.startDate,
                              endDate: this.state.endDate,
                              locale: {
                                format: "MMMM D, YYYY",
                              },
                              ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                  moment().subtract(1, "days").toDate(),
                                  moment().subtract(1, "days").toDate(),
                                ],
                                "This Week": [
                                  moment()
                                    .startOf("week")
                                    .format("MMM DD, YYYY"),
                                  moment().endOf("week").format("MMM DD, YYYY"),
                                ],
                                "This Month": [
                                  moment().startOf("month").toDate(),
                                  moment().endOf("month").toDate(),
                                ],
                                "Last Month": [
                                  moment()
                                    .subtract(1, "month")
                                    .startOf("month")
                                    .toDate(),
                                  moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Quarter": [
                                  moment().startOf("month").toDate(),
                                  moment()
                                    .add(2, "month")
                                    .endOf("month")
                                    .toDate(),
                                ],
                                "This Financial Year": [
                                  moment()
                                    .month("April")
                                    .startOf("month")
                                    .format("MMM DD, YYYY"),
                                  moment()
                                    .add(1, "year")
                                    .month("March")
                                    .endOf("month")
                                    .format("MMM DD, YYYY"),
                                ],
                              },
                            }}
                            onCallback={this.handleCallBackDate}
                          >
                            <input
                              type="text"
                              className="form-control col-7"
                              style={{ display: "inline-block" }}
                            />
                          </DateRangePicker>
                        </div>
                        
                        <div className="col-lg-2">
                           <div className="row pb-20 pull-right">
                              <div className="text-right">
                                  <div className="js-appear-enabled text-center">
                                    <Link onClick={this.exportData} >
                                        <div className="text-info">
                                            <i className="fa fa-file-excel-o"></i>
                                        </div>
                                        <div className="font-size-sm text-muted">
                                                Excel Export
                                        </div>
                                    </Link>
                                </div>
                              </div>
                           </div>
                        </div>   
                       <br />  
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block" style={{ marginTop: "-60px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="block">                  
                  <div className="block-content">     
                    <h5><b>PROFIT AND LOSS REPORT</b></h5>
                    {this.state.isLoaded == true &&
                     <div className="block-content" id="tableData">
                        <div className="form-group row" style={{border : '2px solid',borderRadius : '10px',padding : '0 0 8px 0', color : '#101010'}}>
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Particulars</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0, left : 'auto'}}>Amount</label>                              
                            </div>
                          </div> 
                        </div> 

                        <div className="form-group row">                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Sales (+)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username"  style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalSaleAmount)}</span></label>                               
                            </div>
                          </div>                            
                        </div> 

                        <div className="form-group row" style={{backgroundColor : '#cacaca', lineHeight : '10px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Cr. Note / Sale Return (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.totalCreditNoteAmount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#cacaca', lineHeight : '10px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Purchase (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.totalPurchaseAmount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row">                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Dr. Note / Purchase Return (+)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalDebitNoteAmount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#cacaca', lineHeight : '10px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Payment Out Discount (+)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalPaymentOutDiscount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#cacaca', lineHeight : '10px'}}>                        
                          <div className="col-12">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Direct Expense (-)</label>                              
                            </div>
                          </div>                                                      
                        </div>
                        
                        
                        <div className="form-group row" style={{backgroundColor : '#eee', lineHeight : '10px', margin : '0 0 5px 5px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Other Direct Expense (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.otherDirectExpense)}</span></label>                              
                            </div>
                          </div>                            
                        </div>
                        
                        <div className="form-group row" style={{backgroundColor : '#eee', lineHeight : '10px', margin : '0 0 5px 5px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Payment In Discount (+)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalPaymentInDiscount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row">                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Opening Stock (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.totalOpeningStock)}</span></label>                              
                            </div>
                          </div>                            
                        </div>
                        
                        <div className="form-group row">                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Closing Stock (+)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalClosingStock)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#eaeaea',lineHeight : '10px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username"><strong>Gross Profit</strong></label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalGrossPrfitAmount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#eee', lineHeight : '10px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Other Income Profit (+)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalOtherIncomeProfit)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#eee', lineHeight : '10px'}}>                        
                          <div className="col-12">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Indirect Expense (-)</label>                              
                            </div>
                          </div>                                                      
                        </div>

                        <div className="form-group row" style={{backgroundColor : '#cacaca', lineHeight : '10px', margin : '0 0 5px 5px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Other Expenses(-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.otherInDirectExpense)}</span></label>                              
                            </div>
                          </div>                            
                        </div>
                        
                        
                        
                        <div className="form-group row"  style={{backgroundColor : '#eee', lineHeight : '10px', margin : '0 0 5px 5px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Loan Interest Expense (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.loanInterestExpenseIndirect)}</span></label>                              
                            </div>
                          </div>                            
                        </div>
                        
                        <div className="form-group row"  style={{backgroundColor : '#eee', lineHeight : '10px', margin : '0 0 5px 5px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Loan Processing Fee Expense (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.loanProcessingFeeExpenseIndirect)}</span></label>                              
                            </div>
                          </div>                            
                        </div>
                        
                        <div className="form-group row"  style={{backgroundColor : '#eee', lineHeight : '10px', margin : '0 0 5px 5px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username">Charge On Loan Expense (-)</label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-danger">{numberFormat(this.state.loanChargesExpenseIndirect)}</span></label>                              
                            </div>
                          </div>                            
                        </div>

                        

                        <div className="form-group row" style={{backgroundColor : '#eaeaea',lineHeight : '10px'}}>                        
                          <div className="col-8">
                            <div className="form-material open">                              
                              <label htmlFor="login-username"><strong>Net Profit</strong></label>                              
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-material open">                              
                              <label htmlFor="login-username" style={{right : 0 , left : 'auto'}}><span className="badge badge-success">{numberFormat(this.state.totalNetProfitAmount)}</span></label>                              
                            </div>
                          </div>                            
                        </div>
                      
                      </div>
                      }
                    </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
