import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewItem from "../userItem/quickSaleItemAdd";
import QuickAddTransSerialNo from "./quickAddTransSerialNo";
import QuickAddTransSerialNoFreeQty from "./quickAddTransSerialNoFreeQty";
import QuickAddNewSaleSerialNo from "./quickAddNewSerialNo";
import QuickAddNewFreeSalePurchaseSerialNo from "./quickAddNewFreeSerialNo";
import QuickAddNewSaleBatchNo from "./quickAddSaleNewBatchNo";
import QuickAddNormalQuantity from "./quickNormalQuantitySaleAdd";
import QuickAddNormalQuantitySaleFree from "./quickNormalQuantitySaleAddFree";
import QuickAddTransBatchNo from "./quickAddTransBatchNo";
import validator from "validator";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { confirmAlert } from "react-confirm-alert";

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _businessSetting = {};

export default class quickTransactionSaleItem extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting; 

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;
    }

    this.state = {
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: this.props.fields ? this.props.fields : [],
      party_id: this.props.party_id ? this.props.party_id : 0,
      duplicateSale : this.props.duplicateSale ? this.props.duplicateSale : '',
      party_name: "",
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      lowStokItemName: "",
      /*Start Srerial Batch Popup Data End*/

      itemSerialNo: [],
      itemBatchNo: [],
      itemAddSerialNo: [],
      itemAddBatchNo: [],

      modalIsOpen_SerialNo: false,
      modalIsOpen_SerialNoFreeItem: false,
      modalIsOpen_NotSerialNo: false,
      modalIsOpen_NotSerialNoFree: false,
      modalIsOpen_BatchNo: false,

      modalIsOpen_BatchViewPopup: false,
      modalIsOpen_SerialBatchNo: false,
      modalIsOpen_SerialBatchNoOld: false,
      modalIsOpen_AddNewSerialNo: false,
      modalIsOpen_AddNewFreeSerialNo: false,
      modalIsOpen_AddNewBatchNo: false,
      usedFreeNormalQty: 0,
      batchNo: "",
      OldBatTotalQty: 0,
      selectTypeTxt: "Normal",
      popupVal: [],
      selectType: 0,
      batchItemEnable: 0,
      serialItemEnable: 0,
      btnShowSerialNormalBatch: 0,
      popuptype: "",
      isFree: 0,
      newItemId: 0,
      normal_remaining_quantity: 0,
      batchSendType: "New",
      freeQtyAk: 0,
      /*End Srerial Batch  Popup Data End*/
      used_stockAK: 0,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      handleAddDisable: "",
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: this.props.transId ? this.props.transId : 0,
      totalAmountOld: 0,
      itemRemovePosition: 0,
      sendActualQty: 0,
      totalAmountOldFree: 0,
      batchOnlyData: 0,
      createBType: "Old",
      itemTypeOption: "",
      handleItemSalePurchasePrice: "",
      sendPageType :  this.props.sendPageType ? this.props.sendPageType : '',
      totalAmountNew: 0,
      errors: {
        party_id: 0,
        party_name: "",
      },
    };
  }
  _handleClearItem = (event) => {
    this.setState({
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      party_name: "",
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      lowStokItemName: "",
      /*Start Srerial Batch Popup Data End*/

      itemSerialNo: [],
      itemBatchNo: [],
      itemAddSerialNo: [],
      itemAddBatchNo: [],

      modalIsOpen_SerialNo: false,
      modalIsOpen_SerialNoFreeItem: false,
      modalIsOpen_NotSerialNo: false,
      modalIsOpen_NotSerialNoFree: false,
      modalIsOpen_BatchNo: false,

      modalIsOpen_BatchViewPopup: false,
      modalIsOpen_SerialBatchNo: false,
      modalIsOpen_SerialBatchNoOld: false,
      modalIsOpen_AddNewSerialNo: false,
      modalIsOpen_AddNewFreeSerialNo: false,
      modalIsOpen_AddNewBatchNo: false,
      usedFreeNormalQty: 0,
      batchNo: "",
      OldBatTotalQty: 0,
      selectTypeTxt: "Normal",
      popupVal: [],
      selectType: 0,
      batchItemEnable: 0,
      serialItemEnable: 0,
      btnShowSerialNormalBatch: 0,
      popuptype: "",
      isFree: 0,
      newItemId: 0,
      normal_remaining_quantity: 0,
      batchSendType: "New",
      freeQtyAk: 0,
      /*End Srerial Batch  Popup Data End*/

      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      handleAddDisable: "",
      handleItemSalePurchasePrice: "",
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      totalAmountNew: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: this.props.transId ? this.props.transId : 0,
      totalAmountOld: 0,
      itemRemovePosition: 0,
      sendActualQty: 0,
      totalAmountOldFree: 0,
    });
    this.getItemListData();
  };
  componentWillUnmount() {
    document.body.removeEventListener("clearData", this._handleClearItem);
  }

  async componentDidMount() {
    document.body.addEventListener("clearData", this._handleClearItem);
    this.getItemListData();
    /*For master Units*/
    let _urlDataUnits =
      global.userUnitList +
      "?businessId=" +
      _businessId +
      "&company_id=" +
      this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    /*For master Units*/

    this.setState({ unitArray: _unitArray, isLoaded: true });

    /*Set Setting Data */
    let _prefixSetArray = [];
    let _SalePrefixName = "";
    let _SalePrefixID = "";
    const requestData = JSON.stringify({
      businessId: _businessId,
    });
    PostData(global.businessPrefixSetData, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _prefixSetArray = responseJson.response;
            _SalePrefixName = _prefixSetArray[0].SalePrefixName;
            _SalePrefixID = _prefixSetArray[0].SalePrefixId;
          }
          this.setState({
            prefixName: _SalePrefixName,
            prefixId: _SalePrefixID,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
    /*Set Setting Data */

    let _transId = this.props.transId;
    if (_transId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: _transId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/sale-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
            //this.setState({unitArray:_unitArray,isLoaded:true});
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let itemsData = _transactionArray.items;
              let transAmout = _transactionArray.totalSummary;
              let _batchItemEnable = 0;
              let _serialItemEnable = 0;
              let _btnShowSerialNormalBatch = 0;
              let _usedFreeNormalQty = 0;
              itemsData.map((_itemsBSData, index) => {
                if (
                  _itemsBSData.itemBatchNoAllData &&
                  _itemsBSData.itemBatchNoAllData.length > 0
                ) {
                  _batchItemEnable = 1;
                  _btnShowSerialNormalBatch = 2;
                }
                if (
                  _itemsBSData.itemSerialNoAllData &&
                  _itemsBSData.itemSerialNoAllData.length > 0
                ) {
                  _serialItemEnable = 1;
                  _btnShowSerialNormalBatch = 1;
                }
                if (
                  itemsData.length == 1 &&
                  _itemsBSData.itemBatchNoAllData.length == 0 &&
                  _itemsBSData.itemSerialNoAllData.length == 0
                ) {
                  _usedFreeNormalQty = _itemsBSData.free_quantity;
                }
              });

              this.setState({
                fields: itemsData,
                isLoaded: true,
                EnableItem: _transactionArray.EnableItem,
                //FreeItemQuantity :_transactionArray.FreeItemQuantity,
                totalAmountOld: transAmout.totalAmount,
                totalAmountNew: transAmout.totalAmount,
                receivedAmount: transAmout.receivedAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,
                batchItemEnable: _batchItemEnable,
                serialItemEnable: _serialItemEnable,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                DisplayPurchasePrice: _transactionArray.DisplayPurchasePrice,
                //FreeItemQuantity :_transactionArray.FreeItemQuantity,
                //TransCount :_transactionArray.Count,
                settingType: _transactionArray.settingType,
                totalAmount: transAmout.totalAmount,
                paidAmount: transAmout.paidAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.dueAmount,
                btnShowSerialNormalBatch: _btnShowSerialNormalBatch,
                usedFreeNormalQty: _usedFreeNormalQty,
                handleAddDisable: "",
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  getItemListData() {
    let _itemsArray = [];

    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      partyId: this.state.party_id,
      for_item_type : 'SALE'
    });
    let _url_GetData =
    /*global.userItemSalePurList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {*/
    PostData(global.userItemSalePurPartyList, requestData, "POST").then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
          }
          console.log('itemArray', JSON.stringify(_itemsArray))
          //this.setState({itemArray:_itemsArray,isLoaded:true});
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }



  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: true });
    } else if (e == "modalAddSerialNo") {
      this.setState({ modalIsOpen_SerialNo: true });
    } else if (e == "modalAddNotSerialNo") {
      this.setState({ modalIsOpen_NotSerialNo: true });
    } else if (e == "modalAddNotSerialNoFree") {
      this.setState({ modalIsOpen_NotSerialNoFree: true });
    } else if (e == "modalAddBatchNo") {
      this.setState({ modalIsOpen_BatchNo: true });
    } else if (e == "modalAddNewSalePurSerialNo") {
      this.setState({ modalIsOpen_AddNewSerialNo: true });
    } else if (e == "modalAddNewSalePurFreeSerialNo") {
      this.setState({ modalIsOpen_AddNewFreeSerialNo: true });
    } else if (e == "modalAddNewBatchNo") {
      this.setState({ modalIsOpen_AddNewBatchNo: true });
    }
  }

  closeModalHandler = (e) => {
    let _totalAmtFree = this.state.totalAmountOldFree;
    if (e == "modalAdjustNewItem") {
      this.setState({
        modalIsOpen_SITEM: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddSerialNo") {
      this.setState({
        modalIsOpen_SerialNo: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddNotSerialNo") {
      this.setState({
        modalIsOpen_NotSerialNo: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddNotSerialNoFree") {
      this.setState({
        modalIsOpen_NotSerialNoFree: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddBatchNo") {
      this.setState({
        modalIsOpen_BatchNo: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddBatchViewPop") {
      this.setState({
        modalIsOpen_BatchViewPopup: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddSerialNoFreeItem") {
      this.setState({
        modalIsOpen_SerialNoFreeItem: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalShowSerialBatchNo") {
      this.setState({ modalIsOpen_SerialBatchNo: false });
    } else if (e == "modalAddNewSalePurSerialNo") {
      this.setState({
        modalIsOpen_AddNewSerialNo: false,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddNewSalePurFreeSerialNo") {
      this.setState({
        modalIsOpen_AddNewFreeSerialNo: false,
        modalIsOpen_SerialNoFreeItem: false,
        totalAmount: _totalAmtFree,
        totalAmountOld: _totalAmtFree,
        totalAmountOldFree: _totalAmtFree,
        modalIsOpen_SerialBatchNo: false,
      });
    } else if (e == "modalAddNewBatchNo") {
      this.setState({
        modalIsOpen_AddNewBatchNo: false,
        modalIsOpen_SerialBatchNo: false,
      });
    }
  };
  /*End Modal open close*/

  closeModalHandlerMainP = (e, pos, itemId) => {
    if (e == "modalShowSerialBatchNo") {
      this.setState({ modalIsOpen_SerialBatchNo: false });
      this.handleRemove(pos, itemId);
    }
  };
  /*End Modal open close*/

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  handleAdd() {
    let errors = this.state.errors;
    this.state.popupShow = 0;

    if (this.state.party_id == 0) {
      let _isError = true;
      errors["party_name"] = "*Please Select Party Name";
      this.setState({ errors: errors });
    } else {
      if (this.state.handleAddDisable.length > 2) {
        alert("Before adding more items, select the item first  in the row.");
        return false;
      } else if (this.state.handleItemSalePurchasePrice.length > 2) {
        alert("Item price can not be zero");
        return false;
      } else {
        const values = this.state.fields;
        values.push([]);
        this.setState({
          fields: values,
          handleAddDisable: "Disabled",
          handleItemSalePurchasePrice: "",
          itemArray: this.state.itemArray,
          itemArrayFilter: this.state.itemArray,
        });

        let _totalAmt = this.state.totalAmount;
        let _dueAmount = this.state.dueAmount;

        let _paidAM = this.state.receivedAmount;
        let _totalAmountNewSend = this.state.totalAmountNew;
        let _totalAmountOldSend = this.state.totalAmountOld;
        let _totalDiscount = this.state.totalDiscount;
        let _totalGstAmt = this.state.gstAmount;
        let _totalQuantity = this.state.totalQuantity;
        let handleAddDisable = "Disabled";
        let handleItemSalePurchasePrice = "";
        let _fieldData = this.state.fields;
        let _transactionWiseTaxId = 0;
        let _totalTransTaxAmount = 0;
        let _totalTransDiscountPer = 0;
        let _totalTransDiscountAmount = 0;

        this.props.callBackItem(
          _fieldData,
          _totalAmountOldSend,
          _totalAmountNewSend,
          _totalAmt,
          _dueAmount,
          _paidAM,
          _totalDiscount,
          _totalGstAmt,
          _totalQuantity,
          handleAddDisable,
          handleItemSalePurchasePrice,
          _transactionWiseTaxId,
          _totalTransTaxAmount,
          _totalTransDiscountPer,
          _totalTransDiscountAmount
        );
      }
    }
  }

  handleRemove(i, itemId) {
    const values = this.state.fields;
    let finalQty = 0;
    let _normal_quantity = values[i].normal_quantity ? values[i].normal_quantity : 0;
    let _normal_remaining_quantity = values[i].normal_remaining_quantity ? values[i].normal_remaining_quantity : _normal_quantity;
    if(values[i].normal_remaining_quantity == 0){
      _normal_remaining_quantity = 0;
    }
    const newFields = values.filter((itmRow, index) => {
      return index!=i;
    });
    let data = this.state.itemArray;
    console.log("==data==",data);
    let _itemBatchNoFinal = [];
    let _itemSerialNoFinal = [];

    if (_normal_quantity > 0 || _normal_quantity!="") {
      if (this.state.transactionId > 0) {
        finalQty = parseInt(_normal_quantity);
      } else {
        finalQty = parseInt(_normal_remaining_quantity) + parseInt(_normal_quantity);
      }
    }
    let removeSrId = [];
    this.setState({ handleAddDisable: "" });
    if (values[i].itemSerialNoAllData!=undefined) {
      let myRemoveSrData = values[i].itemSerialNoAllData ? values[i].itemSerialNoAllData : [];
      if(myRemoveSrData.length!=undefined){
        myRemoveSrData.map((valSrData, i) => {
          removeSrId.push(valSrData.serial_id);
        });
      }
    }
    finalQty = parseInt(finalQty) - parseInt(_normal_quantity);
    data.map((item, index1) => {
      if (item.item_id == itemId) {
        if (finalQty == 0) {
          if (this.state.transactionId > 0) {
            item.normal_quantity = finalQty;
            item.normal_remaining_quantity = item.normal_remaining_quantity;
          } else {
            if (finalQty == 0) {
              item.normal_quantity = item.normal_remaining_quantity;
              item.normal_remaining_quantity = item.normal_remaining_quantity;
            } else {
              item.normal_quantity = finalQty;
              item.normal_remaining_quantity = _normal_quantity;
            }
          }
        } else {
          if (this.state.transactionId > 0) {
            item.normal_quantity = finalQty;
            item.normal_remaining_quantity = parseFloat(item.normal_remaining_quantity) + parseFloat(_normal_quantity);
          } else {
            if (item.normal_remaining_quantity > finalQty) {
              _normal_remaining_quantity = item.normal_remaining_quantity;
            }
            item.normal_quantity = finalQty;
            item.normal_remaining_quantity = parseFloat(_normal_remaining_quantity); 
          } 
        }
        item.itemSerialNoAllData = [];
        item.itemBatchNoAllData = [];
        if (item.itemSerialNo && item.itemSerialNo!="" &&  newFields!="" && newFields.length > 0)
        {
          _itemSerialNoFinal = item.itemSerialNo.map((srData) => {
            removeSrId.map((rmvSrId, i) => {
              if (rmvSrId == srData.serial_id) {
                srData.isSelected = false;
                srData.isFreeQuantity = false;
                srData.dataCheck = 1;
              }
            });
            return srData;
          });

          _itemBatchNoFinal = item.itemBatchNo.map((batchData) => {
            return {
              ...batchData,
              used_stock: 0,
              free_quantity: 0,
            };
          });
        }
        else {
          const values = [];
          values.push([]);
          this.setState({
            fields: values,
            normal_remaining_quantity: _normal_remaining_quantity,
            normal_quantityAdded: finalQty,
          });
          data.map((item, index1) => {
            if (item.item_id == itemId) {
              _itemSerialNoFinal = item.itemSerialNo.map((srData) => {
                return {
                  ...srData,
                  isSelected: false,
                  isFreeQuantity: false,
                  dataCheck: values[i].itemSerialNoAllData ? 0 : 1,
                };
              });

              _itemBatchNoFinal = item.itemBatchNo.map((batchData) => {
                return {
                  ...batchData,
                  used_stock: 0,
                  free_quantity: 0,
                };
              });
            }
          });
        }
        item.itemSerialNo = _itemSerialNoFinal;
        item.itemBatchNo = _itemBatchNoFinal;

        data[index1].isFilter = false;
      }
    });
    /*Low Stock Data */
    /*if(this.state.lowStokItemName.length > 0){
          let _lowStokItemName = this.state.lowStokItemName;
          let _stockItemName = '';
          let _objLowStock = _lowStokItemName.filter((data) => data.item_name!=_stockItemName);
          this.setState({lowStokItemName:_objLowStock});
      }*/
    /*End Low Stock Data */

    const fieldArray = newFields;
    let _quantity = 0;
    let _totalAmt = 0;
    let _receivedAmount = this.state.receivedAmount;
    let _dueAmount = 0;
    let _totalDiscountAmt = 0;
    let _totalGstAmt = 0;
    let _discountAmtAk_1 = 0;
    let _discountTaxAk_1 = 0;
    let _amountDisK_1 = 0;
    let _amountTaxK_1 = 0;

    fieldArray.map((field, index) => {
      let _itemId = field.item_id ? field.item_id : 0;
      if (_itemId > 0) {
        let _obj = {};
        _obj.item_id = field.item_id ? field.item_id : 0;
        _obj.item_name = field.item_name ? field.item_name : "";
        _obj.quantity = field.quantity ? field.quantity : 0;
        _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
        _obj.rate = field.rate ? field.rate : 0;
        _obj.discount_type =  field.discount_type ? field.discount_type : '';
        _obj.discount_in_per = field.discount_in_amount ? field.discount_in_amount : 0;
        _obj.discount_in_amount = field.discount_in_per ? field.discount_in_per : 0;
        _obj.tax_value = field.tax_value ? field.tax_value : 0;
       
        /*Add by AK */
        _obj.subtotal = field.subtotal ? field.subtotal : 0;
        _obj.total_amount = field.total_amount ? field.total_amount : 0;

        _obj.normal_quantity = finalQty ? finalQty : 0;
        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);
        /*Add by AK */

        _receivedAmount = this.state.receivedAmount;
        _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.receivedAmount);
        _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);

        if (this.state.FreeItemQuantity == 1) {
          _quantity = parseFloat(_quantity) + parseFloat(_obj.free_quantity);
        }
        _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(field.discount_in_amount);
        _totalGstAmt = parseFloat(_totalGstAmt) + parseFloat(field.tax_value);

        _discountAmtAk_1 = field.discount_in_amount ? field.discount_in_amount : 0;
        _discountTaxAk_1 = field.tax_value ? field.tax_value : 0;
        _amountDisK_1 = parseFloat(_amountDisK_1) + parseFloat(_discountAmtAk_1);
        if (_businessSetting.ItemWiseTax == 1) {
          _amountTaxK_1 = parseFloat(_amountTaxK_1) + parseFloat(_discountTaxAk_1);
        }
      }
    });

    if (_dueAmount > 0) {
      _dueAmount = _dueAmount.toFixed(2);
    }
    if (_totalDiscountAmt > 0) {
      _totalDiscountAmt = _totalDiscountAmt.toFixed(2);
    }
    if (_totalGstAmt > 0) {
      _totalGstAmt = _totalGstAmt.toFixed(2);
    }

    //_totalAmt =  parseFloat(_totalAmt) + parseFloat(_amountTaxK_1);
    let _usedFreeNormalQtyAK = this.state.usedFreeNormalQty;
    _usedFreeNormalQtyAK = parseFloat(_usedFreeNormalQtyAK) - parseFloat(this.state.usedFreeNormalQty);

    /*Final Calculation Discount and Tax */
    let _amountDisK = 0;
    let _amountTaxK = 0;
    let _discountTaxAk = 0;


    _totalAmt = parseFloat(_totalAmt);// + parseFloat(_amountTaxK) - parseFloat(_amountDisK);
    /*Final Calculation Discount and Tax */

    let _handleItemSalePurchasePriceAK = this.state.handleItemSalePurchasePrice;
    
    let _batchItemEnableAK = this.state.batchItemEnable;
    let _serialItemEnableAK = this.state.serialItemEnable;
    if(this.state.batchItemEnable == 1 && fieldArray.length == 0){
      _batchItemEnableAK = 0;
    }
    if(this.state.serialItemEnable == 1 && fieldArray.length == 0){
      _serialItemEnableAK = 0;
    }
    if (this.state.isMakePayment == false) {
      if (this.state.toggleState == true) {
        this.setState({
          fields: fieldArray,
          totalQuantity: _quantity,
          dueAmount: 0,
          receivedAmount: 0,
          totalAmount: _totalAmt,
          totalAmountOld: _totalAmt,
          totalAmountNew: _totalAmt,
          usedFreeNormalQty: _usedFreeNormalQtyAK,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
        ,batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK}); //,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
      } else {
        this.setState({
          fields: fieldArray,
          totalQuantity: _quantity,
          dueAmount: _dueAmount,
          totalAmount: _totalAmt,
          totalAmountOld: _totalAmt,
          totalAmountNew: _totalAmt,
          usedFreeNormalQty: _usedFreeNormalQtyAK,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
          ,batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK}); //,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
      }

      /*Final Calculation Discount and Tax */
      /*let _amountDisK = 0;
        let _amountTaxK = 0;

        fieldArray.map((field, idx) => {
            let _discountAmtAk = (field.discount_in_amount) ? field.discount_in_amount : 0;
            let _discountTaxAk = (field.tax_value) ? field.tax_value : 0;
            _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);
            if(_businessSetting.ItemWiseTax == 1){
                _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
            }
            
            this.setState({totalDiscount:_amountDisK.toFixed(2),gstAmount:_amountTaxK.toFixed(2)});
        });*/
      /*End Calculation Discount and Tax */

      this.setState({ itemArrayFilter: data });
    } 
    else {
      if (fieldArray.length > 0) {
        if (this.state.toggleState == true) {
          this.setState({
            fields: fieldArray,
            totalQuantity: _quantity,
            dueAmount: 0,
            receivedAmount: 0,
            totalAmount: _totalAmt,
            totalAmountOld: _totalAmt,
            totalAmountNew: _totalAmt,
            usedFreeNormalQty: _usedFreeNormalQtyAK,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt,
            subtotal: _totalAmt,batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
          }); //,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
        } 
        else {
          this.setState({
            fields: fieldArray,
            totalQuantity: _quantity,
            dueAmount: _dueAmount,
            totalAmount: _totalAmt,
            totalAmountOld: _totalAmt,
            totalAmountNew: _totalAmt,
            usedFreeNormalQty: _usedFreeNormalQtyAK,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt,
            subtotal: _totalAmt,batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
          }); //,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
        }
        this.setState({ fields: fieldArray, itemArrayFilter: data });

        /*Final Calculation Discount and Tax */
        /*let _amountDisK = 0;
          let _amountTaxK = 0;

          fieldArray.map((field, idx) => {
              let _discountAmtAk = (field.discount_in_amount) ? field.discount_in_amount : 0;
              let _discountTaxAk = (field.tax_value) ? field.tax_value : 0;
              _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);
              if(_businessSetting.ItemWiseTax == 1){
                  _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
              }
              
              this.setState({totalDiscount:_amountDisK.toFixed(2),gstAmount:_amountTaxK.toFixed(2)});
          });*/
        let _totalAmtCAK = _totalAmt;
        let _dueAmountCAK = _totalAmt;
        let _paidAM = 0;
        let _totalAmountNewSend = _totalAmt;
        let _totalAmountOldSend = _totalAmt;
        let _totalDiscount = _totalDiscountAmt;//this.state.totalDiscount;
        let _totalGstAmtCAK = _totalGstAmt;//this.state.gstAmount;
        let _totalQuantity = _quantity; //this.state.totalQuantity;
        let handleAddDisable = "Disabled";
        let _transactionWiseTaxId = 0;
        let _totalTransTaxAmount = 0;
        let _totalTransDiscountPer = 0;
        let _totalTransDiscountAmount = 0;
        if (fieldArray.length >= 1) {
          handleAddDisable = "";
        }
        let _fieldData = fieldArray;
        if(_fieldData.length == 0 || _fieldData.length == 1){
          _handleItemSalePurchasePriceAK = "";
        }
        this.props.callBackItem(
          _fieldData,
          _totalAmountOldSend,
          _totalAmountNewSend,
          _totalAmtCAK,
          _dueAmountCAK,
          _paidAM,
          _totalDiscount,
          _totalGstAmtCAK,
          _totalQuantity,
          handleAddDisable,
          _handleItemSalePurchasePriceAK,
          _transactionWiseTaxId,
          _totalTransTaxAmount,
          _totalTransDiscountPer,
          _totalTransDiscountAmount
        );
      }
       else {
        this.setState({
          totalAmount: 0,
          fields: fieldArray,
          itemArrayFilter: data,
          totalQuantity: 0,
          totalAmountOld: 0,
          totalAmountNew: 0,
          transactionWiseTaxId: 0,
          totalTransTaxAmount: 0,
          totalTransDiscountPer: 0,
          totalTransDiscountAmount: 0,
          receivedAmount: 0,
          gstAmount: 0,
          totalDiscount: 0,
          usedFreeNormalQty: _usedFreeNormalQtyAK,
          OldChnageQuantity : 0,
          subtotal: 0,batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
        });

        let _totalAmtCAK = 0;
        let _dueAmountCAK = 0;
        let _paidAM = 0;
        let _totalAmountNewSend = 0;
        let _totalAmountOldSend = 0;
        let _totalDiscount = 0;
        let _totalGstAmtCAK = 0;
        let _totalQuantity = 0;
        let handleAddDisable = "Disabled";
        let _fieldData = fieldArray;
        let _transactionWiseTaxId = 0;
        let _totalTransTaxAmount = 0;
        let _totalTransDiscountPer = 0;
        let _totalTransDiscountAmount = 0;

        this.props.callBackItem(
          _fieldData,
          _totalAmountOldSend,
          _totalAmountNewSend,
          _totalAmtCAK,
          _dueAmountCAK,
          _paidAM,
          _totalDiscount,
          _totalGstAmtCAK,
          _totalQuantity,
          handleAddDisable,
          _handleItemSalePurchasePriceAK,
          _transactionWiseTaxId,
          _totalTransTaxAmount,
          _totalTransDiscountPer,
          _totalTransDiscountAmount
        );
      }
    }
    this.setState({fields:newFields,itemArrayFilter:data});

    this.getReadyItemList();
  }

  serialPopChnage(
    e,
    batchSendType,
    position,
    OldChnageQuantity,
    OldUsedStockQty,
    batchNo
  ) {
    if (e == "modalAddSerialNo") {
      this.setState({
        modalIsOpen_SerialNo: true,
        modalIsOpen_BatchViewPopup: false,
      });
    }
    if (e == "modalAddBatchNo") {
      let arrayData = this.state.popupVal;
      let _element = arrayData.filter((data) => data.batch_no == batchNo);
      if (this.state.createBType == "New") {
        //modalIsOpen_AddNewBatchNo
        this.setState({
          modalIsOpen_BatchNo: true,
          modalIsOpen_BatchViewPopup: false,
          batchSendType: batchSendType,
          itemPosition: position,
          OldChnageQuantity: OldChnageQuantity,
          OldUsedStockQty: OldUsedStockQty,
          batchNo: batchNo,
          itemBatchNo: _element,
          createBType: this.state.createBType,
        });
      } else {
        this.setState({
          modalIsOpen_BatchNo: true,
          modalIsOpen_BatchViewPopup: false,
          batchSendType: batchSendType,
          itemPosition: position,
          OldChnageQuantity: OldChnageQuantity,
          OldUsedStockQty: OldUsedStockQty,
          batchNo: batchNo,
          itemBatchNo: _element,
        });
      }

      //this.setState({modalIsOpen_BatchNo: true,modalIsOpen_BatchViewPopup:false, itemId:i.id, name:i.name, itemPrice:_itemPrice,itemPosition:_itemPosition,itemBatchNo :_batchSendData,batchItemEnable:1});
    }
  }

  clickSrialBatchD(
    _position,
    event,
    _dataBS,
    _arrayData,
    _itemId,
    _itemName,
    _itemPrice,
    totalBatchQty,
    OldUsedStockQty
  ) {
    let finalPopVal = _dataBS;
    if (event == "Serial") {
      finalPopVal = _dataBS.filter(
        (ele, ind) =>
          ind ===
          _dataBS.findIndex(
            (elem) =>
              elem.serial_id == ele.serial_id &&
              elem.serial_id == ele.serial_id
          )
      );
      let _serialId = finalPopVal[0].serial_id ? finalPopVal[0].serial_id : "";

      if (_serialId == "" || _serialId.length == 0) {
        finalPopVal = _dataBS;
      }
      this.setState({
        modalIsOpen_BatchViewPopup: true,
        popuptype: event,
        popupVal: finalPopVal,
        itemId: _itemId,
        name: _itemName,
        itemPrice: _itemPrice,
        position: _position,
        itemRemovePosition: _position,
      });
    }
     else if (event == "Btach") {
      this.setState({
        modalIsOpen_BatchViewPopup: true,
        popuptype: event,
        popupVal: finalPopVal,
        itemBatchNo: _arrayData,
        itemId: _itemId,
        name: _itemName,
        itemPrice: _itemPrice,
        itemPosition: _position,
        OldChnageQuantity: totalBatchQty,
        OldUsedStockQty: OldUsedStockQty,
        itemRemovePosition: _position,
      });
    }
  }

  removeSelectedSrData(srId, _itemId, _delType, _position) {
    let _serialGetData = this.state.fields;
    let _newQuantity = this.state.totalQuantity;
    let _oldTotalAmountOld = this.state.totalAmountOld;
    let _oldtotalAmt = 0;
    let _fieldAmt = 0;
    let _fieldQty = 0;
    let _popupVal = this.state.popupVal;
    let normalQtySel = _popupVal.filter(
      (popNSelData) => popNSelData.isSelected == true
    );
    let normalFreeQtySel = _popupVal.filter(
      (popNSelFData) => popNSelFData.isFreeQuantity == true
    );
    let normalFreeQtySelLen = normalFreeQtySel ? normalFreeQtySel.length : 0;
    let normalQtySelLeng = normalQtySel ? normalQtySel.length : 0;

    let finalLength = parseFloat(normalQtySelLeng) - parseFloat(normalFreeQtySelLen);
    if (_delType == "N" && this.state.popupVal.length > 1) {
      if (finalLength <= 1) {
        alert(
          "Only free item cannot be transacted, it is necessary to add at least one item which have amount."
        );
        return false;
      }
    }
    if (this.state.popupVal.length == 1) {
      alert(
        "If you do not want any single serial of this item, then remove the item or keep at least one serial"
      );
      return false;
    }
    let _itemSerialNo = [];
    let dataItem = this.state.itemArray;

    dataItem.filter(function (item, index) {
      if (item.item_id == _itemId) {
        if (item.itemSerialNo && item.itemSerialNo!="") {
          _itemSerialNo = item.itemSerialNo;
          _itemSerialNo.filter(function (itemSrD, index) {
            if (itemSrD.serial_no == srId && itemSrD.isFreeQuantity!=true) {
              itemSrD.isSelected = false;
              itemSrD.dataCheck = 1;
            }
            if (itemSrD.serial_no == srId && itemSrD.isFreeQuantity == true) {
              itemSrD.isSelected = false;
              itemSrD.isFreeQuantity = false;
              itemSrD.dataCheck = 1;
            }
            if (itemSrD.serial_no!=srId && itemSrD.isFreeQuantity == true) {
              itemSrD.isSelected = true;
              itemSrD.isFreeQuantity = true;
              itemSrD.dataCheck = 0;
            }
          });
        }
      }
    });

    let _srialDataAk = [];

    let updatedSrData = _serialGetData.map((itemD, index) => {
      let _itemSerialNoAllDataAKT = itemD.itemSerialNoAllData;
      _fieldQty = itemD.quantity - 1;
      _fieldAmt = parseFloat(_fieldQty) * parseFloat(itemD.rate);

      if (_delType == "F" && itemD.item_id == _itemId) {
        if (itemD.free_quantity > 1) {
          if (this.state.itemRemovePosition == index) {
            itemD.free_quantity = itemD.free_quantity - 1;
            if (itemD.availLenAk == 0 || itemD.availLenAk == "0") {
              itemD.availLenAk = 1;
            }
            _newQuantity = _newQuantity; // - 1;
          }
        } else {
          if (this.state.itemRemovePosition == index) {
            itemD.free_quantity = 0;
            if (itemD.availLenAk == 0 || itemD.availLenAk == "0") {
              itemD.availLenAk = 1;
            }
            _newQuantity = _newQuantity;
          }
        }
      } else {
        itemD.availLenAk = finalLength - 1;
      }
      if (this.state.itemRemovePosition == index) {
        if (_delType == "N" && itemD.item_id == _itemId) {
          itemD.itemSerialNoAllData.map((rmvSrId, i) => {
            if (srId == rmvSrId.serial_no) {
              itemD.quantity = itemD.quantity - 1;
              itemD.total_amount = _fieldAmt;
              _newQuantity = _newQuantity - 1;
              _oldtotalAmt = 1 * itemD.rate;
            }
          });

          if (_delType == "N") {
            _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
              ...srData,
              isSelected: srData.serial_no == srId ? false : srData.isSelected,
              dataCheck: srData.serial_no == srId ? 1 : srData.dataCheck,
            }));
          }

          itemD.itemSerialNoAllData = _srialDataAk.filter(
            (serialDataAk) => serialDataAk.serial_no!=srId
          );

          if (this.state.transactionId > 0) {
            itemD.itemSerialNo = _srialDataAk;
          } else {
            itemD.itemSerialNo = _itemSerialNo; //_srialDataAk;
          }
          //itemD.itemSerialNo = _itemSerialNo;//_srialDataAk;
        }
      }

      if (_delType == "F") {
       
        if (this.state.itemRemovePosition == index) {
          _srialDataAk = itemD.itemSerialNoAllData.map((srDataF) => ({
            ...srDataF,
            isSelected: srDataF.serial_no == srId ? false : srDataF.isSelected,
            isFreeQuantity:
              srDataF.serial_no == srId ? false : srDataF.isFreeQuantity,
            dataCheck: srDataF.serial_no == srId ? 1 : srDataF.dataCheck,
          }));

          itemD.itemSerialNoAllData = _srialDataAk.filter(
            (serialDataAk) => serialDataAk.serial_no!=srId
          );
          itemD.itemSerialNo = _itemSerialNo; //_srialDataAk;
        }
      }

      return itemD;
    });
    if (_delType == "F") {
      _newQuantity = _newQuantity - 1;
    }
    _oldTotalAmountOld =
      parseFloat(_oldTotalAmountOld) - parseFloat(_oldtotalAmt);
    
    let _popupData = this.state.popupVal;
    let updatedPopupData = _popupData.filter(
      (serialDataAk) => serialDataAk.serial_no!=srId
    );
    this.setState({
      popupVal: updatedPopupData,
      fields: updatedSrData,
      itemSerialNo: _itemSerialNo,
      subtotal: _oldTotalAmountOld.toFixed(2), // 4300 
      dueAmount: _oldTotalAmountOld.toFixed(2),
      totalAmount: _oldTotalAmountOld.toFixed(2),
      totalAmountOld: _oldTotalAmountOld.toFixed(2),
      totalAmountNew: _oldTotalAmountOld.toFixed(2),
      totalQuantity: _newQuantity,
    });
    this.state.popupVal = updatedPopupData;

    let _totalAmt = _oldTotalAmountOld;
    let _dueAmount = _oldTotalAmountOld;
    let _paidAM = 0;
    let _totalAmountNewSend = _oldTotalAmountOld;
    let _totalAmountOldSend = _oldTotalAmountOld;
    let _totalDiscount = this.state.totalDiscount;
    let _totalGstAmt = this.state.gstAmount;
    let _totalQuantity = _newQuantity;//this.state.totalQuantity;
    let handleAddDisable = "";
    let _fieldData = this.state.fields;
    let _receivedAmount = 0;

    let _transactionWiseTaxId = 0;
    let _totalTransTaxAmount = 0;
    let _totalTransDiscountPer = 0;
    let _totalTransDiscountAmount = 0;

    this.props.callBackItem(
      _fieldData,
      _totalAmountOldSend,
      _totalAmountNewSend,
      _totalAmt,
      _dueAmount,
      _paidAM,
      _totalDiscount,
      _totalGstAmt,
      _totalQuantity,
      handleAddDisable,
      this.state.handleItemSalePurchasePrice,
      _transactionWiseTaxId,
      _totalTransTaxAmount,
      _totalTransDiscountPer,
      _totalTransDiscountAmount
    );
  }

  handleSerialPopup(
    selectItemData,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice
  ) {
    let _serialSendData = selectItemData.itemSerialNo;
    this.state.popupShow = 1;
    let _serialGetData = "";
    if (this.state.fields.length > 1) {
      _serialGetData = this.state.fields;
      _serialGetData.map((itemD, index) => {
        if (itemD.itemSerialNoAllData) {
          let _size = Object.keys(itemD.itemSerialNoAllData).length;
          let _secItemD = _size > 2 ? itemD.itemSerialNoAllData.serial_id : 0;
          _serialSendData = _serialSendData.filter(
            (item) => item.serial_id!=_secItemD
          );
        }
      });
    }
    if (this.state.fields.length > 1) {
      let _serialNoData = [];
      let aa = _serialSendData.filter((item) => item.dataCheck == 1);
      if (aa) {
        _serialNoData.push(aa);
      }
      _serialSendData = _serialNoData[0];
    }

    this.setState({
      modalIsOpen_SerialBatchNo: false,
      modalIsOpen_SerialNo: true,
      itemId: itemId,
      name: itemName,
      itemPosition: _itemPosition,
      itemPrice: _itemPrice,
      serialItemEnable: 1,
      popupShow: 1,
      itemSerialNo: _serialSendData,
      selectType: 1,
    });
    //this.handleDynamicInputs(selectItemData,'item','');
  }

  handleNotSerialPopup(
    norSrNo,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice,
    selectTypeTxt,
    _normal_quantity,
    normalQtyField
  ) {
    if (selectTypeTxt == "Free") {
      console.log("==QQQ===22==");
      this.setState({
        modalIsOpen_SerialBatchNo: false,
        modalIsOpen_NotSerialNoFree: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemPrice: _itemPrice,
        norSrNo: norSrNo,
        selectTypeTxt: selectTypeTxt,
        normal_quantity: _normal_quantity,
        serialItemEnable: 0,
      });
    } else {
      let _usedFreeNormalQtyAK = this.state.usedFreeNormalQty;
      let _normal_remaining_quantity = this.state.normal_remaining_quantity;
      if (_normal_remaining_quantity < 0) {
        _normal_remaining_quantity = 0; //Added by 17Nov
      }
      if (
        _itemPosition == this.state.itemPosition &&
        this.state.fields.length > 1
      ) {
        _usedFreeNormalQtyAK = 0;
        //_normal_remaining_quantity = 0;
      }
      let _serialItemEnable = 0;
      if(this.state.serialItemEnable == 1){
        _serialItemEnable = 1;
      }
      console.log("==_serialItemEnable==",_serialItemEnable);
      this.setState({
        modalIsOpen_SerialBatchNo: false,
        modalIsOpen_NotSerialNo: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemPrice: _itemPrice,
        norSrNo: norSrNo,
        selectTypeTxt: selectTypeTxt,
        normal_quantity: _normal_quantity,
        normalQtyField: normalQtyField,
        serialItemEnable: _serialItemEnable,
        usedFreeNormalQty: _usedFreeNormalQtyAK,
        normal_remaining_quantity: _normal_remaining_quantity,
        itemTypeOption: this.state.itemTypeOption,
      });
    }
  }

  handleBatchPopup(
    selectItemData,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice
  ) {
    let _batchSendData = selectItemData.itemBatchNo;
    this.state.popupShow = 2;
    let _BatchGetData = "";
    if (this.state.fields.length > 1) {
      _BatchGetData = this.state.fields;
      _BatchGetData.map((itemD, index) => {
        let _size = itemD.itemBatchNoAllData.length;
        if (
          itemD.itemBatchNoAllData.map((_batchD, index) => {
            let _secItemD = _size >= 1 ? _batchD.batch_id : 0;
            let _secItemStockD = _size > 2 ? _batchD.opening_stock : 0;
            if (itemD.opening_stock > _batchD.createdInStock) {
              //_batchSendData = _batchSendData.filter((item) => item.batch_id!=_secItemD);
            }
            _batchSendData = _batchSendData.filter(
              (item) => item.batch_id!=_secItemD
            );
          })
        );
      });
    }
    _batchSendData = _batchSendData.filter((item) => item.opening_stock > 0);
    this.setState({
      modalIsOpen_SerialBatchNo: false,
      modalIsOpen_BatchNo: true,
      itemId: itemId,
      name: itemName,
      itemPosition: _itemPosition,
      itemPrice: _itemPrice,
      batchItemEnable: 1,
      popupShow: 2,
      itemBatchNo: _batchSendData,
      selectType: 1,
    });
    //this.handleDynamicInputs(selectItemData,'item','');
  }

  handleAddNewSerialPopup(
    selectItemData,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice,
    selectTypeTxt
  ) {
    if (selectTypeTxt == "Free") {
      this.setState({
        modalIsOpen_AddNewFreeSerialNo: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemAddSerialNo: [],
        serialItemEnable: 1,
        itemPrice: _itemPrice,
        selectType: 2,
        selectTypeTxt: selectTypeTxt,
      });
    } else {
      this.setState({
        modalIsOpen_AddNewSerialNo: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemAddSerialNo: [],
        serialItemEnable: 1,
        itemPrice: _itemPrice,
        selectType: 2,
        selectTypeTxt: selectTypeTxt,
      });
    }
  }

  handleAddNewBatchPopup(
    selectItemData,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice
  ) {
    this.setState({
      modalIsOpen_AddNewBatchNo: true,
      itemId: itemId,
      name: itemName,
      itemPosition: _itemPosition,
      itemPrice: _itemPrice,
      batchItemEnable: 1,
      itemAddBatchNo: [],
      batchSendType: "New",
    });
  }

  handleDynamicInputsTransCount(i, type, event) {
    let _itemPosition = i;
    let _itemName,
      _itemPrice,
      _itemId,
      _value = "";
    let _transCount = 0;
    const fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
          _value = event.target.value;
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = { id: "0", name: "", ratio: "0" };
          _obj.trans_count = field.trans_count ? field.trans_count : 0;

          if (type == "trans_count") {
            _transCount = _value;
            if (_transCount == "" || _transCount == "null") {
              _transCount = 0;
            }
            _obj.trans_count = _transCount;
          }
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.discount_type =  field.discount_type ? field.discount_type : '';
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = field.discount_in_per
            ? field.discount_in_per
            : 0;
          _obj.discount_in_amount = field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = field.tax_type ? field.tax_type : _objTaxType;
          _obj.tax_value = field.tax_value ? field.tax_value : 0;
          _obj.subtotal = field.subtotal ? field.subtotal : 0;
          _obj.total_amount = field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData : {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData: {};
          fieldArray[_itemPosition] = _obj;
        }
      });
      this.setState({ fields: fieldArray });
    }
  }

  handleDynamicInputSalePurTaxType(i, type, event) {
    let _itemPosition = i;
    let _itemName,_itemPrice,_itemId, _value = "";
    let _transCount = 0;
    let fieldArray = this.state.fields;    
    let discountAmt = 0;   
    let totalAmount = 0;
    let tax = 0;
    let amountBeforeTaxWithoutDisc = 0;
    let disc = 0;
    let amountBeforeTax = 0 ;
    let subTotal = 0;    
    let _discountAmtSend = 0;
    let _taxAmtSend = 0;
    let _itemToalAmtSend = 0;
    let _costPrice = 0;
    let _oldTotalItemAmt = 0; 
    let _gstObj = {};
    if (fieldArray.length > 0) {     
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
            let qty = field.quantity ? field.quantity : 0;
            let rateValue = parseFloat(field.rate);
            let taxType = parseFloat(field.tax_type.ratio) ? parseFloat(field.tax_type.ratio) : 0.0;
            let additionalCess = parseFloat(field.Additional_CESS) ? parseFloat(field.Additional_CESS) : 0;
            let discount_in_per =  parseFloat(field.discount_in_per) ?  parseFloat(field.discount_in_per) : 0
            let discount_in_amount =  parseFloat(field.discount_in_amount) ?  parseFloat(field.discount_in_amount) : 0;
            subTotal = rateValue * qty;
             _oldTotalItemAmt = field.total_amount ? field.total_amount : 0;
            console.log("==d==",JSON.stringify(field));
            _gstObj.id = field.tax_type.id;
            _gstObj.name = field.tax_type.name;
            _gstObj.ratio = field.tax_type.ratio;
            _value = event.target.value;
            if (_value == 'With Tax' && taxType > 0)
            {                 
              console.log(subTotal+"==yyyyy==",_value);    
              if(field.discount_in_amount > 0){ 
                if(taxType > 0){
                  _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                }else{
                  _costPrice = subTotal;
                }          
                discountAmt = discount_in_amount;// * qty;
                amountBeforeTax = _costPrice - discountAmt;
                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                tax = amountBeforeTax * taxType / 100;
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);

                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;  
              }
              else{
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
                discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                console.log(amountBeforeTaxWithoutDisc+"===disc==",disc) ;
                totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);

                //Discount amont == disc;
                disc = disc.toFixed(2);
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = disc;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
            }

            else if (_value == 'With Tax' && (taxType == 0 || taxType == '0'))
            {      
              console.log(subTotal+"==uuu==",_value);
              if(field.discount_in_amount > 0){     
                if(taxType > 0){
                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                }else{
                    _costPrice = subTotal;
                }                 
                discountAmt = discount_in_amount;// * qty;
                
                amountBeforeTax = _costPrice - discountAmt;
                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                tax = amountBeforeTax * taxType / 100;
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);

                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);                    
                discountAmt = (discount_in_per / 100) * subTotal;
                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + parseFloat(additionalCess);

                //Discount amont == disc;
                disc = disc.toFixed(2);
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = disc;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;               
              }
            }   

            else {
              if(field.discount_in_amount > 0){ 
                console.log(subTotal+"==qqq==",_value);     
                _costPrice = subTotal;           
                discountAmt = discount_in_amount;// * qty;
                amountBeforeTax = subTotal - discountAmt;
                tax = amountBeforeTax * taxType / 100;
                //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTax+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');   
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{    
                console.log(subTotal+"==zz==",_value);              
                _costPrice = subTotal;          
                discountAmt = (discount_in_per / 100) * subTotal;
                amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);         

                console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTaxWithoutDisc+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');  

                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }    
          }
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = _gstObj;//{ id: "0", name: "", ratio: "0" };
          console.log("==_objTaxType==",_objTaxType);
          let discountPer  = (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
          // if(_value == 'Without Tax'){
          //   discountPer = _taxAmtSend;
          // }
          console.log(discountPer+'==>--discountPer-->=='+_costPrice+'==>_costPrice>=='+_discountAmtSend+'==>_discountAmtSend>==');

          _obj.trans_count = field.trans_count ? field.trans_count : 0;
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.Additional_CESS = additionalCess;
          _obj.discount_type =  field.discount_type ? field.discount_type : '';
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = discountPer;
          _obj.discount_in_amount = field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = _objTaxType//'';
          _obj.tax_value = _taxAmtSend;//field.tax_value ? field.tax_value : 0;
          _obj.sale_pruchase_tax_type = _value;
          _obj.subtotal = totalAmount;
          _obj.total_amount = totalAmount;//field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData: {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData : {};
          fieldArray[_itemPosition] = _obj;
        }
        
      console.log(_discountAmtSend+"==>discountAmt 22-->=="+_taxAmtSend+"==>tax-->=="+_itemToalAmtSend+"==>totalAmount-->==");   
      });
      console.log(this.state.totalAmount+'==>>=='+this.state.totalAmountOld+"==>=="+this.state.totalAmountNew+"==>>=="+this.state.totalAmountOldFree+"==>>=="+_oldTotalItemAmt);
      this.setState({ fields: fieldArray });

      let _totalAmountOld = 0;
      if(this.state.fields.length == 1){
        _totalAmountOld = _itemToalAmtSend;
      }
      else{
        //_totalAmountOld = (parseFloat(_itemToalAmtSend)+ parseFloat(this.state.totalAmountOld)) - parseFloat(_oldTotalItemAmt) ;
        this.state.fields.map((data) => {
          _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(data.total_amount);  
        })
      }
      let _totalAmountNew = _totalAmountOld;
      let _paidAM = 0;
      let _dueAmount = _totalAmountOld;

      let _transactionWiseTaxId = 0;
      let _totalTransTaxAmount = 0;
      let _totalTransDiscountPer = 0;
      let _totalTransDiscountAmount = 0;
      let _totalGstAmt = 0;
      let _totalDiscount = 0;
      
      if(this.state.fields.length == 1){
        _totalDiscount = _discountAmtSend;
        _totalGstAmt = _taxAmtSend;
      }
      else{                 
        // _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
        // _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
        fieldArray.map((field, index) => {
          if (index!=_itemPosition) {
          _totalDiscount+= parseFloat(_discountAmtSend) + parseFloat(field.discount_in_amount);
          _totalGstAmt+= parseFloat(_taxAmtSend) + parseFloat(field.tax_value);
          }
        })
      }
      console.log(_taxAmtSend+"==>_taxAmtSend-->=="+_totalGstAmt+"==>_totalGstAmt-->==");   
      this.setState({
        subtotal: _totalAmountOld,
        totalAmountOld: _totalAmountOld,
        gstAmount: _totalGstAmt,
        totalDiscount: _totalDiscount,
      });
      let _totalAmt = _totalAmountOld;

      if(this.state.transactionId > 0){
        this.setState({
          totalAmount: _totalAmountOld,
        });
      }
      this.props.callBackItem(
        fieldArray,
        _totalAmountOld,
        _totalAmountNew,
        _totalAmt,
        _dueAmount,
        _paidAM,
        _totalDiscount,
        _totalGstAmt,
        this.state.totalQuantity,
        this.state.handleAddDisable,
        this.state.handleItemSalePurchasePrice,
        _transactionWiseTaxId,
        _totalTransTaxAmount,
        _totalTransDiscountPer,
        _totalTransDiscountAmount
      );

    }
  }

  onChangeCalculation(i, type, event) {
    console.log(event.target.value+"===>--i-->=="+i+"==>-type-->==="+type);
    let _itemPosition = i;
    let _itemName,_itemPrice,_itemId, _value = "";
    let _transCount = 0;
    let fieldArray = this.state.fields;    
    let discountAmt = 0;   
    let totalAmount = 0;
    let tax = 0;
    let amountBeforeTaxWithoutDisc = 0;
    let disc = 0;
    let amountBeforeTax = 0 ;
    let subTotal = 0;    
    let _subTotalCheck = 0;
    let _discountAmtSend = 0;
    let _taxAmtSend = 0;
    let _itemToalAmtSend = 0;
    let _costPrice = 0;
    let _oldTotalItemAmt = 0;
    let handleItemSalePurchasePrice = this.state.handleItemSalePurchasePrice;
    console.log(fieldArray.length+"===NKKKK==",JSON.stringify(fieldArray));
    
    let _gstObj = {};
    if (fieldArray.length > 0) {     
      fieldArray.map((field, index) => {
        let _itemIdK = field.item_id ? field.item_id : 0;
        if (index == _itemPosition && _itemIdK > 0) {
            _value = event.target.value;       
            let qty = field.quantity ? field.quantity : 0;
            let rateValue = parseFloat(field.rate) ? field.rate : 0;
            let taxType = parseFloat(field.tax_type.ratio) ? parseFloat(field.tax_type.ratio) : 0;
            let additionalCess = parseFloat(field.Additional_CESS) ? parseFloat(field.Additional_CESS) : 0;
            let discount_in_per =  parseFloat(field.discount_in_per) ?  parseFloat(field.discount_in_per) : 0
            let discount_in_amount =  parseFloat(field.discount_in_amount) ?  parseFloat(field.discount_in_amount) : 0;
            if(type == 'rate'){
              rateValue = parseFloat(_value) ? _value : 0;
              handleItemSalePurchasePrice = "";
              discount_in_per = 0;
              discount_in_amount = 0;
            }
            _subTotalCheck = parseFloat(rateValue) * parseFloat(qty);
            console.log("==_subTotalCheck==",_subTotalCheck);
            if(type == 'discount_in_per' && (_value == '' || _value == null)){
              discount_in_per = 0;
              discount_in_amount = 0;
            }
            if(type == 'discount_in_amount' && (_value == '' || _value == null)){
              discount_in_amount = 0;
              discount_in_per = 0;
            }
            if(type == 'Additional_CESS' && (_value == '' || _value == null)){
              additionalCess = 0;
            }
            if(type == 'rate' && (_value == '' || _value == null ||  _value <= 0)){
              rateValue = 0;
            }
            subTotal = parseFloat(rateValue) * parseFloat(qty);


            if(field.tax_type.ratio > 0){              
              _subTotalCheck = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
            }
            
            if(type!='taxType'){
              _gstObj.id = field.tax_type.id;
              _gstObj.name = field.tax_type.name;
              _gstObj.ratio = field.tax_type.ratio;
            }
            if(type == 'rate'){
              rateValue = parseFloat(_value) ? _value : 0;
              handleItemSalePurchasePrice = "";
              discount_in_per = 0;
              discount_in_amount = 0;
            }
            else if(type == 'Additional_CESS'){
              additionalCess = _value;
            }
            else if(type == 'discount_in_per'){
              if(_value > 100){
                alert("Discount cannot be more than 100%");
              }else{
                discount_in_per = _value;
                if(_value == ''){
                  discount_in_per = 0;
                }
              }
            }
            else if(type == 'discount_in_amount'){
              if(parseFloat(_value) > parseFloat(subTotal)) {
                //alert("Discount cannot be more than total item amount");
                discount_in_amount = parseFloat(_value) >0 ? parseFloat(_value) : 0;//field.discount_in_amount;
                discount_in_per = 0;
              }else{
                discount_in_amount = parseFloat(_value) >0 ? parseFloat(_value) : 0;
                discount_in_per = 0;
              }
            }
            else if(type == 'taxType'){
              if(event.target.value > 0){
                this.state.taxRateArray.map((tax, index) => {       
                  if (_value == tax.id) {
                    taxType = tax.ratio ? tax.ratio : 0;
                    _gstObj.id = tax.id;
                    _gstObj.name = tax.name;
                    _gstObj.ratio = tax.ratio;
                  }
                })
              }else{
                taxType =  0;
                _gstObj={};
              }
            }
            
            if(additionalCess == ''){
              additionalCess = 0;
            }
            
            

            _oldTotalItemAmt = field.total_amount ? field.total_amount : 0;

            if(type == 'discount_in_per'){
              discount_in_amount = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
            }
            
            if(type == 'discount_in_amount' && (_value > subTotal)){
              alert("Discount cannot be more than total item amount ");
              _discountAmtSend = this.state.totalDiscount;
              _taxAmtSend = this.state.gstAmount;
              _itemToalAmtSend = this.state.totalAmount;
              return;
            } 
            //else{                
                if (field.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
                {                 

                  if(_value > 0 && type == 'discount_in_amount'){
                    console.log("==ashu--");
                    if(taxType > 0){
                      _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                    }else{
                      _costPrice = subTotal;
                    }
                    if(type == 'rate'){
                      discountAmt = 0;
                      amountBeforeTax = subTotal;
                      amountBeforeTaxWithoutDisc  = subTotal;
                      disc = 0;
                      tax = 0;
                      _gstObj={};
                    }
                    else{
                      discountAmt = parseFloat(discount_in_amount);// * qty;
                      amountBeforeTax = parseFloat(_costPrice) - parseFloat(discountAmt);
                      disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                      tax = parseFloat(amountBeforeTax) * parseFloat(taxType) / 100;
                    }          
                    totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

                    discountAmt = discountAmt.toFixed(2);
                    tax = tax.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);

                    _discountAmtSend = discountAmt;
                    _taxAmtSend = tax;
                    _itemToalAmtSend = totalAmount;  
                  }
                  else{
                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
                    
                    if(type == 'rate'){
                      discountAmt = 0;
                      amountBeforeTax = subTotal;
                      amountBeforeTaxWithoutDisc  = subTotal;
                      disc = 0;
                      tax = 0;
                      _gstObj={};
                    }
                    else{
                      discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                      amountBeforeTaxWithoutDisc = parseFloat(qty) * (parseFloat(rateValue) / (1 + (parseFloat(taxType) / 100)));
                      disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                      tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(parseFloat(taxType)) / 100;
                    }
                    totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);

                    //Discount amont == disc;
                    disc = disc.toFixed(2);
                    discountAmt = disc;//discountAmt.toFixed(2);
                    tax = tax.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);
                    
                    _discountAmtSend = disc;
                    _taxAmtSend = tax;
                    _itemToalAmtSend = totalAmount;
                  }
                }

                else if (field.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0'))
                {      
                  let _disAmtHere = parseFloat(field.discount_in_amount);
                  if(type == 'rate'){
                    _disAmtHere = 0;
                  }
                  if(type == 'discount_in_amount'){
                    _disAmtHere = _value;
                  }
                  if(_disAmtHere > 0){    
                    console.log(_disAmtHere+"==_disAmtHere==",_value);
                    if(taxType > 0){
                        _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                    }else{
                        _costPrice = subTotal;
                    } 
                    
                    if(type == 'rate'){
                      discountAmt = 0;
                      amountBeforeTax = subTotal;
                      amountBeforeTaxWithoutDisc  = subTotal;
                      disc = 0;
                      tax = 0;
                      _gstObj={};
                    }
                    else{
                      discountAmt = parseFloat(discount_in_amount);// * qty;
                      amountBeforeTax = parseFloat(_costPrice) - parseFloat(discountAmt);
                      disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                      tax = parseFloat(amountBeforeTax) * parseFloat(taxType) / 100;
                    }                
                    totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                    
                    discountAmt = discountAmt;
                    tax = tax.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);

                    _discountAmtSend = discountAmt;
                    _taxAmtSend = tax;
                    _itemToalAmtSend = totalAmount;
                  }
                  else{                
                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                    
                    if(type == 'rate'){
                      discountAmt = 0;
                      amountBeforeTax = parseFloat(qty) * parseFloat(rateValue);
                      amountBeforeTaxWithoutDisc = parseFloat(qty) * parseFloat(rateValue);
                      disc = 0;
                      tax = 0;
                      _gstObj={};
                    }
                    else{
                      discountAmt = parseFloat((discount_in_per)/ 100) * parseFloat(subTotal);
                      amountBeforeTaxWithoutDisc = parseFloat(qty) * (parseFloat(rateValue) / (1 + (parseFloat(taxType) / 100)));
                      disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                      tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                    }
                    
                    totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);

                    //Discount amont == disc;
                    disc = disc;
                    discountAmt = discountAmt.toFixed(2);
                    tax = tax.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);
                    
                    _discountAmtSend = disc;
                    _taxAmtSend = tax;
                    _itemToalAmtSend = totalAmount;               
                  }
                }   

                else {
                  let _disCountAmt =  field.discount_in_amount;
                  if(type == 'discount_in_amount'){
                    _disCountAmt =  _value;
                  }
                  if(_disCountAmt > 0){     
                    _costPrice = subTotal;     
                    
                    if(type == 'rate'){
                      discountAmt = 0;
                      amountBeforeTax = subTotal;
                      amountBeforeTaxWithoutDisc  = subTotal;
                      disc = 0;
                      tax = 0;
                      _gstObj={};
                    }
                    else{  
                      if(discount_in_amount == ''){
                        discount_in_amount = 0;
                      }  
                      discountAmt = parseFloat(discount_in_amount);// * qty;
                      amountBeforeTax = parseFloat(subTotal) - parseFloat(discountAmt);
                      tax = parseFloat(amountBeforeTax) * parseFloat(taxType) / 100;
                    }      
                    totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

                    discountAmt = discountAmt.toFixed(2);
                    tax = tax.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);
                    
                    _discountAmtSend = discountAmt;
                    _taxAmtSend = tax;
                    _itemToalAmtSend = totalAmount;
                  }
                  else{                              
                    _costPrice = subTotal;    
                    console.log("==ASHU---"+taxType);
                    if(type == 'rate'){
                      discountAmt = 0;
                      amountBeforeTax = subTotal;
                      amountBeforeTaxWithoutDisc  = subTotal;
                      disc = 0;
                      tax = 0;
                      _gstObj={};
                    }
                    else{
                      if(discountAmt == 0  || discountAmt == ''){
                        discount_in_per = 0;
                      }
                      discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                      amountBeforeTaxWithoutDisc = parseFloat(subTotal) - parseFloat(discountAmt);
                      tax = (parseFloat(amountBeforeTaxWithoutDisc)) * parseFloat(taxType) / 100;
                      console.log(discountAmt+"==KHARE---"+amountBeforeTaxWithoutDisc+"==KHARE---"+tax);
                    }      
                    totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);         

                    discountAmt = discountAmt.toFixed(2);
                    tax = tax.toFixed(2);
                    totalAmount = totalAmount.toFixed(2);
                    
                    _discountAmtSend = discountAmt;
                    _taxAmtSend = tax;
                    _itemToalAmtSend = totalAmount;
                  }    
                }
            //}
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = _gstObj;//{ id: "0", name: "", ratio: "0" };

          let discountPer  = (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
          console.log("==discountPer==",discountPer);
          discountPer = discountPer.toFixed(2)
          if(type == 'discount_in_per'){
            discountPer = discount_in_per;//.toFixed(2);
            discount_in_amount = discountAmt;//.toFixed(2);
          }
          _obj.trans_count = field.trans_count ? field.trans_count : 0;
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.discount_type =  field.discount_type ? field.discount_type : '';
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.Additional_CESS = additionalCess;
          _obj.rate = rateValue;
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = discountPer;//discountPer;
          _obj.discount_in_amount = discount_in_amount;//_discountAmtSend;//field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = _objTaxType;//field.tax_type ? field.tax_type : _objTaxType;
          _obj.tax_value = _taxAmtSend;//field.tax_value ? field.tax_value : 0;
          _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type ? field.sale_pruchase_tax_type : "";
          _obj.subtotal = totalAmount;
          _obj.total_amount = totalAmount;//field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData: {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData : {};
          fieldArray[_itemPosition] = _obj;
        }
        
      console.log(_discountAmtSend+"==>discountAmt 22-->=="+_taxAmtSend+"==>tax-->=="+_itemToalAmtSend+"==>totalAmount-->==");   
      });
      console.log(this.state.totalAmount+'==>>=='+this.state.totalAmountOld+"==>=="+this.state.totalAmountNew+"==>>=="+this.state.totalAmountOldFree+"==>>=="+_oldTotalItemAmt);

      this.setState({ fields: fieldArray });

      let _totalAmountOld = 0;
      if(this.state.fields.length == 1){
        _totalAmountOld = _itemToalAmtSend;
      }
      else{
        //_totalAmountOld = (parseFloat(_itemToalAmtSend)+ parseFloat(this.state.totalAmountOld)) - parseFloat(_oldTotalItemAmt) ;        
        this.state.fields.map((data) => {
          _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(data.total_amount);  
        })
        _totalAmountOld =  _totalAmountOld.toFixed(2);
      }
      let _totalAmountNew = _totalAmountOld;
      let _paidAM = 0;
      let _dueAmount = _totalAmountOld;

      let _transactionWiseTaxId = 0;
      let _totalTransTaxAmount = 0;
      let _totalTransDiscountPer = 0;
      let _totalTransDiscountAmount = 0;
      let _totalGstAmt = 0;
      let _totalDiscount = 0;
      
      if(this.state.fields.length == 1){
        _totalDiscount = _discountAmtSend;
        _totalGstAmt = _taxAmtSend;
      }
      else{                 
        fieldArray.map((field, index) => {
          if (index!=_itemPosition) {
          _totalDiscount+= parseFloat(_discountAmtSend) + parseFloat(field.discount_in_amount);
          _totalGstAmt+= parseFloat(_taxAmtSend) + parseFloat(field.tax_value);
          }
        })
      }
      this.setState({
        subtotal: _totalAmountOld,
        totalAmount: _totalAmountOld,
        totalAmountOld: _totalAmountOld,
        gstAmount: _totalGstAmt,
        totalDiscount: _totalDiscount,        
        handleItemSalePurchasePrice: handleItemSalePurchasePrice,
      });
      let _totalAmt = _totalAmountOld;

      this.props.callBackItem(
        fieldArray,
        _totalAmountOld,
        _totalAmountNew,
        _totalAmt,
        _dueAmount,
        _paidAM,
        _totalDiscount,
        _totalGstAmt,
        this.state.totalQuantity,
        this.state.handleAddDisable,
        handleItemSalePurchasePrice,
        _transactionWiseTaxId,
        _totalTransTaxAmount,
        _totalTransDiscountPer,
        _totalTransDiscountAmount
      );

    }
  }

  handleDynamicInputs(i, type, event) {
    let _itemPosition = i;
    let _itemName,
      _itemPrice,
      _itemId,
      _value,
      _sale_pruchase_tax_type = "";
    let _base_unit_id = 0;
    let _unitindividual = [];
    let _taxTypeIndividual = { id: "0", name: "", ratio: "0" };

    let _discount_in_sale_price = 0;
    let _Additional_CESS = i.Additional_CESS ? i.Additional_CESS : 0;
    let _discount_type = "Amount";
    let _availableQty = 0;
    let _itemQtyType = 0;
    let _itemType = "";
    let _normal_remaining_quantity = 0;
    let _normal_remaining_quantity_AA = 0;
    let _selectTypeTxt = "Normal";
    let _itemTypeOption = i.type ? i.type : "";
    console.log("==cc--", type);
    console.log("==_itemPosition--", JSON.stringify(_itemPosition));
    console.log("==fields-1--", JSON.stringify(this.state.fields));
    // this.setState({fields:_itemPosition})
    // console.log("==fields-1--", JSON.stringify(this.state.fields));
    if (type == "item") {
      console.log("==dd--", type);
      if (
        i.normal_remaining_quantity == 0 ||
        i.normal_remaining_quantity == undefined
      ) {
        _normal_remaining_quantity_AA = 0;
      } else {
        _normal_remaining_quantity_AA = i.normal_remaining_quantity;
      }
      this.setState({
        transactionWiseTaxId: 0,
        totalTransTaxAmount: 0,
        totalTransDiscountPer: 0,
        totalTransDiscountAmount: 0,
        normal_remaining_quantity: _normal_remaining_quantity_AA,
      });
    console.log("==_normal_remaining_quantity_AA--",_normal_remaining_quantity_AA);
    }

    console.log("==_normal_remaining_quantity_AA--",_normal_remaining_quantity_AA);
    let _mainItemD = this.state.itemArray;

    if (type == "item") {
      _itemPosition = i.index;
      _itemPrice = i.price;
      _itemId = i.id;
      _itemName = i.name;
      _base_unit_id = i.base_unit_id;
      _itemQtyType = i.item_type;
      _sale_pruchase_tax_type = i.sale_pruchase_tax_type;

      _normal_remaining_quantity = _normal_remaining_quantity_AA;//i.normal_remaining_quantity ? i.normal_remaining_quantity : 0;
      _availableQty = i.availableQty;
      _itemType = i.type;
      if (_base_unit_id > 0) {
        _unitindividual = i.unitindividual;
      } else {
        _unitindividual = this.state.unitArray;
      }

      if (i.tax_type.id) {
        _taxTypeIndividual = i.tax_type;
      }
      console.log("==DDD==",i.discount_type);
      _discount_in_sale_price = i.discount_in_sale_price;
      _Additional_CESS = i.Additional_CESS;
      _discount_type = i.discount_type;

      /*Delete or disabled Data after select */
      document.getElementById("item_name_" + _itemPosition).disabled = true;
      document.getElementById("item_name_" + _itemPosition).readOnly = true;
      const elementUlDelete = document.getElementById(
        "itemListData_" + _itemPosition
      );
      //elementUlDelete.remove();

      /*Delete or disabled Data after select */
    } else {
      _value = event.target.value;
      _unitindividual = i.unitindividual;
    }

    /*
      Note  : btnShowSerialNormalBatch  (0= Normal, 1=serial, 2 = batch, 3 = ALL Tracking ,
          4= Normal quantity greater 0 and batch data greater than 0)
          5 = normal qty 0 and serial 0 and batch stock 0, 
          6 normal qty greater than 0 and serial data greater than 
          7 normal qty  0 and serial and data greater than 0
        */
    /*Low Stock Data*/
    if ((type == "item" && _itemTypeOption == "ITEM") && (_itemPosition == i.index)) {
      const { lowStokItemName } = this.state;
      let _objLowStock = [...lowStokItemName];
      if (i.availableQty <= 0 && i.type == "ITEM") {
        _objLowStock.push(i.name);
        this.setState({ lowStokItemName: _objLowStock });
      }
      this.setState({ itemTypeOption: "" });

      let _serialSendData = i.itemSerialNo;
      let _batchSendData = i.itemBatchNo;
      let _itemSerialNoAllData = [];
      let _itemBatchNoAllData = [];
      let _serialGetData = "";
      let _BatchGetData = "";

      _mainItemD = _mainItemD.filter((item) => item.item_id == _itemId);
      let batchDataL = _mainItemD[0].itemBatchNo
        ? _mainItemD[0].itemBatchNo.length
        : 0;
      let filterBatchData = _mainItemD[0].itemBatchNo;

      let totalBatchAvalCount = 0;
      if (batchDataL > 0) {
        filterBatchData.map((itemBData, index) => {
          totalBatchAvalCount =
            parseFloat(totalBatchAvalCount) +
            (parseFloat(itemBData.opening_stock) -
              parseFloat(itemBData.used_stock));
        });
      }

      console.log(this.state.fields.length+"==fields-2--", JSON.stringify(this.state.fields));
      if (this.state.fields.length > 0) {
        let _fieldConditionData = this.state.fields;

        _fieldConditionData.map((fieldData, index) => {
          if (
            _itemId == fieldData.item_id &&
            fieldData.normal_quantity!="" &&
            fieldData.normal_quantity!=undefined
          ) {
            if (fieldData.trans_id == 0) {
              _normal_remaining_quantity = parseFloat(_normal_remaining_quantity) - parseFloat(fieldData.normal_quantity);
            }
            console.log(fieldData.normal_quantity+"===KK--00--",_normal_remaining_quantity);
          }
          if (
            fieldData.itemSerialNoAllData &&
            fieldData.itemSerialNoAllData.length > 0
          ) {
            _itemSerialNoAllData = [
              ..._itemSerialNoAllData,
              ...fieldData.itemSerialNoAllData,
            ];
          }
          if (
            fieldData.itemBatchNoAllData &&
            fieldData.itemBatchNoAllData.length > 0
          ) {
            _itemBatchNoAllData.push(fieldData.itemBatchNoAllData);
          }
        });
      }
      let _normalQtyField = 0;
      if (
        _businessSetting.SerialNoChk == 0 &&
        _businessSetting.SerialNoAdditionalChk1 == 0 &&
        _businessSetting.SerialNoAdditionalChk2 == 0 &&
        _businessSetting.BatchNoChk == 0
      ) 
      {
        _normalQtyField = 1;
      }

      if (
        i.itemSerialNo.length > _itemSerialNoAllData.length &&
        i.itemBatchNo.length > _itemBatchNoAllData.length &&
        _normal_remaining_quantity > 0
      ) 
      {
        this.setState({
          modalIsOpen_SerialBatchNo: true,
          selectItemData: i,
          itemId: i.id,
          name: i.name,
          itemPosition: _itemPosition,
          itemPrice: _itemPrice,
          selectTypeTxt: "Normal",
          srLength: i.itemSerialNo.length,
          batchLength: i.itemBatchNo.length,
          normal_quantity: _normal_remaining_quantity,
          normalQtyField: _normalQtyField,
          selectType: 1,
          btnShowSerialNormalBatch: 3,
        });
      }
      else if (
        _businessSetting.BatchNoChk == 0 &&
        _businessSetting.SerialNoChk == 0 &&
        _businessSetting.SerialNoAdditionalChk1 == 0 &&
        _businessSetting.SerialNoAdditionalChk2 == 0 &&
        _normal_remaining_quantity == 0
      )
      {
        console.log("==KK--33--",_normal_remaining_quantity);
        this.setState({
          modalIsOpen_SerialBatchNo: true,
          selectItemData: i,
          itemId: i.id,
          name: i.name,
          itemPosition: _itemPosition,
          itemPrice: _itemPrice,
          selectType: 1,
          selectTypeTxt: "Normal",
          srLength: i.itemSerialNo.length,
          batchLength: i.itemBatchNo.length,
          normal_quantity: _normal_remaining_quantity,
          normalQtyField: _normalQtyField,
          btnShowSerialNormalBatch: 5,
        });
      }

      else if (
        i.itemSerialNo.length > _itemSerialNoAllData.length &&
        i.itemBatchNo.length > _itemBatchNoAllData.length &&
        _normal_remaining_quantity == 0
      ) 
      {
        this.setState({
          modalIsOpen_SerialBatchNo: true,
          selectItemData: i,
          itemId: i.id,
          name: i.name,
          itemPosition: _itemPosition,
          itemPrice: _itemPrice,
          selectType: 1,
          selectTypeTxt: "Normal",
          srLength: i.itemSerialNo.length,
          batchLength: i.itemBatchNo.length,
          normal_quantity: _normal_remaining_quantity,
          normalQtyField: _normalQtyField,
          btnShowSerialNormalBatch: 7,
        });
      }
      else if (
        i.itemBatchNo.length > _itemBatchNoAllData.length &&
        _normal_remaining_quantity > 0
      )
      {
        this.setState({
          modalIsOpen_SerialBatchNo: true,
          selectItemData: i,
          itemId: i.id,
          name: i.name,
          itemPosition: _itemPosition,
          itemPrice: _itemPrice,
          selectType: 1,
          selectTypeTxt: "Normal",
          srLength: i.itemSerialNo.length,
          batchLength: i.itemBatchNo.length,
          normal_remaining_quantity: _normal_remaining_quantity,
          btnShowSerialNormalBatch: 2,
          normal_quantity: _normal_remaining_quantity,
          batchOnlyData: 1,
        });
      }
      else if (i.itemSerialNo.length > _itemSerialNoAllData.length && _normal_remaining_quantity > 0)
      {
        this.setState({
          modalIsOpen_SerialBatchNo: true,
          selectItemData: i,
          itemId: i.id,
          name: i.name,
          itemPosition: _itemPosition,
          itemPrice: _itemPrice,
          selectType: 1,
          selectTypeTxt: "Normal",
          srLength: i.itemSerialNo.length,
          batchLength: i.itemBatchNo.length,
          btnShowSerialNormalBatch: 6,
          normal_quantity: _normal_remaining_quantity,
          normalQtyField: _normalQtyField,
          normal_remaining_quantity: _normal_remaining_quantity,
        });
      }
      else if (i.itemSerialNo.length > _itemSerialNoAllData.length) {
        if (this.state.fields.length > 1) {
          
      console.log(this.state.fields.length+"==fields-3--", JSON.stringify(this.state.fields));
          _serialGetData = this.state.fields;
          _serialGetData.map((itemD, index) => {
            if (itemD.itemSerialNoAllData) {
              let _size = Object.keys(itemD.itemSerialNoAllData).length;
              let _secItemD =
                _size > 2 ? itemD.itemSerialNoAllData.serial_id : 0;
              _serialSendData = _serialSendData.filter(
                (item) => item.serial_id!=_secItemD
              );
            }
          });
        }
        if (this.state.fields.length > 1) {
          let _serialNoData = [];
          let aa = _serialSendData.filter((item) => item.dataCheck == 1);
          if (aa) {
            _serialNoData.push(aa);
          }
          _serialSendData = _serialNoData[0];
        }

        if (_businessSetting.SerialNoChk == 1 && _serialSendData.length > 0) {
          console.log("==NHA--->>--"+_serialSendData.length);
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPrice: _itemPrice,
            itemPosition: _itemPosition,
            itemSerialNo: _serialSendData,
            //serialItemEnable: 1,
            selectType: 1,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 1,
            normal_quantity: _normal_remaining_quantity,
            selectItemData: i,
            normal_remaining_quantity: _normal_remaining_quantity,
          });
        } 
        else {
          //btnShowSerialNormalBatch:4

          console.log("==KK--66--",_normal_remaining_quantity);
          let normalQtyField = 0;
          if (
            _businessSetting.SerialNoChk == 0 &&
            _businessSetting.SerialNoAdditionalChk1 == 0 &&
            _businessSetting.SerialNoAdditionalChk2 == 0 &&
            _businessSetting.BatchNoChk == 0
          ) {
            normalQtyField = 1;
          } else {
            normalQtyField = 0;
          }
          //this.setState({modalIsOpen_SerialBatchNo: true, itemId:i.id, name:i.name, itemPosition:_itemPosition,itemAddSerialNo :[] ,serialItemEnable: 1,itemPrice:_itemPrice,selectType:2,selectTypeTxt:'Normal',normalQtyField : normalQtyField,btnShowSerialNormalBatch:4});

          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPosition: _itemPosition,
            itemAddSerialNo: [],
            serialItemEnable: 1,
            itemPrice: _itemPrice,
            selectType: 1,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 5,
            normalQtyField: normalQtyField,
            normal_remaining_quantity: _normal_remaining_quantity,
          });
        }
      }
      else if (totalBatchAvalCount > 0) {
        let _itemBatchNoAllData = [];

        if (this.state.fields.length >= 1) {
          _BatchGetData = this.state.fields;
          _BatchGetData.map((itemD, index) => {
            if (itemD.itemBatchNoAllData) {
              let _sizeAK = itemD.itemBatchNoAllData.length;
              if (itemD.itemBatchNoAllData.length >= 1) {
                _itemBatchNoAllData = itemD.itemBatchNoAllData;
                _itemBatchNoAllData.map((itemBatchD, index) => {
                  let totalUsedQty =
                    parseFloat(itemBatchD.used_stock) +
                    parseFloat(itemBatchD.free_quantity);

                  let _secItemDAK = _sizeAK >= 1 ? itemBatchD.batch_id : 0;
                  if (totalUsedQty >= 1) {
                    _batchSendData = _batchSendData.filter(
                      (item) => item.batch_id!=_secItemDAK
                    );
                  } else {
                    _batchSendData = _batchSendData.filter(
                      (item) => item.batch_id!=_secItemDAK
                    );
                  }
                });
              }
            }
          });
        }

        if (
          totalBatchAvalCount > 0 &&
          _businessSetting.BatchNoChk == 1 &&
          _batchSendData.length > 0
        )
        {
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPrice: _itemPrice,
            itemPosition: _itemPosition,
            itemBatchNo: _batchSendData,
            batchSendType: "New",
            selectType: 1,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 2,
            normal_quantity: _normal_remaining_quantity,
            selectItemData: i,
            normal_remaining_quantity: this.state.normal_remaining_quantity,
            batchOnlyData: 1,
          });
        } 
        else {
          console.log("==KK--77--",_normal_remaining_quantity);
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPrice: _itemPrice,
            itemPosition: _itemPosition,
            itemBatchNo: _batchSendData,
            batchSendType: "New",
            selectType: 1,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 0,
            normal_quantity: _normal_remaining_quantity,
            normal_remaining_quantity: _normal_remaining_quantity,
            selectItemData: i,
            batchOnlyData: 1,
          });
        }
      } 
      else if (
        _businessSetting.SerialNoChk == 1 &&
        _serialSendData.length > 0 &&
        this.state.transactionId > 0
      )
      {
        console.log("==1--NHA--->>--"+_serialSendData.length);
        this.setState({
          modalIsOpen_SerialBatchNo: true,
          itemId: i.id,
          name: i.name,
          itemPrice: _itemPrice,
          itemPosition: _itemPosition,
          itemSerialNo: _serialSendData,
          serialItemEnable: 1,
          selectType: 1,
          selectTypeTxt: "Normal",
          btnShowSerialNormalBatch: 5,//1 Remove by AK issue no 2091
          normal_quantity: _normal_remaining_quantity,
          selectItemData: i,
          normal_remaining_quantity: this.state.normal_remaining_quantity,
        });
      }
      else
      {
        let _serialNoDataK = [];
        let _aa = _serialSendData.filter((item) => item.dataCheck == 1);
        if (_aa) {
          _serialNoDataK.push(_aa);
        }
        _serialSendData = _serialNoDataK[0];

        if (
          _businessSetting.SerialNoChk == 1 &&
          _serialSendData.length == 0 &&
          _businessSetting.BatchNoChk == 1 &&
          _batchSendData.length == 0 &&
          _normal_remaining_quantity > 0
        ) {
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPosition: _itemPosition,
            itemAddSerialNo: [],
            serialItemEnable: 1,
            itemPrice: _itemPrice,
            selectType: 1,
            selectTypeTxt: "Normal",
            normalQtyField: 1,
            normal_remaining_quantity: _normal_remaining_quantity,
            normal_quantity: _normal_remaining_quantity,
            btnShowSerialNormalBatch: 0,
          });
        } 

        else if (
          _businessSetting.SerialNoChk == 1 &&
          _serialSendData.length == 0
        ) 
        {
          console.log("==KK--99--",_normal_remaining_quantity);
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPosition: _itemPosition,
            itemAddSerialNo: [],
            serialItemEnable: 1,
            itemPrice: _itemPrice,
            selectType: 1,
            selectTypeTxt: "Normal",
            normalQtyField: 1,
            normal_remaining_quantity: _normal_remaining_quantity,
            btnShowSerialNormalBatch: 5,
          });
        }
        else if (
          _businessSetting.SerialNoChk == 1 &&
          _serialSendData.length > 0
        ) {
          console.log("==2--NHA--->>--"+_serialSendData.length);
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPrice: _itemPrice,
            itemPosition: _itemPosition,
            itemSerialNo: _serialSendData,
            serialItemEnable: 1,
            selectType: 1,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 1,
            normal_quantity: _normal_remaining_quantity,
            selectItemData: i,
            normal_remaining_quantity: this.state.normal_remaining_quantity,
          });
        }
        else if ((_businessSetting.BatchNoChk == 1 &&  _batchSendData.length == 0) && _normal_remaining_quantity > 0) {
          console.log("==KK--88--",_normal_remaining_quantity);
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPosition: _itemPosition,
            itemAddSerialNo: [],
            serialItemEnable: 1,
            itemPrice: _itemPrice,
            selectType: 1,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 6,
            normalQtyField: 1,
            normal_remaining_quantity: _normal_remaining_quantity,
          });
        }
        else {
          console.log("==KK--999--",_normal_remaining_quantity);
          this.setState({
            modalIsOpen_SerialBatchNo: true,
            itemId: i.id,
            name: i.name,
            itemPosition: _itemPosition,
            itemAddSerialNo: [],
            serialItemEnable: 1,
            itemPrice: _itemPrice,
            selectType: 2,
            selectTypeTxt: "Normal",
            btnShowSerialNormalBatch: 4,
            normal_remaining_quantity: _normal_remaining_quantity,
          });
        }
      }
    }

    if (type == "item" && _itemTypeOption == "SERVICE") {
      this.setState({
        modalIsOpen_SerialBatchNo: true,
        itemId: i.id,
        name: i.name,
        itemPosition: _itemPosition,
        itemAddSerialNo: [],
        serialItemEnable: 0,
        itemPrice: _itemPrice,
        selectType: 1,
        selectTypeTxt: "Normal",
        normalQtyField: 1,
        normal_remaining_quantity: _normal_remaining_quantity,
        btnShowSerialNormalBatch: 111,
        itemTypeOption: _itemTypeOption,
      });
    }

    console.log("==this.state.itemTypeOption==",this.state.itemTypeOption);
    if (type == "free_quantity" && this.state.itemTypeOption == "") {
      this.setState({ itemTypeOption: "" });
      let _serialGetData = this.state.fields;
      let dataItem = this.state.itemArray;
      let _errorD = false;
      let _valFree = 0;
      _selectTypeTxt = "Free";
      let normal_remaining_quantity_free = 0;
      let _totalSelLength = 0;
      let mainItemData = [];
      let _normal_quantityFree = 0;
      let _mainItemData = [];
      let normal_remaining_quantityAK = 0;
      let _availLenAk = 0;
      let srNoLngth = 0;
      let itemSerialNoAK = 0;
      let _totalAmountOld = this.state.totalAmountOld;
      let srMainNoLngth = 0;
      let _finalEditAvalLen = 0;
      let availableSrLen = 0;

      _serialGetData.map((itemD, index) => {
        console.log("===AAAAAAAA==",itemD)
        if (index == _itemPosition) {
          let _itemName = itemD.item_name;
          if (itemD!="") {
            srNoLngth = itemD.itemSerialNoAllData ? itemD.itemSerialNoAllData.length : 0;
            srMainNoLngth = itemD.itemSerialNo ? itemD.itemSerialNo.length : 0;
            if (srMainNoLngth > srNoLngth) {
              _finalEditAvalLen = parseFloat(srMainNoLngth) - parseFloat(srNoLngth);
            }
            
            console.log(srNoLngth+"==srNoLngth=="+srMainNoLngth+"==srMainNoLngth=="+_finalEditAvalLen);
            console.log(index+"==index--_itemPosition=="+_itemPosition);
            if (this.state.transactionId > 0 && srNoLngth == srMainNoLngth) {
              itemD.availLenAk = 0;
            }
            _normal_quantityFree = itemD.normal_quantity ? itemD.normal_quantity : 0;
            if (itemD.normal_quantity == "null" || itemD.normal_quantity == null) {
              _normal_quantityFree = 0;
            }

            mainItemData = dataItem.filter(
              (itemData) => itemData.item_id == itemD.item_id
            );
            _totalSelLength = parseFloat(_totalSelLength) + parseFloat(srNoLngth);

            _mainItemData = mainItemData[0];
            _availLenAk = itemD.availLenAk;

            if (mainItemData[0]!="" && _mainItemData!=undefined) {
              normal_remaining_quantityAK = _mainItemData.normal_remaining_quantity;
              itemSerialNoAK = _mainItemData.itemSerialNo ? _mainItemData.itemSerialNo.length : 0;
              let _checkSrAva = _mainItemData.itemSerialNo.filter((data) => data.dataCheck == 1);
              //itemSerialNoAK = itemSerialNoAK.filter((data) => data.dataCheck == 1);
              availableSrLen = _checkSrAva.length;
              console.log(_checkSrAva.length+"==len HHH==",JSON.stringify(_checkSrAva));
             
            }

            if (itemD.availLenAk == undefined || itemD.availLenAk == "undefined") {
              _availLenAk = _finalEditAvalLen;
            }
            if (_availLenAk < 0) {
              _availLenAk = 0;
            }
            let _itemSerialNoAllDataAK = itemD.itemSerialNoAllData ? itemD.itemSerialNoAllData.length : 0;

            if (this.state.transactionId > 0 && (srMainNoLngth == srNoLngth)) {
              _availLenAk = srMainNoLngth;
            }
            if (index == _itemPosition) {
              _errorD = true;

              console.log(srNoLngth+"==>aaa-->=="+_value+"==NN==KK==",_availLenAk);
              //return false;
              if (normal_remaining_quantityAK > 0) {
                _errorD = true;
                normal_remaining_quantity_free = parseFloat(itemD.normal_remaining_quantity) - parseFloat(_normal_quantityFree);

                if (srNoLngth > 0) {
                  if (_availLenAk > _value && this.state.transactionId > 0) {
                    this.setState({
                      itemId: itemD.item_id,
                      name: _itemName,
                      itemPosition: _itemPosition,
                      itemPrice: itemD.rate,
                      selectType: 2,
                      serialItemEnable :0,
                      selectTypeTxt: "Free",
                      normal_remaining_quantity: normal_remaining_quantity_free,
                      normal_quantity: _normal_quantityFree,
                      modalIsOpen_SerialNoFreeItem: true,
                      selectItemData: itemD,
                      sendActualQty: normal_remaining_quantityAK,
                      totalAmountOldFree: _totalAmountOld,
                    });
                  } 
                  else if (_availLenAk > 0 && this.state.transactionId == 0) {
                    this.setState({
                      itemId: itemD.item_id,
                      name: _itemName,
                      itemPosition: _itemPosition,
                      itemPrice: itemD.rate,
                      selectType: 2,
                      serialItemEnable :0,
                      selectTypeTxt: "Free",
                      normal_remaining_quantity: normal_remaining_quantity_free,
                      normal_quantity: _normal_quantityFree,
                      //modalIsOpen_SerialBatchNo: true,
                      modalIsOpen_SerialNoFreeItem: true,
                      selectItemData: itemD,
                      sendActualQty: normal_remaining_quantityAK,
                      totalAmountOldFree: _totalAmountOld,
                    });
                  } 
                  else {
                    console.log("==DEMO--66--");
                    this.setState({
                      itemId: itemD.item_id,
                      name: _itemName,
                      itemPosition: _itemPosition,
                      itemPrice: itemD.rate,
                      selectType: 2,
                      selectTypeTxt: "Free",
                      normal_remaining_quantity: normal_remaining_quantity_free,
                      normal_quantity: _normal_quantityFree,
                      modalIsOpen_AddNewFreeSerialNo: true,
                      selectItemData: itemD,
                      sendActualQty: normal_remaining_quantityAK,
                      totalAmountOldFree: _totalAmountOld,
                    });
                  }
                } 
                
                else {
                  console.log(normal_remaining_quantity_free+"==QQQ===1=="+_normal_quantityFree+'=>>='+normal_remaining_quantityAK);
                  _errorD = true;
                  let _usedFreeNormalQtyAK = itemD.free_quantity;
                  this.setState({
                    itemId: itemD.item_id,
                    name: _itemName,
                    itemPosition: _itemPosition,
                    itemPrice: itemD.rate,
                    selectType: 2,
                    selectTypeTxt: "Free",
                    normal_remaining_quantity: normal_remaining_quantity_free,
                    normal_quantity: _normal_quantityFree,
                    modalIsOpen_NotSerialNoFree: true,
                    selectItemData: itemD,
                    sendActualQty: normal_remaining_quantityAK,
                    totalAmountOldFree: _totalAmountOld,
                    totalAmountOld: _totalAmountOld,
                    totalAmount: _totalAmountOld,
                    usedFreeNormalQty: _usedFreeNormalQtyAK,
                  });
                }
                this.state.selectTypeTxt = "Free";
                return false;
              }

              if (_value == itemSerialNoAK && this.state.transactionId > 0 && this.state.serialItemEnable == 1)
              {
                console.log(_value+"===XXXX==="+itemSerialNoAK);
                this.setState({
                  modalIsOpen_SerialNoFreeItem: true,
                  itemPosition: _itemPosition,
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPrice: itemD.rate,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantity_free,
                  normal_quantity: _normal_quantityFree,
                  selectItemData: itemD,
                  sendActualQty: normal_remaining_quantityAK,
                  totalAmountOldFree: _totalAmountOld,
                  itemSerialNo: itemD.itemSerialNo,
                });
                //eturn false;
              } 

              else if (_value == _availLenAk && this.state.serialItemEnable == 1)
              {
                console.log(_value+"===YYYY==="+_availLenAk);
                _errorD = true;
                itemD.availLenAk =
                  parseFloat(srNoLngth) - parseFloat(itemD.quantity);
                if (normal_remaining_quantityAK > 0) {
                  itemD.normal_remaining_quantity =
                    parseFloat(normal_remaining_quantityAK) -
                    parseFloat(itemD.quantity);
                } else {
                  itemD.normal_remaining_quantity = parseFloat(
                    normal_remaining_quantityAK
                  );
                }

                if (_totalSelLength > itemSerialNoAK) {
                  console.log("==DEMO--44--");
                  console.log("==3--NHA--->>--");
                  this.setState({
                    itemId: itemD.item_id,
                    name: _itemName,
                    itemPosition: _itemPosition,
                    itemAddSerialNo: [],
                    serialItemEnable: 1,
                    itemPrice: itemD.sale_price,
                    selectType: 2,
                    selectTypeTxt: "Free",
                    normal_remaining_quantity: normal_remaining_quantityAK,
                    btnShowSerialNormalBatch: 1,
                    modalIsOpen_AddNewFreeSerialNo: true,
                    sendActualQty: normal_remaining_quantityAK,
                  });
                  let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                    ...srData,
                    isSelected:
                      srData.productId == itemD.item_id ? false : false,
                    dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                    isFreeQuantity:
                      srData.productId == itemD.item_id ? false : false,
                  }));
                  itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                    if (itemSerialData.isFreeQuantity == true) {
                    }
                  });
                } 
                else {
                  console.log("==4--NHA--->>--");
                  this.setState({
                    itemId: itemD.item_id,
                    name: _itemName,
                    itemPosition: _itemPosition,
                    itemAddSerialNo: [],
                    serialItemEnable: 1,
                    itemPrice: itemD.sale_price,
                    selectType: 2,
                    selectTypeTxt: "Free",
                    normal_remaining_quantity: normal_remaining_quantityAK,
                    btnShowSerialNormalBatch: 1,
                    modalIsOpen_SerialNoFreeItem: true,
                    sendActualQty: normal_remaining_quantityAK,
                  });

                  if (this.state.transactionId > 0) {
                    this.setState({
                      itemSerialNo: itemD.itemSerialNo,
                    });
                  }
                  let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                    ...srData,
                    isSelected:
                      srData.productId == itemD.item_id ? false : false,
                    dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                    isFreeQuantity:
                      srData.productId == itemD.item_id ? false : false,
                  }));
                  itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                    if (itemSerialData.isFreeQuantity == true) {
                    }
                  });
                }
              }

              else if (_availLenAk > 0 && this.state.serialItemEnable == 1) {
                console.log("==ZZZZ===");
                _errorD = true;

                itemD.availLenAk =
                  parseFloat(srNoLngth) - parseFloat(itemD.quantity);
                if (normal_remaining_quantityAK > 0) {
                  itemD.normal_remaining_quantity =
                    parseFloat(normal_remaining_quantityAK) -
                    parseFloat(itemD.quantity);
                } else {
                  itemD.normal_remaining_quantity = parseFloat(
                    normal_remaining_quantityAK
                  );
                }

                if (_totalSelLength > itemSerialNoAK) {
                  console.log("==DEMO--11--");
                  console.log("==5--NHA--->>--");
                  this.setState({
                    itemId: itemD.item_id,
                    name: _itemName,
                    itemPosition: _itemPosition,
                    itemAddSerialNo: [],
                    serialItemEnable: 1,
                    itemPrice: itemD.sale_price,
                    selectType: 2,
                    selectTypeTxt: "Free",
                    normal_remaining_quantity: normal_remaining_quantityAK,
                    btnShowSerialNormalBatch: 1,
                    modalIsOpen_AddNewFreeSerialNo: true,
                    sendActualQty: normal_remaining_quantityAK,
                  });
                  let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                    ...srData,
                    isSelected:
                      srData.productId == itemD.item_id ? false : false,
                    dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                    isFreeQuantity:
                      srData.productId == itemD.item_id ? false : false,
                  }));
                  itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                    if (itemSerialData.isFreeQuantity == true) {
                    }
                  });
                } 
                else {
                  console.log("==6--NHA--->>--");
                  if(availableSrLen == 0){
                      console.log("==65--NHA--->>--");
                      this.setState({
                        itemId: itemD.item_id,
                        name: _itemName,
                        itemPosition: _itemPosition,
                        itemAddSerialNo: [],
                        serialItemEnable: 1,
                        itemPrice: itemD.sale_price,
                        selectType: 2,
                        selectTypeTxt: "Free",
                        normal_remaining_quantity: normal_remaining_quantityAK,
                        btnShowSerialNormalBatch: 1,
                        modalIsOpen_AddNewFreeSerialNo: true,
                        sendActualQty: normal_remaining_quantityAK,
                      });
                      let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                        ...srData,
                        isSelected:
                          srData.productId == itemD.item_id ? false : false,
                        dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                        isFreeQuantity:
                          srData.productId == itemD.item_id ? false : false,
                      }));
                      itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                        if (itemSerialData.isFreeQuantity == true) {
                        }
                      });
                  }else{
                    this.setState({
                      itemId: itemD.item_id,
                      name: _itemName,
                      itemPosition: _itemPosition,
                      itemAddSerialNo: [],
                      serialItemEnable: 1,
                      itemPrice: itemD.sale_price,
                      selectType: 2,
                      selectTypeTxt: "Free",
                      normal_remaining_quantity: normal_remaining_quantityAK,
                      btnShowSerialNormalBatch: 1,
                      modalIsOpen_SerialNoFreeItem: true,
                      sendActualQty: normal_remaining_quantityAK,
                    });
                    if (this.state.transactionId > 0) {
                      this.setState({
                        itemSerialNo: itemD.itemSerialNo,
                      });
                    }
                    let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                      ...srData,
                      isSelected:
                        srData.productId == itemD.item_id ? false : false,
                      dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                      isFreeQuantity:
                        srData.productId == itemD.item_id ? false : false,
                    }));
                    itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                      if (itemSerialData.isFreeQuantity == true) {
                      }
                    });
                  }
                }
              } 

              else if (_availLenAk == 0 && this.state.serialItemEnable == 1) {
                console.log("==DEMO--22--");
                _errorD = true;

                itemD.availLenAk =
                  parseFloat(srNoLngth) - parseFloat(itemD.quantity);
                if (normal_remaining_quantityAK > 0) {
                  itemD.normal_remaining_quantity =
                    parseFloat(normal_remaining_quantityAK) -
                    parseFloat(itemD.quantity);
                } else {
                  itemD.normal_remaining_quantity = parseFloat(
                    normal_remaining_quantityAK
                  );
                }

                let _totalAmountOldFree = this.state.totalAmount;

                this.state.selectTypeTxt = "Free";
                this.state.totalAmountOld = _totalAmountOldFree;
                this.state.totalAmount = _totalAmountOldFree;
                this.state.totalAmountNew = _totalAmountOldFree;
                console.log("==8--NHA--->>--");
                this.setState({
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPosition: _itemPosition,
                  itemAddSerialNo: [],
                  serialItemEnable: 1,
                  itemPrice: itemD.sale_price,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantityAK,
                  btnShowSerialNormalBatch: 1,
                  modalIsOpen_AddNewFreeSerialNo: true,
                  sendActualQty: normal_remaining_quantityAK,
                  totalAmount: _totalAmountOldFree,
                  totalAmountOld: _totalAmountOldFree,
                  totalAmountNew: _totalAmountOldFree,
                  totalAmountOldFree: _totalAmountOldFree,
                });
                let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                  ...srData,
                  isSelected: srData.productId == itemD.item_id ? false : false,
                  dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                  isFreeQuantity:
                    srData.productId == itemD.item_id ? false : false,
                }));
                itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                  if (itemSerialData.isFreeQuantity == true) {
                  }
                });
              } 

              else if ( srMainNoLngth > srNoLngth && this.state.serialItemEnable == 1)
              {
                _errorD = true;

                itemD.availLenAk =
                  parseFloat(srNoLngth) - parseFloat(itemD.quantity);
                if (normal_remaining_quantityAK > 0) {
                  itemD.normal_remaining_quantity =
                    parseFloat(normal_remaining_quantityAK) -
                    parseFloat(itemD.quantity);
                } else {
                  itemD.normal_remaining_quantity = parseFloat(
                    normal_remaining_quantityAK
                  );
                }
                console.log("==9--NHA--->>--");
                this.setState({
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPosition: _itemPosition,
                  itemAddSerialNo: [],
                  serialItemEnable: 1,
                  itemPrice: itemD.sale_price,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantityAK,
                  btnShowSerialNormalBatch: 1,
                  modalIsOpen_SerialNoFreeItem: true,
                  sendActualQty: normal_remaining_quantityAK,
                });
                let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                  ...srData,
                  isSelected: srData.productId == itemD.item_id ? false : false,
                  dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                  isFreeQuantity:
                    srData.productId == itemD.item_id ? false : false,
                }));
                itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                  if (itemSerialData.isFreeQuantity == true) {
                  }
                });
              } 

              else if (_totalSelLength == itemSerialNoAK && this.state.serialItemEnable == 1)
              {
                _errorD = true;
                alert("Maximum add limit is 0");

                itemD.availLenAk =
                  parseFloat(srNoLngth) - parseFloat(itemD.quantity);
                if (normal_remaining_quantityAK > 0) {
                  itemD.normal_remaining_quantity =
                    parseFloat(normal_remaining_quantityAK) -
                    parseFloat(itemD.quantity);
                } else {
                  itemD.normal_remaining_quantity = parseFloat(
                    normal_remaining_quantityAK
                  );
                }

                console.log("==10--NHA--->>--");
                this.setState({
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPosition: _itemPosition,
                  itemAddSerialNo: [],
                  serialItemEnable: 1,
                  itemPrice: itemD.sale_price,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantityAK,
                  btnShowSerialNormalBatch: 1,
                  modalIsOpen_SerialBatchNo: true,
                  modalIsOpen_SerialNoFreeItem: false,
                  sendActualQty: normal_remaining_quantityAK,
                  totalAmount: _totalAmountOld,
                  totalAmountOld: _totalAmountOld,
                  totalAmountOldFree: _totalAmountOld,
                });
                this.state.selectTypeTxt = "Free";

                let _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
                  ...srData,
                  isSelected: srData.productId == itemD.item_id ? false : false,
                  dataCheck: srData.productId == itemD.item_id ? 1 : 1,
                  isFreeQuantity:
                    srData.productId == itemD.item_id ? false : false,
                }));
                itemD.itemSerialNoAllData.map((itemSerialData, index) => {
                  if (itemSerialData.isFreeQuantity == true) {
                  }
                });
              }
              
              else if (normal_remaining_quantityAK == 0 && _itemSerialNoAllDataAK == 0)
              {
                console.log("==999999===",itemD.free_quantity);
                this.setState({
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPosition: _itemPosition,
                  itemPrice: itemD.rate,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantity_free,
                  normal_quantity: _normal_quantityFree,
                  modalIsOpen_NotSerialNoFree: true,
                  selectItemData: itemD,
                  sendActualQty: normal_remaining_quantityAK,
                  totalAmountOldFree: _totalAmountOld,
                  totalAmountOld: _totalAmountOld,
                  totalAmount: _totalAmountOld,
                  usedFreeNormalQty: itemD.free_quantity,
                });
              }
            }
          }

          /*if (_errorD == false && this.state.serialItemEnable == 1) {
            let lengthMainSrN = this.state.itemSerialNo.length;
            let lengthFieldSrN = itemD.itemSerialNoAllData.length;
            if (lengthFieldSrN!=0 && lengthMainSrN!=0) {
              if (lengthFieldSrN > lengthMainSrN) {
                this.setState({
                  modalIsOpen_AddNewFreeSerialNo: true,
                  itemPosition: _itemPosition,
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPrice: itemD.rate,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantity_free,
                  normal_quantity: _normal_quantityFree,
                  selectItemData: itemD,
                  sendActualQty: normal_remaining_quantityAK,
                  totalAmountOldFree: _totalAmountOld,
                });
              } 
              else {
                this.setState({
                  modalIsOpen_SerialNoFreeItem: true,
                  itemPosition: _itemPosition,
                  itemId: itemD.item_id,
                  name: _itemName,
                  itemPrice: itemD.rate,
                  selectType: 2,
                  selectTypeTxt: "Free",
                  normal_remaining_quantity: normal_remaining_quantity_free,
                  normal_quantity: _normal_quantityFree,
                  selectItemData: itemD,
                  sendActualQty: normal_remaining_quantityAK,
                  totalAmountOldFree: _totalAmountOld,
                });
              }
            }
          }*/
        }
      });
    }

    else if (type == "free_quantity" && this.state.itemTypeOption == "SERVICE") {
      let _totalAmountOld = this.state.totalAmountOld;
      this.setState({
        itemId: i.id,
        name: i.name,
        itemPosition: _itemPosition,
        itemAddSerialNo: [],
        serialItemEnable: 0,
        itemPrice: _itemPrice,
        selectType: 2,
        selectTypeTxt: "Free",
        normalQtyField: 1,
        normal_remaining_quantity: _normal_remaining_quantity,
        modalIsOpen_NotSerialNoFree: true,
        totalAmountOldFree: _totalAmountOld,
        totalAmountOld: _totalAmountOld,
        totalAmount: _totalAmountOld,
        btnShowSerialNormalBatch: 222,
        itemTypeOption: this.state.itemTypeOption,
      });
    }

    /*End Low Stock Data */
    else{
      const fieldArray = this.state.fields;
      console.log("==KHARE==",fieldArray);
      let _quantity = 0;
      let _totalAmt = 0; //this.state.totalAmountOld ? this.state.totalAmountOld : 0;
      let _receivedAmount = this.state.receivedAmount;
      let _dueAmount = 0;
      let _totalDiscountAmt = parseFloat(this.state.totalDiscount);
      let _totalGstAmt = parseFloat(this.state.gstAmount);
      if (fieldArray.length == "1" && this.state.transactionId == 0) {
        _receivedAmount = 0;
      }
      let _transCount = 0;
      let _itemPriceAK = 0;
      let handleItemSalePurchasePrice = "";
      if (fieldArray.length > 0) {
        fieldArray.map((field, index) => {
          let _unitPreArray = field.unitArrayOld  ? field.unitArrayOld : this.state.unitArray;

          if (index == _itemPosition) {
            
            let _obj = {};
            let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
            let _objRateTax = { value: "1", label: this.state.rate_tax };
            let _objTaxType = { id: "0", name: "", ratio: "0" };

            /*Low Stock */
            let _availableQtyNew = field.availableQty;
            let _itemTypeNew = field.type;
            let _itemNameNew = field.item_name;
            
            _itemPriceAK =  parseFloat(field.rate) * parseFloat(field.quantity); 
            /*End Low Stock */

            _obj.item_id = field.item_id ? field.item_id : 0;
            _obj.item_name = field.item_name;
            _obj.trans_id = 0;
            _obj.quantity = field.quantity ? field.quantity : 0;
            _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
            _obj.trans_count = field.trans_count ? field.trans_count : 0;
            _obj.discount_type =  field.discount_type ? field.discount_type : '';
            _obj.unit = field.unit ? field.unit : _objUnit;
            _obj.rate = field.rate ? field.rate : 0;
            _obj.rate_tax = _objRateTax;
            _obj.discount_in_per = field.discount_in_per
              ? field.discount_in_per
              : 0;
            _obj.discount_in_amount = field.discount_in_amount? field.discount_in_amount: 0;
            _obj.Additional_CESS = field.Additional_CESS ? field.Additional_CESS : 0;
            _obj.tax_type = field.tax_type ? field.tax_type : _objTaxType;
            _obj.tax_value = field.tax_value ? field.tax_value : 0;
            _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type
              ? field.sale_pruchase_tax_type
              : "";
            _obj.subtotal = field.subtotal ? field.subtotal : 0;
            _obj.total_amount = field.total_amount ? field.total_amount : 0;
            _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
            _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
            _obj.itemSerialNoAllData = field.itemSerialNoAllData
              ? field.itemSerialNoAllData
              : {};
            _obj.itemBatchNoAllData = field.itemBatchNoAllData
              ? field.itemBatchNoAllData
              : {};

            let _percGstAmt = 0;
            let _percDisAmt = 0;
            let _percDisPerc = 0;
            let _isNewItem = false;

            if (type == "unit") {
              let _unitObj = {};
              _unitPreArray.map((unit, index) => {
                if (_value == unit.unit_name) {
                  _unitObj.unit_id = unit.unit_id;
                  _unitObj.unit_name = unit.unit_name;
                  _unitObj.old_unit_id = unit.unit_id;
                }
              });
              _obj.unit = _unitObj;
            }
            if (type == "item") {
              _isNewItem = true;
             console.log(_discount_type+"==NEH==",field);
              _obj.item_id = _itemId;
              _obj.item_name = _itemName;
              if (_itemPrice < 0) {
                _obj.rate = 0;
                _obj.subtotal = 0;
                _obj.total_amount = 0;
              } else {
                _obj.rate = _itemPrice;
                _obj.subtotal = _itemPrice;
                _obj.total_amount = _itemPrice;
              }
              _obj.quantity = field.quantity ? field.quantity : 0;
              _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
              _obj.discount_type =  _discount_type;
              _obj.discount_in_sale_price = _discount_in_sale_price;
              _obj.trans_count = 0;
              _obj.tax_type = _objTaxType;
              _obj.tax_value = i.tax_type.id ? i.tax_type.id : 0;
              _obj.availableQty = _availableQty;
              _obj.type = _itemType;
              _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
              _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
              _obj.Additional_CESS = field.Additional_CESS
                ? field.Additional_CESS
                : 0;
              _obj.itemSerialNoAllData = [];

              _obj.itemBatchNoAllData = [];

              if (_base_unit_id > 0) {
                _obj.unit = _unitindividual[0];
              } else {
                _obj.unit = _objUnit;
              }
              _obj.tax_type = _taxTypeIndividual;
              _obj.tax_value = i.tax_type.id ? i.tax_type.id : 0;
              _obj.sale_pruchase_tax_type = i.sale_pruchase_tax_type
                ? i.sale_pruchase_tax_type
                : "";
            }
            
            else {
            }

            if (type == "quantity")
            {
              if (_value == "" || _value == "null") {
                _value = 0;
              }
              _obj.quantity = _value;
              /*Low Stock */
              if (_value >= _availableQtyNew && _itemTypeNew == "ITEM") {
                const { lowStokItemName } = this.state;
                let _objLowStock = [...lowStokItemName];
                _objLowStock.push(_itemNameNew);
                this.setState({ lowStokItemName: _objLowStock });
              }
              /*End Low Stock */
            }

            if (type == "trans_count")
            {
              _transCount = _value;
              if (_transCount == "" || _transCount == "null") {
                _transCount = 0;
              }
              _obj.trans_count = _transCount;
            }

            if (type == "rate") {
              if (_value == "" || _value == "null") {
                _value = 0;
              }
              if (_value == 0) {
                alert("Price can not be zero");
                handleItemSalePurchasePrice = "Disabled";
              }
              _obj.rate = _value;
              let _totalAmtRate = parseFloat(_value) * parseFloat(_obj.quantity);
              _totalAmtRate = _totalAmtRate + parseFloat(_obj.Additional_CESS);
              _obj.discount_in_per = 0;
              _obj.discount_in_amount = 0;
              _obj.tax_value = 0;
              _obj.tax_type = 0;
              _obj.total_amount = _totalAmtRate;
              _totalGstAmt = 0;
              _totalDiscountAmt = 0;
            }

            let _totalItemAmount = parseFloat(_obj.rate) * parseFloat(_obj.quantity); 
            let _itemSubtotal = 0;

            if (_businessSetting.AdditionalCESS == 1)
            {
              if (_Additional_CESS > 0) {
                _obj.Additional_CESS = _Additional_CESS ? _Additional_CESS : 0;
                _itemSubtotal =
                  parseFloat(_totalItemAmount) + parseFloat(_Additional_CESS);
                _obj.subtotal = _itemSubtotal;
                _obj.total_amount = _itemSubtotal;
              }
              if (type == "Additional_CESS") {
                console.log(_value + "==demo ==aaa==", type);
                _obj.Additional_CESS = _value;
                _itemSubtotal = parseFloat(_totalItemAmount) + parseFloat(_value);
                _obj.subtotal = _itemSubtotal;
                _obj.total_amount = _itemSubtotal;
              }
              console.log(_Additional_CESS + "==here zz===" + _itemSubtotal);
            }

            if (_businessSetting.ItemWiseDiscount == 1)
            {
              if (type == "discount_in_per") {
                if (_obj.tax_value > 0) {
                  if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax") {
                    //_totalItemAmount =  parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value);
                    //_totalItemAmount = parseFloat(_totalItemAmount) * (field.tax_type.ratio / 100);
                  }
                }
                if (_value > 100) {
                  alert("Discount cannot be more than 100%");
                } else {
                  if (_value == "" || _value == "null") {
                    _value = 0;
                  }
                  _obj.discount_in_per = _value;
                  if (_obj.discount_in_per >= 0) {
                    _percDisAmt = (parseFloat(_totalItemAmount) * parseFloat(_obj.discount_in_per)) /  parseFloat(100);
                  }
                  _obj.discount_in_amount = _percDisAmt.toFixed(2);
                  
                  if (_businessSetting.ItemWiseTax == 1 && _businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
                  {
                    if (_obj.tax_value > 0) {
                      console.log(fieldArray.length+"==_totalGstAmt===11--", _totalGstAmt);
                      if(fieldArray.length == 1){
                        _totalGstAmt = parseFloat(_obj.tax_value);
                      }else{
                        _totalGstAmt = parseFloat(this.state.gstAmount);// + parseFloat(_obj.tax_value);
                      }
                    } else {
                      _totalGstAmt = 0;
                    }
                  }
                }
                _itemSubtotal = parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount);
                  
                if (_businessSetting.ItemWiseDiscount == 1) {
                  if (_obj.discount_in_amount > 0) {
                    _totalDiscountAmt = parseFloat(_obj.discount_in_amount);
                  }
                }
                if(_itemSubtotal == 0 || _itemSubtotal == '0'){
                  _obj.total_amount = 0;
                }else{
                  _obj.total_amount = _itemSubtotal;
                }
              }

              if (type == "discount_in_amount") {
                if (_value > _itemPriceAK) {
                  alert("Discount cannot be more than item total amount");
                } else {
                  
                  if (_value == "" || _value == "null") {
                    _value = 0;
                  }
                  if(_value <= _itemPriceAK){
                    _obj.discount_in_amount = _value;
                    let _percDisPerc = 0;
                    let _finalDisPrice = 0;
                    if (_obj.discount_in_amount >= 0) {
                      if (field.sale_pruchase_tax_type == "With Tax")
                      {
                        let _costPrice = (parseFloat(_itemPriceAK) / (100 + parseFloat(_obj.tax_type.ratio)) * 100);
                        let _finalDisPrice =  (parseFloat(_costPrice) - parseFloat(_value));
                        _percDisPerc = (_finalDisPrice * (100 + parseFloat(_obj.tax_type.ratio)) ) /100;
                       
                      }
                      if (field.sale_pruchase_tax_type == "Without Tax")
                      {
                        _finalDisPrice =  (parseFloat(_itemPriceAK) - parseFloat(_value));
                        
                        let _calculateAmt = parseFloat(_finalDisPrice) * (parseFloat(100) + parseFloat(_obj.tax_type.ratio));
                        _totalItemAmount = _calculateAmt/100;
                        _percDisPerc = ( parseFloat(_value) / parseFloat(_itemPriceAK)) * 100;
                      }
                    
                    } 
                    _percDisAmt = _obj.discount_in_amount;
                    _obj.discount_in_per = _percDisPerc.toFixed(2);
                  }
                        
                  if (_businessSetting.ItemWiseDiscount == 1) {
                    if (_obj.discount_in_amount > 0) {
                      if(fieldArray.length == 1){
                        _totalDiscountAmt = parseFloat(_obj.discount_in_amount);
                      }else{
                        _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(_obj.discount_in_amount);

                      }
                    }
                  }                  
                  _itemSubtotal = parseFloat(_itemPriceAK) - parseFloat(_obj.discount_in_amount);
                  if(_itemSubtotal == 0 || _itemSubtotal == '0'){
                    _obj.total_amount = 0;
                  }else{
                    _obj.total_amount = _itemSubtotal;
                  }
                }
                
              }
            }

            if (_isNewItem == true) {
              let _newDisAmt = _obj.discount_in_amount > 0 ? _obj.discount_in_amount : 0;
              if (_obj.discount_in_per > 0) {
                _newDisAmt = (parseFloat(_totalItemAmount) * parseFloat(_obj.discount_in_per)) / parseFloat(100);
              }
              _obj.discount_in_amount = _newDisAmt.toFixed(2);
            }

            if (_businessSetting.ItemWiseDiscount == 1)
            {
              if (_discount_in_sale_price > 0) {
                if (_discount_type == "Amount") {
                  if (_discount_in_sale_price == "" || _discount_in_sale_price == "null") {
                    _discount_in_sale_price = 0;
                  }
                  _obj.discount_in_per = _discount_in_sale_price;
                  if (_obj.discount_in_per >= 0) {
                    _percDisAmt = (parseFloat(_totalItemAmount) * parseFloat(_obj.discount_in_per)) / parseFloat(100);
                  }
                  //_obj.discount_in_amount = _percDisAmt.toFixed(2);
                  _obj.discount_in_amount = _discount_in_sale_price;
                  _obj.discount_in_per = 0;
                } else {
                  if (_discount_in_sale_price == "" || _discount_in_sale_price == "null") {
                    _discount_in_sale_price = 0;
                  }
                  let _percDisPerc = 0;
                  if (_obj.discount_in_amount >= 0) {
                    _percDisPerc = (parseFloat(_obj.discount_in_amount) * parseFloat(100)) / parseFloat(_totalItemAmount);
                  }
                  _percDisAmt = _obj.discount_in_amount;
                  _obj.discount_in_per = _discount_in_sale_price;
                  _obj.discount_in_amount = 0;
                }
              }
            }

            if (_businessSetting.ItemWiseTax == 1)
            {
              if (type == "taxType") {
                let _gstObj = {};
                this.state.taxRateArray.map((tax, index) => {
                  let _sel = "";
                  if (_value == tax.id) {
                    _gstObj.id = tax.id;
                    _gstObj.name = tax.name;
                    _gstObj.ratio = tax.ratio;

                    if (tax.ratio >= 0) {
                      if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax" )
                      {
                        _percGstAmt = ((parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount)) * parseFloat(tax.ratio)) / parseFloat(100);
                        console.log("==AK-1---",_percGstAmt);
                      }
                      else if (_businessSetting.AllowInclusiveExclusive == 0) {
                        _percGstAmt = ((parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount)) * parseFloat(tax.ratio)) / parseFloat(100);
                        console.log("==AK-2---",_percGstAmt);
                      }
                      else if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
                      {
                        let _costPrice = (parseFloat(_totalItemAmount) / (100 + parseFloat(tax.ratio)) * 100);
                        let _finalPercenTageP =  (parseFloat(_totalItemAmount) - parseFloat(_costPrice));
                        _percGstAmt =  _finalPercenTageP;
                        
                        //_percGstAmt = ((parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount)) * parseFloat(tax.ratio)) / parseFloat(100);
                        //parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount);
                        //console.log("==AK-3---",_percGstAmt);
                      }
                    }
                  }
                });
                _obj.tax_type = _gstObj;
                console.log("==AK-4---",_percGstAmt);
                if (_percGstAmt < 0) {
                  _obj.tax_value = 0;
                } else {
                  _obj.tax_value = _percGstAmt.toFixed(2);
                  console.log("==AK-5---",_percGstAmt);
                }
                if (
                  _businessSetting.AllowInclusiveExclusive == 1 &&
                  field.sale_pruchase_tax_type == "Without Tax"
                ) {
                  console.log("==PPP==", field.sale_pruchase_tax_type);
                  _itemSubtotal = parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value) - parseFloat(_obj.discount_in_amount);
                }

                if (_businessSetting.AllowInclusiveExclusive == 0) {
                  console.log("==III==", field.sale_pruchase_tax_type);
                  _itemSubtotal =  parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value) - parseFloat(_obj.discount_in_amount);
                }

                if (
                  _businessSetting.AllowInclusiveExclusive == 1 &&
                  field.sale_pruchase_tax_type == "With Tax"
                ) {
                  console.log("==UU==", field.sale_pruchase_tax_type);
                  _itemSubtotal =
                    parseFloat(_totalItemAmount) -
                    parseFloat(_obj.discount_in_amount);
                }
                console.log(_itemSubtotal + "--Sub T--here--88--");
              } 
              else {
                if (_taxTypeIndividual.id > 0) {
                  this.state.taxRateArray.map((tax, index) => {
                    if (_taxTypeIndividual.id == tax.id) {
                      if (tax.ratio >= 0) {
                        if (
                          _businessSetting.AllowInclusiveExclusive == 1 &&
                          field.sale_pruchase_tax_type == "Without Tax"
                        ) {
                          console.log("==UU==", field.sale_pruchase_tax_type);
                          _percGstAmt =
                            ((parseFloat(_totalItemAmount) -
                              parseFloat(_obj.discount_in_amount)) *
                              parseFloat(tax.ratio)) /
                            parseFloat(100);
                        }
                        if (_businessSetting.AllowInclusiveExclusive == 0) {
                          console.log("==TT==", field.sale_pruchase_tax_type);
                          _percGstAmt =
                            ((parseFloat(_totalItemAmount) -
                              parseFloat(_obj.discount_in_amount)) *
                              parseFloat(tax.ratio)) /
                            parseFloat(100);
                        }
                      }
                    }
                  });
                  if (_percGstAmt < 0) {
                    _obj.tax_value = 0;
                  } else {
                    _obj.tax_value = _percGstAmt.toFixed(2);
                  }
                }

                _itemSubtotal = parseFloat(_totalItemAmount) -  parseFloat(_obj.discount_in_amount);
                console.log(_itemSubtotal + "--Sub T--here--99--");
              }
            }

            /* Total Calculation */
            

            if (type == "discount_in_amount")
            {              
                if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
                {
                  _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// -  parseFloat(_totalDiscountAmt);
                  console.log(_totalAmt + "==KHARE-11-==",field.sale_pruchase_tax_type
                  );
                }

                if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax"
                ) 
                {
                  _totalAmt = parseFloat(_totalAmt) +  parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
                  console.log(_totalAmt+"==KHARE-22-==",field.sale_pruchase_tax_type);
                }
                if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax")
                {
                  console.log("==yy-xx==", field.sale_pruchase_tax_type);
                  _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
                }

                if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
                {
                  console.log("==KHARE-3-==", field.sale_pruchase_tax_type);
                  _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);// - parseFloat(_totalDiscountAmt);
                }
            }
            else if (type == "discount_in_per")
            {              
              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
              {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// -  parseFloat(_totalDiscountAmt);
                console.log(_totalAmt + "==KHARE-11-==",field.sale_pruchase_tax_type
                );
              }

              if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax"
              ) 
              {
                _totalAmt = parseFloat(_totalAmt) +  parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
                console.log(_totalAmt+"==KHARE-22-==",field.sale_pruchase_tax_type);
              }
              if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax")
              {
                console.log("==yy-xx==", field.sale_pruchase_tax_type);
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
              }

              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
              {
                console.log("==KHARE-3-==", field.sale_pruchase_tax_type);
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);// - parseFloat(_totalDiscountAmt);
              }
            }
            else{              
              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
              {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value) -  parseFloat(_totalDiscountAmt);
                console.log(_totalAmt + "==KHARE-001-==",field.sale_pruchase_tax_type
                );
              }

              if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax")
              {
                _totalAmt = parseFloat(_totalAmt) +  parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value) - parseFloat(_totalDiscountAmt);
                console.log(_totalAmt+"==KHARE-002-==",field.sale_pruchase_tax_type);
              }

              if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax"
              ) {
                console.log("==yy-xx==", field.sale_pruchase_tax_type);
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value) - parseFloat(_totalDiscountAmt);
              }

              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
              {
                console.log("==KHARE-3-==", field.sale_pruchase_tax_type);
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) - parseFloat(_totalDiscountAmt);
              }
            }


            if (type == "taxType") {
              //this.setState({totalAmount:_totalAmt.toFixed(2),totalAmountOld:_totalAmt.toFixed(2),subtotal:_totalAmt.toFixed(2)})
            }

            /*Add by AK */

            _dueAmount = parseFloat(_totalAmt) - parseFloat(_receivedAmount);
            _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);

            if (this.state.FreeItemQuantity == 1) {
              _quantity = parseFloat(_quantity) + parseFloat(_obj.free_quantity);
            }

            /* End Total Calculation */

            fieldArray[_itemPosition] = _obj;
          }
          
          else if (_itemPosition == 0 && type == "") {
            let _obj = {};
            let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
            let _objRateTax = { value: "1", label: this.state.rate_tax };
            let _objTaxType = { id: "0", name: "", ratio: "0" };

            //_obj.unitArrayOld = _unitindividual;
            _obj.item_id = 0;
            _obj.item_name = "";
            _obj.quantity = 0;
            _obj.unit = _objUnit;
            _obj.rate = 0;
            _obj.rate_tax = _objRateTax;
            _obj.discount_in_per = 0;
            _obj.discount_in_amount = 0;
            _obj.tax_type = _objTaxType;
            _obj.tax_value = 0;
            _obj.sale_pruchase_tax_type = field.purchase_tax_type
              ? field.purchase_tax_type
              : "";
            _obj.subtotal = 0;
            _obj.total_amount = 0;
            _obj.availLenAk = 0;
            _obj.totalStocks = 0;
            _obj.trans_id = 0;
            _obj.Additional_CESS = _Additional_CESS;
            _obj.itemBatchNoAllData = [];
            _obj.itemSerialNoAllData = [];
            fieldArray[_itemPosition] = _obj;
          } 

          else {
            let _itemId = field.item_id ? field.item_id : 0;
            if (_itemId > 0) {
              let _obj = {};
              console.log("==here--2--", field.total_amount);
              _obj.item_id = field.item_id ? field.item_id : 0;
              _obj.trans_id = 0;
              _obj.item_name = field.item_name;
              _obj.quantity = field.quantity ? field.quantity : "0";
              _obj.free_quantity = field.free_quantity ? field.free_quantity : "0";
              _obj.discount_type =  field.discount_type ? field.discount_type : '';
              _obj.rate = field.rate ? field.rate : 0;
              _obj.discount_in_per = field.discount_in_amount ? field.discount_in_amount: 0;
              _obj.discount_in_amount = field.discount_in_per ? field.discount_in_per : 0;
              _obj.tax_value = field.tax_value ? field.tax_value : 0;
              _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type ? field.sale_pruchase_tax_type : "NA3";
              _obj.subtotal = field.subtotal ? field.subtotal : 0;
              _obj.total_amount = field.total_amount ? field.total_amount : 0;
              _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
              _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
              _obj.Additional_CESS = field.Additional_CESS > 0 ? field.Additional_CESS : 0;

              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
              {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount);// + parseFloat(field.tax_value);// - parseFloat(field.discount_in_amount); // Add by AK 
                console.log("==KHARE-4-==", _totalAmt);
              } 
              else if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax")
              {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount);// + parseFloat(field.tax_value) - parseFloat(field.discount_in_amount); // Add by AK
                console.log("==KHARE-5-==", _totalAmt);
              } 
              else if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
             {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount);

                console.log("==KHARE-777-==", _totalAmt);
                _totalAmt = parseFloat(_totalAmt);// - parseFloat(field.discount_in_amount); // Add by AK
                console.log("==KHARE-6-==", _totalAmt);
              } 
              else if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax")
              {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount) + parseFloat(field.tax_value) - parseFloat(field.discount_in_amount);
                //_totalAmt = this.state.totalAmountOld; // Add by AK
                console.log("==KHARE-5666-==", _totalAmt);
              }
              console.log("===11 _totalAmt AAKK==", _totalAmt);
              _dueAmount = parseFloat(_totalAmt) - parseFloat(_receivedAmount);
              _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
              if (this.state.FreeItemQuantity == 1) {
                _quantity =
                  parseFloat(_quantity) + parseFloat(_obj.free_quantity);
              }
              _totalGstAmt = parseFloat(this.state.gstAmount);// + parseFloat(_obj.discount_in_per);
              console.log("--here T GST--", _totalGstAmt);
            }
          }
        });
        console.log(this.state.selectTypeTxt + "===_totalAmt AAKK==", _totalAmt);
        if (this.state.toggleState == true) {
        }
        else {
          let _receivedAM = 0;
          if (_receivedAmount == 0) {
            _receivedAM = 0;
          } else {
            _receivedAM = _receivedAmount; //.toFixed(2);
          }
        }
        /*Final Calculation Discount and Tax */
        let _amountDisK = this.state.totalDiscount ?  this.state.totalDiscount : 0;
        let _amountTaxK = this.state.gstAmount ? this.state.gstAmount : 0;
        let _discountTaxAk = 0;

        fieldArray.map((field, idx) => {
          if(idx ==  _itemPosition){
            console.log("==field KHARE---==", JSON.stringify(field));
            let _discountAmtAk = field.discount_in_amount ? field.discount_in_amount: 0;
            _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);// + parseFloat(this.state.totalDiscount);
            if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
            {
              _discountTaxAk = field.tax_value ? field.tax_value : 0;
              _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
            }
            if (field.rate == 0 || field.rate == "" || field.rate == "0") {
              handleItemSalePurchasePrice = "Disabled";
            }
            console.log("==FF==_amountDisK==",_amountDisK);
            let _totalAmountNew = _totalAmt;
            this.setState({
              totalDiscount: _totalDiscountAmt.toFixed(2),
              handleAddDisable: "",
              gstAmount: _totalGstAmt,
              handleItemSalePurchasePrice: handleItemSalePurchasePrice,
              totalAmountOld: _totalAmt,
              totalAmountNew: _totalAmt,
              totalAmount: _totalAmt,
            });
          }
        });
        
        console.log(_totalGstAmt+"==final AMT===", _totalAmt);
        /*End Calculation Discount and Tax */
        console.log("==demo---demo---", fieldArray);
        let _totalAmountOld = _totalAmt;
        let _totalAmountNew = _totalAmt;
        let _paidAM = this.state.receivedAmount;
        this.props.callBackItem(
          fieldArray,
          _totalAmountOld,
          _totalAmountNew,
          _totalAmt,
          _dueAmount,
          _paidAM,
          _totalDiscountAmt,//_amountDisK,//this.state.totalDiscount,
          _totalGstAmt,//_amountTaxK,//.toFixed(2),
          this.state.totalQuantity,
          this.state.handleAddDisable,
          handleItemSalePurchasePrice
        );
      }
    }
    this.onMouseLeaveHandlerItem("I", _itemPosition);
    this.onItemFilterName(_itemPosition);
  }
  
  handlePermanentRemove(value,itemId,_itemTransId) {
    confirmAlert({
      title: "Confirm to delete",
      message: "By removing this item, this item will be permanently deleted from this transaction. Are you still want to remove this item?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: itemId,
              transactionId: this.state.transactionId,
              itemTransId: _itemTransId,
            });
            this.handleRemove(value,itemId);
            PostData(global.userTransationItemDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getItemListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  dynamicInputFiled() {
    console.log('_itemsArrayMap', this.state.fields.length)
    let _dataSet = [];
    if (this.state.fields.length > 0) {
      let _j = 0;
      let _itemsArrayMap = this.state.fields;
      console.log('_itemsArrayMap', JSON.stringify(_itemsArrayMap))
      if (this.state.transactionId > 0) {
        _itemsArrayMap.map((fields, ind) => {
          this.state.itemArrayFilter.map((items, i) => {
            let _itemUnitArray = [];
            if (fields.item_id == items.item_id) {
              let _itmUnit = items.unit ? items.unit : "";
              if (_itmUnit == "") {
                _itemUnitArray = this.state.unitArray;
              } else {
                _itemUnitArray.push(items.unit);
              }
            } else {
              _itemUnitArray = this.state.unitArray;
            }
            //fields.unitArrayOld = _itemUnitArray;
          });
        });
      }
      let _amountDisK = 0;
      let _disableBtn = "";
      let _readonly = "";
      let _itemTransId = 0;
      _itemsArrayMap.map((field, idx) => {
        _j++;

        let _key = idx + 222;
        let _hideMenuItem = "iti__hide";
        if (this.state._itemRegIndex == idx) {
          _hideMenuItem = this.state._itemReg;
        }

        let _itemId = field.item_id ? field.item_id : 0;
        if(field.item_name !='' && (_itemId > 0)){
          _disableBtn  = "disabled";
          _readonly  = "readOnly";
        }
        else{
          _disableBtn  = "";
          _readonly  = "";
        }
        let _unitArray = field.unit
          ? field.unit
          : { unit_id: "0", unit_name: "", old_unit_id: 0 };

        let _taxTypeArray = field.tax_type
          ? field.tax_type
          : { id: "0", name: "", ratio: "0" };
        let _taxTypeId = "0";
        if (_taxTypeArray.id > 0) {
          _taxTypeId = _taxTypeArray.id;
        }

        let _unitId = _unitArray.unit_id ? _unitArray.unit_id : 0;
        let _oldunitId = _unitArray.old_unit_id ? _unitArray.old_unit_id : 0;
        if (_unitArray.unit_id > 0) {
          _unitId = _unitArray.unit_id;
        }

        //let _unitPreArray = field.unitArrayOld;
        let _unitPreArray = this.state.unitArray;
        if (_itemId == 0) {
          _unitPreArray = this.state.unitArray;
        }
        let totalBatchQty = 0;
        let OldUsedStockQty = 0;

        if (
          field.itemBatchNoAllData!=undefined &&
          field.itemBatchNoAllData!=""
        ) {
          if (field.itemBatchNoAllData[0]!=undefined) {
            let OldUsedStockQty = field.itemBatchNoAllData[0].used_stock
              ? field.itemBatchNoAllData[0].used_stock
              : 0;
            let _batchFreeQty = field.itemBatchNoAllData[0].free_quantity
              ? field.itemBatchNoAllData[0].free_quantity
              : 0;

            totalBatchQty = parseInt(OldUsedStockQty) + parseInt(_batchFreeQty);
          }
          /**/
        }
        let _used_stockAK = 0;
        if (field.length!=0 && field.itemBatchNoAllData.length > 0) {
          //_used_stockAK =  field.itemBatchNoAllData[0].used_stock ? field.itemBatchNoAllData[0].used_stock  : 0;
        }
        const itemTaxTypeData = [
          { value: "With Tax", label: "With Tax" },
          { value: "Without Tax", label: "Without Tax" },
        ];
        //this.setState({used_stockAK: _used_stockAK});
        _itemTransId = field.inv_item_id ?  field.inv_item_id : 0;
        let _readonlyFieldDup = false;
        if(this.state.duplicateSale === 'DUPLICATE'){
          _readonlyFieldDup = true;
        }
        _dataSet.push(
          <tr key={_key}>
            <td>
              {/* {this.state.sendPageType == 'Duplicate' &&
                <button
                type="button"
                onClick={this.handleRemove.bind(this, idx, _itemId)}
                className="btn btn-outline-danger"
              >
                X
              </button>
              }  */}
              {this.state.sendPageType!='Duplicate' &&
              <div>
                {(_itemTransId == 0) &&
                  <button
                    type="button"
                    onClick={this.handleRemove.bind(this, idx, _itemId)}
                    className="btn btn-outline-danger"
                  >
                    X
                  </button>
                }
                {(this.state.transactionId > 0 && _itemTransId > 0) &&
                  <button
                    type="button"
                    onClick={this.handlePermanentRemove.bind(this, idx, _itemId,_itemTransId)}
                    className="btn btn-outline-danger"
                  >
                    X
                  </button>
                }
              </div>  
              }
            </td>
            <td>{_j}</td>
            <td>
              <div className="col-lg-12">
                {/* onMouseEnter={this.manageShowItemFilter.bind(this,'I',idx)} onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)} */}
                <div
                  className="form-material open"
                  id="paddingOff"
                  onMouseLeave={this.onMouseLeaveHandlerItem.bind(
                    this,
                    "I",
                    idx
                  )}
                >
                  <input
                    type="text"
                    className="form-control search-box"
                    onChange={this.onItemFilterTypeName.bind(this, idx)}
                    onClick={this.manageShowItemFilter.bind(this, "I", idx)}
                    onKeyDown={this.manageShowItemFilter.bind(this, "I", idx)}
                    placeholder="Item Name Here..."
                    autoComplete="off"
                    name="item_name"
                    id={"item_name_" + idx}
                    value={field.item_name ? field.item_name : ""}
                    disabled={_disableBtn}
                    readOnly={_readonly}
                  />
                  <div id={"itemListData_" + idx}>
                    <ul
                      className={
                        _hideMenuItem +
                        " iti__party-list iti__party-list--dropup"
                      }
                      name={"country-listbox_" + idx}
                      id="country-listbox"
                      aria-expanded="false"
                      role="listbox"
                      aria-activedescendant="iti-item-ad"
                    >
                      {this.getReadyItemList(idx)}
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            {this.state.transactionId == 0 &&
              this.state.batchItemEnable == 1 &&
              this.state.used_stockAK > 0 && (
                <td>
                  {field.length!=0 && field.itemBatchNoAllData.length > 0 && (
                    <button
                      className="btn btn-info"
                      href="#"
                      title="View Batch"
                      onClick={() =>
                        this.clickSrialBatchD(
                          idx,
                          "Btach",
                          field.itemBatchNoAllData,
                          field.itemBatchNo,
                          field.item_id,
                          field.item_name,
                          field.rate,
                          totalBatchQty,
                          field.itemBatchNoAllData[0].used_stock ? field.itemBatchNoAllData[0].used_stock : 0
                        )
                      }
                    >
                      <i className="fa fa-eye"  title="View Batch"></i>
                    </button>
                  )}
                </td>
              )}
            {this.state.transactionId > 0 &&
              this.state.batchItemEnable == 1 && (
                <td>
                  {field.length!=0 && field.itemBatchNoAllData.length > 0 && (
                    <button
                      className="btn btn-info"
                      href="#"
                      title="View Batch"
                      onClick={() =>
                        this.clickSrialBatchD(
                          idx,
                          "Btach",
                          field.itemBatchNoAllData,
                          field.itemBatchNo,
                          field.item_id,
                          field.item_name,
                          field.rate,
                          totalBatchQty,
                          field.itemBatchNoAllData[0].used_stock ? field.itemBatchNoAllData[0].used_stock : 0
                        )
                      }
                    >
                      <i className="fa fa-eye"  title="View Batch"></i>
                    </button>
                  )}
                </td>
              )}
            {this.state.serialItemEnable == 1 && (
              <td>
                {
                  field.length!=0 && field.itemSerialNoAllData.length > 0 && (
                    <button
                      onClick={() =>
                        this.clickSrialBatchD(
                          idx,
                          "Serial",
                          field.itemSerialNoAllData,
                          field.itemSerialNo,
                          field.item_id,
                          field.item_name,
                          field.rate,
                          0
                        )
                      }
                      className="btn btn-info"
                      href="#"
                      title="View Serial"
                    >
                       <i className="fa fa-eye" title="View Serial"></i>
                    </button>
                  )
                  //itemSerialNoAll={this.state.itemSerialNo}  itemId={this.state.itemId}   itemName={this.state.name}
                }
              </td>
            )}
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  maxLength="100"
                  required="required"
                  className="form-control"
                  id={"_qty_" + idx}
                  name={"_qty_" + idx}
                  value={field.quantity ? field.quantity : ""}
                  onChange={this.handleDynamicInputs.bind(
                    this,
                    idx,
                    "quantity"
                  )}
                  readOnly
                />
              </div>
            </td>
            {this.state.FreeItemQuantity == 1 && (
              <td>
                {field.length!=0 &&
                  Object.keys(field.itemBatchNoAllData).length > 0 && (
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        min="0"
                        maxLength="100"
                        required="required"
                        className="form-control"
                        id={"_free_quantity_" + idx}
                        name={"_free_quantity_" + idx}
                        value={field.free_quantity ? field.free_quantity : ""}
                        onChange={this.handleDynamicInputs.bind(
                          this,
                          idx,
                          "free_quantity"
                        )}
                        demoK="B"
                        readOnly
                      />
                    </div>
                  )}

                {field.length!=0 && field.itemSerialNoAllData.length > 0 && (
                  <div className="col-lg-12 form-material" id="paddingOff">
                    <input
                      type="number"
                      min="0"
                      maxLength="100"
                      required="required"
                      className="form-control"
                      id={"_free_quantity_" + idx}
                      name={"_free_quantity_" + idx}
                      value={field.free_quantity ? field.free_quantity : ""}
                      onChange={this.handleDynamicInputs.bind(
                        this,
                        idx,
                        "free_quantity"
                      )}
                      demoK="S"
                      readOnly={_readonlyFieldDup}
                    />
                  </div>
                )}

                {field.length!=0 && field.normal_quantity > 0 && (
                  <div className="col-lg-12 form-material" id="paddingOff">
                    <input
                      type="number"
                      min="0"
                      maxLength="100"
                      required="required"
                      className="form-control"
                      id={"_free_quantity_" + idx}
                      name={"_free_quantity_" + idx}
                      value={field.free_quantity ? field.free_quantity : ""}
                      onChange={this.handleDynamicInputs.bind(
                        this,
                        idx,
                        "free_quantity"
                      )}
                      demoK="N"
                      readOnly={_readonlyFieldDup}
                    />
                  </div>
                )}
                {field.length == undefined &&
                  field.normal_quantity == undefined &&
                  field.length!=0 &&
                  field.itemSerialNoAllData.length == 0 &&
                  field.itemBatchNoAllData.length == 0 && (
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        min="0"
                        maxLength="100"
                        required="required"
                        className="form-control"
                        id={"_free_quantity_" + idx}
                        name={"_free_quantity_" + idx}
                        value={field.free_quantity ? field.free_quantity : ""}
                        onChange={this.handleDynamicInputs.bind(
                          this,
                          idx,
                          "free_quantity"
                        )}
                        demoK="N-UN"
                        readOnly={_readonlyFieldDup}
                      />
                    </div>
                  )}
              </td>
            )}
            {this.state.TransCount == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <input
                    type="number"
                    min="0"
                    maxLength="100"
                    required="required"
                    className="form-control"
                    id={"_trans_count_" + idx}
                    name={"_trans_count_" + idx}
                    value={field.trans_count ? field.trans_count : ""}
                    onChange={this.handleDynamicInputsTransCount.bind(this,idx,"trans_count")}
                    readOnly={_readonlyFieldDup}
                  />
                </div>
              </td>
            )}
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <select
                  disabled={this.state.sendPageType == 'Duplicate'}
                  style={{ width: "90px" }}
                  className="form-control"
                  id={"seUnit_" + idx}
                  name={"seUnit_" + idx}
                  onChange={this.onChangeCalculation.bind(this, idx, "unit")}
                  readOnly={_readonlyFieldDup}
                >
                  {_unitId > 0 ? (
                    ""
                  ) : (
                    <option value="" selected>
                      None
                    </option>
                  )}
                  {_unitPreArray.map((item, index) => {
                    let _selBase = "";
                    if (_unitId > 0 && _oldunitId == 0) {
                      if (_unitId == item.unit_id) {
                        _selBase = "Selected";
                        }
                        return (
                          <option
                            key={index}
                            data-Id={_unitId}
                            value={item.unit_name}
                            selected={_selBase}
                          >
                            {item.unit_name}
                          </option>
                        );
                    } 
                    else {
                      return (
                        <option
                          key={index}
                          data-Id={_unitId}
                          value={item.unit_name}
                          selected={_selBase}
                        >
                          {item.unit_name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  disabled={this.state.sendPageType == 'Duplicate'}
                  type="number"
                  min="0"
                  required="required"
                  className="form-control"
                  id={"rate_" + idx}
                  name={"rate_" + idx}
                  value={field.rate ? field.rate : ""}
                  onChange={this.onChangeCalculation.bind(this, idx, "rate")}
                  readOnly={_readonlyFieldDup}
                />
              </div>
            </td>
            {_businessSetting.AdditionalCESS == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <input
                    type="number"
                    min="0"
                    required="required"
                    className="form-control"
                    id={"Additional_CESS_" + idx}
                    name={"Additional_CESS_" + idx}
                    value={field.Additional_CESS ? field.Additional_CESS : ""}
                    onChange={this.onChangeCalculation.bind(this,idx,"Additional_CESS")}
                    readOnly={_readonlyFieldDup}
                  />
                </div>
              </td>
            )}
            {_businessSetting.AllowInclusiveExclusive == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <select
                    style={{ width: "90px" }}
                    className="form-control"
                    id={"salePurTaxType_" + idx}
                    name={"salePurTaxType_" + idx}
                    onChange={this.handleDynamicInputSalePurTaxType.bind(this,idx,"salePurTaxType")}
                    readOnly={_readonlyFieldDup}
                    disabled={_readonlyFieldDup}
                  >
                    {itemTaxTypeData.map(({ value, label }, index) => {
                      let _selSetTaxT = "";
                      if (field.sale_pruchase_tax_type == value) {
                        _selSetTaxT = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={value}
                          selected={_selSetTaxT}
                        >
                          {label}
                        </option>
                      );
                    })}
                    {/* <option  value="With Tax" >With Tax</option>
                      <option  value="Without Tax" >Without Tax</option> */}
                  </select>
                </div>
              </td>
            )}

            {_businessSetting.ItemWiseDiscount == 1 && (
              <td>
                <tr>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        style={{ width: "60px" }}
                        min="0"
                        maxLength="2"
                        className="form-control"
                        id={"discount_in_per_" + idx}
                        value={field.discount_in_per ? field.discount_in_per : ""}
                        name={"discount_in_per_" + idx}
                        autoComplete="off"
                        onChange={this.onChangeCalculation.bind(this,idx,"discount_in_per")}
                        max="100"
                        readOnly={_readonlyFieldDup}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        min="0"
                        maxLength="99999"
                        className="form-control"
                        id={"discount_in_amount_" + idx}
                        value={field.discount_in_amount ? field.discount_in_amount  : ""}
                        name={"discount_in_amount_" + idx}
                        autoComplete="off"
                        onChange={this.onChangeCalculation.bind(this,idx,"discount_in_amount")}
                        readOnly={_readonlyFieldDup}
                      />
                    </div>
                  </td>
                </tr>
              </td>
            )}
            {_businessSetting.ItemWiseTax == 1 && (
              <td>
                <tr>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <select
                        style={{ width: "85px" }}
                        className="form-control"
                        id={"taxType_" + idx}
                        name={"taxType_" + idx}
                        onChange={this.onChangeCalculation.bind(this,idx,"taxType")}
                        readOnly={_readonlyFieldDup}
                        disabled={_readonlyFieldDup}
                      >
                        <option value="0" selected>
                          {" "}
                          None
                        </option>
                        {this.state.taxRateArray.map((tax, index) => {
                          let _sel = "";
                          if (_taxTypeId == tax.id) {
                            _sel = "selected";
                          }
                          return (
                            <option key={index} value={tax.id} selected={_sel}>
                              {tax.name + "@" + tax.ratio + "%"}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        readOnly
                        min="0"
                        maxLength="100"
                        className="form-control demo1"
                        value={field.tax_value ? field.tax_value : ""}
                        id={"tax_value_" + idx}
                        name={"tax_value_" + idx}
                      />
                    </div>
                  </td>
                </tr>
              </td>
            )}
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  id={"subtotal_" + idx}
                  name={"subtotal_" + idx}
                  value={field.total_amount ? field.total_amount : ""}
                />
                {/* Add by AK */}
              </div>
            </td>
          </tr>
        );
      });
    }
    return _dataSet;
  }
  /* End Add More Data */
  /* Start Item Code */
  onItemFilterName = (a) => {
    const data = this.state.itemArray;
    let fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        data.filter(function (item, index) {
          if (item.item_id == field.item_id && item.itemSerialNo.length > 0) {
            //data[index].isFilter = true;
          }
        });
      });
    }
    this.setState({ itemArrayFilter: data });
    this.getReadyItemList();
  };

  onItemFilterTypeName = (idx, e) => {
    const data = this.state.itemArray;
    let _name = e.target.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );

    const fieldArray = this.state.fields;
    fieldArray.map((field, index) => {
      if (index == idx) {
        field.item_name = keyword;
      }
    });

    this.setState({
      itemArrayFilter: filtered,
      item_name: keyword,
      fields: fieldArray,
    });
    this.getReadyItemList();
  };

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  getReadyItemList(index) {
    let data = [];
    if (this.state.isLoaded == true) {
      let _itemsArray = this.state.itemArrayFilter;
      if (_itemsArray.length > 0) {
        data = this.state.itemArrayFilter.map((items, i) => {
          if (items.isFilter == false) {
            let _obj = {};
            let _itemUnitArray = [];

            if (items.base_unit_id > 0) {
              _itemUnitArray.push(items.unit);
            } else {
              _itemUnitArray.push(this.state.unitArray);
            }

            _obj.index = index;
            _obj.name = items.name;
            _obj.id = items.item_id;
            _obj.item_type = items.item_type;
            _obj.availableQty = items.availableQty;
            _obj.type = items.type;
            _obj.price = items.sale_price;
            _obj.purchase_price = items.purchase_price;
            _obj.base_unit_id = items.base_unit_id;
            _obj.unitindividual = _itemUnitArray;
            _obj.tax_type = items.tax_type;
            _obj.discount_in_sale_price = items.discount_in_sale_price;
            _obj.discount_type = items.discount_type;
            _obj.itemSerialNo = items.itemSerialNo;
            _obj.itemBatchNo = items.itemBatchNo;
            _obj.normal_remaining_quantity = items.normal_remaining_quantity;
            _obj.sale_pruchase_tax_type = items.sale_tax_type ? items.sale_tax_type : "";
            if(_businessSetting.AllowInclusiveExclusive === 0){
              _obj.sale_pruchase_tax_type =  "Without Tax";
            }
            _obj.Additional_CESS =
              items.Additional_CESS > 0 ? items.Additional_CESS : 0;

            return (
              <li
                key={i}
                className="iti__party iti__standard"
                id="iti-item-in"
                onClick={this.handleDynamicInputs.bind(this, _obj, "item")}
              >
                <span className="iti__party-name">
                  {items.name} <br />
                  {this.state.DisplayPurchasePrice == 1 && (
                    <span> Purchase Price : {items.purchase_price}</span>
                  )}
                </span>
              </li>
            );
          }
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            // onClick={this.openModalHandler.bind(this, "modalAdjustNewItem")}
            onClick={() => {
              this.handleRemove(index);
              this.openModalHandler("modalAdjustNewItem");
            }}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Item
            </span>
          </li>
        );
      }
    }
    return data;
  }
  /* End Item Code */

  render() {
    return (
      <React.Fragment>
        {this.state.EnableItem == 1 && (
          <div className="row">
            <div className="col-md-12" id="formSideItem">
              <div className="block block-transparent">
                <div className="block-content" id="formSideItem">
                  <table className="table table-bordered table-striped table-vcenter">
                    <thead id="sideTable">
                      <tr>
                        <th style={{ width: "30px" }}></th>
                        <th style={{ width: "30px" }}>#</th>
                        <th style={{ width: "200px" }}>Item</th>
                        {this.state.transactionId == 0 &&
                          this.state.batchItemEnable == 1 &&
                          this.state.used_stockAK > 0 && (
                            <th style={{ width: "50px" }}>Batch</th>
                          )}
                        {this.state.transactionId > 0 &&
                          this.state.batchItemEnable == 1 && (
                            <th style={{ width: "50px" }}>Batch</th>
                          )}
                        {this.state.serialItemEnable == 1 && (
                          <th style={{ width: "50px" }}>Serial</th>
                        )}
                        <th style={{ width: "50px" }}>Qty</th>
                        {this.state.FreeItemQuantity == 1 && (
                          <th style={{ width: "50px" }}>Free Qty</th>
                        )}
                        {this.state.TransCount == 1 && (
                          <th style={{ width: "50px" }}>Count</th>
                        )}
                        <th style={{ width: "90px" }}>Unit</th>
                        <th style={{ width: "150px" }}>
                          Price / Unit
                          {/* <tr><td style={{width:"194px"}}>Price / Unit</td></tr>
                            <tr>
                                {_businessSetting.AllowInclusiveExclusive == 1 &&
                                <td style={{width:"140px"}}>
                                <select name="rate_tax" id="rate_tax" onChange={this.handleChange}>
                                    <option value="With Tax">With Tax</option>
                                    <option value="Without Tax">Without Tax</option>
                                </select>
                                </td>
                                }
                          </tr> */}
                        </th>
                        {_businessSetting.AdditionalCESS == 1 && (
                          <th style={{ width: "90px" }}>Additional CESS</th>
                        )}
                        {_businessSetting.AllowInclusiveExclusive == 1 && (
                          <th style={{ width: "150px" }}> Tax Type </th>
                        )}

                        {_businessSetting.ItemWiseDiscount == 1 && (
                          <th style={{ width: "250px" }}>
                            <tr>
                              <td style={{ width: "194px" }} colSpan="2">
                                Discount
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "130px" }}>%</td>
                              <td style={{ width: "100px" }}>Amount</td>
                            </tr>
                          </th>
                        )}
                        {_businessSetting.ItemWiseTax == 1 && (
                          <th style={{ width: "300px" }}>
                            <tr>
                              <td style={{ width: "194px" }} colSpan="2">
                                Tax
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50px" }}>%</td>
                              <td style={{ width: "100px" }}>Amount</td>
                            </tr>
                          </th>
                        )}
                        <th style={{ width: "100px" }}>Amount</th>
                      </tr>
                    </thead>

                    <tbody id="sideTable">
                      {this.dynamicInputFiled()}

                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.sendPageType!== 'Duplicate' &&
                            <button
                              className="badge badge-primary pull-left"
                              onClick={this.handleAdd.bind(this)}
                            >
                              Add Item
                            </button>
                          }
                          <span className="titleTable">Total</span>
                        </td>
                        {this.state.batchItemEnable > 0 && <td></td>}
                        {this.state.serialItemEnable > 0 && <td></td>}
                        <td>
                          <span className="titleTable">
                            {this.state.totalQuantity}
                          </span>
                        </td>
                        {_businessSetting.FreeItemQuantity > 0 && <td></td>}
                        {_businessSetting.Count > 0 && <td></td>}

                        <td></td>

                        <td></td>
                        {_businessSetting.AdditionalCESS == 1 && <td></td>}

                        {_businessSetting.AllowInclusiveExclusive > 0 && (
                          <td></td>
                        )}
                        {_businessSetting.ItemWiseDiscount == 1 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalDiscount}
                            </span>
                          </td>
                        )}

                        {_businessSetting.ItemWiseTax == 1 && (
                          <td>
                            <span className="titleTable">
                              {this.state.gstAmount}
                            </span>
                          </td>
                        )}

                        {this.state.transactionId > 0 && (
                          <td>
                            {/* <span className="titleTable">{(this.state.totalQuantity > 0) ? this.state.subtotal : 0}</span> */}
                            {(parseFloat(this.state.subtotal) !== parseFloat(this.state.totalAmount)) && 
                              <span className="titleTable">
                                {this.state.subtotal}
                              </span>
                            }
                            {/* {(parseFloat(this.state.totalAmount) > parseFloat(this.state.subtotal)) && 
                              <span className="titleTable">
                                {this.state.totalAmount}
                              </span>
                            } */}
                            {(parseFloat(this.state.subtotal) === parseFloat(this.state.totalAmount)) &&
                              <span className="titleTable">
                                {this.state.totalAmount}
                              </span>
                            }
                            {/* <span className="titleTable">
                              {this.state.totalAmount <= 0
                                ? this.state.subtotal
                                : this.state.totalAmount}
                            </span> */}
                          </td>
                        )}
                        {this.state.transactionId === 0 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalQuantity > 0
                                ? this.state.totalAmountOld
                                : 0}
                            </span>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal Edit Item Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SITEM}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Item</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewItem
                    callBack={(data) => {
                      let _itemArray = this.state.itemArray;
                      _itemArray.push(data[0]);
                      let _quantityAK = data[0].opening_stock ? data[0].opening_stock : 0;
                      let _totalQuantity = 0;//parseInt(_quantityAK) + parseInt(this.state.totalQuantity);

                      let _totalGstAmt = this.state.gstAmount;
                      let _totalDiscount = this.state.totalDiscount;
                      let _totalAmountOld = this.state.totalAmountOld;
                      let percenTageAmt = 0;
                      let _Additional_CESSAK = 0;

                      if (_totalDiscount == 0 || _totalDiscount == "") {
                        _totalDiscount = data[0].discount_in_sale_price;
                      }
                      if (_totalGstAmt == 0 || _totalGstAmt == "") {
                        _totalGstAmt = data[0].tax_rate_ratio;
                      }
                      percenTageAmt =
                        parseFloat(data[0].sale_price) *
                        (data[0].tax_type.ratio / 100);

                      if (_totalGstAmt > 0) {
                        _totalGstAmt =
                          parseFloat(percenTageAmt) +
                          parseFloat(this.state.gstAmount);
                      } else {
                        _totalGstAmt =
                          parseFloat(_totalGstAmt) + parseFloat(percenTageAmt);
                      }
                      let _freeQty = 0;
                      let _itemBatchNo = data[0].itemBatchNo;
                      if (data[0].itemSerialNo.length > 0) {
                        _quantityAK = data[0].itemSerialNo.length;
                      }
                      if (data[0].itemBatchNo.length > 0) {
                        let _totalBatcQty = 0;
                        _freeQty = 0;
                        _itemBatchNo.map((batchData, index) => {
                          console.log("==data batch===", batchData);
                          _freeQty = batchData.free_quantity
                            ? batchData.free_quantity
                            : 0;
                          //_totalBatcQty = batchData.used_stock + _freeQty;
                          _quantityAK = batchData.opening_stock;
                        });
                        //_quantityAK = _totalBatcQty;
                      }
                      console.log(_quantityAK+"==_quantityAK==",data[0]);
                      const arrayLength = parseInt(this.state.fields.length) + parseInt(1);
                      console.log(this.state.fields.length+"==arrayLength==",arrayLength);
                      let objItemData = {};
                      let _unitArray = [];
                      _unitArray.push(data[0].unit);

                      objItemData.item_id = data[0].item_id;
                      objItemData.item_name = data[0].name;
                      objItemData.quantity = _quantityAK;
                      objItemData.free_quantity = _freeQty;
                      objItemData.trans_count = 0;
                      objItemData.rate = data[0].sale_price;
                      objItemData.discount_type =  data[0].discount_type;
                      objItemData.discount_in_per = data[0].discount_in_sale_price;
                      objItemData.discount_in_amount = data[0].discount_in_sale_price;
                      objItemData.tax_value = data[0].tax_rate_ratio;
                      objItemData.subtotal = 0;
                      objItemData.total_amount = (parseFloat(_quantityAK) * parseFloat(data[0].sale_price)).toFixed(2);
                      objItemData.availLenAk = 0;
                      objItemData.totalStocks = 0;
                      objItemData.availableQty = 0;
                      objItemData.type = "ITEM";
                      objItemData.unit = data[0].unit;
                      objItemData.unitArrayOld = _unitArray;
                      //objItemData.rate_tax = data[0].tax_rate_ratio;
                      objItemData.tax_type = data[0].tax_type;
                      objItemData.itemSerialNoAllData = data[0].itemSerialNo;
                      objItemData.itemBatchNoAllData = data[0].itemBatchNo;
                      objItemData.unitindividual = data[0].unit;
                      objItemData.sale_pruchase_tax_type = data[0].sale_tax_type;
                      
                      if(_businessSetting.AllowInclusiveExclusive === 0){
                        objItemData.sale_pruchase_tax_type =  "Without Tax";
                      }
                      objItemData.Additional_CESS = data[0].Additional_CESS ? data[0].Additional_CESS  : 0;
                      _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(data[0].Additional_CESS);
                      let newArray = [];
                      for (let index = 0; index <= arrayLength; index++) {
                        console.log(index+"==index==",this.state.fields[index]);
                        if (this.state.fields[index]) {
                          console.log(this.state.fields[index]['item_name']+"==item_name==",objItemData.item_name);
                          if(this.state.fields[index]['item_name'] == objItemData.item_name) {
                              newArray.push(objItemData);
                          }
                          else {
                              newArray.push(this.state.fields[index]);
                          }
                          //newArray.push(objItemData);
                          console.log(this.state.fields.length+"==newArray==",objItemData);
                        }
                      }

                      let _itemTotal = 0;//(parseFloat(_quantityAK) * parseFloat(data[0].sale_price)).toFixed(2);
                      let _totalAmtAK = 0;//parseFloat(_totalAmountOld) + parseFloat(_totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)) + parseFloat(_itemTotal);

                      let _totalAmt = 0;//parseFloat(_totalAmountOld) + parseFloat(_totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)) + parseFloat(_itemTotal);

                      let _dueAmount = 0;//parseFloat(_totalAmountOld) + parseFloat( _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)) + parseFloat(_itemTotal);

                      let _paidAM = 0;//this.state.receivedAmount;
                      let _totalAmountNewSend = _totalAmountOld;
                      let _totalAmountOldSend = _totalAmtAK;
                      let _handleAddDisable = "";
                      this.props.callBackItem(
                        newArray,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        _handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: newArray,
                        itemArrayFilter: _itemArray,
                        itemArray: _itemArray,
                        isLoaded: true,
                        itemPosition: -1,
                        modalIsOpen_SITEM: false,
                        totalQuantity: _totalQuantity,
                        totalAmount: _totalAmtAK.toFixed(2),
                        totalAmountOld: _totalAmtAK.toFixed(2),
                        gstAmount: _totalGstAmt.toFixed(2),
                        totalDiscount: _totalDiscount,
                        dueAmount: _totalAmtAK.toFixed(2),
                        handleAddDisable: "",
                      });
                    }}
                    newItemId={this.state.newItemId}
                    itemName={this.state.item_name}
                    itemCreateType="Sale"
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Item Details*/}

        {/* Modal View Batch Serial Data Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BatchViewPopup}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddBatchNo"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Data</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddBatchViewPop"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>

                <div className="block-content">
                  {this.state.popuptype == "Btach" && (
                    <div>
                      <h3>Batch Data</h3>

                      {this.state.popupVal.map((element, index) => (
                        <div
                          style={{
                            height: "40px !important",
                            margin: "0 0 14px 0 !important",
                          }}
                          className="block-content"
                        >
                          {this.state.transactionId > 0 && (
                            <div>
                              {this.state.transactionId > 0 &&
                                _businessSetting.BatchNoChk == 1 && this.state.sendPageType === 'Normal' && (
                                  <button
                                    className="btn btn-info pull-right"
                                    onClick={this.serialPopChnage.bind(
                                      this,
                                      "modalAddBatchNo",
                                      "Old",
                                      this.state.itemPosition,
                                      this.state.OldChnageQuantity,
                                      this.state.OldUsedStockQty,
                                      element.batch_no
                                    )}
                                  >
                                    Change Batch
                                  </button>
                                )}

                              {(element.used_stock > 0 ||
                                element.free_quantity > 0) && (
                                <div>
                                  <p>
                                    {_businessSetting.BatchNoTxt} :{" "}
                                    {element.batch_no}
                                  </p>

                                  <p>
                                    {_businessSetting.ExpDateTxt} :{" "}
                                    {element.exp_date}
                                  </p>

                                  <p>
                                    {_businessSetting.MfgDateTxt} :{" "}
                                    {element.mfg_date}
                                  </p>

                                  <p>
                                    {_businessSetting.ModelNoTxt} :{" "}
                                    {element.model_no}
                                  </p>

                                  <p>
                                    {_businessSetting.SizeTxt} : {element.size}
                                  </p>

                                  <p>
                                    {_businessSetting.BatchNoAdditionalLabel1} :{" "}
                                    {element.batchNoAdditionalValue1}
                                  </p>

                                  <p>
                                    {_businessSetting.BatchNoAdditionalLabel2} :{" "}
                                    {element.batchNoAdditionalValue2}
                                  </p>

                                  <p>Opening Stock : {element.opening_stock}</p>
                                  
                                  {this.state.FreeItemQuantity > 0 && 
                                    <p>Free Quantity : {element.free_quantity}</p>
                                  }
                                  <p>Quantity : {element.used_stock}</p>
                                </div>
                              )}
                            </div>
                          )}

                          {this.state.transactionId == 0 && (
                            <div>
                              <button
                                className="btn btn-info pull-right"
                                onClick={this.serialPopChnage.bind(
                                  this,
                                  "modalAddBatchNo",
                                  "Old",
                                  this.state.itemPosition,
                                  this.state.OldChnageQuantity,
                                  this.state.OldUsedStockQty,
                                  element.batch_no
                                )}
                              >
                                Change Batch
                              </button>

                              {(element.used_stock > 0 ||
                                element.free_quantity > 0) && (
                                <div>
                                  {_businessSetting.BatchNoChk == 1 && (
                                    <p>
                                      {_businessSetting.BatchNoTxt} :{" "}
                                      {element.batch_no}
                                    </p>
                                  )}
                                  {_businessSetting.ExpDateChk == 1 && (
                                    <p>
                                      {_businessSetting.ExpDateTxt} :{" "}
                                      {element.exp_date}
                                    </p>
                                  )}
                                  {_businessSetting.MfgDateChk == 1 && (
                                    <p>
                                      {_businessSetting.MfgDateTxt} :{" "}
                                      {element.mfg_date}
                                    </p>
                                  )}
                                  {_businessSetting.ModelNoChk == 1 && (
                                    <p>
                                      {_businessSetting.ModelNoTxt} :{" "}
                                      {element.model_no}
                                    </p>
                                  )}
                                  {_businessSetting.SizeChk == 1 && (
                                    <p>
                                      {_businessSetting.SizeTxt} :{" "}
                                      {element.size}
                                    </p>
                                  )}
                                  {_businessSetting.BatchNoAdditionalChk1 ==
                                    1 && (
                                    <p>
                                      {_businessSetting.BatchNoAdditionalLabel1}{" "}
                                      : {element.batchNoAdditionalValue1}
                                    </p>
                                  )}
                                  {_businessSetting.BatchNoAdditionalChk2 ==
                                    1 && (
                                    <p>
                                      {_businessSetting.BatchNoAdditionalLabel2}{" "}
                                      : {element.batchNoAdditionalValue2}
                                    </p>
                                  )}
                                  {_businessSetting.opening_stock == 1 && (
                                    <p>
                                      Opening Stock : {element.opening_stock}
                                    </p>
                                  )}
                                  {element.free_quantity > 0 && (
                                    <p>
                                      Free Quantity : {element.free_quantity}
                                    </p>
                                  )}
                                  {element.used_stock > 0 && (
                                    <p>Quantity : {element.used_stock}</p>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {this.state.popuptype == "Serial" && (
                    <div>
                      <h3>Serial Data </h3>
                      {/*{this.state.transactionId >0 && */}
                      {/*<button className='btn btn-info pull-right' onClick={this.serialPopChnage.bind(this,'modalAddSerialNo')} itemBatchNoAll={this.state.itemBatchNo}  >Change Serial
                          </button> */}
                      {/*} */}
                      {this.state.transactionId > 0 && (
                        <div>
                          {this.state.popupVal.map((element, index) => (
                            <div
                              style={{
                                height: "40px !important",
                                margin: "0 0 14px 0 !important",
                              }}
                              className="block-content"
                            >
                              <p>
                                {_businessSetting.SerialNoTxt} :{" "}
                                {element.serial_no}
                              </p>

                              <p>
                                {_businessSetting.SerialNoAdditionalLabel1} :{" "}
                                {element.serialNoAdditionalValue1
                                  ? element.serialNoAdditionalValue1
                                  : "NA"}
                              </p>

                              <p>
                                {_businessSetting.SerialNoAdditionalLabel2} :{" "}
                                {element.serialNoAdditionalValue2
                                  ? element.serialNoAdditionalValue2
                                  : "NA"}
                              </p>

                              {
                                <p>
                                  {element.isFreeQuantity ? "Free" : "Not Free"}
                                </p>
                              }

                              {(_businessSetting.SerialNoChk == 1 ||
                                _businessSetting.SerialNoAdditionalChk1 == 1 ||
                                _businessSetting.SerialNoAdditionalChk2 ===
                                  1)  && this.state.sendPageType === 'Normal'  && (
                                <button
                                  onClick={() =>
                                    this.removeSelectedSrData(
                                      element.serial_no,
                                      element.productId,
                                      element.isFreeQuantity ? "F" : "N",
                                      index
                                    )
                                  }
                                  className="btn btn-danger"
                                >
                                  <i className="si si-close"></i>Remove{" "}
                                </button>
                              )}

                              <hr />
                            </div>
                          ))}
                        </div>
                      )}
                      {this.state.transactionId == 0 && (
                        <div>
                          {this.state.popupVal.map((element, index) => (
                            <div
                              style={{
                                height: "40px !important",
                                margin: "0 0 14px 0 !important",
                              }}
                              className="block-content"
                            >
                              {_businessSetting.SerialNoChk == 1 && (
                                <p>
                                  {_businessSetting.SerialNoTxt} :{" "}
                                  {element.serial_no}
                                </p>
                              )}
                              {_businessSetting.SerialNoAdditionalChk1 == 1 && (
                                <p>
                                  {_businessSetting.SerialNoAdditionalLabel1} :{" "}
                                  {element.serialNoAdditionalValue1
                                    ? element.serialNoAdditionalValue1
                                    : "NA"}
                                </p>
                              )}
                              {_businessSetting.SerialNoAdditionalChk2 == 1 && (
                                <p>
                                  {_businessSetting.SerialNoAdditionalLabel2} :{" "}
                                  {element.serialNoAdditionalValue2
                                    ? element.serialNoAdditionalValue2
                                    : "NA"}
                                </p>
                              )}
                              {
                                <p>
                                  {element.isFreeQuantity ? "Free" : "Not Free"}
                                </p>
                              }

                              <button
                                onClick={() =>
                                  this.removeSelectedSrData(
                                    element.serial_no,
                                    element.productId,
                                    element.isFreeQuantity ? "F" : "N",
                                    index
                                  )
                                }
                                className="btn btn-danger"
                              >
                                <i className="si si-close"></i>Remove{" "}
                              </button>
                              <hr />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddBatchViewPop"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal View Batch Serial Data Begins */}

        {/*  Modal Batch Serial Data and Normal Condition Popup */}
        <Modal
          isOpen={this.state.modalIsOpen_SerialBatchNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalShowSerialBatchNo"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Select item tracking type<span> ({this.state.name})</span>
                  </h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerMainP.bind(
                        this,
                        "modalShowSerialBatchNo",
                        this.state.itemPosition,
                        this.state.itemId
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                {this.state.selectType == 1 && (
                  <div className="block-content">
                    <p>Please choose tracking type
                       {/* {this.state.btnShowSerialNormalBatch+"==>>=="+this.state.normal_quantity+"==>>=="+this.state.batchOnlyData+"==>>=="+this.state.batchLength+"==>>=="+this.state.normal_remaining_quantity+"==>=="+this.state.itemSerialNo.length+"==<SR->=="} */}
                    </p>
                    {this.state.normal_quantity > 0 &&
                      this.state.btnShowSerialNormalBatch == 0 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Select without tracking{" "}
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Add Serial{" "}
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Add Batch{" "}
                            </button>
                          )}
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.normal_quantity > 0 &&
                      this.state.btnShowSerialNormalBatch == 2 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Select without tracking{" "}
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Add Serial{" "}
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {
                            _businessSetting.BatchNoChk == 1 && (
                              <button
                                type="button"
                                className="btn btn-alt-secondary"
                                onClick={() =>
                                  this.handleBatchPopup(
                                    this.state.selectItemData,
                                    this.state.itemId,
                                    this.state.name,
                                    this.state.itemPosition,
                                    this.state.itemPrice
                                  )
                                }
                              >
                                Batch{" "}
                              </button>
                            )

                            // <button type="button" className="btn btn-alt-secondary" onClick={()=> this.handleAddNewBatchPopup(this.state.selectItemData,this.state.itemId,this.state.name,this.state.itemPosition,this.state.itemPrice)}>Add Batch </button>
                          }
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.normal_quantity <= 0 &&
                      this.state.btnShowSerialNormalBatch == 2 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                "Purchase",
                                this.state.normal_quantity,
                                this.state.normalQtyField,
                                this.state.selectTypeTxt
                              )
                            }
                          >
                            Add without tracking{" "}
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Add Serial{" "}
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Batch{" "}
                            </button>
                          )}
                        </div>
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {this.state.normal_quantity > 0 &&
                        this.state.btnShowSerialNormalBatch == 1 && (
                          <div>
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleNotSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt,
                                  this.state.normal_quantity,
                                  this.state.normalQtyField
                                )
                              }
                            >
                              Select without tracking{" "}
                            </button>
                            &nbsp;&nbsp;
                            {(_businessSetting.SerialNoChk == 1 ||
                              _businessSetting.SerialNoAdditionalChk1 == 1 ||
                              _businessSetting.SerialNoAdditionalChk2 == 1) && (
                              <button
                                type="button"
                                className="btn btn-alt-secondary"
                                onClick={() =>
                                  this.handleAddNewSerialPopup(
                                    this.state.selectItemData,
                                    this.state.itemId,
                                    this.state.name,
                                    this.state.itemPosition,
                                    this.state.itemPrice,
                                    this.state.selectTypeTxt
                                  )
                                }
                              >
                                Add Serial{" "}
                              </button>
                            )}
                            &nbsp;&nbsp;
                            {_businessSetting.BatchNoChk == 1 && (
                              <button
                                type="button"
                                className="btn btn-alt-secondary"
                                onClick={() =>
                                  this.handleAddNewBatchPopup(
                                    this.state.selectItemData,
                                    this.state.itemId,
                                    this.state.name,
                                    this.state.itemPosition,
                                    this.state.itemPrice
                                  )
                                }
                              >
                                Add Batch{" "}
                              </button>
                            )}
                          </div>
                        )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.normal_quantity == 0 &&
                      this.state.btnShowSerialNormalBatch == 1 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                "Purchase",
                                this.state.normal_quantity,
                                this.state.normalQtyField,
                                this.state.selectTypeTxt
                              )
                            }
                          >
                            Add without tracking
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Serial
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Add Batch{" "}
                            </button>
                          )}
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.normal_quantity < 0 &&
                      this.state.btnShowSerialNormalBatch == 1 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                "Purchase",
                                this.state.normal_quantity,
                                this.state.normalQtyField,
                                this.state.selectTypeTxt
                              )
                            }
                          >
                            Add without tracking
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Serial
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Add Batch{" "}
                            </button>
                          )}
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.normal_quantity > 0 &&
                      this.state.btnShowSerialNormalBatch == 3 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Select without tracking{" "}
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Serial
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Batch
                            </button>
                          )}
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.batchOnlyData == 0 &&
                      this.state.btnShowSerialNormalBatch == 2 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Select without tracking{" "}
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Add Serial
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Batch{" "}
                            </button>
                          )}
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {(this.state.normal_quantity == 0 && this.state.btnShowSerialNormalBatch == 0 )&& (
                      <div>
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              "Purchase",
                              this.state.normal_quantity,
                              this.state.normalQtyField,
                              this.state.selectTypeTxt
                            )
                          }
                        >
                          Add without tracking
                        </button>
                        &nbsp;&nbsp;
                        {(_businessSetting.SerialNoChk == 1 ||
                          _businessSetting.SerialNoAdditionalChk1 == 1 ||
                          _businessSetting.SerialNoAdditionalChk2 == 1) && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleAddNewSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt
                              )
                            }
                          >
                            Add Serial{" "}
                          </button>
                        )}
                        &nbsp;&nbsp;
                        {_businessSetting.BatchNoChk == 1 && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleAddNewBatchPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice
                              )
                            }
                          >
                            Add Batch{" "}
                          </button>
                        )}
                      </div>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.btnShowSerialNormalBatch == 5 && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              "Purchase",
                              this.state.normal_quantity,
                              this.state.normalQtyField,
                              this.state.selectTypeTxt
                            )
                          }
                        >
                          Add without tracking
                        </button>
                        &nbsp;&nbsp;
                        {(_businessSetting.SerialNoChk == 1 ||
                          _businessSetting.SerialNoAdditionalChk1 == 1 ||
                          _businessSetting.SerialNoAdditionalChk2 == 1) && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleAddNewSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt
                              )
                            }
                          >
                            Add Serial{" "}
                          </button>
                        )}
                        &nbsp;&nbsp;
                        {_businessSetting.BatchNoChk == 1 && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleAddNewBatchPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice
                              )
                            }
                          >
                            Add Batch{" "}
                          </button>
                        )}
                      </div>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.normal_remaining_quantity > 0 &&
                      this.state.btnShowSerialNormalBatch == 6 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Select without tracking{" "}
                          </button>
                          &nbsp;&nbsp;
                          {(_businessSetting.SerialNoChk == 1 ||
                            _businessSetting.SerialNoAdditionalChk1 == 1 ||
                            _businessSetting.SerialNoAdditionalChk2 == 1) && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Serial
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {_businessSetting.BatchNoChk == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Add Batch
                            </button>
                          )}
                        </div>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.btnShowSerialNormalBatch == 7 && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              "Purchase",
                              this.state.normal_quantity,
                              this.state.normalQtyField,
                              this.state.selectTypeTxt
                            )
                          }
                        >
                          Add without tracking
                        </button>
                        &nbsp;&nbsp;
                        {(_businessSetting.SerialNoChk == 1 ||
                          _businessSetting.SerialNoAdditionalChk1 == 1 ||
                          _businessSetting.SerialNoAdditionalChk2 == 1) && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt
                              )
                            }
                          >
                            Serial
                          </button>
                        )}
                        &nbsp;&nbsp;
                        {_businessSetting.BatchNoChk == 1 && (
                          // <button type="button" className="btn btn-alt-secondary" onClick={()=> this.handleBatchPopup(this.state.selectItemData,this.state.itemId,this.state.name,this.state.itemPosition,this.state.itemPrice)}>Batch </button>
                          // <button
                          //   type="button"
                          //   className="btn btn-alt-secondary"
                          //   onClick={() =>
                          //     this.handleAddNewBatchPopup(
                          //       this.state.selectItemData,
                          //       this.state.itemId,
                          //       this.state.name,
                          //       this.state.itemPosition,
                          //       this.state.itemPrice
                          //     )
                          //   }
                          // >
                          //   Add Batch
                          // </button>
                          <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Batch{" "}
                          </button>
                        )}
                      </div>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.btnShowSerialNormalBatch == 111 && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              "Purchase",
                              this.state.normal_quantity,
                              this.state.normalQtyField,
                              this.state.selectTypeTxt
                            )
                          }
                        >
                          Add without tracking
                        </button>
                        &nbsp;&nbsp;
                      </div>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                )}
                {this.state.selectType == 2 && (
                  <div className="block-content">
                    <p>Please choose tracking type </p>
                    
                    {/* {this.state.btnShowSerialNormalBatch+"==>>=="+this.state.normal_quantity+"==>>=="+this.state.batchOnlyData+"==>batchOnlyData>=="+this.state.batchLength+"==>batchLength>=="+this.state.normal_remaining_quantity}  */}
                    {this.state.btnShowSerialNormalBatch == 4 && (
                      <div>
                        {this.state.normal_remaining_quantity > 0 && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Select without tracking{" "}
                          </button>
                        )}
                        {this.state.normal_remaining_quantity == 0 && (
                          <div>
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleNotSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt,
                                  this.state.normal_quantity,
                                  this.state.normalQtyField
                                )
                              }
                            >
                              Add without tracking{" "}
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {(_businessSetting.SerialNoChk == 1 ||
                              _businessSetting.SerialNoAdditionalChk1 == 1 ||
                              _businessSetting.SerialNoAdditionalChk2 ===
                                1) && (
                              <button
                                type="button"
                                className="btn btn-alt-secondary"
                                onClick={() =>
                                  this.handleAddNewSerialPopup(
                                    this.state.selectItemData,
                                    this.state.itemId,
                                    this.state.name,
                                    this.state.itemPosition,
                                    this.state.itemPrice,
                                    this.state.selectTypeTxt
                                  )
                                }
                              >
                                Add Serial{" "}
                              </button>
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {_businessSetting.BatchNoChk == 1 && (
                                <button
                                  type="button"
                                  className="btn btn-alt-secondary"
                                  onClick={() =>
                                    this.handleAddNewBatchPopup(
                                      this.state.selectItemData,
                                      this.state.itemId,
                                      this.state.name,
                                      this.state.itemPosition,
                                      this.state.itemPrice
                                    )
                                  }
                                >
                                  Add Batch
                                </button>
                              )}
                          </div>
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {this.state.btnShowSerialNormalBatch == 222 && (
                          <button
                            type="button"
                            className="btn btn-alt-secondary"
                            onClick={() =>
                              this.handleNotSerialPopup(
                                this.state.selectItemData,
                                this.state.itemId,
                                this.state.name,
                                this.state.itemPosition,
                                this.state.itemPrice,
                                this.state.selectTypeTxt,
                                this.state.normal_quantity,
                                this.state.normalQtyField
                              )
                            }
                          >
                            Add without tracking{" "}
                          </button>
                        )}
                        {(_businessSetting.SerialNoChk == 1 ||
                          _businessSetting.SerialNoAdditionalChk1 == 1 ||
                          _businessSetting.SerialNoAdditionalChk2 == 1) &&
                          this.state.btnShowSerialNormalBatch == 1 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewSerialPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice,
                                  this.state.selectTypeTxt
                                )
                              }
                            >
                              Add Serial{" "}
                            </button>
                          )}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {_businessSetting.BatchNoChk == 1 &&
                          this.state.batchLength > 0 &&
                          this.state.btnShowSerialNormalBatch == 2 && (
                            <button
                              type="button"
                              className="btn btn-alt-secondary"
                              onClick={() =>
                                this.handleAddNewBatchPopup(
                                  this.state.selectItemData,
                                  this.state.itemId,
                                  this.state.name,
                                  this.state.itemPosition,
                                  this.state.itemPrice
                                )
                              }
                            >
                              Add Batch
                            </button>
                          )}
                      </div>
                    )}
                    {this.state.btnShowSerialNormalBatch == 0 &&
                      this.state.normal_remaining_quantity > 0 && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              this.state.selectTypeTxt,
                              this.state.normal_quantity,
                              this.state.normalQtyField
                            )
                          }
                        >
                          Select without tracking{" "}
                        </button>
                      )}
                    {this.state.btnShowSerialNormalBatch == 0 &&
                      this.state.normal_remaining_quantity == 0 &&
                      this.state.selectTypeTxt == "Free" && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              this.state.selectTypeTxt,
                              this.state.normal_quantity,
                              this.state.normalQtyField
                            )
                          }
                        >
                          Add without tracking{" "}
                        </button>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.btnShowSerialNormalBatch == 1 &&
                      (_businessSetting.SerialNoChk == 1 ||
                        _businessSetting.SerialNoAdditionalChk1 == 1 ||
                        _businessSetting.SerialNoAdditionalChk2 == 1) && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleAddNewSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              this.state.selectTypeTxt
                            )
                          }
                        >
                          Add Serial
                        </button>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.btnShowSerialNormalBatch == 2 &&
                      _businessSetting.BatchNoChk == 1 && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleAddNewBatchPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice
                            )
                          }
                        >
                          Add Batch
                        </button>
                      )}
                  </div>
                )}
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerMainP.bind(
                    this,
                    "modalShowSerialBatchNo",
                    this.state.itemPosition,
                    this.state.itemId
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Batch Serial Data Condition Popup */}

        {/* Modal Item Add Serial No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddSerialNo"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Select Serial No<span> ({this.state.name})</span>
                  </h3>
                  {/* <div className="block-options">
                      <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAddSerialNo')}>
                          <i className="si si-close"></i>
                      </button>
                  </div> */}
                </div>
                <div className="block-content">
                  <QuickAddTransSerialNo
                    isRedirectTo={"business/add-item/"}
                    callBack={(
                      data,
                      _opening_stock,
                      mrpPrice,
                      _totalAmountOld,
                      _totalQuantity,
                      _allSerialSendRedturn,
                      _discountAmtSend,
                      _taxAmtSend,
                      _itemToalAmtSend,
                      indexPosition,
                      _costPrice
                    ) => {
                      const productId = this.state.itemId; //data[0].productId;
                      let _fieldData = this.state.fields;
                      let percenTageAmt = 0;

                      let _totalGstAmt = 0;//this.state.gstAmount;
                      let _totalDiscount = 0;//this.state.totalDiscount;
                      
                      if(this.state.fields.length == 1){
                        _totalDiscount = _discountAmtSend;
                        _totalGstAmt = _taxAmtSend;
                      }
                      else{                 
                        _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                        _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                      }

                      let _Additional_CESSAK = 0;
                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          let finalPerDisAmt = 0;
                          
                          let discountPer  = itemData.discount_in_per;                          
                          if(itemData.discount_in_per == 0){
                            discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                          }                          
                          itemData.quantity = _opening_stock;
                          itemData.total_amount = parseFloat(_itemToalAmtSend);
                          itemData.discount_in_amount = _discountAmtSend;
                          itemData.discount_in_per = discountPer;
                          itemData.tax_value = _taxAmtSend;
                          itemData.itemSerialNoAllData = data;
                          itemData.allSerialSendRedturn = _allSerialSendRedturn;
                          itemData.totalStocks = parseFloat(this.state.itemSerialNo.length);
                          itemData.availLenAk = parseFloat(this.state.itemSerialNo.length) - (parseFloat(_opening_stock) + parseFloat(itemData.free_quantity));
                          _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);
                        }
                        

                      });

                      if(this.state.fields.length == 1){
                        _totalAmountOld = _itemToalAmtSend;
                      }else{
                        _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                      }
                      let _totalAmount = _totalAmountOld;
                      let _dueAmount = parseFloat(_totalAmountOld) ;
                      _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.receivedAmount);
                    
                      let _totalAmt = _totalAmount;
                    
                      let _paidAM = this.state.receivedAmount;
                      let _totalAmountNewSend = this.state.totalAmountOld;
                      let _totalAmountOldSend = _totalAmt;
                      let _totalAmountNew = parseFloat(_totalAmountOld);

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_SerialNo: false,
                        opening_stock: _opening_stock,
                        totalQuantity: _totalQuantity,
                        totalAmountOld: _totalAmount,
                        totalAmountNew: _totalAmountNew,
                        totalAmount: _totalAmount,
                        dueAmount: _dueAmount,
                        totalDiscount: _totalDiscount,
                        gstAmount: _totalGstAmt,
                        btnShowSerialNormalBatch: 1,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                    totalQuantity={this.state.totalQuantity}
                    itemPrice={this.state.itemPrice}
                    totalAmountOld={this.state.totalAmountNew}
                    transactionId={this.state.transactionId}
                    indexPosition={this.state.itemPosition}
                    itemFields={this.state.fields}
                  />
                </div>

                <br />
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddSerialNo')}>Close</button> */}
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Serial No*/}

        {/* Modal Item Add Serial No Begins Free Item */}
        <Modal
          isOpen={this.state.modalIsOpen_SerialNoFreeItem}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddSerialNoFreeItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Select Serial No For Free Item<span> ({this.state.name})</span>
                  </h3>

                  {/* <div className="block-options">
                      <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAddSerialNoFreeItem')}>
                          <i className="si si-close"></i>
                      </button>
                  </div> */}
                </div>

                <div className="block-content">
                  <QuickAddTransSerialNoFreeQty
                    isRedirectTo={"business/add-item/"}
                    callBack={(data, _opening_stock, _totalQuantity) => {
                      const productId = data[0].productId;
                      let _fieldData = this.state.fields;

                      let _totalAmountOldFree = this.state.totalAmount;
                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          let _srLengthD =  this.state.itemSerialNo ?  this.state.itemSerialNo.length : 0;                          
                          let _availLenAk = 0;
                          if(_srLengthD > 0){
                            _availLenAk = parseFloat(_srLengthD) - (parseFloat(_opening_stock) + parseFloat(itemData.quantity))
                          }
                          //let _availLenAk = parseFloat(this.state.itemSerialNo.length) - (parseFloat(_opening_stock) + parseFloat(itemData.quantity));

                          if (_availLenAk == -1) {
                            _availLenAk = 0;
                          }
                          let _oldItemQty = itemData.free_quantity ?  itemData.free_quantity : 0;
                          console.log(_oldItemQty+"==>_oldItemQty-->=="+_opening_stock)
                          itemData.free_quantity = parseFloat(_opening_stock) + parseFloat(_oldItemQty);
                          itemData.itemSerialNoAllData = [
                            ...itemData.itemSerialNoAllData,
                            ...data,
                          ];

                          itemData.availLenAk = _availLenAk;
                        }
                      });

                      let _totalAmt = parseFloat(this.state.totalAmount);
                      if (_totalAmountOldFree > 0) {
                        _totalAmt = _totalAmountOldFree;
                      }

                      let _paidAM = this.state.receivedAmount;
                      let _totalAmountNewSend = _totalAmountOldFree;
                      let _totalAmountOldSend = _totalAmt;
                      let _dueAmount = _totalAmountOldFree;
                      let _totalDiscount = this.state.totalDiscount;
                      let _totalGstAmt = 0;//this.state.gstAmount;

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_SerialNoFreeItem: false,
                        opening_stock: _opening_stock,
                        totalQuantity: _totalQuantity,
                        btnShowSerialNormalBatch: 1,
                        totalAmount: _totalAmt,
                        dueAmount: _totalAmt,
                        totalAmountOld: _totalAmt,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemSerialNo ? this.state.itemSerialNo : this.state.allSerialSendRedturn}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                    totalQuantity={this.state.totalQuantity}
                    itemFields={this.state.fields}
                    transactionId={this.state.transactionId}
                    itemPosition={this.state.itemPosition}
                  />
                </div>

                <br />
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddSerialNoFreeItem')}>Close</button> */}
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Serial No Free Item*/}

        {/* Modal Item Add Batch No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BatchNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddBatchNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Select Batch No<span> ({this.state.name}) </span>
                  </h3>
                  <div className="block-options">
                    <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalAddBatchNo"
                        )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>

                <div className="block-content">
                  <QuickAddTransBatchNo
                    isRedirectTo={"business/add-item/"}
                    duplicateSale={this.state.duplicateSale}
                    callBack={(
                      data,
                      _used_stock,
                      freeQuantity,
                      _totalAmount,
                      _totalQuantity,
                      _totalAmountOld,
                      mrpPrice,
                      batchIds,
                      _discountAmtSend,
                      _taxAmtSend,
                      _itemToalAmtSend,
                      indexPosition,
                      _costPrice
                    ) => {

                      _totalQuantity = String(_totalQuantity).replace('-', '');
                      _totalQuantity= parseFloat(_totalQuantity);
                      const productId = this.state.itemId; //data[0].productId;
                      let percenTageAmt = 0;
                      let _Additional_CESSAK = 0;
                      let _fieldData = this.state.fields; 
                      let _totalGstAmt = 0;//this.state.gstAmount;
                      let _totalDiscount = 0;//this.state.totalDiscount;
                      
                      if(this.state.fields.length == 1){
                        _totalDiscount = _discountAmtSend;
                        _totalGstAmt = _taxAmtSend;
                      }
                      else{                 
                        //_totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                        //_totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                        console.log("==KKKK==",_discountAmtSend);
                        _fieldData.map((itemData, i) => {
                            if (i!=indexPosition) {
                              console.log(itemData.discount_in_amount+"==HHHH==",JSON.stringify(itemData));
                              _totalDiscount =  parseFloat(_discountAmtSend) + parseFloat(itemData.discount_in_amount);
                              _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(itemData.tax_value);
                            }
                        })
                      }

                      _fieldData.map((itemData, i) => {
                        console.log("===itemData==",JSON.stringify(itemData));
                        let _itemPosition = this.state.itemPosition;

                        let _rationAk = itemData.tax_type.ratio ? itemData.tax_type.ratio : 0;
                        if (_itemPosition == -1) {
                          _itemPosition = 0;
                        }

                        if (i == _itemPosition) {
                          let finalPerDisAmt = 0;
                          
                          let discountPer  = itemData.discount_in_per;                         
                          if(itemData.discount_in_per == 0){
                            discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                          }
                          
                          itemData.quantity = _used_stock;
                          itemData.free_quantity = freeQuantity;
                          itemData.total_amount = parseFloat(_itemToalAmtSend);
                          itemData.discount_in_amount = _discountAmtSend;
                          itemData.discount_in_per = discountPer;
                          itemData.tax_value = _taxAmtSend;
                          itemData.itemBatchNoAllData = data[0];
                          itemData.itemBatchNo = this.state.itemBatchNo;
                          _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);             
                        }
                      });
                      console.log("==other PAGE==",_totalAmountOld);
                      if(this.state.fields.length == 1){
                        _totalAmountOld = _itemToalAmtSend;
                      }else{
                        //_totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                        let _totalItemAmtAk =0;
                        _fieldData.map((itemData, i) => {
                          if (i!=indexPosition) {
                            console.log(itemData.item_id+'==>FFFF-->=='+itemData.subtotal);
                            //_totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(itemData.total_amount) ;
                            _totalItemAmtAk = parseFloat(_totalItemAmtAk) + parseFloat(itemData.total_amount);
                            console.log(itemData.total_amount+"==Main Page===",_totalItemAmtAk);
                          }
                        })
                        _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(_totalItemAmtAk) ;
                        console.log(_totalAmountOld+"==Amt KK===="+_itemToalAmtSend+"==_totalItemAmtAk==="+_totalItemAmtAk); 
                      }
                      _totalAmount = _totalAmountOld;
                      let _totalAmountNewSend = _totalAmount;
                      let _totalAmountOldSend = _totalAmount
                      let _totalAmt = _totalAmount
                      let _dueAmount = parseFloat(_totalAmountOld) ;
                      if(parseFloat(this.state.receivedAmount) >0){
                        _dueAmount =  parseFloat(_totalAmountOld) - parseFloat(this.state.receivedAmount)
                      }
                      let _paidAM = this.state.receivedAmount;
                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_BatchNo: false,
                        used_stock: _used_stock,
                        totalQuantity: _totalQuantity,
                        totalAmountOld: _totalAmt,
                        subtotal : _totalAmountOld,
                        totalAmountNew: _totalAmountOld,
                        totalAmount: _totalAmountOld,
                        dueAmount: _dueAmount,
                        gstAmount: _totalGstAmt,
                        totalDiscount: _totalDiscount,
                        btnShowSerialNormalBatch: 2,
                        used_stockAK: _used_stock,
                        batchItemEnable: 1,
                        popupVal :data
                      });
                      this.state.batchItemEnable = 1;
                    }}
                    used_stock={this.state.used_stock}
                    itemBatchNoAll={this.state.itemBatchNo}
                    itemId={this.state.itemId}
                    totalQuantity={this.state.totalQuantity}
                    totalAmountOld={this.state.totalAmountNew}
                    itemPrice={this.state.itemPrice}
                    batchSendType={this.state.batchSendType}
                    OldChnageQuantity={this.state.OldChnageQuantity}
                    OldUsedStockQty={this.state.OldUsedStockQty}
                    batchNo={this.state.batchNo}
                    transactionId={this.state.transactionId}
                    createBType={this.state.createBType}
                    itemFields={this.state.fields}
                    indexPosition={this.state.itemPosition}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                 {/*<button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddBatchNo')}>Close</button>*/}
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Batch No*/}

        {/* Modal Add New Serial No In Item Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddNewSerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNewSalePurSerialNo"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Serial No<span> ({this.state.name})</span>
                  </h3>
                </div>
                <div className="block-content">
                  <QuickAddNewSaleSerialNo
                    callBack={(
                      data,
                      _opening_stock,
                      mrpPrice,
                      _totalAmountOld,
                      _totalQuantity,
                      _discountAmtSend,
                      _taxAmtSend,
                      _itemToalAmtSend,
                      indexPosition,
                      _costPrice
                    ) => {
                      const productId = this.state.itemId; //data[0].productId;
                      let _fieldData = this.state.fields;
                      let percenTageAmt = 0;
                      let _totalGstAmt = 0;//this.state.gstAmount;
                      let _totalDiscount = 0;//this.state.totalDiscount;
                      
                      if(this.state.fields.length == 1){
                        _totalDiscount = _discountAmtSend;
                        _totalGstAmt = _taxAmtSend;
                      }
                      else{                 
                        _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                        _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                      }
                      let _Additional_CESSAK = 0;
                      
                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          let discountPer  = itemData.discount_in_per;                         
                          if(itemData.discount_in_per == 0){
                            discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                          }
                          let finalPerDisAmt = 0;                          
                          itemData.quantity = _opening_stock;
                          itemData.total_amount = parseFloat(_itemToalAmtSend);
                          itemData.discount_in_amount = _discountAmtSend;
                          itemData.discount_in_per = discountPer;
                          itemData.tax_value = _taxAmtSend;
                          itemData.itemSerialNoAllData = data;
                          itemData.totalStocks = parseFloat(data.length);
                          itemData.availLenAk = 0;
                          _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);
                        }

                      });
                      
                      if(this.state.fields.length == 1){
                        _totalAmountOld = _itemToalAmtSend;
                      }else{
                        _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                      }
                      let _totalAmount = _totalAmountOld;
                      let _dueAmount = parseFloat(_totalAmountOld) ;
                      _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.receivedAmount);
                    
                      let _totalAmt = _totalAmount;
                    
                      let _paidAM = this.state.receivedAmount;
                      let _totalAmountNewSend = this.state.totalAmountOld;
                      let _totalAmountOldSend = _totalAmt;
                    
                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      let _batchItemEnable = this.state.batchItemEnable;
                      if (this.state.batchItemEnable == 0) {
                        _batchItemEnable = 0;
                      }

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_AddNewSerialNo: false,
                        modalIsOpen_SerialBatchNo: false,
                        opening_stock: _opening_stock,
                        totalQuantity: _totalQuantity,
                        totalAmountOld: _totalAmount,
                        totalAmountNew: parseFloat(_totalAmountOld),
                        totalAmount: _totalAmount,
                        dueAmount: _dueAmount,
                        gstAmount: _totalGstAmt,
                        totalDiscount: _totalDiscount,
                        btnShowSerialNormalBatch: 1,
                        serialItemEnable: 1,
                      });
                      this.state.serialItemEnable = 1;
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemAddSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                    totalQuantity={this.state.totalQuantity}
                    itemPrice={this.state.itemPrice}
                    totalAmountOld={this.state.totalAmountNew}
                    transactionId={this.state.transactionId}
                    indexPosition={this.state.itemPosition}
                    itemFields={this.state.fields}
                  />
                </div>
                <br />
              </div>
              {/* <div className="modal-footer">
                  <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddNewSalePurSerialNo')}>Close</button>
              </div> */}
            </div>
          </div>
        </Modal>
        {/* End Modal Add New Serial No In Item*/}

        {/* Modal Add New Free Serial No In Item Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddNewFreeSerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNewSalePurFreeSerialNo"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Free Serial No<span> ({this.state.name})</span>
                  </h3>
                </div>
                <div className="block-content">
                  <QuickAddNewFreeSalePurchaseSerialNo
                    callBack={(
                      data,
                      _opening_stock,
                      _totalQuantity,
                      _totalAmountOld
                    ) => {
                      const productId = data[0].productId;
                      let _fieldData = this.state.fields;
                      let _totalAmountOldFree = this.state.totalAmountOldFree;
                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          itemData.free_quantity = parseFloat(itemData.free_quantity) + parseFloat(_opening_stock);
                          itemData.itemSerialNoAllData = [
                            ...itemData.itemSerialNoAllData,
                            ...data,
                          ];
                          //itemData.itemSerialNoAllData = data;
                          itemData.availLenAk = parseFloat(this.state.itemSerialNo.length) - (parseFloat(_opening_stock) + parseFloat(itemData.quantity));
                        }
                      });

                      let _totalAmount = _totalAmountOldFree;
                      let _dueAmount = _totalAmountOldFree;

                      _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.receivedAmount);

                      let _totalAmt = _totalAmount;

                      let _paidAM = this.state.receivedAmount;
                      let _totalAmountNewSend = _totalAmountOldFree;
                      let _totalAmountOldSend = _totalAmt;
                      let _totalDiscount = this.state.totalDiscount;
                      let _totalGstAmt = this.state.gstAmount;

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );
                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_AddNewFreeSerialNo: false,
                        modalIsOpen_SerialBatchNo: false,
                        modalIsOpen_SerialNoFreeItem: false,
                        opening_stock: _opening_stock,
                        totalQuantity: _totalQuantity,
                        btnShowSerialNormalBatch: 1,
                        totalAmountOld: _totalAmountNewSend,
                        totalAmount: _totalAmountNewSend,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemAddSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                    totalQuantity={this.state.totalQuantity}
                    totalAmount={this.state.totalAmount}
                    totalAmountOld={this.state.totalAmountOld}
                    itemFields={this.state.fields}
                  />
                </div>
                <br />
              </div>
              {/* <div className="modal-footer">
                  <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddNewSalePurFreeSerialNo')}>Close</button>
              </div> */}
            </div>
          </div>
        </Modal>
        {/* End Modal Add New Free Serial No In Item*/}

        {/* Modal Item Add New Sale Purchase Batch No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddNewBatchNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNewBatchNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Batch<span> ({this.state.name}) </span>
                  </h3>
                </div>
                <div className="block-content">
                  <QuickAddNewSaleBatchNo
                    callBack={(
                      data,
                      _used_stock,
                      freeQuantity,
                      _totalAmount,
                      _totalQuantity,
                      _totalAmountOld,
                      mrpPrice,
                      _discountAmtSend,
                      _taxAmtSend,
                      _itemToalAmtSend,
                      indexPosition,
                      _costPrice
                    ) => {
                      console.log("==after save batch d--", data);
                      const productId = this.state.itemId; //data[0].productId;
                      let percenTageAmt = 0;
                      let _fieldData = this.state.fields;                      
                      let _totalGstAmt = 0;//this.state.gstAmount;
                      let _totalDiscount = 0;//this.state.totalDiscount;
                      let _Additional_CESSAK = 0;
                      
                      if(this.state.fields.length == 1){
                        _totalDiscount = _discountAmtSend;
                        _totalGstAmt = _taxAmtSend;
                      }
                      else{                 
                        _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                        _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                      }

                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          let finalPerDisAmt = 0;                          
                          let discountPer  = itemData.discount_in_per;                         
                          if(itemData.discount_in_per == 0){
                            discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                          }
                          itemData.quantity = _used_stock;
                          itemData.free_quantity = freeQuantity;
                          itemData.total_amount = parseFloat(_itemToalAmtSend);
                          itemData.discount_in_amount = _discountAmtSend;
                          itemData.discount_in_per = discountPer;
                          itemData.tax_value = _taxAmtSend;
                          itemData.itemBatchNoAllData = data;
                          itemData.itemBatchNo = this.state.itemBatchNo;
                          _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);
                          
                        }
                      });

                      if(this.state.fields.length == 1){
                        _totalAmountOld = _itemToalAmtSend;
                      }else{
                        _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                      }
                      _totalAmount = _totalAmountOld;
                      let _dueAmount = parseFloat(_totalAmountOld) ;
                      if (this.state.receivedAmount > 0) {
                        _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.receivedAmount);
                      }
                      
	                    let _totalAmt = _totalAmount;

                      let _paidAM = this.state.receivedAmount;
                      let _totalAmountNewSend = this.state.totalAmountOld;
                      let _totalAmountOldSend = _totalAmt;

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );
                      

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_AddNewBatchNo: false,
                        modalIsOpen_SerialBatchNo: false,
                        used_stock: _used_stock,
                        totalQuantity: _totalQuantity,
                        totalAmountOld: _totalAmt,
                        totalAmountNew: parseFloat(_totalAmountOld),
                        totalAmount: _totalAmount,
                        dueAmount: _dueAmount,
                        gstAmount: _totalGstAmt,
                        totalDiscount: _totalDiscount,
                        btnShowSerialNormalBatch: 2,
                        used_stockAK: _used_stock,
                        createBType: "New",
                      });
                    }}
                    used_stock={this.state.used_stock}
                    itemBatchNoAll={this.state.itemAddBatchNo}
                    itemId={this.state.itemId}
                    totalQuantity={this.state.totalQuantity}
                    totalAmountOld={this.state.totalAmountNew}
                    itemPrice={this.state.itemPrice}
                    itemFields={this.state.fields}
                  />
                </div>
                <br />
              </div>
              {/* <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this,"modalAddNewBatchNo")}
                >
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </Modal>
        {/* Modal Item Add New Sale Purchase Batch No*/}

        {/*  Modal Normal Quantity select*/}
        <Modal
          isOpen={this.state.modalIsOpen_NotSerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNotSerialNo"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Normal Quantity
                    {/* {this.state.gstAmount+"==>>=="+this.state.totalAmountNew+"==>>=="+this.state.totalAmount+"==>>=="+this.state.totalAmountOld} */}                 
                  </h3>
                  {/* <div className="block-options">
                      {this.state.transactionId == 0 &&
                      <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAddNotSerialNo')}>
                          <i className="si si-close"></i>
                      </button>
                      }
                  </div> */}
                </div>
                <QuickAddNormalQuantity
                  callBack={(
                    _used_stock,
                    mrpPrice,
                    _totalAmountOld,
                    _totalQuantity,
                    indexPosition,
                    _finalRemainQty,
                    _normal_quantity_type,
                    _discountAmtSend,
                    _taxAmtSend,
                    _itemToalAmtSend,
                    _costPrice
                  ) => {
                    const productId = this.state.itemId;
                    let percenTageAmt = 0;
                    let _fieldData = this.state.fields;
                    let _totalGstAmt = 0;//this.state.gstAmount; 
                    let _totalDiscount = 0;//this.state.totalDiscount;
                    if(this.state.fields.length == 1){
                      _totalDiscount = _discountAmtSend;
                      _totalGstAmt = _taxAmtSend;
                    }
                    else{                 
                      _totalDiscount = parseFloat(this.state.totalDiscount); //parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                      _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                    }

                    console.log("===aa--_finalRemainQty==",_finalRemainQty);
                    let _normal_quantity = this.state.normal_quantityAdded ? this.state.normal_quantityAdded : _used_stock;

                    let _Additional_CESSAK = 0;
                    if (_normal_quantity == _used_stock) {
                      _normal_quantity = parseFloat(_normal_quantity);
                    } else if (_normal_quantity!=_used_stock) {
                      _normal_quantity =
                        parseFloat(_normal_quantity) + parseFloat(_used_stock);
                    }
                    this.state.normal_quantityAdded = _normal_quantity;                    
                    
                    _fieldData.map((itemData, i) => {
                      if (i == this.state.itemPosition) {

                        let discountPer  = itemData.discount_in_per;
                        if(itemData.discount_in_per == 0){
                          discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                        }

                        itemData.quantity = _used_stock;
                        itemData.total_amount = parseFloat(_itemToalAmtSend);
                        itemData.discount_in_amount = _discountAmtSend;
                        itemData.discount_in_per = discountPer;
                        itemData.normal_quantity = _used_stock ? _used_stock : 0;
                        itemData.normal_quantity_type = _normal_quantity_type ? _normal_quantity_type : "";

                        itemData.normal_remaining_quantity = _finalRemainQty;
                        itemData.tax_value = _taxAmtSend;

                        _Additional_CESSAK = parseFloat(itemData.Additional_CESS) + parseFloat(itemData.Additional_CESS);
                        
                      }
                    });
                    
                    if(this.state.fields.length == 1){
                      _totalAmountOld = _itemToalAmtSend;
                    }else{
                      _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                    }
                    let _totalAmount = _totalAmountOld;
                    let _dueAmount = parseFloat(_totalAmountOld) ;
                    _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.receivedAmount);

                    let _totalAmt = _totalAmount;

                    let _paidAM = this.state.receivedAmount;
                    let _totalAmountNewSend = this.state.totalAmountOld;
                    let _totalAmountOldSend = _totalAmt;

                    this.props.callBackItem(
                      _fieldData,
                      _totalAmountOldSend,
                      _totalAmountNewSend,
                      _totalAmt,
                      _dueAmount,
                      _paidAM,
                      _totalDiscount,
                      parseFloat(_totalGstAmt).toFixed(2),
                      _totalQuantity,
                      this.state.handleAddDisable,
                      this.state.handleItemSalePurchasePrice
                    );

                    let _serialItemEnable = this.state.serialItemEnable;
                    if (this.state.serialItemEnable == 0) {
                      _serialItemEnable = 0;
                    }
                    console.log(_totalAmount + "--KKKKK==Add N--", _fieldData);
                    
                    console.log("===bbb--_finalRemainQty==",_finalRemainQty);
                    this.setState({
                      fields: _fieldData,
                      itemPosition: -1,
                      modalIsOpen_NotSerialNo: false,
                      totalQuantity: _totalQuantity,
                      totalDiscount: _totalDiscount,
                      totalAmountOld: _totalAmount,
                      totalAmountNew: parseFloat(_totalAmountOld),
                      totalAmount: _totalAmount,
                      dueAmount: _dueAmount,
                      gstAmount: parseFloat(_totalGstAmt).toFixed(2),
                      normal_quantityAdded: _normal_quantity,
                      btnShowSerialNormalBatch: 0,
                      normal_remaining_quantity: _finalRemainQty,
                      serialItemEnable: _serialItemEnable,
                    });
                  }}
                  used_stock={this.state.used_stock}
                  itemBatchNoAll={this.state.itemBatchNo}
                  itemId={this.state.itemId}
                  totalQuantity={this.state.totalQuantity}
                  totalAmountOld={this.state.totalAmountNew}
                  itemPrice={this.state.itemPrice}
                  normalRemainingQuantity={this.state.normal_remaining_quantity}
                  normalQuantityTYpe="Sale"
                  addedQty={this.state.normal_quantityAdded}
                  normalQtyField={this.state.normalQtyField}
                  itemTypeOption={this.state.itemTypeOption}
                  itemFields={this.state.fields}
                  indexPosition={this.state.itemPosition}
                />
              </div>
              {/* <div className="modal-footer">
                  {this.state.transactionId == 0 &&
                      <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddNotSerialNo')}>Close</button>
                  }
              </div> */}
            </div>
          </div>
        </Modal>
        {/*  Modal Normal Quantity select*/}

        {/*  Modal Normal Quantity select*/}
        <Modal
          isOpen={this.state.modalIsOpen_NotSerialNoFree}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNotSerialNoFree"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Free Normal Quantity </h3>
                  <div className="block-options">
                    {this.state.usedFreeNormalQty == 0 && (
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddNotSerialNoFree"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    )}
                  </div>
                </div>
                <QuickAddNormalQuantitySaleFree
                  callBack={(
                    _used_stock,
                    _totalQuantity,
                    indexPosition,
                    _finalRemainQty,
                    _normal_quantity_type
                  ) => {
                    _totalQuantity = String(_totalQuantity).replace('-', '');
                    _totalQuantity= parseFloat(_totalQuantity);
                    const productId = this.state.itemId;
                    let percenTageAmt = 0;
                    let _fieldData = this.state.fields;
                    let _normal_quantity = 0;
                    let _usedFreeNormalQty = 0;
                    let _totalAmountOldFree = this.state.totalAmountOldFree;
                    console.log(_used_stock+"==HERE NQQQQQ--"+this.state.normal_remaining_quantity+"==>_totalQuantity>=="+_totalQuantity+'==>-_finalRemainQty->=='+_finalRemainQty);
                    _fieldData.map((itemData, i) => {
                      if (i == this.state.itemPosition) {
                        _normal_quantity =  parseFloat(itemData.quantity) + parseFloat(_used_stock);

                        itemData.free_quantity = _used_stock;
                        itemData.normal_quantity = _normal_quantity ? _normal_quantity : 0;
                        itemData.normal_quantity_type = _normal_quantity_type  ? _normal_quantity_type : "";
                        itemData.normal_remaining_quantity = _finalRemainQty;

                        //itemData.normal_remaining_quantity =  parseFloat(this.state.normal_remaining_quantity) -  parseFloat(_normal_quantity);

                        _usedFreeNormalQty = _used_stock;
                      }
                    });
                    console.log("===here--US--", _used_stock);
                    console.log("===here--TQ--", _totalQuantity);
                    console.log(
                      "===here--_totalAmountOldFree--",
                      _totalAmountOldFree
                    );
                    let _totalAmount = _totalAmountOldFree; //this.state.totalAmount;
                    let _dueAmount = this.state.dueAmount; //_totalAmountOldFree;//this.state.totalAmount;

                    ///_dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.receivedAmount);

                    let _totalAmt = _totalAmount;

                    let _paidAM = this.state.receivedAmount;//0
                    let _totalAmountNewSend = _totalAmountOldFree; //this.state.totalAmountOld;
                    let _totalAmountOldSend = _totalAmt;
                    let _totalDiscount = this.state.totalDiscount;
                    let _totalGstAmt = this.state.gstAmount;

                    this.props.callBackItem(
                      _fieldData,
                      _totalAmountOldSend,
                      _totalAmountNewSend,
                      _totalAmt,
                      _dueAmount,
                      _paidAM,
                      _totalDiscount,
                      _totalGstAmt,
                      _totalQuantity,
                      this.state.handleAddDisable,
                      this.state.handleItemSalePurchasePrice
                    );

                    let _serialItemEnable = this.state.serialItemEnable;
                    if (this.state.serialItemEnable == 0) {
                      _serialItemEnable = 0;
                    }

                    this.setState({
                      fields: _fieldData,
                      itemPosition: -1,
                      modalIsOpen_NotSerialNoFree: false,
                      totalQuantity: _totalQuantity,
                      btnShowSerialNormalBatch: 0,
                      normal_remaining_quantity: _finalRemainQty,
                      serialItemEnable: _serialItemEnable,
                      usedFreeNormalQty: _usedFreeNormalQty,
                      totalAmountOld: _totalAmountNewSend,
                      totalAmount: _totalAmountNewSend,
                    });
                  }}
                  used_stock={this.state.used_stock}
                  itemBatchNoAll={this.state.itemBatchNo}
                  itemId={this.state.itemId}
                  totalQuantity={this.state.totalQuantity}
                  totalAmountOld={this.state.totalAmountNew}
                  itemPrice={this.state.itemPrice}
                  normalRemainingQuantity={this.state.normal_remaining_quantity}
                  indexPosition={this.state.itemPosition}
                  normalQuantityTYpe="Sale"
                  addedQty={this.state.normal_quantityAdded}
                  normalQtyField={this.state.normalQtyField}
                  selectTypeTxt={this.state.selectTypeTxt}
                  normalUsedQty={this.state.normal_quantity}
                  sendActualQty={this.state.sendActualQty}
                  usedFreeNormalQty={this.state.usedFreeNormalQty}
                  transactionId={this.state.transactionId}
                  itemTypeOption={this.state.itemTypeOption}
                  itemFields={this.state.fields}
                />
              </div>
              <div className="modal-footer">
                {this.state.usedFreeNormalQty == 0 && (
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAddNotSerialNoFree"
                    )}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
        {/*  Modal Normal Quantity select*/}
      </React.Fragment>
    );
  }
}
