import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _sessionPeriod = true;
let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _sessionPeriod = false;
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}
global.BACK_URL = 'Business';

export default class businessAddPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',

            _countryLang:'',
            _countryReg:'iti__hide',
            _countryReg_O:'iti__hide',
            _countryFlag:'',
            _countryName:'',
            _countryPh:'',
            _countryId:'0',
            countryArray : [],

            isLoaded : false,
            isLoggedin : false,
            businessName : '',
            businessEmail: '',
            businessLogo: '',
            imagePreviewUrl : '',
            businessLogoImage: '',
            business_id:0,
            businessPhone: '',
            businessAddress: '',
            ownerName: '',
            ownerPhone: '',
            ownerEmail: '',
            ownerPassword: "",
            errors : {
                businessName : '',
                businessEmail : '',
                businessLogo : '',
                businessPhone : '',
                businessAddress : '',
                ownerName : '',
                ownerPhone : '',
                ownerEmail : '',
                ownerPassword : ''
            }
        }
    }

    async componentDidMount()
    {
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _businessId = _handleParam[1];
        let _userCountryId = '0';

        if(_businessId > 0){
            let _businessArray = [];
            let _url_GetData = global.adminBusinessDetails+'?businessId='+_businessId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _businessArray = responseJson.response[0];
                        _userCountryId = _businessArray.country_code;
                        this.setState({buttonTitle:'Edit',businessName:_businessArray.business_name,businessEmail:_businessArray.business_email,businessPhone:_businessArray.business_contact,businessAddress:_businessArray.business_address,ownerName:_businessArray.owner_name,ownerPhone:_businessArray.owner_contact,ownerEmail:_businessArray.owner_email,businessLogoImage:_businessArray.logo_path,business_id:_businessArray.business_id,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }

        let _urlCountryData = global.adminCountryList;
        let countryData = await PostData(_urlCountryData,'','GET');
        let _conLang,_conFlag,_conName,_conPh,_conId = '';
        countryData.response.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            let _imgPath = global.FLAG_URL+_name+'.png';
            //alert(_userCountryId+'-----'+_countryCode);
            if(_userCountryId > 0){
                if(_userCountryId == conty.phonecode){
                    _conLang = conty.sortname;
                    _conFlag = _imgPath;
                    _conName=conty.name;
                    _conPh=conty.phonecode;
                    _conId=conty.id;
                }
            }
            else if(_countryCode == conty.sortname){
                _conLang = conty.sortname;
                _conFlag = _imgPath;
                _conName=conty.name;
                _conPh=conty.phonecode;
                _conId=conty.id;
            }
        });
        this.setState({countryArray:countryData.response,_countryId:_conId,_countryLang:_conLang,_countryFlag:_conFlag,_countryName:_conName,_countryPh:_conPh,isLoaded:true});
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        
        switch (name) {
            case 'businessName': 
            errors.businessName = 
                value.length < 3 ? '*Business Name must be 3 characters long!' : '';
                this.setState({businessName:value}); 
            break;
            case 'ownerPassword': 
                errors.ownerPassword = 
                value.length < 5
                ? '*Password must be 5 characters long!'
                : '';
                this.setState({ownerPassword:value}); 
            break;
            case 'businessEmail':
                if (validator.isEmail(value)) {
                    errors.businessEmail = '';
                } else {
                    errors.businessEmail = '*Please enter valid business email-id.';
                }
                this.setState({businessEmail:value});
            break;
            case 'businessPhone': 
                /*errors.businessPhone = 
                value.length < 5 ? '*Please enter valid mobile no.' : '';

                if (!value.match(/^[0-9]*$/)) {
                    errors.businessPhone = "*Please enter valid mobile no.";
                }*/
                
                if (validator.isInt(value)) {
                    errors.businessPhone = '';
                } else {
                    errors.businessPhone = '*Please enter valid mobile no.';
                } 
                this.setState({businessPhone:value}); 
            break;
            case 'businessAddress': 
                errors.businessAddress = 
                value.length < 5
                ? '*Please enter valid business address.'
                : '';
                this.setState({businessAddress:value}); 
            break;
            // case 'businessLogo': 
            //     errors.businessLogo = 
            //     value.length < 2
            //     ? '*Please upload business logo.'
            //     : '';
            //     this.setState({businessLogo:value}); 
            // break;
            case 'ownerName': 
                errors.ownerName = 
                value.length < 3 ? '*Owner Name must be 3 characters long!' : '';
                if (!value.match(/^[a-zA-Z ]*$/)) {
                    errors.ownerName = "*Please enter alphabet characters only.";
                }
                this.setState({ownerName:value}); 
            break;
            case 'ownerPhone': 
                /*errors.ownerPhone = 
                value.length < 5 ? '*Please enter valid mobile no.' : '';

                if (!value.match(/^[0-9]*$/)) {
                    errors.ownerPhone = "*Please enter valid mobile no.";
                }
                */
                if (validator.isInt(value)) {
                    errors.ownerPhone = '';
                } else {
                    errors.ownerPhone = '*Please enter valid owner mobile no.';
                } 
                this.setState({ownerPhone:value}); 
            break;
            case 'ownerEmail': 
                if (validator.isEmail(value)) {
                    errors.ownerEmail = '';
                } else {
                    errors.ownerEmail = '*Please enter valid owner email-id.';
                }
                this.setState({ownerEmail:value}); 
            break;
            default:
            break;
        }
    }

    clickToAddBusiness = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.businessEmail == '')
        {
            _isError = true;
            errors['businessEmail'] = "*Please Enter Business Email-id";
            this.setState({errors: errors});
        }
        if(this.state.businessName == '')
        {
            _isError = true;
            errors['businessName'] = "*Please Enter Business Name.";
            this.setState({errors: errors});
        }
        // if(this.state.businessLogo == '')
        // {
        //     if(this.state.business_id == 0)
        //     {
        //         _isError = true;
        //         errors['businessLogo'] = "*Please Upload Business Logo.";
        //         this.setState({errors: errors});
        //     }
        // }
        if(this.state.businessPhone == '')
        {
            _isError = true;
            errors['businessPhone'] = "*Please Enter Business Contact No.";
            this.setState({errors: errors});
        }
        if(this.state.businessAddress == '')
        {
            _isError = true;
            errors['businessAddress'] = "*Please Enter Business Address.";
            this.setState({errors: errors});
        }
        if(this.state.ownerName == '')
        {
            _isError = true;
            errors['ownerName'] = "*Please Enter Owner Name.";
            this.setState({errors: errors});
        }
        if(this.state.ownerPhone == '')
        {
            _isError = true;
            errors['ownerPhone'] = "*Please Enter Owner Contact No.";
            this.setState({errors: errors});
        }
        if(this.state.ownerPhone.length < 8 || this.state.ownerPhone.length >12 )
        {
            _isError = true;
            errors['ownerPhone'] = "*Please enter valid owner contact no.";
            this.setState({errors: errors});
        }
        if(this.state.businessPhone.length < 8 || this.state.businessPhone.length >12 )
        {
            _isError = true;
            errors['businessPhone'] = "*Please enter valid business contact no.";
            this.setState({errors: errors});
        }
        if(this.state.ownerEmail == '')
        {
            _isError = true;
            errors['ownerEmail'] = "*Please Enter Owner Email.";
            this.setState({errors: errors});
        }
        if(this.state.ownerPassword == '' || this.state.ownerPassword == "")
        {
            if(this.state.business_id == 0)
            {
                _isError = true;
                errors['ownerPassword'] = "*Please Enter Password.";
                this.setState({errors: errors});
            }
        }

        if(_isError == false){
            
            if(this.state.business_id > 0){
                this.editBusinesMethodCall();
            }
            else{
                this.addBusinesMethodCall();
            }
        }
    }

    addBusinesMethodCall(){
        var businessData = new FormData();
        businessData.append('businessName', this.state.businessName);
        businessData.append('businessEmail', this.state.businessEmail);
        businessData.append('businessLogo', this.state.businessLogo);
        businessData.append('businessPhone', this.state.businessPhone);
        businessData.append('businessAddress', this.state.businessAddress);
        businessData.append('ownerName', this.state.ownerName);
        businessData.append('ownerPhone', this.state.ownerPhone);
        businessData.append('ownerEmail', this.state.ownerEmail);
        businessData.append('ownerPassword', this.state.ownerPassword);
        businessData.append('countryID', this.state._countryId);
        businessData.append('businessPhoneCode', this.state._countryPh);
        businessData.append('ownerPhoneCode', this.state._countryPh);
        
        new Promise((resolve, reject) =>{
            fetch(global.adminAddBusiness, {
                method:  'POST',
                body: businessData
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success == true)
                {
                    sendNotification("Success Message 👍",res.message,"success");
                    window.location.href = global.webUrl+'administrator/manage-business/';
                }
                else
                {
                    sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['businessPhone'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    editBusinesMethodCall()
    {
        var businessData = new FormData();
        businessData.append('businessName', this.state.businessName);
        businessData.append('businessEmail', this.state.businessEmail);
        businessData.append('businessLogo', this.state.businessLogo);
        businessData.append('businessPhone', this.state.businessPhone);
        businessData.append('businessAddress', this.state.businessAddress);
        businessData.append('ownerName', this.state.ownerName);
        businessData.append('ownerPhone', this.state.ownerPhone);
        businessData.append('ownerEmail', this.state.ownerEmail);
        businessData.append('ownerPassword', this.state.ownerPassword);
        businessData.append('business_id', this.state.business_id);
        businessData.append('countryID', this.state._countryId);
        businessData.append('businessPhoneCode', this.state._countryPh);
        businessData.append('ownerPhoneCode', this.state._countryPh);

        new Promise((resolve, reject) =>{
            fetch(global.adminEditBusiness, {
                method:  'POST',
                body: businessData
            })
            .then((response) => response.json())
            .then((res) => {
                //console.log(res);
                if(res.success == true)
                {
                    sendNotification("Success Message 👍",res.message,"success");
                    window.location.href = global.webUrl+'administrator/manage-business/';
                }
                else
                {
                    sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['businessPhone'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    /* Image Code */
    // onChangeFileUploadHandler=(event)=>{
    //     if (event.target.files) {
    //         /* Get files in array form */
    //         const files = Array.from(event.target.files);    
    //         /* Map each file to a promise that resolves to an array of image URI's */ 
    //         Promise.all(files.map(file => {
    //             return (new Promise((resolve,reject) => {
    //                 const reader = new FileReader();
    //                 reader.addEventListener('load', (ev) => {
    //                     resolve(ev.target.result);
    //                 });
    //                 reader.addEventListener('error', reject);
    //                 reader.readAsDataURL(file);
    //             }));
    //         }))
    //         .then(images => {
    //             this.setState({ businessLogo : images })
    //         }, error => {        
    //             console.error(error);
    //         });
    //     }
    // }

    onChangeFileUploadHandler=event=>{       
        let reader = new FileReader();
        let file = event.target.files[0];        
        let errors = this.state.errors;
        errors['businessLogo'] = '';
        reader.onloadend = () => {
        this.setState({
            businessLogo: file,
            errors: errors,
            imagePreviewUrl: reader.result
        });
        }
        reader.readAsDataURL(file);
    }

    previewImagesListing()
    {   
        if(this.state.isLoaded == true)
        {
            let dataList = [];
            let _imageDetails = this.state.imagePreviewUrl;
            if(_imageDetails!=''){
                dataList.push(
                    <img style={{width:'35px'}} className="preview" src={_imageDetails} alt={"image-"}  key="13213"/>
                )
            }
            return dataList;
        }
    }
    /* End Here*/

    /* Country Code Dropdown Start Here */
    selectCounties(code,flag,name,e){
        this.setState({
            _countryReg:'iti__hide',
            _countryFlag:flag,
            _countryName:name,
            _countryPh:code,
        })
    }

    manageMultiCounties(key){
        if(key == 'I'){
            this.setState({_countryReg:''});
        }
        else if(key == 'O'){
            this.setState({_countryReg_O:''});
        }
    }

    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';

                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{conty.phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }

    onMouseLeaveHandler(key) {
        if(key == 'I'){
            this.setState({_countryReg:'iti__hide'});
        }
        else if(key == 'O'){
            this.setState({_countryReg_O:'iti__hide'});
        }
    }
    /* Country Code Dropdown End Here */

    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Add / Edit Business<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/manage-business/"}>Business List</a>
                        <span className="breadcrumb-item active">Add / Edit Business </span>
                    </nav>
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Business</h3>
                        </div>

                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="businessName" name="businessName" value={this.state.businessName} placeholder="Business Name Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Business Name <span className="text-danger">*</span></label>
                                            <span className="vs_error_txt">{errors.businessName.length > 0 && <span className='error'>{errors.businessName}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="business Email-id Here" value={this.state.businessEmail} className="form-control" id="userPassword" name="businessEmail" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Business Email-Id <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="businessEmail-error" className="animated fadeInDown">{errors.businessEmail.length > 0 && <span className='error'>{errors.businessEmail}</span>}</div>
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    {/* <div className="col-6">
                                        <div className="form-material open">
                                            <input type="file" style={{width:'60%',display: 'inline-block'}} required="required" placeholder="file" autoComplete="false" className="form-control" id="businessLogo" name="businessLogo" onChange={this.onChangeFileUploadHandler}/>
                                            {this.previewImagesListing()}
                                            <label htmlFor="login-password">Business Logo <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="businessLogo-error" className="animated fadeInDown">{errors.businessLogo.length > 0 && <span className='error'>{errors.businessLogo}</span>}</div>
                                        
                                    </div> */}
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this,'I')} onMouseLeave={this.onMouseLeaveHandler.bind(this,'I')}>
                                                <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName}>
                                                    <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div>
                                                    <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                    <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                </div>

                                                <ul className={this.state._countryReg +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                    {this.getReadyMobileCode()}
                                                </ul>
                                            </div>
                                            <input type="text" required="required" style={{display: 'inline-block',width:'80%'}} placeholder="9876XXXXXX" autoComplete="false" value={this.state.businessPhone} className="form-control" id="businessPhone" name="businessPhone" onChange={this.handleChange} maxLength="12" />
                                            <label htmlFor="login-username">Business Contact No. <span className="text-danger">*</span></label>
                                            <span className="vs_error_txt">{errors.businessPhone.length > 0 && <span className='error'>{errors.businessPhone}</span>}</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-6">
                                        <div className="form-material open">
                                            <input type="number" required="required" placeholder="9876XXXXXX" autoComplete="false" value={this.state.businessPhone} className="form-control" id="businessPhone" name="businessPhone" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Business Contact Number <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="businessPhone-error" className="animated fadeInDown">{errors.businessPhone.length > 0 && <span className='error'>{errors.businessPhone}</span>}</div>
                                    </div> */}
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Business Address Here" autoComplete="false" value={this.state.businessAddress} className="form-control" id="businessAddress" name="businessAddress" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Business Address <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="businessAddress-error" className="animated fadeInDown">{errors.businessAddress.length > 0 && <span className='error'>{errors.businessAddress}</span>}</div>
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Owner Name Here" autoComplete="false" value={this.state.ownerName} className="form-control" id="ownerName" name="ownerName" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Owner Name <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="ownerName-error" className="animated fadeInDown">{errors.ownerName.length > 0 && <span className='error'>{errors.ownerName}</span>}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this,'O')} onMouseLeave={this.onMouseLeaveHandler.bind(this,'O')}>
                                                <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName}>
                                                    <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div>
                                                    <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                    <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                </div>

                                                <ul className={this.state._countryReg_O +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                    {this.getReadyMobileCode()}
                                                </ul>
                                            </div>
                                            <input type="text" required="required" style={{display: 'inline-block',width:'80%'}} placeholder="9876XXXXXX" autoComplete="false" value={this.state.ownerPhone} className="form-control" id="ownerPhone" name="ownerPhone" onChange={this.handleChange} maxLength="12" />
                                            <label htmlFor="login-username">Owner Contact No. <span className="text-danger">*</span></label>
                                            <span className="vs_error_txt">{errors.ownerPhone.length > 0 && <span className='error'>{errors.ownerPhone}</span>}</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-6">
                                        <div className="form-material open">
                                            <input type="number" required="required" placeholder="9876XXXXXX" autoComplete="false" value={this.state.ownerPhone} className="form-control" id="ownerPhone" name="ownerPhone" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Owner Contact Number <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="ownerPhone-error" className="animated fadeInDown">{errors.ownerPhone.length > 0 && <span className='error'>{errors.ownerPhone}</span>}</div>
                                    </div> */}
                                </div>
                                
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="email" required="required" placeholder="Owner Email-id Here" autoComplete="false" value={this.state.ownerEmail} className="form-control" id="ownerEmail" name="ownerEmail" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Owner Email-id <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="ownerEmail-error" className="animated fadeInDown">{errors.ownerEmail.length > 0 && <span className='error'>{errors.ownerEmail}</span>}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="password" required="required" placeholder="******" autoComplete="false" value={this.state.ownerPassword} className="form-control" id="ownerPassword" name="ownerPassword" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Password 
                                            {this.state.business_id == 0 &&
                                                <span className="text-danger">*</span>
                                            }
                                            </label>
                                        </div>
                                        <div id="ownerPassword-error" className="animated fadeInDown">{errors.ownerPassword.length > 0 && <span className='error'>{errors.ownerPassword}</span>}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" disabled={isDisabled} className="btn btn-sm btn-hero btn-alt-primary" onClick={this.clickToAddBusiness}>
                                    <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} Business
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}