import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import QuickAddNewParty from '../businessParty/quickPartyAdd';


let _UserSession = localStorage.getItem('vs_UserSession');
let _signingId = '0';
let _businessId = '0';
let company_id = '0';
let country_code = '0';
let companyData = [];
let tax_name = '';
let gstTypeData = '';
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
    company_id = _UserSession.company_id;
    companyData = _UserSession.companies;
}

/*Active Compnay Data*/
companyData.map((companies, i) =>{
    let activeData =  companyData[i]['isActive'];
    if(activeData == true){
        country_code =  companyData[i]['country_code'];
        tax_name =  companyData[i]['tax_name'];
        gstTypeData =  companyData[i]['taxType'];
    }
})
/*Active Compnay Data*/

let _loader = 'glb-ldr-prt active';

export default class partyListPanel extends Component{
    constructor(props){
        super(props);
    }
    
    render()
    {
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _partyId = _handleParam[1] ? _handleParam[1] : 0;

        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Add / Edit Party<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"business/dashboard/"}>Dashboard</a>                        
                        <a className="breadcrumb-item" href={global.webUrl+"business/manage-party/"}>View Party List</a>
                        <span className="breadcrumb-item active">Add / Edit Party</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Party </h3>
                        </div>
                        <div className="block-content">
                            <QuickAddNewParty isMainPage={true} partyId={_partyId} />
                        </div>
                    </div>

                        
                </div>
                
            </React.Fragment>
        )    
    }
}