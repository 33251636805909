import React,{Component} from 'react';
import {PostData} from '../service/postData';
import {userSessionManage} from '../service/userLocalStorage';
import {sendNotification} from '../_notificationSettings/notificationPanel';
import Modal from 'react-modal';

export default class accountLoginPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            modalIsPopupAlert : false,
            alertMsg : '',

            _countryLang:'',
            _countryReg:'iti__hide',
            _countryFlag:'',
            _countryName:'',
            _countryPh:'',
            _countryId:'0',

            countryArray : [],
            //userContact : 'admin@vyavsay.com',
            userContact : '',//9755299999
            userPassword: '',//iamadmin
            selUserType: 'Admin',
            errors : {
                userContact : '',
                userPassword : '',
                selUserType : ''
            }
        }
    }

    async componentDidMount()
    {
        localStorage.removeItem('vs_UserSession');
        localStorage.removeItem('vs_UserCountrySession');
        
        let _countryCode = 'IN';
        
        //'http://api.hostip.info'
        //'https://ipinfo.io/'
        PostData('http://ip-api.com/json','','GET').then(response => {
            let responseJson = response;
            _countryCode = responseJson.countryCode;
            userSessionManage(localStorage,responseJson,'countrySession');
        })
        .catch(error => this.setState({ error, isLoaded: false }));
        
        let _urlCountryData = global.adminCountryList;
        let countryData = await PostData(_urlCountryData,'','GET');
        let _conLang,_conFlag,_conName,_conPh,_conId = '';
        countryData.response.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            let _imgPath = global.FLAG_URL+_name+'.png';

            if(_countryCode == conty.sortname){
                _conLang = conty.sortname;
                _conFlag = _imgPath;
                _conName=conty.name;
                _conPh=conty.phonecode;
                _conId=conty.id;
            }
        });
        this.setState({countryArray:countryData.response,_countryId:_conId,_countryLang:_conLang,_countryFlag:_conFlag,_countryName:_conName,_countryPh:_conPh,isLoaded:true});
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'userContact': 
            errors.userContact = 
              value.length < 8
                ? '*Please enter valid contact no.'
                : '';
                this.setState({userContact:value}); 
            break;
            case 'userPassword': 
                errors.userPassword = 
                value.length < 5
                ? '*Password must be 5 characters long!'
                : '';
                this.setState({userPassword:value}); 
            break;
            case 'selUserType':
                errors.selUserType = value.length < 2 ? 'Please select user type' : '';
                this.setState({selUserType:value});    
            break;
            default:
            break;
        }
    }

    clickToSignAdmin = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.userContact == null)
        {
            _isError = true;
            errors['userContact'] = "*Please Enter Contact No.";
            this.setState({errors: errors});
        }
        if(this.state.userContact.length < 8 || this.state.userContact.length >12 )
        {
            _isError = true;
            errors['userContact'] = "*Please enter valid contact no.";
            this.setState({errors: errors});
        }
        if(this.state.userPassword == null)
        {
            _isError = true;
            errors['userPassword'] = "*Please Enter Password.";
            this.setState({errors: errors});
        }
        if(this.state.selUserType == '')
        {
            _isError = true;
            errors['selUserType'] = "*Please select User Type.";
            this.setState({errors: errors});
        }

        if(_isError == false)
        {
            this.signMethodCall();
        }
    }

    signMethodCall(){
        const requestData = JSON.stringify({
            password: this.state.userPassword,
            contact_no:this.state.userContact,
            loginType:this.state.selUserType,
            country_code:this.state._countryPh
        });
        PostData(global.userSignIn, requestData,'POST').then((result) => {
            let responseJson = result;                
            if(responseJson.success == false)
            { 
                //sendNotification("Error Message 😓",responseJson.message,"danger");
                
                this.openModalHandler('modalIsPopupAlert');
                this.setState({'alertMsg': responseJson.message});
                return;
            }
            else
            {
                let _response = responseJson.response[0];
                let _redirectUrl = global.webUrl+'administrator/dashboard/';
                if(_response.login_type == 'Business' || _response.login_type == 'BusinessUser'){
                    _redirectUrl = global.webUrl+'business/dashboard/';
                }
                // else if(_response.login_type == 'BusinessUser'){
                //     _redirectUrl = global.webUrl+'user/dashboard/';
                // }
                
                sendNotification("Welcome Back ("+_response.user_name+") 👍",responseJson.message,"success");
                userSessionManage(localStorage,_response,'startSession');

                let _companyDetails = _response.companies;
                _companyDetails.map((compDatas, i) =>
                {
                    if(compDatas.isActive == true){
                        userSessionManage(localStorage,compDatas,'companySession');
                    }
                });
                setTimeout(
                    () => {
                        window.location.href = _redirectUrl;
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
                return;
            }
        });
    }

    /* Country Code Dropdown Start Here */
    selectCounties(code,flag,name,e){
        this.setState({
            _countryReg:'iti__hide',
            _countryFlag:flag,
            _countryName:name,
            _countryPh:code,
        })
    }

    manageMultiCounties(){
        this.setState({
            _countryReg:'',
        })
    }

    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';

                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{conty.phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }
    
    /*For Modal open close*/
    openModalHandler(e)
    {
        if(e == 'modalIsPopupAlert'){
            this.setState({modalIsPopupAlert: true});
        }
    }
    closeModalHandler = (e) => {
        if(e == 'modalIsPopupAlert'){
            this.setState({modalIsPopupAlert: false});
        }
        
    } 
    /*End Modal open close*/
    
    onMouseLeaveHandler() {
        this.setState({
            _countryReg:'iti__hide',
        })
    }
    /* Country Code Dropdown End Here */

    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div className="bg-image" style={{backgroundImage: 'url('+global.webUrl+'exImages/background.jpg'+')'}}>
                        <div className="row mx-0 bg-black-op">
                            <div className="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end">
                                <div className="p-30 invisible" data-toggle="appear">
                                    <p className="font-size-h3 font-w600 text-white">
                                        Created By Hvantage Technologies Inc.
                                    </p>
                                    <p className="font-italic text-white-op">
                                        Copyright &copy; <span className="js-year-copy"></span>
                                    </p>
                                </div>
                            </div>
                            <div className="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-white invisible" data-toggle="appear" data-class="animated fadeInRight">
                                <div className="content content-full">
                                    <div className="px-30 py-10">
                                        <a className="font-w700" href={global.webUrl}>
                                            <img src={global.webUrl+"websiteFilesFolder/img/logo.png"} alt="Vyavsay Dashboard Login" title="Vyavsay Dashboard Login" />
                                            {/* <i className="si si-fire"></i><span className="font-size-xl text-primary-dark">Vyavsay </span><span className="font-size-xl">Dashboard</span> */}
                                        </a>
                                        
                                        <h1 className="h3 font-w700 mt-30 mb-10">Welcome to Dashboard</h1>
                                        <h2 className="h5 font-w400 text-muted mb-0">Please sign in</h2>
                                    </div>
                                    <form method="post" id="form-login" className="js-validation-signin px-30">
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this)} onMouseLeave={this.onMouseLeaveHandler.bind(this)}>
                                                        <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName}>
                                                            <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div>
                                                            <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                            <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                        </div>

                                                        <ul className={this.state._countryReg +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                            {this.getReadyMobileCode()}
                                                        </ul>
                                                    </div>
                                                    <input type="text" style={{display: 'inline-block',width:'70%'}} required="required" className="form-control" id="userContact" name="userContact" value={this.state.userContact} placeholder="999999999" onChange={this.handleChange}maxLength="12"/>
                                                    <label htmlFor="login-username">Contact No. <span className="text-danger">*</span></label>
                                                    <span className="vs_error_txt">{errors.userContact.length > 0 && <span className='error'>{errors.userContact}</span>}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <input type="password" required="required" placeholder="******" autoComplete="false" value={this.state.userPassword} className="form-control" id="userPassword" name="userPassword" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">Password <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="userPassword-error" className="animated fadeInDown">{errors.userPassword.length > 0 && <span className='error'>{errors.userPassword}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <select name="selUserType" id="selUserType" className="form-control" required="required" defaultValue={this.state.selUserType} onChange={this.handleChange}>
                                                        <option value="">Select Login via</option>
                                                        <option value="Admin" >Admin</option>
                                                        <option value="Business">Business</option>
                                                        <option value="BusinessUser">Business User</option>
                                                    </select>
                                                    
                                                    <label htmlFor="login-usertype">Login via <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="selUserType-error" className="animated fadeInDown">{errors.selUserType.length > 0 && <span className='error'>{errors.selUserType}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" disabled={isDisabled} className="btn btn-sm btn-hero btn-alt-primary" onClick={this.clickToSignAdmin}>
                                                <i className="si si-login mr-10"></i> Login to Dashboard
                                            </button>

                                            <div class="mt-30">
                                                <a class="link-effect text-muted mr-10 mb-5 d-inline-block" href={global.webUrl+"business-register/"}> <i class="fa fa-plus mr-5"></i> Create Account </a>
                                                <a class="link-effect text-muted mr-10 mb-5 d-inline-block" href={global.webUrl}> <i class="si si-globe mr-5"></i> Goto Website </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Modal Alert Message Data Begins */}
                    <Modal isOpen={this.state.modalIsPopupAlert} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalIsPopupAlert">
                            <div className="modal-dialog modal-ss" role="document">
                                <div className="modal-content">
                                    <div className="block block-themed block-transparent mb-0">
                                        <div className="block-header bg-primary-dark">
                                            <h3 className="block-title">Message</h3>
                                            <div className="block-options">
                                                <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalIsPopupAlert')}>
                                                    <i className="si si-close"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="block-content">
                                            <p>{this.state.alertMsg}</p>
                                        </div>
                                        <br/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalIsPopupAlert')}>OK</button>
                                    </div>
                                </div>
                            </div>
                    </Modal>
                    {/* Modal Alert Message Data End */}

                </React.Fragment>
                
            </div>
            
        )
    }
}