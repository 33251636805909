import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

global.BACK_URL = 'Company';

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'GST Type Name',
        field: 'name',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class companyListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            gstName : null,
            gstArray : [],
            gstId : 0,
            errors: {
                gstName : ''
            }
        }
    }

    async componentDidMount ()
    {
        /* Get Url Pass Parameter */
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _gstId = _handleParam[1] ? _handleParam[1] : 0;
        /* End Here Url Pass Parameter */

        if(_gstId > 0){
            let _gstArray = [];
            let _url_GetData = global.gstTypeDetails+'?gstId='+_gstId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _gstArray = responseJson.response[0];
                        this.setState({buttonTitle:'Edit',gstName:_gstArray.gst_type,gstId:_gstArray.id,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
        this.getGstListData();
    }

    getGstListData(){
        let _gstArray = [];

        let _url_GetData = global.gstTypeList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _gstArray = responseJson.response;
                }
                this.setState({gstArray:_gstArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'gstName': 
            errors.gstName = 
                value.length < 3 ? '*GST Type Name must be 3 characters long!' : '';
                this.setState({gstName:value}); 
            break;
            default:
            break;
        }
    }

    clickToAddGst = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.gstName == null)
        {
            _isError = true;
            errors['gstName'] = "*Please Enter GST Type Name";
            this.setState({errors: errors});
        }

        if(_isError == false){
            if(this.state.gstId > 0){
                this.editGstMethodCall();
            }
            else{
                this.addGstMethodCall();
            }
        }
    }

    editGstMethodCall()
    {
        const requestData = JSON.stringify({
            gst_name: this.state.gstName,
            gstId: this.state.gstId
        });
        PostData(global.adminEditGstType, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-gst-type/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['gstName'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    addGstMethodCall(){
        const requestData = JSON.stringify({
            gst_name: this.state.gstName
        });

        PostData(global.adminAddGstType, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-gst-type/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['gstName'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    loadOrderListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _gstDetails = this.state.gstArray;
            let dataList = [];
            let rows = [];

            if(_gstDetails.length > 0)
            {                          
                _gstDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    
                    rows.push({
                        name: row.name,
                        srNo: row.srNo,
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/manage-gst-type/?auth='+row.id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage GST Type List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage GST Type List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit GST Type</h3>
                        </div>
                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="gstName" name="gstName" value={this.state.gstName} placeholder="Gst Type Name Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">GST Type Name <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.gstName.length > 0 && <span className='error'>{errors.gstName}</span>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddGst}>
                                            <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} GST Type
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">List of Company</h3>
                        </div>
                        <div className="block-content">
                            <MDBDataTableV5 
                            hover
                            entriesOptions={[5, 20, 25]}
                            entries={5}
                            pagesAmount={4}
                            span
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            data={this.loadOrderListing()} />
                        </div>
                    </div>    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div class="gl-ldr-cld">
                        <div class="gl-ldr-ctr">
                            <div class="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}