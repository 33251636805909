import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import QuickAddNewItem from "../userItem/quickItemAdd";

import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let maxNumber = 999999;
let _invoiceNumber = Math.floor(Math.random() * maxNumber + 1);

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];
let _settingType = "showall";
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _businessSetting = {};
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        _bankArray.push(bank);
      });
    }
    this.state = {
      buttonTitle: "Add Purchase",
      _timeReg: "iti__hide",

      partyRemainBalance: 0,
      _partyReg: "iti__hide",
      party_id: "0",
      party_name: "",
      partyArrayFilter: [],
      partyArray: [],
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,

      billing_name: "",
      invoiceTime:
        _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : "",

      _itemReg: "iti__hide",
      _itemRegIndex: "0",

      isLoaded: true,
      isDateUpdate: false,

      receivedHide: "iti__hide",

      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",
      settingType: _settingType,
      invoiceNo: _invoiceNumber,
      invoiceDate: moment().format("MM/DD/YYYY"),
      countryState: "",
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: "",

      bankId: "0",
      bankName: "",

      receivedAmount: 0,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      party_contact_no: "",
      paidAmountEr: "",

      errors: {
        invoiceNo: "",
        invoiceDate: "",
        party_id: "",
        party_name: "",
        paidAmountEr: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let transactionId = _handleParam[1] ? _handleParam[1] : 0;
    if (transactionId > 0) {
      _isEditMode = true;
    }

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/purchase-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let customerData = _transactionArray.customer;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              this.setState({
                billing_name: customerData.billing_name
                  ? customerData.billing_name
                  : "",
                invoiceTime: _transactionArray.invoice_time,

                party_id: customerData.party_id,
                party_name: customerData.party_name,
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                buttonTitle: "Edit Payment Out",
                //invoiceNo:_transactionArray.invoiceNo,
                invoiceDate: moment(_transactionArray.invoiceDate).format(
                  "MM/DD/YYYY"
                ),
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                paidAmount: transAmout.paidAmount,
                totalAmount: transAmout.dueAmount,
                receivedAmount: transAmout.dueAmount,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _transactionArray.paymentRefNo,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                dueAmount: transAmout.dueAmount,
                settingType: _transactionArray.settingType,
                party_contact_no: _transactionArray.party_contact_no,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
    this.getPartyListData();
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            _partyArray = responseJson.response;

            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
          value.length < 0 ? "*Invoice No Must be 1 numbers" : "";
        this.setState({ invoiceNo: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "party_contact_no":
        this.setState({ party_contact_no: value });
        break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        //console.log(value);
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        //console.log(_bankId+'--'+_bankName+'---'+value+'----'+_valType);
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;

      case "receivedAmount":
        let _valuePaid = value;
        if (parseFloat(_valuePaid) > parseFloat(this.state.dueAmount)) {
          errors.paidAmountEr =
            "*Paid amount cannot be greater than due amount";
        } else {
          errors.paidAmountEr = "";
        }
        let _remainAmount = 0;
        let _paidAmt = _valuePaid;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        if (_paidAmt >= 0) {
          _remainAmount =
            parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
        }
        //this.setState({receivedAmount:_paidAmt,dueAmount:_remainAmount.toFixed(2)});
        this.setState({ receivedAmount: _paidAmt });
        break;

      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;
      case "settingType":
        this.setState({ settingType: value });
        break;

      default:
        break;
    }
  };

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  /* Add Party Code */
  selectPartiess(name, e, balance, party_contact_no) {
    let errors = this.state.errors;
    errors.party_name = "";
    this.setState({
      _partyReg: "iti__hide",
      party_name: name,
      party_id: e,
      partyRemainBalance: balance,
      party_contact_no: party_contact_no,
    });

    this.setState({ fields: [] });
  }

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance,
                party.party_contact_no
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance">
                {numberFormat(_balance)}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ partyArrayFilter: filtered, party_name: keyword });
    this.getReadyPartyList();
  };
  /* End Party Code */

  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }
    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Expense No.";
      this.setState({ errors: errors });
    }

    if (
      parseFloat(this.state.receivedAmount) > parseFloat(this.state.dueAmount)
    ) {
      _isError = true;
      errors["paidAmountEr"] = "*Paid amount cannot be greater than due amount";
      this.setState({ errors: errors });
    }
    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editSaleTransactionMethodCall();
      } else {
        this.addSaleTransactionMethodCall();
      }
    }
  };

  addSaleTransactionMethodCall() {
    let _prefix = JSON.stringify({
      name: "",
      id: "0",
      isActive: "1",
    });

    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: this.state.totalAmount,
      paidAmount: this.state.paidAmount,
      dueAmount: this.state.dueAmount,
      receivedAmount: this.state.receivedAmount,
    });

    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    var saleData = new FormData();
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "PAYMENT-OUT");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    saleData.append("invoiceTime", this.state.invoiceTime);

    saleData.append("totalSummary", _totalSummary);
    saleData.append("customer", _customer);
    //saleData.append('items', this.state.fields);
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("settingType", this.state.settingType);

    new Promise((resolve, reject) => {
      fetch(global.createExpensePaymentOutTransaction, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "/business/manage-expense/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editSaleTransactionMethodCall() {
    let _prefix = JSON.stringify({
      name: "",
      id: "0",
      isActive: "1",
    });

    let _totalSummary = JSON.stringify({
      subtotal: this.state.totalAmount,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: this.state.totalAmount,
      paidAmount: this.state.paidAmount,
      dueAmount: this.state.dueAmount,
      receivedAmount: this.state.receivedAmount,
    });

    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    var saleData = new FormData();
    saleData.append("transaction_id", this.state.transactionId);
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "PAYMENT-OUT");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    saleData.append("invoiceTime", this.state.invoiceTime);

    saleData.append("totalSummary", _totalSummary);
    saleData.append("customer", _customer);
    //saleData.append('items', this.state.fields);
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("selectedCountry", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("settingType", this.state.settingType);

    new Promise((resolve, reject) => {
      fetch(global.createPurchasePaymentOutRecevTransaction, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/manage-expense/";
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    //this.setState({invoiceDate:start.format('MMMM D, YYYY')});
    this.setState({ invoiceDate: start.format("MM/DD/YYYY") });
  };

  settleAmountType() {
    let _payData = [];

    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          _payData.push(
            <option
              value={_valOption}
              selected={_selBank}
              data-bankName={_disOption}
              data-bankId={_valOption}
            >
              {_disOption}
            </option>
          );

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }

    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_invoiceDate}
          />
        </DateRangePicker>
      );
    }
  }

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Payment-Out
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">Payment-Out </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandler.bind(
                            this,
                            "I"
                          )}
                        >
                          <input
                            type="text"
                            className="form-control search-box"
                            readOnly="reaonly"
                            placeholder="Search Party Name Here..."
                            autoComplete="off"
                            name="party_name"
                            id="party_name"
                            value={this.state.party_name}
                          />
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>
                          <ul
                            className={
                              this.state._partyReg +
                              " iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {this.getReadyPartyList()}
                          </ul>
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>
                          <div
                            className={
                              this.state.partyRemainBalance < 0
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {this.state.party_name!=""
                              ? "Bal : " +
                                numberFormat(this.state.partyRemainBalance)
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="number"
                            className="form-control search-box"
                            placeholder="Phone Number"
                            autoComplete="off"
                            name="party_contact_no"
                            id="party_contact_no"
                            value={this.state.party_contact_no}
                            onChange={this.handleChange}
                            readOnly="reaonly"
                          />
                          <label htmlFor="login-username">Phone Number</label>
                        </div>
                      </div>
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Name Here..."
                              autoComplete="off"
                              name="billing_name"
                              id="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Name</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      {_businessSetting.InvoiceBillNo == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Receipt No. <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="invoiceNo"
                              name="invoiceNo"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Expense No."
                              autoComplete="off"
                              value={this.state.invoiceNo}
                            />
                            <div
                              id="invoiceNo-error"
                              className="animated fadeInDown"
                            >
                              {errors.invoiceNo.length > 0 && (
                                <span className="error">
                                  {errors.invoiceNo}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly={_readonlyInput}
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType!="Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                required="required"
                                id="paymentRefNo"
                                name="paymentRefNo"
                                value={this.state.paymentRefNo}
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}

                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>
                        {_businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                id="settingType"
                                name="settingType"
                                onChange={this.handleChange}
                              >
                                {optionsData.map(({ value, label }, index) => {
                                  let _selSet = "";
                                  if (this.state.settingType == value) {
                                    _selSet = "selected";
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                      selected={_selSet}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="col-md-12" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="balAmount"
                        >
                          Balance Due
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="number"
                            className="form-control"
                            id="dueAmount"
                            name="dueAmount"
                            placeholder=""
                            value={this.state.dueAmount}
                            onChange={this.handleChange}
                            autoComplete="off"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="paidAmount"
                          >
                            Paid Amount
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              className="form-control"
                              id="receivedAmount"
                              name="receivedAmount"
                              placeholder=""
                              value={this.state.receivedAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />

                            <div
                              id="paidAmountEr-error"
                              className="animated fadeInDown"
                            >
                              {errors.paidAmountEr.length > 0 && (
                                <span className="error">
                                  {errors.paidAmountEr}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty
                      isRedirectTo={"business/add-purchase-payment-out/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Edit Item Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SITEM}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewItem"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Item 1</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewItem"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewItem />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewItem"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Item Details*/}
          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail
                      isRedirectTo={"business/add-sale/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}
        </div>
      </React.Fragment>
    );
  }
}
