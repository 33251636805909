import React, { Component } from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';



let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Sms Type',
        field: 'sms_type',
        width: 100,
    },
    {
        label: 'Sms Title',
        field: 'sms_title',
        width: 100,
    },
    {
        label: 'Sms Header',
        field: 'sms_header',
        width: 100,
    },
    {
        label: 'Sms Details',
        field: 'sms_details',
        width: 100,
    },
    {
        label: 'Sms Footer',
        field: 'sms_footer',
        width: 100,
    },
    {
        label: 'Sms DLT Id',
        field: 'dlt_id',
        width: 100,
    },
    {
        label: 'Status',
        field: 'status',
        width: 100,
    },
    {
        label: 'Dlt Status',
        field: 'dltstatus',
        width: 100,
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
    
];

let _loader = 'glb-ldr-prt active';

export default class serviceListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            modalIsOpen_CAT : false,
            smsArrayFilter : [],
            smsArray :[],
            sms_title : '',
            sms_header : '',
            sms_details : 'Details: Item(s): {#var#}. Quantity: {#var#}. Invoice Amount: {#var#}. -----',
            sms_footer : 'Powered By vyavsay.in',
            dlt_id : '0',
            _smsId : 0,
            sms_type : 'SALE',
            errors: {
                sms_title : '',
                sms_header : '',
                sms_details : '',
                sms_footer : '',
                dlt_id : '',
                sms_type : '',
            }
        }
    }
    
    async componentDidMount ()
    {
        this.getSMSListData();
        
        /* Get Url Pass Parameter */
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _smsId = _handleParam[1] ? _handleParam[1] : 0;
        /* End Here Url Pass Parameter */
    
        if(_smsId > 0){
            let _smsTypeArray = [];
            let _url_GetData = global.smsTypeDetails+'?smsId='+_smsId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {

                    if (responseJson.response) {
                        _smsTypeArray = responseJson.response[0];
                        
                        this.setState({buttonTitle:'Edit',sms_type:_smsTypeArray.sms_type,dlt_id:_smsTypeArray.dlt_id,sms_footer:_smsTypeArray.sms_footer,sms_details:_smsTypeArray.sms_details,sms_header:_smsTypeArray.sms_header,sms_title:_smsTypeArray.sms_title,_smsId:_smsId,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        
        switch (name) {
            case 'sms_title': 
            errors.sms_title = 
                value.length < 3 ? '* Title must be 3 characters long!' : '';
                this.setState({sms_title:value}); 
            break;

            case 'sms_header':
                errors.sms_header  = 
                value.length < 5 ? '* SMS header must be 5 characters long!' : '';
                this.setState({sms_header:value}); 
            break;

            case 'sms_details':
                errors.sms_details  = 
                value.length < 5 ? '* SMS detail must be 5 characters long!' : '';
                this.setState({sms_details:value}); 
            break;

            case 'sms_footer':
                errors.sms_footer  = 
                value.length < 5 ? '* SMS footer content must be 5 characters long!' : '';
                this.setState({sms_footer:value}); 
            break;

            case 'dlt_id':
               
                this.setState({dlt_id:value}); 
            break;
            case 'sms_type':
                errors.sms_type  = 
                value.length < 0 ? '* Please select any sms type' : '';
                this.setState({sms_type:value}); 
            break;
            
            
            default:
            break;
        }
    }
    
  

    
    clickToAddSMSSetting = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.sms_title == '')
        {
            _isError = true;
            errors['sms_title'] = "*Please Enter Sms Title";
            this.setState({errors: errors});
        }
        if(this.state.sms_header == '')
        {
            _isError = true;
            errors['sms_header'] = "*Please Enter sms header.";
            this.setState({errors: errors});
        }
        if(this.state.sms_details == '')
        {
            _isError = true;
            errors['sms_details'] = "*Please Enter sms details";
            this.setState({errors: errors});
        }
        if(this.state.sms_footer == '')
        {
            _isError = true;
            errors['sms_footer'] = "*Please Enter sms footer content";
            this.setState({errors: errors});
        }
        
        if(_isError == false){
            if(this.state._smsId > 0){
                this.editSmsSettingMethod();
            }else{
                this.addSmsSettingMethod();
            }
        }
    }

    addSmsSettingMethod(){
        const requestData = JSON.stringify({
            sms_type : this.state.sms_type,
            sms_title : this.state.sms_title,
            sms_header : this.state.sms_header,
            sms_details : this.state.sms_details,
            sms_footer : this.state.sms_footer,
            dlt_id : this.state.dlt_id

        });
        PostData(global.addNewSMSTemplate,requestData,'POST').then((result)=>{
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                window.location.href = global.webUrl+'administrator/manage-sms-setting/';
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }

    editSmsSettingMethod(){
        const requestData = JSON.stringify({
            smsId: this.state._smsId,
            sms_type : this.state.sms_type,
            sms_title : this.state.sms_title,
            sms_header : this.state.sms_header,
            sms_details : this.state.sms_details,
            sms_footer : this.state.sms_footer,
            dlt_id : this.state.dlt_id

        });
        PostData(global.editSMSTemplate,requestData,'POST').then((result)=>{
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                window.location.href = global.webUrl+'administrator/manage-sms-setting/';
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    } 
    
    clickToStatus(value,_status)
    {
        confirmAlert({
            title: 'Confirm to active this Sms',
            message: 'Are you sure you want to change the status?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        smsId: value,
                        status:_status
                    });
                    PostData(global.smsManageStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSMSListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }
    
    clickToDelete (value){
        confirmAlert({
            title: 'Confirm to update delete this sms',
            message: 'Are you sure you want to delete?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-dark',
                onClick: () => {
                    const requestData = JSON.stringify({
                        smsId: value
                    });
                    PostData(global.smsManageDelete, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSMSListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    clickToDLTApprove(value,_status){
        confirmAlert({
            title: 'Confirm to update DLT status',
            message: 'Are you sure you want to change the status?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        smsId: value,
                        dltstatus:_status
                    });
                    PostData(global.smsManageDLTStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            this.getSMSListData();
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }

    getSMSListData(){
        let _allSMSArray = [];

        let _url_GetData = global.allSMSList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _allSMSArray = responseJson.response;
                }
                this.setState({smsArray:_allSMSArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    loadSMSListing()
    {   
        if(this.state.isLoaded == true)
        {
            
            let _allSMSDetails = this.state.smsArray;
            let dataList = [];
            let rows = [];
            
            if(_allSMSDetails.length > 0)
            {                          
                _allSMSDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    let _status = 1;
                    let status = <span className={`badge badge-danger`}>Disabled</span>;
                    if (row.isActive == '1') {
                        _status = 0;
                        status = <span className={`badge badge-success`}>Enabled</span>;
                    }
                    
                    let dltstatus = <span className={`badge badge-danger`}>Not-Approve</span>;
                    let _dltstatus = 1;
                    if (row.dlt_status == '1') {
                        _dltstatus = 0;
                        dltstatus = <span className={`badge badge-success`}>Approved</span>;
                    }

                    let _dtt = row.added_on;
                    var options = {year: 'numeric', month: 'long', day: 'numeric' };

                    rows.push({ 
                        date: new Intl.DateTimeFormat('en-US', options).format(_dtt),
                        sms_type: row.sms_type,
                        sms_title: row.sms_title,
                        sms_header:row.sms_header,
                        sms_details:row.sms_details,
                        sms_footer:row.sms_footer,
                        dlt_id:row.dlt_id,
                        sms_footer:row.sms_footer,
                        srNo: row.srNo,
                        status: (status),
                        dltstatus: (dltstatus),
                        added_on: new Intl.DateTimeFormat('en-US', options).format(_dtt),
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/manage-sms-setting/?auth='+row.id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.id} onClick={this.clickToStatus.bind(this,row.id,_status)}>
                                        <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>Manage Status
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.id} onClick={this.clickToDelete.bind(this,row.id)}>
                                        <i className="si si-trash mr-5 text-danger"></i>Delete SMS
                                    </a>
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.id} onClick={this.clickToDLTApprove.bind(this,row.id,_dltstatus)}>
                                        <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>DLT Status
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {                
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }   

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _smsTitle = this.state.sms_title;
        if(_smsTitle == '' || _smsTitle == null){
            _smsTitle = 'Not Set';
        }
        
        let _readActive = false;
        if(this.state._smsId > 0){
            _readActive = true;
        }

        const {errors} = this.state;        
        const isDisabled = Object.keys(errors).some(x => errors[x]);

        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage SMS Setting List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage SMS Setting List</span>
                    </nav>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block">
                                <div className="block-header block-header-default">
                                    <h3 className="block-title">Add / Edit SMS Details</h3>
                                </div>
                                <div className="block-content">
                                    <form method="post" id="form-login" className="webForm px-30">
                                        <div className="form-group row">
                                            <div className="col-4">
                                                <div className="form-material open">
                                                <select disabled={_readActive} className="form-control" id="sms_type" name="sms_type" onChange={this.handleChange}>
                                                    <option value="SALE" selected={(this.state.sms_type == 'SALE') ? 'selected' : ''}> SALE</option>
                                                    <option value="PURCHASE" selected={(this.state.sms_type == 'PURCHASE') ? 'selected' : ''}> PURCHASE</option>
                                                    <option value="DELIVERY CHALLAN" selected={(this.state.sms_type == 'DELIVERY CHALLAN') ? 'selected' : ''}> DELIVERY CHALLAN</option>
                                                    <option value="SALE ORDER" selected={(this.state.sms_type == 'SALE ORDER') ? 'selected' : ''}> SALE ORDER</option>
                                                    <option value="ESTIMATE" selected={(this.state.sms_type == 'ESTIMATE') ? 'selected' : ''}> ESTIMATE</option>
                                                    <option value="CREDIT NOTE" selected={(this.state.sms_type == 'CREDIT NOTE') ? 'selected' : ''}> CREDIT NOTE</option>
                                                    <option value="PAYMENT-IN" selected={(this.state.sms_type == 'PAYMENT-IN') ? 'selected' : ''}> PAYMENT-IN</option>
                                                    <option value="PAYMENT-OUT" selected={(this.state.sms_type == 'PAYMENT-OUT') ? 'selected' : ''}> PAYMENT-OUT</option>
                                                    <option value="DEBIT NOTE" selected={(this.state.sms_type == 'DEBIT NOTE') ? 'selected' : ''}> DEBIT NOTE</option>
                                                    <option value="PURCHASE ORDER" selected={(this.state.sms_type == 'PURCHASE ORDER') ? 'selected' : ''}> PURCHASE ORDER</option>
                                                </select>
                                                    <label htmlFor="login-password">SMS Type <span className="text-danger">*</span></label>
                                                </div>
                                                { <div id="sms_type-error" className="animated fadeInDown">{errors.sms_type.length > 0 && <span className='error'>{errors.sms_type}</span>}</div> }
                                            </div>
                                            <div className="col-4">
                                                <div className="form-material open">
                                                    <input type="text" required="required" placeholder="SMS Title Here" autoComplete="false" value={this.state.sms_title} className="form-control" id="sms_title" name="sms_title" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">SMS Title <span className="text-danger">*</span></label>
                                                </div>
                                                { <div id="sms_title-error" className="animated fadeInDown">{errors.sms_title.length > 0 && <span className='error'>{errors.sms_title}</span>}</div> }
                                            </div>
                                            <div className="col-4">
                                                <div className="form-material open">
                                                    <input type="text" required="required" style={{display: 'inline-block',width:'80%'}} placeholder="SMS Header Here" autoComplete="false" value={this.state.sms_header} className="form-control" id="sms_header" name="sms_header" onChange={this.handleChange}/>
                                                    <label htmlFor="login-username">SMS Header <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="sms_header-error" className="animated fadeInDown">{errors.sms_header.length > 0 && <span className='error'>{errors.sms_header}</span>}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open">
                                                    <textarea type="text" required="required" placeholder="SMS Details Here" autoComplete="false"  className="form-control" id="sms_details" name="sms_details" onChange={this.handleChange} value={this.state.sms_details} />
                                                    <label htmlFor="login-password">SMS Details <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="sms_details-error" className="animated fadeInDown">{errors.sms_details.length > 0 && <span className='error'>{errors.sms_details}</span>}</div>
                                            </div>                                        
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" placeholder="SMS Footer Content" autoComplete="false" value={this.state.sms_footer} className="form-control" id="sms_footer" name="sms_footer" onChange={this.handleChange}/>
                                                    <label htmlFor="login-password">SMS Footer Content <span className="text-danger">*</span></label>
                                                </div>
                                                <div id="sms_footer-error" className="animated fadeInDown">{errors.sms_footer.length > 0 && <span className='error'>{errors.sms_footer}</span>}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" style={{display: 'inline-block',width:'80%'}} placeholder="SMS DLT ID" autoComplete="false" value={this.state.dlt_id} className="form-control" id="dlt_id" name="dlt_id" onChange={this.handleChange}/>
                                                    <label htmlFor="login-username">SMS DLT ID </label>
                                                </div>
                                            </div>
                                        </div>
                                                                                
                                        <div className="form-group">
                                            <button type="submit" disabled={isDisabled} className="btn btn-sm btn-hero btn-alt-primary" onClick={this.clickToAddSMSSetting}>
                                            <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} SMS
                                            </button>
                                        </div>
                                    </form>
                                </div>       
                            </div>  

                            <div className="block">
                                <div className="block-header block-header-default">
                                    <h3 className="block-title">List of SMS Details</h3>
                                </div>
                                <div className="block-content">
                                    <MDBDataTableV5 
                                    hover
                                    entriesOptions={[25, 50, 100]}
                                    entries={25}
                                    pagesAmount={4}
                                    span
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    barReverse
                                    data={this.loadSMSListing()} />
                                </div>
                            </div>          
                        </div>
                    </div>
                </div>   
                    
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}