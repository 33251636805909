import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import QuickAddNewExpenseCategory from "../userExpense/expenseCategoryMaster";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import validator from "validator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal"; 
import { PaginationControl } from 'react-bootstrap-pagination-control';
import * as XLSX from "xlsx";

import ButtonPdf from "../htmlPdfCreation";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";

let _countryCode = "IN";
let _businessSetting = {};
let _settingType = "showall";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessSetting = _UserSession.businessSetting;
  if (_businessSetting!=undefined || _businessSetting!=null) {
    if (_businessSetting.PrintOriginalDuplicate == 0) {
      _settingType = null;
    }
  }
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}
let columns = [];

let columnsPHistory = [
  {
    label: "Date",
    field: "payment_date",
    width: 50,
  },
  {
    label: "Receipt No",
    field: "payment_rec_no",
    width: 150,
  },
  {
    label: "Amount",
    field: "payment_amount",
    width: 100,
  },
];
let _loader = "glb-ldr-prt active";

export default class expenseCategoryListPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeExpenseCat: "N/A",
      activeExpenseCatId: 0,
      searchInvNo : "",
      activeExpenseCatBalanace: "0",
      activeExpenseCatTotal: "",
      activeExpenseCatExpense: "",

      activeEditCategoryId: 0,

      filterSearch: true,
      isLoaded: true,
      modalIsOpen_AC: false,
      company_id: _company_id,
      businessId: _businessId,

      expenseCatArray: [],
      expenseCatArrayFilter: [],
      expenseCatArrayTransaction: [],
      modalIsOpen_PayHis: false,
      paymentHistoryArray: [],
      paidAmountDuringTrans: 0,
      pageCount : 10,
      page : 1,
      totalCount  : 0,
      pageShow  : 0,
      setPage : 1,
    };
  }

  async componentDidMount() {
    this.getExpenseCatListData();
  }

  getInvoiceSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let _valueSend = value;
    this.setState({searchInvNo:_valueSend});
    if(_valueSend.length >= 3 || _valueSend == ''){
        this.getTransactionListData(this.state.activeExpenseCatId,this.state.activeExpenseCatExpense,_valueSend,this.state.page,this.state.pageCount);
    }
  }
  getTransactionListData(id, expense_type, searchInvNo,_page,pageCount) {
    let _transArray = [];

    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      type: expense_type,
      id: id, //this.state.activeExpenseCatId
      search_inv_no:searchInvNo,
      pageCount : pageCount,
      page:_page
    });

    let _url = "";
    if (expense_type == "EMI PAYMENT") {
      _url = global.userLoanAccountViaTypeList;
    } else if (expense_type == "CHARGES") {
      _url = global.userLoanAccountViaTypeList;
    } else if (expense_type == "LOAN PROCESS") {
      _url = global.userLoanAccountProcessList;
    } else {
      _url = global.userExpenseCategoryTransactionList;
    }

    PostData(_url, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            expenseCatArrayTransaction: _transArray,
            isLoaded: true,
          });
        } else {
          let _activeTotalPrice = 0;
          let _activeBalancePrice = 0;
          if (responseJson.response) {
            _transArray = responseJson.response;
            _transArray.map((trans, i) => {
              _activeTotalPrice =
                parseFloat(_activeTotalPrice) + parseFloat(trans.total_amount);
              _activeBalancePrice =
                parseFloat(_activeBalancePrice) +
                parseFloat(trans.balance_amount);
            });
          }
          this.setState({
            expenseCatArrayTransaction: _transArray,
            isLoaded: true,
            activeExpenseCatBalanace: _activeBalancePrice,
            activeExpenseCatTotal: _activeTotalPrice,totalCount:responseJson.totalCount,pageShow:responseJson.pageShow
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getExpenseCatListData() {
    let _expenseCatArray = [];
    let _url_GetData =
      global.userExpenseCategoryList +
      "?company_id=" +
      this.state.company_id +
      "&businessId=" +
      this.state.businessId;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _activeExpenseCat,
            _activeExpenseCatId,
            _activeExpenseCatTotal,
            _activeExpenseCatBalance,
            _activeExpenseCatExpense = "";
          if (responseJson.response) {
            _expenseCatArray = responseJson.response;
            if (responseJson.response) {
              _expenseCatArray = responseJson.response;
              _expenseCatArray.map((exp, i) => {
                if (i == 0) {
                  _expenseCatArray[i].isActiveClick = true;
                  _activeExpenseCat = exp.expense_category;
                  _activeExpenseCatTotal = exp.total;
                  _activeExpenseCatBalance = exp.balance;
                  _activeExpenseCatId = exp.expenseCat_id;
                  _activeExpenseCatExpense = exp.expense_type;
                } else {
                  _expenseCatArray[i].isActiveClick = false;
                }
              });
            }
          }
          this.setState({
            expenseCatArrayFilter: _expenseCatArray,
            expenseCatArray: _expenseCatArray,
            isLoaded: true,
            activeExpenseCat: _activeExpenseCat,
            activeExpenseCatId: _activeExpenseCatId,
            activeExpenseCatTotal: _activeExpenseCatTotal,
            activeExpenseCatBalanace: _activeExpenseCatBalance,
            activeExpenseCatExpense: _activeExpenseCatExpense,
          });

          if (_activeExpenseCatId > 0) {
            this.getTransactionListData(_activeExpenseCatId,this.state.activeExpenseCatExpense,this.state.searchInvNo,this.state.page,this.state.pageCount);
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadExpenseCatListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _partyDetails = this.state.expenseCatArrayFilter;

      //_partyDetails.sort((a, b) => (a.added_on > b.added_on) ? 1 : -1).reverse();
      //_partyDetails.reverse();

      if (_partyDetails.length > 0) {
        _partyDetails.map((value, index) => {
          let _activeAction = "";

          let _deletebtn = (
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              onClick={this.clickToDeleteExpenseCategory.bind(
                this,
                value.expenseCat_id,
                "Category"
              )}
              style={{ margin: "0px" }}
            >
              Delete
            </a>
          );
          if (value.isTransaction == true) {
            _deletebtn = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.clickToDeleteNotifiyExpenseCategory.bind(
                  this,
                  value.expenseCat_id
                )}
                style={{ margin: "0px" }}
              >
                Delete
              </a>
            );
          }

          if (value.expense_type == "EMI PAYMENT") {
            _activeAction = "";
          } else if (value.expense_type == "CHARGES") {
            _activeAction = "";
          } else if (value.expense_type == "LOAN PROCESS") {
            _activeAction = "";
          } else {
            _activeAction = (
              <div className="btn-group show" role="group">
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right min-width-200"
                  aria-labelledby="toolbarDrop"
                  style={{ margin: "0px" }}
                >
                  <a
                    className="dropdown-item"
                    onClick={this.openModalEditExpenseCategoryHandler.bind(
                      this,
                      "modalAddExpenseCategory",
                      value.expenseCat_id
                    )}
                    style={{ margin: "0px" }}
                  >
                    Edit
                  </a>
                  {_deletebtn}
                </div>
              </div>
            );
          }

          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeExpenseCat == value.expense_category) {
            _activeClass = "activeRow";
          }
          // style={{width:115px,overflow-x:scroll}}

          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.expenseCat_id,
                value.expense_category,
                value.total,
                value.balance,
                value.expense_type
              )}
            >
              <td title={value.expense_category}>
                <span
                  className="categoryC"
                  style={{
                    "word-break": "break-all",
                    "font-size": "15px !important",
                  }}
                >
                  {value.expense_category}
                </span>
              </td>

              <td>{numberFormat(value.total)}</td>
              <th className="text-center" scope="row">
                {_activeAction}
              </th>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  clickToDeleteNotifiyExpenseCategory() {
    confirmAlert({
      title: "Category Alert",
      message:
        "This expense cannot be deleted as it already has expense. Please delete all expense before deleting expense category",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToActiveRow(id, txt, total, balance, expense_type) {
    this.setState({
      activeExpenseCat: txt,
      activeExpenseCatId: id,
      activeExpenseCatTotal: total,
      activeExpenseCatBalanace: balance,
      activeExpenseCatExpense: expense_type,
    });

    if (id > 0) {
      this.getTransactionListData(id, expense_type,this.state.searchInvNo,this.state.page,this.state.pageCount);
    }
  }

  clickToDeleteExpenseCategory(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              expenseCatId: value,
            });
            PostData(
              global.userExpenseCategoryDelete,
              requestData,
              "POST"
            ).then((result) => {
              let responseJson = result;
              if (responseJson.success == false) {
                sendNotification(
                  "Error Message 😓",
                  responseJson.message,
                  "danger"
                );
                return;
              } else {
                sendNotification(
                  "Success Message 👍",
                  responseJson.message,
                  "success"
                );
                this.getExpenseCatListData();
                return;
              }
            });
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteTransactionNotify(value) {
    confirmAlert({
      title: "Transaction can't be deleted",
      message:
        "Cheque against this transaction has been closed, please reopen it to delete this transaction.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }
  clickToEditTransactionNotify(value) {
    confirmAlert({
      title: "Transaction can't be edited",
      message:
        "Cheque against this transaction has been closed, please reopen it to edit this transaction.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }
  clickToDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              expanse_id: value,
              type_value: "Expense",
            });
            PostData(global.deleteExpenseTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getExpenseCatListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  loadTrasactionListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.expenseCatArrayTransaction;
      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let _type = "&tType=manage-expense";
          let _delB = "";
          let _editB = "";
          if (value.cheque_status == "1" && value.cheque_status == 1) {
            _delB = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToDeleteTransactionNotify.bind(
                  this,
                  value.id
                )}
              >
                Delete
              </a>
            );
            _editB = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToEditTransactionNotify.bind(this, value.id)}
              >
                View/Edit Details
              </a>
            );
          } else {
            _delB = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                data-id={value.id}
                onClick={this.clickToDelete.bind(this, value.id, "Expense")}
              >
                Delete
              </a>
            );
            _editB = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-expense/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          }

          let _actionExtra = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                {_editB}

                <a
                  className="dropdown-item"
                  href={
                    global.webUrl +
                    "business/duplicate-expense/?auth=" +
                    value.id+_type
                  }
                >
                  Duplicate
                </a>
                {value.payment_paid!="PAID" && (
                  <a
                    className="dropdown-item"
                    href={
                      global.webUrl +
                      "business/expense-make-payment/?auth=" +
                      value.id
                    }
                  >
                    Make Payment
                  </a>
                )}
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.expensePaymentHistory.bind(
                    this,
                    value.id,
                    "modalExpensePaymentHistory"
                  )}
                >
                  Payment History
                </a>
                {_delB}
                {/* isChallanPreview={false} */}
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Download PDF
                </a>
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Preview
                </a>
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl + "business/download-pdf/?auth=" + value.id
                  }
                  target="_blank"
                >
                  Print
                </a>
              </div>
            </div>
          );

          let _invoicNo = value.customPrefix + " #" + value.invoice_no;
          let _partyName = value.party_name;

          if (value.type == "EMI PAYMENT") {
            _actionExtra = "";
            _invoicNo = "";
            _partyName = "";
          } else if (value.type == "CHARGES") {
            _actionExtra = "";
            _invoicNo = "";
            _partyName = "";
          } else if (value.type == "LOAN PROCESS") {
            _actionExtra = "";
            _invoicNo = "";
          }
          var msDiff = new Date(value.dueDate).getTime() - new Date().getTime(); //Future date - current date
          var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));

          let _statusType = <span className="badge badge-warning">UNPAID</span>;
          if (value.payment_paid == "PARTIAL") {
            _statusType = (
              <span className="badge badge-info">{value.payment_paid}</span>
            );
          } else if (value.payment_paid == "PAID") {
            _statusType = (
              <span className="badge badge-success">{value.payment_paid}</span>
            );
          }

          if (daysTill < -1) {
            _statusType = <span className="text-danger">Order Overdue </span>;
          }
          if (value.balance_amount <= 0) {
            _statusType = <span className="badge badge-success">Paid</span>;
          }
          let showDateTime = value.invoice_date;
          if (_businessSetting.Tran_AddTime == 1) {
            showDateTime = value.invoice_date + " " + value.invoice_time;
          }
          let _pageAK = this.state.setPage;

          rows.push({
            //srNo: _i, //value.srNo,
            srNo: ((_pageAK - 1) * this.state.pageCount) + _i,
            invoice_date: showDateTime,
            invoice_no: _invoicNo,
            party_name: _partyName,
            payment_type: value.payment_type,
            total_amount: numberFormat(value.total_amount),
            balance_amount: numberFormat(value.balance_amount),
            dueDate: value.dueDate,
            statusType: _statusType,
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  loadPaymentHistoryListing() {
    let dataPaymentList = [];
    let rowPayments = [];
    if (this.state.isLoaded == true) {
      let _paymentHistoryArrayDetails = this.state.paymentHistoryArray;
      if (_paymentHistoryArrayDetails.length > 0) {
        let _i = 0;
        _paymentHistoryArrayDetails.map((value, index) => {
          _i++;

          rowPayments.push({
            payment_date: value.invoice_date,
            payment_rec_no: value.invoice_no + " (" + value.type + ")",
            payment_amount: value.total_amount,
          });
        });
      }
    }

    if (rowPayments) {
      dataPaymentList = {
        columns: columnsPHistory,
        rows: rowPayments,
      };
    }
    return dataPaymentList;
  }

  /*For Modal open close*/
  openModalEditExpenseCategoryHandler(popId, expenseCatId) {
    if (popId == "modalAddExpenseCategory") {
      this.setState({
        modalIsOpen_AC: true,
        activeEditCategoryId: expenseCatId,
      });
    }
  }

  openModalHandler(popId) {
    if (popId == "modalAddExpenseCategory") {
      this.setState({ modalIsOpen_AC: true });
    }
  }

  expensePaymentHistory(transId, popId) {
    if (popId == "modalExpensePaymentHistory") {
      this.setState({ modalIsOpen_PayHis: true, paymentTransId: transId });

      let _paymentHistoryTransArray = [];
      let _paidAmountDuringTrans = 0;
      const requestData = JSON.stringify({
        transaction_id: transId,
      });

      PostData(global.expensePaymentHistory, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          _paidAmountDuringTrans = responseJson.paid_amount;
          if (responseJson.success == false) {
            this.setState({
              paymentHistoryArray: _paymentHistoryTransArray,
              isLoaded: true,
              paidAmountDuringTrans: _paidAmountDuringTrans,
            });
          } else {
            if (responseJson.response) {
              _paymentHistoryTransArray = responseJson.response;
            }
            this.setState({
              paymentHistoryArray: _paymentHistoryTransArray,
              paidAmountDuringTrans: _paidAmountDuringTrans,
            });
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAddExpenseCategory") {
      this.setState({ modalIsOpen_AC: false, activeEditCategoryId: 0 });
    } else if (popId == "modalExpensePaymentHistory") {
      this.setState({ modalIsOpen_PayHis: false, paymentTransId: 0 });
    }
  };
  /*End Modal open close*/

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_AC: false, activeEditCategoryId: 0 });
    this.getExpenseCatListData();
  };
  /* End Here */

  /* party Filter Code */
  onPartyFilterName = (e) => {
    const data = this.state.expenseCatArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ expenseCatArrayFilter: filtered });
    this.loadExpenseCatListing();
  };
  /* End party Filter Code */

  setFilterSearch = () => {
    this.setState({ filterSearch: !this.state.filterSearch });
  };
  setFilterSearchCancel = () => {
    this.setState({
      filterSearch: !this.state.filterSearch,
      expenseCatArrayFilter: this.state.expenseCatArray,
    });
    this.loadExpenseCatListing();
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    if (_businessSetting.DueDatesPaymentTerms == 1) {
      let _t = "";
      if (_businessSetting.Tran_AddTime == 1) {
        _t = " & Time";
      }
      columns = [
        {
          label: "#",
          field: "srNo",
          width: 20,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "#",
          },
        },
        {
          label: "Date" + _t,
          field: "invoice_date",
          width: 50,
        },
        {
          label: "Exp No.",
          field: "invoice_no",
          width: 150,
        },
        {
          label: "Party",
          field: "party_name",
          width: 100,
        },
        {
          label: "Payment Type",
          field: "payment_type",
          width: 100,
        },
        {
          label: "Amount",
          field: "total_amount",
          width: 100,
        },
        {
          label: "Balance",
          field: "balance_amount",
          width: 100,
        },
        {
          label: "Due Date",
          field: "dueDate",
          width: 50,
        },
        {
          label: "Status",
          field: "statusType",
          width: 50,
        },
        {
          label: "",
          field: "actionextra",
          width: 100,
        },
      ];
    }
    if (_businessSetting.DueDatesPaymentTerms == 0) {
      columns = [
        {
          label: "#",
          field: "srNo",
          width: 20,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "#",
          },
        },
        {
          label: "Date",
          field: "invoice_date",
          width: 50,
        },
        {
          label: "Exp No.",
          field: "invoice_no",
          width: 150,
        },
        {
          label: "Party",
          field: "party_name",
          width: 100,
        },
        {
          label: "Payment Type",
          field: "payment_type",
          width: 100,
        },
        {
          label: "Amount",
          field: "total_amount",
          width: 100,
        },
        {
          label: "Balance",
          field: "balance_amount",
          width: 100,
        },
        {
          label: "Status",
          field: "statusType",
          width: 50,
        },
        {
          label: "",
          field: "actionextra",
          width: 100,
        },
      ];
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-3">
            <div className="block">
              <div className="block pull-r-l">
                <div className="block-content">
                  {this.state.filterSearch ? (
                    <div className="mb-10">
                      <button
                        type="button"
                        data-toggle="layout"
                        data-action="header_search_on"
                        className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                        onClick={this.setFilterSearch}
                      >
                        <i className="fa fa-search"></i>
                      </button>

                      <button
                        type="button"
                        className="btn btn-secondary pull-right"
                        onClick={this.openModalHandler.bind(
                          this,
                          "modalAddExpenseCategory"
                        )}
                      >
                        <i className="fa fa-plus mr-5"></i>{" "}
                        <b>Add Expense Category</b>
                      </button>
                    </div>
                  ) : (
                    <div id="page-header-search" className="show mb-10">
                      <div className="content-header content-header-fullrow">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-toggle="layout"
                              data-action="header_search_off"
                              onClick={this.setFilterSearchCancel}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            onChange={this.onPartyFilterName}
                            className="form-control"
                            placeholder="Search here.."
                            id="page-header-search-input"
                            name="page-header-search-input"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                <div
                  className="block-content"
                  data-toggle="slimscroll"
                  data-height="600px"
                  data-color="#cccccc"
                  data-opacity="1"
                  data-always-visible="true"
                >
                  <table className="table table-striped table-vcenter">
                    <thead id="sideTable">
                      <tr>
                        <th>Category</th>
                        <th style={{ width: "50px" }}>Total&nbsp;</th>
                        <th style={{ width: "50px" }}>&nbsp;&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody id="sideTable">{this.loadExpenseCatListing()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="block">
              <div className="block-content">
                <div className="row items-push">
                  <div className="col-lg-12">
                    <div>
                      <p className="mb-10" style={{ fontSize: "15px" }}>
                        <b>{this.state.activeExpenseCat}</b>{" "}
                        <i className="si si-pin"></i>
                        <br />
                        {this.state.activeExpenseCatExpense}
                        <span
                          className="mr-5 mb-5 pull-right"
                          style={{ marginTop: "-30px" }}
                        >
                          <span>
                            Total :{" "}
                            {numberFormat(this.state.activeExpenseCatTotal)}
                          </span>
                          <br />
                          <span className="pull-right">
                            Balance :{" "}
                            {numberFormat(this.state.activeExpenseCatBalanace)}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{margin : '0 0 36px 0'}}>
              <div>
                <input type="text" className="form-control col-6" style={{display:'inline-block'}} placeholder='search expense number (type at least three characters)'onChange={this.getInvoiceSearch}/>
              </div>
            </div>
            <div className="block" style={{ marginTop: "-40px" }}>
              <div className="block-content">
                <MDBDataTableV5
                  //hover
                  // entriesOptions={[25, 50, 100]}
                  // entries={25}
                  // pagesAmount={4}
                  // span
                  // pagingTop={false}
                  // searchTop={false}
                  // searchBottom={false}
                  // rowsPerPageOptions={false}
                  // paging={false}
                  // data={this.loadTrasactionListing()}                  
                  hover
                  span
                  displayEntries={false}
                  entries={100}
                  pagingTop={false}
                  searchTop={false}
                  searchBottom={false}
                  info={false}
                  barReverse
                  data={this.loadTrasactionListing()}
                />
              </div>
              
              <div style={{ float: "right", marginTop: "-15px" }}>
                <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center">
                    <div role="status" aria-live="polite"> Rows per page:</div>
                    </div>
                    <div className="d-flex align-items-center" >
                    <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                            onChange={(event) => {
                                event.preventDefault();
                                const { name, value } = event.target;
                                //this.setState({ pageCount: value, setPage: 1 });
                                this.setState(prevState => ({
                                        pageCount : value,
                                        setPage : 1,
                                    }),
                                    () => {
                                        this.getTransactionListData(this.state.activeExpenseCatId,this.state.activeExpenseCatExpense,this.state.searchInvNo,1,value);
                                    });
                            }}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    </div>
                    <div className="d-flex align-items-center">
                    <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                    </div>
                </div>
              </div>
              <div className="block-content">
                  <div style={{ float: "left", marginTop: "-45px" }}>
                  <PaginationControl
                      between={this.state.pageCount}
                      total={this.state.totalCount}
                      limit={this.state.pageCount}
                      changePage={(page) => {
                      this.setState({ setPage: page });
                      this.getTransactionListData(this.state.activeExpenseCatId,this.state.activeExpenseCatExpense,this.state.searchInvNo,this.state.page,this.state.pageCount
                      );
                      }}
                      ellipsis={1}
                      activeClass={this.state.setPage}
                      page={this.state.setPage}
                  />
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Party Add Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AC}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddExpenseCategory"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Manage New / Edit Expense Category
                  </h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddExpenseCategory"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <QuickAddNewExpenseCategory
                      callBack={this.getUpdateResponse}
                      expenseCatId={this.state.activeEditCategoryId}
                      isRedirectTo={"business/manage-expense/"}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddExpenseCategory"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Party Add*/}

        {/* Modal Payment History Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_PayHis}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalExpensePaymentHistory"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Payment History</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalExpensePaymentHistory"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <h5>
                      Paid During Expense : {this.state.paidAmountDuringTrans}
                    </h5>
                    <h5>Linked With :</h5>

                    <div className="">
                      <MDBDataTableV5
                        hover
                        entriesOptions={[25, 50, 100]}
                        entries={25}
                        pagesAmount={4}
                        span
                        pagingTop
                        searchTop
                        searchBottom={false}
                        barReverse
                        data={this.loadPaymentHistoryListing()}
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalExpensePaymentHistory"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Payment History Add*/}
      </React.Fragment>
    );
  }
}
