import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import QuickAddNewItem from "../userItem/quickItemAdd";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import QuickAddLinkOtherTransaction from "./quickAddLinkOtherTransaction";
import QuickPaymentInPayLinkHistory from "./quickPaymentInPayLinkHistory";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _colorVal = "";
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];
let _settingType = "showall";
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let maxNumber = 999999;
let lengthInvNo = 6;
let _invoiceNumber = Math.floor(Math.pow(10, lengthInvNo - 1) + Math.random() * (Math.pow(10, lengthInvNo) - Math.pow(10, lengthInvNo - 1) - 1));

let _UserSession = localStorage.getItem("vs_UserSession");
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _company_id = _UserSession.company_id;
  _businessSetting = _UserSession.businessSetting;

  if (_businessSetting!=undefined || _businessSetting!=null) {
    if (_businessSetting.PrintOriginalDuplicate == 0) {
      _settingType = null;
    }
  }
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  _countryCode = _companySession.country_code;
  _countryName = _companySession.country_name;
  _taxRateArray = _companySession.taxDetails;

  let bankArrayData = _companySession.bankDetails
    ? _companySession.bankDetails
    : [];
  bankArrayData.map((bank, index) => {
    if (
      !(
        _bankArray.filter((item) => item.bank_name == bank.bank_name).length >
        0
      )
    ) {
      _bankArray.push(bank);
    }
  });
}
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userSaleListPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _timeReg: "iti__hide",
      buttonTitle: "Add Sale",
      partyRemainBalance: 0,
      _partyReg: "iti__hide",
      party_id: "0",
      party_name: "",
      is_party: "",
      partyArrayFilter: [],
      partyArray: [],
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,

      billing_name: "",
      invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : moment().format("h:mm A"),

      _itemReg: "iti__hide",
      _itemRegIndex: "0",

      isLoaded: true,
      isDateUpdate: false,

      receivedHide: "iti__hide",

      rowHtml: "",
      countryCode: _countryCode,
      countryName: _countryName,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",
      settingType: _settingType,
      invoiceNo: _invoiceNumber,
      invoiceDate: moment().format("MM/DD/YYYY"),
      countryState: "",
      paymentRefNo: "",
      description: "",
      descImage: "",
      imagePreviewUrl: "",

      bankId: "0",
      bankName: "",

      dueAmount: 0,
      receivedAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      remainingAmount: 0,
      unusedAmount: 0,
      party_contact_no: "",
      transactionType: "",
      otherTransationArray: [],
      otherTransationPayInArray: [],
      otherTranPayInHistoryArray: [],
      totalLinkHistoryAmt: 0,
      errors: {
        invoiceNo: "",
        invoiceDate: "",
        party_id: "",
        party_name: "",
        party_contact_no: "",
      },
    };
  }

  async componentDidMount() {
    let _handleParam = this.props.location.search;
    let _transactionType = "";
    let transactionId = 0;
    _handleParam = _handleParam.split("?auth=");
    if (_handleParam.length > 1) {
      _handleParam = _handleParam[1].split("&");
      transactionId = _handleParam[0] ? _handleParam[0] : 0;
      if (transactionId > 0) {
        _isEditMode = true;
      }
      _transactionType = _handleParam[1].split("tType=");
      _transactionType = _transactionType[1];
    }

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });
      let _partyIdK = 0
      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/sale-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;
              let customerData = _transactionArray.customer;
              _partyIdK = customerData.party_id;
              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              this.setState({
                billing_name: customerData.billing_name ? customerData.billing_name : "",
                //invoiceTime: _transactionArray.invoice_time,
                party_id: customerData.party_id,
                party_name: customerData.party_name,
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                buttonTitle: "Edit Sale",
                //invoiceNo: _transactionArray.invoiceNo,
                //invoiceDate: moment(_transactionArray.invoiceDate).format("MM/DD/YYYY"),
                state: _transactionArray.countryState,
                countryState: _transactionArray.countryState,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                totalAmount: transAmout.totalAmount,
                receivedAmount: transAmout.dueAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.dueAmount,
                description: _transactionArray.description,
                paymentType: "Cash",
                paymentRefNo: _transactionArray.paymentRefNo,
                fields: itemsData,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                settingType: _transactionArray.settingType,
                party_contact_no: _transactionArray.party_contact_no,
                transactionType: _transactionType,
                otherTransationPayInArray:
                  _transactionArray.otherTransationPayInArray,
                otherTranPayInHistoryArray:
                  _transactionArray.otherTranPayInHistoryArray,
                totalLinkHistoryAmt: _transactionArray.totalLinkHistoryAmt,
              });


              /*Link Payment Data Here*/        
              let _otherTransationArray = [];
              const requestDataLink = JSON.stringify({
                company_id: _company_id,
                business_id: _businessId,
                party_id: _partyIdK,
                startDate: "",
                endDate: "",
              });
              PostData(global.userPartyPaymentLinkTransList, requestDataLink, "POST")
                .then((result) => {
                  let responseJson = result;
                  if (responseJson.success == false) {
                    this.setState({});

                    this.setState({
                      otherTransationPayInArray: [],
                      otherTransationArray: [],
                    });
                  } else {
                    if (responseJson.response) {
                      _otherTransationArray = responseJson.response;
                    }
                    this.setState({
                      otherTransationPayInArray: [],
                      otherTransationArray: _otherTransationArray,
                    });
                  }
                })
                .catch((error) => this.setState({ error, isLoaded: false }));
              /*End Link Payment Data */
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));

        
      
    }
    this.getPartyListData();
  }

  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            _partyArray = responseJson.response;

            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
          value.length < 0 ? "*Invoice No Must be 4 numbers" : "";

        if (validator.isInt(value)) {
          errors.invoiceNo = "";
        } else {
          errors.invoiceNo = "*Please enter only numeric value.";
        }
        this.setState({ invoiceNo: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "party_contact_no":
        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          if(value.length == 0 || value == ''){
            errors.party_contact_no = "";
          }else{
            errors.party_contact_no = "*Please enter valid mobile number!";
          }
        }
        this.setState({ party_contact_no: value });
      break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        //console.log(value);
        let _valType = "";

        if (this.state.transactionId > 0) {
          this.setState({ paymentRefNo: "" });
        }
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        //console.log(_bankId+'--'+_bankName+'---'+value+'----'+_valType);
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      case "receivedAmount":
        let _remainAmount = 0;
        let _unusedAmount = 0;
        let _paidAmt = value;
        if (_paidAmt == "") {
          _paidAmt = 0;
        }
        if (value >= 0) {
          _remainAmount = parseFloat(this.state.dueAmount) - parseFloat(_paidAmt);
          if (_remainAmount < 0) {
            _unusedAmount = value - this.state.dueAmount;
          }
        }
        this.setState({
          receivedAmount: parseFloat(_paidAmt),
          remainingAmount: parseFloat(_remainAmount),
          unusedAmount: parseFloat(_unusedAmount),
        });
        break;

      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment  must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;

      case "billing_name":
        this.setState({ billing_name: value });
        break;
      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;

      default:
        break;
    }
  };

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
    else if (e == "modalAddLinkPayment") {
      if (this.state.transactionId > 0) {
        this.setState({
          modalIsOpen_LINKPay: true,
          otherTransationArray: this.state.otherTransationPayInArray,
        });
      }
      if (this.state.transactionId == 0) {
        this.setState({ modalIsOpen_LINKPay: true });
      }
    }
     else if (e == "modalAddLinkPaymentHistory") {
      if (this.state.transactionId > 0) {
        this.setState({
          modalIsOpen_LINKPayHistory: true,
          otherTranPayInHistoryArray: this.state.otherTranPayInHistoryArray,
        });
      }
      if (this.state.transactionId == 0) {
        this.setState({
          modalIsOpen_LINKPayHistory: true,
          otherTranPayInHistoryArray: this.state.otherTranPayInHistoryArray,
        });
      }
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
    else if (e == "modalAddLinkPayment") {
      this.setState({ modalIsOpen_LINKPay: false });
    } else if (e == "modalAddLinkPaymentHistory") {
      this.setState({ modalIsOpen_LINKPayHistory: false });
    } 
  };
  /*End Modal open close*/

  openModalHandlerLink(e) {
    this.setState({
      modalIsOpen_LINKPay: true,
      otherTransationArray: this.state.otherTransationArray,
    });
  }

  /* Add Party Code */
  selectPartiess(name, e, balance, party_contact_no) {
    let errors = this.state.errors;
    errors.party_name = "";
    this.setState({
      _partyReg: "iti__hide",
      party_name: name,
      party_id: e,
      is_party: "yes",
      partyRemainBalance: balance,
      party_contact_no: party_contact_no,
    });
  }

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance,
                party.party_contact_no
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(Math.abs(_balance))}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({
      partyArrayFilter: filtered,
      party_name: keyword,
      is_party: "No",
    });
    this.getReadyPartyList();
  };
  /* End Party Code */

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Click Submit Button */
  clickToAddSale = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let transactionId = _handleParam[1] ? _handleParam[1] : 0;

    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter Party Name";
      this.setState({ errors: errors });
    }
    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Receipt No.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editSaleTransactionMethodCall();
      } else {
      }
    }
  };

  editSaleTransactionMethodCall() {
    
    // if(parseFloat(this.state.receivedAmount) <= 0  ){
    //   sendNotification(
    //     "warning Message 😓",
    //     "received amount cannot be 0 or blank",
    //     "warning"
    //   );
    //   return;
    // }
    let _prefix = JSON.stringify({
      name: "",
      id: "0",
      isActive: "1",
    });

    let _totalSummary = JSON.stringify({
      subtotal: 0,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalQty: this.state.totalQuantity,
      totalDiscount: this.state.totalDiscount,
      totalAmount: this.state.receivedAmount,
      receivedAmount: this.state.receivedAmount,
      remainingAmount: this.state.remainingAmount,
      unusedAmount: this.state.unusedAmount,
      dueAmount: this.state.dueAmount,
    });
    let _customer = JSON.stringify({
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_opening_balance: 0,
      billing_name: this.state.billing_name,
    });

    var saleData = new FormData();
    saleData.append("transaction_id", this.state.transactionId);
    saleData.append("business_id", this.state.businessId);
    saleData.append("user_id", this.state.userId);
    saleData.append("company_id", this.state.company_id);
    saleData.append("type", "PAYMENT-IN");
    saleData.append("prefix", _prefix);
    saleData.append("invoiceNo", this.state.invoiceNo);
    saleData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );

    saleData.append("invoiceTime", this.state.invoiceTime);

    saleData.append("totalSummary", _totalSummary);
    saleData.append("customer", _customer);
    saleData.append("items", JSON.stringify(this.state.fields));
    saleData.append("paymentType", this.state.paymentType);
    saleData.append("paymentRefNo", this.state.paymentRefNo);
    saleData.append("countryState", this.state.countryName);
    saleData.append("countryState", this.state.countryState);
    saleData.append("description", this.state.description);
    saleData.append("descImage", this.state.descImage);
    saleData.append("bankId", this.state.bankId);
    saleData.append("bankName", this.state.bankName);
    saleData.append("settingType", this.state.settingType);
    //console.log("===Data===",_totalSummary);return;
    saleData.append(
      "otherTransationPayInArray",
      JSON.stringify(this.state.otherTransationPayInArray)
    );

    new Promise((resolve, reject) => {
      fetch(global.createSalePaymentINRecevTransaction, {
        method: "POST",
        body: saleData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              if (this.state.transactionType == "ITEM") {
                window.location.href = global.webUrl + "business/manage-items/";
              } else if (this.state.transactionType == "PARTY") {
                window.location.href = global.webUrl + "business/manage-party/";
              } else if (this.state.transactionType == "SERVICE") {
                window.location.href = global.webUrl + "business/manage-service/";
              } 
              else if (this.state.transactionType == "Dashboard") {
                window.location.href = global.webUrl + "business/dashboard/";
              }
              else if (this.state.transactionType == "Bank") {
                window.location.href = global.webUrl + "business/manage-account-list/";
              }
              else {
                window.location.href = global.webUrl + "business/sale-invoice/";
              }
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    this.setState({ invoiceDate: start.format("MMMM D, YYYY") });
  };

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly dataSend="1111" value={this.state.invoiceDate} />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" readOnly />
        </DateRangePicker>
      );
    }
  }

  settleAmountType() {
    let _payData = [];

    //_payData.push(<option value="NewBank">Add New Bank</option>);
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              //_selBank = 'selected';
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          _payData.push(
            <option
              value={_valOption}
              selected={_selBank}
              data-bankName={_disOption}
              data-bankId={_valOption}
            >
              {_disOption}
            </option>
          );

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }

    return _payData;
  }

  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }

  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    let _colorBal = "text-normal";
    if (this.state.partyRemainBalance < 0) {
      _colorBal = "text-danger";
    } else if (this.state.partyRemainBalance > 0) {
      _colorBal = "text-success";
    } else {
      _colorBal = "text-normal";
    }

    let _disableTrans = false;
    if (this.state.viaAgainst > 0) {
      _disableTrans = true;
    }
    if (this.state.otherTransationPayInArray.length > 0) {
      _disableTrans = true;
    }

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Payment-In
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Add / Edit Payment-In
            </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Search Party Name Here..."
                            autoComplete="off"
                            name="party_name"
                            id="party_name"
                            readOnly="readOnly"
                            value={this.state.party_name}
                          />
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>
                          <ul
                            className={
                              this.state._partyReg +
                              " iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {this.getReadyPartyList()}
                          </ul>
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>

                          <div className={_colorBal}>
                            {this.state.party_name!="" &&
                            this.state.is_party!="No"
                              ? "Bal : " +
                                numberFormat(
                                  Math.abs(this.state.partyRemainBalance)
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-material open">
                          <input
                            type="text"
                            className="form-control search-box"
                            placeholder="Phone Number"
                            autoComplete="off"
                            name="party_contact_no"
                            id="party_contact_no"
                            value={this.state.party_contact_no}
                            onChange={this.handleChange}
                            maxLength="13"
                          />
                          <label htmlFor="login-username">Phone Number</label>
                          <span className="iris_error_txt">
                            {errors.party_contact_no.length > 0 && (
                              <span className="error">
                                {errors.party_contact_no}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      {_businessSetting.Tran_BillingNameParty == 1 && (
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              type="text"
                              className="form-control search-box"
                              placeholder="Billing Name Here..."
                              autoComplete="off"
                              name="billing_name"
                              id="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleChange}
                            />
                            <label htmlFor="login-username">Billing Name</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      {_businessSetting.InvoiceBillNo == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Receipt No. <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="invoiceNo"
                              name="invoiceNo"
                              required="required"
                              onChange={this.handleChange}
                              placeholder="Enter a Receipt No."
                              autoComplete="off"
                              value={this.state.invoiceNo}
                            />
                            <div
                              id="invoiceNo-error"
                              className="animated fadeInDown"
                            >
                              {errors.invoiceNo.length > 0 && (
                                <span className="error">
                                  {errors.invoiceNo}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly="readOnly"
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType == "Cheque" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                required="required"
                                id="paymentRefNo"
                                name="paymentRefNo"
                                value={this.state.paymentRefNo}
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}

                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>
                        {_businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                id="settingType"
                                name="settingType"
                                onChange={this.handleChange}
                              >
                                {optionsData.map(({ value, label }, index) => {
                                  let _selSet = "";
                                  if (this.state.settingType == value) {
                                    _selSet = "selected";
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                      selected={_selSet}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="col-md-12" id="formSideRight">
                      <div className="">
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="dueAmount"
                          >
                            Balance Due
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              className="form-control"
                              id="dueAmount"
                              name="dueAmount"
                              placeholder=""
                              value={this.state.dueAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              readOnly
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="receivedAmount"
                          >
                            Received
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              className="form-control"
                              id="receivedAmount"
                              name="receivedAmount"
                              placeholder=""
                              value={this.state.receivedAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              onWheel={() => document.activeElement.blur()}
                            />
                          </div>
                        </div>
                        {this.state.receivedAmount > this.state.dueAmount && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="unusedAmount"
                            >
                              {/* Unused Amount */}
                              Remaining Balance
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="number"
                                className="form-control"
                                id="unusedAmount"
                                name="unusedAmount"
                                placeholder=""
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.unusedAmount}
                                onWheel={() => document.activeElement.blur()}
                              />
                            </div>
                          </div>
                        )}
                        {this.state.receivedAmount <= this.state.dueAmount && (
                          <div className="form-group row">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-lg-4 col-form-label"
                              htmlFor="remainingAmount"
                            >
                              {/* Unused Amount */}
                              Remaining Balance
                            </label>
                            <div
                              className="col-lg-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="number"
                                className="form-control"
                                id="remainingAmount"
                                name="remainingAmount"
                                placeholder=""
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.remainingAmount}
                                onWheel={() => document.activeElement.blur()}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* <div className="col-md-6">
                <div className="row justify-content-center text-left">
                  <div className="col-lg-9">
                    {_businessSetting.PaymentLink == 1 && (
                      <span>
                        {this.state.party_id > 0 &&
                          this.state.otherTransationPayInArray.length == 0 &&
                          this.state.otherTransationArray.length > 0 && (
                            <a
                              href="javascript:void(0)"
                              onClick={this.openModalHandlerLink.bind(
                                this,
                                "modalAddLinkPayment"
                              )}
                              className="btn btn-alt-primary mb-10 mx-5"
                              datatype="F"
                            >
                              Link Payment
                              <i className="fa fa-question-circle"></i>
                            </a>
                          )}
                        {this.state.party_id > 0 &&
                          this.state.otherTransationPayInArray.length > 0 &&
                          this.state.otherTransationArray.length > 0 && (
                            <a
                              href="javascript:void(0)"
                              onClick={this.openModalHandler.bind(
                                this,
                                "modalAddLinkPayment"
                              )}
                              className="btn btn-alt-primary mb-10 mx-5"
                              datatype="S"
                            >
                              {" "}
                              Link Payment
                              <i className="fa fa-question-circle"></i>
                            </a>
                          )}
                      </span>
                    )}
                    {_businessSetting.PaymentLink == 1 && (
                      <span>
                        {this.state.party_id > 0 &&
                          this.state.otherTranPayInHistoryArray.length > 0 && (
                            <a
                              href="javascript:void(0)"
                              onClick={this.openModalHandler.bind(
                                this,
                                "modalAddLinkPaymentHistory"
                              )}
                              className="btn btn-alt-primary mb-10 mx-5"
                              datatype="F"
                            >
                              Payment History <i className="fa fa-history"></i>
                            </a>
                          )}
                      </span>
                    )}
                  </div>
                </div>
              </div> */}

              <div className="col-md-6">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddSale}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Edit Item Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SITEM}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewItem"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Item</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewItem"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewItem />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewItem"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Item Details*/}

          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail />
                    {/* <QuickAddNewBankDetail isRedirectTo={'business/add-sale/'}/> */}
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}

          
          {/* Modal Add Link Other transaction Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_LINKPay}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAddLinkPayment"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add Link Payment To Txns</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddLinkPayment"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddLinkOtherTransaction
                      otherTransationArray={this.state.otherTransationArray}
                      receivedAmountSend={this.state.receivedAmount}
                      PaymentDiscountPayInAmount={
                        this.state.PaymentDiscountPayInAmount
                      }
                      totalAmount={this.state.totalAmount}
                      partyId={this.state.party_id}
                      transactionId={this.state.transactionId}
                      callBack={(
                        returnPayInData,
                        _receivedAmount,
                        _otherTranPayInHistoryArray,
                        _unUsedAmt,
                        _totalAmount
                      ) => {
                        this.setState({
                          otherTransationPayInArray: returnPayInData,
                          modalIsOpen_LINKPay: false,
                          receivedAmount: _receivedAmount,
                          otherTranPayInHistoryArray:
                            _otherTranPayInHistoryArray,
                          totalLinkHistoryAmt: _totalAmount,
                          unUsedAmount: _unUsedAmt ? _unUsedAmt : 0,
                          totalAmount: _totalAmount ? _totalAmount : 0,
                        });
                      }}
                    />
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddLinkPayment')}>Close</button> */}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Link Other transaction Details*/}

          {/* Modal Pay Link Histpory Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_LINKPayHistory}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAddLinkPaymentHistory"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Payment History</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddLinkPaymentHistory"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickPaymentInPayLinkHistory
                      otherTranPayInHistoryArray={
                        this.state.otherTranPayInHistoryArray
                      }
                      totalLinkHistoryAmt={this.state.totalLinkHistoryAmt}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-alt-secondary"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddLinkPaymentHistory"
                      )}
                    >
                      Close
                    </button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Pay Link History Details*/}
        </div>
      </React.Fragment>
    );
  }
}
