import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let _loader = 'glb-ldr-prt active';

export default class QuickAddNewOIItem extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
        }

        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-service/',
            buttonTitle:'Add New',
            isLoaded : true, 
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            item_name :  this.props.item_name ? this.props.item_name : '',
            errors: {
                item_name : '',
            }
        }
    }
    async componentDidMount ()
    { 
        
    }
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'item_name': 
            errors.item_name = 
                value.length < 3 ? '*Item name must be 3 characters long!' : '';
                this.setState({item_name:value}); 
            break;
            default:
            break;
        }
    }

    clickToAddGst = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.item_name == null || this.state.item_name == '')
        {
            _isError = true;
            errors['item_name'] = "*Please Enter Item Name";
            this.setState({errors: errors});
        }      

        if(_isError == false){
            this.addGstMethodCall();            
        }
    }
    

    addGstMethodCall(){
        const requestData = JSON.stringify({
            item_name: this.state.item_name,
            businessId : this.state.businessId,
            company_id : this.state.company_id,
            userId : this.state.userId,
        });

        PostData(global.userAddOIItem, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                //let category_name = result.category_name;
                //let category_id = result.category_id;
                sendNotification("Success Message 👍",result.message,"success");
                //this.props.callBackCategory(category_name,category_id);
            
               /* setTimeout(
                    () => {
                        window.location.href = global.webUrl+'business/manage-unit/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )*/
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['category'] = result.message;
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }
    /* End Item Filter Code */       

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="block-content">
                    <form method="post" id="form-login" className="webForm px-30">
                        <div className="form-group row">
                            <div className="col-6">
                                <div className="form-material open">
                                    <input type="text" required="required" className="form-control" id="item_name" name="item_name" value={this.state.item_name} placeholder="Item Name Here" onChange={this.handleChange}/>
                                    <label htmlFor="login-username">Item Name <span className="text-danger">*</span></label>
                                    <span className="iris_error_txt">{errors.item_name.length > 0 && <span className='error'>{errors.item_name}</span>}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <div className="col-12">
                                <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddGst}>
                                    <i className="fa fa-check mr-5"></i>  Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )    
    }
}