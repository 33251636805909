import React,{Component} from 'react';
import {PostData} from '../service/postData';

let _sessionPeriod = true;
let _UserSession = localStorage.getItem('vs_UserSession');
let _signingName = 'Administrator';
let _signingId = '0';
let _companyList = [];
if(_UserSession!=undefined || _UserSession!=null)
{
    _sessionPeriod = false;
    _UserSession = JSON.parse(_UserSession);
    _signingName = _UserSession.loginName;
    _companyList = _UserSession.companies;
    _signingId = _UserSession.loginId;
}

export default class TopHeaderPage extends Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn : _sessionPeriod,
            loggedName : _signingName,
            isSessionOff : _sessionPeriod,
            companies : _companyList,
            reChangeComapny : false
        }
    }

    componentDidMount(){
        this.manageCompany();
    }

    updateCompany(value){

        const requestData = JSON.stringify({
            userId : _signingId,
            company_id : value
        });
        PostData(global.userUpdateCompanyPriority, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                //console.log(result);
            }
        });

        //localStorage.removeItem('vs_UserSession');
        let _updateLocal = _UserSession;
        let _comData = _updateLocal.companies;
        _comData.map((compDatas, i) =>{
            _comData[i].isActive = false;
            if(value == _comData[i].company_id){
                _comData[i].isActive = true;
                localStorage.removeItem('vs_UserCompanySession');
                localStorage.setItem('vs_UserCompanySession', JSON.stringify(compDatas));
            }
        });
        _updateLocal.company_id = value;
        _updateLocal.companies = _comData;
        localStorage.removeItem('vs_UserSession');
        localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
        this.setState({reChangeComapny:true});
        window.location.reload();
    }

    manageCompany(){
        let _companyDetails = this.state.companies;
        let dataList = [];
        if(_companyDetails.length > 0)
        {
            let _activeCompany = 'Change Company';                    
            _companyDetails.map((compDatas, i) =>
            {
                const row = compDatas;
                let _iconActive = '';
                if(row.isActive == true){
                    _activeCompany = row.company_name;
                    _iconActive = ' text-info';
                }
                dataList.push(
                    <a key={i} className={"dropdown-item"+_iconActive} href="javascript:void(0)" onClick={this.updateCompany.bind(this,row.company_id)}>
                        <i className="fa fa-check"></i> {row.company_name}
                    </a>
                )
            });
            return(
                <div className="dropdown d-inline-block" role="group">
                    <button type="button" className="btn btn-dual-secondary dropdown-toggle active" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {_activeCompany}
                    </button>
                    <div className="dropdown-menu min-width-200" aria-labelledby="page-header-user-dropdown">
                        {dataList}
                    </div>
                </div>
            );
        }
    }

    handleLogoutClick = (event) => {
        localStorage.removeItem('vs_UserSession');
        this.setState({isSessionOff:true})
        //window.location.reload();
    }

    render(){
        if (this.state.isSessionOff == true)
        {
            //console.log('Session Expire...!');   
            //this.logoutCallBack();         
            window.location.href = global.webUrl+'login/';
        }
        return (
            <React.Fragment>
                <header id="page-header">
                    <div className="content-header">
                        <div className="content-header-section">
                            <button type="button" className="btn btn-circle btn-dual-secondary" data-toggle="layout" data-action="sidebar_toggle">
                                <i className="fa fa-navicon"></i>
                            </button>
                            <div className="btn-group" role="group">
                                <div className="dateTime" style={{padding: '15px 15px'}}>
                                    <span><i className="gi gi-calendar"></i> </span><span id="date-time"></span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="content-header-section">
                            <ul className="nav-main-header">
                                {this.manageCompany()}
                                <li>
                                    <button className="btn btn-outline-primary"><i className="si si-plus"></i> Add Purchases</button>
                                </li>
                                <li>
                                    <a href={global.webUrl+"business/sale-invoice/"} className="btn btn-outline-info"><i className="si si-plus"></i> Add Sale</a>
                                </li>
                                <li>
                                    <button type="button" className="btn btn-outline-secondary" data-toggle="layout" data-action="side_overlay_toggle">
                                        <i className="si si-plus"></i> Add More
                                    </button>
                                </li>
                            </ul>
                            <button type="button" className="btn btn-circle btn-dual-secondary d-lg-none" data-toggle="layout" data-action="sidebar_toggle">
                                <i className="fa fa-navicon"></i>
                            </button>
                        </div>
                        <div className="content-header-section">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-rounded btn-dual-secondary" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-user d-sm-none"></i>
                                    <span className="d-none d-sm-inline-block">{this.state.loggedName}</span>
                                    <i className="fa fa-angle-down ml-5"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right min-width-200" aria-labelledby="page-header-user-dropdown">
                                    <h5 className="h6 text-center py-10 mb-5 border-b text-uppercase">User</h5>
                                    <a className="dropdown-item" href={global.webUrl+"business/profile/"}>
                                        <i className="si si-user mr-5"></i> Profile
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={this.handleLogoutClick} data-box="#mb-signout" href={() => false}>
                                        <i className="si si-logout mr-5"></i> Sign Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="page-header-search" className="overlay-header">
                        <div className="content-header content-header-fullrow">
                            <form action="be_pages_generic_search.php" method="post">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button type="button" className="btn btn-secondary" data-toggle="layout" data-action="header_search_off">
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input"/>
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-secondary">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                    <div id="page-header-loader" className="overlay-header bg-primary">
                        <div className="content-header content-header-fullrow text-center">
                            <div className="content-header-item">
                                <i className="fa fa-sun-o fa-spin text-white"></i>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

