import React, { Component } from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';



let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Company Name',
        field: 'company_name',
        width: 100,
    },
    {
        label: 'Sms Type',
        field: 'sms_type',
        width: 100,
    },
    {
        label: 'Sms Title',
        field: 'sms_title',
        width: 100,
    },
    {
        label: 'Sms Header',
        field: 'sms_header',
        width: 100,
    },
    {
        label: 'Sms Details',
        field: 'sms_details',
        width: 100,
    },
    {
        label: 'Sms Footer',
        field: 'sms_footer',
        width: 100,
    },
    {
        label: 'Sms DLT Id',
        field: 'dlt_id',
        width: 100,
    },
    {
        label: 'SMS Status',
        field: '_status',
        width: 100,
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
    
];

let _loader = 'glb-ldr-prt active';

export default class serviceListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            modalIsOpen_CAT : false,
            smsArrayFilter : [],
            smsArray :[],
            sms_title : '',
            sms_header : '',
            sms_details : '',
            sms_footer : '',
            dlt_id : '0',
            _smsId : 0,
            sms_type : '',
            errors: {
                sms_title : '',
                sms_header : '',
                sms_details : '',
                sms_footer : '',
                dlt_id : '',
                sms_type : '',
            }
        }
    }
    
    async componentDidMount ()
    {
        this.getSMSListData();
        
    }

    /* Country Code Listing */
    
    
    clickToStatus(value,_status)
    {
        confirmAlert({
            title: 'Confirm to approve this company Sms',
            message: 'Are you sure you want to approve?',
            buttons: [
            {
                label: 'Yes',
                className: 'btn btn-danger',
                onClick: () => {
                    const requestData = JSON.stringify({
                        smsId: value,
                        status:_status
                    });
                    PostData(global.smsManagePendingStatus, requestData,'POST').then((result) => {
                        let responseJson = result;                
                        if(responseJson.success == false)
                        {
                            sendNotification("Error Message 😓",responseJson.message,"danger");
                            return;
                        }
                        else
                        {
                            sendNotification("Success Message 👍",responseJson.message,"success");
                            //this.getSMSListData();
                            window.location.href = global.webUrl+'administrator/manage-sms-setting/';
                            return;
                        }
                    });
                }
            },
            {
                label: 'No',
                className: 'btn btn-dark',
            }
            ]
        });
    }
  

    getSMSListData(){
        let _allSMSArray = [];

        let _url_GetData = global.allPendingSMSList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _allSMSArray = responseJson.response;
                }
                this.setState({smsArray:_allSMSArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    loadSMSListing()
    {   
        if(this.state.isLoaded == true)
        {
            
            let _allSMSDetails = this.state.smsArray;
            let dataList = [];
            let rows = [];
            
            if(_allSMSDetails.length > 0)
            {                          
                _allSMSDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    
                    
                    
                    let _statussms = <span className={`badge badge-danger`}>Pending</span>;
                    let _sms_approve_status = 1;
                    if (row.sms_approve_status == '1') {
                        _sms_approve_status = 0;
                        _statussms = <span className={`badge badge-success`}>Approved</span>;
                    }
        
                    
                    
                    
                    let _dtt = row.added_on;
                    var options = {year: 'numeric', month: 'long', day: 'numeric' };
                    

                    rows.push({ 
                        date: new Intl.DateTimeFormat('en-US', options).format(_dtt),
                        company_name:row.company_name,
                        sms_type: row.sms_type,
                        sms_title: row.sms_title,
                        sms_header:row.sms_header,
                        sms_details:row.sms_details,
                        sms_footer:row.sms_footer,
                        dlt_id:row.dlt_id,
                        sms_footer:row.sms_footer,
                        srNo: row.srNo,
                        _status: (_statussms),
                        added_on: new Intl.DateTimeFormat('en-US', options).format(_dtt),
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href="javascript:void(0)" data-id={row.id} onClick={this.clickToStatus.bind(this,row.id,_sms_approve_status)}>
                                        <i className="fa fa-exclamation-triangle mr-5 text-primary"></i>Manage Status
                                    </a>
                                    
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {                
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }
    
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _smsTitle = this.state.sms_title;
        if(_smsTitle == '' || _smsTitle == null){
            _smsTitle = 'Not Set';
        }
        
        const {errors} = this.state;        

        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Pending SMS List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage Pending SMS List</span>
                    </nav>
                    
                    <div className="row">
                        
                        <div className="col-md-12">
                           

                            

                            <div className="block">
                                <div className="block-header block-header-default">
                                    <h3 className="block-title">List of Company Pending SMS Details</h3>
                                </div>
                                <div className="block-content">
                                    <MDBDataTableV5 
                                    hover
                                    entriesOptions={[25, 50, 100]}
                                    entries={25}
                                    pagesAmount={4}
                                    span
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    barReverse
                                    data={this.loadSMSListing()} />
                                </div>
                            </div>          
                    </div>
                        </div>
                    </div>   
                    
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

                

                
            </React.Fragment>
        )    
    }
}