import React, { Component } from "react";
import { PostData } from "../../service/postData";
import { numberFormat } from "../../config/numberFormat";
import Modal from "react-modal";
import moment from "moment";
import jsPDF from "jspdf";
import html2PDF from "jspdf-html2canvas";
import { ToWords } from "to-words";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

let _UserSession = localStorage.getItem("vs_UserSession");

let _companyName = "NA";
let _companyLogo = global.LIVE_URL + "websiteFilesFolder/img/vyavsay.png";
let _companySignature = "NA";
let _companySignatureLen = 0;
let _companyAddress = "NA";
let _display_bank_name = "";
let _display_bank_accountNo = "";
let _display_bank_holderName = "";
let _companyPhone = "NA";
let _companyEmail = "NA";
let _countryGst = "NA";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _signingId = "0";
let _tax_name = "";
let invTime = "";
let _pdfDate = "";
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _company_id = _UserSession.company_id;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");

if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  //console.log(_companySession);
  _companyName = _companySession.company_name;

  if (_companySession.company_logo.length > 6) {
    let _company_Logo = _companySession.company_logo;
    _companyLogo = _company_Logo;
  } else {
    _companyLogo = global.LIVE_URL + "websiteFilesFolder/img/vyavsay.png";
  }
  _companySignature = _companySession.company_signature;
  _companyAddress = _companySession.company_address;
  _companyPhone = _companySession.company_contact;
  _companyEmail = _companySession.email ? _companySession.email : "";
  _company_id = _companySession.company_id;
  _countryCode = _companySession.country_code;
  _countryName = _companySession.country_name;

  _countryGst = _companySession.gstin ? _companySession.gstin : "";
  _tax_name = _companySession.tax_name;

  let bankArrayData = _companySession.bankDetails
    ? _companySession.bankDetails
    : [];
  bankArrayData.map((bank, index) => {
    if (
      bank.bank_print == 1 ||
      bank.bank_print == "true" ||
      bank.bank_print == true
    ) {
      _display_bank_name = bank.bank_name2;
      _display_bank_accountNo = bank.bank_accountNumber;
      _display_bank_holderName = bank.bank_accountHolderName;
    }
    if (
      bank.bank_name2 == _display_bank_name &&
      bank.bank_print!=1 &&
      bank.bank_print!="true" &&
      bank.bank_print!=true
    ) {
      _display_bank_name = "";
    }
  });
}
console.log("==dd==", JSON.stringify(_companySession));
let _checkOriginal = "";
let _checkDuplicate = "";
let _checkTriplicate = "";
let _checkOriginalDisable = "";
let _checkDuplicateDisable = "";
let _checkTriplicateDisable = "";

let _imageBaseDecode = _companyLogo;
let _signatureBaseDecode = _companySignature;
let _mainLogoBaseDecode = global.webUrl + "websiteFilesFolder/img/logo.png";
let _vLogoBaseDecode = "";
let _extraReceiveDiv = (
  <p>
    <b>Received By</b>
    <br />
    Name:
    <br />
    Comment:
    <br />
    Date:
    <br />
    Signature:
  </p>
);
let _extraDeleiveryDiv = (
  <p>
    <b>Delivered By</b>
    <br />
    Name:
    <br />
    Comment:
    <br />
    Date:
    <br />
    Signature:
  </p>
);
let _invoiceName = "invoiceRecipt";

const footer = {
  //position: 'absolute',
  //top: '90%',
  //width: '93%',
  display: "none",
};

let _loader = "glb-ldr-prt active";
console.log("===buss sett==", JSON.stringify(_businessSetting));
export default class ReturnAndChallanPDF extends Component {
  constructor(props) {
    super(props);
    if (
      _companyPhone == null ||
      _companyPhone == "" ||
      _companyPhone == "0-null"
    ) {
      _companyPhone = "";
    }
    this.state = {
      invoiceRtnHide: "hide",
      modalIsOpen_PDF: true,
      isLoaded: false,
      transactionId: this.props.transactionId,
      companyName: _companyName,
      companyAddress: _companyAddress,
      companyPhone: _companyPhone,
      companyEmail: _companyEmail,
      display_bank_name: _display_bank_name,
      display_bank_accountNo: _display_bank_accountNo,
      display_bank_holderName: _display_bank_holderName,
      companyGstin: _countryGst,
      logoMForExcel: global.webUrl + "websiteFilesFolder/img/logo.png",
      stateNamePrint: "",
      customerId: "#001",
      customerCompanyName: "Pahal Collection",
      customerAddress: "#861/9, Nanda Nagar, Indore (M.P.) - 452010",
      customerPhone: "0123456789",
      invoiceNo: "1122334455",

      invoiceRtnNo: "1122334455",
      invoiceDate: "1 June, 2021",
      date: "13 Apr 2021",
      logo: "NA",
      paymentType: "Cash",

      titleTxt: "TAX INVOICE",
      billTxt: "Bill To",

      _invNo: "Invoice No.",
      gstArray: [],
      settingTypeDisplay: "",
      description: "",
      totalAddinalCESSAmt: 0,
      totalAmount: 0,
      receivedAmount: 0,
      subtotal: 0,
      gstRatio: 0,
      gstAmount: 0,
      totalQty: 0,
      totalDiscount: 0,
      dueAmount: 0,
      imagePreviewUrl: "",
      poDate: "",
      dueTerms: 0,
      dueOnDate: "",
      poNumber: "",
      EWayBillNo: "",
      E_Way_Bill_No: "",
      _deliveryChallanPrint: 0,
      _invoiceBillNo: 0,
      FreeItemQuantity: 0,
      Count: 0,
      CountItem: 0,
      DisplayPurchasePrice: 0,
      PhoneNumber: 0,
      returnUrl: "",
      printTerms: 0,
      printTermsMsg: "",
      partyBalance: "",
      checkOriginal: "",
      checkOriginalDisable: "",
      checkDuplicate: "",
      checkDuplicateDisable: "",
      checkTriplicate: "",
      checkTriplicateDisable: "",
      transactionWiseTaxName: "",
      transactionWiseTaxId: 0,
      totalTransTaxAmount: 0,
      totalTransDiscountPer: 0,
      totalTransDiscountAmount: 0,
      transactionWiseTaxPercentage: 0,
      PaymentDiscountPayInAmount: 0,
      unUsedAmount: 0,
      prefixName: "",
      prefixId: 0,
      totalAmtInChar: 0,
      dueDate: "",
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      additional_value_date: "",
      firm_additional_field1: "",
      firm_additional_value1: "",
      firm_additional_field2: "",
      firm_additional_value2: "",
      isToggle: false,
      items: [
        {
          id: 1,
          item_name: "Sample Item 1",
          quantity: 1,
          free_quantity: 0,
          trans_count: 0,
          qtyType: "BAG",
          rate: 50,
          subTotalAmount: 50 * 10,
          totalAmount: (50 * 10 * 18) / 100 + 50 * 10,
          itemBatchNoAllData: {},
          itemSerialNoAllData: {},
          logoMForExcel: global.webUrl + "websiteFilesFolder/img/logo.png",
        },
      ],
    };
  }

  async componentDidMount() {
    if (_companyLogo.length < 15) {
      _companyLogo = global.webUrl + "websiteFilesFolder/img/logo.png";
    }
    await this.toDataURL(_companyLogo, function (dataUrl) {
      _imageBaseDecode = dataUrl;
    });
    if (_companySignature!="undefined" && _companySignature!=undefined) {
      if (_companySignature.length > 15) {
        _companySignatureLen = _companySignature.length;

        await this.toDataURL(_companySignature, function (dataUrl) {
          _signatureBaseDecode = dataUrl;
        });
      }
    }

    if (_mainLogoBaseDecode.length > 15) {
      await this.toDataURL(_mainLogoBaseDecode, function (dataUrl) {
        _vLogoBaseDecode = dataUrl;
      });
    }

    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");

    let transactionId = _handleParam[1] ? _handleParam[1] : 0;
    if (transactionId > 0) {
      this.getTransactionDetails(transactionId);
      this.openModalHandler("modalAdjustPDFDownload");
      this.setState({ transactionId: transactionId,_companySignatureLen:_companySignatureLen });
    }
  }

  convertImageUrlToBase64(url, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
      var originalWidth = img.style.width;
      var originalHeight = img.style.height;

      img.style.width = "auto";
      img.style.height = "auto";
      img.crossOrigin = "Anonymous";

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      img.style.width = originalWidth;
      img.style.height = originalHeight;

      // Get the data-URL formatted image
      // Firefox supports PNG and JPEG. You could check img.src to
      // guess the original format, but be aware the using "image/jpg"
      // will re-encode the image.
      var dataUrl = canvas.toDataURL(outputFormat);
      //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      _imageBaseDecode = dataUrl;
    };
    img.src = url;
  }

  convertMainImageUrlToBase64(url, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
      var originalWidth = img.style.width;
      var originalHeight = img.style.height;

      img.style.width = "auto";
      img.style.height = "auto";
      img.crossOrigin = "Anonymous";

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      img.style.width = originalWidth;
      img.style.height = originalHeight;

      // Get the data-URL formatted image
      // Firefox supports PNG and JPEG. You could check img.src to
      // guess the original format, but be aware the using "image/jpg"
      // will re-encode the image.
      var dataUrl = canvas.toDataURL(outputFormat);
      //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      _vLogoBaseDecode = dataUrl;
    };
    img.src = url;
  }

  toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);

      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete == undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }

  async getTransactionDetails(transactionId) {
    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userReturnTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({});
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              console.log('_transactionArray', JSON.stringify(_transactionArray))
              let transAmout = _transactionArray.totalSummary;
              //let itemsData = _transactionArray.items;
              let invoiceData = _transactionArray.invoiceData;
              let customerData = _transactionArray.customer;
              let prefixData = _transactionArray.prefix;
              let _gstArray = [];
              let _itemsArray = [];
              let _CountItem = 0;

              let _prefixName = "";
              let _prefixId = 0;
              if (prefixData.id > 0) {
                _prefixName = prefixData.name;
                _prefixId = prefixData.id;
              }
              if (_businessSetting.PrintOriginalDuplicate == 1) {
                if (
                  _transactionArray.settingType == "showall" ||
                  _transactionArray.settingType == "null" ||
                  _transactionArray.settingType == null ||
                  _transactionArray.settingType == ""
                ) {
                  _checkDuplicate = "checked";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "checked";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "checked";
                  _checkTriplicateDisable = "disbaled";
                } else if (_transactionArray.settingType == "Original") {
                  _checkDuplicate = "";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "checked";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "";
                  _checkTriplicateDisable = "disbaled";
                } else if (_transactionArray.settingType == "Duplicate") {
                  _checkDuplicate = "checked";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "";
                  _checkTriplicateDisable = "disbaled";
                } else if (_transactionArray.settingType == "Triplicate") {
                  _checkDuplicate = "";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "checked";
                  _checkTriplicateDisable = "disbaled";
                }
              }
              let itemsData = [];
                  invoiceData.map((invoice) => {
                    invoice.items.map((item) => {
                      itemsData.push(item)
                    })
              });
              console.log('itemsData', JSON.stringify(itemsData))
              let subTotal = 0;
              itemsData.map((items, index) => {
                let _obj = {};
                _obj.item_name = items.item_name;
                _obj.hsn_sac = items.hsn_sac;
                _obj.quantity = items.newQuantity ? items.newQuantity : items.quantity;
                _obj.free_quantity = items.free_quantity;
                _obj.trans_count = items.trans_count;
                _obj.rate = items.rate;
                _obj.qtyType = "";
                _obj.discountAmount = 0;
                _obj.discountPer = 0;
                _obj.itemBatchNoAllData = items.itemBatchNoAllData;
                _obj.itemSerialNoAllData = items.itemSerialNoAllData;
                if (items.discount_in_amount > 0) {
                  _obj.discountAmount = items.discount_in_amount;
                }
                if (items.discount_in_per > 0) {
                  _obj.discountPer = items.discount_in_per;
                }
                _obj.gstAmount = 0;
                _obj.gstPerRatio = 0;
                if (Object.keys(items.tax_type).length > 0) {
                  _obj.gstAmount = items.tax_value;
                  _obj.gstPerRatio = items.tax_type.ratio;
                }
                _obj.subTotalAmount = items.subtotal;
                subTotal += parseInt(items.subtotal);
                _obj.totalAmount = items.total_amount;

                _obj.unitName = items.unit.unit_name
                  ? items.unit.unit_name
                  : "";
                _obj.Additional_CESS = items.Additional_CESS;
                _itemsArray.push(_obj);

                items.tax_type.price = items.tax_value;
                _gstArray.push(items.tax_type);
                if (items.trans_count > 0) {
                  _CountItem = 1;
                }
              });
              let _titleTrans = "TAX INVOICE";
              let _billTrans = "TAX INVOICE";
              let _invoiceRtnHide = "hide";
              let _invNumber = "Invoice No.";
              let _returnUrl = global.webUrl + "business/sale-invoice/";
              let _printTerms = 0;
              let _printTermsMsg = "";
              let _dueDate = "";
              let _dueDateText = "";

              let _pdfInvoiceTime = _transactionArray.invoiceDate;
              if (
                  _businessSetting.Tran_AddTimePrint == 1 ||
                  _businessSetting.Tran_AddTimePrint == "1"
              ) {
                _pdfInvoiceTime =
                    _transactionArray.invoiceDate +
                    " " +
                    _transactionArray.invoice_time;

                invTime = _transactionArray.invoice_time;
              }

              if (_transactionArray.type == "DELIVERY CHALLAN" || _transactionArray.type == "SALE") {
                _transactionArray.type = "DELIVERY CHALLAN";
                _invNumber = "Challan No.";
                _titleTrans = "Delivery Challan";
                _billTrans = "Delivery Challan For";
                _invoiceRtnHide = "";
                _returnUrl =
                  global.webUrl + "business/manage-sale-delivery-challan/";
                _printTermsMsg = _businessSetting.TermCondDelvChallanMsg;
                _printTerms = _businessSetting.TermCondDelvChallanMsgPrint;
                if (_businessSetting.DeliveryChallanTransName.length > 1) {
                  _titleTrans = _businessSetting.DeliveryChallanTransName;
                }
                _pdfDate = _pdfInvoiceTime;
              } else if (_transactionArray.type == "CREDIT NOTE") {
                _invNumber = "Return No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _invoiceRtnHide = "";
                _titleTrans = "Credit Note";
                _billTrans = "Return For";
                _returnUrl = global.webUrl + "business/manage-sale-return/";
                if (_businessSetting.CreditNoteTransName.length > 1) {
                  _titleTrans = _businessSetting.CreditNoteTransName;
                }
                _pdfDate = _transactionArray.invoice_date_return;
              } else if (_transactionArray.type == "DEBIT NOTE") {
                _invNumber = "Return No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _invoiceRtnHide = "";
                _titleTrans = "Debit Note";
                _billTrans = "Return To";
                _returnUrl = global.webUrl + "business/manage-purchase-return/";
                if (_businessSetting.DebitNoteTransName.length > 1) {
                  _titleTrans = _businessSetting.DebitNoteTransName;
                }
                _pdfDate = _transactionArray.invoice_date_return;
              } else {
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _returnUrl = global.webUrl + "business/sale-invoice/";
              }
              let _partyBalance = 0;
              if (
                customerData.partyBalance!=null ||
                customerData.partyBalance!=""
              ) {
                _partyBalance = customerData.partyBalance;
              }

              let _contactNo = "";
              if (customerData.party_contact_no!=null) {
                let _party_contact_no = customerData.party_contact_no
                  ? customerData.party_contact_no
                  : "NA";
                if (
                  customerData.party_contact_no == null ||
                  customerData.party_contact_no == "null" ||
                  customerData.party_contact_no == 0 ||
                  customerData.party_contact_no == "0"
                ) {
                  _party_contact_no = "NA";
                }
                _contactNo = "Contact No. : " + _party_contact_no;
              }


              let _paymentType = _transactionArray.paymentType;
              if (_transactionArray.paymentType == "Bank") {
                _paymentType =
                  _transactionArray.paymentType +
                  " - " +
                  _transactionArray.bankName +
                  " (Ref No. - " +
                  _transactionArray.paymentRefNo +
                  ")";
              } else if (_transactionArray.paymentType == "Cheque") {
                _paymentType =
                  _transactionArray.paymentType +
                  " (Ref No. - " +
                  _transactionArray.paymentRefNo +
                  ")";
              }
              let _isToggle = _transactionArray.isToggle;
              if (
                _isToggle == true ||
                _isToggle == "true" ||
                _isToggle == 1 ||
                _isToggle == "1"
              ) {
                _isToggle = true;
              }
              let _finalBillName = customerData.party_name;
              if (
                  customerData.billing_name &&
                  _businessSetting.Tran_BillingNameParty == 1
              ) {
                _finalBillName = customerData.billing_name;
              }
              let _billingAddressAK = _transactionArray.billingAddress ? _transactionArray.billingAddress : '';
              let _billingAddress = customerData.party_billing_address;
              if((_transactionArray.billingAddress).length > 0){
                _billingAddress = _billingAddressAK;
              }
              let _finalSubTAmt = parseFloat(transAmout.subtotal);// + parseFloat(_transactionArray.totalAddinalCESSAmt)
              this.setState({
                isLoaded: true,
                logoMForExcel:
                  global.webUrl + "websiteFilesFolder/img/logo.png",
                settingTypeDisplay: _transactionArray.settingType,
                invoiceRtnHide: _invoiceRtnHide,
                customerId: customerData.party_id,
                customerCompanyName: _finalBillName, //customerData.party_name,
                customerBillingName: _finalBillName,
                customerAddress: _billingAddress, //customerData.party_billing_address,
                customerPhone: _contactNo,
                partyBalance: numberFormat(_partyBalance),
                prefixName: _prefixName,
                prefixId: _prefixId,
                invoiceNo: _transactionArray.invoiceNo,
                invoiceRtnNo: _transactionArray.invoice_no_return,
                invoiceDate: _pdfInvoiceTime,
                dueDate: _transactionArray.dueDate == '0' ? _transactionArray.invoiceDate : _transactionArray.dueDate,
                date: _transactionArray.invoiceDate,
                description: _transactionArray.description,
                totalAmtInChar: transAmout.totalAmount,
                reverseChargeStatus: transAmout.reverseChargeStatus,
                reverseChargeTax: transAmout.reverseChargeTax,
                payableAmount: transAmout.payableAmount,
                totalAmount: numberFormat(transAmout.totalAmount),
                subtotal: _transactionArray.type == "DELIVERY CHALLAN" ? numberFormat(transAmout.subtotal) : numberFormat(_finalSubTAmt),
                receivedAmount: numberFormat(transAmout.receivedAmount),
                paidAmount: numberFormat(transAmout.paidAmount),
                gstRatio: numberFormat(transAmout.gstRatio),
                gstAmount: numberFormat(transAmout.gstAmount),
                totalQty: transAmout.totalQty,
                totalDiscount: numberFormat(transAmout.totalDiscount),
                dueAmount: numberFormat(transAmout.dueAmount),
                PaymentDiscountPayInAmount: numberFormat(
                  transAmout.PaymentDiscountPayInAmount
                ),
                unUsedAmount: numberFormat(transAmout.unUsedAmount),
                paymentType: _paymentType,
                items: _itemsArray,
                CountItem: _CountItem,
                gstArray: _gstArray,
                totalAddinalCESSAmt: numberFormat(
                  _transactionArray.totalAddinalCESSAmt ? _transactionArray.totalAddinalCESSAmt : 0
                ),
                _invNo: _invNumber,
                _type: _transactionArray.type,
                poDate: _transactionArray.po_date,
                dueTerms: _transactionArray.dueTerms,
                dueOnDate: _transactionArray.dueOnDate,
                poNumber: _transactionArray.po_number,
                EWayBillNo: _transactionArray.EWayBillNo,
                E_Way_Bill_No: _transactionArray.E_Way_Bill_No,
                stateNamePrint: _transactionArray.countryState,
                titleTxt: _titleTrans,
                billTxt: _billTrans,
                _deliveryChallanPrint: _transactionArray.DeliveryChallanPrint,
                _invoiceBillNo: _transactionArray.InvoiceBillNo,
                party_add_field_key1: _transactionArray.party_add_field_key1,
                party_add_field_key2: _transactionArray.party_add_field_key2,
                party_add_field_key3: _transactionArray.party_add_field_key3,
                party_add_field_value1:
                  _transactionArray.party_add_field_value1,
                party_add_field_value2:
                  _transactionArray.party_add_field_value2,
                party_add_field_value3:
                  _transactionArray.party_add_field_value3,
                PartyAddField1ShowPrint:
                  _businessSetting.PartyAddField1ShowPrint,
                PartyAddField2ShowPrint:
                  _businessSetting.PartyAddField2ShowPrint,
                PartyAddField3ShowPrint:
                  _businessSetting.PartyAddField3ShowPrint,
                PrintCurrentBalanceParty:
                  _transactionArray.PrintCurrentBalanceParty,
                BalanceAmount: _transactionArray.BalanceAmount,
                ReceivedAmount: _transactionArray.ReceivedAmount,
                GSTINNumber: _transactionArray.GSTINNumber,
                FreeItemQuantity: _transactionArray.FreeItemQuantity,
                Count: _transactionArray.Count,
                DisplayPurchasePrice: _transactionArray.DisplayPurchasePrice,
                //ReceivedAmount : _transactionArray.ReceivedAmount,
                PhoneNumber: _transactionArray.PhoneNumber,
                returnUrl: _returnUrl,
                printTermsMsg: _printTermsMsg,
                printTerms: _printTerms,
                checkOriginal: _checkOriginal,
                checkOriginalDisable: _checkOriginalDisable,
                checkDuplicate: _checkDuplicate,
                checkDuplicateDisable: _checkDuplicateDisable,
                checkTriplicate: _checkTriplicate,
                checkTriplicateDisable: _checkTriplicateDisable,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,

                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                transactionWiseTaxName:
                  _transactionArray.transactionWiseTaxName,
                transactionWiseTaxPercentage:
                  _transactionArray.transactionWiseTaxPercentage,
                TransportDetailField1: _transactionArray.TransportDetailField1,
                TransportDetailField2: _transactionArray.TransportDetailField2,
                TransportDetailField3: _transactionArray.TransportDetailField3,
                TransportDetailField4: _transactionArray.TransportDetailField4,
                TransportDetailField5: _transactionArray.TransportDetailField5,
                TransportDetailField6: _transactionArray.TransportDetailField6,
                TransAdditionalCharge1:
                  _transactionArray.TransAdditionalCharge1,
                TransAdditionalCharge2:
                  _transactionArray.TransAdditionalCharge2,
                TransAdditionalCharge3:
                  _transactionArray.TransAdditionalCharge3,
                additional_value_1: _transactionArray.additional_value_1,
                additional_value_2: _transactionArray.additional_value_2,
                additional_value_3: _transactionArray.additional_value_3,
                additional_value_date: _transactionArray.additional_value_date,
                firm_additional_field1:
                  _transactionArray.firm_additional_field1,
                firm_additional_value1:
                  _transactionArray.firm_additional_value1,
                firm_additional_field2:
                  _transactionArray.firm_additional_field2,
                firm_additional_value2:
                  _transactionArray.firm_additional_value2,
              });
              if (_businessSetting.AmountWithDecimal == 0) {
                this.setState({
                  totalAmount: parseInt(transAmout.totalAmount),
                  receivedAmount: parseInt(transAmout.receivedAmount),
                  paidAmount: parseInt(transAmout.paidAmount),
                  subtotal: parseInt(transAmout.subtotal),
                  gstRatio: parseInt(transAmout.gstRatio),
                  gstAmount: parseInt(transAmout.gstAmount),
                  totalQty: parseInt(transAmout.totalQty),
                  totalDiscount: parseInt(transAmout.totalDiscount),
                  dueAmount: parseInt(transAmout.dueAmount),
                  partyBalance: parseInt(_partyBalance),
                  totalAmtInChar: transAmout.totalAmount,
                });
                //console.log(this.state.gstAmount+'==rrr=='+parseInt(transAmout.gstAmount)+'==zzzz=='+(transAmout.gstAmount));
              }
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
          this.setState({ error })
        });
    }
  }

  async clickToGenratePDF(value) {
    let _currentDate = moment().format("MM-DD-YYYY-HH-mm-GG");
    const printArea = document.getElementById("pdfFormatReceipt");
    const pdf = await html2PDF(printArea, {
      html2canvas: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      },
      imageType: "image/png",
      output: _invoiceName + _currentDate + ".pdf",
    });
  }

  async clickToGenratePrint(e) {
    /*let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

        mywindow.document.write(`<html><head><title>Test NJ</title>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById("pdfFormatReceipt").innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10

        mywindow.print();
        mywindow.close();*/

    let printContents = document.getElementById("pdfFormatReceipt").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.print();
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    this.getTransactionDetails();
    if (popId == "modalAdjustPDFDownload") {
      this.setState({ modalIsOpen_PDF: true });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustPDFDownload") {
      this.setState({ modalIsOpen_PDF: false });
    }
  };
  /*End Modal open close*/

  getGstAmtDetails() {
    if (this.state.gstArray.length > 0) {
      this.state.gstArray.map((value, index) => {
        let _i = parseInt(index) + 1;
        return (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {value.name + "(" + value.ratio + "%)"}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.AmountWithDecimal == 1 &&
                numberFormat(value.price)}
              {_businessSetting.AmountWithDecimal == 0 && parseInt(value.price)}

              {/* {numberFormat(value.price)} */}
            </td>
          </tr>
        );
      });
    }
  }

  getTotalCalculation() {
    let totalAmtCode = (
      <tr style={{ background: "#9f9fdf" }}>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#ffffff",
            lineHeight: "24px",
          }}
        >
          Total
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#ffffff",
            lineHeight: "24px",
          }}
        >
          {this.state.totalAmount}
        </td>
      </tr>
    );
    let discountAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Discount
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.totalDiscount}
        </td>
      </tr>
    );

    let subTotalAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Subtotal
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.subtotal}
        </td>
      </tr>
    );

    let receiveAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state._type == 'CREDIT NOTE' ? 'Paid Amount' : 'Received Amount'}
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state._type == 'CREDIT NOTE' ? (this.state.paidAmount === 0 ? this.state.receivedAmount : this.state.paidAmount) : this.state.receivedAmount}
        </td>
      </tr>
    );
    let balanceAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Balance Due
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.dueAmount}
        </td>
      </tr>
    );
    let paymentAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Payment Mode
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.paymentType}
        </td>
      </tr>
    );

    let _challanCssTotal = "";
    let _invType = this.state._type;
    if (_invType == "DELIVERY CHALLAN") {
      _challanCssTotal = "marginTOpCss";
      _invoiceName = "Delivery-Challan-";
      receiveAmtCode = "";
      balanceAmtCode = "";
      paymentAmtCode = "";
    } else if (_invType == "DEBIT NOTE") {
      _invoiceName = "Purchase-Debit-Note-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Received Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.receivedAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
    }

    if (this.state.totalQty == "0") {
      subTotalAmtCode = "";
      discountAmtCode = "";
      paymentAmtCode = "";
    }
    let _transWiseTaxData = "";
    let _transWiseTaxDataTab = "";
    let _transWisePayableTab = "";
    let _transWiseTaxData1 = "";
    let _transWiseTaxData2 = "";
    let _showTransData = 0;
    if (_businessSetting.TransactionWiseTax == 1) {
      if (this.state.transactionWiseTaxName == "GST") {
        _showTransData = 1;
        if (this.state.transactionWiseTaxPercentage > 0) {
          let _finalTranWiseTaxPer =
            parseFloat(this.state.transactionWiseTaxPercentage) / 2;
          let _finalTranWiseTaxAmt =
            parseFloat(this.state.totalTransTaxAmount) / 2;

          _transWiseTaxData1 = (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                SGST@{_finalTranWiseTaxPer}%
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {numberFormat(_finalTranWiseTaxAmt)}
              </td>
            </tr>
          );

          _transWiseTaxData2 = (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                CGST@{_finalTranWiseTaxPer}%
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {numberFormat(_finalTranWiseTaxAmt)}
              </td>
            </tr>
          );

          //_transWiseTaxData = _transWiseTaxData1 + _transWiseTaxData2;
        } else {
          _transWiseTaxData = "";
        }
      } else {
        _showTransData = 2;
        if (this.state.transactionWiseTaxPercentage > 0) {
          let _finalTranWiseTaxPer = parseFloat(
            this.state.transactionWiseTaxPercentage
          );
          let _finalTranWiseTaxAmt = parseFloat(this.state.totalTransTaxAmount);

          _transWiseTaxData = (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {this.state.transactionWiseTaxName}@{_finalTranWiseTaxPer}%
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {numberFormat(_finalTranWiseTaxAmt)}
              </td>
            </tr>
          );
        } else {
          _transWiseTaxData = "";
        }
      }
    }
    if (_businessSetting.ReverseCharge == 1) {
      if (this.state.reverseChargeStatus == 1) {
        let _reverseChargeTax = parseFloat(this.state.reverseChargeTax);
        let _payableAmount = parseFloat(this.state.payableAmount);
        _transWiseTaxDataTab = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              Reverse Charge Tax
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(_reverseChargeTax)}
            </td>
          </tr>
        );
        _transWisePayableTab = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              Payable Amount
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(_payableAmount)}
            </td>
          </tr>
        );
      } else {
        _transWiseTaxDataTab = "";
        _transWisePayableTab = "";
      }
    }
    let _shippingCharge1 = "";
    let _shippingCharge2 = "";
    let _shippingCharge3 = "";
    if (
      _businessSetting.TransactionAdditionalChargeEnable == 1 &&
      this.state._type!="PAYMENT-IN"
    ) {
      if (_businessSetting.TransactionAdditionalCharge1Enable == 1) {
        _shippingCharge1 = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.TransactionAdditionalCharge1Name}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(this.state.TransAdditionalCharge1)}
            </td>
          </tr>
        );
      }
      if (_businessSetting.TransactionAdditionalCharge2Enable == 1) {
        _shippingCharge2 = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.TransactionAdditionalCharge2Name}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(this.state.TransAdditionalCharge2)}
            </td>
          </tr>
        );
      }
      if (_businessSetting.TransactionAdditionalCharge3Enable == 1) {
        _shippingCharge3 = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.TransactionAdditionalCharge3Name}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(this.state.TransAdditionalCharge3)}
            </td>
          </tr>
        );
      }
    }

    return (
      <div>
        <table
          cellPadding="0"
          cellSpacing="0"
          width="100%"
          className={_challanCssTotal}
        >
          <tr>
            <td colSpan="2" height="10">
              &nbsp;
            </td>
          </tr>
          {subTotalAmtCode}
          {/* {discountAmtCode} */}
          {_businessSetting.TransactionWiseDiscount == 1 &&
            this.state._type!="PAYMENT-IN" && (
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  Discount ({this.state.totalTransDiscountPer} %)
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  {numberFormat(this.state.totalTransDiscountAmount)}
                </td>
              </tr>
            )}



          {_showTransData == 1 && _transWiseTaxData1}
          {_showTransData == 1 && _transWiseTaxData2}
          {_showTransData == 2 && _transWiseTaxData}
          {_showTransData == 0 && _transWiseTaxData}
          {_transWiseTaxDataTab}
          {_transWisePayableTab}
          {this.getGstAmtDetails()}

          {_shippingCharge1}
          {_shippingCharge2}
          {_shippingCharge3}
          {totalAmtCode}
          {_businessSetting.ReceivedAmount == 1 && receiveAmtCode}
          {/* { (this.state.PrintCurrentBalanceParty ==1 && this.state.BalanceAmount ==1 && this.state._type == 'DELIVERY CHALLAN') &&
                        balanceAmtCode
                    } */}
          {_businessSetting.BalanceAmount == 1 && balanceAmtCode}
          {_businessSetting.PaymentMode == 1 && paymentAmtCode}

          {_businessSetting.PrintCurrentBalanceParty == 1 &&
            this.state._type!="PAYMENT-IN" &&
            this.state._type!="EXPENSE" && (
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  Current Balance
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  {this.state.partyBalance}
                </td>
              </tr>
            )}
          <tr>
            <td colSpan="2" height="50">
              &nbsp;
            </td>
          </tr>
          {_businessSetting.PrintSignatureText == 1 && (
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "center",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  maxWidth: "100px",
                  maxHeight: "100px",
                  borderRadius: "10px",
                }}
              >
                {/* {_signatureBaseDecode+'==>>=='} */}

                {_companySession.company_signature.length > 15 && (
                  <img
                    src={_companySession.company_signature}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      borderRadius: "10px",
                      marginLeft: "20px",
                    }}
                  />
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Authorized Signature
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  }
  printDemo() {
    let _extra = "";
    let _hideItemPrice = "";
    let _showTransportD = 0;
    if (
      (this.state.TransportDetailField1!=null &&
        this.state.TransportDetailField1!="") ||
      (this.state.TransportDetailField2!=null &&
        this.state.TransportDetailField2!="") ||
      (this.state.TransportDetailField3!=null &&
        this.state.TransportDetailField3!="") ||
      (this.state.TransportDetailField4!=null &&
        this.state.TransportDetailField4!="")
    ) {
      _showTransportD = 1;
    }
    let amt = this.state.totalAmtInChar;
    let _totalAmtInChar = "";
    if (amt > 0) {
      let returnData = new ToWords({
        converterOptions: { ignoreDecimal: false },
      }).convert(amt ? amt : 0);
      _totalAmtInChar = returnData + " Only";
    } else {
      _totalAmtInChar = "Zero";
    }
    let _companyAddressPrint = this.state.companyAddress
      ? this.state.companyAddress.length
      : 0;
    let _stateNamePrint = this.state.stateNamePrint
      ? this.state.stateNamePrint.length
      : 0;
    let _itemName = _businessSetting.item_name
      ? _businessSetting.item_name
      : "Item Name";
    let _si_no = "#";
    let _item_code = "Item code";
    let _hsn_sac = "HSN/SAC";
    let _quantity = "Qty";
    let _unit = "Unit";
    let _discount = "Discount";
    let _price_per_unit = "Rate";
    let _total_amount = "Amount";
    let _addditional_cess = _businessSetting.addditional_cess
      ? _businessSetting.addditional_cess
      : "Ad. CESS Tabish";

    if (_businessSetting.si_enable == 1) {
      _si_no = _businessSetting.si_no;
    }
    if (_businessSetting.item_code_enabled == 1) {
      _item_code = _businessSetting.item_code;
    }
    if (_businessSetting.hsn_sac_enable == 1) {
      _hsn_sac = _businessSetting.hsn_sac;
    }
    if (_businessSetting.quantity_enable == 1) {
      _quantity = _businessSetting.quantity;
    }
    if (_businessSetting.unit_enabled == 1) {
      _unit = _businessSetting.unit;
    }
    if (_businessSetting.price_per_unit_enabled == 1) {
      _price_per_unit = _businessSetting.price_per_unit;
    }
    if (_businessSetting.discount_enable == 1) {
      _discount = _businessSetting.discount;
    }
    if (_businessSetting.total_amount_enable == 1) {
      _total_amount = _businessSetting.total_amount;
    }
    //console.log("==buss sett==",JSON.stringify(_businessSetting));

    if (this.state.isLoaded == true) {
      return (
        <table
          cellPadding=""
          cellSpacing=""
          border={0}
          width="100%"
          id={"exportxls"}
          style={{ display: "none" }}
        >
          <tbody>
            {_businessSetting.PrintOriginalDuplicate == 1 && (
              <tr>
                <td></td>
                <td colSpan={3} width="100%" align="right">
                  {this.state.settingTypeDisplay == "showall" && (
                    <div
                      style={{
                        flex: "1",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      <span
                        style={{
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <b>Setting Type : </b>{" "}
                      </span>
                      <span
                        style={{
                          display: "inline-flex",
                          float: "right",
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        <b>Original For Recipient</b>
                      </span>
                      ,&nbsp;&nbsp;
                      <span
                        style={{
                          display: "inline-flex",
                          float: "right",
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        <b> Duplicate For Transporter</b>
                      </span>
                      ,&nbsp;&nbsp;
                      <span
                        style={{
                          display: "inline-flex",
                          float: "right",
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        <b> Triplicate For Supplier</b>
                      </span>
                    </div>
                  )}
                  {this.state.settingTypeDisplay == "Duplicate" && (
                    <div
                      style={{
                        flex: "1",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      <span
                        style={{
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <b>Setting Type : </b>{" "}
                      </span>
                      <span
                        style={{
                          display: "inline-flex",
                          float: "right",
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        <b> Duplicate For Transporter</b>
                      </span>
                    </div>
                  )}
                  {this.state.settingTypeDisplay == "Original" && (
                    <div
                      style={{
                        flex: "1",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      <span
                        style={{
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <b>Setting Type : </b>
                      </span>
                      <span
                        style={{
                          display: "inline-flex",
                          float: "right",
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        <b>Original For Recipient</b>
                      </span>
                    </div>
                  )}
                  {this.state.settingTypeDisplay == "Triplicate" && (
                    <div
                      style={{
                        flex: "1",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "left",
                      }}
                    >
                      <span
                        style={{
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        <b>Setting Type : </b>{" "}
                      </span>
                      <span
                        style={{
                          display: "inline-flex",
                          float: "right",
                          flex: "1",
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "left",
                        }}
                      >
                        <b>Triplicate For Supplier</b>
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td valign="top">
                <div
                  id="logo"
                  style={{
                    flex: "1",
                    alignItems: "center",
                    width: "150px !important",
                    height: "110px  !important",
                    marginTop: "10px",
                  }}
                  onClick="_captureLogoButton()"
                >
                  {/* {_imageBaseDecode} */}
                  {_businessSetting.CompanyLogo == 1 && (
                    <img
                      src={_companySession.company_logo}
                      style={{
                        maxWidth: "150px !important",
                        maxHeight: "100px !important",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </td>
              <td align="right" valign="top">
                {(_businessSetting.TransportationDetailsField1Enable == 1 ||
                  _businessSetting.TransportationDetailsField2Enable == 1 ||
                  _businessSetting.TransportationDetailsField3Enable == 1 ||
                  _businessSetting.TransportationDetailsField4Enable == 1 ||
                  _businessSetting.TransportationDetailsField5Enable == 1 ||
                  _businessSetting.TransportationDetailsField5Enable == 1) &&
                  _showTransportD == 1 && (
                    <div
                      style={{
                        flex: "1",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "right",
                        marginRight: "50px",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "bold",
                          margin: "3px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {" "}
                        Transportation Details
                      </h5>

                      {_businessSetting.TransportationDetailsField1Enable ==
                        1 &&
                        this.state.TransportDetailField1!=null &&
                        this.state.TransportDetailField1!="" && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {_businessSetting.TransportationDetailsField1Name} :{" "}
                            {this.state.TransportDetailField1}
                          </p>
                        )}

                      {_businessSetting.TransportationDetailsField2Enable ==
                        1 &&
                        this.state.TransportDetailField2!=null &&
                        this.state.TransportDetailField2!="" && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {_businessSetting.TransportationDetailsField2Name} :{" "}
                            {this.state.TransportDetailField2}
                          </p>
                        )}
                      {_businessSetting.TransportationDetailsField3Enable ==
                        1 &&
                        this.state.TransportDetailField3!=null &&
                        this.state.TransportDetailField3!="" && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {_businessSetting.TransportationDetailsField3Name} :{" "}
                            {this.state.TransportDetailField3}
                          </p>
                        )}
                      {_businessSetting.TransportationDetailsField4Enable ==
                        1 &&
                        this.state.TransportDetailField4!=null &&
                        this.state.TransportDetailField4!="" && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {_businessSetting.TransportationDetailsField4Name} :{" "}
                            {this.state.TransportDetailField4}
                          </p>
                        )}
                      {_businessSetting.TransportationDetailsField5Enable ==
                        1 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField5Name} :{" "}
                          {this.state.TransportDetailField5}
                        </p>
                      )}
                      {_businessSetting.TransportationDetailsField6Enable ==
                        1 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField6Name} :{" "}
                          {this.state.TransportDetailField6}
                        </p>
                      )}
                    </div>
                  )}
              </td>
              <td align="right" valign="top">
                <div
                  style={{
                    flex: "1",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    lineHeight: "20px",
                    textAlign: "right",
                  }}
                >
                  {_businessSetting.SateOfSupply == 1 &&
                    this.state.stateNamePrint!="Select State" && (
                      <p
                        style={{
                          margin: "3px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_stateNamePrint > 2
                          ? "State Of Supply : " + this.state.stateNamePrint
                          : ""}
                      </p>
                    )}
                  {_businessSetting.PartyCompanyName == 1 && (
                    <p
                      style={{
                        fontWeight: "bold",
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      Company Name : {this.state.companyName}
                    </p>
                  )}

                  {_businessSetting.Address == 1 && (
                    <p
                      style={{
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {_companyAddressPrint > 2
                        ? "Company Address : " + this.state.companyAddress
                        : ""}
                    </p>
                  )}
                  {_businessSetting.PhoneNumber == 1 && (
                    <p
                      style={{
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.companyPhone.length > 2
                        ? "Company Phone no. : " + this.state.companyPhone
                        : ""}
                    </p>
                  )}

                  {_businessSetting.Email == 1 && (
                    <p
                      style={{
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.companyEmail.length > 2
                        ? "Company Email : " + this.state.companyEmail
                        : ""}
                    </p>
                  )}

                  {this.state.firm_additional_field1!="" && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.firm_additional_field1} :{" "}
                      {this.state.firm_additional_value1}
                    </p>
                  )}
                  {this.state.firm_additional_field2!="" && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.firm_additional_field2} :{" "}
                      {this.state.firm_additional_value2}
                    </p>
                  )}

                  {_businessSetting.GSTINSale == 1 && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.companyGstin!=""
                        ? "GSTIN : " + this.state.companyGstin
                        : ""}
                    </p>
                  )}
                  {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                    <div id="additionFields">
                      {_businessSetting.additional_field_check1 == 1 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.additional_field_name1} :{" "}
                          {this.state.additional_value_1}
                        </p>
                      )}
                      {_businessSetting.additional_field_check2 == 1 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.additional_field_name2} :{" "}
                          {this.state.additional_value_2}
                        </p>
                      )}
                      {_businessSetting.additional_field_check3 == 1 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.additional_field_name3} :{" "}
                          {this.state.additional_value_3}
                        </p>
                      )}
                      {_businessSetting.additional_field_date_field_check ===
                        1 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.additional_field_date_field_name} :{" "}
                          {this.state.additional_value_date}
                        </p>
                      )}
                    </div>
                  )}
                  {_businessSetting.Tran_CustomerPO ? (
                    <div>
                      {this.state.poDate!=null &&
                        this.state.poNumber!="null" &&
                        this.state.poNumber!="Invalid date" && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {this.state.poDate!=""
                              ? "PO Date : " + this.state.poDate
                              : ""}
                          </p>
                        )}
                      {this.state.poNumber!=null &&
                        this.state.poNumber!="null" && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {this.state.poNumber!=""
                              ? "PO Number : " + this.state.poNumber
                              : ""}
                          </p>
                        )}
                    </div>
                  ) : (
                    <div />
                  )}
                  {_businessSetting.DueDatesPaymentTerms ? (
                    <div>
                      {/* {(this.state.dueOnDate!=null ||
                        this.state.dueOnDate!="null" ||
                        this.state.dueOnDate!="Invalid date"  || this.state.dueOnDate!=0 || this.state.dueOnDate!="0") && */}
                      {this.state.dueOnDate.length > 6 &&
                        this.state.dueTerms > 0 && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {this.state.dueOnDate!=""
                              ? "Due Date  : " + this.state.dueOnDate
                              : ""}
                          </p>
                        )}
                    </div>
                  ) : (
                    <div />
                  )}

                  {this.state.E_Way_Bill_No!=null &&
                    this.state.E_Way_Bill_No!="null" &&
                    this.state.EWayBillNo == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.E_Way_Bill_No!=""
                          ? "E-Way Bill Number : " + this.state.E_Way_Bill_No
                          : ""}
                      </p>
                    )}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center"></td>
            </tr>
            <tr>
              <td colSpan={2} align="center">
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontFamily: "Arial",
                    padding: "10px 0",
                    fontWeight: "bold",
                    color: "#9f9fdf",
                  }}
                >
                  {this.state.titleTxt}
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan={2} valign="top" width="100%">
                <div>
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    width="100%"
                    style={{ marginBottom: "40px" }}
                  >
                    <tr>
                      <td width="50%" valign="top">
                        <h6
                          style={{
                            background: "#9f9fdf",
                            color: "black",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Arial",
                            margin: "5px 0",
                          }}
                        >
                          {this.state.billTxt}
                        </h6>

                        <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              margin: "3px 0",
                              color: "black",
                              marginBottom: "5px",
                            }}
                        >
                          {this.state.customerBillingName}
                        </p>

                        {this.state.customerAddress!=null && this.state.customerAddress!="" &&
                          this.state.customerAddress!="null" && (
                            <p
                              style={{
                                fontFamily: "Arial",
                                fontSize: "15px",
                                lineHeight: "20px",
                                margin: "0 0",
                                color: "black",
                                marginBottom: "5px",
                              }}
                            >
                              {this.state.customerAddress}
                            </p>
                          )}
                        {/* {this.state.customerBillingName && (
                            <p
                              style={{
                                fontFamily: "Arial",
                                fontSize: "15px",
                                lineHeight: "20px",
                                margin: "0 0",
                                color: "black",
                                marginBottom: "5px",
                              }}
                            >
                              {this.state.customerBillingName}
                            </p>
                          )} */}
                        <p
                          style={{
                            fontFamily: "Arial",
                            fontSize: "15px",
                            lineHeight: "20px",
                            margin: "0 0",
                            color: "black",
                          }}
                        >
                          {this.state.customerPhone}
                        </p>

                        {this.state.PartyAddField1ShowPrint == 1 && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {this.state.party_add_field_value1!=null &&
                              this.state.party_add_field_value1!="" &&
                              this.state.party_add_field_key1!=null &&
                              this.state.party_add_field_key1!="" && (
                                <span>
                                  {this.state.party_add_field_value1!=""
                                    ? this.state.party_add_field_key1 +
                                      " : " +
                                      this.state.party_add_field_value1
                                    : ""}
                                </span>
                              )}
                          </p>
                        )}
                        {this.state.PartyAddField2ShowPrint == 1 && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {this.state.party_add_field_value2!=null &&
                              this.state.party_add_field_value2!="" &&
                              this.state.party_add_field_key2!=null &&
                              this.state.party_add_field_key2!="" && (
                                <span>
                                  {this.state.party_add_field_value2!=""
                                    ? this.state.party_add_field_key2 +
                                      " : " +
                                      this.state.party_add_field_value2
                                    : ""}
                                </span>
                              )}
                          </p>
                        )}
                        {this.state.PartyAddField3ShowPrint == 1 && (
                          <p
                            style={{
                              margin: "3px 0 10px 0",
                              color: "black",
                              fontSize: "15px",
                            }}
                          >
                            {this.state.party_add_field_value3!=null &&
                              this.state.party_add_field_value3!="" &&
                              this.state.party_add_field_key3!=null &&
                              this.state.party_add_field_key3!="" && (
                                <span>
                                  {this.state.party_add_field_value3!=""
                                    ? this.state.party_add_field_key3 +
                                      " : " +
                                      this.state.party_add_field_value3
                                    : ""}
                                </span>
                              )}
                          </p>
                        )}
                      </td>
                      <td width="50%" valign="top">
                        <h6
                            style={{
                              background: "#9f9fdf",
                              color: "white",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              margin: "5px 0",
                            }}
                        >
                          {this.state._invNo}
                        </h6>
                        {(this.state._type == 'CREDIT NOTE' || this.state._type == 'DEBIT NOTE') ?
                            <p
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  lineHeight: "20px",
                                  textAlign: "center",
                                  margin: "0 0",
                                  color: "black",
                                }}
                            >
                              {this.state.prefixId > 0 &&
                              this.state.prefixName!="None" &&
                              this.state.prefixName!="none" &&
                              "(" + this.state.prefixName + ") "}
                              {this.state.invoiceRtnNo}
                            </p> :
                            <p
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  lineHeight: "20px",
                                  textAlign: "center",
                                  margin: "0 0",
                                  color: "black",
                                }}
                            >
                              {this.state.prefixId > 0 &&
                              this.state.prefixName!="None" &&
                              this.state.prefixName!="none" &&
                              "(" + this.state.prefixName + ") "}
                              {this.state.invoiceNo}
                            </p>
                          }

                        <h6
                            style={{
                              background: "#9f9fdf",
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "15px",
                              fontFamily: "Arial",
                              margin: "5px 0",
                            }}
                        >
                          Date.
                        </h6>
                        <p
                            style={{
                              fontFamily: "Arial",
                              fontSize: "15px",
                              lineHeight: "20px",
                              textAlign: "center",
                              margin: "0 0",
                              color: "black",
                            }}
                        >
                          {_pdfDate}
                        </p>
                        {
                          (this.state._type == 'CREDIT NOTE' || this.state._type == 'DEBIT NOTE') ?
                              <></> :
                              <>
                                <h6
                                    style={{
                                      background: "#9f9fdf",
                                      color: "#FFFFFF",
                                      textAlign: "center",
                                      fontSize: "15px",
                                      fontFamily: "Arial",
                                      margin: "5px 0",
                                    }}
                                >
                                  Due Date.
                                </h6>
                                <p
                                    style={{
                                      fontFamily: "Arial",
                                      fontSize: "15px",
                                      lineHeight: "20px",
                                      textAlign: "center",
                                      margin: "0 0",
                                      color: "black",
                                    }}
                                >
                                  {this.state.dueDate}
                                </p>
                              </>
                        }
                      </td>
                    </tr>
                    {this.state.totalQty!=0 && (
                      <tr>
                        <td colSpan="3" width="100%">
                          <table
                            width="100%"
                            cellPadding="0"
                            cellSpacing="0"
                            style={{ marginTop: "10px" }}
                          >
                            <tr style={{ backgroundColor: "#9f9fdf" }}>
                              {_businessSetting.si_enable == 1 && (
                                <th
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_si_no}
                                </th>
                              )}

                              <th
                                style={{
                                  color: "#FFFFFF",
                                  textAlign: "center",
                                  //fontSize: '15px',
                                  fontFamily: "Arial",
                                  //lineHeight: '24px',
                                  padding: "3px",
                                }}
                              >
                                {_itemName}
                              </th>

                              {_businessSetting.hsn_sac_enable == 1 && (
                                <th
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_hsn_sac}
                                </th>
                              )}
                              {this.state.Count > 0 &&
                                this.state.CountItem > 0 && (
                                  <th
                                    style={{
                                      color: "#FFFFFF",
                                      textAlign: "center",
                                      //fontSize: '15px',
                                      fontFamily: "Arial",
                                      //lineHeight: '24px',
                                      padding: "3px",
                                    }}
                                  >
                                    Count
                                  </th>
                                )}

                              {_businessSetting.quantity_enable == 1 && (
                                <th
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_quantity}
                                </th>
                              )}
                              {_businessSetting.unit_enabled == 1 && (
                                <th
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_unit}
                                </th>
                              )}

                              {_businessSetting.price_per_unit_enabled ===
                                1 && (
                                <th
                                  className={_hideItemPrice}
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_price_per_unit}
                                </th>
                              )}

                              {_businessSetting.discount_enable == 1 && (
                                <th
                                  className={_hideItemPrice}
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_discount}
                                </th>
                              )}
                              <th
                                className={_hideItemPrice}
                                style={{
                                  color: "#FFFFFF",
                                  textAlign: "center",
                                  //fontSize: '15px',
                                  fontFamily: "Arial",
                                  //lineHeight: '24px',
                                  padding: "3px",
                                }}
                              >
                                GST
                              </th>

                              {_businessSetting.AdditionalCESS == 1 && (
                                <th
                                  className={_hideItemPrice}
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_addditional_cess}
                                </th>
                              )}

                              {_businessSetting.total_amount_enable == 1 && (
                                <th
                                  className={_hideItemPrice}
                                  style={{
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                    //fontSize: '15px',
                                    fontFamily: "Arial",
                                    //lineHeight: '24px',
                                    padding: "3px",
                                  }}
                                >
                                  {_total_amount}
                                </th>
                              )}
                            </tr>
                            {this.state.items.map((value, index) => {
                              console.log("==DATA==", JSON.stringify(value));
                              let _i = parseInt(index) + 1;
                              let _free_quantity = "";
                              if (
                                _businessSetting.FreeItemQuantity == 1 &&
                                value.free_quantity > 0
                              ) {
                                _free_quantity = " + " + value.free_quantity;
                              }
                              let _gstRatio = "";
                              if (
                                _businessSetting.tax_percentage_enable == 1
                              ) {
                                _gstRatio = "(" + value.gstPerRatio + " %)";
                              }
                              let _batchData = "";
                              let _batchModelData = "";
                              if (
                                Object.keys(value.itemBatchNoAllData).length > 1
                              ) {
                                if (value.itemBatchNoAllData.batch_no!="") {
                                  _batchData =
                                    _businessSetting.BatchNoTxt +
                                    " : " +
                                    value.itemBatchNoAllData.batch_no;
                                }
                                if (value.itemBatchNoAllData.model_no!="") {
                                  _batchModelData =
                                    _businessSetting.ModelNoTxt +
                                    " : " +
                                    value.itemBatchNoAllData.model_no;
                                }
                              }
                              let _serialNoData = "";
                              let _serialAdd1Data = "";
                              let _serialAdd2Data = "";
                              if (
                                Object.keys(value.itemSerialNoAllData).length >
                                1
                              ) {
                                if (
                                  value.itemSerialNoAllData.serial_no!=""
                                ) {
                                  _serialNoData =
                                    _businessSetting.SerialNoTxt +
                                    " : " +
                                    value.itemSerialNoAllData.serial_no;
                                }
                                if (
                                  _businessSetting.SerialNoAdditionalLabel1 !==
                                  ""
                                ) {
                                  _serialAdd1Data =
                                    _businessSetting.SerialNoAdditionalLabel1 +
                                    " : " +
                                    value.itemSerialNoAllData
                                      .serialNoAdditionalValue1;
                                }
                                if (
                                  value.itemSerialNoAllData
                                    .serialNoAdditionalValue2!=""
                                ) {
                                  _serialAdd2Data =
                                    _businessSetting.SerialNoAdditionalLabel2 +
                                    " : " +
                                    value.itemSerialNoAllData
                                      .serialNoAdditionalValue2;
                                }
                              }

                              return (
                                <tr>
                                  {_businessSetting.si_enable == 1 && (
                                    <td
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {_i}
                                    </td>
                                  )}
                                  <td
                                    style={{
                                      fontFamily: "Arial",
                                      fontSize: "15px",
                                      textAlign: "center",
                                      color: "#333333",
                                      lineHeight: "24px",
                                      borderTop: "1px solid #2929af",
                                      borderBottom: "1px solid #2929af",
                                    }}
                                  >
                                    {value.item_name}

                                    {/* <span>{_batchData}</span>
                                  <br />
                                  <span>{_batchModelData}</span>
                                  <br />
                                  <span>{_serialNoData}</span>
                                  <br />
                                  <span>{_serialAdd1Data}</span>
                                  <br />
                                  <span>{_serialAdd2Data}</span> */}

                                    {value.itemBatchNoAllData.map(
                                      (batchDataLoop, index) => {
                                        return (
                                          <table>
                                            {batchDataLoop.batch_no!="" && (
                                              <tr>
                                                {_businessSetting.batch_tracking_enable ===
                                                  1 && (
                                                  <td>
                                                    <span>
                                                      {
                                                        _businessSetting.BatchNoTxt
                                                      }{" "}
                                                      : {batchDataLoop.batch_no}
                                                    </span>
                                                  </td>
                                                )}
                                              </tr>
                                            )}
                                          </table>
                                        );
                                      }
                                    )}

                                    {value.itemSerialNoAllData.map(
                                      (serialDataLoop, index) => {
                                        return (
                                          <table>
                                            {serialDataLoop.serial_no!="" && (
                                              <tr>
                                                {_businessSetting.serial_tracking_enabled ===
                                                  1 && (
                                                  <td>
                                                    {serialDataLoop.serial_no !=
                                                      "" && (
                                                      <span>
                                                        {" "}
                                                        {
                                                          _businessSetting.SerialNoTxt
                                                        }{" "}
                                                        :{" "}
                                                        {
                                                          serialDataLoop.serial_no
                                                        }
                                                      </span>
                                                    )}
                                                    <br />
                                                    {_businessSetting.SerialNoAdditionalLabel1 !==
                                                      "" && (
                                                      <span>
                                                        {
                                                          _businessSetting.SerialNoAdditionalLabel1
                                                        }{" "}
                                                        :{" "}
                                                        {
                                                          serialDataLoop.serialNoAdditionalValue1
                                                        }
                                                      </span>
                                                    )}
                                                    <br />
                                                    {_businessSetting.SerialNoAdditionalLabel2 !==
                                                      "" && (
                                                      <span>
                                                        {
                                                          _businessSetting.SerialNoAdditionalLabel2
                                                        }{" "}
                                                        :{" "}
                                                        {
                                                          serialDataLoop.serialNoAdditionalValue2
                                                        }
                                                      </span>
                                                    )}
                                                    <br />
                                                  </td>
                                                )}
                                              </tr>
                                            )}
                                          </table>
                                        );
                                      }
                                    )}
                                  </td>

                                  {_businessSetting.hsn_sac_enable == 1 && (
                                    <td
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {value.hsn_sac}
                                    </td>
                                  )}

                                  {this.state.Count == 1 &&
                                    this.state.CountItem == 1 && (
                                      <td
                                        style={{
                                          fontFamily: "Arial",
                                          fontSize: "15px",
                                          textAlign: "center",
                                          color: "#333333",
                                          lineHeight: "24px",
                                          borderTop: "1px solid #2929af",
                                          borderBottom: "1px solid #2929af",
                                        }}
                                      >
                                        {value.trans_count}
                                      </td>
                                    )}
                                  {_businessSetting.quantity_enable == 1 && (
                                    <td
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {value.quantity ? value.quantity : 0}{" "}
                                      {(_businessSetting.FreeItemQuantity ===
                                        1 ||
                                        _businessSetting.FreeItemQuantity ===
                                          "1") &&
                                      _free_quantity
                                        ? "  " + _free_quantity
                                        : ""}
                                    </td>
                                  )}

                                  {_businessSetting.unit_enabled == 1 && (
                                    <td
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {value.unitName ? value.unitName : "NA"}
                                    </td>
                                  )}

                                  {_businessSetting.price_per_unit_enabled === 1 && (
                                    <td
                                      className={_hideItemPrice}
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {value.rate}
                                    </td>
                                  )}

                                  {_businessSetting.discount_enable == 1 && (
                                    <td
                                      className={_hideItemPrice}
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {value.discountAmount}
                                      {/* +' ('+value.discountPer +'%)' */}
                                      {_businessSetting.discount_percentage_enable ===
                                        1 && (
                                        <span>({value.discountPer + "%"})</span>
                                      )}
                                    </td>
                                  )}
                                  <td
                                    className={_hideItemPrice}
                                    style={{
                                      fontFamily: "Arial",
                                      fontSize: "15px",
                                      textAlign: "center",
                                      color: "#333333",
                                      lineHeight: "24px",
                                      borderTop: "1px solid #2929af",
                                      borderBottom: "1px solid #2929af",
                                    }}
                                  >
                                    {_businessSetting.AmountWithDecimal == 1 &&
                                      numberFormat(value.gstAmount) + _gstRatio}
                                    {_businessSetting.AmountWithDecimal == 0 &&
                                      parseInt(value.gstAmount) + _gstRatio}
                                    {/* {value.gstAmount} */}
                                  </td>

                                  {_businessSetting.AdditionalCESS == 1 && (
                                    <td
                                      className={_hideItemPrice}
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {numberFormat(value.Additional_CESS)}
                                    </td>
                                  )}
                                  {_businessSetting.total_amount_enable ===
                                    1 && (
                                    <td
                                      className={_hideItemPrice}
                                      style={{
                                        fontFamily: "Arial",
                                        fontSize: "15px",
                                        textAlign: "center",
                                        color: "#333333",
                                        lineHeight: "24px",
                                        borderTop: "1px solid #2929af",
                                        borderBottom: "1px solid #2929af",
                                      }}
                                    >
                                      {_businessSetting.AmountWithDecimal ==
                                        1 &&
                                        numberFormat(value.totalAmount)
                                        /*numberFormat(
                                          parseFloat(value.rate) *
                                            parseFloat(value.quantity)
                                        )*/
                                      }
                                      {_businessSetting.AmountWithDecimal ==
                                      0 &&
                                      parseInt(value.totalAmount)
                                        /*parseInt(
                                          parseFloat(value.rate) *
                                            parseFloat(value.quantity)
                                        )*/
                                        /*{numberFormat(value.totalAmount)}*/
                                      }
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                            {_businessSetting.TotalItemQuantity == 1 && (
                              <tr
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_businessSetting.si_enable == 1 && <td></td>}
                                <td>
                                  <b>Total</b>
                                </td>
                                {_businessSetting.hsn_sac_enable == 1 && (
                                  <td></td>
                                )}
                                {this.state.Count > 0 &&
                                  this.state.CountItem > 0 && <td></td>}
                                {_businessSetting.quantity_enable == 1 && (
                                  <td>
                                    <b>{this.state.totalQty}</b>
                                  </td>
                                )}
                                {_businessSetting.unit_enabled == 1 && (
                                  <td></td>
                                )}
                                {_businessSetting.price_per_unit_enabled ===
                                  1 && <td></td>}
                                {_businessSetting.discount_enable == 1 && (
                                  <td>
                                    <b>{this.state.totalDiscount}</b>
                                  </td>
                                )}
                                {
                                  <td>
                                    <b>{this.state.gstAmount}</b>
                                  </td>
                                }
                                {_businessSetting.AdditionalCESS == 1 && (
                                  <td>
                                    <b>{this.state.totalAddinalCESSAmt}</b>
                                  </td>
                                )}
                                {_businessSetting.total_amount_enable == 1 && (
                                  <td>
                                    <b>{this.state.subtotal}</b>
                                  </td>
                                )}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td width="40%">
                        {_businessSetting.PrintDescription > 0 && (
                          <div
                            className={
                              this.state.description.length > 0 ? "" : "hide"
                            }
                            style={{ fontSize: "18px" }}
                          >
                            <br />
                            Description
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "15px",
                              }}
                            >
                              {this.state.description}
                            </p>
                          </div>
                        )}

                        <div style={{ fontSize: "18px" }}>
                          <br />
                          <b>Total Amount In Words</b>
                          <p
                            style={{
                              margin: "3px 0",
                              fontSize: "15px",
                              textTransform: "capitalize",
                            }}
                          >
                            {_totalAmtInChar}
                          </p>
                        </div>
                        {this.state.printTerms > 0 && (
                          <div
                            className={
                              this.state.printTermsMsg.length > 2 ? "" : "hide"
                            }
                            style={{ fontSize: "18px" }}
                          >
                            <br />
                            <b>Terms and Conditions</b>
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "15px",
                              }}
                            >
                              {this.state.printTermsMsg}
                            </p>
                          </div>
                        )}
                        {this.state.display_bank_name!="" && (
                          <div
                            style={{
                              marginTop: "30px",
                              fontSize: "18px",
                            }}
                          >
                            <span
                              style={{
                                textAlign: "center",
                                fontFamily: "Arial",
                                fontSize: "15px",
                                color: "#333333",
                                lineHeight: "24px",
                                textTransform: "uppercase",
                                fontWeight: "bold",
                              }}
                            >
                              Pay To -
                            </span>
                            <p
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Bank Name - {this.state.display_bank_name}
                              <br></br>
                              Bank Account number -{" "}
                              {this.state.display_bank_accountNo}
                              <br></br>
                              Account Holder's Name -{" "}
                              {this.state.display_bank_holderName}
                              <br></br>
                            </p>
                          </div>
                        )}
                        {_extraReceiveDiv}
                        {_extraDeleiveryDiv}
                        <div
                          style={{
                            float: "left !important",
                            margin: "0 0 0 0",
                          }}
                        >
                          <a
                            className="float-left"
                            style={{
                              flex: "1",
                              alignItems: "center",
                              width: "200px",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              src={this.state.logoMForExcel}
                              style={{
                                maxWidth: "150px",
                                maxHeight: "110px",
                                borderRadius: "10px",
                              }}
                            />
                          </a>
                        </div>
                      </td>

                      <td width="60%">{this.getTotalCalculation()}</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              
            <td colSpan={2}>
                <br />
                <br />
                <br />
                {_businessSetting.PrintAcknowledgement == 1 && (
                  <table>
                     <tr>
                    <td>
                      <div className="form-material open">
                        <p style={{
                            textAlign: "center",
                            fontFamily: "Arial",
                            fontSize: "15px",
                            color: "#333333",
                            lineHeight: "24px",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}>{this.state.customerCompanyName}</p>
                        <p style={{
                            textAlign: "center",
                            fontFamily: "Arial",
                            fontSize: "15px",
                            color: "#333333",
                            lineHeight: "24px",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}>{this.state.customerAddress}</p>
                      </div>
                    </td>
                    <td>
                        <div className="form-material open">
                          <p style={{
                              textAlign: "center",
                              fontFamily: "Arial",
                              fontSize: "15px",
                              color: "#333333",
                              lineHeight: "24px",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}>Acknowledgement</p>
                          <p style={{
                              textAlign: "center",
                              fontFamily: "Arial",
                              fontSize: "14px",
                              color: "#9f9fb9 !important",
                              lineHeight: "24px",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}>{this.state.companyName}
                          </p>
                          
                          <p> &nbsp;</p>
                          <p>Receiver's Seal & Sign</p>
                        </div>
                      </td>
                    <td>
                        <div className="form-material open">
                          <span style={{
                              textAlign: "center",
                              fontFamily: "Arial",
                              fontSize: "12px",
                              color: "#333333",
                              lineHeight: "24px",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}>Invoice No : {this.state.invoiceNo}</span><br/>
                          <span style={{
                              textAlign: "center",
                              fontFamily: "Arial",
                              fontSize: "12px",
                              color: "#333333",
                              lineHeight: "24px",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}>Invoice Date : {this.state.invoiceDate}</span><br/>
                          <span style={{
                              textAlign: "center",
                              fontFamily: "Arial",
                              fontSize: "12px",
                              color: "#333333",
                              lineHeight: "24px",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}>Invoice Amount : {this.state.totalAmount}</span>  <br/>
                        </div>
                      </td>
                    </tr> 
                </table>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }
  printPdfArea() {
    let _hideItemPrice = "";
    let _showTransportD = 0;
    if (
      (this.state.TransportDetailField1!=null &&
        this.state.TransportDetailField1!="") ||
      (this.state.TransportDetailField2!=null &&
        this.state.TransportDetailField2!="") ||
      (this.state.TransportDetailField3!=null &&
        this.state.TransportDetailField3!="") ||
      (this.state.TransportDetailField4!=null &&
        this.state.TransportDetailField4!="")
    ) {
      _showTransportD = 1;
    }
    let amt = this.state.totalAmtInChar;
    let _totalAmtInChar = "";
    if (amt > 0) {
      let returnData = new ToWords({
        converterOptions: { ignoreDecimal: false },
      }).convert(amt ? amt : 0);
      _totalAmtInChar = returnData + " Only";
    } else {
      _totalAmtInChar = "Zero";
    }
    let _companyAddressPrint = this.state.companyAddress
      ? this.state.companyAddress.length
      : 0;
    let _stateNamePrint = this.state.stateNamePrint
      ? this.state.stateNamePrint.length
      : 0;
    let _itemName = _businessSetting.item_name
      ? _businessSetting.item_name
      : "Item Name";
    let _si_no = "#";
    let _item_code = "Item code";
    let _hsn_sac = "HSN/SAC";
    let _quantity = "Qty";
    let _unit = "Unit";
    let _discount = "Discount";
    let _price_per_unit = "Rate";
    let _total_amount = "Amount";
    let _addditional_cess = _businessSetting.addditional_cess
      ? _businessSetting.addditional_cess
      : "Ad. CESS";

    if (_businessSetting.si_enable == 1) {
      _si_no = _businessSetting.si_no;
    }
    if (_businessSetting.item_code_enabled == 1) {
      _item_code = _businessSetting.item_code;
    }
    if (_businessSetting.hsn_sac_enable == 1) {
      _hsn_sac = _businessSetting.hsn_sac;
    }
    if (_businessSetting.quantity_enable == 1) {
      _quantity = _businessSetting.quantity;
    }
    if (_businessSetting.unit_enabled == 1) {
      _unit = _businessSetting.unit;
    }
    if (_businessSetting.price_per_unit_enabled == 1) {
      _price_per_unit = _businessSetting.price_per_unit;
    }
    if (_businessSetting.discount_enable == 1) {
      _discount = _businessSetting.discount;
    }
    if (_businessSetting.total_amount_enable == 1) {
      _total_amount = _businessSetting.total_amount;
    }
    console.log("PartyCompanyName", _businessSetting.PartyCompanyName);

    if (this.state.isLoaded == true) {
      return (
        <html style={{ marginTop: "15px" }}>
          <head>
            <title>Recipt</title>
          </head>
          <div id="template">
            {_businessSetting.PrintOriginalDuplicate == 1 && (
              <div
                style={{
                  flex: "1",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  lineHeight: "20px",
                  textAlign: "right",
                }}
              >
                {this.state._type!="PAYMENT-IN" && (
                  <ul>
                    <li
                      style={{
                        display: "inline",
                        listStyleType: "none",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Original for Recipient{" "}
                      </span>
                      <input
                        type="checkbox"
                        id="originalRecipient"
                        name="originalRecipient"
                        value="1"
                        checked={this.state.checkOriginal}
                        disabled={this.state.checkOriginalDisable}
                      />
                    </li>
                    <li
                      style={{
                        display: "inline",
                        listStyleType: "none",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Duplicate for Transporter{" "}
                      </span>
                      <input
                        type="checkbox"
                        id="duplicateTransporter"
                        name="duplicateTransporter"
                        value="1"
                        checked={this.state.checkDuplicate}
                        disabled={this.state.checkDuplicateDisable}
                      />
                    </li>
                    <li
                      style={{
                        display: "inline",
                        listStyleType: "none",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Triplicate for Supplier{" "}
                      </span>
                      <input
                        type="checkbox"
                        id="triplicateSupplier"
                        name="triplicateSupplier"
                        value="1"
                        checked={this.state.checkTriplicate}
                        disabled={this.state.checkTriplicateDisable}
                      />
                    </li>
                  </ul>
                )}
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                id="logo"
                style={{
                  flex: "1",
                  alignItems: "center",
                  width: "250px",
                  height: "150px",
                  marginTop: "10px",
                }}
                onClick="_captureLogoButton()"
              >
                {/* {_imageBaseDecode} */}
                {_businessSetting.CompanyLogo == 1 && (
                  <img
                    src={_imageBaseDecode}
                    style={{
                      maxWidth: "250px",
                      maxHeight: "150px",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </div>
              {(_businessSetting.TransportationDetailsField1Enable == 1 ||
                _businessSetting.TransportationDetailsField2Enable == 1 ||
                _businessSetting.TransportationDetailsField3Enable == 1 ||
                _businessSetting.TransportationDetailsField4Enable == 1 ||
                _businessSetting.TransportationDetailsField5Enable == 1 ||
                _businessSetting.TransportationDetailsField5Enable == 1) &&
                _showTransportD == 1 && (
                  <div
                    style={{
                      flex: "1",
                      fontFamily: "Arial",
                      fontSize: "15px",
                      lineHeight: "20px",
                      textAlign: "right",
                      marginRight: "50px",
                    }}
                  >
                    <h5
                      style={{
                        fontWeight: "bold",
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Transportation Details
                    </h5>

                    {_businessSetting.TransportationDetailsField1Enable == 1 &&
                      this.state.TransportDetailField1!=null &&
                      this.state.TransportDetailField1!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField1Name} :{" "}
                          {this.state.TransportDetailField1}
                        </p>
                      )}

                    {_businessSetting.TransportationDetailsField2Enable == 1 &&
                      this.state.TransportDetailField2!=null &&
                      this.state.TransportDetailField2!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField2Name} :{" "}
                          {this.state.TransportDetailField2}
                        </p>
                      )}
                    {_businessSetting.TransportationDetailsField3Enable == 1 &&
                      this.state.TransportDetailField3!=null &&
                      this.state.TransportDetailField3!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField3Name} :{" "}
                          {this.state.TransportDetailField3}
                        </p>
                      )}
                    {_businessSetting.TransportationDetailsField4Enable == 1 &&
                      this.state.TransportDetailField4!=null &&
                      this.state.TransportDetailField4!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField4Name} :{" "}
                          {this.state.TransportDetailField4}
                        </p>
                      )}
                    {_businessSetting.TransportationDetailsField5Enable ==
                      1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.TransportationDetailsField5Name} :{" "}
                        {this.state.TransportDetailField5}
                      </p>
                    )}
                    {_businessSetting.TransportationDetailsField6Enable ==
                      1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.TransportationDetailsField6Name} :{" "}
                        {this.state.TransportDetailField6}
                      </p>
                    )}
                  </div>
                )}
              <div
                style={{
                  flex: "1",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  lineHeight: "20px",
                  textAlign: "right",
                }}
              >
                {_businessSetting.SateOfSupply == 1 &&
                  this.state.stateNamePrint!="Select State" && (
                    <p
                      style={{
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {_stateNamePrint > 2
                        ? "State Of Supply : " + this.state.stateNamePrint
                        : ""}
                    </p>
                  )}
                {_businessSetting.PartyCompanyName == 1 && (
                  <p
                    style={{
                      fontWeight: "bold",
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    Company Name : {this.state.companyName}
                  </p>
                )}

                {_businessSetting.Address == 1 && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {_companyAddressPrint > 2
                      ? "Company Address : " + this.state.companyAddress
                      : ""}
                  </p>
                )}
                {_businessSetting.PhoneNumber == 1 && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.companyPhone.length > 2
                      ? "Company Phone no. : " + this.state.companyPhone
                      : ""}
                  </p>
                )}

                {_businessSetting.Email == 1 && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.companyEmail.length > 2
                      ? "Company Email : " + this.state.companyEmail
                      : ""}
                  </p>
                )}

                {this.state.firm_additional_field1!="" && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.firm_additional_field1} :{" "}
                    {this.state.firm_additional_value1}
                  </p>
                )}
                {this.state.firm_additional_field2!="" && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.firm_additional_field2} :{" "}
                    {this.state.firm_additional_value2}
                  </p>
                )}

                {_businessSetting.GSTINSale == 1 && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.companyGstin!=""
                      ? "GSTIN : " + this.state.companyGstin
                      : ""}
                  </p>
                )}
                {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                  <div id="additionFields">
                    {_businessSetting.additional_field_check1 == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_name1} :{" "}
                        {this.state.additional_value_1}
                      </p>
                    )}
                    {_businessSetting.additional_field_check2 == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_name2} :{" "}
                        {this.state.additional_value_2}
                      </p>
                    )}
                    {_businessSetting.additional_field_check3 == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_name3} :{" "}
                        {this.state.additional_value_3}
                      </p>
                    )}
                    {_businessSetting.additional_field_date_field_check ==
                      1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_date_field_name} :{" "}
                        {this.state.additional_value_date}
                      </p>
                    )}
                  </div>
                )}
                {_businessSetting.Tran_CustomerPO ? (
                  <div>
                    {this.state.poDate!=null &&
                      this.state.poNumber!="null" &&
                      this.state.poNumber!="Invalid date" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.poDate!=""
                            ? "PO Date : " + this.state.poDate
                            : ""}
                        </p>
                      )}
                    {this.state.poNumber!=null &&
                      this.state.poNumber!="null" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.poNumber!=""
                            ? "PO Number : " + this.state.poNumber
                            : ""}
                        </p>
                      )}
                  </div>
                ) : (
                  <div />
                )}
                {_businessSetting.DueDatesPaymentTerms ? (
                  <div>
                    {/* {this.state.dueOnDate!=null ||
                        this.state.dueOnDate!="null" ||
                        this.state.dueOnDate!="Invalid date"  || this.state.dueOnDate!=0 || } */}
                    {this.state.dueOnDate.length > 6 &&
                      this.state.dueTerms > 0 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.dueOnDate!=""
                            ? "Due Date : " + this.state.dueOnDate
                            : ""}
                        </p>
                      )}
                  </div>
                ) : (
                  <div />
                )}

                {this.state.E_Way_Bill_No!=null &&
                  this.state.E_Way_Bill_No!="null" &&
                  this.state.EWayBillNo == 1 && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.E_Way_Bill_No!=""
                        ? "E-Way Bill Number : " + this.state.E_Way_Bill_No
                        : ""}
                    </p>
                  )}
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontFamily: "Arial",
                padding: "10px 0",
                fontWeight: "bold",
                color: "#9f9fdf",
              }}
            >
              {this.state.titleTxt}
            </div>
            <div>
              <table
                cellPadding="0"
                cellSpacing="0"
                border="0"
                width="100%"
                style={{ marginBottom: "40px" }}
              >
                <tr>
                  <td width="49%" valign="top">
                    <h6
                      style={{
                        background: "#9f9fdf",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "15px",
                        fontFamily: "Arial",
                        margin: "5px 0",
                      }}
                    >
                      {this.state.billTxt}
                    </h6>

                    <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          margin: "3px 0",
                          color: "black",
                          marginBottom: "5px",
                        }}
                    >
                      {this.state.customerBillingName}
                    </p>
                    {(this.state.customerAddress!=null && this.state.customerAddress!='' &&
                      this.state.customerAddress!="null") && (
                        <p
                          style={{
                            fontFamily: "Arial",
                            fontSize: "15px",
                            lineHeight: "20px",
                            margin: "0 0",
                            color: "black",
                            marginBottom: "5px",
                          }}
                        >
                          {this.state.customerAddress}
                        </p>
                      )}

                    {/* {this.state.customerBillingName && (
                        <p
                            style={{
                              fontFamily: "Arial",
                              fontSize: "15px",
                              lineHeight: "20px",
                              margin: "0 0",
                              color: "black",
                              marginBottom: "5px",
                            }}
                        >
                          {this.state.customerBillingName}
                        </p>
                    )} */}
                    <p
                      style={{
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        margin: "0 0",
                        color: "black",
                      }}
                    >
                      {this.state.customerPhone}
                    </p>

                    {this.state.PartyAddField1ShowPrint == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.party_add_field_value1!=null &&
                          this.state.party_add_field_value1!="" &&
                          this.state.party_add_field_key1!=null &&
                          this.state.party_add_field_key1!="" && (
                            <span>
                              {this.state.party_add_field_value1!=""
                                ? this.state.party_add_field_key1 +
                                  " : " +
                                  this.state.party_add_field_value1
                                : ""}
                            </span>
                          )}
                      </p>
                    )}
                    {this.state.PartyAddField2ShowPrint == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.party_add_field_value2!=null &&
                          this.state.party_add_field_value2!="" &&
                          this.state.party_add_field_key2!=null &&
                          this.state.party_add_field_key2!="" && (
                            <span>
                              {this.state.party_add_field_value2!=""
                                ? this.state.party_add_field_key2 +
                                  " : " +
                                  this.state.party_add_field_value2
                                : ""}
                            </span>
                          )}
                      </p>
                    )}
                    {this.state.PartyAddField3ShowPrint == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.party_add_field_value3!=null &&
                          this.state.party_add_field_value3!="" &&
                          this.state.party_add_field_key3!=null &&
                          this.state.party_add_field_key3!="" && (
                            <span>
                              {this.state.party_add_field_value3!=""
                                ? this.state.party_add_field_key3 +
                                  " : " +
                                  this.state.party_add_field_value3
                                : ""}
                            </span>
                          )}
                      </p>
                    )}
                  </td>
                  <td width="2%" valign="top">
                    &nbsp;
                  </td>
                  <td width="50%" valign="top">
                    <h6
                        style={{
                          background: "#9f9fdf",
                          color: "white",
                          textAlign: "center",
                          fontSize: "15px",
                          fontFamily: "Arial",
                          margin: "5px 0",
                        }}
                    >
                      {this.state._invNo}
                    </h6>
                    {(this.state._type == 'CREDIT NOTE' || this.state._type == 'DEBIT NOTE') ?
                        <p
                            style={{
                              fontFamily: "Arial",
                              fontSize: "15px",
                              lineHeight: "20px",
                              textAlign: "center",
                              margin: "0 0",
                              color: "black",
                            }}
                        >
                          {this.state.prefixId > 0 &&
                          this.state.prefixName!="None" &&
                          this.state.prefixName!="none" &&
                          "(" + this.state.prefixName + ") "}
                          {this.state.invoiceRtnNo}
                        </p> :
                        <p
                            style={{
                              fontFamily: "Arial",
                              fontSize: "15px",
                              lineHeight: "20px",
                              textAlign: "center",
                              margin: "0 0",
                              color: "black",
                            }}
                        >
                          {this.state.prefixId > 0 &&
                          this.state.prefixName!="None" &&
                          this.state.prefixName!="none" &&
                          "(" + this.state.prefixName + ") "}
                          {this.state.invoiceNo}
                        </p>
                    }

                    <h6
                        style={{
                          background: "#9f9fdf",
                          color: "#FFFFFF",
                          textAlign: "center",
                          fontSize: "15px",
                          fontFamily: "Arial",
                          margin: "5px 0",
                        }}
                    >
                      Date.
                    </h6>
                    <p
                        style={{
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "center",
                          margin: "0 0",
                          color: "black",
                        }}
                    >
                      {_pdfDate}
                    </p>
                    {
                      (this.state._type == 'CREDIT NOTE' || this.state._type == 'DEBIT NOTE') ?
                          <></> :
                          <>
                            <h6
                                style={{
                                  background: "#9f9fdf",
                                  color: "#FFFFFF",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  fontFamily: "Arial",
                                  margin: "5px 0",
                                }}
                            >
                              Due Date.
                            </h6>
                            <p
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  lineHeight: "20px",
                                  textAlign: "center",
                                  margin: "0 0",
                                  color: "black",
                                }}
                            >
                              {this.state.dueDate}
                            </p>
                          </>
                    }
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" width="100%">
                    <table
                      width="100%"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{ marginTop: "10px" }}
                    >
                      <tr style={{ backgroundColor: "#9f9fdf" }}>
                        {_businessSetting.si_enable == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_si_no}
                          </th>
                        )}

                        <th
                          style={{
                            color: "#FFFFFF",
                            textAlign: "center",
                            //fontSize: '15px',
                            fontFamily: "Arial",
                            //lineHeight: '24px',
                            padding: "3px",
                          }}
                        >
                          {_itemName}
                        </th>

                        {_businessSetting.hsn_sac_enable == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_hsn_sac}
                          </th>
                        )}
                        {this.state.Count > 0 && this.state.CountItem > 0 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            Count
                          </th>
                        )}

                        {_businessSetting.quantity_enable == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_quantity}
                          </th>
                        )}
                        {_businessSetting.unit_enabled == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_unit}
                          </th>
                        )}

                        {_businessSetting.price_per_unit_enabled === 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_price_per_unit}
                          </th>
                        )}

                        {_businessSetting.discount_enable == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_discount}
                          </th>
                        )}
                        <th
                          className={_hideItemPrice}
                          style={{
                            color: "#FFFFFF",
                            textAlign: "center",
                            //fontSize: '15px',
                            fontFamily: "Arial",
                            //lineHeight: '24px',
                            padding: "3px",
                          }}
                        >
                          GST
                        </th>

                        {_businessSetting.AdditionalCESS == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_addditional_cess}
                          </th>
                        )}

                        {_businessSetting.total_amount_enable == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_total_amount}
                          </th>
                        )}
                      </tr>
                      {this.state.items.map((value, index) => {
                        console.log("==DATA==", JSON.stringify(value));
                        let _i = parseInt(index) + 1;
                        let _free_quantity = "";
                        if (
                          _businessSetting.FreeItemQuantity == 1 &&
                          value.free_quantity > 0
                        ) {
                          _free_quantity = " + " + value.free_quantity;
                        }
                        let _gstRatio = "";
                        if (_businessSetting.tax_percentage_enable == 1) {
                          _gstRatio = "(" + value.gstPerRatio + " %)";
                        }
                        let _batchData = "";
                        let _batchModelData = "";
                        if (Object.keys(value.itemBatchNoAllData).length > 1) {
                          if (value.itemBatchNoAllData.batch_no!="") {
                            _batchData =
                              _businessSetting.BatchNoTxt +
                              " : " +
                              value.itemBatchNoAllData.batch_no;
                          }
                          if (value.itemBatchNoAllData.model_no!="") {
                            _batchModelData =
                              _businessSetting.ModelNoTxt +
                              " : " +
                              value.itemBatchNoAllData.model_no;
                          }
                        }
                        let _serialNoData = "";
                        let _serialAdd1Data = "";
                        let _serialAdd2Data = "";
                        if (Object.keys(value.itemSerialNoAllData).length > 1) {
                          if (value.itemSerialNoAllData.serial_no!="") {
                            _serialNoData =
                              _businessSetting.SerialNoTxt +
                              " : " +
                              value.itemSerialNoAllData.serial_no;
                          }
                          if (
                            value.itemSerialNoAllData
                              .serialNoAdditionalValue1!=""
                          ) {
                            _serialAdd1Data =
                              _businessSetting.SerialNoAdditionalLabel1 +
                              " : " +
                              value.itemSerialNoAllData
                                .serialNoAdditionalValue1;
                          }
                          if (
                            value.itemSerialNoAllData
                              .serialNoAdditionalValue2!=""
                          ) {
                            _serialAdd2Data =
                              _businessSetting.SerialNoAdditionalLabel2 +
                              " : " +
                              value.itemSerialNoAllData
                                .serialNoAdditionalValue2;
                          }
                        }

                        return (
                          <tr>
                            {_businessSetting.si_enable == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_i}
                              </td>
                            )}
                            <td
                              style={{
                                fontFamily: "Arial",
                                fontSize: "15px",
                                textAlign: "center",
                                color: "#333333",
                                lineHeight: "24px",
                                borderTop: "1px solid #2929af",
                                borderBottom: "1px solid #2929af",
                              }}
                            >
                              <table width="100%">
                                <tr>
                                  <td>
                                    <span>{value.item_name}</span>
                                  </td>
                                </tr>
                                {value.itemBatchNoAllData.map(
                                  (batchDataLoop, index) => {
                                    return (
                                      <div>
                                        {batchDataLoop.batch_no!="" && (
                                          <tr>
                                            {_businessSetting.batch_tracking_enable ===
                                              1 && (
                                              <td>
                                                <span>
                                                  {_businessSetting.BatchNoTxt}{" "}
                                                  : {batchDataLoop.batch_no}
                                                </span>
                                              </td>
                                            )}
                                          </tr>
                                        )}
                                      </div>
                                    );
                                  }
                                )}

                                {value.itemSerialNoAllData.map(
                                  (serialDataLoop, index) => {
                                    return (
                                      <div>
                                        {serialDataLoop.serial_no!="" && (
                                          <tr>
                                            {_businessSetting.serial_tracking_enabled ===
                                              1 && (
                                              <td>
                                                {serialDataLoop.serial_no !=
                                                  "" && (
                                                  <span>
                                                    {" "}
                                                    {
                                                      _businessSetting.SerialNoTxt
                                                    }{" "}
                                                    : {serialDataLoop.serial_no}
                                                  </span>
                                                )}
                                                <br />
                                                {_businessSetting.SerialNoAdditionalLabel1 !==
                                                  "" && (
                                                  <span>
                                                    {
                                                      _businessSetting.SerialNoAdditionalLabel1
                                                    }{" "}
                                                    :{" "}
                                                    {
                                                      serialDataLoop.serialNoAdditionalValue1
                                                    }
                                                  </span>
                                                )}
                                                <br />
                                                {_businessSetting.SerialNoAdditionalLabel2 !==
                                                  "" && (
                                                  <span>
                                                    {
                                                      _businessSetting.SerialNoAdditionalLabel2
                                                    }{" "}
                                                    :{" "}
                                                    {
                                                      serialDataLoop.serialNoAdditionalValue2
                                                    }
                                                  </span>
                                                )}
                                                <br />
                                              </td>
                                            )}
                                          </tr>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </table>
                              {/* {value.item_name}<br/>
                                                                <span>{_batchData}</span><br/>
                                                                <span>{_batchModelData}</span><br/>
                                                                <span>{_serialNoData}</span><br/>
                                                                <span>{_serialAdd1Data}</span><br/>
                                                                <span>{_serialAdd2Data}</span> */}
                            </td>

                            {_businessSetting.hsn_sac_enable == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.hsn_sac}
                              </td>
                            )}

                            {this.state.Count == 1 &&
                              this.state.CountItem == 1 && (
                                <td
                                  style={{
                                    fontFamily: "Arial",
                                    fontSize: "15px",
                                    textAlign: "center",
                                    color: "#333333",
                                    lineHeight: "24px",
                                    borderTop: "1px solid #2929af",
                                    borderBottom: "1px solid #2929af",
                                  }}
                                >
                                  {value.trans_count}
                                </td>
                              )}
                            {_businessSetting.quantity_enable == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.quantity ? value.quantity : 0}{" "}
                                {(_businessSetting.FreeItemQuantity == 1 ||
                                  _businessSetting.FreeItemQuantity == "1") &&
                                _free_quantity
                                  ? "  " + _free_quantity
                                  : ""}
                              </td>
                            )}

                            {_businessSetting.unit_enabled == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.unitName ? value.unitName : "NA"}
                              </td>
                            )}

                            {_businessSetting.price_per_unit_enabled == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.rate}
                              </td>
                            )}

                            {_businessSetting.discount_enable == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.discountAmount}
                                {/* +' ('+value.discountPer +'%)' */}
                                {_businessSetting.discount_percentage_enable ===
                                  1 && <span>({value.discountPer + "%"})</span>}
                              </td>
                            )}
                            <td
                              className={_hideItemPrice}
                              style={{
                                fontFamily: "Arial",
                                fontSize: "15px",
                                textAlign: "center",
                                color: "#333333",
                                lineHeight: "24px",
                                borderTop: "1px solid #2929af",
                                borderBottom: "1px solid #2929af",
                              }}
                            >
                              {_businessSetting.AmountWithDecimal == 1 &&
                                numberFormat(value.gstAmount) + _gstRatio}
                              {_businessSetting.AmountWithDecimal == 0 &&
                                parseInt(value.gstAmount) + _gstRatio}
                              {/* {value.gstAmount} */}
                            </td>

                            {_businessSetting.AdditionalCESS == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {numberFormat(value.Additional_CESS)}
                              </td>
                            )}
                            {_businessSetting.total_amount_enable == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_businessSetting.AmountWithDecimal == 1 &&
                                  numberFormat(value.totalAmount)
                                  /*numberFormat(
                                    parseFloat(value.rate) *
                                      parseFloat(value.quantity)
                                  )*/
                                }
                                {_businessSetting.AmountWithDecimal == 0 &&
                                  parseInt(value.totalAmount)
                                  /*parseInt(
                                    parseFloat(value.rate) *
                                      parseFloat(value.quantity)
                                  )*/
                                }
                              </td>
                            )}
                          </tr>
                        );
                      })}
                      {_businessSetting.TotalItemQuantity == 1 && (
                        <tr
                          style={{
                            fontFamily: "Arial",
                            fontSize: "15px",
                            textAlign: "center",
                            color: "#333333",
                            lineHeight: "24px",
                            borderTop: "1px solid #2929af",
                            borderBottom: "1px solid #2929af",
                          }}
                        >
                          {_businessSetting.si_enable == 1 && <td></td>}
                          <td>
                            <b>Total</b>
                          </td>
                          {_businessSetting.hsn_sac_enable == 1 && <td></td>}
                          {this.state.Count > 0 && this.state.CountItem > 0 && (
                            <td></td>
                          )}
                          {_businessSetting.quantity_enable == 1 && (
                            <td>
                              <b>{this.state.totalQty}</b>
                            </td>
                          )}
                          {_businessSetting.unit_enabled == 1 && <td></td>}
                          {_businessSetting.price_per_unit_enabled == 1 && (
                            <td></td>
                          )}
                          {_businessSetting.discount_enable == 1 && (
                            <td>
                              <b>{this.state.totalDiscount}</b>
                            </td>
                          )}
                          {
                            <td>
                              <b>{this.state.gstAmount}</b>
                            </td>
                          }
                          {_businessSetting.AdditionalCESS == 1 && (
                            <td>
                              <b>{this.state.totalAddinalCESSAmt}</b>
                            </td>
                          )}
                          {_businessSetting.total_amount_enable == 1 && (
                            <td>
                              <b>{this.state.subtotal}</b>
                            </td>
                          )}
                        </tr>
                      )}
                      {/*{_businessSetting.TotalItemQuantity == 1 &&
                                            <tr style={{
                                                fontFamily: 'Arial',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                color: '#333333',
                                                lineHeight: '24px',
                                                borderTop: '1px solid #2929af',
                                                borderBottom: '1px solid #2929af'
                                            }}>
                                                {_businessSetting.hsn_sac_enable == 1 && <td></td>}
                                                <td><b>Total</b></td>
                                                {_businessSetting.quantity_enable == 1 &&
                                                <td></td>
                                                }
                                                {_businessSetting.quantity_enable == 1 &&
                                                <td><b>{this.state.totalQty}</b></td>
                                                }
                                                {_businessSetting.unit_enabled == 1 && <td></td>}

                                                {_businessSetting.discount_enable == 1 &&
                                                <td></td>
                                                }

                                                {_businessSetting.discount_enable == 1 &&
                                                <td><b>{this.state.totalDiscount}</b></td>
                                                }
                                                <td><b>{this.state.gstAmount}</b></td>


                                                {_businessSetting.AdditionalCESS == 1 &&
                                                <td></td>
                                                }
                                                {_businessSetting.total_amount_enable == 1 &&
                                                <td><b>{this.state.subtotal}</b></td>
                                                }
                                            </tr>
                                            }*/}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <table cellPadding="0" cellSpacing="0" width="100%">
                      {_businessSetting.PrintDescription > 0 && (
                        <tr>
                          <td colSpan="2" height="10">
                            <div
                              className={
                                this.state.description.length > 0 ? "" : "hide"
                              }
                            >
                              <br />
                              Description
                              <p
                                style={{
                                  margin: "3px 0",
                                  fontSize: "15px",
                                }}
                              >
                                {this.state.description}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td colSpan="2" height="10">
                          <div>
                            <br />
                            <b>Total Amount In Words</b>
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                            >
                              {_totalAmtInChar}
                            </p>
                          </div>
                        </td>
                      </tr>

                      {this.state.printTerms > 0 && (
                        <tr>
                          <td colSpan="2" height="10">
                            <div
                              className={
                                this.state.printTermsMsg.length > 2
                                  ? ""
                                  : "hide"
                              }
                            >
                              <br />
                              <b>Terms and Conditions</b>
                              <p
                                style={{
                                  margin: "3px 0",
                                  fontSize: "15px",
                                }}
                              >
                                {this.state.printTermsMsg}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="2" height="10">
                          {this.state.display_bank_name!="" && (
                            <div
                              style={{
                                marginTop: "30px",
                              }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Pay To -
                              </span>
                              <p
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Bank Name - {this.state.display_bank_name}
                                <br></br>
                                Bank Account number -{" "}
                                {this.state.display_bank_accountNo}
                                <br></br>
                                Account Holder's Name -{" "}
                                {this.state.display_bank_holderName}
                                <br></br>
                              </p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          {_extraReceiveDiv}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          {_extraDeleiveryDiv}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                          <div
                            style={{
                              float: "left !important",
                              margin: "0 0 0 0",
                            }}
                          >
                            <a
                              className="float-left"
                              style={{
                                flex: "1",
                                alignItems: "center",
                                width: "200px",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                src={_vLogoBaseDecode}
                                style={{
                                  maxWidth: "250px",
                                  maxHeight: "110px",
                                  borderRadius: "10px",
                                }}
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td colSpan="2" width="60%">
                    {this.getTotalCalculation()}
                  </td>
                </tr>
              </table>
            </div>
            
            {_businessSetting.PrintAcknowledgement == 1 && (
            <div className="claerfix"><hr/></div>
            )}
            {_businessSetting.PrintAcknowledgement == 1 && (
            <div className="form-group row">
              <div className="col-4">
                  <div className="form-material open">
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>{this.state.customerCompanyName}</p>
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>{this.state.customerAddress}</p>
                  </div>
                </div>
              <div className="col-4">
                  <div className="form-material open">
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Acknowledgement</p>
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "14px",
                        color: "#9f9fb9 !important",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>{this.state.companyName}
                    </p>
                    
                    <p> &nbsp;</p>
                    <p>Receiver's Seal & Sign</p>
                  </div>
                </div>
              <div className="col-4">
                  <div className="form-material open">
                    <span style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Invoice No : {this.state.invoiceNo}</span><br/>
                    <span style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Invoice Date : {this.state.invoiceDate}</span><br/>
                    <span style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Invoice Amount : {this.state.totalAmount}</span>  <br/>
                  </div>
                </div>
            </div>
            )}
            {/* {_businessSetting.PrintAcknowledgement == 1 && (
              <div>
                <span
                  style={{
                    textAlign: "center",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Acknowledgement
                </span>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {this.state.companyName}
                </p>
                <p> &nbsp;</p>
                <p>Receiver's Seal & Sign</p>
              </div>
            )} */}
            {/* <div style={footer}>
              <p style={{float:'left'}}>https://vyavsay.in</p>
              <img src={_imageLogoBaseDecode} style={{float:'right',width: '105px'}}/>
            </div> */}
          </div>
        </html>
      );
    }
  }

  closeWindow() {
    window.close();
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
      this.convertImageUrlToBase64(_companyLogo, function (url) {});
      this.convertMainImageUrlToBase64(_mainLogoBaseDecode, function (url) {});
    }

    return (
      <React.Fragment>
        {/* <a className="dropdown-item" href="javascript:void(0)" data-id={this.props.transactionId} onClick={this.clickTogenratePDF.bind(this,this.props.transactionId)}>
                    Download PDF {this.props.transactionId}
                </a> */}

        {/* <div className="content">
                    CLick Pdf Button
                    <QuickPDF />
                </div> */}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_PDF}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          id="modalAdjustPDFDownload"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Order Preview</h3>
                  <div className="block-options">
                    {/* <button type="button" className="btn-block-option" onClick={this.closeWindow.bind()}>
                                            <i className="si si-close"></i>
                                        </button> */}
                  </div>
                </div>
                <div
                  className="block-content"
                  style={{
                    background: "#fff",
                    border: "2px solid #cacaca",
                    height: "97%",
                    padding: "10px",
                  }}
                >
                  <div
                    className="block-content"
                    id="pdfFormatReceipt"
                    ref={(el) => (this.componentRef = el)}
                  >
                    {this.printDemo()}
                    {this.printPdfArea()}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" >Close</button> */}

                <ReactHTMLTableToExcel
                  className="btn btn-alt-secondary"
                  table="exportxls"
                  filename="exportxls"
                  sheet="sheet"
                  buttonText="Download as XLS"
                />

                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.clickToGenratePDF.bind(
                    this,
                    this.props.transactionId
                  )}
                >
                  <i className="fa fa-file-pdf-o"></i> Download PDF
                </button>

                <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn btn-alt-secondary">
                      <i className="fa fa-print"></i> View Print
                    </button>
                  )}
                  content={() => this.componentRef}
                />

                <a
                  type="button"
                  className="btn btn-alt-danger"
                  href={this.state.returnUrl}
                >
                  <i className="fa fa-times"></i>Close
                </a>
              </div>
              <div id="global_loader" className={_loader}>
                <div className="gl-ldr-cld">
                  <div className="gl-ldr-ctr">
                    <div className="gl-loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}
      </React.Fragment>
    );
  }
}
