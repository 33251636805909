import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import {userSessionManage} from '../../../service/userLocalStorage';
import 'react-confirm-alert/src/react-confirm-alert.css';

let _loader = 'glb-ldr-prt active';
let vsSession = localStorage.getItem('vs_UserSession');
let _adminId = '0';
let _loginName = '';
let _loginEmail = '';
let _loginNumner = '';
let _loginCounCode = '';
let _profileLogo = '';
if(vsSession!=undefined || vsSession!=null)
{
    vsSession = JSON.parse(vsSession);
    _adminId = (vsSession.loginId > 0) ? vsSession.loginId : '0';
    _loginName = vsSession.loginName ? vsSession.loginName : 'Business Admin / User';
    _loginEmail = vsSession.loginEmail ? vsSession.loginEmail : 'admin@vyavsay.com';
    _loginNumner = vsSession.loginNumner ? vsSession.loginNumner : '9755299999';
    _loginCounCode = vsSession.loginCounCode ? vsSession.loginCounCode : '+91';
    _profileLogo = vsSession ? vsSession.profile_logo : '';
}
let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}

export default class profilePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Update Profile',
            isLoaded : true,
            loginName : _loginName,
            loginEmail : _loginEmail,
            loginNumner : _loginNumner,
            loginCounCode : _loginCounCode,
            logo : '',
            currentPassword : '',
            newPassword : '',
            confirmPassword : '',
            
            _countryLang:'',
            _countryReg:'iti__hide',
            _countryReg_O:'iti__hide',
            _countryFlag:'',
            _countryName:'',
            _countryPh:'',
            _countryId:'0',
            countryArray : [],


            errors: {
                loginName : '',
                loginEmail : '',
                loginNumner : '',
                loginCounCode : '',
                currentPassword : '',
                newPassword : '',
                confirmPassword : ''
            }
        }
    }

    async componentDidMount ()
    {
        let _countryCode = 'IN';
        
        //'http://api.hostip.info'
        //'https://ipinfo.io/'
        PostData('http://ip-api.com/json','','GET').then(response => {
            let responseJson = response;
            _countryCode = responseJson.countryCode;
            userSessionManage(localStorage,responseJson,'countrySession');
        })
        .catch(error => this.setState({ error, isLoaded: false }));
        
        let _urlCountryData = global.adminCountryList;
        let countryData = await PostData(_urlCountryData,'','GET');
        let _conLang,_conFlag,_conName,_conPh,_conId = '';
        countryData.response.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            let _imgPath = global.FLAG_URL+_name+'.png';

            if(_countryCode == conty.sortname){
                _conLang = conty.sortname;
                _conFlag = _imgPath;
                _conName=conty.name;
                _conPh=conty.phonecode;
                _conId=conty.id;
            }
        });
       
        if(this.state.loginCounCode.length > 2){
            _conPh = this.state.loginCounCode;
        }
        this.setState({countryArray:countryData.response,_countryId:_conId,_countryLang:_conLang,_countryFlag:_conFlag,_countryName:_conName,_countryPh:_conPh,isLoaded:true});
    }

    
    selectCounties(code,flag,name,e){
        
        this.setState({
            _countryReg:'iti__hide',
            _countryFlag:flag,
            _countryName:name,
            _countryPh:code,
        })
    }
    
    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';
                let _phonecode = conty.phonecode;
                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{_phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'loginName': 
                errors.loginName = value.length < 3 ? '*Name must be 3 characters long!' : '';
                this.setState({loginName:value}); 
            break;
            case 'loginEmail': 
                errors.loginEmail = value.length < 3 ? '*Enter Valid Email Id!' : '';
                this.setState({loginEmail:value}); 
            break;
            case 'loginNumner': 
                errors.loginNumner = value.length < 8 ? '*Enter Valid Numner!' : '';
                errors.loginNumner = value.length > 12 ? '*Enter Valid Numner!' : '';
                this.setState({loginNumner:value}); 
            break;

            case 'currentPassword': 
                errors.currentPassword = value.length < 5 ? '*Old Password must be 5 characters long!' : '';
                this.setState({currentPassword:value}); 
            break;
            case 'newPassword': 
                errors.newPassword = value.length < 5 ? '*New Password must be 5 characters long!' : '';
                this.setState({newPassword:value}); 
            break;
            case 'confirmPassword': 
                errors.confirmPassword = value.length < 5 ? '*Confirm Password must be 5 characters long!' : '';
                if(value.length > 4){
                    errors.confirmPassword = value!=this.state.newPassword ? '*Confirm Password not match!' : '';
                }
                this.setState({confirmPassword:value}); 
            break;
            default:
            break;
        }
    }

    clickToManageProfileInfo = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.loginName == '')
        {
            _isError = true;
            errors['loginName'] = "*Please Enter Name";
            this.setState({errors: errors});
        }
        if(this.state.loginEmail == '')
        {
            _isError = true;
            errors['loginEmail'] = "*Please Enter Email-Id";
            this.setState({errors: errors});
        }
        if(this.state.loginNumner == '')
        {
            _isError = true;
            errors['loginNumner'] = "*Please Enter Number";
            this.setState({errors: errors});
        }

        if(_isError == false){
            
            const requestData = JSON.stringify({
                loginName: this.state.loginName,
                emailId :this.state.loginEmail,
                loginNumner :this.state.loginNumner,
                loginNumnerCountry :this.state._countryPh,
                loginId: _adminId,
                loginType: 'Admin',
            });
    
            PostData(global.adminProfileUpdate, requestData,'POST').then((result) => {
                if(result.success == true)
                {
                    sendNotification("Success Message 👍",result.message,"success");
                    // setTimeout(
                    //     () => {
                    //         window.location.href = global.webUrl+'administrator/dashboard/';
                    //     }, 
                    //     Math.floor(Math.random() * 5000) + 1
                    // )
                    let _updateLocal = vsSession;
                    _updateLocal.loginEmail = this.state.loginEmail;
                    _updateLocal.loginNumner = this.state.loginNumner;
                    _updateLocal.loginName = this.state.loginName;
                    _updateLocal.loginCounCode = this.state._countryPh;
                    localStorage.removeItem('vs_UserSession');
                    localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                    this.setState({reChangeComapny:true,loginCounCode:this.state._countryPh});
                    window.location.href = global.webUrl+'administrator/dashboard/';
                }
                else
                {
                    sendNotification("Error Message 😓",result.message,"danger");
                    let errors = this.state.errors;
                    errors['loginName'] = result.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            });
        }
    }

    clickToManageProfilePassword = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.currentPassword == '')
        {
            _isError = true;
            errors['currentPassword'] = "*Please Enter Old Password.";
            this.setState({errors: errors});
        }
        if(this.state.newPassword == '')
        {
            _isError = true;
            errors['newPassword'] = "*Please Enter New Password.";
            this.setState({errors: errors});
        }
        if(this.state.confirmPassword == '')
        {
            _isError = true;
            errors['confirmPassword'] = "*Please Enter Confirm Password.";
            this.setState({errors: errors});
        }

        if(_isError == false){
            
            const requestData = JSON.stringify({
                newPassword :this.state.newPassword,
                currentPassword :this.state.currentPassword,
                loginId: _adminId,
                loginType: 'Admin',
            });
    
            PostData(global.adminPasswordUpdate, requestData,'POST').then((result) => {
                if(result.success == true)
                {
                    sendNotification("Success Message 👍",result.message,"success");
                    setTimeout(
                        () => {
                            window.location.href = global.webUrl+"login/";
                        }, 
                        Math.floor(Math.random() * 5000) + 1
                    )
                }
                else
                {
                    sendNotification("Error Message 😓",result.message,"danger");
                    let errors = this.state.errors;
                    errors['loginName'] = result.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            });
        }
    }
    
    manageMultiCounties(key){
        if(key == 'I'){
            this.setState({_countryReg:''});
        }
        else if(key == 'O'){
            this.setState({_countryReg_O:''});
        }
    }

    onMouseLeaveHandler(key) {
        if(key == 'I'){
            this.setState({_countryReg:'iti__hide'});
        }
        else if(key == 'O'){
            this.setState({_countryReg_O:'iti__hide'});
        }
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Profile<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage Profile</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-user-circle mr-5 text-muted"></i> User Profile
                            </h3>
                        </div>
                        <div className="block-content">
                            <div className="row items-push">
                                <div className="col-lg-3">
                                    <p className="text-muted">
                                        Your account’s vital info. Your username will be publicly visible.
                                    </p>
                                </div>
                                <div className="col-lg-7 offset-lg-1">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-name">Name</label>
                                            <input type="text" className="form-control form-control-lg" id="loginName" name="loginName" onChange={this.handleChange} placeholder="Enter your name.." value={this.state.loginName} />
                                            <span className="iris_error_txt">{errors.loginName.length > 0 && <span className='error'>{errors.loginName}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-name">Email Id</label>
                                            <input type="text" className="form-control form-control-lg" id="loginEmail" name="loginEmail" onChange={this.handleChange} placeholder="Enter your name.." value={this.state.loginEmail} />
                                            <span className="iris_error_txt">{errors.loginEmail.length > 0 && <span className='error'>{errors.loginEmail}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-name">Phone No</label>
                                            <div className="form-material open">
                                                <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this,'I')} onMouseLeave={this.onMouseLeaveHandler.bind(this,'I')}>
                                                    <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName}>
                                                        {/* <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div> */}
                                                        <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                        <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                    </div>

                                                    <ul className={this.state._countryReg +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                        {this.getReadyMobileCode()}
                                                    </ul>
                                                </div>
                                                <input type="numner" className="form-control form-control-lg" id="loginNumner" name="loginNumner" onChange={this.handleChange} placeholder="Enter your name.." value={this.state.loginNumner} maxLength="12" autoComplete='off' style={{display: 'inline-block',width:'70%'}}/>
                                                <span className="iris_error_txt">{errors.loginNumner.length > 0 && <span className='error'>{errors.loginNumner}</span>}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToManageProfileInfo}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">
                                <i className="fa fa-asterisk mr-5 text-muted"></i> Change Password
                            </h3>
                        </div>
                        <div className="block-content">
                            <div className="row items-push">
                                <div className="col-lg-3">
                                    <p className="text-muted">
                                        Changing your sign in password is an easy way to keep your account secure.
                                    </p>
                                </div>
                                
                                <div className="col-lg-7 offset-lg-1">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-password">Current Password</label>
                                            <input type="password" className="form-control form-control-lg" id="currentPassword" name="currentPassword" onChange={this.handleChange}/>
                                            <span className="iris_error_txt">{errors.currentPassword.length > 0 && <span className='error'>{errors.currentPassword}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-password-new">New Password</label>
                                            <input type="password" className="form-control form-control-lg" id="newPassword" name="newPassword" onChange={this.handleChange}/>
                                            <span className="iris_error_txt">{errors.newPassword.length > 0 && <span className='error'>{errors.newPassword}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label htmlFor="profile-settings-password-new-confirm">Confirm New Password</label>
                                            <input type="password" className="form-control form-control-lg" id="confirmPassword" name="confirmPassword" onChange={this.handleChange}/>
                                            <span className="iris_error_txt">{errors.confirmPassword.length > 0 && <span className='error'>{errors.confirmPassword}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToManageProfilePassword}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}