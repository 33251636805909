import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";

let gstTaxDetails = [];
let _businessSetting = {};
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Item Name",
    field: "item_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Item Name",
    },
  },
  {
    label: "Opening Qty (for normal type only)",
    field: "opening_qty",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Opening Qty",
    },
  },
  {
    label: "As Date",
    field: "date",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "As Date",
    },
  },
  {
    label: "Min Stock",
    field: "min_Stock",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Min Stock",
    },
  },
  {
    label: "Location",
    field: "locatoin",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Location",
    },
  },
];

let _loader = "glb-ldr-prt active";

export default class ManageBullStockUpdate extends Component {
  constructor(props) {
    super(props);
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    if (_UserSession!=null && _UserSession!="") {
      let _companySession = localStorage.getItem("vs_UserCompanySession");
      if (_companySession!=undefined || _companySession!=null) {
        _companySession = JSON.parse(_companySession);
        _businessId = _companySession.business_id;
        _companyId = _companySession.company_id;
        gstTaxDetails = _companySession.taxDetails;

        if (_UserSession!=null && _UserSession!="") {
          if (_UserSession.loginType!="Admin") {
            if (_UserSession!=undefined || _UserSession!=null) {
              _businessSetting = _UserSession.businessSetting;
            }
          }
        }
      }
    }

    this.state = {
      isLoaded: true,
      modalIsOpen_IMI: false,
      itemArrayFilter: [],
      updateItemArrayFilter: [],
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      dateLabel: "",
      importExcelFile: "",
      showError: "",
      date: moment().format("MM/DD/YYYY"),
      dateChange: -1,
      errors: {
        name: "",
        importExcelFile: "",
        showError: "",
      },
    };
  }
  async componentDidMount() {
    this.geItemListData();
  }

  geItemListData() {
    let _itemArray = [];
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      activeFilter: true,
      inActiveFilter: false,
    });
    PostData(global.userItemList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            itemArrayFilter: _itemArray,
            itemArray: _itemArray,
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _itemArray = responseJson.response;
            _itemArray.map((itm, i) => {});
          }
          this.setState({
            itemArrayFilter: _itemArray,
            itemArray: _itemArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleCallBackDate = (start) => {
    this.setState({ date: start.format("MMMM D, YYYY") });
    let _name = {
      target: { name: "date", value: start.format("MMMM D, YYYY") },
    };
    this.handleChange(this.state.dateChange, _name);
  };
  handleChange(i, e) {
    let itemArrayFilter = this.state.itemArrayFilter;
    let _updateItemArrayFilter = this.state.updateItemArrayFilter;
    let _updateItemArray = [];
    let errors = this.state.errors;
    errors["showError"] = "";
    itemArrayFilter.map((data, index) => {
      if (i == index) {
        if (
          _updateItemArrayFilter.filter((item) => item.item_id == data.item_id)
            .length > 0
        ) {
          _updateItemArrayFilter.map((item, indexD) => {
            if (item.item_id == data.item_id) {
              _updateItemArrayFilter[indexD] = data;
            }
          });
        } else {
          _updateItemArray.push(data);
        }
      }
    });
    _updateItemArrayFilter = [..._updateItemArrayFilter, ..._updateItemArray];
    itemArrayFilter[i][e.target.name] = e.target.value;
    this.setState({
      itemArrayFilter,
      updateItemArrayFilter: _updateItemArrayFilter,
      errors: errors,
    });
  }
  loadTranscListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.itemArrayFilter;
      if (_transDetails.length > 0) {
        let _i = 0;

        let _itemName = "";
        let _normalQty = "";
        let _asDate = "";
        let _minStock = "";
        let _location = "";

        _transDetails.map((data, index) => {
          _i++;
          //Enter Item Name,Enter Normal Quantity, Enter Minimum Stock, Enter Item Location
          _itemName = (
            <input
              type="text"
              required="required"
              className="form-control"
              id="name"
              name="name"
              value={data.name || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );

          _normalQty = (
            <input
              type="text"
              required="required"
              className="form-control"
              id="opening_stock"
              name="opening_stock"
              value={data.opening_stock || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );
          //moment(data.date).format('MM/DD/YYYY') //data.date
          _asDate = (
            <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: moment(data.date).format("MM/DD/YYYY"),
              }}
              onCallback={this.handleCallBackDate}
              onEvent={(event) => {
                if (event.type == "show") {
                  this.setState({ dateChange: index });
                }
              }}
            >
              <input
                type="text"
                className="form-control"
                readOnly
                onChange={(e) => this.handleChange(index, e)}
                id="date"
                name="date"
              />
            </DateRangePicker>
          );

          _minStock = (
            <input
              type="number"
              required="required"
              className="form-control"
              id="min_stock_quantity"
              name="min_stock_quantity"
              value={data.min_stock_quantity || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );

          _location = (
            <input
              type="text"
              required="required"
              className="form-control"
              id="item_location"
              name="item_location"
              value={data.item_location || ""}
              placeholder=""
              onChange={(e) => this.handleChange(index, e)}
            />
          );

          rows.push({
            srNo: _i,
            item_name: _itemName,
            opening_qty: _normalQty,
            date: _asDate,
            min_Stock: _minStock,
            locatoin: _location,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  clickToAddItem = (event) => {
    event.preventDefault();
    console.log(
      "--data save--",
      JSON.stringify(this.state.updateItemArrayFilter)
    );

    let errors = this.state.errors;
    if (
      this.state.updateItemArrayFilter == "" ||
      this.state.updateItemArrayFilter.length == 0
    ) {
      errors["showError"] =
        "You have not change any item, before save please change any item details";
      this.setState({ errors: errors });
    } else {
      errors["showError"] = "";
      this.setState({ errors: errors });
      confirmAlert({
        title: "Confirm to Update",
        message: "Are you sure you want to update all these change items?",
        buttons: [
          {
            label: "Yes",
            className: "btn btn-danger",
            onClick: () => {
              let _itemsCatArray = [];
              const requestData = JSON.stringify({
                businessId: this.state.businessId,
                company_id: this.state.company_id,
                userId: this.state.userId,
                type: "Stock-Update",
                itemUpdate: this.state.updateItemArrayFilter,
              });
              console.log("==here data==", requestData);
              //return false;
              PostData(global.multipleBulkItemUpdate, requestData, "POST")
                .then((result) => {
                  let responseJson = result;
                  if (responseJson.success == false) {
                    this.setState({ isLoaded: true });
                  } else {
                    if (responseJson.response) {
                      _itemsCatArray = responseJson.response;
                    }
                    this.setState({
                      itemCategoryArray: _itemsCatArray,
                      isLoaded: true,
                    });
                    sendNotification(
                      "Success Message 👍",
                      result.message,
                      "success"
                    );
                  }
                })
                .catch((error) => this.setState({ error, isLoaded: false }));
            },
          },
          {
            label: "No",
            className: "btn btn-dark",
          },
        ],
      });
    }
  };
  resetForm = (event) => {
    event.preventDefault();

    confirmAlert({
      title: "Confirm to Update",
      message: "Are you sure you want to reset all these changes?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            this.setState({ updateItemArrayFilter: [] });
            window.location.href =
              global.webUrl + "business/bulk-update-items/";
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <h2 className="block-title ml-10">Update Stock</h2>
        <h5 className="block-title ml-10">
          Updated Item Count : {this.state.updateItemArrayFilter.length}
        </h5>
        <button className="btn btn-primary ml-10" onClick={this.resetForm}>
          Reset Data
        </button>
        <div className="block-content" id="tableData">
          <MDBDataTableV5
            hover
            entriesOptions={[25, 50, 100]}
            entries={25}
            pagesAmount={4}
            span
            pagingTop
            searchTop
            searchBottom={false}
            barReverse
            data={this.loadTranscListing()}
          />

          <div className="pull-right mb-10">
            <button
              type="submit"
              className="btn btn-alt-primary text-right"
              onClick={this.clickToAddItem}
            >
              <i className="fa fa-check mr-5"></i> Save Item
            </button>
            <div id="showError-error" className="animated fadeInDown">
              {errors.showError.length > 0 && (
                <span className="error">{errors.showError}</span>
              )}
            </div>
          </div>
        </div>
        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
