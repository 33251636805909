import React,{Component} from 'react';
import {PostData} from '../service/postData';
import {sendNotification} from '../_notificationSettings/notificationPanel';
//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class contactPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false,
            name:'',
            email:'',
            contact:'',
            message:'',
            errors:{
                name:'',
                email:'',
                contact:'',
                message:'',
            }
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'name': 
            errors.name = 
                value.length < 3 ? '*Name must be 3 characters long!' : '';
                this.setState({name:value}); 
            break;
            case 'email': 
                errors.email = value.length < 5 ? '*Please enter valid email id!' : '';
                this.setState({email:value}); 
            break;
            case 'contact': 
                errors.contact = value.length < 5 ? '*Please enter valid mobile no.!' : '';
                if (/^\d*$/.test(value)) {
                    if (value.length >= 0 && value.length <= 10) {
                        this.setState({contact:value}); 
                    }
                  }else{
                    errors.contact = "*Please enter valid mobile no.!";
                  }
                  
                
            break;
            case 'message': 
                errors.message =  value.length < 0 ? '*Please enter some message!' : '';
                this.setState({message:value}); 
            break;
            default:
            break;
        }
    }

    clickToSubmitEnqury = (event) => {
        event.preventDefault();
        
        const regex = /^\d{10}$/;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(this.state.name == '')
        {
            alert("Name must be 3 characters long!");
        }else if(!(this.state.email && isValidEmail.test(this.state.email))){
            alert("Email-Id is invalid.");
        }else if(!regex.test(this.state.contact)){
            alert('Mobile number is invalid.')
        }else if(this.state.message == "") {
            alert("Please Enter Some Message.");
        }else{
            this.getTouchFormSubmit();
        }
    }

    getTouchFormSubmit()
    {
        const requestData = JSON.stringify({
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            message: this.state.message,
            pageSubject:'Enquiry From Get In Touch - Home Page',
        });
        
        PostData(global.webistePageGetTouchForm, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                this.setState({name: '',contact: '',email: '',message: ''});
                sendNotification("Success Message 👍","Enquiry mail send successfully.","success");
            }
            else
            {
                sendNotification("Error Message 😓","Some error occured. Please try again.","danger");
                return;
            }
        });
    }

    render(){
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Contact Us</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                       
                        <section className="sec4 sec4C sec4D">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-header"><i className="fa fa-envelope"></i> Contact us.
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="form-group">
                                                    <label for="name">Name</label>
                                                    <input type="text" className="form-control" name="name" placeholder="Enter name" value={this.state.name} onChange={this.handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label for="email">Email address</label>
                                                    <input type="email" className="form-control" name="email" aria-describedby="emailHelp" placeholder="Enter email" value={this.state.email} onChange={this.handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label for="name">Mobile No.</label>
                                                    <input type="text" className="form-control" name="contact" placeholder="Enter Mobile No." value={this.state.contact} onChange={this.handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label for="message">Message</label>
                                                    <textarea className="form-control" name="message" rows="4" placeholder="Enter your message..." value={this.state.message} onChange={this.handleChange} required></textarea>
                                                </div>
                                                <div className="mx-auto">
                                                <button type="submit" className="btn btn-primary text-right" onClick={this.clickToSubmitEnqury}>Submit</button></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="card bg-light mb-3">
                                        <div className="card-header"><i className="fa fa-home"></i> Address</div>
                                        <div className="card-body">
                                            <p>3 rue des Champs Elysées 75008 PARIS</p>
                                            <p>France</p>
                                            <p>Email : email@example.com</p>
                                            <p>Tel. +33 12 56 11 51 84</p>

                                        </div>

                                    </div>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.1506161053303!2d114.18939931442942!3d22.31014284817659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040120a74dc4e1%3A0x4fc503ea51020f08!2sHarbour%20Centre%20Block%202%2C%208%20Hok%20Cheung%20St%2C%20To%20Kwa%20Wan%2C%20Hong%20Kong!5e0!3m2!1sen!2sin!4v1638334947163!5m2!1sen!2sin" width="100%" height="350" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}