import React, { Component } from 'react';

//import {PostData} from '../service/postData';
import '../App.css';
import { Route, Switch } from 'react-router'
import routes from '../routes/routes';
//import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

//----Include Fragment Pages-----
import HeaderSection from '../fragment_folder/header';
import MenuSection from '../fragment_folder/menuUsers';
import TopHeaderPage from '../fragment_folder/topUserHeader';
import FooterPage from '../fragment_folder/footer';
//----End Include Fragment Pages

// Start Transaction Pages Here...
//import _user_DashboardPanel from '../views_folder/userController/dashboard';
import DashboardPanel from '../views_folder/userController/dashboard';

import _user_AddParty from '../views_folder/userController/businessParty/manageAddParty';
import _user_Party from '../views_folder/userController/businessParty/party';

import _user_saleInvoice from '../views_folder/userController/userSale/manageSaleList';
import _user_createSale from '../views_folder/userController/userSale/manageCreateSale';
import _user_createItem from '../views_folder/userController/userItem/manageAddItem';
import _user_itemLists from '../views_folder/userController/userItem/itemList';
import _user_serviceList from '../views_folder/userController/userItem/manageAllService';
import _user_createService from '../views_folder/userController/userItem/manageAddService';

import _user_unitList from '../views_folder/userController/unit/unit';

import _user_purchaseInvoice from '../views_folder/userController/userPurchase/managePurchaseList';
import _user_createPurchase from '../views_folder/userController/userPurchase/manageCreatePurchase';

import _user_saleEstimateList from '../views_folder/userController/userSale/manageSaleEstimateList';
import _user_createSaleEstimate from '../views_folder/userController/userSale/manageCreateSaleEstimate';
//import businessUserListPanel from '../views_folder/businessController/businessUser/manageBusinessUser';

export default class PrivatePanel extends Component{
  render(){
    return(
        <div id="page-wrapper">
            <div id="page-container" className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed">
            <aside id="side-overlay">
                <HeaderSection pagekey="user"/>
            </aside>  
            <MenuSection pageId={global.BACK_URL} />
            <TopHeaderPage />
            <main id="main-container">
                <div>
                    <Switch>
                        <Route exact={true} path={routes._user_DashboardPanel} component={DashboardPanel} />
                        {/* <Route exact={true} path={routes._user_PartyPanel} component={_user_PartyPanel} /> */}

                        <Route exact={true} path={routes._user_PartyPanel} component={_user_Party} />

                        <Route exact={true} path={routes._user_AddParty} component={_user_AddParty} />
                        <Route exact={true} path={routes._user_saleInvoice} component={_user_saleInvoice} />
                        <Route exact={true} path={routes._user_saleInvoice_Add} component={_user_createSale} />
                        <Route exact={true} path={routes._user_Item_Add} component={_user_createItem} />
                        <Route exact={true} path={routes._user_itemList} component={_user_itemLists} />
                        <Route exact={true} path={routes._user_ServiceList} component={_user_serviceList} />
                        <Route exact={true} path={routes._user_Service_Add} component={_user_createService} />

                        <Route exact={true} path={routes._user_unitList} component={_user_unitList} />

                        <Route exact={true} path={routes._user_purchaseInvoice} component={_user_purchaseInvoice} />
                        <Route exact={true} path={routes._user_purchaseInvoice_Add} component={_user_createPurchase} />
                        
                        <Route exact={true} path={routes._user_saleEstimateList} component={_user_saleEstimateList} />
                        <Route exact={true} path={routes._user_saleEdtimate_Add} component={_user_createSaleEstimate} />
                    </Switch>
                </div>
            </main>
            <FooterPage />
            </div>
        </div>
    )
  }
}