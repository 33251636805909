import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTypeData = [];

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTypeData = _companySession.taxType;
}

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
export default class quickBankAddEditPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/add-sale/",
      buttonTitle: "Add New",
      hideShow: "",
      isLoaded: true,
      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,

      opening_balance: "",
      as_on_date: moment().format("MMMM D, YYYY"),
      account_number: "",
      ifsc_code: "",
      upi_code: "",
      account_holder_name: "",
      display_name: "",
      toggleStateUpi: true,
      toggleStateInvoice: true,
      bank_name: "",

      accountId: 0,
      errors: {
        opening_balance: "",
        bank_account_enable: "",
        account_number: "",
        ifsc_code: "",
        upi_code: "",
        account_holder_name: "",
        display_name: "",
        bank_name: "",
      },
    };
    _isEditMode = this.props.accountId > 0 ? true : false;
  }

  async componentDidMount() {
    let _accountId = this.props.accountId;
    if (_accountId > 0) {
      const requestData = JSON.stringify({
        accountId: _accountId,
      });

      PostData(global.userBankDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transArray = responseJson.response[0];
              _isEditMode = true;
              this.setState({
                buttonTitle: "Edit",
                accountId: _accountId,
                loan_id: _transArray.id,
                display_name: _transArray.display_name,
                opening_balance: _transArray.opening_balance,
                as_on_date: moment(_transArray.as_on_date).format("MM/DD/YYYY"),
                account_number: _transArray.account_number,
                ifsc_code: _transArray.ifsc_code,
                upi_code: _transArray.upi_code,
                account_holder_name: _transArray.account_holder_name,
                bank_name: _transArray.bank_name,
                toggleStateUpi: _transArray.or_code_enable,
                toggleStateInvoice: _transArray.bank_account_enable,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    } else {
      _isEditMode = false;
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "opening_balance":
        if (validator.isInt(value)) {
          errors.opening_balance = "";
        } else {
          errors.opening_balance = "*Opening balace should be numeric!";
        }
        this.setState({ opening_balance: value });
        break;

      case "as_on_date":
        errors.as_on_date = value.length < 3 ? "*Date must be select!" : "";
        this.setState({ as_on_date: value });
        break;

      case "account_number":
        if (this.state.toggleStateInvoice == true) {
          errors.account_number =
            value.length < 9 ? "*Account No. must be 9 number " : "";
          this.setState({ account_number: value });
        } else {
          errors.account_number = "";
          this.setState({ account_number: value });
        }
        break;

      case "ifsc_code":
        //errors.ifsc_code =
        //value.length < 7 ? '*IFSC code must be 8 characters long!' : '';
        this.setState({ ifsc_code: value });
        break;

      case "upi_code":
        //errors.upi_code =
        //value.length < 6 ? '*UPI Code number must be 7 character long!' : '';
        this.setState({ upi_code: value });
        break;
      case "account_holder_name":
        //errors.account_holder_name =
        //value.length < 2 ? '*Account holder name must be 3 character long!' : '';
        this.setState({ account_holder_name: value });
        break;

      case "bank_name":
        this.setState({ bank_name: value });
        break;

      case "display_name":
        errors.display_name =
          value.length < 2
            ? "*Account display name must be 3 character long!"
            : "";
        this.setState({ display_name: value });
        break;
      default:
        break;
    }
  };

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    /*if(this.state.account_holder_name == '')
        {
            _isError = true;
            errors['account_holder_name'] = "*Please Enter Account Holder Name";
            this.setState({errors: errors});
        }*/

    if (this.state.display_name == "") {
      _isError = true;
      errors["display_name"] = "*Please Enter Account Display Name";
      this.setState({ errors: errors });
    }

    if (this.state.opening_balance == "") {
      _isError = true;
      errors["opening_balance"] = "*Please Enter Opening Balance.";
      this.setState({ errors: errors });
    }

    if (this.state.toggleStateInvoice == true || this.state.toggleStateUpi == true ) {
      if (this.state.account_number == "") {
        _isError = true;
        errors["account_number"] = "*Please Enter Account No.";
        this.setState({ errors: errors });
      }
    }

    if (_isError == false) {
      if (this.props.accountId > 0) {
        this.editBankDetailsMethodCall();
      } else {
        this.addBankDetailsMethodCall();
      }
    }
  };

  addBankDetailsMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      openingBalance: this.state.opening_balance,
      accountDisplayName: this.state.display_name,
      asOnDate: this.state.as_on_date,
      asOnTime: moment().format("h:mm A"),
      accountNumber: this.state.account_number,
      ifscCode: this.state.ifsc_code,
      upiCode: this.state.upi_code,
      accountHolderName: this.state.account_holder_name,
      bank_name: this.state.bank_name,
      isQRUpi: this.state.toggleStateUpi,

      transfer_date: moment().format("MMMM D, YYYY"),
      isPrintInvoice: this.state.toggleStateInvoice,
    });
    PostData(global.createBankAccountDetail, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          let _updateLocal = _UserSession;
          let _comData = _updateLocal.companies;
          _comData.map((compData, idx) => {
            if (compData.company_id == this.state.company_id) {
              compData.bankDetails.push(result.bankData);
            }
          });
          _updateLocal.companies = _comData;
          localStorage.removeItem("vs_UserSession");
          localStorage.setItem("vs_UserSession", JSON.stringify(_updateLocal));

          let _updateCompany = _companySession;
          _updateCompany.bankDetails.push(result.bankData);
          localStorage.removeItem("vs_UserCompanySession");
          localStorage.setItem(
            "vs_UserCompanySession",
            JSON.stringify(_updateCompany)
          );

          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            window.location.href = global.webUrl + this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editBankDetailsMethodCall() {
    const requestData = JSON.stringify({
      account_id: this.props.accountId,
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      openingBalance: this.state.opening_balance,
      accountDisplayName: this.state.display_name,
      asOnDate: this.state.as_on_date,
      accountNumber: this.state.account_number,
      ifscCode: this.state.ifsc_code,
      upiCode: this.state.upi_code,
      accountHolderName: this.state.account_holder_name,
      bank_name: this.state.bank_name,
      isQRUpi: this.state.toggleStateUpi,
      isPrintInvoice: this.state.toggleStateInvoice,
    });
    PostData(global.editBankAccountDetail, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          let _updateLocal = _UserSession;
          let _comData = _updateLocal.companies;
          _comData.map((compData, idx) => {
            if (compData.company_id == this.state.company_id) {
              compData.bankDetails.push(result.bankData);
            }
          });
          _updateLocal.companies = _comData;
          localStorage.removeItem("vs_UserSession");
          localStorage.setItem("vs_UserSession", JSON.stringify(_updateLocal));

          let _updateCompany = _companySession;
          //_updateCompany.bankDetails.push(result.bankData);
          _updateCompany.bankDetails.map((bankD, index) => {
            if (bankD.bank_id == result.bankData.bank_id) {
              _updateCompany.bankDetails[index] = result.bankData;
            }
          });
          localStorage.removeItem("vs_UserCompanySession");
          localStorage.setItem(
            "vs_UserCompanySession",
            JSON.stringify(_updateCompany)
          );

          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            window.location.href = global.webUrl + this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  onToggleChangeUpi = (checked) => {
    this.setState({ toggleStateUpi: checked });
  };
  onToggleChangeInvoice = (checked) => {
    let errors = this.state.errors;
    if (checked == false) {
      errors.account_number = "";
    }
    this.setState({ toggleStateInvoice: checked, errors: errors });
  };

  onRadioChange = (e) => {
    this.setState({
      payType: e.target.value,
    });
  };

  handleCallBackDate = (start) => {
    this.setState({ as_on_date: start.format("MMMM D, YYYY") });
  };

  isDateRefresh = () => {
    let _invoiceDate = this.state.as_on_date;

    if (_isEditMode == true && this.state.accountId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate, //moment().format('MM/DD/YYYY'),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.state.accountId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: moment().format("MM/DD/YYYY"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="display_name"
                  name="display_name"
                  value={this.state.display_name}
                  placeholder="Enter Account Display Name"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Account Display Name <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.display_name.length > 0 && (
                    <span className="error">{errors.display_name}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="opening_balance"
                  name="opening_balance"
                  value={this.state.opening_balance}
                  placeholder="Enter Opening Balance"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Opening Balance <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.opening_balance.length > 0 && (
                    <span className="error">{errors.opening_balance}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">As On</label>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <label
                style={{ textAlign: "right" }}
                className="col-form-label"
                htmlFor="val-username"
              >
                Print Upi QR code on invoice
              </label>
              <div>
                <ToggleSwitch
                  txtLeft="No"
                  txtRight="Yes"
                  small
                  id="upiQR"
                  checked={this.state.toggleStateUpi}
                  onChange={this.onToggleChangeUpi}
                />
                {/*<p>Newsletter: {String(this.state.toggleStateUpi)}</p> */}
              </div>
            </div>
            <div className="col-6">
              <label
                style={{ textAlign: "right" }}
                className="col-form-label"
                htmlFor="val-username"
              >
                Print this bank account details on invoice
              </label>
              <div>
                <ToggleSwitch
                  txtLeft="No"
                  txtRight="Yes"
                  small
                  id="bankInvoice"
                  checked={this.state.toggleStateInvoice}
                  onChange={this.onToggleChangeInvoice}
                />
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          {(this.state.toggleStateInvoice == true ||
            this.state.toggleStateUpi == true) && (
            <div className="form-group row">
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="text"
                    className="form-control"
                    id="account_number"
                    name="account_number"
                    value={this.state.account_number}
                    placeholder="Enter Account Number"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <span className="iris_error_txt">
                    {errors.account_number.length > 0 && (
                      <span className="error">{errors.account_number}</span>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="text"
                    className="form-control"
                    id="ifsc_code"
                    name="ifsc_code"
                    value={this.state.ifsc_code}
                    placeholder="Enter IFSC Code"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">IFSC Code</label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="text"
                    className="form-control"
                    id="bank_name"
                    name="bank_name"
                    value={this.state.bank_name}
                    placeholder="Enter Bank Name"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">Bank Name</label>
                </div>
              </div>
            </div>
          )}
          <div className="claerfix"></div>

          <div className="form-group row">
            {this.state.toggleStateInvoice == true && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    className="form-control"
                    id="account_holder_name"
                    name="account_holder_name"
                    value={this.state.account_holder_name}
                    placeholder="Enter Account Holder Name"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">Account Holder Name</label>
                </div>
              </div>
            )}

            {this.state.toggleStateUpi == true && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    className="form-control"
                    id="upi_code"
                    name="upi_code"
                    value={this.state.upi_code}
                    placeholder="Enter UPI Code"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">UPI Code(optional)</label>
                </div>
              </div>
            )}
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Bank Details
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
