import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from 'react-modal';
import * as XLSX from "xlsx";

import ButtonPdf from '../htmlPdfCreation';

let _UserSession = localStorage.getItem('vs_UserSession');
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';

if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _companyId = _companySession.company_id;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Party Name',
        field: 'party_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Party Name',
        },
    },
    {
        label: 'Contact No',
        field: 'party_contact_no',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Contact No',
        },
    },
    {
        label: 'Email',
        field: 'party_email_id',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Email',
        },
    },
    {
        label: 'Billing Address',
        field: 'party_billing_address',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Billing Address',
        },
    },
    {
        label: 'Shipping Address',
        field: 'party_shipping_address',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Shipping Address',
        },
    },
    {
        label: 'Opening Balance',
        field: 'balance',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Opening Balance',
        },
    },
    {
        label: 'GST No',
        field: 'gst_number',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'GST No',
        },
    }/*,
    {
        label: 'Action',
        field: 'actionExtra',
        width: 100,
    }*/
];


let _loader = 'glb-ldr-prt active';

export default class manageGenerateBarcode extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : true,
            modalIsOpen_IMI : false,
            partyArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            startDate : moment().startOf('month').toDate(),
            endDate : moment().endOf('month').toDate(),
            dateLabel : '',
            importExcelFile : '',
            errors: {
                name : '',
                importExcelFile : '',
            }
        }
    }

    async componentDidMount ()
    {   
        this.getPartyListData();
    }

    getPartyListData(){
        let _partyArray = [];
        

        let _url_GetData = global.userPartyList+'?company_id='+this.state.company_id;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({partyArrayFilter:_partyArray,partyArray:_partyArray,isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _partyArray = responseJson.response;
                    _partyArray.map((itm, i) =>{
                        
                    });
                }
                //_totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
                this.setState({partyArrayFilter:_partyArray,partyArray:_partyArray,isLoaded:true,});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    

    loadTranscListing(){
        let dataList = [];
        let rows = [];
        
        if(this.state.isLoaded == true)
        {
            
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }
    
    
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Barcode Generator<br/>
                            </h1>
                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <Link className="breadcrumb-item" to={"/business/dashboard/"}>Dashboard</Link>                    
                        <span className="breadcrumb-item active">Barcode Generator</span>
                    </nav>                   

                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h2 className="block-title">Barcode Generator</h2>
                                            <div className="form-group row">
                                                <div className="col-3">
                                                    <div className="form-material open">
                                                        <input type="text" id="Pricing" name="bulkUpdate" className="form-control"/>
                                                        <label htmlFor="login-username">Item Name</label>
                                                    </div>
                                                </div>  
                                                <div className="col-3">
                                                    <div className="form-material open">
                                                        <input type="number" id="Stock" name="bulkUpdate"  className="form-control"/>
                                                        <label htmlFor="login-username">No Of Lables</label>
                                                    </div>
                                                </div>   
                                                <div className="col-3">
                                                    <div className="form-material open">
                                                        <input type="text" id="Info" name="bulkUpdate"  className="form-control"/>
                                                        <label htmlFor="login-username">Header</label>
                                                    </div>
                                                </div>   
                                                <div className="col-3">
                                                    <div className="form-material open">
                                                        <input type="text" id="Info" name="bulkUpdate"  className="form-control"/>
                                                        <label htmlFor="login-username">Line 1</label>
                                                    </div>
                                                </div>   
                                                <div className="col-3">
                                                    <div className="form-material open">
                                                        <input type="text" id="Info" name="bulkUpdate"  className="form-control"/>
                                                        <label htmlFor="login-username">Line 2</label>
                                                    </div>
                                                </div>    
                                            </div>
                                    </div>
                                    
                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>
                
                

            </React.Fragment>
        )    
    }
}