import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTaxDetails = _companySession.taxDetails;
}

let _loader = "glb-ldr-prt active";

export default class quickAddTermPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/dashboard/",
      itemSelectData: this.props.itemSelectData
        ? this.props.itemSelectData
        : "0",
      _dataType: this.props.dataType ? this.props.dataType : "ITEM",
      buttonTitle: "Add New",
      isLoaded: true,
      unitArray: [],
      secUnitArray: [],
      checkedItems: new Map(),
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      modalIsOpen_AddTerm: false,
      terms_name: "",
      terms_days: "",
      termId: 0,

      errors: {
        terms_name: "",
        terms_days: "",
      },
    };
  }
  async componentDidMount() {}

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "terms_name":
        errors.terms_name =
          value.length < 3 ? "*Term name must be 3 characters long!" : "";
        this.setState({ terms_name: value });
        break;
      case "terms_days":
        if (validator.isInt(value)) {
          errors.terms_days = "";
          if (value == 0) {
            errors.terms_days = "*Terms days can not be 0";
          }
        } else {
          if (value == 0) {
            errors.terms_days = "*Terms days can not be 0";
          }
          errors.terms_days = "*Terms days must be numeric";
        }

        this.setState({ terms_days: value });
        break;
      default:
        break;
    }
  };

  clickToAddTerms = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.terms_name == "") {
      _isError = true;
      errors["terms_name"] = "*Please Enter Terms Name";
      this.setState({ errors: errors });
    }
    if (this.state.terms_days == "") {
      _isError = true;
      errors["terms_days"] = "*Please Enter Terms Days";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      this.addNewTermData();
    }
  };

  addNewTermData() {
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      companyId: this.state.company_id,
      terms_name: this.state.terms_name,
      terms_days: this.state.terms_days,
    });

    PostData(global.addNewTerms, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          this.props.callBack("hello");
          this.props.callBackTerms();
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        return;
      }
    });
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="terms_name"
                  name="terms_name"
                  value={this.state.terms_name}
                  placeholder="Terms Name Here"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Terms Name <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.terms_name.length > 0 && (
                    <span className="error">{errors.terms_name}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="number"
                  required="required"
                  className="form-control"
                  id="terms_days"
                  name="terms_days"
                  value={this.state.terms_days}
                  placeholder="Terms Days Here"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Terms Days <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.terms_days.length > 0 && (
                    <span className="error">{errors.terms_days}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddTerms}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
