import React,{Component} from 'react';
import {PostData} from '../../service/postData';
import {sendNotification} from '../../_notificationSettings/notificationPanel';
import { numberFormat } from '../../config/numberFormat';
import ToggleSwitch from "../../_toggleSwitch/toggleSwitch";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let _countryCode = 'IN';
let _countryName = 'India';
let _taxRateArray = [];
let _bankArray = [];
let _settingType = "showall";
let _businessSetting = {};
_bankArray.push({"bank_id":"0","bank_name":"","paymentType":"Cash"},{"bank_id":"0","bank_name":"","paymentType":"Cheque"});

let maxNumber = 999999;
let _invoiceNumber = Math.floor((Math.random() * maxNumber) + 1);

/*For State Data*/

let _loader = 'glb-ldr-prt active';
let _isEditMode = false;
let _isGetData = false;
export default class userViewTransactionPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');

        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _company_id = _UserSession.company_id;
            _businessSetting = _UserSession.businessSetting;
      
            if (_businessSetting!=undefined || _businessSetting!=null) {
              if (_businessSetting.PrintOriginalDuplicate == 0) {
                _settingType = null;
              }
            }
          
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            _countryCode = _companySession.country_code;
            _countryName = _companySession.country_name;
            _taxRateArray = _companySession.taxDetails;
            let bankArrayData = (_companySession.bankDetails) ? _companySession.bankDetails : [];
            bankArrayData.map((bank,index) => {
                _bankArray.push(bank);
            });
        }
        _isGetData = false;
        this.state = {
            buttonTitle:'Add Purchase Order',
            
            _partyReg:'iti__hide',
            party_id : '0',
            party_name : '',
            partyArrayFilter : [],
            partyArray :[],
            stateArray : [],
            itemArray :[],
            itemArrayFilter:[],
            unitArray : [],
            taxRateArray : _taxRateArray,
            rows: [],
            fields : [],
            modalIsOpen_SP : false,
            modalIsOpen_SBANK : false,
            
            _itemReg : 'iti__hide',
            _itemRegIndex : '0',

            isLoaded : false,
            isDateUpdate : false,
            
            receivedHide : 'iti__hide',

            type : 'Sale',
            rowHtml :  '',
            countryCode : _countryCode,
            countryName : _countryName,
            businessId:_businessId,
            userId : _signingId,
            company_id : _company_id,
            paymentType : 'Cash',
            invoiceNo : _invoiceNumber,
            rate_tax : 'With Tax',
            invoiceDate : moment().format('MM/DD/YYYY'),
            dueDate : '0',//moment().format('MM/DD/YYYY'),
            countryState : '',
            paymentRefNo : '',
            description : '',
            descImage : '',
            partyId : '',
            bankId : 0,
            bankName : '',
            payment_ref_no : '',
            dueAmount : 0,
            receivedAmount : 0,
            totalAmount : 0,
            totalQuantity : 0,
            gstAmount : 0,
            totalDiscount : 0,
            transactionId : this.props.transactionIdActiveView, 
            isItemNumber : 0,
            TransAdditionalCharge1: 0,
            TransAdditionalCharge2: 0,
            TransAdditionalCharge3: 0,
            TransportDetailField1: "",
            TransportDetailField2: "",
            TransportDetailField3: "",
            TransportDetailField4: "",
            TransportDetailField5: "",
            TransportDetailField6: "",
            additional_value_1: "",
            additional_value_2: "",
            additional_value_3: "",
            totalTransDiscountAmount: 0,
            totalTransTaxAmount: 0,
            transactionWiseTaxId: 0,

            errors: {
                invoiceNo : '',
                invoiceDate : '',
                dueDate : '',
                partyId : '',
                party_name : '',
            }
        }
    }

    async componentDidMount ()
    {
        let transactionId = this.state.transactionId;
        if(transactionId > 0){
            _isEditMode = true;
            _isGetData = true;
        }

        this.getPartyListData();        
        this.getStateListData();
        this.getItemListData(_company_id);

        /*For master Units*/        
        let _urlDataUnits = global.userUnitList + "?businessId=" + _businessId+'&company_id='+this.state.company_id;
        let resUnits = await PostData(_urlDataUnits,'','GET'); 
                
        if(resUnits.response == null || resUnits.response == undefined){
            window.location.href = global.BASE_URL+'not-found/';
            return false;
        }

        
        let _unitArray = resUnits.response;
        /*For master Units*/

        this.setState({unitArray:_unitArray});

        if(transactionId >0)
        {
            
            let _transactionArray = [];
            const requestData = JSON.stringify({
                transaction_id: transactionId
            });
            
            PostData(global.userTransactionDetails, requestData,'POST').then((result) => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({unitArray:_unitArray,isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _transactionArray = responseJson.response[0];
                        let transAmout = _transactionArray.totalSummary;
                        let itemsData = _transactionArray.items;
                        let customerData = _transactionArray.customer;

                        let _imgPath = '';                        
                        if(_transactionArray.descImage !=''){
                            _imgPath = global.LIVE_URL+'uploadDirectory/transaction/'+_transactionArray.descImage;
                        }
                        let _finalDueDate = '0';
                        if(_transactionArray.dueDate!=0){
                            _finalDueDate = moment(_transactionArray.dueDate).format('MM/DD/YYYY');
                        }
                        let _additional_value_date =  _transactionArray.additional_value_date;
                        if(_transactionArray.additional_value_date == ''){
                          _additional_value_date = moment().format("MM/DD/YYYY");
                        }
                        this.setState({
                            type:_transactionArray.type,payment_ref_no:_transactionArray.paymentRefNo,
                            party_id:customerData.party_id,party_name:customerData.party_name,
                            bankId : _transactionArray.bankId,bankName : _transactionArray.bankName,
                            unitArray:_unitArray,buttonTitle:'Edit Purchase Order',invoiceNo:_transactionArray.invoiceNo,
                            invoiceDate:moment(_transactionArray.invoiceDate).format('MM/DD/YYYY'),
                            dueDate:_finalDueDate,
                            state:_transactionArray.countryState,countryState:_transactionArray.countryState,
                            businessId:_transactionArray.business_id,companyId:_transactionArray.company_id,
                            totalAmount:transAmout.totalAmount,receivedAmount:transAmout.receivedAmount,
                            subtotal:transAmout.subtotal,gstRatio:transAmout.gstRatio,
                            gstAmount:transAmout.gstAmount,totalQuantity:transAmout.totalQty,
                            totalDiscount:transAmout.totalDiscount,dueAmount:transAmout.dueAmount,
                            description:_transactionArray.description,paymentType:_transactionArray.paymentType,paymentRefNo:_transactionArray.paymentRefNo,fields:itemsData,isLoaded:true,transactionId:transactionId,descImage:_transactionArray.descImage,imagePreviewUrl:_imgPath,isDateUpdate:true,
                            transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                            totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                            totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                            totalTransDiscountAmount:
                              _transactionArray.totalTransDiscountAmount,
                            TransAdditionalCharge1:
                              _transactionArray.TransAdditionalCharge1,
                            TransAdditionalCharge2:
                              _transactionArray.TransAdditionalCharge2,
                            TransAdditionalCharge3:
                              _transactionArray.TransAdditionalCharge3,
                            TransportDetailField1: _transactionArray.TransportDetailField1,
                            TransportDetailField2: _transactionArray.TransportDetailField2,
                            TransportDetailField3: _transactionArray.TransportDetailField3,
                            TransportDetailField4: _transactionArray.TransportDetailField4,
                            TransportDetailField5: _transactionArray.TransportDetailField5,
                            TransportDetailField6: _transactionArray.TransportDetailField6,
                            party_contact_no: _transactionArray.party_contact_no,
                            additional_value_1: _transactionArray.additional_value_1,
                            additional_value_2: _transactionArray.additional_value_2,
                            additional_value_3: _transactionArray.additional_value_3,
                            additional_value_date: moment(_additional_value_date).format("MM/DD/YYYY"),isItemNumber:_transactionArray.isItemNumber});
                    } 
                }
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
    }

    getStateListData(){
        let _stateArray = [];

        let _url_GetData = global.adminStateList+'?countryCode='+this.state.countryCode;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _stateArray = responseJson.response;
                }
                this.setState({stateArray:_stateArray});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    getPartyListData(){
        let _partyArray = [];
        let _url_GetData = global.userPartyList+'?company_id='+this.state.company_id;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _partyArray = responseJson.response;
                }
                this.setState({partyArrayFilter:_partyArray,partyArray:_partyArray});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    getItemListData(company_id){
        let _itemsArray = [];
        
        let _url_GetData = global.userItemSalePurList+'?company_id='+this.state.company_id;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _itemsArray = responseJson.response;
                    _itemsArray.map((field, index) => {
                        _itemsArray[index].isFilter = false;
                    });
                }
                //this.setState({itemArray:_itemsArray,isLoaded:true});      
                this.setState({itemArrayFilter:_itemsArray,itemArray:_itemsArray});            
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
       
        switch (name) {
            
            case 'invoiceNo': 
                errors.invoiceNo = value.length < 0 ? '*Invoice No Must be 4 numbers' : '';
                this.setState({invoiceNo:value}); 
            break;

            case 'invoiceDate' :
                errors.invoiceDate = value.length < 5 ? '* Invoice date must be selected' : '';
                this.setState({invoiceDate:value});
            break;

            case 'dueDate' :
                errors.dueDate = value.length < 5 ? '* Due date must be selected' : '';
                this.setState({dueDate:value});
            break;

            case 'countryState' :
                errors.countryState = value.length < 0 ? '* State must be selected' : ''; 
                this.setState({countryState:value});
            break;

            case 'paymentType' :
                errors.paymentType = value.length < 0 ? '* State must be selected' : ''; 
                
                this.setState({paymentType:value});
            break;
            case 'receivedAmount' :
                let _remainAmount = 0;
                let _paidAmt = value;
                if(_paidAmt == ''){
                    _paidAmt = 0;
                }
                if(_paidAmt >= 0){
                    _remainAmount = parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
                }
                this.setState({receivedAmount:_paidAmt,dueAmount:_remainAmount.toFixed(2)});
            break;
            case 'paymentRefNo' :
                errors.paymentRefNo = value.length < 1 ? '* Payment  must be selected' : ''; 
                this.setState({paymentRefNo:value});
            break;
            case 'description' :
                this.setState({description:value});
            break;
            case 'totalAmount' :
                let _balanaceDue = this.state.dueAmount;
                if(this.state.fields.length == 0){
                    _balanaceDue = value;
                }

                this.setState({totalAmount:value,dueAmount:_balanaceDue});
            break;
            default:
            break;
        }
    }

    /* Start Add More Data */
    clickToAddMoreItem = () => {
        let xx = 1;
        this.setState((prevState, props) => {
            xx++;
            let rowHtml = { content: "<tr><td>"+xx+"</td>"};
            return { rows: [...prevState.rows, rowHtml] };
        });
    };

    handleAdd() {
        const values = this.state.fields;
        values.push({ value: null });
        this.setState({fields:values});
    }

    handleRemove(i,idx) {
        const values = this.state.fields;
        //const newFields = values.filter((itmRow, index) => index!=i);
        const newFields = values.filter((itmRow, index) => {
            return index!=i;
        });
    
        const data = this.state.itemArray;
        data.filter(function(item,index) { 
            if(item.item_id == idx){
                data[index].isFilter = false;
            }
        });
    
        const fieldArray = newFields;
        let _quantity=0;
        let _totalAmt = '0';
        let _receivedAmount = this.state.receivedAmount;
        let _dueAmount = 0;
        let _totalDiscountAmt = 0;
        let _totalGstAmt = 0;
    
        fieldArray.map((field, index) => {
            let _itemId = (field.item_id) ? field.item_id : 0;
            if(_itemId > 0)
            {
                let _obj = {};
                _obj.item_id = (field.item_id) ? field.item_id : 0;
                _obj.item_name = (field.item_name) ? field.item_name : '';
                _obj.quantity = (field.quantity) ? field.quantity : 0;
                _obj.rate = (field.rate) ? field.rate : 0;
                _obj.discount_in_per = (field.discount_in_amount) ? field.discount_in_amount : 0;
                _obj.discount_in_amount = (field.discount_in_per) ? field.discount_in_per : 0;
                _obj.tax_value = (field.tax_value) ? field.tax_value : 0;
                _obj.subtotal = (field.subtotal) ? field.subtotal : 0;
                _obj.total_amount = (field.total_amount) ? field.total_amount : 0;
    
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);
    
                _receivedAmount = this.state.receivedAmount;
                _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.receivedAmount);
                _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
                _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(_obj.discount_in_amount);
                _totalGstAmt = parseFloat(_totalGstAmt) + parseFloat(_obj.discount_in_per);
            }
        });
        
        if(_dueAmount > 0){
            _dueAmount = _dueAmount.toFixed(2);
        }
        if(_totalAmt > 0){
            _totalAmt = _totalAmt.toFixed(2);
        }
        if(_totalDiscountAmt > 0){
            _totalDiscountAmt = _totalDiscountAmt.toFixed(2);
        }
        if(_totalGstAmt > 0){
            _totalGstAmt = _totalGstAmt.toFixed(2);
        }
    
        if(this.state.toggleState == true){
            this.setState({fields:fieldArray,totalQuantity:_quantity,dueAmount:0,receivedAmount:0,totalAmount:_totalAmt,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt});    
        }
        else{
            this.setState({fields:fieldArray,totalQuantity:_quantity,dueAmount:_dueAmount,totalAmount:_totalAmt,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt});
        }
    
        this.setState({fields:newFields,itemArrayFilter:data});
        this.getReadyItemList();
    }
    
    handleDynamicInputs(i, type, event)
    {
        let _itemPosition = i;
        
        let _itemName,_itemPrice,_itemId,_value = '';
        let _unitindividual = [];
        if(type == 'item')
        {
            _itemPosition = i.index;
            _itemPrice = i.price;
            _itemId = i.id;
            _itemName = i.name;
            _unitindividual = (i.unitindividual[0].unit_id) ? i.unitindividual : this.state.unitArray;
        }
        else
        {
            _value = event.target.value;
            _unitindividual = i.unitindividual;
        }
        
        const fieldArray = this.state.fields;
        let _quantity=0;        
        let _totalAmt = 0;
        let _receivedAmount = this.state.receivedAmount;
        let _dueAmount = 0;
        let _totalDiscountAmt = 0;
        let _totalGstAmt = 0;
        
        if(fieldArray.length > 0){
            fieldArray.map((field, index) => {
                let _unitPreArray = (field.unitArrayOld) ? field.unitArrayOld : this.state.unitArray;

                if(index == _itemPosition)
                {
                    let _obj = {};
                    let _objUnit = {unit_id:'0',unit_name:''};
                    let _objRateTax = {value:'1',label:this.state.rate_tax};
                    let _objTaxType = {id:'0',name:'',ratio:'0'};
                    
                    
                    _obj.item_id = (field.item_id) ? field.item_id : 0;
                    _obj.item_name = field.item_name;
                    _obj.quantity = (field.quantity) ? field.quantity : 0;
                    _obj.unit = (field.unit) ? field.unit : _objUnit;
                    _obj.rate = (field.rate) ? field.rate : 0;
                    _obj.rate_tax = _objRateTax;
                    _obj.discount_in_per = (field.discount_in_per) ? field.discount_in_per : 0;
                    _obj.discount_in_amount = (field.discount_in_amount) ? field.discount_in_amount : 0;
                    _obj.tax_type = (field.tax_type) ? field.tax_type : _objTaxType;
                    _obj.tax_value = (field.tax_value) ? field.tax_value : 0;
                    _obj.subtotal = (field.subtotal) ? field.subtotal : 0;
                    _obj.total_amount = (field.total_amount) ? field.total_amount : 0;

                    let _percGstAmt = 0;
                    let _percDisAmt = 0;
                    let _percDisPerc = 0;
                    
                    if(type == 'unit'){
                        let _unitObj = {};
                        _unitPreArray.map((unit,index) => {
                            if(_value == unit.unit_name)
                            {
                                _unitObj.unit_id = unit.unit_id;
                                _unitObj.unit_name = unit.unit_name;
                            }
                        });
                        _obj.unit = _unitObj;
                    }
                    
                    if(type == 'item'){
                        _obj.item_id = _itemId;
                        _obj.item_name = _itemName;
                        _obj.rate = _itemPrice;
                        _obj.subtotal = _itemPrice;
                        _obj.total_amount = _itemPrice;
                        _obj.quantity = 1;
                        _obj.unitArrayOld = _unitindividual;
                    }
                    else{
                        _obj.unitArrayOld = field.unitArrayOld;
                    }

                    if(type == 'quantity'){
                        if(_value == '' || _value == 'null'){
                            _value = 0;
                        }
                        _obj.quantity = _value;
                    }
                    if(type == 'rate'){
                        if(_value == '' || _value == 'null'){
                            _value = 0;
                        }
                        _obj.rate = _value;
                    }

                    let _totalItemAmount = parseFloat(_obj.rate) * parseFloat(_obj.quantity);
                    
                    if(type == 'discount_in_per')
                    {
                        if(_value == '' || _value == 'null'){
                            _value = 0;
                        }
                        _obj.discount_in_per = _value;
                    }
                    
                    if(type == 'discount_in_amount'){
                        if(_value == '' || _value == 'null'){
                            _value = 0;
                        }
                        _obj.discount_in_amount = _value;
                    }
                    
                    if(type == 'discount_in_per')
                    {
                        if(_value == '' || _value == 'null'){
                            _value = 0;
                        }
                        _obj.discount_in_per = _value;
                        if(_obj.discount_in_per >= 0){
                            _percDisAmt = (parseFloat(_totalItemAmount) * parseFloat(_obj.discount_in_per)) / parseFloat(100);
                        }
                        _obj.discount_in_amount = _percDisAmt.toFixed(2);
                    }
                    

                    if(type == 'discount_in_amount'){
                        if(_value == '' || _value == 'null'){
                            _value = 0;
                        }
                        _obj.discount_in_amount = _value;
                        let _percDisPerc = 0;
                        if(_obj.discount_in_amount >= 0){
                            _percDisPerc = (parseFloat(_obj.discount_in_amount) * parseFloat(100)) / parseFloat(_totalItemAmount);
                        }
                        _percDisAmt = _obj.discount_in_amount;
                        _obj.discount_in_per = _percDisPerc.toFixed(2);
                    }
                    

                    if(type == 'taxType')
                    {
                        let _gstObj = {};
                        this.state.taxRateArray.map((tax,index) => {
                            let _sel = '';
                            if(_value == tax.id)
                            {
                                _gstObj.id = tax.id;
                                _gstObj.name = tax.name;
                                _gstObj.ratio = tax.ratio;

                                if(tax.ratio >= 0)
                                {
                                    _percGstAmt = (parseFloat(_totalItemAmount) * parseFloat(tax.ratio)) / parseFloat(100);
                                }
                            }
                        });
                        _obj.tax_type = _gstObj;
                        _obj.tax_value = _percGstAmt.toFixed(2);
                    }
                    

                    /* Total Calculation */
                    //console.log(parseFloat(_totalItemAmount)+'-----'+parseFloat(_obj.tax_value)+'-----'+parseFloat(_obj.discount_in_amount));
                    let _itemSubtotal = parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value) - parseFloat(_obj.discount_in_amount);
                    _obj.subtotal = _itemSubtotal.toFixed(2);
                    _obj.total_amount = _totalItemAmount.toFixed(2);
                    _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);
                    _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.receivedAmount);
                    _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
                    
                    if(_obj.discount_in_amount > 0){
                        _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(_obj.discount_in_amount);
                    }
                    else{
                        _totalDiscountAmt = 0;
                    }

                    if(_obj.tax_value > 0){
                        _totalGstAmt = parseFloat(_totalGstAmt) + parseFloat(_obj.tax_value);
                    }
                    else{
                        _totalGstAmt = 0;
                    }
                    /* End Total Calculation */
                    
                    fieldArray[_itemPosition] = _obj;
                }
                else if(_itemPosition == 0 && type == '')
                {
                    let _obj = {};
                    let _objUnit = {unit_id:'0',unit_name:''};
                    let _objRateTax = {value:'1',label:this.state.rate_tax};
                    let _objTaxType = {id:'0',name:'',ratio:'0'};
                    
                    _obj.unitArrayOld = _unitindividual;
                    _obj.item_id = 0;
                    _obj.item_name = '';
                    _obj.quantity = 0;
                    _obj.unit = _objUnit;
                    _obj.rate = 0;
                    _obj.rate_tax = _objRateTax;
                    _obj.discount_in_per = 0;
                    _obj.discount_in_amount = 0;
                    _obj.tax_type = _objTaxType;
                    _obj.tax_value = 0;
                    _obj.subtotal = 0;
                    _obj.total_amount = 0;
                    fieldArray[_itemPosition] = _obj;
                }
                else
                {
                    let _itemId = (field.item_id) ? field.item_id : 0;
                    if(_itemId > 0)
                    {
                        let _obj = {};
                        _obj.unitArrayOld = _unitindividual;
                        _obj.item_id = (field.item_id) ? field.item_id : 0;
                        _obj.item_name = field.item_name;
                        _obj.quantity = (field.quantity) ? field.quantity : '0';
                        _obj.rate = (field.rate) ? field.rate : 0;
                        _obj.discount_in_per = (field.discount_in_amount) ? field.discount_in_amount : 0;
                        _obj.discount_in_amount = (field.discount_in_per) ? field.discount_in_per : 0;
                        _obj.tax_value = (field.tax_value) ? field.tax_value : 0;
                        _obj.subtotal = (field.subtotal) ? field.subtotal : 0;
                        _obj.total_amount = (field.total_amount) ? field.total_amount : 0;

                        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);
                        
                        _receivedAmount = this.state.receivedAmount;
                        _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.receivedAmount);
                        _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
                        _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(_obj.discount_in_amount);
                        _totalGstAmt = parseFloat(_totalGstAmt) + parseFloat(_obj.discount_in_per);
                    }
                }
            });
            
            if(this.state.toggleState == true){
                this.setState({fields:fieldArray,totalQuantity:_quantity,dueAmount:0,receivedAmount:0,totalAmount:_totalAmt.toFixed(2),gstAmount : _totalGstAmt.toFixed(2),totalDiscount:_totalDiscountAmt.toFixed(2)});    
            }
            else{
                this.setState({fields:fieldArray,totalQuantity:_quantity,dueAmount:_dueAmount.toFixed(2),totalAmount:_totalAmt.toFixed(2),gstAmount : _totalGstAmt.toFixed(2),totalDiscount:_totalDiscountAmt.toFixed(2)});
            }
            
        }
        this.onMouseLeaveHandlerItem('I',_itemPosition);
        this.onItemFilterName();
    }

    dynamicInputFiled(){
        let _dataSet = [];
        if(this.state.fields.length > 0)
        {
            let _j = 0;
            
            if(this.state.transactionId > 0){
                this.state.fields.map((fields,ind) =>{
                    this.state.itemArrayFilter.map((items, i) =>{
                        if(fields.item_id == items.item_id){
                            let _itemUnitArray = [];
                            let _itmUnit = (items.unit) ? (items.unit) : '';
                            if(_itmUnit == ''){
                                _itemUnitArray = this.state.unitArray;
                            }
                            else{
                                _itemUnitArray.push((items.unit) ? (items.unit) : '');
                            }
                            fields.unitArrayOld = _itemUnitArray;
                        }
                    });
                });
            }

            this.state.fields.map((field, idx) => {
                _j++;
                let _key = idx + 222;
                //console.log(field);
                let _hideMenuItem = 'iti__hide';
                if(this.state._itemRegIndex == idx){
                    _hideMenuItem = this.state._itemReg;
                }

                let _itemId = (field.item_id) ? field.item_id : 0;

                let _unitArray = (field.unit) ? field.unit : {unit_id:'0',unit_name:''};
                let _taxTypeArray = (field.tax_type) ? field.tax_type : {id:'0',name:'',ratio:'0'};
                let _taxTypeId = '0';
                if(_taxTypeArray.id > 0){
                    _taxTypeId = _taxTypeArray.id;
                }

                let _unitId = '0';
                if(_unitArray.unit_id > 0){
                    _unitId = _unitArray.unit_id;
                }
                
                //let _unitPreArray = field.unitArrayOld;
                
                let _unitPreArray = this.state.unitArray;
                if(_itemId == 0){
                    _unitPreArray = this.state.unitArray;
                }
                
                const itemTaxTypeData = [
                    { value: "With Tax", label: "With Tax" },
                    { value: "Without Tax", label: "Without Tax" },
                ];
                _dataSet.push(                    
                    <tr key={_key}>
                        <td>
                            {_j}
                        </td>
                        <td>
                            <div className="col-lg-12">
                                {/* onMouseEnter={this.manageShowItemFilter.bind(this,'I',idx)} onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)} */}
                                <div className="form-material open"  id="paddingOff" onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)}>
                                    <input
                                        readOnly="readOnly"
                                        type="text"
                                        className="form-control search-box"
                                        placeholder="Item Name Here..."
                                        autoComplete="off"
                                        name="item_name"
                                        id="item_name"
                                        value={(field.item_name) ? field.item_name : ''}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">                                
                                <input 
                                    readOnly="readOnly"
                                    type="number" min="0" maxLength="100"
                                    required="required" 
                                    className="form-control" 
                                    id={"_qty_"+idx}
                                    name={"_qty_"+idx}
                                    value={(field.quantity) ? field.quantity : ''}
                                />
                            </div>
                        </td>
                        {_businessSetting.FreeItemQuantity >0 &&
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">                                
                                <input 
                                    readOnly="readOnly"
                                    type="number" min="0" maxLength="100"
                                    required="required" 
                                    className="form-control" 
                                    id={"_free_quantity_"+idx}
                                    name={"_free_quantity_"+idx}
                                    value={(field.free_quantity) ? field.free_quantity : ''}
                                />
                            </div>
                        </td>
                        }
                        {_businessSetting.Count >0 &&
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">                                
                                <input 
                                    readOnly="readOnly"
                                    type="number" min="0" maxLength="100"
                                    required="required" 
                                    className="form-control" 
                                    id={"_trans_count_"+idx}
                                    name={"_trans_count_"+idx}
                                    value={(field.trans_count) ? field.trans_count : ''}
                                />
                            </div>
                        </td>
                        }
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <select disabled="disabled" style={{width: '90px'}} className="form-control" id={"seUnit_"+idx} name={"seUnit_"+idx}>
                                    <option value="" selected>None</option>
                                    {
                                        _unitPreArray.map((item,index) => {
                                            let _selBase = '';
                                            if(_unitId == item.unit_id){_selBase = 'Selected';}
                                            return(            
                                            <option key={index} value={item.unit_name} selected={_selBase}>{item.unit_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </td>
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">                                
                                <input 
                                    readOnly="readOnly"
                                    type="number" min="0" maxLength="100"
                                    required="required" 
                                    className="form-control" 
                                    id={"_Additional_CESS_"+idx}
                                    name={"_Additional_CESS_"+idx}
                                    value={(field.Additional_CESS) ? field.Additional_CESS : ''}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <input 
                                    type="number" min="0" 
                                    required="required" 
                                    className="form-control" 
                                    id={"rate_"+idx}
                                    name={"rate_"+idx}
                                    value={(field.rate) ? field.rate : ''}
                                    readOnly="readOnly"
                                />
                            </div>
                        </td>
                        
                        {_businessSetting.AllowInclusiveExclusive == 1 && (
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                            <select
                                style={{ width: "90px" }}
                                className="form-control"
                                id={"salePurTaxType_" + idx}
                                name={"salePurTaxType_" + idx}
                                //onChange={this.handleDynamicInputSalePurTaxType.bind(this,idx,"salePurTaxType")}
                            >
                                {itemTaxTypeData.map(({ value, label }, index) => {
                                let _selSetTaxT = "";
                                if (field.sale_pruchase_tax_type == value) {
                                    _selSetTaxT = "selected";
                                }
                                return (
                                    <option
                                    key={index}
                                    value={value}
                                    selected={_selSetTaxT}
                                    >
                                    {label}
                                    </option>
                                );
                                })}
                                {/* <option  value="With Tax" >With Tax</option>
                                <option  value="Without Tax" >Without Tax</option> */}
                            </select>
                            </div>
                        </td>
                        )}
                        <td>
                            <tr>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <input readOnly="readOnly" type="number" style={{width: '60px'}} min="0" maxLength="99" className="form-control" id={"discount_in_per_"+idx} value={(field.discount_in_per) ? field.discount_in_per : ''} name={"discount_in_per_"+idx}  autoComplete="off"/>
                                    </div>
                                </td>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <input readOnly="readOnly" type="number" min="0" maxLength="99999" className="form-control" id={"discount_in_amount_"+idx} value={(field.discount_in_amount) ? field.discount_in_amount : ''} name={"discount_in_amount_"+idx}  autoComplete="off"/>
                                    </div>
                                </td>
                            </tr>
                        </td>
                        <td>
                            <tr>
                                <td>
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <select disabled="disabled" style={{width: '85px'}} className="form-control" id={"taxType_"+idx} name={"taxType_"+idx}>
                                            <option value="" selected> None</option>
                                            {
                                                this.state.taxRateArray.map((tax,index) => {
                                                    let _sel = '';
                                                    if(_taxTypeId  == tax.id){_sel = 'selected';}
                                                    return(            
                                                        <option key={index} value={tax.id} selected={_sel}>{tax.name+'@'+tax.ratio+'%'}</option>
                                                    )
                                                })
                                            }
                                        </select> 
                                    </div>
                                </td>
                                <td> 
                                    <div className="col-lg-12 form-material" id="paddingOff">
                                        <input readOnly="readOnly" type="number"  min="0" maxLength="100" className="form-control" value={(field.tax_value) ? field.tax_value : ''} id={"tax_value_"+idx} name={"tax_value_"+idx}/>
                                    </div>
                                </td>
                            </tr>
                        </td>
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <input readOnly="readOnly" type="text"  className="form-control" id={"subtotal_"+idx} name={"subtotal_"+idx} value={(field.subtotal) ? field.subtotal : ''}/>
                            </div>
                        </td>
                    </tr>                    
                );
            });
        }
        return _dataSet;
    }
    /* End Add More Data */
    
    /* Add Party Code */
    selectPartiess(name,e){
        let errors = this.state.errors;
        errors.party_name = "";
        this.setState({
            _partyReg:'iti__hide',
            party_name:name,
            party_id:e
        })
    }

    getReadyPartyList(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            let _partyArray = this.state.partyArrayFilter;
            if(_partyArray.length > 0){
                data = this.state.partyArrayFilter.map((party, i) =>{

                    let _balance = party.party_opening_balance;
                    return(
                        <li key={i} className="iti__party iti__standard" id="iti-item-in" onClick={this.selectPartiess.bind(this,party.party_name,party.party_id)}>
                            <span className="iti__party-name">{party.party_name}</span>
                            <span className="iti__party-balance">{numberFormat(_balance)}</span>
                        </li>
                    )
                });
            }
        }
        return data;
    }
    
    onMouseLeaveHandler(key) {
        if(key == 'I'){
            this.setState({_partyReg:'iti__hide'});
        }
    }

    manageShowPartyFilter(key){
        if(key == 'I'){
            this.setState({_partyReg:''});
        }
    }

    onPartyFilterName = (e) => {
        const data = this.state.partyArray;
        let _name = e.currentTarget.value;
        const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
        const filtered = data.filter(entry => Object.values(entry).some(val => typeof val == "string" && val.includes(keyword)));
        this.setState({partyArrayFilter:filtered,party_name:keyword});
        this.getReadyPartyList();
    }
    /* End Party Code */

    /* Start Item Code */
    onItemFilterName = () => {
        const data = this.state.itemArray;
        let fieldArray = this.state.fields;
        if(fieldArray.length > 0){
            fieldArray.map((field, index) => {
                data.filter(function(item,index) { 
                    if(item.item_id == field.item_id){
                        //data[index].isFilter = true;
                    }
                });
            });
        }
        this.setState({itemArrayFilter:data});
        this.getReadyItemList();
    }

    onItemFilterTypeName = (idx,e) => {
        const data = this.state.itemArray;
        let _name = e.target.value;
        const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
        const filtered = data.filter(entry => Object.values(entry).some(val => typeof val == "string" && val.includes(keyword)));

        const fieldArray = this.state.fields;
        fieldArray.map((field, index) => {
            if(index == idx){
                field.item_name = keyword;
            }
        });

        this.setState({itemArrayFilter:filtered,item_name:keyword,fields:fieldArray});
        this.getReadyItemList();
    }
    
    manageShowItemFilter(key,idx){
        if(key == 'I'){
            this.setState({_itemReg:'',_itemRegIndex:idx});
        }
    }

    onMouseLeaveHandlerItem(key,idx){
        if(key == 'I'){
            this.setState({_itemReg:'iti__hide',_itemRegIndex:idx});
        }
    }

    manageShowPartyFilterItem(key){
        if(key == 'I'){
            this.setState({_itemReg:''});
        }
    }
    
    getReadyItemList(index){
        let data = [];
        if(this.state.isLoaded == true)
        {
            let _itemsArray = this.state.itemArrayFilter;
            if(_itemsArray.length > 0){
                data = this.state.itemArrayFilter.map((items, i) =>{
                    if(items.isFilter == false){
                        let _obj={};
                        let _itemUnitArray = [];
                        _itemUnitArray.push((items.unit) ? (items.unit) : '');
                        _obj.index = index;
                        _obj.name = items.name;
                        _obj.id = items.item_id;
                        _obj.price = items.sale_price;
                        _obj.unitindividual = _itemUnitArray;
                        
                        return(
                            <li key={i} className="iti__party iti__standard" id="iti-item-in" onClick={this.handleDynamicInputs.bind(this,_obj,'item')}>
                                <span className="iti__party-name">{items.name}</span>
                            </li>
                        )
                    }
                });
            }
        }
        return data;
    } 
    /* End Item Code */

    
    /* Image Code */
    onChangeFileUploadHandler=(event)=>{
        if (event.target.files) {
            /* Get files in array form */
            const files = Array.from(event.target.files);    
            /* Map each file to a promise that resolves to an array of image URI's */ 
            Promise.all(files.map(file => {
                return (new Promise((resolve,reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
            .then(images => {
                this.setState({ descImage : images })
            }, error => {        
                console.error(error);
            });
        }
    }

    previewImagesListing()
    {   
        if(this.state.isLoaded == true)
        {
            let dataList = [];
            let _imageDetails = this.state.imagePreviewUrl;
            if(_imageDetails!=''){
                dataList.push(
                    <img style={{width:'35px'}} className="preview" src={_imageDetails} alt={"image-"}  key="13213"/>
                )
            }
            return dataList;
        }
    }
    /* End Here*/

    handleCallBackDate = (start) => {
        this.setState({invoiceDate:start.format('MMMM D, YYYY')});
    };

    handleCallBackDateDue = (start) => {
        this.setState({dueDate:start.format('MMMM D, YYYY')});
    };
    
    settleAmountType(){
        let _payData = [];
        
        //console.log(this.state.bankId+'------'+_isGetData+'-----'+this.state.paymentType)
        if(_isGetData == true)
        {
            if(this.state.bankId > 0 && this.state.paymentType == 'Bank')
            {
                _payData.push(<option value={this.state.bankId}>{this.state.bankName}</option>);
            }
            else{
                _payData.push(<option value={this.state.paymentType}>{this.state.paymentType}</option>);
            }
        }
        return _payData;
    }

    isDateRefresh(){
        let _invoiceDate = this.state.invoiceDate;
        if(_isEditMode == true && this.state.transactionId > 0){
            return(
                <input type="text" className="form-control" value={_invoiceDate} readOnly="readOnly"/>
            );
        }
        else if(this.state.isLoaded == true && _isEditMode == false){
            return(
                <input type="text" className="form-control" value={_invoiceDate} readOnly="readOnly"/>
            );
        }
    }

    isDateDueRefresh(){
        let _invoiceDueDate = this.state.dueDate;
        if(_isEditMode == true && this.state.transactionId > 0){
            return(
                <input type="text" className="form-control" value={_invoiceDueDate} readOnly="readOnly"/>
            );
        }
        else if(this.state.isLoaded == true && _isEditMode == false){
            return(
                <input type="text" className="form-control" value={_invoiceDueDate} readOnly="readOnly"/>
            );
        }
    }

    render()
    {   
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        let _hideRows = 'iti__hide';
        let _readonlyInput = false;
        let _readonlyCss = "";
        if(this.state.fields.length > 0){
            _hideRows = '';
            _readonlyInput = true;
            _readonlyCss = "disabled";
        }
        else{
            if(this.state.totalAmount > 0){
                _hideRows = '';
                _readonlyInput = false;
                _readonlyCss = "";
            }
        }

        let _dueDateTxt = 'row';
        let _itemRowTxt = 'row';
        if(this.state.type == 'PAYMENT-OUT'){
            _dueDateTxt = 'hide';
            _itemRowTxt = 'hide';
            _hideRows = 'hide';
        }
        else if(this.state.type == 'PAYMENT-IN'){
            _dueDateTxt = 'hide';
            _itemRowTxt = 'hide';
            _hideRows = 'hide';
        }
        else if(this.state.type == 'DELIVERY CHALLAN'){
            _dueDateTxt = 'hide';
            _hideRows = 'hide';
        }
        else if(this.state.type == 'ESTIMATE'){
            _hideRows = 'hide';
        }

        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="block">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="block block-transparent">
                                    <div className="block-content" id="formSideLeft">
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <div className="form-material open" onMouseLeave={this.onMouseLeaveHandler.bind(this,'I')}>
                                                    <input
                                                        readOnly="readOnly"
                                                        type="text"
                                                        className="form-control search-box"
                                                        placeholder="Search Party Name Here..."
                                                        autoComplete="off"
                                                        name="party_name"
                                                        id="party_name"
                                                        value={this.state.party_name}
                                                    />
                                                    <label htmlFor="login-username">Party Name <span className="text-danger">*</span></label>
                                                    <div id="party_name-error" className="animated fadeInDown">{errors.party_name.length > 0 && <span className='error'>{errors.party_name}</span>}</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="block block-transparent">
                                    <div className="block-content">
                                        <div className="" id="formSideRight">
                                            <div className="form-group row">
                                                <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="val-username">Order No. <span className="text-danger">*</span></label>
                                                <div className="col-lg-8 form-material" id="paddingOff">
                                                    <input readOnly="readOnly" type="text" className="form-control" id="invoiceNo" name="invoiceNo" required="required" onChange={this.handleChange} placeholder="Enter a Order No." autoComplete="off" value={this.state.invoiceNo}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="val-username">Order Date<span className="text-danger">*</span></label>
                                                <div className="col-lg-8 form-material" id="paddingOff">
                                                    {this.isDateRefresh()}
                                                </div>
                                            </div>
                                            
                                            {this.state.dueDate !=0 && 
                                            <div className={"form-group "+_dueDateTxt}>
                                                <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="val-username">Due Date<span className="text-danger">*</span></label>
                                                <div className="col-lg-8 form-material" id="paddingOff">
                                                    {this.isDateDueRefresh()}
                                                </div>
                                            </div>
                                            }
                                            <div className="form-group row">
                                                <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="val-username">State of Supply</label>
                                                <div className="col-lg-8 form-material" id="paddingOff">
                                                    <select disabled="disabled" className="form-control " id="countryState" name="countryState" required="required"  onChange={this.handleChange}>
                                                        <option value=""> None</option>
                                                        {
                                                            this.state.stateArray.map((item,index) => {
                                                                let _sel = '';
                                                                if(this.state.state == item.name){_sel = 'selected';}
                                                                return(            
                                                                <option key={index} value={item.name} selected={_sel}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-material open">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.isItemNumber > 0 &&                                
                        <div className={_itemRowTxt}>
                            <div className="col-md-12" id="formSideItem">
                                <div className="block block-transparent">
                                    <div className="block-content" id="formSideItem">
                                        <table className="table table-bordered table-striped table-vcenter">
                                            <thead id="sideTable">
                                                <tr >
                                                    <th style={{width:"30px"}}>
                                                        #
                                                    </th>
                                                    <th style={{width:"200px"}}>
                                                        Item
                                                    </th>
                                                    <th style={{width:"50px"}}>
                                                        Qty
                                                    </th>
                                                    
                                                    {_businessSetting.FreeItemQuantity == 1 && (
                                                    <th style={{ width: "50px" }}>Free Qty</th>
                                                    )}
                                                    {_businessSetting.Count == 1 && (
                                                    <th style={{ width: "50px" }}>Count</th>
                                                    )}
                                                    <th style={{width:"90px"}}>
                                                        Unit
                                                    </th>
                                                    {_businessSetting.AdditionalCESS == 1 && (
                                                    <th style={{ width: "90px" }}>Additional CESS</th>
                                                    )}
                                                    <th style={{width:"150px"}}>
                                                        Price / Unit
                                                        {/* <tr><td style={{width:"194px"}}>Price / Unit</td></tr>
                                                        <tr>
                                                            <td style={{width:"140px"}}>
                                                            <select disabled="disabled" name="rate_tax" id="rate_tax" onChange={this.handleChange}>
                                                                <option value="With Tax">With Tax</option>
                                                                <option value="Without Tax">Without Tax</option>
                                                            </select>
                                                            </td>
                                                        </tr> */}
                                                    </th>
                                                    
                                                    {_businessSetting.AllowInclusiveExclusive == 1 && (
                                                    <th style={{ width: "150px" }}> Tax Type </th>
                                                    )}
                                                    <th style={{width:"150px"}}>
                                                        <tr><td style={{width:"194px"}} colSpan="2">Discount</td></tr>
                                                        <tr>
                                                            <td style={{width:"130px"}}>%</td>
                                                            <td style={{width:"100px"}}>Amount</td>
                                                        </tr>
                                                    </th>
                                                    <th style={{width:"150px"}}>
                                                        <tr><td style={{width:"194px"}} colSpan="2">Tax</td></tr>
                                                        <tr>
                                                            <td style={{width:"50px"}}>%</td>
                                                            <td style={{width:"100px"}}>Amount</td>
                                                        </tr>
                                                    </th>
                                                    <th style={{width:"100px"}}>
                                                        Amount
                                                    </th>
                                                </tr>
                                                
                                            </thead>
                                        
                                            <tbody id="sideTable">
                                                {this.dynamicInputFiled()}
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <span className="titleTable">Total</span>
                                                    </td>
                                                    <td>
                                                        <span className="titleTable">{this.state.totalQuantity}</span>
                                                    </td>
                                                    {_businessSetting.FreeItemQuantity > 0 && <td></td>}
                                                    {_businessSetting.Count > 0 && <td></td>}
                                                    <td>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <span className="titleTable">{this.state.totalDiscount}</span>
                                                    </td>
                                                    <td>
                                                        <span className="titleTable">{this.state.gstAmount}</span>
                                                    </td>
                                                    <td>
                                                        <span className="titleTable">{(this.state.totalQuantity > 0) ? this.state.totalAmount : 0}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-6">
                                <div className="block block-transparent">
                                    <div className="block-content" id="formSideLeft">
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="form-material open" id="paddingOff">
                                                    <select disabled="disabled" className="col-8 form-control" defaultValue={(this.state.bankId > 0) ? this.state.bankId : this.state.paymentType} id="paymentType" name="paymentType" onChange={this.handleChange} value={this.state.paymentType}>
                                                        {this.settleAmountType()}
                                                    </select>
                                                </div>
                                                {this.state.paymentType !='Cash'  &&
                                                <div className="form-material open">
                                                    <div className="col-8 form-material" id="paddingOff">
                                                        <input type="text" readOnly="readOnly" className="form-control" value={this.state.payment_ref_no} required="required" id="paymentRefNo" name="paymentRefNo" onChange={this.handleChange}  placeholder="Enter Reference No." autoComplete="off" />
                                                    </div>
                                                </div>
                                                }

                                                <div className="form-material open">
                                                    <div className="col-12 form-material" id="paddingOff">
                                                        <input type="text" readOnly="readOnly" className="form-control" required="required" id="description" name="description" onChange={this.handleChange}  placeholder="Enter a description" autoComplete="off" value={this.state.description} />
                                                    </div>
                                                </div>                                               
                                                <div className="form-material open">
                                                     <div className="col-8 form-material" id="paddingOff">
                                                        {this.previewImagesListing()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="block block-transparent">
                                    <div className="block-content">
                                        <div className="col-md-12" id="formSideRight">
                                        {this.state.fields.length > 0 && (
                                            <div id="taxDisData">
                                                {_businessSetting.TransactionWiseDiscount == 1 && (
                                                <div className="col-md-12" id="formSideRight">
                                                    <div className="form-group row">
                                                    <label
                                                        style={{
                                                        textAlign: "right",
                                                        margin: "25px 0 0 0",
                                                        }}
                                                        className="col-lg-4 col-form-label"
                                                        htmlFor="totalAmount"
                                                    >
                                                        Discount %
                                                    </label>
                                                    <div
                                                        className="col-lg-4 form-material"
                                                        id=""
                                                        style={{
                                                        padding: "0 !important",
                                                        margin: "0 !important",
                                                        }}
                                                    >
                                                        <input
                                                        type="text"
                                                        id="totalTransDiscountPer"
                                                        name="totalTransDiscountPer"
                                                        onChange={this.handleChange}
                                                        placeholder="In %"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.totalTransDiscountPer}
                                                        />
                                                    </div>
                                                    <div
                                                        className="col-lg-4 form-material"
                                                        id=""
                                                        style={{
                                                        padding: "0 !important",
                                                        margin: "0 !important",
                                                        }}
                                                    >
                                                        <input
                                                        type="text"
                                                        id="totalTransDiscountAmount"
                                                        name="totalTransDiscountAmount"
                                                        onChange={this.handleChange}
                                                        placeholder=""
                                                        className="form-control"
                                                        value={this.state.totalTransDiscountAmount}
                                                        autoComplete="off"
                                                        style={{
                                                            padding: "0 !important",
                                                            margin: "0 !important",
                                                        }}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                )}

                                                {_businessSetting.TransactionWiseTax == 1 && (
                                                <div className="col-md-12" id="formSideRight">
                                                    <div className="form-group row">
                                                    <label
                                                        style={{
                                                        textAlign: "right",
                                                        margin: "25px 0 0 0",
                                                        }}
                                                        className="col-lg-4 col-form-label"
                                                        htmlFor="totalAmount"
                                                    >
                                                        Tax{" "}
                                                    </label>
                                                    <div
                                                        className="col-lg-4 form-material"
                                                        id="paddingOff"
                                                        style={{
                                                        padding: "0 !important",
                                                        margin: "0 0 !important",
                                                        }}
                                                    >
                                                        <select
                                                        style={{ width: "85px" }}
                                                        className="form-control"
                                                        id="taxTypeTrans"
                                                        name="taxTypeTrans"
                                                        disabled="disabled"
                                                        >
                                                        <option value="0@0" selected>
                                                            {" "}
                                                            None
                                                        </option>
                                                        {this.state.taxRateArray.map((tax, index) => {
                                                            let _sel = "";
                                                            if (
                                                            this.state.transactionWiseTaxId == tax.id
                                                            ) {
                                                            _sel = "selected";
                                                            }
                                                            return (
                                                            <option
                                                                key={index}
                                                                value={tax.id + "@" + tax.ratio}
                                                                selected={_sel}
                                                            >
                                                                {tax.name + "@" + tax.ratio + "%"}
                                                            </option>
                                                            );
                                                        })}
                                                        </select>
                                                    </div>
                                                    <div
                                                        className="col-lg-4 form-material"
                                                        id="paddingOff"
                                                        style={{
                                                        padding: "0 !important",
                                                        margin: "0 !important",
                                                        }}
                                                    >
                                                        <input
                                                        type="text"
                                                        id="totalTransTaxAmount"
                                                        name="totalTransTaxAmount"
                                                        placeholder=""
                                                        className={"form-control " + _readonlyCss}
                                                        autoComplete="off"
                                                        value={this.state.totalTransTaxAmount}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                            )}
                                            {/*Additional Charges */}
                                            {_businessSetting.TransactionAdditionalChargeEnable ==
                                            1 && (
                                            <div id="taxDisData">
                                                {_businessSetting.TransactionAdditionalCharge1Enable ==
                                                1 && (
                                                <div className="col-md-12" id="formSideRight">
                                                    <div className="form-group row">
                                                    <label
                                                        style={{ textAlign: "right" }}
                                                        className="col-lg-4 col-form-label"
                                                        htmlFor="TransAdditionalCharge1"
                                                    >
                                                        {
                                                        _businessSetting.TransactionAdditionalCharge1Name
                                                        }
                                                    </label>
                                                    <div
                                                        className="col-lg-8 form-material"
                                                        id="paddingOff"
                                                    >
                                                        <input
                                                        type="number"
                                                        className="form-control"
                                                        id="TransAdditionalCharge1"
                                                        name="TransAdditionalCharge1"
                                                        onChange={this.handleChange}
                                                        placeholder=""
                                                        value={this.state.TransAdditionalCharge1}
                                                        autoComplete="off"
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                )}

                                                {_businessSetting.TransactionAdditionalCharge2Enable ==
                                                1 && (
                                                <div className="col-md-12" id="formSideRight">
                                                    <div className="form-group row">
                                                    <label
                                                        style={{ textAlign: "right" }}
                                                        className="col-lg-4 col-form-label"
                                                        htmlFor="TransAdditionalCharge2"
                                                    >
                                                        {
                                                        _businessSetting.TransactionAdditionalCharge2Name
                                                        }
                                                    </label>
                                                    <div
                                                        className="col-lg-8 form-material"
                                                        id="paddingOff"
                                                    >
                                                        <input
                                                        type="number"
                                                        className="form-control"
                                                        id="TransAdditionalCharge2"
                                                        name="TransAdditionalCharge2"
                                                        onChange={this.handleChange}
                                                        placeholder=""
                                                        value={this.state.TransAdditionalCharge2}
                                                        autoComplete="off"
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                )}

                                                {_businessSetting.TransactionAdditionalCharge3Enable ==
                                                1 && (
                                                <div className="col-md-12" id="formSideRight">
                                                    <div className="form-group row">
                                                    <label
                                                        style={{ textAlign: "right" }}
                                                        className="col-lg-4 col-form-label"
                                                        htmlFor="TransAdditionalCharge3"
                                                    >
                                                        {
                                                        _businessSetting.TransactionAdditionalCharge3Name
                                                        }
                                                    </label>
                                                    <div
                                                        className="col-lg-8 form-material"
                                                        id="paddingOff"
                                                    >
                                                        <input
                                                        type="number"
                                                        className="form-control"
                                                        id="TransAdditionalCharge3"
                                                        name="TransAdditionalCharge3"
                                                        onChange={this.handleChange}
                                                        placeholder=""
                                                        value={this.state.TransAdditionalCharge3}
                                                        autoComplete="off"
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                            )}
                                            {/*End Additional Charges */}
                                            <div className="form-group row">
                                                <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="totalAmount">Total Amount</label>
                                                <div className="col-lg-8 form-material" id="paddingOff">
                                                    <input type="text" readOnly="readOnly" className={"form-control "+_readonlyCss} id="totalAmount" name="totalAmount" placeholder="" onChange={this.handleChange} value={this.state.totalAmount} autoComplete="off"/>
                                                </div>
                                            </div>
                                            <div className={_hideRows}>
                                                <div className="form-group row">
                                                    <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="receivedAmount">Advance Amount</label>
                                                    <div className="col-lg-8 form-material" id="paddingOff">
                                                        <input type="number" readOnly="readOnly" className="form-control" id="receivedAmount" name="receivedAmount" placeholder="" value={this.state.receivedAmount} onChange={this.handleChange} autoComplete="off"/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label style={{textAlign: 'right'}} className="col-lg-4 col-form-label" htmlFor="dueAmount">Balance Due</label>
                                                    <div className="col-lg-8 form-material" id="paddingOff">
                                                        <input type="text" readOnly="readOnly" className="form-control" id="dueAmount" name="dueAmount" placeholder="" value={this.state.dueAmount} autoComplete="off"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}