import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import validator from "validator";

import Modal from "react-modal";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTypeData = [];
let _countryCode = "IN";

let _bankArray = [];
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" }
  // { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _loader = "glb-ldr-prt active";

let _isEditMode = false;
export default class partyListPanel extends Component {
  constructor(props) {
    _isEditMode = false;
    super(props);

    //_bankArray.push({"bank_id":"0","bank_name":"","paymentType":"Cash"});
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTypeData = _companySession.taxType;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    this.state = {
      minDate: this.props.minDate
        ? this.props.minDate
        : [moment().subtract(7, "days")],
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-loan-accounts/",
      buttonTitle: "",
      hideShow: "",
      isLoaded: true,
      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,
      loanAccountTrasacId: this.props.loanAccountId,
      loanTrasactionId: this.props.loanTrasactionId,
      amount: "",
      received_in: "Cash",
      bankId: "0",
      pay_date: moment().format("MM/DD/YYYY"),
      errors: {
        amount: "",
        received_in: "",
      },
    };
  }

  async componentDidMount() {
    let _loanAccountTrasacId = this.props.loanTrasactionId;

    if (_loanAccountTrasacId > 0) {
      _isEditMode = true;
      const requestData = JSON.stringify({
        loanTrasacId: _loanAccountTrasacId,
      });

      PostData(global.userLoanAccountTrasactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transArray = responseJson.response[0];

              this.setState({
                buttonTitle: "Edit",
                loan_detail_id: _transArray.loan_detail_id,
                loan_id: _transArray.loan_id,
                amount: _transArray.amount,
                loan_received: _transArray.loan_received,
                bankId: _transArray.received_bank_id,
                received_in: _transArray.loan_received,
                pay_date: moment(_transArray.pay_date).format("MM/DD/YYYY"),
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "amount":
        if (validator.isInt(value) && value > 0) {
          errors.amount = "";
        } else {
          errors.amount = "*Amount should be greater than 0!";
        }
        this.setState({ amount: value });
        break;

      case "received_in":
        errors.received_in = value.length < 1 ? "*Select payment type!" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }
        //this.setState({received_in:value});

        let _bankId = 0;
        let _bankName = "";
        let _valType = "";
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        //console.log(_bankId+'--'+_bankName+'---'+value+'----'+_valType);
        this.setState({
          received_in: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      default:
        break;
    }
  };

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.received_in == "") {
      _isError = true;
      errors["received_in"] = "*Please Select Loan Received In";
      this.setState({ errors: errors });
    }

    if (this.state.amount == "") {
      _isError = true;
      errors["amount"] = "*Please Enter amount.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.loanTrasactionId > 0) {
        this.editLoanTrasacDMethodCall();
      } else {
        this.addLoanTrasacDMethodCall();
      }
      //this.addLoanTrasacDMethodCall();
    }
  };
  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;
          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.received_bank_id) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.loan_received) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }
  settleAmountTypeOld() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.received_in) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  addLoanTrasacDMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      loan_id: this.state.loanAccountTrasacId,
      amount: this.state.amount,
      pay_date: moment(this.state.pay_date).format("MMMM D, YYYY"),
      received_in: this.state.received_in,
      bankId: this.state.bankId,
      bankName: this.state.bankName,
    });
    PostData(global.userAddLoanIncreaseAmount, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editLoanTrasacDMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      loan_detail_id: this.state.loanTrasactionId,
      loan_id: this.state.loanAccountTrasacId,
      amount: this.state.amount,
      pay_date: moment(this.state.pay_date).format("MMMM D, YYYY"),
      received_in: this.state.received_in,
      bankId: this.state.bankId,
      bankName: this.state.bankName,
    });
    PostData(global.userEditLoanIncreaseAmount, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  handleCallBackDate = (start) => {
    this.setState({ pay_date: start.format("MMMM D, YYYY") });
  };

  isDateRefresh() {
    let _pay_date = this.state.pay_date;
    let _given = moment(this.state.minDate, "MMMM D, YYYY");
    let _current = moment().startOf("day");
    let _remianDays =
      moment.duration(_given.diff(_current)).asDays() < 0
        ? moment.duration(_given.diff(_current)).asDays()
        : 7;
    _remianDays = Math.abs(_remianDays);

    if (_isEditMode == true && this.state.loanTrasactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _pay_date,
            minDate: moment().subtract(_remianDays, "days"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.state.loanTrasactionId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _pay_date,
            minDate: moment().subtract(_remianDays, "days"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="amount"
                  name="amount"
                  value={this.state.amount}
                  placeholder="Enter Amount"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Enter Amount <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.amount.length > 0 && (
                    <span className="error">{errors.amount}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="col-8 form-control"
                  defaultValue={
                    this.state.bankId > 0
                      ? this.state.bankId
                      : this.state.received_in
                  }
                  id="received_in"
                  name="received_in"
                  onChange={this.handleChange}
                >
                  {this.settleAmountType()}
                </select>
                <label htmlFor="login-username">Loan Received In</label>
                <span className="iris_error_txt">
                  {errors.received_in.length > 0 && (
                    <span className="error">{errors.received_in}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <div className="col-lg-8 form-material" id="paddingOff">
                  {this.isDateRefresh()}
                </div>
                <label htmlFor="login-username">Date</label>
                <span className="iris_error_txt">
                  {<span className="error">{errors.received_in}</span>}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SBANK}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewBank"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Bank</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewBank"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewBankDetail isRedirectTo={"business/add-sale/"} />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewBank"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}
      </React.Fragment>
    );
  }
}
