import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};
let _loader = 'glb-ldr-prt active';

export default class QuickAdditionalTransactionFieldPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,    
            modalIsOpen_Info: false,
            _infoData: '',
            infoTitle: '',
            additionalFieldsData : [],
            businessId : _businessId,
            company_id : _companyId,
        }
    }

    componentDidMount()
    {
        this.getTransAdditionalAllData();
    }

    getTransAdditionalAllData(){
        const requestData = JSON.stringify({
            businessId:this.state.businessId,
            company_id:this.state.company_id
        });
        PostData(global.businessTransAdditionalFieldList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {                    
                }
                this.setState({isLoaded:true,additionalFieldsData:responseJson.response});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id : this.state.company_id,
        });
        PostData(global.businessTransactionSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {                 
                
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                this.getTransAdditionalAllData();      
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (i,e) => {
        let activeValue = e.target.value;
        let _keyVal = e.target.attributes['data-slug'].value;
        let _keyId = e.target.attributes['data-id'].value;         
        //let additionalFieldsData = this.state.additionalFieldsData;
        //additionalFieldsData[i][_keyId] =_keyVal;
        //this.setState({additionalFieldsData});        
        const requestData = JSON.stringify({
            businessId : _businessId,
            company_id : this.state.company_id,            
            settingValue: activeValue,
            settingKey: _keyId,
        });
        PostData(global.businessTransactionSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            { 
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyId] = activeValue;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                this.getTransAdditionalAllData();                        
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/

    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',infoTitle:''});
        }
    }     
    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }        
        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        return(
            <React.Fragment> 
                <div className="block-content" >
                    <div className="col-md-12">                 
                        {
                            this.state.additionalFieldsData.map((termData,index) => {
                                let _titleName = termData.name;
                                let _titleTooltip = termData.tooltip;
                                let _titleTooltipLength = _titleTooltip ? _titleTooltip.length : 0;
                                let _dataLoop = termData.data;
                                let _dataMain = (<table cellPadding="0" cellSpacing="0"  >
                                    <tr><td style={{color : '#101010', marginTop  : '10px', fontWeight : 'bold',width:'100%'}}>
                                    {_titleName}&nbsp;&nbsp;
                                    {_titleTooltipLength > 0 &&
                                         <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_titleTooltip,'Info')}></i> 
                                    }
                                    </td>
                                    </tr><tr><td>&nbsp;</td></tr>
                                </table>);
                                 
                                let _innerData = _dataLoop.map((loopData,index) => {

                                    let _title =  loopData.title ? loopData.title : '';
                                    let _mainKey =  loopData.key ? loopData.key : '';

                                    let _labelName1 =  loopData.labelName1 ? loopData.labelName1 : '';
                                    let _labelkey1 =  loopData.labelkey1 ? loopData.labelkey1 : '';
                                    let _labelValue1 =  loopData.labelvalue1 ? loopData.labelvalue1 : ''; 
                                    let _labelName2 =  loopData.labelName2 ? loopData.labelName2 : '';
                                    let _labelkey2 =  loopData.labelkey2 ? loopData.labelkey2 : '';
                                    let _labelValue2 =  loopData.labelvalue2 ? loopData.labelvalue2 : '';
                                    
                                    let _showInPrintLabelName =  loopData.showInPrintLabelName ? loopData.showInPrintLabelName : '';
                                    let _showInPrintKey =  loopData.showInPrintKey ? loopData.showInPrintKey : '';
                                    let _showInPrintValue =  loopData.showInPrintValue ? loopData.showInPrintValue : 0;

                                    return(
                                    <div className="col-6 col-form-label" style={{marginBottom : '50px'}}>
                                        <table >
                                            <tr>
                                                <td width="100%" valign="top" align="left">
                                                    <input type="checkbox" defaultChecked={(loopData.value > 0) ? true : false} name={_mainKey} value="1"  data-slug={_mainKey} onChange={this.onFilterChange} id={_mainKey}  />
                                                    &nbsp;&nbsp;
                                                    <b style={{fontSize : '13px'}}>
                                                        {_title}
                                                    </b>
                                                </td>            
                                            </tr>
                                            { (_labelName1.length > 0 && loopData.value >0) &&
                                                <tr>
                                                    <td width="100%" valign="top" style={{padding : '0 0 0 22px'}} >
                                                        <label style={{color : '#ccc', fontSize : '13px' , marginBottom : '0'}} >{_labelName1}
                                                        </label>
                                                        <input type="text" style={{border : 'none',borderBottom : '1px #333 solid', width : '100%', marginBottom : '10px', padding : '6px'}}
                                                        value={_labelValue1} name={_labelkey1} id={_labelkey1} data-slug={_labelValue1} data-id={_labelkey1} onChangeCapture={e => this.handleLTxtKeyUp(index, e)} />
                                                    </td>
                                                </tr>
                                            }
                                            { (_labelName2.length > 0 && loopData.value >0) &&
                                                <tr>
                                                    <td width="100%" valign="top" style={{padding : '0 0 0 22px'}} >
                                                        <label style={{color : '#ccc', fontSize : '13px' , marginBottom : '0'}} >{_labelName2}
                                                        </label>
                                                        <input type="text" style={{border : 'none',borderBottom : '1px #333 solid', width : '100%', marginBottom : '10px', padding : '6px'}}
                                                        value={_labelValue2}  data-slug={_labelValue2} data-id={_labelkey2}  name={_labelkey2} id={_labelkey2} onChangeCapture={e => this.handleLTxtKeyUp(index, e)}/>
                                                    </td>
                                                </tr>
                                            }
                                            { (_showInPrintLabelName.length > 0 && loopData.value >0) &&
                                                <tr>
                                                <td width="100%" valign="middle" align="right">
                                                <b style={{fontSize:"13px"}}>Show in print</b>&nbsp;&nbsp;<input type="checkbox" defaultChecked={(_showInPrintValue > 0) ? true : false} name={_showInPrintKey} value="1"  data-slug={_showInPrintKey} onChange={this.onFilterChange} id={_showInPrintKey}  />
                                                    &nbsp;&nbsp;
                                                    
                                                </td>            
                                            </tr>
                                            }
                                        </table>
                                        </div>
                                   
                                    )

                                }) 
                                return(
                                    <div id="mainId">
                                        <div>{_dataMain}</div>
                                        <div className='form-group row'>{_innerData}</div><hr/>
                                    </div>
                                )                           
                            })
                        }
                    </div>
                </div>   

            {/* Modal  Tooltip Information Begins */}
            <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="block block-themed block-transparent mb-0">
                            <div className="block-header bg-primary-dark">
                                <h3 className="block-title">{this.state._infoTitle}</h3>
                                <div className="block-options">
                                    <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                        <i className="si si-close"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                            {parse(this.state._infoData)}
                            </div>
                            <br/>
                        </div>
                        
                    </div>
                </div>
            </Modal>
            {/* Modal End Tooltip Information*/}
            </React.Fragment>
        )
    }

}