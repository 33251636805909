import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class QuickDueDatesPaymentsTermsPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }


        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : false,     
            paymentTermsData : [],
            businessId : _businessId,
            company_id : _companyId,
            DueDatesPaymentTerms : _businessSetting.DueDatesPaymentTerms ? _businessSetting.DueDatesPaymentTerms : 0,
            
            modalIsOpen_AddTerm : false, 
            modalIsOpen_DeleteTerm : false, 
            terms_name : '',
            terms_days : '',
            termId:0,
            
            errors: {
                terms_name : '',
                terms_days: ''
            }
        }
    }

    componentDidMount()
    {
        this.getDueDatePaymentTermsAllData();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'terms_name': 
            errors.terms_name = 
                value.length < 3 ? '*Term name must be 3 characters long!' : '';
                this.setState({terms_name:value}); 
            break;
            case 'terms_days': 
                
                if(validator.isInt(value)){
                    errors.terms_days = '';                    
                    if(value == 0){
                        errors.terms_days = '*Terms days can not be 0!';
                    }
                } else {
                    if(value == 0){
                        errors.terms_days = '*Terms days can not be 0!';
                    }
                    errors.terms_days = '*Terms days must be 1!';
                }
                
                this.setState({terms_days:value}); 
            break;
            default:
            break;
        }
    }
    closeModalHandler = (e) => {
        if(e == 'openModalAddDueDateTerms'){
            this.setState({modalIsOpen_AddTerm: false});            
        }  
        else if(e == 'openModalDeleteDueDateTerms'){
            this.setState({modalIsOpen_DeleteTerm: false,termId:0});            
        }  
    }         
    
    openModalOtherTransac(e,dataId)
    {    
        if(e == 'openModalAddDueDateTerms'){
            this.setState({modalIsOpen_AddTerm: true});      
            if(dataId == 'Add'){
                this.setState({terms_days: '',terms_name:''});  
            }      
        }  
        else if(e == 'openModalDeleteDueDateTerms'){
            this.setState({modalIsOpen_DeleteTerm: true,termId:dataId});            
        }  
    }
    
    
    clickToAddTerms = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.terms_name == '')
        {
            _isError = true;
            errors['terms_name'] = "*Please Enter Terms Name";
            this.setState({errors: errors});
        }
        if(this.state.terms_days == '')
        {
            _isError = true;
            errors['terms_days'] = "*Please Enter Terms Days";
            this.setState({errors: errors});
        }
        
        if (_isError == false){
            this.addNewTermData();
        }

    }

    addNewTermData()
    {  
        const requestData = JSON.stringify({
            businessId : this.state.businessId,
            companyId: this.state.company_id,
            terms_name: this.state.terms_name,
            terms_days: this.state.terms_days,
        });  
        
        PostData(global.addNewTerms,requestData,'POST').then((result)=>{            
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {                        
                        //this.props.callBack('hello');
                        //window.location.href = global.webUrl+this.state.isRedirectTo;
                        this.setState({modalIsOpen_AddTerm:false});
                        this.getDueDatePaymentTermsAllData();
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
                this.setState({terms_name:'',terms_days:''});
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }

    getDueDatePaymentTermsAllData(){
        let _transArray = [];
        const requestData = JSON.stringify({
            businessId:this.state.businessId,
            company_id:this.state.company_id
        });

        PostData(global.businessDueDatePaymentTermList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});
            }
            else
            {
                let data = responseJson.response;
                data = data.map((item) => ({
                    ...item,
                    isEdit: false,
                }))
                this.setState({isLoaded:true,paymentTermsData:data});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
       
    clickToDelete = (event) => {        
        event.preventDefault();    
        const requestData = JSON.stringify({
            termId : this.state.termId
        });  
        
        PostData(global.deleteTerms,requestData,'POST').then((result)=>{            
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {                        
                        this.setState({modalIsOpen_DeleteTerm:false});
                        this.getDueDatePaymentTermsAllData();
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }

    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        

        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");
                this.setState({DueDatesPaymentTerms : _value});                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    setAsDefault= (i,e) => {
        let activeValue = e.target.value;
        let _keyVal = e.target.attributes['data-slug'].value;
        let _keyId = e.target.attributes['data-id'].value;


        let paymentTermsData = this.state.paymentTermsData;
        paymentTermsData = paymentTermsData.map((data,index) => ({
            ...data,
            terms_default_status: i === index ? 1 : 0
        }))
        //paymentTermsData[i][e.target.name] = e.target.value;
        this.setState({paymentTermsData: paymentTermsData});
        //console.log('paymentTermsData', JSON.stringify(paymentTermsData))

        const requestData = JSON.stringify({
            dueDateValue: paymentTermsData[i].terms_default_status, //activeValue,
            dueDateType: _keyVal,
            dueDateId: _keyId,
            businessId : _businessId,
            companyId : _companyId,
        });
        PostData(global.businessUpdateDueDate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                //sendNotification("Success Message 😓",result.message,"success");
                //this.setState({_keyVal:activeValue}); 
                //return;
                this.getDueDatePaymentTermsAllData();
                         
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/

    handleOnChange = (index, e) => {
        let key = e.target.attributes['data-slug'].value;
        let activeValue = e.target.value;
        console.log('key', key);
        console.log('activeValue', activeValue);

        let data = this.state.paymentTermsData.map((item, mainIndex) => ({
            ...item,
            [key]: index === mainIndex ? activeValue : item[key]
        }));
        this.setState({paymentTermsData: data})
    }
    updateTerm = (index) => {
        let paymentTermsData = this.state.paymentTermsData[index];
        let name = paymentTermsData.terms_name;
        if(name){
            let isNameExits = this.state.paymentTermsData.filter((data) => data.terms_name.toLowerCase() === name.toLowerCase() && paymentTermsData.id !== data.id).length > 0;
            if (isNameExits) {
                alert('Payment terms must have unique term name!');
                return;
            }
        }else {
            alert('Payment terms name cannot be empty!');
            return;
        }

        let day = paymentTermsData.terms_days ? parseInt(paymentTermsData.terms_days) : '';
        if (String(day) === '') {
            alert('Payment terms day cannot be empty!');
            return;
        }else{
            let isDayExits = this.state.paymentTermsData.filter((data) => data.terms_days === day && paymentTermsData.id !== data.id).length > 0;

            if (isDayExits) {
                alert('Payment terms must have unique term day!!');
                return;
            }
        }


        const requestData = JSON.stringify({
            dueDateNameValue: name,
            dueDateTermValue: day,
            dueDateValue: '',
            dueDateType: 'both',
            dueDateId: paymentTermsData.id,
            businessId : _businessId,
            companyId : _companyId,
        });

        PostData(global.businessUpdateDueDate, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                this.getDueDatePaymentTermsAllData();
                sendNotification("Success Message 👍",'setting details updated in system successfully.',"success");
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }


    enableEditMode = (index) => {
        let data = this.state.paymentTermsData.map((item, mainIndex) => ({
            ...item,
            isEdit: index === mainIndex,
        }));
        this.setState({paymentTermsData: data})
        return undefined;
    };
    disableEditMode = (index) => {
        let data = this.state.paymentTermsData.map((item, mainIndex) => ({
            ...item,
            isEdit: false,
        }));
        this.setState({paymentTermsData: data})
        return undefined;
    };

    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        
        const optionsData = [
            { value: 'Indian Eng 1,00,00,000', label: 'Indian Eg 1,00,00,000' },
            { value: 'English Eg 10,000,000', label: 'English Eg 10,000,000' },
        ];
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-12">
                        <h6 className="content-heading">Due Dates and Payments terms
                                <p style={{fontSize: '15px !important'}}>
                                    <span  style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Track overdue payments by enabling due dates and payments terms on sale and purchase invoices.
                                    &nbsp;&nbsp;
                                        <input type="checkbox" defaultChecked={(this.state.DueDatesPaymentTerms > 0) ? true : false} id="DueDatesPaymentTerms" name="DueDatesPaymentTerms" value="1" data-slug="DueDatesPaymentTerms" onChange={this.onFilterChange} />
                                    </span>
                                </p>
                        </h6>
                        {this.state.DueDatesPaymentTerms === 1 &&    
                            <button type="button"  className='btn btn-primary pull-right' onClick={this.openModalOtherTransac.bind(this,'openModalAddDueDateTerms','Add')} > Add Term</button>
                        }
                        {this.state.DueDatesPaymentTerms === 1 &&
                        <div style={{width : 'auto', padding : '15px', boxShadow : '0px 1px 3px rgba(0,0,0,0.3)'}}>                       
                            <table style={{width:'100%'}} cellpadding="0" cellspacing="0">                            
                                {
                                    this.state.paymentTermsData.map((termData,index) => {
                                        let _editBtn = "";
                                        if(termData.terms_default_status == 0){
                                            _editBtn = (
                                                <div style={{marginTop: 40, marginLeft: 20}}>
                                                    <td style={{width: "10%"}} align="left">

                                                        <a style={{
                                                            fontWeight: '400',
                                                            fontSize: '15px',
                                                            color: 'green',
                                                        }}> <i className="si si-check"
                                                               onClick={() => this.updateTerm(index)}></i></a>
                                                    </td>
                                                    <td style={{width: "10%"}} align="left">
                                                        <a style={{
                                                            fontWeight: '400',
                                                            fontSize: '15px',
                                                            color: 'red'
                                                        }}> <i className="si si-close" data-toggle="tooltip"
                                                               data-placement="bottom"
                                                               onClick={() => this.disableEditMode(index)}></i></a>
                                                    </td>
                                                </div>
                                            );
                                        }
                                        let _delBtn = "";
                                        if(termData.terms_default_status == 0){
                                            _delBtn = (
                                                <div style={{marginTop: 40, marginLeft: 20}}>
                                                    <td style={{width: "10%"}} align="left">

                                                        <a style={{
                                                            fontWeight: '400',
                                                            fontSize: '15px',
                                                            color: 'red',
                                                        }}> <i className="si si-pencil"
                                                               onClick={() => this.enableEditMode(index)}></i></a>
                                                    </td>
                                                    <td style={{width: "10%"}} align="left">
                                                        <a style={{
                                                            fontWeight: '400',
                                                            fontSize: '15px',
                                                            color: 'red'
                                                        }}> <i className="si si-trash" data-toggle="tooltip"
                                                               data-placement="bottom"
                                                               onClick={this.openModalOtherTransac.bind(this, 'openModalDeleteDueDateTerms', termData.id)}></i></a>
                                                    </td>
                                                </div>
                                            );
                                        }
                                        return(<tr>
                                            <td style={{width:'40%'}}>
                                                <label  style={{fontSize: '14px !important',fontWeight : 'bold',display : 'block' }} >Term</label>
                                                <input type="text" placeholder="Due On Reciept" style={{width: '90%',borderBottom : '1px #CCC solid;margin-top:10px',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem' }} value ={termData.terms_name}  name="terms_name" data-slug="terms_name" data-id={termData.id} id={"terms_name_"+termData.id} onChange={e => this.handleOnChange(index, e)} readOnly={!termData.isEdit} disabled={!termData.isEdit}/>
                                            </td>
                                            <td style={{width:'20%'}}>
                                                <label style={{fontSize: '14px !important',fontWeight : 'bold',display : 'block' }} >Days</label>
                                                <input type="text" placeholder="0" style={{width: '90%',borderBottom : '1px #CCC solid;margin-top:10px', marginTop : '10px',color:'#575757', border:'1px solid #d4dae3',padding:'0.428571rem 1rem','font-weight':'400','font-size':'1rem'}} value ={termData.terms_days} name="terms_days" data-slug="terms_days" data-id={termData.id} id={"terms_days_"+termData.id} onChange={e => this.handleOnChange(index, e)}   readOnly={!termData.isEdit} disabled={!termData.isEdit}/>
                                            </td>
                                            <td style={{width:'10%'}} align="center">
                                                <label style={{fontSize: '14px !important',fontWeight : 'bold',display : 'block' }} >Default</label>
                                                <input type="checkbox" style={{marginTop:'10px'}} checked={termData.terms_default_status > 0}  id={"terms_default_status_"+termData.id} name="terms_default_status" value={termData.terms_default_status} data-slug="terms_default_status" data-id={termData.id} onChange={e => this.setAsDefault(index, e)}  />
                                            </td>
                                            {!termData.isEdit && _delBtn}
                                            {termData.isEdit && _editBtn}
                                        </tr>)
                                    })
                                }                           
                            </table>
                        </div>
                        }
                </div>                    
            </div> 
            {/* Modal Add Due date payment terms */}
            <Modal isOpen={this.state.modalIsOpen_AddTerm} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalAddDueDateTerms">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Due Dates and Payment terms</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                        <div className="form-group row">
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" className="form-control" id="terms_name" name="terms_name" value={this.state.terms_name} placeholder="Terms Name Here" onChange={this.handleChange} maxLength="15"/>
                                                    <label htmlFor="login-username">Terms Name <span className="text-danger">*</span></label>
                                                    <span className="iris_error_txt">{errors.terms_name.length > 0 && <span className='error'>{errors.terms_name}</span>}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-material open">
                                                    <input type="text" required="required" className="form-control" id="terms_days" name="terms_days" value={this.state.terms_days} placeholder="Terms Days Here" onChange={this.handleChange} maxLength="3"/>
                                                    <label htmlFor="login-username">Terms Days <span className="text-danger">*</span></label>
                                                    <span className="iris_error_txt">{errors.terms_days.length > 0 && <span className='error'>{errors.terms_days}</span>}</span>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick=    {this.closeModalHandler.bind(this,'openModalAddDueDateTerms')}>Close
                                    </button>
                                    <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddTerms} disabled={isDisabled}>
                                        <i className="fa fa-check mr-5"></i> Save
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* End Modal Add Due date payment terms*/}
                    {/* Modal Delete Due date payment terms */}
                    <Modal isOpen={this.state.modalIsOpen_DeleteTerm} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalDeleteDueDateTerms">
                        <div className="modal-dialog modal-ls" role="document">
                            <div className="modal-content">
                                <div className="block block-themed block-transparent mb-0">
                                    <div className="block-header bg-primary-dark">
                                        <h3 className="block-title">Delete Terms</h3>
                                        
                                    </div>
                                    <div className="block-content">
                                        <div className="form-group row">
                                            <h3 className='block-title' style={{margin:'0 0 0 18%'}}>Are you sure you want to delete this term</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-alt-secondary" onClick=    {this.closeModalHandler.bind(this,'openModalDeleteDueDateTerms')}>No
                                    </button>
                                    <button type="submit" className="btn btn-alt-primary" onClick={this.clickToDelete} >Yes
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal>                
                    {/* End Modal Delete Due date payment terms*/}
            </React.Fragment>
        )
    }

}