import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

import Modal from "react-modal";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTypeData = [];
let _countryCode = "IN";

let _bankArray = [];
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" }
  //{ bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class quickLoanAccountAdd extends Component {
  constructor(props) {
    _isEditMode = false;
    super(props);
    //_bankArray.push({"bank_id":"0","bank_name":"","received_in":"Cash"});
    //_bankArray.push({"bank_id":"0","bank_name":"","paymentType":"Cash"},{"bank_id":"0","bank_name":"","paymentType":"Cheque"});
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTypeData = _companySession.taxType;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-loan-accounts/",
      buttonTitle: "",
      hideShow: "",
      isLoaded: true,
      company_id: _company_id,
      businessId: _businessId,
      userId: _signingId,
      loanAccountId: this.props.loanAccountId,
      loan_id: this.props.loanAccountId,
      account_name: "",
      balance_as_of: moment().format("MM/DD/YYYY"),
      lender_bank: "",
      account_number: "",
      description: "",
      current_balance: "",
      received_in: "Cash",
      interest_rate: "",
      term_duration: "",
      processing_fee: "0",
      dis_t: "hide",
      processing_fee_paid_from: "Cash",
      bank_id: "0",
      bankPFee_id: "0",
      paid_form_bank_id: 0,

      isTransaction: false,
      minimumDate: moment().format("MM/DD/YYYY"),

      errors: {
        balance_as_of: "",
        account_name: "",
        lender_bank: "",
        account_number: "",
        description: "",
        current_balance: "",
        received_in: "",
        interest_rate: "",
        term_duration: "",
        processing_fee: "",
        processing_fee_paid_from: "",
      },
    };
  }

  async componentDidMount() {
    let _loanAccountId = this.props.loanAccountId;
    _isEditMode = false;
    if (_loanAccountId > 0) {
      const requestData = JSON.stringify({
        loanId: _loanAccountId,
      });

      PostData(global.userLoanAccountDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _transArray = responseJson.response[0];
              _isEditMode = true;
              if (
                _transArray.processing_fee!=0 &&
                _transArray.processing_fee!=""
              ) {
                this.setState({ dis_t: "" });
              } else {
                this.setState({ dis_t: "hide" });
              }

              this.setState({
                buttonTitle: "Edit",
                loan_id: _transArray.loan_id,
                account_name: _transArray.account_name,
                balance_as_of: moment(_transArray.date_as).format("MM/DD/YYYY"),
                lender_bank: _transArray.lender_bank,
                account_number: _transArray.account_number,
                description: _transArray.description,
                current_balance: _transArray.opening_balance,
                received_in: _transArray.received_in ? _transArray.received_in : 'Cash',
                interest_rate: _transArray.interest_rate,
                term_duration: _transArray.duration,
                processing_fee: _transArray.processing_fee,
                processing_fee_paid_from: _transArray.paid_form ? _transArray.paid_form : 'Cash',
                minimumDate: moment(_transArray.minimumDate).format(
                  "MM/DD/YYYY"
                ),
                isTransaction: _transArray.isTransaction,
                received_bank_id: _transArray.received_bank_id,
                paid_form_bank_id: _transArray.paid_form_bank_id,
                loan_received: _transArray.loan_received,
                
                bank_id: _transArray.received_bank_id,
                bank_name: _transArray.received_bank_name,
                bankPFee_id : _transArray.paid_form_bank_id,
                bankPFee_name : _transArray.paid_form_bank_name,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "account_name":
        errors.account_name =
          value.account_name < 3
            ? "*Account name should be 3 character length"
            : "";
        this.setState({ account_name: value });
        break;

      case "lender_bank":
        errors.lender_bank = value.length < 1 ? "*Enter Lender Bank!" : "";
        this.setState({ lender_bank: value });
        break;

      case "account_number":
        errors.account_number =
          value.length < 3
            ? "*Enter Account Number should be 3 character long !"
            : "";

        if (validator.isInt(value)) {
          errors.account_number = "";
        } else {
          errors.account_number = "*Please enter only numeric value.";
        }
        this.setState({ account_number: value });
        break;

      case "balance_as_of":
        errors.balance_as_of = value.length < 3 ? "*Date must be select!" : "";
        this.setState({ balance_as_of: value });
        break;
      case "description":
        errors.description = value.length < 3 ? "*Enter description" : "";
        this.setState({ description: value });
        break;
      case "current_balance":
        errors.current_balance =
          value.length < 1 ? "*Enter current balance" : "";
        // if(validator.isDecimal(value)){
        //     errors.current_balance = '';
        // } else {
        //     errors.current_balance = '*opening balance should be numeric!';
        // }
        this.setState({ current_balance: value });
        break;
      case "received_in":
        errors.received_in = value.length < 1 ? "*Select payment type!" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        let _valType = "";
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }

        this.setState({
          received_in: _valType,
          bank_id: _bankId,
          bank_name: _bankName,
        });
        break;
      case "interest_rate":
        if (validator.isFloat(value)) {
          errors.interest_rate = "";
        } else {
          errors.interest_rate = "*interest rate should be numeric!";
        }
        this.setState({ interest_rate: value });
        break;

      case "term_duration":
        if (validator.isInt(value)) {
          errors.term_duration = "";
        } else {
          errors.term_duration = "*Duration should be numeric only!";
        }
        this.setState({ term_duration: value });
        break;
      case "processing_fee":
        // if(validator.isInt(value)){
        //     errors.processing_fee = '';
        // } else {
        //     errors.processing_fee = '*processing fee should be numeric!';
        // }
        if (value == 0 || value == "") {
          this.setState({ dis_t: "hide" });
        } else {
          this.setState({ dis_t: "" });
        }
        this.setState({ processing_fee: value });
        break;
      case "processing_fee_paid_from":
        errors.processing_fee_paid_from =
          value.length < 0 ? "*Enter loan processing fee paid from" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankPFeeId = 0;
        let _bankPFeeName = "";
        let _valPFeeType = "";
        if (value > 0) {
          _valPFeeType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankPFeeId = optionElement.getAttribute("data-bankPFeekId");
          _bankPFeeName = optionElement.getAttribute("data-bankPFeekName");
        } else {
          _valPFeeType = value;
        }
        console.log("==ss==", _valPFeeType);
        console.log(_bankPFeeName + "==dd==", _bankPFeeId);
        this.setState({
          processing_fee_paid_from: _valPFeeType,
          bankPFee_id: _bankPFeeId,
          bankPFee_name: _bankPFeeName,
        });
        break;

      default:
        break;
    }
  };

  clickToAddBankDetails = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.description == "") {
      _isError = true;
      errors["description"] = "*Please Enter Description";
      this.setState({ errors: errors });
    }

    if (this.state.lender_bank == "") {
      _isError = true;
      errors["lender_bank"] = "*Please Enter Lender Bank";
      this.setState({ errors: errors });
    }

    if (this.state.account_name == "") {
      _isError = true;
      errors["account_name"] = "*Please Enter Account Name.";
      this.setState({ errors: errors });
    }

    if (this.state.account_number == "") {
      _isError = true;
      errors["account_number"] = "*Please Enter Account Number.";
      this.setState({ errors: errors });
    }
    if (this.state.current_balance == "") {
      _isError = true;
      errors["current_balance"] = "*Please Enter current balance";
      this.setState({ errors: errors });
    }
    if (this.state.received_in == "") {
      _isError = true;
      errors["received_in"] = "*Please Enter received in";
      this.setState({ errors: errors });
    }
    if (this.state.interest_rate == "") {
      _isError = true;
      errors["interest_rate"] = "*Please Enter interest rate";
      this.setState({ errors: errors });
    }
    if (this.state.term_duration == "") {
      _isError = true;
      errors["term_duration"] = "*Please Enter term duration";
      this.setState({ errors: errors });
    }
    if (this.state.processing_fee_paid_from == "") {
      _isError = true;
      errors["processing_fee_paid_from"] =
        "*Please Enter processing fee  paid from";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.loan_id > 0) {
        this.editLoanAccountMethodCall();
      } else {
        this.addLoanAccountMethodCall();
      }
      //this.addLoanAccountMethodCall();
    }
  };

  settleAmountTypeOld() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _payData.push(<option value="Cash">Cash</option>);
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.received_bank_id) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              //_payData.push(<option value="">Select Bank</option>);
              //_payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
        _payData.push(<option value="NewBank">Add New Bank</option>);
      } else {
        _payData.push(<option value="">Select Bank</option>);
        _payData.push(<option value="NewBank">Add New Bank</option>);
        //return(<option value="NewBank">Add New Bank</option>)
      }
    }
    return _payData;
  }

  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;
          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.received_bank_id) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.loan_received) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  settleAmountTypeProcessingFeeOld() {
    let _payData = [];

    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.received_in;
          let _disOption = bankD.received_in;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.paid_form_bank_id) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          _payData.push(
            <option
              value={_valOption}
              selected={_selBank}
              data-bankPFeekName={_disOption}
              data-bankPFeekId={_valOption}
            >
              {_disOption}
            </option>
          );

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              //_payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
        _payData.push(<option value="NewBank">Add New Bank</option>);
      } else {
        _payData.push(<option value="">Select Bank</option>);
        _payData.push(<option value="NewBank">Add New Bank</option>);
        //return(<option value="NewBank">Add New Bank</option>)
      }
    }

    return _payData;
  }

  settleAmountTypeProcessingFee() {
    let _payData = [];

    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;
          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.paid_form_bank_id) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.processing_fee_paid_from) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankPFeekName={_disOption}
                data-bankPFeekId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          let _addNewBank = "";
          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }

    return _payData;
  }

  addLoanAccountMethodCall() {
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      account_name: this.state.account_name,
      lender_bank: this.state.lender_bank,
      account_number: this.state.account_number,
      balanceAsOf: moment(this.state.balance_as_of).format("MMMM D, YYYY"),
      asOnTime: moment().format("h:mm A"),
      description: this.state.description,
      current_balance: this.state.current_balance,
      received_in: this.state.received_in,
      interest_rate: this.state.interest_rate,
      term_duration: this.state.term_duration,
      processing_fee: this.state.processing_fee,
      processing_fee_paid_from: this.state.processing_fee_paid_from,
      bank_id: this.state.bank_id,
      bank_name: this.state.bank_name,
      bankPFee_id: this.state.bankPFee_id,
      bankPFee_name: this.state.bankPFee_name,
    });
    PostData(global.userAddLoanAccount, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          this.props.callBack("hello");
          //window.location.href = global.webUrl+this.state.isRedirectTo;
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        return;
      }
    });
  }

  editLoanAccountMethodCall() {
    const requestData = JSON.stringify({
      loan_id: this.state.loan_id,
      company_id: this.state.company_id,
      businessId: this.state.businessId,
      userId: this.state.userId,
      account_name: this.state.account_name,
      lender_bank: this.state.lender_bank,
      account_number: this.state.account_number,
      balanceAsOf: moment(this.state.balance_as_of).format("MMMM D, YYYY"),
      description: this.state.description,
      current_balance: this.state.current_balance,
      received_in: this.state.received_in,
      interest_rate: this.state.interest_rate,
      term_duration: this.state.term_duration,
      processing_fee: this.state.processing_fee,
      processing_fee_paid_from: this.state.processing_fee_paid_from,
      bank_id: this.state.bank_id,
      bank_name: this.state.bank_name,
      bankPFee_id: this.state.bankPFee_id,
      bankPFee_name: this.state.bankPFee_name,
    });
    //console.log(this.state.received_in+"==requestData==",requestData);return false;
    PostData(global.userEditLoanAccount, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          this.props.callBack("hello");
          //window.location.href = global.webUrl+this.state.isRedirectTo;
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        return;
      }
    });
  }

  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
  };
  /*End Modal open close*/

  handleCallBackDate = (start) => {
    let errors = this.state.errors;
    errors["balance_as_of"] = "";
    if (this.state.isTransaction == true) {
      let _selectDate = start.format("MM/DD/YYYY");
      if (_selectDate > this.state.minimumDate) {
        errors["balance_as_of"] = "Minimum Date " + this.state.minimumDate;
        this.setState({ errors: errors });
        return;
      }
    }
    this.setState({
      errors: errors,
      balance_as_of: start.format("MMMM D, YYYY"),
    });
  };

  isDateRefresh = () => {
    let _invoiceDate = this.state.balance_as_of;
    if (_isEditMode == true && this.props.loanAccountId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.props.loanAccountId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="account_name"
                  name="account_name"
                  value={this.state.account_name}
                  placeholder="Enter Account Name"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Account Name <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.account_name.length > 0 && (
                    <span className="error">{errors.account_name}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="lender_bank"
                  name="lender_bank"
                  value={this.state.lender_bank}
                  placeholder="Enter Lender Bank"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Lender Bank <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.lender_bank.length > 0 && (
                    <span className="error">{errors.lender_bank}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="account_number"
                  name="account_number"
                  value={this.state.account_number}
                  placeholder="Enter Account Number"
                  onChange={this.handleChange}
                />

                <label htmlFor="login-username">
                  Account Number <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.account_number.length > 0 && (
                    <span className="error">{errors.account_number}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="description"
                  name="description"
                  value={this.state.description}
                  placeholder="Enter description"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Description <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.description.length > 0 && (
                    <span className="error">{errors.description}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="number"
                  required="required"
                  className="form-control"
                  id="current_balance"
                  name="current_balance"
                  value={this.state.current_balance}
                  placeholder="Enter Current Balance"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Current Balance <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.current_balance.length > 0 && (
                    <span className="error">{errors.current_balance}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">Balance As Of</label>
                <span className="iris_error_txt">
                  {errors.balance_as_of.length > 0 && (
                    <span className="error">{errors.balance_as_of}</span>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <select
                  className="col-8 form-control"
                  defaultValue={
                    this.state.bank_id > 0
                      ? this.state.bank_id
                      : this.state.received_bank_id
                  }
                  id="received_in"
                  name="received_in"
                  onChange={this.handleChange}
                >
                  {this.settleAmountType()}
                </select>
                <label htmlFor="login-username">Loan Received In</label>
                <span className="iris_error_txt">
                  {errors.received_in.length > 0 && (
                    <span className="error">{errors.received_in}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="number"
                  required="required"
                  className="form-control"
                  id="interest_rate"
                  name="interest_rate"
                  value={this.state.interest_rate}
                  placeholder="Enter Interest Rate"
                  onChange={this.handleChange}
                  maxLength="3"
                  min="0"
                  max="100"
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Interest Rate %per annum{" "}
                  <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.interest_rate.length > 0 && (
                    <span className="error">{errors.interest_rate}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="number"
                  required="required"
                  className="form-control"
                  id="term_duration"
                  name="term_duration"
                  value={this.state.term_duration}
                  placeholder="Enter term duration"
                  onChange={this.handleChange}
                  min="1"
                  maxLength="8"
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Term Duration (in months){" "}
                  <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.term_duration.length > 0 && (
                    <span className="error">{errors.term_duration}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="number"
                  required="required"
                  className="form-control"
                  id="processing_fee"
                  name="processing_fee"
                  value={this.state.processing_fee}
                  placeholder="Enter processing fee"
                  onChange={this.handleChange}
                  min="1"
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Processing Fee</label>
                <span className="iris_error_txt">
                  {errors.processing_fee.length > 0 && (
                    <span className="error">{errors.processing_fee}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className={this.state.dis_t}>
                <div className="form-material open">
                  {/* <select name="processing_fee_paid_from" id="processing_fee_paid_from" className="form-control" onChange={this.handleChange}>
                                        <option value="Cash">Cash</option>
                                </select> */}

                  <select
                    className="col-8 form-control"
                    defaultValue={
                      this.state.bankPFee_id > 0
                        ? this.state.bankPFee_id
                        : this.state.paid_form_bank_id
                    }
                    id="processing_fee_paid_from"
                    name="processing_fee_paid_from"
                    onChange={this.handleChange}
                  >
                    {this.settleAmountTypeProcessingFee()}
                  </select>

                  <label htmlFor="login-username">
                    Processing Fee Paid From
                  </label>
                  <span className="iris_error_txt">
                    {errors.processing_fee_paid_from.length > 0 && (
                      <span className="error">
                        {errors.processing_fee_paid_from}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddBankDetails}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SBANK}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewBank"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Bank</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewBank"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewBankDetail
                    isRedirectTo={"business/manage-loan-accounts/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewBank"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}
      </React.Fragment>
    );
  }
}
