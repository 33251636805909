import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
//import QuickAddNewItem from '../userItem/quickSaleItemAdd';
import QuickAddNewItem from "../userExpense/expenseItemMaster";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _businessSetting = {};

export default class quickTransactionExpenseItemSection extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;
    }
    this.state = {
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      party_id: this.props.party_id ? this.props.party_id : 0,
      party_name: "",

      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      handleAddDisable: "",
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalAmountNew: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      totalAmountOld: 0,
      itemRemovePosition: 0,
      itemType: "",
      handleItemSalePurchasePrice: "",

      errors: {
        party_id: "",
        party_name: "",
      },
    };
  }

  _handleClearItem = (event) => {
    this.setState({
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      party_name: "",
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      lowStokItemName: "",

      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      handleAddDisable: "",
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      totalAmountOld: 0,
      itemRemovePosition: 0,
      sendActualQty: 0,
      totalAmountOldFree: 0,
      handleItemSalePurchasePrice: "",
    });
    this.getItemListData(_company_id);
  };

  componentWillUnmount() {
    document.body.removeEventListener("clearData", this._handleClearItem);
  }

  async componentDidMount() {
    this.getExpenseCategoryListData();
    this.getItemListData(_company_id);

    let _transId = this.props.transId ? this.props.transId : "";
    let transactionId = 0;

    if (_transId.length > 0) {
      let _handleParam = _transId.split("&tType=");
      if (_handleParam.length > 1) {
        transactionId = _handleParam[0] ? _handleParam[0] : 0;
      }
    }

    if (transactionId > 0) {
      _isEditMode = true;
    }
    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userExpenseTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/purchase-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let itemsData = _transactionArray.items;
              let transAmout = _transactionArray.totalSummary;

              this.setState({
                fields: itemsData,
                isLoaded: true,
                transactionId: transactionId,
                //EnableItem: _transactionArray.EnableItem,
                //FreeItemQuantity :_transactionArray.FreeItemQuantity,
                totalAmountOld: transAmout.totalAmount,
                totalAmountNew: transAmout.totalAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                totalAmount: transAmout.totalAmount,
                paidAmount: transAmout.paidAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.dueAmount,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  getExpenseCategoryListData() {
    let _expenseArray = [];
    let _url_GetData =
      global.userExpenseCategoryList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _expenseArray = responseJson.response;
          }
          this.setState({
            expenseArrayFilter: _expenseArray,
            expenseArray: _expenseArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getItemListData(company_id) {
    let _itemsArray = [];

    let _url_GetData =
      global.userexpenseItemList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
          }
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  /*For Modal open close*/

  openModalHandler(e) {
    if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: true });
    }
  }

  closeModalHandler = (e) => {
    if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: false });
    }
  };

  /*End Modal open close*/

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  handleAdd() {
    const values = this.state.fields;
    values.push([]);
    let _itemPosition = this.state.itemPosition;
    if (_itemPosition == -1) {
      _itemPosition = 0;
    }
    this.setState({
      fields: values,
      itemPosition: _itemPosition,
      handleAddDisable: "disabled",
      handleItemSalePurchasePrice: "",
    });
    let _totalAmt = this.state.totalAmount;
    let _dueAmount = this.state.dueAmount;

    let _paidAM = this.state.paidAmount;
    let _totalAmountNewSend = this.state.totalAmountNew;
    let _totalAmountOldSend = this.state.totalAmountOld;
    let _totalDiscount = this.state.totalDiscount;
    let _totalGstAmt = this.state.gstAmount;
    let _totalQuantity = this.state.totalQuantity;
    let handleAddDisable = "Disabled";
    let handleItemSalePurchasePrice = "";
    let _fieldData = this.state.fields;

    this.props.callBackItem(
      _fieldData,
      _totalAmountOldSend,
      _totalAmountNewSend,
      _totalAmt,
      _dueAmount,
      _paidAM,
      _totalDiscount,
      _totalGstAmt,
      _totalQuantity
    );
  }

  handleRemove(i, idx) {
    const values = this.state.fields;
    //const newFields = values.filter((itmRow, index) => index!=i);
    const newFields = values.filter((itmRow, index) => {
      return index!=i;
    });

    const data = this.state.itemArray;
    data.filter(function (item, index) {
      if (item.item_id == idx) {
        data[index].isFilter = false;
      }
    });

    const fieldArray = newFields;
    let _quantity = 0;
    let _totalAmt = "0";
    let _paidAmount = this.state.paidAmount;
    let _dueAmount = 0;

    fieldArray.map((field, index) => {
      let _itemId = field.item_id ? field.item_id : 0;
      if (_itemId > 0) {
        let _obj = {};
        _obj.item_id = field.item_id ? field.item_id : 0;
        _obj.item_name = field.item_name ? field.item_name : "";
        _obj.quantity = field.quantity ? field.quantity : 0;
        _obj.rate = field.rate ? field.rate : 0;
        _obj.tax_value = field.tax_value ? field.tax_value : 0;
        _obj.subtotal = field.subtotal ? field.subtotal : 0;
        _obj.total_amount = field.total_amount ? field.total_amount : 0;

        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);

        _paidAmount = this.state.paidAmount;
        _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.paidAmount);
        _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
      }
    });

    if (_dueAmount > 0) {
      _dueAmount = _dueAmount.toFixed(2);
    }
    if (_totalAmt > 0) {
      _totalAmt = _totalAmt.toFixed(2);
    }

    this.setState({
      fields: fieldArray,
      totalQuantity: _quantity,
      dueAmount: _dueAmount,
      totalAmount: _totalAmt,
      totalAmountOld: _totalAmt,
      subtotal: _totalAmt,
    });

    //this.props.callBackItem(fieldArray,_totalAmountOldSend,_totalAmountNewSend,_totalAmt,_dueAmount,_paidAM,_totalDiscount,_totalGstAmt,_totalQuantity);

    if (fieldArray.length == 0 || fieldArray.length == "0") {
      this.setState({
        fields: fieldArray,
        totalQuantity: 0,
        dueAmount: 0,
        totalAmount: 0,
      });
      let _totalAmountOldSend = 0;
      let _totalAmountNewSend = 0;
      let _totalAmt = 0;
      let _dueAmount = 0;
      let _paidAM = 0;
      let _totalDiscount = 0;
      let _totalGstAmt = 0;
      let _totalQuantity = 0;
      this.props.callBackItem(
        fieldArray,
        _totalAmountOldSend,
        _totalAmountNewSend,
        _totalAmt,
        _dueAmount,
        _paidAM,
        _totalDiscount,
        _totalGstAmt,
        _totalQuantity
      );
      this.setState({ gstAmount: _totalGstAmt, totalDiscount: _totalDiscount });
      this.getReadyItemList();
    } else {
      let _amountDisK = 0;
      let _amountTaxK = 0;
      let _discountTaxAk = 0;

      fieldArray.map((field, idx) => {
        let _discountAmtAk = field.discount_in_amount
          ? field.discount_in_amount
          : 0;
        _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);
        if (
          _businessSetting.AllowInclusiveExclusive == 1 &&
          field.sale_pruchase_tax_type == "Without Tax"
        ) {
          _discountTaxAk = field.tax_value ? field.tax_value : 0;
          _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
        }
        this.setState({
          totalDiscount: _amountDisK.toFixed(2),
          gstAmount: _amountTaxK.toFixed(2),
        });
      });
      _totalAmt =
        parseFloat(_totalAmt) +
        parseFloat(_amountTaxK) -
        parseFloat(_amountDisK);

      let _totalAmtCAK = _totalAmt;
      let _dueAmountCAK = _totalAmt;
      let _paidAM = 0;
      let _totalAmountNewSend = _totalAmt;
      let _totalAmountOldSend = _totalAmt;
      let _totalDiscount = _amountDisK;
      let _totalGstAmtCAK = _amountTaxK;
      let _totalQuantity = _quantity; //this.state.totalQuantity;
      let _fieldData = fieldArray;
      this.props.callBackItem(
        _fieldData,
        _totalAmountOldSend,
        _totalAmountNewSend,
        _totalAmtCAK,
        _dueAmountCAK,
        _paidAM,
        _totalDiscount,
        _totalGstAmtCAK,
        _totalQuantity
      );

      this.setState({
        gstAmount: _totalGstAmtCAK,
        totalDiscount: _totalDiscount,
        fields: newFields,
        itemArrayFilter: data,
        totalAmount: _totalAmt,
      });

      this.getReadyItemList();
    }
  }

  removeSelectedSrData(srId, _itemId, _delType, _position) {}
  handleDynamicInputSalePurTaxType(i, type, event) {
    let _itemPosition = i;
    let _itemName,_itemPrice,_itemId, _value = "";
    let _transCount = 0;
    let fieldArray = this.state.fields;
    let discountAmt = 0;
    let totalAmount = 0;
    let tax = 0;
    let amountBeforeTaxWithoutDisc = 0;
    let disc = 0;
    let amountBeforeTax = 0 ;
    let subTotal = 0;
    let _discountAmtSend = 0;
    let _taxAmtSend = 0;
    let _itemToalAmtSend = 0;
    let _costPrice = 0;
    let _oldTotalItemAmt = 0;
    let _gstObj = {};
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
          let qty = field.quantity ? field.quantity : 0;
          let rateValue = parseFloat(field.rate);
          let taxType = parseFloat(field.tax_type.ratio) ? parseFloat(field.tax_type.ratio) : 0.0;
          let additionalCess = parseFloat(field.Additional_CESS) ? parseFloat(field.Additional_CESS) : 0;
          let discount_in_per =  parseFloat(field.discount_in_per) ?  parseFloat(field.discount_in_per) : 0
          let discount_in_amount =  parseFloat(field.discount_in_amount) ?  parseFloat(field.discount_in_amount) : 0;
          subTotal = rateValue * qty;
          _oldTotalItemAmt = field.total_amount ? field.total_amount : 0;
          console.log("==d==",JSON.stringify(field));
          _gstObj.id = field.tax_type.id;
          _gstObj.name = field.tax_type.name;
          _gstObj.ratio = field.tax_type.ratio;
          _value = event.target.value;
          if (_value == 'With Tax' && taxType > 0)
          {
            console.log(subTotal+"==yyyyy==",_value);
            if(field.discount_in_amount > 0){
              if(taxType > 0){
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
              }else{
                _costPrice = subTotal;
              }
              discountAmt = discount_in_amount;// * qty;
              amountBeforeTax = _costPrice - discountAmt;
              disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
              tax = amountBeforeTax * taxType / 100;
              totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

              discountAmt = discountAmt.toFixed(2);
              tax = tax.toFixed(2);
              totalAmount = totalAmount.toFixed(2);

              _discountAmtSend = discountAmt;
              _taxAmtSend = tax;
              _itemToalAmtSend = totalAmount;
            }
            else{
              _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
              discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
              amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
              disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
              tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
              console.log(amountBeforeTaxWithoutDisc+"===disc==",disc) ;
              totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);

              //Discount amont == disc;
              disc = disc.toFixed(2);
              discountAmt = discountAmt.toFixed(2);
              tax = tax.toFixed(2);
              totalAmount = totalAmount.toFixed(2);

              _discountAmtSend = disc;
              _taxAmtSend = tax;
              _itemToalAmtSend = totalAmount;
            }
          }

          else if (_value == 'With Tax' && (taxType == 0 || taxType == '0'))
          {
            console.log(subTotal+"==uuu==",_value);
            if(field.discount_in_amount > 0){
              if(taxType > 0){
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
              }else{
                _costPrice = subTotal;
              }
              discountAmt = discount_in_amount;// * qty;

              amountBeforeTax = _costPrice - discountAmt;
              disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
              tax = amountBeforeTax * taxType / 100;
              totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

              discountAmt = discountAmt.toFixed(2);
              tax = tax.toFixed(2);
              totalAmount = totalAmount.toFixed(2);

              _discountAmtSend = discountAmt;
              _taxAmtSend = tax;
              _itemToalAmtSend = totalAmount;
            }
            else{
              _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
              discountAmt = (discount_in_per / 100) * subTotal;
              amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
              disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
              tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
              totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + parseFloat(additionalCess);

              //Discount amont == disc;
              disc = disc.toFixed(2);
              discountAmt = discountAmt.toFixed(2);
              tax = tax.toFixed(2);
              totalAmount = totalAmount.toFixed(2);

              _discountAmtSend = disc;
              _taxAmtSend = tax;
              _itemToalAmtSend = totalAmount;
            }
          }

          else {
            if(field.discount_in_amount > 0){
              console.log(subTotal+"==qqq==",_value);
              _costPrice = subTotal;
              discountAmt = discount_in_amount;// * qty;
              amountBeforeTax = subTotal - discountAmt;
              tax = amountBeforeTax * taxType / 100;
              //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
              totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

              console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTax+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');
              discountAmt = discountAmt.toFixed(2);
              tax = tax.toFixed(2);
              totalAmount = totalAmount.toFixed(2);

              _discountAmtSend = discountAmt;
              _taxAmtSend = tax;
              _itemToalAmtSend = totalAmount;
            }
            else{
              console.log(subTotal+"==zz==",_value);
              _costPrice = subTotal;
              discountAmt = (discount_in_per / 100) * subTotal;
              amountBeforeTaxWithoutDisc = subTotal - discountAmt;
              tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
              totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);

              console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTaxWithoutDisc+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');

              discountAmt = discountAmt.toFixed(2);
              tax = tax.toFixed(2);
              totalAmount = totalAmount.toFixed(2);

              _discountAmtSend = discountAmt;
              _taxAmtSend = tax;
              _itemToalAmtSend = totalAmount;
            }
          }
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = _gstObj;//{ id: "0", name: "", ratio: "0" };
          console.log("==_objTaxType==",_objTaxType);
          let discountPer  = (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
          // if(_value == 'Without Tax'){
          //   discountPer = _taxAmtSend;
          // }
          console.log(discountPer+'==>--discountPer-->=='+_costPrice+'==>_costPrice>=='+_discountAmtSend+'==>_discountAmtSend>==');

          _obj.trans_count = field.trans_count ? field.trans_count : 0;
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.Additional_CESS = additionalCess;
          _obj.discount_type =  field.discount_type ? field.discount_type : '';
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = discountPer;
          _obj.discount_in_amount = field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = _objTaxType//'';
          _obj.tax_value = _taxAmtSend;//field.tax_value ? field.tax_value : 0;
          _obj.sale_pruchase_tax_type = _value;
          _obj.subtotal = totalAmount;
          _obj.total_amount = totalAmount;//field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData: {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData : {};
          fieldArray[_itemPosition] = _obj;
        }

        console.log(_discountAmtSend+"==>discountAmt 22-->=="+_taxAmtSend+"==>tax-->=="+_itemToalAmtSend+"==>totalAmount-->==");
      });
      console.log(this.state.totalAmount+'==>>=='+this.state.totalAmountOld+"==>=="+this.state.totalAmountNew+"==>>=="+this.state.totalAmountOldFree+"==>>=="+_oldTotalItemAmt);
      this.setState({ fields: fieldArray });

      let _totalAmountOld = 0;
      if(this.state.fields.length == 1){
        _totalAmountOld = _itemToalAmtSend;
      }
      else{
        //_totalAmountOld = (parseFloat(_itemToalAmtSend)+ parseFloat(this.state.totalAmountOld)) - parseFloat(_oldTotalItemAmt) ;
        this.state.fields.map((data) => {
          _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(data.total_amount);
        })
      }
      let _totalAmountNew = _totalAmountOld;
      let _paidAM = 0;
      let _dueAmount = _totalAmountOld;

      let _transactionWiseTaxId = 0;
      let _totalTransTaxAmount = 0;
      let _totalTransDiscountPer = 0;
      let _totalTransDiscountAmount = 0;
      let _totalGstAmt = 0;
      let _totalDiscount = 0;

      if(this.state.fields.length == 1){
        _totalDiscount = _discountAmtSend;
        _totalGstAmt = _taxAmtSend;
      }
      else{
        // _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);
        // _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
        fieldArray.map((field, index) => {
          if (index!=_itemPosition) {
            _totalDiscount+= parseFloat(_discountAmtSend) + parseFloat(field.discount_in_amount);
            _totalGstAmt+= parseFloat(_taxAmtSend) + parseFloat(field.tax_value);
          }
        })
      }
      console.log(_taxAmtSend+"==>_taxAmtSend-->=="+_totalGstAmt+"==>_totalGstAmt-->==");
      this.setState({
        subtotal: _totalAmountOld,
        totalAmountOld: _totalAmountOld,
        gstAmount: _totalGstAmt,
        totalDiscount: _totalDiscount,
      });
      let _totalAmt = _totalAmountOld;

      if(this.state.transactionId > 0){
        this.setState({
          totalAmount: _totalAmountOld,
        });
      }
      this.props.callBackItem(
          fieldArray,
          _totalAmountOld,
          _totalAmountNew,
          _totalAmt,
          _dueAmount,
          _paidAM,
          _totalDiscount,
          _totalGstAmt,
          this.state.totalQuantity,
          this.state.handleAddDisable,
          this.state.handleItemSalePurchasePrice,
          _transactionWiseTaxId,
          _totalTransTaxAmount,
          _totalTransDiscountPer,
          _totalTransDiscountAmount
      );

    }
  }
  handleDynamicInputs(i, type, event) {
    let _itemPosition = i;
    let _itemName,
        _itemPrice,
        _itemId,
        _value,
        _sale_pruchase_tax_type = "";
    let _taxTypeIndividual = { id: "0", name: "", ratio: "0" };
    this.setState({
      transactionWiseTaxId: 0,
      totalTransTaxAmount: 0,
      totalTransDiscountPer: 0,
      totalTransDiscountAmount: 0,
    });
    
    if (type == "item") {
      _itemPosition = i.index;
      _itemPrice = i.price;
      _itemId = i.id;
      _itemName = i.name;
      _sale_pruchase_tax_type = i.sale_pruchase_tax_type;
      if (i !== 0) {
        if (i.tax_type.id) {
          _taxTypeIndividual = i.tax_type;
        }
      }
    } else {
      _value = event.target.value;
    }

    const fieldArray = this.state.fields;
    let _quantity = 0;
    let _totalAmt = 0;
    let _paidAmount = this.state.paidAmount;
    let _dueAmount = 0;

    let _percDisAmt = 0;
    let _percGstAmt = 0;

    if (fieldArray.length == "1" && this.state.transactionId == 0) {
      _paidAmount = 0;
    }
    let _itemPriceAK =  0;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
          let _obj = {};
          let _objTaxType = { id: "0", name: "", ratio: "0" };

          _itemPriceAK =  parseFloat(field.rate) * parseFloat(field.quantity);
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.subtotal = field.subtotal ? field.subtotal : 0;
          _obj.total_amount = field.total_amount ? field.total_amount : 0;
          _obj.tax_type = field.tax_type ? field.tax_type : _objTaxType;
          _obj.tax_value = field.tax_value ? field.tax_value : 0;
          _obj.discount_in_per = field.discount_in_per
              ? field.discount_in_per
              : 0;
          _obj.discount_in_amount = field.discount_in_amount
              ? field.discount_in_amount
              : 0;
          _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type
              ? field.sale_pruchase_tax_type
              : "";

          if (type == "item") {
            _obj.item_id = _itemId;
            _obj.item_name = _itemName;
            _obj.rate = _itemPrice;
            _obj.subtotal = _itemPrice;
            _obj.total_amount = _itemPrice;
            _obj.quantity = 1;
            _obj.tax_type = _taxTypeIndividual;
            _obj.tax_value = i.tax_type.id ? i.tax_type.id : 0;
            _obj.sale_pruchase_tax_type = i.sale_pruchase_tax_type
                ? i.sale_pruchase_tax_type
                : "";
          }

          if (type == "quantity") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            _obj.quantity = _value;
            let _totalAmtRate = parseFloat(_value) * parseFloat(_obj.rate);

            _obj.discount_in_per = 0;
            _obj.discount_in_amount = 0;
            _obj.tax_value = 0;
            _obj.tax_type = 0;
            _obj.total_amount = _totalAmtRate;
          }
          if (type == "rate") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            _obj.rate = _value;
            let _totalAmtRate = parseFloat(_value) * parseFloat(_obj.quantity);

            _obj.discount_in_per = 0;
            _obj.discount_in_amount = 0;
            _obj.tax_value = 0;
            _obj.tax_type = 0;
            _obj.total_amount = _totalAmtRate;
          }

          let _totalItemAmount =
              parseFloat(_obj.rate) * parseFloat(_obj.quantity);
          let _itemSubtotal = _totalItemAmount;
          if (_businessSetting.ItemWiseTax == 1) {
            if (type == "taxType") {
              let _gstObj = {};
              this.state.taxRateArray.map((tax, index) => {
                let _sel = "";
                if (_value == tax.id) {
                  _gstObj.id = tax.id;
                  _gstObj.name = tax.name;
                  _gstObj.ratio = tax.ratio;

                  if (tax.ratio >= 0) {
                    _percGstAmt =
                        ((parseFloat(_totalItemAmount) -
                            parseFloat(_obj.discount_in_amount)) *
                            parseFloat(tax.ratio)) /
                        parseFloat(100);
                  }
                }
              });
              _obj.tax_type = _gstObj;
              if (_percGstAmt < 0) {
                _obj.tax_value = 0;
              } else {
                _obj.tax_value = _percGstAmt.toFixed(2);
              }
              //_itemSubtotal = parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value) - parseFloat(_obj.discount_in_amount);
            } else {
              if (_taxTypeIndividual.id > 0) {
                this.state.taxRateArray.map((tax, index) => {
                  if (_taxTypeIndividual.id == tax.id) {
                    if (tax.ratio >= 0) {
                      _percGstAmt =
                          ((parseFloat(_totalItemAmount) -
                              parseFloat(_obj.discount_in_amount)) *
                              parseFloat(tax.ratio)) /
                          parseFloat(100);
                    }
                  }
                });
                if (_percGstAmt < 0) {
                  _obj.tax_value = 0;
                } else {
                  _obj.tax_value = _percGstAmt.toFixed(2);
                }
              }
              //_itemSubtotal = parseFloat(_totalItemAmount)  - parseFloat(_obj.discount_in_amount);
            }
          }

          if (_businessSetting.ItemWiseDiscount == 1) {
            if (type == "discount_in_per") {
              if (_obj.tax_value > 0) {
                //_totalItemAmount  = parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value);

                if (
                    _businessSetting.AllowInclusiveExclusive === 1 &&
                    field.sale_pruchase_tax_type === "Without Tax"
                ) {
                  _totalItemAmount =
                      parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value);
                }
              }
              if (_value == "" || _value == "null") {
                _value = 0;
              }
              if (_value > 100) {
                alert("Discount cannot be more than 100%");
              } else {
                _obj.discount_in_per = _value;
                if (_obj.discount_in_per >= 0) {
                  _percDisAmt =
                      (parseFloat(_totalItemAmount) *
                          parseFloat(_obj.discount_in_per)) /
                      parseFloat(100);
                }
                _obj.discount_in_amount = _percDisAmt.toFixed(2);
              }
              //_itemSubtotal = parseFloat(_totalItemAmount);
            }

            if (type == "discount_in_amount") {
              if (_value == "" || _value == "null") {
                _value = 0;
              }

              if (parseFloat(_value) > parseFloat(_itemPriceAK)) {
                alert("Discount amount cannot be more than total amount.");
              } else {
                _obj.discount_in_amount = _value;
                let _percDisPerc = 0;
                if (_obj.discount_in_amount >= 0) {
                  _percDisPerc =
                      (parseFloat(_obj.discount_in_amount) * parseFloat(100)) /
                      parseFloat(_totalItemAmount);
                }
                _percDisAmt = _obj.discount_in_amount;
                _obj.discount_in_per = _percDisPerc.toFixed(2);
              }
            }
          }

          /* Total Calculation */
          _obj.subtotal = _itemSubtotal.toFixed(2);
          _obj.total_amount = _totalItemAmount.toFixed(2);
          _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);
          _dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);
          _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
          /* End Total Calculation */
          fieldArray[_itemPosition] = _obj;
        } else if (_itemPosition == 0 && type == "") {
          let _obj = {};
          let _objTaxType = { id: "0", name: "", ratio: "0" };

          _obj.item_id = 0;
          _obj.item_name = "";
          _obj.quantity = 0;
          _obj.rate = 0;
          _obj.subtotal = 0;
          _obj.total_amount = 0;
          _obj.discount_in_per = 0;
          _obj.discount_in_amount = 0;
          _obj.tax_type = _objTaxType;
          _obj.tax_value = 0;
          _obj.sale_pruchase_tax_type = field.purchase_tax_type
              ? field.purchase_tax_type
              : "";
          fieldArray[_itemPosition] = _obj;
        } else {
          let _itemId = field.item_id ? field.item_id : 0;
          if (_itemId > 0) {
            let _obj = {};
            let _objTaxType = { id: "0", name: "", ratio: "0" };
            _obj.item_id = field.item_id ? field.item_id : 0;
            _obj.item_name = field.item_name;
            _obj.quantity = field.quantity ? field.quantity : "0";
            _obj.rate = field.rate ? field.rate : 0;
            _obj.subtotal = field.subtotal ? field.subtotal : 0;
            _obj.total_amount = field.total_amount ? field.total_amount : 0;
            _obj.discount_in_per = 0;
            _obj.discount_in_amount = 0;
            _obj.tax_type = _objTaxType;
            _obj.tax_value = 0;
            _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type
                ? field.sale_pruchase_tax_type
                : "";

            _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);

            //_paidAmount = this.state.paidAmount;
            //_dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);
            _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
          }
        }
      });

      this.setState({});
    }

    let _paidAM = 0;
    if (_paidAmount == 0) {
      _paidAM = 0;
    } else {
      _paidAM = _paidAmount.toFixed(2);
    }
    let _totalAmountOld = _totalAmt;
    let _totalAmountNew = _totalAmt;
    let _transactionWiseTaxId = 0;
    let _totalTransTaxAmount = 0;
    let _totalTransDiscountPer = 0;
    let _totalTransDiscountAmount = 0;

    let _amountDisK = 0;
    let _amountTaxK = 0;
    fieldArray.map((field, idx) => {
      let _discountAmtAk = field.discount_in_amount
          ? field.discount_in_amount
          : 0;
      let _discountTaxAk =  field.sale_pruchase_tax_type === 'With Tax'  ? 0 : (field.tax_value ? field.tax_value : 0);
      _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);
      _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
    });
    _totalAmt =
        parseFloat(_totalAmt) + parseFloat(_amountTaxK) - parseFloat(_amountDisK);
    _dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);

    this.setState({
      fields: fieldArray,
      totalQuantity: _quantity,
      dueAmount: _dueAmount.toFixed(2),
      totalAmount: _totalAmt.toFixed(2),
      totalAmountOld: _totalAmt.toFixed(2),
      subtotal: _totalAmt.toFixed(2),
      totalDiscount: _amountDisK.toFixed(2),
      gstAmount: _amountTaxK.toFixed(2),
    });

    this.props.callBackItem(
        fieldArray,
        _totalAmountOld,
        _totalAmountNew,
        _totalAmt.toFixed(2),
        _dueAmount.toFixed(2),
        _paidAM,
        _amountDisK,
        _amountTaxK,
        _quantity,
        _transactionWiseTaxId,
        _totalTransTaxAmount,
        _totalTransDiscountPer,
        _totalTransDiscountAmount
    );
    this.onMouseLeaveHandlerItem("I", _itemPosition);
    this.onItemFilterName();
  }

  getTotalAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalAmount = this.state.totalAmountOld;
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);
    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);
    return _totalAmount;
  }

  getTotalRemainAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalDuePayAmount = this.state.totalAmountOld;

    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);

    _totalDuePayAmount = parseFloat(_totalDuePayAmount) + parseFloat(_totalOtherCharges);

    if(parseFloat(this.state.receivedAmount) > 0) {
      _totalDuePayAmount = parseFloat(_totalDuePayAmount) - parseFloat(this.state.receivedAmount);
    }
    return _totalDuePayAmount;
  }

  dynamicInputFiled() {
    let _dataSet = [];
    if (this.state.fields.length > 0) {
      let _j = 0;
      this.state.fields.map((field, idx) => {
        _j++;
        let _key = idx + 222;

        let _hideMenuItem = "iti__hide";
        if (this.state._itemRegIndex == idx) {
          _hideMenuItem = this.state._itemReg;
        }

        let _itemId = field.item_id ? field.item_id : 0;
        let _taxTypeArray = field.tax_type
          ? field.tax_type
          : { id: "0", name: "", ratio: "0" };
        let _taxTypeId = "0";
        if (_taxTypeArray.id > 0) {
          _taxTypeId = _taxTypeArray.id;
        }
        const itemTaxTypeData = [
          { value: "Without Tax", label: "Without Tax" },
          { value: "With Tax", label: "With Tax" },
        ];
        _dataSet.push(
          <tr key={_key}>
            <td>
              <button
                type="button"
                onClick={this.handleRemove.bind(this, idx, _itemId)}
                className="btn btn-outline-danger"
              >
                X
              </button>
            </td>
            <td>{_j}</td>
            <td>
              <div className="col-lg-12">
                {/* onMouseEnter={this.manageShowItemFilter.bind(this,'I',idx)} onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)} */}
                <div
                  className="form-material open"
                  id="paddingOff"
                  onMouseLeave={this.onMouseLeaveHandlerItem.bind(
                    this,
                    "I",
                    idx
                  )}
                >
                  <input
                    type="text"
                    className="form-control search-box"
                    onChange={this.onItemFilterTypeName.bind(this, idx)}
                    onClick={this.manageShowItemFilter.bind(this, "I", idx)}
                    onKeyDown={this.manageShowItemFilter.bind(this, "I", idx)}
                    placeholder="Item Name Here..."
                    autoComplete="off"
                    name="item_name"
                    id="item_name"
                    value={field.item_name ? field.item_name : ""}
                  />
                  <ul
                    className={
                      _hideMenuItem + " iti__party-list iti__party-list--dropup"
                    }
                    id="country-listbox"
                    aria-expanded="false"
                    role="listbox"
                    aria-activedescendant="iti-item-ad"
                  >
                    {this.getReadyItemList(idx)}
                  </ul>
                </div>
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  maxLength="100"
                  required="required"
                  className="form-control"
                  id={"_qty_" + idx}
                  name={"_qty_" + idx}
                  value={field.quantity ? field.quantity : ""}
                  onChange={this.handleDynamicInputs.bind(
                    this,
                    idx,
                    "quantity"
                  )}
                />
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  required="required"
                  className="form-control"
                  id={"rate_" + idx}
                  name={"rate_" + idx}
                  value={field.rate ? field.rate : ""}
                  onChange={this.handleDynamicInputs.bind(this, idx, "rate")}
                />
              </div>
            </td>
            {_businessSetting.AllowInclusiveExclusive == 1 && (
              <td style={{ width: "140px" }}>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <select
                      className="form-control"
                      id={"salePurTaxType_" + idx}
                      name={"salePurTaxType_" + idx}
                      onChange={this.handleDynamicInputSalePurTaxType.bind(
                          this,
                          idx,
                          "salePurTaxType"
                      )}
                  >
                    {itemTaxTypeData.map(({ value, label }, index) => {
                      let _selSetTaxT = "";
                       if(field.sale_pruchase_tax_type == value)
                       {_selSetTaxT = 'selected';}
                      return (
                        <option
                          key={index}
                          value={value}
                          selected={_selSetTaxT}
                        >
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </td>
            )}

            {_businessSetting.ItemWiseDiscount == 1 && (
              <td>
                <tr>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        style={{ width: "60px" }}
                        min="0"
                        maxLength="99"
                        className="form-control"
                        id={"discount_in_per_" + idx}
                        value={
                          field.discount_in_per ? field.discount_in_per : ""
                        }
                        name={"discount_in_per_" + idx}
                        autoComplete="off"
                        onChange={this.handleDynamicInputs.bind(
                          this,
                          idx,
                          "discount_in_per"
                        )}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        min="0"
                        maxLength="99999"
                        className="form-control"
                        id={"discount_in_amount_" + idx}
                        value={
                          field.discount_in_amount
                            ? field.discount_in_amount
                            : ""
                        }
                        name={"discount_in_amount_" + idx}
                        autoComplete="off"
                        onChange={this.handleDynamicInputs.bind(
                          this,
                          idx,
                          "discount_in_amount"
                        )}
                      />
                    </div>
                  </td>
                </tr>
              </td>
            )}

            {_businessSetting.ItemWiseTax == 1 && (
              <td>
                <tr>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <select
                        style={{ width: "85px" }}
                        className="form-control"
                        id={"taxType_" + idx}
                        name={"taxType_" + idx}
                        onChange={this.handleDynamicInputs.bind(
                          this,
                          idx,
                          "taxType"
                        )}
                        //disabled={field.sale_pruchase_tax_type === "With Tax"}
                      >
                        <option value="" selected>
                          {" "}
                          None
                        </option>
                        {this.state.taxRateArray.map((tax, index) => {
                          let _sel = "";
                          if (_taxTypeId == tax.id) {
                            _sel = "selected";
                          }
                          return (
                            <option key={index} value={tax.id} selected={_sel}>
                              {tax.name + "@" + tax.ratio + "%"}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        readOnly={true}
                        min="0"
                        maxLength="100"
                        className="form-control"
                        value={field.tax_value ? field.tax_value : ""}
                        id={"tax_value_" + idx}
                        name={"tax_value_" + idx}
                      />
                    </div>
                  </td>
                </tr>
              </td>
            )}
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="text"
                  style={{ textAlign: "right" }}
                  readOnly="readOnly"
                  className="form-control"
                  id={"subtotal_" + idx}
                  name={"subtotal_" + idx}
                  value={field.subtotal ? field.subtotal : ""}
                />
              </div>
            </td>
          </tr>
        );
      });
    }
    return _dataSet;
  }
  /* End Add More Data */

  /* Start Item Code */
  onItemFilterName = () => {
    const data = this.state.itemArray;
    let fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        data.filter(function (item, index) {
          if (item.item_id == field.item_id) {
            //data[index].isFilter = true;
          }
        });
      });
    }
    this.setState({ itemArrayFilter: data });
    this.getReadyItemList();
  };

  onItemFilterTypeName = (idx, e) => {
    const data = this.state.itemArray;
    let _name = e.target.value;
    const keyword = _name; //_name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val == "string" && val.includes(keyword)
      )
    );

    const fieldArray = this.state.fields;
    fieldArray.map((field, index) => {
      if (index == idx) {
        field.item_name = keyword;
      }
    });

    this.setState({
      itemArrayFilter: filtered,
      item_name: keyword,
      fields: fieldArray,
    });
    this.getReadyItemList();
  };

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowPartyFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  getReadyItemList(index) {
    let data = [];
    if (this.state.isLoaded == true) {
      let _itemsArray = this.state.itemArrayFilter;
      if (_itemsArray.length > 0) {
        data = this.state.itemArrayFilter.map((items, i) => {
          if (items.isFilter == false) {
            let _obj = {};
            _obj.index = index;
            _obj.name = items.name;
            _obj.id = items.item_id;
            _obj.price = items.sale_price;
            _obj.tax_type = items.tax_type;
            _obj.sale_pruchase_tax_type = items.sale_tax_type;

            return (
              <li
                key={i}
                className="iti__party iti__standard"
                id="iti-item-in"
                onClick={this.handleDynamicInputs.bind(this, _obj, "item")}
              >
                <span className="iti__party-name">{items.name}</span>
              </li>
            );
          }
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewItem")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Expense Item
            </span>
          </li>
        );
      }
    }
    return data;
  }
  /* End Item Code */

  getItemTotalAmount(Itemposition, pricePerUnit, discountAmt, discountPer) {
    let _mainSubTotalAmt = 0;
    let _totalOtherCharges = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt +=
        parseFloat(data.total_amount) +
        parseFloat(data.tax_value) -
        parseFloat(data.discount_in_amount);
    });
    let _totalTaxAmount = _mainSubTotalAmt;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1
      ? this.state.TransAdditionalCharge1
      : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2
      ? this.state.TransAdditionalCharge2
      : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3
      ? this.state.TransAdditionalCharge3
      : 0;

    let _totalAmount = 0;
    return _totalAmount;
  }

  render() {
    return (
      <React.Fragment>
        {this.state.EnableItem == 1 && (
          <div className="row">
            <div className="col-md-12" id="formSideItem">
              <div className="block block-transparent">
                <div className="block-content" id="formSideItem">
                  <table className="table table-bordered table-striped table-vcenter">
                    <thead id="sideTable">
                      <tr>
                        <th style={{ width: "30px" }}></th>
                        <th style={{ width: "30px" }}>#</th>
                        <th style={{ width: "200px" }}>Item</th>

                        <th style={{ width: "50px" }}>Qty</th>
                        <th style={{ width: "150px" }}>Price / Unit</th>
                        {_businessSetting.AllowInclusiveExclusive == 1 && (
                          <th style={{ width: "150px" }}> Tax Type </th>
                        )}

                        {_businessSetting.ItemWiseDiscount == 1 && (
                          <th style={{ width: "150px" }}>
                            <tr>
                              <td style={{ width: "194px" }} colSpan="2">
                                Discount
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "130px" }}>%</td>
                              <td style={{ width: "100px" }}>Amount</td>
                            </tr>
                          </th>
                        )}

                        {_businessSetting.ItemWiseTax == 1 && (
                          <th style={{ width: "150px" }}>
                            <tr>
                              <td style={{ width: "194px" }} colSpan="2">
                                Tax
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50px" }}>%</td>
                              <td style={{ width: "100px" }}>Amount</td>
                            </tr>
                          </th>
                        )}
                        <th style={{ width: "100px" }}>Amount</th>
                      </tr>
                    </thead>

                    <tbody id="sideTable">
                      {this.dynamicInputFiled()}

                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            className="badge badge-primary pull-left"
                            onClick={this.handleAdd.bind(this)}
                          >
                            Add Item
                          </button>
                          <span className="titleTable">Total</span>
                        </td>

                        <td>
                          <span className="titleTable">
                            {this.state.totalQuantity}
                          </span>
                        </td>

                        <td></td>
                        {_businessSetting.AllowInclusiveExclusive == 1 && (
                          <td></td> 
                        )}
                        {_businessSetting.ItemWiseDiscount == 1 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalDiscount}
                            </span>
                          </td>
                        )}

                        {_businessSetting.ItemWiseTax == 1 && (
                          <td>
                            <span className="titleTable">
                              {this.state.gstAmount}
                            </span>
                          </td>
                        )}

                        {this.state.transactionId > 0 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalQuantity > 0
                                ? this.state.subtotal
                                : 0}
                            </span>
                          </td>
                        )}
                        {this.state.transactionId == 0 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalQuantity > 0
                                ? this.state.totalAmountOld
                                : 0}
                            </span>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal Edit Item Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SITEM}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Item</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewItem
                    callBack={(data) => {
                      let _itemArray = this.state.itemArray;
                      _itemArray.push(data[0]);

                      let _quantityAK = data[0].opening_stock
                        ? data[0].opening_stock
                        : 0;
                      let _totalQuantity =
                        parseInt(_quantityAK) +
                        parseInt(this.state.totalQuantity);

                      let _totalGstAmt = this.state.gstAmount;
                      let _totalDiscount = this.state.totalDiscount;
                      let _totalAmountOld = this.state.totalAmountOld;
                      let percenTageAmt = 0;
                      if (_totalDiscount == 0 || _totalDiscount == "") {
                        _totalDiscount = data[0].discount_in_sale_price;
                      }
                      if (_totalGstAmt == 0 || _totalGstAmt == "") {
                        _totalGstAmt = data[0].tax_rate_ratio;
                      }
                      let _ratioAk = data[0].tax_type.ratio
                        ? data[0].tax_type.ratio
                        : 0;
                      percenTageAmt =
                        parseFloat(data[0].sale_price) * (_ratioAk / 100);

                      if (_totalGstAmt > 0) {
                        _totalGstAmt =
                          parseFloat(percenTageAmt) +
                          parseFloat(this.state.gstAmount);
                      } else {
                        _totalGstAmt =
                          parseFloat(_totalGstAmt) + parseFloat(percenTageAmt);
                      }

                      const arrayLength =
                        parseInt(this.state.fields.length) + parseInt(1);
                      let objItemData = {};
                      let _unitArray = [];
                      _unitArray.push(data[0].unit);

                      objItemData.item_id = data[0].item_id;
                      objItemData.item_name = data[0].name;
                      objItemData.quantity = _quantityAK;
                      objItemData.rate = data[0].purchase_price;
                      objItemData.discount_in_per =
                        data[0].discount_in_sale_price;
                      objItemData.discount_in_amount =
                        data[0].discount_in_sale_price;
                      objItemData.tax_value = data[0].tax_rate_ratio;
                      objItemData.subtotal = 0;
                      objItemData.total_amount = (
                        parseFloat(_quantityAK) *
                        parseFloat(data[0].purchase_price)
                      ).toFixed(2);
                      objItemData.availLenAk = 0;
                      objItemData.totalStocks = 0;
                      objItemData.availableQty = 0;
                      objItemData.type = "ITEM";
                      objItemData.tax_type = data[0].tax_type;
                      objItemData.isFilter = false;

                      let newArray = [];
                      for (let index = 0; index < arrayLength; index++) {
                        if (this.state.fields[index]) {
                          if (
                            this.state.fields[index]["item_name"] ===
                            objItemData.item_name
                          ) {
                            newArray.push(objItemData);
                          } else {
                            newArray.push(this.state.fields[index]);
                          }
                        }
                      }

                      let _itemTotal = (
                        parseFloat(_quantityAK) *
                        parseFloat(data[0].purchase_price)
                      ).toFixed(2);
                      let _totalAmtAK =
                        parseFloat(_totalAmountOld) +
                        parseFloat(
                          _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)
                        ) +
                        parseFloat(_itemTotal);

                      let _totalAmt =
                        parseFloat(_totalAmountOld) +
                        parseFloat(
                          _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)
                        ) +
                        parseFloat(_itemTotal);

                      let _dueAmount =
                        parseFloat(_totalAmountOld) +
                        parseFloat(
                          _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)
                        ) +
                        parseFloat(_itemTotal);

                      let _paidAM = 0;
                      let _totalAmountNewSend = _totalAmountOld;
                      let _totalAmountOldSend = _totalAmtAK;
                      let _handleAddDisable = "";
                      //this.props.callBackItem(newArray,_totalAmountOldSend,_totalAmountNewSend,_totalAmt,_dueAmount,_paidAM,_totalDiscount,_totalGstAmt,_totalQuantity,_handleAddDisable,this.state.handleItemSalePurchasePrice);

                      this.setState({
                        fields: newArray,
                        itemArrayFilter: _itemArray,
                        itemArray: _itemArray,
                        isLoaded: true,
                        itemPosition: -1,
                        modalIsOpen_SITEM: false,
                        totalQuantity: _totalQuantity,
                        totalAmount: _totalAmtAK.toFixed(2),
                        totalAmountOld: _totalAmtAK.toFixed(2),
                        totalAmountNew: _totalAmtAK.toFixed(2),
                        gstAmount: _totalGstAmt.toFixed(2),
                        totalDiscount: _totalDiscount,
                        dueAmount: _totalAmtAK.toFixed(2),
                        handleAddDisable: "",
                      });

                      this.getReadyItemList();
                    }}
                    newItemId={this.state.newItemId}
                    itemName={this.state.item_name}
                    itemCreateType="Purchase"
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Item Details*/}
      </React.Fragment>
    );
  }
}
