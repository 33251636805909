import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from 'react-modal';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';

let _UserSession = localStorage.getItem('vs_UserSession');
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';

if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _companyId = _companySession.company_id;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Item Name',
        field: 'item_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Item Name',
        },
    },
    {
        label: 'Item Code',
        field: 'code',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Item Code',
        }
    },
    {
        label: 'HSN/SAC',
        field: 'hsn_sac',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'HSN/SAC',
        },
    }, 
    {
        label: 'Sale Price',
        field: 'sale_price',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Sale Price',
        },
    },
    {
        label: 'Purchase Price',
        field: 'purchase_price',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Purchase Price',
        },
    },
    {
        label: 'Discount Type',
        field: 'discount_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Discount Type',
        },
    },
    {
        label: 'Discount In Sale Price',
        field: 'discount_in_sale_price',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Discount In Sale Price',
        },
    },
    {
        label: 'Current Stock',
        field: 'remaining_stock',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Remaining Stock',
        }
    },
    {
        label: 'Min Stock Quantity',
        field: 'min_stock_quantity',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Min Stock Quantity',
        }
    },
    {
        label: 'Item Location',
        field: 'item_location',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Item Location',
        }
    },
    {
        label: 'Tax Rate',
        field: 'tax_rate_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Tax Rate',
        }
    },
    {
        label: 'Sale Tax',
        field: 'sale_tax_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Sale Tax',
        }
    }
];

let _loader = 'glb-ldr-prt active';

export default class manageExportItemListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : true,
            modalIsOpen_IMI : false,
            itemArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            startDate : moment().startOf('month').toDate(),
            endDate : moment().endOf('month').toDate(),
            dateLabel : '',
            importExcelFile : '',
            errors: {
                name : '',
                importExcelFile : '',
            }
        }
    }
    async componentDidMount ()
    {   
        this.getPartyListData();
    }
    getPartyListData(){
        let _itemArray = [];
        
        const requestData = JSON.stringify({
            company_id: this.state.company_id,
            activeFilter : true,
            inActiveFilter : false,
        });  
        PostData(global.userItemList,requestData,'POST').then((result)=>{   
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({itemArrayFilter:_itemArray,itemArray:_itemArray,isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _itemArray = responseJson.response;
                    _itemArray.map((itm, i) =>{
                        
                    });
                }
                this.setState({itemArrayFilter:_itemArray,itemArray:_itemArray,isLoaded:true,});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }    

    loadTranscListing(){
        let dataList = [];
        let rows = [];        
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.itemArrayFilter;
            if(_transDetails.length > 0){
                let _i = 0;
                _transDetails.map((data,index) => {
                    _i++;
                    let _tax_rate_name  = '';
                    if(data.tax_rate_name.length > 0){
                        _tax_rate_name = data.tax_rate_name+' %';
                    }
                    rows.push({
                        srNo: _i,
                        item_name: data.name,
                        hsn_sac: data.hsn_sac ? data.hsn_sac : 'NA',
                        sale_price: data.sale_price,
                        purchase_price: data.purchase_price,
                        discount_type: data.discount_type,
                        discount_in_sale_price: data.discount_in_sale_price,
                        remaining_stock: data.availableQty,
                        min_stock_quantity: data.min_stock_quantity,
                        item_location: data.item_location,
                        tax_rate_name: _tax_rate_name,
                        sale_tax_type: data.sale_tax_type,
                        code: data.code,                        
                    });
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }    
    
    /* Code For Upload - Import Excel */
    eportItemData = (event) =>{
        let _itemDetails = this.state.itemArrayFilter;
        try {
            _itemDetails = _itemDetails.map((data) => {
                
                let _tax_rate_name  = '';
                if(data.tax_rate_name.length > 0){
                    _tax_rate_name = data.tax_rate_name+' %';
                }
                let newData = {
                    'Item Code': data.code,
                    'Name': data.name,
                    'HSN SAC': data.hsn_sac,
                    'Sale Price': data.sale_price,
                    'Purchase Price': data.purchase_price,
                    'Discount Type': data.discount_type,
                    'Discount Price': data.discount_in_sale_price,
                    'Remaining Stock': data.availableQty,
                    'Minimum Stock': data.min_stock_quantity,
                    'Item Location': data.item_location,
                    'Tax': _tax_rate_name,
                    'With Tax': data.sale_tax_type == 'With Tax' ? 'Y' : 'N',
                }
                return newData;
            })
            var ws = XLSX.utils.json_to_sheet(_itemDetails);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb,ws,"Items");
            const wbout = XLSX.write(wb, {type:'array', bookType:"xlsx"});
            let length = 6;
            let uniqueNo = String(Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)));
            let fileName = 'exported_items_'+uniqueNo+'.xlsx';            
            const data = new Blob([wbout], {type: "xlsx"});
            FileSaver.saveAs(data, fileName);
            
        } catch (err) {
            //alert('Unknown Error: ' + JSON.stringify(err));
            throw err;
        }
    }
    
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Export Items<br/>
                            </h1>                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <Link className="breadcrumb-item" to={"/business/dashboard/"}>Dashboard</Link>                    
                        <span className="breadcrumb-item active">Manage Export Items</span>
                    </nav>                  

                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <h2 className="block-title ml-10">Transactions</h2>
                                        <Link onClick={this.eportItemData}  style={{marginTop: '35px'}} className="pull-right pull-right btn btn-sm btn-primary mb-10">
                                            <i className="si si-equalizer"></i> Export Items
                                        </Link>
                                    </div>
                                    <div className="block-content" id="tableData">
                                        <MDBDataTableV5 
                                        hover
                                        entriesOptions={[25, 50, 100]}
                                        entries={25}
                                        pagesAmount={4}
                                        span
                                        pagingTop
                                        searchTop
                                        searchBottom={false}
                                        barReverse
                                        data={this.loadTranscListing()}
                                        />
                                    </div>
                                </div>                                 
                            </div>
                        </div>
                    </div>
                </div>                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>                

            </React.Fragment>
        )    
    }
}