import React, {useEffect, useState} from "react";
import "../../../index.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import {numberFormat} from "../../../config/numberFormat";


const QuickTransactionSaleInvoiceSection = (props) => {

    const [invoiceArray, setInvoiceArray] = useState([]);
    const [tempInvoice, setTempInvoice] = useState({items: []});
    const [selectedInvoice, setSelectedInvoice] = useState([]);
    const [changeQty, setChangeQty] = useState({invoiceIndex: 0, itemIndex: 0, item: {}});
    const [selectedItem, setSelectedItem] = useState({invoiceIndex: 0, itemIndex: 0, item: {}});
    const [totalAmount, setTotalAmount] = useState(0);
    const [businessSetting, setBusinessSetting] = useState({});
    const [transactionType, setTransactionType] = useState(props.transactionType);
    const [isEditMode, setIsEditMode] = useState(props.isEditMode);
    const [isConvert, setIsConvert] = useState(props.isConvert);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        let _UserSession = localStorage.getItem("vs_UserSession");
        if (_UserSession!=undefined || _UserSession!=null) {
            _UserSession = JSON.parse(_UserSession);
            setBusinessSetting(_UserSession.businessSetting);
        }
    },[]);

    useEffect(() => {
        setIsEditMode(props.isEditMode);
    },[props.isEditMode]);

    useEffect(() => {
        setIsConvert(props.isConvert);
    },[props.isConvert]);

    useEffect(() => {
        let invoices = props.invoiceArray;

        invoices.sort(function(a, b) {
            var c = new Date(a.invoiceDate +' '+a.invoiceTime);
            var d = new Date(b.invoiceDate +' '+b.invoiceTime);
            return d-c;
        });
        setInvoiceArray(invoices);

    },[props.invoiceArray])
    useEffect(() => {
        let invoiceData = props.selectedInvoice;

        if(invoiceData.length > 0){
            let totalAmount = 0;
            invoiceData = invoiceData.map((data) => {
                return ({
                    ...data,
                    items : data.items.filter((item) => item.isSelect).map((item) => {
                        totalAmount += item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                        if(item.sale_pruchase_tax_type == 'Without Tax'){
                            totalAmount += item.tax_value
                        }
                        return ({
                            ...item,

                        })
                    })
                })
            });
            setSelectedInvoice(invoiceData);
            setTotalAmount(totalAmount);
        }
    }, [props.selectedInvoice])

    const getTransactionTypeShortName = (type) => {
        let text = type;
        if (type == 'SALE') {
            text = 'Sale #';
        } else if (type == 'PAYMENT-IN') {
            text = 'PayIn  #';
        } else if (type == 'CREDIT NOTE') {
            text = 'CN #';
        } else if (type == 'ESTIMATE') {
            text = 'Estimate #';
        } else if (type == 'SALE ORDER') {
            text = 'Sale Order #';
        } else if (type == 'DELIVERY CHALLAN') {
            text = 'Delivery Challan #';
        } else if (type == 'PURCHASE') {
            text = 'Purchase #';
        } else if (type == 'PAYMENT-OUT') {
            text = 'PayOut #';
        } else if (type == 'DEBIT NOTE') {
            text = 'DN #';
        } else if (type == 'PURCHASE ORDER') {
            text = 'Purchase Order #';
        } else if (type == 'EXPENSE') {
            text = 'Exp No. #';
        } else if (type) {
            text = type;
        } else {
            text = '';
        }
        return text;
    };

    const updateItemView = (invoice) => {
        let newInvoiceItem = {...invoice};
        newInvoiceItem.items = newInvoiceItem.items.map((item) => {
            let newQuantity = item.quantity;
            let perUnitPrice = item.rate / item.quantity;
            let subtotal = newQuantity * perUnitPrice;
            //let tax_value = subtotal * (item.tax_type.ratio ? item.tax_type.ratio : 0) / 100;

            return ({
                ...item,
                newQuantity: newQuantity,
                //total_amount: subtotal,
                //tax_value: tax_value,
            })
        });
        return newInvoiceItem
    }

    const invoiceItemSave = () => {
        if(tempInvoice.items.filter((item) => item.isSelect).length > 0){
            let data = invoiceArray.map((item, itemIndex) => ({
                ...item,
                isSelect: tempInvoice.invoice_no == item.invoice_no ? true : item.isSelect,
                items: tempInvoice.invoice_no == item.invoice_no ? tempInvoice.items : item.items
            }))
            setInvoiceArray(data);
            let remainingSelectedInvoice = data.filter((data) => data.isSelect);
            setSelectedInvoice(remainingSelectedInvoice);
            setTempInvoice({items: []});
            let totalAmount = 0;
            remainingSelectedInvoice.map((data) => {
                data.items.filter((item) => item.isSelect).map((item) => {
                    totalAmount += item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                    if(item.sale_pruchase_tax_type == 'Without Tax'){
                        totalAmount += item.tax_value
                    }
                })
            })
            setTotalAmount(totalAmount);
            props.callBackItem(remainingSelectedInvoice, totalAmount);
        }else {
            alert('Please select at least one item!');
        }
    }
    const saveChangeQty = () => {
        if(parseInt(changeQty.item.newQuantity) > 0){
            let bb = [...selectedInvoice];
            let item = changeQty.item;

            if(item.quantity < item.newQuantity){
                alert('can\'t be greater then '+item.quantity+ 'Qty')
                return;
            }
            item.total_amount = item.newQuantity * (item.rate);
            bb[changeQty.invoiceIndex].items[changeQty.itemIndex] = item;

            setSelectedInvoice(bb);
            let totalAmount = 0;
            bb.map((data) => {
                data.items.filter((item) => item.isSelect).map((item) => {
                    totalAmount += item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                    if(item.sale_pruchase_tax_type == 'Without Tax'){
                        totalAmount += item.tax_value
                    }
                })
            })
            setTotalAmount(totalAmount);
            props.callBackItem(bb, totalAmount);
        }else {
            alert('Please enter some quantity!');
        }

    }
    const saveChangeSerialQty = () => {
        let bb = [...selectedInvoice];
        let item = {...selectedItem.item};
        item.newQuantity = item.itemSerialNoAllData.filter((data) => data.isSelected).length;
        //item.total_amount = item.newQuantity * (item.rate);

        bb[selectedItem.invoiceIndex].items[selectedItem.itemIndex] = item;

        setSelectedInvoice(bb);
        let totalAmount = 0;
        bb.map((data) => {
            data.items.filter((item) => item.isSelect).map((item) => {
                totalAmount += item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                if(item.sale_pruchase_tax_type == 'Without Tax'){
                    totalAmount += item.tax_value
                }
            })
        })
        setTotalAmount(totalAmount);
        props.callBackItem(bb, totalAmount);

    }
    const saveChangeBatchQty = () => {
        let bb = [...selectedInvoice];
        let item = {...selectedItem.item};
        item.newQuantity = item.itemBatchNoAllData.filter((data) => data.isSelected).length;
        //item.total_amount = item.newQuantity * (item.rate);

        bb[selectedItem.invoiceIndex].items[selectedItem.itemIndex] = item;

        setSelectedInvoice(bb);
        let totalAmount = 0;
        bb.map((data) => {
            data.items.filter((item) => item.isSelect).map((item) => {
                totalAmount += item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                if(item.sale_pruchase_tax_type == 'Without Tax'){
                    totalAmount += item.tax_value
                }
            })
        })
        setTotalAmount(totalAmount);
        props.callBackItem(bb, totalAmount);

    }
    const deleteItem = (mainIndex, itemIndex, inv_item_id) => {
        let _selectedInvoice = [...selectedInvoice];
        let deletedInvoice = _selectedInvoice.filter((data, index) => index == mainIndex);
        let deletedItem = deletedInvoice[0].items.filter((data, index) => index!=itemIndex);

        if(deletedItem.length > 0){
            _selectedInvoice[mainIndex].items = deletedItem;
        }else {
            _selectedInvoice = _selectedInvoice.filter((data, index) => index!=mainIndex);
        }
        setSelectedInvoice(_selectedInvoice);
        let invoiceData = invoiceArray.map((item, _mainIndex) => ({
            ...item,
            items: item.items.map((data, index) => {
                return({
                    ...data,
                    isSelect: data.inv_item_id == inv_item_id ? false : data.isSelect,
                    itemSerialNoAllData: data.inv_item_id == inv_item_id ? data.itemSerialNoAllData.map((serial) => ({
                        ...serial,
                        isSelected: true
                    })) : data.itemSerialNoAllData
                })
            })
        }));
        invoiceData = invoiceData.map((item) => ({
            ...item,
            isSelect : item.items.filter((data) => data.isSelect).length > 0,
        }));
        setInvoiceArray(invoiceData);
        let totalAmount = 0;
        _selectedInvoice.map((data) => {
            data.items.filter((item) => item.isSelect).map((item) => {
                totalAmount += item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                if(item.sale_pruchase_tax_type == 'Without Tax'){
                    totalAmount += item.tax_value
                }
            })
        })
        setTotalAmount(totalAmount);
        props.callBackItem(_selectedInvoice, totalAmount);
    }

    const isAdditionalCess = () => {
        let isAdditionalCess = false;
        selectedInvoice.length > 0 && selectedInvoice.map((data, index) => {
            data.items.filter((item) => item.isSelect).map((item, itemIndex) => {
                if(item.Additional_CESS > 0){
                    isAdditionalCess = true;
                }
            });
        });
        return isAdditionalCess;
    }

    const dynamicInputFiled = () => {
        return selectedInvoice.length > 0 && selectedInvoice.map((data, index) => {
            let isPaidOrReceive;
            return data.items.filter((item) => item.isSelect).map((item, itemIndex) => {
                let subTotal = item.rate * item.newQuantity - (transactionType == 3 && item.discount_in_amount) + item.Additional_CESS;
                if(item.sale_pruchase_tax_type == 'Without Tax'){
                    subTotal += item.tax_value
                }
                return (
                    <tr key={itemIndex}>
                        {
                            (!isConvert && !isEditMode) &&
                            <td>
                                <button
                                    type="button"
                                    onClick={() => {
                                        deleteItem(index, itemIndex, item.inv_item_id)
                                    }}
                                    className="btn btn-outline-danger"
                                >
                                    X
                                </button>
                            </td>
                        }

                        <td>{itemIndex+1}</td>
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <span>{data.prefix.id > 0  && "(" +data.prefix.name+")" }{item.invoice_no}</span>
                            </div>
                        </td>

                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <span>{item.item_name}</span>
                            </div>
                        </td>
                        {
                            isAdditionalCess() &&
                            <td>
                                <div className="col-lg-12 form-material" id="paddingOff">
                                    <span>{item.Additional_CESS}</span>
                                </div>
                            </td>
                        }

                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <span style={{marginRight: 10}}>{item.free_quantity > 0 && '('+item.free_quantity+' Free)'} {item.newQuantity} {item.unit.slug} X {(item.rate).toFixed(2)}</span>
                                {
                                    (item.itemSerialNoAllData.length > 0) ?
                                        <button type="button" className="btn btn-outline-danger"
                                                data-toggle="modal"
                                                data-target="#viewSerialModal" onClick={() => {
                                            let aa = {...item};
                                            setSelectedItem({invoiceIndex: index, itemIndex: itemIndex, item: aa})
                                        }}>
                                            <span>View Serial</span>
                                        </button>  :
                                        item.itemBatchNoAllData.length > 0 ?
                                            <button type="button" className="btn btn-outline-danger"
                                                    data-toggle="modal"
                                                    data-target="#viewBatchModal" onClick={() => {
                                                let aa = {...item};
                                                setSelectedItem({invoiceIndex: index, itemIndex: itemIndex, item: aa})
                                            }}>
                                                <span>View Batch</span>
                                            </button> :
                                            (item.normal_quantity > 1 &&
                                            <button type="button" className="btn btn-outline-danger"
                                                data-toggle="modal"
                                                data-target="#changeQtyModal" onClick={() => {
                                            let aa = {...item};
                                            setChangeQty({invoiceIndex: index, itemIndex: itemIndex, item: aa})
                                        }}>
                                            <span>{isConvert ? "View" : "Change"} QTY</span>
                                        </button>)
                                }
                            </div>
                        </td>
                        {
                            transactionType == 3 &&
                            <td>
                                <div className="col-lg-12 form-material" id="paddingOff">
                                    <span>{item.discount_in_amount}</span>
                                </div>
                            </td>
                        }
                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <span>{item.tax_type.ratio ? (item.tax_type.ratio + "("+ item.sale_pruchase_tax_type + ")") : 0}</span>
                            </div>
                        </td>

                        <td>
                            <div className="col-lg-12 form-material" id="paddingOff">
                                <span>{subTotal}</span>
                            </div>
                        </td>
                    </tr>
                )
            })
        })
    }
    return (
        <div className="App">
            <div className="row">
                <div className="col-md-12" id="formSideItem">
                    <div className="block block-transparent">
                        <div className="block-content" id="formSideItem">
                            <table className="table table-bordered table-striped table-vcenter">
                                <thead id="sideTable">
                                <tr>
                                    {
                                        (!isConvert && !isEditMode) &&
                                        <th style={{ width: "30px" }}></th>
                                    }
                                    <th style={{ width: "30px" }}>#</th>
                                    <th style={{ width: "130px" }}>{(transactionType == 1 || transactionType == 3) ? "Invoice No" : "Bill No"}</th>
                                    <th style={{ width: "130px" }}>Item Name</th>
                                    {
                                        isAdditionalCess() &&
                                        <th style={{ width: "200px" }}>Additional CESS</th>
                                    }
                                    <th style={{ width: "200px" }}>Quantity</th>
                                    {
                                        transactionType == 3 &&
                                        <th style={{ width: "200px" }}>Discount %</th>
                                    }
                                    <th style={{ width: "80px" }}>Tax %</th>
                                    <th style={{ width: "100px" }}>Subtotal</th>
                                </tr>
                                </thead>

                                <tbody id="sideTable">
                                {dynamicInputFiled()}
                                <tr>
                                    {
                                        (!isConvert && !isEditMode) &&
                                        <td></td>
                                    }
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {
                                            (!isConvert && selectedInvoice.length == 0) &&
                                            <button type="button" className="btn btn-primary" data-toggle="modal"
                                                    data-target="#invoiceModal">
                                                {(transactionType == 1 || transactionType == 3) ? "Add Invoice" : "Add Bill"}
                                            </button>
                                        }
                                    </td>
                                    {
                                        isAdditionalCess() &&
                                        <td></td>
                                    }
                                    <td>
                                        <span><b>Total</b></span>
                                    </td>

                                    {
                                        transactionType == 3 &&
                                        <td></td>
                                    }
                                    <td></td>
                                    <td>
                                        <span><b>{totalAmount}</b></span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/*Select invoice dialog start*/}
            <div className="modal fade" id="invoiceModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document" style={{maxWidth: '45%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {(transactionType == 1 || transactionType == 3) ? "Select Invoice" : "Select Bill"}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div style={{marginLeft: 20, marginRight: 20}}>
                            <input
                                type="text"
                                className="form-control search-box"
                                onChange={(e) => {
                                    let value = e.currentTarget.value;
                                    setFilterText(value)
                                }}
                                placeholder={"Search by "+ ((transactionType == 1 || transactionType == 3) ? "Invoice No" : "Bill No") + " and total amount"}
                                autoComplete="off"
                                value={filterText}
                            />
                        </div>
                        <div className="modal-body">
                            <div className="list-group">
                                {
                                    invoiceArray.filter((item) => String(item.invoice_no).includes(filterText) ||
                                        String((item.totalSummary.reverseChargeStatus == 1 ? item.totalSummary.payableAmount : item.totalSummary.totalAmount)).includes(filterText)).map((data, index) => {

                                        return(
                                            <button type="button" onClick={() => {
                                                let newInvoiceItem = updateItemView(data);
                                                console.log('transactionType', transactionType);
                                                if(transactionType == 1 || transactionType == 2){
                                                    if(parseInt(newInvoiceItem.totalTransDiscountAmount) > 0){
                                                        if(transactionType == 1){
                                                            alert('You can\'t create a sales return for this sales invoice as there is a discount given. To create a sales return please go to the sales invoices section and remove the discount from this sales invoice. Thanks');
                                                        }else{
                                                            alert('You can\'t create a purchase return for this purchase bill as there is a discount given. To create a purchase return please go to the purchase bill section and remove the discount from this purchase bill. Thanks');
                                                        }

                                                        return;
                                                    }
                                                }
                                                console.log('newInvoiceItem', newInvoiceItem);
                                                setTempInvoice(newInvoiceItem);
                                            }} data-toggle="modal" data-dismiss="modal" data-target={(transactionType!=3 && parseInt(data.totalTransDiscountAmount) > 0) ? '' : "#invoiceItemModal"}
                                                 className={"list-group-item list-group-item-action "+( data.isSelect ? 'active' : '')}>

                                                <div className="row">
                                                    <div className="col-4">
                                                        <span>{ (transactionType == 1 || transactionType == 3) ? "Invoice No" : "Bill No" }</span><br/>
                                                        <span>{data.prefix.id > 0  &&
                                                        "(" +data.prefix.name+")"
                                                        }{data.invoice_no}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span >{ (transactionType == 1 || transactionType == 3) ? "Invoice Date" : "Bill Date" }</span><br/>
                                                        <span >{data.invoiceDate} {businessSetting.Tran_AddTime!=0 && data.invoiceTime}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span >Status</span><br/>
                                                        <span className={data.payment_paid == 'UNPAID' ? "badge badge-warning" : (data.payment_paid == 'PAID' ? "badge badge-success" : "badge badge-info")}>{data.payment_paid}</span>
                                                    </div>
                                                </div>
                                                <div className="row" style={{marginTop: 20}}>
                                                    <div className="col-4">
                                                        <span>Total Amount</span><br/>
                                                        <span>{data.totalSummary.reverseChargeStatus == 1 ? data.totalSummary.payableAmount : data.totalSummary.totalAmount}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span>Balance Amount</span><br/>
                                                        <span>{data.totalSummary.dueAmount}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span>Total Item</span><br/>
                                                        <span>{data.items.length}</span>
                                                    </div>
                                                </div>
                                                {index!=invoiceArray.length -1 && <div style={{ borderBottom: "1px solid #e4e7ed", marginTop: 10 }}></div>}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    data-dismiss="modal">Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*Select invoice dialog end*/}
            {/*Select invoice item dialog start*/}
            <div className="modal fade" id="invoiceItemModal" tabIndex="-2" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document" style={{maxWidth: '45%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {(transactionType == 1 || transactionType == 3) ? "Invoice Items Details" : "Bill Items Details"}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    data-toggle="modal" data-target="#invoiceModal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <span className="modal-title" id="exampleModalLabel">
                                { (transactionType == 1 || transactionType == 3) ? "Invoice No" : "Bill No" }: {tempInvoice.prefix && (tempInvoice.prefix.id > 0  && "(" +tempInvoice.prefix.name+")" )} {tempInvoice.invoice_no}</span>
                        <div className="modal-body">
                            <div className="list-group">
                                {
                                    tempInvoice.items && tempInvoice.items.map((data, index) => {
                                        return(
                                            <button type={'button'} onClick={() => {
                                                let _tempInvoice = {...tempInvoice};
                                                //if(_tempInvoice.items.filter((item) => parseInt(item.discount_in_amount) > 0).length > 0){
                                                if(transactionType == 1 || transactionType == 2){
                                                    if(parseFloat(data.discount_in_amount) > 0){
                                                        if(transactionType == 1){
                                                            alert('You can\'t create a sales return for this sales invoice as there is a discount given. To create a sales return please go to the sales invoices section and remove the discount from this sales invoice. Thanks');
                                                        }else{
                                                            alert('You can\'t create a purchase return for this purchase bill as there is a discount given. To create a purchase return please go to the purchase bill section and remove the discount from this purchase bill. Thanks');
                                                        }
                                                        return;
                                                    }
                                                }
                                                _tempInvoice.items = _tempInvoice.items.map((data, dataIndex) => ({
                                                    ...data,
                                                    isSelect: index == dataIndex ? !data.isSelect : data.isSelect
                                                }));
                                                setTempInvoice(_tempInvoice);
                                            }} className={"list-group-item list-group-item-action "+( data.isSelect ? 'active' : '')}>
                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <span><b>{index+1}. {data.item_name}</b></span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span>{data.rate * data.newQuantity}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <span>Item Subtotal</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span>{data.newQuantity} {data.unit.slug} X {(data.rate).toFixed(2)} = {data.rate * data.newQuantity}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <span>Discount %</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span>{data.discount_in_per ? (data.discount_in_per).toFixed(2) : 0.0}%</span>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <span>Tax % {data.tax_type.name == 'None' ? '' : data.tax_type.name}</span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span>{data.tax_type.ratio ? data.tax_type.ratio : 0.0}%</span>
                                                    </div>
                                                </div>

                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    data-dismiss="modal" data-toggle="modal"
                                    data-target="#invoiceModal">Close
                            </button>
                            <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={() => invoiceItemSave()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*Select invoice item dialog end*/}
            {/*Change Quantity dialog start*/}
            <div className="modal fade" id="changeQtyModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{maxWidth: '45%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {isConvert ? 'View' : 'Change'} Quantity</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="list-group">
                                <input
                                    // type="text"
                                    className="form-control search-box"
                                    onChange={(e) => {
                                        let aa = {...changeQty};
                                        aa.item.newQuantity = e.target.value;
                                        setChangeQty(aa);
                                    }}
                                    type="number"
                                    placeholder="Change Quantity...."
                                    autoComplete="off"
                                    name="quantity"
                                    id={"quantity"}
                                    readOnly={isConvert}
                                    value={changeQty.item.newQuantity}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    data-dismiss="modal" data-toggle="modal"
                                    data-target="#changeQtyModal">Close
                            </button>
                            {
                                !isConvert &&
                                <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={() => saveChangeQty()}>Save</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/*Select Quantity dialog end*/}
            {/*Select view serial dialog end*/}
            <div className="modal fade" id="viewSerialModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{maxWidth: '35%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Serial Details</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                selectedItem.item.item_name &&
                                <div className="list-group">
                                    <h3 className="list-group-item list-group-item-action active">{selectedItem.item.item_name}</h3>
                                    {
                                        selectedItem.item.itemSerialNoAllData.map((data, index) => {
                                            return(
                                                <div key={index} className={"list-group-item "+(!data.isSelected ? "list-group-item-dark" : "")} style={{border: '2px solid #e4e7ed'}}>
                                                    {
                                                        data.isFreeQuantity &&
                                                        <div style={{ transform: 'rotate(-40deg)', position: 'absolute', left: 5}}>
                                                            <span style={{color: 'green', fontSize: 20}}>FREE</span>
                                                        </div>
                                                    }
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.SerialNoTxt} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.serial_no}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.SerialNoAdditionalLabel1} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.serialNoAdditionalValue1}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.SerialNoAdditionalLabel2} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.serialNoAdditionalValue2}</span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-6">

                                                        </div>
                                                        <div className="col-6">
                                                            {
                                                                ((selectedItem.item.itemSerialNoAllData.filter((data) => data.isSelected && !data.isFreeQuantity).length!=1 && data.isSelected) && !data.isFreeQuantity && !isConvert)&&
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let serialData = selectedItem.item.itemSerialNoAllData.map((data, itemIndex) => ({
                                                                            ...data,
                                                                            isSelected: index == itemIndex ? false : data.isSelected
                                                                        }));
                                                                        let _selectedItem = {...selectedItem};
                                                                        _selectedItem.item.itemSerialNoAllData = serialData;
                                                                        setSelectedItem(_selectedItem);
                                                                    }}
                                                                    className="btn btn-outline-danger"
                                                                >
                                                                    Delete
                                                                </button>
                                                            }

                                                            {
                                                                (!isConvert && !data.isSelected) &&
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        let serialData = selectedItem.item.itemSerialNoAllData.map((data, itemIndex) => ({
                                                                            ...data,
                                                                            isSelected: index == itemIndex ? true : data.isSelected
                                                                        }));
                                                                        let _selectedItem = {...selectedItem};
                                                                        _selectedItem.item.itemSerialNoAllData = serialData;
                                                                        setSelectedItem(_selectedItem);
                                                                    }}
                                                                    className="btn btn-outline-danger"
                                                                >
                                                                    Undo
                                                                </button>
                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    data-dismiss="modal">Close
                            </button>
                            {
                                !isConvert &&
                                <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={() => saveChangeSerialQty()}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*Select view serial dialog end*/}
            {/*Select view batch dialog end*/}
            <div className="modal fade" id="viewBatchModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{maxWidth: '35%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Batch Details</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            {
                                selectedItem.item.item_name &&
                                <div className="list-group">
                                    <h3 className="list-group-item list-group-item-action active">{selectedItem.item.item_name}</h3>
                                    {
                                        selectedItem.item.itemBatchNoAllData.map((data, index) => {
                                            return(
                                                <div key={index} className={"list-group-item"} style={{border: '2px solid #e4e7ed'}}>
                                                    {businessSetting.BatchNoChk == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.BatchNoTxt} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.batch_no}</span>
                                                        </div>
                                                    </div>}
                                                    {businessSetting.ModelNoChk == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.ModelNoTxt} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.model_no}</span>
                                                        </div>
                                                    </div>}
                                                    {businessSetting.SizeChk == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.SizeTxt} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.size}</span>
                                                        </div>
                                                    </div>}
                                                    {businessSetting.MfgDateChk == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.MfgDateTxt} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.mfg_date}</span>
                                                        </div>
                                                    </div>}
                                                    {businessSetting.ExpDateChk == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.ExpDateTxt} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.exp_date}</span>
                                                        </div>
                                                    </div>}
                                                    {businessSetting.BatchNoAdditionalChk1 == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.BatchNoAdditionalLabel1 ? businessSetting.BatchNoAdditionalLabel1 : 'N/A'} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.batchNoAdditionalValue1}</span>
                                                        </div>
                                                    </div>}
                                                    {businessSetting.BatchNoAdditionalChk2 == 1 &&
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>{businessSetting.BatchNoAdditionalLabel2 ? businessSetting.BatchNoAdditionalLabel2 : 'N/A'} : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.batchNoAdditionalValue2}</span>
                                                        </div>
                                                    </div>}
                                                    <div className="form-group row">
                                                        <div className="col-6">
                                                            <span>Quantity : </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{data.used_stock}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    data-dismiss="modal">Close
                            </button>
                            {/*<button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={() => saveChangeBatchQty()}>Save</button>*/}
                        </div>
                    </div>
                </div>
            </div>
            {/*Select view batch dialog end*/}
        </div>
    );
}
export default QuickTransactionSaleInvoiceSection
