import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class QuickAddNewSalePurchaseSerialNo extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                    }
                }
            } 
        }

        this.state = {
            itemId : this.props.itemId ? this.props.itemId : '0',
            normalRemainingQuantity : this.props.normalRemainingQuantity ? this.props.normalRemainingQuantity : '0',
            normalQuantityTYpe : this.props.normalQuantityTYpe ? this.props.normalQuantityTYpe : '',
            openingStock : 0,  
            isLoaded : true, 
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            transactionId : this.props.transactionId ?  this.props.transactionId : 0,
            indexPosition : this.props.indexPosition ?  this.props.indexPosition : 0,
            addedQty : this.props.addedQty ?  this.props.addedQty : 0,
            normalQtyField : this.props.normalQtyField ? this.props.normalQtyField : 0,
            
            isError : false,
            without_sr_quantity : 0,
            errors: {
                error  :  '',
                without_sr_quantity : '',
            }
        }
    }
    async componentDidMount ()
    {  
        if(this.state.addedQty > 0){
            let  normalRemainingQuantity =  parseFloat(this.state.normalRemainingQuantity) - parseFloat(this.state.addedQty);
            console.log(parseFloat(this.state.normalRemainingQuantity)+'==>Data>=='+ parseFloat(this.state.addedQty));
            if(normalRemainingQuantity <= 0){
                normalRemainingQuantity = 0;
                this.setState({normalRemainingQuantity : normalRemainingQuantity, normalQuantityTYpe : 'Purchase'});
            }
            else{
                this.setState({normalRemainingQuantity : normalRemainingQuantity});
            }
        }
    }    
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        let _isError = false;
        switch (name) {            
            case 'without_sr_quantity':
                if(this.state.normalRemainingQuantity < value && this.state.normalQuantityTYpe == 'Sale'){
                    if(this.state.normalQtyField == 0){
                        _isError = true;
                        errors.error = '*Entered quantity should not be greater than available quantity';
                    }
                    else{
                        _isError = false;
                        errors.error = '';
                    }
                }else{
                    if(validator.isInt(value)){
                        _isError = false;
                        errors.error = '';
                    } else {
                        _isError = true;
                        errors.error = '*quantity should be numeric!';
                    }
                    //_isError = false;
                    //errors.error = '';
                }
                this.setState({without_sr_quantity:value,error:errors,isError:_isError}); 
            break;
        }        
    }    

    clickToAddData = (event) => {   
        event.preventDefault();
        let errors = this.state.errors;
        let _isError =  false;

        if(this.state.without_sr_quantity == 0 || this.state.without_sr_quantity == '0'){
            _isError  = true;
            errors.error = 'Quantity cannot be 0 or blank';
            this.setState({errors :errors,isError:_isError});
            return false;
        }
        if(this.state.isError ==  false){
            let _totalQuantity = parseFloat(this.state.without_sr_quantity) +  parseFloat(this.state.totalQuantity);
            let itemPrice = parseFloat(this.state.itemPrice) * parseFloat(this.state.without_sr_quantity); 
            let _totalAmountOld =  parseFloat(this.state.itemPrice) * parseFloat(this.state.without_sr_quantity); 
            _totalAmountOld = parseFloat(this.state.totalAmountOld) + parseFloat(_totalAmountOld);
            
            
            //this.props.callBack (_itemSerialData, _opening_stock);
            
            this.props.callBack (this.state.without_sr_quantity,itemPrice,_totalAmountOld,_totalQuantity,this.state.indexPosition);
        }
              
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        let  isDisabled = '';//Object.keys(errors).some(x => errors[x]);
        if(this.state.isError == true){
            isDisabled = 'disabled';
        }
        return(
            <React.Fragment>           
                <form method="post" id="form-login" className="webForm px-30">                  
                    <div className="form-group row"> 
                        {(this.state.normalQuantityTYpe == 'Sale' ) && 
                            <h4 className="badge badge-light k-mt-md ">Available Quantity Is : {this.state.normalRemainingQuantity}</h4>
                        }
                    </div> 
                                            
                    <div className="form-group row">                       
                        {(this.state.normalRemainingQuantity >= 0 && this.state.normalQuantityTYpe == 'Sale' ) && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || ""} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity </label>
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }
                          
                        {this.state.normalQuantityTYpe == 'Purchase' && 
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="without_sr_quantity" value={this.state.without_sr_quantity || ""} onChange={this.handleChange}  placeholder="Enter Quantity" />
                                <label htmlFor="login-username">Enter Quantity </label>
                                <span className="iris_error_txt">{errors.error?.length > 0 && <span className='error'>{errors.error}</span>}</span>
                            </div> 
                        </div>  
                        }                        
                    </div>                    
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}