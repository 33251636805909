import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = 'IN';
let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
    country_code = _companySession.country_code;
    tax_name = _companySession.tax_name;
    gstTaxDetails = _companySession.taxDetails;
}

let _loader = 'glb-ldr-prt active';

export default class quickUniConversionListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-service/',
            buttonTitle:'Add New',
            _unitId : (this.props.getUnitId) ? this.props.getUnitId  :'0' ,
            isLoaded : true, 
            unitConversionArray : [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            itemSelect :  '',
            errors: {
                selectItems : '',
            }
        }
    }
    async componentDidMount ()
    { 
        this.getUnitConversionData();
    }
    getUnitConversionData (){
        const requestData = JSON.stringify({
            unitId:this.props.getUnitId,
            businessId:_businessId,
            company_id:this.state.company_id
        });
        PostData(global.userUnitConversionList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {                
                let _unitConversionArray = responseJson.response;
                this.setState({unitConversionArray:_unitConversionArray,isLoaded:true});
            }
        }).catch(error => this.setState({ error, isLoaded: false }));
        
    }
    
    
    /* End Item Filter Code */       

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">   

                    <div className="claerfix"></div>
                    <div className="form-group row">
                        <ul className="text-center">
                    {
                        this.state.unitConversionArray.map((unitData,index) => {                            
                            return(  
                                <li>                        
                                    <strong>1 {unitData.fromUnitName} = {unitData.conversion} {unitData.toUnitName}</strong><br/>
                                </li> 
                                )
                        })
                    }     
                              </ul>                      
                    </div>
                    {this.state.unitConversionArray == '' &&
                    <p>No unit conversion found</p>
                    }
                    
                </form>
            </React.Fragment>
        )    
    }
}