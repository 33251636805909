import React, { Component } from "react";
import { PostData } from "../../service/postData";
import { numberFormat } from "../../config/numberFormat";
import Modal from "react-modal";
import moment from "moment";
import jsPDF from "jspdf";
import html2PDF from "jspdf-html2canvas";
import { ToWords } from "to-words";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

let _UserSession = localStorage.getItem("vs_UserSession");

let _companyName = "NA";
let _companyLogo = global.LIVE_URL + "websiteFilesFolder/img/vyavsay.png";
let _companySignature = "NA";
let _companySignatureLen = 0;
let _companyAddress = "NA";
let _display_bank_name = "";
let _display_bank_accountNo = "";
let _display_bank_holderName = "";
let _companyPhone = "NA";
let _companyEmail = "NA";
let _countryGst = "NA";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _signingId = "0";
let _tax_name = "";
let invTime = "";
let _pdfDate = "";
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _company_id = _UserSession.company_id;
  _businessSetting = _UserSession.businessSetting;
}
console.log("==_businessSetting==",JSON.stringify(_businessSetting));
let _companySession = localStorage.getItem("vs_UserCompanySession");

if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  //console.log(_companySession);
  _companyName = _companySession.company_name;

  if (_companySession.company_logo.length > 6) {
    let _company_Logo = _companySession.company_logo;
    _companyLogo = _company_Logo;
  } else {
    _companyLogo = global.LIVE_URL + "websiteFilesFolder/img/vyavsay.png";
  }
  _companySignature = _companySession.company_signature;
  _companyAddress = _companySession.company_address;
  _companyPhone = _companySession.company_contact;
  _companyEmail = _companySession.email ? _companySession.email : "";
  _company_id = _companySession.company_id;
  _countryCode = _companySession.country_code;
  _countryName = _companySession.country_name;

  _countryGst = _companySession.gstin ? _companySession.gstin : "";
  _tax_name = _companySession.tax_name;

  let bankArrayData = _companySession.bankDetails
    ? _companySession.bankDetails
    : [];
  console.log('bankArrayData', JSON.stringify(bankArrayData))
  bankArrayData.map((bank, index) => {
    if (
      bank.bank_print == 1 ||
      bank.bank_print == "true" ||
      bank.bank_print == true
    ) {
      _display_bank_name = bank.bank_name2;
      _display_bank_accountNo = bank.bank_accountNumber;
      _display_bank_holderName = bank.bank_accountHolderName;
    }
    if (
      bank.bank_name2 == _display_bank_name &&
      bank.bank_print!=1 &&
      bank.bank_print!="true" &&
      bank.bank_print!=true
    ) {
      _display_bank_name = "";
    }
  });
}
console.log("==dd==", JSON.stringify(_companySession));
let _checkOriginal = "";
let _checkDuplicate = "";
let _checkTriplicate = "";
let _checkOriginalDisable = "";
let _checkDuplicateDisable = "";
let _checkTriplicateDisable = "";

let _imageBaseDecode = _companyLogo;
let _signatureBaseDecode = _companySignature;
let _mainLogoBaseDecode = global.webUrl + "websiteFilesFolder/img/logo.png";
let _vLogoBaseDecode = "";
let _extraReceiveDiv = (
  <p>
    <b>Received By</b>
    <br />
    Name:
    <br />
    Comment:
    <br />
    Date:
    <br />
    Signature:
  </p>
);
let _extraDeleiveryDiv = (
  <p>
    <b>Delivered By</b>
    <br />
    Name:
    <br />
    Comment:
    <br />
    Date:
    <br />
    Signature:
  </p>
);
let _invoiceName = "invoiceRecipt";

const footer = {
  //position: 'absolute',
  //top: '90%',
  //width: '93%',
  display: "none",
};

let _loader = "glb-ldr-prt active";
export default class userPDFPanel extends Component {
  constructor(props) {
    super(props);
    if (
      _companyPhone == null ||
      _companyPhone == "" ||
      _companyPhone == "0-null"
    ) {
      _companyPhone = "";
    }
    this.state = {
      invoiceRtnHide: "hide",
      modalIsOpen_PDF: true,
      isLoaded: false,
      transactionId: this.props.transactionId,
      companyName: _companyName,
      companyAddress: _companyAddress,
      companyPhone: _companyPhone,
      companyEmail: _companyEmail,
      display_bank_name: _display_bank_name,
      display_bank_accountNo: _display_bank_accountNo,
      display_bank_holderName: _display_bank_holderName,
      companyGstin: _countryGst,
      logoMForExcel: global.webUrl + "websiteFilesFolder/img/logo.png",
      stateNamePrint: "",
      customerId: "#001",
      customerCompanyName: "Pahal Collection",
      customerAddress: "#861/9, Nanda Nagar, Indore (M.P.) - 452010",
      customerPhone: "0123456789",
      invoiceNo: "1122334455",

      invoiceRtnNo: "1122334455",
      invoiceDate: "1 June, 2021",
      date: "13 Apr 2021",
      logo: "NA",
      paymentType: "Cash",

      titleTxt: "TAX INVOICE",
      billTxt: "Bill To",

      _invNo: "Invoice No.",
      gstArray: [],
      settingTypeDisplay: "",
      description: "",
      totalAddinalCESSAmt: 0,
      totalAmount: 0,
      receivedAmount: 0,
      subtotal: 0,
      gstRatio: 0,
      gstAmount: 0,
      totalQty: 0,
      totalDiscount: 0,
      dueAmount: 0,
      imagePreviewUrl: "",
      poDate: "",
      dueTerms: 0,
      dueOnDate: "",
      poNumber: "",
      EWayBillNo: "",
      E_Way_Bill_No: "",
      _deliveryChallanPrint: 0,
      _invoiceBillNo: 0,
      FreeItemQuantity: 0,
      Count: 0,
      CountItem: 0,
      DisplayPurchasePrice: 0,
      PhoneNumber: 0,
      returnUrl: "",
      printTerms: 0,
      printTermsMsg: "",
      partyBalance: "",
      checkOriginal: "",
      checkOriginalDisable: "",
      checkDuplicate: "",
      checkDuplicateDisable: "",
      checkTriplicate: "",
      checkTriplicateDisable: "",
      transactionWiseTaxName: "",
      transactionWiseTaxId: 0,
      totalTransTaxAmount: 0,
      totalTransDiscountPer: 0,
      totalTransDiscountAmount: 0,
      transactionWiseTaxPercentage: 0,
      PaymentDiscountPayInAmount: 0,
      unUsedAmount: 0,
      prefixName: "",
      prefixId: 0,
      totalAmtInChar: 0,
      dueDate: "",
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      additional_value_date: "",
      firm_additional_field1: "",
      firm_additional_value1: "",
      firm_additional_field2: "",
      firm_additional_value2: "",
      isToggle: false,
      trans_duplicate_normal: "",
      _type :  '',
      totalLinkHistoryAmt : 0,
      items: [
        {
          id: 1,
          item_name: "Sample Item 1",
          quantity: 1,
          free_quantity: 0,
          trans_count: 0,
          qtyType: "BAG",
          rate: 50,
          subTotalAmount: 50 * 10,
          totalAmount: (50 * 10 * 18) / 100 + 50 * 10,
          itemBatchNoAllData: {},
          itemSerialNoAllData: {},
          logoMForExcel: global.webUrl + "websiteFilesFolder/img/logo.png",
        },
      ],
    };
  }

  async componentDidMount() {
    if (_companyLogo.length < 15) {
      _companyLogo = global.webUrl + "websiteFilesFolder/img/logo.png";
    }
    await this.toDataURL(_companyLogo, function (dataUrl) {
      _imageBaseDecode = dataUrl;
    });
    if (_companySignature!="undefined" && _companySignature!=undefined) {
      if (_companySignature.length > 15) {
        _companySignatureLen = _companySignature.length;
        await this.toDataURL(_companySignature, function (dataUrl) {
          _signatureBaseDecode = dataUrl;
        });
      }
    }

    if (_mainLogoBaseDecode.length > 15) {
      await this.toDataURL(_mainLogoBaseDecode, function (dataUrl) {
        _vLogoBaseDecode = dataUrl;
      });
    }

    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");

    let transactionId = _handleParam[1] ? _handleParam[1] : 0;
    if (transactionId > 0) {
      this.getTransactionDetails(transactionId);
      this.openModalHandler("modalAdjustPDFDownload");
      this.setState({ transactionId: transactionId,_companySignatureLen:_companySignatureLen });
    }
  }

  convertImageUrlToBase64(url, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
      var originalWidth = img.style.width;
      var originalHeight = img.style.height;

      img.style.width = "auto";
      img.style.height = "auto";
      img.crossOrigin = "Anonymous";

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      img.style.width = originalWidth;
      img.style.height = originalHeight;

      // Get the data-URL formatted image
      // Firefox supports PNG and JPEG. You could check img.src to
      // guess the original format, but be aware the using "image/jpg"
      // will re-encode the image.
      var dataUrl = canvas.toDataURL(outputFormat);
      //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      _imageBaseDecode = dataUrl;
    };
    img.src = url;
  }

  convertMainImageUrlToBase64(url, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
      var originalWidth = img.style.width;
      var originalHeight = img.style.height;

      img.style.width = "auto";
      img.style.height = "auto";
      img.crossOrigin = "Anonymous";

      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      img.style.width = originalWidth;
      img.style.height = originalHeight;

      // Get the data-URL formatted image
      // Firefox supports PNG and JPEG. You could check img.src to
      // guess the original format, but be aware the using "image/jpg"
      // will re-encode the image.
      var dataUrl = canvas.toDataURL(outputFormat);
      //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      _vLogoBaseDecode = dataUrl;
    };
    img.src = url;
  }

  toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);

      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete == undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }

  async getTransactionDetails(transactionId) {
    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({});
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              console.log('_transactionArray', JSON.stringify(_transactionArray))
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;
              let customerData = _transactionArray.customer;
              let prefixData = _transactionArray.prefix;
              let _gstArray = [];
              let _itemsArray = [];
              let _CountItem = 0;

              let _prefixName = "";
              let _prefixId = 0;
              if (prefixData.id > 0) {
                _prefixName = prefixData.name;
                _prefixId = prefixData.id;
              }
              if (_businessSetting.PrintOriginalDuplicate == 1) {
                if (
                  _transactionArray.settingType == "showall" ||
                  _transactionArray.settingType == "null" ||
                  _transactionArray.settingType == null ||
                  _transactionArray.settingType == ""
                ) {
                  _checkDuplicate = "checked";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "checked";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "checked";
                  _checkTriplicateDisable = "disbaled";
                } else if (_transactionArray.settingType == "Original") {
                  _checkDuplicate = "";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "checked";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "";
                  _checkTriplicateDisable = "disbaled";
                } else if (_transactionArray.settingType == "Duplicate") {
                  _checkDuplicate = "checked";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "";
                  _checkTriplicateDisable = "disbaled";
                } else if (_transactionArray.settingType == "Triplicate") {
                  _checkDuplicate = "";
                  _checkDuplicateDisable = "disbaled";
                  _checkOriginal = "";
                  _checkOriginalDisable = "disbaled";
                  _checkTriplicate = "checked";
                  _checkTriplicateDisable = "disbaled";
                }
              }
              itemsData.map((items, index) => {
                let _obj = {};
                _obj.item_name = items.item_name;
                _obj.hsn_sac = items.hsn_sac;
                _obj.item_code = items.item_code;
                _obj.quantity = items.quantity;
                _obj.free_quantity = items.free_quantity;
                _obj.trans_count = items.trans_count;
                _obj.rate = items.rate;
                _obj.qtyType = "";
                _obj.discountAmount = 0;
                _obj.discountPer = 0;
                _obj.itemBatchNoAllData = items.itemBatchNoAllData;
                _obj.itemSerialNoAllData = items.itemSerialNoAllData;
                if (items.discount_in_amount > 0) {
                  _obj.discountAmount = items.discount_in_amount;
                }
                if (items.discount_in_per > 0) {
                  _obj.discountPer = (parseFloat(items.discount_in_per)).toFixed(2);
                }
                _obj.gstAmount = 0;
                _obj.gstPerRatio = 0;
                if (Object.keys(items.tax_type).length > 0) {
                  _obj.gstAmount = items.tax_value;
                  _obj.gstPerRatio = items.tax_type.ratio;
                }
                _obj.subTotalAmount = items.subtotal;
                _obj.totalAmount = items.subtotal;
                _obj.total_amount = items.total_amount

                _obj.unitName = items.unit.unit_name
                  ? items.unit.unit_name
                  : "";
                _obj.Additional_CESS = items.Additional_CESS;
                _itemsArray.push(_obj);

                items.tax_type.price = items.tax_value;
                _gstArray.push(items.tax_type);
                if (items.trans_count > 0) {
                  _CountItem = 1;
                }
              });
              let _titleTrans = "TAX INVOICE";
              let _billTrans = "TAX INVOICE";
              let _invoiceRtnHide = "hide";
              let _invNumber = "Invoice No.";
              let _expensePartyName = "";
              let _returnUrl = global.webUrl + "business/sale-invoice/";
              let _printTerms = 0;
              let _printTermsMsg = "";
              let _dueDate = "";
              let _dueDateText = "";

              if (_transactionArray.type == "SALE") {
                _printTermsMsg = _businessSetting.TermCondSaleInvMsg;
                _printTerms = _businessSetting.TermCondSaleInvMsgPrint;
                _titleTrans = "TAX INVOICE";
                if (_businessSetting.SaleTransName.length > 1) {
                  _titleTrans = _businessSetting.SaleTransName;
                }
              }
              if (_transactionArray.type == "SALE ORDER") {
                _invNumber = "Order No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Sale Order";
                _billTrans = "Order For";
                _returnUrl = global.webUrl + "business/manage-sale-order/";
                _printTermsMsg = _businessSetting.TermCondSaleOrderMsg;
                _printTerms = _businessSetting.TermCondSaleOrderMsgPrint;
                if (_businessSetting.SaleOrderTransName.length > 1) {
                  _titleTrans = _businessSetting.SaleOrderTransName;
                }
              } else if (_transactionArray.type == "DELIVERY CHALLAN") {
                _invNumber = "Challan No.";
                _titleTrans = "Delivery Challan";
                _billTrans = "Delivery Challan For";
                _returnUrl =
                  global.webUrl + "business/manage-sale-delivery-challan/";
                _printTermsMsg = _businessSetting.TermCondDelvChallanMsg;
                _printTerms = _businessSetting.TermCondDelvChallanMsgPrint;
                if (_businessSetting.DeliveryChallanTransName.length > 1) {
                  _titleTrans = _businessSetting.DeliveryChallanTransName;
                }
              } else if (_transactionArray.type == "ESTIMATE") {
                _invNumber = "Estimate No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Estimate Quotation";
                _billTrans = "Estimate For";
                _returnUrl = global.webUrl + "business/manage-sale-estimate/";
                _printTermsMsg = _businessSetting.TermCondEstimateMsg;
                _printTerms = _businessSetting.TermCondEstimateMsgPrint;
                if (_businessSetting.EstimateTransName.length > 1) {
                  _titleTrans = _businessSetting.EstimateTransName;
                }
              } else if (_transactionArray.type == "CREDIT NOTE") {
                _invNumber = "Invoice No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _invoiceRtnHide = "";
                _titleTrans = "Credit Note";
                _billTrans = "Return For";
                _returnUrl = global.webUrl + "business/manage-sale-return/";
                if (_businessSetting.CreditNoteTransName.length > 1) {
                  _titleTrans = _businessSetting.CreditNoteTransName;
                }
              } else if (_transactionArray.type == "PAYMENT-IN") {
                _invNumber = "Receipt No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Payment Receipt";
                _billTrans = "Received For";
                _returnUrl = global.webUrl + "business/manage-sale-payment-in/";
                if (_businessSetting.PaymentInTransName.length > 1) {
                  _titleTrans = _businessSetting.PaymentInTransName;
                }
              } else if (_transactionArray.type == "PURCHASE") {
                _invNumber = "Bill No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Bill";
                _billTrans = "Bill From";
                _returnUrl = global.webUrl + "business/purchase-invoice/";
                _printTermsMsg = _businessSetting.TermCondPruchaseMsg;
                _printTerms = _businessSetting.TermCondPruchaseMsgPrint;
                if (_businessSetting.PurchaseTransName.length > 1) {
                  _titleTrans = _businessSetting.PurchaseTransName;
                }
              } else if (_transactionArray.type == "PAYMENT-OUT") {
                _invNumber = "Receipt No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Payment Out";
                _billTrans = "Paid To";
                _returnUrl =
                  global.webUrl + "business/manage-purchase-payment-out/";
                if (_businessSetting.PaymentOutTransName.length > 1) {
                  _titleTrans = _businessSetting.PaymentOutTransName;
                }
              } else if (_transactionArray.type == "PURCHASE ORDER") {
                _invNumber = "Order No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Purchase Order";
                _billTrans = "Order To";
                _returnUrl = global.webUrl + "business/manage-purchase-order/";
                _printTermsMsg = _businessSetting.TermCondPruchaseOrderMsg;
                _printTerms = _businessSetting.TermCondPruchaseOrderMsgPrint;
                if (_businessSetting.PurchaseOrderTransName.length > 1) {
                  _titleTrans = _businessSetting.PurchaseOrderTransName;
                }
                _dueDateText = "Due Date";
                _dueDate = _transactionArray.dueDate;
              } else if (_transactionArray.type == "DEBIT NOTE") {
                _invNumber = "Invoice No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _invoiceRtnHide = "";
                _titleTrans = "Debit Note";
                _billTrans = "Return To";
                _returnUrl = global.webUrl + "business/manage-purchase-return/";
                if (_businessSetting.DebitNoteTransName.length > 1) {
                  _titleTrans = _businessSetting.DebitNoteTransName;
                }
              } else if (_transactionArray.type == "EXPENSE") {
                _invNumber = "Expense No.";
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _titleTrans = "Expense";
                _billTrans = "Expense For";
                _expensePartyName = customerData.party_name;
                if (customerData.party_name == "NA") {
                  _expensePartyName = "";
                }
                customerData.party_name = customerData.expense_category;
                _returnUrl = global.webUrl + "business/manage-expense/";
                if (_businessSetting.ExpensesTransName.length > 1) {
                  _titleTrans = _businessSetting.ExpensesTransName;
                }
              } else {
                _extraReceiveDiv = "";
                _extraDeleiveryDiv = "";
                _returnUrl = global.webUrl + "business/sale-invoice/";
              }
              let _partyBalance = 0;
              if (
                customerData.partyBalance!=null ||
                customerData.partyBalance!=""
              ) {
                _partyBalance = customerData.partyBalance;
              }

              let _contactNo = "";
              if (customerData.party_contact_no!=null) {
                let _party_contact_no = customerData.party_contact_no
                  ? customerData.party_contact_no
                  : "NA";
                if (
                  customerData.party_contact_no == null ||
                  customerData.party_contact_no == "null" ||
                  customerData.party_contact_no == 0 ||
                  customerData.party_contact_no == "0"
                ) {
                  _party_contact_no = "NA";
                }
                _contactNo = "Contact No. : " + _party_contact_no;
              }

              let _pdfInvoiceTime = _transactionArray.invoiceDate;
              if (
                _businessSetting.Tran_AddTimePrint == 1 ||
                _businessSetting.Tran_AddTimePrint == "1"
              ) {
                _pdfInvoiceTime =
                  _transactionArray.invoiceDate +
                  " " +
                  _transactionArray.invoice_time;

                invTime = _transactionArray.invoice_time;
              }
              _pdfDate = _transactionArray.invoice_date_return;
              let _finalBillName = customerData.party_name;
              if (
                customerData.billing_name &&
                _businessSetting.Tran_BillingNameParty == 1
              ) {
                _finalBillName = customerData.billing_name;
              }
              let _paymentType = _transactionArray.paymentType;
              if (_transactionArray.paymentType == "Bank") {
                _paymentType =
                  _transactionArray.paymentType +
                  " - " +
                  _transactionArray.bankName +
                  " (Ref No. - " +
                  _transactionArray.paymentRefNo +
                  ")";
                  if(_transactionArray.paymentRefNo == ''){
                    _paymentType = _transactionArray.paymentType + " (Ref No. - NA)";
                  }
              } else if (_transactionArray.paymentType == "Cheque") {
                _paymentType =
                  _transactionArray.paymentType +
                  " (Ref No. - " +
                  _transactionArray.paymentRefNo +
                  ")";
                  if(_transactionArray.paymentRefNo == ''){
                    _paymentType = _transactionArray.paymentType + " (Ref No. - NA)";
                  }
              }
              let _isToggle = _transactionArray.isToggle;
              if (
                _isToggle == true ||
                _isToggle == "true" ||
                _isToggle == 1 ||
                _isToggle == "1"
              ) {
                _isToggle = true;
              }
              let _billingAddressAK = _transactionArray.billingAddress ? _transactionArray.billingAddress : '';
              let _billingAddress = customerData.party_billing_address;
              if((_transactionArray.billingAddress).length > 0){
                _billingAddress = _billingAddressAK;
              }
              this.setState({
                isLoaded: true,
                logoMForExcel:
                  global.webUrl + "websiteFilesFolder/img/logo.png",
                settingTypeDisplay: _transactionArray.settingType,
                invoiceRtnHide: _invoiceRtnHide,
                customerId: customerData.party_id,
                customerCompanyName: _finalBillName, //customerData.party_name,
                customerAddress: _billingAddress,//customerData.party_billing_address,
                customerPhone: _contactNo,
                partyBalance: numberFormat(_partyBalance),
                prefixName: _prefixName,
                prefixId: _prefixId,
                invoiceNo: _transactionArray.invoiceNo,
                invoiceRtnNo: _transactionArray.invoice_no_return,
                invoiceDate: _pdfInvoiceTime,
                dueDate: _transactionArray.dueDate,
                date: _transactionArray.invoiceDate,
                description: _transactionArray.description,
                totalAmtInChar: transAmout.totalAmount,
                reverseChargeStatus: transAmout.reverseChargeStatus,
                reverseChargeTax: transAmout.reverseChargeTax,
                payableAmount: transAmout.payableAmount,
                totalAmount: numberFormat(transAmout.totalAmount),
                receivedAmount: numberFormat(transAmout.receivedAmount),
                paidAmount: numberFormat(transAmout.paidAmount),
                subtotal: numberFormat(transAmout.subtotal),
                gstRatio: numberFormat(transAmout.gstRatio),
                gstAmount: numberFormat(transAmout.gstAmount),
                totalQty: transAmout.totalQty,
                totalDiscount: numberFormat(transAmout.totalDiscount),
                dueAmount: numberFormat(transAmout.dueAmount),
                PaymentDiscountPayInAmount: numberFormat(
                  transAmout.PaymentDiscountPayInAmount
                ),
                unUsedAmount: numberFormat(transAmout.unUsedAmount),
                totalLinkHistoryAmt : (_transactionArray.totalLinkHistoryAmt),
                paymentType: _paymentType,
                items: _itemsArray,
                CountItem: _CountItem,
                gstArray: _gstArray,
                totalAddinalCESSAmt: numberFormat(
                  _transactionArray.totalAddinalCESSAmt
                ),
                _invNo: _invNumber,
                trans_duplicate_normal : _transactionArray.trans_duplicate_normal,
                _type: _transactionArray.type,
                poDate: _transactionArray.po_date,
                dueTerms: _transactionArray.dueTerms,
                dueOnDate: _transactionArray.dueOnDate,
                poNumber: _transactionArray.po_number,
                EWayBillNo: _transactionArray.EWayBillNo,
                E_Way_Bill_No: _transactionArray.E_Way_Bill_No,
                stateNamePrint: _transactionArray.countryState,
                titleTxt: _titleTrans,
                billTxt: _billTrans,
                expensePartyName: _expensePartyName,
                _deliveryChallanPrint: _transactionArray.DeliveryChallanPrint,
                _invoiceBillNo: _transactionArray.InvoiceBillNo,
                party_add_field_key1: _transactionArray.party_add_field_key1,
                party_add_field_key2: _transactionArray.party_add_field_key2,
                party_add_field_key3: _transactionArray.party_add_field_key3,
                party_add_field_value1:
                  _transactionArray.party_add_field_value1,
                party_add_field_value2:
                  _transactionArray.party_add_field_value2,
                party_add_field_value3:
                  _transactionArray.party_add_field_value3,
                PartyAddField1ShowPrint:
                  _businessSetting.PartyAddField1ShowPrint,
                PartyAddField2ShowPrint:
                  _businessSetting.PartyAddField2ShowPrint,
                PartyAddField3ShowPrint:
                  _businessSetting.PartyAddField3ShowPrint,
                PrintCurrentBalanceParty:
                  _transactionArray.PrintCurrentBalanceParty,
                BalanceAmount: _transactionArray.BalanceAmount,
                ReceivedAmount: _transactionArray.ReceivedAmount,
                GSTINNumber: _transactionArray.GSTINNumber,
                FreeItemQuantity: _transactionArray.FreeItemQuantity,
                Count: _transactionArray.Count,
                DisplayPurchasePrice: _transactionArray.DisplayPurchasePrice,
                //ReceivedAmount : _transactionArray.ReceivedAmount,
                PhoneNumber: _transactionArray.PhoneNumber,
                returnUrl: _returnUrl,
                printTermsMsg: _printTermsMsg,
                printTerms: _printTerms,
                checkOriginal: _checkOriginal,
                checkOriginalDisable: _checkOriginalDisable,
                checkDuplicate: _checkDuplicate,
                checkDuplicateDisable: _checkDuplicateDisable,
                checkTriplicate: _checkTriplicate,
                checkTriplicateDisable: _checkTriplicateDisable,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,

                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                transactionWiseTaxName:
                  _transactionArray.transactionWiseTaxName,
                transactionWiseTaxPercentage:
                  _transactionArray.transactionWiseTaxPercentage,
                TransportDetailField1: _transactionArray.TransportDetailField1,
                TransportDetailField2: _transactionArray.TransportDetailField2,
                TransportDetailField3: _transactionArray.TransportDetailField3,
                TransportDetailField4: _transactionArray.TransportDetailField4,
                TransportDetailField5: _transactionArray.TransportDetailField5,
                TransportDetailField6: _transactionArray.TransportDetailField6,
                TransAdditionalCharge1:
                  _transactionArray.TransAdditionalCharge1,
                TransAdditionalCharge2:
                  _transactionArray.TransAdditionalCharge2,
                TransAdditionalCharge3:
                  _transactionArray.TransAdditionalCharge3,
                additional_value_1: _transactionArray.additional_value_1,
                additional_value_2: _transactionArray.additional_value_2,
                additional_value_3: _transactionArray.additional_value_3,
                additional_value_date: _transactionArray.additional_value_date,
                firm_additional_field1:
                  _transactionArray.firm_additional_field1,
                firm_additional_value1:
                  _transactionArray.firm_additional_value1,
                firm_additional_field2:
                  _transactionArray.firm_additional_field2,
                firm_additional_value2:
                  _transactionArray.firm_additional_value2,
              });
              if (_businessSetting.AmountWithDecimal == 0) {
                this.setState({
                  totalAmount: parseFloat(transAmout.totalAmount),
                  receivedAmount: parseFloat(transAmout.receivedAmount),
                  paidAmount: parseFloat(transAmout.paidAmount),
                  subtotal: parseFloat(transAmout.subtotal),
                  gstRatio: parseFloat(transAmout.gstRatio),
                  gstAmount: parseFloat(transAmout.gstAmount),
                  totalQty: parseFloat(transAmout.totalQty),
                  totalDiscount: parseFloat(transAmout.totalDiscount),
                  dueAmount: parseFloat(transAmout.dueAmount),
                  partyBalance: parseFloat(_partyBalance),
                  totalAmtInChar: transAmout.totalAmount,
                });
                //console.log(this.state.gstAmount+'==rrr=='+parseInt(transAmout.gstAmount)+'==zzzz=='+(transAmout.gstAmount));
              }
            }
          }
        })
        .catch((error) => this.setState({ error }));
    }
  }

  async clickToGenratePDF(value) {
    let _currentDate = moment().format("MM-DD-YYYY-HH-mm-GG");
    const printArea = document.getElementById("pdfFormatReceipt");
    const pdf = await html2PDF(printArea, {
      html2canvas: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      },
      imageType: "image/png",
      output: _invoiceName + _currentDate + ".pdf",
    });
  }

  async clickToGenratePrint(e) {
    /*let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

        mywindow.document.write(`<html><head><title>Test NJ</title>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById("pdfFormatReceipt").innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10

        mywindow.print();
        mywindow.close();*/

    let printContents = document.getElementById("pdfFormatReceipt").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    //window.print();
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    this.getTransactionDetails();
    if (popId == "modalAdjustPDFDownload") {
      this.setState({ modalIsOpen_PDF: true });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustPDFDownload") {
      this.setState({ modalIsOpen_PDF: false });
    }
  };
  /*End Modal open close*/

  getGstAmtDetails() {
    if (this.state.gstArray.length > 0) {
      this.state.gstArray.map((value, index) => {
        let _i = parseInt(index) + 1;
        return (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {value.name + "(" + value.ratio + "%)"}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.AmountWithDecimal == 1 &&
                numberFormat(value.price)}
              {_businessSetting.AmountWithDecimal == 0 && parseFloat(value.price)}

              {/* {numberFormat(value.price)} */}
            </td>
          </tr>
        );
      });
    }
  }

  getTotalCalculation() {
    let totalAmtCode = (
      <tr style={{ background: "#9f9fdf" }}>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#ffffff",
            lineHeight: "24px",
          }}
        >
          Total
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#ffffff",
            lineHeight: "24px",
          }}
        >
          {this.state.totalAmount}
        </td>
      </tr>
    );
    
    if(this.state._type == "PAYMENT-IN" || this.state._type == "PAYMENT-OUT"){
      totalAmtCode = '';
    }
    let discountAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Discount
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.totalDiscount}
        </td>
      </tr>
    );

    let subTotalAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Subtotal
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.subtotal}
        </td>
      </tr>
    );

    let receiveAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Received Amount
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.receivedAmount}
        </td>
      </tr>
    );
    let balanceAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Balance Due
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.dueAmount}
        </td>
      </tr>
    );
    let paymentAmtCode = (
      <tr>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          Payment Mode
        </td>
        <td
          style={{
            textAlign: "left",
            fontFamily: "Arial",
            fontSize: "15px",
            color: "#333333",
            lineHeight: "24px",
          }}
        >
          {this.state.paymentType}
        </td>
      </tr>
    );

    let _challanCssTotal = "";
    let _invType = this.state._type;
    if (_invType == "SALE ORDER") {
      _invoiceName = "Sale-Order-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Advance Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.receivedAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
      paymentAmtCode = "";
    } else if (_invType == "DELIVERY CHALLAN") {
      _challanCssTotal = "marginTOpCss";
      _invoiceName = "Delivery-Challan-";
      receiveAmtCode = "";
      balanceAmtCode = "";
      paymentAmtCode = "";
    } else if (_invType == "ESTIMATE") {
      _invoiceName = "Estimate-Quotation-";
      receiveAmtCode = "";
      balanceAmtCode = "";
      paymentAmtCode = "";
    } else if (_invType == "CREDIT NOTE") {
      _invoiceName = "Sale-Credit-Note-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Paid Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.receivedAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
    } else if (_invType == "PAYMENT-IN" && this.state.totalLinkHistoryAmt <= 0) {
      totalAmtCode = (
        <tr style={{ background: "#9f9fdf" }}>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#ffffff",
              lineHeight: "24px",
            }}
          >
            Total
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#ffffff",
              lineHeight: "24px",
            }}
          >
            {this.state.totalAmount}
          </td>
        </tr>
      );
    } 
    else if (_invType == "PAYMENT-IN") {
      _invoiceName = "Payment-In-";
      subTotalAmtCode = "";
      discountAmtCode = "";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Received
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.receivedAmount}
          </td>
        </tr>
      );
      balanceAmtCode = "";
      paymentAmtCode = "";
    } 
    else if (_invType == "PAYMENT-OUT" &&  this.state.totalLinkHistoryAmt <= 0) {
      totalAmtCode = (
        <tr style={{ background: "#9f9fdf" }}>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#ffffff",
              lineHeight: "24px",
            }}
          >
            Total
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#ffffff",
              lineHeight: "24px",
            }}
          >
            {this.state.totalAmount}
          </td>
        </tr>
      );
    }
    
    else if (_invType == "PAYMENT-OUT") {
      _invoiceName = "Payment-Out-";
      subTotalAmtCode = "";
      discountAmtCode = "";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Paid Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.paidAmount}
          </td>
        </tr>
      );
      balanceAmtCode = "";
      paymentAmtCode = "";
    }
    
    else if (_invType == "DEBIT NOTE") {
      _invoiceName = "Purchase-Debit-Note-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Received Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.receivedAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
    } else if (_invType == "PURCHASE ORDER") {
      _invoiceName = "Purchase-Order-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Advance Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.receivedAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
      paymentAmtCode = "";
    } else if (_invType == "PURCHASE") {
      _invoiceName = "Purchase-Invoice-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Paid Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.paidAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
      //paymentAmtCode = '';
    } else if (_invType == "EXPENSE") {
      _invoiceName = "Expense-Invoice-";
      receiveAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Paid Amount
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.paidAmount}
          </td>
        </tr>
      );
      balanceAmtCode = (
        <tr>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            Balance Due
          </td>
          <td
            style={{
              textAlign: "left",
              fontFamily: "Arial",
              fontSize: "15px",
              color: "#333333",
              lineHeight: "24px",
            }}
          >
            {this.state.dueAmount}
          </td>
        </tr>
      );
    }

    if (this.state.totalQty == "0") {
      subTotalAmtCode = "";
      discountAmtCode = "";
      //paymentAmtCode = "";
    }
    let _transWiseTaxData = "";
    let _transWiseTaxDataTab = "";
    let _transWisePayableTab = "";
    let _transWiseTaxData1 = "";
    let _transWiseTaxData2 = "";
    let _showTransData = 0;
    if (_businessSetting.TransactionWiseTax == 1) {
      if (this.state.transactionWiseTaxName == "GST") {
        _showTransData = 1;
        if (this.state.transactionWiseTaxPercentage > 0) {
          let _finalTranWiseTaxPer =
            parseFloat(this.state.transactionWiseTaxPercentage) / 2;
          let _finalTranWiseTaxAmt =
            parseFloat(this.state.totalTransTaxAmount) / 2;

          _transWiseTaxData1 = (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                SGST@{_finalTranWiseTaxPer}%
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {numberFormat(_finalTranWiseTaxAmt)}
              </td>
            </tr>
          );

          _transWiseTaxData2 = (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                CGST@{_finalTranWiseTaxPer}%
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {numberFormat(_finalTranWiseTaxAmt)}
              </td>
            </tr>
          );

          //_transWiseTaxData = _transWiseTaxData1 + _transWiseTaxData2;
        } else {
          _transWiseTaxData = "";
        }
      } else {
        _showTransData = 2;
        if (this.state.transactionWiseTaxPercentage > 0) {
          let _finalTranWiseTaxPer = parseFloat(
            this.state.transactionWiseTaxPercentage
          );
          let _finalTranWiseTaxAmt = parseFloat(this.state.totalTransTaxAmount);

          _transWiseTaxData = (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {this.state.transactionWiseTaxName}@{_finalTranWiseTaxPer}%
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {numberFormat(_finalTranWiseTaxAmt)}
              </td>
            </tr>
          );
        } else {
          _transWiseTaxData = "";
        }
      }
    }
    if (_businessSetting.ReverseCharge == 1) {
      if (this.state.reverseChargeStatus == 1) {
        let _reverseChargeTax = parseFloat(this.state.reverseChargeTax);
        let _payableAmount = parseFloat(this.state.payableAmount);
        _transWiseTaxDataTab = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              Reverse Charge Tax
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(_reverseChargeTax)}
            </td>
          </tr>
        );
        _transWisePayableTab = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              Payable Amount
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(_payableAmount)}
            </td>
          </tr>
        );
      } else {
        _transWiseTaxDataTab = "";
        _transWisePayableTab = "";
      }
    }
    let _shippingCharge1 = "";
    let _shippingCharge2 = "";
    let _shippingCharge3 = "";
    if (_businessSetting.TransactionAdditionalChargeEnable == 1 && (this.state._type!="PAYMENT-IN")) {
      if (_businessSetting.TransactionAdditionalCharge1Enable == 1) {
        _shippingCharge1 = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.TransactionAdditionalCharge1Name}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(this.state.TransAdditionalCharge1)}
            </td>
          </tr>
        );
      }
      if (_businessSetting.TransactionAdditionalCharge2Enable == 1) {
        _shippingCharge2 = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.TransactionAdditionalCharge2Name}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(this.state.TransAdditionalCharge2)}
            </td>
          </tr>
        );
      }
      if (_businessSetting.TransactionAdditionalCharge3Enable == 1) {
        _shippingCharge3 = (
          <tr>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {_businessSetting.TransactionAdditionalCharge3Name}
            </td>
            <td
              style={{
                textAlign: "left",
                fontFamily: "Arial",
                fontSize: "15px",
                color: "#333333",
                lineHeight: "24px",
              }}
            >
              {numberFormat(this.state.TransAdditionalCharge3)}
            </td>
          </tr>
        );
      }
    }
    if (_businessSetting.TransactionAdditionalChargeEnable == 1 && (this.state._type == "PAYMENT-OUT")) {
      _shippingCharge1 = "";
      _shippingCharge2 = "";
      _shippingCharge3 = "";
    }
    return (
      <div>
        <table
          cellPadding="0"
          cellSpacing="0"
          width="100%"
          className={_challanCssTotal}
        >
          <tr>
            <td colSpan="2" height="10">
              &nbsp;
            </td>
          </tr>
          {subTotalAmtCode}
          {/* {discountAmtCode} */}
          {_businessSetting.TransactionWiseDiscount == 1 &&
            this.state._type!="PAYMENT-IN"  || this.state._type!="PAYMENT-OUT" && (
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  Discount ({this.state.totalTransDiscountPer} %)
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  {numberFormat(this.state.totalTransDiscountAmount)}
                </td>
              </tr>
            )}
          {this.state._type == "PAYMENT-IN" || this.state._type == "PAYMENT-OUT" && (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                Discount Amount
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {this.state.PaymentDiscountPayInAmount}
              </td>
            </tr>
          )}
          {(this.state._type == "PAYMENT-IN" || this.state._type == "PAYMENT-OUT") && (
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                Un Used Amount
              </td>
              <td
                style={{
                  textAlign: "left",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                }}
              >
                {this.state.unUsedAmount}
              </td>
            </tr>
          )}

          {_showTransData == 1 && _transWiseTaxData1}
          {_showTransData == 1 && _transWiseTaxData2}
          {_showTransData == 2 && _transWiseTaxData}
          {_showTransData == 0 && _transWiseTaxData}
          {_transWiseTaxDataTab}
          {_transWisePayableTab}
          {this.getGstAmtDetails()}

          {_shippingCharge1}
          {_shippingCharge2}
          {_shippingCharge3}
          {totalAmtCode}
          {_businessSetting.ReceivedAmount == 1 && receiveAmtCode}
          {/* { (this.state.PrintCurrentBalanceParty ==1 && this.state.BalanceAmount ==1 && this.state._type == 'DELIVERY CHALLAN') &&
                        balanceAmtCode
                    } */}
          {_businessSetting.BalanceAmount == 1 && balanceAmtCode}
          {_businessSetting.PaymentMode == 1 && paymentAmtCode}

          {_businessSetting.PrintCurrentBalanceParty == 1 &&
            this.state._type!="PAYMENT-IN" &&
            this.state._type!="EXPENSE" && (
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  Current Balance
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  {this.state.partyBalance}
                </td>
              </tr>
            )}
          {_businessSetting.PrintCurrentBalanceParty == 1 &&
            this.state._type == "EXPENSE" &&
            (this.state.isToggle == true ||
              this.state.isToggle == "true" ||
              this.state.isToggle == 0) && (
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  Current Balance
                </td>
                <td
                  style={{
                    textAlign: "left",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                  }}
                >
                  {this.state.partyBalance}
                </td>
              </tr>
            )}
          <tr>
            <td colSpan="2" height="50">
              &nbsp;
            </td>
          </tr>
          {/* {_businessSetting.PrintSignatureText == 1 && (
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "center",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  color: "#333333",
                  lineHeight: "24px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  maxWidth: "100px",
                  maxHeight: "100px",
                  borderRadius: "10px",
                }}
              >
                {_companySignatureLen > 15 && (
                  <img
                    src={_companySession.company_signature}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      borderRadius: "10px",
                      marginLeft: "20px",
                    }}
                  />
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Authorized Signature
              </td>
            </tr>
          )} */}
        </table>
      </div>
    );
  }

  printDemo() {
    let _hideDivv = "";
    let _extra = "";
    if (
      this.state._type == "PAYMENT-IN" ||
      this.state._type == "PAYMENT-OUT" ||
      this.state.totalQty == 0
    ) {
      _hideDivv = "hide";
      _extra = "display:none";
    }
    let _hideItemPrice = "";
    if (this.state._type == "DELIVERY CHALLAN") {
      if (_businessSetting.DeliveryChallanPrint == 0) {
        //_hideItemPrice = "hide";
      }
    }
    let _showTransportD = 0;
    if (
      (this.state.TransportDetailField1!=null &&
        this.state.TransportDetailField1!="") ||
      (this.state.TransportDetailField2!=null &&
        this.state.TransportDetailField2!="") ||
      (this.state.TransportDetailField3!=null &&
        this.state.TransportDetailField3!="") ||
      (this.state.TransportDetailField4!=null &&
        this.state.TransportDetailField4!="")
    ) {
      _showTransportD = 1;
    }
    let amt = this.state.totalAmtInChar;
    let _totalAmtInChar = "";
    if (amt > 0) {
      let returnData = new ToWords({
        converterOptions: { ignoreDecimal: false },
      }).convert(amt ? amt : 0);
      _totalAmtInChar = returnData + " Only";
    } else {
      _totalAmtInChar = "Zero";
    }
    let _companyAddressPrint = this.state.companyAddress
      ? this.state.companyAddress.length
      : 0;
    let _stateNamePrint = this.state.stateNamePrint
      ? this.state.stateNamePrint.length
      : 0;
    let _itemName = _businessSetting.item_name ? _businessSetting.item_name : "Item Name";
    let _si_no = "#";
    let _item_code = "Item code";
    let _hsn_sac = "HSN/SAC";
    let _quantity = "Qty";
    let _unit = "Unit";
    let _discount = "Discount";
    let _price_per_unit = "Rate";
    let _total_amount = "Amount";
    let _addditional_cess = _businessSetting.addditional_cess ? _businessSetting.addditional_cess : "Ad. CESS Tabish";

    if (_businessSetting.si_enable == 1) {
      _si_no = _businessSetting.si_no;
    }
    if (_businessSetting.item_code_enabled == 1) {
      _item_code = _businessSetting.item_code;
    }
    if (_businessSetting.hsn_sac_enable == 1) {
      _hsn_sac = _businessSetting.hsn_sac;
    }
    if (_businessSetting.quantity_enable == 1) {
      _quantity = _businessSetting.quantity;
    }
    if (_businessSetting.unit_enabled == 1) {
      _unit = _businessSetting.unit;
    }
    if (_businessSetting.price_per_unit_enabled == 1) {
      _price_per_unit = _businessSetting.price_per_unit;
    }
    if (_businessSetting.discount_enable == 1) {
      _discount = _businessSetting.discount;
    }
    if (_businessSetting.total_amount_enable == 1) {
      _total_amount = _businessSetting.total_amount;
    }
    let _finalTranWiseTaxPer = parseFloat(this.state.transactionWiseTaxPercentage);
    let _finalTranWiseTaxAmt = parseFloat(this.state.totalTransTaxAmount);
  
    // for Excel export xls
    if (this.state.isLoaded == true) {
      return (
        
        <table
          cellPadding=""
          cellSpacing=""
          border={0}
          width="100%"
          id={"exportxls"}
          style={{ display: "none" }}
        >
          {_businessSetting.PrintOriginalDuplicate === 1 && (
          <tr>
                <td align="right">                    
                    <table>  
                      
                      {this.state.settingTypeDisplay === "showall" && (  
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                            Original For Recipient 
                                        </td>
                                        <td>
                                            <input type="checkbox" checked />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                            Duplicate For Transporter
                                        </td>
                                        <td>
                                            <input type="checkbox" checked />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                            Triplicate For Supplier
                                        </td>
                                        <td>
                                            <input type="checkbox" checked />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                      )}  
                      {this.state.settingTypeDisplay === "Duplicate" && (                   
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                        Duplicate For Transporter 
                                        </td>
                                        <td>
                                            <input type="checkbox" checked />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                      )}  
                      {this.state.settingTypeDisplay === "Original" && (                   
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                          Original For Recipient
                                        </td>
                                        <td>
                                            <input type="checkbox" checked />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                      )}                        
                      {this.state.settingTypeDisplay === "Triplicate" && (                   
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>
                                        Triplicate For Supplier 
                                        </td>
                                        <td>
                                            <input type="checkbox" checked />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                      )} 
                    </table>
                </td>
            </tr>
            )}
            <tr>
                <td>
                    <table width="100%">
                        <tr>
                            <td width="20%" valign="top">
                                <div stlye={{ height:"200px", width:"200px", display:"block" }} onClick="_captureLogoButton()" id="logo">
                                    {/* image here */}
                                    {_businessSetting.CompanyLogo == 1 && (
                                      <img src={_companySession.company_logo} style={{maxWidth: "100% !important"}} height="100" width="100" alt="" title=""
                                    />
                                    )}
                                </div>
                            </td>
                            {(_businessSetting.TransportationDetailsField1Enable == 1 ||
                            _businessSetting.TransportationDetailsField2Enable == 1 ||
                            _businessSetting.TransportationDetailsField3Enable == 1 ||
                            _businessSetting.TransportationDetailsField4Enable == 1 ||
                            _businessSetting.TransportationDetailsField5Enable == 1 ||
                            _businessSetting.TransportationDetailsField5Enable == 1) &&
                            _showTransportD == 1 && (
                            <td style= {{textAlign: "right" ,width :"40%", valign:"top"}}>                                
                                <p style={{margin: "6px 0"}}><b>Transportation Details :</b></p>
                                {_businessSetting.TransportationDetailsField1Enable ==
                                1 &&
                                this.state.TransportDetailField1!=null &&
                                this.state.TransportDetailField1!="" && (
                                  <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField1Name} : {" "} </b> 
                                  {this.state.TransportDetailField1} 
                                  </p>
                                )}

                                {_businessSetting.TransportationDetailsField2Enable ==
                                  1 &&
                                  this.state.TransportDetailField2!=null &&
                                  this.state.TransportDetailField2!="" && (
                                    <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField2Name} : {" "} </b>
                                    {this.state.TransportDetailField2}</p>	
                                )}
                                {_businessSetting.TransportationDetailsField3Enable ==
                                1 &&
                                this.state.TransportDetailField3!=null &&
                                this.state.TransportDetailField3!="" && (
                                  <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField3Name} :{" "} </b> 
                                  {this.state.TransportDetailField3}
                                  </p>										
                                )}
                                {_businessSetting.TransportationDetailsField4Enable ==
                                1 &&
                                this.state.TransportDetailField4!=null &&
                                this.state.TransportDetailField4!="" && (
                                  <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField4Name} :{" "} </b> {this.state.TransportDetailField4}
                                </p> 
                                )}     
                                
                                {_businessSetting.TransportationDetailsField5Enable ==
                                1 &&
                                this.state.TransportDetailField5!=null &&
                                this.state.TransportDetailField5!="" && (
                                  <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField5Name} :{" "} </b> {this.state.TransportDetailField5}
                                </p> 

                                )}       
                                {_businessSetting.TransportationDetailsField6Enable ==
                                1 &&
                                this.state.TransportDetailField6!=null &&
                                this.state.TransportDetailField6!="" && (
                                  <p style={{margin: "6px 0"}}><b>{_businessSetting.TransportationDetailsField6Name} :{" "} </b> {this.state.TransportDetailField6}
                                </p> 
                                )}                              
                            </td>
                            )}
                            <td width="40%" valign="top" style={{textAlign : "right"}}>
                                {_businessSetting.SateOfSupply === 1 &&  this.state.stateNamePrint!== "Select State" && ( 
                                  <p style={{margin: "6px 0"}}><b>
                                    {_stateNamePrint > 2
                                      ? "State Of Supply : " + this.state.stateNamePrint
                                      : ""}</b> 
                                    {/* {_stateNamePrint > 2 ? "State Of Supply" : ' : ' }</b> {this.state.stateNamePrint}  */}
                                    </p>
                                )}
                                {_businessSetting.PartyCompanyName === 1 && (
                                  <p style={{margin: "6px 0"}}><b> Company Name : </b> {this.state.companyName}
                                  </p>
                                )}
                                {_businessSetting.Address == 1 && (
                                  <p style={{margin: "6px 0"}}><b>Company Address :</b> {this.state.companyAddress}
                                  </p>
                                )}
                                {_businessSetting.PhoneNumber == 1 && (
                                  <p style={{margin: "6px 0"}}><b>Company Phone no. :</b> {this.state.companyPhone}
                                  </p>
                                )}
                                {_businessSetting.Email == 1 && (
                                  <p style={{margin: "6px 0"}}><b>Company Email :</b> {this.state.companyEmail}
                                  </p>
                                )}

                                {this.state.firm_additional_field1!="" && (
                                  <p style={{margin: "6px 0"}}><b>{this.state.firm_additional_field1} : {" "}</b> {this.state.firm_additional_value1}
                                  </p>
                                )}

                                {this.state.firm_additional_field2!="" && (
                                  <p style={{margin: "6px 0"}}><b>{this.state.firm_additional_field2} : {" "}</b> {this.state.firm_additional_value2}
                                  </p>
                                )}
                                
                                {/* {this.state.firm_additional_field3!="" && (
                                  <p style={{margin: "6px 0"}}><b>{this.state.firm_additional_field3} : {" "}</b> {this.state.firm_additional_value3}
                                  </p>
                                )} */}

                                {_businessSetting.GSTINSale == 1 && (
                                  <p style={{margin: "6px 0"}}><b>GSTIN :</b> {this.state.companyGstin}
                                  </p>
                                )}
                                
                                {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                  <p style={{margin: "6px 0"}}>
                                    {_businessSetting.additional_field_check1 === 1 && (
                                    <b>{_businessSetting.additional_field_name1} :{" "} : {this.state.additional_value_1}</b> 
                                    )}
                                  </p>
                                )}
                                
                                {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                  <p style={{margin: "6px 0"}}>
                                    {_businessSetting.additional_field_check2 === 1 && (
                                    <b>{_businessSetting.additional_field_name2} :{" "} : {this.state.additional_value_2}</b> 
                                    )}
                                  </p>
                                )}
                                
                                {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                  <p style={{margin: "6px 0"}}>
                                    {_businessSetting.additional_field_check3 === 1 && (
                                    <b>{_businessSetting.additional_field_name3} :{" "} : {this.state.additional_value_3}</b> 
                                    )}
                                  </p>
                                )}
                                
                                {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                                  <p style={{margin: "6px 0"}}>
                                    {(_businessSetting.additional_field_date_field_check === 1 || _businessSetting.additional_field_date_field_check === true) && (
                                    <b>{_businessSetting.additional_field_date_field_name} :{" "} : {this.state.additional_value_date}</b> 
                                    )}
                                  </p>
                                )}
                                {_businessSetting.Tran_CustomerPO > 0 && (
                                  <p style={{margin: "6px 0"}}>
                                      {this.state.poDate!=null &&
                                      this.state.poNumber!="null" &&
                                      this.state.poNumber!="Invalid date" && (
                                        <b>PO Date :</b>  + this.state.poDate    )} 
                                  </p>
                                )}
                                {this.state.poNumber!=null && this.state.poNumber!="null" && (
                                  <p style={{margin: "6px 0"}}><b>PO Number :</b> {this.state.poNumber}</p>
                                )}
                                
                                {_businessSetting.DueDatesPaymentTerms > 0 && (
                                  <p style={{margin: "6px 0"}}>
                                      {this.state.dueOnDate.length > 6 &&
                                       this.state.dueTerms > 0 && (
                                        <b>Due Date  :</b>  + this.state.dueOnDate    )} 
                                  </p>
                                )}

                                {(this.state.E_Way_Bill_No != null &&
                                this.state.E_Way_Bill_No != "null" &&
                                this.state.EWayBillNo == 1) && (
                                  <p style={{margin: "6px 0"}}><b>E-Way Bill Number :</b> {this.state.E_Way_Bill_No}</p>
                                )}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>"
                <td align="center">
                    <p style={{color: "#9f9fdf", margin: "15px 0", fontSize : "24px"}}><b>{this.state.titleTxt}</b></p>
                </td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr>
                <td>
                    <table width="100%">
                        <tr>
                            <td width="49%" align="center">
                              <p  style={{background : "#cacaca", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#9f9fdf", fontSize: "20px"}}>{this.state.billTxt}</p>

                              {this.state.expensePartyName === "" && (
                                <p style={{ margin: "5px 0" }}>{this.state.customerCompanyName}</p>
                              )}
                              {this.state.expensePartyName != "" && (
                                <p style={{ margin: "5px 0" }}>{this.state.expensePartyName} (
                                  {this.state.customerCompanyName})</p>
                              )}
                              {this.state.customerAddress!=null &&
                              this.state.customerAddress!="null" && (
                                <p style={{ margin: "5px 0" }} >
                                  {this.state.customerAddress}
                                </p>
                              )}

                              <p style={{background: "#cacaca !important", color: "#FFF", textAlign: "center", WebkitPrintColorAdjust: "exact", margin:"5px 0"}}>Contact No.</p>
                              <p style={{ margin: "5px 0" }}>{this.state.customerPhone}</p>

                              {this.state.PartyAddField1ShowPrint == 1 && (
                                <p style={{ margin: "5px 0" }}>
                                    {this.state.party_add_field_value1!=null &&
                                      this.state.party_add_field_value1!="" &&
                                      this.state.party_add_field_key1!=null &&
                                      this.state.party_add_field_key1!="" && (
                                        <span>
                                          {this.state.party_add_field_value1!=""
                                            ? this.state.party_add_field_key1 +
                                              " : " +
                                              this.state.party_add_field_value1
                                            : ""}
                                        </span>
                                      )}  
                                </p>
                              )}

                              {this.state.PartyAddField2ShowPrint == 1 && (
                                <p style={{ margin: "5px 0" }}>
                                    {this.state.party_add_field_value2!=null &&
                                      this.state.party_add_field_value2!="" &&
                                      this.state.party_add_field_key2!=null &&
                                      this.state.party_add_field_key2!="" && (
                                        <span>
                                          {this.state.party_add_field_value2!=""
                                            ? this.state.party_add_field_key2 +
                                              " : " +
                                              this.state.party_add_field_value2
                                            : ""}
                                        </span>
                                      )}  
                                </p>
                              )}

                              {this.state.PartyAddField3ShowPrint == 1 && (
                                <p style={{ margin: "5px 0" }}>
                                    {this.state.party_add_field_value3!=null &&
                                      this.state.party_add_field_value3!="" &&
                                      this.state.party_add_field_key3!=null &&
                                      this.state.party_add_field_key3!="" && (
                                        <span>
                                          {this.state.party_add_field_value3!=""
                                            ? this.state.party_add_field_key3 +
                                              " : " +
                                              this.state.party_add_field_value3
                                            : ""}
                                        </span>
                                      )}  
                                </p>
                              )}

                            </td>
                                   
                            <td width="49%" align="center">
                                <p style={{background: "#9f9fdf !important", color: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", margin:"5px 0", fontSize : "20px"}}>Invoice No.</p>
                                <p style={{ margin: "5px 0" }}>0021354</p>
                                <p style={{background: "#9f9fdf !important", color: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", margin:"5px 0", fontSize : "20px"}}>Date & Time</p>
                                <p style={{ margin: "5px 0" }}>23rh February 2024 | 08:30 PM</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            {this.state.totalQty!=0 && (                            
            
            <tr>
                <td>
                    <table width="100%" cellpadding="0" cellspacing="0">
                        <tr>
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px"}}>#</th>
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px"}}>{_itemName}</th>
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>{_hsn_sac}</th>

                            {_businessSetting.item_code_enabled === 1 && (
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>{_item_code}</th>
                            )}

                            {this.state.Count > 0 && this.state.CountItem > 0 && (
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>Count</th>
                            )}

                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>{_quantity}</th>

                            {(_businessSetting.unit_enabled == 1 && this.state._type!='EXPENSE') && (
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>Unit</th>
                            )}

                            {_businessSetting.price_per_unit_enabled === 1 && (
                              <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>{_price_per_unit}</th>
                            )}
                             
                            {_businessSetting.discount_enable == 1 && (
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>{_discount}</th>
                            )}
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>GST</th>

                            {_businessSetting.AdditionalCESS == 1 && (
                            <th width="8%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}>{_addditional_cess}</th>
                            )}
                            {_businessSetting.total_amount_enable == 1 && (
                            <th width="10%" style={{background: "#9f9fdf", textAlign: "center", WebkitPrintColorAdjust: "exact", color: "#FFF", fontSize: "14px", padding: "3px"}}> {_total_amount}</th>
                            )}
                        </tr>
                        {this.state.items.map((value, index) => { 
                            let _i = parseInt(index) + 1;
                            let _free_quantity = "";
                            if (
                              _businessSetting.FreeItemQuantity == 1 &&
                              value.free_quantity > 0
                            ) {
                              _free_quantity = " + " + value.free_quantity;
                            }
                            let _gstRatio = "";
                            if (
                              _businessSetting.tax_percentage_enable == 1
                            ) {
                              _gstRatio = "(" + value.gstPerRatio + " %)";
                            }
                            let _batchData = "";
                            let _batchModelData = "";
                            if (
                              Object.keys(value.itemBatchNoAllData).length > 1
                            ) {
                              if (value.itemBatchNoAllData.batch_no!="") {
                                _batchData =
                                  _businessSetting.BatchNoTxt +
                                  " : " +
                                  value.itemBatchNoAllData.batch_no;
                              }
                              if (value.itemBatchNoAllData.model_no!="") {
                                _batchModelData =
                                  _businessSetting.ModelNoTxt +
                                  " : " +
                                  value.itemBatchNoAllData.model_no;
                              }
                            }
                            let _serialNoData = "";
                            let _serialAdd1Data = "";
                            let _serialAdd2Data = "";
                            if (
                              Object.keys(value.itemSerialNoAllData).length >
                              1
                            ) {
                              if (
                                value.itemSerialNoAllData.serial_no!=""
                              ) {
                                _serialNoData =
                                  _businessSetting.SerialNoTxt +
                                  " : " +
                                  value.itemSerialNoAllData.serial_no;
                              }
                              if (
                                _businessSetting.SerialNoAdditionalLabel1 !==
                                ""
                              ) {
                                _serialAdd1Data =
                                  _businessSetting.SerialNoAdditionalLabel1 +
                                  " : " +
                                  value.itemSerialNoAllData
                                    .serialNoAdditionalValue1;
                              }
                              if (
                                value.itemSerialNoAllData
                                  .serialNoAdditionalValue2!=""
                              ) {
                                _serialAdd2Data =
                                  _businessSetting.SerialNoAdditionalLabel2 +
                                  " : " +
                                  value.itemSerialNoAllData
                                    .serialNoAdditionalValue2;
                              }
                            } 
                            return (   
                              <tr>
                                  {_businessSetting.si_enable == 1 && (
                                  <td align="center" style={{padding: "3px"}}><p>{_i}</p></td>
                                  )}
                                  <td align="center" style={{padding: "3px"}}><p>{value.item_name}</p></td>
                                  {_businessSetting.hsn_sac_enable == 1 && (
                                  <td align="center" style={{padding: "3px"}}><p>{value.hsn_sac}</p></td>
                                  )}
                                  {_businessSetting.item_code_enabled === 1 && (
                                  <td align="center" style={{padding: "3px"}}><p> {value.item_code}</p></td>
                                  )}
                                  {this.state.Count == 1 && this.state.CountItem == 1 && (
                                  <td align="center" style={{padding: "3px"}}><p>{value.trans_count}</p></td>
                                  )}
                                  {_businessSetting.quantity_enable == 1 && (
                                    <td align="center" style={{padding: "3px"}}>
                                      <p>
                                        {value.quantity ? value.quantity : 0}{" "}
                                        {(_businessSetting.FreeItemQuantity ===
                                          1 ||
                                          _businessSetting.FreeItemQuantity ===
                                            "1") &&
                                        _free_quantity
                                          ? "  " + _free_quantity
                                          : ""}
                                      </p>
                                    </td>
                                  )}
                                  {(_businessSetting.unit_enabled == 1 && this.state._type!='EXPENSE') && (
                                  <td align="center" style={{padding: "3px"}}>
                                    <p>
                                    {value.unitName ? value.unitName : "NA"}
                                    </p>
                                  </td>
                                  )}
                                  {_businessSetting.price_per_unit_enabled === 1 && (
                                  <td align="center" style={{padding: "3px"}}><p>{value.rate}</p></td>
                                  )}
                                  {_businessSetting.discount_enable == 1 && (
                                  <td align="center" style={{padding: "3px"}}>
                                    <p>
                                      {value.discountAmount}                                      
                                      {_businessSetting.discount_percentage_enable === 1 && (
                                        <span>({value.discountPer + "%"})</span>
                                      )}
                                    </p>
                                    </td>
                                  )}
                                  <td align="center" style={{padding: "3px"}}>
                                    <p>
                                      {_businessSetting.AmountWithDecimal == 1 &&
                                        numberFormat(value.gstAmount) + _gstRatio}
                                      {_businessSetting.AmountWithDecimal == 0 &&
                                      parseFloat(value.gstAmount) + _gstRatio}
                                    </p>
                                  </td>
                                  {_businessSetting.AdditionalCESS == 1 && (
                                  <td align="center" style={{padding: "3px"}}>
                                    <p>{numberFormat(value.Additional_CESS)}</p>
                                  </td>
                                  )}
                                  
                                  <td align="center" style={{padding: "3px"}}>
                                    <p>
                                      {numberFormat(value.total_amount)}
                                      {/*{numberFormat(parseFloat(value.rate) * parseFloat(value.quantity))}*/}
                                    </p>
                                  </td>
                                  
                              </tr>
                            )
                          })}
                        {_businessSetting.TotalItemQuantity == 1 && (
                        <tr>
                            <td align="center" style={{padding: "3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}} colspan="3"><p><b>Total</b></p></td>

                            <td align="center" style={{padding: "3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}}><p><b>{this.state.totalQty}</b></p></td>

                            {_businessSetting.discount_enable === 1 && (
                            <td align="right" style= {{padding: "3px 16px 3px 3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}} colspan="3"><p><b>{this.state.totalDiscount}</b></p></td>
                            )}

                            {_businessSetting.discount_enable === 0 && (
                            <td align="right" style= {{padding: "3px 16px 3px 3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}} colspan="3"><p><b></b></p></td>
                            )}

                            <td align="center" style={{padding: "3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}}><p><b>{this.state.gstAmount}</b></p></td>
                            
                            {_businessSetting.AdditionalCESS == 1 && (
                              <td align="center" style={{padding: "3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}}><p><b>{this.state.totalAddinalCESSAmt}</b></p></td>
                            )}
                            {_businessSetting.total_amount_enable == 1 && (
                            <td align="center" style={{padding: "3px", borderTop: "#9f9fdf 2px solid", borderBottom: "#9f9fdf 2px solid"}}><p><b>{this.state.subtotal}</b></p></td>
                            )}
                        </tr>
                        )}
                    </table>
                </td>
            </tr>
            
            )}
            <tr>
                <td>
                    <table width="100%">
                        <tr>
                            <td width="40%" valign="middle">
                            {_businessSetting.PrintDescription > 0 && (
                            <p><b>Description</b></p>
                            )}
                            {_businessSetting.PrintDescription > 0 && (
                            <p>{this.state.description}</p>
                            )}

                            <p><b>Total Amount In Words</b></p>
                            <p>{_totalAmtInChar}</p>

                            {this.state.printTerms > 0 && (
                            <p><b>Terms & Conditions</b></p>
                            )}                            
                            {this.state.printTerms > 0 && (
                            <p>{this.state.printTermsMsg}</p>
                            )}

                            {this.state.display_bank_name!="" && (
                               <p><b>Pay To -</b></p>
                            )}
                            {this.state.display_bank_name!="" && (
                               <p>{" "}
                                Bank Name - {this.state.display_bank_name}
                               
                                <br></br>
                                Bank Account number -{" "}
                                {this.state.display_bank_accountNo}
                                <br></br>
                                Account Holder's Name -{" "}
                                {this.state.display_bank_holderName}
                               </p>
                            )}
                            </td>
                            <td width="10%" valign="middle"></td>
                            <td width="50%" valign="middle">
                                <table width="100%" cellpadding="0" cellspacing="0">{this.getTotalCalculation()}
                                </table>
                                
                                {/* <table width="100%" cellpadding="0" cellspacing="0">
                                        <tr>
                                        <td width="50%" style={{padding:"3px"}}><p style={{margin:"5px 0"}}><b>Subtotal</b></p></td>
                                        <td width="50%" style={{padding:"3px"}}><p style={{margin:"5px 0" }}>{this.state.subtotal}</p></td>
                                        </tr>
                                        <tr>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>Discount (10%)</b></p></td>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>{this.state.totalDiscount}</p></td>
                                        </tr>
                                        <tr>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b> {this.state.transactionWiseTaxName}@{_finalTranWiseTaxPer}%</b></p></td>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>{numberFormat(_finalTranWiseTaxAmt)}</p></td>
                                        </tr>
                                        
                                        {_businessSetting.TransactionAdditionalChargeEnable == 1 && this.state._type!="PAYMENT-IN" && (
                                        <div>
                                            {_businessSetting.TransactionAdditionalCharge1Enable === 1 && (
                                            <tr>
                                                <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>{_businessSetting.TransactionAdditionalCharge1Name}</b></p></td>
                                                <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>{numberFormat(this.state.TransAdditionalCharge1)}</p></td>
                                            </tr>
                                            )}
                                            {_businessSetting.TransactionAdditionalCharge2Enable === 1 && (
                                            <tr>
                                              <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>{_businessSetting.TransactionAdditionalCharge2Name}</b></p></td>
                                              <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>{numberFormat(this.state.TransAdditionalCharge2)}</p></td>
                                            </tr>
                                            )}
                                            {_businessSetting.TransactionAdditionalCharge3Enable === 1 && (
                                            <tr>
                                              <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>{_businessSetting.TransactionAdditionalCharge3Name}</b></p></td>
                                              <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>{numberFormat(this.state.TransAdditionalCharge3)}</p></td>
                                            </tr>
                                            )}
                                        </div>
                                        )}
                                        

                                        <tr>
                                        <td width="50%" style={{padding:"3px", background: "#9f9fdf", WebkitPrintColorAdjust: "exact"}}><p style={{margin:"5px 0", color:"#FFF"}}><b>Total</b></p></td>
                                        <td width="50%" style={{padding:"3px", background: "#9f9fdf", WebkitPrintColorAdjust: "exact"}}><p style={{margin:"5px 0", color:"#FFF"}}>{this.state.totalAmount}</p></td>
                                        </tr>
                                        <tr>
                                        {}
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>Received Amount</b></p></td>

                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}> {this.state.receivedAmount}</p></td>
                                        </tr>
                                        <tr>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>Balance Due</b></p></td>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>483.40</p></td>
                                        </tr>
                                        <tr>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>Payment Mode</b></p></td>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>Bank - SBI (Ref No. - 33543534)</p></td>
                                        </tr>
                                        <tr>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}><b>Current Balance</b></p></td>
                                        <td width="50%" style={{padding:"3px"}}><p style={{ margin: "5px 0" }}>579.60</p></td>
                                        </tr>
                                </table> */}
                            </td>
                        </tr>
                        
                        
                        <tr>
                            <td  width="50%" valign="bottom">
                                {/* image here */}
                                
                                <div stlye={{ height:"100px !important", width:"100px !important", display:"block" }} onClick="_captureLogoButton()" id="logo">
                                  <img src={this.state.logoMForExcel} alt="" title="" style={{maxWidth : "100px"}} />
                                </div>
                                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {_businessSetting.PrintSignatureText == 1 && (
                                <div style={{textAlign : "right", marginLeft : '50px'}} align="center">
                                  {_companySignatureLen > 15 && (
                                  <img src={_companySession.company_signature} height="100" width="100" />
                                  )}
                                  {_companySignatureLen > 15 && (
                                  <p><b>Authorised Signature</b></p>
                                  )}
                                </div>
                                )} */}
                            </td>
                            {_businessSetting.PrintSignatureText == 1 && (
                            <td  width="50%"  valign="bottom" align="center">
                                  {/* image here */}
                                  {_companySignatureLen > 15 && (
                                  <img src={_companySession.company_signature} height="100" width="100" />
                                  )}
                                  {_companySignatureLen > 15 && (
                                  <p><b>Authorised Signature</b></p>
                                  )}
                              </td>
                            )}
                        </tr>
                        
                    </table>
                </td>
            </tr>
            {(_businessSetting.PrintAcknowledgement == 1 && this.state.titleTxt !== 'Expense') && (
            <tr>
                <td>
                    <table width="100%">
                        <tr>
                            <td width="27%" valign="middle">
                                <p style={{margin: "30px 0 6px 0"}}><b>{this.state.customerCompanyName}</b></p>
                            </td>
                            <td width="33%" valign="middle">
                                <p style={{margin: "30px 0 6px 0"}}><b>Acknowledgement</b></p>	
                                    <p style={{margin: "6px 0"}}><b>{this.state.companyName}</b></p>	                                
                            </td>
                            <td width="40%" valign="middle" style={{textAlign: "right"}}>
                                <p style={{margin: "30px 0 6px 0"}}><b>Invoice No :</b> {this.state.invoiceNo}</p>
                                <p style={{margin: "6px 0"}}><b>Invoice Date :</b> {this.state.invoiceDate}</p>
                                <p style={{margin: "6px 0"}}><b>Invoice Amount :</b>{this.state.totalAmount}</p>								
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            )}
            <tr>
                <td width="30%" valign="bottom" align="center"> </td>
                <td width="30%" valign="bottom" align="center"> </td>
                <td width="40%" valign="bottom" align="center" colspan="3">
                    {/* image here */}
                    <p>&nbsp;&nbsp;</p>
                    <p><b>Receiver's Seal & Sign</b></p>
                </td>
            </tr>
        </table>
      );
    }
  }
  printPdfArea() {
    
    let _hideDiv = "";
    if (
      this.state._type === "PAYMENT-IN" ||
      this.state._type === "PAYMENT-OUT" ||
      this.state.totalQty === 0
    ) {
      _hideDiv = "hide";
    }

    let _hideItemPrice = "";
    if (this.state._type == "DELIVERY CHALLAN") {
      if (_businessSetting.DeliveryChallanPrint == 0) {
        //_hideItemPrice = "hide";
      }
    }
    let _showTransportD = 0;
    if (
      (this.state.TransportDetailField1!=null &&
        this.state.TransportDetailField1!="") ||
      (this.state.TransportDetailField2!=null &&
        this.state.TransportDetailField2!="") ||
      (this.state.TransportDetailField3!=null &&
        this.state.TransportDetailField3!="") ||
      (this.state.TransportDetailField4!=null &&
        this.state.TransportDetailField4!="")
    ) {
      _showTransportD = 1;
    }
    let amt = this.state.totalAmtInChar;
    let _totalAmtInChar = "";
    if (amt > 0) {
      let returnData = new ToWords({
        converterOptions: { ignoreDecimal: false },
      }).convert(amt ? amt : 0);
      _totalAmtInChar = returnData + " Only";
    } else {
      _totalAmtInChar = "Zero";
    }
    let _companyAddressPrint = this.state.companyAddress
      ? this.state.companyAddress.length
      : 0;
    let _stateNamePrint = this.state.stateNamePrint
      ? this.state.stateNamePrint.length
      : 0;
    let _itemName = _businessSetting.item_name
      ? _businessSetting.item_name
      : "Item Name";
    let _si_no = "#";
    let _item_code = "Item code";
    let _hsn_sac = "HSN/SAC";
    let _quantity = "Qty";
    let _unit = "Unit";
    let _discount = "Discount";
    let _price_per_unit = "Rate";
    let _total_amount = "Amount";
    let _addditional_cess = _businessSetting.addditional_cess
      ? _businessSetting.addditional_cess
      : "Ad. CESS";

    if (_businessSetting.si_enable == 1) {
      _si_no = _businessSetting.si_no;
    }
    if (_businessSetting.item_code_enabled === 1) {
      _item_code = _businessSetting.item_code;
    }
    if (_businessSetting.hsn_sac_enable == 1) {
      _hsn_sac = _businessSetting.hsn_sac;
    }
    if (_businessSetting.quantity_enable == 1) {
      _quantity = _businessSetting.quantity;
    }
    if (_businessSetting.unit_enabled == 1) {
      _unit = _businessSetting.unit;
    }
    if (_businessSetting.price_per_unit_enabled == 1) {
      _price_per_unit = _businessSetting.price_per_unit;
    }
    if (_businessSetting.discount_enable == 1) {
      _discount = _businessSetting.discount;
    }
    if (_businessSetting.total_amount_enable == 1) {
      _total_amount = _businessSetting.total_amount;
    }
    console.log("==buss tabish sett==", _businessSetting.CompanyLogo);
    let _invdate = "Date";
    // if(this.state._type == "PAYMENT-IN" || this.state._type == "PAYMENT-OUT"){
    //   _invdate = "Date";
    // }
    if (this.state.isLoaded == true) {
      return (
        <html style={{ marginTop: "15px" }}>
          <head>
            <title>Recipt</title>
          </head>
          <div id="template">
            {_businessSetting.PrintOriginalDuplicate == 1 && (
              <div
                style={{
                  flex: "1",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  lineHeight: "20px",
                  textAlign: "right",
                }}
              >
                {this.state._type!="PAYMENT-IN" && (
                  <ul>
                    <li
                      style={{
                        display: "inline",
                        listStyleType: "none",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Original for Recipient{" "}
                      </span>
                      <input
                        type="checkbox"
                        id="originalRecipient"
                        name="originalRecipient"
                        value="1"
                        checked={this.state.checkOriginal}
                        disabled={this.state.checkOriginalDisable}
                      />
                    </li>
                    <li
                      style={{
                        display: "inline",
                        listStyleType: "none",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Duplicate for Transporter{" "}
                      </span>
                      <input
                        type="checkbox"
                        id="duplicateTransporter"
                        name="duplicateTransporter"
                        value="1"
                        checked={this.state.checkDuplicate}
                        disabled={this.state.checkDuplicateDisable}
                      />
                    </li>
                    <li
                      style={{
                        display: "inline",
                        listStyleType: "none",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Triplicate for Supplier{" "}
                      </span>
                      <input
                        type="checkbox"
                        id="triplicateSupplier"
                        name="triplicateSupplier"
                        value="1"
                        checked={this.state.checkTriplicate}
                        disabled={this.state.checkTriplicateDisable}
                      />
                    </li>
                  </ul>
                )}
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                id="logo"
                style={{
                  flex: "1",
                  alignItems: "center",
                  width: "250px",
                  height: "150px",
                  marginTop: "10px",
                }}
                onClick="_captureLogoButton()"
              >
                {/* {_imageBaseDecode} */}
                {_businessSetting.CompanyLogo == 1 && (
                  <img
                    src={_imageBaseDecode}
                    style={{
                      maxWidth: "250px",
                      maxHeight: "150px",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </div>
              {(_businessSetting.TransportationDetailsField1Enable == 1 ||
                _businessSetting.TransportationDetailsField2Enable == 1 ||
                _businessSetting.TransportationDetailsField3Enable == 1 ||
                _businessSetting.TransportationDetailsField4Enable == 1 ||
                _businessSetting.TransportationDetailsField5Enable == 1 ||
                _businessSetting.TransportationDetailsField5Enable == 1) &&
                _showTransportD == 1 && (
                  <div
                    style={{
                      flex: "1",
                      fontFamily: "Arial",
                      fontSize: "15px",
                      lineHeight: "20px",
                      textAlign: "right",
                      marginRight: "50px",
                    }}
                  >
                    <h5
                      style={{
                        fontWeight: "bold",
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Transportation Details
                    </h5>

                    {_businessSetting.TransportationDetailsField1Enable == 1 &&
                      this.state.TransportDetailField1!=null &&
                      this.state.TransportDetailField1!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField1Name} :{" "}
                          {this.state.TransportDetailField1}
                        </p>
                      )}

                    {_businessSetting.TransportationDetailsField2Enable == 1 &&
                      this.state.TransportDetailField2!=null &&
                      this.state.TransportDetailField2!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField2Name} :{" "}
                          {this.state.TransportDetailField2}
                        </p>
                      )}
                    {_businessSetting.TransportationDetailsField3Enable == 1 &&
                      this.state.TransportDetailField3!=null &&
                      this.state.TransportDetailField3!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField3Name} :{" "}
                          {this.state.TransportDetailField3}
                        </p>
                      )}
                    {_businessSetting.TransportationDetailsField4Enable == 1 &&
                      this.state.TransportDetailField4!=null &&
                      this.state.TransportDetailField4!="" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {_businessSetting.TransportationDetailsField4Name} :{" "}
                          {this.state.TransportDetailField4}
                        </p>
                      )}
                    {_businessSetting.TransportationDetailsField5Enable ==
                      1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.TransportationDetailsField5Name} :{" "}
                        {this.state.TransportDetailField5}
                      </p>
                    )}
                    {_businessSetting.TransportationDetailsField6Enable ==
                      1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.TransportationDetailsField6Name} :{" "}
                        {this.state.TransportDetailField6}
                      </p>
                    )}
                  </div>
                )}
              <div
                style={{
                  flex: "1",
                  fontFamily: "Arial",
                  fontSize: "15px",
                  lineHeight: "20px",
                  textAlign: "right",
                }}
              >
                {_businessSetting.SateOfSupply == 1 &&
                  this.state.stateNamePrint!="Select State" && (
                    <p
                      style={{
                        margin: "3px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {_stateNamePrint > 2
                        ? "State Of Supply : " + this.state.stateNamePrint
                        : ""}
                    </p>
                  )}
                {_businessSetting.PartyCompanyName == 1 && (
                  <p
                    style={{
                      fontWeight: "bold",
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    Company Name : {this.state.companyName}
                  </p>
                )}

                {_businessSetting.Address == 1 && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {_companyAddressPrint > 2
                      ? "Company Address : " + this.state.companyAddress
                      : ""}
                  </p>
                )}
                {_businessSetting.PhoneNumber == 1 && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.companyPhone.length > 2
                      ? "Company Phone no. : " + this.state.companyPhone
                      : ""}
                  </p>
                )}

                {_businessSetting.Email == 1 && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.companyEmail.length > 2
                      ? "Company Email : " + this.state.companyEmail
                      : ""}
                  </p>
                )}

                {this.state.firm_additional_field1!="" && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.firm_additional_field1} :{" "}
                    {this.state.firm_additional_value1}
                  </p>
                )}
                {this.state.firm_additional_field2!="" && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.firm_additional_field2} :{" "}
                    {this.state.firm_additional_value2}
                  </p>
                )}

                {_businessSetting.GSTINSale == 1 && (
                  <p
                    style={{
                      margin: "3px 0 10px 0",
                      color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {this.state.companyGstin!="" ? "GSTIN : " + this.state.companyGstin : ""}
                  </p>
                )}
                {_businessSetting.TransactionAdditionalFieldSale > 0 && (
                  <div id="additionFields">
                    {_businessSetting.additional_field_check1 == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_name1} :{" "}
                        {this.state.additional_value_1}
                      </p>
                    )}
                    {_businessSetting.additional_field_check2 == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_name2} :{" "}
                        {this.state.additional_value_2}
                      </p>
                    )}
                    {_businessSetting.additional_field_check3 == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_name3} :{" "}
                        {this.state.additional_value_3}
                      </p>
                    )}
                    {(_businessSetting.additional_field_date_field_check == 1 || _businessSetting.additional_field_date_field_check == true) && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {_businessSetting.additional_field_date_field_name} :{" "}
                        {this.state.additional_value_date}
                      </p>
                    )}
                  </div>
                )}
                {_businessSetting.Tran_CustomerPO ? (
                  <div>
                    {this.state.poDate!=null &&
                      this.state.poNumber!="null" &&
                      this.state.poNumber!="Invalid date" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.poDate!=""
                            ? "PO Date : " + this.state.poDate
                            : ""}
                        </p>
                      )}
                    {this.state.poNumber!=null &&
                      this.state.poNumber!="null" && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.poNumber!=""
                            ? "PO Number : " + this.state.poNumber
                            : ""}
                        </p>
                      )}
                  </div>
                ) : (
                  <div />
                )}
                {_businessSetting.DueDatesPaymentTerms ? (
                  <div>
                    {/* {this.state.dueOnDate!=null ||
                        this.state.dueOnDate!="null" ||
                        this.state.dueOnDate!="Invalid date"  || this.state.dueOnDate!=0 || } */}
                    {this.state.dueOnDate.length > 6 && this.state.dueTerms > 0 && (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.dueOnDate!="" ? "Due Date : " + this.state.dueOnDate : ""}
                        </p>
                      )}
                      {(this.state.dueOnDate.length > 6 && (this.state._type == 'SALE ORDER' || this.state._type == 'PURCHASE ORDER') )&& (
                        <p
                          style={{
                            margin: "3px 0 10px 0",
                            color: "black",
                            fontSize: "15px",
                          }}
                        >
                          {this.state.dueOnDate!="" ? "Due Date : " + this.state.dueOnDate : ""}
                        </p>
                      )}
                  </div>
                ) : (
                  <div />
                )}

                {this.state.E_Way_Bill_No!=null &&
                  this.state.E_Way_Bill_No!="null" &&
                  this.state.EWayBillNo == 1 && (
                    <p
                      style={{
                        margin: "3px 0 10px 0",
                        color: "black",
                        fontSize: "15px",
                      }}
                    >
                      {this.state.E_Way_Bill_No!=""
                        ? "E-Way Bill Number : " + this.state.E_Way_Bill_No
                        : ""}
                    </p>
                  )}
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontFamily: "Arial",
                padding: "10px 0",
                fontWeight: "bold",
                color: "#9f9fdf",
              }}
            >
              {this.state.titleTxt}
            </div>
            <div>
              <table
                cellPadding="0"
                cellSpacing="0"
                border="0"
                width="100%"
                style={{ marginBottom: "40px" }}
              >
                <tr>
                  <td width="49%" valign="top">
                    <h6
                      style={{
                        background: "#9f9fdf",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "15px",
                        fontFamily: "Arial",
                        margin: "5px 0",
                      }}
                    >
                      {this.state.billTxt}
                    </h6>

                    {this.state.expensePartyName == "" && (
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          margin: "3px 0",
                          color: "black",
                          marginBottom: "5px",
                        }}
                      >
                        {this.state.customerCompanyName}
                      </p>
                    )}

                    {this.state.expensePartyName!="" && (
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          margin: "3px 0",
                          color: "black",
                          marginBottom: "5px",
                        }}
                      >
                        {this.state.expensePartyName} (
                        {this.state.customerCompanyName})
                      </p>
                    )}
                    {/* 
                      <p style={{
                          fontWeight: 'bold',
                          fontSize: '15px',
                          margin: '3px 0',
                          color: 'black',
                          marginBottom: '5px'
                      }}>
                          {this.state.customerCompanyName}
                      </p> */}
                    {this.state.customerAddress!=null &&
                      this.state.customerAddress!="null" && (
                        <p
                          style={{
                            fontFamily: "Arial",
                            fontSize: "15px",
                            lineHeight: "20px",
                            margin: "0 0",
                            color: "black",
                            marginBottom: "5px",
                          }}
                        >
                          {this.state.customerAddress}
                        </p>
                      )}
                    <p
                      style={{
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        margin: "0 0",
                        color: "black",
                      }}
                    >
                      {this.state.customerPhone}
                    </p>

                    {this.state.PartyAddField1ShowPrint == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.party_add_field_value1!=null &&
                          this.state.party_add_field_value1!="" &&
                          this.state.party_add_field_key1!=null &&
                          this.state.party_add_field_key1!="" && (
                            <span>
                              {this.state.party_add_field_value1!=""
                                ? this.state.party_add_field_key1 +
                                  " : " +
                                  this.state.party_add_field_value1
                                : ""}
                            </span>
                          )}
                      </p>
                    )}
                    {this.state.PartyAddField2ShowPrint == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.party_add_field_value2!=null &&
                          this.state.party_add_field_value2!="" &&
                          this.state.party_add_field_key2!=null &&
                          this.state.party_add_field_key2!="" && (
                            <span>
                              {this.state.party_add_field_value2!=""
                                ? this.state.party_add_field_key2 +
                                  " : " +
                                  this.state.party_add_field_value2
                                : ""}
                            </span>
                          )}
                      </p>
                    )}
                    {this.state.PartyAddField3ShowPrint == 1 && (
                      <p
                        style={{
                          margin: "3px 0 10px 0",
                          color: "black",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.party_add_field_value3!=null &&
                          this.state.party_add_field_value3!="" &&
                          this.state.party_add_field_key3!=null &&
                          this.state.party_add_field_key3!="" && (
                            <span>
                              {this.state.party_add_field_value3!=""
                                ? this.state.party_add_field_key3 +
                                  " : " +
                                  this.state.party_add_field_value3
                                : ""}
                            </span>
                          )}
                      </p>
                    )}
                  </td>
                  <td width="2%" valign="top">
                    &nbsp;
                  </td>
                  <td width="49%" valign="top">
                    <h6
                      className={this.state.invoiceRtnHide}
                      style={{
                        background: "#9f9fdf",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "15px",
                        fontFamily: "Arial",
                        margin: "5px 0",
                      }}
                    >
                      Return No.
                    </h6>
                    <p
                      className={this.state.invoiceRtnHide}
                      style={{
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "center",
                        margin: "0 0",
                        color: "black",
                      }}
                    >
                      {this.state.invoiceRtnNo}
                    </p>
                    {_businessSetting.InvoiceBillNo > 0 && (
                      <div>
                        <h6
                          style={{
                            background: "#9f9fdf",
                            color: "#FFFFFF",
                            textAlign: "center",
                            fontSize: "15px",
                            fontFamily: "Arial",
                            margin: "5px 0",
                          }}
                        >
                          {this.state._invNo}
                        </h6>
                        <p
                          style={{
                            fontFamily: "Arial",
                            fontSize: "15px",
                            lineHeight: "20px",
                            textAlign: "center",
                            margin: "0 0",
                            color: "black",
                          }}
                        >
                          {this.state.prefixId > 0 &&
                            this.state.prefixName!="None" &&
                            this.state.prefixName!="none" &&
                            "(" + this.state.prefixName + ") "}
                          {this.state.invoiceNo}
                          
                          {this.state.trans_duplicate_normal === 'Duplicate' && 
                              ' ('+this.state.trans_duplicate_normal+')'
                          }
                        </p>
                      </div>
                    )}
                    <h6
                      style={{
                        background: "#9f9fdf",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "15px",
                        fontFamily: "Arial",
                        margin: "5px 0",
                      }}
                    >
                    {_invdate} {invTime!="" && <span> & Time</span>}
                    </h6>
                    <p
                      style={{
                        fontFamily: "Arial",
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "center",
                        margin: "0 0",
                        color: "black",
                      }}
                    >
                      {this.state.invoiceDate}
                    </p>

                    {this.state._type == "CREDIT NOTE" && (
                      <h6
                        style={{
                          background: "#9f9fdf",
                          color: "#FFFFFF",
                          textAlign: "center",
                          fontSize: "15px",
                          fontFamily: "Arial",
                          margin: "5px 0",
                        }}
                      >
                        Date {invTime!="" && <span> & Time</span>}
                      </h6>
                    )}
                    {this.state._type == "CREDIT NOTE" && (
                      <p
                        style={{
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "center",
                          margin: "0 0",
                          color: "black",
                        }}
                      >
                        {_pdfDate}
                      </p>
                    )}

                    {this.state._type == "PURCHASE ORDER" && (
                      <h6
                        style={{
                          background: "#9f9fdf",
                          color: "#FFFFFF",
                          textAlign: "center",
                          fontSize: "15px",
                          fontFamily: "Arial",
                          margin: "5px 0",
                        }}
                      >
                        Due Date
                      </h6>
                    )}

                    {this.state._type == "PURCHASE ORDER" && (
                      <p
                        style={{
                          fontFamily: "Arial",
                          fontSize: "15px",
                          lineHeight: "20px",
                          textAlign: "center",
                          margin: "0 0",
                          color: "black",
                        }}
                      >
                        {this.state.dueDate}
                      </p>
                    )}
                  </td>
                </tr>
                <tr className={_hideDiv} >
                  <td colSpan="3" width="100%">
                    <table
                      width="100%"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{ marginTop: "10px" }}
                    >
                      <tr style={{background : '#9f9fdf'}}>
                        {_businessSetting.si_enable == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_si_no}
                          </th>
                        )}

                        <th
                          style={{
                            color: "#FFFFFF",
                            textAlign: "center",
                            //fontSize: '15px',
                            fontFamily: "Arial",
                            //lineHeight: '24px',
                            padding: "3px",
                          }}
                        >
                          {_itemName}
                        </th>

                        {_businessSetting.hsn_sac_enable == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_hsn_sac}
                          </th>
                        )}
                        
                        {_businessSetting.item_code_enabled == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_item_code}
                          </th>
                        )}
                        {this.state.Count > 0 && this.state.CountItem > 0 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            Count
                          </th>
                        )}

                        {_businessSetting.quantity_enable == 1 && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_quantity}
                          </th>
                        )}
                        {(_businessSetting.unit_enabled == 1 && this.state._type!='EXPENSE') && (
                          <th
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_unit}
                          </th>
                        )}

                        {_businessSetting.price_per_unit_enabled == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_price_per_unit}
                          </th>
                        )}

                        {_businessSetting.discount_enable == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_discount}
                          </th>
                        )}
                        <th
                          className={_hideItemPrice}
                          style={{
                            color: "#FFFFFF",
                            textAlign: "center",
                            //fontSize: '15px',
                            fontFamily: "Arial",
                            //lineHeight: '24px',
                            padding: "3px",
                          }}
                        >
                          GST
                        </th>

                        {_businessSetting.AdditionalCESS == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_addditional_cess}
                          </th>
                        )}

                        {_businessSetting.total_amount_enable == 1 && (
                          <th
                            className={_hideItemPrice}
                            style={{
                              color: "#FFFFFF",
                              textAlign: "center",
                              //fontSize: '15px',
                              fontFamily: "Arial",
                              //lineHeight: '24px',
                              padding: "3px",
                            }}
                          >
                            {_total_amount}
                          </th>
                        )}
                      </tr>
                      {this.state.items.map((value, index) => {
                        console.log("==DATA==", JSON.stringify(value));
                        let _i = parseInt(index) + 1;
                        let _free_quantity = "";
                        if (
                          _businessSetting.FreeItemQuantity == 1 &&
                          value.free_quantity > 0
                        ) {
                          _free_quantity = " + " + value.free_quantity;
                        }
                        let _gstRatio = "";
                        if (_businessSetting.tax_percentage_enable == 1) {
                          _gstRatio = "(" + value.gstPerRatio + " %)";
                        }
                        let _batchData = "";
                        let _batchModelData = "";
                        if (Object.keys(value.itemBatchNoAllData).length > 1) {
                          if (value.itemBatchNoAllData.batch_no!="") {
                            _batchData =
                              _businessSetting.BatchNoTxt +
                              " : " +
                              value.itemBatchNoAllData.batch_no;
                          }
                          if (value.itemBatchNoAllData.model_no!="") {
                            _batchModelData =
                              _businessSetting.ModelNoTxt +
                              " : " +
                              value.itemBatchNoAllData.model_no;
                          }
                        }
                        let _serialNoData = "";
                        let _serialAdd1Data = "";
                        let _serialAdd2Data = "";
                        if (Object.keys(value.itemSerialNoAllData).length > 1) {
                          if (value.itemSerialNoAllData.serial_no!="") {
                            _serialNoData =
                              _businessSetting.SerialNoTxt +
                              " : " +
                              value.itemSerialNoAllData.serial_no;
                          }
                          if (
                            value.itemSerialNoAllData
                              .serialNoAdditionalValue1!=""
                          ) {
                            _serialAdd1Data =
                              _businessSetting.SerialNoAdditionalLabel1 +
                              " : " +
                              value.itemSerialNoAllData
                                .serialNoAdditionalValue1;
                          }
                          if (
                            value.itemSerialNoAllData
                              .serialNoAdditionalValue2!=""
                          ) {
                            _serialAdd2Data =
                              _businessSetting.SerialNoAdditionalLabel2 +
                              " : " +
                              value.itemSerialNoAllData
                                .serialNoAdditionalValue2;
                          }
                        }

                        return (
                          <tr>
                            {_businessSetting.si_enable == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_i}
                              </td>
                            )}
                            <td
                              style={{
                                fontFamily: "Arial",
                                fontSize: "15px",
                                textAlign: "center",
                                color: "#333333",
                                lineHeight: "24px",
                                borderTop: "1px solid #2929af",
                                borderBottom: "1px solid #2929af",
                              }}
                            >
                              <table width="100%">
                                <tr>
                                  <td>
                                    <span>{value.item_name}</span>
                                  </td>
                                </tr>
                                {value.itemBatchNoAllData.map(
                                  (batchDataLoop, index) => {
                                    return (
                                      <div>
                                        {batchDataLoop.batch_no!="" && (
                                          <tr>
                                            {_businessSetting.batch_tracking_enable ===
                                              1 && (
                                              <td>
                                                <span>
                                                  {_businessSetting.BatchNoTxt}{" "}
                                                  : {batchDataLoop.batch_no}
                                                </span>
                                              </td>
                                            )}
                                          </tr>
                                        )}
                                      </div>
                                    );
                                  }
                                )}

                                {value.itemSerialNoAllData.map(
                                  (serialDataLoop, index) => {
                                    return (
                                      <div>
                                        {serialDataLoop.serial_no!="" && (
                                          <tr>
                                            {_businessSetting.serial_tracking_enabled ===
                                              1 && (
                                              <td>
                                                {serialDataLoop.serial_no !=
                                                  "" && (
                                                  <span>
                                                    {" "}
                                                    {
                                                      _businessSetting.SerialNoTxt
                                                    }{" "}
                                                    : {serialDataLoop.serial_no}
                                                  </span>
                                                )}
                                                <br />
                                                {_businessSetting.SerialNoAdditionalLabel1 !==
                                                  "" && (
                                                  <span>
                                                    {
                                                      _businessSetting.SerialNoAdditionalLabel1
                                                    }{" "}
                                                    :{" "}
                                                    {
                                                      serialDataLoop.serialNoAdditionalValue1
                                                    }
                                                  </span>
                                                )}
                                                <br />
                                                {_businessSetting.SerialNoAdditionalLabel2 !==
                                                  "" && (
                                                  <span>
                                                    {
                                                      _businessSetting.SerialNoAdditionalLabel2
                                                    }{" "}
                                                    :{" "}
                                                    {
                                                      serialDataLoop.serialNoAdditionalValue2
                                                    }
                                                  </span>
                                                )}
                                                <br />
                                              </td>
                                            )}
                                          </tr>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </table>
                              {/* {value.item_name}<br/>
                                  <span>{_batchData}</span><br/>
                                  <span>{_batchModelData}</span><br/>
                                  <span>{_serialNoData}</span><br/>
                                  <span>{_serialAdd1Data}</span><br/>
                                  <span>{_serialAdd2Data}</span> */}
                            </td>

                            {_businessSetting.hsn_sac_enable == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.hsn_sac}
                              </td>
                            )}

                            {_businessSetting.item_code_enabled === 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.item_code}
                              </td>
                            )}

                            {this.state.Count == 1 &&
                              this.state.CountItem == 1 && (
                                <td
                                  style={{
                                    fontFamily: "Arial",
                                    fontSize: "15px",
                                    textAlign: "center",
                                    color: "#333333",
                                    lineHeight: "24px",
                                    borderTop: "1px solid #2929af",
                                    borderBottom: "1px solid #2929af",
                                  }}
                                >
                                  {value.trans_count}
                                </td>
                              )}
                            {_businessSetting.quantity_enable == 1 && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.quantity ? value.quantity : 0}{" "}
                                {(_businessSetting.FreeItemQuantity == 1 ||
                                  _businessSetting.FreeItemQuantity == "1") &&
                                _free_quantity
                                  ? "  " + _free_quantity
                                  : ""}
                              </td>
                            )}

                            {(_businessSetting.unit_enabled == 1 && this.state._type!='EXPENSE') && (
                              <td
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.unitName ? value.unitName : "NA"}
                              </td>
                            )}

                            {_businessSetting.price_per_unit_enabled == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.rate}
                              </td>
                            )}

                            {_businessSetting.discount_enable == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {value.discountAmount}
                                {/* +' ('+value.discountPer +'%)' */}
                                {_businessSetting.discount_percentage_enable ===
                                  1 && <span>({value.discountPer + "%"})</span>}
                              </td>
                            )}
                            <td
                              className={_hideItemPrice}
                              style={{
                                fontFamily: "Arial",
                                fontSize: "15px",
                                textAlign: "center",
                                color: "#333333",
                                lineHeight: "24px",
                                borderTop: "1px solid #2929af",
                                borderBottom: "1px solid #2929af",
                              }}
                            >
                              {_businessSetting.AmountWithDecimal == 1 &&
                                numberFormat(value.gstAmount) + _gstRatio}
                              {_businessSetting.AmountWithDecimal == 0 &&
                              parseFloat(value.gstAmount) + _gstRatio}
                              {/* {value.gstAmount} */}
                            </td>

                            {_businessSetting.AdditionalCESS == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {numberFormat(value.Additional_CESS)}
                              </td>
                            )}
                            {_businessSetting.total_amount_enable == 1 && (
                              <td
                                className={_hideItemPrice}
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  borderTop: "1px solid #2929af",
                                  borderBottom: "1px solid #2929af",
                                }}
                              >
                                {_businessSetting.AmountWithDecimal == 1 &&
                                  numberFormat(value.total_amount)
                                  /*numberFormat(
                                    parseFloat(value.rate) *
                                      parseFloat(value.quantity)
                                  )*/}
                                {_businessSetting.AmountWithDecimal == 0 &&
                                parseFloat(value.total_amount)
                                /*parseFloat(
                                    parseFloat(value.rate) *
                                      parseFloat(value.quantity)
                                  )*/}
                                {/* {numberFormat(value.totalAmount)} */}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                      {_businessSetting.TotalItemQuantity == 1 && (
                        <tr
                          style={{
                            fontFamily: "Arial",
                            fontSize: "15px",
                            textAlign: "center",
                            color: "#333333",
                            lineHeight: "24px",
                            borderTop: "1px solid #2929af",
                            borderBottom: "1px solid #2929af",
                          }}
                        >
                          {_businessSetting.si_enable == 1 && <td></td>}
                          <td>
                            <b>Total</b>
                          </td>
                          {_businessSetting.hsn_sac_enable == 1 && <td></td>}
                          {_businessSetting.item_code_enabled == 1 && <td></td>}
                          {this.state.Count > 0 && this.state.CountItem > 0 && (
                            <td></td>
                          )}
                          {_businessSetting.quantity_enable == 1 && (
                            <td>
                              <b>{this.state.totalQty}</b>
                            </td>
                          )}
                          {(_businessSetting.unit_enabled == 1 && this.state._type!='EXPENSE') && <td></td>}
                          {_businessSetting.price_per_unit_enabled == 1 && (
                            <td></td>
                          )}
                          {_businessSetting.discount_enable == 1 && (
                            <td>
                              <b>{this.state.totalDiscount}</b>
                            </td>
                          )}
                          {
                            <td>
                              <b>{this.state.gstAmount}</b>
                            </td>
                          }
                          {_businessSetting.AdditionalCESS == 1 && (
                            <td>
                              <b>{this.state.totalAddinalCESSAmt}</b>
                            </td>
                          )}
                          {_businessSetting.total_amount_enable == 1 && (
                            <td>
                              <b>{this.state.subtotal}</b>
                            </td>
                          )}
                        </tr>
                      )}
                      {/*{_businessSetting.TotalItemQuantity == 1 &&
                              <tr style={{
                                  fontFamily: 'Arial',
                                  fontSize: '15px',
                                  textAlign: 'center',
                                  color: '#333333',
                                  lineHeight: '24px',
                                  borderTop: '1px solid #2929af',
                                  borderBottom: '1px solid #2929af'
                              }}>
                                  {_businessSetting.hsn_sac_enable == 1 && <td></td>}
                                  <td><b>Total</b></td>
                                  {_businessSetting.quantity_enable == 1 &&
                                  <td></td>
                                  }
                                  {_businessSetting.quantity_enable == 1 &&
                                  <td><b>{this.state.totalQty}</b></td>
                                  }
                                  {_businessSetting.unit_enabled == 1 && <td></td>}

                                  {_businessSetting.discount_enable == 1 &&
                                  <td></td>
                                  }

                                  {_businessSetting.discount_enable == 1 &&
                                  <td><b>{this.state.totalDiscount}</b></td>
                                  }
                                  <td><b>{this.state.gstAmount}</b></td>


                                  {_businessSetting.AdditionalCESS == 1 &&
                                  <td></td>
                                  }
                                  {_businessSetting.total_amount_enable == 1 &&
                                  <td><b>{this.state.subtotal}</b></td>
                                  }
                              </tr>
                            }*/}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <table cellPadding="0" cellSpacing="0" width="100%">
                      {_businessSetting.PrintDescription > 0 && (
                        <tr>
                          <td colSpan="2" height="10">
                            <div
                              className={
                                this.state.description.length > 0 ? "" : "hide"
                              }
                            >
                              <br />
                              Description
                              <p
                                style={{
                                  margin: "3px 0",
                                  fontSize: "15px",
                                }}
                              >
                                {this.state.description}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td colSpan="2" height="10">
                          <div>
                            <br />
                            <b>Total Amount In Words</b>
                            <p
                              style={{
                                margin: "3px 0",
                                fontSize: "15px",
                                textTransform: "capitalize",
                              }}
                            >
                              {_totalAmtInChar}
                            </p>
                          </div>
                        </td>
                      </tr>

                      {this.state.printTerms > 0 && (
                        <tr>
                          <td colSpan="2" height="10">
                            <div
                              className={
                                this.state.printTermsMsg.length > 2
                                  ? ""
                                  : "hide"
                              }
                            >
                              <br />
                              <b>Terms and Conditions</b>
                              <p
                                style={{
                                  margin: "3px 0",
                                  fontSize: "15px",
                                }}
                              >
                                {this.state.printTermsMsg}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="2" height="10">
                          {this.state.display_bank_name!="" && (
                            <div
                              style={{
                                marginTop: "30px",
                              }}
                            >
                              <span
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Arial",
                                  fontSize: "15px",
                                  color: "#333333",
                                  lineHeight: "24px",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Pay To -
                              </span>
                              <p
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Bank Name - {this.state.display_bank_name}
                                <br></br>
                                Bank Account number -{" "}
                                {this.state.display_bank_accountNo}
                                <br></br>
                                Account Holder's Name -{" "}
                                {this.state.display_bank_holderName}
                                <br></br>
                              </p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          {_extraReceiveDiv}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          {_extraDeleiveryDiv}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" height="10">
                          &nbsp;
                          <div
                            style={{
                              float: "left !important",
                              margin: "0 0 0 0",
                            }}
                          >
                            <a
                              className="float-left"
                              style={{
                                flex: "1",
                                alignItems: "center",
                                width: "200px",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                src={_vLogoBaseDecode}
                                style={{
                                  maxWidth: "250px",
                                  maxHeight: "110px",
                                  borderRadius: "10px",
                                }}
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td colSpan="2" width="60%">
                    {this.getTotalCalculation()}
                  </td>
                </tr>
              </table>
            </div>
            {(_businessSetting.PrintAcknowledgement == 1 && this.state.titleTxt !== 'Expense') && (
            <div className="claerfix"><hr/></div>
            )}
            {(_businessSetting.PrintAcknowledgement == 1 && this.state.titleTxt !== 'Expense') && (
            <div className="form-group row">
              <div className="col-4">
                  <div className="form-material open">
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>{this.state.customerCompanyName}</p>
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>{this.state.customerAddress}</p>
                  </div>
                </div>
              <div className="col-4">
                  <div className="form-material open">
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "15px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Acknowledgement</p>
                    <p style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "14px",
                        color: "#9f9fb9 !important",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>{this.state.companyName}
                    </p>
                    
                    <p> &nbsp;</p>
                    <p>Receiver's Seal & Sign</p>
                  </div>
                </div>
              <div className="col-4">
                  <div className="form-material open">
                    <span style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Invoice No : {this.state.invoiceNo}</span><br/>
                    <span style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Invoice Date : {this.state.invoiceDate}</span><br/>
                    <span style={{
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: "#333333",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>Invoice Amount : {this.state.totalAmount}</span>  <br/>
                  </div>
                </div>
            </div>
            )}
            
            {/* {_businessSetting.PrintAcknowledgement == 1 && (
              <div>
                <span
                  style={{
                    textAlign: "center",
                    fontFamily: "Arial",
                    fontSize: "15px",
                    color: "#333333",
                    lineHeight: "24px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Acknowledgement
                </span>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {this.state.companyName}
                </p>
                <p> &nbsp;</p>
                <p>Receiver's Seal & Sign</p>
              </div>
            )} */}
            
            
           
            {/* <div style={footer}>
              <p style={{float:'left'}}>https://vyavsay.in</p>
              <img src={_imageLogoBaseDecode} style={{float:'right',width: '105px'}}/>
            </div> */}
          </div>
        </html>
      );
    }
  }

  closeWindow() {
    window.close();
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
      this.convertImageUrlToBase64(_companyLogo, function (url) {});
      this.convertMainImageUrlToBase64(_mainLogoBaseDecode, function (url) {});
    }

    return (
      <React.Fragment>
        {/* <a className="dropdown-item" href="javascript:void(0)" data-id={this.props.transactionId} onClick={this.clickTogenratePDF.bind(this,this.props.transactionId)}>
                    Download PDF {this.props.transactionId}
                </a> */}

        {/* <div className="content">
                    CLick Pdf Button
                    <QuickPDF />
                </div> */}

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_PDF}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          id="modalAdjustPDFDownload"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Order Preview</h3>
                  <div className="block-options">
                    {/* <button type="button" className="btn-block-option" onClick={this.closeWindow.bind()}>
                                            <i className="si si-close"></i>
                                        </button> */}
                  </div>
                </div>
                <div
                  className="block-content"
                  style={{
                    background: "#fff",
                    border: "2px solid #cacaca",
                    height: "97%",
                    padding: "10px",
                  }}
                >
                  <div
                    className="block-content"
                    id="pdfFormatReceipt"
                    ref={(el) => (this.componentRef = el)}
                  >
                    {this.printDemo()}
                    {this.printPdfArea()}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" >Close</button> */}

                <ReactHTMLTableToExcel
                  className="btn btn-alt-secondary"
                  table="exportxls"
                  filename="exportxls"
                  sheet="sheet"
                  buttonText="Download as XLS"
                />

                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.clickToGenratePDF.bind(
                    this,
                    this.props.transactionId
                  )}
                >
                  <i className="fa fa-file-pdf-o"></i> Download PDF
                </button>

                <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn btn-alt-secondary">
                      <i className="fa fa-print"></i> View Print
                    </button>
                  )}
                  content={() => this.componentRef}
                />

                <a
                  type="button"
                  className="btn btn-alt-danger"
                  href={this.state.returnUrl}
                >
                  <i className="fa fa-times"></i>Close
                </a>
              </div>
              <div id="global_loader" className={_loader}>
                <div className="gl-ldr-cld">
                  <div className="gl-ldr-ctr">
                    <div className="gl-loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}
      </React.Fragment>
    );
  }
}
