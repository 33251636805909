import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";

import Modal from "react-modal";

let _activeCategoryS = localStorage.getItem("_activeCategoryS");
let _signingId = "0";
let _businessId = "0";
let company_id = "0";
let _Tran_BillingNameParty = "1";

global.BACK_URL = "Unit";
let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Category Name21",
    field: "category_name",
    width: 150,
  },
  {
    label: "Billing Name",
    field: "billing_name",
    width: 150,
  },
  {
    label: "Total Amount",
    field: "total_amount",
    width: 150,
  },
  {
    label: "Action",
    field: "action",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class companyListPanel extends Component {
  constructor(props) {
    super(props);
    
    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      company_id = _UserSession.company_id;
      let _businessSetting = _UserSession.businessSetting;
      _Tran_BillingNameParty = _businessSetting.Tran_BillingNameParty;
    }
    this.state = {
      buttonTitle: "Add New",
      isLoaded: true,
      unit_name: null,
      slug: null,
      businessId: _businessId,
      userId: _signingId,
      company_id: company_id,
      otherIncomeTransData: [],
      unitId: 0,
      errors: {
        unit_name: "",
        slug: "",
      },
    };
  }

  async componentDidMount() {
    let _otherIncomeTransData = [];
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });
    PostData(global.otherIncomeTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _otherIncomeTransData = responseJson.response;
          }
          this.setState({
            otherIncomeTransData: _otherIncomeTransData,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  deleteUnit(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              unit_id: value,
            });
            PostData(global.userUnitDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getGstListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  loadOrderListing() {
    if (this.state.isLoaded == true) {
      let _otherIncomeTransDetails = this.state.otherIncomeTransData;
      let dataList = [];
      let rows = [];

      if (_otherIncomeTransDetails.length > 0) {
        _otherIncomeTransDetails.map((compDatas, i) => {
          const row = compDatas;
          let _action = "N/A";
          _action = (
            <div className="btn-group" role="group">
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                <Link
                  className="dropdown-item"
                  href={
                    global.webUrl +
                    "business/create-other-income/?auth=" +
                    row.id
                  }
                >
                  <i className="si si-pencil mr-5 text-info"></i>Edit Details
                </Link>

                <Link
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={this.deleteUnit.bind(this, row.id)}
                >
                  <i className="si si-trash mr-5 text-danger"></i>Delete Details
                </Link>

                <Link
                  className="dropdown-item"
                  to={"/business/download-oi-pdf/?auth=" + row.id}
                  target="_blank"
                >
                  Download PDF
                </Link>
                <Link
                  className="dropdown-item"
                  to={"/business/download-oi-pdf/?auth=" + row.id}
                  target="_blank"
                >
                  Preview
                </Link>
                <Link
                  className="dropdown-item"
                  to={"/business/download-oi-pdf/?auth=" + row.id}
                  target="_blank"
                >
                  Print
                </Link>
              </div>
            </div>
          );

          rows.push({
            srNo: row.srNo,
            category_name: _activeCategoryS,
            total_amount: numberFormat(row.total_amount),
            billing_name: row.billing_name,
            action: _action,
          });
        });
      }

      if (rows) {
        dataList = {
          columns: columns,
          rows: rows,
        };
      }
      return dataList;
    }
  }

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_ActiveUnitConversion: false });
    this.getGstListData();
  };
  /* End Here */
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Other Income List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "administrator/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Manage Other Income List
            </span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Other Income Transactions</h3>
              {/* <a className="btn btn-primary" href="javascript:void(0)" style={{margin: '0px'}}> Add Other Income </a> */}

              <Link
                className="btn btn-primary"
                to={"/business/create-other-income/"}
              >
                {" "}
                Add Other Income{" "}
              </Link>
            </div>
            <div className="block-content">
              <MDBDataTableV5
                hover
                entriesOptions={[25, 50, 100]}
                entries={25}
                pagesAmount={4}
                span
                pagingTop
                searchTop
                searchBottom={false}
                barReverse
                data={this.loadOrderListing()}
              />
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
