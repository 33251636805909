import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import QuickAddUniConversion from "./quickUnitConversion";
import QuickAddUnitData from "../unit/quickAddUnit";
import QuickAddNewItemCatDetail from "./quickItemCategory";
import QuickAddSerialNo from "./quickAddSerialNo";
import QuickAddBatchNo from "./quickAddBatchNo";
import Modal from "react-modal";
import "react-confirm-alert/src/react-confirm-alert.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];
let _classValue = "";
let _businessSetting = {};
let HSN_SAC_CODE = 0;
let _AdditionalCESS = 0;
let _ItemCategory = 0;
let _Description = 0;
let _countryCode = "IN";
let _disabledOpeningStock = "";
let _readonly = "";
let _loader = "glb-ldr-prt active";

export default class QuickAddItem extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    if (_UserSession!=null && _UserSession!="") {
      let _companySession = localStorage.getItem("vs_UserCompanySession");
      if (_companySession!=undefined || _companySession!=null) {
        _companySession = JSON.parse(_companySession);
        _businessId = _companySession.business_id;
        _company_id = _companySession.company_id;
        country_code = _companySession.country_code;
        tax_name = _companySession.tax_name;
        gstTaxDetails = _companySession.taxDetails;

        if (_UserSession!=null && _UserSession!="") {
          if (_UserSession.loginType!="Admin") {
            if (_UserSession!=undefined || _UserSession!=null) {
              _businessSetting = _UserSession.businessSetting;
              HSN_SAC_CODE = _businessSetting.HSN_SAC_CODE;
              _AdditionalCESS = _businessSetting.AdditionalCESS;
              _ItemCategory = _businessSetting.ItemCategory;
              _Description = _businessSetting.Description;
              if (_businessSetting.ItemsUnit == 0) {
                _classValue = "iti__hide";
              }
            }
          }
        }
      }
    }
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-items/",
      isPageRedirect: this.props.getPageRedirect
        ? this.props.getPageRedirect
        : "business/manage-items/",
      buttonTitle: "Add New",
      isLoaded: true,
      modalIsOpen_ActiveUnitConversion: false,
      modalIsOpen_AddUnitSlug: false,
      modalIsOpen_ItemCat: false,
      modalIsOpen_SerialNo: false,
      modalIsOpen_BatchNo: false,
      imagePreviewUrl: [],
      imgCollection: [],
      imgCollectionOld: [],
      taxRateArray: gstTaxDetails,
      unitArray: [],
      secUnitArray: [],
      itemCategoryArray: [],
      baseUnitName: "",
      secUnitName: "",
      stl: "",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      name: "",
      selBaseUnitId: "0",
      selSecUnitd: "0",
      unit_conversion_rates: "",
      hsn_sac: "",
      code: "",
      category: "1",
      sale_price: 0,
      sale_tax_type: "With Tax",
      purchase_price: 0,
      purchase_tax_type: "With Tax",
      opening_stock: 0,
      price_per_unit: "",
      //date :  moment().format('MMMM D, YYYY'),
      min_stock_quantity: "",
      item_location: "",
      taxRateId: "0",
      taxRate_id: "0",
      date: moment().format("MM/DD/YYYY"),

      discount_type: "Percentage",
      discount_in_sale_price: "0",
      classValue: _classValue,
      Additional_CESS: "",
      item_description: "",
      btnBatchNo: "iti__hide",
      btnSerialNo: "iti__hide",
      checkBatchRadioBtn: "",
      checkSrRadioBtn: "",
      checkSrRadioNormal: "checked",

      /*Setting Data */
      HSNSACCODE: HSN_SAC_CODE,
      AdditionalCESS: _AdditionalCESS,
      ItemCategory: _ItemCategory,
      Description: _Description,
      itemSerialNo: [],
      itemBatchNo: [],
      itemId: 0,
      itemType: 0,
      normal_remaining_quantity: 0,
      isTransaction: false,
      item_type: 0,
      onEditQtyNormal: 0,
      errors: {
        name: "",
        selBaseUnitId: "",
        selSecUnitd: "",
        unit_conversion_rates: "",
        hsn_sac: "",
        code: "",
        category: "",
        sale_price: "",
        purchase_price: "",
        purchase_tax_type: "",
        opening_stock: 0,
        price_per_unit: "",
        date: "",
        min_stock_quantity: "",
        item_location: "",
        sale_tax_type: "",
        taxRateId: "",
        discount_type: "",
        discount_in_sale_price: "",
        Additional_CESS: "",
        item_description: "",
        stockChange: "",
        item_type: 0,
      },
    };
  }

  async componentDidMount() {
    let _itemId = this.props.itemId ? this.props.itemId : 0;
    if (_itemId > 0) {
      this.getItemDetail(_itemId);
    }
    this.setState({ date: moment().format("MMMM D, YYYY") });
    /*For master Units*/
    let _urlDataUnits =
      global.userUnitList +
      "?businessId=" +
      _businessId +
      "&company_id=" +
      this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    this.setState({ unitArray: _unitArray });

    /* Get Url Pass Parameter */

    this.getItemCategoryListData();
  }

  getItemDetail(_itemId) {
    if (_itemId > 0) {
      let _itemArray = [];
      let _url_GetData = global.userItemDetails + "?itemId=" + _itemId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          //console.log('responseJson', JSON.stringify(responseJson))
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _sedSecUnitArray = [];
              let _baseUnitName,
                _secUnitName = "";

              _itemArray = responseJson.response[0];
              let _btnSerialNo = "";
              let _btnBatchNo = "";
              let _itemsSerialData = _itemArray.itemSerialNo;
              let _itemsBatchData = _itemArray.itemBatchNo;

              if (_itemsSerialData.length > 0) {
                _btnSerialNo = "";
              }
              if (_itemsBatchData.length > 0) {
                _btnBatchNo = "";
              }
              this.state.unitArray.map((item, index) => {
                if (item.unit_id!=_itemArray.base_unit_id) {
                  _sedSecUnitArray.push(item);
                }

                if (item.unit_id == _itemArray.base_unit_id) {
                  _baseUnitName = item.unit_name;
                }
                if (item.unit_id == _itemArray.secound_unit_id) {
                  _secUnitName = item.unit_name;
                }
              });
              let _checkSrRadioBtn = "";
              let _checkBatchRadioBtn = "";
              let _checkSrRadioNormal = "";
              if (_itemArray.itemType == 1) {
                _checkSrRadioBtn = "checked";
              }
              if (_itemArray.itemType == 2) {
                _checkBatchRadioBtn = "checked";
              }
              if (_itemArray.itemType == 0) {
                _checkSrRadioNormal = "checked";
              }
              let _item_descriptionAK = _itemArray.item_description;
              if (
                _itemArray.item_description == "" ||
                _itemArray.item_description == null ||
                _itemArray.item_description == "null"
              ) {
                _item_descriptionAK = "";
              }
              //console.log(_itemsSerialData.length+'===LENGTH===')
              this.setState({
                buttonTitle: "Edit",
                name: _itemArray.name,
                businessId: _itemArray.business_id,
                userId: _itemArray.user_id,
                itemId: _itemId,
                hsn_sac: _itemArray.hsn_sac,
                code: _itemArray.code,
                category: _itemArray.category,
                sale_price: _itemArray.sale_price,
                sale_tax_type: _itemArray.sale_tax_type,
                purchase_price: _itemArray.purchase_price,
                purchase_tax_type: _itemArray.purchase_tax_type,
                tax_rate_name: _itemArray.tax_rate_name,
                opening_stock: _itemArray.opening_stock,
                price_per_unit: _itemArray.price_per_unit,
                //date:_itemArray.date,
                date: moment(_itemArray.date).format("MM/DD/YYYY"),
                min_stock_quantity: _itemArray.min_stock_quantity,
                item_location: _itemArray.item_location,
                taxRate_id: _itemArray.tax_rate_id,
                taxRateId:
                  _itemArray.tax_rate_id +
                  "##" +
                  _itemArray.tax_rate_name +
                  "##" +
                  _itemArray.tax_rate_ratio,
                unit_conversion_rates: _itemArray.unit_conversion_rates,
                selBaseUnitId: _itemArray.base_unit_id,
                selSecUnitd: _itemArray.secound_unit_id,
                imgCollectionOld: _itemArray.images,
                isLoaded: true,
                secUnitArray: _sedSecUnitArray,
                baseUnitName: _baseUnitName,
                secUnitName: _secUnitName,
                discount_type: _itemArray.discount_type,
                discount_in_sale_price: _itemArray.discount_in_sale_price,
                Additional_CESS: _itemArray.Additional_CESS,
                item_description: _item_descriptionAK,
                itemSerialNo: _itemsSerialData,
                itemBatchNo: _itemsBatchData,
                btnSerialNo: _btnSerialNo,
                btnBatchNo: _btnBatchNo,
                checkSrRadioBtn: _checkSrRadioBtn,
                checkBatchRadioBtn: _checkBatchRadioBtn,
                itemType: _itemArray.itemType,
                checkSrRadioNormal: _checkSrRadioNormal,
                normal_remaining_quantity: _itemArray.normal_remaining_quantity,
                isTransaction: _itemArray.isTransaction,
                item_type: _itemArray.item_type,
              });

              if (_itemId > 0) {
                let _opening_stock = this.state.opening_stock;
                if (this.state.itemType == 0 || this.state.itemType == "0") {
                  this.setState({
                    //opening_stock: _itemArray.normal_remaining_quantity, //issue fix for 2249
                  });
                } else if (
                  this.state.itemType == 1 ||
                  this.state.itemType == "1"
                ) {
                  //let _itemSerialNo =  this.state.itemSerialNo.filter((data) => data.isSoldOut!=true);
                  let _itemSerialNo = this.state.itemSerialNo;
                  this.setState({ opening_stock: _itemSerialNo.length });
                } else if (
                  this.state.itemType == 2 ||
                  this.state.itemType == "2"
                ) {
                  let _used_stock = 0;
                  this.state.itemBatchNo.map((batchD, index) => {
                    _used_stock =
                      parseFloat(_used_stock) +
                      parseFloat(batchD.opening_stock);
                  });
                  _opening_stock = _used_stock;
                  this.setState({ opening_stock: _used_stock });
                }
                //console.log("--here--final--",this.state.opening_stock);
                this.isDateRefresh();
              }
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  getItemCategoryListData() {
    let _itemsCatArray = [];

    const requestData = JSON.stringify({
      businessId: _businessId,
    });
    PostData(global.itemCategoryList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsCatArray = responseJson.response;
          }
          this.setState({ itemCategoryArray: _itemsCatArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  async getGstListData(_businessId) {
    let _urlDataUnits =
      global.userUnitList +
      "?businessId=" +
      _businessId +
      "&company_id=" +
      this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    let _unitArray = resUnits.response;
    this.setState({ unitArray: _unitArray });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name =
          value.length < 3 ? "*Item Name must be 3 characters long!" : "";
        errors.stockChange = "";
        this.setState({ name: value });
        break;
      case "selBaseUnitId":
        errors.selBaseUnitId = value < 0 ? "* Select any base unit" : "";
        if (value == "AddUnit") {
          this.openModalHandler("modalAddUnitSlug");
        }
        let filteredArray = this.state.unitArray.filter(function (uDetail) {
          return uDetail.unit_id!=value;
        });

        let _baseUnitName = "";
        this.state.unitArray.map((unitD, index) => {
          if (unitD.unit_id == value) {
            _baseUnitName = unitD.unit_name;
          }
        });
        this.setState({
          selBaseUnitId: value,
          secUnitArray: filteredArray,
          baseUnitName: _baseUnitName,
          secUnitName: "",
          selSecUnitd: 0,
        });

        this.getUnitConversionData(this.state.selSecUnitd, value);

        break;

      case "selSecUnitd":
        errors.selSecUnitd = value < 1 ? "* Select any secondary unit" : "";
        let _secUnitName = "";
        this.state.unitArray.map((unitD, index) => {
          if (unitD.unit_id == value) {
            _secUnitName = unitD.unit_name;
          }
        });
        this.setState({ selSecUnitd: value, secUnitName: _secUnitName });
        this.getUnitConversionData(value, this.state.selBaseUnitId);
        break;

      case "hsn_sac":
        errors.hsn_sac =
          value.length < 3 ? "*Item HSN SAC must be 3 characters long!" : "";

        if (validator.isInt(value)) {
          errors.hsn_sac = "";
        } else {
          errors.hsn_sac = "*Please enter only numeric value.";
        }
        this.setState({ hsn_sac: value });
        break;

      case "code":
        errors.code =
          value.length < 3
            ? "*Item Code/Barcode must be 3 characters long!"
            : "";
        this.setState({ code: value });
        break;

      case "sale_price":
        if (validator.isFloat(value)) {
          errors.sale_price = "";
        } else {
          errors.sale_price = "*Sale price must be 1!";
        }
        this.setState({ sale_price: value });
        break;

      case "purchase_price":
        if (validator.isFloat(value)) {
          errors.purchase_price = "";
        } else {
          errors.purchase_price = "*Purchase price must be 1!";
        }
        this.setState({ purchase_price: value });
        break;

      case "Additional_CESS":
        if (validator.isFloat(value)) {
          errors.Additional_CESS = "";
        } else {
          errors.Additional_CESS = "*Enter numeric value only!";
        }
        this.setState({ Additional_CESS: value });
        break;

      case "unit_conversion_rates":
        if (validator.isInt(value)) {
          errors.unit_conversion_rates = "";
          if (value == 0) {
            errors.unit_conversion_rates = "*Conversion rate can not be 0!";
          }
        } else {
          if (value == 0) {
            errors.unit_conversion_rates = "*Conversion rate can not be 0!";
          }
          errors.unit_conversion_rates = "*Unit conversion rate must be 1!";
        }

        this.setState({ unit_conversion_rates: value });
        break;

      case "opening_stock":
        //errors.opening_stock = value.length < 1 ? '*Opening stock must be 1!' : '';

        if (validator.isInt(value)) {
          errors.opening_stock = "";
        } else {
          errors.opening_stock = "*Opening stock must be 1!";
        }
        this.setState({ opening_stock: value, onEditQtyNormal: 1 });

        if (parseFloat(value) > parseFloat(this.state.min_stock_quantity)) {
          errors.min_stock_quantity = "";
          //this.setState({ min_stock_quantity: 0 });
        } else {
          this.setState({ min_stock_quantity: 0 });
        }
        break;

      // case 'price_per_unit':
      //     if(validator.isFloat(value)){
      //         errors.price_per_unit = '';
      //     } else {
      //         errors.price_per_unit = '*Price per unit must be 1!';
      //     }
      //     this.setState({price_per_unit:value});
      // break;

      case "min_stock_quantity":
        if (validator.isInt(value)) {
          errors.min_stock_quantity = "";
        } else {
          errors.min_stock_quantity = "*Minimum stock quantity atleast 1!";
        }

        if (parseFloat(value) > parseFloat(this.state.opening_stock)) {
          errors.min_stock_quantity =
            "*Minimum quantity cannot be greater than in-stock quantity!";
        }
        this.setState({ min_stock_quantity: value });
        break;

      case "sale_tax_type":
        errors.sale_tax_type =
          value.length < 3 ? "*Sale tax type 3 characters long!" : "";
        this.setState({ sale_tax_type: value });
        break;

      case "taxRateId":
        errors.taxRateId = value.length < 0 ? "*Select tax rate" : "";
        this.setState({ taxRateId: value });
        break;

      case "category":
        errors.category = value.length < 0 ? "*Please Select Category" : "";
        if (value == "AddNew") {
          this.openModalHandler("modalAdjustItemCat");
        }
        this.setState({ category: value });
        break;

      case "item_description":
        errors.item_description =
          value.length < 3 ? "*Description must be 3 characters long!" : "";
        this.setState({ item_description: value });
        break;
      case "purchase_tax_type":
        errors.purchase_tax_type =
          value.length < 0 ? "*Please Select Purchase Tax Type" : "";
        this.setState({ purchase_tax_type: value });
        break;

      case "item_location":
        errors.item_location = value.length < 1 ? "*Enter item locarion" : "";
        this.setState({ item_location: value });
        break;

      case "discount_type":
        errors.discount_type = value.length < 3 ? "*Select discount type!" : "";
        this.setState({ discount_type: value });
        break;

      case "discount_in_sale_price":
        if (validator.isInt(value)) {
          errors.discount_in_sale_price = "";
        } else {
          errors.discount_in_sale_price =
            "*Minimum discount on sale value atleast 1!";
        }
        if (this.state.discount_type == "Percentage") {
          if (value > 100) {
            errors.discount_in_sale_price =
              "*Discount can not be more than 100 %";
          } else {
            if (validator.isInt(value)) {
              errors.discount_in_sale_price = "";
            } else {
              errors.discount_in_sale_price =
                "*Minimum discount on sale value atleast 0 or greater than 0!";
              this.setState({ discount_in_sale_price: 0 });
            }
          }
        } else {
          if (parseFloat(value) > parseFloat(this.state.sale_price)) {
            errors.discount_in_sale_price =
              "*Discount can not be more than 100 %";
          } else {
            if (validator.isInt(value)) {
              errors.discount_in_sale_price = "";
            } else {
              errors.discount_in_sale_price =
                "*Minimum discount on sale value atleast 0 or greater than 0!";
              this.setState({ discount_in_sale_price: 0 });
            }
          }
        }
        let _discountValue = value;
        if (_discountValue == "") {
          _discountValue = 0;
          errors.discount_in_sale_price = "";
        }
        this.setState({ discount_in_sale_price: _discountValue });
        break;

      default:
        break;
    }
  };

  clickToAddItem = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.name == "") {
      _isError = true;
      errors["name"] = "*Please Enter Item Name";
      this.setState({ errors: errors });
    }

    if (this.state.code == "") {
      _isError = true;
      errors["code"] = "*Please Enter Item Code/Barcode";
      this.setState({ errors: errors });
    }
    if (this.state.item_description == "" && this.state.Description == 1) {
      _isError = true;
      errors["item_description"] = "*Please Enter description";
      this.setState({ errors: errors });
    }

    if (this.state.category == "") {
      _isError = true;
      errors["category"] = "*Please Select Category";
      this.setState({ errors: errors });
    }
    if (this.state.sale_price == "") {
      _isError = true;
      errors["sale_price"] = "*Please Enter Sale Price";
      this.setState({ errors: errors, sale_price: 0 });
    }
    if (this.state.sale_tax_type == "") {
      _isError = true;
      errors["sale_tax_type"] = "*Please Select Sale Tax Type";
      this.setState({ errors: errors });
    }
    if (this.state.purchase_price == "") {
      _isError = true;
      errors["purchase_price"] = "*Please Enter Purchase Price";
      this.setState({ errors: errors, purchase_price: 0 });
    }
    /*if(this.state.Additional_CESS == '' && this.state.AdditionalCESS == 1)
        {
            _isError = true;
            errors['Additional_CESS'] = "*Please Enter Additional CESS";
            this.setState({errors: errors});
        }*/
    if (this.state.purchase_tax_type == "") {
      _isError = true;
      errors["purchase_tax_type"] = "*Please Select Purchase Tax Type";
      this.setState({ errors: errors });
    }

    if (this.state.opening_stock < 0) {
      _isError = true;
      errors["opening_stock"] = "*Please Enter Opening Stock";
      this.setState({ errors: errors });
    }
    /*if(this.state.price_per_unit == '')
        {
            _isError = true;
            errors['price_per_unit'] = "*Please Enter Price Per Unit";
            this.setState({errors: errors});
        }*/
    if (this.state.date == "") {
      _isError = true;
      errors["date"] = "*Please Enter Date";
      this.setState({ errors: errors });
    }
    if (this.state.min_stock_quantity == "") {
      _isError = true;
      errors["min_stock_quantity"] = "*Please Enter Minimum Stock Quantity";
      this.setState({ errors: errors });
      return false;
    }
    if (parseInt(this.state.min_stock_quantity) > this.state.opening_stock ) {
      _isError = true;
      errors["min_stock_quantity"] = "Minimum stock cannot be more than opening stock";
      this.setState({ errors: errors });
      return false;
    }
    if (this.state.item_location == "") {
      _isError = true;
      errors["item_location"] = "*Please Enter Item Location";
      this.setState({ errors: errors });
    }
    if (this.state.taxRateId == "") {
      _isError = true;
      errors["taxRateId"] = "*Please Select Any Tax Rate.";
      this.setState({ errors: errors });
    }

    if (this.state.discount_type == "") {
      _isError = true;
      errors["discount_type"] = "*Please Select Any Discount Value.";
      this.setState({ errors: errors });
    }

    if (
      this.state.discount_in_sale_price > 0 ||
      this.state.discount_in_sale_price == ""
    ) {
      if (this.state.discount_in_sale_price == "") {
        _isError = true;
        errors["discount_in_sale_price"] =
          "*Discount value enter minimum 0 or 1";
      } else if (this.state.discount_type == "Percentage") {
        if (this.state.discount_in_sale_price > 100) {
          _isError = true;
          errors["discount_in_sale_price"] =
            "*Discount can not be more than 100 %";
        } else {
          _isError = false;
          errors["discount_in_sale_price"] = "";
        }
      } else {
        if (
          parseFloat(this.state.discount_in_sale_price) >
          parseFloat(this.state.sale_price)
        ) {
          _isError = true;
          errors["discount_in_sale_price"] =
            "*Discount can not be more than 100 %";
        } else {
          _isError = false;
          errors["discount_in_sale_price"] = "";
        }
      }
      this.setState({ errors: errors });
    }

    let _itemSerialNo = JSON.stringify(this.state.itemSerialNo);
    let _itemBatchNo = JSON.stringify(this.state.itemBatchNo);
    /*if(_businessSetting.SerialNoChk == 1 && _businessSetting.BatchNoChk == 1){
            if(_itemSerialNo.length <= 2 && _itemBatchNo.length  <= 2){
                _isError = true;
                errors['stockChange'] = "*Please check serial or batch no";
            }else{
                _isError = false;
                errors['stockChange']  = '';
            }
            this.setState({errors: errors});
        }
        if(_businessSetting.SerialNoChk == 1 && _businessSetting.BatchNoChk == 0){

            if(_itemSerialNo.length <= 2){
                _isError = true;
                errors['stockChange'] = "*Please check serial no";
            }else{
                _isError = false;
                errors['stockChange']  = '';
            }
            this.setState({errors: errors});
        }
        if(_businessSetting.SerialNoChk == 0 && _businessSetting.BatchNoChk == 1){
            if(_itemBatchNo.length <= 2){
                _isError = true;
                errors['stockChange'] = "*Please check batch no";
            }else{
                _isError = false;
                errors['stockChange']  = '';
            }
            this.setState({errors: errors});
        }*/
    if (_isError == false) {
      if (this.props.itemId > 0) {
        this.editItemMethodCall();
      } else {
        this.addItemMethodCall();
      }
    }
  };

  stockChangeBtn = (e) => {
    let activeValue = e.target.checked;
    let _key = e.target.attributes["data-stock"].value;
    _key = parseInt(_key);
    let _opening_stock = 0;
    let errors = this.state.errors;

    if (this.state.name == "") {
      errors["stockChange"] = "Please enter item name first";
      this.setState({
        checkBatchRadioBtn: "",
        checkSrRadioBtn: "",
        checkSrRadioNormal: "",
        errors: errors,
      });
    } else {
      let _isError = false;
      errors["stockChange"] = "";

      // if (this.state.itemId > 0) {
      //   if (this.state.itemSerialNo!="" && _key == 0) {
      //     _opening_stock = this.state.normal_remaining_quantity;
      //     _disabledOpeningStock = "";
      //   }
      //   if (this.state.itemSerialNo!="" && _key == 1) {
      //     //let _itemSerialNo =  this.state.itemSerialNo.filter((data) => data.isSoldOut!=true);
      //     let _itemSerialNo = this.state.itemSerialNo;
      //     _opening_stock = _itemSerialNo.length;
      //     _disabledOpeningStock = "disabled";
      //   } else if (this.state.itemBatchNo!="" && _key == 2) {
      //     let _used_stock = 0;
      //     this.state.itemBatchNo.map((batchD, index) => {
      //       _used_stock =
      //         parseFloat(_used_stock) + parseFloat(batchD.opening_stock);
      //     });
      //     _opening_stock = _used_stock;
      //     _disabledOpeningStock = "disabled";
      //   }
      // }
      console.log('this.state.normal_remaining_quantity', this.state.normal_remaining_quantity)
      if (this.state.itemId > 0) {
        if (_key == 0) {
          _opening_stock = this.state.normal_remaining_quantity;
          _disabledOpeningStock = "";
        }else if (this.state.itemSerialNo!="" && _key == 1) {
          //let _itemSerialNo =  this.state.itemSerialNo.filter((data) => data.isSoldOut!=true);
          let _itemSerialNo = this.state.itemSerialNo;
          _opening_stock = _itemSerialNo.length;
          _disabledOpeningStock = "disabled";
        } else if (this.state.itemBatchNo!="" && _key == 2) {
          let _used_stock = 0;
          this.state.itemBatchNo.map((batchD, index) => {
            _used_stock =
                parseFloat(_used_stock) + parseFloat(batchD.opening_stock);
          });
          _opening_stock = _used_stock;
          _disabledOpeningStock = "disabled";
        }
      }
      let _itemSerialNo = this.state.itemSerialNo;
      let _itemBatchNo = this.state.itemBatchNo;
      if (this.state.itemId == 0) {
        if (_key == 1) {
          _itemSerialNo = [];
          _itemBatchNo = [];
        } else if (_key == 2) {
          _itemSerialNo = [];
          _itemBatchNo = [];
        } else {
          _itemSerialNo = [];
          _itemBatchNo = [];
        }
      }
      
      if (_key == 1) {
        this.setState({
          btnSerialNo: "",
          btnBatchNo: "iti__hide",
          checkSrRadioBtn: "checked",
          checkBatchRadioBtn: "",
          checkSrRadioNormal: "",
          itemType: _key,
          opening_stock: _itemSerialNo.length,
          stl: "readonly",
          errors: errors,
          itemSerialNo: _itemSerialNo,
          itemBatchNo: _itemBatchNo,
        });
        _disabledOpeningStock = "disabled";
      } else if (_key == 2) {
        errors["opening_stock"] = "";
        this.setState({
          btnSerialNo: "iti__hide",
          btnBatchNo: "",
          checkBatchRadioBtn: "checked",
          checkSrRadioBtn: "",
          checkSrRadioNormal: "",
          itemType: _key,
          opening_stock: _opening_stock,
          stl: "readonly",
          errors: errors,
          itemSerialNo: _itemSerialNo,
          itemBatchNo: _itemBatchNo,
        });
        _disabledOpeningStock = "disabled";
      } else {
        this.setState({
          btnSerialNo: "",
          btnBatchNo: "",
          checkBatchRadioBtn: "",
          checkSrRadioBtn: "",
          checkSrRadioNormal: "checked",
          itemType: 0,
          opening_stock: _opening_stock,
          stl: "",
          errors: errors,
          itemSerialNo: _itemSerialNo,
          itemBatchNo: _itemBatchNo,
        });
        _disabledOpeningStock = "";
      }

      if (_opening_stock!="" && this.state.min_stock_quantity!="") {
        if (parseFloat(_opening_stock) > parseFloat(this.state.min_stock_quantity)) {
          errors.min_stock_quantity = "";
          //this.setState({ min_stock_quantity: 0 });
        } else {
          if(this.state.itemId == 0){
            errors.min_stock_quantity = "*Minimum quantity cannot be greater than in-stock quantity!";
          }
        }
      }
    }
  };

  addItemMethodCall() {
    let fullTaxRateD = this.state.taxRateId;
    let mainData = fullTaxRateD.split("##");

    let _rateTax = {};
    _rateTax.id = mainData[0];
    _rateTax.name = mainData[1] + mainData[2];
    _rateTax.ratio = mainData[2];

    let _itemSerialNo = JSON.stringify(this.state.itemSerialNo);
    let _itemBatchNo = JSON.stringify(this.state.itemBatchNo);
    // console.log("Batch Data Tabish", _itemBatchNo);
    // return false;
    var itemData = new FormData();
    itemData.append("businessId", this.state.businessId);
    itemData.append("userId", this.state.userId);
    itemData.append("company_id", this.state.company_id);
    itemData.append("name", this.state.name);
    itemData.append("base_unit_id", this.state.selBaseUnitId);
    itemData.append("second_unit_id", this.state.selSecUnitd);
    itemData.append("unit_conversion_rates", this.state.unit_conversion_rates);
    itemData.append("code", this.state.code);
    itemData.append("hsn_sac", this.state.hsn_sac);
    itemData.append("sale_price", this.state.sale_price);
    itemData.append("sale_tax_type", this.state.sale_tax_type);
    itemData.append("purchase_price", this.state.purchase_price);
    itemData.append("Additional_CESS", this.state.Additional_CESS);
    itemData.append("item_description", this.state.item_description);
    itemData.append("purchase_tax_type", this.state.purchase_tax_type);
    itemData.append("rateTax", JSON.stringify(_rateTax));
    itemData.append("opening_stock", this.state.opening_stock);
    itemData.append("min_stock_quantity", this.state.min_stock_quantity);
    itemData.append("price_per_unit", this.state.price_per_unit);
    //itemData.append('date', this.state.date);
    itemData.append("date", moment(this.state.date).format("MMMM D, YYYY"));
    itemData.append("item_location", this.state.item_location);
    itemData.append("discount_type", this.state.discount_type);
    itemData.append(
      "discount_in_sale_price",
      this.state.discount_in_sale_price
    );
    itemData.append("category", this.state.category);
    itemData.append("itemSerialNo", _itemSerialNo);
    itemData.append("itemBatchNo", _itemBatchNo);
    itemData.append("itemType", this.state.itemType);
    for (let _i = 0; _i < this.state.imgCollection.length; _i++) {
      itemData.append("imgCollection", this.state.imgCollection[_i][0]);
    }
    new Promise((resolve, reject) => {
      fetch(global.userAddItem, {
        method: "POST",
        body: itemData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + this.state.isRedirectTo;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editItemMethodCall() {
    let fullTaxRateD = this.state.taxRateId;
    let mainData = fullTaxRateD.split("##");

    let _rateTax = {};
    _rateTax.id = mainData[0];
    _rateTax.name = mainData[1] + mainData[2];
    _rateTax.ratio = mainData[2];

    let _itemSerialNo = this.state.itemSerialNo.filter((data) => data.isSelected == true);
    _itemSerialNo = JSON.stringify(_itemSerialNo);

    let _itemBatchNo = JSON.stringify(this.state.itemBatchNo);
    //console.log("===opening_stock==",this.state.opening_stock);return;
    var itemData = new FormData();
    itemData.append("itemId", this.state.itemId);
    itemData.append("businessId", this.state.businessId);
    itemData.append("userId", this.state.userId);
    itemData.append("company_id", this.state.company_id);
    itemData.append("name", this.state.name);
    itemData.append("base_unit_id", this.state.selBaseUnitId);
    itemData.append("second_unit_id", this.state.selSecUnitd);
    itemData.append("unit_conversion_rates", this.state.unit_conversion_rates);
    itemData.append("code", this.state.code);
    itemData.append("hsn_sac", this.state.hsn_sac);
    itemData.append("sale_price", this.state.sale_price);
    itemData.append("sale_tax_type", this.state.sale_tax_type);
    itemData.append("purchase_price", this.state.purchase_price);
    itemData.append("Additional_CESS", this.state.Additional_CESS);
    itemData.append("item_description", this.state.item_description);
    itemData.append("purchase_tax_type", this.state.purchase_tax_type);
    itemData.append("rateTax", JSON.stringify(_rateTax));
    itemData.append("opening_stock", this.state.opening_stock);
    itemData.append("min_stock_quantity", this.state.min_stock_quantity);
    itemData.append("price_per_unit", this.state.price_per_unit);
    //itemData.append('date', this.state.date);
    itemData.append("date", moment(this.state.date).format("MMMM D, YYYY"));
    itemData.append("item_location", this.state.item_location);
    itemData.append("discount_type", this.state.discount_type);
    itemData.append(
      "discount_in_sale_price",
      this.state.discount_in_sale_price
    );
    itemData.append("category", this.state.category);
    itemData.append("itemSerialNo", _itemSerialNo);
    itemData.append("itemBatchNo", _itemBatchNo);
    itemData.append("itemType", this.state.itemType);
    //itemData.append('imgCollection', this.state.imgCollection);
    //console.log(_itemBatchNo);return false;
    //console.log(moment(this.state.date).format('MMMM D, YYYY'));return false;
    //console.log("==_itemSerialNo==",(_itemSerialNo));return false;
    for (let _i = 0; _i < this.state.imgCollection.length; _i++) {
      itemData.append("imgCollection", this.state.imgCollection[_i][0]);
    }

    new Promise((resolve, reject) => {
      fetch(global.userEditItem, {
        method: "POST",
        body: itemData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + this.state.isRedirectTo;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  onChangeFileUploadHandler = (event) => {
    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(file);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          var newStateArray = this.state.imgCollection.slice();
          newStateArray.push(images);
          this.setState({ imgCollection: newStateArray });
        },
        (error) => {
          console.error(error);
        }
      );
    }

    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          var newStateArray = this.state.imagePreviewUrl.slice();
          newStateArray.push(images);
          this.setState({ imagePreviewUrl: newStateArray });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let _imageDetails = this.state.imagePreviewUrl;
      let dataList = [];
      if (_imageDetails.length > 0) {
        _imageDetails.map((img, i) => {
          dataList.push(
            <span>
              <img
                style={{ width: "100px" }}
                className="preview"
                src={img}
                alt={"image-" + i}
                key={i}
              />
            </span>
          );
        });
      }
      return dataList;
    }
  }

  previewOldImagesListing() {
    if (this.state.isLoaded == true) {
      let _imageDetails = this.state.imgCollectionOld;
      let dataList = [];
      if (_imageDetails.length > 0) {
        _imageDetails.map((img, i) => {
          dataList.push(
            <span>
              <img
                style={{ width: "100px" }}
                className="preview"
                src={img.file_name}
                alt={"image-" + i}
                key={i}
              />
              <i
                onClick={this.deleteItemImage.bind(this, img.id)}
                className="si si-close mr-5"
                id="removeItmImgEvent"
              ></i>
            </span>
          );
        });
      }
      return dataList;
    }
  }

  deleteItemImage = (image_id) => {
    const requestData = JSON.stringify({
      itemImageId: image_id,
      itemId: this.state.itemId,
    });
    PostData(global.userItemImageDelete, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification(
          "Success Message 👍",
          "Item image deleted from system.",
          "success"
        );
        let filteredArray = this.state.imgCollectionOld.filter(
          (item) => item.id!=image_id
        );
        this.setState({ imgCollectionOld: filteredArray });
        this.previewOldImagesListing();
      } else {
        sendNotification(
          "Error Message 😓",
          "Some error occured. Please try again.",
          "danger"
        );
        return;
      }
    });
  };

  handleCallBackDate = (start) => {
    this.setState({ date: start.format("MMMM D, YYYY") });
  };

  isDateRefresh() {
    let _invoiceDate = this.state.date;
    //if(this.state.itemId > 0){
    return (
      <DateRangePicker
        initialSettings={{
          singleDatePicker: true,
          startDate: _invoiceDate,
        }}
        onCallback={this.handleCallBackDate}
      >
        <input type="text" className="form-control" readOnly onWheel={() => document.activeElement.blur()}/>
      </DateRangePicker>
    );
    // }
    // else {
    // }
  }
  isDateRefreshNew() {
    let _invoiceDate = this.state.date;
    return (
      <DateRangePicker
        initialSettings={{
          singleDatePicker: true,
          startDate: _invoiceDate,
        }}
        onCallback={this.handleCallBackDate}
      >
        <input type="text" className="form-control" readOnly onWheel={() => document.activeElement.blur()}/>
      </DateRangePicker>
    );
  }
  getUnitConversionData(secUnit, primaryUnit) {
    const requestData = JSON.stringify({
      secUnit: secUnit,
      primaryUnit: primaryUnit,
      businessId: this.state.businessId,
    });
    PostData(global.userUnitLastConversionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, unit_conversion_rates: 0 });
        } else {
          let _conversionArray = [];
          if (responseJson.response) {
            _conversionArray = responseJson.response[0];

            this.setState({
              unit_conversion_rates: _conversionArray.conversion,
            });
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalUnitConversion") {
      this.setState({
        modalIsOpen_ActiveUnitConversion: true,
        isPageRedirect1: "business/add-item/",
      });
    } else if (popId == "modalAddUnitSlug") {
      this.setState({ modalIsOpen_AddUnitSlug: true });
    } else if (popId == "modalAdjustItemCat") {
      this.setState({ modalIsOpen_ItemCat: true });
    } else if (popId == "modalAddSerialNo") {
      this.setState({ modalIsOpen_SerialNo: true });
    } else if (popId == "modalAddBatchNo") {
      this.setState({ modalIsOpen_BatchNo: true });
    }
  }
  closeModalHandler = (popId) => {
    if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: false });
    } else if (popId == "modalAddUnitSlug") {
      this.setState({ modalIsOpen_AddUnitSlug: false });
    } else if (popId == "modalAdjustItemCat") {
      this.setState({ modalIsOpen_ItemCat: false });
    } else if (popId == "modalAddSerialNo") {
      this.setState({ modalIsOpen_SerialNo: false });
    } else if (popId == "modalAddBatchNo") {
      this.setState({ modalIsOpen_BatchNo: false });
    }
  };

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_ActiveUnitConversion: false });
    this.setState({ modalIsOpen_ItemCat: false });
    this.getItemCategoryListData();
  };
  /* End Here */

  getUpdateUnitResponse = () => {
    this.setState({ modalIsOpen_AddUnitSlug: false });
    this.setState({ modalIsOpen_SerialNo: false });
    this.getGstListData(_businessId);
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _conversationText = "";

    if (this.state.unit_conversion_rates >= 0 && this.state.selSecUnitd > 0) {
      _conversationText =
        "1 " +
        this.state.baseUnitName +
        " = " +
        this.state.unit_conversion_rates +
        " " +
        this.state.secUnitName;
    }
    console.log(this.state.itemType+'==>--zz-->=='+this.state.item_type);
    if ((this.state.itemType === 2 || this.state.itemType === "2")) 
    {
      _disabledOpeningStock = "disabled";
    } else if ((this.state.itemType === 1 || this.state.itemType === "1"))
    {
      _disabledOpeningStock = "disabled";
    }
    else if (
      this.state.itemType === 0 &&
      this.state.itemId > 0 &&
      this.state.isTransaction === true &&
      this.state.item_type === 0
    ) 
    {
      _disabledOpeningStock = "disabled";
    } else {
      _disabledOpeningStock = "";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    let _checkSrRadioNormal = this.state.checkSrRadioNormal;
    if (this.state.itemId > 0) {
      if (
        _businessSetting.SerialNoChk == 0 &&
        _businessSetting.SerialNoAdditionalChk1 == 0 &&
        _businessSetting.SerialNoAdditionalChk2 == 0 &&
        _businessSetting.BatchNoChk == 0
      ) {
        _checkSrRadioNormal = "checked";
        this.state.checkSrRadioNormal = _checkSrRadioNormal;
        if (this.state.onEditQtyNormal!=1) {
          this.state.opening_stock = this.state.normal_remaining_quantity;
        }
      }

      if (
        _businessSetting.SerialNoChk == 0 &&
        _businessSetting.SerialNoAdditionalChk1 == 0 &&
        _businessSetting.SerialNoAdditionalChk2 == 0 &&
        this.state.checkBatchRadioBtn!="checked"
      ) {
        _checkSrRadioNormal = "checked";
        this.state.checkSrRadioNormal = _checkSrRadioNormal;
        if (this.state.onEditQtyNormal!=1) {
          this.state.opening_stock = this.state.normal_remaining_quantity;
        }
        //this.state.opening_stock = this.state.normal_remaining_quantity;
      }
    }

    return (
      <React.Fragment>
        <form method="post" id="form-login" className="itemForm px-30">
          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Item Details{" "}
          </h3>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="name"
                  name="name"
                  value={this.state.name}
                  placeholder="Enter Item Name"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Item Name <span className="text-danger">*</span>{" "}
                </label>
                <span className="iris_error_txt">
                  {errors.name.length > 0 && (
                    <span className="error">{errors.name}</span>
                  )}
                </span>
              </div>
            </div>
            {this.state.HSNSACCODE == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="hsn_sac"
                    name="hsn_sac"
                    value={this.state.hsn_sac}
                    placeholder="Item HSN/SAC"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Item HSN/SAC</label>
                  <span className="iris_error_txt">
                    {errors.hsn_sac.length > 0 && (
                      <span className="error">{errors.hsn_sac}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  name="code"
                  value={this.state.code}
                  placeholder="Enter Item Code/Barcode"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Item Code/Barcode <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.code.length > 0 && (
                    <span className="error">{errors.code}</span>
                  )}
                </span>
              </div>
            </div>
            {/* style={{display:'none'}}*/}
            {this.state.ItemCategory == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="category"
                    name="category"
                    onChange={this.handleChange}
                  >
                    <option value="AddNew">Add new category</option>
                    {this.state.itemCategoryArray.map((itemCat, index) => {
                      let _selCat = "";
                      if (this.state.category == itemCat.id) {
                        _selCat = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={itemCat.id}
                          selected={_selCat}
                        >
                          {itemCat.categoryName}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">Select Category </label>
                </div>
                <div id="category-error" className="animated fadeInDown">
                  {errors.category.length > 0 && (
                    <span className="error">{errors.category}</span>
                  )}
                </div>
              </div>
            )}

            {this.state.Description == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="item_description"
                    name="item_description"
                    value={this.state.item_description}
                    placeholder="Enter description"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">
                    Description <span className="text-danger">*</span>
                  </label>
                  <span className="iris_error_txt">
                    {errors.item_description.length > 0 && (
                      <span className="error">{errors.item_description}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="claerfix"></div>
          <div id="hideShow" className={this.state.classValue}>
            <h3 className="content-heading" style={{ paddingTop: "6px" }}>
              <i className="fa fa-angle-right"></i> Unit Details{" "}
            </h3>
            <div className="form-group row">
              <div className="col-4">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="selBaseUnitId"
                    name="selBaseUnitId"
                    onChange={this.handleChange}
                  >
                    <option value="0" selected>
                      {" "}
                      Select Primary Unit
                    </option>
                    <option value="AddUnit"> Add Unit</option>
                    {this.state.unitArray.map((item, index) => {
                      let _selBase = "";
                      if (this.state.selBaseUnitId == item.unit_id) {
                        _selBase = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={item.unit_id}
                          selected={_selBase}
                        >
                          {item.unit_name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">Select Primary Unit</label>
                </div>
                <div id="selBaseUnitId-error" className="animated fadeInDown">
                  {errors.selBaseUnitId.length > 0 && (
                    <span className="error">{errors.selBaseUnitId}</span>
                  )}
                </div>
              </div>

              <div className="col-4">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="selSecUnitd"
                    name="selSecUnitd"
                    onChange={this.handleChange}
                  >
                    <option value="0" selected>
                      {" "}
                      Select Secondary Unit
                    </option>
                    {this.state.secUnitArray.map((item, index) => {
                      let _selSec = "";
                      if (this.state.selSecUnitd == item.unit_id) {
                        _selSec = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={item.unit_id}
                          selected={_selSec}
                        >
                          {item.unit_name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">Select Secondary Unit</label>
                </div>
                <div id="selSecUnitd-error" className="animated fadeInDown">
                  {errors.selSecUnitd.length > 0 && (
                    <span className="error">{errors.selSecUnitd}</span>
                  )}
                </div>
              </div>

              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="unit_conversion_rates"
                    name="unit_conversion_rates"
                    value={this.state.unit_conversion_rates}
                    placeholder="Enter Unit Conversion Rates"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Unit Conversion Rates</label>
                  <span className="iris_error_txt">
                    {errors.unit_conversion_rates.length > 0 && (
                      <span className="error">
                        {errors.unit_conversion_rates}
                      </span>
                    )}
                  </span>
                </div>
                <span>
                  <strong>{_conversationText}</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Pricing Details{" "}
          </h3>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="sale_price"
                  name="sale_price"
                  value={this.state.sale_price}
                  placeholder="Enter Sale Price"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Sale Price <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.sale_price.length > 0 && (
                    <span className="error">{errors.sale_price}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-2">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="sale_tax_type"
                  name="sale_tax_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Without Tax"
                    selected={
                      this.state.sale_tax_type == "Without Tax"
                        ? "selected"
                        : ""
                    }
                  >
                    Without Tax
                  </option>
                  <option
                    value="With Tax"
                    selected={
                      this.state.sale_tax_type == "With Tax" ? "selected" : ""
                    }
                  >
                    With Tax
                  </option>
                </select>
                <label htmlFor="login-password">Select Tax Type </label>
              </div>
              <div id="sale_tax_type-error" className="animated fadeInDown">
                {errors.sale_tax_type.length > 0 && (
                  <span className="error">{errors.sale_tax_type}</span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="purchase_price"
                  name="purchase_price"
                  value={this.state.purchase_price}
                  placeholder="Enter Purchase Price"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Purchase Price <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.purchase_price.length > 0 && (
                    <span className="error">{errors.purchase_price}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-2">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="purchase_tax_type"
                  name="purchase_tax_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Without Tax"
                    selected={
                      this.state.purchase_tax_type == "Without Tax"
                        ? "selected"
                        : ""
                    }
                  >
                    Without Tax
                  </option>
                  <option
                    value="With Tax"
                    selected={
                      this.state.purchase_tax_type == "With Tax"
                        ? "selected"
                        : ""
                    }
                  >
                    With Tax
                  </option>
                </select>
                <label htmlFor="login-password">Select Tax Type </label>
              </div>
              <div id="purchase_tax_type-error" className="animated fadeInDown">
                {errors.purchase_tax_type.length > 0 && (
                  <span className="error">{errors.purchase_tax_type}</span>
                )}
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="taxRateId"
                  name="taxRateId"
                  onChange={this.handleChange}
                >
                  <option value="0" selected>
                    {" "}
                    Select Tax Rate
                  </option>
                  {this.state.taxRateArray.map((item, index) => {
                    let _sel = "";
                    if (this.state.taxRate_id == item.id) {
                      _sel = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.id + "##" + item.name + "##" + item.ratio}
                        selected={_sel}
                      >
                        {item.name + "@" + item.ratio + "%"}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Tax Rate</label>
              </div>
              <div id="taxRateId-error" className="animated fadeInDown">
                {errors.taxRateId.length > 0 && (
                  <span className="error">{errors.taxRateId}</span>
                )}
              </div>
            </div>
            {this.state.AdditionalCESS == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    min="0"
                    className="form-control"
                    id="Additional_CESS"
                    name="Additional_CESS"
                    value={this.state.Additional_CESS}
                    placeholder="Enter Additional CESS"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Additional CESS</label>
                  <span className="iris_error_txt">
                    {errors.Additional_CESS.length > 0 && (
                      <span className="error">{errors.Additional_CESS}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="radio"
                  id="Normal"
                  name="stockChange"
                  data-stock="0"
                  onClick={this.stockChangeBtn}
                  checked={this.state.checkSrRadioNormal}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Normal</label>
              </div>
            </div>
            {_businessSetting.SerialNoChk > 0 && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="radio"
                    id="SerialNo"
                    name="stockChange"
                    data-stock="1"
                    onClick={this.stockChangeBtn}
                    checked={this.state.checkSrRadioBtn}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Serial No</label>
                </div>
              </div>
            )}
            {_businessSetting.BatchNoChk > 0 && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="radio"
                    id="batchNo"
                    name="stockChange"
                    data-stock="2"
                    onClick={this.stockChangeBtn}
                    checked={this.state.checkBatchRadioBtn}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Batch No</label>
                </div>
              </div>
            )}
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <span className="error">{errors.stockChange}</span>
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-3">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="opening_stock"
                  name="opening_stock"
                  value={this.state.opening_stock}
                  placeholder="Enter Opening Stocks"
                  disabled={_disabledOpeningStock}
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Opening Stock<span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.opening_stock.length > 0 && (
                    <span className="error">{errors.opening_stock}</span>
                  )}
                </span>
              </div>
            </div>
            {this.state.btnSerialNo == "" &&
              this.state.name!="" &&
              this.state.itemType == 1 &&
              _businessSetting.SerialNoChk > 0 && (
                <div className="col-1">
                  <div className="form-material open">
                    <a
                      className="pull-right btn btn-alt-primary"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAddSerialNo",
                        "0"
                      )}
                    >
                      Serial No
                    </a>
                  </div>
                </div>
              )}
            {this.state.btnBatchNo == "" &&
              this.state.name!="" &&
              this.state.itemType == 2 &&
              _businessSetting.BatchNoChk == 1 && (
                <div className="col-1">
                  <div className="form-material open">
                    <a
                      className="pull-right btn btn-alt-primary"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAddBatchNo",
                        "0"
                      )}
                    >
                      Batch No
                    </a>
                  </div>
                </div>
              )}
            {/* <div className="col-4">
                            <div className="form-material open">
                                <input type="text" min="0" className="form-control" id="price_per_unit" name="price_per_unit" value={this.state.price_per_unit} placeholder="Enter At Price/Unit" onChange={this.handleChange}/>
                                <label htmlFor="login-username"> At Price/Unit <span className="text-danger">*</span></label>
                                <span className="iris_error_txt">{errors.price_per_unit.length > 0 && <span className='error'>{errors.price_per_unit}</span>}</span>
                            </div>
                        </div>  */}
            <div className="col-4">
              <div className="form-material open">
                {/* <DateRangePicker
                                    initialSettings={{
                                    singleDatePicker: true,
                                    startDate: this.state.date
                                    }}
                                    onCallback={this.handleCallBackDate}
                                >
                                    <input type="text" className="form-control" readOnly/>
                                </DateRangePicker> */}
                {this.state.itemId > 0 && this.isDateRefresh()}
                {this.state.itemId == 0 && this.isDateRefreshNew()}
                {/* {this.isDateRefresh()} */}
                <label htmlFor="login-username">Date</label>
              </div>
            </div>
          </div>

          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="min_stock_quantity"
                  name="min_stock_quantity"
                  value={this.state.min_stock_quantity}
                  placeholder="Enter Min Stock Qty"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Min Stock Qty <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.min_stock_quantity.length > 0 && (
                    <span className="error">{errors.min_stock_quantity}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  className="form-control"
                  id="item_location"
                  name="item_location"
                  value={this.state.item_location}
                  placeholder="Enter Item Location"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Item Location <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.item_location.length > 0 && (
                    <span className="error">{errors.item_location}</span>
                  )}
                </span>
              </div>
            </div>

            <div className="col-4">
              <div className="form-material">
                <label htmlFor="login-username">Item Image</label>
                <input
                  type="file"
                  onChange={this.onChangeFileUploadHandler}
                  name="item_image"
                  accept="image/*"
                  onWheel={() => document.activeElement.blur()}
                />
              </div>
              <div>
                {this.previewImagesListing()}
                {this.previewOldImagesListing()}
              </div>
            </div>
          </div>

          <div className="claerfix"></div>
          <br />
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="discount_type"
                  name="discount_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Percentage"
                    selected={
                      this.state.discount_type == "Percentage" ? "selected" : ""
                    }
                  >
                    Percentage
                  </option>
                  <option
                    value="Amount"
                    selected={
                      this.state.discount_type == "Amount" ? "selected" : ""
                    }
                  >
                    Amount
                  </option>
                </select>
                <label htmlFor="login-username">Discount Type</label>
                <span className="iris_error_txt">
                  {errors.discount_type.length > 0 && (
                    <span className="error">{errors.discount_type}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="discount_in_sale_price"
                  name="discount_in_sale_price"
                  value={this.state.discount_in_sale_price}
                  placeholder="Enter Discount On Sale Price"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Discount On Sale Price</label>
                <span className="iris_error_txt">
                  {errors.discount_in_sale_price.length > 0 && (
                    <span className="error">
                      {errors.discount_in_sale_price}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddItem}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Item
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add  Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversion}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversion"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Set Conversion</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversion"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddUniConversion
                    isRedirectTo={"business/add-item/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversion"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Unit Conversion Details*/}

        {/* Modal List Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddUnitSlug}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddUnitSlug"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Unit</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddUnitSlug"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddUnitData callBack={this.getUpdateUnitResponse} />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddUnitSlug"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal List Unit Conversion Details*/}

        {/* Modal Item Category Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ItemCat}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustItemCat"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Category</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustItemCat"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewItemCatDetail
                    isRedirectTo={"business/add-item/"}
                    callBack={this.getUpdateResponse}
                  />
                  {/* isRedirectTo={'business/add-purchase-debit-note/'} */}
                </div>
                <br />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustItemCat"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Category Details*/}

        {/* Modal Item Add Serial No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddSerialNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Serial No<span> ({this.state.name})</span>
                  </h3>

                  <div className="block-options">
                    {this.state.itemId == 0 && (
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAddSerialNo"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddSerialNo
                    isRedirectTo={"business/add-item/"}
                    callBack={(data, _opening_stock) => {
                      let errors = this.state.errors;
                      errors["stockChange"] = "";
                      errors["opening_stock"] = "";
                      this.setState({
                        itemSerialNo: data,
                        modalIsOpen_SerialNo: false,
                        opening_stock: _opening_stock,
                        errors: errors,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
              <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAddSerialNo"
                    )}
                  >
                    Close
                  </button>

                {/*this.state.itemId == 0 && (
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAddSerialNo"
                    )}
                  >
                    Close
                  </button>
                )*/}
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Serial No*/}

        {/* Modal Item Add Batch No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BatchNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddBatchNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Batch No</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddBatchNo"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddBatchNo
                    isRedirectTo={"business/add-item/"}
                    callBack={(data, _opening_stock) => {
                      let errors = this.state.errors;
                      errors["stockChange"] = "";
                      this.setState({
                        itemBatchNo: data,
                        modalIsOpen_BatchNo: false,
                        opening_stock: _opening_stock,
                        errors: errors,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemBatchNoAll={this.state.itemBatchNo}
                    itemId={this.state.itemId}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this, "modalAddBatchNo")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Batch No*/}
      </React.Fragment>
    );
  }
}
