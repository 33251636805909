export function userSessionManage(localStorage,result,_key)
{    
    if(_key == 'startSession')
    {
        localStorage.removeItem('vs_UserSession');
        var obj = {};
        obj.smallName = result.short_name;
        obj.loginId = result.login_session_id;
        obj.loginType = result.login_type;
        //obj.loginTime = result.login_time;
        obj.loginName = result.user_name;
        obj.loginEmail = result.user_email;
        obj.loginNumner = result.loginNumner;
        obj.loginCounCode = result.loginCounCode;
        obj.tokenKey = result.business_id;
        obj.companies = result.companies;
        obj.company_id = result.company_id;
        obj.profile_logo = result.profile_logo;
        obj.owner_name = result.owner_name;
        obj.owner_email = result.owner_email;
        obj.owner_country_code = result.owner_country_code;
        obj.owner_contact = result.owner_contact;
        obj.business_address = result.business_address;
        obj.businessSetting = result.businessSetting;
        localStorage.setItem('vs_UserSession', JSON.stringify(obj));
        setLocalSessionExpire('Local', '5');
    }
    else if(_key == 'countrySession')
    {
        localStorage.removeItem('vs_UserCountrySession');
        var obj = {};
        obj.country = (result.country);
        obj.city = result.city;
        obj.countryCode = result.countryCode;
        obj.lat = result.lat;
        obj.lon = result.lon;
        obj.region = result.region;
        obj.regionName = result.regionName;
        obj.ip = result.query;
        obj.loginName = result.login_session_name;
        localStorage.setItem('vs_UserCountrySession', JSON.stringify(obj));
        setLocalSessionExpire('Locall', '5');
    }
    
    else if(_key == 'companySession')
    {
        localStorage.removeItem('vs_UserCompanySession');
        var obj = {};
        obj.company_name = (result.company_name);
        obj.company_logo = (result.company_logo);
        obj.company_signature = (result.company_signature);
        obj.company_contact = (result.company_contact);
        obj.email = (result.email);
        obj.gstin = (result.gstin);
        obj.company_address = (result.company_address);
        obj.comapny_gstin = result.gstin;
        obj.company_id = result.company_id;
        obj.business_id = result.business_id;
        obj.country_code = result.country_code;
        obj.country_short = result.country_short;
        obj.currency_symbol = result.currency_symbol;
        obj.currency_code = result.currency_code;
        obj.country_name = result.country_name;
        obj.tax_name = result.tax_name;
        obj.taxType = result.taxType;
        obj.taxDetails = result.taxDetails;
        obj.bankDetails = result.bankDetails;
        localStorage.setItem('vs_UserCompanySession', JSON.stringify(obj));
        setLocalSessionExpire('Locall', '5');
    }
    else if(_key == 'itemBatchData')
    {
        localStorage.removeItem('vs_ItemBatchSerialData');
        var obj = {};
        obj.batchItemData = result.batchItemData;

        localStorage.setItem('vs_ItemBatchSerialData', JSON.stringify(obj));
        setLocalSessionExpire('Local', '5');
    }
    else if(_key == 'itemSerialData')
    {
        localStorage.removeItem('vs_ItemBatchSerialData');
        var obj = {};
        obj.serialItemData = result.serialItemData;

        localStorage.setItem('vs_ItemBatchSerialData', JSON.stringify(obj));
        setLocalSessionExpire('Local', '5');
    }
}

function capitalizeFirstLetter(string)
{
    var str = string;
    var arr = str.split(' ');
    var _caps = '';
    for(var _i = 0;_i< arr.length;_i++)
    {
        var keyys = arr[_i].charAt(0).toUpperCase();
        _caps += keyys;
    }
    return _caps;
}

function setLocalSessionExpire(key,expires)
{
    var hours = 1; // Reset when storage is more than 24hours
    var now = new Date().getTime();
    var setupTime = localStorage.getItem(key + '_expiresIn');
    if (setupTime == null) {
        localStorage.setItem(key + '_expiresIn', now)
    }
    else
    {
        if(now-setupTime > hours*60*60*1000)
        { 
            console.log('Expire Local Storage');          
            //localStorage.setItem(key + '_expiresIn', now);
        }
    }
}