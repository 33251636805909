/* Expnese Category Section */
global.userAddExpenseCategory = global.API_USER_URL+'userAddExpenseCategory/';
global.userEditExpenseCategory = global.API_USER_URL+'userEditExpenseCategory/';
global.userExpenseCategoryDetails = global.API_USER_URL+'userExpenseCategoryDetails/';
global.userExpenseCategoryList = global.API_USER_URL+'userExpenseCategoryList/';
global.userExpenseCategoryDelete = global.API_USER_URL+'userExpenseCategoryDelete/';
global.userAddExpenseCategoryList = global.API_USER_URL+'userAddExpenseCategoryList/';

/* Expnese Item Section */
global.userAddExpenseItem = global.API_USER_URL+'userAddExpenseItem/';
global.userEditExpenseItem = global.API_USER_URL+'userEditExpenseItem/';
global.userExpenseItemDetails = global.API_USER_URL+'userExpenseItemDetails/';
global.userexpenseItemList = global.API_USER_URL+'userExpenseItemList/';
global.userExpenseItemDelete = global.API_USER_URL+'userExpenseItemDelete/';

/* Expense Transaction */
global.createExpenseTransaction = global.API_USER_URL+'createExpenseTransaction/';
global.editExpenseTransaction = global.API_USER_URL+'editExpenseTransaction/';
global.deleteExpenseTransaction = global.API_USER_URL+'deleteExpenseTransaction/';
global.userExpenseCategoryTransactionList = global.API_USER_URL+'userExpenseCategoryTransactionList/';
global.userExpenseItemTransactionList = global.API_USER_URL+'userExpenseItemTransactionList/';
global.userExpenseTransactionDetails = global.API_USER_URL+'userExpenseTransactionDetails/';
global.createDuplicateExpenseTransaction = global.API_USER_URL+'createDuplicateExpenseTransaction/';
global.createExpensePaymentOutTransaction = global.API_USER_URL+'createExpensePaymentOutTransaction/';
global.expensePaymentHistory = global.API_USER_URL+'expensePaymentHistory/'; 
