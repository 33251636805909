import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './globalSetting';
import App from './App';

// System Apis Here ---->>>
// Login Api
import './_apiSettings/_api-signIn';
// Business Api
import './_apiSettings/business/_api-businessProfileApi';
import './_apiSettings/business/_api-businessApi';
import './_apiSettings/business/_api-businessCompanyApi';
import './_apiSettings/business/_api-businessUserApi';
import './_apiSettings/business/_api-bussinessSettingApi';

// Admin Api
import './_apiSettings/admin/_api-adminCountryApi';
import './_apiSettings/admin/_api-adminGstTypeApi';
import './_apiSettings/admin/_api-adminGstApi';
import './_apiSettings/admin/_api-adminUnitApi';
import './_apiSettings/admin/_api-adminBusinessTypeApi';
import './_apiSettings/admin/_api-adminBusinessCategoryApi';
import './_apiSettings/admin/_api-adminBlog';
import './_apiSettings/admin/_api-adminBlogCategory';
import './_apiSettings/admin/_api-adminWebPlanApi';
import './_apiSettings/admin/_api-adminWebPlanPriceApi';
import './_apiSettings/admin/_api-adminSmsApi';

// User Dashboard Api
import './_apiSettings/user/_api-userProfileApi';
import './_apiSettings/user/_api-userDashContoller';
import './_apiSettings/user/_api-userUnitApi';
import './_apiSettings/user/_api-userParty';
import './_apiSettings/user/_api-userItems'; 
import './_apiSettings/user/_api-userService';
import './_apiSettings/user/_api-userSaleTransaction';
import './_apiSettings/user/_api-userPurchaseTransaction';
import './_apiSettings/user/_api-userSmsApi';
import './_apiSettings/user/_api-userExpense';
import './_apiSettings/user/_api-userCashInHand';
import './_apiSettings/user/_api-userChequeBank';
import './_apiSettings/user/_api-userLoanAccount';
import './_apiSettings/user/_api-userAccountTrasnfer';
import './_apiSettings/user/_api-userReportTransactionApi';
import './_apiSettings/user/_api-userOrherIncome';
// User Dashboard Api
import './_apiSettings/website/_api-websitePlanApi';

//import Private from './private/private';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
