import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};
let _SerialNoChk = 0;
let _SerialNoTxt = '';
let _SerialNoAdditionalChk1 = 0;
let _SerialNoAdditionalChk2 = 0;
let _SerialNoAdditionalLabel1 = '';
let _SerialNoAdditionalLabel2 = '';

let _loader = 'glb-ldr-prt active';

export default class QuickAddNewSaleSerialNumber extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                        _SerialNoAdditionalChk1 = _businessSetting.SerialNoAdditionalChk1;
                        _SerialNoAdditionalChk2 = _businessSetting.SerialNoAdditionalChk2;
                        _SerialNoAdditionalLabel1 = _businessSetting.SerialNoAdditionalLabel1;
                        _SerialNoAdditionalLabel2 = _businessSetting.SerialNoAdditionalLabel2;
                        _SerialNoChk = _businessSetting.SerialNoChk;
                        _SerialNoTxt = _businessSetting.SerialNoTxt;
                    }
                }
            } 
        }

        this.state = {
            itemSerialNoAll : this.props.itemSerialNoAll ? this.props.itemSerialNoAll : [],    
            itemId : this.props.itemId ? this.props.itemId : '0',
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : '0',
            openingStock : 0,  
            isLoaded : true, 
            modalIsOpen_SerialNo : true,
            itemBatchNo : [],
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            SerialNoAdditionalLabel1 : _SerialNoAdditionalLabel1,
            SerialNoAdditionalLabel2 : _SerialNoAdditionalLabel2,
            SerialNoAdditionalChk1 : _SerialNoAdditionalChk1,
            SerialNoAdditionalChk2 : _SerialNoAdditionalChk2,
            SerialNoChk : _SerialNoChk,
            SerialNoTxt : _SerialNoTxt,
            opSCount : (this.props.itemSerialNoAll.length > 0) ? this.props.itemSerialNoAll.length : 0,
            totalAmountOld : this.props.totalAmountOld ? this.props.totalAmountOld : 0,
            totalQuantity : this.props.totalQuantity ? this.props.totalQuantity : 0,
            itemPrice : this.props.itemPrice ? this.props.itemPrice : 0,
            transactionId : this.props.transactionId ?  this.props.transactionId : 0,
            itemFields :  this.props.itemFields ? this.props.itemFields : [],
            indexPosition: this.props.indexPosition ? this.props.indexPosition : 0,
            serial_no_val : '',
            isError : false,
            errors: {
                error  :  '',
                serial_no_val : '',
                error1  :  '',
                error2  :  '',
            }
        }
    }
    async componentDidMount ()
    {   
        
    }
    
    handleChange(i, e) {
        let itemSerialNoAll = this.state.itemSerialNoAll;
        let _opCount = 0;
        let errors = this.state.errors;
        let _isError = false;
        
        if(this.state.itemSerialNoAll.length == 1){
            _opCount =  _opCount + 1;
        }else{
            _opCount =  this.state.itemSerialNoAll.length;
        }
        if(e.target.name == 'serial_no' && _businessSetting.SerialNoChk == 1){
            if(this.state.itemSerialNoAll.length == 1 && e.target.value == '' && this.state.itemId == 0){
                _opCount = 0;
            }
            
            _isError = false;
            itemSerialNoAll.map((data,index) => {
               if(data.serial_no == e.target.value){
                    _isError = true;
                    data['error'] = "*Serial number already exists.";
               }else{
                    data['error'] = "";
               }
            })
            this.setState({opSCount : _opCount});
        }
        else if(e.target.name == 'serialNoAdditionalValue1' && _businessSetting.SerialNoAdditionalChk1 == 1){
            _isError = false;            
            itemSerialNoAll.map((data,index) => {
            if(data.serialNoAdditionalValue1 == e.target.value){
                 _isError = true;
                 data['error1'] = "* "+this.state.SerialNoAdditionalLabel1+" already exists.";
                }
                else{
                    data['error1'] = "";
                }
            })
        }
        else if(e.target.name == 'serialNoAdditionalValue2' && _businessSetting.SerialNoAdditionalChk2 == 1){
            _isError = false;
            itemSerialNoAll.map((data,index) => {
                if(data.serialNoAdditionalValue2 == e.target.value){
                    _isError = true;
                    data['error2'] = "* "+this.state.SerialNoAdditionalLabel2+" already exists.";
                }
                else{
                    data['error2'] = "";
                }
            })
        }
        else{
            _opCount = this.state.opSCount;
            this.setState({opSCount : _opCount });
        }
        
        itemSerialNoAll[i][e.target.name] = e.target.value;
        //itemSerialNoAll[i][isSelected] = true;
        //itemSerialNoAll[i][isNewItem] = true;

        this.setState({ itemSerialNoAll,isError : _isError});
    }
    
    addFormFields() {
        let errors = this.state.errors;
        let _isError = false; 
        if(this.state.opSCount  == 0 && this.state.itemId == 0){
            _isError = true;      
        }
        else{         
            _isError = false;
            this.setState(({
                itemSerialNoAll: [...this.state.itemSerialNoAll, { serial_no: "", serialNoAdditionalValue1: "" , serialNoAdditionalValue2: "" }]
            }))
        }
    }

    removeFormFields(i) {        
        let _opCount = this.state.opSCount;
        if(this.state.itemSerialNoAll.length == 1){
            _opCount =  this.state.itemSerialNoAll.length;//_opCount + 1;
        }else{
            _opCount =  this.state.itemSerialNoAll.length -  1;
        }
        this.setState({opSCount : _opCount });

        let itemSerialNoAll = this.state.itemSerialNoAll;
        itemSerialNoAll.splice(i, 1);
        
        let errors = this.state.errors;
        let _isError = false;

        this.setState({ itemSerialNoAll,isError:_isError });
    }  

    clickToAddData = (event) => {   
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false; 
        let _itemSerialData = (this.state.itemSerialNoAll);
        
        _itemSerialData.map((data,index) => {
            data.isSelected =  true;
            data.isNewItem =  true;
            data.productId = this.state.itemId;
            //data.availLenAk = 0;
            if(data.serial_no == '' && _businessSetting.SerialNoChk == 1){
                _isError = true;
                data['error'] = "*Please Enter "+this.state.SerialNoTxt+" ";
                this.setState({isError: _isError}); 
            } 
            else if(data.serialNoAdditionalValue1 == '' && _businessSetting.SerialNoAdditionalChk1 == 1){
                _isError = true;
                data['error1'] = "*Please Enter  "+this.state.SerialNoAdditionalLabel1+" ";
                this.setState({isError: _isError}); 
            }  
            else if(data.serialNoAdditionalValue2 == '' && _businessSetting.SerialNoAdditionalChk2 == 1){
                _isError = true;
                data['error2'] = "*Please Enter  "+this.state.SerialNoAdditionalLabel2+" ";
                this.setState({isError: _isError}); 
            } 
           
            else if((data.error!='' && _businessSetting.SerialNoChk == 1) || (data.error1!='' && _businessSetting.SerialNoAdditionalChk1 == 1)  || (data.error2!='' && _businessSetting.SerialNoAdditionalChk2 == 1)){
                _isError = true;
                this.setState({isError: _isError}); 
                this.state.isError = true;
            }    
                 
        });
        if(_isError ==  false){   
            let _count = _itemSerialData.length;
            let _totalQuantity = parseFloat(_itemSerialData.length) +  parseFloat(this.state.totalQuantity);
            let itemPrice = parseFloat(this.state.itemPrice) * parseFloat(_count); 
            let _totalAmountOld =  parseFloat(this.state.itemPrice) * parseFloat(_count); 
            _totalAmountOld = parseFloat(this.state.totalAmountOld) + parseFloat(_totalAmountOld);

            let _fieldData =  this.state.itemFields;
            let discountAmt = 0;   
            let totalAmount = 0;
            let tax = 0;
            let amountBeforeTaxWithoutDisc = 0;
            let disc = 0;
            let amountBeforeTax = 0 ;
            let subTotal = 0;    
            let _discountAmtSend = 0;
            let _taxAmtSend = 0;
            let _itemToalAmtSend = 0;
            let _costPrice = 0;
         
            _fieldData.map((itemData, i) => {
                if (i == this.state.indexPosition) {          
                    let qty = _count ? _count : 0;
                    let rateValue = parseFloat(itemData.rate);
                    let taxType = parseFloat(itemData.tax_type.ratio) ? parseFloat(itemData.tax_type.ratio) : 0.0;
                    let additionalCess = parseFloat(itemData.Additional_CESS) ? parseFloat(itemData.Additional_CESS) : 0;
                    let discount_in_per =  parseFloat(itemData.discount_in_per) ?  parseFloat(itemData.discount_in_per) : 0;
                    let discount_in_amount =  parseFloat(itemData.discount_in_amount) ?  parseFloat(itemData.discount_in_amount) : 0;
             
                    subTotal = rateValue * qty;   
                    if (itemData.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
                    {   
                        if(discount_in_per > 0){            
                            _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);      
                            discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                            amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                            disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                            tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                            totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);
            
                            //Discount amont == disc;
                            disc = disc.toFixed(2);
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = disc;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{  
                            if(taxType > 0){
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            }else{
                                _costPrice = subTotal;
                            }              
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = _costPrice - discountAmt;
                            disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                            tax = amountBeforeTax * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                    }
        
                    else if (itemData.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0')) 
                    {              
                        if(discount_in_per > 0){             
                            _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);     
                            discountAmt = (discount_in_per / 100) * subTotal;
                            amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                            disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                            tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                            totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + additionalCess;
            
                            //Discount amont == disc;
                            disc = disc.toFixed(2);
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = disc;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{ 
                            if(taxType > 0){
                                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                            }else{
                                _costPrice = subTotal;
                            }           
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = _costPrice - discountAmt;
                            disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                            tax = amountBeforeTax * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                    }
                    
                    else {
                        if(discount_in_per > 0){
                            subTotal = rateValue * qty;    
                            _costPrice = subTotal;        
                            discountAmt = (discount_in_per / 100) * subTotal;
                            amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                            tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                            totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                        else{
                            subTotal = rateValue * qty;
                            _costPrice = subTotal; 
                            discountAmt = discount_in_amount;// * qty;;
                            amountBeforeTax = subTotal - discountAmt;
                            tax = amountBeforeTax * taxType / 100;
                            //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                            totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                            
                            discountAmt = discountAmt.toFixed(2);
                            tax = tax.toFixed(2);
                            totalAmount = totalAmount.toFixed(2);
                            
                            _discountAmtSend = discountAmt;
                            _taxAmtSend = tax;
                            _itemToalAmtSend = totalAmount;
                        }
                                
                    }
                }
            })

            this.props.callBack(_itemSerialData,_count,itemPrice,_totalAmountOld.toFixed(2),_totalQuantity,_discountAmtSend,_taxAmtSend,_itemToalAmtSend,this.state.indexPosition,_costPrice);
        }
              
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        if(this.state.itemSerialNoAll.length == 0){
            this.setState({ 
                itemSerialNoAll: [{ serial_no: "", serialNoAdditionalValue1 : "" , serialNoAdditionalValue2 : "" }]
            });            
        }
        let _countMain = this.state.openingStock;
        if(this.state.openingStock == 0){
            _countMain = this.state.opSCount;
        }        
        if(this.state.openingStock > 0 && (this.state.opSCount > this.state.openingStock)){
            _countMain = this.state.opSCount;
        }
        const {errors} = this.state;
        let  isDisabled = '';//Object.keys(errors).some(x => errors[x]);
        if(this.state.isError == true){
            isDisabled = 'disabled';
        }
        return(
            <React.Fragment>           
                <form method="post" id="form-login" className="webForm px-30">                  
                    <div className="form-group row"> 
                        
                        <span className="badge badge-light pull-right"> {this.state.opSCount} / {_countMain} entered</span>
                    </div> 
                    {this.state.itemSerialNoAll.map((element, index) => (
                        
                    <div className="form-group row">
                       
                        {this.state.SerialNoChk == 1 &&
                        <div className="col-3">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serial_no" name="serial_no" value={element.serial_no || ""} onChange={e => this.handleChange(index, e)}  placeholder={this.state.SerialNoTxt} />
                                <label htmlFor="login-username">{this.state.SerialNoTxt} <span className="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error?.length > 0 && <span className='error'>{element.error}</span>}</span>
                            </div> 
                        </div>  
                        }
                        {this.state.SerialNoAdditionalChk1 == 1 &&
                        <div className="col-3">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serialNoAdditionalValue1" name="serialNoAdditionalValue1" value={element.serialNoAdditionalValue1 || ""} onChange={e => this.handleChange(index, e)}  placeholder="Enter Value 1" />
                                <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel1} <span className="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error1?.length > 0 && <span className='error'>{element.error1}</span>}</span>
                                
                            </div> 
                        </div>  
                        }
                        {this.state.SerialNoAdditionalChk2 == 1 &&
                        <div className="col-3">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="serialNoAdditionalValue2" name="serialNoAdditionalValue2" value={element.serialNoAdditionalValue2 || ""} onChange={e => this.handleChange(index, e)} placeholder="Enter Value 2" />
                                <label htmlFor="login-username">{this.state.SerialNoAdditionalLabel2} <span className="text-danger">*</span> </label>
                                <span className="iris_error_txt">{element.error2?.length > 0 && <span className='error'>{element.error2}</span>}</span>
                            </div> 
                        </div>
                        
                        
                        }
                        {((this.state.itemSerialNoAll.length  >1 ) && (index >= 1) ) &&
                        <div className="col-3">
                            <a  className="badge badge-danger pull-right badge-danger" onClick={() => this.removeFormFields(index)} >
                                <i className="fa fa-solid fa-trash"></i>
                            </a>
                        </div>                            
                        }
                        
                    </div>
                    ))}
                    
                    <span className="iris_error_txt">{errors.serial_no_val.length > 0 && <span className='error'>{errors.serial_no_val}</span>}</span>
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>&nbsp;&nbsp;
                            {this.state.opSCount > 0 &&
                            
                            <button type='button' className="btn btn-alt-primary" onClick={() => this.addFormFields()}  disabled={isDisabled}> <i className='fa fa-plus'></i>Add </button>
                            }
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}