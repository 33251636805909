import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewItem from "../userItem/quickSaleItemAdd";

import QuickAddNewSalePurchaseSerialNo from "./quickAddNewSerialNo";
import QuickAddNewFreePurchaseSerialNo from "./quickAddNewFreeSerialNo";
import QuickAddNewPurchaseBatchNo from "./quickAddNewBatchNo";
import QuickAddNormalQuantity from "./quickNormalQuantityAdd";
import QuickAddNormalQuantityFree from "./quickNormalQuantityAddFree";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { confirmAlert } from "react-confirm-alert";

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _businessSetting = {};

export default class QuickTransactionItemSection extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;
    }
    this.state = {
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      party_id: this.props.party_id ? this.props.party_id : 0,
      party_name: "",

      /*Start Srerial Batch Popup Data End*/

      modalIsOpen_BatchViewPopup: false,
      modalIsOpen_SerialBatchNo: false,
      modalIsOpen_AddNewSerialNo: false,
      modalIsOpen_AddNewFreeSerialNo: false,
      modalIsOpen_AddNewBatchNo: false,
      modalIsOpen_NotSerialNo: false,
      modalIsOpen_NotSerialNoFree: false,
      batchNo: 0,
      OldBatTotalQty: 0,
      OldBatStockTotalQty: 0,
      selectTypeTxt: "Normal",
      itemAddSerialNo: [],
      itemAddBatchNo: [],
      popupVal: [],
      selectType: 0,
      batchItemEnable: 0,
      serialItemEnable: 0,
      btnShowSerialNormalBatch: 0,
      popuptype: "",
      isFree: 0,
      newItemId: 0,
      normal_remaining_quantity: 0,
      batchSendType: "New",
      freeQtyAk: 0,
      /*End Srerial Batch  Popup Data End*/

      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      handleAddDisable: "",
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalAmountNew: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      totalAmountOld: 0,
      itemRemovePosition: 0,
      itemType: "",
      handleItemSalePurchasePrice: "",
      transactionId: this.props.transId ? this.props.transId : 0,
      sendPageType :  this.props.sendPageType ? this.props.sendPageType : '',
      duplicateSale : this.props.duplicateSale ? this.props.duplicateSale : '',
      errors: {
        party_id: "",
        party_name: "",
      },
    };
  }

  _handleClearItem = (event) => {
    this.setState({
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      unitArray: [],
      taxRateArray: _taxRateArray,
      rows: [],
      fields: [],
      party_name: "",
      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      lowStokItemName: "",
      /*Start Srerial Batch Popup Data End*/

      itemSerialNo: [],
      itemBatchNo: [],

      modalIsOpen_SerialNo: false,
      modalIsOpen_SerialNoFreeItem: false,
      modalIsOpen_NotSerialNo: false,
      modalIsOpen_NotSerialNoFree: false,
      modalIsOpen_BatchNo: false,

      modalIsOpen_BatchViewPopup: false,
      modalIsOpen_SerialBatchNo: false,
      modalIsOpen_SerialBatchNoOld: false,
      modalIsOpen_AddNewSerialNo: false,
      modalIsOpen_AddNewFreeSerialNo: false,
      modalIsOpen_AddNewBatchNo: false,
      usedFreeNormalQty: 0,
      batchNo: "",
      OldBatTotalQty: 0,
      selectTypeTxt: "Normal",
      itemAddSerialNo: [],
      itemAddBatchNo: [],
      popupVal: [],
      selectType: 0,
      batchItemEnable: 0,
      serialItemEnable: 0,
      btnShowSerialNormalBatch: 0,
      popuptype: "",
      isFree: 0,
      newItemId: 0,
      normal_remaining_quantity: 0,
      batchSendType: "New",
      freeQtyAk: 0,
      /*End Srerial Batch  Popup Data End*/

      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      handleAddDisable: "",
      EnableItem: _businessSetting.EnableItem,
      FreeItemQuantity: _businessSetting.FreeItemQuantity,
      TransCount: _businessSetting.Count,
      DisplayPurchasePrice: _businessSetting.DisplayPurchasePrice,
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      totalAmountOld: 0,
      itemRemovePosition: 0,
      sendActualQty: 0,
      totalAmountOldFree: 0,
      handleItemSalePurchasePrice: "",
      transactionId: this.props.transId ? this.props.transId : 0,
    });
    this.getItemListData(_company_id);
  };
  componentWillUnmount() {
    document.body.removeEventListener("clearData", this._handleClearItem);
  }
  async componentDidMount() {
    document.body.addEventListener("clearData", this._handleClearItem);
    this.getItemListData(_company_id);

    /*For master Units*/
    let _urlDataUnits =
      global.userUnitList +
      "?businessId=" +
      _businessId +
      "&company_id=" +
      this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    /*For master Units*/

    this.setState({ unitArray: _unitArray, isLoaded: true });

    let _transId = this.props.transId;
    if (_transId > 0) {
      _isEditMode = true;
    }
    if (_transId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: _transId,
      });

      PostData(global.userTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/purchase-invoice/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let itemsData = _transactionArray.items;
              let transAmout = _transactionArray.totalSummary;
              let _batchItemEnable = 0;
              let _serialItemEnable = 0;
              let _btnShowSerialNormalBatch = 0;
              itemsData.map((_itemsBSData, index) => {
                if (
                  _itemsBSData.itemBatchNoAllData &&
                  _itemsBSData.itemBatchNoAllData.length > 0
                ) {
                  _batchItemEnable = 1;
                  _btnShowSerialNormalBatch = 2;
                }
                if (
                  _itemsBSData.itemSerialNoAllData &&
                  _itemsBSData.itemSerialNoAllData.length > 0
                ) {
                  _serialItemEnable = 1;
                  _btnShowSerialNormalBatch = 1;
                }
              });

              this.setState({
                fields: itemsData,
                isLoaded: true,
                transactionId: _transId,
                EnableItem: _transactionArray.EnableItem,
                //FreeItemQuantity :_transactionArray.FreeItemQuantity,
                totalAmountOld: transAmout.totalAmount,
                totalAmountNew: transAmout.totalAmount,
                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount:
                  _transactionArray.totalTransDiscountAmount,
                batchItemEnable: _batchItemEnable,
                serialItemEnable: _serialItemEnable,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                totalAmount: transAmout.totalAmount,
                paidAmount: transAmout.paidAmount,
                subtotal: transAmout.subtotal,
                gstRatio: transAmout.gstRatio,
                gstAmount: transAmout.gstAmount,
                totalQuantity: transAmout.totalQty,
                totalDiscount: transAmout.totalDiscount,
                dueAmount: transAmout.dueAmount,
                btnShowSerialNormalBatch: _btnShowSerialNormalBatch,
                handleAddDisable: "",
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  getItemListData(company_id) {
    let _itemsArray = [];

    let _url_GetData =
      global.userItemSalePurList + "?company_id=" + this.state.company_id+ "&for_item_type=PURCHASE";
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
          }
          //this.setState({itemArray:_itemsArray,isLoaded:true});
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  /*For Modal open close*/

  openModalHandler(e) {
    if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: true });
    } else if (e == "modalAddBatchViewPop") {
      this.setState({ modalIsOpen_BatchViewPopup: true });
    } else if (e == "modalShowSerialBatchNo") {
      this.setState({ modalIsOpen_SerialBatchNo: true });
    } else if (e == "modalAddNewSalePurSerialNo") {
      this.setState({ modalIsOpen_AddNewSerialNo: true });
    } else if (e == "modalAddNewSalePurFreeSerialNo") {
      this.setState({ modalIsOpen_AddNewFreeSerialNo: true });
    } else if (e == "modalAddNewBatchNo") {
      this.setState({ modalIsOpen_AddNewBatchNo: true });
    } else if (e == "modalAddNotSerialNo") {
      this.setState({ modalIsOpen_NotSerialNo: true });
    } else if (e == "modalAddNotSerialNoFree") {
      this.setState({ modalIsOpen_NotSerialNoFree: true });
    }
  }

  closeModalHandler = (e) => {
    if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: false });
    } else if (e == "modalAddBatchViewPop") {
      this.setState({ modalIsOpen_BatchViewPopup: false });
    } else if (e == "modalShowSerialBatchNo") {
      this.setState({ modalIsOpen_SerialBatchNo: false });
    } else if (e == "modalAddNewSalePurSerialNo") {
      this.setState({ modalIsOpen_AddNewSerialNo: false });
    } else if (e == "modalAddNewSalePurFreeSerialNo") {
      this.setState({ modalIsOpen_AddNewFreeSerialNo: false });
    } else if (e == "modalAddNewBatchNo") {
      this.setState({ modalIsOpen_AddNewBatchNo: false });
    } else if (e == "modalAddNotSerialNo") {
      this.setState({ modalIsOpen_NotSerialNo: false });
    } else if (e == "modalAddNotSerialNoFree") {
      this.setState({ modalIsOpen_NotSerialNoFree: false });
    }
  };

  closeModalHandlerMainP = (e, pos, itemId) => {
    console.log(e + "==<<==" + pos + "==<<==" + itemId);
    if (e == "modalShowSerialBatchNo") {
      this.setState({
        modalIsOpen_SerialBatchNo: false,
        handleItemSalePurchasePrice: "",
      });
      this.handleRemove(pos, itemId);
    }
  };

  /*End Modal open close*/

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  handleAdd() {
    let errors = this.state.errors;
    if (this.state.party_id == 0) {
      let _isError = true;
      errors["party_name"] = "*Please Select Party Name";
      this.setState({ errors: errors });
    }
    // else if (this.state.handleItemSalePurchasePrice.length > 2) {
    //   alert("Item price can not be zero");
    //   return false;
    // }
    else {
      if (this.state.handleAddDisable.length > 2) {
        alert("Before adding more items, select the item first  in the row.");
        return false;
      } else {
        const values = this.state.fields;
        values.push([]);
        let _itemPosition = this.state.itemPosition;
        if (_itemPosition == -1) {
          _itemPosition = 0;
        }
        this.setState({
          fields: values,
          itemPosition: _itemPosition,
          handleAddDisable: "disabled",
          handleItemSalePurchasePrice: "",
        });
        let _totalAmt = this.state.totalAmount;
        let _dueAmount = this.state.dueAmount;

        let _paidAM = this.state.paidAmount;
        let _totalAmountNewSend = this.state.totalAmountNew;
        let _totalAmountOldSend = this.state.totalAmountOld;
        let _totalDiscount = this.state.totalDiscount;
        let _totalGstAmt = this.state.gstAmount;
        let _totalQuantity = this.state.totalQuantity;
        let handleAddDisable = "Disabled";
        let handleItemSalePurchasePrice = "";
        let _fieldData = this.state.fields;
        this.props.callBackItem(
          _fieldData,
          _totalAmountOldSend,
          _totalAmountNewSend,
          _totalAmt,
          _dueAmount,
          _paidAM,
          _totalDiscount,
          _totalGstAmt,
          _totalQuantity,
          handleAddDisable,
          handleItemSalePurchasePrice
        );
      }
    }
  }

  handleRemove(i, itemId) {
    const values = this.state.fields;
    let finalQty = 0;
    const newFields = values.filter((itmRow, index) => {
      return index!=i;
    });
    let data = this.state.itemArray;
    let _itemBatchNoFinal = [];
    let _itemSerialNoFinal = [];
    let _normal_remaining_quantity = values[i].normal_remaining_quantity;
    let _normal_quantity = values[i].normal_quantity;

    if (_normal_quantity > 0 || _normal_quantity!="") {
      finalQty = parseInt(_normal_remaining_quantity) - parseInt(_normal_quantity);
    }

    let removeSrId = [];
    this.setState({ handleAddDisable: "" });
    if (values[i].itemSerialNoAllData!=undefined) {
      let myRemoveSrData = values[i].itemSerialNoAllData;
      myRemoveSrData.map((valSrData, i) => {
        removeSrId.push(valSrData.serial_id);
      });
    }

    data.map((item, index1) => {
      if (item.item_id == itemId) {
        item.normal_quantity = finalQty;
        item.itemSerialNoAllData = [];
        item.itemBatchNoAllData = [];
        if (
          item.itemSerialNo &&
          item.itemSerialNo!="" &&
          newFields!="" &&
          newFields.length > 0
        ) {
          _itemSerialNoFinal = item.itemSerialNo.map((srData) => {
            removeSrId.map((rmvSrId, i) => {
              if (rmvSrId == srData.serial_id) {
                srData.isSelected = false;
                srData.isFreeQuantity = false;
                srData.dataCheck = 1;
              }
            });
            return srData;
          });

          _itemBatchNoFinal = item.itemBatchNo.map((batchData) => {
            return {
              ...batchData,
              used_stock: "",
              free_quantity: "",
            };
          });
        } else {
          const values = [];
          values.push([]);
          this.setState({
            fields: values,
            normal_remaining_quantity: _normal_remaining_quantity,
            normal_quantityAdded: finalQty,
          });
          data.map((item, index1) => {
            if (item.item_id == itemId) {
              _itemSerialNoFinal = item.itemSerialNo.map((srData) => {
                return {
                  ...srData,
                  isSelected: false,
                  isFreeQuantity: false,
                  dataCheck: 0,
                };
              });

              _itemBatchNoFinal = item.itemBatchNo.map((batchData) => {
                return {
                  ...batchData,
                  used_stock: "",
                  free_quantity: "",
                };
              });
            }
          });
        }
        /*if(item.itemBatchNo && item.itemBatchNo!==''){                    
            _itemBatchNoFinal = item.itemBatchNo.map((batchData) => ({                
                ...batchData,
                used_stock : 0,
                free_quantity :0
            })) 
        }*/
        item.itemSerialNo = _itemSerialNoFinal;
        item.itemBatchNo = _itemBatchNoFinal;

        data[index1].isFilter = false;
      }
    });
    /*Low Stock Data */
    /*if(this.state.lowStokItemName.length > 0){
          let _lowStokItemName = this.state.lowStokItemName;
          let _stockItemName = '';
          let _objLowStock = _lowStokItemName.filter((data) => data.item_name!=_stockItemName);
          this.setState({lowStokItemName:_objLowStock});
      }*/
    /*End Low Stock Data */

    const fieldArray = newFields;
    let _quantity = 0;
    let _totalAmt = "0";
    let _receivedAmount = this.state.receivedAmount;
    let _dueAmount = 0;
    let _totalDiscountAmt = 0;
    let _totalGstAmt = 0;
    if (this.state.transactionId == 0) {
      //_totalGstAmt = this.state.gstAmount;
    }
    let _discountAmtAk_1 = 0;
    let _discountTaxAk_1 = 0;
    let _amountDisK_1 = 0;
    let _amountTaxK_1 = 0;
    fieldArray.map((field, index) => {
      let _itemId = field.item_id ? field.item_id : 0;
      if (_itemId > 0) {
        let _obj = {};
        _obj.item_id = field.item_id ? field.item_id : 0;
        _obj.item_name = field.item_name ? field.item_name : "";
        _obj.quantity = field.quantity ? field.quantity : 0;
        _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
        _obj.rate = field.rate ? field.rate : 0;
        _obj.discount_in_per = field.discount_in_amount
          ? field.discount_in_amount
          : 0;
        _obj.discount_in_amount = field.discount_in_per
          ? field.discount_in_per
          : 0;
        _obj.tax_value = field.tax_value ? field.tax_value : 0;

        /*_obj.subtotal = (field.subtotal) ? field.subtotal : 0;
        _obj.total_amount = (field.total_amount) ? field.total_amount : 0;    
        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);
        */

        /*Add by AK */
        _obj.subtotal = field.subtotal ? field.subtotal : 0;
        _obj.total_amount = field.total_amount ? field.total_amount : 0;

        _obj.normal_quantity = finalQty ? finalQty : 0;
        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);
        /*Add by AK */

        _receivedAmount = this.state.receivedAmount;
        _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.receivedAmount);
        _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);

        if (this.state.FreeItemQuantity == 1) {
          _quantity = parseFloat(_quantity) + parseFloat(_obj.free_quantity);
        }
        
        _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(field.discount_in_amount);
        _totalGstAmt = parseFloat(_totalGstAmt) + parseFloat(field.tax_value);

        _discountAmtAk_1 = field.discount_in_amount ? field.discount_in_amount : 0;
        _discountTaxAk_1 = field.tax_value ? field.tax_value : 0;
        _amountDisK_1 = parseFloat(_amountDisK_1) + parseFloat(_discountAmtAk_1);
        if (_businessSetting.ItemWiseTax == 1) {
          _amountTaxK_1 = parseFloat(_amountTaxK_1) + parseFloat(_discountTaxAk_1);
        }
      }
    });

    if (_dueAmount > 0) {
      _dueAmount = _dueAmount.toFixed(2);
    }

    if (_totalDiscountAmt > 0) {
      _totalDiscountAmt = _totalDiscountAmt; //.toFixed(2);
    }
    if (_totalGstAmt > 0) {
      _totalGstAmt = _totalGstAmt; //.toFixed(2);
    }

    //_totalAmt =  parseFloat(_totalAmt) + parseFloat(_totalGstAmt);
    //_totalAmt =  parseFloat(_totalAmt) + parseFloat(_amountTaxK_1);

    /*Final Calculation Discount and Tax */
    let _amountDisK = 0;
    let _amountTaxK = 0;
    let _discountTaxAk = 0;

    /*fieldArray.map((field, idx) => {
      let _discountAmtAk = field.discount_in_amount
        ? field.discount_in_amount
        : 0;
      _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);
      if (
        _businessSetting.AllowInclusiveExclusive == 1 &&
        field.sale_pruchase_tax_type == "Without Tax"
      ) {
        _discountTaxAk = field.tax_value ? field.tax_value : 0;
        _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
      }
      this.setState({
        totalDiscount: _amountDisK.toFixed(2),
        gstAmount: _amountTaxK.toFixed(2),
      });
    });*/

    _totalAmt = parseFloat(_totalAmt) ;//+ parseFloat(_amountTaxK) - parseFloat(_amountDisK);
    let _totalBalDue = parseFloat(_totalAmt) - parseFloat(this.state.paidAmount);
    /*Final Calculation Discount and Tax */

    console.log("==_totalGstAmt=="+_totalGstAmt);
    
    let _batchItemEnableAK = this.state.batchItemEnable;
    let _serialItemEnableAK = this.state.serialItemEnable;
    if(this.state.batchItemEnable == 1 && fieldArray.length == 0){
      _batchItemEnableAK = 0;
    }
    if(this.state.serialItemEnable == 1 && fieldArray.length == 0){
      _serialItemEnableAK = 0;
    }

    if (this.state.isMakePayment == false) {
      console.log("==ELSE==QQQQ==");
      if (this.state.toggleState == true) {
        this.setState({
          fields: fieldArray,
          totalQuantity: _quantity,
          dueAmount: 0,
          receivedAmount: 0,
          totalAmount: _totalAmt,
          totalAmountOld: _totalAmt,
          totalAmountNew: _totalAmt,
          gstAmount : _totalGstAmt,
          totalDiscount:_totalDiscountAmt,
          subtotal: _totalAmt,batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
        }); //,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
      } else {
        this.setState({
          fields: fieldArray,
          totalQuantity: _quantity,
          dueAmount: _totalBalDue,
          totalAmount: _totalAmt,
          totalAmountOld: _totalAmt,
          totalAmountNew: _totalAmt,
          gstAmount : _totalGstAmt,
          totalDiscount:_totalDiscountAmt,
          subtotal: _totalAmt,
          batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
        }); //,gstAmount : _totalGstAmt,totalDiscount:_totalDiscountAmt
      }

      this.setState({ itemArrayFilter: data });
    } else {
      if (fieldArray.length > 0) {
        console.log("==ELSE==AA==");
        _totalAmt = _totalAmt.toFixed(2);

        if (this.state.toggleState == true) {
          this.setState({
            fields: fieldArray,
            totalQuantity: _quantity,
            dueAmount: 0,
            receivedAmount: 0,
            totalAmount: _totalAmt,
            totalAmountOld: _totalAmt,
            totalAmountNew: _totalAmt,
            gstAmount : _totalGstAmt,
            totalDiscount:_totalDiscountAmt,
            subtotal: _totalAmt,
            batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
          });
        } else {
          this.setState({
            fields: fieldArray,
            totalQuantity: _quantity,
            dueAmount: _totalBalDue,
            totalAmount: _totalAmt,
            totalAmountOld: _totalAmt,
            totalAmountNew: _totalAmt,
            subtotal: _totalAmt,
            gstAmount : _totalGstAmt,
            totalDiscount:_totalDiscountAmt,
            subtotal: _totalAmt,
            batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
          });
        }
        this.setState({ fields: fieldArray, itemArrayFilter: data });

        let _totalAmtCAK = _totalAmt;
        let _dueAmountCAK = _totalAmt;
        if (this.state.paidAmount > 0) {
          _dueAmountCAK = _totalBalDue;
        }
        let _paidAM = this.state.paidAmount;
        let _totalAmountNewSend = _totalAmt;
        let _totalAmountOldSend = _totalAmt;
        let _totalDiscount = _totalDiscountAmt;//_amountDisK;
        let _totalGstAmtCAK = _totalGstAmt;//_amountTaxK;
        let _totalQuantity = _quantity;
        let handleAddDisable = "Disabled";
        if (fieldArray.length >= 1) {
          handleAddDisable = "";
        }
        let _fieldData = fieldArray;
        this.props.callBackItem(
          _fieldData,
          _totalAmountOldSend,
          _totalAmountNewSend,
          _totalAmtCAK,
          _dueAmountCAK,
          _paidAM,
          _totalDiscount,
          _totalGstAmtCAK,
          _totalQuantity,
          handleAddDisable,
          this.state.handleItemSalePurchasePrice
        );
      } else {
        console.log("==ELSE==WWW==");
        this.setState({
          totalAmount: 0,
          fields: fieldArray,
          itemArrayFilter: data,
          totalQuantity: 0,
          totalAmountOld: 0,
          totalAmountNew: 0,
          gstAmount: 0,
          totalDiscount: 0,
          subtotal: 0,
          batchItemEnable:_batchItemEnableAK,serialItemEnable:_serialItemEnableAK
        });

        let _totalAmtCAK = 0;
        let _dueAmountCAK = 0;
        let _paidAM = 0;
        let _totalAmountNewSend = 0;
        let _totalAmountOldSend = 0;
        let _totalDiscount = 0;
        let _totalGstAmtCAK = 0;
        let _totalQuantity = 0;
        let handleAddDisable = "Disabled";
        let _fieldData = fieldArray;
        this.props.callBackItem(
          _fieldData,
          _totalAmountOldSend,
          _totalAmountNewSend,
          _totalAmtCAK,
          _dueAmountCAK,
          _paidAM,
          _totalDiscount,
          _totalGstAmtCAK,
          _totalQuantity,
          handleAddDisable,
          this.state.handleItemSalePurchasePrice
        );
      }
    }
    //this.setState({fields:newFields,itemArrayFilter:data});
    this.getReadyItemList();
  }

  handleAddNewSerialPopup(
    selectItemData,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice
  ) {
    if (this.state.isFree == 1) {
      this.setState({
        modalIsOpen_AddNewFreeSerialNo: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemAddSerialNo: [],
        serialItemEnable: 1,
        itemPrice: _itemPrice,
        selectType: 2,
      });
    } else {
      this.setState({
        modalIsOpen_AddNewSerialNo: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemAddSerialNo: [],
        serialItemEnable: 1,
        itemPrice: _itemPrice,
        selectType: 2,
      });
    }
  }

  handleAddNewBatchPopup(
    selectItemData,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice
  ) {
    this.setState({
      modalIsOpen_AddNewBatchNo: true,
      itemId: itemId,
      name: itemName,
      itemPosition: _itemPosition,
      itemPrice: _itemPrice,
      batchItemEnable: 1,
      itemAddBatchNo: [],
      batchSendType: "New",
    });
  }

  handleNotSerialPopup(
    norSrNo,
    itemId,
    itemName,
    _itemPosition,
    _itemPrice,
    selectTypeTxt,
    _normal_quantity
  ) {
    //this.setState({modalIsOpen_SerialBatchNo:false, modalIsOpen_NotSerialNo: true, itemId:itemId, name:itemName, itemPosition:_itemPosition,itemPrice:_itemPrice,norSrNo :norSrNo,normal_quantity:_normal_quantity});
    if (selectTypeTxt == "Free") {
      this.setState({
        modalIsOpen_SerialBatchNo: false,
        modalIsOpen_NotSerialNoFree: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemPrice: _itemPrice,
        norSrNo: norSrNo,
        selectTypeTxt: selectTypeTxt,
        normal_quantity: _normal_quantity,
      });
    } else {
      this.setState({
        modalIsOpen_SerialBatchNo: false,
        modalIsOpen_NotSerialNo: true,
        itemId: itemId,
        name: itemName,
        itemPosition: _itemPosition,
        itemPrice: _itemPrice,
        norSrNo: norSrNo,
        normal_quantity: _normal_quantity,
        selectTypeTxt: selectTypeTxt,
      });
    }
  }

  clickSrialBatchD(
    _position,
    event,
    _dataBS,
    _arrayData,
    _itemId,
    _itemName,
    _itemPrice
  ) {
    if (event == "Serial") {
      this.setState({
        modalIsOpen_BatchViewPopup: true,
        popuptype: event,
        popupVal: _dataBS,
        itemSerialNo: _arrayData,
        itemId: _itemId,
        name: _itemName,
        itemPrice: _itemPrice,
        position: _position,
        itemRemovePosition: _position,
      });
    } else if (event == "Batch") {
      if (_businessSetting.BatchNoChk == 0) {
        alert(
          "Your batch settings are disabled, Please enable the batch setting from the item section!"
        );
      } else {
        this.setState({
          modalIsOpen_BatchViewPopup: true,
          popuptype: event,
          popupVal: _dataBS,
          itemBatchNo: _arrayData,
          itemId: _itemId,
          name: _itemName,
          itemPrice: _itemPrice,
          position: _position,
          itemRemovePosition: _position,
        });
      }
    }
  }

  serialPopChnage(e, batchSendType, batchNo, element, position) {
    if (e == "modalAddSerialNo") {
      this.setState({
        modalIsOpen_AddNewSerialNo: true,
        modalIsOpen_BatchViewPopup: false,
      });
    }
    if (e == "modalAddBatchNo") {
      let _opening_stock = element.opening_stock ? element.opening_stock : 0;
      let _free_quantity = element.free_quantity ? element.free_quantity : 0;
      let OldBatTotalQty =
        parseFloat(_opening_stock) + parseFloat(_free_quantity);

      this.setState({
        modalIsOpen_AddNewBatchNo: true,
        modalIsOpen_BatchViewPopup: false,
        batchSendType: batchSendType,
        batchNo: batchNo,
        itemAddBatchNo: [element],
        OldBatTotalQty: OldBatTotalQty,
        OldBatStockTotalQty: _opening_stock,
        itemPosition: position,
      });
    }
  }

  removeSelectedSrData(srId, _itemId, _delType, _position) {
    let _serialGetData = this.state.fields;
    let _newQuantity = this.state.totalQuantity;
    let _oldTotalAmountOld = this.state.totalAmountOld;
    let _oldtotalAmt = 0;
    let _fieldAmt = 0;
    let _fieldQty = 0;
    let _popupVal = this.state.popupVal;
    let normalQtySel = _popupVal.filter(
      (popNSelData) => popNSelData.isSelected == true
    );
    let normalFreeQtySel = _popupVal.filter(
      (popNSelFData) => popNSelFData.isFreeQuantity == true
    );
    let normalFreeQtySelLen = normalFreeQtySel ? normalFreeQtySel.length : 0;
    let normalQtySelLeng = normalQtySel ? normalQtySel.length : 0;
    
    let finalLength =
      parseFloat(normalQtySelLeng) - parseFloat(normalFreeQtySelLen);

    if (_delType == "N" && this.state.popupVal.length > 1) {
      if (finalLength <= 1) {
        alert(
          "Only free item cannot be transacted, it is necessary to add at least one item which have amount."
        );
        return false;
      }
    }
    if (this.state.popupVal.length == 1) {
      alert(
        "If you do not want any single serial of this item, then remove the item or keep at least one serial"
      );
      return false;
    }
    let _itemSerialNo = [];
    let dataItem = this.state.itemArray;

    dataItem.filter(function (item, index) {
      if (item.item_id == _itemId) {
        if (item.itemSerialNo && item.itemSerialNo!="") {
          _itemSerialNo = item.itemSerialNo;
          _itemSerialNo.filter(function (itemSrD, index) {
            if (itemSrD.serial_no == srId && itemSrD.isFreeQuantity!=true) {
              itemSrD.isSelected = false;
              itemSrD.dataCheck = 1;
            }
            if (itemSrD.serial_no == srId && itemSrD.isFreeQuantity == true) {
              itemSrD.isSelected = false;
              itemSrD.isFreeQuantity = false;
              itemSrD.dataCheck = 1;
            }
            if (itemSrD.serial_no!=srId && itemSrD.isFreeQuantity == true) {
              itemSrD.isSelected = true;
              itemSrD.isFreeQuantity = true;
              itemSrD.dataCheck = 0;
            }
          });
        }
      }
    });

    let _srialDataAk = [];

    let updatedSrData = _serialGetData.map((itemD, index) => {
      _fieldQty = itemD.quantity - 1;
      _fieldAmt = parseFloat(_fieldQty) * parseFloat(itemD.rate);

      if (_delType == "F" && itemD.item_id == _itemId) {
        if (itemD.free_quantity > 1) {
          if (this.state.itemRemovePosition == index) {
            itemD.free_quantity = itemD.free_quantity - 1;
            if (itemD.availLenAk == 0 || itemD.availLenAk == "0") {
              itemD.availLenAk = 1;
            }
            _newQuantity = _newQuantity; // - 1;
          }
        } else {
          if (this.state.itemRemovePosition == index) {
            itemD.free_quantity = 0;
            if (itemD.availLenAk == 0 || itemD.availLenAk == "0") {
              itemD.availLenAk = 1;
            }
            _newQuantity = _newQuantity;
          }
        }
      }
      if (this.state.itemRemovePosition == index) {
        if (_delType == "N" && itemD.item_id == _itemId) {
          itemD.itemSerialNoAllData.map((rmvSrId, i) => {
            if (srId == rmvSrId.serial_no) {
              itemD.quantity = itemD.quantity - 1;
              itemD.total_amount = _fieldAmt;
              _newQuantity = _newQuantity - 1;
              _oldtotalAmt = 1 * itemD.rate;
            }
          });

          if (_delType == "N") {
            _srialDataAk = itemD.itemSerialNoAllData.map((srData) => ({
              ...srData,
              isSelected: srData.serial_no == srId ? false : false,
              dataCheck: srData.serial_no == srId ? 1 : 1,
            }));
          }

          itemD.itemSerialNoAllData = _srialDataAk.filter(
            (serialDataAk) => serialDataAk.serial_no!=srId
          );
          itemD.itemSerialNo = _itemSerialNo; //_srialDataAk;
        }
      }

      if (_delType == "F") {
        /*_srialDataAk = itemD.itemSerialNoAllData;
                    _srialDataAk.map((itemSrD,i) => {
                        console.log(srId+"==srId=="+itemSrD.serial_no);
                        if(itemSrD.serial_no == srId && itemSrD.isFreeQuantity == true){ 
                            console.log("---OUT 22---");
                            itemSrD.isSelected = false;
                            itemSrD.isFreeQuantity = false;
                            itemSrD.dataCheck  = 1;                            
                        }
                    })*/
        if (this.state.itemRemovePosition == index) {
          _srialDataAk = itemD.itemSerialNoAllData.map((srDataF) => ({
            ...srDataF,
            isSelected: srDataF.serial_no == srId ? false : srDataF.isSelected,
            isFreeQuantity:
              srDataF.serial_no == srId ? false : srDataF.isFreeQuantity,
            dataCheck: srDataF.serial_no == srId ? 1 : srDataF.dataCheck,
          }));

          itemD.itemSerialNoAllData = _srialDataAk.filter(
            (serialDataAk) => serialDataAk.serial_no!=srId
          );
          itemD.itemSerialNo = _itemSerialNo; //_srialDataAk;
        }
      }
      return itemD;
    });

    if (_delType == "F") {
      _newQuantity = _newQuantity - 1;
    }
    _oldTotalAmountOld = _oldTotalAmountOld - _oldtotalAmt;

    let _popupData = this.state.popupVal;
    let updatedPopupData = _popupData.filter(
      (serialDataAk) => serialDataAk.serial_no!=srId
    );

    this.setState({
      popupVal: updatedPopupData,
      itemSerialNo: _itemSerialNo,
      fields: updatedSrData,
      dueAmount: _oldTotalAmountOld.toFixed(2),
      totalAmount: _oldTotalAmountOld.toFixed(2),
      subtotal: _oldTotalAmountOld.toFixed(2),
      totalAmountOld: _oldTotalAmountOld.toFixed(2),
      totalAmountNew: _oldTotalAmountOld.toFixed(2),
      totalQuantity: _newQuantity,
    });
    this.state.popupVal = updatedPopupData;

    let _totalAmt = _oldTotalAmountOld;
    let _dueAmount = _oldTotalAmountOld;
    let _paidAM = this.state.paidAmount;
    let _totalAmountNewSend = _oldTotalAmountOld;
    let _totalAmountOldSend = _oldTotalAmountOld;
    let _totalDiscount = this.state.totalDiscount;
    let _totalGstAmt = this.state.gstAmount;
    let _totalQuantity = _newQuantity;
    let handleAddDisable = "";
    let _fieldData = this.state.fields;

    this.props.callBackItem(
      _fieldData,
      _totalAmountOldSend,
      _totalAmountNewSend,
      _totalAmt,
      _dueAmount,
      _paidAM,
      _totalDiscount,
      _totalGstAmt,
      _totalQuantity,
      handleAddDisable,
      this.state.handleItemSalePurchasePrice
    );
  }

  
  handleDynamicInputSalePurTaxType(i, type, event) {
    let _itemPosition = i;
    let _itemName,_itemPrice,_itemId, _value = "";
    let _transCount = 0;
    let fieldArray = this.state.fields;    
    let discountAmt = 0;   
    let totalAmount = 0;
    let tax = 0;
    let amountBeforeTaxWithoutDisc = 0;
    let disc = 0;
    let amountBeforeTax = 0 ;
    let subTotal = 0;    
    let _discountAmtSend = 0;
    let _taxAmtSend = 0;
    let _itemToalAmtSend = 0;
    let _costPrice = 0;
    let _oldTotalItemAmt = 0; 
    let _gstObj = {};
    if (fieldArray.length > 0) {     
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {          
            let qty = field.quantity ? field.quantity : 0;
            let rateValue = parseFloat(field.rate);
            let taxType = parseFloat(field.tax_type.ratio) ? parseFloat(field.tax_type.ratio) : 0.0;
            let additionalCess = parseFloat(field.Additional_CESS) ? parseFloat(field.Additional_CESS) : 0;
            let discount_in_per =  parseFloat(field.discount_in_per) ?  parseFloat(field.discount_in_per) : 0
            let discount_in_amount =  parseFloat(field.discount_in_amount) ?  parseFloat(field.discount_in_amount) : 0;
            subTotal = rateValue * qty;
             _oldTotalItemAmt = field.total_amount ? field.total_amount : 0;
            console.log("==d==",JSON.stringify(field));
            _gstObj.id = field.tax_type.id;
            _gstObj.name = field.tax_type.name;
            _gstObj.ratio = field.tax_type.ratio;
            _value = event.target.value;
            if (_value == 'With Tax' && taxType > 0)
            {                 
              console.log(subTotal+"==yyyyy==",_value);    
              if(field.discount_in_amount > 0){ 
                if(taxType > 0){
                  _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                }else{
                  _costPrice = subTotal;
                }          
                discountAmt = discount_in_amount;// * qty;
                amountBeforeTax = _costPrice - discountAmt;
                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                tax = amountBeforeTax * taxType / 100;
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;

                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);

                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;  
              }
              else{
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
                discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                console.log(amountBeforeTaxWithoutDisc+"===disc==",disc) ;
                totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);

                //Discount amont == disc;
                disc = disc.toFixed(2);
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = disc;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
            }

            else if (_value == 'With Tax' && (taxType == 0 || taxType == '0')) 
            {      
              console.log(subTotal+"==uuu==",_value);
              if(field.discount_in_amount > 0){     
                if(taxType > 0){
                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                }else{
                    _costPrice = subTotal;
                }                 
                discountAmt = discount_in_amount;// * qty;
                
                amountBeforeTax = _costPrice - discountAmt;
                disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                tax = amountBeforeTax * taxType / 100;
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);

                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{
                _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);                    
                discountAmt = (discount_in_per / 100) * subTotal;
                amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                disc = amountBeforeTaxWithoutDisc * discount_in_per / 100;
                tax = (amountBeforeTaxWithoutDisc - disc) * taxType / 100;
                totalAmount = (amountBeforeTaxWithoutDisc - disc) + tax + parseFloat(additionalCess);

                //Discount amont == disc;
                disc = disc.toFixed(2);
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = disc;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;               
              }
            }   

            else {
              if(field.discount_in_amount > 0){ 
                console.log(subTotal+"==qqq==",_value);     
                _costPrice = subTotal;           
                discountAmt = discount_in_amount;// * qty;
                amountBeforeTax = subTotal - discountAmt;
                tax = amountBeforeTax * taxType / 100;
                //totalAmount = amountBeforeTax + tax + (qty * additionalCess);
                totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                
                console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTax+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');   
                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }
              else{    
                console.log(subTotal+"==zz==",_value);              
                _costPrice = subTotal;          
                discountAmt = (discount_in_per / 100) * subTotal;
                amountBeforeTaxWithoutDisc = subTotal - discountAmt;
                tax = (amountBeforeTaxWithoutDisc) * taxType / 100;
                totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);         

                console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTaxWithoutDisc+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');  

                discountAmt = discountAmt.toFixed(2);
                tax = tax.toFixed(2);
                totalAmount = totalAmount.toFixed(2);
                
                _discountAmtSend = discountAmt;
                _taxAmtSend = tax;
                _itemToalAmtSend = totalAmount;
              }    
          }
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = _gstObj;//{ id: "0", name: "", ratio: "0" };
          console.log("==_objTaxType==",_objTaxType);
          let discountPer  = (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
          // if(_value == 'Without Tax'){
          //   discountPer = _taxAmtSend;
          // }
          console.log(discountPer+'==>--discountPer-->=='+_costPrice+'==>_costPrice>=='+_discountAmtSend+'==>_discountAmtSend>==');

          _obj.trans_count = field.trans_count ? field.trans_count : 0;
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.Additional_CESS = additionalCess;
          _obj.discount_type =  field.discount_type ? field.discount_type : '';
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = discountPer;
          _obj.discount_in_amount = field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = _objTaxType//'';
          _obj.tax_value = _taxAmtSend;//field.tax_value ? field.tax_value : 0;
          _obj.sale_pruchase_tax_type = _value;
          _obj.subtotal = totalAmount;
          _obj.total_amount = totalAmount;//field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData: {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData : {};
          fieldArray[_itemPosition] = _obj;
        }
        
      console.log(_discountAmtSend+"==>discountAmt 22-->=="+_taxAmtSend+"==>tax-->=="+_itemToalAmtSend+"==>totalAmount-->==");   
      });
      console.log(this.state.totalAmount+'==>>=='+this.state.totalAmountOld+"==>=="+this.state.totalAmountNew+"==>>=="+this.state.totalAmountOldFree+"==>>=="+_oldTotalItemAmt);
      this.setState({ fields: fieldArray });

      let _totalAmountOld = 0;
      if(this.state.fields.length == 1){    
        _totalAmountOld = _itemToalAmtSend;
      }
      else{
        //_totalAmountOld = (parseFloat(_itemToalAmtSend)+ parseFloat(this.state.totalAmountOld)) - parseFloat(_oldTotalItemAmt) ;        
        this.state.fields.map((data) => {
          _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(data.total_amount);  
        })
      }
      let _totalAmountNew = _totalAmountOld;
      let _paidAM = 0;
      let _dueAmount = _totalAmountOld;

      let _transactionWiseTaxId = 0;
      let _totalTransTaxAmount = 0;
      let _totalTransDiscountPer = 0;
      let _totalTransDiscountAmount = 0;
      let _totalGstAmt = 0;
      let _totalDiscount = 0;
      
      if(this.state.fields.length == 1){                     
        _totalDiscount = _discountAmtSend;
        _totalGstAmt = _taxAmtSend;
      }
      else{                 
        // _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
        // _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
        fieldArray.map((field, index) => {
          if (index!=_itemPosition) {            
          _totalDiscount+= parseFloat(_discountAmtSend) + parseFloat(field.discount_in_amount);
          _totalGstAmt+= parseFloat(_taxAmtSend) + parseFloat(field.tax_value);
          }
        })
      }
      console.log(_taxAmtSend+"==>_taxAmtSend-->=="+_totalGstAmt+"==>_totalGstAmt-->==");   
      this.setState({
        subtotal: _totalAmountOld,
        totalAmountOld: _totalAmountOld,
        gstAmount: _totalGstAmt,
        totalDiscount: _totalDiscount,
      });
      let _totalAmt = _totalAmountOld;

      if(this.state.transactionId > 0){
        this.setState({
          totalAmount: _totalAmountOld,
        });
      }
      this.props.callBackItem(
        fieldArray,
        _totalAmountOld,
        _totalAmountNew,
        _totalAmt,
        _dueAmount,
        _paidAM,
        _totalDiscount,
        _totalGstAmt,
        this.state.totalQuantity,
        this.state.handleAddDisable,
        this.state.handleItemSalePurchasePrice,
        _transactionWiseTaxId,
        _totalTransTaxAmount,
        _totalTransDiscountPer,
        _totalTransDiscountAmount
      );

    }
  }

  handleDynamicInputsTransCount(i, type, event) {
    let _itemPosition = i;
    let _itemName,
      _itemPrice,
      _itemId,
      _value = "";
    let _transCount = 0;
    const fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
          _value = event.target.value;
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = { id: "0", name: "", ratio: "0" };
          _obj.trans_count = field.trans_count ? field.trans_count : 0;

          if (type == "trans_count") {
            _transCount = _value;
            if (_transCount == "" || _transCount == "null") {
              _transCount = 0;
            }
            _obj.trans_count = _transCount;
          }
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = field.discount_in_per
            ? field.discount_in_per
            : 0;
          _obj.discount_in_amount = field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = field.tax_type ? field.tax_type : _objTaxType;
          _obj.tax_value = field.tax_value ? field.tax_value : 0;
          _obj.subtotal = field.subtotal ? field.subtotal : 0;
          _obj.total_amount = field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData : {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData: {};
          fieldArray[_itemPosition] = _obj;
        }
      });
      this.setState({ fields: fieldArray });
    }
  }

  onChangeCalculation(i, type, event) {
    console.log(event.target.value+"===>--i-->=="+i+"==>-type-->==="+type);
    let _itemPosition = i;
    let _itemName,_itemPrice,_itemId, _value = "";
    let _transCount = 0;
    let fieldArray = this.state.fields;    
    let discountAmt = 0;   
    let totalAmount = 0;
    let tax = 0;
    let amountBeforeTaxWithoutDisc = 0;
    let disc = 0;
    let amountBeforeTax = 0 ;
    let subTotal = 0;    
    let _subTotalCheck = 0;
    let _discountAmtSend = 0;
    let _taxAmtSend = 0;
    let _itemToalAmtSend = 0;
    let _costPrice = 0;
    let _oldTotalItemAmt = 0;
    let handleItemSalePurchasePrice = this.state.handleItemSalePurchasePrice;
    let _gstObj = {};
    if (fieldArray.length > 0) {     
      fieldArray.map((field, index) => {
        let _itemIdK = field.item_id ? field.item_id : 0;
        if (index == _itemPosition && _itemIdK > 0) { 
            _value = event.target.value;       
            let qty = field.quantity ? field.quantity : 0;
            let rateValue = parseFloat(field.rate) ? field.rate : 0;
            let taxType = parseFloat(field.tax_type.ratio) ? parseFloat(field.tax_type.ratio) : 0.0;
            let additionalCess = parseFloat(field.Additional_CESS) ? parseFloat(field.Additional_CESS) : 0;
            let discount_in_per =  parseFloat(field.discount_in_per) ?  parseFloat(field.discount_in_per) : 0
            let discount_in_amount =  parseFloat(field.discount_in_amount) ?  parseFloat(field.discount_in_amount) : 0;
            
            if(type == 'rate'){
              rateValue = parseFloat(_value) ? _value : 0;
              handleItemSalePurchasePrice = "";
              discount_in_per = 0;
              discount_in_amount = 0;
            }
            _subTotalCheck = parseFloat(rateValue) * parseFloat(qty);

            
            if(type == 'discount_in_per' && (_value == '' || _value == null)){
              discount_in_per = 0;
              discount_in_amount = 0;
            }
            if(type == 'discount_in_amount' && (_value == '' || _value == null)){
              discount_in_amount = 0;
              discount_in_per = 0;
            }
            if(type == 'Additional_CESS' && (_value == '' || _value == null)){
              additionalCess = 0;
            }
            if(type == 'rate' && (_value == '' || _value == null ||  _value <= 0)){
              rateValue = 0;
            }
            subTotal = parseFloat(rateValue) * parseFloat(qty);
            
            if(field.tax_type.ratio > 0){              
              _subTotalCheck = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
            }
            
            
            if(type!='taxType'){              
              _gstObj.id = field.tax_type.id;
              _gstObj.name = field.tax_type.name;
              _gstObj.ratio = field.tax_type.ratio;
            }
            if(type == 'rate'){
              rateValue = parseFloat(_value) ? _value : 0;
              handleItemSalePurchasePrice = "";
              discount_in_per = 0;
              discount_in_amount = 0;
            }
            else if(type == 'Additional_CESS'){
              additionalCess = _value;
            }
            else if(type == 'discount_in_per'){
              if(_value > 100){
                alert("Discount cannot be more than 100%");
              }else{
                discount_in_per = _value;
                if(_value == ''){
                  discount_in_per = 0;
                }
              }
            }
            else if(type == 'discount_in_amount'){
              //discount_in_amount = _value;
              if(parseFloat(_value) > parseFloat(subTotal)) {
                //alert("Discount cannot be more than total item amount");
                discount_in_amount = parseFloat(_value) >0 ? parseFloat(_value) : 0;//field.discount_in_amount;
                discount_in_per = 0;
              }else{
                discount_in_amount = parseFloat(_value) >0 ? parseFloat(_value) : 0;
                discount_in_per = 0;
              }
            } 
            
            else if(type == 'taxType'){
              if(event.target.value > 0){
                this.state.taxRateArray.map((tax, index) => {       
                  if (_value == tax.id) {
                    taxType = tax.ratio ? tax.ratio : 0;
                    _gstObj.id = tax.id;
                    _gstObj.name = tax.name;
                    _gstObj.ratio = tax.ratio;
                  }
                })
              }else{
                taxType =  0;
                _gstObj={};
              }
            }
            
            if(additionalCess == ''){
              additionalCess = 0;
            }
            _oldTotalItemAmt = field.total_amount ? field.total_amount : 0;

            if(type == 'discount_in_amount' && (_value > subTotal)){
              alert("Discount cannot be more than total item amount ");
              _discountAmtSend = this.state.totalDiscount;
              _taxAmtSend = this.state.gstAmount;
              _itemToalAmtSend = this.state.totalAmount;
              return;
            } 
            //else{
              if(type == 'discount_in_per'){             
                discount_in_amount = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
              }

              if (field.sale_pruchase_tax_type == 'With Tax' && taxType > 0)
              {     
                console.log("==$$$$$$$==");            
  
                if(_value > 0 && type == 'discount_in_amount'){
                  console.log("==!!!!==");
                  if(taxType > 0){
                    _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                  }else{
                    _costPrice = subTotal;
                  }    
                  if(type == 'rate'){
                    discountAmt = 0;
                    amountBeforeTax = subTotal;
                    amountBeforeTaxWithoutDisc  = subTotal;
                    disc = 0;
                    tax = 0;
                    _gstObj={};
                  }
                  else{      
                    discountAmt = parseFloat(discount_in_amount);// * qty;
                    amountBeforeTax = parseFloat(_costPrice) - parseFloat(discountAmt);
                    disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                    tax = amountBeforeTax * taxType / 100;
                    console.log(discount_in_amount+"==NEH==",discountAmt)
                  }
                  totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
  
                  discountAmt = discountAmt.toFixed(2);
                  tax = tax.toFixed(2);
                  totalAmount = totalAmount.toFixed(2);
  
                  _discountAmtSend = discountAmt;
                  _taxAmtSend = tax;
                  _itemToalAmtSend = totalAmount;  
                }
                else{
                  console.log("==%%%%%%%==");
                  _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
                  
                  if(type == 'rate'){
                    discountAmt = 0;
                    amountBeforeTax = subTotal;
                    amountBeforeTaxWithoutDisc  = subTotal;
                    disc = 0;
                    tax = 0;
                    _gstObj={};
                  }
                  else{
                    discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                    amountBeforeTaxWithoutDisc = qty * ((rateValue) / (1 + (taxType / 100)));
                    disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                    tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                  }
                  console.log(amountBeforeTaxWithoutDisc+"===disc=="+disc+"===tax=="+tax+"===taxType=="+taxType) ;
                  totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);
  
                  //Discount amont == disc;
                  disc = disc.toFixed(2);
                  discountAmt = disc;//discountAmt.toFixed(2);
                  tax = tax.toFixed(2);
                  totalAmount = totalAmount.toFixed(2);
                  
                  _discountAmtSend = disc;
                  _taxAmtSend = tax;
                  _itemToalAmtSend = totalAmount;
                }
              }
  
              else if (field.sale_pruchase_tax_type == 'With Tax' && (taxType == 0 || taxType == '0')) 
              {      
                console.log(subTotal+"==uuu==",_value);
                let _disAmtHere = field.discount_in_amount;
                if(type == 'rate'){
                  _disAmtHere = 0;
                }
                if(type == 'discount_in_amount'){
                  _disAmtHere = _value;
                }
                if(_disAmtHere > 0){
                  if(taxType > 0){
                      _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);   
                  }else{
                      _costPrice = subTotal;
                  }    
                  if(type == 'rate'){
                    discountAmt = 0;
                    amountBeforeTax = subTotal;
                    amountBeforeTaxWithoutDisc  = subTotal;
                    disc = 0;
                    tax = 0;
                    _gstObj={};
                  }
                  else{            
                    discountAmt = discount_in_amount;// * qty;                
                    amountBeforeTax = parseFloat(_costPrice) - parseFloat(discountAmt);
                    disc = (parseFloat(discountAmt) / parseFloat(rateValue)) * parseFloat(100);
                    tax = parseFloat(amountBeforeTax) * parseFloat(taxType) / 100;
                  }
                  totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                  
                  discountAmt = discountAmt;
                  tax = tax.toFixed(2);
                  totalAmount = totalAmount.toFixed(2);
  
                  _discountAmtSend = discountAmt;
                  _taxAmtSend = tax;
                  _itemToalAmtSend = totalAmount;
                }
                else{
                  _costPrice = (parseFloat(subTotal) / (parseFloat(100) + parseFloat(taxType))) * parseFloat(100);
                  
                  if(type == 'rate'){
                    discountAmt = 0;
                    amountBeforeTax = subTotal;
                    amountBeforeTaxWithoutDisc  = subTotal;
                    disc = 0;
                    tax = 0;
                  }
                  else{                    
                    discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                    amountBeforeTaxWithoutDisc = parseFloat(qty) * (parseFloat(rateValue) / (1 + (parseFloat(taxType) / 100)));
                    disc = parseFloat(amountBeforeTaxWithoutDisc) * parseFloat(discount_in_per) / 100;
                    tax = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) * parseFloat(taxType) / 100;
                  }
                  totalAmount = (parseFloat(amountBeforeTaxWithoutDisc) - parseFloat(disc)) + parseFloat(tax) + parseFloat(additionalCess);
  
                  //Discount amont == disc;
                  disc = disc.toFixed(2);
                  discountAmt = discountAmt.toFixed(2);
                  tax = tax.toFixed(2);
                  totalAmount = totalAmount.toFixed(2);
                  
                  _discountAmtSend = disc;
                  _taxAmtSend = tax;
                  _itemToalAmtSend = totalAmount;               
                }
              }   
  
              else {
                console.log(_value+"==DIS==",field.discount_in_amount);
                let _disCountAmt =  field.discount_in_amount;
                if(type == 'discount_in_amount'){
                  _disCountAmt =  _value;
                }
                if(_disCountAmt > 0){    
                  _costPrice = subTotal;   
                  
                  if(type == 'rate'){
                    discountAmt = 0;
                    amountBeforeTax = subTotal;
                    amountBeforeTaxWithoutDisc  = subTotal;
                    disc = 0;
                    tax = 0;
                    _gstObj={};
                  }
                  else{        
                    if(discount_in_amount == ''){
                      discount_in_amount = 0;
                    }  
                    discountAmt = discount_in_amount;// * qty;
                    amountBeforeTax = parseFloat(subTotal) - parseFloat(discountAmt);
                    tax = parseFloat(amountBeforeTax) * parseFloat(taxType) / 100;
                  }
                  totalAmount = parseFloat(amountBeforeTax) + parseFloat(tax) + parseFloat(additionalCess) ;
                  
                  console.log(discountAmt+"==>discountAmt-->=="+amountBeforeTax+"==>tax-->=="+tax+"==>totalAmount-->=="+totalAmount+'==>final>==');   
                  discountAmt = discountAmt;//.toFixed(2);
                  tax = tax.toFixed(2);
                  totalAmount = totalAmount.toFixed(2);
                  
                  _discountAmtSend = discountAmt;
                  _taxAmtSend = tax;
                  _itemToalAmtSend = totalAmount;
                }
                else{                              
                  _costPrice = subTotal;      
                  
                  if(type == 'rate'){
                    discountAmt = 0;
                    amountBeforeTax = subTotal;
                    amountBeforeTaxWithoutDisc  = subTotal;
                    disc = 0;
                    tax = 0;
                    _gstObj={};
                  }
                  else{    
                    if(discountAmt == 0  || discountAmt == ''){
                      discount_in_per = 0;
                    }
                    discountAmt = (parseFloat(discount_in_per) / 100) * parseFloat(subTotal);
                    amountBeforeTaxWithoutDisc = parseFloat(subTotal) - parseFloat(discountAmt);
                    tax = (parseFloat(amountBeforeTaxWithoutDisc)) * parseFloat(taxType) / 100;
                  }
                  console.log(discountAmt+"==>DDD-->=="+amountBeforeTax+"==>TTT-->=="+tax+"==>TOT-->=="+totalAmount+'==>END->==');   
                  totalAmount = parseFloat(amountBeforeTaxWithoutDisc) + parseFloat(tax) + parseFloat(additionalCess);         
  
                  discountAmt = discountAmt.toFixed(2);
                  tax = tax.toFixed(2);
                  totalAmount = totalAmount.toFixed(2);
                  
                  _discountAmtSend = discountAmt;
                  _taxAmtSend = tax;
                  _itemToalAmtSend = totalAmount;
                }              
              }
            //}


            
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = _gstObj;//{ id: "0", name: "", ratio: "0" };

          let discountPer  = (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
          discountPer = discountPer.toFixed(2);
          if(type == 'discount_in_per'){
            discountPer = discount_in_per;
            discount_in_amount = discountAmt;
          }
          console.log("==discountPer==",discountPer);
          _obj.trans_count = field.trans_count ? field.trans_count : 0;
          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.trans_id = 0;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.Additional_CESS = additionalCess;
          _obj.rate = rateValue;
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = discountPer;//discountPer;
          _obj.discount_in_amount = discount_in_amount;//_discountAmtSend;//field.discount_in_amount ? field.discount_in_amount : 0;
          _obj.tax_type = _objTaxType;//field.tax_type ? field.tax_type : _objTaxType;
          _obj.tax_value = _taxAmtSend;//field.tax_value ? field.tax_value : 0;
          _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type ? field.sale_pruchase_tax_type : "";
          _obj.subtotal = totalAmount;
          _obj.total_amount = totalAmount;//field.total_amount ? field.total_amount : 0;
          _obj.availLenAk = field.availLenAk ? field.availLenAk : 0;
          _obj.totalStocks = field.totalStocks ? field.totalStocks : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData: {};
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData : {};
          fieldArray[_itemPosition] = _obj;
        }
        
      console.log(_discountAmtSend+"==>discountAmt 22-->=="+_taxAmtSend+"==>tax-->=="+_itemToalAmtSend+"==>totalAmount-->==");   
      });
      console.log(this.state.totalAmount+'==>>=='+this.state.totalAmountOld+"==>=="+this.state.totalAmountNew+"==>>=="+this.state.totalAmountOldFree+"==>>=="+_oldTotalItemAmt);

      this.setState({ fields: fieldArray });

      let _totalAmountOld = 0;
      if(this.state.fields.length == 1){    
        _totalAmountOld = _itemToalAmtSend;
      }
      else{
        //_totalAmountOld = (parseFloat(_itemToalAmtSend)+ parseFloat(this.state.totalAmountOld)) - parseFloat(_oldTotalItemAmt) ;
        
        this.state.fields.map((data) => {
          _totalAmountOld = parseFloat(_totalAmountOld) + parseFloat(data.total_amount);  
        })
      }
      let _totalAmountNew = _totalAmountOld;
      let _paidAM = 0;
      let _dueAmount = _totalAmountOld;

      let _transactionWiseTaxId = 0;
      let _totalTransTaxAmount = 0;
      let _totalTransDiscountPer = 0;
      let _totalTransDiscountAmount = 0;
      let _totalGstAmt = 0;
      let _totalDiscount = 0;
      
      if(this.state.fields.length == 1){                     
        _totalDiscount = _discountAmtSend;
        _totalGstAmt = _taxAmtSend;
      }
      else{                 
        // _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
        // _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
        fieldArray.map((field, index) => {
          if (index!=_itemPosition) {            
          _totalDiscount+= parseFloat(_discountAmtSend) + parseFloat(field.discount_in_amount);
          _totalGstAmt+= parseFloat(_taxAmtSend) + parseFloat(field.tax_value);
          }
        })
      }
      this.setState({
        subtotal: _totalAmountOld,
        totalAmount: _totalAmountOld,
        totalAmountOld: _totalAmountOld,
        gstAmount: _totalGstAmt,
        totalDiscount: _totalDiscount,
        handleItemSalePurchasePrice: handleItemSalePurchasePrice,
      });
      let _totalAmt = _totalAmountOld;

      this.props.callBackItem(
        fieldArray,
        _totalAmountOld,
        _totalAmountNew,
        _totalAmt,
        _dueAmount,
        _paidAM,
        _totalDiscount,
        _totalGstAmt,
        this.state.totalQuantity,
        this.state.handleAddDisable,
        handleItemSalePurchasePrice,
        _transactionWiseTaxId,
        _totalTransTaxAmount,
        _totalTransDiscountPer,
        _totalTransDiscountAmount
      );

    }
  }

  handleDynamicInputs(i, type, event) {
    let _itemPosition = i;
    console.log("==_itemPosition==", _itemPosition);
    console.log("==_TypeT==", type);
    let _itemName,
      _itemPrice,
      _itemId,
      _value,
      _itemType,
      _sale_pruchase_tax_type = "";
    let _base_unit_id = 0;
    let _unitindividual = [];
    let _taxTypeIndividual = { id: "0", name: "", ratio: "0" };
    let _discount_in_sale_price = 0;
    let _Additional_CESS = 0;
    let _discount_type = "Amount";
    console.log("==_Additional_CESS===", _Additional_CESS);
    this.setState({
      transactionWiseTaxId: 0,
      totalTransTaxAmount: 0,
      totalTransDiscountPer: 0,
      totalTransDiscountAmount: 0,
    });

    if (type == "item") {
      console.log(i.Additional_CESS + "==khare 3333==", i);
      _itemPosition = i.index;
      _itemPrice = i.price;
      _itemId = i.id;
      _itemName = i.name;
      _base_unit_id = i.base_unit_id;
      _Additional_CESS = i.Additional_CESS;
      _itemType = i.itemType;
      _sale_pruchase_tax_type = i.sale_pruchase_tax_type;
      if (_base_unit_id > 0) {
        _unitindividual = i.unitindividual;
      } else {
        _unitindividual = this.state.unitArray;
      }

      if (i.tax_type.id) {
        _taxTypeIndividual = i.tax_type;
      }

      _discount_in_sale_price = i.discount_in_sale_price;
      _discount_type = i.discount_type;

      /*Delete or disabled Data after select */
      //document.getElementById("item_name_" + _itemPosition).disabled = true;
      //document.getElementById("item_name_" + _itemPosition).readOnly = true;
      if (this.state.FreeItemQuantity == 1) {
        //document.getElementById("_free_quantity_"+_itemPosition).readOnly = false;
      }
      const elementUlDelete = document.getElementById(
        "itemListData_" + _itemPosition
      );
      //elementUlDelete.remove();
      /*Delete or disabled Data after select */

      let _itemSerialNoAllData = [];
      let _itemBatchNoAllData = [];
      let _serialGetData = "";
      let _BatchGetData = "";
      if (this.state.fields.length > 0) {
        let _fieldConditionData = this.state.fields;
        _fieldConditionData.map((fieldData, index) => {
          if (
            fieldData.itemSerialNoAllData &&
            fieldData.itemSerialNoAllData.length > 0
          ) {
            _itemSerialNoAllData = [
              ..._itemSerialNoAllData,
              ...fieldData.itemSerialNoAllData,
            ];
          }
          if (
            fieldData.itemBatchNoAllData &&
            fieldData.itemBatchNoAllData.length > 0
          ) {
            _itemBatchNoAllData.push(fieldData.itemBatchNoAllData);
          }
        });
      }
      this.setState({
        modalIsOpen_SerialBatchNo: true,
        selectItemData: i,
        itemId: i.id,
        name: i.name,
        itemPosition: _itemPosition,
        itemPrice: _itemPrice,
        selectType: 0,
        isFree: 0,
        selectTypeTxt: "Normal",
        itemType: _itemType,
      });
    } else {
      _value = event.target.value;
      _unitindividual = i.unitindividual;
      console.log("Here", _value);
      console.log("Here", _unitindividual);
    }

    const fieldArray = this.state.fields;
    let _quantity = 0;
    let _totalAmt = 0;
    let _paidAmount = this.state.paidAmount;
    let _dueAmount = 0;
    //let _totalDiscountAmt = 0;
    //let _totalGstAmt = 0;
    let _totalDiscountAmt = parseFloat(this.state.totalDiscount);
    let _totalGstAmt = parseFloat(this.state.gstAmount);
    let _itemPriceAK = 0;

    if (fieldArray.length == "1" && this.state.transactionId == 0) {
      _paidAmount = 0;
    }

    let handleItemSalePurchasePrice = "";
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        let _unitPreArray = field.unitArrayOld ? field.unitArrayOld : this.state.unitArray;

        if (index == _itemPosition) {
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = { id: "0", name: "", ratio: "0" };

          _itemPriceAK =  parseFloat(field.rate) * parseFloat(field.quantity); 

          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.free_quantity = field.free_quantity ? field.free_quantity : 0;
          _obj.trans_count = field.trans_count ? field.trans_count : 0;
          _obj.unit = field.unit ? field.unit : _objUnit;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = field.discount_in_per ? field.discount_in_per : 0;
          _obj.discount_in_amount = field.discount_in_amount ? field.discount_in_amount: 0;
          _obj.tax_type = field.tax_type ? field.tax_type : _objTaxType;
          _obj.tax_value = field.tax_value ? field.tax_value : 0;
          _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type ? field.sale_pruchase_tax_type : "";
          _obj.Additional_CESS = field.Additional_CESS ? field.Additional_CESS : 0;
          _obj.subtotal = field.subtotal ? field.subtotal : 0;
          _obj.total_amount = field.total_amount ? field.total_amount : 0;
          _obj.itemSerialNoAllData = field.itemSerialNoAllData ? field.itemSerialNoAllData : [];
          _obj.itemBatchNoAllData = field.itemBatchNoAllData ? field.itemBatchNoAllData : [];

          let _percGstAmt = 0;
          let _percDisAmt = 0;
          let _percDisPerc = 0;
          let _isNewItem = false;

          if (type == "unit") {
            let _unitObj = {};
            _unitPreArray.map((unit, index) => {
              if (_value == unit.unit_name) {
                _unitObj.unit_id = unit.unit_id;
                _unitObj.unit_name = unit.unit_name;
                _unitObj.old_unit_id = unit.unit_id;
              }
            });
            _obj.unit = _unitObj;
          }

          if (type == "item") {
            _isNewItem = true;
            _obj.item_id = _itemId;
            _obj.item_name = _itemName;
            _obj.rate = _itemPrice;
            _obj.subtotal = _itemPrice;
            _obj.total_amount = _itemPrice;
            _obj.quantity = 0;
            _obj.free_quantity = 0;
            _obj.trans_count = 0;
            _obj.Additional_CESS = field.Additional_CESS ? field.Additional_CESS : 0;
            _obj.itemSerialNoAllData = [];
            _obj.itemBatchNoAllData = [];
            if (_base_unit_id > 0) {
              _obj.unit = _unitindividual[0];
            } else {
              _obj.unit = _objUnit;
            }

            //_obj.unitArrayOld = _unitindividual;
            _obj.tax_type = _taxTypeIndividual;
            _obj.tax_value = i.tax_type.id ? i.tax_type.id : 0;
            _obj.sale_pruchase_tax_type = i.sale_pruchase_tax_type ? i.sale_pruchase_tax_type : "";
            _obj.discount_type =  _discount_type;
            _obj.discount_in_sale_price = _discount_in_sale_price;
          } else {
            //_obj.unitArrayOld = field.unitArrayOld;
          }

          if (type == "quantity") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            _obj.quantity = _value;
          }
          if (type == "free_quantity") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            let _itemPosData = this.state.fields[_itemPosition];
            let _serialLength = _itemPosData.itemSerialNoAllData.length;

            if (_serialLength == 0) {
              this.setState({
                modalIsOpen_NotSerialNoFree: true,
                itemId: field.item_id,
                name: field.item_name,
                itemPosition: _itemPosition,
                itemPrice: field.rate,
                norSrNo: i,
                selectTypeTxt: "Free",
                normal_quantity: field.free_quantity,
              });
            }
            if (_serialLength > 0) {
              let _itemFreeAddedSerialNo = _itemPosData.itemSerialNoAllData;
              _itemFreeAddedSerialNo = _itemFreeAddedSerialNo.filter(
                (srData) => srData.isFreeQuantity == true
              );

              this.setState({
                modalIsOpen_AddNewFreeSerialNo: true,
                itemId: field.item_id,
                name: field.item_name,
                itemPosition: _itemPosition,
                itemAddSerialNo: _itemFreeAddedSerialNo,
                serialItemEnable: 1,
                itemPrice: field.rate,
                selectType: 2,
                normal_quantity: field.free_quantity,
              });
            }
          }
          if (type == "trans_count") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            _obj.trans_count = _value;
          }
          if (type == "rate") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            if (_value == 0) {
              alert("Price can not be zero");
              handleItemSalePurchasePrice = "Disabled";
            }
            _obj.rate = _value;
            let _totalAmtRate = parseFloat(_value) * parseFloat(_obj.quantity);
            _totalAmtRate = _totalAmtRate + parseFloat(field.Additional_CESS);

            _obj.discount_in_per = 0;
            _obj.discount_in_amount = 0;
            _obj.tax_value = 0;
            _obj.tax_type = 0;
            _obj.total_amount = _totalAmtRate;
            console.log(_value + "==KHARE ==aaa==", _totalAmtRate);
            _totalGstAmt = 0;
            _totalDiscountAmt = 0;
          }

          let _totalItemAmount =
            parseFloat(_obj.rate) * parseFloat(_obj.quantity);
          let _itemSubtotal = _totalItemAmount;
          console.log(
            _totalItemAmount + "==here RRRR===" + _obj.Additional_CESS
          );
          if (_businessSetting.AdditionalCESS == 1) {
            if (_Additional_CESS > 0 || _obj.Additional_CESS > 0) {
              console.log(
                field.Additional_CESS + "==demo kkk==",
                _Additional_CESS
              );
              if (field.Additional_CESS > 0) {
                _Additional_CESS = field.Additional_CESS;
                console.log(
                  _Additional_CESS + "==here khare===",
                  field.Additional_CESS
                );
              }
              _obj.Additional_CESS = _Additional_CESS ? _Additional_CESS : 0;
              _itemSubtotal =
                parseFloat(_totalItemAmount) + parseFloat(_Additional_CESS);
              _obj.subtotal = _itemSubtotal;
              _obj.total_amount = _itemSubtotal;
            }
            if (type == "Additional_CESS") {
              console.log(_value + "==demo ==aaa==", type);
              _obj.Additional_CESS = _value;
              _itemSubtotal = parseFloat(_totalItemAmount) + parseFloat(_value);
              _obj.subtotal = _itemSubtotal;
              _obj.total_amount = _itemSubtotal;
            }
            console.log(_Additional_CESS + "==here zz===" + _itemSubtotal);
          }

          
          if (_businessSetting.ItemWiseDiscount == 1) {
            if (type == "discount_in_per") {
              if (_obj.tax_value > 0) {
                if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax") {
                  //_totalItemAmount =  parseFloat(_totalItemAmount) + parseFloat(_obj.tax_value);
                  //_totalItemAmount = parseFloat(_totalItemAmount) * (field.tax_type.ratio / 100);
                }
              }
              if (_value > 100) {
                alert("Discount cannot be more than 100%");
              } else {
                if (_value == "" || _value == "null") {
                  _value = 0;
                }
                _obj.discount_in_per = _value;
                if (_obj.discount_in_per >= 0) {
                  _percDisAmt = (parseFloat(_totalItemAmount) * parseFloat(_obj.discount_in_per)) /  parseFloat(100);
                }
                _obj.discount_in_amount = _percDisAmt.toFixed(2);
                
                if (_businessSetting.ItemWiseTax == 1 && _businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
                {
                  if (_obj.tax_value > 0) {
                    console.log(fieldArray.length+"==_totalGstAmt===11--", _totalGstAmt);
                    if(fieldArray.length == 1){
                      _totalGstAmt = parseFloat(_obj.tax_value);
                    }else{
                      _totalGstAmt = parseFloat(this.state.gstAmount);// parseFloat(_totalGstAmt) + parseFloat(_obj.tax_value);
                    }
                  } else {
                    _totalGstAmt = 0;
                  }
                }
              }
              _itemSubtotal = parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount);
                
              console.log(_totalItemAmount+"==>--dd-->=="+_itemSubtotal); 
              if (_businessSetting.ItemWiseDiscount == 1) {
                if (_obj.discount_in_amount > 0) {
                  _totalDiscountAmt = parseFloat(_obj.discount_in_amount);
                }
              }
              if(_itemSubtotal == 0 || _itemSubtotal == '0'){
                _obj.total_amount = 0;
              }else{
                _obj.total_amount = _itemSubtotal;
              }
            }

            if (type == "discount_in_amount") {
              if (_value > _itemPriceAK) {
                alert("Discount cannot be more than item total amount");
              } else {
                
                if (_value == "" || _value == "null") {
                  _value = 0;
                }
                if(_value <= _itemPriceAK){
                  _obj.discount_in_amount = _value;
                  let _percDisPerc = 0;
                  let _finalDisPrice = 0;
                  if (_obj.discount_in_amount >= 0) {
                    if (field.sale_pruchase_tax_type == "With Tax")
                    {
                      let _costPrice = (parseFloat(_itemPriceAK) / (100 + parseFloat(_obj.tax_type.ratio)) * 100);
                      let _finalDisPrice =  (parseFloat(_costPrice) - parseFloat(_value));
                      _percDisPerc = (_finalDisPrice * (100 + parseFloat(_obj.tax_type.ratio)) ) /100;
                     
                    }
                    if (field.sale_pruchase_tax_type == "Without Tax")
                    {
                      _finalDisPrice =  (parseFloat(_itemPriceAK) - parseFloat(_value));
                      
                      let _calculateAmt = parseFloat(_finalDisPrice) * (parseFloat(100) + parseFloat(_obj.tax_type.ratio));
                      _totalItemAmount = _calculateAmt/100;
                      _percDisPerc = ( parseFloat(_value) / parseFloat(_itemPriceAK)) * 100;
                    }
                  
                  } 
                  _percDisAmt = _obj.discount_in_amount;
                  _obj.discount_in_per = _percDisPerc.toFixed(2);
                }
                      
                if (_businessSetting.ItemWiseDiscount == 1) {
                  if (_obj.discount_in_amount > 0) {
                    if(fieldArray.length == 1){
                      _totalDiscountAmt = parseFloat(_obj.discount_in_amount);
                    }else{
                      _totalDiscountAmt = parseFloat(_totalDiscountAmt) + parseFloat(_obj.discount_in_amount);

                    }
                  }
                }                  
                _itemSubtotal = parseFloat(_itemPriceAK) - parseFloat(_obj.discount_in_amount);
                if(_itemSubtotal == 0 || _itemSubtotal == '0'){
                  _obj.total_amount = 0;
                }else{
                  _obj.total_amount = _itemSubtotal;
                }
                
                if (_businessSetting.ItemWiseTax == 1 && _businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax")
                {
                  if (_obj.tax_value > 0) {
                    console.log(fieldArray.length+"==_totalGstAmt===11--", _totalGstAmt);
                    if(fieldArray.length == 1){
                      _totalGstAmt = parseFloat(_obj.tax_value);
                    }else{
                      _totalGstAmt = parseFloat(this.state.gstAmount);// parseFloat(_totalGstAmt) + parseFloat(_obj.tax_value);
                    }
                  } else {
                    _totalGstAmt = 0;
                  }
                }
              }
              
            }
          }

          if (_isNewItem == true) {
            let _newDisAmt =
              _obj.discount_in_amount > 0 ? _obj.discount_in_amount : 0;
            if (_obj.discount_in_per > 0) {
              _newDisAmt =
                (parseFloat(_totalItemAmount) *
                  parseFloat(_obj.discount_in_per)) /
                parseFloat(100);
            }
            _obj.discount_in_amount = _newDisAmt.toFixed(2);
          }
         

          if (_businessSetting.ItemWiseTax == 1) {
            if (type == "taxType") {
              let _gstObj = {};
              this.state.taxRateArray.map((tax, index) => {
                let _sel = "";
                if (_value == tax.id) {
                  _gstObj.id = tax.id;
                  _gstObj.name = tax.name;
                  _gstObj.ratio = tax.ratio;

                  if (tax.ratio >= 0) {
                    if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax" ) 
                    {
                      _percGstAmt = ((parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount)) * parseFloat(tax.ratio)) / parseFloat(100);
                      console.log("==AK-1---",_percGstAmt);
                    }
                    else if (_businessSetting.AllowInclusiveExclusive == 0) {
                      _percGstAmt = ((parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount)) * parseFloat(tax.ratio)) / parseFloat(100);
                      console.log("==AK-2---",_percGstAmt);
                    }
                    else if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
                    {
                      let _costPrice = (parseFloat(_totalItemAmount) / (100 + parseFloat(tax.ratio)) * 100);
                      let _finalPercenTageP =  (parseFloat(_totalItemAmount) - parseFloat(_costPrice));
                      _percGstAmt =  _finalPercenTageP;
                      
                      //_percGstAmt = ((parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount)) * parseFloat(tax.ratio)) / parseFloat(100);
                      //parseFloat(_totalItemAmount) - parseFloat(_obj.discount_in_amount);
                      //console.log("==AK-3---",_percGstAmt);
                    }
                  }
                }
              });
              _obj.tax_type = _gstObj;
              console.log("==AK-4---",_percGstAmt);
              if (_percGstAmt < 0) {
                _obj.tax_value = 0;
              } else {
                _obj.tax_value = _percGstAmt.toFixed(2);
                console.log("==AK-5---",_percGstAmt);
              }
              if (
                _businessSetting.AllowInclusiveExclusive == 1 &&
                field.sale_pruchase_tax_type == "Without Tax"
              ) {
                console.log("==PPP==", field.sale_pruchase_tax_type);
                _itemSubtotal =
                  parseFloat(_totalItemAmount) +
                  parseFloat(_obj.tax_value) -
                  parseFloat(_obj.discount_in_amount);
              }

              if (_businessSetting.AllowInclusiveExclusive == 0) {
                console.log("==III==", field.sale_pruchase_tax_type);
                _itemSubtotal =
                  parseFloat(_totalItemAmount) +
                  parseFloat(_obj.tax_value) -
                  parseFloat(_obj.discount_in_amount);
              }

              if (
                _businessSetting.AllowInclusiveExclusive == 1 &&
                field.sale_pruchase_tax_type == "With Tax"
              ) {
                console.log("==UU==", field.sale_pruchase_tax_type);
                _itemSubtotal =
                  parseFloat(_totalItemAmount) -
                  parseFloat(_obj.discount_in_amount);
              }
              console.log(_itemSubtotal + "--Sub T--here--88--");
            } 
            else {
              if (_taxTypeIndividual.id > 0) {
                this.state.taxRateArray.map((tax, index) => {
                  if (_taxTypeIndividual.id == tax.id) {
                    if (tax.ratio >= 0) {
                      if (
                        _businessSetting.AllowInclusiveExclusive == 1 &&
                        field.sale_pruchase_tax_type == "Without Tax"
                      ) {
                        console.log("==UU==", field.sale_pruchase_tax_type);
                        _percGstAmt =
                          ((parseFloat(_totalItemAmount) -
                            parseFloat(_obj.discount_in_amount)) *
                            parseFloat(tax.ratio)) /
                          parseFloat(100);
                      }
                      if (_businessSetting.AllowInclusiveExclusive == 0) {
                        console.log("==TT==", field.sale_pruchase_tax_type);
                        _percGstAmt =
                          ((parseFloat(_totalItemAmount) -
                            parseFloat(_obj.discount_in_amount)) *
                            parseFloat(tax.ratio)) /
                          parseFloat(100);
                      }
                    }
                  }
                });
                if (_percGstAmt < 0) {
                  _obj.tax_value = 0;
                } else {
                  _obj.tax_value = _percGstAmt.toFixed(2);
                }
              }

              _itemSubtotal = parseFloat(_totalItemAmount) -  parseFloat(_obj.discount_in_amount);
              console.log(_itemSubtotal + "--Sub T--here--99--");
            }
          }

          if (_businessSetting.ItemWiseDiscount == 1) {
            if (_discount_in_sale_price > 0) {
              if (_discount_type == "Amount") {
                if (
                  _discount_in_sale_price == "" ||
                  _discount_in_sale_price == "null"
                ) {
                  _discount_in_sale_price = 0;
                }
                _obj.discount_in_per = _discount_in_sale_price;
                if (_obj.discount_in_per >= 0) {
                  _percDisAmt =
                    (parseFloat(_totalItemAmount) *
                      parseFloat(_obj.discount_in_per)) /
                    parseFloat(100);
                }
                //_obj.discount_in_amount = _percDisAmt.toFixed(2);
                _obj.discount_in_amount = _discount_in_sale_price;
                _obj.discount_in_per = 0;
              } else {
                if (
                  _discount_in_sale_price == "" ||
                  _discount_in_sale_price == "null"
                ) {
                  _discount_in_sale_price = 0;
                }
                //_obj.discount_in_amount = _discount_in_sale_price;
                let _percDisPerc = 0;
                if (_obj.discount_in_amount >= 0) {
                  _percDisPerc =
                    (parseFloat(_obj.discount_in_amount) * parseFloat(100)) /
                    parseFloat(_totalItemAmount);
                }
                _percDisAmt = _obj.discount_in_amount;

                _obj.discount_in_per = _discount_in_sale_price;
                _obj.discount_in_amount = 0;
              }
            }
          }

          /* Total Calculation */          
          
          if (type == "discount_in_amount") {              
              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax") 
              {
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// -  parseFloat(_totalDiscountAmt);
                console.log(_totalAmt + "==KHARE-11-==",field.sale_pruchase_tax_type
                );
              }

              if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax"
              ) 
              {
                _totalAmt = parseFloat(_totalAmt) +  parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
                console.log(_totalAmt+"==KHARE-22-==",field.sale_pruchase_tax_type);
              }
              if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax")
              {
                console.log("==yy-xx==", field.sale_pruchase_tax_type);
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
              }

              if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
              {
                console.log("==KHARE-3-==", field.sale_pruchase_tax_type);
                _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);// - parseFloat(_totalDiscountAmt);
              }
          }
          else if (type == "discount_in_per") {              
            if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax") 
            {
              _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// -  parseFloat(_totalDiscountAmt);
              console.log(_totalAmt + "==KHARE-11-==",field.sale_pruchase_tax_type
              );
            }

            if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax"
            ) 
            {
              _totalAmt = parseFloat(_totalAmt) +  parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
              console.log(_totalAmt+"==KHARE-22-==",field.sale_pruchase_tax_type);
            }
            if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax")
            {
              console.log("==yy-xx==", field.sale_pruchase_tax_type);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value);// - parseFloat(_totalDiscountAmt);
            }

            if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
            {
              console.log("==KHARE-3-==", field.sale_pruchase_tax_type);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);// - parseFloat(_totalDiscountAmt);
            }
          }
          else{              
            if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax") 
            {
              _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value) -  parseFloat(_totalDiscountAmt);
              console.log(_totalAmt + "==KHARE-001-==",field.sale_pruchase_tax_type
              );
            }

            if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax"
            ) 
            {
              _totalAmt = parseFloat(_totalAmt) +  parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value) - parseFloat(_totalDiscountAmt);
              console.log(_totalAmt+"==KHARE-002-==",field.sale_pruchase_tax_type);
            }

            if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax"
            ) {
              console.log("==yy-xx==", field.sale_pruchase_tax_type);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) + parseFloat(_obj.tax_value) - parseFloat(_totalDiscountAmt);
            }

            if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax")
            {
              console.log("==KHARE-3-==", field.sale_pruchase_tax_type);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount) - parseFloat(_totalDiscountAmt);
            }
          }
          /*Add by AK */

          _dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);
          _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);

          if (this.state.FreeItemQuantity == 1) {
            _quantity = parseFloat(_quantity) + parseFloat(_obj.free_quantity);
          }

          // if (_businessSetting.ItemWiseDiscount == 1) {
          //   if (_obj.discount_in_amount > 0) {
          //     _totalDiscountAmt =
          //       parseFloat(_totalDiscountAmt) +
          //       parseFloat(_obj.discount_in_amount);
          //   } else {
          //     _totalDiscountAmt = 0;
          //   }
          // }

          // // if (_businessSetting.ItemWiseTax == 1) {
          // //   if (_obj.tax_value > 0) {
          // //     _totalGstAmt =
          // //       parseFloat(_totalGstAmt) + parseFloat(_obj.tax_value);
          // //   } else {
          // //     _totalGstAmt = 0;
          // //   }
          // // }
          /* End Total Calculation */

          fieldArray[_itemPosition] = _obj;
        } 
        else if (_itemPosition == 0 && type == "") {
          let _obj = {};
          let _objUnit = { unit_id: "0", unit_name: "", old_unit_id: 0 };
          let _objRateTax = { value: "1", label: this.state.rate_tax };
          let _objTaxType = { id: "0", name: "", ratio: "0" };

          //_obj.unitArrayOld = _unitindividual;
          _obj.item_id = 0;
          _obj.item_name = "";
          _obj.quantity = 0;
          _obj.unit = _objUnit;
          _obj.rate = 0;
          _obj.rate_tax = _objRateTax;
          _obj.discount_in_per = 0;
          _obj.discount_in_amount = 0;
          _obj.tax_type = _objTaxType;
          _obj.tax_value = 0;
          _obj.sale_pruchase_tax_type = field.purchase_tax_type
            ? field.purchase_tax_type
            : "";
          _obj.subtotal = 0;
          _obj.total_amount = 0;
          _obj.Additional_CESS = _Additional_CESS;
          _obj.itemBatchNoAllData = [];
          _obj.itemSerialNoAllData = [];
          fieldArray[_itemPosition] = _obj;
        } else {
          let _itemId = field.item_id ? field.item_id : 0;
          if (_itemId > 0) {
            let _obj = {};
            //_obj.unitArrayOld = _unitindividual;
            _obj.item_id = field.item_id ? field.item_id : 0;
            _obj.item_name = field.item_name;
            _obj.quantity = field.quantity ? field.quantity : "0";
            _obj.free_quantity = field.free_quantity ? field.free_quantity : "0";
            _obj.rate = field.rate ? field.rate : 0;
            _obj.discount_in_per = field.discount_in_amount ? field.discount_in_amount : 0;
            _obj.discount_in_amount = field.discount_in_per ? field.discount_in_per : 0;
            _obj.tax_value = field.tax_value ? field.tax_value : 0;
            _obj.sale_pruchase_tax_type = field.sale_pruchase_tax_type ? field.sale_pruchase_tax_type : "NA3";
            _obj.subtotal = field.subtotal ? field.subtotal : 0;
            _obj.total_amount = field.total_amount ? field.total_amount : 0;
            _obj.Additional_CESS = field.Additional_CESS > 0 ? field.Additional_CESS : 0;

           
            if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax") 
            {
              _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount);// + parseFloat(field.tax_value);// - parseFloat(field.discount_in_amount); // Add by AK
              console.log("==KHARE-4-==", _totalAmt);
            } else if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "Without Tax")
            {
              _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount);// + parseFloat(field.tax_value) - parseFloat(field.discount_in_amount); // Add by AK
              console.log("==KHARE-5-==", _totalAmt);
            } else if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "With Tax") 
            {
              _totalAmt = parseFloat(_totalAmt) + parseFloat(field.total_amount);

              console.log("==KHARE-777-==", _totalAmt);
              _totalAmt = parseFloat(_totalAmt);// - parseFloat(field.discount_in_amount); // Add by AK
              console.log("==KHARE-6-==", _totalAmt);
            }
            else if (_businessSetting.AllowInclusiveExclusive == 0 && field.sale_pruchase_tax_type == "With Tax")
            {
              _totalAmt = (parseFloat(_totalAmt) + parseFloat(field.total_amount) + parseFloat(field.tax_value)) - parseFloat(field.discount_in_amount);
              //_totalAmt = this.state.totalAmountOld; // Add by AK
              console.log("==KHARE-5666-==", _totalAmt);
            }

            console.log("===>KHARE-->>===", _totalAmt);
            //_paidAmount = this.state.paidAmount;
            _dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);
            _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
            if (this.state.FreeItemQuantity == 1) {
              _quantity = parseFloat(_quantity) + parseFloat(_obj.free_quantity);
            }
            //_totalDiscountAmt = parseFloat(_totalDiscountAmt) +  parseFloat(_obj.discount_in_amount);
            _totalGstAmt = parseFloat(this.state.gstAmount);// parseFloat(_totalGstAmt) + parseFloat(_obj.discount_in_per);
          }
        }
      });

      let _paidAM = 0;
      if (_paidAmount == 0) {
        _paidAM = 0;
      } else {
        _paidAM = parseFloat(_paidAmount);
      }
      
      console.log("===>_totalGstAmt-->>===", _totalGstAmt);

      this.setState({
        fields: fieldArray,
        totalQuantity: _quantity,
        paidAmount: _paidAM,
        dueAmount: _dueAmount.toFixed(2),
        totalAmount: _totalAmt.toFixed(2),
        subtotal: _totalAmt.toFixed(2),
        totalAmountOld: _totalAmt,
        totalAmountNew: _totalAmt,
      }); //,gstAmount : _totalGstAmt.toFixed(2),totalDiscount:_totalDiscountAmt.toFixed(2)

      /*Final Calculation Discount and Tax */
      let _amountDisK = 0;
      let _amountTaxK = 0;
      let _discountTaxAk = 0;
      fieldArray.map((field, idx) => {
        let _discountAmtAk = field.discount_in_amount ? field.discount_in_amount : 0;
        _amountDisK = parseFloat(_amountDisK) + parseFloat(_discountAmtAk);

        if (_businessSetting.AllowInclusiveExclusive == 1 && field.sale_pruchase_tax_type == "Without Tax"
        ) 
        {
          _discountTaxAk = field.tax_value ? field.tax_value : 0;
          _amountTaxK = parseFloat(_amountTaxK) + parseFloat(_discountTaxAk);
        }

        //this.setState({totalDiscount:_amountDisK.toFixed(2),gstAmount:_amountTaxK.toFixed(2)});

        if (field.rate == 0 || field.rate == "" || field.rate == "0") {
          handleItemSalePurchasePrice = "Disabled";
        }
        this.setState({
          totalDiscount: _totalDiscountAmt.toFixed(2),
          gstAmount: _totalGstAmt.toFixed(2),
          handleAddDisable: "",
          handleItemSalePurchasePrice: handleItemSalePurchasePrice,
        });
      });


      /*End Calculation Discount and Tax */
      let _totalAmountOld = _totalAmt.toFixed(2);
      let _totalAmountNew = _totalAmt.toFixed(2);

      let _transactionWiseTaxId = 0;
      let _totalTransTaxAmount = 0;
      let _totalTransDiscountPer = 0;
      let _totalTransDiscountAmount = 0;

      this.props.callBackItem(
        fieldArray,
        _totalAmountOld,
        _totalAmountNew,
        _totalAmt.toFixed(2),
        _dueAmount.toFixed(2),
        _paidAM,
        _totalDiscountAmt,//_amountDisK.toFixed(2),
        _totalGstAmt,//_amountTaxK, //this.state.gstAmount,
        this.state.totalQuantity,
        this.state.handleAddDisable,
        handleItemSalePurchasePrice,
        _transactionWiseTaxId,
        _totalTransTaxAmount,
        _totalTransDiscountPer,
        _totalTransDiscountAmount
      );

      //this.props.totalAmount(_totalAmt);
      //this.props.dueAmount(_dueAmount);
      //this.props.paidAmount(_paidAM);
    }
    this.onMouseLeaveHandlerItem("I", _itemPosition);
    this.onItemFilterName();
  }
  
  handlePermanentRemove(value,itemId,_itemTransId) {
    confirmAlert({
      title: "Confirm to delete",
      message: "By removing this item, this item will be permanently deleted from this transaction. Are you still want to remove this item?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              itemId: itemId,
              transactionId: this.state.transactionId,
              itemTransId: _itemTransId,
            });
            this.handleRemove(value,itemId);
            PostData(global.userTransationItemDelete, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  let _payableAmount = this.state.gstAmount;
                  this.setState({payableAmount : _payableAmount,dueAmount:_payableAmount})
                  this.getItemListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  dynamicInputFiled() {
    let _dataSet = [];
    if (this.state.fields.length > 0) {
      let _j = 0;

      let _itemsArrayMap = this.state.fields;
      
      let _disableBtn = "";
      let _readonly = "";
      let _itemTransId = 0;
      if (this.state.transactionId > 0) {
        _itemsArrayMap.map((fields, ind) => {
          this.state.itemArrayFilter.map((items, i) => {
            let _itemUnitArray = [];
            if (fields.item_id == items.item_id) {
              let _itmUnit = items.unit ? items.unit : "";
              if (_itmUnit == "") {
                _itemUnitArray = this.state.unitArray;
              } else {
                _itemUnitArray.push(items.unit);
              }
            } else {
              _itemUnitArray = this.state.unitArray;
            }
            //fields.unitArrayOld = _itemUnitArray;
          });
        });
      }

      _itemsArrayMap.map((field, idx) => {
        _j++;
        let _key = idx + 222;
        let _hideMenuItem = "iti__hide";
        if (this.state._itemRegIndex == idx) {
          _hideMenuItem = this.state._itemReg;
        }

        let _itemId = field.item_id ? field.item_id : 0;
        
        if(field.item_name !='' && (_itemId > 0)){
          _disableBtn  = "disabled";
          _readonly  = "readOnly";
        }
        else{
          _disableBtn  = "";
          _readonly  = "";
        }
        let _unitArray = field.unit
          ? field.unit
          : { unit_id: "0", unit_name: "", old_unit_id: 0 };
        let _taxTypeArray = field.tax_type
          ? field.tax_type
          : { id: "0", name: "", ratio: "0" };
        let _taxTypeId = "0";
        if (_taxTypeArray.id > 0) {
          _taxTypeId = _taxTypeArray.id;
        }

        let _unitId = _unitArray.unit_id ? _unitArray.unit_id : 0;
        let _oldunitId = _unitArray.old_unit_id ? _unitArray.old_unit_id : 0;
        if (_unitId > 0) {
          _unitId = _unitArray.unit_id;
        }

        // let _unitPreArrayOld = field.unitArrayOld;
        // let _unitPreArray = this.state.unitArray;
        let _unitPreArray = this.state.unitArray;
        if (_itemId == 0) {
          _unitPreArray = this.state.unitArray;
        }
        let _lengthBatch = 0;
        if (field!=undefined) {
          _lengthBatch = field.itemBatchNoAllData
            ? field.itemBatchNoAllData.length
            : 0;

          if (_lengthBatch > 0) {
            _lengthBatch = _lengthBatch;
          }
        }
        let _readOnlydHere = "";
        if (
          _businessSetting.SerialNoChk == 0 &&
          _businessSetting.SerialNoAdditionalChk1 == 0 &&
          _businessSetting.SerialNoAdditionalChk2 == 0
        ) {
          _readOnlydHere = "readOnly";
        }

        const itemTaxTypeData = [
          { value: "With Tax", label: "With Tax" },
          { value: "Without Tax", label: "Without Tax" },
        ];
        _itemTransId = field.inv_item_id ?  field.inv_item_id : 0;
        
        let _readonlyFieldDup = false;
        if(this.state.duplicateSale === 'DUPLICATE'){
          _readonlyFieldDup = true;
        }

        _dataSet.push(
          <tr key={_key}>
            <td>     
              {/* {this.state.sendPageType == 'Duplicate' &&               
                <button
                type="button"
                onClick={this.handleRemove.bind(this, idx, _itemId)}
                className="btn btn-outline-danger"
              >
                X
              </button>
              }  */}
              {this.state.sendPageType!='Duplicate' &&       
              <div>
                {(_itemTransId == 0) &&
                  <button
                    type="button"
                    onClick={this.handleRemove.bind(this, idx, _itemId)}
                    className="btn btn-outline-danger"
                  >
                    X
                  </button>
                }
                {(this.state.transactionId > 0 && _itemTransId > 0) &&
                  <button
                    type="button"
                    onClick={this.handlePermanentRemove.bind(this, idx, _itemId,_itemTransId)}
                    className="btn btn-outline-danger"
                  >
                    X
                  </button>
                }
              </div>  
              }
              
            </td>
            <td>{_j}</td>
            <td>
              <div className="col-lg-12">
                {/* onMouseEnter={this.manageShowItemFilter.bind(this,'I',idx)} onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)} */}
                <div
                  className="form-material open"
                  id="paddingOff"
                  onMouseLeave={this.onMouseLeaveHandlerItem.bind(
                    this,
                    "I",
                    idx
                  )}
                >
                  <input
                    type="text"
                    className="form-control search-box"
                    onChange={this.onItemFilterTypeName.bind(this, idx)}
                    onClick={this.manageShowItemFilter.bind(this, "I", idx)}
                    onKeyDown={this.manageShowItemFilter.bind(this, "I", idx)}
                    placeholder="Item Name Here..."
                    autoComplete="off"
                    name="item_name"
                    id={"item_name_" + idx}
                    value={field.item_name ? field.item_name : ""}
                    disabled={_disableBtn}
                    readOnly={_readonly}
                  />
                  <div id={"itemListData_" + idx}>
                    <ul
                      className={
                        _hideMenuItem +
                        " iti__party-list iti__party-list--dropup"
                      }
                      id="country-listbox"
                      aria-expanded="false"
                      role="listbox"
                      aria-activedescendant="iti-item-ad"
                    >
                      {this.getReadyItemList(idx)}
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            {this.state.batchItemEnable == 1 && (
              <td>
                {field.length!=0 && field.itemBatchNoAllData.length > 0 && (
                  <button
                    className="btn btn-info"
                    href="#"
                    title="View Batch"
                    onClick={() =>
                      this.clickSrialBatchD(
                        idx,
                        "Batch",
                        field.itemBatchNoAllData,
                        field.itemBatchNo,
                        field.item_id,
                        field.item_name,
                        field.rate
                      )
                    }
                  >
                    <i className="fa fa-eye"  placeholder="View Batch"></i>
                  </button>
                )}
              </td>
            )}
            {this.state.serialItemEnable == 1 && (
              <td>
                {field.length!=0 && field.itemSerialNoAllData.length > 0 && (
                  <button
                    onClick={() =>
                      this.clickSrialBatchD(
                        idx,
                        "Serial",
                        field.itemSerialNoAllData,
                        field.itemSerialNo,
                        field.item_id,
                        field.item_name,
                        field.rate
                      )
                    }
                    className="btn btn-info"
                    href="#"
                    title="View Serial"
                  >
                    <i className="fa fa-eye"  placeholder="View Serial"></i> 
                  </button>
                )}
              </td>
            )}
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  maxLength="100"
                  required="required"
                  className="form-control"
                  id={"_qty_" + idx}
                  name={"_qty_" + idx}
                  value={field.quantity ? field.quantity : ""}
                  onChange={this.handleDynamicInputs.bind(this,idx,"quantity")}
                  readOnly
                />
              </div>
            </td>
            {this.state.FreeItemQuantity == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  {this.state.transactionId > 0 && _lengthBatch == 0 && (
                    <input
                      type="number"
                      min="0"
                      maxLength="100"
                      required="required"
                      className="form-control"
                      id={"_free_quantity_" + idx}
                      name={"_free_quantity_" + idx}
                      value={field.free_quantity ? field.free_quantity : ""}
                      onChange={this.handleDynamicInputs.bind(this,idx,"free_quantity")}
                      readOnly={_readOnlydHere}
                    />
                  )}
                  {this.state.transactionId > 0 && _lengthBatch > 0 && (
                    <input
                      type="number"
                      min="0"
                      maxLength="100"
                      required="required"
                      className="form-control"
                      id={"_free_quantity_" + idx}
                      name={"_free_quantity_" + idx}
                      value={field.free_quantity ? field.free_quantity : ""}
                      onChange={this.handleDynamicInputs.bind(this,idx,"free_quantity")}
                      readOnly
                    />
                  )}

                  {this.state.transactionId == 0 && (
                    <input
                      type="number"
                      min="0"
                      maxLength="100"
                      required="required"
                      className="form-control"
                      id={"_free_quantity_" + idx}
                      name={"_free_quantity_" + idx}
                      value={field.free_quantity ? field.free_quantity : ""}
                      onChange={this.handleDynamicInputs.bind(this,idx,"free_quantity")}
                      readOnly
                    />
                  )}
                </div>
              </td>
            )}
            {this.state.TransCount == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <input
                    type="number"
                    min="0"
                    maxLength="100"
                    required="required"
                    className="form-control"
                    id={"_trans_count_" + idx}
                    name={"_trans_count_" + idx}
                    value={field.trans_count ? field.trans_count : ""}
                    onChange={this.handleDynamicInputsTransCount.bind(this,idx,"trans_count")}
                    readOnly={_readonlyFieldDup}
                  />
                </div>
              </td>
            )}

            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <select
                  style={{ width: "90px" }}
                  className="form-control"
                  id={"seUnit_" + idx}
                  name={"seUnit_" + idx}
                  onChange={this.onChangeCalculation.bind(this, idx, "unit")}
                  
                  readOnly={_readonlyFieldDup}
                  disabled={_readonlyFieldDup}
                >
                  {_unitId > 0 ? (
                    ""
                  ) : (
                    <option value="" selected>
                      None
                    </option>
                  )}
                  {_unitPreArray.map((item, index) => {
                    let _selBase = "";
                    if (_unitId > 0 && _oldunitId == 0) {
                      if (_unitId == item.unit_id) {
                        _selBase = "Selected";
                      }
                      return (
                        <option
                          key={index}
                          data-Id={_unitId}
                          value={item.unit_name}
                          selected={_selBase}
                        >
                          {item.unit_name}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          key={index}
                          data-Id={_unitId}
                          value={item.unit_name}
                          selected={_selBase}
                        >
                          {item.unit_name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  required="required"
                  className="form-control"
                  id={"rate_" + idx}
                  name={"rate_" + idx}
                  value={field.rate ? field.rate : ""}
                  onChange={this.onChangeCalculation.bind(this, idx, "rate")}
                  readOnly={_readonlyFieldDup}
                />
              </div>
            </td>

            {_businessSetting.AdditionalCESS == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <input
                    type="number"
                    min="0"
                    required="required"
                    className="form-control"
                    id={"Additional_CESS_" + idx}
                    name={"Additional_CESS_" + idx}
                    value={field.Additional_CESS ? field.Additional_CESS : ""}
                    onChange={this.onChangeCalculation.bind(this,idx,"Additional_CESS")}
                    readOnly={_readonlyFieldDup}
                  />
                </div>
              </td>
            )}

            {_businessSetting.AllowInclusiveExclusive == 1 && (
              <td>
                <div className="col-lg-12 form-material" id="paddingOff">
                  <select
                    style={{ width: "90px" }}
                    className="form-control"
                    id={"salePurTaxType_" + idx}
                    name={"salePurTaxType_" + idx}
                    onChange={this.handleDynamicInputSalePurTaxType.bind(this,idx,"salePurTaxType")}
                    readOnly={_readonlyFieldDup}
                    disabled={_readonlyFieldDup}
                  > 
                    {itemTaxTypeData.map(({ value, label }, index) => {
                      let _selSetTaxT = "";
                      if (field.sale_pruchase_tax_type == value) {
                        _selSetTaxT = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={value}
                          selected={_selSetTaxT}
                        >
                          {label}
                        </option>
                      );
                    })}
                    {/* <option  value="With Tax" >With Tax</option>
                                 <option  value="Without Tax" >Without Tax</option> */}
                  </select>
                </div>
              </td>
            )}
            {_businessSetting.ItemWiseDiscount == 1 && (
              <td>
                <tr>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        style={{ width: "60px" }}
                        min="0"
                        maxLength="99"
                        className="form-control"
                        id={"discount_in_per_" + idx}
                        value={
                          field.discount_in_per ? field.discount_in_per : ""
                        }
                        name={"discount_in_per_" + idx}
                        autoComplete="off"
                        onChange={this.onChangeCalculation.bind(this,idx,"discount_in_per")}
                        readOnly={_readonlyFieldDup}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        min="0"
                        maxLength="99999"
                        className="form-control"
                        id={"discount_in_amount_" + idx}
                        value={
                          field.discount_in_amount
                            ? field.discount_in_amount
                            : ""
                        }
                        name={"discount_in_amount_" + idx}
                        autoComplete="off"
                        onChange={this.onChangeCalculation.bind(this,idx,"discount_in_amount")}
                        readOnly={_readonlyFieldDup}
                      />
                    </div>
                  </td>
                </tr>
              </td>
            )}

            {_businessSetting.ItemWiseTax == 1 && (
              <td>
                <tr>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <select
                        style={{ width: "85px" }}
                        className="form-control"
                        id={"taxType_" + idx}
                        name={"taxType_" + idx}
                        onChange={this.onChangeCalculation.bind(this,idx,"taxType")}
                        readOnly={_readonlyFieldDup}
                        disabled={_readonlyFieldDup}
                      >
                        <option value="" selected>
                          {" "}
                          None
                        </option>
                        {this.state.taxRateArray.map((tax, index) => {
                          let _sel = "";
                          if (_taxTypeId == tax.id) {
                            _sel = "selected";
                          }
                          return (
                            <option key={index} value={tax.id} selected={_sel}>
                              {tax.name + "@" + tax.ratio + "%"}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="col-lg-12 form-material" id="paddingOff">
                      <input
                        type="number"
                        readOnly={true}
                        min="0"
                        maxLength="100"
                        className="form-control"
                        value={field.tax_value ? field.tax_value : ""}
                        id={"tax_value_" + idx}
                        name={"tax_value_" + idx}
                      />
                    </div>
                  </td>
                </tr>
              </td>
            )}
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control"
                  id={"subtotal_" + idx}
                  name={"subtotal_" + idx}
                  value={field.total_amount ? field.total_amount : ""}
                />
                {/* Add by AK */}
              </div>
            </td>
          </tr>
        );
      });
    }
    return _dataSet;
  }
  /* End Add More Data */

  /* Start Item Code */
  onItemFilterName = () => {
    const data = this.state.itemArray;
    let fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        data.filter(function (item, index) {
          if (item.item_id == field.item_id) {
            //data[index].isFilter = true;
          }
        });
      });
    }
    this.setState({ itemArrayFilter: data });
    this.getReadyItemList();
  };

  onItemFilterTypeName = (idx, e) => {
    const data = this.state.itemArray;
    let _name = e.target.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );

    const fieldArray = this.state.fields;
    fieldArray.map((field, index) => {
      if (index == idx) {
        field.item_name = keyword;
      }
    });

    this.setState({
      itemArrayFilter: filtered,
      item_name: keyword,
      fields: fieldArray,
    });
    this.getReadyItemList();
  };

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowPartyFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  getReadyItemList(index) {
    let data = [];
    if (this.state.isLoaded == true) {
      let _itemsArray = this.state.itemArrayFilter;
      if (_itemsArray.length > 0) {
        data = this.state.itemArrayFilter.map((items, i) => {
          if (items.isFilter == false) {
            let _obj = {};
            let _itemUnitArray = [];

            if (items.base_unit_id > 0) {
              _itemUnitArray.push(items.unit);
            } else {
              _itemUnitArray.push(this.state.unitArray);
            }
            _obj.index = index;
            _obj.name = items.name;
            _obj.itemType = items.type;
            _obj.id = items.item_id;
            _obj.price = items.purchase_price;
            _obj.base_unit_id = items.base_unit_id;
            _obj.unitindividual = _itemUnitArray;
            _obj.tax_type = items.tax_type;
            _obj.discount_in_sale_price = items.discount_in_sale_price;
            _obj.discount_type = items.discount_type;
            _obj.sale_pruchase_tax_type = items.purchase_tax_type
              ? items.purchase_tax_type
              : "";
            _obj.Additional_CESS =
              items.Additional_CESS > 0 ? items.Additional_CESS : 0;

            return (
              <li
                key={i}
                className="iti__party iti__standard"
                id="iti-item-in"
                onClick={this.handleDynamicInputs.bind(this, _obj, "item")}
              >
                <span className="iti__party-name">{items.name}</span>
              </li>
            );
          }
        });
      } else {
        /*return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            //onClick={this.openModalHandler.bind(this, "modalAdjustNewItem")}
            onClick={() => {
              this.handleRemove(index);
              this.openModalHandler("modalAdjustNewItem");
            }}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Item
            </span>
          </li>
        );*/
      }
      data.push(<li
        key="22"
        className="iti__party iti__standard"
        id="iti-item-in"
        //onClick={this.openModalHandler.bind(this, "modalAdjustNewItem")}
        onClick={() => {
          this.handleRemove(index);
          this.openModalHandler("modalAdjustNewItem");
        }}
      >
        <span className="iti__party-name">
          <i className="si si-users"></i> Add New Item
        </span>
      </li>)
    }
    return data;
  }
  /* End Item Code */

  getItemTotalAmount(Itemposition, pricePerUnit, discountAmt, discountPer) {
    let _mainSubTotalAmt = 0;
    let _totalOtherCharges = 0;
    this.state.fields.map((data) => {
      _mainSubTotalAmt +=
        parseFloat(data.total_amount) +
        parseFloat(data.tax_value) -
        parseFloat(data.discount_in_amount);
    });
    let _totalTaxAmount = _mainSubTotalAmt;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1
      ? this.state.TransAdditionalCharge1
      : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2
      ? this.state.TransAdditionalCharge2
      : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3
      ? this.state.TransAdditionalCharge3
      : 0;
    console.log("--charge--3--", _TransAdditionalCharge3);
    let _totalAmount = 0;
    return _totalAmount;
  }

  render() {
    return (
      <React.Fragment>
        {this.state.EnableItem == 1 && (
          <div className="row">
            <div className="col-md-12" id="formSideItem">
              <div className="block block-transparent">
                <div className="block-content" id="formSideItem">
                  <table className="table table-bordered table-striped table-vcenter">
                    <thead id="sideTable">
                      <tr>
                        <th style={{ width: "30px" }}></th>
                        <th style={{ width: "30px" }}>#</th>
                        <th style={{ width: "200px" }}>Item</th>
                        {this.state.batchItemEnable == 1 && (
                          <th style={{ width: "50px" }}>Batch</th>
                        )}
                        {this.state.serialItemEnable == 1 && (
                          <th style={{ width: "50px" }}>Serial</th>
                        )}
                        <th style={{ width: "50px" }}>Qty</th>
                        {this.state.FreeItemQuantity == 1 && (
                          <th style={{ width: "50px" }}>Free Qty</th>
                        )}
                        {this.state.TransCount == 1 && (
                          <th style={{ width: "50px" }}>Count</th>
                        )}
                        <th style={{ width: "90px" }}>Unit</th>
                        <th style={{ width: "150px" }}>
                          Price / Unit
                          {/* <tr>
                            {this.state.rate_tax+'==>>=='}
                            {_businessSetting.AllowInclusiveExclusive == 1 &&
                            <td style={{width:"140px"}}>
                            <select name="rate_tax" id="rate_tax" onChange={this.handleChange}>
                                <option value="With Tax">With Tax</option>
                                <option value="Without Tax">Without Tax</option>
                            </select>
                            </td>
                            }
                        </tr> */}
                        </th>
                        {_businessSetting.AdditionalCESS == 1 && (
                          <th style={{ width: "90px" }}>Additional CESS</th>
                        )}
                        {_businessSetting.AllowInclusiveExclusive == 1 && (
                          <th style={{ width: "150px" }}> Tax Type </th>
                        )}
                        {_businessSetting.ItemWiseDiscount == 1 && (
                          <th style={{ width: "250px" }}>
                            <tr>
                              <td style={{ width: "194px" }} colSpan="2">
                                Discount
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "130px" }}>%</td>
                              <td style={{ width: "100px" }}>Amount</td>
                            </tr>
                          </th>
                        )}

                        {_businessSetting.ItemWiseTax == 1 && (
                          <th style={{ width: "300px" }}>
                            <tr>
                              <td style={{ width: "194px" }} colSpan="2">
                                Tax
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "50px" }}>%</td>
                              <td style={{ width: "100px" }}>Amount</td>
                            </tr>
                          </th>
                        )}
                        <th style={{ width: "100px" }}>Amount</th>
                      </tr>
                    </thead>

                    <tbody id="sideTable">
                      {this.dynamicInputFiled()}

                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.sendPageType !== 'Duplicate' &&
                            <button
                              className="badge badge-primary pull-left"
                              onClick={this.handleAdd.bind(this)}
                            >
                              Add Item
                            </button>
                          }
                          <span className="titleTable">Total</span>
                        </td>
                        {this.state.batchItemEnable > 0 && <td></td>}
                        {this.state.serialItemEnable > 0 && <td></td>}

                        <td>
                          <span className="titleTable">
                            {this.state.totalQuantity}
                          </span>
                        </td>

                        {_businessSetting.FreeItemQuantity > 0 && <td></td>}
                        {_businessSetting.Count > 0 && <td></td>}

                        <td></td>

                        <td></td>

                        {_businessSetting.AdditionalCESS == 1 && <td></td>}
                        {_businessSetting.AllowInclusiveExclusive > 0 && (
                          <td></td>
                        )}

                        {_businessSetting.ItemWiseDiscount == 1 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalDiscount}
                            </span>
                          </td>
                        )}

                        {_businessSetting.ItemWiseTax == 1 && (
                          <td>
                            <span className="titleTable">
                              {this.state.gstAmount}
                            </span>
                          </td>
                        )}
                        {this.state.transactionId > 0 && (
                          <td>
                            {(parseFloat(this.state.subtotal) > parseFloat(this.state.totalAmount)) && 
                              <span className="titleTable">
                                {this.state.subtotal}
                              </span>
                            }
                            {(parseFloat(this.state.subtotal) < parseFloat(this.state.totalAmount)) && 
                              <span className="titleTable">
                                {this.state.subtotal}
                              </span>
                            }
                            {(parseFloat(this.state.subtotal) == parseFloat(this.state.totalAmount)) && 
                              <span className="titleTable">
                                {this.state.totalAmount}
                              </span>
                            }
                            {/* <span className="titleTable">                              
                              {this.state.totalAmount <= 0
                                ? this.state.subtotal
                                : this.state.totalAmount}
                            </span> */}                            
                          </td>
                        )}
                        {this.state.transactionId == 0 && (
                          <td>
                            <span className="titleTable">
                              {this.state.totalQuantity > 0
                                ? this.state.totalAmountOld
                                : 0}
                            </span>
                          </td>
                        )}
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal Edit Item Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SITEM}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add New Item </h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewItem
                    callBack={(data) => {
                      console.log("==data[0]===", data[0]);
                      let _itemArray = this.state.itemArray;
                      _itemArray.push(data[0]);
                      let _quantityAK = data[0].opening_stock
                        ? data[0].opening_stock
                        : 0;
                      let _totalQuantity =
                        parseInt(_quantityAK) +
                        parseInt(this.state.totalQuantity);

                      let _totalGstAmt = this.state.gstAmount;
                      let _totalDiscount = this.state.totalDiscount;
                      let _totalAmountOld = this.state.totalAmountOld;
                      let percenTageAmt = 0;
                      let _Additional_CESSAK = 0;
                      if (_totalDiscount == 0 || _totalDiscount == "") {
                        _totalDiscount = data[0].discount_in_sale_price;
                      }
                      if (_totalGstAmt == 0 || _totalGstAmt == "") {
                        _totalGstAmt = data[0].tax_rate_ratio;
                      }

                      percenTageAmt =
                        parseFloat(data[0].purchase_price) *
                        (data[0].tax_type.ratio / 100);

                      if (_totalGstAmt > 0) {
                        _totalGstAmt =
                          parseFloat(percenTageAmt) +
                          parseFloat(this.state.gstAmount);
                      } else {
                        _totalGstAmt =
                          parseFloat(_totalGstAmt) + parseFloat(percenTageAmt);
                      }
                      let _freeQty = 0;
                      let batchItemEnable = 0;
                      let serialItemEnable = 0;
                      let _itemBatchNo = data[0].itemBatchNo;

                      // if (this.state.FreeItemQuantity == 1) {
                      //   document.getElementById(
                      //     "_free_quantity_" + this.state.itemPosition
                      //   ).readOnly = false;
                      // }

                      if (data[0].itemSerialNo.length > 0) {
                        _quantityAK = data[0].itemSerialNo.length;
                        serialItemEnable = 1;

                        // if (this.state.FreeItemQuantity == 1) {
                        //   document.getElementById(
                        //     "_free_quantity_" + this.state.itemPosition
                        //   ).readOnly = false;
                        // }
                      }
                      if (data[0].itemBatchNo.length > 0) {
                        let _totalBatcQty = 0;
                        _freeQty = 0;
                        batchItemEnable = 1;
                        _itemBatchNo.map((batchData, index) => {
                          _freeQty = batchData.free_quantity
                            ? batchData.free_quantity
                            : 0;
                          //_totalBatcQty = batchData.used_stock + _freeQty;
                          _quantityAK = batchData.opening_stock;
                        });
                        //_quantityAK = _totalBatcQty;

                        // if (this.state.FreeItemQuantity == 1) {
                        //   document.getElementById(
                        //     "_free_quantity_" + this.state.itemPosition
                        //   ).readOnly = true;
                        // }
                      }

                      const arrayLength =
                        parseInt(this.state.fields.length) + parseInt(1);
                      let objItemData = {};
                      let _unitArray = [];
                      _unitArray.push(data[0].unit);

                      objItemData.item_id = data[0].item_id;
                      objItemData.item_name = data[0].name;
                      objItemData.quantity = _quantityAK;
                      objItemData.free_quantity = _freeQty;
                      objItemData.trans_count = 0;
                      objItemData.rate = data[0].purchase_price;
                      objItemData.discount_in_per =
                        data[0].discount_in_sale_price;
                      objItemData.discount_in_amount =
                        data[0].discount_in_sale_price;
                      objItemData.tax_value = data[0].tax_rate_ratio;
                      objItemData.subtotal = 0;
                      objItemData.total_amount = (parseFloat(_quantityAK) * parseFloat(data[0].purchase_price)).toFixed(2);
                      objItemData.availLenAk = 0;
                      objItemData.totalStocks = 0;
                      objItemData.availableQty = 0;
                      objItemData.type = "ITEM";
                      objItemData.unit = data[0].unit;
                      //objItemData.unitArrayOld = _unitArray;
                      //objItemData.rate_tax = data[0].tax_rate_ratio;
                      objItemData.tax_type = data[0].tax_type;
                      objItemData.itemSerialNoAllData = data[0].itemSerialNo;
                      objItemData.itemBatchNoAllData = data[0].itemBatchNo;
                      objItemData.unitindividual = data[0].unit;
                      objItemData.sale_pruchase_tax_type =
                        data[0].purchase_tax_type;
                      objItemData.isFilter = false;
                      objItemData.Additional_CESS = data[0].Additional_CESS
                        ? data[0].Additional_CESS
                        : 0;
                      _Additional_CESSAK =
                        parseFloat(_Additional_CESSAK) +
                        parseFloat(data[0].Additional_CESS);

                      console.log("==objItemData--kk===", objItemData);
                      let newArray = [];

                      console.log(
                        arrayLength + "==arrayLength--kk===",
                        this.state.fields
                      );
                      for (let index = 0; index < arrayLength; index++) {
                        if (this.state.fields[index]) {
                          console.log("==index==", index);
                          // console.log("==objItemData JSON==",JSON.stringify(objItemData));
                          /*if(this.state.fields[index]['item_name'] == objItemData.item_name) {
                                                        newArray.push(objItemData);
                                                    }
                                                    else {
                                                        newArray.push(this.state.fields[index]);
                                                    }*/
                          newArray.push(objItemData);
                          console.log("==objItemData--final===", objItemData);
                        }
                      }
                      newArray.push(objItemData);
                      console.log("==newArray--kk===", newArray);

                      let _itemTotal = (
                        parseFloat(_quantityAK) *
                        parseFloat(data[0].purchase_price)
                      ).toFixed(2);
                      let _totalAmtAK =
                        parseFloat(_totalAmountOld) +
                        parseFloat(
                          _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)
                        ) +
                        parseFloat(_itemTotal);

                      let _totalAmt =
                        parseFloat(_totalAmountOld) +
                        parseFloat(
                          _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)
                        ) +
                        parseFloat(_itemTotal);

                      let _dueAmount =
                        parseFloat(_totalAmountOld) +
                        parseFloat(
                          _totalGstAmt.toFixed(2) - parseFloat(_totalDiscount)
                        ) +
                        parseFloat(_itemTotal);

                      let _paidAM = 0;
                      let _totalAmountNewSend = _totalAmountOld;
                      let _totalAmountOldSend = _totalAmtAK;
                      let _handleAddDisable = "";
                      this.props.callBackItem(
                        newArray,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        _handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: newArray,
                        itemArrayFilter: _itemArray,
                        itemArray: _itemArray,
                        isLoaded: true,
                        itemPosition: -1,
                        modalIsOpen_SITEM: false,
                        totalQuantity: _totalQuantity,
                        totalAmount: _totalAmtAK.toFixed(2),
                        totalAmountOld: _totalAmtAK.toFixed(2),
                        totalAmountNew: _totalAmtAK.toFixed(2),
                        gstAmount: _totalGstAmt.toFixed(2),
                        totalDiscount: _totalDiscount,
                        dueAmount: _totalAmtAK.toFixed(2),
                        batchItemEnable: batchItemEnable,
                        serialItemEnable: serialItemEnable,
                        handleAddDisable: "",
                      });

                      // this.getReadyItemList();
                    }}
                    newItemId={this.state.newItemId}
                    itemName={this.state.item_name}
                    itemCreateType="Purchase"
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewItem"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Item Details*/}

        {/*  Modal Batch Serial Data Condition Popup */}
        <Modal
          isOpen={this.state.modalIsOpen_SerialBatchNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalShowSerialBatchNo"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Tracking Type</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandlerMainP.bind(
                        this,
                        "modalShowSerialBatchNo",
                        this.state.itemPosition,
                        this.state.itemId
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>

                {this.state.selectType!=2 && (
                  <div className="block-content">
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) &&
                      _businessSetting.BatchNoChk == 1 && (
                        <p>Please add tracking type</p>
                      )}
                    {(_businessSetting.SerialNoChk == 0 ||
                      _businessSetting.SerialNoAdditionalChk1 == 0 ||
                      _businessSetting.SerialNoAdditionalChk2 == 0) &&
                      _businessSetting.BatchNoChk == 0 && (
                        <p>Please add normal quantity</p>
                      )}
                    <button
                      type="button"
                      className="btn btn-alt-secondary"
                      onClick={() =>
                        this.handleNotSerialPopup(
                          this.state.selectItemData,
                          this.state.itemId,
                          this.state.name,
                          this.state.itemPosition,
                          this.state.itemPrice,
                          this.state.selectTypeTxt,
                          this.state.normal_quantity
                        )
                      }
                    >
                      Add without tracking
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) &&
                      this.state.itemType!="SERVICE" && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleAddNewSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice
                            )
                          }
                        >
                          Add Serial
                        </button>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {_businessSetting.BatchNoChk == 1 &&
                      this.state.itemType!="SERVICE" && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleAddNewBatchPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice
                            )
                          }
                        >
                          Add Batch
                        </button>
                      )}
                  </div>
                )}

                {this.state.selectType == 2 && (
                  <div className="block-content">
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) &&
                      _businessSetting.BatchNoChk == 1 &&
                      this.state.itemType!="SERVICE" && (
                        <p>Please add tracking type</p>
                      )}
                    {(_businessSetting.SerialNoChk == 0 ||
                      _businessSetting.SerialNoAdditionalChk1 == 0 ||
                      _businessSetting.SerialNoAdditionalChk2 == 0) &&
                      _businessSetting.BatchNoChk == 0 && (
                        <p>Please add normal quantity</p>
                      )}
                    {this.state.btnShowSerialNormalBatch == 0 &&
                      this.state.itemType!="SERVICE" && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleNotSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice,
                              this.state.selectTypeTxt,
                              this.state.normal_quantity
                            )
                          }
                        >
                          Add without tracking
                        </button>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {(_businessSetting.SerialNoChk == 1 ||
                      _businessSetting.SerialNoAdditionalChk1 == 1 ||
                      _businessSetting.SerialNoAdditionalChk2 == 1) &&
                      this.state.btnShowSerialNormalBatch == 1 && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleAddNewSerialPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice
                            )
                          }
                        >
                          Add Serial
                        </button>
                      )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {_businessSetting.BatchNoChk == 1 &&
                      this.state.btnShowSerialNormalBatch == 2 && (
                        <button
                          type="button"
                          className="btn btn-alt-secondary"
                          onClick={() =>
                            this.handleAddNewBatchPopup(
                              this.state.selectItemData,
                              this.state.itemId,
                              this.state.name,
                              this.state.itemPosition,
                              this.state.itemPrice
                            )
                          }
                        >
                          Add Batch
                        </button>
                      )}
                  </div>
                )}
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandlerMainP.bind(
                    this,
                    "modalShowSerialBatchNo",
                    this.state.itemPosition,
                    this.state.itemId
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Batch Serial Data Condition Popup */}

        {/* Modal Add New Serial No In Item Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddNewSerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNewSalePurSerialNo"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Serial No <span> ({this.state.name})</span>
                  </h3>
                </div>
                <div className="block-content">
                  <QuickAddNewSalePurchaseSerialNo
                    callBack={(
                      data,
                      _opening_stock,
                      mrpPrice,
                      _totalAmountOld,
                      _totalQuantity,
                      _discountAmtSend,
                      _taxAmtSend,
                      _itemToalAmtSend,
                      indexPosition,
                      _costPrice
                    ) => {
                      const productId = this.state.itemId; //data[0].productId;
                      let _fieldData = this.state.fields;
                      let percenTageAmt = 0;
                      //let _totalGstAmt = this.state.gstAmount;
                      //let _totalDiscount = this.state.totalDiscount;
                      let _totalAmtItem = 0;
                      let _qtyAK = 0;
                      let _Additional_CESSAK = 0;

                      if (this.state.FreeItemQuantity == 1) {
                        document.getElementById("_free_quantity_" + this.state.itemPosition).readOnly = false;
                      }
                      let _totalGstAmt = 0;//this.state.gstAmount;
                      let _totalDiscount = 0;//this.state.totalDiscount;
                      
                      if(this.state.fields.length == 1){                     
                        _totalDiscount = _discountAmtSend;
                        _totalGstAmt = _taxAmtSend;
                      }
                      else{                 
                        _totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                        _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                      }

                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          let finalPerDisAmt = 0;
                          
                          let discountPer  = itemData.discount_in_per;                         
                          if(itemData.discount_in_per == 0){
                            discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                          }

                          _qtyAK = itemData.quantity;
                          if (itemData.quantity == 0) {
                            _qtyAK = _opening_stock;
                          }
                          let itemPrice = parseFloat(_qtyAK) * parseFloat(itemData.rate);
                          _totalAmtItem = parseFloat(_totalAmtItem) + parseFloat(itemPrice);

                          itemData.quantity = _opening_stock;
                          itemData.total_amount = parseFloat(_itemToalAmtSend);
                          itemData.discount_in_amount = _discountAmtSend;
                          itemData.discount_in_per = discountPer;
                          itemData.tax_value = _taxAmtSend;
                          itemData.itemSerialNoAllData = data;
                          itemData.totalStocks = parseFloat(data.length);
                          itemData.availLenAk = 0;
                          _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);
                        }
                        
                      });
                      
                      if(this.state.fields.length == 1){    
                        _totalAmountOld = _itemToalAmtSend;
                      }else{
                        _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                      }
                      let _totalAmount = _totalAmountOld;
                      let _dueAmount = parseFloat(_totalAmountOld) ;
                      _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.paidAmount);
                    
                      let _totalAmt = _totalAmount;
                    
                      let _paidAM = this.state.paidAmount;
                      let _totalAmountNewSend = this.state.totalAmountOld;
                      let _totalAmountOldSend = _totalAmt;

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_AddNewSerialNo: false,
                        modalIsOpen_SerialBatchNo: false,
                        opening_stock: _opening_stock,
                        totalQuantity: _totalQuantity,
                        totalAmountOld: _totalAmt,
                        totalAmountNew: parseFloat(_totalAmountOld),
                        totalAmount: _totalAmt,
                        subtotal: _totalAmt,
                        dueAmount: _dueAmount,
                        gstAmount: _totalGstAmt,
                        totalDiscount: _totalDiscount,
                        btnShowSerialNormalBatch: 1,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemAddSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                    totalQuantity={this.state.totalQuantity >= 1 ? this.state.totalQuantity : 0}
                    itemPrice={this.state.itemPrice}
                    totalAmountOld={this.state.totalAmountOld}
                    transactionId={this.state.transactionId}
                    itemFields={this.state.fields}
                    indexPosition={this.state.itemPosition}
                  />
                </div>
                <br />
              </div>
              {/* <div className="modal-footer">
                  <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddNewSalePurSerialNo')}>Close</button>
              </div> */}
            </div>
          </div>
        </Modal>
        {/* End Modal Add New Serial No In Item*/}

        {/* Modal Add New Free Serial No In Item Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddNewFreeSerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNewSalePurFreeSerialNo"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Free Serial No<span> ({this.state.name})</span>
                  </h3>
                </div>
                <div className="block-content">
                  <QuickAddNewFreePurchaseSerialNo
                    callBack={(data, _opening_stock, _totalQuantity) => {
                      if (this.state.FreeItemQuantity == 1) {
                        document.getElementById(
                          "_free_quantity_" + this.state.itemPosition
                        ).readOnly = false;
                      }
                      let _fieldData = this.state.fields;
                      _fieldData.map((itemData, i) => {
                        if (i == this.state.itemPosition) {
                          itemData.free_quantity = _opening_stock;
                          itemData.itemSerialNoAllData = [
                            ...itemData.itemSerialNoAllData.filter(
                              (data) => !data.isFreeQuantity
                            ),
                            ...data,
                          ];
                          itemData.availLenAk = 0;

                          //itemData.itemSerialNoAllData = [...itemData.itemSerialNoAllData, ...data];
                        }
                      });

                      let _totalAmt = this.state.totalAmount;
                      let _totalDiscount = this.state.totalDiscount;
                      let _totalGstAmt = this.state.gstAmount;
                      let _dueAmount = this.state.totalAmount;

                      let _paidAM = 0;
                      let _totalAmountNewSend = this.state.totalAmount;
                      let _totalAmountOldSend = _totalAmt;

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_AddNewFreeSerialNo: false,
                        modalIsOpen_SerialBatchNo: false,
                        opening_stock: _opening_stock,
                        totalQuantity: _totalQuantity,
                        btnShowSerialNormalBatch: 1,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemAddSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                    totalQuantity={this.state.totalQuantity}
                    normalFQtyAdded={this.state.normal_quantity}
                    itemFields={this.state.fields}
                    indexPosition={this.state.itemPosition}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddNewSalePurFreeSerialNo')}>Close</button> */}
              </div>
            </div>
          </div>
        </Modal>
        {/* End Modal Add New Free Serial No In Item*/}

        {/* Modal Item Add New Sale Purchase Batch No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AddNewBatchNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNewBatchNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Batch No <span> ({this.state.name}) </span>
                  </h3>
                </div>
                <div className="block-content">
                  <QuickAddNewPurchaseBatchNo
                    callBack={(
                      data,
                      _used_stock,
                      freeQuantity,
                      _totalAmount,
                      _totalQuantity,
                      _totalAmountOld,
                      mrpPrice,
                      _discountAmtSend,
                      _taxAmtSend,
                      _itemToalAmtSend,
                      indexPosition,
                      _costPrice
                    ) => {
                      if (this.state.FreeItemQuantity == 1) {
                        document.getElementById("_free_quantity_" + this.state.itemPosition).readOnly = true;
                      }
                      let percenTageAmt = 0;
                      let _fieldData = this.state.fields;
                      //let _totalGstAmt = this.state.gstAmount ? this.state.gstAmount : 0;
                      //let _totalDiscount = this.state.totalDiscount ? this.state.totalDiscount : 0;
                      
                      let _totalGstAmt = 0;//this.state.gstAmount;
                      let _totalDiscount = 0;//this.state.totalDiscount;
                      
                      if(this.state.fields.length == 1){                     
                        _totalDiscount = _discountAmtSend;
                        _totalGstAmt = _taxAmtSend;
                      }
                      else{                 
                        //_totalDiscount = parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                        //_totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                        
                        _fieldData.map((itemData, i) => {
                          if (i!=indexPosition) { 
                            _totalDiscount =  parseFloat(_discountAmtSend) + parseFloat(itemData.discount_in_amount);
                            _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(itemData.tax_value);
                          }
                      })
                      }

                      let _Additional_CESSAK = 0;

                      _fieldData.map((itemData, i) => {
                        let _itemPosition = this.state.itemPosition;
                        //let percenTageAmtCol = parseFloat(mrpPrice) * (itemData.tax_type.ratio / 100);
                        if (_itemPosition == -1) {
                          _itemPosition = 0;
                        }
                        if (i == _itemPosition) {                          
                          let finalPerDisAmt = 0;
                          /*if (_businessSetting.ItemWiseDiscount == 1) {
                            if (itemData.discount_in_per > 0) {
                              finalPerDisAmt = parseFloat(mrpPrice) * parseFloat(itemData.discount_in_per / 100);
                              itemData.discount_in_amount = finalPerDisAmt;                            
                              _totalDiscount = parseFloat(_totalDiscount) +  parseFloat(finalPerDisAmt);
                            }
                            else{
                              let _totalDicountAmt = parseFloat(itemData.discount_in_amount) * parseFloat(_used_stock);
                              let finalDiscountPercentage = (parseFloat(itemData.discount_in_amount) * parseFloat(itemData.rate)) / 100;

                              itemData.discount_in_amount = _totalDicountAmt;
                              if(this.state.fields.length == 1){
                              _totalDiscount = parseFloat(_totalDiscount) + parseFloat(_totalDicountAmt);
                              }
                              itemData.discount_in_per = finalDiscountPercentage;
                              finalPerDisAmt =  _totalDicountAmt;
                            }
                            mrpPrice =  parseFloat(mrpPrice) - parseFloat(finalPerDisAmt);
                          }
                          
                          let percenTageAmtCol = parseFloat(mrpPrice) * (itemData.tax_type.ratio / 100);
                          if (_businessSetting.ItemWiseTax == 1 && itemData.sale_pruchase_tax_type == "Without Tax") 
                          {
                            percenTageAmt = parseFloat(mrpPrice) * (itemData.tax_type.ratio / 100);
                          } else {
                            percenTageAmt = 0;//this.state.gstAmount;
                          }*/

                          let discountPer  = itemData.discount_in_per;                         
                          if(itemData.discount_in_per == 0){
                            discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                          }

                          itemData.quantity = _used_stock;
                          itemData.free_quantity = freeQuantity;
                          itemData.total_amount = parseFloat(_itemToalAmtSend);
                          itemData.discount_in_amount = _discountAmtSend;
                          itemData.discount_in_per = discountPer;
                          itemData.tax_value = _taxAmtSend;
                          itemData.itemBatchNoAllData = data;
                          itemData.itemBatchNo = this.state.itemBatchNo;

                          _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);

                        }
                      });

                      if(this.state.fields.length == 1){    
                        _totalAmountOld = _itemToalAmtSend;
                      }else{
                        //_totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                        
                        _fieldData.map((itemData, i) => {
                          if (i!=indexPosition) { 
                            _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(itemData.total_amount) ;
                          }
                        })
                      }
                      _totalAmount = _totalAmountOld;
                      let _dueAmount = parseFloat(_totalAmountOld) ;
                      _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.paidAmount);
                    
                      let _totalAmt = _totalAmount;
                    
                      let _paidAM = this.state.paidAmount;
                      let _totalAmountNewSend = this.state.totalAmountOld;
                      let _totalAmountOldSend = _totalAmt;

                      this.props.callBackItem(
                        _fieldData,
                        _totalAmountOldSend,
                        _totalAmountNewSend,
                        _totalAmt,
                        _dueAmount,
                        _paidAM,
                        _totalDiscount,
                        _totalGstAmt,//.toFixed(2),
                        _totalQuantity,
                        this.state.handleAddDisable,
                        this.state.handleItemSalePurchasePrice
                      );

                      this.setState({
                        fields: _fieldData,
                        itemPosition: -1,
                        modalIsOpen_AddNewBatchNo: false,
                        modalIsOpen_SerialBatchNo: false,
                        used_stock: _used_stock,
                        totalQuantity: _totalQuantity,
                        totalAmountOld: _totalAmt,
                        totalAmountNew: parseFloat(_totalAmountOld),
                        totalAmount: _totalAmt,
                        subtotal: _totalAmt,
                        dueAmount: _dueAmount,
                        gstAmount: parseFloat(_totalGstAmt),
                        itemAddBatchNo: data,
                        totalDiscount: _totalDiscount,
                        btnShowSerialNormalBatch: 2,
                      });
                    }}
                    used_stock={this.state.used_stock}
                    itemBatchNoAll={this.state.itemAddBatchNo}
                    itemId={this.state.itemId}
                    totalQuantity={this.state.totalQuantity}
                    totalAmountOld={this.state.totalAmountNew}
                    itemPrice={this.state.itemPrice}
                    batchSendType={this.state.batchSendType}
                    batchNo={this.state.batchNo}
                    OldBatTotalQty={this.state.OldBatTotalQty}
                    OldBatStockTotalQty={this.state.OldBatStockTotalQty}
                    itemFields={this.state.fields}
                    indexPosition={this.state.itemPosition}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAddNewBatchNo')}>Close</button> */}
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add New Sale Purchase Batch No*/}

        {/* Modal View Batch Serial Data Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BatchViewPopup}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddBatchNo"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">View Data</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddBatchViewPop"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>

                
                <div className="block-content">
                  {this.state.popuptype == "Batch" && (
                    <div>
                      <h3>Batch Data</h3>

                      {this.state.popupVal.map((element, index) => (
                        <div
                          style={{
                            height: "40px !important",
                            margin: "0 0 14px 0 !important",
                          }}
                          className="block-content"
                        >
                          <button
                            className="btn btn-info pull-right"
                            onClick={this.serialPopChnage.bind(
                              this,
                              "modalAddBatchNo",
                              "Old",
                              element.batch_no,
                              element,
                              this.state.position
                            )}
                          >
                            Change Batch
                          </button>
                          {(element.used_stock > 0 ||
                            element.free_quantity > 0 ||
                            element.opening_stock > 0) && (
                            <div>
                              {_businessSetting.BatchNoChk == 1 && (
                                <p>
                                  {_businessSetting.BatchNoTxt} :{" "}
                                  {element.batch_no}
                                </p>
                              )}
                              {_businessSetting.ExpDateChk == 1 && (
                                <p>
                                  {_businessSetting.ExpDateTxt} :{" "}
                                  {element.exp_date}
                                </p>
                              )}
                              {_businessSetting.MfgDateChk == 1 && (
                                <p>
                                  {_businessSetting.MfgDateTxt} :{" "}
                                  {element.mfg_date}
                                </p>
                              )}
                              {_businessSetting.ModelNoChk == 1 && (
                                <p>
                                  {_businessSetting.ModelNoTxt} :{" "}
                                  {element.model_no!=""
                                    ? element.model_no
                                    : "NA"}
                                </p>
                              )}
                              {_businessSetting.SizeChk == 1 && (
                                <p>
                                  {_businessSetting.SizeTxt} :{" "}
                                  {element.size!="" ? element.size : "NA"}
                                </p>
                              )}
                              {_businessSetting.BatchNoAdditionalChk1 == 1 && (
                                <p>
                                  {_businessSetting.BatchNoAdditionalLabel1} :{" "}
                                  {element.batchNoAdditionalValue1!=""
                                    ? element.batchNoAdditionalValue1
                                    : "NA"}
                                </p>
                              )}
                              {_businessSetting.BatchNoAdditionalChk2 == 1 && (
                                <p>
                                  {_businessSetting.BatchNoAdditionalLabel2} :{" "}
                                  {element.batchNoAdditionalValue2!=""
                                    ? element.batchNoAdditionalValue2
                                    : "NA"}
                                </p>
                              )}
                              {_businessSetting.opening_stock == 1 && (
                                <p>
                                  Opening Stock :{" "}
                                  {element.opening_stock!=""
                                    ? element.opening_stock
                                    : "NA"}
                                </p>
                              )}
                              {element.free_quantity > 0 && (
                                <p>
                                  Free Quantity :{" "}
                                  {element.free_quantity!=""
                                    ? element.free_quantity
                                    : "NA"}
                                </p>
                              )}
                              {element.opening_stock > 0 && (
                                <p>
                                  Quantity :{" "}
                                  {element.opening_stock!=""
                                    ? element.opening_stock
                                    : "NA"}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {this.state.popuptype == "Serial" && (
                    <div>
                      <h3>Serial Data</h3>
                      {this.state.popupVal.map((element, index) => (
                        <div
                          style={{
                            height: "40px !important",
                            margin: "0 0 14px 0 !important",
                          }}
                          className="block-content"
                        >
                          {_businessSetting.SerialNoChk == 1 && (
                            <p>
                              {_businessSetting.SerialNoTxt} :{" "}
                              {element.serial_no}
                            </p>
                          )}
                          {_businessSetting.SerialNoAdditionalChk1 == 1 && (
                            <p>
                              {_businessSetting.SerialNoAdditionalLabel1} :{" "}
                              {element.serialNoAdditionalValue1
                                ? element.serialNoAdditionalValue1
                                : "NA"}
                            </p>
                          )}
                          {_businessSetting.SerialNoAdditionalChk2 == 1 && (
                            <p>
                              {_businessSetting.SerialNoAdditionalLabel2} :{" "}
                              {element.serialNoAdditionalValue2
                                ? element.serialNoAdditionalValue2
                                : "NA"}
                            </p>
                          )}
                          {
                            <p>
                              {element.isFreeQuantity ? "Free" : "Not Free"}
                            </p>
                          }
                          <button
                            onClick={() =>
                              this.removeSelectedSrData(
                                element.serial_no,
                                element.productId,
                                element.isFreeQuantity ? "F" : "N",
                                index
                              )
                            }
                            className="btn btn-danger"
                          >
                            <i className="si si-close"></i>Remove{" "}
                          </button>
                          <hr />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddBatchViewPop"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal View Batch Serial Data Begins */}

        {/*  Modal Normal Quantity select*/}
        <Modal
          isOpen={this.state.modalIsOpen_NotSerialNo}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNotSerialNo"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Without Tracking Type</h3>
                  <div className="block-options">
                    {/* <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddNotSerialNo"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button> */}
                  </div>
                </div>
                <QuickAddNormalQuantity
                  callBack={(
                    _used_stock,
                    mrpPrice,
                    _totalAmountOld,
                    _totalQuantity,
                    indexPosition,
                    _discountAmtSend,
                    _taxAmtSend,
                    _itemToalAmtSend,
                    _costPrice
                  ) => {
                    console.log('_itemToalAmtSend', _itemToalAmtSend);
                    const productId = this.state.itemId;
                    let percenTageAmt = 0;
                    let _fieldData = this.state.fields;

                    let _totalGstAmt = 0;//this.state.gstAmount;
                    let _totalDiscount = 0;//this.state.totalDiscount;
                    
                    if(this.state.fields.length == 1){                     
                      _totalDiscount = _discountAmtSend;
                      _totalGstAmt = _taxAmtSend;
                    }
                    else{                 
                      _totalDiscount = parseFloat(this.state.totalDiscount); //parseFloat(_discountAmtSend) + parseFloat(this.state.totalDiscount);        
                      _totalGstAmt = parseFloat(_taxAmtSend) + parseFloat(this.state.gstAmount);
                    }

                    let _Additional_CESSAK = 0;

                    if (this.state.FreeItemQuantity == 1) {
                      document.getElementById("_free_quantity_" + this.state.itemPosition).readOnly = false;
                    }
                    _fieldData.map((itemData, i) => {
                      if (i == this.state.itemPosition) {
                        
                        let discountPer  = itemData.discount_in_per;                         
                        if(itemData.discount_in_per == 0){
                          discountPer =  (parseFloat(_discountAmtSend) / parseFloat(_costPrice)) * parseFloat(100);
                        }
                        let finalPerDisAmt = 0;                                               

                        itemData.quantity = _used_stock;
                        itemData.total_amount = parseFloat(_itemToalAmtSend);
                        itemData.discount_in_amount = _discountAmtSend;
                        itemData.discount_in_per = discountPer;
                        itemData.tax_value = _taxAmtSend;
                        itemData.normal_quantity = _used_stock ? _used_stock : 0;

                        _Additional_CESSAK = parseFloat(_Additional_CESSAK) + parseFloat(itemData.Additional_CESS);

                      }
                    });

                    if(this.state.fields.length == 1){    
                      _totalAmountOld = _itemToalAmtSend;
                    }else{
                      _totalAmountOld = parseFloat(_itemToalAmtSend) + parseFloat(this.state.totalAmountOld) ;
                    }
                    console.log('_totalAmountOld', _totalAmountOld);
                    let _totalAmount = _totalAmountOld;
                    let _dueAmount = parseFloat(_totalAmountOld) ;
                    _dueAmount = parseFloat(_dueAmount) - parseFloat(this.state.paidAmount);
                  
                    let _totalAmt = _totalAmount;
                  
                    let _paidAM = 0;
                    let _totalAmountNewSend = this.state.totalAmountOld;
                    let _totalAmountOldSend = _totalAmt;

                    this.props.callBackItem(
                      _fieldData,
                      _totalAmountOldSend,
                      _totalAmountNewSend,
                      _totalAmt,
                      _dueAmount,
                      _paidAM,
                      _totalDiscount,
                      _totalGstAmt,
                      _totalQuantity,
                      this.state.handleAddDisable,
                      this.state.handleItemSalePurchasePrice
                    );

                    this.setState({
                      fields: _fieldData,
                      itemPosition: -1,
                      modalIsOpen_NotSerialNo: false,
                      totalQuantity: _totalQuantity,
                      totalAmountOld: _totalAmt,
                      totalAmountNew: parseFloat(_totalAmountOld),
                      totalAmount: _totalAmt,
                      subtotal: _totalAmt,
                      dueAmount: _totalAmt,
                      gstAmount: _totalGstAmt,
                      totalDiscount: _totalDiscount,
                      btnShowSerialNormalBatch: 0,
                    });
                  }}
                  used_stock={this.state.used_stock}
                  itemBatchNoAll={this.state.itemBatchNo}
                  itemId={this.state.itemId}
                  totalQuantity={this.state.totalQuantity}
                  totalAmountOld={this.state.totalAmountNew}
                  itemPrice={this.state.itemPrice}
                  normalRemainingQuantity={this.state.normal_remaining_quantity}
                  indexPosition={this.state.itemPosition}
                  normalQuantityTYpe="Purchase"
                  itemFields={this.state.fields}
                />
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddNotSerialNo"
                  )}
                >
                  Close
                </button> */}
              </div>
            </div>
          </div>
        </Modal>
        {/*  Modal Normal Quantity select*/}

        {/*  Modal Normal Free Quantity select*/}
        <Modal
          isOpen={this.state.modalIsOpen_NotSerialNoFree}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddNotSerialNoFree"
        >
          <div className="modal-dialog modal-ls" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Add Free Without Tracking Type
                  </h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddNotSerialNoFree"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <QuickAddNormalQuantityFree
                  callBack={(_used_stock, _totalQuantity, indexPosition) => {
                    const productId = this.state.itemId;
                    let percenTageAmt = 0;
                    let _fieldData = this.state.fields;
                    let _normal_quantity = this.state.normal_quantity;
                    _fieldData.map((itemData, i) => {
                      if (i == this.state.itemPosition) {
                        _normal_quantity =
                          parseFloat(_normal_quantity) +
                          parseFloat(_used_stock);
                        itemData.free_quantity = _used_stock;
                        itemData.normal_quantity = _normal_quantity
                          ? _normal_quantity
                          : 0;
                        itemData.normal_remaining_quantity =
                          this.state.normal_remaining_quantity -
                          _normal_quantity;
                      }
                    });
                    let _totalAmt = this.state.totalAmount;
                    let _totalDiscount = this.state.totalDiscount;
                    let _totalGstAmt = this.state.gstAmount;
                    let _dueAmount = this.state.totalAmount;

                    let _paidAM = 0;
                    let _totalAmountNewSend = this.state.totalAmount;
                    let _totalAmountOldSend = _totalAmt;

                    this.props.callBackItem(
                      _fieldData,
                      _totalAmountOldSend,
                      _totalAmountNewSend,
                      _totalAmt,
                      _dueAmount,
                      _paidAM,
                      _totalDiscount,
                      _totalGstAmt,
                      _totalQuantity,
                      this.state.handleAddDisable,
                      this.state.handleItemSalePurchasePrice
                    );

                    this.setState({
                      fields: _fieldData,
                      itemPosition: -1,
                      modalIsOpen_NotSerialNoFree: false,
                      totalQuantity: _totalQuantity,
                      btnShowSerialNormalBatch: 0,
                    });
                  }}
                  used_stock={this.state.used_stock}
                  itemBatchNoAll={this.state.itemBatchNo}
                  itemId={this.state.itemId}
                  totalQuantity={this.state.totalQuantity}
                  totalAmountOld={this.state.totalAmountNew}
                  itemPrice={this.state.itemPrice}
                  normalRemainingQuantity={this.state.normal_remaining_quantity}
                  indexPosition={this.state.itemPosition}
                  normalQuantityTYpe="Purchase"
                  normalUsedQty={this.state.normal_quantity}
                  freeQtyAk={this.state.freeQtyAk}
                  normalFQtyAdded={this.state.normal_quantity}
                  transactionId={this.state.transactionId}
                  itemFields={this.state.fields}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddNotSerialNoFree"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/*  Modal Normal Quantity select*/}
      </React.Fragment>
    );
  }
}
