import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = 'IN';
let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
    country_code = _companySession.country_code;
    tax_name = _companySession.tax_name;
    gstTaxDetails = _companySession.taxDetails;
}

let _loader = 'glb-ldr-prt active';

export default class ItemListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-service/',
            buttonTitle:'Add New',
            isLoaded : true, 
            itemArray : [],
            itemArrayFilter : [],
            _itemsArray : [],
            selectItems: [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            itemSelect :  '',
            errors: {
                selectItems : '',
            }
        }
    }
    async componentDidMount ()
    {  
        this.getItemData();
    }

    getItemData (){
        const requestData = JSON.stringify({
            company_id: _company_id
        });
        PostData(global.userItemInActiveList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                
                let _itemArray = responseJson.response;
                this.setState({itemArray:_itemArray,isLoaded:true});
            }
        }).catch(error => this.setState({ error, isLoaded: false }));
    }
    
    onChange(e) {
        let errors = this.state.errors;
        const _ItemsArray = this.state.itemArray;
        _ItemsArray.forEach(item => {
            if (item.item_id == e.target.value)
            {
                item.isChecked = e.target.checked;
            }
        });
        this.setState({ itemArray: _ItemsArray });
        errors['selectItems'] = "";
    }
    
    selectAllCheckboxes = event => {
        //console.log(this.state.itemArray);
        let _itemArray = this.state.itemArray;
        _itemArray.forEach(_itemArray => (_itemArray.isChecked = event.target.checked));
        this.setState({ itemArray: _itemArray });
        let errors = this.state.errors;
        errors['selectItems'] = "";
    }

    clickToAddService = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        const _ItemsArray = this.state.itemArray;
       
        isOneCheck = false;
        _ItemsArray.forEach(item => {
            if (item.isChecked == true)
            {               
                isOneCheck = true;
            }            
        });

        if(isOneCheck == false)
        {
            _isError = true;
            errors['selectItems'] = "*Please select any item";
            this.setState({errors: errors});
        }else{
            errors['selectItems'] = "";
        }       
        if (_isError == false){
            this.addItemInactiveMethodCall();
        }
    }

    addItemInactiveMethodCall()
    {         
        let _selectedItems = [];
        const _ItemsArray = this.state.itemArray;
        _ItemsArray.forEach(item => {
            if (item.isChecked == true)
            {
                _selectedItems.push(item.item_id);
            }
        });
        
        const requestData = JSON.stringify({
            businessId : this.state.businessId,
            userId : this.state.userId,
            company_id: this.state.company_id,
            //selectItems : this.state.selectItems,
            selectItems : _selectedItems,
        });       
        
        PostData(global.userItemMultipleActive,requestData,'POST').then((result)=>{            
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'business/manage-items/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    /* Item Filter Code */
    onItemFilterName = (e) => {
        const data = this.state.itemArray;
        let _name = e.currentTarget.value;
        if(_name == ''){
            this.getItemData();        
        }else{         
            //const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
            const keyword =  _name.slice(1);
            const filtered = data.filter(entry => Object.values(entry).some(val => typeof val == "string" && val.includes(keyword)));
            
            this.setState({itemArray:filtered});
        }
        
    }
    
    /* End Item Filter Code */
    
    

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let arraylength = this.state.itemArray.length;
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">  

                    <div className="claerfix"></div>
                    <div className="form-group row">
                        <div className="col-6">
                                <input type="text" onChange={this.onItemFilterName} className="form-control" placeholder="Search Item here.." id="page-header-search-input-item" name="page-header-search-input-item" autoComplete="off" onWheel={() => document.activeElement.blur()}/>                    
                            
                        </div>
                        <div className="claerfix"></div>
                        
                        {this.state.itemArray!='' &&
                        <div className="col-12">
                            <input type="checkbox" name="selectAll" onChange={this.selectAllCheckboxes} onWheel={() => document.activeElement.blur()}/> Select All
                        </div>
                        }
                        <div className="claerfix"></div>
                        <div className="col-12">

                            <div className="form-material open">    
                            {
                                this.state.itemArray.map((item,index) => {
                                    let isChecked = '';
                                    if(item.isChecked == true){
                                        isChecked = 'checked';
                                    }
                                    return(  
                                        <div>                                  
                                            <input type="checkbox" name="selectItems" checked={isChecked} className="checkBoxClass" value={item.item_id} onChange={this.onChange.bind(this)} onWheel={() => document.activeElement.blur()}/>&nbsp;&nbsp;
                                            <label><storng>{item.name}</storng></label>
                                        </div> 
                                        )
                                })
                            }     
                            {arraylength == 0 &&
                                <p>No inactive items found</p>
                            }            
                            
                            {this.state.itemArray!='' &&
                            <label htmlFor="login-password">Select Items</label>
                            }
                            <span className="vs_error_txt">{errors.selectItems.length > 0 && <span className='error'>{errors.selectItems}</span>}</span>
                            </div>
                        </div>                            
                    </div>
                    <div className="claerfix"></div>

                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddService} >
                                <i className="fa fa-check mr-5"></i> Active Item
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}