import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Modal from "react-modal";
import * as XLSX from "xlsx";

import ButtonPdf from "../htmlPdfCreation";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}

let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Party Name",
    field: "party_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Name",
    },
  },
  {
    label: "Contact No",
    field: "party_contact_no",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Contact No",
    },
  },
  {
    label: "Email",
    field: "party_email_id",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Email",
    },
  },
  {
    label: "Billing Address",
    field: "party_billing_address",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Billing Address",
    },
  },
  {
    label: "Shipping Address",
    field: "party_shipping_address",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Shipping Address",
    },
  },
  {
    label: "Opening Balance",
    field: "balance",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Opening Balance",
    },
  },
  {
    label: "GST No",
    field: "gst_number",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "GST No",
    },
  } /*,
    {
        label: 'Action',
        field: 'actionExtra',
        width: 100,
    }*/,
];

let _loader = "glb-ldr-prt active";

export default class userPartyUtilitiesListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      modalIsOpen_IMI: false,
      partyArrayFilter: [],
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      dateLabel: "",
      importExcelFile: "",
      errors: {
        name: "",
        importExcelFile: "",
      },
    };
  }

  async componentDidMount() {
    this.getPartyListData();
  }

  getPartyListData() {
    let _partyArray = [];

    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
          });
        } else {
          if (responseJson.response) {
            _partyArray = responseJson.response;
            _partyArray.map((itm, i) => {});
          }
          //_totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadTranscListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _transDetails = this.state.partyArrayFilter;
      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let _class = "";
          if (value.color == "Green") {
            _class = "text-success";
          } else if (value.color == "Red") {
            _class = "text-danger";
          } else {
            _class = "text";
          }
          let _actionExtra = "";
          let amountShow = (
            <span className={_class}>
              {numberFormat(Math.abs(value.totalOutCome))} ({value.txtBalance})
            </span>
          );
          if(value.totalOutCome == 0 || value.totalOutCome == null){
            amountShow = (
              <span >
                {numberFormat(0)} (Balance Clear)
              </span>
            )
          }
          rows.push({
            srNo: _i, //value.srNo,
            party_name: value.party_name,
            party_contact_no:
              value.party_contact_no!="" && value.party_contact_no!=null
                ? value.party_contact_no
                : "NA",
            party_email_id:
              value.party_email_id!="" && value.party_email_id!=null
                ? value.party_email_id
                : "NA",
            party_billing_address:
              value.party_billing_address!=""
                ? value.party_billing_address
                : "NA",
            party_shipping_address:
              value.party_shipping_address!=""
                ? value.party_shipping_address
                : "NA",
            date: value.party_opening_date,
            balance: amountShow,
            gst_number:
              value.gst_number!="" && value.gst_number!=null
                ? value.gst_number
                : "NA",
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalUploadParty") {
      this.setState({ modalIsOpen_IMI: true });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalUploadParty") {
      this.setState({ modalIsOpen_IMI: false });
    }
  };
  /*End Modal open close*/

  /* Code For Upload - Import Excel */
  onChangeFileUploadHandler = (event) => {
    // this.setState({
    //   importExcelData: [],
    //   errors: {
    //     name: "",
    //     importExcelFile: "",
    //   },
    // });
    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
              // evt = on_file_select event
              /* Parse data */
              const bstr = evt.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
              /* Update state */
              //console.log("Data>>>" + data);// shows that excel data is read
              let _jsonData = this.convertToJson(data);
              
              //console.log('JSON',_jsonData); // shows data in json format
              this.setState({
                importExcelData: _jsonData,
                importExcelFile: file,
              });
            };
            reader.readAsBinaryString(file);
          });
        })
      );
    }
  };

  
  convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      var currentline = lines[i].split(",");
      let _fileColumn = 7;
      var obj = {};
      for (var j = 0; j < _fileColumn; j++) {
        if (currentline[0]!="") {
          obj[headers[j]] = currentline[j];
        }
      }
      if(obj.hasOwnProperty('Name')){
        if (obj["Name"].length > 0 && obj["Opening Balance"] >= 0) {
          result.push(obj);
        }
      }
    }
    return JSON.stringify(result); //JSON
  }

  clickToImportSheet = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.importExcelFile == "") {
      _isError = true;
      errors["importExcelFile"] = "*Please Upload Party Excel File.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      this.importSheetMethodCall();
    }
  };

  importSheetMethodCall() {
    const requestData = JSON.stringify({
      importExcelData: this.state.importExcelData,
      userId: this.state.userId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });

    if (JSON.parse(this.state.importExcelData).length > 0) {
      PostData(global.userImportPartySheet, requestData, "POST").then(
        (result) => {
          if (result.success == true) {
            sendNotification("Success Message 👍", result.message, "success");
            this.closeModalHandler("modalUploadParty");
            this.getPartyListData();
          } else {
            sendNotification("Error Message 😓", result.message, "danger");
            return;
          }
        }
      );
    } else {
      sendNotification(
        "Error Message 😓",
        "Please Upload Correct Party Excel File",
        "danger"
      );
      return;
    }
  }
  /* End Code For Upload - Import Excel */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Import Parties
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <Link className="breadcrumb-item" to={"/business/dashboard/"}>
              Dashboard
            </Link>
            <span className="breadcrumb-item active">
              Manage Import Parties
            </span>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content">
                    <h2 className="block-title ml-10">Transactions</h2>
                    <Link
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalUploadParty"
                      )}
                      style={{ marginTop: "35px" }}
                      className="pull-right pull-right btn btn-sm btn-primary mb-10"
                    >
                      <i className="si si-equalizer"></i> Import Parties
                    </Link>
                  </div>
                  <div className="block-content" id="tableData">
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTranscListing()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Edit Party Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_IMI}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadParty"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Import Excel - Party Details</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadParty"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <div className="block-content">
                    <form
                      method="post"
                      id="form-login"
                      className="webForm px-30"
                    >
                      <h3
                        className="content-heading"
                        style={{ paddingTop: "6px" }}
                      >
                        <i className="fa fa-angle-right"></i> Upload Party Excel
                        Details{" "}
                      </h3>
                      <div className="form-group row">
                        <div className="col-6">
                          <div className="form-material open">
                            <input
                              accept=".xls,.xlsx"
                              type="file"
                              required="required"
                              className="form-control"
                              id="importExcelFile"
                              name="importExcelFile"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            <label htmlFor="login-username">
                              Upload Import Excel Party File
                            </label>
                            <span className="vs_error_txt">
                              {errors.importExcelFile.length > 0 && (
                                <span className="error">
                                  {errors.importExcelFile}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <span className="font-w600 text-info justify-content-end">
                            Download .xls/.xlsx (excel file) template file to
                            upload data
                          </span>
                          <br />
                          <a
                            href={
                              global.webUrl +
                              "sample-file/Import Parties Template.xlsx"
                            }
                            target="_blank"
                            className="btn btn-alt-info min-width-125"
                          >
                            Download <i className="fa fa-file-excel-o ml-5"></i>
                          </a>
                        </div>
                      </div>
                      <div className="claerfix"></div>
                      <div className="form-group row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-sm btn-hero btn-alt-primary"
                            onClick={this.clickToImportSheet}
                          >
                            <i className="fa fa-check mr-5"></i> Import Party
                            Details
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadParty"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Party Details*/}
      </React.Fragment>
    );
  }
}
