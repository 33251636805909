import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTaxDetails = [];

let isOneCheck = false;
let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTaxDetails = _companySession.taxDetails;
}

let _loader = "glb-ldr-prt active";

export default class ItemListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-unit/",
      itemSelectData: this.props.itemSelectData
        ? this.props.itemSelectData
        : "0",
      _dataType: this.props.dataType ? this.props.dataType : "ITEM",
      buttonTitle: "Add New",
      isLoaded: true,
      modalIsOpen_ActiveAssignUnit: false,
      unitArray: [],
      secUnitArray: [],
      checkedItems: new Map(),
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      selBaseUnitId: "0",
      selSecUnitd: "0",
      unit_conversion_rates: "",
      errors: {
        selBaseUnitId: "",
        selSecUnitd: "",
        unit_conversion_rates: "",
      },
    };
  }
  async componentDidMount() {
    let _urlDataUnits = global.userUnitList + "?businessId=" + _businessId;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    this.setState({ unitArray: _unitArray });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "selBaseUnitId":
        errors.selBaseUnitId = value < 1 ? "* Select any base unit" : "";
        let filteredArray = this.state.unitArray.filter(function (uDetail) {
          return uDetail.unit_id!=value;
        });

        let _baseUnitName = "";
        this.state.unitArray.map((unitD, index) => {
          if (unitD.unit_id == value) {
            _baseUnitName = unitD.unit_name;
          }
        });
        this.setState({
          selBaseUnitId: value,
          selSecUnitd: 0,
          secUnitName: "",
          secUnitArray: filteredArray,
          baseUnitName: _baseUnitName,
        });
        break;

      case "selSecUnitd":
        errors.selSecUnitd = value < 1 ? "* Select any secondary unit" : "";
        let _secUnitName = "";
        this.state.unitArray.map((unitD, index) => {
          if (unitD.unit_id == value) {
            _secUnitName = unitD.unit_name;
          }
        });
        this.setState({ selSecUnitd: value, secUnitName: _secUnitName });
        break;

      case "unit_conversion_rates":
        if (validator.isInt(value)) {
          errors.unit_conversion_rates = "";
          if (value == 0) {
            errors.unit_conversion_rates = "*Conversion rate can not be 0!";
          }
        } else {
          if (value == 0) {
            errors.unit_conversion_rates = "*Conversion rate can not be 0!";
          }
          errors.unit_conversion_rates = "*Unit conversion rate must be 1!";
        }

        this.setState({ unit_conversion_rates: value });
        break;

      default:
        break;
    }
  };

  clickToAddConversion = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.selBaseUnitId == "0") {
      _isError = true;
      errors["selBaseUnitId"] = "*Please Select Primary Unit";
      this.setState({ errors: errors });
    }
    if (this.state.selSecUnitd == "0") {
      _isError = true;
      errors["selSecUnitd"] = "*Please Select Secondary Unit";
      this.setState({ errors: errors });
    }

    if (this.state.unit_conversion_rates == "") {
      _isError = true;
      errors["unit_conversion_rates"] = "*Please Enter Unit Conversion";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      this.addUnitConversion();
    }
  };

  addUnitConversion() {
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      base_unit_id: this.state.selBaseUnitId,
      second_unit_id: this.state.selSecUnitd,
      unit_conversion_rates: this.state.unit_conversion_rates,
      itemIds: this.state.itemSelectData,
    });
    PostData(global.userAddUnitConversion, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            //this.props.callBack('hello');
            window.location.href = global.webUrl + this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _conversationText = "";

    if (this.state.unit_conversion_rates >= 0 && this.state.selSecUnitd > 0) {
      _conversationText =
        "1 " +
        this.state.baseUnitName +
        " = " +
        this.state.unit_conversion_rates +
        " " +
        this.state.secUnitName;
    }
    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="selBaseUnitId"
                  name="selBaseUnitId"
                  onChange={this.handleChange}
                >
                  <option value="0" selected>
                    {" "}
                    Select Primary Unit
                  </option>
                  {this.state.unitArray.map((item, index) => {
                    let _selBase = "";
                    if (this.state.selBaseUnitId == item.unit_id) {
                      _selBase = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.unit_id}
                        selected={_selBase}
                      >
                        {item.unit_name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Primary Unit</label>
              </div>
              <div id="selBaseUnitId-error" className="animated fadeInDown">
                {errors.selBaseUnitId.length > 0 && (
                  <span className="error">{errors.selBaseUnitId}</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="selSecUnitd"
                  name="selSecUnitd"
                  onChange={this.handleChange}
                >
                  <option value="0" selected>
                    {" "}
                    Select Secondary Unit
                  </option>
                  {this.state.secUnitArray.map((item, index) => {
                    let _selSec = "";
                    if (this.state.selSecUnitd == item.unit_id) {
                      _selSec = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.unit_id}
                        selected={_selSec}
                      >
                        {item.unit_name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Secondary Unit</label>
              </div>
              <div id="selSecUnitd-error" className="animated fadeInDown">
                {errors.selSecUnitd.length > 0 && (
                  <span className="error">{errors.selSecUnitd}</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-material open">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  id="unit_conversion_rates"
                  name="unit_conversion_rates"
                  value={this.state.unit_conversion_rates}
                  placeholder="Enter Unit Conversion Rates"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Unit Conversion Rates</label>
                <span className="iris_error_txt">
                  {errors.unit_conversion_rates.length > 0 && (
                    <span className="error">
                      {errors.unit_conversion_rates}
                    </span>
                  )}
                </span>
              </div>
              <span>
                <strong>{_conversationText}</strong>
              </span>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddConversion}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
