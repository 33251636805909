import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import QuickAddSerialNoAdjustment from "./quickAddSerialNoAdjustment";
import QuickAddBatchNoAdjustment from "./quickAddBatchNoAdjustment";
import Modal from "react-modal";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _businessSetting = {};
let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class quickItemSingleAdjustment extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;

      if (_UserSession!=null && _UserSession!="") {
        if (_UserSession.loginType!="Admin") {
          if (_UserSession!=undefined || _UserSession!=null) {
            _businessSetting = _UserSession.businessSetting;
          }
        }
      }
    }
    _isEditMode = false;
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-items/",

      itemName: this.props.itemName ? this.props.itemName : "ITEM",
      itemId: this.props.itemId ? this.props.itemId : "0",
      adjustmentId: this.props.adjustmentId ? this.props.adjustmentId : 0,
      itemAdjustType: this.props.itemAdjustType
        ? this.props.itemAdjustType
        : "",
      stockAddEditType: this.props.stockAddEditType
        ? this.props.stockAddEditType
        : "",
      buttonTitle: "Add New",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      isLoaded: true,
      stockQuantity: "",
      stockPerPrice: "",
      adjustmentDate: moment().format("MM/DD/YYYY"),
      //adjustmentDate :  moment().format('MMMM D, YYYY'),
      itemStockType: true,

      btnBatchNo: "iti__hide",
      btnSerialNo: "iti__hide",
      checkBatchRadioBtn: "",
      checkSrRadioBtn: "",
      checkSrRadioNormal: "checked",
      modalIsOpen_SerialNoSingle: false,
      modalIsOpen_BatchNoSingle: false,
      itemType: 0,
      totalBatchAddStock: 0,
      totalBatchReduceStock: 0,
      opening_stock: 0,

      details: "",
      errors: {
        stockQuantity: "",
        stockPerPrice: "",
      },
    };
  }
  async componentDidMount() {
    _isEditMode = false;

    /* Get Url Pass Parameter */
    let _urlDataUnits = global.userUnitList + "?businessId=" + _businessId+'&company_id='+this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    this.setState({ unitArray: _unitArray });

    let _itemId = this.state.itemId;
    if (this.state.stockAddEditType == "AddStock") {
      //console.log("==Fisrt-Add--Stock--");
      if (_itemId > 0) {
        let _itemArray = [];
        let _url_GetData = global.userItemDetails + "?itemId=" + _itemId;
        PostData(_url_GetData, "", "GET")
          .then((result) => {
            let responseJson = result;
            if (responseJson.success == false) {
              this.setState({ isLoaded: true });
            } else {
              if (responseJson.response) {
                let _sedSecUnitArray = [];
                let _baseUnitName,
                  _secUnitName = "";

                _itemArray = responseJson.response[0];
                let _btnSerialNo = "iti__hide";
                let _btnBatchNo = "iti__hide";
                let _itemsSerialData = _itemArray.itemSerialNo;
                let _itemsBatchData = _itemArray.itemBatchNo;

                if (_itemsSerialData.length > 0) {
                  _btnSerialNo = "";
                }
                if (_itemsBatchData.length > 0) {
                  _btnBatchNo = "";
                }
                _unitArray.map((item, index) => {
                  if (item.unit_id!=_itemArray.base_unit_id) {
                    _sedSecUnitArray.push(item);
                  }

                  if (item.unit_id == _itemArray.base_unit_id) {
                    _baseUnitName = item.unit_name;
                  }
                  if (item.unit_id == _itemArray.secound_unit_id) {
                    _secUnitName = item.unit_name;
                  }
                });
                let _checkSrRadioBtn = "";
                let _checkBatchRadioBtn = "";
                let _checkSrRadioNormal = "";
                if (this.state.itemAdjustType == "Serial") {
                  _checkSrRadioBtn = "checked";
                }
                if (this.state.itemAdjustType == "Batch") {
                  _checkBatchRadioBtn = "checked";
                }
                if (this.state.itemAdjustType == "Normal") {
                  _checkSrRadioNormal = "checked";
                }
                let _stockPerPrice = _itemArray.sale_price;
                this.setState({
                  buttonTitle: "Edit",
                  name: _itemArray.name,
                  businessId: _itemArray.business_id,
                  userId: _itemArray.user_id,
                  itemId: _itemId,
                  sale_price: _itemArray.sale_price,
                  purchase_price: _itemArray.purchase_price,
                  opening_stock: _itemArray.opening_stock,
                  isLoaded: true,
                  itemSerialNo: _itemsSerialData,
                  itemBatchNo: _itemsBatchData,
                  btnSerialNo: _btnSerialNo,
                  btnBatchNo: _btnBatchNo,
                  checkSrRadioBtn: _checkSrRadioBtn,
                  checkBatchRadioBtn: _checkBatchRadioBtn,
                  checkSrRadioNormal: _checkSrRadioNormal,
                  normal_remaining_quantity:
                    _itemArray.normal_remaining_quantity,
                  totalBatchAddStock: _itemArray.totalBatchAddStock,
                  totalBatchReduceStock: _itemArray.totalBatchReduceStock,
                  stockPerPrice: _stockPerPrice,
                });

                if (_itemId > 0) {
                  let _opening_stock = this.state.opening_stock;
                  if (this.state.itemAdjustType == "Normal") {
                    this.setState({
                      opening_stock: _itemArray.normal_remaining_quantity,
                    });
                  } else if (this.state.itemAdjustType == "Serial") {
                    let _itemSerialNo = this.state.itemSerialNo;
                    this.setState({ opening_stock: _itemSerialNo.length });
                  } else if (this.state.itemAdjustType == "Batch") {
                    let _used_stock = 0;
                    this.state.itemBatchNo.map((batchD, index) => {
                      _used_stock =
                        parseFloat(_used_stock) +
                        parseFloat(batchD.opening_stock);
                    });
                    _opening_stock = _used_stock;
                    this.setState({ opening_stock: _used_stock });
                  }
                }
              }
            }
          })
          .catch((error) => this.setState({ error, isLoaded: false }));
      }
    }

    if (this.state.stockAddEditType == "EditStock") {
      //console.log("==Fisrt-Edit--Stock--");
      //let _adjustmentId = this.props.adjustmentId;
      if (this.state.adjustmentId > 0) {
        let _itemAdjustmentArray = [];
        const requestData = JSON.stringify({
          adjustmentId: this.state.adjustmentId,
        });
        PostData(global.userItemStockAdjustmentDetail, requestData, "POST")
          .then((result) => {
            let responseJson = result;
            _isEditMode = true;
            if (responseJson.success == false) {
              this.setState({ isLoaded: true });
            } else {
              if (responseJson.response) {
                _itemAdjustmentArray = responseJson.response[0];
                let _stock_typeAK = false;
                if (_itemAdjustmentArray.stock_type == 1) {
                  _stock_typeAK = true;
                }
                this.setState({
                  buttonTitle: "Edit",
                  itemName: _itemAdjustmentArray.itemName,
                  adjustmentId: _itemAdjustmentArray.id,
                  userId: _itemAdjustmentArray.user_id,
                  itemId: _itemAdjustmentArray.item_id,
                  stockQuantity: _itemAdjustmentArray.stock_quantity,
                  stockPerPrice: _itemAdjustmentArray.stock_per_price,
                  adjustmentDate: moment(
                    _itemAdjustmentArray.adjustment_date
                  ).format("MM/DD/YYYY"),
                  itemStockType: _stock_typeAK,
                  details: _itemAdjustmentArray.details,
                  isLoaded: true,
                });

                //_itemArray = responseJson.response[0];
                let _btnSerialNo = "iti__hide";
                let _btnBatchNo = "iti__hide";
                let _itemsSerialData = _itemAdjustmentArray.itemSerialNo;
                let _itemsBatchData = _itemAdjustmentArray.itemBatchNo;

                if (_itemsSerialData.length > 0) {
                  _btnSerialNo = "";
                }
                if (_itemsBatchData.length > 0) {
                  _btnBatchNo = "";
                }
                /*_unitArray.map((item,index) => {
                      if(item.unit_id!=_itemAdjustmentArray.base_unit_id){
                          _sedSecUnitArray.push(item);
                      }
                      if(item.unit_id == _itemAdjustmentArray.base_unit_id){
                          _baseUnitName = item.unit_name;
                      }
                      if(item.unit_id == _itemAdjustmentArray.secound_unit_id){
                          _secUnitName = item.unit_name;
                      }
                })*/
                let _checkSrRadioBtn = "";
                let _checkBatchRadioBtn = "";
                let _checkSrRadioNormal = "";
                if (this.state.itemAdjustType == "Serial") {
                  _checkSrRadioBtn = "checked";
                }
                if (this.state.itemAdjustType == "Batch") {
                  _checkBatchRadioBtn = "checked";
                }
                if (this.state.itemAdjustType == "Normal") {
                  _checkSrRadioNormal = "checked";
                }
                this.setState({
                  buttonTitle: "Edit",
                  itemId: _itemId,
                  opening_stock: _itemAdjustmentArray.opening_stock,
                  isLoaded: true,
                  itemSerialNo: _itemsSerialData,
                  itemBatchNo: _itemsBatchData,
                  btnSerialNo: _btnSerialNo,
                  btnBatchNo: _btnBatchNo,
                  checkSrRadioBtn: _checkSrRadioBtn,
                  checkBatchRadioBtn: _checkBatchRadioBtn,
                  checkSrRadioNormal: _checkSrRadioNormal,
                  normal_remaining_quantity:
                    _itemAdjustmentArray.normal_remaining_quantity,
                  totalBatchAddStock: _itemAdjustmentArray.totalBatchAddStock,
                  totalBatchReduceStock:
                    _itemAdjustmentArray.totalBatchReduceStock,
                });

                if (_itemId > 0) {
                  let _opening_stock = this.state.opening_stock;
                  if (this.state.itemAdjustType == "Normal") {
                    this.setState({
                      opening_stock:
                        _itemAdjustmentArray.normal_remaining_quantity,
                    });
                  } else if (this.state.itemAdjustType == "Serial") {
                    let _itemSerialNo = this.state.itemSerialNo;
                    this.setState({ opening_stock: _itemSerialNo.length });
                  } else if (this.state.itemAdjustType == "Batch") {
                    let _used_stock = 0;
                    this.state.itemBatchNo.map((batchD, index) => {
                      _used_stock =
                        parseFloat(_used_stock) +
                        parseFloat(batchD.opening_stock);
                    });
                    _opening_stock = _used_stock;
                    this.setState({ opening_stock: _used_stock });
                  }
                }
              }
            }
          })
          .catch((error) => this.setState({ error, isLoaded: false }));
      }
    }

    let _totalStockAddEdit = "";
    if (
      this.state.stockAddEditType == "EditStock" &&
      this.state.itemAdjustType == "Batch"
    ) {
      if (this.state.itemStockType == true) {
        _totalStockAddEdit = this.state.totalBatchAddStock;
      } else {
        _totalStockAddEdit = this.state.totalBatchReduceStock;
      }
      //console.log(" total B--",_totalStockAddEdit);
    }
    if (
      this.state.stockAddEditType == "EditStock" &&
      this.state.itemAdjustType == "Normal"
    ) {
      _totalStockAddEdit = this.state.opening_stock;
      //console.log(" total N--",_totalStockAddEdit);
    }
    this.setState({ opening_stock: _totalStockAddEdit });
    //console.log(this.state.adjustmentId+" ID--total Final--"+_totalStockAddEdit);
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalAddSerialNo") {
      this.setState({ modalIsOpen_SerialNoSingle: true });
    } else if (popId == "modalAddBatchNo") {
      this.setState({ modalIsOpen_BatchNoSingle: true });
    }
  }
  closeModalHandler = (popId) => {
    if (popId == "modalAddSerialNo") {
      this.setState({ modalIsOpen_SerialNoSingle: false });
    } else if (popId == "modalAddBatchNo") {
      this.setState({ modalIsOpen_BatchNoSingle: false });
    }
  };
  stockChangeBtn = (e) => {
    let activeValue = e.target.checked;
    let _key = e.target.attributes["data-stock"].value;
    let _opening_stock = 0;
    let errors = this.state.errors;

    if (this.state.name == "") {
      errors["stockChange"] = "Please enter item name first";
      this.setState({
        checkBatchRadioBtn: "",
        checkSrRadioBtn: "",
        checkSrRadioNormal: "",
        errors: errors,
      });
    } else {
      let _isError = false;
      errors["stockChange"] = "";

      if (this.state.itemId > 0) {
        if (this.state.itemSerialNo!="" && _key == 0) {
          _opening_stock = this.state.normal_remaining_quantity;
        }
        if (this.state.itemSerialNo!="" && _key == 1) {
          //let _itemSerialNo =  this.state.itemSerialNo.filter((data) => data.isSoldOut!=true);
          let _itemSerialNo = this.state.itemSerialNo;
          _opening_stock = _itemSerialNo.length;
        } else if (this.state.itemBatchNo!="" && _key == 2) {
          let _used_stock = 0;
          this.state.itemBatchNo.map((batchD, index) => {
            _used_stock =
              parseFloat(_used_stock) + parseFloat(batchD.opening_stock);
          });
          _opening_stock = _used_stock;
        }
      }
      let _itemSerialNo = this.state.itemSerialNo;
      let _itemBatchNo = this.state.itemBatchNo;
      if (this.state.itemId == 0) {
        if (_key == 1) {
          _itemSerialNo = [];
          _itemBatchNo = [];
        } else if (_key == 2) {
          _itemSerialNo = [];
          _itemBatchNo = [];
        } else {
          _itemSerialNo = [];
          _itemBatchNo = [];
        }
      }
      if (_key == 1) {
        this.setState({
          btnSerialNo: "",
          btnBatchNo: "iti__hide",
          checkSrRadioBtn: "checked",
          checkBatchRadioBtn: "",
          checkSrRadioNormal: "",
          itemType: _key,
          opening_stock: _opening_stock,
          errors: errors,
          itemSerialNo: _itemSerialNo,
          itemBatchNo: _itemBatchNo,
        });
      } else if (_key == 2) {
        errors["opening_stock"] = "";
        this.setState({
          btnSerialNo: "iti__hide",
          btnBatchNo: "",
          checkBatchRadioBtn: "checked",
          checkSrRadioBtn: "",
          checkSrRadioNormal: "",
          itemType: _key,
          opening_stock: _opening_stock,
          errors: errors,
          itemSerialNo: _itemSerialNo,
          itemBatchNo: _itemBatchNo,
        });
      } else {
        this.setState({
          btnSerialNo: "",
          btnBatchNo: "",
          checkBatchRadioBtn: "",
          checkSrRadioBtn: "",
          checkSrRadioNormal: "checked",
          itemType: 0,
          opening_stock: _opening_stock,
          errors: errors,
          itemSerialNo: _itemSerialNo,
          itemBatchNo: _itemBatchNo,
        });
      }
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "stockQuantity":
        if (validator.isInt(value)) {
          errors.stockQuantity = "";
          if (
            this.state.itemAdjustType == "Normal" &&
            this.state.itemStockType == false
          ) {
            if (parseFloat(value) > parseFloat(this.state.opening_stock)) {
              errors.stockQuantity =
                "*Reduce quantity cannot be more than in-stock quantity!";
            }
          }
        } else {
          errors.stockQuantity = "*Stock Quantity should be numeric!";
        }
        this.setState({ stockQuantity: value });
        break;

      case "stockPerPrice":
        if (validator.isInt(value)) {
          errors.stockPerPrice = "";
        } else {
          errors.stockPerPrice = "*Stock Price should be numeric!";
        }
        this.setState({ stockPerPrice: value });
        break;

      case "details":
        this.setState({ details: value });
        break;
    }
  };

  clickToAddItemAdjustment = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.stockQuantity == "0" || this.state.stockQuantity == "") {
      _isError = true;
      errors["stockQuantity"] = "*Please enter the valid quantity";
      this.setState({ errors: errors });
    }
    if (this.state.stockPerPrice == "0" || this.state.stockPerPrice == "") {
      _isError = true;
      errors["stockPerPrice"] = "*Please enter the valid stock price";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.adjustmentId > 0) {
        this.editIemAdjustMent();
      } else {
        this.addItemAdjustMent();
      }
    }
  };

  addItemAdjustMent() {
    let _itemSerialNo = [];
    let _itemBatchNo = [];
    if (this.state.itemAdjustType == "Serial") {
      _itemSerialNo = this.state.itemSerialNo;
    }
    if (this.state.itemAdjustType == "Normal") {
    }
    if (this.state.itemAdjustType == "Batch") {
      _itemBatchNo = this.state.itemBatchNo;
    }

    const requestData = JSON.stringify({
      itemId: this.state.itemId,
      userId: this.state.userId,
      businessId: this.state.businessId,
      itemName: this.state.itemName,
      stockQuantity: this.state.stockQuantity,
      stockPerPrice: this.state.stockPerPrice,
      adjustmentDate: moment(this.state.adjustmentDate).format("MMMM D, YYYY"), //this.state.adjustmentDate,
      itemStockType: this.state.itemStockType,
      details: this.state.details,
      itemAdjustType: this.state.itemAdjustType,
      itemBatchNo: _itemBatchNo,
      itemSerialNo: _itemSerialNo,
    });
    //console.log("==here--sub--",(requestData));return;
    PostData(global.userItemAddStockAdjustment, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            //this.props.callBack('hello');
            window.location.href = global.webUrl + this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editIemAdjustMent() {
    let _itemSerialNo = [];
    let _itemBatchNo = [];
    if (this.state.itemAdjustType == "Serial") {
      _itemSerialNo = this.state.itemSerialNo;
    }
    if (this.state.itemAdjustType == "Normal") {
    }
    if (this.state.itemAdjustType == "Batch") {
      _itemBatchNo = this.state.itemBatchNo;
    }
    const requestData = JSON.stringify({
      adjustmentId: this.state.adjustmentId,
      itemId: this.state.itemId,
      userId: this.state.userId,
      businessId: this.state.businessId,
      itemName: this.state.itemName,
      stockQuantity: this.state.stockQuantity,
      stockPerPrice: this.state.stockPerPrice,
      adjustmentDate: moment(this.state.adjustmentDate).format("MMMM D, YYYY"), //this.state.adjustmentDate,
      itemStockType: this.state.itemStockType,
      details: this.state.details,
      itemAdjustType: this.state.itemAdjustType,
      itemBatchNo: _itemBatchNo,
      itemSerialNo: _itemSerialNo,
    });
    //console.log("==here--sub--",(requestData));return;
    PostData(global.userItemEditStockAdjustment, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            //this.props.callBack('hello');
            window.location.href = global.webUrl + this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  handleCallBackDate = (start) => {
    this.setState({ adjustmentDate: start.format("MMMM D, YYYY") });
  };

  onToggleItemStock = (checked) => {
    if (this.state.stockAddEditType == "EditStock") {
      alert("You can not change the stock type");
    } else {
      let data = this.state.itemSerialNo ? this.state.itemSerialNo : [];
      if(data.length > 0){
        data = data.map((data) => ({
          ...data,
            isSelected: false,
          productId: 0
        }));
      }
      this.setState({ itemStockType: checked, stockQuantity: "", itemSerialNo: data});
      //console.log(checked+'==>CHK>===')
    }
  };

  isDateRefresh = () => {
    let _invoiceDate = this.state.adjustmentDate;
    if (_isEditMode == true && this.props.adjustmentId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" 
                  onWheel={() => document.activeElement.blur()}/>
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.props.adjustmentId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: moment().format("MM/DD/YYYY"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" 
                  onWheel={() => document.activeElement.blur()}/>
        </DateRangePicker>
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    let _stockText = "Reduce Stock";
    if (this.state.itemStockType == true) {
      _stockText = "Add Stock";
    }
    //console.log("-d chk--",_stockText);

    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          {/* {this.state.stockAddEditType+'==>>=='+this.state.opening_stock+'==>>=='+this.state.itemAdjustType} */}
          {this.state.stockAddEditType == "EditStock" &&
            this.state.itemStockType == true && (
              <div className="form-group row">
                <div className="col-8">
                  <div>
                    <ToggleSwitch
                      txtRight="Add Stock"
                      small
                      id="itemStock"
                      checked={this.state.itemStockType}
                      onChange={this.onToggleItemStock}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
          {this.state.stockAddEditType == "EditStock" &&
            this.state.itemStockType == false && (
              <div className="form-group row">
                <div className="col-8">
                  <div>
                    <ToggleSwitch
                      txtLeft="Reduce Stock"
                      small
                      id="itemStock"
                      checked={this.state.itemStockType}
                      onChange={this.onToggleItemStock}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
          {this.state.stockAddEditType == "AddStock" && (
            <div className="form-group row">
              <div className="col-8">
                <div>
                  <ToggleSwitch
                    txtLeft="Reduce Stock"
                    txtRight={"Add Stock"}
                    small
                    id="itemStock"
                    checked={this.state.itemStockType}
                    onChange={this.onToggleItemStock}
                    disabled={parseInt(this.state.stockQuantity) > 0 ? true : false }
                  />
                </div>
              </div>
            </div>
          )}
          {/* <div className="form-group row">
                        <div className="col-8">
                            <div>
                                <ToggleSwitch
                                txtLeft="Reduce Stock"
                                txtRight="Add Stock"
                                small
                                id="itemStock"
                                checked={this.state.itemStockType}
                                onChange={this.onToggleItemStock}
                                />
                                <p>Stock Type: {String(this.state.itemStockType)}</p>
                            </div>
                        </div>
                    </div>
                     */}
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <label htmlFor="login-password">Item Name</label>
                <p htmlFor="login-password">{this.state.itemName}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">Adjustment Date</label>
              </div>
            </div>
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            {this.state.itemAdjustType == "Normal" && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="radio"
                    id="Normal"
                    name="stockChange"
                    data-stock="0"
                    onClick={this.stockChangeBtn}
                    checked={this.state.checkSrRadioNormal}
                  />
                  <label htmlFor="login-username">Normal</label>
                </div>
              </div>
            )}
            {_businessSetting.SerialNoChk > 0 &&
              this.state.itemAdjustType == "Serial" && (
                <div className="col-4">
                  <div className="form-material open">
                    <input
                      type="radio"
                      id="SerialNo"
                      name="stockChange"
                      data-stock="1"
                      onClick={this.stockChangeBtn}
                      checked={this.state.checkSrRadioBtn}
                    />
                    <label htmlFor="login-username">Serial No</label>
                  </div>
                </div>
              )}
            {_businessSetting.BatchNoChk > 0 &&
              this.state.itemAdjustType == "Batch" && (
                <div className="col-4">
                  <div className="form-material open">
                    <input
                      type="radio"
                      id="batchNo"
                      name="stockChange"
                      data-stock="2"
                      onClick={this.stockChangeBtn}
                      checked={this.state.checkBatchRadioBtn}
                    />
                    <label htmlFor="login-username">Batch No</label>
                  </div>
                </div>
              )}
          </div>

          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                {this.state.stockAddEditType == "EditStock" &&
                  this.state.itemAdjustType == "Batch" && (
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      id="stockQuantity"
                      name="stockQuantity"
                      value={this.state.stockQuantity}
                      placeholder="Enter Quantity"
                      onChange={this.handleChange}
                      readOnly
                      onWheel={() => document.activeElement.blur()}
                    />
                  )}
                {this.state.stockAddEditType == "AddStock" &&
                  this.state.itemAdjustType == "Batch" && (
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      id="stockQuantity"
                      name="stockQuantity"
                      value={this.state.stockQuantity}
                      placeholder="Enter Quantity"
                      onChange={this.handleChange}
                      readOnly
                      onWheel={() => document.activeElement.blur()}
                    />
                  )}

                {this.state.itemAdjustType == "Serial" && (
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="stockQuantity"
                    name="stockQuantity"
                    value={this.state.stockQuantity}
                    placeholder="Enter Quantity"
                    onChange={this.handleChange}
                    readOnly
                    onWheel={() => document.activeElement.blur()}
                  />
                )}

                {this.state.itemAdjustType == "Normal" && (
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="stockQuantity"
                    name="stockQuantity"
                    value={this.state.stockQuantity}
                    placeholder="Enter Quantity"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                )}
                <label htmlFor="login-password">
                  Total Quantity
                  {this.state.itemAdjustType == "Normal" && (
                    <span> (In Stock : {this.state.opening_stock})</span>
                  )}
                </label>
              </div>
              <div id="stockQuantity-error" className="animated fadeInDown">
                {errors.stockQuantity.length > 0 && (
                  <span className="error">{errors.stockQuantity}</span>
                )}
              </div>
            </div>
            {this.state.itemAdjustType == "Serial" &&
              _businessSetting.SerialNoChk == 1 && (
                <div className="col-1">
                  <div className="form-material open">
                    <a
                      className="pull-right btn btn-alt-primary"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAddSerialNo",
                        "0"
                      )}
                    >
                      Serial No
                    </a>
                  </div>
                </div>
              )}
            {this.state.itemAdjustType == "Batch" &&
              _businessSetting.BatchNoChk == 1 && (
                <div className="col-1">
                  <div className="form-material open">
                    <a
                      className="pull-right btn btn-alt-primary"
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAddBatchNo",
                        "0"
                      )}
                    >
                      Batch No
                    </a>
                  </div>
                </div>
              )}

            <div className="col-3">
              <div className="form-material open">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  id="stockPerPrice"
                  name="stockPerPrice"
                  value={this.state.stockPerPrice}
                  placeholder="Enter At Price"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-password">
                  At Price <span className="text-danger">*</span>
                </label>
              </div>
              <div id="stockPerPrice-error" className="animated fadeInDown">
                {errors.stockPerPrice.length > 0 && (
                  <span className="error">{errors.stockPerPrice}</span>
                )}
              </div>
            </div>

            <div className="col-3">
              <div className="form-material open">
                <input
                  type="text"
                  className="form-control"
                  id="details"
                  name="details"
                  value={this.state.details}
                  placeholder="Enter Details"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Details </label>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddItemAdjustment}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> Save
              </button>
            </div>
          </div>
        </form>

        {/* Modal Item Add Serial No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SerialNoSingle}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddSerialNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">
                    Serial No<span> ({_stockText})</span>
                  </h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddSerialNo"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddSerialNoAdjustment
                    isRedirectTo={"business/add-item/"}
                    adjustmentId={this.state.adjustmentId}
                    stockType={_stockText}
                    stockAddEditType={this.state.stockAddEditType}
                    itemStockType={this.state.itemStockType}
                    callBack={(data, _stockQuantity) => {
                      let errors = this.state.errors;
                      errors["stockChange"] = "";
                      errors["opening_stock"] = "";
                      this.setState({
                        itemSerialNo: data,
                        modalIsOpen_SerialNoSingle: false,
                        //opening_stock: _opening_stock,
                        errors: errors,
                        stockQuantity: _stockQuantity,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemSerialNoAll={this.state.itemSerialNo}
                    itemId={this.state.itemId}
                    itemName={this.state.name}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAddSerialNo"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Serial No*/}

        {/* Modal Item Add Batch No Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BatchNoSingle}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddBatchNo"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Batch No ({_stockText})</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddBatchNo"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddBatchNoAdjustment
                    isRedirectTo={"business/add-item/"}
                    stockType={_stockText}
                    stockAddEditType={this.state.stockAddEditType}
                    itemStockType={this.state.itemStockType}
                    callBack={(data, _opening_stock, _stockQuantity) => {
                      let errors = this.state.errors;
                      errors["stockChange"] = "";
                      this.setState({
                        itemBatchNo: data,
                        modalIsOpen_BatchNoSingle: false,
                        opening_stock: _opening_stock,
                        errors: errors,
                        stockQuantity: _stockQuantity,
                      });
                    }}
                    opening_stock={this.state.opening_stock}
                    itemBatchNoAll={this.state.itemBatchNo}
                    itemId={this.state.itemId}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this, "modalAddBatchNo")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Add Batch No*/}
      </React.Fragment>
    );
  }
}
