import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';

let _UserSession = localStorage.getItem('vs_UserSession');
if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _company_id = _companySession.company_id;
}

let _loader = 'glb-ldr-prt active';

export default class ItemListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isRedirectTo: (this.props.isRedirectTo) ? this.props.isRedirectTo : 'business/manage-unit/',
           
            buttonTitle:'Add New',
            isLoaded : true, 
            unitArray : [],
            secUnitArray : [],
            checkedItems: new Map(),
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,
            partyGroup :  '',
            errors: {
                partyGroup  :  '',
            }
        }
    }
    async componentDidMount ()
    {        
        

    }
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            
            case 'partyGroup':                 
                errors.partyGroup = value.length < 3 ? '*Party Group must be 3 characters long!' : '';
                                 
                this.setState({partyGroup:value}); 
            break;

        }
    }
    

    clickToAddData = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
                   
        
        if(this.state.partyGroup == '')
        {
            _isError = true;
            errors['partyGroup'] = "*Please Enter party Group";
            this.setState({errors: errors});
        }
        
        if (_isError == false){
            this.addNewItemPartyGroup();
        }

    }

    addNewItemPartyGroup()
    {  
        const requestData = JSON.stringify({
            businessId : this.state.businessId,
            name: this.state.partyGroup,
            company_id : this.state.company_id,
        });   
          
        PostData(global.userAddPartyGroup,requestData,'POST').then((result)=>{            
            if(result.success == true){
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        this.props.callBack('hello');
                        //window.location.href = global.webUrl+this.state.isPageRedirect;
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
              
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <form method="post" id="form-login" className="webForm px-30">  
                    
                    <div className="claerfix"></div>
                    <div className="form-group row">                                                        
                            
                        <div className="col-4">
                            <div className="form-material open">
                                <input type="text" className="form-control" id="partyGroup" name="partyGroup" value={this.state.partyGroup} placeholder="Enter Party Group" onChange={this.handleChange}/>
                                    <label htmlFor="login-username">Party Group Name</label>
                                <span className="iris_error_txt">{errors.partyGroup.length > 0 && <span className='error'>{errors.partyGroup}</span>}</span>
                            </div>                           
                            
                        </div>                                    
                        
                    </div>
                    <div className="claerfix"></div>

                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddData} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}