import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewExpensecategory from "./expenseCategoryMaster";
import QuickAddNewExpenseItem from "./expenseItemMaster";
import QuickAdjustAddNewTerms from "./quickAddTerms";
import QuickTransactionExpenseItemSection from "./quickTransactionExpenseItemSection";
import QuickAddNewParty from "../businessParty/quickPartyAdd";
import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let maxNumber = 999999;
let lengthInvNo = 6;
let _invoiceNumber = Math.floor(Math.pow(10, lengthInvNo - 1) + Math.random() * (Math.pow(10, lengthInvNo) - Math.pow(10, lengthInvNo - 1) - 1));

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _colorVal = "";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _bankArray = [];
let _settingType = "showall";
_bankArray.push(
  { bank_id: "0", bank_name: "", paymentType: "Cash" },
  { bank_id: "0", bank_name: "", paymentType: "Cheque" }
);

let _businessSetting = {};
/*For State Data*/

let _loader = "glb-ldr-prt active";
let _isEditMode = false;

export default class userAddExxpensePanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;
      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;

      let bankArrayData = _companySession.bankDetails
        ? _companySession.bankDetails
        : [];
      bankArrayData.map((bank, index) => {
        if (
          !(
            _bankArray.filter((item) => item.bank_name == bank.bank_name)
              .length > 0
          )
        ) {
          _bankArray.push(bank);
        }
      });
    }
    //console.log("---here===", JSON.stringify(_businessSetting));

    this.state = {
      buttonTitle: "Add Purchase",
      toggleState: false,

      _catReg: "iti__hide",
      _partyReg: "iti__hide",
      _timeReg: "iti__hide",
      expenseCat_id: "0",
      expense_category: "",
      expenseArrayFilter: [],
      expenseArray: [],

      partyArrayFilter: [],
      partyArray: [],

      itemArray: [],
      itemArrayFilter: [],
      rows: [],
      fields: [],
      stateArray: [],
      taxRateArray: _taxRateArray,
      paymentTermsData: [],
      modalIsOpen_SP: false,
      modalIsOpen_SBANK: false,
      modalIsOpen_NP: false,
      modalIsOpen_SITEM: false,

      _itemReg: "iti__hide",
      _itemRegIndex: "0",

      isLoaded: true,
      isDateUpdate: false,

      receivedHide: "iti__hide",

      rowHtml: "",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      paymentType: "Cash",
      invoiceNo: _invoiceNumber,
      invoiceDate: moment().format("MM/DD/YYYY"),
      //invoiceTime: _businessSetting.Tran_AddTime > 0 ? moment().format("h:mm A") : "",
      invoiceTime: moment().format("h:mm A"),
      paymentRefNo: "",
      description: "",
      descImage: "",

      imagePreviewUrl: [],
      expenseCatId: "",
      bankId: "0",

      bankName: "",
      partyRemainBalance: 0,
      party_id: "",

      isPaidAmountDisable: false,

      party_name: "",
      dueAmount: 0,
      paidAmount: 0,
      totalAmount: 0,
      totalAmountOld: 0,
      totalQuantity: 0,
      gstAmount: 0,
      totalDiscount: 0,
      transactionId: 0,
      isToggleEdit: false,
      additional_value_1: "",
      additional_value_2: "",
      additional_value_3: "",
      additional_value_date: moment().format("MM/DD/YYYY"),
      poDate: moment().format("MM/DD/YYYY"),
      poNumber: "",
      dueOnDate: moment().format("MM/DD/YYYY"),
      dueTerms: "",
      countryCode: _countryCode,
      countryName: _countryName,
      TransportDetailField1: "",
      TransportDetailField2: "",
      TransportDetailField3: "",
      TransportDetailField4: "",
      TransportDetailField5: "",
      TransportDetailField6: "",
      pageLoadTerms: 0,
      dateCalendarChange: 0,
      itemQtySend: 0,
      totalTransDiscountAmount: 0,
      total_amount_er: "",
      paid_amount_er: "",
      modalIsOpen_AddTerm: false,
      transactionType: "",
      cheque_open_status : 0,
      errors: {
        invoiceNo: "",
        invoiceDate: "",
        expenseCatId: "",
        expense_category: "",
        party_name: "",
        total_amount_er: "",
        paid_amount_er: "",
      },
    };
  }

  async componentDidMount() {
    /*Due Dates terms setting data */
    let _selTermsId = 0;
    let _terms_days = 0;
    const requestDueDateData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });
    PostData(global.businessDueDatePaymentTermList, requestDueDateData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
          }
          let _mainResponse = responseJson.response;
          this.setState({
            isLoaded: true,
            paymentTermsData: responseJson.response,
          });
          
          console.log('_mainResponse', JSON.stringify(_mainResponse))
          _mainResponse.map((item, index) => {
            if (item.terms_default_status == 1) {
              _selTermsId = item.id;
              _terms_days = item.terms_days;
              let dueDateOnload = moment()
                .add(_terms_days, "days")
                .format("MM/DD/YYYY");
              this.setState({ dueDays: _terms_days, dueOnDate: dueDateOnload });
            }
          });
          console.log("==_selTermsId==", _selTermsId);
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
    //this.state.dueTerms = _selTermsId;
    //this.setState({ dueTerms: _selTermsId });
    /* End Due Dates terms setting data */

    let _handleParam = this.props.location.search;
    let _transactionType = "";
    let transactionId = 0;
    _handleParam = _handleParam.split("?auth=");
    if (_handleParam.length > 1) {
      _handleParam = _handleParam[1].split("&");
      transactionId = _handleParam[0] ? _handleParam[0] : 0;
      if (transactionId > 0) {
        _isEditMode = true;
      }
      _transactionType = _handleParam[1].split("tType=");
      _transactionType = _transactionType[1];
    }
    // let transactionId = _handleParam[1] ? _handleParam[1] : 0;
    // if (transactionId > 0) {
    //   _isEditMode = true;
    // }

    this.getExpenseCategoryListData();
    this.getPartyListData();
    this.getItemListData(_company_id);
    this.getStateListData();
    //this.getPaymentTerms();

    /*For master Units*/
    let _urlDataUnits = global.userUnitList + "?businessId=" + _businessId;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    /*For master Units*/
    this.setState({ unitArray: _unitArray, isLoaded: true });

    if (transactionId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: transactionId,
      });

      PostData(global.userExpenseTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href = global.webUrl + "business/manage-expense/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              console.log('_transactionArray', JSON.stringify(_transactionArray))
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;
              let customerData = _transactionArray.customer;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              let _toggleState = false;
              if (
                _transactionArray.isToggle === "true" ||
                _transactionArray.isToggle === true
              ) {
                _toggleState = true;
              }
              let _dateCalendarChange = 0
              let dueTermsExits = this.state.paymentTermsData.filter((item) => item.id === _transactionArray.dueTerms)
                if(dueTermsExits.length === 0){
                  _transactionArray.dueTerms = 0
                }
                
              if(_transactionArray.dueTerms == 0){
                _dateCalendarChange = 1;
              }
              

              this.setState({
                transactionType: _transactionType,
                expenseCat_id: customerData.expenseCat_id,
                expense_category: customerData.expense_category,
                invoiceTime: _transactionArray.invoice_time,
                party_id: customerData.party_id,
                party_name: customerData.party_name,
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                buttonTitle: "Edit Purchase",
                invoiceNo: _transactionArray.invoiceNo,
                invoiceDate: moment(_transactionArray.invoiceDate).format(
                  "MM/DD/YYYY"
                ),
                isToggleEdit: _toggleState,
                toggleState: _toggleState,
                businessId: _transactionArray.business_id,
                companyId: _transactionArray.company_id,
                state: _transactionArray.countryState,
                countryState: _transactionArray.countryState,
                totalAmount: transAmout.totalAmount,
                totalAmountOld: transAmout.totalAmount,
                paidAmount: transAmout.paidAmount,
                isPaidAmountDisable : parseFloat(transAmout.totalAmount) === parseFloat(transAmout.paidAmount),
                subtotal: transAmout.subtotal,
                totalQuantity: transAmout.totalQty,
                dueAmount: transAmout.dueAmount,
                gstAmount: transAmout.gstAmount,
                totalDiscount: transAmout.totalDiscount,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _transactionArray.paymentRefNo,
                fields: itemsData,
                isLoaded: true,
                transactionId: transactionId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                additional_value_1: _transactionArray.additional_value_1,
                additional_value_2: _transactionArray.additional_value_2,
                additional_value_3: _transactionArray.additional_value_3,
                additional_value_date: moment(
                  _transactionArray.additional_value_date
                ).format("MM/DD/YYYY"),

                dueTerms: _transactionArray.dueTerms,
                dueOnDate: moment(_transactionArray.dueOnDate).format("MM/DD/YYYY"),
                dateCalendarChange :  _dateCalendarChange,
                poDate: moment(_transactionArray.po_date).format("MM/DD/YYYY"),
                poNumber: _transactionArray.po_number,
                TransportDetailField1: _transactionArray.TransportDetailField1,
                TransportDetailField2: _transactionArray.TransportDetailField2,
                TransportDetailField3: _transactionArray.TransportDetailField3,
                TransportDetailField4: _transactionArray.TransportDetailField4,
                TransportDetailField5: _transactionArray.TransportDetailField5,
                TransportDetailField6: _transactionArray.TransportDetailField6,
                settingType: _transactionArray.settingType,

                transactionWiseTaxId: _transactionArray.transactionWiseTaxId,
                totalTransTaxAmount: _transactionArray.totalTransTaxAmount,
                totalTransDiscountPer: _transactionArray.totalTransDiscountPer,
                totalTransDiscountAmount: _transactionArray.totalTransDiscountAmount,

                TransAdditionalCharge1: _transactionArray.TransAdditionalCharge1,
                TransAdditionalCharge2: _transactionArray.TransAdditionalCharge2,
                TransAdditionalCharge3: _transactionArray.TransAdditionalCharge3,
                cheque_open_status : _transactionArray.cheque_open_status,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  getPaymentTerms() {}

  getStateListData() {
    let _stateArray = [];

    let _url_GetData =
      global.adminStateList + "?countryCode=" + this.state.countryCode;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _stateArray = responseJson.response;
          }
          this.setState({ stateArray: _stateArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  getPartyListData() {
    let _partyArray = [];
    let _url_GetData =
      global.userPartyList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          let _partyBal = 0;
          if (responseJson.response) {
            _partyArray = responseJson.response;
            if (this.state.party_id > 0) {
              _partyArray.map((party, index) => {
                if (party.party_id == this.state.party_id) {
                  _partyBal = party.totalOutCome;
                }
              });
            }
          }
          this.setState({
            partyArrayFilter: _partyArray,
            partyArray: _partyArray,
            isLoaded: true,
            partyRemainBalance: _partyBal,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getExpenseCategoryListData() {
    let _expenseArray = [];
    let _url_GetData =
      global.userAddExpenseCategoryList +
      "?company_id=" +
      this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _expenseArray = responseJson.response;
          }
          this.setState({
            expenseArrayFilter: _expenseArray,
            expenseArray: _expenseArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getItemListData(company_id) {
    let _itemsArray = [];

    let _url_GetData =
      global.userexpenseItemList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
            _itemsArray.map((field, index) => {
              _itemsArray[index].isFilter = false;
            });
          }
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getTotalAmount(value, _type) {
    let _mainSubTotalAmt = 0;
    let _totalOtherCharges = 0;
    let _valueGet = parseFloat(value);
    this.state.fields.map((data) => {
      let _tax_value = data.tax_value ? data.tax_value : 0;
      let _discount_in_amount = data.discount_in_amount ? data.discount_in_amount : 0;
      let _total_amount = data.total_amount ? data.total_amount : 0;

      _mainSubTotalAmt += parseFloat(_total_amount) +  parseFloat(_tax_value) - parseFloat(_discount_in_amount);
    });
    if(this.state.fields.length == 0){
      _mainSubTotalAmt =  (this.state.totalAmount ? parseFloat(this.state.totalAmount) : 0) + (this.state.totalTransDiscountAmount ? parseFloat(this.state.totalTransDiscountAmount) : 0);
    }
    console.log(
      _mainSubTotalAmt +
        "==>DD-->==" +
        _valueGet +
        "==>--aa-->===" +
        value +
        "==>--b--->==" +
        _type
    );
    let _totalTaxAmount = _mainSubTotalAmt;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1
      ? this.state.TransAdditionalCharge1
      : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2
      ? this.state.TransAdditionalCharge2
      : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3
      ? this.state.TransAdditionalCharge3
      : 0;

    let _totalTransTaxAmount = this.state.totalTransTaxAmount
      ? this.state.totalTransTaxAmount
      : 0;

    let _totalTranDisAmount = this.state.totalTransDiscountAmount ? this.state.totalTransDiscountAmount : 0;

    let _totalTransDiscountPer = this.state.totalTransDiscountPer
      ? this.state.totalTransDiscountPer
      : 0;

    if (_valueGet >= 0 && _type == "totalTransDiscountPer") {
      _totalTranDisAmount =
        (parseFloat(_totalTaxAmount) * parseFloat(_valueGet)) / parseFloat(100);
    }

    if (_valueGet > 0 && _type == "totalTransDiscountAmount") {
      console.log(value + "==>value-->==" + _valueGet);
      _totalTranDisAmount = _valueGet;
    }
    if ((value == 0 || value == "0") && _type == "totalTransDiscountAmount"
    ) 
    {
      console.log(value + "==>QQQQQ-->==" + _valueGet);
      _totalTranDisAmount = 0;
    }
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(_valueGet);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(_valueGet);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(_valueGet);
    }
    if (_type == "taxTypeTrans") {
      _totalTransTaxAmount = parseFloat(_valueGet);
    }

    let _totalAmount = parseFloat(_totalTaxAmount) - parseFloat(_totalTranDisAmount);

    console.log(
      _totalAmount +
        "==>bbbb>==" +
        _totalTaxAmount +
        "==>AAAA>==" +
        _totalTranDisAmount
    );
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3) + parseFloat(_totalTransTaxAmount);
    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);
    console.log(_totalAmount + "==>_type>==" + _type);
    return _totalAmount;
  }

  getTotalDueAmount(_totalAmount) {
    let _dueAmountAK = _totalAmount;
    if (this.state.receivedAmount > 0) {
      _dueAmountAK =
        parseFloat(_totalAmount) - parseFloat(this.state.receivedAmount);
    }
    if (_dueAmountAK < 0) {
      _dueAmountAK = 0;
    }
    return _dueAmountAK;
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "invoiceNo":
        errors.invoiceNo =
          value.length < 0 ? "*Expense No Must be 4 numbers" : "";
        this.setState({ invoiceNo: value });
        break;

      case "invoiceDate":
        errors.invoiceDate =
          value.length < 5 ? "* Invoice date must be selected" : "";
        this.setState({ invoiceDate: value });
        break;

      case "paymentType":
        errors.paymentType = value.length < 0 ? "* State must be selected" : "";
        if (value == "NewBank") {
          this.openModalHandler("modalAdjustNewBank");
        }

        let _bankId = 0;
        let _bankName = "";
        //console.log(value);
        let _valType = "";
        if (value > 0) {
          _valType = "Bank";
          var index = event.target.selectedIndex;
          var optionElement = event.target.childNodes[index];

          _bankId = optionElement.getAttribute("data-bankId");
          _bankName = optionElement.getAttribute("data-bankName");
        } else {
          _valType = value;
        }
        //console.log(_bankId+'--'+_bankName+'---'+value+'----'+_valType);
        this.setState({
          paymentType: _valType,
          bankId: _bankId,
          bankName: _bankName,
        });
        break;
      case "paidAmount":
        let _remainAmount = 0;
        let _paidAmt = value;
        console.log("==d===", value);

        if(parseFloat(this.state.totalAmount) < parseFloat(value)) {
          this.setState({
            paidAmount: _paidAmt,
          });
          console.log("==bbbbb=");
          errors.paid_amount_er ="Paid amount cannot be greater than total amount";
        }
        else{
            console.log("==aaaaaa=");
            if (_paidAmt == "") {
              _paidAmt = 0;
            }
            if (value >= 0) {
              _remainAmount = parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
            }
            this.setState({
              paidAmount: _paidAmt,
              dueAmount: _remainAmount.toFixed(2),
            });
            errors.paid_amount_er = "";
        }
        /*if (parseFloat(this.state.totalAmount) >= parseFloat(value)) {
          console.log("==aaaaaa=");
          if (_paidAmt == "") {
            _paidAmt = 0;
          }
          if (value >= 0) {
            _remainAmount =
              parseFloat(this.state.totalAmount) - parseFloat(_paidAmt);
          }
          this.setState({
            paidAmount: _paidAmt,
            dueAmount: _remainAmount.toFixed(2),
          });
          errors.paid_amount_er = "";
        } else {
          this.setState({
            paidAmount: _paidAmt,
          });
          console.log("==bbbbb=");
          errors.paid_amount_er ="Paid amount cannot be greater than total amount";
        }*/
        break;
      case "paymentRefNo":
        errors.paymentRefNo =
          value.length < 1 ? "* Payment must be selected" : "";
        this.setState({ paymentRefNo: value });
        break;
      case "description":
        this.setState({ description: value });
        break;
      case "totalAmount":
        let _balanaceDue = this.state.dueAmount;
        console.log("==value==", value);
        if (validator.isInt(value)) {
          console.log("==11==");
          if (this.state.fields.length == 0) {
            _balanaceDue = value;
          }
          if (value == 0 || value == "0") {
            errors.total_amount_er = "* Amount should be greater than zero";
          } else {
            errors.total_amount_er = "";
          }
        } else {
          console.log("==333==");
          errors.total_amount_er = "*Amount should be greater than zero";
        }
        this.setState({
          totalAmount: value,
          dueAmount: _balanaceDue,
          errors: errors,
        });
        break;

      case "additional_value_date":
        this.setState({ additional_value_date: value });
        break;
      case "additional_value_1":
        this.setState({ additional_value_1: value });
        break;
      case "additional_value_2":
        this.setState({ additional_value_2: value });
        break;
      case "additional_value_3":
        this.setState({ additional_value_3: value });
        break;

      case "countryState":
        errors.countryState =
          value.length < 0 ? "* State must be selected" : "";
        this.setState({ countryState: value });
        break;

      case "dueTerms":
        if (value == "ADDTERMS") {
          this.openModalHandler("modalAdjustAddNewTerms");
        } else {
          let mainVal = value;
          mainVal = mainVal.split(",");
          errors.dueTerms = value.length < 0 ? "* Due terms must be selected" : "";
          let _invDate = moment(this.state.invoiceDate).format("MM/DD/YYYY");
          let newDueDate = moment(_invDate).add(mainVal[1], "days").format("MM/DD/YYYY");
          this.setState({
            dueOnDate: newDueDate,
            startDate: newDueDate,
            pageLoadTerms: 1,
            dateCalendarChange: 0,
            dueDays : mainVal[1],
            dueTerms: mainVal[0]
          });

          this.isDueOnDateDateRefreshOnChange(newDueDate);
        }
        break;

      case "invoiceTime":
        this.setState({ invoiceTime: value });
        break;
      case "poNumber":
        this.setState({ poNumber: value });
        break;

      case "poDate":
        this.setState({ poDate: value });
        break;

      case "settingType":
        this.setState({ settingType: value });
        break;

      case "TransportDetailField1":
        this.setState({ TransportDetailField1: value });
        break;

      case "TransportDetailField2":
        this.setState({ TransportDetailField2: value });
        break;

      case "TransportDetailField3":
        this.setState({ TransportDetailField3: value });
        break;

      case "TransportDetailField4":
        this.setState({ TransportDetailField4: value });
        break;

      case "TransportDetailField5":
        this.setState({ TransportDetailField5: value });
        break;

      case "TransportDetailField6":
        this.setState({ TransportDetailField6: value });
        break;

      case "totalTransDiscountPer":
        let _valueM = value;
        if (_valueM > 100) {
          alert("Discount cannot be more than 100%");
        } else {
          if (_valueM == "") {
            _valueM = 0;
          }
          
          let _totalTaxAmount = this.state.totalAmount;
          if (this.state.totalAmountOld > 0) {
            _totalTaxAmount = this.state.totalAmountOld;
          }
          let _finalTotalAmt = this.getTotalAmount(
            _valueM,
            "totalTransDiscountPer"
          );
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);
          let _mainSubTotalAmt = 0;

          this.state.fields.map((data) => {
            let _tax_value = data.tax_value ? data.tax_value : 0;
            let _discount_in_amount = data.discount_in_amount
              ? data.discount_in_amount
              : 0;
            let _total_amount = data.total_amount ? data.total_amount : 0;

            _mainSubTotalAmt += parseFloat(_total_amount) + parseFloat(_tax_value) - parseFloat(_discount_in_amount);
            //_mainSubTotalAmt += (parseFloat(data.total_amount) + parseFloat(data.tax_value)) - parseFloat(data.discount_in_amount);
          });
          
          if(this.state.fields.length == 0){
            _mainSubTotalAmt =  this.state.totalAmount;
          }
          let _totalTranDisAmount = (parseFloat(_mainSubTotalAmt) * parseFloat(value)) / parseFloat(100);
          console.log('_valueM', _valueM)
          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            totalTransDiscountAmount: _totalTranDisAmount.toFixed(2),
            totalTransDiscountPer: value,
            dueAmount: _finalDueAmt.toFixed(2),
            //transactionWiseTaxId: parseInt(_valueM) === 100 ? 0 : this.state.transactionWiseTaxId,
            //totalTransTaxAmount: parseInt(_valueM) === 100 ? 0 : this.state.totalTransTaxAmount
          });

          if (_valueM <= 0 || _valueM == "") {
            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              totalTransDiscountAmount: 0,
              totalTransDiscountPer: 0,
              dueAmount: _finalDueAmt.toFixed(2),
            });
          }
        }

        break;

      case "totalTransDiscountAmount":
        let _totalTaxAmount = this.state.totalAmount;
        console.log("==aa==");
        let _value = value;
        let _mainSubTotalAmt = 0;
        this.state.fields.map((data) => {
          _mainSubTotalAmt += parseFloat(data.total_amount);
        });
        
        if(this.state.fields.length == 0){
          _mainSubTotalAmt =  this.state.totalAmount;
        }

        if (parseFloat(_value) > parseFloat(_mainSubTotalAmt)) {
          alert("Discount amount cannot be more than total amount.");
        } else {
          if (_value == "" || _value == undefined || _value == null) { 
            console.log("==ccc===");
            _value = 0;
          }

          console.log("==11--KHARE--==", _value);
          let _finalTotalAmt = this.getTotalAmount(_value,"totalTransDiscountAmount");
          let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);

          let _totalTranPerAmount = parseFloat(_value * 100) / parseFloat(_mainSubTotalAmt);

          this.setState({
            totalAmount: _finalTotalAmt.toFixed(2),
            totalTransDiscountPer: _totalTranPerAmount.toFixed(2),
            totalTransDiscountAmount: value,
            dueAmount: _finalDueAmt.toFixed(2),
          });

          if (_value <= 0 || _value == "") {
            this.setState({
              totalAmount: _finalTotalAmt,
              totalTransDiscountAmount: _value,
              totalTransDiscountPer: 0,
            });
          }
        }
        break;
      case "TransAdditionalCharge1":

        if (!validator.isInt(value)) {
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge1");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge1");
          this.setState({
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge1: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });
          //alert("Charges can not be less than zero or character");
          //return;
        }else{
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{
            let _val1 = value;
            if (_val1 == "" || _val1 == undefined || _val1 == null) {
              _val1 = 0;
            }
            
            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val1, "TransAdditionalCharge1");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val1, "TransAdditionalCharge1");
            console.log('_finalTotalAmt', _finalTotalAmt)
            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge1: value,
              dueAmount: _finalDueAmt.toFixed(2),
            });

            if (_val1 <= 0 || _val1 == "") {
              this.setState({
                TransAdditionalCharge1: _val1,
              });
            }
          }
        }

        break;

      case "TransAdditionalCharge2":
        if (!validator.isInt(value)) {
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge2");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge2");
          this.setState({
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge2: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });
          //alert("Charges can not be less than zero or character");
          return;
        }else{
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{
            let _val2 = value;
            if (_val2 == "" || _val2 == undefined || _val2 == null) {
              _val2 = 0;
            }

            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val2, "TransAdditionalCharge2");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val2, "TransAdditionalCharge2");

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge2: value,
              dueAmount: _finalDueAmt.toFixed(2),
            });


            if (_val2 <= 0 || _val2 == "") {
              this.setState({
                TransAdditionalCharge2: _val2,
              });
            }
          }
        }

        break;

      case "TransAdditionalCharge3":
        if (!validator.isInt(value)) {
          let _finalTotalAmt_1 = this.getTotalAmountAdditionCharge(0, "TransAdditionalCharge3");
          let _finalDueAmt_1 = this.getTotalRemainAmountAdditionCharge(0, "TransAdditionalCharge3");
          this.setState({
            totalAmount: _finalTotalAmt_1.toFixed(2),
            TransAdditionalCharge3: 0,
            dueAmount: _finalDueAmt_1.toFixed(2),
          });
          //alert("Charges can not be less than zero or character");
          return;
        }else{
          if (value < 0) {
            alert("Charges can not be less than zero");
          }
          else{
            let _val1 = value;
            if (_val1 == "" || _val1 == undefined || _val1 == null) {
              _val1 = 0;
            }
            let _finalTotalAmt = this.getTotalAmountAdditionCharge(_val1, "TransAdditionalCharge3");
            let _finalDueAmt = this.getTotalRemainAmountAdditionCharge(_val1, "TransAdditionalCharge3");

            this.setState({
              totalAmount: _finalTotalAmt.toFixed(2),
              TransAdditionalCharge3: _val1,
              dueAmount: _finalDueAmt.toFixed(2),
            });

            if (_val1 <= 0 || _val1 == "") {
              this.setState({
                TransAdditionalCharge1: _val1,
              });
            }
          }
        }
        break;

      default:
        break;
    }
  };
  getTotalAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    console.log(this.state.totalAmountOld+"==subt==="+this.state.subtotal+"==FF=="+this.state.totalAmountNew+"==TT=="+this.state.totalAmount);
    let _totalAmount = this.state.subtotal;
    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);
    console.log(_totalAmount+"==_totalOtherCharges===",_totalOtherCharges);
    _totalAmount = parseFloat(_totalAmount) + parseFloat(_totalOtherCharges);
    return _totalAmount;
  }

  getTotalRemainAmountAdditionCharge (value, _type){
    let _totalOtherCharges = 0;
    let _TransAdditionalCharge1 = this.state.TransAdditionalCharge1 ? this.state.TransAdditionalCharge1 : 0;
    let _TransAdditionalCharge2 = this.state.TransAdditionalCharge2 ? this.state.TransAdditionalCharge2 : 0;
    let _TransAdditionalCharge3 = this.state.TransAdditionalCharge3 ? this.state.TransAdditionalCharge3 : 0;
    if (_type == "TransAdditionalCharge1") {
      _TransAdditionalCharge1 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge2") {
      _TransAdditionalCharge2 = parseFloat(value);
    }
    if (_type == "TransAdditionalCharge3") {
      _TransAdditionalCharge3 = parseFloat(value);
    }
    let _totalDuePayAmount = this.state.subtotal;

    _totalOtherCharges = parseFloat(_TransAdditionalCharge1) + parseFloat(_TransAdditionalCharge2) + parseFloat(_TransAdditionalCharge3);

    _totalDuePayAmount = parseFloat(_totalDuePayAmount) + parseFloat(_totalOtherCharges);

    if(parseFloat(this.state.receivedAmount) > 0) {
      _totalDuePayAmount = parseFloat(_totalDuePayAmount) - parseFloat(this.state.receivedAmount);
    }
    return _totalDuePayAmount;
  }
  /*For Modal open close*/
  openModalHandler(e) {
    if (e == "modalAdjustNewExpenseCategory") {
      this.setState({ modalIsOpen_SP: true });
    } else if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: true });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true });
    } else if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_NP: true });
    } else if (e == "modalAdjustAddNewTerms") {
      this.setState({ modalIsOpen_AddTerm: true });
    }
  }
  closeModalHandler = (e) => {
    if (e == "modalAdjustNewExpenseCategory") {
      this.setState({ modalIsOpen_SP: false });
    } else if (e == "modalAdjustNewItem") {
      this.setState({ modalIsOpen_SITEM: false });
    } else if (e == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    } else if (e == "modalAdjustNewParty") {
      this.setState({ modalIsOpen_NP: false });
    } else if (e == "modalAdjustAddNewTerms") {
      this.setState({ modalIsOpen_AddTerm: false,dueTerms : '01'  });
    }
  };
  /*End Modal open close*/

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({
      modalIsOpen_SP: false,
      modalIsOpen_SITEM: false,
      modalIsOpen_SBANK: false,
    });
    this.getItemListData(this.state.company_id);
    this.getExpenseCategoryListData();
    this.getPaymentTerms();
  };

  /* End Here */

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  handleAdd() {
    const values = this.state.fields;
    values.push({ value: null });
    this.setState({ fields: values });
  }

  handleRemove(i, idx) {
    const values = this.state.fields;
    //const newFields = values.filter((itmRow, index) => index!=i);
    const newFields = values.filter((itmRow, index) => {
      return index!=i;
    });

    const data = this.state.itemArray;
    data.filter(function (item, index) {
      if (item.item_id == idx) {
        data[index].isFilter = false;
      }
    });

    const fieldArray = newFields;
    let _quantity = 0;
    let _totalAmt = "0";
    let _paidAmount = this.state.paidAmount;
    let _dueAmount = 0;

    fieldArray.map((field, index) => {
      let _itemId = field.item_id ? field.item_id : 0;
      if (_itemId > 0) {
        let _obj = {};
        _obj.item_id = field.item_id ? field.item_id : 0;
        _obj.item_name = field.item_name ? field.item_name : "";
        _obj.quantity = field.quantity ? field.quantity : 0;
        _obj.rate = field.rate ? field.rate : 0;
        _obj.tax_value = field.tax_value ? field.tax_value : 0;
        _obj.subtotal = field.subtotal ? field.subtotal : 0;
        _obj.total_amount = field.total_amount ? field.total_amount : 0;

        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);

        _paidAmount = this.state.paidAmount;
        _dueAmount = parseFloat(_totalAmt) - parseFloat(this.state.paidAmount);
        _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
      }
    });

    if (_dueAmount > 0) {
      _dueAmount = _dueAmount.toFixed(2);
    }
    if (_paidAmount > 0) {
      _paidAmount = _paidAmount.toFixed(2);
    }
    if (_totalAmt > 0) {
      _totalAmt = _totalAmt.toFixed(2);
    }

    this.setState({
      fields: fieldArray,
      totalQuantity: _quantity,
      dueAmount: _dueAmount,
      paidAmount: _paidAmount,
      totalAmount: _totalAmt,
    });

    this.setState({ fields: newFields, itemArrayFilter: data });
    this.getReadyItemList();
  }

  handleDynamicInputsTrans(event) {
    let dataVal = event.target.value;
    const finalTaxTrans = dataVal.split("@");
    let _percGstAmt = 0;
    if (finalTaxTrans[1] >= 0) {
      let _totalTaxAmount = 0;
      this.state.fields.map((data) => {
        let _tatAmt = data.total_amount ? data.total_amount : 0;
        let _tax_value = data.tax_value ? data.tax_value : 0;
        let _discount_in_amount = data.discount_in_amount ? data.discount_in_amount : 0;
        _totalTaxAmount += parseFloat(_tatAmt) + parseFloat(_tax_value) -parseFloat(_discount_in_amount);
      });
      if(this.state.fields.length == 0){
        _totalTaxAmount =  this.state.totalAmount;
      }
      _percGstAmt = (parseFloat(_totalTaxAmount) * parseFloat(finalTaxTrans[1])) / parseFloat(100);
      _totalTaxAmount = parseFloat(_totalTaxAmount) + parseFloat(_percGstAmt); // -  parseFloat(this.state.totalTransDiscountAmount);

      let _finalTotalAmt = this.getTotalAmount(_percGstAmt, "taxTypeTrans");
      let _finalDueAmt = this.getTotalDueAmount(_finalTotalAmt);
      this.setState({
        totalAmount: _finalTotalAmt.toFixed(2),
        dueAmount: _finalDueAmt.toFixed(2),
        totalTransTaxAmount: _percGstAmt.toFixed(2),
        transactionWiseTaxId: finalTaxTrans[0],
      });
    }
  }

  handleDynamicInputs(i, type, event) {
    let _itemPosition = i;

    let _itemName,
      _itemPrice,
      _itemId,
      _value = "";
    if (type == "item") {
      _itemPosition = i.index;
      _itemPrice = i.price;
      _itemId = i.id;
      _itemName = i.name;
    } else {
      _value = event.target.value;
    }

    const fieldArray = this.state.fields;
    let _quantity = 0;
    let _totalAmt = 0;
    let _paidAmount = 0;
    let _dueAmount = 0;

    if (fieldArray.length == "1" && this.state.transactionId == 0) {
      _paidAmount = 0;
    }
    this.setState({ itemQtySend: 0 });
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
          let _obj = {};

          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.subtotal = field.subtotal ? field.subtotal : 0;
          _obj.total_amount = field.total_amount ? field.total_amount : 0;

          if (type == "item") {
            _obj.item_id = _itemId;
            _obj.item_name = _itemName;
            _obj.rate = _itemPrice;
            _obj.subtotal = _itemPrice;
            _obj.total_amount = _itemPrice;
            _obj.quantity = 1;
          }

          if (type == "quantity") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            if (_value <= 0) {
              alert("Item qty cannot be 0");
              _obj.quantity = 0;
              this.setState({ itemQtySend: 1 });
            }
            _obj.quantity = _value;
          }
          if (type == "rate") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            _obj.rate = _value;
          }

          let _totalItemAmount =
            parseFloat(_obj.rate) * parseFloat(_obj.quantity);

          /* Total Calculation */
          //console.log(parseFloat(_totalItemAmount)+'-----'+parseFloat(_obj.tax_value)+'-----'+parseFloat(_obj.discount_in_amount));
          let _itemSubtotal = parseFloat(_totalItemAmount);
          _obj.subtotal = _itemSubtotal.toFixed(2);
          _obj.total_amount = _totalItemAmount.toFixed(2);
          _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);
          _dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);
          _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
          /* End Total Calculation */
          fieldArray[_itemPosition] = _obj;
        } else if (_itemPosition == 0 && type == "") {
          let _obj = {};

          _obj.item_id = 0;
          _obj.item_name = "";
          _obj.quantity = 0;
          _obj.rate = 0;
          _obj.subtotal = 0;
          _obj.total_amount = 0;
          fieldArray[_itemPosition] = _obj;
        } else {
          let _itemId = field.item_id ? field.item_id : 0;
          if (_itemId > 0) {
            let _obj = {};
            _obj.item_id = field.item_id ? field.item_id : 0;
            _obj.item_name = field.item_name;
            _obj.quantity = field.quantity ? field.quantity : "0";
            _obj.rate = field.rate ? field.rate : 0;
            _obj.subtotal = field.subtotal ? field.subtotal : 0;
            _obj.total_amount = field.total_amount ? field.total_amount : 0;

            _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.subtotal);

            //_paidAmount = this.state.paidAmount;
            _dueAmount = parseFloat(_totalAmt) - parseFloat(_paidAmount);
            _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
          }
        }
      });

      this.setState({
        fields: fieldArray,
        totalQuantity: _quantity,
        dueAmount: _dueAmount.toFixed(2),
        totalAmount: _totalAmt.toFixed(2),
        subtotal: _totalAmt.toFixed(2),
      });
    }
    this.onMouseLeaveHandlerItem("I", _itemPosition);
    this.onItemFilterName();
  }

  dynamicInputFiled() {
    let _dataSet = [];
    if (this.state.fields.length > 0) {
      let _j = 0;
      this.state.fields.map((field, idx) => {
        _j++;
        let _key = idx + 222;

        let _hideMenuItem = "iti__hide";
        if (this.state._itemRegIndex == idx) {
          _hideMenuItem = this.state._itemReg;
        }

        let _itemId = field.item_id ? field.item_id : 0;

        _dataSet.push(
          <tr key={_key}>
            <td>
              <button
                type="button"
                onClick={this.handleRemove.bind(this, idx, _itemId)}
                className="btn btn-outline-danger"
              >
                X
              </button>
            </td>
            <td>{_j}</td>
            <td>
              <div className="col-lg-12">
                {/* onMouseEnter={this.manageShowItemFilter.bind(this,'I',idx)} onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)} */}
                <div
                  className="form-material open"
                  id="paddingOff"
                  onMouseLeave={this.onMouseLeaveHandlerItem.bind(
                    this,
                    "I",
                    idx
                  )}
                >
                  <input
                    type="text"
                    className="form-control search-box"
                    onChange={this.onItemFilterTypeName.bind(this, idx)}
                    onClick={this.manageShowItemFilter.bind(this, "I", idx)}
                    onKeyDown={this.manageShowItemFilter.bind(this, "I", idx)}
                    placeholder="Item Name Here..."
                    autoComplete="off"
                    name="item_name"
                    id="item_name"
                    value={field.item_name ? field.item_name : ""}
                  />
                  <ul
                    className={
                      _hideMenuItem + " iti__party-list iti__party-list--dropup"
                    }
                    id="country-listbox"
                    aria-expanded="false"
                    role="listbox"
                    aria-activedescendant="iti-item-ad"
                  >
                    {this.getReadyItemList(idx)}
                  </ul>
                </div>
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  maxLength="100"
                  required="required"
                  className="form-control"
                  id={"_qty_" + idx}
                  name={"_qty_" + idx}
                  value={field.quantity ? field.quantity : ""}
                  datatype="zero"
                  onChange={this.handleDynamicInputs.bind(
                    this,
                    idx,
                    "quantity"
                  )}
                  onWheel={() => document.activeElement.blur()}
                />
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  required="required"
                  className="form-control"
                  id={"rate_" + idx}
                  name={"rate_" + idx}
                  value={field.rate ? field.rate : ""}
                  onChange={this.handleDynamicInputs.bind(this, idx, "rate")}
                  onWheel={() => document.activeElement.blur()}
                />
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="text"
                  style={{ textAlign: "right" }}
                  readOnly="readOnly"
                  className="form-control"
                  id={"subtotal_" + idx}
                  name={"subtotal_" + idx}
                  value={field.subtotal ? field.subtotal : ""}
                />
              </div>
            </td>
          </tr>
        );
      });
    }
    return _dataSet;
  }
  /* End Add More Data */

  /* Add Party Code */
  selectExpCategory(name, e) {
    let errors = this.state.errors;
    errors.expense_category = "";
    this.setState({
      _catReg: "iti__hide",
      expense_category: name,
      expenseCat_id: e,
    });
  }

  getReadyExpCategoryList() {
    let data = [];
    if (this.state.isLoaded == true) {
      let _expenseArray = this.state.expenseArrayFilter;
      if (_expenseArray.length > 0) {
        data = this.state.expenseArrayFilter.map((expense, i) => {
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectExpCategory.bind(
                this,
                expense.expense_category,
                expense.expenseCat_id
              )}
            >
              <span className="iti__party-name">
                {expense.expense_category}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(
              this,
              "modalAdjustNewExpenseCategory"
            )}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Expense Category
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandlerCat(key) {
    if (key == "I") {
      this.setState({ _catReg: "iti__hide" });
    }
  }

  manageShowExpCategoryFilter(key) {
    if (key == "I") {
      this.setState({ _catReg: "" });
    }
  }

  onExpCategoryFilterName = (e) => {
    const data = this.state.expenseArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val == "string" && val.includes(keyword)
      )
    );
    this.setState({ expenseArrayFilter: filtered, expense_category: keyword });
    this.getReadyExpCategoryList();
  };
  /* End Party Code */

  /* Start Item Code */
  onItemFilterName = () => {
    const data = this.state.itemArray;
    let fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        data.filter(function (item, index) {
          if (item.item_id == field.item_id) {
            //data[index].isFilter = true;
          }
        });
      });
    }
    this.setState({ itemArrayFilter: data });
    this.getReadyItemList();
  };

  onItemFilterTypeName = (idx, e) => {
    const data = this.state.itemArray;
    let _name = e.target.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val == "string" && val.includes(keyword)
      )
    );

    const fieldArray = this.state.fields;
    fieldArray.map((field, index) => {
      if (index == idx) {
        field.item_name = keyword;
      }
    });

    this.setState({
      itemArrayFilter: filtered,
      item_name: keyword,
      fields: fieldArray,
    });
    this.getReadyItemList();
  };

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowExpCategoryFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  getReadyItemList(index) {
    let data = [];
    if (this.state.isLoaded == true) {
      let _itemsArray = this.state.itemArrayFilter;
      if (_itemsArray.length > 0) {
        data = this.state.itemArrayFilter.map((items, i) => {
          if (items.isFilter == false) {
            let _obj = {};
            _obj.index = index;
            _obj.name = items.name;
            _obj.id = items.item_id;
            _obj.price = items.sale_price;

            return (
              <li
                key={i}
                className="iti__party iti__standard"
                id="iti-item-in"
                onClick={this.handleDynamicInputs.bind(this, _obj, "item")}
              >
                <span className="iti__party-name">{items.name}</span>
              </li>
            );
          }
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewItem")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Expense Item
            </span>
          </li>
        );
      }
    }
    return data;
  }
  /* End Item Code */

  /* Image Code */
  onChangeFileUploadHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        descImage: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let dataList = [];
      let _imageDetails = this.state.imagePreviewUrl;
      if (_imageDetails!="") {
        dataList.push(
          <img
            style={{ width: "35px" }}
            className="preview"
            src={_imageDetails}
            alt={"image-"}
            key="13213"
          />
        );
      }
      return dataList;
    }
  }
  /* End Here*/

  /* Toggle Manage */
  onToggleChange = (checked) => {
    console.log('Tabish');
    this.setState({ toggleState: checked,
      party_name: "",
      party_id: "" ,
      fields: [],
      transactionWiseTaxId: 0,
      totalTransTaxAmount: 0,
      totalTransDiscountPer: 0,
      totalTransDiscountAmount: 0,
      TransAdditionalCharge1: 0,
      TransAdditionalCharge2: 0,
      TransAdditionalCharge3: 0,
    });
    // True = Cash
    // False = Credit
    /*Due Dates terms setting data */
    let _selTermsId = 0;
    let _terms_days = 0;
    const requestDueDateData = JSON.stringify({
      businessId: this.state.businessId,
      company_id: this.state.company_id,
    });
    PostData(global.businessDueDatePaymentTermList, requestDueDateData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
          }
          let _mainResponse = responseJson.response;
          this.setState({
            isLoaded: true,
            paymentTermsData: responseJson.response,
            totalAmount :0,
            totalQuantity:0,
            dueAmount :0
          });

          _mainResponse.map((item, index) => {
            
            if (item.terms_default_status == 1) {
              _selTermsId = item.id;
              _terms_days = item.terms_days;
              let dueDateOnload = moment()
                .add(_terms_days, "days")
                .format("MM/DD/YYYY");
              this.setState({
                dueTerms: _selTermsId,
                dueOnDate: dueDateOnload,
              });
            }
          });
          console.log("==_selTermsId 111==", _selTermsId);
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
    /* End Due Dates terms setting data */
  };
  /* Toggle Manage */

  /* Click Submit Button */
  clickToAddPurchase = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.itemQtySend == 1) {
      alert("item qty cannot be 0");
      return false;
    }
    if (this.state.expense_category == "") {
      _isError = true;
      errors["expense_category"] = "*Please Enter Expense Category Name";
      this.setState({ errors: errors });
    }

    if (this.state.toggleState == true) {
      if (this.state.party_name == "") {
        _isError = true;
        errors["party_name"] = "*Please Enter Party Name";
        this.setState({ errors: errors });
      }
    }
    if (this.state.invoiceNo == "") {
      _isError = true;
      errors["invoiceNo"] = "*Please Enter Expense No";
      this.setState({ errors: errors });
    }

    // if (this.state.totalAmount == 0 || this.state.totalAmount == "0") {
    //   _isError = true;
    //   errors.total_amount_er = "* Amount should be greater than zero";
    //   this.setState({ errors: errors });
    // }
    console.log(_isError + "==here", this.state.errors);
    //return false;
    if (_isError == false) {
      if (this.state.transactionId > 0) {
        this.editExpenseTransactionMethodCall();
      } else {
        this.addExpenseTransactionMethodCall();
      }
    }
  };

  addExpenseTransactionMethodCall() {
    if (this.state.dueAmount < 0) {
      sendNotification(
        "Error Message 😓",
        "Received amount on expense order can not be more than total order amount.",
        "warning"
      );
      return;
    }
   
    if (this.state.dueTerms == '01') {
      sendNotification(
        "warning Message 😓",
        "Please select any Pmt. Terms",
        "warning"
      );
      return;
    }

    let _prefix = JSON.stringify({
      name: "",
      id: "0",
      isActive: "1",
    });
    let _mainSubTotalAmt = 0;
    this.state.fields.map((data) => {      
      let _tax_value = data.tax_value ? data.tax_value : 0;
      let _discount_in_amount = data.discount_in_amount ? data.discount_in_amount : 0;
      let _total_amount = data.total_amount ? data.total_amount : 0;
      _mainSubTotalAmt += parseFloat(_total_amount) +  parseFloat(_tax_value) - parseFloat(_discount_in_amount);
    })

    let _totalSummary = JSON.stringify({
      subtotal: _mainSubTotalAmt,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalDiscount: this.state.totalDiscount,
      totalQty: this.state.totalQuantity,
      totalAmount: this.state.totalAmount,
      paidAmount: this.state.paidAmount,
      dueAmount: this.state.dueAmount,
    });

    let _customer = JSON.stringify({
      expenseCat_id: this.state.expenseCat_id,
      expense_category: this.state.expense_category,
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: "",
      party_opening_balance: 0,
    });

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
        ? this.state.TransportDetailField1
        : "",
      TransportDetailField2: this.state.TransportDetailField2
        ? this.state.TransportDetailField2
        : "",
      TransportDetailField3: this.state.TransportDetailField3
        ? this.state.TransportDetailField3
        : "",
      TransportDetailField4: this.state.TransportDetailField4
        ? this.state.TransportDetailField4
        : "",
      TransportDetailField5: this.state.TransportDetailField5
        ? this.state.TransportDetailField5
        : "",
      TransportDetailField6: this.state.TransportDetailField6
        ? this.state.TransportDetailField6
        : "",
    }); //Add by AK

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId === 'disabled' ? 0 : (this.state.transactionWiseTaxId ? this.state.transactionWiseTaxId : 0),
      totalTransTaxAmount: this.state.totalTransTaxAmount
        ? this.state.totalTransTaxAmount
        : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
        ? this.state.totalTransDiscountPer
        : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
        ? this.state.totalTransDiscountAmount
        : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
        ? this.state.TransAdditionalCharge1
        : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
        ? this.state.TransAdditionalCharge2
        : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
        ? this.state.TransAdditionalCharge3
        : 0,
    }); //Add by AK

    var purchaseData = new FormData();
    purchaseData.append("business_id", this.state.businessId);
    purchaseData.append("user_id", this.state.userId);
    purchaseData.append("company_id", this.state.company_id);
    purchaseData.append("type", "EXPENSE");
    purchaseData.append("prefix", _prefix);
    purchaseData.append("invoiceNo", this.state.invoiceNo);
    purchaseData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );
    purchaseData.append("invoiceTime", this.state.invoiceTime);
    purchaseData.append("totalSummary", _totalSummary);
    purchaseData.append(
      "transactionTransportationDetails",
      _transacTransportationDetails
    );
    purchaseData.append("transactionWiseAmount", _transactionWiseAmount);
    purchaseData.append(
      "transactionAdditionalCharge",
      _transactionAdditionalCharge
    );
    purchaseData.append("customer", _customer);
    purchaseData.append("items", JSON.stringify(this.state.fields));
    purchaseData.append("paymentType", this.state.paymentType);
    purchaseData.append("paymentRefNo", this.state.paymentRefNo);
    purchaseData.append("selectedCountry", this.state.countryName);
    purchaseData.append("countryState", this.state.countryState);
    purchaseData.append("description", this.state.description);
    purchaseData.append("descImage", this.state.descImage);
    purchaseData.append("isGST", this.state.toggleState);
    purchaseData.append("settingType", this.state.settingType);
    purchaseData.append("bankId", this.state.bankId);
    purchaseData.append("bankName", this.state.bankName);
    purchaseData.append("poNumber", this.state.poNumber);
    purchaseData.append(
      "poDate",
      moment(this.state.poDate).format("MMMM D, YYYY")
    );
    purchaseData.append(
      "dueOnDate",
      moment(this.state.dueOnDate).format("MMMM D, YYYY")
    );
    purchaseData.append("dueTerms", this.state.dueTerms);
    purchaseData.append("additional_value_1", this.state.additional_value_1);
    purchaseData.append("additional_value_2", this.state.additional_value_2);
    purchaseData.append("additional_value_3", this.state.additional_value_3);
    purchaseData.append(
      "additional_value_date",
      moment(this.state.additional_value_date).format("MMMM D, YYYY")
    );
    //console.log("===itedata==",JSON.stringify(this.state.dueTerms)); return false;
    new Promise((resolve, reject) => {
      fetch(global.createExpenseTransaction, {
        method: "POST",
        body: purchaseData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              //window.location.href = global.webUrl+'business/manage-expense/';
              window.location.href =
                global.webUrl +
                "business/download-pdf/?auth=" +
                res.transaction_Id;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editExpenseTransactionMethodCall() {
    if (this.state.dueAmount < 0) {
      sendNotification(
        "Error Message 😓",
        "Received amount on expense order can not be more than total order amount.",
        "warning"
      );
      return;
    }    
    if (this.state.dueTerms == '01') {
      sendNotification(
        "warning Message 😓",
        "Please select any Pmt. Terms",
        "warning"
      );
      return;
    }
    if(this.state.cheque_open_status === 1){
      sendNotification(
        "Error Message 😓",
        "This transaction can not be edited because cheque associated with the transaction is already   closed. Re-open the cheque to edit this transaction.",
        "warning"
      );
      return;
    }
    

    let _prefix = JSON.stringify({
      name: "",
      id: "0",
      isActive: "1",
    });

    let _mainSubTotalAmt = 0;
    this.state.fields.map((data) => {      
      let _tax_value = data.tax_value ? data.tax_value : 0;
      let _discount_in_amount = data.discount_in_amount ? data.discount_in_amount : 0;
      let _total_amount = data.total_amount ? data.total_amount : 0;
      _mainSubTotalAmt += parseFloat(_total_amount) +  parseFloat(_tax_value) - parseFloat(_discount_in_amount);
    })
    let _totalSummary = JSON.stringify({
      subtotal: _mainSubTotalAmt,
      gstRatio: "0",
      gstAmount: this.state.gstAmount,
      totalDiscount: this.state.totalDiscount,
      totalQty: this.state.totalQuantity,
      totalAmount: this.state.totalAmount,
      paidAmount: this.state.paidAmount,
      dueAmount: this.state.dueAmount,
    });
    
    let _customer = JSON.stringify({
      expenseCat_id: this.state.expenseCat_id,
      expense_category: this.state.expense_category,
      party_id: this.state.party_id,
      party_name: this.state.party_name,
      party_contact_no: "",
      party_opening_balance: 0,
    });

    let _transacTransportationDetails = JSON.stringify({
      TransportDetailField1: this.state.TransportDetailField1
        ? this.state.TransportDetailField1
        : "",
      TransportDetailField2: this.state.TransportDetailField2
        ? this.state.TransportDetailField2
        : "",
      TransportDetailField3: this.state.TransportDetailField3
        ? this.state.TransportDetailField3
        : "",
      TransportDetailField4: this.state.TransportDetailField4
        ? this.state.TransportDetailField4
        : "",
      TransportDetailField5: this.state.TransportDetailField5
        ? this.state.TransportDetailField5
        : "",
      TransportDetailField6: this.state.TransportDetailField6
        ? this.state.TransportDetailField6
        : "",
    }); //Add by AK

    let _transactionWiseAmount = JSON.stringify({
      transactionWiseTaxId: this.state.transactionWiseTaxId
        ? this.state.transactionWiseTaxId
        : 0,
      totalTransTaxAmount: this.state.totalTransTaxAmount
        ? this.state.totalTransTaxAmount
        : 0,
      totalTransDiscountPer: this.state.totalTransDiscountPer
        ? this.state.totalTransDiscountPer
        : 0,
      totalTransDiscountAmount: this.state.totalTransDiscountAmount
        ? this.state.totalTransDiscountAmount
        : 0,
    }); //Add by AK

    let _transactionAdditionalCharge = JSON.stringify({
      TransAdditionalCharge1: this.state.TransAdditionalCharge1
        ? this.state.TransAdditionalCharge1
        : 0,
      TransAdditionalCharge2: this.state.TransAdditionalCharge2
        ? this.state.TransAdditionalCharge2
        : 0,
      TransAdditionalCharge3: this.state.TransAdditionalCharge3
        ? this.state.TransAdditionalCharge3
        : 0,
    }); //Add by AK

    var purchaseData = new FormData();
    purchaseData.append("business_id", this.state.businessId);
    purchaseData.append("user_id", this.state.userId);
    purchaseData.append("company_id", this.state.company_id);
    purchaseData.append("transaction_id", this.state.transactionId);
    purchaseData.append("type", "EXPENSE");
    purchaseData.append("prefix", _prefix);
    purchaseData.append("invoiceNo", this.state.invoiceNo);
    purchaseData.append(
      "invoiceDate",
      moment(this.state.invoiceDate).format("MMMM D, YYYY")
    );
    purchaseData.append("invoiceTime", this.state.invoiceTime);
    purchaseData.append("totalSummary", _totalSummary);
    purchaseData.append(
      "transactionTransportationDetails",
      _transacTransportationDetails
    );
    purchaseData.append("transactionWiseAmount", _transactionWiseAmount);
    purchaseData.append(
      "transactionAdditionalCharge",
      _transactionAdditionalCharge
    );
    purchaseData.append("customer", _customer);
    purchaseData.append("items", JSON.stringify(this.state.fields));
    purchaseData.append("paymentType", this.state.paymentType);
    purchaseData.append("paymentRefNo", this.state.paymentRefNo);
    purchaseData.append("selectedCountry", this.state.countryName);
    purchaseData.append("countryState", this.state.countryState);
    purchaseData.append("description", this.state.description);
    purchaseData.append("descImage", this.state.descImage);
    purchaseData.append("isGST", this.state.toggleState);
    purchaseData.append("settingType", this.state.settingType);
    purchaseData.append("bankId", this.state.bankId);
    purchaseData.append("bankName", this.state.bankName);
    purchaseData.append("poNumber", this.state.poNumber);
    purchaseData.append(
      "poDate",
      moment(this.state.poDate).format("MMMM D, YYYY")
    );
    purchaseData.append(
      "dueOnDate",
      moment(this.state.dueOnDate).format("MMMM D, YYYY")
    );
    purchaseData.append("dueTerms", this.state.dueTerms);
    purchaseData.append("additional_value_1", this.state.additional_value_1);
    purchaseData.append("additional_value_2", this.state.additional_value_2);
    purchaseData.append("additional_value_3", this.state.additional_value_3);
    purchaseData.append(
      "additional_value_date",
      moment(this.state.additional_value_date).format("MMMM D, YYYY")
    );
    //console.log("===itedata==",JSON.stringify(this.state.fields)); return false;
    new Promise((resolve, reject) => {
      fetch(global.editExpenseTransaction, {
        method: "POST",
        body: purchaseData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            // setTimeout(() => {
            //   window.location.href = global.webUrl + "business/manage-expense/";
            // }, Math.floor(Math.random() * 5000) + 1);
            setTimeout(() => {
              if (this.state.transactionType == "PARTY") {
                window.location.href = global.webUrl + "business/manage-party/";
              } else if (this.state.transactionType == "CASHINHAND") {
                window.location.href =
                  global.webUrl + "business/manage-cash-in-hand/";
              } else if (this.state.transactionType == "Bank") {
                window.location.href =
                  global.webUrl + "business/manage-account-list/";
              } else {
                window.location.href =
                  global.webUrl + "business/manage-expense/";
              }
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }
  /* End Submit button */

  handleCallBackDate = (start) => {
    //this.setState({ invoiceDate: start.format("MMMM D, YYYY") });
    this.setState({ invoiceDate: start.format("MM/DD/YYYY") });    
    let changeInvD = moment(this.state.invoiceDate).format("MM/DD/YYYY")
    let dueDateChange = moment(changeInvD).add(this.state.dueDays, "days").format("MM/DD/YYYY");
    this.setState({
      dueOnDate: dueDateChange,
      startDate: dueDateChange,
      pageLoadTerms: 1,
      dateCalendarChange: 0,
    });
  };

  /* PO Date */
  handleCallBackPODate = (start) => {
    //this.setState({poDate:start.format('MMMM D, YYYY')});
    this.setState({ poDate: start.format("MM/DD/YYYY") });
  };

  isPODateRefresh() {
    let _poDate = moment().format("MM/DD/YYYY");
    if (
      this.state.poDate == "Invalid date" ||
      this.state.poDate == "" ||
      this.state.poDate == "null" ||
      this.state.poDate == null
    ) {
      _poDate = moment().format("MM/DD/YYYY");
    } else {
      _poDate = this.state.poDate;
    }
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _poDate,
          }}
          onCallback={this.handleCallBackPODate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_poDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _poDate,
          }}
          onCallback={this.handleCallBackPODate}
        >
          <input
            type="text"
            className="form-control"
            readOnly
            value={_poDate}
          />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _poDate,
          }}
          onCallback={this.handleCallBackPODate}
        >
          <input
            type="text"
            className="form-control"
            datach="else if"
            readOnly
            value={_poDate}
          />
        </DateRangePicker>
      );
    }
  }
  /* PO Date End */

  /* Due date onDate */
  handleCallBackDueTermsDateDate = (start) => {
    let _dateCalendarChange = 1;
    if(this.state.transactionId >0)
    {
      _dateCalendarChange = 11;
    }
    this.setState({
      dueOnDate: start.format("MM/DD/YYYY"),
      dateCalendarChange: _dateCalendarChange,
      dueTerms:0
    });
  };

  isDueOnDateDateRefresh(newDueDate) {
    let _dueOnDate = this.state.dueOnDate; //newDueDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _dueOnDate,
              }}
              onCallback={this.handleCallBackDueTermsDateDate}
          >
            <input type="text" className="form-control" readOnly  />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _dueOnDate,
              }}
              onCallback={this.handleCallBackDueTermsDateDate}
          >
            <input type="text" className="form-control" readOnly  />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _dueOnDate,
              }}
              onCallback={this.handleCallBackDueTermsDateDate}
          >
            <input
                type="text"
                className="form-control"
                datach="else if"
                readOnly
            />
          </DateRangePicker>
      );
    }
  }
  isDueOnDateDateRefreshOnChange(newDueDate) {
    let _dueOnDate = this.state.dueOnDate; //newDueDate;

    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _dueOnDate,
              }}
              onCallback={this.handleCallBackDueTermsDateDate}
          >
            <input
                type="text"
                className="form-control"
                readOnly
                value={this.state.startDate}
            />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _dueOnDate,
              }}
              onCallback={this.handleCallBackDueTermsDateDate}
          >
            <input
                type="text"
                className="form-control"
                readOnly
                value={this.state.startDate}
            />
          </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == true) {
      return (
          <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: _dueOnDate,
              }}
              onCallback={this.handleCallBackDueTermsDateDate}
          >
            <input
                type="text"
                className="form-control"
                datach="else if"
                readOnly
                value={this.state.startDate}
            />
          </DateRangePicker>
      );
    }
  }
  /* Due date on Date End */

  settleAmountType() {
    let _payData = [];
    if (this.state.isLoaded == true) {
      if (_bankArray.length > 0) {
        let _addNewBank = 0;
        _bankArray.map((bankD, index) => {
          let _arrayLength = _bankArray.length;
          let _valOption = bankD.paymentType;
          let _disOption = bankD.paymentType;
          let _isBankExist = 0;

          if (bankD.bank_id > 0) {
            _isBankExist = 1;
            _valOption = bankD.bank_id;
            _disOption = bankD.bank_name;
          }

          let _selBank = "";
          if (bankD.bank_id > 0) {
            if (_valOption == this.state.bankId) {
              _selBank = "selected";
            }
          } else {
            if (_valOption == this.state.paymentType) {
              _selBank = "selected";
            }
          }

          if (
            _valOption > 0 ||
            _disOption == "Cash" ||
            _disOption == "Cheque"
          ) {
            _payData.push(
              <option
                value={_valOption}
                selected={_selBank}
                data-bankName={_disOption}
                data-bankId={_valOption}
              >
                {_disOption}
              </option>
            );
          }

          if (_arrayLength == index + 1) {
            if (_isBankExist == 0) {
              _addNewBank = 1;
              _payData.push(<option value="NewBank">Add New Bank</option>);
            }
          }
        });
      } else {
        return <option value="NewBank">Add New Bank</option>;
      }
    }
    return _payData;
  }

  isDateRefresh() {
    let _invoiceDate = this.state.invoiceDate;
    if (_isEditMode == true && this.state.transactionId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (this.state.isLoaded == true && _isEditMode == false) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  }
  /* Invoice Time Slot */
  onMouseLeaveHandler_Time(key) {
    if (key == "I") {
      this.setState({ _timeReg: "iti__hide" });
    }
  }

  isAdditionalDateRefresh() {
    let _additional_value_date = this.state.additional_value_date;

    return (
      <DateRangePicker
        initialSettings={{
          singleDatePicker: true,
          startDate: _additional_value_date,
        }}
        onCallback={this.handleCallBackAddDate}
      >
        <input
          type="text"
          id="additional_value_date"
          name="additional_value_date"
          className="form-control"
          readOnly
        />
      </DateRangePicker>
    );
  }

  handleCallBackAddDate = (start) => {
    this.setState({ additional_value_date: start.format("MMMM D, YYYY") });
  };

  onTimeFilterName = (e) => {
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    this.setState({ invoiceTime: keyword });
    this.getTimeSlotList();
  };

  manageShowTimeFilter(key) {
    if (key == "I") {
      this.setState({ _timeReg: "" });
    }
  }

  selectInvoiceTime(e) {
    this.setState({
      _timeReg: "iti__hide",
      invoiceTime: e,
    });
  }
  selectCustom() {
    console.log("Tabish is clicked");
  }
  getTimeSlotList() {
    let i = 1;
    let menuItems = [];
    while (i <= 24) {
      let amPmCaption = i < 12 ? "AM" : "PM";
      let timeValue = i <= 12 ? i : i - 12;

      let _timeInvoice = `${timeValue}:00 ${amPmCaption}`;
      menuItems.push(
        <li
          key={i}
          className="iti__party iti__standard"
          id="iti-item-in"
          onClick={this.selectInvoiceTime.bind(this, _timeInvoice)}
        >
          <span className="iti__party-name">{_timeInvoice}</span>
        </li>
      );
      i++;
    }
    return menuItems;
  }
  /* Invoice Time Slot End */

  /* Add Party Code */
  selectPartiess(name, e, balance) {
    let errors = this.state.errors;
    errors.party_name = "";
    this.setState({
      _partyReg: "iti__hide",
      party_name: name,
      party_id: e,
      partyRemainBalance: balance,
    });
  }

  getReadyPartyList() {
    let data = [];
    if (this.state.isLoaded == true) {
      let _partyArray = this.state.partyArrayFilter;
      if (_partyArray.length > 0) {
        data = this.state.partyArrayFilter.map((party, i) => {
          let _balance = party.totalOutCome;
          if (_balance < 0) {
            _colorVal = "red";
          } else if (_balance == 0 || _balance == "0") {
            _colorVal = "black";
          } else {
            _colorVal = "green";
          }
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.selectPartiess.bind(
                this,
                party.party_name,
                party.party_id,
                _balance
              )}
            >
              <span className="iti__party-name">{party.party_name}</span>
              <span className="iti__party-balance" style={{ color: _colorVal }}>
                {numberFormat(Math.abs(_balance))}
              </span>
            </li>
          );
        });
      } else {
        return (
          <li
            key="22"
            className="iti__party iti__standard"
            id="iti-item-in"
            onClick={this.openModalHandler.bind(this, "modalAdjustNewParty")}
          >
            <span className="iti__party-name">
              <i className="si si-users"></i> Add New Party
            </span>
          </li>
        );
      }
    }
    return data;
  }

  onMouseLeaveHandler(key) {
    if (key == "I") {
      this.setState({ _partyReg: "iti__hide" });
    }
  }

  manageShowPartyFilter(key) {
    if (key == "I") {
      this.setState({ _partyReg: "" });
    }
  }

  onPartyFilterName = (e) => {
    const data = this.state.partyArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ partyArrayFilter: filtered, party_name: keyword });
    this.getReadyPartyList();
  };
  /* End Party Code */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideRows = "iti__hide";
    let _readonlyInput = false;
    let _readonlyCss = "";
    if (this.state.fields.length > 0) {
      _hideRows = "";
      _readonlyInput = true;
      _readonlyCss = "disabled";
    } else {
      if (this.state.totalAmount > 0) {
        _hideRows = "";
        _readonlyInput = false;
        _readonlyCss = "";
      }
    }

    let _hidePaid = "";
    if (this.state.toggleState == false) {
      if (this.state.transactionId > 0) {
        if (this.state.isToggleEdit == false) {
          _hidePaid = "iti__hide";
        }
      } else {
        _hidePaid = "iti__hide";
      }
    }
    let _isPaidAmountDisable = false;
    if(this.state.transactionId > 0 && (this.state.totalAmount != this.state.dueAmount)){
      _isPaidAmountDisable = true;
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);

    const optionsData = [
      { value: "showall", label: "Show All" },
      { value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Triplicate", label: "Triplicate" },
    ];

    let _handleParamItemPage = this.props.location.search;
    _handleParamItemPage = _handleParamItemPage.split("?auth=");
    let _transId = _handleParamItemPage[1] ? _handleParamItemPage[1] : 0;

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Expense
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/manage-expense/"}
            >
              Manage Expense List
            </a>
            <span className="breadcrumb-item active">Add / Edit Expense</span>

            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/add-expense/"}
              target="_blank"
            >
              <i className="fa fa-plus"> (Add More)</i>
            </a>
          </nav>

          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-6">
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandlerCat.bind(
                            this,
                            "I"
                          )}
                        >
                          <input
                            type="text"
                            className="form-control search-box"
                            onChange={this.onExpCategoryFilterName}
                            onClick={this.manageShowExpCategoryFilter.bind(
                              this,
                              "I"
                            )}
                            onKeyDown={this.manageShowExpCategoryFilter.bind(
                              this,
                              "I"
                            )}
                            placeholder="Search Name Here..."
                            autoComplete="off"
                            name="expense_category"
                            id="expense_category"
                            value={this.state.expense_category}
                          />
                          <label htmlFor="login-username">
                            Expense Category Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <ul
                            className={
                              this.state._catReg +
                              " iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {this.getReadyExpCategoryList()}
                          </ul>
                          <div
                            id="expense_category-error"
                            className="animated fadeInDown"
                          >
                            {errors.expense_category.length > 0 && (
                              <span className="error">
                                {errors.expense_category}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <div className={this.state.transactionId > 0 ? "hide" : "col-6"}> */}
                      {/* className= {this.state.toggleState == false ? "toggleColor"  : ""} */}
                      <div className="col-6">
                        <div
                          className={
                            this.state.transactionId > 0 ? "hide" : "col-6"
                          }
                        >
                          <ToggleSwitch
                            txtLeft=""
                            txtRight="GST"
                            small
                            id="newsletter"
                            checked={this.state.toggleState}
                            onChange={this.onToggleChange}
                          />
                          {/* <p>Newsletter: {String(this.state.toggleState)}</p> */}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div
                        className={
                          this.state.toggleState == false
                            ? _hidePaid + " col-6"
                            : " col-6"
                        }
                      >
                        <div
                          className="form-material open"
                          onMouseLeave={this.onMouseLeaveHandler.bind(
                            this,
                            "I"
                          )}
                        >
                          <input
                            type="text"
                            className="form-control search-box"
                            onChange={this.onPartyFilterName}
                            onClick={this.manageShowPartyFilter.bind(this, "I")}
                            onKeyDown={this.manageShowPartyFilter.bind(
                              this,
                              "I"
                            )}
                            placeholder="Search Party Name Here..."
                            autoComplete="off"
                            name="party_name"
                            id="party_name"
                            value={this.state.party_name}
                          />
                          <label htmlFor="login-username">
                            Party Name <span className="text-danger">*</span>
                          </label>
                          <ul
                            className={
                              this.state._partyReg +
                              " iti__party-list iti__party-list--dropup"
                            }
                            id="country-listbox"
                            aria-expanded="false"
                            role="listbox"
                            aria-activedescendant="iti-item-ad"
                          >
                            {this.getReadyPartyList()}
                          </ul>
                          <div
                            id="party_name-error"
                            className="animated fadeInDown"
                          >
                            {errors.party_name.length > 0 && (
                              <span className="error">{errors.party_name}</span>
                            )}
                          </div>
                          <div
                            className={
                              this.state.partyRemainBalance < 0
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {this.state.party_name!=""
                              ? "Bal : " +
                                numberFormat(this.state.partyRemainBalance)
                              : ""}
                          </div>
                        </div>
                      </div>
                      {this.state.toggleState == true &&
                        _businessSetting.Tran_CustomerPO == 1 && (
                          <div className="col-6">
                            <div className="form-material open">
                              {this.isPODateRefresh()}
                              <label htmlFor="login-username">PO Date</label>
                            </div>
                          </div>
                        )}
                      {this.state.toggleState == true &&
                        _businessSetting.Tran_CustomerPO == 1 && (
                          <div className="col-6">
                            <div className="form-material open">
                              <input
                                type="text"
                                className="form-control search-box"
                                placeholder="PO Number"
                                autoComplete="off"
                                name="poNumber"
                                id="poNumber"
                                value={this.state.poNumber}
                                onChange={this.handleChange}
                              />
                              <label htmlFor="login-username">PO Number</label>
                            </div>
                          </div>
                        )}
                      {this.state.toggleState == true &&
                        _businessSetting.DueDatesPaymentTerms == 1 && (
                          <div className="col-6">
                            <div className="form-material open">
                              {this.state.dateCalendarChange == 11 &&
                              <select
                                  className="form-control "
                                  id="dueTerms"
                                  name="dueTerms"
                                  required="required"
                                  onChange={this.handleChange}
                              >
                                <option key="0" value="0" selected="selected">
                                  Custom
                                </option>
                                {this.state.paymentTermsData.map(
                                    (item, index) => {
                                      let _selTerms = "";
                                      if (item.terms_default_status == 1) {
                                        _selTerms = "selected";
                                      }
                                      if (this.state.transactionId > 0 && this.state.dueTerms == item.id
                                      )
                                      {
                                        _selTerms = "selected";
                                      } else if (this.state.transactionId > 0 && this.state.dateCalendarChange == 11){
                                        _selTerms = "";
                                      } else if (this.state.transactionId > 0 && this.state.dateCalendarChange == 1){
                                        _selTerms = "";
                                      }
                                      // if(this.state.state == item.name){_sel = 'selected';}
                                      return (
                                          <option
                                              key={index}
                                              value={item.id + "," + item.terms_days}
                                              selected={_selTerms}
                                          >
                                            {item.terms_name}
                                          </option>
                                      );
                                    }
                                )}
                              </select>
                              }
                              {this.state.dateCalendarChange!=11 &&
                              <select
                                  className="form-control "
                                  id="dueTerms"
                                  name="dueTerms"
                                  required="required"
                                  onChange={this.handleChange}
                              >
                                <option key="0" value="ADDTERMS">
                                  Add Terms
                                </option>
                                
                                {this.state.dateCalendarChange == 1 &&
                                <option key="0" value="0" selected="selected">
                                  Custom
                                </option>
                                }
                                {this.state.paymentTermsData.map(
                                    (item, index) => {
                                      let _selTerms = "";
                                      if (item.terms_default_status == 1) {
                                        _selTerms = "selected";
                                      }
                                      if (this.state.transactionId > 0 && this.state.dueTerms == item.id
                                      )
                                      {
                                        _selTerms = "selected";
                                      } else if (this.state.transactionId > 0 && this.state.dateCalendarChange == 11){
                                        _selTerms = "";
                                      } else if (this.state.transactionId > 0 && this.state.dateCalendarChange == 1){
                                        _selTerms = "";
                                      }
                                      // if(this.state.state == item.name){_sel = 'selected';}
                                      return (
                                          <option
                                              key={index}
                                              value={item.id + "," + item.terms_days}
                                              selected={_selTerms}
                                          >
                                            {item.terms_name}
                                          </option>
                                      );
                                    }
                                )}
                              </select>
                              }

                              <label htmlFor="login-username">Pmt. Terms</label>
                            </div>
                          </div>
                        )}
                      {/* {_businessSetting.DueDatesPaymentTerms+"==>>=="+this.state.pageLoadTerms+"==>>=="+this.state.transactionId+"==>>=="+this.state.toggleState} */}
                      {_businessSetting.DueDatesPaymentTerms == 1 &&
                        this.state.pageLoadTerms == 0 &&
                        this.state.transactionId == 0 &&
                        this.state.toggleState == true && (
                          <div className="col-6">
                            <div className="form-material open">
                              {this.isDueOnDateDateRefresh(
                                moment().format("MM/DD/YYYY")
                              )}
                              <label htmlFor="login-username">Due On</label>
                            </div>
                          </div>
                        )}
                      {_businessSetting.DueDatesPaymentTerms == 1 &&
                        this.state.pageLoadTerms == 1 &&
                        this.state.transactionId == 0 &&
                        this.state.toggleState == true && (
                          <div className="col-6">
                            <div className="form-material open">
                              {this.isDueOnDateDateRefreshOnChange(
                                this.state.dueOnDate
                              )}
                              <label htmlFor="login-username">Due On</label>
                            </div>
                          </div>
                        )}
                      {_businessSetting.DueDatesPaymentTerms == 1 &&
                        this.state.transactionId > 0 &&
                        this.state.toggleState == true && (
                          <div className="col-6">
                            <div className="form-material open">
                              {this.isDueOnDateDateRefreshOnChange(
                                this.state.dueOnDate
                              )}
                              <label htmlFor="login-username">Due On</label>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    <div className="" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Expense No. <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            className="form-control"
                            id="invoiceNo"
                            name="invoiceNo"
                            required="required"
                            onChange={this.handleChange}
                            placeholder="Enter a Expense No."
                            autoComplete="off"
                            value={this.state.invoiceNo}
                          />
                          <div
                            id="invoiceNo-error"
                            className="animated fadeInDown"
                          >
                            {errors.invoiceNo.length > 0 && (
                              <span className="error">{errors.invoiceNo}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Date<span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          {this.isDateRefresh()}
                        </div>
                      </div>
                      {_businessSetting.Tran_AddTime == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                            onMouseLeave={this.onMouseLeaveHandler_Time.bind(
                              this,
                              "I"
                            )}
                          >
                            <input
                              readOnly={_readonlyInput}
                              type="text"
                              className="form-control search-box"
                              onChange={this.onTimeFilterName}
                              onClick={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              onKeyDown={this.manageShowTimeFilter.bind(
                                this,
                                "I"
                              )}
                              placeholder="Search Party Name Here..."
                              autoComplete="off"
                              name="invoiceTime"
                              id="invoiceTime"
                              value={this.state.invoiceTime}
                            />
                            <ul
                              className={
                                this.state._timeReg +
                                " iti__party-list iti__party-list--dropup"
                              }
                              id="country-listbox"
                              aria-expanded="false"
                              role="listbox"
                              aria-activedescendant="iti-item-ad"
                            >
                              {this.getTimeSlotList()}
                            </ul>
                          </div>
                        </div>
                      )}
                      {_businessSetting.SateOfSupply == 1 && (
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            State of Supply
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <select
                              className="form-control "
                              id="countryState"
                              name="countryState"
                              required="required"
                              onChange={this.handleChange}
                            >
                              <option value=""> None</option>
                              {this.state.stateArray.map((item, index) => {
                                let _sel = "";
                                if (this.state.state == item.name) {
                                  _sel = "selected";
                                }
                                return (
                                  <option
                                    key={index}
                                    value={item.name}
                                    selected={_sel}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.toggleState == false && (
              <div className="row">
                <div className="col-md-12" id="formSideItem">
                  <div className="block block-transparent">
                    <div className="block-content" id="formSideItem">
                      <table className="table table-bordered table-striped table-vcenter">
                        <thead id="sideTable">
                          <tr>
                            <th style={{ width: "30px" }}></th>
                            <th style={{ width: "30px" }}>#</th>
                            <th style={{ width: "200px" }}>Item</th>
                            <th style={{ width: "50px" }}>Qty</th>
                            <th style={{ width: "50px" }}>PRICE</th>
                            <th style={{ width: "100px" }}>Amount</th>
                          </tr>
                        </thead>

                        <tbody id="sideTable">
                          {this.dynamicInputFiled()}

                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <button
                                className="badge badge-primary pull-left"
                                onClick={this.handleAdd.bind(this)}
                              >
                                Add Item
                              </button>
                              <span className="titleTable">Total</span>
                            </td>
                            <td>
                              <span className="titleTable">
                                {this.state.totalQuantity}
                              </span>
                            </td>
                            <td></td>
                            <td>
                              <span className="titleTable">
                                {this.state.totalQuantity > 0
                                  ? this.state.subtotal
                                  : 0}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )} 
            {(this.state.toggleState == true ||
              this.state.toggleState == "true") && (
              <QuickTransactionExpenseItemSection
                party_id={this.state.party_id}
                fields={this.state.fields}
                transId={_transId}
                callBackItem={(
                  fieldArray,
                  _totalAmountOldSend,
                  _totalAmountNewSend,
                  _totalAmt,
                  _dueAmount,
                  _paidAM,
                  _totalDiscount,
                  _totalGstAmt,
                  _totalQuantity,
                  _transactionWiseTaxId,
                  _totalTransTaxAmount,
                  _totalTransDiscountPer,
                  _totalTransDiscountAmount
                ) => {
                  this.setState({
                    fields: fieldArray,
                    totalAmountOld: _totalAmountOldSend,
                    totalAmountNew: _totalAmountNewSend,
                    subtotal: _totalAmt,
                    totalAmount: parseInt(_totalAmt) + parseInt(this.state.TransAdditionalCharge1) + parseInt(this.state.TransAdditionalCharge2) + parseInt(this.state.TransAdditionalCharge3),
                    dueAmount: _dueAmount - parseFloat(this.state.paidAmount ),
                    receivedAmount: _paidAM,
                    gstAmount: _totalGstAmt,
                    totalDiscount: _totalDiscount,
                    totalQuantity: _totalQuantity,
                    //handleAddDisable :  handleAddDisable,
                    //handleItemSalePurchasePrice : handleItemSalePurchasePrice,
                    transactionWiseTaxId: _transactionWiseTaxId
                      ? _transactionWiseTaxId
                      : 0,
                    totalTransTaxAmount: _totalTransTaxAmount
                      ? _totalTransTaxAmount
                      : 0,
                    totalTransDiscountPer: _totalTransDiscountPer
                      ? _totalTransDiscountPer
                      : 0,
                    totalTransDiscountAmount: _totalTransDiscountAmount
                      ? _totalTransDiscountAmount
                      : 0,
                  });
                }}
              />
            )}
            <div className="row">
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content" id="formSideLeft">
                    <div className="form-group row">
                      <div className="col-12">
                        <div className="form-material open" id="paddingOff">
                          <select
                            className="col-8 form-control"
                            defaultValue={
                              this.state.bankId > 0
                                ? this.state.bankId
                                : this.state.paymentType
                            }
                            id="paymentType"
                            name="paymentType"
                            onChange={this.handleChange}
                          >
                            {this.settleAmountType()}
                          </select>
                        </div>
                        {this.state.paymentType!="Cash" && (
                          <div className="form-material open">
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <input
                                type="text"
                                className="form-control"
                                required="required"
                                value={this.state.paymentRefNo}
                                id="paymentRefNo"
                                name="paymentRefNo"
                                onChange={this.handleChange}
                                placeholder="Enter Reference No.."
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}
                        <div className="form-material open">
                          <div className="col-12 form-material" id="paddingOff">
                            <input
                              type="text"
                              className="form-control"
                              required="required"
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder="Enter a description"
                              autoComplete="off"
                              value={this.state.description}
                            />
                          </div>
                        </div>
                        <div className="form-material open">
                          <div className="col-8 form-material" id="paddingOff">
                            <input
                              type="file"
                              accept="image/*"
                              style={{ width: "60%", display: "inline-block" }}
                              autoComplete="false"
                              className="form-control"
                              id="descImage"
                              name="descImage"
                              onChange={this.onChangeFileUploadHandler}
                            />
                            {this.previewImagesListing()}
                          </div>
                        </div>
                        {_businessSetting.PrintOriginalDuplicate == 1 && (
                          <div className="form-material open">
                            <label
                              style={{ textAlign: "right" }}
                              className="col-form-label"
                              htmlFor="totalAmount"
                            >
                              Original / Duplicate Setting
                            </label>
                            <div
                              className="col-8 form-material"
                              id="paddingOff"
                            >
                              <select
                                className="col-8 form-control"
                                id="settingType"
                                name="settingType"
                                onChange={this.handleChange}
                              >
                                {optionsData.map(({ value, label }, index) => {
                                  let _selSet = "";
                                  if (this.state.settingType == value) {
                                    _selSet = "selected";
                                  }
                                  return (
                                    <option
                                      key={index}
                                      value={value}
                                      selected={_selSet}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="block block-transparent">
                  <div className="block-content">
                    {/* {this.state.fields.length > 0 && ( */}
                    <div id="taxDisData">
                      {this.state.toggleState == true &&
                        _businessSetting.TransactionWiseDiscount == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                  margin: "25px 0 0 0",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Discount %
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountPer"
                                  name="totalTransDiscountPer"
                                  onChange={this.handleChange}
                                  placeholder="In %"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.totalTransDiscountPer}
                                />
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id=""
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransDiscountAmount"
                                  name="totalTransDiscountAmount"
                                  onChange={this.handleChange}
                                  placeholder=""
                                  className="form-control"
                                  value={this.state.totalTransDiscountAmount}
                                  autoComplete="off"
                                  style={{
                                    padding: "0 !important",
                                    margin: "0 !important",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      {this.state.toggleState == true &&
                        _businessSetting.TransactionWiseTax == 1 && (
                          <div className="col-md-12" id="formSideRight">
                            <div className="form-group row">
                              <label
                                style={{
                                  textAlign: "right",
                                }}
                                className="col-lg-4 col-form-label"
                                htmlFor="totalAmount"
                              >
                                Tax{" "}
                              </label>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 0 !important",
                                }}
                              >
                                <select
                                  style={{ width: "100px" }}
                                  className="form-control"
                                  id="taxTypeTrans"
                                  name="taxTypeTrans"
                                  onChange={this.handleDynamicInputsTrans.bind(
                                    this
                                  )}
                                >
                                  <option value="0@0" selected>
                                    {" "}
                                    None
                                  </option>
                                  {console.log('transactionWiseTaxId', this.state.transactionWiseTaxId)}
                                  {this.state.taxRateArray.map((tax, index) => {
                                    let _sel = "";
                                    if (
                                      this.state.transactionWiseTaxId == tax.id
                                    ) {
                                      _sel = "selected";
                                    }
                                    return (
                                      <option
                                        key={index}
                                        value={tax.id + "@" + tax.ratio}
                                        selected={_sel}
                                      >
                                        {tax.name + "@" + tax.ratio + "%"}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div
                                className="col-lg-4 form-material"
                                id="paddingOff"
                                style={{
                                  padding: "0 !important",
                                  margin: "0 !important",
                                }}
                              >
                                <input
                                  type="text"
                                  id="totalTransTaxAmount"
                                  name="totalTransTaxAmount"
                                  placeholder=""
                                  className={"form-control " + _readonlyCss}
                                  autoComplete="off"
                                  value={this.state.totalTransTaxAmount}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      {/*Additional Charges */}
                      {_businessSetting.TransactionAdditionalChargeEnable ==
                        1 && (
                        <div id="taxDisData">
                          {_businessSetting.TransactionAdditionalCharge1Enable ==
                            1 && (
                            <div className="col-md-12" id="formSideRight">
                              <div className="form-group row">
                                <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="TransAdditionalCharge1"
                                >
                                  {
                                    _businessSetting.TransactionAdditionalCharge1Name
                                  }
                                </label>
                                <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="TransAdditionalCharge1"
                                    name="TransAdditionalCharge1"
                                    onChange={this.handleChange}
                                    placeholder=""
                                    value={this.state.TransAdditionalCharge1}
                                    autoComplete="off"
                                    onWheel={() => document.activeElement.blur()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransactionAdditionalCharge2Enable ==
                            1 && (
                            <div className="col-md-12" id="formSideRight">
                              <div className="form-group row">
                                <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="TransAdditionalCharge2"
                                >
                                  {
                                    _businessSetting.TransactionAdditionalCharge2Name
                                  }
                                </label>
                                <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="TransAdditionalCharge2"
                                    name="TransAdditionalCharge2"
                                    onChange={this.handleChange}
                                    placeholder=""
                                    value={this.state.TransAdditionalCharge2}
                                    autoComplete="off"
                                    onWheel={() => document.activeElement.blur()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {_businessSetting.TransactionAdditionalCharge3Enable ==
                            1 && (
                            <div className="col-md-12" id="formSideRight">
                              <div className="form-group row">
                                <label
                                  style={{ textAlign: "right" }}
                                  className="col-lg-4 col-form-label"
                                  htmlFor="TransAdditionalCharge3"
                                >
                                  {
                                    _businessSetting.TransactionAdditionalCharge3Name
                                  }
                                </label>
                                <div
                                  className="col-lg-8 form-material"
                                  id="paddingOff"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="TransAdditionalCharge3"
                                    name="TransAdditionalCharge3"
                                    onChange={this.handleChange}
                                    placeholder=""
                                    value={this.state.TransAdditionalCharge3}
                                    autoComplete="off"
                                    onWheel={() => document.activeElement.blur()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {/*End Additional Charges */}
                    </div>
                    {/* )} */}
                    <div className="col-md-12" id="formSideRight">
                      <div className="form-group row">
                        <label
                          style={{ textAlign: "right" }}
                          className="col-lg-4 col-form-label"
                          htmlFor="totalAmount"
                        >
                          Total Amount
                        </label>
                        <div className="col-lg-8 form-material" id="paddingOff">
                          <input
                            type="text"
                            readOnly={_readonlyInput}
                            className={"form-control " + _readonlyCss}
                            id="totalAmount"
                            name="totalAmount"
                            onChange={this.handleChange}
                            placeholder=""
                            value={this.state.totalAmount}
                            autoComplete="off"
                          />
                          <div
                            id="total_amount_er-error"
                            className="animated fadeInDown"
                          >
                            {errors.total_amount_er.length > 0 && (
                              <span className="error">
                                {errors.total_amount_er}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.toggleState == false
                            ? _hidePaid
                            : _hideRows
                        }
                      >
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="paidAmount"
                          >
                            Paid Amount
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="number"
                              className="form-control"
                              id="paidAmount"
                              name="paidAmount"
                              placeholder=""
                              value={this.state.paidAmount}
                              onChange={this.handleChange}
                              autoComplete="off"
                              disabled={_isPaidAmountDisable}
                              onWheel={() => document.activeElement.blur()}
                            />

                            <div
                              id="paid_amount_er-error"
                              className="animated fadeInDown"
                            >
                              {errors.paid_amount_er.length > 0 && (
                                <span className="error">
                                  {errors.paid_amount_er}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            style={{ textAlign: "right" }}
                            className="col-lg-4 col-form-label"
                            htmlFor="dueAmount"
                          >
                            Balance Due
                          </label>
                          <div
                            className="col-lg-8 form-material"
                            id="paddingOff"
                          >
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="form-control"
                              id="dueAmount"
                              name="dueAmount"
                              placeholder=""
                              value={this.state.dueAmount}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>

                      {_businessSetting.TransactionAdditionalFieldExpense >
                        0 && (
                        <div id="additionFields">
                          {_businessSetting.additional_field_check1 > 0 && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addF1"
                              >
                                {_businessSetting.additional_field_name1}
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="additional_value_1"
                                  name="additional_value_1"
                                  placeholder=""
                                  value={this.state.additional_value_1}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          )}

                          {_businessSetting.additional_field_check2 > 0 && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addF2"
                              >
                                {_businessSetting.additional_field_name2}
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="additional_value_2"
                                  name="additional_value_2"
                                  placeholder=""
                                  value={this.state.additional_value_2}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          )}

                          {_businessSetting.additional_field_check3 > 0 && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addF3"
                              >
                                {_businessSetting.additional_field_name3}
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="additional_value_3"
                                  name="additional_value_3"
                                  placeholder=""
                                  value={this.state.additional_value_3}
                                  autoComplete="off"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          )}

                          {_businessSetting.additional_field_date_field_check >
                            0 && (
                            <div className="form-group row">
                              <label
                                style={{ textAlign: "right" }}
                                className="col-lg-4 col-form-label"
                                htmlFor="addFDate"
                              >
                                {
                                  _businessSetting.additional_field_date_field_name
                                }
                              </label>
                              <div
                                className="col-lg-8 form-material"
                                id="paddingOff"
                              >
                                {this.isAdditionalDateRefresh()}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {_businessSetting.TransportationDetailsField1Enable == 1 && (
                <div className="col-md-12">
                  <div className="block block-transparent">
                    <div className="block-content">
                      <h5 className="content-heading">
                        Transportation Details
                      </h5>
                      <div className="block">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="block block-transparent">
                              <div className="block-content" id="formSideLeft">
                                <div className="form-group row">
                                  {_businessSetting.TransportationDetailsField1Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField1"
                                          id="TransportDetailField1"
                                          value={
                                            this.state.TransportDetailField1
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField1Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField2Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField2"
                                          id="TransportDetailField2"
                                          value={
                                            this.state.TransportDetailField2
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField2Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField3Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField3"
                                          id="TransportDetailField3"
                                          value={
                                            this.state.TransportDetailField3
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField3Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField4Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField4"
                                          id="TransportDetailField4"
                                          value={
                                            this.state.TransportDetailField4
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField4Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField5Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField5"
                                          id="TransportDetailField5"
                                          value={
                                            this.state.TransportDetailField5
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField5Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {_businessSetting.TransportationDetailsField6Enable ==
                                    1 && (
                                    <div className="col-4">
                                      <div className="form-material open">
                                        <input
                                          type="text"
                                          className="form-control search-box"
                                          placeholder={
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                          autoComplete="off"
                                          name="TransportDetailField6"
                                          id="TransportDetailField6"
                                          value={
                                            this.state.TransportDetailField6
                                          }
                                          onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-username">
                                          {
                                            _businessSetting.TransportationDetailsField6Name
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="row justify-content-center text-right">
                  <div className="col-lg-9">
                    <button
                      type="submit"
                      onClick={this.clickToAddPurchase}
                      disabled={isDisabled}
                      className="btn btn-alt-primary mb-10 mx-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewExpenseCategory"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Expense Category</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewExpenseCategory"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewExpensecategory
                      callBack={this.getUpdateResponse}
                      isRedirectTo={"business/manage-expense/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewExpenseCategory"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Edit Item Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SITEM}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewItem"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Expense Item</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewItem"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewExpenseItem
                      callBack={this.getUpdateResponse}
                      isRedirectTo={"business/manage-expense/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewItem"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Item Details*/}

          {/* Modal Add Bank Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_SBANK}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewBank"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Bank</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewBank"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewBankDetail
                      isRedirectTo={"business/manage-expense/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewBank"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Bank Details*/}

          {/* Modal Edit Party Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_NP}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustNewParty"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Add New Party</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustNewParty"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAddNewParty
                      isRedirectTo={"business/manage-expense/"}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustNewParty"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal End Edit Party Details*/}

          {/* Modal Add Terms Details Begins */}
          <Modal
            isOpen={this.state.modalIsOpen_AddTerm}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalAdjustAddNewTerms"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="block block-themed block-transparent mb-0">
                  <div className="block-header bg-primary-dark">
                    <h3 className="block-title">Due Dates and Payment terms</h3>
                    <div className="block-options">
                      <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                          this,
                          "modalAdjustAddNewTerms"
                        )}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <QuickAdjustAddNewTerms
                      callBack={this.getUpdateResponse}
                      callBackTerms={() => {
                        this.setState({
                          modalIsOpen_AddTerm: false,
                        });
                      }}
                    />
                  </div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                      this,
                      "modalAdjustAddNewTerms"
                    )}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Modal Add Terms  Details*/}
        </div>
      </React.Fragment>
    );
  }
}
