import React,{Component} from 'react';
import {PostData} from '../../../../service/postData';
import {sendNotification} from '../../../../_notificationSettings/notificationPanel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import validator from 'validator';
import moment from 'moment';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _allCompanyFirm = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';
let _classValue = 'iti__hide';
export default class GeneralSettingPanel extends Component{
    constructor(props){
        super(props);
                
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _allCompanyFirm = _UserSession.companies;
            _businessSetting = _UserSession.businessSetting;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }

        let _classValue1 = 'iti__hide';
        let _classValue2 = 'iti__hide';
        let _classValue3 = 'iti__hide';
        let _classValue1PTag = '';
        let _classValue2PTag = '';
        let _classValue3PTag = '';
        if(_businessSetting.PartyAddField1Chk > 0){
            _classValue1 =  '';
            _classValue1PTag = 'iti__hide';
        }
        if(_businessSetting.PartyAddField2Chk > 0){
            _classValue2 =   '';
            _classValue2PTag = 'iti__hide';
        }
        if(_businessSetting.PartyAddField3Chk > 0){
            _classValue3 =  '';
            _classValue3PTag = 'iti__hide';
        }
        this.state = {
            
            isLoaded : false,         
            modalIsOpen_Info : false,
            _infoData : '',
            PartyAddField1Name : (_businessSetting.PartyAddField1Name) ? _businessSetting.PartyAddField1Name : '',
            PartyAddField2Name : (_businessSetting.PartyAddField2Name) ? _businessSetting.PartyAddField2Name : '',
            PartyAddField3Name : (_businessSetting.PartyAddField3Name) ? _businessSetting.PartyAddField3Name : '',
            _infoTitle : 'Information',
            classValue1 :  _classValue1,
            classValue2 :  _classValue2,
            classValue3 :  _classValue3,
            classValue1PTag : _classValue1PTag,
            classValue2PTag : _classValue2PTag,
            classValue3PTag : _classValue3PTag,
        }
    }

    componentDidMount()
    {  
        //console.log(this.state.classValue1PTag+'==>>=='+this.state.classValue2PTag+'==>>=='+this.state.classValue3PTag+'==>>=='+this.state.classValue1+'==>>=='+this.state.classValue2+'==>>=='+this.state.classValue3);
    }
    onFilterChange = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-slug'].value;
        
        let _value = 0;
        if(activeValue == true){
            _value = 1;
        }
        const requestData = JSON.stringify({
            settingValue: _value,
            settingKey: _key,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        if(_key == 'PartyShippingAddress'){
            if(_value ==  1){
                document.getElementById("PartyPrintShippingAddress").checked = true;
            }else{
                document.getElementById("PartyPrintShippingAddress").checked = false;
            }
        }
        if(_value == 0){
            if(_key == 'PartyAddField1Chk'){
                this.setState({classValue1 : 'iti__hide',classValue1PTag:''}); 
            } 
            else if(_key == 'PartyAddField2Chk'){
                this.setState({classValue2 : 'iti__hide',classValue2PTag:''}); 
            } 
            
            else if(_key == 'PartyAddField3Chk'){
                this.setState({classValue3 : 'iti__hide',classValue3PTag:''}); 
            } 
        }else{
            if(_key == 'PartyAddField1Chk'){
                this.setState({classValue1 : '',classValue1PTag : 'iti__hide'}); 
            } 
            else if(_key == 'PartyAddField2Chk'){
                this.setState({classValue2 : '',classValue2PTag : 'iti__hide'}); 
            } 
            else if(_key == 'PartyAddField3Chk'){
                this.setState({classValue3 : '',classValue3PTag : 'iti__hide'}); 
            } 
        }



        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {

                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_key] = _value;
                
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));
                //sendNotification("Success Message 👍",result.message,"success");                
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    }
    
    /*Start Textbox Chnage*/
    handleLTxtKeyUp= (event) => {
        let activeValue = event.target.value;
        let _keyVal = event.target.attributes['data-slug'].value; 

        if(_keyVal == 'PartyAddField1Name'){
            this.setState({PartyAddField1Name:activeValue}); 
        } 
        else if(_keyVal == 'PartyAddField2Name'){
            this.setState({PartyAddField2Name:activeValue}); 
        } 
        else if(_keyVal == 'PartyAddField3Name'){
            this.setState({PartyAddField3Name:activeValue}); 
        }          
        
        const requestData = JSON.stringify({
            settingValue: activeValue,
            settingKey: _keyVal,
            businessId : _businessId,
            company_id:_companyId,
            passcode : 0
        });
        PostData(global.businessSettingUpdate, requestData,'POST').then((result) => {
            if(result.success == true)
            {

                let _UserSession = localStorage.getItem('vs_UserSession');             
                _UserSession = JSON.parse(_UserSession);
                let _updateLocal = _UserSession;
                let _businessSettingData = _updateLocal.businessSetting;
                _businessSettingData[_keyVal] = activeValue;   
                localStorage.removeItem('vs_UserSession');
                localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));     
                this.setState({_keyVal:activeValue});          
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                this.setState({_keyVal:activeValue}); 
                return;
            }
        });
    }
    /*End Textbox Chnage*/
    openModalInfoHandler(e,infoData,infoTitle)
    {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: true,_infoData:infoData,_infoTitle:infoTitle});            
        }
    }
    
    closeModalHandler = (e) => {
        if(e == 'openModalInfoHandler'){
            this.setState({modalIsOpen_Info: false,_infoData:'',_infoTitle:''});
        }
    }  
    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        let _toolPartyGrouping = "What is this? <br/> You can group similar type of parties together. You can make groups and assign parties to the groups. <br/> <br/> Why to use? <br/> If you want to create groups of Customers, Vendors or Region wise Customers & see reports based on groups then you can enable this setting";

        let _toolPartyShippingAddress = "What is this? <br/> Enables you to add shipping address for the party. <br/><br/>  Why to use? <br/> There are Parties for which Billing address is different than shipping address then it is important to mention the shipping adress so that the items are delivered at the shipping address only.";

        let _toolPartyPrintShippingAddress = "What is this? <br/> Enables you to print shipping address for the party on invoices/bills. <br/> <br/> Why to use? <br/> There are Parties for which Billing address is different than shipping address then it is important to mention the shipping adress so that the items are delivered at the shipping address only.";

        let _toolPartyGSTINNumber = "What is this? <br/> You can enter GSTIN/TIN of the party while adding a party to Vyavsay. This GSTIN/TIN will be printed invoices to the parties. <br/> <br/> Why to use? <br/> In case you want Party's GSTIN/TIN no, to be printed on invoice then you can enable this."; 

        let _toolPartyAdditionalField = "What is this? <br/>Add extra fields to save more information for parties. <br/> <br/>Why to use? <br/>In case you have more information to be entered & tracked on party level you can enable these fields & name them based on your business requirement";
        return(
            <React.Fragment>
                <div className="row items-push myownh1" style={{marginTop: '-50px'}}>
                    <div className="col-md-6">
                        <h5 className="content-heading">Party Setting</h5>
                        <ul>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.GSTINNumber > 0) ? true : false} id="GSTINNumber" name="GSTINNumber" value="1" data-slug="GSTINNumber" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>GSTIN Number <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyGSTINNumber,'GSTIN Number ')}></i></span>
                            </li>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyGrouping > 0) ? true : false} id="PartyGrouping" name="PartyGrouping" value="1" data-slug="PartyGrouping" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Party Grouping <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyGrouping,'Party Grouping')}></i></span>
                            </li>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyShippingAddress > 0) ? true : false} id="PartyShippingAddress" name="PartyShippingAddress" value="1" data-slug="PartyShippingAddress" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Party Shipping Address <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyShippingAddress,'Party Shipping Address')}></i></span>
                            </li>
                            <li style={{listStyle: 'none'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyPrintShippingAddress > 0) ? true : false} id="PartyPrintShippingAddress" name="activeItem" value="1" data-slug="PartyPrintShippingAddress" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>Party Print Shipping Address <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyPrintShippingAddress,'Party Print Shipping Address')}></i></span>
                            </li>

                        </ul>
                    </div>
                    <div className="col-md-6">
                        <h5 className="content-heading">Party Additional Fields <i className="si si-info" data-toggle="tooltip" data-placement="bottom" onClick={this.openModalInfoHandler.bind(this,'openModalInfoHandler',_toolPartyAdditionalField,'Party Additional Fields ')}></i></h5>
                        <ul>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyAddField1Chk > 0) ? true : false} id="PartyAddField1Chk" name="PartyAddField1Chk" value="1" data-slug="PartyAddField1Chk" onChange={this.onFilterChange}/>
                                <span style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}} className={this.state.classValue1}>
                                    <input type="text" placeholder="Additional Field 1" data-slug="PartyAddField1Name" id="PartyAddField1Name" name="PartyAddField1Name" value ={this.state.PartyAddField1Name} onChange={this.handleLTxtKeyUp}/>
                                </span>
                                <span className={this.state.classValue1PTag} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Additional Field 1</span>
                                
                                <span className={this.state.classValue1} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                Show In Print &nbsp;&nbsp;<input type="checkbox" defaultChecked={(_businessSetting.PartyAddField1ShowPrint > 0) ? true : false} id="PartyAddField1ShowPrint" name="PartyAddField1ShowPrint" value="1" data-slug="PartyAddField1ShowPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyAddField2Chk > 0) ? true : false} id="PartyAddField2Chk" name="PartyAddField2Chk" value="1" data-slug="PartyAddField2Chk" onChange={this.onFilterChange}/>
                                <span className={this.state.classValue2} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                    <input type="text" placeholder="Additional Field 2" data-slug="PartyAddField2Name" id="PartyAddField2Name" name="PartyAddField2Name" value ={this.state.PartyAddField2Name} onChange={this.handleLTxtKeyUp}/>
                                </span>
                                <span className={this.state.classValue2PTag} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Additional Field 2</span>
                                
                                <span className={this.state.classValue2} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                Show In Print &nbsp;&nbsp;<input type="checkbox" defaultChecked={(_businessSetting.PartyAddField2ShowPrint > 0) ? true : false} id="PartyAddField2ShowPrint" name="PartyAddField2ShowPrint" value="1" data-slug="PartyAddField2ShowPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                            <li style={{listStyle: 'none',marginBottom: '10px'}}>
                                <input type="checkbox" defaultChecked={(_businessSetting.PartyAddField3Chk > 0) ? true : false} id="PartyAddField3Chk" name="PartyAddField3Chk" value="1" data-slug="PartyAddField3Chk" onChange={this.onFilterChange}/>
                                <span className={this.state.classValue3} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                    <input type="text" placeholder="Additional Field 3" data-slug="PartyAddField3Name" id="PartyAddField3Name" name="PartyAddField3Name" value ={this.state.PartyAddField3Name} onChange={this.handleLTxtKeyUp}/>
                                </span>
                                <span className={this.state.classValue3PTag} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}> Additional Field 3</span>                                
                                <span className={this.state.classValue3} style={{fontWeight: '400',fontSize: '15px',marginLeft: '5px'}}>
                                Show In Print &nbsp;&nbsp;<input type="checkbox" defaultChecked={(_businessSetting.PartyAddField3ShowPrint > 0) ? true : false} id="PartyAddField3ShowPrint" name="PartyAddField3ShowPrint" value="1" data-slug="PartyAddField3ShowPrint" onChange={this.onFilterChange}/>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Modal  Tooltip Information Begins */}
               <Modal isOpen={this.state.modalIsOpen_Info} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="openModalInfoHandler">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">{this.state._infoTitle}</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'openModalInfoHandler')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                {parse(this.state._infoData)}
                                </div>
                                <br/>
                            </div>
                            
                        </div>
                    </div>
                </Modal>
                {/* Modal End Tooltip Information*/}
            </React.Fragment>
        )
    }
} 