import React,{Component} from 'react';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class termConditionPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    render(){
        
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Terms & Conditions</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="sec4 sec4C">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="blog_content">
                                            <div className="blog_txt blog_txt1">
                                                <h3>General</h3>
                                                <p>
                                                    One of the greatest things about Las Vegas, Reno and Atlantic City (but especially Las Vegas) is the number of shows that are available. You can get to watch top class comedians like Jay Leno, Jerry Seinfeld, Ray
                                                    Romano, Tim Allen and even the likes of Bill Cosby and Chris Rock. If you are into music you can watch female singers like Celine Dion, Mariah Carey, Britney Spears, Christina Aguilera and Beyonc?, male
                                                    performers like Phil Collins, Eric Clapton, Snoopy Dog and bands like Oasis and Bon Jovi. I could go on and on but the list is endless. If you are into magic you can watch magicians like David Copperfield do
                                                    their thing meters from you. Whatever you like, you can find it here from Western music to oldies to Jazz, Rock, Heavy Mettle to Trance. All you have to do is look at the itenary offered during your visit.
                                                </p>
                                                <h3>Modification of terms & conditions</h3>
                                                <p>
                                                    Chinese to Japanese to Korean to Jewish and even Vegetarian and proper meat eating establishments await your every delight in Vegas. Do not opt for the cheap and oily fried dishes served for free while you play.
                                                    Stop a while and take in the delightful scenery and smells of East Asian or European dishes. What is wondrous is that you get to see man’s ability to mix. A real melting pot if I may say so myself.
                                                </p>
                                                <h3>When This Privacy Statement Applies</h3>
                                                <p>
                                                    But is that all what casino cities like Las Vegas are about? Do you have to remain in the city to really and truly enjoy your stay? No.
                                                </p>
                                                <p>
                                                    While looking at the Dam and Canyon is from above, to see the true beauty of the river, you have to go down. The Colorado river is excellent for river-rafting and water sports, but you do not have to take part if
                                                    it is not your thing. Instead just sit back and enjoy another of nature’s marvels.
                                                </p>
                                                <h3>Description of Services</h3>
                                                <p>
                                                    We provide an array of services including Inventory management, Invoicing, Reporting tool, Estimate/Order Form creator and so on to serve as a complete business management Software ("Service" or "Services"). The
                                                    User may use the Services for his/her personal and business use or for internal business purpose in the organization that he/she represent. The User may download the application from Official website/ Google Play
                                                    Store using the Internet. Once downloaded, User no longer needs internet connection for its usage. A User can create and add content to his/her account staying offline. But, the User is responsible for obtaining
                                                    access to the Internet and the device necessary to use the Services. ( Side Note: vyavsay tries its best to make Businesses compliant to the taxation and other laws as much as possible. But, it is solely the
                                                    User’s responsibility to see to that he/she remains compliant with GST and other laws levied by Government. vyavsay is not in any case responsible with the violation of the compliance caused by software)
                                                </p>
                                                <h3>Business Data and Privacy</h3>
                                                <p>
                                                    The User owns the content created or stored by his/her as we respect his/her right to ownership of content created or stored by them. The Users use of the Services does grant vyavsay the license to use,
                                                    reproduce, adapt, modify, publish or distribute the content created by them or stored in the user’s account for vyavsay internal purpose. vyavsay tries keeping Users data safe but holds zero responsibility in
                                                    case of data leaks.
                                                </p>
                                                <h3>Permissions</h3>
                                                <p>
                                                    <b>Camera</b> To take the picture of the bills etc to be attached with transactions.<br />
                                                    <b>Contacts</b> To autofill the details during party creation.<br />
                                                    <b>Storage</b> To store reports, PDF, Excels generated by users and access user selected images to attach with bills<br />
                                                    <b>Location</b> To customize the app according to your region.
                                                </p>
                                                <h3>Approval of Alerts, Messages and Promotional campaign</h3>

                                                <p>
                                                    In addition to the General Conditions of vyavsay service a User agrees to be bound by the following terms and conditions: (i) The User agrees to receive promotional, transactional and commercial communications
                                                    from vyavsay through calls, email, SMS or push notifications. (ii) Under certain conditions, promotional messages are transmitted to the users and that vyapar shall not bear any further obligation in respect of
                                                    such promotional messages.
                                                </p>
                                                <h3>Cancellation/Refund Policy:</h3>

                                                <p>
                                                    1. If the Refund request is reported to us through email / website within 7 days of purchase and we will give you 100% money back.<br />
                                                    2. The refund request should be raised via email or website.<br />
                                                    3. Refund policies will be applicable only for purchases equal to or more than 1 year.
                                                </p>
                                                <h3>End of Terms of Service</h3>
                                                <p>
                                                    If you have any questions or concerns regarding this Agreement, please contact us at contact@vyavsay.in
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}