import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTypeData = [];

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTypeData = _companySession.taxType;
}

let _loader = "glb-ldr-prt active";

export default class partyListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-expense/",
      buttonTitle: "Add New",
      isLoaded: true,

      expenseCatId: this.props.expenseCatId,
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,

      expense_category: "",
      expense_type: "Indirect Expense",
      errors: {
        expense_category: "",
        expense_type: "",
      },
    };
  }

  async componentDidMount() {
    let _expenseCatId = this.props.expenseCatId;

    if (_expenseCatId > 0) {
      let _expCatArray = [];
      let _url_GetData =
        global.userExpenseCategoryDetails + "?expenseCatId=" + _expenseCatId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _expCatArray = responseJson.response[0];

              this.setState({
                buttonTitle: "Edit",
                expense_category: _expCatArray.expense_category,
                expense_type: _expCatArray.expense_type,
                isLoaded: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "expense_category":
        errors.expense_category =
          value.length < 2
            ? "*Expense Category must be 2 characters long!"
            : "";
        this.setState({ expense_category: value });
        break;
      case "expense_type":
        this.setState({ expense_type: value });
        break;
      default:
        break;
    }
  };

  clickToAddParty = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.expense_category == "") {
      _isError = true;
      errors["expense_category"] = "*Please Enter Expense Category";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.props.expenseCatId > 0) {
        this.editCategoryMethodCall();
      } else {
        this.addCategoryMethodCall();
      }
    }
  };

  addCategoryMethodCall() {
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      userId: this.state.userId,
      company_id: this.state.company_id,
      expense_category: this.state.expense_category,
      expense_type: this.state.expense_type,
    });
    PostData(global.userAddExpenseCategory, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
            //window.location.href = global.webUrl+this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  editCategoryMethodCall() {
    const requestData = JSON.stringify({
      expenseCatId: this.state.expenseCatId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      expense_category: this.state.expense_category,
      expense_type: this.state.expense_type,
    });
    PostData(global.userEditExpenseCategory, requestData, "POST").then(
      (result) => {
        if (result.success == true) {
          sendNotification("Success Message 👍", result.message, "success");
          setTimeout(() => {
            this.props.callBack("hello");
            //window.location.href = global.webUrl+this.state.isRedirectTo;
          }, Math.floor(Math.random() * 5000) + 1);
        } else {
          sendNotification("Error Message 😓", result.message, "danger");
          return;
        }
      }
    );
  }

  onRadioChange = (e) => {
    this.setState({
      payType: e.target.value,
    });
  };

  handleCallBackDate = (start) => {
    this.setState({ party_opening_date: start.format("MMMM D, YYYY") });
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="expense_category"
                  name="expense_category"
                  value={this.state.expense_category}
                  placeholder="Enter Expense Category"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Expense Category <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.expense_category.length > 0 && (
                    <span className="error">{errors.expense_category}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="expense_type"
                  name="expense_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Indirect Expense"
                    selected={
                      this.state.expense_type == "Indirect Expense"
                        ? "selected"
                        : ""
                    }
                  >
                    {" "}
                    Indirect Expense
                  </option>
                  <option
                    value="Direct Expense"
                    selected={
                      this.state.expense_type == "Direct Expense"
                        ? "selected"
                        : ""
                    }
                  >
                    {" "}
                    Direct Expense
                  </option>
                </select>
                <label htmlFor="login-username">Expense Type</label>
                <span className="iris_error_txt">
                  {errors.expense_type.length > 0 && (
                    <span className="error">{errors.expense_type}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddParty}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Expense Category
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
