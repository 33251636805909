import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { numberFormat } from '../../../config/numberFormat';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Link } from 'react-router-dom';


let _UserSession = localStorage.getItem('vs_UserSession');
let _signingId = '0';
let _businessId = '0';
let _companyId = '0';

if(_UserSession!=undefined || _UserSession!=null)
{
    _UserSession = JSON.parse(_UserSession);
    _signingId = _UserSession.loginId;
    _businessId = _UserSession.tokenKey;
}

let _companySession = localStorage.getItem('vs_UserCompanySession');
if(_companySession!=undefined || _companySession!=null)
{
    _companySession = JSON.parse(_companySession);
    _businessId = _companySession.business_id;
    _companyId = _companySession.company_id;
}

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Date',
        field: 'invoice_date',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Date',
        },
    },
    {
        label: 'Ref No.',
        field: 'invoice_no',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Ref No.',
        },
    },
    {
        label: 'Party Name',
        field: 'party_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Party Name',
        },
    },
    {
        label: 'Type',
        field: 'payment_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Type',
        },
    },
    {
        label: 'Amount',
        field: 'total_amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Amount',
        },
    },
    {
        label: 'Paid Amount',
        field: 'received_amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Received Amount',
        },
    },
    {
        label: 'Balance Amount',
        field: 'balance_amount',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Balance Amount',
        },
    },
    {
        label: 'Status',
        field: 'pay_status',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';

export default class userSaleListPanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add Sale',
            isLoaded : true,
            transactionArray :[],
            transactionArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            name :  null,

            paidAmount:0,
            unpaidAmount:0,
            total:0,
            startDate : moment().startOf('month').toDate(),
            endDate : moment().endOf('month').toDate(),
            typeFilter : 'Both',
            dateLabel : '',
            countList : 0,
            totalTransAmt : 0,
            totalBalanceAmt : 0,
            totalTransAmtSign : '',
            totalBalanceAmtSign : '',

            pageCount : 10,
            page : 1,
            totalCount  : 0,
            pageShow  : 0,
            setPage : 1,
            searchInvNo : '',
            
            errors: {
                name : '',
            }
        }
    }

    async componentDidMount ()
    {   
        this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
    }

    getInvoiceSearch = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let _valueSend = value;
        this.setState({searchInvNo:_valueSend})
        if(_valueSend.length >= 3 || _valueSend == ''){
            this.getSaleListData(this.state.page,_valueSend,this.state.pageCount);
        }
    }
    getSaleListData(_page,searchInvSend,pageCount){
        let _transArray = [];
        
        let _paidAmt = 0;
        let _unpaidAmt = 0;
        let _totalAmt = 0;

        const requestData = JSON.stringify({
            business_id:this.state.businessId,
            company_id:this.state.company_id,
            type:"Both",
            isDateFilter:true,
            start_date : this.state.startDate,
            end_date : this.state.endDate,
            pageCount:pageCount,
            search_inv_no:searchInvSend,
            page:_page
        });

        PostData(global.userSaleTransactionList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {   
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray, paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,isLoaded:true,countList:0,totalTransAmt:0,totalBalanceAmt:0,totalBalanceAmtSign:'',totalTransAmtSign:''});
            }
            else
            {
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    console.log('_transArray', JSON.stringify(_transArray));
                    _transArray.map((itm, i) =>{
                        if(itm.payment_paid == 'UNPAID'){
                            _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.total_amount);
                        }
                        else{
                            _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.total_amount);
                        }

                        itm.payType = 'Credit Note';
                        //if(itm.against_invoice_no > 0 || itm.from_invoice_no > 0)
                        if(itm.against_invoice_no > 0)
                        {
                            itm.payType = 'Credit Note';//'Sale';
                        }
                        else if(itm.type == 'SALE'){
                            itm.payType = 'Sale';
                        }
                    });
                }
                let _totalTransAmtSign = ''; 
                let _totalBalanceAmtSign = '';
                if(responseJson.totalTransAmt >0){
                    _totalTransAmtSign = '';//' - ';
                }
                if(responseJson.totalBalanceAmt >0){
                    _totalBalanceAmtSign = '';//' - ';
                }
                _totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,countList:responseJson.countList,totalTransAmt:responseJson.totalTransAmt,totalBalanceAmt:responseJson.totalBalanceAmt,totalBalanceAmtSign:_totalBalanceAmtSign,totalTransAmtSign:_totalTransAmtSign,totalCount:responseJson.totalCount,pageShow:responseJson.pageShow}); 
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    
    deleteSaleReturn(value,type){
        if(value.isReturn){
            alert("Transaction can't be deleted");
        }else {
            confirmAlert({
                title: 'Confirm to delete',
                message: 'Are you sure you want to delete?',
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-danger',
                        onClick: () => {
                            const requestData = JSON.stringify({
                                sale_id: value.id,
                                type_value: type
                            });
                            PostData(global.deleteSaleTransaction, requestData, 'POST').then((result) => {
                                let responseJson = result;
                                if (responseJson.success == false) {
                                    sendNotification("Error Message 😓", responseJson.message, "danger");
                                    return;
                                } else {
                                    sendNotification("Success Message 👍", responseJson.message, "success");
                                    this.getSaleListData(this.state.page, this.state.searchInvNo, this.state.pageCount);
                                    return;
                                }
                            });
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-dark',
                    }
                ]
            });
        }
    }

    loadTranscListing(){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.transactionArrayFilter;
            if(_transDetails.length > 0){
                let _filterType = 'Both';
            
                console.log(this.state.typeFilter+'==zz==');
                if(this.state.typeFilter == 'Sale')
                {
                    _filterType = 'Sale';
                }
                else if(this.state.typeFilter == 'Credit Note'){
                    _filterType = 'Credit Note';
                }
                let _i = 0;
                console.log('_transDetails', JSON.stringify(_transDetails))
                _transDetails.map((value,index) => {
                    _i++;
                    let _trans_duplicate_normal = value.trans_duplicate_normal ? value.trans_duplicate_normal : '';
                    let _pageAK = this.state.setPage;
                    let _payType = <span className="badge badge-warning">UNPAID</span>;
                    if(value.payment_paid == 'PARTIAL'){
                        _payType = <span className="badge badge-info">{value.payment_paid}</span>;
                    }
                    else if(value.payment_paid == 'PAID'){
                        _payType = <span className="badge badge-success">{value.payment_paid}</span>;
                    }

                    let _type = "&tType="+value.type;
                    let _editActionUrl = '';
                    let _editDuplicateActionUrl = '/business/add-sale-duplicate/?auth='+value.id;
                    if(value.type == 'SALE'){
                        _editActionUrl = global.webUrl+'business/add-sale/?auth='+value.id+_type;
                        _editDuplicateActionUrl = '/business/add-sale-duplicate/?auth='+value.id
                    }
                    else if(value.type == 'CREDIT NOTE'){
                        _editActionUrl = global.webUrl+'business/add-sale-credit-note/?auth='+value.id+"&tType=CREDIT NOTE";
                    }
                    let _showDupL = '';
                    if(_trans_duplicate_normal == 'Duplicate'){
                        _showDupL = ' (Duplicate)';
                    }
                    let _showInv = value.customPrefix+' #'+value.invoice_no_web;
                    if(value.type == "CREDIT NOTE"){
                        _showInv = value.customPrefix+' #'+value.invoice_no_return_web;
                    }
                    let downloadPdf = value.payType == 'Sale' ? global.webUrl+'business/download-pdf/?auth='+value.id :
                        global.webUrl+'business/return-challan-pdf/?auth='+value.id;
                    rows.push({
                        srNo: ((_pageAK - 1) * this.state.pageCount) + _i,
                        invoice_date: value.invoice_date,
                        invoice_no:_showInv + _showDupL,
                        party_name:value.party_name,
                        payment_type: value.type,//value.payType,
                        total_amount:numberFormat(value.total_amount),
                        received_amount: numberFormat(value.received_amount ? value.received_amount : value.paid_amount),
                        balance_amount : numberFormat(value.balance),
                        pay_status: _payType,
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    {
                                        (value.type == 'SALE' && _trans_duplicate_normal == 'Normal' ) &&
                                        <Link className="dropdown-item" to={_editDuplicateActionUrl}>
                                            Duplicate
                                        </Link>
                                    }
                                    { _trans_duplicate_normal == 'Normal' &&
                                        <a className="dropdown-item" href={_editActionUrl}>
                                        View / Edit Details
                                    </a>
                                    }
                                    <a className="dropdown-item" onClick={this.deleteSaleReturn.bind(this,value, 'Sale Return')}>
                                        Delete
                                    </a>
                                    <a className="dropdown-item" href={downloadPdf} target="_blank">
                                        Download PDF
                                    </a>
                                    <a className="dropdown-item" href={downloadPdf} target="_blank">
                                        Preview
                                    </a>
                                    <a className="dropdown-item" href={downloadPdf} target="_blank">
                                        Print
                                    </a>
                                </div>
                            </div>
                        )
                    });
                });
            }
        }


        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }

    handleCallBackDate = (start, end) => {
        this.setState({startDate:start.format('MMMM D, YYYY'),endDate:end.format('MMMM D, YYYY')});
        this.getSaleListData(this.state.page,this.state.searchInvNo,this.state.pageCount);
    };

    handleCallBackFilter(event){
        event.preventDefault();
        let _typeFilter = event.target.value;
        this.setState({typeFilter:_typeFilter});
        //console.log(this.state.typeFilter+'==sss==');
        //this.loadTranscListing();
        let _transArray = [];
        
        let _paidAmt = 0;
        let _unpaidAmt = 0;
        let _totalAmt = 0;

        const requestData = JSON.stringify({
            business_id:this.state.businessId,
            company_id:this.state.company_id,
            type:_typeFilter,
            isDateFilter:true,
            start_date : this.state.startDate,
            end_date : this.state.endDate,
        });

        PostData(global.userSaleTransactionList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {   
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray, paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,isLoaded:true,countList:0,totalTransAmt:0,totalBalanceAmt:0,totalBalanceAmtSign:'',totalTransAmtSign:''});
            }
            else
            {
                if (responseJson.response) {
                    _transArray = responseJson.response;
                    _transArray.map((itm, i) =>{
                        if(itm.payment_paid == 'UNPAID'){
                            _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.total_amount);
                        }
                        else{
                            _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.total_amount);
                        }

                        itm.payType = 'Credit Note';
                        //if(itm.against_invoice_no > 0 || itm.from_invoice_no > 0)
                        if(itm.against_invoice_no > 0)
                        {
                            itm.payType = 'Credit Note';//'Sale';
                        }
                        else if(itm.type == 'SALE'){
                            itm.payType = 'Sale';
                        }
                    });
                }
                let _totalTransAmtSign = ''; 
                let _totalBalanceAmtSign = '';
                if(responseJson.totalTransAmt >0){
                    _totalTransAmtSign = '';//' - ';
                }
                if(responseJson.totalBalanceAmt >0){
                    _totalBalanceAmtSign = '';//' - ';
                }
                _totalAmt = parseFloat(_paidAmt) + parseFloat(_unpaidAmt);
                this.setState({transactionArrayFilter:_transArray,itemArray:_transArray,isLoaded:true,paidAmount:_paidAmt,unpaidAmount:_unpaidAmt,total:_totalAmt,countList:responseJson.countList,totalTransAmt:responseJson.totalTransAmt,totalBalanceAmt:responseJson.totalBalanceAmt,totalBalanceAmtSign:_totalBalanceAmtSign,totalTransAmtSign:_totalTransAmtSign});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Credit Note / Sale Return List<br/>
                            </h1>
                            
                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"business/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"business/add-sale-credit-note/"}>Create New Credit Note / Sale Return</a>                        
                        <span className="breadcrumb-item active">Manage Return Credit Note / Sale Return List</span>
                    </nav>
                    

                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content">
                                        <div className="row items-push">
                                            <div className="row col-lg-12">
                                                <div className="col-lg-9">
                                                    <span>Filter : </span>
                                                    <DateRangePicker
                                                        initialSettings={{ 
                                                            startDate: this.state.startDate, 
                                                            endDate: this.state.endDate,
                                                            locale: {
                                                                format: 'MMMM D, YYYY',
                                                            },
                                                            ranges: {
                                                                Today: [moment().toDate(), moment().toDate()],
                                                                Yesterday: [
                                                                moment().subtract(1, 'days').toDate(),
                                                                moment().subtract(1, 'days').toDate(),
                                                                ],
                                                                'This Week': [
                                                                    moment().startOf('week').format("MMM DD, YYYY"),
                                                                    moment().endOf('week').format("MMM DD, YYYY"),
                                                                    ],
                                                                'This Month': [
                                                                    moment().startOf('month').toDate(),
                                                                    moment().endOf('month').toDate(),
                                                                ],
                                                                'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                                ],
                                                                'This Quarter': [
                                                                    moment().startOf('month').toDate(),
                                                                    moment().add(2, 'month').endOf('month').toDate(),
                                                                ],
                                                                'This Financial Year': [
                                                                    moment().month('April').startOf('month').format("MMM DD, YYYY"),
                                                                    moment().add(1, 'year').month('March').endOf('month').format("MMM DD, YYYY"),
                                                                ],
                                                            }
                                                        }}
                                                        onCallback={this.handleCallBackDate}
                                                    >
                                                    <input type="text" className="form-control col-4" style={{display: 'inline-block'}}/>
                                                    </DateRangePicker>

                                                    <select name="" onChange={this.handleCallBackFilter.bind(this)} className="form-control col-3 ml-20" style={{display: 'inline-block'}}>
                                                        <option value="Both">Sale & Cr. Note</option>
                                                        <option value="Sale">Sale</option>
                                                        <option value="CREDIT NOTE">Cr. Note</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="row pb-20 pull-right">
                                                        <div className="text-right">
                                                            <div className="js-appear-enabled text-center">
                                                                <div className="text-info"><i className="fa fa-file-excel-o"></i></div>
                                                                <div className="font-size-sm text-muted">Excel Export</div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-6 text-right border-r">
                                                            <div className="js-appear-enabled text-center">
                                                                <div className="text-info"><i className="fa fa-file-excel-o"></i></div>
                                                                <div className="font-size-sm text-muted">Excel Export</div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-6">
                                                            <div className="js-appear-enabled text-center">
                                                                <div className="text-success"><i className="fa fa-print"></i></div>
                                                                <div className="font-size-sm text-muted">Print</div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>       
                                                <br />
                                            </div>
                                        </div>    
                                        <h2 className="block-title ml-10">Transactions</h2>
                                        <div className="text-center">
                                            <strong>
                                                <span>No Of Txn : {this.state.countList}</span>&nbsp;&nbsp;&nbsp;
                                                <span>Total Amount : {this.state.totalTransAmtSign}  {numberFormat(this.state.totalTransAmt)}</span>&nbsp;&nbsp;&nbsp;
                                                <span>Total Balance : {this.state.totalBalanceAmtSign} {numberFormat(this.state.totalBalanceAmt)}</span>&nbsp;&nbsp;&nbsp;
                                            </strong>
                                        </div>                                  
                                        <input type="text" className="form-control col-6" style={{display:'inline-block'}} placeholder='search reference number (type at least three characters)'onChange={this.getInvoiceSearch}/>
                                        <Link to={"/business/add-sale-credit-note/"} style={{}} className="pull-right btn btn-sm btn-primary">
                                            <i className="fa fa-plus-circle"></i> Add Credit Note
                                        </Link>
                                        
                                    </div>
                                    <div className="block-content">
                                        <MDBDataTableV5 
                                            hover
                                            span
                                            displayEntries={false}
                                            entries={100}
                                            pagingTop={false}
                                            searchTop={false}
                                            searchBottom={false}
                                            info={false}
                                            barReverse
                                            data={this.loadTranscListing()} 
                                        />
                                    </div>                                   
                  
                                    <div style={{ float: "right", marginTop: "-15px" }}>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Rows per page:</div>
                                            </div>
                                            <div className="d-flex align-items-center" >
                                            <select className=" d-flex align-items-center custom-select custom-select-sm form-control form-control-sm"
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        const { name, value } = event.target;
                                                        //this.setState({ pageCount: value, setPage: 1 });
                                                        this.setState(prevState => ({
                                                                pageCount : value,
                                                                setPage : 1,
                                                            }),
                                                            () => {
                                                                this.getSaleListData(1,this.state.searchInvNo,
                                                                    value
                                                                );
                                                            });
                                                    }}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <div role="status" aria-live="polite"> Total: {this.state.totalCount}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-content">
                                        <div style={{ float: "left", marginTop: "-45px" }}>
                                        <PaginationControl
                                            between={this.state.pageCount}
                                            total={this.state.totalCount}
                                            limit={this.state.pageCount}
                                            changePage={(page) => {
                                            this.setState({ setPage: page });
                                            this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount
                                            );
                                            }}
                                            ellipsis={1}
                                            activeClass={this.state.setPage}
                                            page={this.state.setPage}
                                        />
                                        </div>
                                    </div>
                                    {/* <div className="block-content">
                                        <div style={{float : 'left', marginTop : '-45px'}}>
                                            <PaginationControl
                                                between={this.state.pageCount}
                                                total={this.state.totalCount}
                                                limit={this.state.pageCount}
                                                changePage={(page) => {
                                                this.setState({setPage:page}); 
                                                this.getSaleListData(page,this.state.searchInvNo,this.state.pageCount); 
                                                }}
                                                ellipsis={1}
                                                activeClass={this.state.setPage}
                                                page={this.state.setPage}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                

                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>

               
                

            </React.Fragment>
        )    
    }
}