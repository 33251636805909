import React, { Component } from "react";
import QuickAddNewItem from "./quickItemAdd";

export default class ItemListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
    };
  }

  render() {
    let _handleParam = this.props.location.search;
    _handleParam = _handleParam.split("?auth=");
    let _itemId = _handleParam[1] ? _handleParam[1] : 0;

    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              {/* <h2 className="content-heading">Manage Business</h2> */}
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Add / Edit Item
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/manage-items/"}
            >
              View Items List
            </a>
            <span className="breadcrumb-item active">Add / Edit Item</span>
          </nav>

          <div className="block">
            <div className="block-header block-header-default">
              <h3 className="block-title">Add / Edit Item </h3>
            </div>
            <div className="block-content">
              <QuickAddNewItem isMainPage={true} itemId={_itemId} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
