import React,{Component} from 'react';
import queryString from 'query-string';
import {NavLink} from 'react-router-dom';

let _sessionPeriod = true;

//className={this.props.location.pathname == '/' ? 'active' : ''}
export default class MenuPage extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');
        let _signingName = 'Administrator';
        
        if(_UserSession!=undefined || _UserSession!=null)
        {    
            if(_UserSession.loginType!='Admin'){
                _sessionPeriod = false;
                _UserSession = JSON.parse(_UserSession);
                _signingName = _UserSession.loginName;
            }
        }

        this.state={
            isLoggedIn : _sessionPeriod,
            loggedName : _signingName,
            isSessionOff : _sessionPeriod,
            pathName:'Dashboard'
        }
    }

    componentDidMount(){
        let _handleMenu = this.props.pageId;
        //console.log(_handleMenu+'Page--Url--->');
    }
    
    handleLogoutClick = (event) => {
        localStorage.removeItem('vs_UserSession');
        this.setState({isSessionOff:true})
        //window.location.href = ("/");
    }

    render(){
        if (this.state.isSessionOff == true)
        {
            //console.log('Session Expire...!');   
            //this.logoutCallBack();         
            window.location.href = global.webUrl+'login/';
        }
        return(
            <React.Fragment>
                {/* Main Sidebar */}
                <nav id="sidebar" data-simplebar="init">
                    <div className="sidebar-content">
                        <div className="content-header content-header-fullrow px-15">
                            <div className="content-header-section sidebar-mini-visible-b">
                                <span className="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                                    <span className="text-dual-primary-dark">V</span><span className="text-primary">D</span>
                                </span>
                            </div>
                            <div className="content-header-section text-center align-parent sidebar-mini-hidden">
                                <button type="button" className="btn btn-circle btn-dual-secondary d-lg-none align-v-r" data-toggle="layout" data-action="sidebar_close">
                                    <i className="fa fa-times text-danger"></i>
                                </button>
                                <div className="content-header-item">
                                    <a className="font-w700" href={global.webUrl+"administrator/dashboard/"}>
                                        <i className="si si-fire text-primary"></i> <span className="text-dual-primary-dark">Vyavsay</span> Dashboard
                                    </a>
                                </div>
                            </div>
                        </div>
                            <div className="content-side content-side-full content-side-user px-10 align-parent">
                                <div className="sidebar-mini-visible-b align-v animated fadeIn">                        
                                    <img src="http://vyavsay.in/img/logo.png" alt="avatar" className="img-avatar img-avatar32"/>
                                </div>
                                <div className="sidebar-mini-hidden-b text-center">
                                    <a className="img-link" href={() => false}>
                                        <img src="http://vyavsay.in/img/logo.png" alt="avatar" className="img-avatar full-width"/>
                                    </a>
                                    <ul className="list-inline mt-10">
                                        <li className="list-inline-item">
                                            <b>{this.state.loggedName}</b>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="link-effect text-dual-primary-dark" data-toggle="layout" data-action="sidebar_style_inverse_toggle" href={() => false}>
                                                <i className="si si-drop"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a onClick={this.handleLogoutClick} className="link-effect text-dual-primary-dark" data-box="#mb-signout" href={() => false}>
                                                <i className="si si-logout"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        <div className="content-side content-side-full">

                        {/* Sidebar Navigation */}
                        <ul className="nav-main">
                            <li>
                                <a href={global.webUrl+"administrator/dashboard/"} eventKey={1} className=" active">
                                    <i className="si si-cup sidebar-nav-icon"></i> <span className="sidebar-mini-hide">Dashboard</span>
                                </a>
                            </li>
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Masters</span>
                            </li>
                            <li>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-layers"></i><span className="sidebar-mini-hide">Master Settings</span></a>    
                                <ul>
                                    <li>
                                        <NavLink to={"/administrator/manage-country/"} eventKey={2}>Manage Country</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/administrator/manage-business-category/"} eventKey={2}>Manage Business Category</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/administrator/manage-business-type/"} eventKey={2}>Manage Business Type</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to={"/administrator/manage-gst-type/"} eventKey={2}>Manage Tax Type</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/administrator/manage-gst/"} eventKey={3}>Manage Tax Rate</NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink to={"/administrator/manage-unit/"} eventKey={4}>Manage Units</NavLink>
                                    </li>
                                    <li>
                                        <a className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage SMS Setting</span></a>
                                        <ul>
                                            <li>
                                                <NavLink to={"/administrator/manage-sms-setting/"}><span className="sidebar-mini-hide">Manage Master SMS</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/administrator/pending-business-sms/"}><span className="sidebar-mini-hide">Pending Business SMS List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>              

                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Webiste</span>
                            </li>
                            <li>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-layers"></i><span className="sidebar-mini-hide">Webiste Settings</span></a>    
                                <ul>
                                    <li>
                                        <a className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Blog</span></a>
                                        <ul>
                                            <li>
                                                <NavLink to={"/administrator/manage-blog-category/"} eventKey={4}>Manage Category</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/administrator/add-blog/"}><span className="sidebar-mini-hide">Add Blog</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/administrator/blog-list/"}><span className="sidebar-mini-hide">Blog List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Plan</span></a>
                                        <ul>
                                            <li>
                                                <NavLink to={"/administrator/manage-website-plan/"}><span className="sidebar-mini-hide">Manage Plan Details</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/administrator/manage-website-plan-price/"}><span className="sidebar-mini-hide">Manage Price Plan</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Busniess</span>
                            </li>
                            <li>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#"><i className="si si-layers"></i><span className="sidebar-mini-hide">Business Settings</span></a>    
                                <ul>
                                    <li>
                                        <a className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Business</span></a>
                                        <ul>
                                            <li>
                                                <NavLink to={"/administrator/add-business/"}><span className="sidebar-mini-hide">Add Business</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/administrator/manage-business/"}><span className="sidebar-mini-hide">Business List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a className="nav-submenu" data-toggle="nav-submenu" href="#"><span className="sidebar-mini-hide">Manage Companies</span></a>
                                        <ul>
                                            <li>
                                                <NavLink to={"/administrator/add-company/"}><span className="sidebar-mini-hide">Add Company</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/administrator/manage-company/"}><span className="sidebar-mini-hide">Company List</span></NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to={"/administrator/manage-business-user/"} eventKey={4}>Manage Business Users</NavLink>
                                    </li>
                                </ul>
                            </li>
                            
                            <li className="nav-main-heading">
                                <span className="sidebar-header-title">Manage Reports</span>
                            </li>
                            <li>
                                <a href={global.webUrl+"administrator/dashboard/"}  className="">
                                    <i className="si si-bar-chart sidebar-nav-icon"></i> <span className="sidebar-mini-hide">Reports</span>
                                </a>
                            </li>
                        </ul>
                        {/* END Sidebar Navigation */}
                </div>
            </div>
        </nav>
            </React.Fragment>
        )
    }
}