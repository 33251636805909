import React, {Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import 'react-confirm-alert/src/react-confirm-alert.css';
import validator from 'validator';
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

let _countrySession = localStorage.getItem('vs_UserCountrySession');
let _countryCode = 'IN';
if(_countrySession!=undefined || _countrySession!=null)
{
    _countrySession = JSON.parse(_countrySession);
    _countryCode = _countrySession.countryCode;
}

let _loader = 'glb-ldr-prt active';
let _businessId = '0';
let _company_id = '0';



export default class addCompanyPanel extends Component{
    constructor(props){
        super(props);
        this.cropperRef = React.createRef();
        let _UserSession = localStorage.getItem('vs_UserSession');
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _businessId = _UserSession ? _UserSession.loginId : '0';
        }

        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _company_id = _companySession.company_id;
        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);


        this.state = {
            image: null,
            previewImage: null,
            signaturImage: '',
            imageSignaturePreviewUrl : '',

            buttonTitle:'Add New',
            isLoaded : true,
            businessArray :[],
            stateArray : [],
            categoryArray : [],
            categoryTypeArray : [],

            _countryLang:'',
            _countryReg:'iti__hide',
            _countryFlag:'',
            _countryName:'',
            _countryPh:'',
            _countryId:'0',
            countryArray : [],

            countryCode:_countryCode,
            companyName : '',
            address : '',
            description : '',
            phone : '',
            email:'',
            logo : '',
            imagePreviewUrl : '',
            gstin :'',
            country_register : '',
            country_registerID : 0,
            state : '',
            business_type : '',
            business_category : '',
            businessId : _businessId,
            companyId : 0,
            newImage: "",
            errors: {
                businessId : '',
                companyName : '',
                companyLogo : '',
                address : '',
                country_register:'',
                email:'',
                phone : ''
            },
            modalIsOpen_ImageCrop: false,
            imageUrl: '',
            modalIsOpen_SignImageCrop: false,
            imageSignUrl: ''
        }
    }

    async componentDidMount ()
    {
        /* Get Url Pass Parameter */
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _companyId = _handleParam[1] ? _handleParam[1] : 0;
        /* End Here Url Pass Parameter */
        let _userCountryId = '0';
        if(_companyId > 0){
            let _companyArray = [];
            let _url_GetData = global.adminCompanyDetails+'?companyId='+_companyId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {
                    this.setState({isLoaded:true});
                }
                else
                {
                    if (responseJson.response) {
                        _companyArray = responseJson.response[0];
                        console.log('_companyArray', JSON.stringify(_companyArray))
                        _userCountryId = _companyArray.country_code;
                        this.setState({
                            buttonTitle:'Save',
                            companyName:_companyArray.company_name,
                            companyId:_companyId,
                            address:_companyArray.address,
                            description:_companyArray.description,
                            phone:_companyArray.phone,
                            email:_companyArray.email,
                            gstin:_companyArray.gstin,
                            state:_companyArray.state,
                            business_type:_companyArray.business_type,
                            business_category:_companyArray.business_category,
                            country_register:_companyArray.country_register,
                            country_registerID:_companyArray.country_registerID,
                            imagePreviewUrl:_companyArray.logo_path,
                            imageSignaturePreviewUrl:_companyArray.signature_path,
                            signaturImage:_companyArray.signature_path,
                            isLoaded:true
                        });
                    }
                }
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }

        let _urlCountryData = global.adminCountryList;
        let countryData = await PostData(_urlCountryData,'','GET');
        let _conLang,_conFlag,_conName,_conPh,_conId = '';
        countryData.response.map((conty, i) =>{
            let _name = conty.sortname.toLowerCase();
            let _imgPath = global.FLAG_URL+_name+'.png';
            //alert(_userCountryId+'-----'+_countryCode);
            if(_userCountryId > 0){
                if(_userCountryId == conty.phonecode){
                    _conLang = conty.sortname;
                    _conFlag = _imgPath;
                    _conName=conty.name;
                    _conPh=conty.phonecode;
                    _conId=conty.id;
                }
            }
            else if(_countryCode == conty.sortname){
                _conLang = conty.sortname;
                _conFlag = _imgPath;
                _conName=conty.name;
                _conPh=conty.phonecode;
                _conId=conty.id;
            }
        });

        let _urlData = global.adminBusinessNameList;
        let res = await PostData(_urlData,'','GET');

        if(res.response == null || res.response == undefined){
            window.location.href = global.BASE_URL+'not-found/';
            return false;
        }
        let _businessArray = res.response;

        //,_countryId:_conId
        this.setState({businessArray:_businessArray,countryArray:countryData.response,_countryLang:_conLang,_countryFlag:_conFlag,_countryName:_conName,_countryPh:_conPh,isLoaded:true});
        if(_companyId > 0){
            this.setState({_countryId:_conId});
        }
        this.getStateListData(_conPh);
        if(_companyId > 0){
            this.getStateListDataEdit(this.state.country_register,this.state.country_registerID);
        }
        this.getCategoryListData();
        this.getCategoryTypeListData();
    }

    getCategoryListData(){
        let _catArray = [];
        let _url_GetData = global.businessCategoryList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _catArray = responseJson.response;
                }
                this.setState({categoryArray:_catArray,isLoaded:true});
            }
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    getCategoryTypeListData(){
        let _typeArray = [];
        let _url_GetData = global.businessTypeList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _typeArray = responseJson.response;
                }
                this.setState({categoryTypeArray:_typeArray,isLoaded:true});
            }
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    getStateListData(e){
        let _stateArray = [];

        let _url_GetData = global.adminStateList+'?countryCode='+e;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _stateArray = responseJson.response;
                }
                this.setState({stateArray:_stateArray,isLoaded:true});
            }
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }
    getStateListDataEdit (e,conId){

        let _stateArray = [];

        let _url_GetData = global.adminStateList+'?countryCode='+e+'&countryId='+conId;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {
                this.setState({isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _stateArray = responseJson.response;
                }
                this.setState({stateArray:_stateArray,isLoaded:true});
            }
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});

        switch (name) {
            case 'companyName':
            errors.companyName =
                value.length < 3 ? '*Company Name must be 3 characters long!' : '';
                this.setState({companyName:value});
            break;
            case 'businessId':
                errors.businessId =
                value.length < 0
                ? '*Please select any business!'
                : '';
                this.setState({businessId:value});
            break;
            case 'address':
            errors.address =
                value.length < 3 ? '*Company Address must be 3 characters long!' : '';
                this.setState({address:value});
            break;
            case 'country_register':
            let _dataCountry =  value.split("@");
            errors.country_register =
                value.length < 0 ? '*Select Company registed location!' : '';
                this.setState({country_register:_dataCountry[0],country_registerID:_dataCountry[1]});
                this.getStateListData(_dataCountry[0]);
            break;
            case 'business_category':
                this.setState({business_category:value});
            break;
            case 'business_type':
                this.setState({business_type:value});
            break;
            case 'logo':
                //this.setState({logo:value});
            break;
            case 'gstin':
                this.setState({gstin:value});
            break;
            case 'state':
                this.setState({state:value});
            break;
            case 'phone':
                if (validator.isInt(value)) {
                    errors.phone = '';
                } else {
                    errors.phone = '*Please enter valid phone.';
                }
                this.setState({phone:value});
            break;
            case 'email':
                if (validator.isEmail(value)) {
                    errors.email = '';
                } else {
                    errors.email = '*Please enter valid email-id.';
                }
                this.setState({email:value});
            break;
            case 'description':
                this.setState({description:value});
            break;
            default:
            break;
        }
    }

    /* Image Code */
    onChangeFileUploadHandler=event=>{
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
        this.setState({
            //logo: file,
            //imagePreviewUrl: reader.result,
            imageUrl: reader.result,
            modalIsOpen_ImageCrop: true,
        });
        }
        console.log('file', file);
        reader.readAsDataURL(file);
    }

    /* Signature Image Code */
    onChangeSignFileUploadHandler=event=>{
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            console.log("==reader.result==",reader.result);
        this.setState({
            imageSignUrl: reader.result,
            modalIsOpen_SignImageCrop: true,
        });
        }
        reader.readAsDataURL(file);
    }

    previewImagesListing()
    {
        if(this.state.isLoaded == true)
        {
            let dataList = [];
            let _imageDetails = this.state.imagePreviewUrl;
            if(_imageDetails.length > 9){
                dataList.push(
                    <img style={{width:'35px'}} className="preview" src={_imageDetails} alt={"image-"}  key="13213"/>
                )
            }
            return dataList;
        }
    }

    closeImageCropModal = () => {
        this.setState({modalIsOpen_ImageCrop: false});
        this.setState({imagePreviewUrl: ''});
        this.fileInput.value = "";
    }
    saveCropImage = () => {
        this.setState({modalIsOpen_ImageCrop: false});
        const cropper = this.cropperRef.current?.cropper.getCroppedCanvas({
            width: 200,
            height: 200,
        });
        let data = cropper.toDataURL('image/png');

        cropper.toBlob((blob) => {
            this.setState({logo: blob});
            this.setState({imagePreviewUrl: data});
            let errors = this.state.errors;
            errors['companyLogo'] = "";
            this.setState({errors: errors});
        }, 'image/png');

    }
    closeSignImageCropModal = () => {
        this.setState({modalIsOpen_SignImageCrop: false});
        this.setState({imageSignaturePreviewUrl: ''});
    }
    saveSignCropImage = () => {
        this.setState({modalIsOpen_SignImageCrop: false});
        const cropper = this.cropperRef.current?.cropper.getCroppedCanvas({
            width: 100,
            height: 100,
        });
        let data = cropper.toDataURL('image/png');

        
        cropper.toBlob((blob) => {
            this.setState({signaturImage: blob});
            this.setState({imageSignaturePreviewUrl: data,signaturImage: data});
        }, 'image/png');

    }

    /* End Here*/

    clickToAddCompany = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.companyName == '')
        {
            _isError = true;
            errors['companyName'] = "*Please Enter Company Name";
            this.setState({errors: errors});
        }
        if(this.state.imagePreviewUrl == '')
        {
            _isError = true;
            errors['companyLogo'] = "*Please Select Company Logo";
            this.setState({errors: errors});
        }
        if(this.state.email == '')
        {
            _isError = true;
            errors['email'] = "*Please Enter Company Email";
            this.setState({errors: errors});
        }
        if(this.state.phone == '')
        {
            _isError = true;
            errors['phone'] = "*Please Enter Company Contact Number";
            this.setState({errors: errors});
        }

        if(this.state.phone.length < 8 || this.state.phone.length >12 )
        {
            _isError = true;
            errors['phone'] = "*Please enter valid company contact no.";
            this.setState({errors: errors});
        }
        if(this.state.address == '')
        {
            _isError = true;
            errors['address'] = "*Please Enter Company Address";
            this.setState({errors: errors});
        }
        if(this.state.businessId == '')
        {
            _isError = true;
            errors['businessId'] = "*Please Select Any Business.";
            this.setState({errors: errors});
        }
        if(this.state.country_register == '')
        {
            _isError = true;
            errors['country_register'] = "*Please Select Company Registered Location.";
            this.setState({errors: errors});
        }

        if(_isError == false){
            if(this.state.companyId > 0){
                this.editCompanyMethodCall();
            }
            else{
                this.addCompanyMethodCall();
            }
        }
    }

    editCompanyMethodCall()
    {
        var companyData = new FormData();
        companyData.append('companyId', this.state.companyId);
        companyData.append('businessId', this.state.businessId);
        companyData.append('company_name', this.state.companyName);
        companyData.append('address', this.state.address);
        companyData.append('description', this.state.description);
        companyData.append('phone', this.state.phone);
        companyData.append('email', this.state.email);
        companyData.append('logo', this.state.logo);
        companyData.append('gstin', this.state.gstin);
        companyData.append('state', this.state.state);
        companyData.append('business_type', this.state.business_type);
        companyData.append('business_category', this.state.business_category);
        companyData.append('countryID', this.state._countryId);
        companyData.append('country_code', this.state._countryPh);
        companyData.append('country_register', this.state.country_register);
        companyData.append('country_registerID', this.state.country_registerID);
        //.append('signaturImage', this.state.imageSignaturePreviewUrl);
        companyData.append('signaturImage', this.state.signaturImage);

        //console.log(this.state.country_register+'==>>=='+this.state.country_registerID+'==>>=='+this.state._countryId+'==>>=='+this.state._countryPh+'==>>=='+this.state.state);return false;
        new Promise((resolve, reject) =>{
            fetch(global.adminEditCompany, {
                method:  'POST',
                body: companyData
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success == true)
                {
                    sendNotification("Success Message 👍",res.message,"success");
                    let _UserSession = localStorage.getItem('vs_UserSession');
                    let _updateLocal = JSON.parse(_UserSession);
                    let _updateCompany = _updateLocal.companies;
                    _updateCompany.filter((companyRow, index) => {
                        if(companyRow.company_id == res.companyData.company_id){
                            companyRow.company_address = res.companyData.company_address;
                            companyRow.gstin = res.companyData.gstin;
                            companyRow.company_contact = res.companyData.company_contact;
                            companyRow.company_name = res.companyData.company_name;
                            companyRow.phone = res.companyData.phone;
                            companyRow.phoneNo = res.companyData.phone;
                            companyRow.company_logo = res.companyData.company_logo;
                            companyRow.company_signature = res.companyData.company_signature;
                            companyRow.country_code = res.companyData.country_code;
                            companyRow.country_short = res.companyData.country_short;
                            companyRow.currency_symbol = res.companyData.currency_symbol;
                            companyRow.currency_code = res.companyData.currency_code;
                            companyRow.country_name = res.companyData.country_name;
                        }
                    });
                    _updateLocal.companies = _updateCompany;
                    localStorage.removeItem('vs_UserSession');
                    localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));

                    let _companyActive = localStorage.getItem('vs_UserCompanySession');
                    _companyActive = JSON.parse(_companyActive);

                    if(_companyActive.company_id == res.companyData.company_id)
                    {
                        _companyActive.company_address = res.companyData.company_address;
                        _companyActive.gstin = res.companyData.gstin;
                        _companyActive.company_contact = res.companyData.company_contact;
                        _companyActive.company_name = res.companyData.company_name;
                        _companyActive.phone = res.companyData.phone;
                        _companyActive.phoneNo = res.companyData.phone;
                        _companyActive.company_logo = res.companyData.company_logo;
                        _companyActive.company_signature = res.companyData.company_signature;
                        _companyActive.country_code = res.companyData.country_code;
                        _companyActive.country_short = res.companyData.country_short;
                        _companyActive.currency_symbol = res.companyData.currency_symbol;
                        _companyActive.currency_code = res.companyData.currency_code;
                        _companyActive.country_name = res.companyData.country_name;
                    }
                    localStorage.removeItem('vs_UserCompanySession');
                    localStorage.setItem('vs_UserCompanySession', JSON.stringify(_companyActive));

                    setTimeout(
                        () => {
                            window.location.href = global.webUrl+'business/manage-company/';
                        },
                        Math.floor(Math.random() * 3000) + 1
                    )
                }
                else
                {
                    sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['companyName'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    addCompanyMethodCall()
    {
        var companyData = new FormData();
        companyData.append('businessId', this.state.businessId);
        companyData.append('company_name', this.state.companyName);
        companyData.append('address', this.state.address);
        companyData.append('description', this.state.description);
        companyData.append('phone', this.state.phone);
        companyData.append('email', this.state.email);
        companyData.append('logo', this.state.logo);
        companyData.append('gstin', this.state.gstin);
        companyData.append('state', this.state.state);
        companyData.append('business_type', this.state.business_type);
        companyData.append('business_category', this.state.business_category);
        companyData.append('countryID', this.state._countryId);
        companyData.append('country_code', this.state._countryPh);
        companyData.append('country_register', this.state.country_register);
        companyData.append('country_registerID', this.state.country_registerID);
        //companyData.append('signaturImage', this.state.imageSignaturePreviewUrl);
        companyData.append('signaturImage', this.state.signaturImage);
        
        new Promise((resolve, reject) =>{
            fetch(global.adminAddCompany , {
                method:  'POST',
                body: companyData
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success == true)
                {
                    sendNotification("Success Message 👍",res.message,"success");

                    let _UserSession = localStorage.getItem('vs_UserSession');
                    let _updateLocal = JSON.parse(_UserSession);
                    let _comData = _updateLocal.companies;
                    _comData.push(res.companyData);
                    _updateLocal.companies = _comData;
                    localStorage.removeItem('vs_UserSession');
                    localStorage.setItem('vs_UserSession', JSON.stringify(_updateLocal));

                    setTimeout(
                        () => {
                            window.location.href = global.webUrl+'business/manage-company/';
                        },
                        Math.floor(Math.random() * 5000) + 1
                    )
                }
                else
                {
                    sendNotification("Error Message 😓",res.message,"danger");
                    let errors = this.state.errors;
                    errors['companyName'] = res.message;//'Please enter valid phone no.';
                    this.setState({
                        errors:errors
                    })
                    return;
                }
            })
            .catch((error) => {
                //reject(error);
            });
        });
    }

    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';

                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{conty.phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }

    /* Country Code Dropdown Start Here */
    selectCounties(code,flag,name,e){
        //console.log(code+'==>-C-F-->=='+flag+'==>-F-N->=='+name+'==>N-ID>=='+e);
        this.setState({
            _countryReg:'iti__hide',
            _countryFlag:flag,
            _countryName:name,
            _countryPh:code,
            _countryId:e,
        })
    }

    manageMultiCounties(){
        this.setState({
            _countryReg:'',
        })
    }

    getReadyMobileCode(){
        let data = [];
        if(this.state.isLoaded == true)
        {
            data = this.state.countryArray.map((conty, i) =>{
                let _name = conty.sortname.toLowerCase();
                let _imgPath = global.FLAG_URL+_name+'.png';

                return(
                    <li key={i} className="iti__country iti__standard" id="iti-item-in" onClick={this.selectCounties.bind(this,conty.phonecode,_imgPath,conty.sortname,conty.id)}>
                        <div className="iti__flag-box">
                            <div className="iti__flag" id="itiFlagImg"><img src={_imgPath} /></div>
                        </div>
                        <span className="iti__country-name">{conty.sortname}</span>
                        <span className="iti__dial-code">{conty.phonecode}</span>
                    </li>
                )
            });
        }
        return data;
    }

    onMouseLeaveHandler() {
        this.setState({
            _countryReg:'iti__hide',
        })
    }
    /* Country Code Dropdown End Here */


    /* Crop Image */
    onChangeSignatureFileUploadHandler = event => {
        let reader = new FileReader();
        const imageFile = event.target.files[0];

        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }
        console.log("==imageFile==",imageFile);
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const { naturalWidth: width, naturalHeight: height } = img;
                if (height > 300 || width > 600) {
                    alert("Height and Width must not exceed 600px X 300px.");
                    this.fileInput.value = null;
                    this.setState({ signaturImage: "", imageSignaturePreviewUrl: "",signature:""});
                }
                else{
                    this.setState({ signaturImage: imageFile, imageSignaturePreviewUrl: reader.result});
                }
            };
            img.onerror = () => {
                alert("Invalid image content.");
                return false;
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
    }

    fileChangedHandler(event) {
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          try {
            Resizer.imageFileResizer(
              event.target.files[0],
              300,
              100,
              "JPEG",
              100,
              0,
              (uri) => {
                this.setState({ signaturImage: uri });
              },
              "base64",
              300,
              100
            );
          } catch (err) {
            console.log(err);
          }
        }
      }

    previewSignatureImagesListing()
    {
        if(this.state.isLoaded == true)
        {
            let dataList = [];
            let _imageDetails = this.state.imageSignaturePreviewUrl;
            if(_imageDetails.length > 9){
                dataList.push(
                    <img style={{width:'120px'}} className="preview" src={_imageDetails} alt={"image-"}  key="13213"/>
                )
            }
            return dataList;
        }
    }
    /* Crop Image */

    /*New Crop by AK */
    onChangeImg(evt) {
        this.setState({
            image: evt.target.files[0]
        })
    }

    async crop() {
        let image = await this.refs.crop.cropImage()
        this.setState({
            previewUrl: window.URL.createObjectURL(image)
        })
    }

    clear() {
        this.refs.file.value = null
        this.setState({
            previewUrl: null,
            image: null
        })
    }

    imageLoaded(img) {
        if (img.naturalWidth && img.naturalWidth < 262 &&
            img.naturalHeight && img.naturalHeight < 147) {
            this.crop()
        }
    }
    /*End Crop by AK */
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }

        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Add / Edit Company<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"business/dashboard/"}>Dashboard</a>
                        <a className="breadcrumb-item" href={global.webUrl+"business/manage-company/"}>View Company List</a>
                        <span className="breadcrumb-item active">Add / Edit Company</span>
                    </nav>

                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Company</h3>
                        </div>
                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="text" required="required" className="form-control" id="companyName" name="companyName" value={this.state.companyName} placeholder="Company Name Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Company Name <span className="text-danger">*</span></label>
                                            <span className="vs_error_txt">{errors.companyName.length > 0 && <span className='error'>{errors.companyName}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input ref={ref=> this.fileInput = ref} type="file" required={true} style={{width:'60%',display: 'inline-block'}} placeholder="file" autoComplete="false" className="form-control" id="logo" name="logo" onChange={this.onChangeFileUploadHandler} accept='.png, .jpg, .jpeg' aria-required={true}/>
                                            {this.previewImagesListing()}
                                            <label htmlFor="login-password">Company Logo <span className="text-danger">*</span></label>
                                            <span className="logo_error">{errors.companyLogo.length > 0 && <span className='error'>{errors.companyLogo}</span>}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">

                                    <div className="col-6">
                                        <div className="form-material open">
                                            <input type="email" required="required" placeholder="abc@xxxx.com" autoComplete="false" value={this.state.email} className="form-control" id="email" name="email" onChange={this.handleChange} />
                                            <label htmlFor="login-password">Company Email <span className="text-danger">*</span></label>
                                            <div id="email-error" className="animated fadeInDown">{errors.email.length > 0 && <span className='error'>{errors.email}</span>}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                    <div className="form-material open">
                                        <div className="iti__flag-container" onMouseEnter={this.manageMultiCounties.bind(this,'I')} onMouseLeave={this.onMouseLeaveHandler.bind(this,'I')}>
                                                <div className="iti__selected-flag" role="combobox" aria-owns="country-listbox" tabIndex="0" title={this.state._countryName}>
                                                    <div className="iti__flag" id="itiFlagImg"><img src={this.state._countryFlag} /></div>
                                                    <div className="iti__selected-dial-code">{this.state._countryPh}</div>
                                                    <div className="iti__arrow"><i className="si si-arrow-down"></i></div>
                                                </div>

                                                <ul className={this.state._countryReg +" iti__country-list iti__country-list--dropup"} id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-ad">
                                                    {this.getReadyMobileCode()}
                                                </ul>
                                            </div>
                                            <input type="text" style={{display: 'inline-block',width:'75%'}}  required="required" placeholder="9876XXXXXX" autoComplete="false" value={this.state.phone} className="form-control" id="phone" name="phone" onChange={this.handleChange}  maxLength="12"/>
                                            <label htmlFor="login-password">Company Contact Number <span className="text-danger">*</span></label>
                                            <div id="phone-error" className="animated fadeInDown">{errors.phone.length > 0 && <span className='error'>{errors.phone}</span>}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Company Address Here" autoComplete="false" value={this.state.address} className="form-control" id="address" name="address" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Company Address <span className="text-danger">*</span></label>
                                        </div>
                                        <div id="address-error" className="animated fadeInDown">{errors.address.length > 0 && <span className='error'>{errors.address}</span>}</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <div className="form-material open">
                                            <input type="text" required="required" placeholder="Company Description Here" autoComplete="false" value={this.state.description} className="form-control" id="description" name="description" onChange={this.handleChange} maxLength="160"/>
                                            <label htmlFor="login-password">Company Description </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <select className="form-control" id="business_type" name="business_type" onChange={this.handleChange}>
                                                <option value=""> Select Business Type</option>
                                                {
                                                    this.state.categoryTypeArray.map((item,index) => {
                                                        let _sel = '';
                                                        if(this.state.business_type == item.id){_sel = 'selected';}
                                                        return(
                                                        <option key={index} value={item.id} selected={_sel}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="login-password">Business Type </label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <select className="form-control" id="business_category" name="business_category" onChange={this.handleChange}>
                                                <option value=""> Select Business Category</option>
                                                {
                                                    this.state.categoryArray.map((item,index) => {
                                                        let _sel = '';
                                                        if(this.state.business_category == item.id){_sel = 'selected';}
                                                        return(
                                                        <option key={index} value={item.id} selected={_sel}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="login-password">Business Category </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <input type="text" required="required" value={this.state.gstin} placeholder="Enter GSTIN Number" autoComplete="false" className="form-control" id="gstin" name="gstin" onChange={this.handleChange}/>
                                            <label htmlFor="login-password">Company GSTIN Number</label>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-material open">
                                            <select className="form-control" id="country_register" name="country_register" onChange={this.handleChange}>
                                                <option value=""> Select Registered Location</option>
                                                
                                                {
                                                    this.state.countryArray.map((item,index) => {
                                                        {console.log('item.id', item.id)}
                                                        {console.log('item.country_registerID', item.country_registerID)}
                                                        let _sel = '';
                                                        if(this.state.country_registerID == item.id){_sel = 'selected';}
                                                        return(
                                                        <option key={index} value={item.phonecode+"@"+item.id} selected={_sel} autoComplete='none'>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="login-password">Registered Country <span className="text-danger">*</span></label>
                                            <span className="vs_error_txt">{errors.country_register.length > 0 && <span className='error'>{errors.country_register}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <select className="form-control" id="state" name="state" onChange={this.handleChange}>
                                                <option value=""> Select State</option>
                                                {
                                                    this.state.stateArray.map((item,index) => {
                                                        let _selState = '';
                                                        if(this.state.state == item.name){_selState = 'selected';}
                                                        return(
                                                        <option key={index} value={item.name} selected={_selState}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="login-password">State </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            {/* <input ref={(input) => { this.fileInput = input; }} type="file" required="required" style={{width:'60%',display: 'inline-block'}} placeholder="file" autoComplete="false" className="form-control" id="signature" name="signature" onChange={this.onChangeSignatureFileUploadHandler}/>
                                            {this.previewSignatureImagesListing()}
                                            <label htmlFor="login-password">Company Signature (Size 600px * 300px)</label> */}

                                            <input type="file"  required="required" style={{width:'60%',display: 'inline-block'}} placeholder="file" autoComplete="false" className="form-control" id="signature" name="signature" onChange={this.onChangeSignFileUploadHandler} />
                                            {this.state.signaturImage.length >7 &&
                                            <img src={this.state.signaturImage} alt="Company Signature" style={{width : '15%'}}/>
                                            }
                                            <label htmlFor="login-password">Company Signature</label>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                            <QuickCropImage />
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="form-group row">
                                    <div className="col-6">
                                        <div className="form-material open">
                                        <input ref='file' type='file' onChange={this.onChange} />

                                            {

                                                this.state.image &&

                                                <div>
                                                    <Cropper
                                                        ref='crop'
                                                        image={this.state.image}
                                                        width={100}
                                                        height={80}
                                                        onImageLoaded={this.imageLoaded}
                                                    />
                                                    <button onClick={this.crop}>Crop</button>
                                                    <button onClick={this.clear}>Clear</button>
                                                </div>

                                            }
                                            {
                                                this.state.previewUrl &&

                                                <img src={this.state.previewUrl} />
                                            }
                                        </div>
                                    </div>
                                </div> */}

                                <div className="form-group row">
                                    <div className="col-6">
                                        <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddCompany}>
                                            <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} Company
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modalIsOpen_ImageCrop} ariaHideApp={false} size="sm" onRequestClose={this.closeImageCropModal}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                    <div style={{backgroundColor: 'white', alignSelf: 'center', width: '700px', margin: 20}}>
                        <div >
                            <h3>Company Logo</h3>
                            <Cropper
                                ref={this.cropperRef}
                                initialAspectRatio={1}
                                preview=".img-preview"
                                src={this.state.imageUrl}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                            />
                        </div>
                        <div className="col-6" style={{alignItems: 'flex-end', marginTop: 20}}>
                            <button type="submit" className="btn btn-alt-primary" onClick={this.closeImageCropModal}>
                                 Close
                            </button>
                            <button type="submit" style={{marginLeft: 10}} className="btn btn-alt-primary" onClick={this.saveCropImage}>
                                 Save
                            </button>
                        </div>
                    </div>
                        </div>
                    </div>
                </Modal>
                
                <Modal isOpen={this.state.modalIsOpen_SignImageCrop} ariaHideApp={false} size="sm" onRequestClose={this.closeSignImageCropModal}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                    <div style={{backgroundColor: 'white', alignSelf: 'center', width: '500px', margin: 20}}>
                        <div >
                            <h3>Signature Image</h3>
                            <Cropper
                                ref={this.cropperRef}
                                initialAspectRatio={1}
                                preview=".img-preview"
                                src={this.state.imageSignUrl}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                            />
                        </div>
                        <div className="col-6" style={{alignItems: 'flex-end', marginTop: 20}}>
                            <button type="submit" className="btn btn-alt-primary" onClick={this.closeSignImageCropModal}>
                                 Close
                            </button>
                            <button type="submit" style={{marginLeft: 10}} className="btn btn-alt-primary" onClick={this.saveSignCropImage}>
                                 Save
                            </button>
                        </div>
                    </div>
                        </div>
                    </div>
                </Modal>
                
            </React.Fragment>
        )
    }
}
