import React, { Component } from "react";
import { PaginationControl } from 'react-bootstrap-pagination-control';

//--Transction Reports include
import TransactionSaleReport from "./childPages/saleReport";
import TransactionPurchaseReport from "./childPages/purchaseReport";
import TransactionAllTransactionReport from "./childPages/allTransaction";
import TransactionBillWiseProfitReportReport from "./childPages/billWiseProfit";
import TransactionBayBookReport from "./childPages/dayBookReport";
import TransactionProfitLossReport from "./childPages/profitLossReport";
import TransactionCashflowReport from "./childPages/cashflowReport";
import TransactionSaleAgingReport from "./childPages/saleAging";
import TransactionBalanceSheetReport from "./childPages/balanceSheetReport";
import PartyStatement from "./childPages/partyStatement";
import PartyProfitLoss from "./childPages/partyProfitLoss";
import AllPartyReport from "./childPages/allPartyReport";
import PartyItemReport from "./childPages/partyItemReport";
import SalePurchasePartyReport from "./childPages/salePurchasePartyReport";
import StockSummeryReport from "./childPages/stockSummeryReport";
import ItemReportByParty from "./childPages/itemReportByParty";
import ItemProfitLossReport from "./childPages/itemProfitLossReport";
import LowStockSummeryReport from "./childPages/lowStockSummeryReport";
import ItemDetailReport from "./childPages/itemDetailReport";
import StockDetailReport from "./childPages/stockDetailReport";
import StockSaleItemReport from "./childPages/stockSaleItemReport";
import StockSummaryItemReport from "./childPages/stockSummaryItemReport";
import ItemBatchReport from "./childPages/itemBatchReport";
import ItemSerialReport from "./childPages/itemSerialReport";
import ItemWiseDiscount from "./childPages/itemWiseDiscount";
import BankStatement from "./childPages/bankStatement";
import DiscountReport from "./childPages/discountReport";
import ExpenseCategoryReport from "./childPages/expenseCategoryReport";
import ExpenseItemReport from "./childPages/expenseItemReport";
import SalePurchaseOrderReport from "./childPages/salePurchaseOrderReport";
import SalePurchaseOrderItemReport from "./childPages/salePurchaseOrderItemReport";
import LoanStatement from "./childPages/loanStatement";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}

let _loader = "glb-ldr-prt active";

export default class reportListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      isActiveType: "TRANSACTION_SALE_REPORT",
      errors: {},
    };
  }

  async componentDidMount() {}

  loadDataTableDirectory(type) {
    //alert(this.state.isActiveType+'----'+type);
    if (this.state.isActiveType == "TRANSACTION_SALE_REPORT") {
      return <TransactionSaleReport />;
    } else if (this.state.isActiveType == "TRANSACTION_PURCHASE_REPORT") {
      return <TransactionPurchaseReport />;
    } else if (this.state.isActiveType == "TRANSACTION_ALL_TRANSACTION") {
      return <TransactionAllTransactionReport />;
    } else if (this.state.isActiveType == "TRANSACTION_BILL_WISE_PROFIT") {
      return <TransactionBillWiseProfitReportReport />;
    } else if (this.state.isActiveType == "TRANSACTION_DAY_BOOK") {
      return <TransactionBayBookReport />;
    } else if (this.state.isActiveType == "TRANSACTION_PROFIT_LOSS") {
      return <TransactionProfitLossReport />;
    } else if (this.state.isActiveType == "TRANSACTION_CASHFLOW") {
      return <TransactionCashflowReport />;
    }else if (this.state.isActiveType == "TRANSACTION_SALEAGING") {
      return <TransactionSaleAgingReport />;
    }    
    else if (this.state.isActiveType == "TRANSACTION_BALANCESHEET") {
      return <TransactionBalanceSheetReport />;
    } else if (this.state.isActiveType == "PARTY_PARTY_STATEMENT") {
      return <PartyStatement />;
    } else if (this.state.isActiveType == "PARTY_PROFIT_LOSS") {
      return <PartyProfitLoss />;
    } else if (this.state.isActiveType == "PARTY_PARTY_REPORT") {
      return <AllPartyReport />;
    } else if (this.state.isActiveType == "PARTY_ITEM_REPORT") {
      return <PartyItemReport />;
    } else if (this.state.isActiveType == "PARTY_SALE_PURCHASE") {
      return <SalePurchasePartyReport />;
    } else if (this.state.isActiveType == "STOCK_SUMMARY_REPORT") {
      return <StockSummeryReport />;
    } else if (this.state.isActiveType == "STOCK_ITEM_PARTY_REPORT") {
      return <ItemReportByParty />;
    } else if (this.state.isActiveType == "STOCK_ITEM_PROFIT_LOSS_REPORT") {
      return <ItemProfitLossReport />;
    } else if (this.state.isActiveType == "STOCK_LOW_SUMMARY_REPORT") {
      return <LowStockSummeryReport />;
    } else if (this.state.isActiveType == "STOCK_ITEM_DETAIL_REPORT") {
      return <ItemDetailReport />;
    } else if (this.state.isActiveType == "STOCK_DAILY_REPORT") {
      return <StockDetailReport />;
    } else if (this.state.isActiveType == "STOCK_SALE_ITEM_REPORT") {
      return <StockSaleItemReport />;
    } else if (this.state.isActiveType == "STOCK_SUMMARY_ITEM_REPORT") {
      return <StockSummaryItemReport />;
    } else if (this.state.isActiveType == "STOCK_BATCH_REPORT") {
      return <ItemBatchReport />;
    } else if (this.state.isActiveType == "STOCK_SERIAL_REPORT") {
      return <ItemSerialReport />;
    } else if (this.state.isActiveType == "STOCK_ITEM_DISCOUNT") {
      return <ItemWiseDiscount />;
    } else if (this.state.isActiveType == "BUSINESS_BANK_STATEMENT") {
      return <BankStatement />;
    } else if (this.state.isActiveType == "BUSINESS_DISCOUNT_REPORT") {
      return <DiscountReport />;
    } else if (this.state.isActiveType == "EXPENSE_CATEGORY_REPORT") {
      return <ExpenseCategoryReport />;
    } else if (this.state.isActiveType == "EXPENSE_ITEM_REPORT") {
      return <ExpenseItemReport />;
    } else if (this.state.isActiveType == "ORDER_TRANSACTION_REPORT") {
      return <SalePurchaseOrderReport />;
    } else if (this.state.isActiveType == "ORDER_ITEM_REPORT") {
      return <SalePurchaseOrderItemReport />;
    } else if (this.state.isActiveType == "LOAN_STATEMENT") {
      return <LoanStatement />;
    }
  }

  clickToActiveRow(type) {
    this.setState({
      isActiveType: type,
    });

    if (type!="") {
      this.loadDataTableDirectory(type);
    }
  }

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Reports
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">Manage Reports</span>
          </nav>

          <div className="row">
            <div className="col-md-2">
              <div className="block">
                <div className="block pull-r-l">
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="1000px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <tbody id="sideTable" style={{ fontSize: "12px" }}>
                      <div>
                        <h6># Transaction</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType ==
                              "TRANSACTION_SALE_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_SALE_REPORT"
                            )}
                          >
                            Sale Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "TRANSACTION_PURCHASE_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_PURCHASE_REPORT"
                            )}
                          >
                            Purchase Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "TRANSACTION_DAY_BOOK"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_DAY_BOOK"
                            )}
                          >
                            Day Book
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "TRANSACTION_ALL_TRANSACTION"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_ALL_TRANSACTION"
                            )}
                          >
                            All Transactions
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "TRANSACTION_BILL_WISE_PROFIT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_BILL_WISE_PROFIT"
                            )}
                          >
                            Bill Wise Profit
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "TRANSACTION_PROFIT_LOSS"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_PROFIT_LOSS"
                            )}
                          >
                            Profit & Loss
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "TRANSACTION_CASHFLOW"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_CASHFLOW"
                            )}
                          >
                            Cashflow
                          </li>
                          
                          <li
                            className={this.state.isActiveType == "TRANSACTION_SALEAGING" ? "activeRow" : "simpleRow"}
                            onClick={this.clickToActiveRow.bind(this,"TRANSACTION_SALEAGING")}
                          >
                            Sale Aging
                          </li>
                          
                          <li
                            className={
                              this.state.isActiveType ==
                              "TRANSACTION_BALANCESHEET"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "TRANSACTION_BALANCESHEET"
                            )}
                          >
                            Balance Sheet
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <h6># Party Reports</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType == "PARTY_PARTY_STATEMENT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "PARTY_PARTY_STATEMENT"
                            )}
                          >
                            Party Statement
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "PARTY_PROFIT_LOSS"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "PARTY_PROFIT_LOSS"
                            )}
                          >
                            Party Wise Profit & Loss
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "PARTY_PARTY_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "PARTY_PARTY_REPORT"
                            )}
                          >
                            All Party Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "PARTY_ITEM_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "PARTY_ITEM_REPORT"
                            )}
                          >
                            Party Report by Items
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "PARTY_SALE_PURCHASE"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "PARTY_SALE_PURCHASE"
                            )}
                          >
                            Sale Purchase by Party
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <h6># Item/Stock Reports</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType == "STOCK_SUMMARY_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_SUMMARY_REPORT"
                            )}
                          >
                            Stock Summary Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "STOCK_ITEM_PARTY_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_ITEM_PARTY_REPORT"
                            )}
                          >
                            Item Report by Party
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "STOCK_ITEM_PROFIT_LOSS_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_ITEM_PROFIT_LOSS_REPORT"
                            )}
                          >
                            Item Wise Profit & Loss
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "STOCK_LOW_SUMMARY_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_LOW_SUMMARY_REPORT"
                            )}
                          >
                            Low Stock Summary Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "STOCK_ITEM_DETAIL_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_ITEM_DETAIL_REPORT"
                            )}
                          >
                            Item Detail Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "STOCK_DAILY_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_DAILY_REPORT"
                            )}
                          >
                            Stock Detail Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "STOCK_SALE_ITEM_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_SALE_ITEM_REPORT"
                            )}
                          >
                            Sale/Purchase By Item Category
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "STOCK_SUMMARY_ITEM_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_SUMMARY_ITEM_REPORT"
                            )}
                          >
                            Stock Summary By Item Category
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "STOCK_BATCH_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_BATCH_REPORT"
                            )}
                          >
                            Item Batch Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "STOCK_SERIAL_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_SERIAL_REPORT"
                            )}
                          >
                            Item Serial Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "STOCK_ITEM_DISCOUNT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "STOCK_ITEM_DISCOUNT"
                            )}
                          >
                            Item Wise Discount
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <h6># Business Status</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType ==
                              "BUSINESS_BANK_STATEMENT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "BUSINESS_BANK_STATEMENT"
                            )}
                          >
                            Bank Statement
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "BUSINESS_DISCOUNT_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "BUSINESS_DISCOUNT_REPORT"
                            )}
                          >
                            Discount Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "BUSINESS_TAX_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "BUSINESS_TAX_REPORT"
                            )}
                          >
                            Tax Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "BUSINESS_TAXRATE_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "BUSINESS_TAXRATE_REPORT"
                            )}
                          >
                            Tax Rate Report
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <h6># Expense Report</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType ==
                              "EXPENSE_TRANSACTION_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "EXPENSE_TRANSACTION_REPORT"
                            )}
                          >
                            Expense
                          </li>
                          <li
                            className={
                              this.state.isActiveType ==
                              "EXPENSE_CATEGORY_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "EXPENSE_CATEGORY_REPORT"
                            )}
                          >
                            Expense Category Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "EXPENSE_ITEM_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "EXPENSE_ITEM_REPORT"
                            )}
                          >
                            Expense Item Report
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <h6># Sale / Purchase Order Report</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType ==
                              "ORDER_TRANSACTION_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "ORDER_TRANSACTION_REPORT"
                            )}
                          >
                            Sale/Purchase Order Transaction Report
                          </li>
                          <li
                            className={
                              this.state.isActiveType == "ORDER_ITEM_REPORT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "ORDER_ITEM_REPORT"
                            )}
                          >
                            Sale/Purchase Order Item Report
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <h6># Loan Accounts</h6>
                        <ul className="list list-activity" id="reportList">
                          <li
                            className={
                              this.state.isActiveType == "LOAN_STATEMENT"
                                ? "activeRow"
                                : "simpleRow"
                            }
                            onClick={this.clickToActiveRow.bind(
                              this,
                              "LOAN_STATEMENT"
                            )}
                          >
                            Loan Statement
                          </li>
                        </ul>
                      </div>
                    </tbody>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className="block">
                <div className="row items-push">
                  {this.loadDataTableDirectory()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
