import React from "react";
import PropTypes from "prop-types";
import "./toggleSwitch.css";

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitch = ({
    id,
    name,
    txtLeft,
    txtRight,
    checked,
    onChange,
    optionLabels,
    small,
    disabled
}) => {
    function handleKeyPress(e) {
        if (e.keyCode!=32) return;
        e.preventDefault();
        onChange(!checked);
    }

    return (
        <div className="toggleArea">
        <span className={checked ? "toggle-txtName" : "toggle-txtName toggleActiveTxt"}>{txtLeft} </span>
        <span className={"toggle-switch" + (small ? " small-switch" : "")}>
        <input
            type="checkbox"
            name={name}
            className="toggle-switch-checkbox"
            id={id}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
        />
        {id ? (
            <label
            className="toggle-switch-label"
            tabIndex={disabled ? -1 : 1}
            onKeyDown={(e) => handleKeyPress(e)}
            htmlFor={id}
            >
            <span
                className={
                disabled
                    ? "toggle-switch-inner toggle-switch-disabled"
                    : "toggle-switch-inner"
                }
                data-yes={txtLeft}
                data-no={txtRight}
                tabIndex={-1}
            />
            <span
                className={
                disabled
                    ? "toggle-switch-switch toggle-switch-disabled"
                    : "toggle-switch-switch"
                }
                tabIndex={-1}
            />
            </label>
        ) : null}
        </span>
        <span className={checked ? "toggle-txtName toggleActiveTxt" : "toggle-txtName"}>{txtRight}</span>
        </div>
    );
};

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ["Yes", "No"]
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ToggleSwitch;
