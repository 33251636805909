import React,{Component} from 'react';
import {PostData} from '../../service/postData';
import {sendNotification} from '../../_notificationSettings/notificationPanel';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

export default class FooterPage extends Component{
    constructor(props){
        super(props);
        this.state={
            name:'',
            email:'',
            contact:'',
            message: '',
            businessname: '',
            mobile_for_download_link: '',
            visibleSendLink: false
        }
    }

    //componentDidMount(){}


    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                this.setState({ name: value });
                break;
            case 'email':
                this.setState({ email: value });
                break;
            case 'contact':
                if (/^\d*$/.test(value)) {
                    if (value.length >= 0 && value.length <= 10) {
                        this.setState({ contact: value });
                    }
                }
                break;
            case 'businessname':
                this.setState({ businessname: value })
                break;
            case 'message':
                this.setState({ message: value })
                break;
            case 'mobile_for_download_link':
                if (/^\d*$/.test(value)) {
                    if (value.length >= 0 && value.length <= 10) {
                        this.setState({ mobile_for_download_link: value });
                    }
                }
                break;
            default:
                break;
        }
    }


      clickToSubmitEnqury = (event) => {
        event.preventDefault();
        var preferredmode = document.getElementsByName('preferredmode');
        var preferredmodeValue = '';
        for (var i = 0; i < preferredmode.length; i++) {
            if (preferredmode[i].checked)
                preferredmodeValue = preferredmode[i].value
        }
        
        const regex = /^\d{10}$/;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(this.state.name == '')
        {
            alert("Name must be 3 characters long!");
        }else if(!(this.state.email && isValidEmail.test(this.state.email))){
            alert("Email-Id is invalid.");
        }else if(!regex.test(this.state.contact)){
            alert('Mobile number is invalid.')
        }else if(this.state.businessname == "") {
            alert("Please Enter Business Name.");
        }else if(preferredmodeValue == '') {
            alert("Please Select Preferred Mode Of Call.");
        }else if(this.state.message == '') {
            alert("Please enter your message.");
        }else{
            this.getFormSubmit();
        }
    }

    getFormSubmit()
    {
        const requestData = JSON.stringify({
            name: this.state.name,
            contact: this.state.contact,
            email: this.state.email,
            pageSubject:'Enquiry From Become a Vyavsay Distributor - Partner With Us Page',
        });
        
        PostData(global.webistePageGetTouchForm, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                this.setState({name: '',contact: '',email: '',message: '', businessname: ''});
                
                var preferredmode = document.getElementsByName('preferredmode');
                for (var i = 0; i < preferredmode.length; i++) {
                        preferredmode[i].checked = false
                }
                sendNotification("Success Message 👍","Request send successfully.","success");
            }
            else
            {
                sendNotification("Error Message 😓","Some error occured. Please try again.","danger");
                return;
            }
        });
    }

    getDownloadLink = (event) => {
        event.preventDefault();
        if(this.state.mobile_for_download_link === ''){
            alert('Please enter your mobile number!')
        }else{
            this.toggleSendLinkDialog();
        }
    }

    toggleSendLinkDialog = () => {
        this.setState({visibleSendLink: !this.state.visibleSendLink})
    }

    render(){
        return (
            <React.Fragment>
                <section className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="space2">
                                    <img src={global.webUrl+"websiteFilesFolder/img/logo.png"} alt="" title="" />
                                </div>
                                <div>
                                    <div className="icon">
                                        <span><i className="fas fa-map-marker-alt"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>
                                            <a style={{color: '#000'}}  target="_blank" href="http://maps.google.com/?q=HSR Layout, Bengaluru, Karnataka 560102">24th, 2 & 3 floor, 150/2 Enzyme Diamond, 7th Cross Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</a>
                                        </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div>
                                    <div className="icon">
                                        <span><i className="fas fa-phone-alt"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>
                                            <a style={{color: '#000'}} href="tel:+91-9333-911-911">+91-9333-911-911</a><br/>
                                            (Whatsapp) <a style={{color: '#000'}} href="whatsapp://send?abid=916366827420">+91-6366-827-420</a>
                                        </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div>
                                    <div className="icon">
                                        <span><i className="fas fa-envelope"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>
                                            <b>SUPPORT</b><br/>
                                            <a style={{color: '#000'}} class="mailto" href="mailto:contact@vyavsaay.in">contact@vyavsaay.in</a><br/>
                                            <b>MARKETING & COLLABORATION</b><br/>
                                            <a style={{color: '#000'}} class="mailto" href="mailto:debraj@vyavsaay.in">debraj@vyavsaay.in</a>
                                        </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <h2 className="txt-style-5">Quick Links</h2>
                                <span className="line line1"></span>
                                <ul className="futer">
                                    <li><a href={global.webUrl}>Home</a></li>
                                    <li><a href={global.webUrl+"about-us/"}>About Us</a></li>
                                    <li><a href={global.webUrl+"pricing/"}>Pricing</a></li>
                                    <li><a href={global.webUrl+"faq/"}>FAQ</a></li>
                                    <li><a href={global.webUrl+"blogs/"}>Blogs</a></li>
                                    <li><a href={global.webUrl+"privacy-policy/"}>Privacy Policy</a></li>
                                    <li><a href={global.webUrl+"contact/"}>Contact Support</a></li>
                                    <li><a href={global.webUrl+"demo-videos/"}>Demo Videos</a></li>
                                    <li><a href={global.webUrl+"partner-with-us/"}>Partner With Us</a></li>
                                    <li><a href={global.webUrl+"terms-condition/"}>Terms & Conditions</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4">
                                <h2 className="txt-style-5">Get Download Link</h2>
                                <span className="line line1"></span>
                                <form className="banner_form">
                                    <input type="text" name='mobile_for_download_link' onChange={this.handleChange} value={this.state.mobile_for_download_link} placeholder="Enter Mobile No." />
                                    <button onClick={this.getDownloadLink}>Go</button>
                                    <div className="clearfix"></div>
                                </form>
                                <br/><br/>
                                <h2 className="txt-style-5">Follow Us</h2>
                                <span className="line line1"></span>
                                <ul className="social">
                                    <li><a><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a><i className="fab fa-twitter"></i></a></li>
                                    <li><a><i className="fab fa-youtube"></i></a></li>
                                    <li><a><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="copyright">
                    <p>Copyright @ 2024. All Rights Reserved</p>
                </section>
                
                <div className="modal fade" id="myModalB" tabindex="-1" role="dialog" aria-labelledby="myModalBLabel" aria-hidden="true">
                    <div className="modal-center">
                        <div className="modal-dialog .modal-align-center">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myModalBLabel">Request A Demo</h4>
                                    <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true"><i className="fa fa-times"></i></span><span className="sr-only">Close</span>

                                    </button>
                                    

                                </div>
                                <div className="modal-body">
                                <form>
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label for="name">Name</label>
                                                <input type="text" className="form-control" onChange={this.handleChange} name="name" value={this.state.name} placeholder="Enter name" required />
                                            </div>
                                            <div className="col-sm-6">
                                                <label for="email">Email address</label>
                                                <input type="email" className="form-control" onChange={this.handleChange} name="email" value={this.state.email} aria-describedby="emailHelp" placeholder="Enter email" required />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label for="name">Mobile No.</label>
                                                <input type="text" className="form-control" onChange={this.handleChange} value={this.state.contact} name="contact" placeholder="Enter mobile" required />
                                            </div>
                                            <div className="col-sm-6">
                                                <label for="email">Business Name</label>
                                                <input type="text" className="form-control" onChange={this.handleChange} value={this.state.businessname} name="businessname" placeholder="Enter business name" required />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            
                                            <div className="col-sm-12">
                                                
                                            <fieldset>
                                                <label>Preferred Mode Of Call :</label>
                                                <div onClick={() => this.handleChange}>
                                                    <input style={{marginRight: 5}} type="radio" name="preferredmode" value="Skype" />
                                                    <label for="skype">Skype</label>

                                                    <input style={{marginRight: 5, marginLeft: 10}} type="radio" name="preferredmode" value="Zoom" />
                                                    <label for="zoom">Zoom</label>

                                                    <input style={{marginRight: 5, marginLeft: 10}} type="radio" name="preferredmode" value="WhatsApp" />
                                                    <label for="whatsapp">WhatsApp</label>

                                                    <input style={{marginRight: 5, marginLeft: 10}} type="radio" name="preferredmode" value="Google Meet" />
                                                    <label for="googlemeet">Google Meet</label>

                                                    <input style={{marginRight: 5, marginLeft: 10}} type="radio" name="preferredmode" value="MS Teams" />
                                                    <label for="msteams">MS Teams</label>
                                                </div>
                                            </fieldset>
                                            
                                    
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label for="message">Message</label>
                                            <textarea className="form-control" name="message" rows="4" onChange={this.handleChange} value={this.state.message}  placeholder='Enter your message....' required></textarea>
                                        </div>
                                        <div className="mx-auto">
                                        <button type="submit" className="btn btn-primary text-right" onClick={this.clickToSubmitEnqury}>Submit</button></div>
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.visibleSendLink && (
                    <Dialog title={"Success"} onClose={() => this.toggleSendLinkDialog()}>
                    <p
                        style={{
                        margin: "25px",
                        textAlign: "center",
                        }}
                    >
                        Download link send to your mobile number successfully.
                    </p>
                    <DialogActionsBar>
                        <Button type="button" onClick={() => this.toggleSendLinkDialog()}>
                        Ok
                        </Button>
                    </DialogActionsBar>
                    </Dialog>
                )} 
            </React.Fragment>
        );
    }
}