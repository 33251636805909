import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import Modal from "react-modal";
import { numberFormat } from "../../../config/numberFormat";
import QuickAddNewOIItem from "./quickAddOtherIncomeItem";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { parse } from "date-fns";

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let _countryCode = "IN";
let _countryName = "India";
let _taxRateArray = [];
let _settingType = "showall";
let _businessSetting = {};

export default class QuickTransactionOIItemSection extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      _businessId = _UserSession.tokenKey;
      _company_id = _UserSession.company_id;
      _businessSetting = _UserSession.businessSetting;

      if (_businessSetting!=undefined || _businessSetting!=null) {
        if (_businessSetting.PrintOriginalDuplicate == 0) {
          _settingType = null;
        }
      }
    }
    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      _countryCode = _companySession.country_code;
      _countryName = _companySession.country_name;
      _taxRateArray = _companySession.taxDetails;
    }
    this.state = {
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      rows: [],
      fields: [],
      category_id: this.props.category_id ? this.props.category_id : 0,
      category_name: "",

      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      totalAmount: 0,
      totalQuantity: 0,
      transactionId: 0,
      itemRemovePosition: 0,
      handleItemSalePurchasePrice: "",
      handleAddDisable: "",
      modalIsOpenAddNewOIItem: false,
      errors: {
        category_id: "",
        category_name: "",
      },
    };
  }

  _handleClearItem = (event) => {
    this.setState({
      isItemCount: 0,
      _timeReg: "iti__hide",
      itemArray: [],
      itemArrayFilter: [],
      rows: [],
      fields: [],
      category_id: this.props.category_id ? this.props.category_id : 0,
      category_name: "",

      _itemReg: "iti__hide",
      _itemRegIndex: "0",
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      itemPosition: -1,
      totalAmount: 0,
      totalQuantity: 0,
      transactionId: 0,
      itemRemovePosition: 0,
      handleItemSalePurchasePrice: "",
      handleAddDisable: "",
    });
    this.getItemListData(_company_id);
  };
  async componentDidMount() {
    this.getItemListData(_company_id);

    let _transId = this.props.transId;
    if (_transId > 0) {
      _isEditMode = true;
    }
    if (_transId > 0) {
      let _transactionArray = [];
      const requestData = JSON.stringify({
        transaction_id: _transId,
      });

      PostData(global.otherIncomeTransactionDetails, requestData, "POST")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            //this.setState({unitArray:_unitArray,isLoaded:true});
            alert("Transaction details not found in system.");
            setTimeout(() => {
              window.location.href =
                global.webUrl + "business/other-income-list/";
              return;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            if (responseJson.response) {
              _transactionArray = responseJson.response[0];
              let transAmout = _transactionArray.totalSummary;
              let itemsData = _transactionArray.items;

              let _imgPath = "";
              if (_transactionArray.descImage!="") {
                _imgPath =
                  global.LIVE_URL +
                  "uploadDirectory/transaction/" +
                  _transactionArray.descImage;
              }

              let _viaAgainst = 0;
              if (_transactionArray.fromInvoiceNo > 0) {
                _viaAgainst = _transactionArray.fromInvoiceNo;
              } else if (_transactionArray.againstInvoiceNo > 0) {
                _viaAgainst = _transactionArray.againstInvoiceNo;
              }

              this.setState({
                billing_name: _transactionArray.billing_name
                  ? _transactionArray.billing_name
                  : "",
                invoiceTime: _transactionArray.invoice_time,
                category_id: _transactionArray.category_id,
                category_name: _transactionArray.category_name,
                buttonTitle: "Edit Other Income",
                bankId: _transactionArray.bankId,
                bankName: _transactionArray.bankName,
                invoiceDate: moment(_transactionArray.invoice_date).format(
                  "MM/DD/YYYY"
                ),
                businessId: _transactionArray.businessId,
                companyId: _transactionArray.company_id,
                description: _transactionArray.description,
                paymentType: _transactionArray.paymentType,
                paymentRefNo: _transactionArray.paymentRefNo,
                isLoaded: true,
                transactionId: _transId,
                descImage: _transactionArray.descImage,
                imagePreviewUrl: _imgPath,
                isDateUpdate: true,
                party_contact_no: _transactionArray.party_contact_no,
                fields: itemsData,
                totalQuantity: transAmout.totalQty,
                subtotal: transAmout.subtotal,
                totalAmount: transAmout.totalAmount,
                totalAmountOld: transAmout.totalAmount,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
  }
  getItemListData() {
    let _itemsArray = [];
    const requestData = JSON.stringify({
      company_id: this.state.company_id,
    });
    PostData(global.userOIItemList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsArray = responseJson.response;
          }
          this.setState({
            itemArrayFilter: _itemsArray,
            itemArray: _itemsArray,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  /*For Modal open close*/

  openModalHandler(e) {
    if (e == "modalAddOIItem") {
      this.setState({ modalIsOpenAddNewOIItem: true });
    }
  }

  closeModalHandler = (e) => {
    if (e == "modalAddOIItem") {
      this.setState({ modalIsOpenAddNewOIItem: false });
    }
  };

  /*End Modal open close*/

  /* Start Add More Data */
  clickToAddMoreItem = () => {
    let xx = 1;
    this.setState((prevState, props) => {
      xx++;
      let rowHtml = { content: "<tr><td>" + xx + "</td>" };
      return { rows: [...prevState.rows, rowHtml] };
    });
  };

  handleAdd() {
    let errors = this.state.errors;
    if (this.state.category_id == 0) {
      let _isError = true;
      errors["category_name"] = "*Please Select Category Name";
      this.setState({ errors: errors });
    } else if (this.state.handleItemSalePurchasePrice.length > 2) {
      alert("Item price can not be zero");
      return false;
    } else {
      const values = this.state.fields;
      values.push([]);
      let _itemPosition = this.state.itemPosition;
      if (_itemPosition == -1) {
        _itemPosition = 0;
      }
      this.setState({
        fields: values,
        itemPosition: _itemPosition,
        handleAddDisable: "disabled",
        handleItemSalePurchasePrice: "",
      });
      let _totalAmt = this.state.totalAmount;
      let _totalAmountNewSend = this.state.totalAmountNew;
      let _totalAmountOldSend = this.state.totalAmountOld;
      let _totalQuantity = this.state.totalQuantity;
      let handleAddDisable = "Disabled";
      let handleItemSalePurchasePrice = "";
      let _fieldData = this.state.fields;

      this.props.callBackItem(
        _fieldData,
        _totalAmountOldSend,
        _totalAmountNewSend,
        _totalAmt,
        _totalQuantity,
        handleAddDisable,
        handleItemSalePurchasePrice
      );
    }
  }

  handleRemove(i, itemId) {
    const values = this.state.fields;
    let finalQty = 0;
    const newFields = values.filter((itmRow, index) => {
      return index!=i;
    });
    let data = this.state.itemArray;

    data.map((item, index1) => {
      if (item.item_id == itemId) {
        item.normal_quantity = finalQty;

        data[index1].isFilter = false;
      }
    });

    const fieldArray = newFields;
    let _quantity = 0;
    let _totalAmt = "0";
    let _amountDisK_1 = 0;
    let _amountTaxK_1 = 0;
    fieldArray.map((field, index) => {
      let _itemId = field.item_id ? field.item_id : 0;
      if (_itemId > 0) {
        let _obj = {};
        _obj.item_id = field.item_id ? field.item_id : 0;
        _obj.item_name = field.item_name ? field.item_name : "";
        _obj.quantity = field.quantity ? field.quantity : 0;
        _obj.rate = field.rate ? field.rate : 0;
        /*Add by AK */
        _obj.subtotal = field.subtotal ? field.subtotal : 0;
        _obj.total_amount = field.total_amount ? field.total_amount : 0;

        _obj.normal_quantity = finalQty ? finalQty : 0;
        _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);
        /*Add by AK */
        _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
      }
    });

    _totalAmt = parseFloat(_totalAmt) + parseFloat(_amountTaxK_1);

    if (fieldArray.length > 0) {
      _totalAmt = _totalAmt.toFixed(2);

      if (this.state.toggleState == true) {
        this.setState({
          fields: fieldArray,
          totalQuantity: _quantity,
          totalAmount: _totalAmt,
          totalAmountOld: _totalAmt,
          totalAmountNew: _totalAmt,
        });
      } else {
        this.setState({
          fields: fieldArray,
          totalQuantity: _quantity,
          totalAmount: _totalAmt,
          totalAmountOld: _totalAmt,
          totalAmountNew: _totalAmt,
          subtotal: _totalAmt,
        });
      }
      this.setState({ fields: fieldArray, itemArrayFilter: data });

      /*Final Calculation Discount and Tax */

      let _totalAmtCAK = _totalAmt;
      let _totalAmountNewSend = _totalAmt;
      let _totalAmountOldSend = _totalAmt;
      let _totalQuantity = _quantity;
      let handleAddDisable = "";
      if (fieldArray.length >= 1) {
        handleAddDisable = "";
      }
      let _fieldData = fieldArray;
      this.props.callBackItem(
        _fieldData,
        _totalAmountOldSend,
        _totalAmountNewSend,
        _totalAmtCAK,
        _totalQuantity,
        handleAddDisable,
        this.state.handleItemSalePurchasePrice
      );
    } else {
      this.setState({
        totalAmount: 0,
        fields: fieldArray,
        itemArrayFilter: data,
        totalQuantity: 0,
        totalAmountOld: 0,
        totalAmountNew: 0,
        gstAmount: 0,
        totalDiscount: 0,
        subtotal: 0,
      });

      let _totalAmtCAK = 0;
      let _totalAmountNewSend = 0;
      let _totalAmountOldSend = 0;
      let _totalQuantity = 0;
      let handleAddDisable = "Disabled";
      let _fieldData = fieldArray;
      this.props.callBackItem(
        _fieldData,
        _totalAmountOldSend,
        _totalAmountNewSend,
        _totalAmtCAK,
        _totalQuantity,
        handleAddDisable,
        this.state.handleItemSalePurchasePrice
      );
    }

    this.getReadyItemList();
  }

  handleDynamicInputs(i, type, event) {
    let _itemPosition = i;

    let _itemName,
      _itemPrice,
      _itemId,
      _value,
      _itemType = "";

    this.setState({
      transactionWiseTaxId: 0,
      totalTransTaxAmount: 0,
      totalTransDiscountPer: 0,
      totalTransDiscountAmount: 0,
    });

    if (type == "item") {
      _itemPosition = i.index;
      _itemPrice = 0;
      _itemId = i.id;
      _itemName = i.name;

      /*Delete or disabled Data after select */
      const elementUlDelete = document.getElementById(
        "itemListData_" + _itemPosition
      );
      elementUlDelete.remove();
      /*Delete or disabled Data after select */
    } else {
      _value = event.target.value;
    }

    const fieldArray = this.state.fields;
    let _quantity = 0;
    let _totalAmt = 0;

    let handleItemSalePurchasePrice = "";
    let handleAddDisable = "Disabled";
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        if (index == _itemPosition) {
          let _obj = {};

          _obj.item_id = field.item_id ? field.item_id : 0;
          _obj.item_name = field.item_name;
          _obj.quantity = field.quantity ? field.quantity : 0;
          _obj.rate = field.rate ? field.rate : 0;
          _obj.subtotal = field.subtotal ? field.subtotal : 0;
          _obj.total_amount = field.total_amount ? field.total_amount : 0;

          let _isNewItem = false;

          if (type == "item") {
            _isNewItem = true;
            _obj.item_id = _itemId;
            _obj.item_name = _itemName;
            _obj.rate = field.rate ? field.rate : 0;
            _obj.subtotal = field.subtotal ? field.subtotal : 0;
            _obj.total_amount = field.total_amount ? field.total_amount : 0;
            _obj.quantity = 0;
          } else {
          }

          if (type == "quantity") {
            if (_value == "" || _value == "null") {
              _value = 0;
            }
            _obj.quantity = _value;
            handleAddDisable = "";
          }
          if (type == "rate") {
            if (_value == "" || _value == "null") {
              _value = 0;
              handleAddDisable = "disabled";
            }
            if (_value == 0) {
              alert("Price can not be zero");
              handleAddDisable = "disabled";
            }
            _obj.rate = _value;
            //let _totalAmtRate = parseFloat(_value) * parseFloat(_obj.quantity);
            //_obj.total_amount = _totalAmtRate;
            //_obj.subtotal = _totalAmtRate;
            handleAddDisable = "";
          }

          let _totalItemAmount =
            parseFloat(_obj.rate) * parseFloat(_obj.quantity);
          let _itemSubtotal = _totalItemAmount;

          /*Add by AK */
          _obj.subtotal = _totalItemAmount.toFixed(2);
          _obj.total_amount = _itemSubtotal.toFixed(2);
          _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);
          _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
          /*Add by AK */

          /* End Total Calculation */
          fieldArray[_itemPosition] = _obj;
        } else if (_itemPosition == 0 && type == "") {
          let _obj = {};

          _obj.item_id = 0;
          _obj.item_name = "";
          _obj.quantity = 0;
          _obj.rate = 0;
          _obj.subtotal = 0;
          fieldArray[_itemPosition] = _obj;
        } else {
          let _itemId = field.item_id ? field.item_id : 0;
          if (_itemId > 0) {
            let _obj = {};
            _obj.item_id = field.item_id ? field.item_id : 0;
            _obj.item_name = field.item_name;
            _obj.quantity = field.quantity ? field.quantity : "0";
            _obj.rate = field.rate ? field.rate : 0;
            _obj.subtotal = field.subtotal ? field.subtotal : 0;
            _obj.total_amount = field.total_amount ? field.total_amount : 0;
            _totalAmt = parseFloat(_totalAmt) + parseFloat(_obj.total_amount);
            _quantity = parseFloat(_quantity) + parseFloat(_obj.quantity);
          }
        }
      });
      //this.setState({fields:fieldArray,totalQuantity:_quantity,totalAmount:_totalAmt.toFixed(2),subtotal:_totalAmt.toFixed(2),handleAddDisable:handleAddDisable,totalAmountOld:_totalAmt.toFixed(2)});
      this.setState({ fields: fieldArray, handleAddDisable: handleAddDisable });
    }
    let _finalItemsArrayMap = this.state.fields;
    let _finalTotalAmt = 0;
    let _finalTotalQuantity = 0;
    _finalItemsArrayMap.map((fields, ind) => {
      _finalTotalQuantity =
        parseFloat(_finalTotalQuantity) + parseFloat(fields.quantity);
      let lItemTotalAmt = fields.rate * fields.quantity;
      _finalTotalAmt = parseFloat(_finalTotalAmt) + parseFloat(lItemTotalAmt);
    });
    this.setState({
      totalQuantity: _finalTotalQuantity,
      totalAmount: _finalTotalAmt.toFixed(2),
      subtotal: _finalTotalAmt.toFixed(2),
      totalAmountOld: _finalTotalAmt.toFixed(2),
    });

    let _totalQuantity = _finalTotalQuantity; //_quantity;
    let _totalAmountOldSend = _finalTotalAmt.toFixed(2); //_totalAmt.toFixed(2);
    let _totalAmountNewSend = _finalTotalAmt.toFixed(2); //_totalAmt.toFixed(2);
    let _totalAmtCAK = _finalTotalAmt.toFixed(2); //_totalAmt.toFixed(2);

    this.props.callBackItem(
      fieldArray,
      _totalAmountOldSend,
      _totalAmountNewSend,
      _totalAmtCAK,
      _totalQuantity,
      handleAddDisable,
      this.state.handleItemSalePurchasePrice
    );

    this.onMouseLeaveHandlerItem("I", _itemPosition);
    this.onItemFilterName();
  }

  dynamicInputFiled() {
    let _dataSet = [];
    if (this.state.fields.length > 0) {
      let _j = 0;

      let _itemsArrayMap = this.state.fields;
      if (this.state.transactionId > 0) {
        _itemsArrayMap.map((fields, ind) => {
          this.state.itemArrayFilter.map((items, i) => {});
        });
      }
      _itemsArrayMap.map((field, idx) => {
        _j++;
        let _key = idx + 222;
        let _hideMenuItem = "iti__hide";
        if (this.state._itemRegIndex == idx) {
          _hideMenuItem = this.state._itemReg;
        }

        let _itemId = field.item_id ? field.item_id : 0;

        let _readOnlydHere = "";
        _dataSet.push(
          <tr key={_key}>
            <td>
              <button
                type="button"
                onClick={this.props.readOnly ? () => {} : this.handleRemove.bind(this, idx, _itemId)}
                className="btn btn-outline-danger"
              >
                X
              </button>
            </td>
            <td>{_j}</td>
            <td>
              <div className="col-lg-12">
                {/* onMouseEnter={this.manageShowItemFilter.bind(this,'I',idx)} onMouseLeave={this.onMouseLeaveHandlerItem.bind(this,'I',idx)} */}
                <div
                  className="form-material open"
                  id="paddingOff"
                  onMouseLeave={this.onMouseLeaveHandlerItem.bind(
                    this,
                    "I",
                    idx
                  )}
                >
                  <input
                    type="text"
                    className="form-control search-box"
                    onChange={this.props.readOnly ? () => {} : this.onItemFilterTypeName.bind(this, idx)}
                    onClick={this.props.readOnly ? () => {} : this.manageShowItemFilter.bind(this, "I", idx)}
                    onKeyDown={this.props.readOnly ? () => {} : this.manageShowItemFilter.bind(this, "I", idx)}
                    placeholder="Item Name Here..."
                    autoComplete="off"
                    name="item_name"
                    id={"item_name_" + idx}
                    value={field.item_name ? field.item_name : ""}
                    readOnly={this.props.readOnly}
                  />
                  <div id={"itemListData_" + idx}>
                    <ul
                      className={
                        _hideMenuItem +
                        " iti__party-list iti__party-list--dropup"
                      }
                      id="country-listbox"
                      aria-expanded="false"
                      role="listbox"
                      aria-activedescendant="iti-item-ad"
                    >
                      {this.getReadyItemList(idx)}
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  maxLength="100"
                  required="required"
                  className="form-control"
                  id={"_qty_" + idx}
                  name={"_qty_" + idx}
                  value={field.quantity ? field.quantity : ""}
                  readOnly={this.props.readOnly}
                  onChange={this.handleDynamicInputs.bind(
                    this,
                    idx,
                    "quantity"
                  )}
                />
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="number"
                  min="0"
                  required="required"
                  className="form-control"
                  id={"rate_" + idx}
                  name={"rate_" + idx}
                  value={field.rate ? field.rate : ""}
                  readOnly={this.props.readOnly}
                  onChange={this.handleDynamicInputs.bind(this, idx, "rate")}
                />
              </div>
            </td>
            <td>
              <div className="col-lg-12 form-material" id="paddingOff">
                <input
                  type="text"
                  style={{ textAlign: "right" }}
                  readOnly={true}
                  className="form-control"
                  id={"subtotal_" + idx}
                  name={"subtotal_" + idx}
                  value={field.total_amount ? field.total_amount : ""}
                />
              </div>
            </td>
          </tr>
        );
      });
    }
    return _dataSet;
  }
  /* End Add More Data */

  /* Start Item Code */
  onItemFilterName = () => {
    const data = this.state.itemArray;
    let fieldArray = this.state.fields;
    if (fieldArray.length > 0) {
      fieldArray.map((field, index) => {
        data.filter(function (item, index) {
          if (item.item_id == field.item_id) {
            //data[index].isFilter = true;
          }
        });
      });
    }
    this.setState({ itemArrayFilter: data });
    this.getReadyItemList();
  };

  onItemFilterTypeName = (idx, e) => {
    const data = this.state.itemArray;
    let _name = e.target.value;
    const keyword = _name; //_name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val == "string" && val.includes(keyword)
      )
    );

    const fieldArray = this.state.fields;
    fieldArray.map((field, index) => {
      if (index == idx) {
        field.item_name = keyword;
      }
    });

    this.setState({
      itemArrayFilter: filtered,
      item_name: keyword,
      fields: fieldArray,
    });
    this.getReadyItemList();
  };

  manageShowItemFilter(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "", _itemRegIndex: idx });
    }
  }

  onMouseLeaveHandlerItem(key, idx) {
    if (key == "I") {
      this.setState({ _itemReg: "iti__hide", _itemRegIndex: idx });
    }
  }

  manageShowPartyFilterItem(key) {
    if (key == "I") {
      this.setState({ _itemReg: "" });
    }
  }

  getReadyItemList(index) {
    let data = [];
    if (this.state.isLoaded == true) {
      let _itemsArray = this.state.itemArrayFilter;
      if (_itemsArray.length > 0) {
        data = this.state.itemArrayFilter.map((items, i) => {
          let _obj = {};
          _obj.index = index;
          _obj.name = items.item_name;
          _obj.id = items.id;
          return (
            <li
              key={i}
              className="iti__party iti__standard"
              id="iti-item-in"
              onClick={this.handleDynamicInputs.bind(this, _obj, "item")}
            >
              <span className="iti__party-name">{items.item_name}</span>
            </li>
          );
        });
      } else {
        /*return(
                    <li key="22" className="iti__party iti__standard" id="iti-item-in" onClick={this.openModalHandler.bind(this,'modalAddOIItem')}>
                        <span className="iti__party-name"><i className="si si-users"></i> Add New Item</span>
                    </li>
                )*/
      }
    }
    return data;
  }
  /* End Item Code */

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12" id="formSideItem">
            <div className="block block-transparent">
              <div className="block-content" id="formSideItem">
                <table className="table table-bordered table-striped table-vcenter">
                  <thead id="sideTable">
                    <tr>
                      <th style={{ width: "30px" }}></th>
                      <th style={{ width: "30px" }}>#</th>
                      <th style={{ width: "200px" }}>Item</th>

                      <th style={{ width: "50px" }}>Qty</th>

                      <th style={{ width: "150px" }}>Price/unit</th>

                      <th style={{ width: "100px" }}>Amount</th>
                    </tr>
                  </thead>

                  <tbody id="sideTable">
                    {this.dynamicInputFiled()}

                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        {
                          this.props.readOnly ? <></> :
                          <button
                          className="badge badge-primary pull-left"
                          onClick={this.props.readOnly ? () => {} : this.handleAdd.bind(this)}
                          >
                          Add Item
                          </button>
                        }

                        <span className="titleTable">Total</span>
                      </td>

                      <td>
                        <span className="titleTable">
                          {this.state.totalQuantity}
                        </span>
                      </td>

                      <td></td>

                      {this.state.transactionId > 0 && (
                        <td>
                          <span className="titleTable">
                            {this.state.totalQuantity > 0
                              ? this.state.totalAmount
                              : 0}
                          </span>
                        </td>
                      )}
                      {this.state.transactionId == 0 && (
                        <td>
                          <span className="titleTable">
                            {this.state.totalQuantity > 0
                              ? this.state.totalAmountOld
                              : 0}
                          </span>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Edit Other Income Item Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpenAddNewOIItem}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAddOIItem"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Income Item</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAddOIItem"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewOIItem />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(this, "modalAddOIItem")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal End Edit Other Income Item Details*/}
      </React.Fragment>
    );
  }
}
