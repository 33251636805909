import React, { Component } from "react";
import { PostData } from "../../../service/postData"; 
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import QuickAddBankTransfer from "../cashBank/quickBankTransfer";
import QuickAddDepositeMakePayment from "../chequeBank/chequeDeposit";
import Modal from "react-modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { PaginationControl } from "react-bootstrap-pagination-control";

import moment from "moment";
import QuickAddAdjustCash from "../cashBank/quickCashAdjust";
import "bootstrap-daterangepicker/daterangepicker.css";

import ButtonPdf from "../htmlPdfCreation";
import QuickAddDepositeMakePaymentOI from "../chequeBank/chequeDepositOI";

let _UserSession = localStorage.getItem("vs_UserSession");
let _signingId = "0";
let _businessId = "0";
let _companyId = "0";
let _businessSetting = {};

if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessId = _UserSession.tokenKey;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _companyId = _companySession.company_id;
}
let _t = "";
if (_businessSetting.Tran_AddTime == 1) {
  _t = " & Time";  
}
let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20, 
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "sale_type",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Type",
    },
  },
  {
    label: "Party Name",
    field: "party_name",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Party Name",
    },
  },
  {
    label: "Date" + _t,
    field: "invoice_date",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Date",
    },
  },
  {
    label: "Amount",
    field: "total_amount",
    width: 150,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Amount",
    },
  },
  {
    label: "Action",
    field: "actionExtra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class userCashInHandListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEditCashHandId: 0,
      buttonTitle: "Add Sale",
      isLoaded: true,
      transactionArray: [],
      transactionArrayFilter: [],
      businessId: _businessId,
      company_id: _companyId,
      userId: _signingId,
      name: null,
      modalIsOpen_AC: false,
      modalIsOpen_MEP: false,
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),

      paidAmount: 0,
      unpaidAmount: 0,
      totalOutCome: 0,

      dateLabel: "",
      errors: {
        name: "",
      },
    };
  }

  async componentDidMount() {
    this.getCashHandListData();
  }

  /*For Modal open close*/
  openModalEditExpenseCategoryHandler(popId, cashHandId) {
    if (popId == "modalAdjustNewCashHand") {
      this.setState({ modalIsOpen_AC: true, activeEditCashHandId: cashHandId });
    }
  }
  openModalHandler(popId, id, tpe, oid, type) {
    if (popId == "modalAdjustNewCashHand") {
      this.setState({ modalIsOpen_AC: true });
    }else if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: true,
        activeTrasactionId: id,
        activeTypeAccount: tpe,
      });
    }
  }

  openModalTrasacHandler(popId, bankId, popType) {
    if (popId == "modalUploadBankToCash") {
      this.setState({
        modalIsOpen_BankToCash: true,
        bankId: bankId,
        popType: popType,
      });
    }else if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: false,
        activeTrasactionId: 0,
        activeTypeAccount: "Deposit",
      });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustNewCashHand") {
      this.setState({ modalIsOpen_AC: false, activeEditCashHandId: 0,modalIsOpen_MEP: false });
    }
    if (popId == "modalUploadBankToCash") {
      this.setState({ modalIsOpen_BankToCash: false });
    }

    if (popId == "modalMakePaymentDeposit") {
      this.setState({ modalIsOpen_MEP: false });
    }
    if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: false,
        activeTrasactionId: 0,
        activeTypeAccount: "Deposit",
      });
    }
  };
  /*End Modal open close*/
  openModalTrasacEditHandler(popId, accountTrasacId, popType) {
    console.log(popType+"==accountTrasacId==",accountTrasacId);
    if(popType === 'WITHDRAWCHEQUE' || popType === 'Withdraw' || popType === 'DEPOSITCHEQUE' || popType === 'Deposit'){
      this.setState({
        modalIsOpen_MEP: true,
        activeTrasactionId: accountTrasacId,
        popType: popType,
      });
    }else{
      if (popId == "modalUploadBankToCash") {
        this.setState({
          modalIsOpen_BankToCash: true,
          activeTrasactionId: accountTrasacId,
          popType: popType,
        });
      }
    }
  }
  clickToEditTransactionNotify(value) {
    confirmAlert({
      title: "Transaction can't be edited",
      message:
        "Cheque against this transaction has been closed, please reopen it to edit this transaction.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }
  getCashHandListData(_page) {
    let _transArray = [];
    let _paidAmt = 0;
    let _unpaidAmt = 0;
    let _totalAmt = 0;
    let _totalOutCome = 0;
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      type: "CASHINHAND",
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      isDateFilter: true,
      pageCount: this.state.pageCount,
      page: _page,
    });

    PostData(global.userCashInHandTransactionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          console.log('responseJson', JSON.stringify(responseJson))
          this.setState({
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            total: _totalAmt,
          });
        } else {
          if (responseJson.response) {
            _transArray = responseJson.response;
            _transArray.map((itm, i) => {
              _unpaidAmt = parseFloat(_unpaidAmt) + parseFloat(itm.balance);
              _paidAmt = parseFloat(_paidAmt) + parseFloat(itm.received_amount);
              _totalAmt = parseFloat(_totalAmt) + parseFloat(itm.total_amount);
            });
            _totalOutCome = responseJson.totalOutCome;
          }
          this.setState({
            totalOutCome: _totalOutCome,
            transactionArrayFilter: _transArray,
            itemArray: _transArray,
            isLoaded: true,
            paidAmount: _paidAmt,
            unpaidAmount: _unpaidAmt,
            total: _totalAmt
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadTranscListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.transactionArrayFilter;
      //_transDetails.sort((a, b) => (a.added_on > b.added_on) ? 1 : -1).reverse();

      if (_transDetails.length > 0) {
        let _i = 0;
        _transDetails.map((value, index) => {
          _i++;
          let _actionExtra = "";
          let _actionLinkEdit = "";
          let _hideActionButton = "";
          let typesend = value.typeShow;
          let _transIdSend = value.id;
          if (value.type === "Other Income") {
            _transIdSend = value.transaction_id ? value.transaction_id : 0;
          }
          if (
            value.typeRedirect == "CTB" ||
            value.typeRedirect == "ADB" ||
            value.typeRedirect == "OPEN" ||
            value.typeRedirect == "BTC"
          ) {
            typesend = value.typeRedirect;
          }

          let _type = "&tType=CASHINHAND";

          let _actionLinkDelete = (
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              data-id={value.id}
              onClick={this.clickToDelete.bind(this, _transIdSend, typesend)}
            >
              Delete
            </a>
          );
          if (value.type == "SALE") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl + "business/add-sale/?auth=" + value.id + _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "PAYMENT-IN") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-sale-payment-in/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "SALE ORDER") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-sale-order/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "CREDIT NOTE") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-sale-credit-note/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "PURCHASE") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-purchase/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "PURCHASE ORDER") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-purchase-order/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "DEBIT NOTE") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-purchase-debit-note/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "PAYMENT-OUT") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-purchase-payment-out/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "EXPENSE") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href={
                  global.webUrl +
                  "business/add-expense/?auth=" +
                  value.id +
                  _type
                }
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "ADDCASH") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                onClick={this.openModalEditExpenseCategoryHandler.bind(
                  this,
                  "modalAdjustNewCashHand",
                  value.id
                )}
                style={{ margin: "0px" }}
              >
                View/Edit Details
              </a>
            );
          } else if (value.type == "REDUCECASH") {
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                onClick={this.openModalEditExpenseCategoryHandler.bind(
                  this,
                  "modalAdjustNewCashHand",
                  value.id
                )}
                style={{ margin: "0px" }}
              > 
                View/Edit Details
              </a>
            ); 
          } else if (
            value.type == "WITHDRAWCHEQUE"
          ) {

            _actionLinkEdit = "";
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.openModalTrasacEditHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  value.type
                )}
              >
                View/Edit
              </a>
            );
            _hideActionButton = "";
          } else if (value.type == "Withdraw"
          ) {

            _actionLinkEdit = "";
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.openModalTrasacEditHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                    typesend
                )}
              >
                View/Edit
              </a>
            );
            _hideActionButton = "";
          } else if (value.type == "DEPOSITCHEQUE") {

            _actionLinkEdit = "";
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.openModalTrasacEditHandler.bind(
                  this,
                  "modalMakePaymentDeposit",
                  value.id,
                  value.type
                )}
              >
                View/Edit
              </a>
            );
            _hideActionButton = "";
          } else if (value.type == "Deposit") {

            _actionLinkEdit = "";
            _actionLinkEdit = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.openModalTrasacEditHandler.bind(
                    this,
                    "modalUploadBankToCash",
                    value.id,
                    typesend
                )}
              >
                View/Edit
              </a>
            );
            _hideActionButton = "";
          } else if (value.type == "Other Income") {
            if (value.cheque_status == "1" && value.cheque_status == 1) {
              _actionLinkEdit = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.openModalHandler.bind(
                      this,
                      "modalMakePaymentDepositOI",
                      value.id,
                      value.sendTransType
                  )}
                  /*onClick={this.clickToEditTransactionNotify.bind(
                    this,
                    value.id
                  )}*/
                >
                  View/Edit Details
                </a>
              );
            } else {
              _actionLinkEdit = (
                <a
                  className="dropdown-item"
                  href={
                    global.webUrl +
                    "business/create-other-income/?auth=" +
                    value.id
                  }
                >
                  View/Edit Details
                </a>
              );
            }


          } else {
            _actionLinkEdit = "";
          }
          _actionExtra = "";
          _actionExtra = (
            <div
              className="btn-group"
              style={{ display: _hideActionButton }}
              role="group"
            >
              <button
                type="button"
                id="btnGroupVerticalDrop3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="btnGroupVerticalDrop1"
              >
                {_actionLinkEdit}
                {_actionLinkDelete}
                {/* <ButtonPdf transactionId={value.id} isChallanPreview={true}/> */}
              </div>
            </div>
          );

          let _via = value.party_name;

          let _inHandAmount = 0;
          _inHandAmount = parseFloat(value.paid_amount);
          let _amount = numberFormat(_inHandAmount);

          let _saleType = "";
          let _amountTxt = "";
          if (value.colorShow == "Green") {
            _saleType = value.typeShow;
            _amountTxt = <span className="text-success">{_amount}</span>;
          } else {
            _saleType = value.typeShow;
            _amountTxt = <span className="text-danger">{_amount}</span>;
          }
          let showDateTime = value.invoice_date;
          if (_businessSetting.Tran_AddTime == 1 && value.invoice_time!==null) {
            showDateTime = value.invoice_date + " " + value.invoice_time;
          }
          rows.push({
            srNo: _i, //value.srNo,
            invoice_date: showDateTime,
            invoice_no: value.customPrefix + " #" + value.invoice_no,
            party_name: _via,
            sale_type: _saleType,
            total_amount: _amountTxt,
            actionExtra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getCashHandListData(
      this.state.page,
      this.state.startDate,
      this.state.endDate
    );
  };

  clickToDelete(value, type) {
    console.log("==type==",type);
    let deleteUrl = global.deleteCashInHandTransaction;
    if(type === 'Loan Adjustment' || type === 'Loan Received'){
      deleteUrl = global.userLoanAccountTransactionDelete;
    }
    let requestData = JSON.stringify({
      sale_id: value,
      type_value: type,
    });
    
    if(type === 'Loan Adjustment' || type === 'Loan Received'){
      requestData = JSON.stringify({
        loan_transaction_id: value,
      });
    }
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            // requestData = JSON.stringify({
            //   sale_id: value,
            //   type_value: type,
            // });
            // if(type === 'Loan Adjustment' || type === 'Loan Received'){
            //   requestData = JSON.stringify({
            //     loan_transaction_id: value,
            //   });
            // }
            PostData(deleteUrl, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getCashHandListData(this.state.page);
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({
      modalIsOpen_AC: false,
      modalIsOpen_MEP: false,
      activeEditCategoryId: 0,
      modalIsOpen_BankToCash: false,
      activeBankId: 0,
      activeTrasactionId : 0,
    });
    this.getCashHandListData(this.state.page);
  };
  /* End Here */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Cash In Hand List
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">
              Manage Cash In Hand List
            </span>
          </nav>
          <div className="block">
            <div class="block-header block-header-default">
              <h3 class="block-title">
                <i className="si si-wallet text-info"></i> Cash In Hand :{" "}
                {numberFormat(this.state.totalOutCome)}
              </h3>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="block">
                  <div className="block-content">
                    <h2 className="block-title ml-10">Transactions</h2>
                    <a
                      onClick={this.openModalHandler.bind(
                        this,
                        "modalAdjustNewCashHand"
                      )}
                      href="javascript:void()"
                      style={{ marginTop: "35px" }}
                      className="pull-right pull-right btn btn-sm btn-primary mb-10 ml-10"
                    >
                      <i className="fa fa-plus-circle"></i> Adjust Cash
                    </a>

                    <a
                      onClick={this.openModalTrasacHandler.bind(
                        this,
                        "modalUploadBankToCash",
                        0,
                        "CTB"
                      )}
                      href="javascript:void()"
                      style={{ marginTop: "35px" }}
                      className="pull-right pull-right btn btn-sm btn-primary mb-10 ml-10"
                    >
                      <i className="fa fa-plus-circle"></i> Bank Transfer
                    </a>
                  </div>
                  <div className="block-content" id="tableData">
                    <span>Filter : </span>
                    <DateRangePicker
                      initialSettings={{
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        locale: {
                          format: "MMMM D, YYYY",
                        },
                        ranges: {
                          Today: [moment().toDate(), moment().toDate()],
                          Yesterday: [
                            moment().subtract(1, "days").toDate(),
                            moment().subtract(1, "days").toDate(),
                          ],
                          "This Week": [
                            moment().startOf("week").format("MMM DD, YYYY"),
                            moment().endOf("week").format("MMM DD, YYYY"),
                          ],
                          "This Month": [
                            moment().startOf("month").toDate(),
                            moment().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            moment()
                              .subtract(1, "month")
                              .startOf("month")
                              .toDate(),
                            moment()
                              .subtract(1, "month")
                              .endOf("month")
                              .toDate(),
                          ],
                          "This Quarter": [
                            moment().startOf("month").toDate(),
                            moment().add(2, "month").endOf("month").toDate(),
                          ],
                          "This Financial Year": [
                            moment()
                              .month("April")
                              .startOf("month")
                              .format("MMM DD, YYYY"),
                            moment()
                              .add(1, "year")
                              .month("March")
                              .endOf("month")
                              .format("MMM DD, YYYY"),
                          ],
                        },
                      }}
                      onCallback={this.handleCallBackDate}
                    >
                      <input
                        type="text"
                        className="form-control col-4"
                        style={{ display: "inline-block" }}
                      />
                    </DateRangePicker>
                    <MDBDataTableV5
                      hover
                      entriesOptions={[25, 50, 100]}
                      entries={25}
                      pagesAmount={4}
                      span
                      pagingTop
                      searchTop
                      searchBottom={false}
                      barReverse
                      data={this.loadTranscListing()}
                    />
                  </div>
                  <div className="block-content">
                    <div style={{ float: "left", marginTop: "-45px" }}>
                      <PaginationControl
                        between={this.state.pageCount}
                        total={this.state.totalCount}
                        limit={this.state.pageCount}
                        changePage={(page) => {
                          this.setState({ setPage: page });
                          this.getSaleListData(page);
                        }}
                        ellipsis={1}
                        activeClass={this.state.setPage}
                        page={this.state.setPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>
        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_AC}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewCashHand"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Cash In Hand</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewCashHand"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddAdjustCash
                    callBack={this.getUpdateResponse}
                    cashHandId={this.state.activeEditCashHandId}
                    isRedirectTo={"business/manage-cash-in-hand/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewCashHand"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}

        {/* Modal Add  Bank Transfer Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BankToCash}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUploadBankToCash"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Bank Transfer</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadBankToCash"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddBankTransfer
                    callBack={this.getUpdateResponse}
                    activeBankId={this.state.activeBankId}
                    popType={this.state.popType}
                    isRedirectTo={"business/manage-account-list/"}
                    accountTrasactionId={this.state.activeTrasactionId}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadBankToCash"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Transfer Details*/}

         {/* Modal Add Withdraw Deposit Details Begins */}
         <Modal
          isOpen={this.state.modalIsOpen_MEP}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalMakePaymentDeposit"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Transfer Cheque</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalMakePaymentDeposit"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddDepositeMakePayment
                    minDate={this.state.activeMinDate}
                    isTransaction={this.state.activeTrasactionId}
                    isTransactionType={this.state.activeTypeAccount}
                    isChequeTrasactionId={this.state.activeTrasactionId}
                    callBack={this.getUpdateResponse}
                    isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalMakePaymentDeposit"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add  Withdraw Deposit Details*/}
        <Modal
            isOpen={this.state.modalIsOpen_MEP_OI}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalMakePaymentDepositOI"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Transfer Cheques</h3>
                  <div className="block-options">
                    <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalMakePaymentDepositOI"
                        )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddDepositeMakePaymentOI
                      minDate={this.state.activeMinDate}
                      isTransaction={this.state.activeTrasactionId}
                      isChequeTrasactionId={this.state.activeTrasactionId}
                      isTransactionType={this.state.activeTypeAccount}
                      forUseBank={false}
                      isButtonHide={false}
                      callBack={this.getUpdateResponse}
                      isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                        this,
                        "modalMakePaymentDepositOI"
                    )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
