import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";
import moment from "moment";
import QuickAddUniConversion from "./quickUnitConversion";
import QuickAddNewUnit from "../unit/quickAddUnit";
import QuickAddNewItemCatDetail from "./quickItemCategory";
import Modal from "react-modal";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let _AdditionalCESS = 0;
let _ItemCategory = 0;
let _Description = 0;
let _businessSetting = {};
let HSN_SAC_CODE = 1;
let _classValue = "";
let tax_name = "";
let gstTaxDetails = [];
let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
  gstTaxDetails = _companySession.taxDetails;
}

let _loader = "glb-ldr-prt active";

export default class QuickAddService extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");
    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
    }

    if (_UserSession!=null && _UserSession!="") {
      let _companySession = localStorage.getItem("vs_UserCompanySession");
      if (_companySession!=undefined || _companySession!=null) {
        _companySession = JSON.parse(_companySession);
        _businessId = _companySession.business_id;
        _company_id = _companySession.company_id;
        country_code = _companySession.country_code;
        tax_name = _companySession.tax_name;
        gstTaxDetails = _companySession.taxDetails;

        if (_UserSession!=null && _UserSession!="") {
          if (_UserSession.loginType!="Admin") {
            if (_UserSession!=undefined || _UserSession!=null) {
              HSN_SAC_CODE = _businessSetting.HSN_SAC_CODE;
              _businessSetting = _UserSession.businessSetting;
              _ItemCategory = _businessSetting.ItemCategory;
              _AdditionalCESS = _businessSetting.AdditionalCESS;
              _Description = _businessSetting.Description;
              if (_businessSetting.ItemsUnit == 0) {
                _classValue = "iti__hide";
              }
            }
          }
        }
      }
    }
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-service/",
      buttonTitle: "Add New",
      isLoaded: true,
      modalIsOpen_ActiveUnitConversion: false,
      serviceArray: [],
      imagePreviewUrl: [],
      imgCollection: [],
      imgCollectionOld: [],
      taxRateArray: gstTaxDetails,
      unitArray: [],
      secUnitArray: [],
      itemCategoryArray: [],
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,
      name: "",
      hsn_sac: "",
      code: "",
      category: "0",
      sale_price: "",
      sale_tax_type: "With Tax",
      tax_rate_id: "0",
      taxRateId: "0",
      taxRate_id: "0",
      selBaseUnitId: "0",
      selSecUnitd: "0",
      Additional_CESS: "",
      unit_conversion_rates: "",
      discount_type: "Percentage",
      discount_in_sale_price: "0",

      /*Setting Data */
      AdditionalCESS: _AdditionalCESS,
      Description: _Description,
      ItemCategory: _ItemCategory,
      HSNSACCODE: HSN_SAC_CODE,
      errors: {
        name: "",
        hsn_sac: "",
        code: "",
        sale_price: "",
        sale_tax_type: "",
        tax_rate_id: "",
        taxRateId: "",
        selBaseUnitId: "",
        selSecUnitd: "",
        unit_conversion_rates: "",
        discount_type: "",
        category: "",
        Additional_CESS: "",
        item_description: "",
        discount_in_sale_price: "",
      },
    };
  }

  async componentDidMount() {
    /*For master Units*/
    //let _urlDataUnits = global.unitList;
    //let resUnits = await PostData(_urlDataUnits,'','GET');

    let _urlDataUnits = global.userUnitList + "?businessId=" + _businessId+'&company_id='+this.state.company_id;
    let resUnits = await PostData(_urlDataUnits, "", "GET");

    if (resUnits.response == null || resUnits.response == undefined) {
      window.location.href = global.BASE_URL + "not-found/";
      return false;
    }
    let _unitArray = resUnits.response;
    this.setState({ unitArray: _unitArray });

    /* Get Url Pass Parameter */
    let _itemId = this.props.itemId;
    if (_itemId > 0) {
      let _serviceArray = [];
      let _url_GetData = global.userServiceDetails + "?itemId=" + _itemId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              let _sedSecUnitArray = [];
              let _baseUnitName,
                _secUnitName = "";

              _serviceArray = responseJson.response[0];
              _unitArray.map((item, index) => {
                if (item.unit_id!=_serviceArray.base_unit_id) {
                  _sedSecUnitArray.push(item);
                }

                if (item.unit_id == _serviceArray.base_unit_id) {
                  _baseUnitName = item.unit_name;
                }
                if (item.unit_id == _serviceArray.secound_unit_id) {
                  _secUnitName = item.unit_name;
                }
              });
              this.setState({
                buttonTitle: "Edit",
                name: _serviceArray.name,
                businessId: _serviceArray.business_id,
                userId: _serviceArray.user_id,
                itemId: _itemId,
                hsn_sac: _serviceArray.hsn_sac,
                code: _serviceArray.code,
                category: _serviceArray.category,
                sale_price: _serviceArray.sale_price,
                sale_tax_type: _serviceArray.sale_tax_type,
                tax_rate_name: _serviceArray.tax_rate_name,
                tax_rate_id: _serviceArray.tax_rate_id,
                unit_conversion_rates: _serviceArray.unit_conversion_rates,
                selBaseUnitId: _serviceArray.base_unit_id,
                selSecUnitd: _serviceArray.secound_unit_id,
                taxRate_id: _serviceArray.tax_rate_id,
                taxRateId:
                  _serviceArray.tax_rate_id +
                  "##" +
                  _serviceArray.tax_rate_name +
                  "##" +
                  _serviceArray.tax_rate_ratio,
                isLoaded: true,
                imgCollectionOld: _serviceArray.images,
                Additional_CESS: _serviceArray.Additional_CESS,
                secUnitArray: _sedSecUnitArray,
                baseUnitName: _baseUnitName,
                secUnitName: _secUnitName,
                discount_type: _serviceArray.discount_type,
                discount_in_sale_price: _serviceArray.discount_in_sale_price,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    }
    this.getItemCategoryListData();
  }

  getItemCategoryListData() {
    let _itemsCatArray = [];

    const requestData = JSON.stringify({
      businessId: _businessId,
    });
    PostData(global.itemCategoryList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _itemsCatArray = responseJson.response;
          }
          this.setState({ itemCategoryArray: _itemsCatArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name =
          value.length < 3 ? "* Name must be 3 characters long!" : "";
        if(!value){
          errors.name = "";
        }
        this.setState({ name: value });
        break;
      case "hsn_sac":
        errors.hsn_sac =
          value.length < 3 ? "* HSN/SAC must be 3 characters long " : "";
        if(!value){
          errors.hsn_sac = "";
        } else if (validator.isInt(value)) {
          errors.hsn_sac = "";
        } else {
          errors.hsn_sac = "*Please enter only numeric value.";
        }
        this.setState({ hsn_sac: value });
        break;

      case "code":
        errors.code =
          value.length < 3 ? "* code must be 3 characters long!" : "";
        this.setState({ code: value });
        break;

      case "item_description":
        errors.item_description =
          value.length < 3 ? "*Description must be 3 characters long!" : "";
        this.setState({ item_description: value });
        break;

      case "Additional_CESS":
        if(!value){
          errors.Additional_CESS = "";
        } else if (validator.isFloat(value)) {
          errors.Additional_CESS = "";
        } else {
          errors.Additional_CESS = "*Enter numeric value only!";
        }
        this.setState({ Additional_CESS: value });
        break;

      case "category":
        errors.category = value.length < 0 ? "*Please Select Category" : "";
        if (value == "AddNew") {
          this.openModalHandler("modalAdjustItemCat");
        }
        this.setState({ category: value });
        break;
      case "sale_price":
        //errors.sale_price = value.length < 1 ? '*Sale Price must be 1 number!' : '';
        if (validator.isFloat(value)) {
          errors.sale_price = "";
        } else {
          errors.sale_price = "*Sale price must be 1!";
        }
        this.setState({ sale_price: value });
        break;

      case "sale_tax_type":
        errors.sale_tax_type = value.length < 0 ? "*Select sale tax !" : "";
        this.setState({ sale_tax_type: value });
        break;

      case "taxRateId":
        errors.taxRateId = value.length < 0 ? "* select tax rate" : "";
        this.setState({ taxRateId: value });
        break;

      case "selBaseUnitId":
        errors.selBaseUnitId = value < 1 ? "* Select any base unit" : "";
        if (value == "AddUnit") {
          this.openModalHandler("modalUnitConversion");
        }
        let filteredArray = this.state.unitArray.filter(function (uDetail) {
          return uDetail.unit_id!=value;
        });

        let _baseUnitName = "";
        this.state.unitArray.map((unitD, index) => {
          if (unitD.unit_id == value) {
            _baseUnitName = unitD.unit_name;
          }
        });
        this.setState({
          selBaseUnitId: value,
          secUnitArray: filteredArray,
          baseUnitName: _baseUnitName,
        });

        this.getUnitConversionData(this.state.selSecUnitd, value);
        break;

      case "selSecUnitd":
        errors.selSecUnitd = value < 1 ? "* Select any secondary unit" : "";
        let _secUnitName = "";
        this.state.unitArray.map((unitD, index) => {
          if (unitD.unit_id == value) {
            _secUnitName = unitD.unit_name;
          }
        });
        this.setState({ selSecUnitd: value, secUnitName: _secUnitName });
        this.getUnitConversionData(value, this.state.selBaseUnitId);
        break;

      case "unit_conversion_rates":
        if (validator.isInt(value)) {
          errors.unit_conversion_rates = "";
        } else {
          errors.unit_conversion_rates = "*Unit conversion rate must be 1!";
        }
        this.setState({ unit_conversion_rates: value });
        break;

      case "discount_type":
        errors.discount_type = value.length < 3 ? "*Select discount type!" : "";
        this.setState({ discount_type: value });
        break;

      case "discount_in_sale_price":
        if (validator.isInt(value)) {
          errors.discount_in_sale_price = "";
        } else {
          errors.discount_in_sale_price =
            "*Minimum discount on sale value atleast 1!";
        }
        if (this.state.discount_type == "Percentage") {
          if (value > 100) {
            errors.discount_in_sale_price =
              "*Discount can not be more than 100 %";
          } else {
            if (validator.isInt(value)) {
              errors.discount_in_sale_price = "";
            } else {
              errors.discount_in_sale_price =
                "*Minimum discount on sale value atleast 0 or greater than 0!";
              this.setState({ discount_in_sale_price: 0 });
            }
          }
        } else {
          if (parseFloat(value) > parseFloat(this.state.sale_price)) {
            errors.discount_in_sale_price =
              "*Discount can not be more than 100 %";
          } else {
            if (validator.isInt(value)) {
              errors.discount_in_sale_price = "";
            } else {
              errors.discount_in_sale_price =
                "*Minimum discount on sale value atleast 0 or greater than 0!";
              this.setState({ discount_in_sale_price: 0 });
            }
          }
        }
        let _discountValue = value;
        if (_discountValue == "") {
          _discountValue = 0;
          errors.discount_in_sale_price = "";
        }
        this.setState({ discount_in_sale_price: _discountValue });
        break;

      default:
        break;
    }
  };

  clickToAddService = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;

    if (this.state.name == "") {
      _isError = true;
      errors["name"] = "*Please Enter Service Name";
      this.setState({ errors: errors });
    }

    if (this.state.item_description == "" && this.state.Description == 1) {
      _isError = true;
      errors["item_description"] = "*Please Enter description";
      this.setState({ errors: errors });
    }

    if (this.state.code == "") {
      _isError = true;
      errors["code"] = "*Please Enter Service Code";
      this.setState({ errors: errors });
    }
    if (this.state.category == "") {
      _isError = true;
      errors["category"] = "*Please Select Category";
      this.setState({ errors: errors });
    }
    if (this.state.sale_price == "") {
      _isError = true;
      errors["sale_price"] = "*Please Enter Sale Price";
      this.setState({ errors: errors });
    }
    if (this.state.sale_tax_type == "") {
      _isError = true;
      errors["sale_tax_type"] = "*Please Select Tax Type";
      this.setState({ errors: errors });
    }
    if (this.state.taxRateId == "") {
      _isError = true;
      errors["taxRateId"] = "*Please Select Tax Rate";
      this.setState({ errors: errors });
    }

    if (this.state.discount_type == "") {
      _isError = true;
      errors["discount_type"] = "*Please Select Any Discount Value.";
      this.setState({ errors: errors });
    }

    if (
      this.state.discount_in_sale_price > 0 ||
      this.state.discount_in_sale_price == ""
    ) {
      if (this.state.discount_in_sale_price == "") {
        _isError = true;
        errors["discount_in_sale_price"] =
          "*Discount value enter minimum 0 or 1";
      } else if (this.state.discount_type == "Percentage") {
        if (this.state.discount_in_sale_price > 100) {
          _isError = true;
          errors["discount_in_sale_price"] =
            "*Discount can not be more than 100 %";
        } else {
          _isError = false;
          errors["discount_in_sale_price"] = "";
        }
      } else {
        if (
          parseFloat(this.state.discount_in_sale_price) >
          parseFloat(this.state.sale_price)
        ) {
          _isError = true;
          errors["discount_in_sale_price"] =
            "*Discount can not be more than 100 %";
        } else {
          _isError = false;
          errors["discount_in_sale_price"] = "";
        }
      }
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.itemId > 0) {
        this.editServiceMethodCall();
      } else {
        this.addServiceMethodCall();
      }
    }
  };

  addServiceMethodCall() {
    let fullTaxRateD = this.state.taxRateId;
    let mainData = fullTaxRateD.split("##");

    let _rateTax = {};
    _rateTax.id = mainData[0];
    _rateTax.name = mainData[1] + mainData[2];
    _rateTax.ratio = mainData[2];

    var itemData = new FormData();
    itemData.append("businessId", this.state.businessId);
    itemData.append("userId", this.state.userId);
    itemData.append("company_id", this.state.company_id);
    itemData.append("name", this.state.name);
    itemData.append("base_unit_id", this.state.selBaseUnitId);
    itemData.append("second_unit_id", this.state.selSecUnitd);
    itemData.append("unit_conversion_rates", this.state.unit_conversion_rates);
    itemData.append("hsn_sac", this.state.hsn_sac);
    itemData.append("code", this.state.code);
    itemData.append("sale_price", this.state.sale_price);
    itemData.append("sale_tax_type", this.state.sale_tax_type);
    itemData.append("Additional_CESS", this.state.Additional_CESS);
    itemData.append("category", this.state.category);
    itemData.append("rateTax", JSON.stringify(_rateTax));
    itemData.append("discount_type", this.state.discount_type);
    itemData.append(
      "discount_in_sale_price",
      this.state.discount_in_sale_price
    );
    for (let _i = 0; _i < this.state.imgCollection.length; _i++) {
      itemData.append("imgCollection", this.state.imgCollection[_i][0]);
    }
    
    new Promise((resolve, reject) => {
      fetch(global.userAddService, {
        method: "POST",
        body: itemData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + this.state.isRedirectTo;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  editServiceMethodCall() {
    let fullTaxRateD = this.state.taxRateId;
    let mainData = fullTaxRateD.split("##");

    let _rateTax = {};
    _rateTax.id = mainData[0];
    _rateTax.name = mainData[1] + mainData[2];
    _rateTax.ratio = mainData[2];
    var itemData = new FormData();
    itemData.append("businessId", this.state.businessId);
    itemData.append("userId", this.state.userId);
    itemData.append("company_id", this.state.company_id);
    itemData.append("name", this.state.name);
    itemData.append("itemId", this.state.itemId);
    itemData.append("base_unit_id", this.state.selBaseUnitId);
    itemData.append("second_unit_id", this.state.selSecUnitd);
    itemData.append("unit_conversion_rates", this.state.unit_conversion_rates);
    itemData.append("hsn_sac", this.state.hsn_sac);
    itemData.append("code", this.state.code);
    itemData.append("sale_price", this.state.sale_price);
    itemData.append("sale_tax_type", this.state.sale_tax_type);
    itemData.append("tax_rate_name", this.state.tax_rate_name);
    itemData.append("Additional_CESS", this.state.Additional_CESS);
    itemData.append("category", this.state.category);
    itemData.append("rateTax", JSON.stringify(_rateTax));
    itemData.append("discount_type", this.state.discount_type);
    itemData.append(
      "discount_in_sale_price",
      this.state.discount_in_sale_price
    );
    for (let _i = 0; _i < this.state.imgCollection.length; _i++) {
      itemData.append("imgCollection", this.state.imgCollection[_i][0]);
    }
    new Promise((resolve, reject) => {
      fetch(global.userEditService, {
        method: "POST",
        body: itemData,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            sendNotification("Success Message 👍", res.message, "success");
            setTimeout(() => {
              window.location.href = global.webUrl + this.state.isRedirectTo;
            }, Math.floor(Math.random() * 5000) + 1);
          } else {
            sendNotification("Error Message 😓", res.message, "danger");
            return;
          }
        })
        .catch((error) => {
          //reject(error);
        });
    });
  }

  onChangeFileUploadHandler = (event) => {
    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(file);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          var newStateArray = this.state.imgCollection.slice();
          newStateArray.push(images);
          this.setState({ imgCollection: newStateArray });
        },
        (error) => {
          console.error(error);
        }
      );
    }

    if (event.target.files) {
      /* Get files in array form */
      const files = Array.from(event.target.files);
      /* Map each file to a promise that resolves to an array of image URI's */
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          /* Once all promises are resolved, update state with image URI array */
          var newStateArray = this.state.imagePreviewUrl.slice();
          newStateArray.push(images);
          this.setState({ imagePreviewUrl: newStateArray });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  previewImagesListing() {
    if (this.state.isLoaded == true) {
      let _imageDetails = this.state.imagePreviewUrl;
      let dataList = [];
      if (_imageDetails.length > 0) {
        _imageDetails.map((img, i) => {
          dataList.push(
            <span>
              <img
                style={{ width: "100px" }}
                className="preview"
                src={img}
                alt={"image-" + i}
                key={i}
              />
            </span>
          );
        });
      }
      return dataList;
    }
  }

  previewOldImagesListing() {
    if (this.state.isLoaded == true) {
      let _imageDetails = this.state.imgCollectionOld;
      let dataList = [];
      if (_imageDetails.length > 0) {
        _imageDetails.map((img, i) => {
          dataList.push(
            <span>
              <img
                style={{ width: "100px" }}
                className="preview"
                src={img.file_name}
                alt={"image-" + i}
                key={i}
              />
              <i
                onClick={this.deleteItemImage.bind(this, img.id)}
                className="si si-close mr-5"
                id="removeItmImgEvent"
              ></i>
            </span>
          );
        });
      }
      return dataList;
    }
  }

  deleteItemImage = (image_id) => {
    const requestData = JSON.stringify({
      itemImageId: image_id,
      itemId: this.state.itemId,
    });
    PostData(global.userItemImageDelete, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification(
          "Success Message 👍",
          "Item image deleted from system.",
          "success"
        );
        let filteredArray = this.state.imgCollectionOld.filter(
          (item) => item.id!=image_id
        );
        this.setState({ imgCollectionOld: filteredArray });
        this.previewOldImagesListing();
      } else {
        sendNotification(
          "Error Message 😓",
          "Some error occured. Please try again.",
          "danger"
        );
        return;
      }
    });
  };

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalUnitConversion") {
      this.setState({
        modalIsOpen_ActiveUnitConversion: true,
        isPageRedirect1: "business/add-item/",
      });
    } else if (popId == "modalAdjustItemCat") {
      this.setState({ modalIsOpen_ItemCat: true });
    }
  }
  closeModalHandler = (popId) => {
    if (popId == "modalUnitConversion") {
      this.setState({ modalIsOpen_ActiveUnitConversion: false });
    } else if (popId == "modalAdjustItemCat") {
      this.setState({ modalIsOpen_ItemCat: false });
    }
  };

  getUnitConversionData(secUnit, primaryUnit) {
    const requestData = JSON.stringify({
      secUnit: secUnit,
      primaryUnit: primaryUnit,
      businessId: this.state.businessId,
    });
    PostData(global.userUnitLastConversionList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true, unit_conversion_rates: 0 });
        } else {
          let _conversionArray = [];
          if (responseJson.response) {
            _conversionArray = responseJson.response[0];

            this.setState({
              unit_conversion_rates: _conversionArray.conversion,
            });
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }
  /* Callback Function from Child */
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_ActiveUnitConversion: false });
    this.setState({ modalIsOpen_ItemCat: false });
    this.getItemCategoryListData();
  };
  /* End Here */

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    let _conversationText = "";

    if (this.state.unit_conversion_rates >= 0 && this.state.selSecUnitd > 0) {
      _conversationText =
        "1 " +
        this.state.baseUnitName +
        " = " +
        this.state.unit_conversion_rates +
        " " +
        this.state.secUnitName;
    }
    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="name"
                  name="name"
                  value={this.state.name}
                  placeholder="Enter Service Name"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Service Name <span className="text-danger">*</span>
                </label>
                <span className="vs_error_txt">
                  {errors.name.length > 0 && (
                    <span className="error">{errors.name}</span>
                  )}
                </span>
              </div>
            </div>
            {this.state.HSNSACCODE === 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                      type="number"
                    required="required"
                    className="form-control"
                    id="hsn_sac"
                    name="hsn_sac"
                    value={this.state.hsn_sac}
                    placeholder="Enter Service HSN/SAC"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Service HSN/SAC</label>
                  <span className="iris_error_txt">
                    {errors.hsn_sac.length > 0 && (
                      <span className="error">{errors.hsn_sac}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="code"
                  name="code"
                  value={this.state.code}
                  placeholder="Enter Service Code"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Service Code <span className="text-danger">*</span>
                </label>
                <span className="vs_error_txt">
                  {errors.code.length > 0 && (
                    <span className="error">{errors.code}</span>
                  )}
                </span>
              </div>
            </div>
            {this.state.ItemCategory == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="category"
                    name="category"
                    onChange={this.handleChange}
                  >
                    <option selected value="">
                      Select Category
                    </option>
                    <option value="AddNew">Add new category</option>
                    {this.state.itemCategoryArray.map((itemCat, index) => {
                      let _selCat = "";
                      if (this.state.category == itemCat.id) {
                        console.log("Hi", this.state.category);
                        _selCat = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={itemCat.id}
                          selected={_selCat}
                        >
                          {itemCat.categoryName}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">Select Category </label>
                </div>
                <div id="category-error" className="animated fadeInDown">
                  {errors.category.length > 0 && (
                    <span className="error">{errors.category}</span>
                  )}
                </div>
              </div>
            )}
            {this.state.Description == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="item_description"
                    name="item_description"
                    value={this.state.item_description}
                    placeholder="Enter description"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">
                    Description <span className="text-danger">*</span>
                  </label>
                  <span className="iris_error_txt">
                    {errors.item_description.length > 0 && (
                      <span className="error">{errors.item_description}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="selBaseUnitId"
                  name="selBaseUnitId"
                  onChange={this.handleChange}
                >
                  <option value="0" selected>
                    {" "}
                    Select Primary Unit
                  </option>
                  <option value="AddUnit"> Add Unit</option>
                  {this.state.unitArray.map((item, index) => {
                    let _selBase = "";
                    if (this.state.selBaseUnitId == item.unit_id) {
                      _selBase = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.unit_id}
                        selected={_selBase}
                      >
                        {item.unit_name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Primary Unit</label>
              </div>
              <div id="selBaseUnitId-error" className="animated fadeInDown">
                {errors.selBaseUnitId.length > 0 && (
                  <span className="error">{errors.selBaseUnitId}</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="selSecUnitd"
                  name="selSecUnitd"
                  onChange={this.handleChange}
                >
                  <option value="0" selected>
                    {" "}
                    Select Secondary Unit
                  </option>
                  {this.state.secUnitArray.map((item, index) => {
                    let _selSec = "";
                    if (this.state.selSecUnitd == item.unit_id) {
                      _selSec = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.unit_id}
                        selected={_selSec}
                      >
                        {item.unit_name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Secondary Unit</label>
              </div>
              <div id="selSecUnitd-error" className="animated fadeInDown">
                {errors.selSecUnitd.length > 0 && (
                  <span className="error">{errors.selSecUnitd}</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-material open">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  id="unit_conversion_rates"
                  name="unit_conversion_rates"
                  value={this.state.unit_conversion_rates}
                  placeholder="Enter Unit Conversion Rates"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Unit Conversion Rates</label>
                <span className="iris_error_txt">
                  {errors.unit_conversion_rates.length > 0 && (
                    <span className="error">
                      {errors.unit_conversion_rates}
                    </span>
                  )}
                </span>
              </div>
              <span>
                <strong>{_conversationText}</strong>
              </span>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  id="sale_price"
                  name="sale_price"
                  value={this.state.sale_price}
                  placeholder="Enter Sale Price"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">
                  Sale Price <span className="text-danger">*</span>
                </label>
                <span className="vs_error_txt">
                  {errors.sale_price.length > 0 && (
                    <span className="error">{errors.sale_price}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="sale_tax_type"
                  name="sale_tax_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Without Tax"
                    selected={
                      this.state.sale_tax_type == "Without Tax"
                        ? "selected"
                        : ""
                    }
                  >
                    Without Tax
                  </option>
                  <option
                    value="With Tax"
                    selected={
                      this.state.sale_tax_type == "With Tax" ? "selected" : ""
                    }
                  >
                    With Tax
                  </option>
                </select>
                <label htmlFor="login-password">Select Tax Type </label>
              </div>
              <div id="sale_tax_type-error" className="animated fadeInDown">
                {errors.sale_tax_type.length > 0 && (
                  <span className="error">{errors.sale_tax_type}</span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="taxRateId"
                  name="taxRateId"
                  onChange={this.handleChange}
                >
                  <option value="" selected>
                    {" "}
                    Select Tax Rate
                  </option>
                  {this.state.taxRateArray.map((item, index) => {
                    let _sel = "";
                    if (this.state.taxRate_id == item.id) {
                      _sel = "selected";
                    }
                    return (
                      <option
                        key={index}
                        value={item.id + "##" + item.name + "##" + item.ratio}
                        selected={_sel}
                      >
                        {item.name + "@" + item.ratio + "%"}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="login-password">Select Tax Rate</label>
              </div>
              <div id="taxRateId-error" className="animated fadeInDown">
                {errors.taxRateId.length > 0 && (
                  <span className="error">{errors.taxRateId}</span>
                )}
              </div>
            </div>

            {this.state.AdditionalCESS == 1 && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="Additional_CESS"
                    name="Additional_CESS"
                    value={this.state.Additional_CESS}
                    placeholder="Enter Additional CESS"
                    onChange={this.handleChange}
                    onWheel={() => document.activeElement.blur()}
                  />
                  <label htmlFor="login-username">Additional CESS</label>
                  <span className="iris_error_txt">
                    {errors.Additional_CESS.length > 0 && (
                      <span className="error">{errors.Additional_CESS}</span>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-4">
              <div className="form-material open">
                <select
                  className="form-control"
                  id="discount_type"
                  name="discount_type"
                  onChange={this.handleChange}
                >
                  <option
                    value="Percentage"
                    selected={
                      this.state.discount_type == "Percentage" ? "selected" : ""
                    }
                  >
                    Percentage
                  </option>
                  <option
                    value="Amount"
                    selected={
                      this.state.discount_type == "Amount" ? "selected" : ""
                    }
                  >
                    Amount
                  </option>
                </select>
                <label htmlFor="login-username">Discount Type</label>
                <span className="iris_error_txt">
                  {errors.discount_type.length > 0 && (
                    <span className="error">{errors.discount_type}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  className="form-control"
                  id="discount_in_sale_price"
                  name="discount_in_sale_price"
                  value={this.state.discount_in_sale_price}
                  placeholder="Enter Discount On Sale Price"
                  onChange={this.handleChange}
                  onWheel={() => document.activeElement.blur()}
                />
                <label htmlFor="login-username">Discount On Sale Price</label>
                <span className="iris_error_txt">
                  {errors.discount_in_sale_price.length > 0 && (
                    <span className="error">
                      {errors.discount_in_sale_price}
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="form-material">
                <label htmlFor="login-username">Service Image</label>
                <input
                  type="file"
                  onChange={this.onChangeFileUploadHandler}
                  name="item_image"
                  accept="image/*"
                  onWheel={() => document.activeElement.blur()}
                />
              </div>
              <div>
                {this.previewImagesListing()}
                {this.previewOldImagesListing()}
              </div>
            </div>
          </div>
          <div className="claerfix"></div>
          <div className="form-group row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddService}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Service
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add  Unit Conversion Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ActiveUnitConversion}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalUnitConversion"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Unit</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUnitConversion"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewUnit
                    isRedirectTo={"business/add-item/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUnitConversion"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Unit Conversion Details*/}

        {/* Modal Item Category Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_ItemCat}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustItemCat"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Category</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustItemCat"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewItemCatDetail
                    isRedirectTo={"business/add-item/"}
                    callBack={this.getUpdateResponse}
                  />
                  {/* isRedirectTo={'business/add-purchase-debit-note/'} */}
                </div>
                <br />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustItemCat"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Item Category Details*/}
      </React.Fragment>
    );
  }
}
