import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ManageBulkPriceUpdate from './manageBulkPriceUpdateItem';
import ManageBullStockUpdate from './manageBulkStockUpdateItem';
import ManageBulkInformationUpdate from './manageBulkInformationUpdateItem';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _AdditionalCESS = 0;
let _businessSetting = {};


let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Item Name',
        field: 'item_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Item Name',
        },
    },
    {
        label: 'Item Code',
        field: 'code',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Item Code',
        }
    },
    {
        label: 'HSN/SAC',
        field: 'hsn_sac',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'HSN/SAC',
        },
    }, 
    {
        label: 'Sale Price',
        field: 'sale_price',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Sale Price',
        },
    },
    {
        label: 'Purchase Price',
        field: 'purchase_price',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Purchase Price',
        },
    },
    {
        label: 'Discount Type',
        field: 'discount_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Discount Type',
        },
    },
    {
        label: 'Discount In Sale Price',
        field: 'discount_in_sale_price',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Discount In Sale Price',
        },
    },
    {
        label: 'Remaining Stock',
        field: 'remaining_stock',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Remaining Stock',
        }
    },
    {
        label: 'Min Stock Quantity',
        field: 'min_stock_quantity',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Min Stock Quantity',
        }
    },
    {
        label: 'Item Location',
        field: 'item_location',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Item Location',
        }
    },
    {
        label: 'Tax Rate',
        field: 'tax_rate_name',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Tax Rate',
        }
    },
    {
        label: 'Sale Tax',
        field: 'sale_tax_type',
        width: 150,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Sale Tax',
        }
    }
];

let _loader = 'glb-ldr-prt active';

export default class manageUpdateBulkItemPanel extends Component{
    constructor(props){
        super(props);
        
        let _UserSession = localStorage.getItem('vs_UserSession');        
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _businessSetting = _UserSession.businessSetting;
            _AdditionalCESS = _businessSetting.AdditionalCESS;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        this.state = {
            isLoaded : true,
            modalIsOpen_IMI : false,
            itemArrayFilter:[],
            businessId:_businessId,
            company_id:_companyId,
            userId : _signingId,
            startDate : moment().startOf('month').toDate(),
            endDate : moment().endOf('month').toDate(),
            dateLabel : '',
            importExcelFile : '',
            
            checkPriceRadioNormal:'checked',
            checkStockRadioNormal:'',
            checkInfoRadioNormal:'',
            isActiveType : 0,
            AdditionalCESS: _AdditionalCESS,
            errors: {
                name : '',
                importExcelFile : '',
            }
        }
    }
    async componentDidMount ()
    {   
        this.getPartyListData();
    }
    getPartyListData(){
        let _itemArray = [];
        
        const requestData = JSON.stringify({
            company_id: this.state.company_id,
            activeFilter : true,
            inActiveFilter : false,
        });  
        PostData(global.userItemList,requestData,'POST').then((result)=>{   
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({itemArrayFilter:_itemArray,itemArray:_itemArray,isLoaded:true});
            }
            else
            {
                if (responseJson.response) {
                    _itemArray = responseJson.response;
                    _itemArray.map((itm, i) =>{
                        
                    });
                }
                this.setState({itemArrayFilter:_itemArray,itemArray:_itemArray,isLoaded:true,});
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }    

    loadTranscListing(){
        let dataList = [];
        let rows = [];        
        if(this.state.isLoaded == true)
        {
            let _transDetails = this.state.itemArrayFilter;
            if(_transDetails.length > 0){
                let _i = 0;
                _transDetails.map((data,index) => {
                    _i++;
                    rows.push({
                        srNo: _i,
                        item_name: data.name,
                        hsn_sac: data.hsn_sac,
                        sale_price: data.sale_price,
                        purchase_price: data.purchase_price,
                        discount_type: data.discount_type,
                        discount_in_sale_price: data.discount_in_sale_price,
                        remaining_stock: data.availableQty,
                        min_stock_quantity: data.min_stock_quantity,
                        item_location: data.item_location,
                        tax_rate_name: data.tax_rate_name,
                        sale_tax_type: data.sale_tax_type,
                        code: data.code,                        
                    });
                });
            }
        }

        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }
        return dataList;
    }    
    bulkUpdateBtn = (e) => {
        let activeValue = e.target.checked;
        let _key = e.target.attributes['data-stock'].value;
        _key = parseFloat(_key);
        if(_key == 0){
            this.setState({checkPriceRadioNormal:'checked',checkStockRadioNormal:'',checkInfoRadioNormal:'',isActiveType:0});
        }else if(_key == 1){
            this.setState({checkPriceRadioNormal:'',checkStockRadioNormal:'checked',checkInfoRadioNormal:'',isActiveType:1});
        }else if(_key == 2){
            this.setState({checkPriceRadioNormal:'',checkStockRadioNormal:'',checkInfoRadioNormal:'checked',isActiveType:2});            
        }else{
            this.setState({checkPriceRadioNormal:'checked',checkStockRadioNormal:'',checkInfoRadioNormal:'',isActiveType:0});         
        }
    }
    
    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        const {errors} = this.state;
        //console.log(this.state.checkPriceRadioNormal+"==>STK>=="+this.state.checkStockRadioNormal+"==>INFO>=="+this.state.checkInfoRadioNormal+"==>ACT>=="+this.state.isActiveType);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Bulk Update Items<br/>
                            </h1>                            
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <Link className="breadcrumb-item" to={"/business/dashboard/"}>Dashboard</Link>                    
                        <span className="breadcrumb-item active">Bulk Update Items</span>
                    </nav>                  

                    <div className="block">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block">
                                    <div className="block-content"> 
                                        <div className="form-group row">
                                            <div className="col-3">
                                                <div className="form-material open">
                                                    <input type="radio" id="Pricing" name="bulkUpdate" data-stock="0"   onClick={this.bulkUpdateBtn} checked={this.state.checkPriceRadioNormal}/>
                                                    <label htmlFor="login-username">Pricing</label>
                                                </div>
                                            </div>  
                                            <div className="col-3">
                                                <div className="form-material open">
                                                    <input type="radio" id="Stock" name="bulkUpdate" data-stock="1" onClick={this.bulkUpdateBtn} checked={this.state.checkStockRadioNormal}/>
                                                    <label htmlFor="login-username">Stock</label>
                                                </div>
                                            </div>   
                                            <div className="col-3">
                                                <div className="form-material open">
                                                    <input type="radio" id="Info" name="bulkUpdate" data-stock="2" onClick={this.bulkUpdateBtn} checked={this.state.checkInfoRadioNormal}/>
                                                    <label htmlFor="login-username">Item Information</label>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>

                                    {this.state.isActiveType == 0 &&
                                        <ManageBulkPriceUpdate />
                                    }
                                    {this.state.isActiveType == 1 &&
                                        <ManageBullStockUpdate />
                                    }
                                    {this.state.isActiveType == 2 &&
                                        <ManageBulkInformationUpdate />
                                    }
        
                                    {/* <div className="block-content" id="tableData">
                                        <MDBDataTableV5 
                                        hover
                                        entriesOptions={[25, 50, 100]}
                                        entries={25}
                                        pagesAmount={4}
                                        span
                                        pagingTop
                                        searchTop
                                        searchBottom={false}
                                        barReverse
                                        data={this.loadTranscListing()}
                                        />
                                    </div> */}
                                </div>                                 
                            </div>
                        </div>
                    </div>
                </div>                
                <div id="global_loader" className={_loader}>
                    <div className="gl-ldr-cld">
                        <div className="gl-ldr-ctr">
                            <div className="gl-loader"></div>
                        </div>
                    </div>
                </div>                

            </React.Fragment>
        )    
    }
}