import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Plan Name',
        field: 'plan_name',
        width: 150
    },
    {
        label: 'Plan Type',
        field: 'plan_type',
        width: 150
    },
    {
        label: 'Plan Mode',
        field: 'type',
        width: 150
    },
    {
        label: 'Plan Price',
        field: 'plan_price',
        width: 150
    },
    {
        label: 'Discount Price',
        field: 'discount_plan_price',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];

let _loader = 'glb-ldr-prt active';


export default class companyListPanel extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            plan_type : '',
            type : '',
            plan_price : '0',
            discount_plan_price : '0',
            planArray : [],
            planPriceArray : [],
            priceId : 0,
            plan_id:'',
            period:'0',
            errors: {
                plan_id:'',
                plan_type : '',
                type : '',
                plan_price : '',
                discount_plan_price : '',
                period:'',
            }
        }
    }

    async componentDidMount ()
    {
        /* Get Url Pass Parameter */
        let _handleParam = this.props.location.search;
        _handleParam = _handleParam.split('?auth=');
        let _priceId = _handleParam[1] ? _handleParam[1] : 0;
        /* End Here Url Pass Parameter */

        if(_priceId > 0){
            let _planPriceArray = [];
            let _url_GetData = global.webistePlanPriceDetails+'?priceId='+_priceId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _planPriceArray = responseJson.response[0];

                        this.setState({
                            buttonTitle:'Edit',
                            plan_type:_planPriceArray.plan_type,
                            type:_planPriceArray.type,
                            plan_price:_planPriceArray.plan_price,
                            discount_plan_price:(_planPriceArray.discount_plan_price),
                            period:(_planPriceArray.period),
                            plan_id:_planPriceArray.plan_id,
                            priceId:_planPriceArray.price_id,
                            isLoaded:true
                        });
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
        this.planListData();
        this.planPriceListData();
    }
    
    planPriceListData(){
        let _planPriceArray = [];

        let _url_GetData = global.webistePlanPriceList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _planPriceArray = responseJson.response;
                }
                this.setState({planPriceArray:_planPriceArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    planListData(){
        let _planArray = [];

        let _url_GetData = global.webistePlanList;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true});                
            }
            else
            {
                if (responseJson.response) {
                    _planArray = responseJson.response;
                }
                this.setState({planArray:_planArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'plan_id': 
            errors.plan_id = 
                value.length < 1 ? '*Please select any plan!' : '';
                this.setState({plan_id:value}); 
            break;
            case 'plan_type': 
            errors.plan_type = 
                value.length < 3 ? '*Please select plan type!' : '';
                this.setState({plan_type:value}); 
            break;
            case 'type': 
            errors.type = 
                value.length < 3 ? '*Please select plan mode!' : '';
                this.setState({type:value}); 
            break;
            case 'plan_price': 
            errors.plan_price = 
                value.length < 1 ? '*Plan price should be greater than 0!' : '';
                this.setState({plan_price:value}); 
            break;
            case 'discount_plan_price': 
            errors.discount_plan_price = 
                value.length < 1 ? '*Plan discount price should be greater than 0!' : '';
                this.setState({discount_plan_price:value}); 
            break;
            case 'period': 
            errors.period = 
                value.length < 0 ? '*Plan year should be greater than 0!' : '';
                this.setState({period:value}); 
            break;
            
            default:
            break;
        }
    }

    clickToAddPlan = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.plan_id == '')
        {
            _isError = true;
            errors['plan_id'] = "*Please Select Any Plan";
            this.setState({errors: errors});
        }
        if(this.state.plan_type == '')
        {
            _isError = true;
            errors['plan_type'] = "*Please select plan type";
            this.setState({errors: errors});
        }
        if(this.state.type == '')
        {
            _isError = true;
            errors['type'] = "*Please select plan mode";
            this.setState({errors: errors});
        }
        if(this.state.plan_price < 1)
        {
            _isError = true;
            errors['plan_price'] = "*Plan discount price should be greater than 0";
            this.setState({errors: errors});
        }
        if(this.state.discount_plan_price < 1)
        {
            _isError = true;
            errors['discount_plan_price'] = "*Plan discount price should be greater than 0";
            this.setState({errors: errors});
        }
        if(this.state.period < 1)
        {
            _isError = true;
            errors['period'] = "*Please select plan year";
            this.setState({errors: errors});
        }

        if(_isError == false){
            if(this.state.priceId > 0){
                this.editPlanMethodCall();
            }
            else{
                this.addPlanMethodCall();
            }
        }
    }

    editPlanMethodCall()
    {
        const requestData = JSON.stringify({
            plan_id: this.state.plan_id,
            plan_type: this.state.plan_type,
            type: this.state.type,
            plan_price: this.state.plan_price,
            discount_plan_price: this.state.discount_plan_price,
            period: this.state.period,
            priceId: this.state.priceId
        });
        PostData(global.adminEditWebistePlanPrice, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-website-plan-price/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['discount_plan_price'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    addPlanMethodCall(){
        
        const requestData = JSON.stringify({
            plan_id: this.state.plan_id,
            plan_type: this.state.plan_type,
            type: this.state.type,
            plan_price: this.state.plan_price,
            discount_plan_price: this.state.discount_plan_price,
            period: this.state.period
        });
        
        PostData(global.adminAddWebistePlanPrice, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                setTimeout(
                    () => {
                        window.location.href = global.webUrl+'administrator/manage-website-plan-price/';
                    }, 
                    Math.floor(Math.random() * 5000) + 1
                )
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['discount_plan_price'] = result.message;//'Please enter valid phone no.';
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    loadPlanListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _planPriceDetails = this.state.planPriceArray;
            let dataList = [];
            let rows = [];

            if(_planPriceDetails.length > 0)
            {                          
                _planPriceDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    
                    rows.push({
                        plan_name:row.plan_name,
                        plan_type: row.plan_type,
                        type: row.type,
                        plan_price: row.plan_price,
                        discount_plan_price: row.discount_plan_price,
                        //period:period,
                        srNo: row.srNo,
                        action:(
                            <div className="btn-group" role="group" key={'3212'+i} data-key={'3212'+i}>
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" href={global.webUrl+'administrator/manage-website-plan-price/?auth='+row.price_id}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }


    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return(
            <React.Fragment>
                <div className="content">
                    <div className="content-header">
                        <div className="header-section">
                            {/* <h2 className="content-heading">Manage Business</h2> */}
                            <h1 className="content-heading">
                                <i className="gi gi-direction"></i>Manage Website Plan List<br/>
                            </h1>
                        </div>
                    </div>
                    <nav className="breadcrumb push">
                        <a className="breadcrumb-item" href={global.webUrl+"administrator/dashboard/"}>Dashboard</a>
                        <span className="breadcrumb-item active">Manage Website Plan Price List</span>
                    </nav>
                    
                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">Add / Edit Website Plan Price</h3>
                        </div>
                        
                        <div className="block-content">
                            <form method="post" id="form-login" className="webForm px-30">
                                <div className="form-group row">
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <select className="form-control" name="plan_id" id="plan_id" onChange={this.handleChange}>
                                                <option value=""> Select Plan</option>
                                                {
                                                    this.state.planArray.map((compDatas, i) =>
                                                    {
                                                        let _sel = '';
                                                        if(this.state.plan_id == compDatas.plan_id){_sel = 'selected';}
                                                        return (
                                                        <option value={compDatas.plan_id} selected={_sel}>{compDatas.plan_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <label htmlFor="login-username">Plan Name <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_id.length > 0 && <span className='error'>{errors.plan_id}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <select className="form-control" name="plan_type" id="plan_type" onChange={this.handleChange}>
                                                <option value=""> Select Plan</option>
                                                
                                                <option value="Mobile" selected={(this.state.plan_type == 'Mobile') ? 'selected' : ''}> Mobile </option>
                                                <option value="Desktop" selected={(this.state.plan_type == 'Desktop') ? 'selected' : ''}> Desktop </option>
                                            </select>
                                            <label htmlFor="login-username">Plan Type <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_type.length > 0 && <span className='error'>{errors.plan_type}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <select className="form-control" name="type" id="type" onChange={this.handleChange}>
                                                <option value=""> Select Mode</option>
                                                <option value="Basic" selected={(this.state.type == 'Basic') ? 'selected' : ''}> Basic </option>
                                                <option value="Mobile" selected={(this.state.type == 'Mobile') ? 'selected' : ''}> Mobile </option>
                                                <option value="Mobile + Desktop" selected={(this.state.type == 'Mobile + Desktop') ? 'selected' : ''}> Mobile + Desktop </option>
                                                <option value="Saver" selected={(this.state.type == 'Saver') ? 'selected' : ''}> Saver </option>
                                                <option value="Only Desktop" selected={(this.state.type == 'Only Desktop') ? 'selected' : ''}> Only Desktop </option>
                                                <option value="Desktop + Mobile" selected={(this.state.type == 'Desktop + Mobile') ? 'selected' : ''}> Desktop + Mobile </option>
                                            </select>
                                            <label htmlFor="login-username">Plan Mode <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.type.length > 0 && <span className='error'>{errors.type}</span>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <input type="number" min="0" required="required" className="form-control" id="plan_price" name="plan_price" value={this.state.plan_price} placeholder="Plan Price Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Plan Price <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.plan_price.length > 0 && <span className='error'>{errors.plan_price}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <input type="number" min="0" required="required" className="form-control" id="discount_plan_price" name="discount_plan_price" value={this.state.discount_plan_price} placeholder="Plan Discount Price Here" onChange={this.handleChange}/>
                                            <label htmlFor="login-username">Discount Price <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.discount_plan_price.length > 0 && <span className='error'>{errors.discount_plan_price}</span>}</span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-material open">
                                            <select className="form-control" name="period" id="period" onChange={this.handleChange}>
                                                <option value=""> Select Mode</option>
                                                <option value="1" selected={(this.state.period == '1') ? 'selected' : ''}> 1 Year </option>
                                                <option value="2" selected={(this.state.period == '2') ? 'selected' : ''}> 2 Years </option>
                                                <option value="3" selected={(this.state.period == '3') ? 'selected' : ''}> 3 Years </option>
                                                <option value="4" selected={(this.state.period == '4') ? 'selected' : ''}> 4 Years </option>
                                                <option value="5" selected={(this.state.period == '5') ? 'selected' : ''}> 5 Years </option>
                                            </select>
                                            <label htmlFor="login-username">Plan Period <span className="text-danger">*</span></label>
                                            <span className="iris_error_txt">{errors.period.length > 0 && <span className='error'>{errors.period}</span>}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-12">
                                        <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddPlan}>
                                            <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} Website Plan
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="block">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">List of Website Plan</h3>
                        </div>
                        <div className="block-content">
                            <MDBDataTableV5 
                            hover
                            entriesOptions={[25, 50, 100]}
                            entries={25}
                            pagesAmount={4}
                            span
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            data={this.loadPlanListing()} />
                        </div>
                    </div>    
                </div>
                
                <div id="global_loader" className={_loader}>
                    <div class="gl-ldr-cld">
                        <div class="gl-ldr-ctr">
                            <div class="gl-loader"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )    
    }
}