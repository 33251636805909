import React, { Component } from "react";
import { PostData } from "../../../service/postData";
import { sendNotification } from "../../../_notificationSettings/notificationPanel";
import validator from "validator";

import moment from "moment";
import QuickAddNewPartyGroup from "./quickAddPartyGroup";
import { numberFormat } from "../../../config/numberFormat";
import Modal from "react-modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let gstTypeData = [];
let _businessSetting = {};
let _GSTINNumberClass = "iti__hide";
let _PartyGrouping = 0;
let _PartyShippingAddress = 0;

let _countryCode = "IN";

let _loader = "glb-ldr-prt active";
let _isEditMode = false;
export default class partyListPanel extends Component {
  constructor(props) {
    super(props);

    let _UserSession = localStorage.getItem("vs_UserSession");

    if (_UserSession!=undefined || _UserSession!=null) {
      _UserSession = JSON.parse(_UserSession);
      _signingId = _UserSession.loginId;
      if (_UserSession.loginType == "Business") {
        _businessSetting = _UserSession.businessSetting;
        if (_businessSetting!="undefined" || _businessSetting!="") {
          _PartyGrouping = _businessSetting.PartyGrouping;
          _PartyShippingAddress = _businessSetting.PartyShippingAddress;
          if (_businessSetting.GSTINNumber > 0) {
            _GSTINNumberClass = "";
          }
        }
      }
    }

    let _companySession = localStorage.getItem("vs_UserCompanySession");
    if (_companySession!=undefined || _companySession!=null) {
      _companySession = JSON.parse(_companySession);
      _businessId = _companySession.business_id;
      _company_id = _companySession.company_id;
      country_code = _companySession.country_code;
      tax_name = _companySession.tax_name;
      gstTypeData = _companySession.taxType;
    }

    _isEditMode = true;
    let _party_group = 0;
    if (_PartyGrouping == 1) {
      _party_group = 1;
    }
    this.state = {
      isRedirectTo: this.props.isRedirectTo
        ? this.props.isRedirectTo
        : "business/manage-party/",
      buttonTitle: "Add New",
      hideShow: "",
      isLoaded: true,
      modalIsOpen_PartyGroup: false,
      stateArray: [],
      partyGroupArray: [],
      businessId: _businessId,
      userId: _signingId,
      company_id: _company_id,

      partyId: 0,
      party_name: "",
      party_contact_no: "",
      party_billing_address: "",
      party_shipping_address: "",
      party_email_id: "",
      party_opening_balance: 0,
      party_opening_date: moment().format("MM/DD/YYYY"),
      payType: "Pay",

      gst_number: "",
      selStateId: "",
      selGstTypeId: "0",
      readOnly: false,
      PartyAddField1Chk: _businessSetting.PartyAddField1Chk,
      PartyAddField2Chk: _businessSetting.PartyAddField2Chk,
      PartyAddField3Chk: _businessSetting.PartyAddField3Chk,
      PartyAddField1Name: _businessSetting.PartyAddField1Name,
      PartyAddField2Name: _businessSetting.PartyAddField2Name,
      PartyAddField3Name: _businessSetting.PartyAddField3Name,
      party_add_field_value1: "",
      party_add_field_value2: "",
      party_add_field_value3: "",
      GSTINNumberClass: _GSTINNumberClass,
      PartyGrouping: _PartyGrouping,
      party_group: _party_group,
      PartyShippingAddress: _PartyShippingAddress,
      errors: {
        party_name: "",
        party_contact_no: "",
        party_billing_address: "",
        party_shipping_address: "",
        party_email_id: "",
        party_opening_balance: "",
        party_opening_date: "",
        gst_number: "",
        selStateId: "",
        selGstTypeId: "",
        party_group: "",
      },
    };
  }

  async componentDidMount() {
    this.getStateListData();
    let _userCountryId = "0";
    let _partyId = this.props.partyId;
    if (_partyId > 0) {
      let _partyArray = [];
      let _url_GetData = global.userPartyDetails + "?partyId=" + _partyId;
      PostData(_url_GetData, "", "GET")
        .then((result) => {
          let responseJson = result;
          if (responseJson.success == false) {
            this.setState({ isLoaded: true });
          } else {
            if (responseJson.response) {
              _isEditMode = true;
              _partyArray = responseJson.response[0];

              let _payReceive = "Pay";
              if (_partyArray.party_to_receive > 0) {
                _payReceive = "Receive";
              }
              //let _isEditable = { readOnly: true };
              let _partyOpeningDate = moment(
                _partyArray.party_opening_date
              ).format("MM/DD/YYYY");
              this.setState({
                party_opening_date: _partyOpeningDate,
                buttonTitle: "Edit",
                payType: _payReceive,
                party_name: _partyArray.party_name,
                businessId: _partyArray.business_id,
                userId: _partyArray.user_id,
                partyId: _partyId,
                party_contact_no: _partyArray.party_contact_no,
                party_billing_address: _partyArray.party_billing_address,
                party_shipping_address: _partyArray.party_shipping_address,
                party_group: _partyArray.party_group,
                party_email_id: _partyArray.party_email_id,
                party_opening_balance: _partyArray.party_opening_balance,
                selGstTypeId: _partyArray.gst_type_id,
                gst_number: _partyArray.gst_number,
                selStateId: _partyArray.countryState,
                isLoaded: true,
                party_add_field_key1: _partyArray.party_add_field_key1,
                party_add_field_key2: _partyArray.party_add_field_key2,
                party_add_field_key3: _partyArray.party_add_field_key3,
                party_add_field_value1: _partyArray.party_add_field_value1,
                party_add_field_value2: _partyArray.party_add_field_value2,
                party_add_field_value3: _partyArray.party_add_field_value3,
                readOnly: true,
              });
            }
          }
        })
        .catch((error) => this.setState({ error, isLoaded: false }));
    } else {
      _isEditMode = false;
    }
    this.getPartyGroupList();
  }

  getStateListData() {
    let _stateArray = [];

    let _url_GetData = global.adminStateList + "?countryCode=" + country_code;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _stateArray = responseJson.response;
          }
          this.setState({ stateArray: _stateArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getPartyGroupList() {
    let _partyGroupArray = [];
    const requestData = JSON.stringify({
      businessId: _businessId,
      company_id: this.state.company_id,
    });
    PostData(global.userPartyGroupList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({ isLoaded: true });
        } else {
          if (responseJson.response) {
            _partyGroupArray = responseJson.response;
          }
          this.setState({ partyGroupArray: _partyGroupArray, isLoaded: true });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  /*For Modal open close*/
  openModalHandler(popId) {
    if (popId == "modalAdjustPartyGroup") {
      this.setState({ modalIsOpen_PartyGroup: true });
    }
  }
  closeModalHandler = (popId) => {
    if (popId == "modalAdjustPartyGroup") {
      this.setState({ modalIsOpen_PartyGroup: false });
    }
  };
  getUpdateResponse = () => {
    this.setState({ modalIsOpen_PartyGroup: false, party_group: 1 });
    this.getPartyGroupList();
  };
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "party_name":
        errors.party_name =
          value.length < 3 ? "*Party Name must be 3 characters long!" : "";
        this.setState({ party_name: value });
        break;
      case "party_contact_no":
        //errors.party_contact_no = value.length < 9 ? '*phone no must be 10 number ' : '';

        if (validator.isMobilePhone(value)) {
          errors.party_contact_no = "";
        } else {
          if (value!="") {
            errors.party_contact_no = "*Please enter valid mobile no.";
          } else {
            errors.party_contact_no = "";
          }
        }
        this.setState({ party_contact_no: value });
        break;

      case "party_billing_address":
        errors.party_billing_address =
          value.length < 3 ? "*Billing address must be 3 characters long!" : "";
        this.setState({ party_billing_address: value });
        break;

      case "party_shipping_address":
        errors.party_shipping_address =
          value.length < 3
            ? "*Shipping address must be 3 characters long!"
            : "";
        this.setState({ party_shipping_address: value });
        break;

      case "party_email_id":
        if (validator.isEmail(value)) {
          errors.party_email_id = "";
        } else {
          errors.party_email_id = "*Please enter valid business email-id.";
        }
        this.setState({ party_email_id: value });
        break;

      case "party_opening_balance":
        //errors.party_opening_balance = value.length < 1 ? '*opening balace must be 1 !' : '';
        if (validator.isFloat(value)) {
          errors.party_opening_balance = "";
          this.setState({ party_opening_balance: value });
        } else {
          errors.party_opening_balance = "*Opening balace should be numeric!";
          this.setState({ party_opening_balance: "" });
        }
        break;

      case "gst_number":
        errors.gst_number =
          value.length < 2 ? "*GST number must be 2 number!" : "";
        this.setState({ gst_number: value });
        break;
      case "selStateId":
        errors.selStateId = value.length < 0 ? "*State must be selected!" : "";
        this.setState({ selStateId: value });
        break;
      case "selGstTypeId":
        errors.selGstTypeId =
          value.length < 0 ? "*GST Type must be selected!" : "";
        let _hide = "";
        if (value == 1) {
          _hide = "hide";
        }
        this.setState({ selGstTypeId: value, hideShow: _hide });
        break;
      case "party_add_field_value1":
        this.setState({ party_add_field_value1: value });
        break;

      case "party_add_field_value2":
        this.setState({ party_add_field_value2: value });
        break;

      case "party_add_field_value3":
        this.setState({ party_add_field_value3: value });
        break;
      case "party_group":
        errors.category = value.length < 0 ? "*Please Select Party Group" : "";
        if (value == "AddNew") {
          this.openModalHandler("modalAdjustPartyGroup");
        }
        this.setState({ party_group: value });
        break;

      default:
        break;
    }
  };

  clickToAddParty = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    console.log("==dd==", this.state.party_opening_balance);
    if (this.state.party_name == "") {
      _isError = true;
      errors["party_name"] = "*Please Enter party Name";
      this.setState({ errors: errors });
    }
    /*
    if (this.state.party_contact_no == "") {
      _isError = true;
      errors["party_contact_no"] = "*Please Enter phone no.";
      this.setState({ errors: errors });
    }
    -> By Tabish */

    /*if(this.state.party_billing_address == '')
        {
            _isError = true;
            errors['party_billing_address'] = "*Please Enter billing address";
            this.setState({errors: errors});
        }
        if( (this.state.party_shipping_address == ''|| this.state.party_shipping_address == null) && this.state.PartyShippingAddress == 1)
        {
            _isError = true;
            errors['party_shipping_address'] = "*Please Enter shipping address";
            this.setState({errors: errors});
        }

        if(this.state.party_email_id == '')
        {
            _isError = true;
            errors['party_email_id'] = "*Please Enter email id";
            this.setState({errors: errors});
        }*/
    if (this.state.party_opening_balance == "") {
      _isError = true;
      errors["party_opening_balance"] = "*Please Enter opening balance amount";
      this.setState({ errors: errors });
    }
    if (this.state.party_opening_date == "") {
      _isError = true;
      errors["party_opening_date"] = "*Please Enter As of date";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.props.partyId > 0) {
        this.editPartyMethodCall();
      } else {
        this.addPartyMethodCall();
      }
    }
  };

  addPartyMethodCall() {
    let partyToPay = 0;
    let partyToReceive = 1;
    if (this.state.payType == "Pay") {
      partyToReceive = 0;
      partyToPay = 1;
    }
    let _PartyAddField1Name = null;
    let _PartyAddField2Name = null;
    let _PartyAddField3Name = null;
    if (this.state.PartyAddField1Chk == 1) {
      _PartyAddField1Name = this.state.PartyAddField1Name;
    }
    if (this.state.PartyAddField2Chk == 1) {
      _PartyAddField2Name = this.state.PartyAddField2Name;
    }
    if (this.state.PartyAddField3Chk == 1) {
      _PartyAddField3Name = this.state.PartyAddField3Name;
    }
    const requestData = JSON.stringify({
      businessId: this.state.businessId,
      userId: this.state.userId,
      company_id: this.state.company_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_billing_address: this.state.party_billing_address,
      party_shipping_address: this.state.party_shipping_address,
      party_email_id: this.state.party_email_id,
      party_opening_balance: this.state.party_opening_balance,
      party_opening_date: moment(this.state.party_opening_date).format(
        "MMMM D, YYYY"
      ),
      gst_type_id: this.state.selGstTypeId,
      gst_number: this.state.gst_number,
      countryState: this.state.selStateId,
      party_to_pay: partyToPay,
      party_to_receive: partyToReceive,
      party_add_field_key1: _PartyAddField1Name,
      party_add_field_key2: _PartyAddField2Name,
      party_add_field_key3: _PartyAddField3Name,
      party_add_field_value1: this.state.party_add_field_value1,
      party_add_field_value2: this.state.party_add_field_value2,
      party_add_field_value3: this.state.party_add_field_value3,
      party_group: this.state.party_group,
    });

    PostData(global.userAddParty, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          window.location.href = global.webUrl + this.state.isRedirectTo;
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        return;
      }
    });
  }

  editPartyMethodCall() {
    let partyToPay = 0;
    let partyToReceive = 1;
    if (this.state.payType == "Pay") {
      partyToReceive = 0;
      partyToPay = 1;
    }
    let _PartyAddField1Name = null;
    let _PartyAddField2Name = null;
    let _PartyAddField3Name = null;
    if (this.state.PartyAddField1Chk == 1) {
      _PartyAddField1Name = this.state.PartyAddField1Name;
    }
    if (this.state.PartyAddField2Chk == 1) {
      _PartyAddField2Name = this.state.PartyAddField2Name;
    }
    if (this.state.PartyAddField3Chk == 1) {
      _PartyAddField3Name = this.state.PartyAddField3Name;
    }
    let _party_opening_balance = this.state.party_opening_balance;
    if (partyToReceive == 1) {
      _party_opening_balance = Math.abs(this.state.party_opening_balance);
    }

    const requestData = JSON.stringify({
      partyId: this.state.partyId,
      businessId: this.state.businessId,
      company_id: this.state.company_id,
      party_name: this.state.party_name,
      party_contact_no: this.state.party_contact_no,
      party_billing_address: this.state.party_billing_address,
      party_shipping_address: this.state.party_shipping_address,
      party_email_id: this.state.party_email_id,
      party_opening_balance: _party_opening_balance,
      party_opening_date: moment(this.state.party_opening_date).format(
        "MMMM D, YYYY"
      ),
      gst_type_id: this.state.selGstTypeId,
      gst_number: this.state.gst_number,
      countryState: this.state.selStateId,
      party_to_pay: partyToPay,
      party_to_receive: partyToReceive,
      party_add_field_key1: _PartyAddField1Name,
      party_add_field_key2: _PartyAddField2Name,
      party_add_field_key3: _PartyAddField3Name,
      party_add_field_value1: this.state.party_add_field_value1,
      party_add_field_value2: this.state.party_add_field_value2,
      party_add_field_value3: this.state.party_add_field_value3,
      party_group: this.state.party_group,
    });

    PostData(global.userEditParty, requestData, "POST").then((result) => {
      if (result.success == true) {
        sendNotification("Success Message 👍", result.message, "success");
        setTimeout(() => {
          window.location.href = global.webUrl + this.state.isRedirectTo;
        }, Math.floor(Math.random() * 5000) + 1);
      } else {
        sendNotification("Error Message 😓", result.message, "danger");
        return;
      }
    });
  }

  onRadioChange = (e) => {
    this.setState({
      payType: e.target.value,
    });
  };

  handleCallBackDate = (start) => {
    this.setState({ party_opening_date: start.format("MMMM D, YYYY") });
  };

  isDateRefresh = () => {
    let _invoiceDate = moment().format("MM/DD/YYYY");
    if (
      this.state.party_opening_date == "Invalid date" ||
      this.state.party_opening_date == "" ||
      this.state.party_opening_date == "null" ||
      this.state.party_opening_date == null
    ) {
      _invoiceDate = moment().format("MM/DD/YYYY");
    } else {
      _invoiceDate = this.state.party_opening_date;
    }
    if (_isEditMode == true && this.state.partyId > 0) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: _invoiceDate,
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    } else if (
      this.state.isLoaded == true &&
      _isEditMode == false &&
      this.state.partyId == 0
    ) {
      return (
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            startDate: moment().format("MM/DD/YYYY"),
          }}
          onCallback={this.handleCallBackDate}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }
  };

  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }
    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <form method="post" id="form-login" className="webForm px-30">
          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Personal Details{" "}
          </h3>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="party_name"
                  name="party_name"
                  value={this.state.party_name}
                  placeholder="Enter Party Name"
                  onChange={this.handleChange}
                />
                {/* readOnly={this.state.readOnly} */}
                <label htmlFor="login-username">
                  Party Name <span className="text-danger">*</span>
                </label>
                <span className="iris_error_txt">
                  {errors.party_name.length > 0 && (
                    <span className="error">{errors.party_name}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  className="form-control"
                  id="party_contact_no"
                  name="party_contact_no"
                  value={this.state.party_contact_no}
                  placeholder="Enter Phone No."
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Phone No. <span className="text-danger"></span>
                </label>
                <span className="iris_error_txt">
                  {errors.party_contact_no.length > 0 && (
                    <span className="error">{errors.party_contact_no}</span>
                  )}
                </span>
              </div>
            </div>
          </div>

          {this.state.PartyGrouping == 1 && (
            <div className="form-group row">
              <div className="col-6">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="party_group"
                    name="party_group"
                    onChange={this.handleChange}
                  >
                    <option value="">Select Party</option>
                    <option value="AddNew">Add new group</option>
                    {this.state.partyGroupArray.map((itemCat, index) => {
                      let _selCat = "";
                      if (this.state.party_group == itemCat.id) {
                        _selCat = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={itemCat.id}
                          selected={_selCat}
                        >
                          {itemCat.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">Select Party Group</label>
                </div>
                <div id="party_group-error" className="animated fadeInDown">
                  {errors.party_group.length > 0 && (
                    <span className="error">{errors.party_group}</span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="claerfix"></div>

          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Address{" "}
          </h3>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  required="required"
                  className="form-control"
                  id="party_billing_address"
                  name="party_billing_address"
                  value={this.state.party_billing_address}
                  placeholder="Enter Billing Address"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-address">
                  Billing Address
                  {/* <span className="text-danger">*</span> */}
                </label>
                <span className="iris_error_txt">
                  {errors.party_billing_address.length > 0 && (
                    <span className="error">
                      {errors.party_billing_address}
                    </span>
                  )}
                </span>
              </div>
            </div>
            {this.state.PartyShippingAddress == 1 && (
              <div className="col-6">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="party_shipping_address"
                    name="party_shipping_address"
                    value={this.state.party_shipping_address}
                    placeholder="Enter Shipping Address"
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-address">
                    Shipping Address
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <span className="iris_error_txt">
                    {errors.party_shipping_address.length > 0 && (
                      <span className="error">
                        {errors.party_shipping_address}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="email"
                  className="form-control"
                  id="party_email_id"
                  name="party_email_id"
                  value={this.state.party_email_id}
                  placeholder="Enter Email Id"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Email Id
                  {/* <span className="text-danger">*</span> */}
                </label>
                <span className="iris_error_txt">
                  {errors.party_email_id.length > 0 && (
                    <span className="error">{errors.party_email_id}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="claerfix"></div>

          <div className={this.state.GSTINNumberClass}>
            <h3 className="content-heading" style={{ paddingTop: "6px" }}>
              <i className="fa fa-angle-right"></i> {tax_name}{" "}
            </h3>
            <div className="form-group row">
              <div className="col-4">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="selGstTypeId"
                    name="selGstTypeId"
                    onChange={this.handleChange}
                  >
                    <option value="" selected>
                      Select {tax_name}
                    </option>
                    {gstTypeData.map((typeData, index) => {
                      let _selGstType = "";
                      if (this.state.selGstTypeId == gstTypeData[index]["id"]) {
                        _selGstType = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={gstTypeData[index]["id"]}
                          selected={_selGstType}
                        >
                          {gstTypeData[index]["name"]}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">{tax_name} Type</label>
                </div>
                <div id="selGstTypeId-error" className="animated fadeInDown">
                  {errors.selGstTypeId.length > 0 && (
                    <span className="error">{errors.selGstTypeId}</span>
                  )}
                </div>
              </div>

              {_businessSetting.GSTINNumber > 0 &&
                this.state.selGstTypeId!=1 && (
                  <div className={this.state.hideShow + " col-4"}>
                    <div className="form-material open">
                      <input
                        type="text"
                        className="form-control"
                        id="gst_number"
                        name="gst_number"
                        value={this.state.gst_number}
                        placeholder={"Enter " + tax_name + " Number"}
                        maxlength="10"
                        onChange={this.handleChange}
                      />
                      <label htmlFor="login-username">{tax_name} Number</label>
                      <span className="vs_error_txt">
                        {errors.gst_number.length > 0 && (
                          <span className="error">{errors.gst_number}</span>
                        )}
                      </span>
                    </div>
                  </div>
                )}

              <div className="col-4">
                <div className="form-material open">
                  <select
                    className="form-control"
                    id="selStateId"
                    name="selStateId"
                    onChange={this.handleChange}
                  >
                    <option value=""> Please select</option>
                    {this.state.stateArray.map((item, index) => {
                      let _selState = "";
                      if (this.state.selStateId == item.name) {
                        _selState = "selected";
                      }
                      return (
                        <option
                          key={index}
                          value={item.name}
                          selected={_selState}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="login-password">Select State</label>
                </div>
                <div id="selStateId-error" className="animated fadeInDown">
                  {errors.selStateId.length > 0 && (
                    <span className="error">{errors.selStateId}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="claerfix"></div>
          </div>

          <h3 className="content-heading" style={{ paddingTop: "6px" }}>
            <i className="fa fa-angle-right"></i> Opening Balance{" "}
          </h3>
          <div className="form-group row">
            <div className="col-6">
              <div className="form-material open">
                <input
                  type="text"
                  min="0"
                  maxLength="10"
                  required="required"
                  className="form-control"
                  id="party_opening_balance"
                  name="party_opening_balance"
                  value={Math.abs(this.state.party_opening_balance)}
                  placeholder="Enter Opening Balance"
                  onChange={this.handleChange}
                />
                <label htmlFor="login-username">
                  Opening Balance <span className="text-danger">*</span>
                </label>
                <span className="vs_error_txt">
                  {errors.party_opening_balance.length > 0 && (
                    <span className="error">
                      {errors.party_opening_balance}
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                {this.isDateRefresh()}
                <label htmlFor="login-username">As of date</label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-material open">
                <input
                  disabled={
                    this.state.party_opening_balance == 0 ? true : false
                  }
                  type="radio"
                  value="Pay"
                  defaultChecked
                  checked={this.state.payType == "Pay" ? "checked" : ""}
                  id="Pay"
                  name="payType"
                  onChange={this.onRadioChange}
                />{" "}
                &nbsp;&nbsp;To Pay&nbsp;&nbsp;
                <input
                  disabled={
                    this.state.party_opening_balance == 0 ? true : false
                  }
                  type="radio"
                  value="Receive"
                  checked={this.state.payType == "Receive" ? "checked" : ""}
                  id="Receive"
                  name="payType"
                  onChange={this.onRadioChange}
                />
                &nbsp;&nbsp; To Receive
              </div>
            </div>
          </div>

          {/* <div className="claerfix"></div> */}

          {(this.state.PartyAddField1Chk == 1 ||
            this.state.PartyAddField2Chk == 1 ||
            this.state.PartyAddField3Chk == 1) && (
            <h3 className="content-heading" style={{ paddingTop: "6px" }}>
              <i className="fa fa-angle-right"></i> Additional Fields
            </h3>
          )}

          <div className="form-group row">
            {this.state.PartyAddField1Chk == 1 && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="text"
                    className="form-control"
                    id="party_add_field_value1"
                    name="party_add_field_value1"
                    value={this.state.party_add_field_value1}
                    placeholder={this.state.PartyAddField1Name}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">
                    {this.state.PartyAddField1Name}
                  </label>
                </div>
              </div>
            )}
            {this.state.PartyAddField2Chk == 1 && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="text"
                    required="required"
                    className="form-control"
                    id="party_add_field_value2"
                    name="party_add_field_value2"
                    value={this.state.party_add_field_value2}
                    placeholder={this.state.PartyAddField2Name}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">
                    {this.state.PartyAddField2Name}
                  </label>
                </div>
              </div>
            )}
            {this.state.PartyAddField3Chk == 1 && (
              <div className="col-4">
                <div className="form-material open">
                  <input
                    type="text"
                    min="0"
                    required="required"
                    className="form-control"
                    id="party_add_field_value3"
                    name="party_add_field_value3"
                    value={this.state.party_add_field_value3}
                    placeholder={this.state.PartyAddField3Name}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="login-username">
                    {this.state.PartyAddField3Name}
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="claerfix"></div>

          <div className="form-group row">
            <div className="col-12">
              {/* <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddParty} disabled={isDisabled}> */}
              <button
                type="submit"
                className="btn btn-alt-primary"
                onClick={this.clickToAddParty}
                disabled={isDisabled}
              >
                <i className="fa fa-check mr-5"></i> {this.state.buttonTitle}{" "}
                Party Name
              </button>
            </div>
          </div>
        </form>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_PartyGroup}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustPartyGroup"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Add Party Group</h3>
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustPartyGroup"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewPartyGroup
                    isRedirectTo={"business/add-item/"}
                    callBack={this.getUpdateResponse}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustPartyGroup"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}
      </React.Fragment>
    );
  }
}
