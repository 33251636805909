import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import { MDBDataTableV5 } from 'mdbreact';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';

let columns = [
    {
        label: '#',
        field: 'srNo',
        width: 20,
        attributes: {
        'aria-controls': 'DataTable',
        'aria-label': '#',
        },
    },
    {
        label: 'Name',
        field: 'name',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        width: 100,
    }
];
export default class gstFormMerge extends Component{
    constructor(props){
        super(props);
        this.state = {
            buttonTitle:'Add New',
            isLoaded : true,
            gstName : null,
            gstRatio : null,
            countryCode : this.props.countryCode,
            gstArray : [],
            gstId : 0,
            modalIsOpen_CTA : false,
            errors: {
                gstName : '',
                gstRatio: ''
            }
        }
    }

    async componentDidMount ()
    {
        this.getGstRateListData();
    }

    getGstRateListData(){
        let _gstArray = [];

        let _url_GetData = global.gstList+'?countryCode='+this.props.countryCode;
        PostData(_url_GetData,'','GET').then(result => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true,gstArray:[]});                
            }
            else
            {
                if (responseJson.response) {
                    _gstArray = responseJson.response;
                }
                this.setState({gstArray:_gstArray,isLoaded:true});               
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }

    gstTaxRateListing()
    {   
        if(this.state.isLoaded == true)
        {
            let _gstDetails = this.state.gstArray;
            let dataList = [];
            let rows = [];

            if(_gstDetails.length > 0)
            {                          
                _gstDetails.map((compDatas, i) =>
                {
                    const row = compDatas;
                    
                    rows.push({
                        name: row.name,
                        ratio: row.ratio,
                        srNo: row.srNo,
                        action:(
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="btnGroupVerticalDrop3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="si si-list"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                                    <a className="dropdown-item" onClick={this.openModalHandlerTax.bind(this,'modalAdjustTax',row.id)}>
                                        <i className="si si-pencil mr-5 text-info"></i>Edit Details
                                    </a>
                                </div>
                            </div>
                        )
                    });
                })
            }

            if (rows) {
                dataList = {
                    columns: columns,
                    rows: rows
                };
            }
            return dataList;
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({isDisabled:false});
        switch (name) {
            case 'gstName': 
            errors.gstName = 
                value.length < 3 ? '*Name must be 3 characters long!' : '';
                this.setState({gstName:value}); 
            break;
            case 'gstRatio': 
                errors.gstRatio = 
                value < 0 ? '*Ratio must be 0 or greater than 0!' : '';

                errors.gstRatio = 
                value > parseFloat(100) ? '*Ratio can not be more than 100!' : '';
                this.setState({gstRatio:value}); 
            break;
            default:
            break;
        }
    }

    clickToAddGst = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.gstName == null || this.state.gstName == '')
        {
            _isError = true;
            errors['gstName'] = "*Please Enter GST Type Name";
            this.setState({errors: errors});
        }
        if(this.state.gstRatio == null || this.state.gstRatio == '')
        {
            _isError = true;
            errors['gstRatio'] = "*Please Enter Ratio";
            this.setState({errors: errors});
        }
        

        if(_isError == false){
            if(this.state.gstId > 0){
                this.editGstMethodCall();
            }
            else{
                this.addGstMethodCall();
            }
        }
    }

    editGstMethodCall()
    {
        const requestData = JSON.stringify({
            gst_name: this.state.gstName,
            gst_ratio: this.state.gstRatio,
            gstId: this.state.gstId,
            countryCode:this.props.countryCode
        });
        PostData(global.adminEditGst, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                this.getGstRateListData();
                this.closeModalHandler('modalAdjustTax');
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['gstName'] = result.message;
                errors['gstRatio'] = result.message;
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    addGstMethodCall(){
        const requestData = JSON.stringify({
            gst_name: this.state.gstName,
            gst_ratio: this.state.gstRatio,
            countryCode:this.props.countryCode
        });

        PostData(global.adminAddGst, requestData,'POST').then((result) => {
            if(result.success == true)
            {
                sendNotification("Success Message 👍",result.message,"success");
                this.getGstRateListData();
                this.closeModalHandler('modalAdjustTax');
            }
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                let errors = this.state.errors;
                errors['gstName'] = result.message;
                errors['gstRatio'] = result.message;
                this.setState({
                    errors:errors
                })
                return;
            }
        });
    }

    getTaxRateData(gstId){
        if(gstId > 0){
            let _gstArray = [];
            let _url_GetData = global.gstDetails+'?gstId='+gstId;
            PostData(_url_GetData,'','GET').then(result => {
                let responseJson = result;
                if(responseJson.success == false)
                {  
                    this.setState({isLoaded:true});                
                }
                else
                {
                    if (responseJson.response) {
                        _gstArray = responseJson.response[0];
                        this.setState({buttonTitle:'Edit',gstName:_gstArray.gst,gstId:_gstArray.gst_id,gstRatio:_gstArray.ratio,isLoaded:true});
                    }
                }             
            })
            .catch(error => this.setState({ error, isLoaded: false }));
        }
    }

    /*For Modal open close*/
    openModalHandlerTax(popId,taxId)
    {
        if(popId == 'modalAdjustTax'){
            this.setState({modalIsOpen_CTA: true});
            if(taxId > 0){
                this.getTaxRateData(taxId);
            }
            else{                
                this.setState({gstName:'',gstRatio:'0',buttonTitle:'Add'});
            }
        }
    }
    closeModalHandler = (popId) => {
        if(popId == 'modalAdjustTax'){
            this.setState({modalIsOpen_CTA: false});
        }
    }
    /*End Modal open close*/

    render(){
        
        if(this.state.countryCode!=this.props.countryCode){
            this.setState({countryCode:this.props.countryCode});
            this.getGstRateListData();
        }

        const {errors} = this.state;        
        const isDisabled = Object.keys(errors).some(x => errors[x]);

        return(
            <div>
                <h3 className="block-title">Manage {this.props.taxName} Rate List
                    <button type="button" className="btn btn-sm btn-secondary mr-5 mb-5 pull-right" onClick={this.openModalHandlerTax.bind(this,'modalAdjustTax')}>
                        <i className="si si-equalizer mr-5"></i>Add New {this.props.taxName} Rate
                    </button>
                </h3>

                <MDBDataTableV5 
                hover
                entriesOptions={[25, 50, 100]}
                entries={25}
                pagesAmount={4}
                span
                pagingTop
                searchTop
                searchBottom={false}
                barReverse
                data={this.gstTaxRateListing()} />

                {/* Modal Party Add Begins */}
                <Modal isOpen={this.state.modalIsOpen_CTA} ariaHideApp={false} size="sm" aria-labelledby="modal-popout" onRequestClose={this.closeModalHandler} id="modalAdjustTax">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="block block-themed block-transparent mb-0">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Add / Edit {this.props.taxName} Rate</h3>
                                    <div className="block-options">
                                        <button type="button" className="btn-block-option" onClick={this.closeModalHandler.bind(this,'modalAdjustTax')}>
                                            <i className="si si-close"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="block-content">
                                    <div className="block-content">
                                        <form method="post" id="form-login" className="webForm px-30">
                                            <div className="form-group row">
                                                <div className="col-6">
                                                    <div className="form-material open">
                                                        <input type="text" required="required" className="form-control" id="gstName" name="gstName" value={this.state.gstName} placeholder={this.props.taxName+" Name Here"} onChange={this.handleChange}/>
                                                        <label htmlFor="login-username">{this.props.taxName} Name <span className="text-danger">*</span></label>
                                                        <span className="iris_error_txt">{errors.gstName.length > 0 && <span className='error'>{errors.gstName}</span>}</span>
                                                        
                                                        {/* <label htmlFor="login-username">{this.props.taxName} Name <span className="text-danger">*</span></label>
                                                        <select className="form-control" id="gstName" name="gstName" onChange={this.handleChange} required="required">
                                                            <option value=""> Select {this.props.taxName+" Name Here"}</option>
                                                            {
                                                                this.state.gstArray.map((item,index) => {
                                                                    let _sel = '';
                                                                    if(this.state.gstName == item.name){_sel = 'selected';}
                                                                    return(            
                                                                    <option key={index} value={item.name} selected={_sel}>{item.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select> */}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-material open">
                                                        <input type="number" min="0" required="required" className="form-control" id="gstRatio" name="gstRatio" value={this.state.gstRatio} placeholder={this.props.taxName+" Ratio Here"} onChange={this.handleChange}/>
                                                        <label htmlFor="login-username">{this.props.taxName} Ratio <span className="text-danger">*</span></label>
                                                        <span className="iris_error_txt">{errors.gstRatio.length > 0 && <span className='error'>{errors.gstRatio}</span>}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <button type="submit" disabled={isDisabled} className="btn btn-alt-primary" onClick={this.clickToAddGst}>
                                                        <i className="fa fa-check mr-5"></i> {this.state.buttonTitle} {this.props.taxName} Rate
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <br/>
                                    
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-alt-secondary" onClick={this.closeModalHandler.bind(this,'modalAdjustTax')}>Close</button>
                                
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Modal End Party Add*/}
            </div>
        )    
    }
}