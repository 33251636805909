import React,{Component} from 'react';

//----Include Fragment Pages-----
import HeaderSection from '../websiteFolder/web_fragment/header';
import FooterPage from '../websiteFolder/web_fragment/footer';
//----End Include Fragment Pages-----

export default class demoVideosPagePanel extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : false,
            isLoggedin : false
        }
    }

    async componentDidMount(){
        this.setState({isLoaded:true});
    }

    render(){
        
        return(
            <div id="page-wrapper">
                <React.Fragment>
                    <div id="main" className="mainSectionWebsite">
                        <section className="header">
                            <div className="container">
                                <HeaderSection />
                                {/* <!-- Banner --> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="txt-style-6">Demo Videos</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                                               
                    
                        <section className="sec4 sec4C sec4D">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/BGmiDeuEgp0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-sm-4">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/BGmiDeuEgp0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div className="col-sm-4">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/BGmiDeuEgp0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        </section>

                        {/* Footer Code Here */}
                        <FooterPage />
                    </div>
                </React.Fragment>                
            </div>
        )
    }
}