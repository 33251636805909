import React,{Component} from 'react';


let _sessionPeriod = true;
let _UserSession = localStorage.getItem('vs_UserSession');
let _signingName = 'Administrator';
if(_UserSession != undefined || _UserSession != null)
{

    console.log('_UserSession', JSON.stringify(_UserSession))
    _sessionPeriod = false;
    _UserSession = JSON.parse(_UserSession);
    _signingName = _UserSession.loginName;
}

export default class TopHeaderPage extends Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn : _sessionPeriod,
            loggedName : _signingName,
            isSessionOff : _sessionPeriod
        }
    }

    handleLogoutClick = (event) => {
        localStorage.removeItem('vs_UserSession');
        this.setState({isSessionOff:true})
        //window.location.href = ("/");
    }
    //componentDidMount(){}

    render(){
        if (this.state.isSessionOff == true)
        {
            //console.log('Session Expire...!');   
            //this.logoutCallBack();         
            window.location.href = global.webUrl+'login/';
        }
        return (
            <React.Fragment>
                <header id="page-header">
                    <div className="content-header">
                        <div className="content-header-section">
                            <button type="button" className="btn btn-circle btn-dual-secondary" data-toggle="layout" data-action="sidebar_toggle">
                                <i className="fa fa-navicon"></i>
                            </button>
                            <div className="btn-group" role="group">
                                <div className="dateTime" style={{padding: '15px 15px'}}>
                                    <span><i className="gi gi-calendar"></i> </span><span id="date-time"></span>
                                </div>
                            </div>
                        </div>
                        <div className="content-header-section">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-rounded btn-dual-secondary" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-user d-sm-none"></i>
                                    <span className="d-none d-sm-inline-block">{this.state.loggedName}</span>
                                    <i className="fa fa-angle-down ml-5"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right min-width-200" aria-labelledby="page-header-user-dropdown">
                                    <h5 className="h6 text-center py-10 mb-5 border-b text-uppercase">User</h5>
                                    <a className="dropdown-item" href={global.webUrl+"administrator/profile/"}>
                                        <i className="si si-user mr-5"></i> Profile
                                    </a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={this.handleLogoutClick} data-box="#mb-signout" href={() => false}>
                                        <i className="si si-logout mr-5"></i> Sign Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="page-header-search" className="overlay-header">
                        <div className="content-header content-header-fullrow">
                            <form action="be_pages_generic_search.php" method="post">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button type="button" className="btn btn-secondary" data-toggle="layout" data-action="header_search_off">
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input"/>
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-secondary">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="page-header-loader" className="overlay-header bg-primary">
                        <div className="content-header content-header-fullrow text-center">
                            <div className="content-header-item">
                                <i className="fa fa-sun-o fa-spin text-white"></i>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

