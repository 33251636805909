import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import Modal from 'react-modal';
import { numberFormat } from '../../../config/numberFormat';
import moment from 'moment';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../custom_theme.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { MDBDataTableV5 } from 'mdbreact';
import ToggleSwitch from "../../../_toggleSwitch/toggleSwitch";
import { parse } from 'date-fns';

let _signingId = '0';
let _businessId = '0';
let _companyId = '0';
let _businessSetting = {};
let _loader = 'glb-ldr-prt active';

let columns = [
    {
        label: '#',
        field: 'checkbox',
        width: 100,
    },
    {
        label: 'Date',
        field: 'date',
        width: 100,
    },
    {
        label: 'Invoice No',
        field: 'receipt_no',
        width: 100,
    },
    {
        label: 'Type',
        field: 'transaction_type',
        width: 100,
    },
    {
        label: 'Total Amount',
        field: 'total_amount',
        width: 100,
    },
    {
        label: 'Current Balance',
        field: 'current_balance',
        width: 100,
    },
    {
        label: 'Link Amount',
        field: 'link_amount',
        width: 100,
    }
];
export default class quickAddLinkOtherTransaction extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
            _businessId = _UserSession.tokenKey;
            _businessSetting = _UserSession.businessSetting;
        }
        
        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _companyId = _companySession.company_id;
        }
        
        let today = new Date();
        let  priorDate = new Date(new Date().setDate(today.getDate() - 365));
        this.state = {
            isLoaded : true,
            otherTransationArray :  this.props.otherTransationArray ?  this.props.otherTransationArray : [],
            receivedAmountSend :  this.props.receivedAmountSend ?  this.props.receivedAmountSend : 0,
            PaymentDiscountPayInAmount : this.props.PaymentDiscountPayInAmount ? this.props.PaymentDiscountPayInAmount : 0,
            totalAmount : this.props.totalAmount ? this.props.totalAmount :0,
            transactionId :  this.props.transactionId ?  this.props.transactionId : 0,
            partyId :  this.props.partyId ?  this.props.partyId : 0,
            otherTransationPayInArray :  this.props.otherTransationPayInArray ?  this.props.otherTransationPayInArray : [],
            toggleStateSelALL : this.props.toggleStateSelALL ?  this.props.toggleStateSelALL :  false,
            noCheck : '',
            startDate : priorDate,//moment().startOf('month').toDate(),
            endDate : moment().endOf('month').toDate(),
            totalLinkPayAmt : 0,
            totalShowAmount : 0,
            finalLinkTotalAmt : 0,
            unUsedAmount : this.props.unUsedAmount ?  this.props.unUsedAmount : 0,
            pageLoadCount :0,
            toggleState : false,
            typeSend : "ALL",
            totalLinkedAMt : 0, 
            isSaveBtn :  this.props.isSaveBtn ? this.props.isSaveBtn : false,
            clickData : "Normal",
            totalTransCount : this.props.totalTransCount ? this.props.totalTransCount : 5,
            errors: {
                noCheck : '',
            }
        }
    }
    
    
    componentDidMount()
    {  
        let otherTransationPayInArray = this.state.otherTransationArray;
        
        let _totalShowAmount = parseFloat(this.state.receivedAmountSend) + parseFloat(this.state.PaymentDiscountPayInAmount);
        if(otherTransationPayInArray.length >0){
            _totalShowAmount = parseFloat(this.state.receivedAmountSend) + parseFloat(this.state.PaymentDiscountPayInAmount);
        }
        if(otherTransationPayInArray.length == 0 || otherTransationPayInArray.length == '0'){
            let typeSend = "ALL";
            this.getOtherTransListData(typeSend);
        }
        this.setState({
            totalShowAmount : _totalShowAmount,
            toggleStateSelALL : this.state.toggleStateSelALL,
            toggleState : this.state.toggleStateSelALL,
            otherTransationArray : otherTransationPayInArray,
        });

        if(this.state.unUsedAmount !== this.state.receivedAmountSend){
            this.setState({
                toggleStateSelALL : false,
                toggleState : false,
            });
        }
        let otherTransationArrayChkPageLoan = this.state.otherTransationArray;
        
        otherTransationArrayChkPageLoan.map((payTransData,index) =>{
            if(payTransData.isSelected  == true){
                //this.setState({toggleStateSelALL :  true,toggleState :  true});
            }
        })
        
        let otherTransationArrForLen = otherTransationPayInArray.filter(otherTransationPayInArray => otherTransationPayInArray.isSelected == true);
        if(otherTransationArrForLen.length == 0){
            this.setState({toggleStateSelALL : false});
        }
        
    }
    
    clickInfo(e) {
        confirmAlert({
        title: "Alert",
        message:"Received amount can not be more the total amount.",
        buttons: [
            {
            label: "Ok",
            id : "AlertMsg",
            className : "demoMsg"
            },
        ],
        });
    }

    clickInfoSave(e) {
        confirmAlert({
        title: "Alert",
        message:"The received and total amount of linked invoices should be same. If you still want to proceed then update received amount first.",
        buttons: [
            {
            label: "Ok",
            id : "AlertMsg",
            className : "demoMsg"
            },
        ],
        });
    }

    onCheckChange(e) {
        const _otherTransationArray = this.state.otherTransationArray;
        
        let errors = this.state.errors; 
        let _oldTotalAmount = this.state.totalShowAmount;   
        
        this.setState({errors: errors});
        let _noCheck = this.state.pageLoadCount;
        let totalLinkedAMt =  this.state.totalLinkedAMt ?  this.state.totalLinkedAMt : 0;
        let _unUsedAmt =  this.state.unUsedAmount ? this.state.unUsedAmount : 0;
        let _totalLinkAMTAK = 0;
        let linkAmt = 0;
        let _isSaveBtn = false;
       // return false;

        _otherTransationArray.forEach(payData => {
            if (payData.transactionId == e.target.value)
            {    

                if(e.target.checked == true){
                    
                    _isSaveBtn = true;
                    //console.log(totalLinkedAMt+"==>--LINK-AMT-->=="+this.state.totalShowAmount);
                    
                    if(this.state.receivedAmountSend <= 0){                        
                        let _linkAmt = parseFloat(payData.balance_amount) ? parseFloat(payData.balance_amount)  : 0;
                        let _balanceAmt = 0;
                        
                        // if(_oldTotalAmount <= _linkAmt){
                        //     _linkAmt = _oldTotalAmount;
                        //     _balanceAmt = parseFloat(payData.balance_amount) - parseFloat(_linkAmt);
                        // }
                        payData.isSelected = e.target.checked;                           
                        payData.link_amount = _linkAmt;
                        payData.balance_amount = _balanceAmt;                        
                        _noCheck =  _noCheck + 1 ;
                        
                        console.log(_linkAmt+"==BBB--UN==",_unUsedAmt)
                        
                        console.log("==IFFFF--_unUsedAmt--"+_unUsedAmt);  
                    }
                    
                    else{
                        if(this.state.unUsedAmount <= 0){                        
                            this.clickInfo();
                            return;
                        }
                        let _linkAmt = payData.balance_amount ?  payData.balance_amount : 0;
                        let _balanceAmt = 0;
                        if(parseFloat(this.state.unUsedAmount) < parseFloat(_linkAmt) ){
                            _linkAmt = this.state.unUsedAmount;
                            _balanceAmt = parseFloat(payData.balance_amount) -  parseFloat(_linkAmt);
                        }
                        payData.isSelected = e.target.checked;                           
                        payData.link_amount = _linkAmt;
                        payData.balance_amount = _balanceAmt;
                        _noCheck =  _noCheck + 1 ;

                        _unUsedAmt =  parseFloat(_unUsedAmt) - parseFloat(_linkAmt);
                        
                        //console.log(_linkAmt+"==AA--UN==",_unUsedAmt)
                    }                  
                }
                 
                else { 
                    _unUsedAmt = parseFloat(this.state.unUsedAmount) + parseFloat(payData.link_amount);                    
                    _noCheck =  _noCheck - 1 ;
                    payData.isSelected = e.target.checked;  
                    if(this.state.receivedAmountSend <= 0){   
                        payData.balance_amount = parseFloat(payData.balance_amount) + parseFloat(payData.link_amount);
                        _unUsedAmt = 0 ;
                    }
                    else{
                        payData.balance_amount = payData.old_balance_amount;

                    }
                    _oldTotalAmount = parseFloat(_oldTotalAmount) - parseFloat(payData.link_amount);
                    payData.link_amount = 0;   
                    if(_oldTotalAmount == 0){
                        _oldTotalAmount = parseFloat(this.state.receivedAmountSend) + parseFloat(this.state.PaymentDiscountPayInAmount);
                    }
                    this.setState({toggleStateSelALL : false,clickData:"Normal"});
                }      
            }
            
            _totalLinkAMTAK = parseFloat(_totalLinkAMTAK) + (parseFloat(payData.link_amount) ? parseFloat(payData.link_amount) : 0);
            if(this.state.toggleStateSelALL == true && this.state.receivedAmountSend <= 0){
                _totalLinkAMTAK = 0;
                _unUsedAmt = 0;
            }
        });
        console.log("==KKHH===",_totalLinkAMTAK);
        let otherTransationArrForLen = _otherTransationArray.filter(_otherTransationArray => _otherTransationArray.isSelected == true);
        if(otherTransationArrForLen.length == 0){
            this.setState({toggleStateSelALL : false,clickData:"Normal"});
        }
        this.setState({ otherTransationArray: _otherTransationArray, pageLoadCount:_noCheck,unUsedAmount :_unUsedAmt,totalLinkedAMt: totalLinkedAMt,finalLinkTotalAmt : _totalLinkAMTAK, clickData:"Normal",isSaveBtn : _isSaveBtn });
        this.loadItemListing("Normal");
    }
    
    
    handleChange(i, e,_type,transactionId) {
        let errors = this.state.errors;
        let _isError = false;
        let otherTransationArray = this.state.otherTransationArray;
        console.log("===aa==",e.target.value);
        let finalStock = 0 ;
        let totalLinkedAMt =  0;
        let _unUsedAmt = 0;
        let _linkAmtAK = 0;
        let _totalLinkAMTAK = 0;
        let linkAmt  = 0;
        let totalRecevDisAmt = parseFloat(this.state.receivedAmountSend) + parseFloat(this.state.PaymentDiscountPayInAmount);
        
        let balanceAmt  = 0;
        let unused  = 0;
        otherTransationArray.map((payTransData,index) =>{
            if(payTransData.link_amount == ''){
                linkAmt = 0;
            }            
            totalLinkedAMt += (index == i ? (e.target.value ? parseFloat(e.target.value) : 0) : parseFloat(payTransData.link_amount ? payTransData.link_amount : 0));
            
            console.log(totalLinkedAMt+"==>--LINK-AMT-->=="+this.state.totalShowAmount);


            if( ((parseFloat(totalLinkedAMt) > parseFloat(this.state.totalShowAmount)) && this.state.receivedAmountSend > 0) ){
                console.log(totalLinkedAMt+"==>--QQQQ-->=="+this.state.totalShowAmount);
                this.clickInfo();
                return false;
            } 
            else{      

                let linkAmt  =  payTransData.link_amount ? payTransData.link_amount : 0;
                //console.log("==rrr==",e.target.value);
                if(parseFloat(e.target.value) <= parseFloat(payTransData.old_balance_amount)){
                    console.log("==eeee==",e.target.value);
                    if(payTransData.link_amount == ''){
                        linkAmt = 0;
                    }
                    _linkAmtAK = parseFloat(linkAmt) + parseFloat(e.target.value);
    
                    if(transactionId == payTransData.transactionId){
                        finalStock = parseFloat(payTransData.used_stock) + parseFloat(payTransData.free_quantity);
                        otherTransationArray[i][e.target.name] = e.target.value;
                        payTransData.balance_amount =  parseFloat(payTransData.old_balance_amount) - parseFloat(payTransData.link_amount); 
                        if(e.target.value  == '' || e.target.value == 0){
                            
                            payTransData.balance_amount =  parseFloat(payTransData.balance_amount);
                        }
                            
                        if(e.target.value > 0){
                            payTransData.isSelected = true;  
                        }
                        if(e.target.value == 0 || e.target.value == '0'){
                            payTransData.isSelected = false;  
                            this.setState({toggleStateSelALL : false});
                        }
                        this.setState({ otherTransationArray });
                    }
                    console.log(_linkAmtAK+"==KK==LINK=--",totalLinkedAMt);           
                    
    
                    if(transactionId == payTransData.transactionId && (e.target.value == 0 || e.target.value == '')){
                        payTransData.isSelected = false;  
                    }
                            
                    if(totalRecevDisAmt < totalLinkedAMt) {
                        totalRecevDisAmt = totalLinkedAMt;
                    }  
                    
                    if(this.state.receivedAmountSend == 0 || this.state.receivedAmountSend == '0'){
                        _unUsedAmt = 0;
                    }
                    else{
                                                
                        if(this.state.transactionId == 0){
                            if(this.state.receivedAmountSend == _linkAmtAK){
                                _unUsedAmt = 0;
                            }
                            else if(totalRecevDisAmt > _linkAmtAK){
                                _unUsedAmt = parseFloat(this.state.receivedAmountSend) - parseFloat(_linkAmtAK);
                                console.log(payTransData.invoice_no+"==aa--dd--=="+_unUsedAmt+"==aa--dd--=="+totalRecevDisAmt);
                            }                        
                            else{
                                _unUsedAmt = parseFloat(this.state.totalShowAmount) - parseFloat(_linkAmtAK);  
                                if(_unUsedAmt < 0){
                                    _unUsedAmt = 0;
                                }                            
                                console.log(_linkAmtAK+"==>>=="+totalLinkedAMt+"==WWW--_unUsedAmt--"+_unUsedAmt);
                                console.log(payTransData.transactionId+"==bb--ww=="+_unUsedAmt+"==bb--ww=="+_unUsedAmt);
                            } 
                        }
                        if(this.state.transactionId > 0){            
                            if(totalRecevDisAmt > _linkAmtAK){
                                _unUsedAmt = parseFloat(totalRecevDisAmt) -  parseFloat(_linkAmtAK);
                            }
                        }
                    }                 
                    if(this.state.receivedAmountSend == 0){
                        _totalLinkAMTAK = 0;
                    }else{
                        _totalLinkAMTAK = parseFloat(_totalLinkAMTAK) + (parseFloat(payTransData.link_amount) ? parseFloat(payTransData.link_amount) : 0);
                    }
                    console.log(_totalLinkAMTAK+"==FINAL--_unUsedAmt--"+_unUsedAmt);
                    if(this.state.receivedAmountSend > 0){
                        _unUsedAmt = parseFloat(this.state.totalShowAmount) -  parseFloat(_totalLinkAMTAK) ;
                    }
                    if(this.state.receivedAmountSend == 0){
                        _unUsedAmt = 0;
                    }
                    console.log(_totalLinkAMTAK+"==FF---FINAL--_unUsedAmt--"+_unUsedAmt);
                    this.setState({errors: errors,unUsedAmount :_unUsedAmt,finalLinkTotalAmt : _totalLinkAMTAK});
                }
                else{
                    if(parseFloat(transactionId) == parseFloat(payTransData.transactionId)){
                        if(e.target.value === '' || e.target.value == '0'){
                            payTransData.isSelected = false;
                            payTransData.link_amount = 0;
                            payTransData.balance_amount = parseFloat(payTransData.old_balance_amount);
                            this.setState({toggleStateSelALL : false,unUsedAmount:this.state.totalShowAmount});
                        }                        
                    }
                }     
                      
            }

        })  
       // console.log("==otherTransationArray==",otherTransationArray);
        this.setState({ otherTransationArray: otherTransationArray,clickData:"Normal"});  
        this.loadItemListing("Normal");
    } 

    clickToAddData = () => {  
        let otherTransationPayInArrayMain = this.state.otherTransationArray

        let otherTransationPayInArray = this.state.otherTransationArray.filter(otherTransationPayInArray => otherTransationPayInArray.isSelected == true)
        let _isError = false;
        let toggleStateSelALL = false;
        let _isSaveBtn = false;
        let totalPayLinkedAMt = 0;
        otherTransationPayInArray.map((payData) =>{
            totalPayLinkedAMt = parseFloat(totalPayLinkedAMt) + parseFloat(payData.link_amount);
        });
        if(this.state.unUsedAmount < 0){
            this.clickInfoSave();
            return false;
        }
        if((totalPayLinkedAMt > this.state.totalShowAmount) && this.state.receivedAmountSend >0){
            this.clickInfo();
            return false;
        }
        let newArray = [];
        console.log(totalPayLinkedAMt+"+==>totalPayLinkedAMt->==+"+this.state.totalShowAmount);
            otherTransationPayInArray.map((payData) =>{
                let _otherTranPayInHistoryArray = {};
                _otherTranPayInHistoryArray.invoice_no = payData.invoice_no;
                _otherTranPayInHistoryArray.type = payData.type;
                _otherTranPayInHistoryArray.link_amount = payData.link_amount;
                _otherTranPayInHistoryArray.trans_Date = payData.added_on;
                newArray.push(_otherTranPayInHistoryArray);
            });
            let _unUsedAmt = this.state.unUsedAmount ? this.state.unUsedAmount : 0;
            let _receivedAmountSend =  parseFloat(this.state.receivedAmountSend) + parseFloat(this.state.PaymentDiscountPayInAmount);
            if(this.state.transactionId == 0){
                if(this.state.receivedAmountSend == totalPayLinkedAMt){
                    _unUsedAmt = 0;
                }
                else if(_receivedAmountSend > totalPayLinkedAMt){
                    _unUsedAmt = parseFloat(_receivedAmountSend) - parseFloat(totalPayLinkedAMt);
                    //totalPayLinkedAMt =  this.state.receivedAmountSend;
                }
            }
            if(this.state.transactionId > 0){            
                if(_receivedAmountSend > totalPayLinkedAMt){
                    _unUsedAmt = parseFloat(_receivedAmountSend) - parseFloat(totalPayLinkedAMt);
                    //totalPayLinkedAMt =  this.state.receivedAmountSend;
                }
            }
            let _totalAmount = this.state.totalAmount;

            if(_isError ==  false){
                let _totalShowAmount =  this.state.totalShowAmount ? this.state.totalShowAmount : 0;
                if(this.state.totalShowAmount > 0){
                    _totalShowAmount = parseFloat(_totalShowAmount) - parseFloat(this.state.PaymentDiscountPayInAmount);
                }
                if(this.state.receivedAmountSend == 0 || this.state.receivedAmountSend == '0'){
                    //console.log(this.state.receivedAmountSend+"==NKKK=="+toggleStateSelALL);
                    _totalShowAmount = this.state.finalLinkTotalAmt;//0;//for issue no 4055 
                    //this.setState({toggleStateSelALL :  true});
                    //toggleStateSelALL = true;
                }
                else{                    
                    toggleStateSelALL = true;
                }
                console.log(totalPayLinkedAMt+"==>FINAL LIN->==+"+_totalShowAmount);
                if(this.state.totalShowAmount > totalPayLinkedAMt){
                    //totalPayLinkedAMt = this.state.totalShowAmount;
                }
                _isSaveBtn = true;
                //this.props.callBack(otherTransationPayInArrayMain,_totalShowAmount,newArray,_unUsedAmt,_totalAmount,toggleStateSelALL,totalPayLinkedAMt,_isSaveBtn);
                if(this.state.clickData === "Reset"){
                    this.props.callBack(otherTransationPayInArrayMain,0,newArray,0,0,toggleStateSelALL,0,_isSaveBtn);                    
                }else{
                    this.props.callBack(otherTransationPayInArrayMain,_totalShowAmount,newArray,_unUsedAmt,_totalAmount,toggleStateSelALL,totalPayLinkedAMt,_isSaveBtn);
                }
                this.setState({isSaveBtn:_isSaveBtn});
            }
        //}
    }
    
    handleCallBackDate = (start, end) => {
        this.setState({startDate:start.format('MMMM D, YYYY'),endDate:end.format('MMMM D, YYYY')});
        let typeSend = this.state.typeSend;
        this.getOtherTransListData(typeSend);
    };

    getOtherTransListData(typeSend){        
        let _otherTransationArray = [];  
        let _totalTransCount = 0;              
        const requestData = JSON.stringify({
            company_id: _companyId,
            business_id: _businessId,
            party_id: this.state.partyId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            type :  typeSend,
        });      
        PostData(global.userPartyPaymentLinkTransList, requestData,'POST').then((result) => {
            let responseJson = result;
            if(responseJson.success == false)
            {  
                this.setState({isLoaded:true,otherTransationArray:_otherTransationArray,finalLinkTotalAmt:0});  
                console.log("KK--HERE--")              
            }
            else
            {
                if (responseJson.response) {
                    _totalTransCount = responseJson.totalCount; 
                    _otherTransationArray = responseJson.response;
                }
                this.setState({otherTransationArray:_otherTransationArray,modalIsOpen_LINKPay: true,totalTransCount : _totalTransCount});            
            }             
        })
        .catch(error => this.setState({ error, isLoaded: false }));
    }    
    
    loadItemListing(_sendD){
        let dataList = [];
        let rows = [];
        if(this.state.isLoaded == true)
        {
            _sendD = _sendD ? _sendD : '';
            let _itemDetails = this.state.otherTransationArray;          
            if(_itemDetails.length > 0){                
                _itemDetails.map((element,index) => {
                    let _varCheck =  '';
                    let _varText = (<input type="number" style={{border : '##CCC solid',padding: '5px',width : '90%'}} id="link_amount" name="link_amount"  onChange={e => this.handleChange(index, e,'link_amount',element.transactionId)} value={element.link_amount || ""} />);
                    //let _varCheck = (<input type="checkbox"  name="checkData" value={element.transactionId} onChange={this.onCheckChange.bind(this)}  checked={element.isSelected ? element.isSelected : ''}/>);
                    
                    if(_sendD == 'Reset'){
                        _varCheck = (<input type="checkbox"  name="checkData55" value={element.transactionId} onChange={this.onCheckChange.bind(this)}  checked="" dataSend="111"/>);
                    }else{
                        _varCheck = (<input type="checkbox" name="checkData" value={element.transactionId} onChange={this.onCheckChange.bind(this)}  checked={element.isSelected ? element.isSelected : ''} dataSend="222"/>);
                        if(this.state.isSaveBtn == false || this.state.isSaveBtn == 0){
                            _varCheck = (<input type="checkbox"  name="checkData" value={element.transactionId} onChange={this.onCheckChange.bind(this)} dataSend="333" checked={element.isSelected ? element.isSelected : ''} />);
                        }
                    }
                    if(element.link_amount == element.balance_amount){
                        element.balance_amount = 0;
                    }
                    let _tranType =  element.type;
                    if(_tranType == 'P2PT'){
                        _tranType = "Party To Party [Paid]";
                    }
                    rows.push({                 
                        checkbox: _varCheck,  
                        date: element.added_on,  
                        receipt_no: element.invoice_no,  
                        transaction_type: _tranType,//element.type,  
                        total_amount: numberFormat(element.total_amount),  
                        current_balance: numberFormat(element.balance_amount),     
                        link_amount: _varText,
                    });
                });
            }
        }        
        if (rows) {
            dataList = {
                columns: columns,
                rows: rows
            };
        }  
        return dataList;
    }
    
    clickToReset = ()=>{        
        let  _otherTransationArray = this.state.otherTransationArray;
        
        let _totalShowAmount= this.state.unUsedAmount;
        if((this.state.receivedAmountSend > 0  || this.state.PaymentDiscountPayInAmount > 0 ))
        {
            _totalShowAmount= this.state.totalShowAmount;
        }
        if((this.state.receivedAmountSend == 0 || this.state.receivedAmountSend == '0') && (this.state.PaymentDiscountPayInAmount == 0))
        {
            _totalShowAmount= this.state.finalLinkTotalAmt;
        } 
        _otherTransationArray = _otherTransationArray.map((value, itemIndex) => {
            return({
                ...value,
                link_amount : 0,
                total_amount_show: value.old_balance_amount,
                balance_amount: value.old_balance_amount,
                isSelected : false,
            })
        });
        this.setState({otherTransationArray: _otherTransationArray,isSaveBtn : false,unUsedAmount : this.state.totalShowAmount,clickData:"Reset",toggleStateSelALL:false});
        this.loadItemListing("Reset");
    }
    /* Toggle Manage */
    onToggleChange = (checked) => {
        let  _otherTransationArray = this.state.otherTransationArray;
        _otherTransationArray = _otherTransationArray.map((value, itemIndex) => {
            return({
                ...value,
                link_amount : 0,
                total_amount_show: value.balance_amount,
                isSelected : false
            })
        });
        let remainingAmt = parseFloat(this.state.totalShowAmount);
        let usedAmount = 0;
        let link_amount = 0;
        let total_amount_show = 0;
        let isSelected = false;
        if(!this.state.toggleState){
            _otherTransationArray = _otherTransationArray.map((value, itemIndex) => {
                if(usedAmount!=parseFloat(this.state.totalShowAmount) && parseFloat(value.balance_amount) <= remainingAmt){
                    remainingAmt -= parseFloat(value.balance_amount ? value.balance_amount : 0);
                    usedAmount += parseFloat(value.balance_amount ? value.balance_amount : 0);
                    link_amount = value.balance_amount;
                    total_amount_show = 0;
                    isSelected = true;
                }else if(usedAmount!=parseFloat(this.state.totalShowAmount) && parseFloat(value.balance_amount) > remainingAmt){
                    usedAmount += parseFloat(remainingAmt);
                    link_amount = parseFloat(remainingAmt);
                    total_amount_show = value.balance_amount - remainingAmt;
                    isSelected = true;
                    remainingAmt -= value.balance_amount - remainingAmt;
                }else {
                    link_amount = value.link_amount;
                    total_amount_show = value.total_amount_show;
                    isSelected = value.isSelected;
                }
                console.log(value.transactionId+"==>ID->=="+link_amount);
                return({
                    ...value,
                    link_amount : link_amount,
                    total_amount_show: value.total_amount_show,
                    isSelected : isSelected
                })
            });
            console.log("==AAA==",JSON.stringify(_otherTransationArray));
            usedAmount = this.state.totalShowAmount == 0 ? 0 : (this.state.totalShowAmount - usedAmount);
        }else {
            usedAmount = this.state.totalShowAmount == 0 ? 0 : (this.state.totalShowAmount - usedAmount);//0;
            _otherTransationArray = _otherTransationArray.map((value, itemIndex) => {
                return({
                    ...value,
                    link_amount : 0,
                    total_amount_show: value.old_balance_amount,
                    balance_amount: value.old_balance_amount,
                    isSelected : false
                })
            });
            console.log("==_otherTransationArray==",JSON.stringify(_otherTransationArray));
        }

        
        this.setState({ otherTransationArray: _otherTransationArray,toggleState:checked,unUsedAmount : usedAmount,isSaveBtn : true,clickData:"Normal"});
        this.loadItemListing("Normal");

        // True = Cash
        // False = Credit
    }
    /* End Toggle Manage */
    
    /* Toggle Manage Select ALL */
    onToggleChangeSelALL = (checked) => {
        const _otherTransationArray = this.state.otherTransationArray;
        let _oldTotalAmount = this.state.totalShowAmount;
        let _totalLinkAMTAK = 0;
        let _isSaveBtn = false;
        if(checked ==  true){
            _isSaveBtn = true;     
            console.log("==ddd==",JSON.stringify(_otherTransationArray));   
            _otherTransationArray.forEach(payData => {
                payData.isSelected = true;   
                payData.link_amount = payData.old_balance_amount;//balance_amount// issue no 3061
                _oldTotalAmount = parseFloat(_oldTotalAmount) + parseFloat(payData.link_amount);
                
                _totalLinkAMTAK = parseFloat(_totalLinkAMTAK) + (parseFloat(payData.total_amount_show) ? parseFloat(payData.total_amount_show) : 0);
                console.log(payData.link_amount+"==>TRUE>=="+payData.total_amount_show+'===>>==='+_totalLinkAMTAK);
            });
        }
        if(checked ==  false){
            _isSaveBtn = false;          
            _otherTransationArray.forEach(payData => {
                payData.isSelected = false;   
                payData.link_amount = 0;
                payData.balance_amount = payData.total_amount_show;
                //_oldTotalAmount = parseFloat(_oldTotalAmount) - parseFloat(payData.link_amount);
                _oldTotalAmount = parseFloat(this.state.receivedAmountSend) + parseFloat(this.state.PaymentDiscountPayInAmount);
                _totalLinkAMTAK = 0;
                console.log(payData.link_amount+"==>FLASE>=="+payData.total_amount_show+'===>>==='+_totalLinkAMTAK);
            });
        }
        this.setState({ otherTransationArray: _otherTransationArray,toggleStateSelALL:checked,finalLinkTotalAmt : _totalLinkAMTAK, isSaveBtn : _isSaveBtn,clickData:"Normal"});    
        this.loadItemListing("Normal");
        // True = Cash
        // False = Credit
    }
    /* End Toggle Manage Select ALL*/

    changeTypeFilter = (e) => {
        this.setState({ typeSend: e.target.value });
        this.getOtherTransListData(e.target.value);
      };
    render()
    {
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        return(
            <React.Fragment>                
                <div className="block"  id='LinkPaymentD' >
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            {this.state.otherTransationArray.length > 0 && 
                            <div> 
                                {(this.state.receivedAmountSend > 0 || this.state.PaymentDiscountPayInAmount > 0 ) && 
                                <h5 style={{marginLeft : '25px'}}> Total Received Amount : {this.state.totalShowAmount}</h5>
                                }
                                {((this.state.receivedAmountSend == 0 || this.state.receivedAmountSend == '0')  && (this.state.PaymentDiscountPayInAmount == 0)) &&
                                <h5 style={{marginLeft : '25px'}}> Total : {this.state.finalLinkTotalAmt}</h5>
                                }
                                
                                {/* <h5 style={{marginLeft : '25px'}}>
                                    {this.state.otherTransationArray.length > 0 &&
                                        <h5> <strong>Unused Amount : {this.state.unUsedAmount}</strong></h5>
                                    }
                                </h5> */}
                            </div>
                            }
                            {this.state.otherTransationArray.length == 0 &&
                                <div>                                     
                                    <h5 style={{marginLeft : '25px'}}> Total : 0</h5>
                                </div>                                
                            }
                            {this.state.otherTransationArray.length > 0 && 
                            <h5 style={{marginLeft : '25px'}}>
                                <h5> <strong>Unused Amount  : {this.state.unUsedAmount}</strong></h5>
                            </h5>
                            }
                            {this.state.otherTransationArray.length == 0 &&
                            <h5 style={{marginLeft : '25px'}}>
                                <h5> <strong>Unused Amount : 0</strong></h5>
                            </h5>
                            }
                            
                            {this.state.totalShowAmount > 0 &&
                            <div className="text-right">
                                <div style={{marginBottom : '15px'}}>
                                    <ToggleSwitch
                                    txtLeft=""
                                    txtRight="Auto-link"
                                    small
                                    id="newsletter"
                                    checked={this.state.toggleState}
                                    onChange={this.onToggleChange}
                                    />
                                    {/* <p>Newsletter: {String(this.state.toggleState)}</p> */}
                                </div>
                            </div>
                            }
                            {this.state.totalShowAmount == 0 &&
                            <div className="text-right">
                                <div style={{marginBottom : '15px'}}>
                                    <ToggleSwitch
                                    txtLeft=""
                                    txtRight="Select All"
                                    small
                                    id="newsletter"
                                    checked={this.state.toggleStateSelALL}
                                    onChange={this.onToggleChangeSelALL}
                                    />
                                </div>
                            </div>
                            }                            
                            <div className="text-right">                     
                                <button type="submit" className="btn btn-alt-primary pull-right" onClick={this.clickToReset}>
                                    <i className="fa fa-refresh mr-5"></i> Reset
                                </button> &nbsp;&nbsp;  
                            </div>
                            {this.state.transactionId == 0 &&
                                <strong>Filter : </strong>
                            }
                            {this.state.transactionId == 0 &&
                                <div className="row col-lg-12">
                                    {/* <div className='col-lg-6'> */}
                                    <select
                                        onChange={this.changeTypeFilter}
                                        className="form-control col-4"
                                        style={{
                                        display: "inline-block",
                                        marginLeft: "5px",
                                        }}
                                    >
                                        <option value="ALL">All Transaction</option>
                                        <option value="SALE">Sale</option>
                                        <option value="PAYMENT-OUT">Payment-Out</option>
                                        <option value="DEBIT NOTE">Debit Note</option>
                                        <option value="P2PT">Party To Party [Paid]</option>
                                        <option value="RECEIVALE">Receivable Opening Balance</option>
                                    </select>
                                    &nbsp;&nbsp;
                                    {/* </div> */}
                                    {/* <div className='col-lg-6'> */}
                                    <DateRangePicker
                                        initialSettings={{ 
                                            startDate: this.state.startDate, 
                                            endDate: this.state.endDate,
                                            locale: {
                                                format: 'MMMM D, YYYY',
                                            },
                                            ranges: {
                                                Today: [moment().toDate(), moment().toDate()],
                                                Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                                ],
                                                'This Week': [
                                                    moment().startOf('week').format("MMM DD, YYYY"),
                                                    moment().endOf('week').format("MMM DD, YYYY"),
                                                    ],
                                                'This Month': [
                                                    moment().startOf('month').toDate(),
                                                    moment().endOf('month').toDate(),
                                                ],
                                                'Last Month': [
                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                ],
                                                'This Quarter': [
                                                    moment().startOf('month').toDate(),
                                                    moment().add(2, 'month').endOf('month').toDate(),
                                                ],
                                                'This Financial Year': [
                                                    moment().month('April').startOf('month').format("MMM DD, YYYY"),
                                                    moment().add(1, 'year').month('March').endOf('month').format("MMM DD, YYYY"),
                                                ],
                                            }
                                        }}
                                        onCallback={this.handleCallBackDate}
                                    >
                                    <input type="text" className="form-control col-6" style={{display: 'inline-block'}}/>
                                    </DateRangePicker>
                                    {/* </div> */}
                                </div>
                            }

                            </div>
                        </div>
                        <div className="col-md-12" id='LinkPaymentD'>
                            <div className="block">
                                <div className="block-content">
                                    {/* <h6 className="block-title ml-10">Linked With : </h6> */}
                                </div>
                                <div className="block-content" id="tableData">
                                    <MDBDataTableV5 
                                    hover
                                    entriesOptions={[this.state.totalTransCount]}
                                    entries={this.state.totalTransCount}
                                    pagesAmount={4}
                                    span
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                    barReverse
                                    data={this.loadItemListing(this.state.clickData)}
                                    />
                                </div>
                            </div>
                                
                        </div>
                        {/* {this.state.otherTransationArray.length == 0 &&
                        <div className="col-md-6">
                            <p>No Record Available</p>
                        </div>
                        } */}
                        <div className="col-md-6 text-right"> </div>
                        {this.state.otherTransationArray.length > 0 &&
                            <div className="col-md-6 text-right">                     
                                <button type="submit" className="btn btn-alt-primary pull-right" onClick={this.clickToAddData}>
                                    <i className="fa fa-check mr-5"></i> Save
                                </button> &nbsp;&nbsp;  
                            </div>
                        }

                </div>                
            </React.Fragment>
        )
    }
}