import React, { Component } from "react";
import { PostData } from "../../../service/postData"; 
import { sendNotification } from "../../../_notificationSettings/notificationPanel";

import QuickAddNewBankDetail from "../userSale/quickBankAdd";
import QuickAddBankTransfer from "../cashBank/quickBankTransfer";
import { MDBDataTableV5 } from "mdbreact";
import { numberFormat } from "../../../config/numberFormat";
import validator from "validator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Modal from "react-modal";
import * as XLSX from "xlsx";

import ButtonPdf from "../htmlPdfCreation";
import QuickAddDepositeMakePaymentOI from "../chequeBank/chequeDepositOI";
import QuickAddReopenDetails from "../chequeBank/chequeReopen";

let _signingId = "0";
let _businessId = "0";
let _company_id = "0";
let country_code = "0";
let tax_name = "";
let _businessSetting = {};

let _countryCode = "IN";
let _UserSession = localStorage.getItem("vs_UserSession");
if (_UserSession!=undefined || _UserSession!=null) {
  _UserSession = JSON.parse(_UserSession);
  _signingId = _UserSession.loginId;
  _businessSetting = _UserSession.businessSetting;
}

let _companySession = localStorage.getItem("vs_UserCompanySession");
if (_companySession!=undefined || _companySession!=null) {
  _companySession = JSON.parse(_companySession);
  _businessId = _companySession.business_id;
  _company_id = _companySession.company_id;
  country_code = _companySession.country_code;
  tax_name = _companySession.tax_name;
}
let _t = "";
if (_businessSetting.Tran_AddTime == 1) {
  _t = " & Time";
}
let columns = [
  {
    label: "#",
    field: "srNo",
    width: 20,
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "#",
    },
  },
  {
    label: "Type",
    field: "type",
    width: 50,
  },
  {
    label: "Name",
    field: "display_name",
    width: 150,
  },
  {
    label: "Date" + _t,
    field: "date",
    width: 100,
  },
  {
    label: "Amount",
    field: "amount",
    width: 100,
  },
  {
    label: "Action",
    field: "actionextra",
    width: 100,
  },
];

let _loader = "glb-ldr-prt active";

export default class bankAccountListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTitle: "Add New",
      activeBankAccount: "N/A",
      activeBankId: "0",
      activeBankAccBalance: "0",
      activeBankUpi: "",
      activeBankAccNumber: "",
      activeBankIFSC: "",

      modalIsOpen_MEP_OI: false,
      modalIsOpen_Reopen: false,
      activeMinDate: moment().format("MM/DD/YYYY"),

      isLoaded: true,
      modalIsOpen_SBANK: false,
      modalIsOpen_BankToCash: false,

      company_id: _company_id,
      businessId: _businessId,

      bankArray: [],
      bankAccArrayFilter: [],
      moneyArrayTransfer: [],

      userId: _signingId,
      display_name: "",
      account_number: "",

      importExcelFile: "",
      activeLoanAccountId: 0,
      activeTrasactionId: 0,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      errors: {
        display_name: "",
        account_number: "",
        importExcelFile: "",
      },
    };
  }

  async componentDidMount() {
    this.getBankAccListData();
  }

  getTransactionListData(id) {
    let _transactionArray = [];
    const requestData = JSON.stringify({
      business_id: this.state.businessId,
      company_id: this.state.company_id,
      bank_id: id, //this.state.activeBankId
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      isDateFilter: true,
    });

    PostData(global.userallMoneyTransferList, requestData, "POST")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            moneyArrayTransfer: _transactionArray,
            isLoaded: true,
          });
        } else {
          let _balanceAmt = 0;
          if (responseJson.response) {
            _transactionArray = responseJson.response;
            _balanceAmt = responseJson.openingBalance;
          }
          this.setState({
            moneyArrayTransfer: _transactionArray,
            //activeBankAccBalance: _balanceAmt,
            isLoaded: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  getBankAccListData() {
    let _bankArray = [];
    let _activeBankAccount = "N/A";
    let _activeBankAccountId = 0;
    let _activeBankAccountBal = 0;
    let _activeBankAccountNumber = "N/A";
    let _activeBankAccountIFSC = "N/A";
    let _activeBankAccountUpiCode = "N/A";
    let _url_GetData =
      global.userBankDetailsList + "?company_id=" + this.state.company_id;
    PostData(_url_GetData, "", "GET")
      .then((result) => {
        let responseJson = result;
        if (responseJson.success == false) {
          this.setState({
            bankAccArrayFilter: _bankArray,
            bankArray: _bankArray,
            isLoaded: true,
            account_number: _activeBankAccountNumber,
            activeBankAccBalance: _activeBankAccountBal,
            display_name: _activeBankAccount,
            activeBankAccount: _activeBankAccount,
            activeBankId: _activeBankAccountId,
            activeBankAccNumber: _activeBankAccountNumber,
            activeBankUpi: _activeBankAccountUpiCode,
            activeBankIFSC: _activeBankAccountIFSC,
          });
        } else {
          if (responseJson.response) {
            _bankArray = responseJson.response;
            if (responseJson.response) {
              _bankArray = responseJson.response;
              _bankArray.map((party, i) => {
                if (i == 0) {
                  _bankArray[i].isActiveClick = true;
                  _activeBankAccount = party.display_name;
                  _activeBankAccountNumber = party.account_number;
                  _activeBankAccountIFSC = party.ifsc_code;
                  _activeBankAccountUpiCode = party.upi_code;
                  _activeBankAccountId = party.id;
                  _activeBankAccountBal = party.current_balance;
                  //_activeBankAccountBal = party.opening_balance;
                } else {
                  _bankArray[i].isActiveClick = false;
                }
              });
            }
          }
          this.setState({
            bankAccArrayFilter: _bankArray,
            bankArray: _bankArray,
            isLoaded: true,
            account_number: _activeBankAccountNumber,
            activeBankAccBalance: _activeBankAccountBal,
            display_name: _activeBankAccount,
            activeBankAccount: _activeBankAccount,
            activeBankId: _activeBankAccountId,
            activeBankAccNumber: _activeBankAccountNumber,
            activeBankUpi: _activeBankAccountUpiCode,
            activeBankIFSC: _activeBankAccountIFSC,
          });
          if (_activeBankAccountId > 0) {
            this.getTransactionListData(_activeBankAccountId);
          }
        }
      })
      .catch((error) => this.setState({ error, isLoaded: false }));
  }

  loadBankAccListing() {
    let dataList = [];
    let rows = [];

    if (this.state.isLoaded == true) {
      let _bankDetails = this.state.bankAccArrayFilter;

      if (_bankDetails.length > 0) {
        _bankDetails.map((value, index) => {
          let _activeClass = "simpleRow"; //deactiveRow
          if (this.state.activeBankAccount == value.display_name) {
            _activeClass = "activeRow";
          }

          let _deletebtn = (
            <a
              className="dropdown-item"
              href="javascript:void(0)"
              onClick={this.clickToDeleteAccount.bind(this, value.id)}
              style={{ margin: "0px" }}
            >
              Delete
            </a>
          );
          if (value.isTransaction == true) {
            _deletebtn = (
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.clickToDeleteNotifiyAccount.bind(this, value.id)}
                style={{ margin: "0px" }}
              >
                Delete
              </a>
            );
          }

          dataList.push(
            <tr
              key={index}
              className={_activeClass + " tableRow"}
              onClick={this.clickToActiveRow.bind(
                this,
                value.id,
                value.display_name,
                value.account_number,
                value.current_balance, //value.opening_balance,
                value.ifsc_code,
                value.upi_code,
                value.account_holder_name,
                value.or_code_enable,
                value.bank_account_enable
              )}
            >
              <td>{value.display_name}</td>
              <td>{numberFormat(value.current_balance)}</td>
              <th className="text-center" scope="row">
                <div className="btn-group show" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right min-width-200"
                    aria-labelledby="toolbarDrop"
                    style={{ margin: "0px" }}
                  >
                    <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      onClick={this.openModalEditAccountHandler.bind(
                        this,
                        "modalAdjustNewBank",
                        value.id
                      )}
                    >
                      Edit
                    </a>
                    {_deletebtn}
                  </div>
                </div>
              </th>
            </tr>
          );
        });
      }
    }
    return dataList;
  }

  clickToActiveRow(id, txt, contact, pOpeningBal, pBAdd, party_email_id) {
    this.setState({
      activeBankAccount: txt,
      display_name: txt,
      account_number: contact,
      activeBankId: id,
      activeBankAccBalance: pOpeningBal,
      activeBankAccNumber: contact,
      activeBankUpi: party_email_id,
      activeBankIFSC: pBAdd!="" ? pBAdd : "NA",
    });
    if (id > 0) {
      this.getTransactionListData(id);
    }
  }

  clickToSaleDelete(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              sale_id: value,
              type_value: type,
            });
            PostData(global.deleteSaleTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getTransactionListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteNotifiyAccount() {
    confirmAlert({
      title: "Bank Alert",
      message:
        "This bank account can not be deleted as it already has transactions. Please delete all transactions other than bank entries before deleting the account.",
      buttons: [
        {
          label: "Ok",
        },
      ],
    });
  }

  clickToDeleteAccount(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?", //All the transactions associated with the account will also be deleted. It may affect your cash in hand and bank balance. Do you really want to delete this account.
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const requestData = JSON.stringify({
              account_id: value,
            });
            PostData(global.deleteBankAccount, requestData, "POST").then(
              (result) => {
                let responseJson = result;

                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  let _finalBankArray = [];
                  let _updateLocal = _UserSession;
                  let _comData = _updateLocal.companies;
                  _comData.map((compData, idx) => {
                    if (compData.company_id == this.state.company_id) {
                      let _bankArray = compData.bankDetails;
                      let _newBankArray = [];
                      _bankArray.map((bankData, idx) => {
                        if (bankData.bank_id!=value) {
                          _newBankArray.push(bankData);
                        }
                      });
                      compData.bankDetails = _newBankArray;
                      _finalBankArray = _newBankArray;
                    }
                  });

                  _updateLocal.companies = _comData;
                  localStorage.removeItem("vs_UserSession");
                  localStorage.setItem(
                    "vs_UserSession",
                    JSON.stringify(_updateLocal)
                  );

                  let _updateCompany = _companySession;
                  _updateCompany.bankDetails = _finalBankArray;
                  localStorage.removeItem("vs_UserCompanySession");
                  localStorage.setItem(
                    "vs_UserCompanySession",
                    JSON.stringify(_updateCompany)
                  );

                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  //this.getBankAccListData();
                  window.location.reload();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  clickToDeleteTrasaction(value, type) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-danger",
          onClick: () => {
            const requestData = JSON.stringify({
              bank_transction_id: value,
              type_value: type,
            });
            PostData(global.deleteMoneyTransaction, requestData, "POST").then(
              (result) => {
                let responseJson = result;
                if (responseJson.success == false) {
                  sendNotification(
                    "Error Message 😓",
                    responseJson.message,
                    "danger"
                  );
                  return;
                } else {
                  sendNotification(
                    "Success Message 👍",
                    responseJson.message,
                    "success"
                  );
                  this.getBankAccListData();
                  return;
                }
              }
            );
          },
        },
        {
          label: "No",
          className: "btn btn-dark",
        },
      ],
    });
  }

  clickToDeleteTransaction(value) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const requestData = JSON.stringify({
              transaction_id: value,
            });
            PostData(
              global.userOIItemTransactionDelete,
              requestData,
              "POST"
            ).then((result) => {
              let responseJson = result;
              if (responseJson.success == false) {
                sendNotification(
                  "Error Message 😓",
                  responseJson.message,
                  "danger"
                );
                return;
              } else {
                sendNotification(
                  "Success Message 👍",
                  responseJson.message,
                  "success"
                );
                this.getOICategoryListData();
                return;
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  loadTrasactionListing() {
    let dataList = [];
    let rows = [];
    if (this.state.isLoaded == true) {
      let _transDetails = this.state.moneyArrayTransfer;
      if (_transDetails.length > 0) {
        _transDetails
          .sort((a, b) => (a.transfer_date > b.transfer_date ? 1 : -1))
          .reverse();
        let _i = 0;
        _transDetails.map((value, index) => {
          let _typeSend = "&tType=Bank";
          _i++;
          let _actionExtra = "";

          if (value.via == "OPEN") {
            _actionExtra = "";
          } else if (value.via == "OPEN-S-P") {
            let _editActionUrl =
              global.webUrl + "business/add-sale/?auth=" + value.id + _typeSend;
            let BtnDelete = "";
            let BtnEdit = "";
            let _hideActionButton = "";

            if (value._typeD == "SALE") {
              _editActionUrl =
                global.webUrl +
                "business/add-sale/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(this, value.id, "Sale")}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "CREDIT NOTE") {
              _editActionUrl =
                global.webUrl +
                "business/add-sale-credit-note/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "Sale Return"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "PAYMENT-IN") {
              _editActionUrl =
                global.webUrl +
                "business/add-sale-payment-in/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "PAYMENT-IN"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "SALE ORDER") {
              _editActionUrl =
                global.webUrl +
                "business/add-sale-order/?auth=" +
                value.id +
                _typeSend;

              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "SALE ORDER"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "PAYMENT-OUT") {
              _editActionUrl =
                global.webUrl +
                "business/add-purchase-payment-out/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "PAYMENT-OUT"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "PURCHASE") {
              _editActionUrl =
                global.webUrl +
                "business/add-purchase/?auth=" +
                value.id +
                _typeSend;

              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "PURCHASE"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "DEBIT NOTE") {
              _editActionUrl =
                global.webUrl +
                "business/add-purchase-debit-note/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "DEBIT NOTE"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "PURCHASE ORDER") {
              _editActionUrl =
                global.webUrl +
                "business/add-purchase-order/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "PURCHASE ORDER"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "EXPENSE") {
              _editActionUrl =
                global.webUrl +
                "business/add-expense/?auth=" +
                value.id +
                _typeSend;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>
                  View/Edit
                </a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToSaleDelete.bind(
                    this,
                    value.id,
                    "PURCHASE ORDER"
                  )}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "Other Income") {
              _editActionUrl =
                global.webUrl +
                "business/create-other-income/?auth=" +
                value.id;
              BtnEdit = (
                <a className="dropdown-item" href={_editActionUrl}>View/Edit</a>
              );
              BtnDelete = (
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  data-id={value.id}
                  onClick={this.clickToDeleteTransaction.bind(this, value.id)}
                >
                  Delete
                </a>
              );
            } else if (value._typeD == "DEPOSITCHEQUE") {
              BtnEdit = "";
              BtnDelete = "";
              _hideActionButton = "none";
            } else if (value._typeD == "WITHDRAWCHEQUE") {
              BtnEdit = "";
              BtnDelete = "";
              _hideActionButton = "none";
            }

            _actionExtra = (
              <div
                className="btn-group"
                style={{ display: _hideActionButton }}
                role="group"
              >
                <button
                  type="button"
                  id="btnGroupVerticalDrop3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="btnGroupVerticalDrop1"
                >
                  {BtnEdit}
                  {BtnDelete}
                </div>
              </div>
            );
          } else {
            if (
             value._typeD == "Cash Withdraw" || value._typeD == "Cash Deposit" // || value._typeD == "WITHDRAWCHEQUE"
            ) {
              _actionExtra = (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    id="btnGroupVerticalDrop3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupVerticalDrop1"
                  >
                    <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      onClick={this.openModalTrasacEditHandler.bind(
                        this,
                        "modalUploadBankToCash",
                        value.bank_transction_id,
                        value.via
                      )}
                    >
                      View/Edit
                    </a>
                    <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      data-id={value.bank_transction_id}
                      onClick={this.clickToDeleteTrasaction.bind(
                        this,
                        value.bank_transction_id,
                        "Money"
                      )}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              );
            }else if(value._typeD == "DEPOSITCHEQUE"){

              _actionExtra = (
                  <div className="btn-group" role="group">
                    <button
                        type="button"
                        id="btnGroupVerticalDrop3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupVerticalDrop1"
                    >
                      <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          onClick={this.openModalHandler.bind(
                              this,
                              "modalMakePaymentDepositOI",
                              value.id,
                              value.sendTransType
                          )}
                      >
                        View/Edit
                      </a>
                      <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          onClick={this.openModalHandler.bind(
                              this,
                              "modalReopenCheque",
                              value.id,
                              'Re-open',
                              value.transaction_id,
                              value.type
                          )}
                      >
                        Re-open
                      </a>
                      <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          data-id={value.bank_transction_id}
                          onClick={this.clickToDeleteTrasaction.bind(
                              this,
                              value.bank_transction_id,
                              "Deposit"
                          )}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
              );
            }else if(value._typeD == "Bank Adjust Increase" || value._typeD == "Bank Adjust Reduce"){

              _actionExtra = (
                  <div className="btn-group" role="group">
                    <button
                        type="button"
                        id="btnGroupVerticalDrop3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupVerticalDrop1"
                    >
                      <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          onClick={this.openModalTrasacEditHandler.bind(
                              this,
                              "modalUploadBankToCash",
                              value.bank_transction_id,
                              value.via
                          )}
                      >
                        View/Edit
                      </a>
                      <a
                          className="dropdown-item"
                          href="javascript:void(0)"
                          data-id={value.bank_transction_id}
                          onClick={this.clickToDeleteTrasaction.bind(
                              this,
                              value.bank_transction_id,
                              "Money"
                          )}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
              );
            }
          }

          let _amountTxt = "";
          if (value.colorShow == "Green") {
            _amountTxt = (
              <span className="text-success">{numberFormat(value.amount)}</span>
            );
          } else if (value.colorShow == "Yellow") {
            _amountTxt = (
              <span className="text-warning">{numberFormat(value.amount)}</span>
            );
          } else {
            _amountTxt = (
              <span className="text-danger">{numberFormat(value.amount)}</span>
            );
          }
          let _dName = value.nameSend;
          if (
            value._typeD == "Cash Withdraw" ||
            value._typeD == "Cash Deposit"
          ) {
            _dName = value.description;
          }
          let showDateTime = value.transfer_date;
          if (_businessSetting.Tran_AddTime == 1 && value.transfer_time!==null) {
            showDateTime = value.transfer_date + " " + (value.transfer_time ? value.transfer_time : "");
          }
          
          rows.push({
            srNo: _i, //value.srNo,
            type: value._typeD,
            date: showDateTime, //added_on,
            display_name: _dName,
            amount: _amountTxt,
            actionextra: _actionExtra,
          });
        });
      }
    }

    if (rows) {
      dataList = {
        columns: columns,
        rows: rows,
      };
    }
    return dataList;
  }

  /*For Modal open close*/
  openModalHandler(popId, id, tpe, oid, type) {
    if (popId == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true, activeBankId: 0 });
    }
    if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: true,
        activeTrasactionId: id,
        activeTypeAccount: tpe,
      });
    }
    if (popId == "modalReopenCheque") {
      this.setState({
        modalIsOpen_Reopen: true,
        activeTrasactionId: id,
        activeTypeAccount: tpe,
        activeTrasactionOId: oid,
        activeTrasactionType: type,
      });
    }
  }

  closeModalHandler = (popId) => {
    if (popId == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: false });
    }
    if (popId == "modalUploadBankToCash") {
      this.setState({ modalIsOpen_BankToCash: false });
    }
    if (popId == "modalMakePaymentDepositOI") {
      this.setState({
        modalIsOpen_MEP_OI: false,
        activeTrasactionId: 0,
        activeTypeAccount: "Deposit",
      });
    }
    if (popId == "modalReopenCheque") {
      this.setState({
        modalIsOpen_Reopen: false,
        activeTrasactionId: 0,
        activeTypeAccount: "Deposit",
        activeTrasactionOId: 0,
        activeTrasactionType: "",
      });
    }
  };

  openModalEditAccountHandler(popId, AccountId) {
    if (popId == "modalAdjustNewBank") {
      this.setState({ modalIsOpen_SBANK: true, activeBankId: AccountId });
    }
  }

  openModalTrasacEditHandler(popId, accountTrasacId, popType) {
    if (popId == "modalUploadBankToCash") {
      this.setState({
        modalIsOpen_BankToCash: true,
        activeTrasactionId: accountTrasacId,
        popType: popType,
      });
    }
  }
  /*End Modal open close*/

  /* Adjust Party */
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "display_name":
        errors.display_name =
          value.length < 3 ? "*Party Name must be 3 characters long!" : "";
        this.setState({ display_name: value });
        break;
      case "account_number":
        if (validator.isMobilePhone(value)) {
          errors.account_number = "";
        } else {
          errors.account_number = "*Please enter valid phone no.";
        }
        this.setState({ account_number: value });
        break;
    }
  };

  clickToUpdatePartyInfo = (event) => {
    event.preventDefault();
    let errors = this.state.errors;
    let _isError = false;
    if (this.state.display_name == "") {
      _isError = true;
      errors["display_name"] = "*Please Enter display Name";
      this.setState({ errors: errors });
    }
    if (this.state.account_number == "") {
      _isError = true;
      errors["account_number"] = "*Please Enter phone no.";
      this.setState({ errors: errors });
    }

    if (_isError == false) {
      if (this.state.activeBankId > 0) {
        const requestData = JSON.stringify({
          partyId: this.state.activeBankId,
          businessId: this.state.businessId,
          company_id: this.state.company_id,
          display_name: this.state.display_name,
          account_number: this.state.account_number,
        });

        PostData(global.userEditPartyInfo, requestData, "POST").then(
          (result) => {
            if (result.success == true) {
              sendNotification("Success Message 👍", result.message, "success");
              this.closeModalHandler("modalAdjustparty");
              this.loadBankAccListing();
            } else {
              sendNotification("Error Message 😓", result.message, "danger");
              let errors = this.state.errors;
              errors["display_name"] = result.message; //'Please enter valid phone no.';
              this.setState({
                errors: errors,
              });
              return;
            }
          }
        );
      }
    }
  };
  /* End Here Adjust Party*/

  openModalTrasacHandler(popId, bankId, popType) {
    if (popId == "modalUploadBankToCash") {
      this.setState({
        modalIsOpen_BankToCash: true,
        bankId: bankId,
        popType: popType,
        activeTrasactionId: 0,
      });
    }
  }

  /* party Filter Code */
  onPartyFilterName = (e) => {
    const data = this.state.bankArray;
    let _name = e.currentTarget.value;
    const keyword = _name.charAt(0).toUpperCase() + _name.slice(1);
    const filtered = data.filter((entry) =>
      Object.values(entry).some(
        (val) =>
          typeof val == "string" &&
          val.toLowerCase().includes(keyword.toLowerCase())
      )
    );
    this.setState({ bankAccArrayFilter: filtered });
    this.loadBankAccListing();
  };
  /* End party Filter Code */

  getUpdateBankTranfer = () => {
    this.setState({ modalIsOpen_BankToCash: false, bankId: 0 });
    this.getBankAccListData();
  };

  handleCallBackDate = (start, end) => {
    this.setState({
      startDate: start.format("MMMM D, YYYY"),
      endDate: end.format("MMMM D, YYYY"),
    });
    this.getTransactionListData(
      this.state.activeBankId,
      this.state.startDate,
      this.state.endDate
    );
  };
  /* Callback Function from Child */

  getUpdateResponse = () => {
    this.setState({
      modalIsOpen_SBANK: false,
      accountId: 0,
      activeTrasactionId: 0,
      modalIsOpen_MEP_OI: false,
      modalIsOpen_Reopen: false
    });
    this.getBankAccListData();
  };
  /* End Here */
  render() {
    if (this.state.isLoaded == true) {
      _loader = "glb-ldr-prt";
    }

    let _hideCss = "hide";
    if (this.state.bankArray.length > 0) {
      _hideCss = "";
    }

    const { errors } = this.state;
    const isDisabled = Object.keys(errors).some((x) => errors[x]);
    return (
      <React.Fragment>
        <div className="content">
          <div className="content-header">
            <div className="header-section">
              <h1 className="content-heading">
                <i className="gi gi-direction"></i>Manage Banks
                <br />
              </h1>
            </div>
          </div>
          <nav className="breadcrumb push">
            <a
              className="breadcrumb-item"
              href={global.webUrl + "business/dashboard/"}
            >
              Dashboard
            </a>
            <span className="breadcrumb-item active">Manage Bank List</span>
          </nav>

          <div className="row">
            <div className="col-md-3">
              <div className="block">
                <div className="block pull-r-l">
                  <div className="block-content">
                    <div className="mb-10">
                      <button
                        type="button"
                        data-toggle="layout"
                        data-action="header_search_on"
                        className="btn btn-sm btn-circle btn-outline-secondary mr-5 mb-5"
                      >
                        <i className="fa fa-search"></i>
                      </button>

                      <div
                        className="btn-group pull-right"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.openModalHandler.bind(
                            this,
                            "modalAdjustNewBank"
                          )}
                        >
                          <i className="fa fa-plus mr-5"></i> <b>Add Bank</b>
                        </button>
                      </div>
                    </div>
                    <div id="page-header-search" className="overlay-header">
                      <div className="content-header content-header-fullrow">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-toggle="layout"
                              data-action="header_search_off"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            onChange={this.onPartyFilterName}
                            className="form-control"
                            placeholder="Search here.."
                            id="page-header-search-input"
                            name="page-header-search-input"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ borderBottom: "1px solid #e4e7ed" }}></div>
                  <div
                    className="block-content"
                    data-toggle="slimscroll"
                    data-height="600px"
                    data-color="#cccccc"
                    data-opacity="1"
                    data-always-visible="true"
                  >
                    <table className="table table-striped table-vcenter">
                      <thead id="sideTable">
                        <tr>
                          <th>Bank Name</th>
                          <th style={{ width: "50px" }}>Amount&nbsp;</th>
                          <th style={{ width: "50px" }}>&nbsp;&nbsp;Action</th>
                        </tr>
                      </thead>
                      <tbody id="sideTable">{this.loadBankAccListing()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="block">
                <div className="block-content">
                  <div className="row items-push">
                    <div className="col-lg-12">
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <b>{this.state.activeBankAccount}</b>{" "}
                          <i className="si si-pin"></i>
                          <span
                            className="btn-group pull-right"
                            role="group"
                            aria-label="Button group with nested dropdown"
                          >
                            <div className={_hideCss}>
                              <button
                                type="button"
                                className="btn btn-secondary"
                              >
                                <i className="fa fa-plus mr-5"></i>{" "}
                                <b>Transfer Money</b>
                              </button>
                              <div className="btn-group show" role="group">
                                <button
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle"
                                  id="toolbarDrop"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                ></button>
                                <div
                                  className="dropdown-menu dropdown-menu-right min-width-200"
                                  aria-labelledby="toolbarDrop"
                                  style={{ margin: "0px" }}
                                >
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={this.openModalTrasacHandler.bind(
                                      this,
                                      "modalUploadBankToCash",
                                      this.state.activeBankId,
                                      "BTC"
                                    )}
                                    style={{ margin: "0px" }}
                                  >
                                    Bank to Cash Transfer
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={this.openModalTrasacHandler.bind(
                                      this,
                                      "modalUploadBankToCash",
                                      this.state.activeBankId,
                                      "CTB"
                                    )}
                                    style={{ margin: "0px" }}
                                  >
                                    Cash to Bank Transfer
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={this.openModalTrasacHandler.bind(
                                      this,
                                      "modalUploadBankToCash",
                                      this.state.activeBankId,
                                      "BTB"
                                    )}
                                    style={{ margin: "0px" }}
                                  >
                                    Bank to Bank Transfer
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={this.openModalTrasacHandler.bind(
                                      this,
                                      "modalUploadBankToCash",
                                      this.state.activeBankId,
                                      "ADB"
                                    )}
                                    style={{ margin: "0px" }}
                                  >
                                    Adjust Bank Balance
                                  </a>
                                </div>
                              </div>
                            </div>
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Bank Name : {this.state.activeBankAccount}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Account Number : {this.state.activeBankAccNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>
                            Current Balance :{" "}
                            {numberFormat(this.state.activeBankAccBalance)}
                          </span>
                        </p>
                      </div>
                      <div className="">
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>IFSC Code : {this.state.activeBankIFSC}</span>
                        </p>
                      </div>
                      <div className="">
                        <p className="mb-10" style={{ fontSize: "15px" }}>
                          <span>UPI ID : {this.state.activeBankUpi}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="block-content">
                  <span>Filter : </span>
                  <DateRangePicker
                    initialSettings={{
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      locale: {
                        format: "MMMM D, YYYY",
                      },
                      ranges: {
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                          moment().subtract(1, "days").toDate(),
                          moment().subtract(1, "days").toDate(),
                        ],
                        "This Week": [
                          moment().startOf("week").format("MMM DD, YYYY"),
                          moment().endOf("week").format("MMM DD, YYYY"),
                        ],
                        "This Month": [
                          moment().startOf("month").toDate(),
                          moment().endOf("month").toDate(),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          moment().subtract(1, "month").endOf("month").toDate(),
                        ],
                        "This Quarter": [
                          moment().startOf("month").toDate(),
                          moment().add(2, "month").endOf("month").toDate(),
                        ],
                        "This Financial Year": [
                          moment()
                            .month("April")
                            .startOf("month")
                            .format("MMM DD, YYYY"),
                          moment()
                            .add(1, "year")
                            .month("March")
                            .endOf("month")
                            .format("MMM DD, YYYY"),
                        ],
                      },
                    }}
                    onCallback={this.handleCallBackDate}
                  >
                    <input
                      type="text"
                      className="form-control col-4"
                      style={{ display: "inline-block" }}
                    />
                  </DateRangePicker>
                  <MDBDataTableV5
                    hover
                    entriesOptions={[25, 50, 100]}
                    entries={25}
                    pagesAmount={4}
                    span
                    pagingTop
                    searchTop
                    searchBottom={false}
                    barReverse
                    data={this.loadTrasactionListing()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="global_loader" className={_loader}>
          <div className="gl-ldr-cld">
            <div className="gl-ldr-ctr">
              <div className="gl-loader"></div>
            </div>
          </div>
        </div>

        {/* Modal Add Bank Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_SBANK}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler}
          id="modalAdjustNewBank"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0"> 
                <div className="block-header bg-primary-dark">
                  {this.state.activeBankId > 0 && (
                    <h3 className="block-title">Update Bank Account</h3>
                  )}
                  {this.state.activeBankId == 0 && (
                    <h3 className="block-title">Add New Bank</h3>
                  )}
                  {/* <h3 className="block-title">Add New Bank</h3> */}
                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalAdjustNewBank"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddNewBankDetail
                    callBack={this.getUpdateResponse}
                    accountId={this.state.activeBankId}
                    isRedirectTo={"business/manage-account-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalAdjustNewBank"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Details*/}

        {/* Modal Add  Bank Transfer Details Begins */}
        <Modal
          isOpen={this.state.modalIsOpen_BankToCash}
          ariaHideApp={false}
          size="sm"
          aria-labelledby="modal-popout"
          onRequestClose={this.closeModalHandler} 
          id="modalUploadBankToCash"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Bank Transfer</h3>

                  <div className="block-options">
                    <button
                      type="button"
                      className="btn-block-option"
                      onClick={this.closeModalHandler.bind(
                        this,
                        "modalUploadBankToCash"
                      )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddBankTransfer
                    callBack={this.getUpdateBankTranfer}
                    activeBankId={this.state.activeBankId}
                    popType={this.state.popType}
                    isRedirectTo={"business/manage-account-list/"}
                    accountTrasactionId={this.state.activeTrasactionId}
                    bankDataSend={this.state.bankArray}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-alt-secondary"
                  onClick={this.closeModalHandler.bind(
                    this,
                    "modalUploadBankToCash"
                  )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add Bank Transfer Details*/}
        {/* Modal Reopen Details Begins OI */}
        <Modal
            isOpen={this.state.modalIsOpen_Reopen}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalReopenCheque"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Reopen Cheque</h3>
                  <div className="block-options">
                    <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalReopenCheque"
                        )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddReopenDetails
                      minDate={this.state.activeMinDate}
                      isTransaction={this.state.activeTrasactionId}
                      isTransactionOId={this.state.activeTrasactionOId}
                      isTransactionType={this.state.activeTypeAccount}
                      isTransactionOType={this.state.activeTrasactionType}
                      callBack={this.getUpdateResponse}
                      isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                        this,
                        "modalReopenCheque"
                    )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Reopen Details*/}
        {/* Modal Add  charges Loan Details Begins OI */}
        <Modal
            isOpen={this.state.modalIsOpen_MEP_OI}
            ariaHideApp={false}
            size="sm"
            aria-labelledby="modal-popout"
            onRequestClose={this.closeModalHandler}
            id="modalMakePaymentDepositOI"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="block block-themed block-transparent mb-0">
                <div className="block-header bg-primary-dark">
                  <h3 className="block-title">Transfer Cheques</h3>
                  <div className="block-options">
                    <button
                        type="button"
                        className="btn-block-option"
                        onClick={this.closeModalHandler.bind(
                            this,
                            "modalMakePaymentDepositOI"
                        )}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  <QuickAddDepositeMakePaymentOI
                      minDate={this.state.activeMinDate}
                      isTransaction={this.state.activeTrasactionId}
                      isChequeTrasactionId={this.state.activeTrasactionId}
                      isTransactionType={this.state.activeTypeAccount}
                      forUseBank={true}
                      callBack={this.getUpdateResponse}
                      isRedirectTo={"business/manage-cheque-list/"}
                  />
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-alt-secondary"
                    onClick={this.closeModalHandler.bind(
                        this,
                        "modalMakePaymentDepositOI"
                    )}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal Add  charges Loan Details*/}
      </React.Fragment>
    );
  }
}
