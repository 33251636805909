import React,{Component} from 'react';
import {PostData} from '../../../service/postData';
import {sendNotification} from '../../../_notificationSettings/notificationPanel';
import validator from 'validator';

let _signingId = '0';
let _businessId = '0';
let _company_id = '0';
let country_code = '0';
let tax_name = '';
let gstTaxDetails = [];
let _businessSetting = {};


let _loader = 'glb-ldr-prt active';

export default class QuickAddNewSalePurchaseSerialNo extends Component{
    constructor(props){
        super(props);
        let _UserSession = localStorage.getItem('vs_UserSession');
        if(_UserSession!=undefined || _UserSession!=null)
        {
            _UserSession = JSON.parse(_UserSession);
            _signingId = _UserSession.loginId;
        }

        let _companySession = localStorage.getItem('vs_UserCompanySession');
        if(_companySession!=undefined || _companySession!=null)
        {
            _companySession = JSON.parse(_companySession);
            _businessId = _companySession.business_id;
            _company_id = _companySession.company_id;
            country_code = _companySession.country_code;
            tax_name = _companySession.tax_name;
            gstTaxDetails = _companySession.taxDetails;
            if(_UserSession!=null && _UserSession!=''){
                if(_UserSession.loginType!='Admin'){
                    if(_UserSession!=undefined || _UserSession!=null)
                    {
                        _businessSetting = _UserSession.businessSetting;
                    }
                }
            } 
        }

        this.state = {
            _infoTitlePre : this.props.transactionType ? this.props.transactionType : '',  
            _infoPrefixId :0,
            isLoaded : true, 
            businessId:_businessId,
            userId : _signingId,
            company_id: _company_id,            
            isError : false,
            prefixName : '',
            errors: {
                error  :  '',
                without_sr_quantity : '',
                prefixName : '',
            }
        }
    }
    async componentDidMount ()
    {  
        
    }    
    
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {

            case 'prefixName':
                errors.prefixName = value.length < 0 ? '*Please Enter Prefix' : '';
                this.setState({prefixName:value}); 
            break;
            
            default:
            break;
        }
    }  
    
    clickToAddPrefix = (event) => {
        event.preventDefault();
        let errors = this.state.errors;
        let _isError = false;
        if(this.state.prefixName == '')
        {
            _isError = true;
            errors['prefixName'] = "*Please Enter Prefix";
            this.setState({errors: errors});
        }        
        
        if(_isError == false){
            this.addPrefixMethodCall();            
        }
    }

    

    addPrefixMethodCall(){
        
        const requestData = JSON.stringify({
            company_id: this.state.company_id,
            businessId : this.state.businessId,
            type : this.state._infoTitlePre,
            name : this.state.prefixName
        });
        PostData(global.businessAddNewPrefix,requestData,'POST').then((result)=>{
            if(result.success == true)
            {                
                let newPrefixId =  result.prefixId ? result.prefixId : 0 ;
                let newPrefixName =  result.prefixName ? result.prefixName : '';
                sendNotification("Success Message 👍",result.message,"success");
                this.setState({modalIsOpen_AddNewPrefix: false, _infoPrefixId:'', _infoTitlePre:'', prefixArray:[],prefixName:''});
            
                this.props.callBack(newPrefixId,newPrefixName);
            }               
            else
            {
                sendNotification("Error Message 😓",result.message,"danger");
                return;
            }
        });
    } 

    render(){
        if(this.state.isLoaded == true){
            _loader = 'glb-ldr-prt';
        }
        
        const {errors} = this.state;
        let  isDisabled = '';//Object.keys(errors).some(x => errors[x]);
        if(this.state.isError == true){
            isDisabled = 'disabled';
        }
        return(
            <React.Fragment>           
                <form method="post" id="form-login" className="webForm px-30">                  
                    <div className="form-group row"> 
                        
                    </div> 
                    <div className="form-group row">
                        <div className="col-6">
                            <div className="form-material open">
                                <input type="text" required="required" className="form-control" id="prefixName" name="prefixName" value={this.state.prefixName} placeholder="Enter Prefix Name" onChange={this.handleChange}/>
                                <label htmlFor="login-username">Prefix Name</label>
                                <span className="iris_error_txt">{errors.prefixName.length > 0 && <span className='error'>{errors.prefixName}</span>}</span>
                            </div>
                        </div>
                        
                    </div>                          
                    <div className="form-group row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-alt-primary" onClick={this.clickToAddPrefix} disabled={isDisabled}>
                                <i className="fa fa-check mr-5"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )    
    }
}